import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginService } from 'src/app/login/login.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageClientsService {
  manageClientList = new BehaviorSubject<any>(null);
  clientListUrl = environment.clientList;
  deleteClientUrl = environment.deleteClient;
  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private readonly toastrService: ToastrService) { }

    getClients(): Observable<any> {
      const header = new HttpHeaders().set("Authorization", this.loginService.getJwtToken());

      return this.http.get<any>(this.clientListUrl, {headers: header})
      .pipe(
        catchError(err => {
          console.log('failed to get data', err);  
          this.showErrorToastMessage('failed to get client list');
          throw err;
        })
      );
    }

    deleteClients(id): Observable<any> {
      const options = {
        headers: new HttpHeaders().set("Authorization", this.loginService.getJwtToken()),
        body: JSON.stringify({ clientId : id }),
      };

      return this.http.delete(this.deleteClientUrl, options);
    }

    showErrorToastMessage(message: string) {
      this.toastrService.error(message, 'error', {
        timeOut: 3000,
      })
    }
}
