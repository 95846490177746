<div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="warnig-dialogtitle">Warning !</div>
</div>
<div mat-dialog-content tabindex = "0" >
    <p class="warning-content">The following fields do not match with with your record.<br>
    are you sure you want to continue</p>
    <ul class="list-items" *ngFor="let datas of data">
        <li>{{datas}}</li>
    </ul>
<div>
<div>
<button class="cancel-btn" mat-button (click)="onNoClick()">Cancel</button>
<button class="confirm-btn" mat-button (click)="onConfirm()">Confirm</button>
</div>
