export const clientpoliciesdata =
{
  "policies": [
    "78H - Homeowner",
    "Candidate",
    "Global Test Policy",
    "Graduate Intern Policy",
    "Long Term International Policy",
    "Lump Sum Only",
    "New Hire Limited Benefits",
    "Points Policy",
    "Short Term Assignment",
    "US Homeowner",
    "test2"
  ],
  "count": 11
}

export const contractSeriesdata = [
  {
    "billingCurrencies": [
      "USD"
    ],
    "moveTypes": [
      "Candidate Program",
      "Executive Homeowner",
      "Expat Long Term",
      "Homebound Repat",
      "Homesale Homeowner",
      "LT International Transfer",
      "Lump Sum Only",
      "One Way Perm",
      "Renter",
      "Repat",
      "Travel or Assignment Calendar",
      "US Direct Reimbursement",
      "US Domestic Non-Homeowner",
      "US Outbound International Moves",
      "US Trainee 1"
    ],
    "baseContract": "456",
    "contractId": "4",
    "contractDescription": "Demo Global Contract"
  },
  {
    "billingCurrencies": [
      "USD"
    ],
    "moveTypes": [
      "Candidate Program",
      "Executive Homeowner",
      "Expat Long Term",
      "Homebound Repat",
      "Homesale Homeowner",
      "LT International Transfer",
      "Lump Sum Only",
      "One Way Perm",
      "Renter",
      "Repat",
      "Travel or Assignment Calendar",
      "US Direct Reimbursement",
      "US Domestic Non-Homeowner",
      "US Outbound International Moves",
      "US Trainee 1"
    ],
    "baseContract": "567",
    "contractDescription": "Demo Global Contract"
  },
  {
    "billingCurrencies": [
      "USD"
    ],
    "moveTypes": [
      "Candidate Program",
      "Executive Homeowner",
      "Expat Long Term",
      "Homebound Repat",
      "Homesale Homeowner",
      "LT International Transfer",
      "Lump Sum Only",
      "One Way Perm",
      "Renter",
      "Repat",
      "Travel or Assignment Calendar",
      "US Direct Reimbursement",
      "US Domestic Non-Homeowner",
      "US Outbound International Moves",
      "US Trainee 1"
    ],
    "baseContract": "501",
    "contractId": "3.1",
    "contractDescription": "Demo Global Contract"
  },
  {
    "billingCurrencies": [
      "USD"
    ],
    "moveTypes": [
      "Candidate Program",
      "Executive Homeowner",
      "Expat Long Term",
      "Homebound Repat",
      "Homesale Homeowner",
      "LT International Transfer",
      "Lump Sum Only",
      "One Way Perm",
      "Renter",
      "Repat",
      "Travel or Assignment Calendar",
      "US Direct Reimbursement",
      "US Domestic Non-Homeowner",
      "US Outbound International Moves",
      "US Trainee 1"
    ],
    "baseContract": "505",
    "contractDescription": "Demo Client Contract"
  },
  {
    "billingCurrencies": [
      "USD"
    ],
    "moveTypes": [
      "Candidate Program",
      "Executive Homeowner",
      "Expat Long Term",
      "Homebound Repat",
      "Homesale Homeowner",
      "LT International Transfer",
      "Lump Sum Only",
      "One Way Perm",
      "Renter",
      "Repat",
      "Travel or Assignment Calendar",
      "US Direct Reimbursement",
      "US Domestic Non-Homeowner",
      "US Outbound International Moves",
      "US Trainee 1"
    ],
    "baseContract": "23",
    "contractId": "3",
    "contractDescription": "Demo Global Contract"
  },
  {
    "billingCurrencies": [
      "USD"
    ],
    "moveTypes": [
      "Candidate Program",
      "Executive Homeowner",
      "Expat Long Term",
      "Homebound Repat",
      "Homesale Homeowner",
      "LT International Transfer",
      "Lump Sum Only",
      "One Way Perm",
      "Renter",
      "Repat",
      "Travel or Assignment Calendar",
      "US Direct Reimbursement",
      "US Domestic Non-Homeowner",
      "US Outbound International Moves",
      "US Trainee 1"
    ],
    "baseContract": "434",
    "contractId": "3",
    "contractDescription": "Demo Global Contract"
  },
  {
    "billingCurrencies": [
      "USD"
    ],
    "moveTypes": [
      "Candidate Program",
      "Executive Homeowner",
      "Expat Long Term",
      "Homebound Repat",
      "Homesale Homeowner",
      "LT International Transfer",
      "Lump Sum Only",
      "One Way Perm",
      "Renter",
      "Repat",
      "Travel or Assignment Calendar",
      "US Direct Reimbursement",
      "US Domestic Non-Homeowner",
      "US Outbound International Moves",
      "US Trainee 1"
    ],
    "baseContract": "12",
    "contractId": "3",
    "contractDescription": "Demo Global Contract"
  }
]
