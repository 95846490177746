export const familyData = [
  {
    "nameDetails": {
      "title": "Mr.",
      "firstName": "George",
      "lastName": "Winston"
    },
    "phoneDetailsList": [
      {
        "_id": "604f1ec73ccfb800080f2f0b",
        "textingAvailable": true,
        "primary": true,
        "usageType": "personal",
        "phoneDialCode": 1,
        "phoneNumber": "201223232"
      }
    ],
    "emailDetailsList": [
      {
        "_id": "604f1ec728d6550008be6550",
        "primary": true,
        "usageType": "personal",
        "emailAddress": "george_winston@gmail.com"
      }
    ],
    "id": "60260c8ca6b485000813f229",
    "dateOfBirth": null,
    "relationshipType": "Partner"
  }
]