import { Component, OnDestroy, ViewEncapsulation, OnInit, Inject, AfterViewInit, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LocationService } from '../../../core/services/location_cost_model.service';
import { LevelService } from '../../../core/services/level.service';
import { Level } from '../../../core/models/level';
import { Location } from '../../../core/models/location';
import { Subscription, of } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap
} from 'rxjs/operators';
import { CostModel } from '../../../core/models/cost-model';
import { CostModelsService } from '../../../core/services/cost-models.service';
import { NotificationsService } from '../../../core/services/notifications.service';
import {
  addCostModelTitleForCreateCostModel,
  addCostModelMode,
  addCostModelTitleForEditCostModel,
  addCostModelTitleForDeleteCostModel,
  addCostModelTitleForGenerateButton,
  addCostModelTitleForUpdateCostModelButton,
  addCostModelOnePerson,
  addCostModelTwoToThreePeople,
  addCostModelFourPlusPeople,
  addCostModelCurrency,
  minInsuranceAmt,
  insurancePercent,
  apiErrorMessage
} from '../../../core/models/constants';
import {Address } from '../../../core/models/address_cost_model.model';
import { APIResponse } from '../../../core/models/response.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { CostEstimate } from '../../../core/models/cost-estimate';
import { MatSnackBar } from '@angular/material';
import { PartySharedService } from '../../../core/services/party-shared.service';
import { LoggerService } from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-service';
import { LEVEL_MAPPING } from '../../../core/data/cost-model-data';


/**
 * Exporting the error messages
 */
export const errorMessages: { [key: string]: string } = {
  CostModelName: 'You must enter cost model name to save',
  Level: 'You must select level',
  Departure: 'You must select departure',
  Destination: 'You must select destination',
  DeptDestSame: 'Destination cannot be same as Departure',
  NoResults: 'Results not found'
};

/** Base component for Add-Cost Model */
@Component({
  selector: 'app-add-cost-model',
  templateUrl: './add-cost-model.component.html',
  styleUrls: ['./add-cost-model.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddCostModelComponent implements OnInit, OnDestroy, AfterViewInit {
  /** Form group name */
  addCostModelForm: FormGroup;
  /** Title to display the dialog window page title */
  title = addCostModelTitleForCreateCostModel;
  /** Title to display in genearete/update cost model button */
  generateOrUpdateCostModel = addCostModelTitleForGenerateButton;
  /**Flag mode for Create */
  mode = addCostModelMode;
  /**Assign formready Variable as False */
  formReady = false;
  /**Variables for error */
  errors = errorMessages;
  /**Binding the dropdown values to level filed */
  levels: Level;
  /** auto complete values for destination */
  options: Location[];
  /** variable declared for depature */
  departures: Address[];
  /** variable declared for destinations */
  destinations: Address[];
  /** variable declared for panelOpenState */
  panelOpenState = false;
  /** variable declared for costModelEstimates*/
  costModelEstimates: CostEstimate[] = [];
  /** variable declared for displayPredictedRelocationCost*/
  displayPredictedRelocationCost = false;
  /** variable declared for showLoader*/
  showLoader = false;
  /** variable declared for deleteDialog*/
  deleteDialog = false;
  /**addCostModel  model of type CostModel */
  addCostModel: CostModel = {} as CostModel;
  /** Used to unable save buttoin only after Add generate button click */
  enableSaveBtn = false;
  /** Used to show spinner while place API call in progress */
  showdDepartureSpinner = false;
  /** Used to show spinner while place API call in progress */
  showdDestinationSpinner = false;
  /** used to store Single level info retrived by ID */
  levelDetails: Level;
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /** Variable declared to check count of currently openned accordion*/
  isTogglePanelCount = 0;
  /** variable to store person one category */
  single = addCostModelOnePerson;
  /** variable to store person two category */
  twoToThreePeople = addCostModelTwoToThreePeople;
  /** variable to store person three category */
  fourPlusPeople = addCostModelFourPlusPeople;
  /** Currency */
  currency = addCostModelCurrency;
  /** flag for dept selected */
  isDeptSelected: boolean;
  /** flag for dest selected */
  isDestSelected: boolean;
  /**property to enable the generate button */
  isDestinationValid = false;
  /**property to enable the generate button */
  isDepartureValid = false;
  /**Stores client contact Id */
  clientContactId: string;
  /**Flag to set for generate button */
  generateButton = true;
  /** attribute to disable mat expansion panel flickering */
  disableAnimation = true;
  /** property to store old cost model id */
  oldCostModelID: string;

  @ViewChild('costModelFields', { static: false }) costModelFormFields: ElementRef;
  @ViewChild('costPredictionTab', { static: false }) costPrediction: any;
  @ViewChild('CostModelName', { static: false }) costModelElement: ElementRef;

  /**
   * Injecting dependencies
   * @param formBuilder formBuilder property
   * @param dialogRef matdialog reference
   * @param data injecting data
   * @param costModelsService service instance
   * @param locationService service instance
   * @param levelService service instance
   * @param notificationsService service instance
   * @param costModelEstimateService service instance
   * @param spinner spinner object
   * @param snackBar snackbar object
   */
  constructor(
    private readonly formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddCostModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly costModelsService: CostModelsService,
    private readonly locationService: LocationService,
    private readonly levelService: LevelService,
    private readonly notificationsService: NotificationsService,
    private readonly partySharedSvc: PartySharedService,
    private readonly spinner: NgxSpinnerService,
    private readonly snackBar: MatSnackBar,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService,
    private readonly renderer: Renderer2
  ) {
    /** Get all the levels */
    this.levelService.levelList$.subscribe(level => {
      this.levels = level;
    });
    /* Create the Add/Edit dialog window */
    this.initializeCostModelForm();
  }

  /**
   * Used for initializing Cost Model Form
   */
  initializeCostModelForm(): void {
    this.addCostModelForm = this.formBuilder.group({
      ModelName: [''],
      Level: ['', Validators.required],
      Departure: ['', Validators.required],
      Destination: ['', Validators.required]
    });
  }

  /**
   * On Page load, sets the flag to show appropriate buttons based on status
   */
  ngOnInit() {
    this.partySharedSvc.getPartyId().then(id => { this.clientContactId = id; });
    /* If the data is present - it will open and pre-populate dialog window */
    if (this.data.costModels) {
      /** set page title to edit candidate */
      this.title = addCostModelTitleForEditCostModel;
      this.displayPredictedRelocationCost = true;
      this.generateButton = true;
      this.enableSaveBtn = true;
      /** Title to display in genearete/update cost model button */
      this.generateOrUpdateCostModel = addCostModelTitleForUpdateCostModelButton;
      /* Setting the default values for form elements in edit candidate dialog */
      this.addCostModelForm.get('ModelName').setValue(this.data.costModels.costModelName);
      this.addCostModelForm
        .get('Destination')
        .setValue(
          `${this.data.costModels.destCity}, ${this.data.costModels.destState}`
        );
      this.addCostModelForm
        .get('Departure')
        .setValue(`${this.data.costModels.deptCity}, ${this.data.costModels.deptState}`);
      this.addCostModelForm.get('Level').setValue(this.data.costModels.level);

      this.data.costModels.costEstimates.sort((a, b) => (a.familySize > b.familySize) ? 1 : -1);
      for (const estimate of this.data.costModels.costEstimates) {
        estimate.familySizeDesc = estimate.familySize === 1 ? addCostModelOnePerson :
          (estimate.familySize === 2 || estimate.familySize === 3) ? addCostModelTwoToThreePeople : addCostModelFourPlusPeople;
      }
      this.costModelEstimates = this.data.costModels.costEstimates;
      this.formatCostModels(this.data.costModels);


      this.loggedInUserService.getLoggedInUserDetails()
        .subscribe(response => {
          const userId: any = response.userId.replace(/ .*/, '');
        });
    }

  }

  /**
     * A callback method that is invoked immediately after
     * Angular has completed initialization of a component's view.
     * It is invoked only once when the view is instantiated.
     *
     */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.disableAnimation = false;
      this.costModelElement.nativeElement.focus();
    }, 500);
  }

  /**on departure field change */
  onDepartureInput(search) {
    this.isDepartureValid = true;
    of(search)
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(searchTerm => {
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          this.showdDepartureSpinner = true;
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          this.isDeptSelected = false;
          return this.locationService.getAddresses(searchTerm);
        })
      )
      .subscribe(locations => {
        this.showdDepartureSpinner = false;
        this.departures = locations;
        if (this.departures && this.departures.length === 0) {
          this.addCostModelForm.controls['Departure'].setErrors({
            invalidAddress: true
          });
        }
      });
  }
  /**on destination input change */
  onDestinationInput(search) {
    this.isDestinationValid = true;
    of(search)
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        switchMap(searchTerm => {
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          this.showdDestinationSpinner = true;
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          this.isDestSelected = false;
          return this.locationService.getAddresses(searchTerm);
        })
      )
      .subscribe(locations => {
        this.showdDestinationSpinner = false;
        this.destinations = locations;
        if (this.destinations && this.destinations.length === 0) {
          this.addCostModelForm.controls['Destination'].setErrors({
            invalidAddress: true
          });
        }
      });
  }
  /**On Departure selection closed */
  onDepartureSelectionClosed() {
    this.isDepartureValid = false;
    if (!this.isDeptSelected && this.addCostModelForm.controls['Departure'].value !== '') {
      this.addCostModelForm.controls['Departure'].setErrors({
        invalidAddress: true
      });
    }
    this.checkAddress();
  }

  /** on dept selection */
  onDepartureSelected() {
    this.isTogglePanelCount = 0;
    this.isDeptSelected = true;
    this.departures = [];
    this.checkAddress();
  }

  /** on dest dropdown close */
  onDestinationSelectionClosed() {
    this.isDestinationValid = false;
    if (!this.isDestSelected && this.addCostModelForm.controls['Destination'].value !== '') {
      this.addCostModelForm.controls['Destination'].setErrors({
        invalidAddress: true
      });
    }
    this.checkAddress();
  }

  /** on dest selection */
  onDestinationSelected() {
    this.isTogglePanelCount = 0;
    this.isDestSelected = true;
    this.destinations = [];
    this.checkAddress();
  }

  /**method to be called on keydown event for departure */
  onDeptTabOut() {
    if (this.isDeptSelected !== undefined && !this.isDeptSelected && this.addCostModelForm.controls.Departure.value) {
      this.addCostModelForm.controls['Departure'].setErrors({
        invalidAddress: true
      });
    }
  }

  /**method to be called on keydown event for destination */
  onDestTabOut() {
    if (this.isDestSelected !== undefined && !this.isDestSelected && this.addCostModelForm.controls.Destination.value) {
      this.addCostModelForm.controls['Destination'].setErrors({
        invalidAddress: true
      });
    }
  }


  /**
   * used to check if departure address is same as destination address
   * @param input form with required fields
   */
  // addressValidator(input: AbstractControl): ValidationErrors | null {
  //   return new Promise((resolve, reject) => {
  //     if (input.value === input.root.value.Departure) {
  //       resolve({ sameAddress: true });
  //     }
  //     resolve(null);
  //   });
  // }

  /**
   * Cross field validation for departure and destination fields
   * @param formControlName Holds the FormControl name
   */
  checkAddress() {
    if (this.addCostModelForm.controls['Destination'].value) {
      if (
        this.addCostModelForm.controls['Departure'].value ===
        this.addCostModelForm.controls['Destination'].value
      ) {
        this.addCostModelForm.controls['Destination'].markAsTouched();
        this.addCostModelForm.controls['Destination'].setErrors({
          sameAddress: true
        });
      } else if (this.destinations) {
        if (this.addCostModelForm.controls['Destination'].value !== '' && !this.isDestSelected) {
          this.addCostModelForm.controls['Destination'].setErrors({
            invalidAddress: true
          });
        } else {
          this.addCostModelForm.controls['Destination'].setErrors(null);
        }
      } else {
        this.addCostModelForm.controls['Destination'].setErrors(null);
      }
    } else {
      if (this.addCostModelForm.controls['Destination'].value.length === 0) {
        this.addCostModelForm.controls['Destination'].setErrors({
          required: true
        });
      } else {
        this.addCostModelForm.controls['Destination'].clearValidators();
        this.addCostModelForm.controls['Destination'].updateValueAndValidity();
      }
    }
  }

  /**
   * Click on Submit button inside the addCstModelForm dialog window
   */
  saveCostModel(): void {
    this.spinner.show();
    this.populateCostModelObject();

    if (this.data.costModels) {
      this.addCostModel.oldCostModelId = this.oldCostModelID;
    }
    const costModelID = this.data.costModelId ? this.data.costModelId : this.data.costModels.costModelId;
    delete this.addCostModel.costModelId;
    delete this.addCostModel.clientContactId;

    this.costModelsService.saveCostModel(costModelID, this.addCostModel).subscribe((costmodel: CostModel) => {
      this.addCostModelForm.enable();
      this.spinner.hide();
      if (costmodel) {
        costmodel.oldCostModelId = this.oldCostModelID;
        this.dialogRef.close(costmodel);
        this.showLoader = false;
      } else {
        this.showLoader = false;
        this.snackBar.open(
          apiErrorMessage,
          undefined,
          { duration: 5000 }
        );
      }
    },
      err => {
        this.addCostModelForm.enable();
        this.spinner.hide();
      });
  }

  /**
   * function to populate the cost model details in addCostModel
   */
  populateCostModelObject() {
    this.addCostModel.clientContactId = this.clientContactId;

    this.addCostModel.costModelName = this.addCostModelForm.controls[
      'ModelName'
    ].value;
    this.addCostModel.level = this.addCostModelForm.controls['Level'].value;
    this.addCostModel.deptCity = this.addCostModelForm.controls[
      'Departure'
    ].value.split(',')[0];
    this.addCostModel.deptState = this.addCostModelForm.controls[
      'Departure'
    ].value.split(',')[1].trim();

    this.addCostModel.destCity = this.addCostModelForm.controls[
      'Destination'
    ].value.split(',')[0];
    this.addCostModel.destState = this.addCostModelForm.controls[
      'Destination'
    ].value.split(',')[1].trim();

    delete this.addCostModel.costEstimates;
    delete this.addCostModel.taxGrossRate;
    delete this.addCostModel.createdDate;
    delete this.addCostModel.updatedDate;
  }
  /**
   * Used to delete the Cost Model.
   */
  deleteCostModel(): void {
    this.costModelsService.deleteCostModel(this.data.costModels.costModelId);
    this.dialogRef.close();
  }

  /**
   * Closing the dialog box - we are setting the form to empty
   */
  onNoClick(evt) {
    evt.preventDefault();
    this.dialogRef.close();
    this.initializeCostModelForm();
  }

  /**
   * show predict relocation cost
   */
  showPredictedRelocationCost(): void {
    this.renderer.setAttribute(this.costModelFormFields.nativeElement, 'role', 'alert');
    this.renderer.setAttribute(this.costModelFormFields.nativeElement, 'aria-hidden', 'false');
    this.renderer.setAttribute(this.costModelFormFields.nativeElement, 'aria-live', 'assertive');
    this.renderer.setAttribute(this.costModelFormFields.nativeElement, 'aria-atomic', 'true');
    this.showLoader = true;

    if (
      this.addCostModelForm.controls.Departure.value !== '' &&
      this.addCostModelForm.controls.Departure.valid &&
      this.addCostModelForm.controls.Destination.value !== '' &&
      this.addCostModelForm.controls.Destination.valid &&
      this.addCostModelForm.controls.Level.value !== '' &&
      this.addCostModelForm.controls.Level.valid
    ) {
      this.addCostModelForm.disable();
      this.populateCostModelObject();
      if (this.data.costModels) {
        this.oldCostModelID = this.addCostModel.costModelId ? this.addCostModel.costModelId : this.data.costModels.costModelId;
      }
      delete this.addCostModel.costModelId;

      this.costModelsService.createCostModel(this.addCostModel).subscribe((costmodel: CostModel) => {
        this.addCostModelForm.enable();
        this.renderer.removeAttribute(this.costModelFormFields.nativeElement, 'role');
        this.renderer.removeAttribute(this.costModelFormFields.nativeElement, 'aria-live');
        this.renderer.removeAttribute(this.costModelFormFields.nativeElement, 'aria-atomic');
        this.renderer.setAttribute(this.costModelFormFields.nativeElement, 'aria-hidden', 'true');
        if (costmodel) {
          this.renderer.setAttribute(this.costPrediction.nativeElement, 'aria-hidden', 'false')
          this.costPrediction.nativeElement.focus();

          this.enableSaveBtn = true;
          this.addCostModel.costModelId = costmodel.costModelId;
          this.responseAssignmentToCostModel(costmodel);
          this.showLoader = false;
        } else {
          this.showLoader = false;
          this.snackBar.open(
            apiErrorMessage,
            undefined,
            { duration: 5000 }
          );
        }
      },
        err => {
          this.addCostModelForm.enable();
          this.showLoader = false;
        }
      );

    }
  }

  /**
   * Function Used to Update Cost Model Object
   * @param resp Getting response of API
   */
  responseAssignmentToCostModel(costmodel: CostModel) {
    this.addCostModel.costModelId = costmodel.costModelId;
    costmodel.costEstimates.sort((a, b) => (a.familySize > b.familySize) ? 1 : -1);
    for (const estimate of costmodel.costEstimates) {
      estimate.familySizeDesc = estimate.familySize === 1 ? addCostModelOnePerson :
        (estimate.familySize === 2 || estimate.familySize === 3) ? addCostModelTwoToThreePeople : addCostModelFourPlusPeople;
    }
    this.addCostModel.costEstimates = costmodel.costEstimates;
    this.addCostModel.taxGrossRate = costmodel.taxGrossRate;
    this.addCostModel.createdDate = costmodel.createdDate;
    this.addCostModel.updatedDate = costmodel.updatedDate;

    this.displayPredictedRelocationCost = true;
    this.generateButton = true;
    this.costModelEstimates = costmodel.costEstimates;
    this.formatCostModels(costmodel);
  }
  /**
   * Method to check count of currently openned accordion
   * @param panel is accordion close or open event
   */
  togglePanel(panelEvent) {
    if (panelEvent === 'opened') {
      ++this.isTogglePanelCount;
    } else {
      --this.isTogglePanelCount;
    }
  }
  /**Function to be called when input field is changed */
  onInputChange() {
    this.generateButton = false;
    this.enableSaveBtn = false;
  }
  onLevelChange(level) {
    LEVEL_MAPPING.filter((lev) => {
      if (lev.level == level.value) {
        this.costModelEstimates = lev.costEstimates;
      }
    });
    for (const estimate of this.costModelEstimates) {
      estimate.familySizeDesc = estimate.familySize === 1 ? addCostModelOnePerson :
        (estimate.familySize === 2 || estimate.familySize === 3) ? addCostModelTwoToThreePeople : addCostModelFourPlusPeople;
    }
  }
  formatCostModels(costModels) {
    const taxGrossRate = Number(costModels.taxGrossRate);
    for (const costmodels of costModels.costEstimates) {
      const minInsurance = (costmodels.coreServices.estimatedSubTotalMinimumCost * insurancePercent);
      const maxInsurance = (costmodels.coreServices.estimatedSubTotalMaximumCost * insurancePercent);
      /**calculate core services amount including insurance */

      (minInsurance <= minInsuranceAmt && minInsurance !== 0) ? costmodels.coreServices.minCostInsurance =
        costmodels.coreServices.estimatedSubTotalMinimumCost +
        minInsuranceAmt : costmodels.coreServices.minCostInsurance = costmodels.coreServices.estimatedSubTotalMinimumCost + minInsurance;

      (maxInsurance <= minInsuranceAmt && maxInsurance !== 0) ? costmodels.coreServices.maxCostInsurance =
        costmodels.coreServices.estimatedSubTotalMaximumCost +
        minInsuranceAmt : costmodels.coreServices.maxCostInsurance = costmodels.coreServices.estimatedSubTotalMaximumCost + maxInsurance;
      /**calculate tax amount without insurance */
      costmodels.minTaxAmount = (costmodels.coreServices.estimatedSubTotalMinimumCost * taxGrossRate) +
        (costmodels.flexServices.estimatedSubTotalMinimumCost * taxGrossRate);
      costmodels.maxTaxAmount = (costmodels.coreServices.estimatedSubTotalMaximumCost * taxGrossRate) +
        (costmodels.flexServices.estimatedSubTotalMaximumCost * taxGrossRate);


      costmodels.estimatedTotalMinimumCost = Math.round(costmodels.coreServices.minCostInsurance) +
        Math.round(costmodels.flexServices.estimatedSubTotalMinimumCost) +
        Math.round(costmodels.minTaxAmount);

      costmodels.estimatedTotalMaximumCost = Math.round(costmodels.coreServices.maxCostInsurance) +
        Math.round(costmodels.flexServices.estimatedSubTotalMaximumCost) +
        Math.round(costmodels.maxTaxAmount);
    }
  }
  /**
   * destroys the object
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
