<div fxLayout="row" fxLayoutAlign="end end">
    <button (click)="closeDialog($event)" class="close-wrapper">
        <mat-icon class="close-icon" aria-hidden="false" aria-label="close icon" role="button">close</mat-icon>
    </button>
</div>
<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center" class="dialog-title">
    <div class="title-wrapper" fxLayoutAlign="start center" fxLayoutGap="15px">
        <img src="../../../../../assets/images/family.svg" alt="title icon" />
        <span class="benefit-card-title">My Family</span>
    </div>
    <p *ngIf="familyMembersRelocating" class="no-people-relocating" fxFlex="46%" fxLayout="row" fxLayoutAlign="space-between center" fxHide.sm=true fxHide.xs=true>
        <span>Total People Relocating</span>
        <span class="no-relocating"> {{familyMembersRelocating}}</span>
    </p>
</div>
<div mat-dialog-content class="dialog-content" *ngIf="data">
    <p *ngIf="familyMembersRelocating" class="no-people-relocating" fxLayout="row" fxLayoutAlign="space-between center" fxHide=true fxHide.sm=false fxHide.xs=false>
        <span>Total People Relocating</span>
        <span class="no-relocating">{{familyMembersRelocating}}</span>
    </p>
    <div *ngIf="familyInformation && familyInformation.length>1">
        <p *ngIf="username">
            <span class="relation">
                You
            </span>
            <span>:</span>
            <span class="name">
                {{username}}
            </span>
        </p>
        <p *ngFor="let familyData of familyInformation">
            <span class="relation">
                {{familyData.relationshipType}}
            </span>
            <span>:</span>
            <span class="name">
                {{familyData.nameDetails.firstName}} {{familyData.nameDetails.lastName}}
            </span>
        </p>
    </div>
</div>
<div mat-dialog-actions fxLayoutAlign="end end" fxLayoutAlign.xs="space-between center" class="btn-wrapper">
    <button class="close-btn" (click)="closeDialog($event);">Close</button>
    <button class="edit-btn" (click)="updateFamilyMembers($event);">Edit</button>
</div>