import {
  Component,
  OnInit,
  ViewChild,
  Input,
  OnDestroy,
  ViewEncapsulation
} from '@angular/core';
import { MatSort, MatPaginator, MatSnackBar } from '@angular/material';
import { MatTableDataSource } from '@angular/material/table';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialog } from '@angular/material/dialog';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup } from '@angular/forms';
import { Selection } from '../../core/models/selection.model';
import { CostModel } from '../../core/models/cost-model';
import { CostModelsService } from '../../core/services/cost-models.service';
import { AddCostModelComponent } from './add-cost-model/add-cost-model.component';
import { NotificationsService } from '../../core/services/notifications.service';
import { Subscription, of } from 'rxjs';
import { LevelService } from '../../core/services/level.service';
import { DeleteCostModelComponent } from './delete-cost-model/delete-cost-model.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvitationSentComponent } from '../components/invitation-sent/invitation-sent.component';
import { CandidateProfilesService } from '../../core/services/candidate-profiles.service';
import { Level } from '../../core/models/level';
import { Router, NavigationExtras } from '@angular/router';
import { PartySharedService } from '../../core/services/party-shared.service';
import { LoggerService } from '../../core/services/logger.service';
import { LoggedInUserService } from '../../core/services/loggedin-user-service';
import {
  debounceTime,
  distinctUntilChanged,
  switchMap
} from 'rxjs/operators';
import * as querystring from 'querystring';
import { apiErrorMessage, skipRecordCount } from '../../core/models/constants';

/** Base component for Cost Model */
@Component({
  selector: 'app-cost-model',
  templateUrl: './cost-model.component.html',
  styleUrls: ['./cost-model.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CostModelComponent implements OnInit, OnDestroy {
  /**Columns title name */
  displayedColumns: string[] = [
    'select',
    'costModelName',
    'level',
    'deptState',
    'destState',
    'status'
  ];

  /** Form to capture the candidate details */
  addCandidateForm: FormGroup;

  /** Data source for table */
  dataSource: any;

  /** Stores the details of Cost Models */
  ELEMENT_DATA: CostModel[] = [];

  /** Store the filter value */
  filterText = '';

  /** Used to delete Cost Model */
  deleteFlag = false;

  /** Holds the Count of the Cost models */
  currentCostModelCount: number;

  /**to store the rows selected */
  selection = new SelectionModel<CostModel>(true, []);

  /** Used for sorting */
  sortBy = '';
  /** sorting direction */
  sortDir = '';
  /** Subscription prop for unsubscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**stores initialCostModel count */
  initialCostModelCount = 0;
  pageInfo = {
    pageSize: 10,
    pageIndex: 0,
    totalCount: 0
  };
  /**Announce sort statement for accessibility */
  sortStatement = '';
  /**store disable button value based on role */
  disableButton = false;
  /**
   * Injecting dependencies
   * @param dialog object for mat-dialog
   * @param costModelsService Object for CostModelService
   * @param notificationsService Object for NotificationsService
   * @param spinner Object for spinner
   * @param levelService Object for LevelServices
   * @param snackBar object for MatSnackBar
   * @param candidateProfilesService object for CandidateProfilesService
   */
  constructor(
    public dialog: MatDialog,
    private readonly costModelsService: CostModelsService,
    private readonly notificationsService: NotificationsService,
    public levelService: LevelService,
    public spinner: NgxSpinnerService,
    public snackBar: MatSnackBar,
    private readonly candidateProfilesService: CandidateProfilesService,
    private readonly _router: Router,
    private readonly partySharedSvc: PartySharedService,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService
  ) { }

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) set sortOrder(sort: MatSort) {
    if (sort) {
      this.dataSource.sort = sort;
    }
  }
  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  /** Input property for receiving data Holds the Selected Columns*/
  @Input() selectedCols: Selection[];
  /** Stores the client contact ID */
  clientContactId: string;
  /**To initialize the component */
  ngOnInit() {
    this.partySharedSvc.getPartyId().then(id => {
      if (id) {
        this.clientContactId = id;
        this.loadCandidates(this.pageInfo.pageSize, this.pageInfo.pageIndex);
      } else {
        this.spinner.hide();
      }
    },
      err => {
        this.spinner.hide();
      }
    );

    this.subscription.add(
      this.levelService.getLevelDetails().subscribe(levels => {
        const levelDetails: Level[] = [];
        for (let index = 0; index < levels.length; index++) {
          const level = levels[index];
          const detailedLevel: Level = {
            levelId: `L${index + 1}`,
            levelName: level,
            levelDescription: level.split('(')[0].trim(),
            levelRange: level.replace('(', '#(').split('#')[1]
          };
          levelDetails.push(detailedLevel);
        }
        this.levelService.level$.next(levelDetails);
      })
    );
  }

  sortData(event) {
    switch (event.active) {
      case 'deptState':
        event.active = 'departure';
        break;
      case 'destState':
        event.active = 'destination';
        break;
    }
    switch (event.direction) {
      case 'asc':
        this.sortStatement = `sorting ${event.active} in ascending order`;
        break;
      case 'desc':
        this.sortStatement = `sorting ${event.active} in descending order`;
        break;
      case '':
        this.sortStatement = `sorting ${event.active} in random order`;
        break;
    }
    this.sortBy = event.active;
    this.sortDir = event.direction === 'desc' ? 'DESC' : 'ASC';
    this.loadCandidates(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }

  getQueryString(_clientContactId, _searchText?, _sortField?, _sortDir?, _skip?, _limit?): string {
    const _queryString = { clientContactId: _clientContactId };
    if (_searchText) { _queryString['searchText'] = _searchText; }
    if (_sortField) { _queryString['sortField'] = _sortField; }
    if (_sortDir) { _queryString['sortDir'] = _sortDir; }
    if (_skip) { _queryString['skip'] = _skip; }
    if (_limit) { _queryString['limit'] = _limit; }
    return querystring.stringify(_queryString);
  }

  loadCandidates(pageSize: number, skipRecords: number) {
    this.spinner.show();
    this.subscription.add(this.costModelsService
      .getCostModels(this.getQueryString(this.clientContactId, this.filterText, this.sortBy, this.sortDir, skipRecords, pageSize))
      .subscribe(response => {
        if (response && response.status === 204) {
          this.calculateActiveCostModels(0);
          this.ELEMENT_DATA = [];
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.spinner.hide();
        } else if (response && response.status === 200 && response.body) {
          this.formatModels(response.body.costmodels);
          this.initialCostModelCount = Number(response.body.totalCostModel);
          this.ELEMENT_DATA = response.body.costmodels;
          this.currentCostModelCount = this.ELEMENT_DATA.length;
          this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          this.dataSource.sort = this.sortOrder;
          setTimeout(() => {
            this.paginator.length = Number(response.body.totalCostModel);
          });
          this.dataSource.paginator = this.paginator;
          this.dataSource.sortingDataAccessor = this.getPropertyByPath;
          this.dataSource.filterPredicate = this.customFilterPredicate;
          this.calculateActiveCostModels(this.initialCostModelCount);
          this.spinner.hide();
          this.loggedInUserService.getLoggedInUserDetails()
            .subscribe(result => {
              const userId: any = response.userId.replace(/ .*/, '');
            });
        } else {
          this.calculateActiveCostModels(this.initialCostModelCount);
          this.spinner.hide();
          this.snackBar.open(
            'apiErrorMessage',
            undefined,
            { duration: 5000 }
          );
        }
      },
        err => {
          this.spinner.hide();
        }
      ));
  }

  formatModels(models) {
    for (const costModel of models) {
      const date = new Date(costModel.updatedDate);
      costModel.expiryDate = new Date(date.setDate(date.getDate() + 30))
        .toISOString()
        .substring(0, 10);
      costModel.status =
        new Date() > new Date(costModel.expiryDate) ? 'Expired' : 'Active';
      costModel.levelName = costModel.level.split('(')[0];
      costModel.levelDes = costModel.level.replace('(', '#(').split('#')[1];
      costModel.deptFullAdd = `${costModel.deptState}, ${costModel.deptCity}`;
      costModel.destFullAdd = `${costModel.destState}, ${costModel.destCity}`;
      if (costModel.clientContactName) {
        costModel.clientContactNameFormat = `creator: ${costModel.clientContactName}`;
      }
    }
  }

  /**
   * Search
   * @param data Data to search
   * @param filter Filter
   */
  customFilterPredicate(data, filter): boolean {
    const searchTerm = filter.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const dataStr =
      data.destFullAdd +
      data.costModelName +
      data.deptFullAdd +
      data.level +
      data.status;
    return dataStr.search(new RegExp(searchTerm, 'gi')) !== -1;
  }

  /**
   * Applying filter value to the data defined
   * @param filterValue holds the filter value
   */
  applyFilter(filterValue: string) {
    this.filterText = filterValue.trim();
    if (this.filterText && this.filterText.length >= 3) {
      this.paginator.pageIndex = skipRecordCount;
      return this.loadCandidates(this.pageInfo.pageSize, skipRecordCount);
    } else if (this.filterText === '') {
      return this.loadCandidates(this.pageInfo.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
    }
    return false;
  }

  /**
   * function to get property by path
   * @param obj -object passed
   * @param pathString -path passed
   */
  getPropertyByPath(obj: Object, pathString: string) {
    return pathString.split('.').reduce((o, i) => o[i], obj);
  }

  /**
   * Function to update the CostModels in the table
   */
  updateDataSource(costModel: CostModel) {
    if (costModel) {
      const index = this.ELEMENT_DATA.findIndex(
        x => x.costModelId === costModel.costModelId
      );
      if (index === -1) {
        this.ELEMENT_DATA.push(costModel);
        this.initialCostModelCount = this.initialCostModelCount + 1;
      } else {
        this.ELEMENT_DATA[index] = costModel;
      }
      /** Delete the Old Cost Model */
      const oldCostModelIndex = this.ELEMENT_DATA.findIndex(
        x => x.costModelId === costModel.oldCostModelId
      );
      if (oldCostModelIndex !== -1) {
        this.ELEMENT_DATA.splice(oldCostModelIndex, 1);
        this.initialCostModelCount = this.initialCostModelCount - 1;
      }

      this.formatModels(this.ELEMENT_DATA);
      this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
      this.dataSource.sort = this.sortOrder;
      setTimeout(() => {
        this.paginator.length = this.ELEMENT_DATA.length;
      });
      this.dataSource.paginator = this.paginator;
      this.dataSource.sortingDataAccessor = this.getPropertyByPath;
      this.calculateActiveCostModels(this.initialCostModelCount);
    }
  }

  /**
   * Method to check if all the rows in the mat table were selected
   */
  isAllSelected() {
    if (this.ELEMENT_DATA.length > 0) {
      const numSelected = this.selection.selected.length;
      const numRows = this.dataSource.data.length;
      return numSelected === numRows;
    }
  }

  /**
   * Method to toggle select all or clear all for rows inside in the mat table
   */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /**
   * function for selecting the rows in the table
   * @param row Represent Individual row
   */
  checkboxLabel(row?: CostModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'
      } row ${row.costModelName + 1}`;
  }

  /**
   * Open the dialog box AddCostModelComponent in EDIT mode when any row is clicked of the mat table
   * @param event Triggered event
   * @param data data to send to AddCostModel
   */
  public open(event, data): void {
    event.stopPropagation();
    event.preventDefault();
    const dialogRef = this.dialog.open(AddCostModelComponent, {
      panelClass: 'dialogMainContainer',
      disableClose: true,
      data: {
        costModels: data,
        disableButton: this.disableButton
      }
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((costModel: CostModel) => {
        if (costModel) {
          costModel.status = 'Active';
          costModel.updatedDate = new Date().toISOString().substring(0, 10);
          const date = new Date(costModel.updatedDate);
          costModel.expiryDate = new Date(date.setDate(date.getDate() + 30))
            .toISOString()
            .substring(0, 10);
          this.updateDataSource(costModel);
          this.notificationsService.flashNotification(
            'success',
            'Cost Model Updated Successfully',
            true,
            'dismiss'
          );
        }
      })
    );
  }

  /**
   * Updating the Table
   */
  updateTable(): void {
    this.selectedCols.forEach((item, index) => {
      if (this.displayedColumns.indexOf(item.value) < 0) {
        this.displayedColumns.push(item.value);
      } else {
        this.displayedColumns = this.displayedColumns.filter(val => {
          return item.value === val;
        });
      }
    });
    if (this.displayedColumns.findIndex(val => val === 'select') < 0) {
      this.displayedColumns.unshift('select');
    }
  }

  /**
   * delete CostModels
   */
  deleteCostModels() {
    const data = {
      noOfSelected: this.selection.selected.length,
      selectedCostModels: this.selection.selected
    };
    const dialogRef = this.dialog.open(DeleteCostModelComponent, {
      panelClass: ['dialogMainContainer', 'cost-candidate-dialog-container'],
      data
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe(
        result => {
          if (result === 'cancel') {
            this.selection.clear();
          } else if (result === 'deleteCostModel') {
            this.spinner.show();
            const costModelId = [];
            this.selection.selected.forEach(costModel => {
              costModelId.push(costModel.costModelId);
            });
            const deleteDetails = { clientContactId: this.clientContactId, costModelId };

            // service call
            this.subscription.add(
              this.costModelsService
                .deleteCostModel(deleteDetails)
                .subscribe(
                  res => {
                    if (res) {
                      this.spinner.hide();
                      this.selection.selected.forEach(costModel => {
                        const index: number = this.ELEMENT_DATA.findIndex(d => d === costModel);
                        this.dataSource.data.splice(index, 1);
                      });
                      // snack bar inside subscribe
                      this.snackBar.openFromComponent(InvitationSentComponent, {
                        horizontalPosition: 'center',
                        verticalPosition: 'bottom',
                        data: this.selection.selected.length === 1 ? `${this.selection.selected.length} Cost Model Deleted` :
                          `${this.selection.selected.length} Cost Models Deleted`,
                        duration: 5000
                      });
                      this.ELEMENT_DATA = this.ELEMENT_DATA.filter(
                        costModel => !this.selection.selected.includes(costModel)
                      );
                      this.initialCostModelCount = this.initialCostModelCount - this.selection.selected.length;
                      this.selection.clear();
                      // this.updateDataSource();
                      // Has to be removed and uncomment this.updateDataSource(); when Api is integrated
                      this.dataSource = new MatTableDataSource<Element>(this.dataSource.data);
                      this.currentCostModelCount = this.dataSource.length;
                      this.dataSource.sort = this.sortOrder;
                      this.dataSource.sortingDataAccessor = this.getPropertyByPath;
                      setTimeout(() => {
                        this.paginator.length = this.initialCostModelCount;
                      });
                      this.calculateActiveCostModels(this.initialCostModelCount);
                    } else {
                      this.spinner.hide();
                      this.snackBar.open(
                        apiErrorMessage,
                        undefined,
                        { duration: 5000 }
                      );
                    }
                  },
                  err => {
                    this.spinner.hide();
                  }
                )
            );

          }
        },
        err => {
          this.spinner.hide();
        }
      )
    );
  }
  /**Function to calculate the number of cost models */
  calculateActiveCostModels(totalCostModelCount) {
    this.candidateProfilesService.totalCount.next({
      type: 'Cost Models',
      length: totalCostModelCount,
      label: 'Cost Models'
    });
  }
  navigateToCompareCostModels() {
    this.spinner.show();
    {
      const data: NavigationExtras = {
        state: {
          selectedCostModels: this.selection.selected
        }
      };
      setTimeout(() => {
        this._router.navigate(['project-alpha/compare-cost-models'], data);
        this.spinner.hide();
      }, 100);
    }
  }
  /**
   * function to capture the event emitted
   * @param flag boolean value
   */
  isDisabled(flag) {
    this.disableButton = flag;
    if (this.disableButton === true) {
      this.displayedColumns = [
        'select',
        'costModelName',
        'client',
        'level',
        'deptState',
        'destState',
        'status'
      ];
    }
  }
  /**
   * Function called when page changed
   * @param event page event
   */
  onPagination(event: any) {
    this.pageInfo.pageSize = this.paginator.pageSize;
    this.loadCandidates(this.paginator.pageSize, this.paginator.pageIndex * this.paginator.pageSize);
  }
  /**
   * destroys the object
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
