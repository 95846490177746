import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Sentiments } from '../models/sentiments.model';
import { HttpParams } from '@angular/common/http';
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';

/** SentimentService to fetch sentiment  details from Api and to display in the details table and Quick view popup */
@Injectable({
  providedIn: 'root'
})
export class SentimentService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
  */
  constructor(private readonly baseClient: BaseClientService, 
    private readonly logSvc: RemoteLoggingService,
    private moveProDashboadDataService: MoveProDashboadDataService) { }

  /** getSentimentsByFileNum() to fetch Sentiment details from Api as sentiment[] */
  getSentimentsByFileNum(fileNumber: number, itemsPerPage: number = 0): Observable<Sentiments> {
    let params: HttpParams;
    if (itemsPerPage > 0) {
      params = new HttpParams({
        fromObject: {
          'itemsPerPage': itemsPerPage.toString()
        }
      });
    }
    // return this.baseClient
    //   .getOne<Sentiments>(`v1/sentiment/${fileNumber}`, 'get the sentiment details', params)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: Sentiments = null;
    //       this.logSvc.logError(err);
    //       return of(empty);
    //     })
    //   );
    let response = [];
    this.moveProDashboadDataService.sentimentDataSubject.getValue().filter((ele) => {
      if (ele.fileNumber === fileNumber) {
        response.push(ele);
      }
    });
    if (!response || response.length == 0) {      
      response = [
        {
          "fileNumber": fileNumber,
          "timeLine": [],
          "totalSentiments": 0
        }
      ];
    }
    return of(JSON.parse(JSON.stringify(response[0])));
  }
}
