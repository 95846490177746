import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BBAdminSharedModule } from 'projects/BBAdmin/src/app/app.module';
import { BenefitBuilderSharedModule } from 'projects/BenefitBuilder/src/app/app.module';
import { MobilifySharedModule } from 'projects/Mobilify/src/app/app.module';
import { DashBoardSharedModule } from 'projects/Dashboard/src/app/app.module';
import { HomePageComponent } from './public/home-page/home-page.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { LoginComponent } from './login/login.component';
import { FooterComponent } from './public/footer/footer.component'
import { LeftPanelComponent } from './public/left-panel/left-panel.component';
import { MaterialModule } from './material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserComponent } from './user/user.component';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatMenuModule, MatButtonModule } from '@angular/material';
import { ManageUsersComponent } from './public/manage-users/manage-users.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { ManageClientsComponent } from './public/manage-clients/manage-clients.component';
import { AddEditClientsComponent } from './public/clients/add-edit-clients/add-edit-clients.component';
import { MatchPasswordDirective } from './user/match-password.directive';
@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    LoginComponent,
    FooterComponent,
    LeftPanelComponent,
    ManageUsersComponent,
    ManageClientsComponent,
    AddEditClientsComponent,
    UserComponent,
    MatchPasswordDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false,
      defaultLanguage: 'en'
    }),
    BBAdminSharedModule.forRoot(),
    BenefitBuilderSharedModule.forRoot(),
    MobilifySharedModule.forRoot(),
    DashBoardSharedModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatMenuModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatDialogModule,
    NgxSpinnerModule
  ],
  providers: [
    { provide: MatDialogRef, useValue: {} },
    { provide: MAT_DIALOG_DATA, useValue: [] },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
