<h2>
  {{ paginator.length }} Client Contacts
</h2>
<div class="emp-details mat-elevation-z8" role="group">
  <div class="table-scroll" role="table">
    <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">

      <!-- fullName Column -->
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Full Name"
          aria-label="full name" (click)="applySort('fullName')"> Full Name </th>
        <td mat-cell *matCellDef="let element">
          <div *ngIf="element.nameDetails" role="cell">
            <span role="cell">
              <span [innerHTML]="element.nameDetails.lastName | searchHighlight: searchKeyword"></span>, 
              <span [innerHTML]="element.nameDetails.firstName | searchHighlight: searchKeyword"></span>
              </span>
          </div>
        </td>
      </ng-container>

      <!-- Email Column -->
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef matTooltip="Change sorting for Email" aria-label="email">
          Email </th>
        <td mat-cell *matCellDef="let element">
          <span role="cell" *ngIf="element.emailAddress">
              <a href="mailto:{{element.emailAddress}}">
                <span [innerHTML]="element.emailAddress | searchHighlight: searchKeyword"></span></a>
          </span>
        </td>
      </ng-container>

      <!-- Mobile Column -->
      <ng-container matColumnDef="mobilePhone">
        <th mat-header-cell *matHeaderCellDef aria-label="mobile"> Mobile
          Phone # </th>
        <td mat-cell *matCellDef="let element">
          <span role="cell" *ngIf="element.phoneDialCode && element.phoneNumber">+{{element.phoneDialCode}} {{element.phoneNumber}}</span>
        </td>
      </ng-container>

      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef aria-label="status">Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.status }}
          <div *ngIf="element.statusDate" style="font-size: 12px;" role="cell">on {{element.statusDate}}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div fxLayout="row" class="table-footer" fxLayoutAlign="end center" fxLayoutAlign.xs="start start">
    <div fxFlex="66%" fxLayoutAlign="start center">
      <!-- <app-download-menu-button tabindex="0" [reportName]="employeeReport"></app-download-menu-button> -->

    </div>
    <!-- <div fxFlex="64%"></div> -->
    <div fxFlex="34%" fxFlex.xs="100%">
      <!-- (page)="onPageChange($event)" -->
      <mat-paginator [pageSizeOptions]="[25, 50, 75]"></mat-paginator>
    </div>
  </div>
</div>