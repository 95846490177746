<div class="clients">
    <h2>Select Client</h2>
    <mat-form-field class="table-search" appearance="outline">
        <button mat-icon-button>
            <mat-icon (click)="applySearch(searchValue)" aria-label="search">search</mat-icon>
        </button>
        <input matInput [(ngModel)]="searchValue" (keyup.enter)="applySearch($event.target.value)" placeholder="Search by Company Name or Client #" (blur)="toggleError()">
        <button mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchTextResults()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-error id="min-text-error" role="main" aria-label="Please enter at least 3 characters" *ngIf="showError"> Please enter at least 3 characters</mat-error> 
    <div class="emp-details mat-elevation-z8">
        <div class="table-scroll">
            <table mat-table #table [dataSource]="dataSource" matSort>
                <!-- client name Column -->
                <ng-container matColumnDef="clientName" sticky>
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
                    <td mat-cell *matCellDef="let element"> 
                        <a (click)="selectClient(element)"><span [innerHTML]="element.clientName | highlightSearch: filterText"></span></a>
                    </td>
                </ng-container>
                <!-- client number Column -->
                <ng-container matColumnDef="clientNo">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Client # </th>
                    <td mat-cell *matCellDef="let element"><span [innerHTML]="element.clientNo | highlightSearch: filterText"></span></td>
                </ng-container> 
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <mat-paginator #paginator [pageSizeOptions]="[10, 25, 50]"></mat-paginator>
        </div>
    </div>
</div>
          
      
      
