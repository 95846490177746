import { Injectable, ViewEncapsulation } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Providers } from './../models/providers.model';
import { RecentProvider } from './../models/recent-provider.model';
import { HttpParams } from '@angular/common/http';
import {providerData, providerInvoiceData} from '../data/employeeInfo'
import moment from 'moment';
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';
/** ProviderService to fetch Provider details from Api and to display in the provider table*/
@Injectable({
  providedIn: 'root'
})
export class ProviderService {

/** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(private readonly baseClient: BaseClientService, 
    private readonly logSvc: RemoteLoggingService,
    private moveProDashboadDataService: MoveProDashboadDataService) { }

  /** getProvidersByFileNum() to fetch Provider details from Api as provider[] */
  getProvidersByFileNum(fileNumber: number, curr : string): Observable<Providers> {
    let params;
    if (curr) {
      params = new HttpParams({ fromObject: {
      currencyAbbr: curr ? curr : null
      }});
    }
    // return this.baseClient
    //   .getOne<Providers>(`v1/provider/${fileNumber}`, 'get the provider details', params)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: Providers = null;
    //       this.logSvc.logError(err);
    //       return of(empty);
    //     })
    //   );
    let response = [];
    this.moveProDashboadDataService.providerInvoiceDataSubject.getValue().filter((ele) => {
      if (ele.fileNumber === fileNumber) {
        this.updateDateForProvidersInvoiceData(ele,fileNumber);
        response.push(ele);
      }
    });
    if (!response || response.length == 0) {      
      response = [
        {
          "fileNumber": fileNumber,
          "suppliers": [],
          "count": 0
        }
      ];
    }
    return of(JSON.parse(JSON.stringify(response[0])));
  }
  
  updateProviderData(data, fileNumber) {
    let employeeDatas = this.moveProDashboadDataService.employeeDataSubject.getValue();
    let employee ;
    employeeDatas.data.filter((ele) => {
      if(ele.fileNumber === fileNumber) {
        employee = ele;
      }
    });

    if(employee) {
      data.suppliers.forEach((element, index) => {
        element.apPaymentDocDate = getDatefromPassedDate((index*2)+2,false,employee.effectiveTransferDate);
      });
    }
  }

  updateDateForProvidersInvoiceData(data, fileNumber) {
    let providersData ;
    providerData.filter((ele)=> {
      if (ele.fileNumber === fileNumber) {
        this.updateProviderData(ele,fileNumber);
        providersData = ele;
      }
    });

    (providersData.suppliers as Array<any>).forEach((val, index)=>{
      let invoiceData = data.suppliers[index]? data.suppliers[index].invoices : null;
      if (invoiceData && invoiceData.length>0) {
        invoiceData.forEach(ele => {
          ele.apPaymentDocDate = val.apPaymentDocDate;
          ele.apInvDt = getDatefromPassedDate(30,false,ele.apPaymentDocDate);
          ele.list.forEach((element, elementIndex) => {
            element.incurredFromDt = getDatefromPassedDate((elementIndex * 2 + 1), false, ele.apPaymentDocDate);
            element.incurredToDt = element.incurredFromDt;
            if(element.distributionDescForPaymentEntry.includes(',')) {
              let desc = element.distributionDescForPaymentEntry.split(',');
              let val='';
              let resIndex = -1;
              desc.forEach((descele) => {                
                let currentValue = descele;
                if(descele.includes('Load Dt')) {
                  currentValue = `Load Dt - ${element.incurredToDt}`;
                  resIndex = resIndex+1;

                } else if (descele.includes('Pack Dt')) {
                  currentValue = `Pack Dt - ${ element.incurredFromDt}`;
                }
                if (resIndex <=0 ) {                  
                  if(resIndex == 0) {
                    resIndex = resIndex+1;
                    val = val + currentValue ;
                  } else {
                    val = val + currentValue + ", ";
                  }
                }
              });
              element.distributionDescForPaymentEntry = val;
            }
          });
        });
      }
    });
  }

  /** getProvidersByFileNum() to fetch Provider details from Api as provider[] */
  getRecentProvidersByFileNum(fileNumber: number, curr : string): Observable<RecentProvider> {
    let params;
    if(curr) {
      params = new HttpParams({ fromObject: {
      currencyAbbr: curr ? curr : null
      }});
    }
    // return this.baseClient
    //   .getOne<RecentProvider>(`v1/provider-invoices/${fileNumber}`, 'get the recent provider details', params)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: RecentProvider = null;
    //       this.logSvc.logError(err);
    //       return of(empty);
    //     })
    //   );
    let response = [];
    this.moveProDashboadDataService.providerDataSubject.getValue().filter((ele) => {
      if (ele.fileNumber === fileNumber) {
        this.updateProviderData(ele,fileNumber);
        response.push(ele);
      }
    });
    if (!response || response.length == 0) {      
      response = [
        {
          "fileNumber": fileNumber,
          "suppliers": [],
          "count": 0
        }
      ];
    }
    return of(JSON.parse(JSON.stringify(response[0])));
  }
}
export const getDatefromPassedDate = (numberOfdays, isFuture, passedDate) => {
  let currentDate: any = new Date(passedDate);
  if (isFuture) {
    return moment(currentDate.setDate(currentDate.getDate() + numberOfdays)).format('YYYY-MM-DD');
    //logic to add number of days for current date;
  } else {
    return moment(currentDate.setDate(currentDate.getDate() - numberOfdays)).format('YYYY-MM-DD')//logic to reduce number of days for currentdate
  }
}