<span style="max-height: 25px">
  <a routerLink="/home">
  <img src="../../../assets/landingpage_assets/CARTUS_Logo_BLUE.svg" style="padding-left:20px;height: 43px;">
  </a>
</span>

<span class="client-name" aria-hidden="true">Logged in as {{loggedInUser | titlecase}}</span>
<div class="settings menu" fxLayout="row" *ngIf = "loggedInUserType != 'user'">
  <mat-icon [matMenuTriggerFor]="settingmenu" class="settings-btn" aria-label="settings icon">settings</mat-icon>
  <mat-menu #settingmenu="matMenu" class="mat-menu">
    <button mat-menu-item class="menu-items" (click)="onClickUsers()">
      <mat-icon>group</mat-icon>
      <span>Users</span>
    </button>
    <button mat-menu-item  class="menu-items" (click)="onClickClients()">
      <mat-icon>groups</mat-icon>
      <span>Clients</span>
    </button>
  </mat-menu>
</div>
<button mat-button class="logout-btn" (click)="logout()">Logout</button>
<div class="Tcontainer">
  <mat-icon style="opacity: 0;">arrow_forward</mat-icon>
  <div
    [ngClass]="{'Tmain2_detailTile':detailCard_bb || detailCard_mobilify || detailCard_move || detailCard_admin == true,'Tmain2' :  detailCard_bb || detailCard_mobilify || detailCard_move || detailCard_admin == false}">
    <div *ngIf="detailCard_bb || detailCard_mobilify || detailCard_move || detailCard_admin">
      <span>
        <h1 style="text-align: center; color:white;margin-left: 70px;">TECHNOLOGY THAT MOVES YOU<sup class="home-header-text">SM</sup></h1>
      </span>
      <mat-card *ngIf="detailCard_landing" class="detail_tile-1" (click)="detailCard_move = true;detailCard_landing = false; onClick(2)">
        <mat-card-title class="head-tile">
          <img mat-card-image src="../../../assets/imgs/MovePro360-Color.svg" class="cardIcon-1">
        </mat-card-title>
        <mat-card-subtitle class="admin-mat-subtile-01" style="margin-top: -8px !important;">MovePro360<sup>®</sup></mat-card-subtitle>
        <mat-card-content class="last-child"></mat-card-content>
      </mat-card>
      <mat-card  *ngIf ="!detailCard_landing" class="detail_tile" [class.detail_tile-cartus] = "detailDashBoard.appId === 1">
        <mat-card-title-group>
          <mat-card-title>
           {{detailDashBoard.FullName}}<sup *ngIf="detailDashBoard.appId == 1 || detailDashBoard.appId == 2" >®</sup>
          </mat-card-title>
        </mat-card-title-group>
        <mat-card-content class="last-child" style="width:480px;">
          {{detailDashBoard.description}}
        </mat-card-content>
        <mat-card-actions>
          <span fxLayoutAlign="end center" *ngIf="detailDashBoard.appId !==1">
            <a (click)="onClickDetail()">
              <mat-icon>arrow_forward</mat-icon>
            </a>
          </span>
          <div  *ngIf="detailDashBoard.appId === 1" style="place-content: center flex-end;align-items: center;box-sizing: border-box;position: relative;margin-top: 2px;">
            <a href="../../../assets/csOnline/CartusOnline/login.html" class="cartus-action" target="_blank" style=" margin-left: 25px;" >CartusOnline<sup>®</sup>  Client
              <mat-icon class="cartus-arrow-icon" style="margin-left: 16px;">arrow_forward</mat-icon>
            </a>
            <a href="../../../assets/csOnline/MobilityTeamHome/mobility_team_home.html" class="cartus-action" target="_blank" style="
            margin-left: 17px;
        "> CartusMobile<sup>®</sup> Client 
              <mat-icon class="cartus-arrow-icon" style="margin-left: 39px;">arrow_forward</mat-icon>
            </a>
          </div>
          <div  *ngIf="detailDashBoard.appId ===1" style="place-content: center flex-end;align-items: center;box-sizing: border-box;margin-left: 22px;margin-top: 5px;">
            <a  class="cartus-action" href="../../../assets/csOnline/DomesticEmployee/pages/introLetter.html" target="_blank" style="
          margin-left: 3px;
      ">US Domestic Employee <mat-icon class="cartus-arrow-icon">arrow_forward</mat-icon></a><a  class="cartus-action" href="../../../assets/csOnline/InternationalEmployee/pages/introLetter.html" target="_blank" style="
          margin-left: 19px;
      "> International Employee <mat-icon class="cartus-arrow-icon" style="margin-left: 24px;">arrow_forward</mat-icon></a>
          </div>
          <div  *ngIf="detailDashBoard.appId ===1" style="place-content: center flex-end;align-items: center;box-sizing: border-box;margin-left: 22px;margin-top: 5px;">
           <a  class="cartus-action" href="../../../assets/csOnline/SmartWallet/pages/welcomePage-01.html" target="_blank" style="
          margin-left: 4px;
      "> Smart Wallet <mat-icon class="cartus-arrow-icon" style="margin-left: 76px;">arrow_forward</mat-icon></a><a  class="cartus-action" href="../../../assets/csOnline/RelocatingEmployee-mobile/RelocatingEmployee-home.html" target="_blank" style="
          margin-left: 18px;
      "> CartusMobile<sup>®</sup> Employee  <mat-icon class="cartus-arrow-icon" style="margin-left: 9px;">arrow_forward</mat-icon></a>
          </div>
        </mat-card-actions>
      </mat-card>

    </div>
    <mat-card *ngIf="!detailCard_bb" (click)="detailCard_bb = true; onClick(0)">
      <mat-card-title>
        <img mat-card-image src="../../../assets/landingpage_assets/MicrosoftTeams-image (2).png" class="cardIcon" style="margin-top: -6px!important;">
      </mat-card-title>
      <mat-card-subtitle style="margin-top: 30px;"> Benefits
        <br>Builder</mat-card-subtitle>
    </mat-card>

    <mat-card *ngIf="!detailCard_mobilify" (click)="detailCard_mobilify = true; onClick(1)">
      <mat-card-title class="cartus-online-mat-title">
        <img mat-card-image src="../../../assets/landingpage_assets/cartusonline_logo.svg" class="cardIcon">
      </mat-card-title>
      <mat-card-subtitle class="cartus-online-mat-sub-title">CartusOnline<sup>®</sup> </mat-card-subtitle>
      <mat-card-content class="last-child"></mat-card-content>
    </mat-card>

    <mat-card *ngIf="!detailCard_move" (click)="detailCard_move = true; onClick(2)">
      <mat-card-title>
        <img mat-card-image src="../../../assets/imgs/MovePro360-Color.svg" class="cardIcon">
      </mat-card-title>
      <mat-card-subtitle class="admin-mat-subtile-01">MovePro360<sup>®</sup></mat-card-subtitle>
      <mat-card-content class="last-child"></mat-card-content>
    </mat-card>

    <mat-card *ngIf="!detailCard_admin" (click)="detailCard_admin = true; onClick(3)">
      <mat-card-title>
        <img mat-card-image src="../../../assets/imgs/MovePro360-Color.svg" class="cardIcon">
      </mat-card-title>
      <mat-card-subtitle class="admin-mat-subtile-02">MovePro360 Admin Page </mat-card-subtitle>
      <mat-card-content class="last-child"></mat-card-content>
    </mat-card>

   
  </div>
</div>