export const cc_divisions = {
  "cc_divisions": [
    {
      "cc_division_id": "5df5195deb1bc8a9f68c6a99",
      "cc_division_name": "Government Division",
      "cc_division_number": "035",
      "currency": [
        {
          "DivCurrency": "US Dollar"
        }
      ]
    },
    {
      "cc_division_id": "5df5195aeb1bc8ced68c6a97",
      "cc_division_name": "International Division",
      "cc_division_number": "034",
      "currency": [
        {
          "DivCurrency": "US Dollar"
        }
      ]
    },
    {
      "cc_division_id": "5df51959eb1bc893788c6a96",
      "cc_division_name": "People & Culture",
      "cc_division_number": "032",
      "currency": [
        {
          "DivCurrency": "US Dollar"
        }
      ]
    },
    {
      "cc_division_id": "5df51958eb1bc86aa78c6a95",
      "cc_division_name": "Research and Development",
      "cc_division_number": "033",
      "currency": [
        {
          "DivCurrency": "US Dollar"
        }
      ]
    },
    {
      "cc_division_id": "5df5195ceb1bc8c70f8c6a98",
      "cc_division_name": "US Domestic Division",
      "cc_division_number": "036",
      "currency": [
        {
          "DivCurrency": "US Dollar"
        }
      ]
    }
  ],
  "count": 5
}

export const cc_divisionPrograms = {
  "cc_divisionPrograms": [
    {
      "programName": "Executive Homeowner Program",
      "points": 55,
      "programId": "5f992f153da5390020dcecd0",
      "coreOfferings": 11,
      "contractId": 345
    },
    {
      "programName": "Executive Renter Program",
      "points": 45,
      "programId": "5f030ab5c18bc800207f676d",
      "coreOfferings": 8,
      "contractId": 123
    },
    {
      "programName": "Expat - Long Term Program",
      "points": 75,
      "programId": "5f0ef0a983bc6d002057b960",
      "coreOfferings": 9,
      "contractId": 587
    },
    {
      "programName": "High Value US Inbound Program",
      "points": 25,
      "programId": "5f4dec62beb10400207e2db1",
      "coreOfferings": 15,
      "contractId": 234
    },
    {
      "programName": "One Way Program",
      "points": 76,
      "programId": "5f8589cccaf2820020204660",
      "coreOfferings": 10,
      "contractId": 234
    },
    {
      "programName": "US Inbound Program",
      "points": 22,
      "programId": "602d035452b0f60020081431",
      "coreOfferings": 32,
      "contractId": 234
    },
    {
      "programName": "US Outbound Program",
      "points": 25,
      "programId": "602d035452b0f60020081431",
      "coreOfferings": 32,
      "contractId": 234
    }
  ],
  "count": 6
}