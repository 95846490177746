import { Component, OnInit, Inject, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { LocationsService } from './../../../../services/locations.service';
import { FamilyMember } from './../../../../../../core/models/family-member.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatRadioChange, MatSelectChange } from '@angular/material';
import { phoneDetails } from './../../../../../../core/models/phone.model';
import { Country } from './../../../../../../core/models/country.model';
import { emailDetails } from './../../../../../../core/models/email.model';
import { FamilyInfoService,phoneTypes, emailTypes } from './../../../../services/family-info.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

export const failureMsg = 'We are unable to process your request at this time. Please try again later.';
export interface ContactFormValue {
  phoneDetails: Array < {
    primary: boolean,
    type: string,
    phoneDialCode: number,
    phoneNumber: number
  } > ;
  emailDetails: Array < {
    primary: boolean,
    emailAddress: string,
    usageType: string
  } > ;
}

@Component({
  selector: 'app-add-family-info-dialog',
  templateUrl: './add-family-info-dialog.component.html',
  styleUrls: ['./add-family-info-dialog.component.scss']
})

export class AddFamilyInfoDialogComponent implements OnInit {
  /** List of titles to be populated */
  titleValues = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Miss'];
  /** List of relationships to be populated */
  relationshipValues = ['Civil Partner', 'Fiancé', 'Partner', 'Significant Other', 'Spouse', 'Child', 'Dependent',
    'Grandparent', 'Parent', 'Sibling'
  ];
  /** List of phone Types for selection */
  phoneTypes: Array < Array < string >> = [
    ['Mobile', 'Departure Business', 'Departure Residence',
      'Destination Business', 'Destination Residence'
    ]
  ];
  /** List of email Types for selection */
  emailTypes: Array < Array < string >> = [
    ['personal', 'business']
  ];
  /** Add member information form group */
  addMemberInfo: FormGroup;
  /** Stores current family member details */
  familyMemberDetails: FamilyMember = {} as FamilyMember;
  /** Stores list of countries and respective phone dial code */
  countryList: Array < Country > ;
  /** Button text value */
  addeditButtonTxt: string;
  /** Stores updated family member details */
  updatedFamilyMemberDet: FamilyMember = {} as FamilyMember;
  /** Flag to indicate new form or edit form */
  isNewForm: boolean;
  /** object to store list of deleted phone details */
  deletedPhoneDetailsArray: Array < phoneDetails > = [];
  /** object to store list of deleted phone details */
  deletedemailDetailsArray: Array < emailDetails > = [];

  /**
   *
   * @param fb Inject form builder
   * @param dialogRef Inject MatDialogRef Object
   * @param locationService Inject Location Service
   * @param data Existing member data to be updated
   * @param familyInfoSvc Family service
   * @param injector Inject Injector
   * @param spinner  Inject spinner
   * @param toastrService Inject notification Service
   */
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef < FamilyMember > ,
    private readonly locationService: LocationsService,
    @Inject(MAT_DIALOG_DATA) public data: FamilyMember,
    private familyInfoSvc: FamilyInfoService,
    private injector: Injector,
    private readonly spinner: NgxSpinnerService,
    private readonly toastrService: ToastrService
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    // to load phone code
    this.locationService.countryList.subscribe(countryList => {
      if (countryList.length > 0) {
        this.countryList = countryList;
      }

    });
    if (this.data) {
      this.familyMemberDetails = JSON.parse(JSON.stringify(this.data));
      this.updatedFamilyMemberDet = this.familyMemberDetails;
      this.addeditButtonTxt = 'Save Changes';
      this.isNewForm = false;
    } else {
      this.addeditButtonTxt = 'Add Member';
      this.isNewForm = true;
    }

    this.addMemberInfo = this.populateForm(this.familyMemberDetails);
    const contactDetails = this.familyInfoSvc.initPhoneEmailList((this.addMemberInfo.controls.contact as FormGroup).value,
    this.phoneTypes, this.emailTypes);
    this.emailTypes = contactDetails ? contactDetails.emailTypes : null;
    this.phoneTypes = contactDetails ? contactDetails.phoneTypes : null;

  }

  /**
   * To initialize formgroup and populate existing family member details
   * @param editData: existing family member Data
   */
  populateForm(editData: FamilyMember): FormGroup {
    let initialForm: FormGroup;
    const isNull = this.familyInfoSvc.isNullOrUndefinedSafeCheck;
    initialForm = this.fb.group({
      nameDetails: this.fb.group({
        title: [editData ? isNull(editData.nameDetails, 'title') : '', Validators.required],
        firstName: [editData ? isNull(editData.nameDetails, 'firstName') : '', [Validators.required,
          Validators.minLength(1), Validators.maxLength(50)
        ]],
        lastName: [editData ? isNull(editData.nameDetails, 'lastName') : '', [Validators.required,
          Validators.minLength(2), Validators.maxLength(50)
        ]]
      }),
      relationship: [editData ? isNull(editData, 'relationshipType') : '', Validators.required],
      contact: this.fb.group({
        phoneDetails: (editData && editData.phoneDetailsList && editData.phoneDetailsList.length > 0) ?
          this.fb.array(this._generatePhoneGroup(editData.phoneDetailsList)) : this.fb.array([]),
        emailDetails: (editData && editData.emailDetailsList && editData.emailDetailsList.length > 0) ?
          this.fb.array(this._generateEmailGroup(editData.emailDetailsList)) : this.fb.array([])
      })
    });
    return initialForm;
  }

  /**
   * Generates phone array for Contact Form
   * @param phoneDetails phonedetails data
   */
  private _generatePhoneGroup(phoneDetails: Array < phoneDetails > ): Array < FormGroup > {
    const fb = this.injector.get(FormBuilder);
    const isNull = this.familyInfoSvc.isNullOrUndefinedSafeCheck;
    if (phoneDetails) {

      phoneDetails = [...phoneDetails || []];
      phoneDetails.forEach((phoneDetail, index) => {
        let phoneType = null;
        phoneDetail.textingAvailable ? phoneType = 'Mobile' : null;

        if (!phoneDetail.textingAvailable) {
          (phoneDetail.usageType === 'business' && phoneDetail.locationType === 'departure') ? phoneType = 'Departure Business' : null;
          (phoneDetail.usageType === 'business' && phoneDetail.locationType === 'destination') ? phoneType = 'Destination Business' : null;
          (phoneDetail.usageType === 'personal' && phoneDetail.locationType === 'departure') ? phoneType = 'Departure Residence' : null;
          (phoneDetail.usageType === 'personal' && phoneDetail.locationType === 'destination') ? phoneType = 'Destination Residence' : null;
        }

        delete phoneDetail.usageType;

        // Extra check to ensure there is only one occurence of type even if backend send same.
        if (index !== 0) {
          (typeof phoneDetails.find(phone => phone.type === phoneType) === 'undefined') ? phoneDetail.type = phoneType : null;
        } else {
          phoneDetail.type = phoneType;
        }
      });

      const phoneDetailsArray: Array < FormGroup > = phoneDetails.map(phoneDetail => {
        return fb.group({
          _id: [isNull(phoneDetail, '_id')],
          primary: [isNull(phoneDetail, 'primary')],
          type: [{
              value: isNull(phoneDetail, 'type'),
              disabled: true
            },
            [Validators.required]
          ],
          phoneDialCode: [isNull(phoneDetail, 'phoneDialCode'), [Validators.required]],
          phoneNumber: [isNull(phoneDetail, 'phoneNumber'), [Validators.required, Validators.minLength(7),
            Validators.maxLength(18), Validators.min(1000000), Validators.max(999999999999999999),
            Validators.pattern('^[0-9]*$')
          ]]
        });
      });
      return phoneDetailsArray;
    } else {
      const phoneDetailsArray = new Array(fb.group({
        _id: [''],
        primary: [''],
        type: ['', []],
        phoneDialCode: ['', []],
        phoneNumber: ['', [Validators.minLength(7), Validators.maxLength(18),
          Validators.min(1000000), Validators.max(999999999999999999), Validators.pattern('^[0-9]*$')
        ]]
      }));
      return phoneDetailsArray;
    }
  }

  /**
   * Generates email array for contact details form
   * @param emailDetails email list
   */
  private _generateEmailGroup(emailDetails: Array < emailDetails > ): Array < FormGroup > {
    const fb = this.injector.get(FormBuilder);
    const isNull = this.familyInfoSvc.isNullOrUndefinedSafeCheck;
    if (emailDetails) {
      const emailDetailsArray = emailDetails.map(emailDetail => {
        return fb.group({
          _id: [isNull(emailDetail, '_id')],
          primary: [isNull(emailDetail, 'primary')],
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          emailAddress: [isNull(emailDetail, 'emailAddress'), [Validators.required,
            Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-]*\\.[a-zA-Z]{2,3}$')
          ]],
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          usageType: [isNull(emailDetail, 'usageType'), [Validators.required]],
        });
      });
      // Disabled if type is business...
      emailDetailsArray.map(email => {
        if (email.getRawValue().usageType) {
          email.controls.usageType.disable();
        }
      });

      return emailDetailsArray;
    }
  }

  /**
   * Add row to Mobile and Email
   * @param type location a new row to be added
   * @param newIndex index of the location
   */
  addNewRow(type: 'phone' | 'email', newIndex: number, contactForm: FormGroup) {
    this.addNewPhoneEmailRow(type, contactForm, newIndex, this.phoneTypes, this.emailTypes);
  }

  /**
   * Remove row from Mobile or Email
   * @param type location of the row
   * @param index index of the location
   * @param contactForm contact form group
   */
  deleteRow(type: 'phone' | 'email', index: number, contactForm: FormGroup, contactId, isPrimary) {

    if (type === 'phone') {
      this.phoneTypes = this.familyInfoSvc.deletePhoneEmailRow(type, index, contactForm, this.phoneTypes, this.emailTypes);
      if (contactId) {
        const deletedPhone = this.familyMemberDetails.phoneDetailsList.filter(phone => {
          if (phone._id === contactId) {
            phone.delete = true;
            if (phone.primary === true) {}
            return phone;
          }
        });
        this.deletedPhoneDetailsArray.push(deletedPhone[0]);
      }
      if (isPrimary) {
        (contactForm.controls.phoneDetails as FormArray).controls[0] ? ((contactForm.controls.phoneDetails as FormArray).controls[0] as FormGroup).get('primary').patchValue(true) : null;
      }
    } else {
      this.emailTypes = this.familyInfoSvc.deletePhoneEmailRow(type, index, contactForm, this.phoneTypes, this.emailTypes);
      if (contactId) {
        const deletedEmail = this.familyMemberDetails.emailDetailsList.filter(email => {
          if (email._id === contactId) {
            email.delete = true;
            return email;
          }
        });
        this.deletedemailDetailsArray.push(deletedEmail[0]);
      }
      if (isPrimary) {
        (contactForm.controls.emailDetails as FormArray).controls[0] ? ((contactForm.controls.emailDetails as FormArray).controls[0] as FormGroup).get('primary').patchValue(true) : null;
      }
    }
  }

  /**
   * To reset primary phone
   * @param event Event Object
   * @param index Index of array
   */
  changePrimaryPhone(event: MatRadioChange, index: number) {
    const updatedPhoneGroup = (((this.addMemberInfo.controls.contact as FormGroup).controls.phoneDetails as FormArray).getRawValue() as Array < phoneDetails > ).map((v, i) => {
      return {
        primary: (i === index) ? true : false
      };
    });
    ((this.addMemberInfo.controls.contact as FormGroup).controls.phoneDetails as FormArray).patchValue(updatedPhoneGroup);
  }
  /**
   * To reset primary email
   * @param event Event Object
   * @param index Index of array
   */
  changePrimaryEmail(event: MatRadioChange, index: number) {
    const updatedEmailGroup = (((this.addMemberInfo.controls.contact as FormGroup).controls.emailDetails as FormArray).getRawValue() as Array < emailDetails > ).map((v, i) => {
      return {
        primary: (i === index) ? true : false
      };
    });
    ((this.addMemberInfo.controls.contact as FormGroup).controls.emailDetails as FormArray).patchValue(updatedEmailGroup);
  }


  /**
   * Disables the phone on dropdown select.
   * @param index index of phone change
   */
  selectPhoneType(event: MatSelectChange, index: number) {
    (((this.addMemberInfo.controls.contact as FormGroup).controls.phoneDetails as FormArray).controls[index] as FormGroup).controls['type'].disable({
      onlySelf: true
    });
  }

  selectEmailType(event: MatSelectChange, index: number) {
    (((this.addMemberInfo.controls.contact as FormGroup).controls.emailDetails as FormArray).controls[index] as FormGroup).controls['usageType'].disable({
      onlySelf: true
    });
  }

  /**
   * To reset the form and close the dialog
   * @param evt : Pass event object
   */
  closeDialog(evt) {
    evt.preventDefault();
    this.addMemberInfo.reset();
    this.updatedFamilyMemberDet = null;
    this.familyMemberDetails = null;
    this.dialogRef.close({
      'event': 'close'
    });
  }
  /**
   * To populate family information data from form to object
   */
  popuplateFamilyInfoValues() {
    this.spinner.show();
    this.updatedFamilyMemberDet.nameDetails = this.addMemberInfo.getRawValue().nameDetails;
    this.updatedFamilyMemberDet.relationshipType = this.addMemberInfo.getRawValue().relationship;
    let phoneDetailsValue = this.addMemberInfo.getRawValue().contact.phoneDetails;
    if (this.deletedPhoneDetailsArray.length > 0) {
      phoneDetailsValue = phoneDetailsValue.concat(this.deletedPhoneDetailsArray);
    }
    const emailDetailsValue = this.addMemberInfo.getRawValue().contact.emailDetails;
    this.deletedemailDetailsArray.length > 0 ? emailDetailsValue.concat(this.deletedemailDetailsArray) : null;
    if (phoneDetailsValue.length > 0) {
      const phoneDetailsList = this.familyInfoSvc.updatePhoneType(phoneDetailsValue);

      this.updatedFamilyMemberDet = Object.assign(this.updatedFamilyMemberDet, {
        'phoneDetailsList': phoneDetailsList
      });
    }

    if (emailDetailsValue.length > 0) {

      const emailDetailsList = this.addMemberInfo.getRawValue().contact.emailDetails;
      this.updatedFamilyMemberDet = Object.assign(this.updatedFamilyMemberDet, {
        'emailDetailsList': emailDetailsList
      });
    }

    // Remove Date if birth from JSON
    delete this.updatedFamilyMemberDet['dateOfBirth'];
    if (this.isNewForm) {
      this.addFamilyInformation(this.updatedFamilyMemberDet);
    } else {
      this.updateFamilyInformation(this.updatedFamilyMemberDet);
    }
  }
  /**
   * To add new family information data
   * @param newInformation new family member information
   */
  addFamilyInformation(newInformation) {
    this.familyInfoSvc.adddFamilyMemberDetails(newInformation)
      .subscribe(status => {
          if (status) {
            this.spinner.hide();
            this.dialogRef.close({
              event: 'add',
              data: status
            });
          } else {
            this.toastrService.error(failureMsg, null, {
              closeButton: true,
              enableHtml: true,
              disableTimeOut: false // User must explicitly dismiss error messages
            });
            this.spinner.hide();
          }
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        });
  }
  /**
   * To add new family information data
   * @param updatedInformation updated family member information
   */
  updateFamilyInformation(updatedInformation) {
    this.familyInfoSvc.updateFamilyMemberDetails(updatedInformation.id, updatedInformation)
      .subscribe(status => {
          if (status) {
            this.spinner.hide();
            this.dialogRef.close({
              event: 'update',
              data: status
            });
          } else {
            this.toastrService.error(failureMsg, null, {
              closeButton: true,
              enableHtml: true,
              disableTimeOut: false // User must explicitly dismiss error messages
            });
          }
          this.spinner.hide();
        },
        err => {
          this.spinner.hide();
        });
  }

  /**
   * Add row to Mobile and Email
   * @param type location a new row to be added
   * @param contactForm Contact Form
   * @param newIndex index of the location
   * @param phoneTypesList Phone List
   * @param emailTypesList Email List
   */
  addNewPhoneEmailRow(type: 'phone' | 'email', contactForm: FormGroup, newIndex: number, phoneTypesList: any, emailTypesList: any) {
    const fb = this.injector.get(FormBuilder);
    if (type == 'phone') {

      // need to find used phone types and filter from existing.
      const existingPhoneTypes: Array < string > = ((contactForm.controls.phoneDetails as FormArray).getRawValue() as any).map(phone => phone.type);

      // updating phoneTypes
      phoneTypesList[newIndex] = [...phoneTypes || []];
      phoneTypesList[newIndex] = phoneTypesList[newIndex].filter(phone => !existingPhoneTypes.includes(phone));

      (contactForm.controls.phoneDetails as FormArray).push(fb.group({
        primary: (newIndex === 0) ? true : false,
        type: [null, [Validators.required]],
        phoneDialCode: [null, [Validators.required]],
        phoneNumber: [null, [Validators.required, Validators.minLength(7), Validators.maxLength(18),
          Validators.min(1000000), Validators.max(999999999999999999), Validators.pattern('^[0-9]*$')
        ]]
      }));
    } else {

      // need to find used phone types and filter from existing.
      const existingEmailTypes: Array < string > = ((contactForm.controls.emailDetails as FormArray).getRawValue() as any).map(email => email.usageType);

      // updating emailTypes
      emailTypesList[newIndex] = [...emailTypes];
      emailTypesList[newIndex] = emailTypesList[newIndex].filter(email => !existingEmailTypes.includes(email));

      (contactForm.controls.emailDetails as FormArray).push(fb.group({
        primary: (newIndex === 0) ? true : false,
        emailAddress: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-]*\\.[a-zA-Z]{2,3}$')]],
        usageType: [null, [Validators.required]],
      }));
    }
  }
}
