import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { PdfFontsService } from './pdf-fonts.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class BookingDetailsPdfGenerationService {
  borderColor = '#AAAAAA';
  labelColor = '#19305A';
  labelFont = 8;
  labelLineHeight = 0.75;
  fieldValueColor = '#22222';
  fieldValueFont = 11;
  sectionHeaderFont = 10;
  lineHeight = 1.5;
  workOrderBorderColor = '#bde1ff';

  constructor(
    private readonly pdfMakeFonts: PdfFontsService,
  ) {
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
    this.pdfMakeFonts.loadLogos();
    }

    generateDocumentDefinition(downloadData) {
      const documentDefinition = {
        info: { title: 'Program Details', subject: 'Details of programs listed' },
        pageOrientation: 'portrait',
        pageSize: 'LETTER',
        pageMargins: [40, 110, 40, 110],
        header: (currentPage) => {
          return {
            table: {
              headerRows: 1,
              widths: ['50%', '50%'],
              body: [
                [{
                  image: this.pdfMakeFonts.bbLogob64, width: 120, height: 40, alignment: 'left',
                  color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [30, 35, 0, 0],
                },
                {
                  image: this.pdfMakeFonts.cartusLogob64, width: 120, height: 40, alignment: 'right',
                  color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [0, 35, 20, 0],
                }
                ]
              ]
            },
          };
        },
        footer: (currentPage, pageCount) => {
          const year = new Date().getFullYear();
          return {
            table: {
              headerRows: 1, widths: ['60%', '40%'],
              body: [
                [{
                  text: `© ${year} Cartus Corporation | All rights reserved. 
               Cartus and the Cartus logo are registered trademarks of Cartus Corporation
               `, fontSize: 9, color: '#575A5D', alignment: 'left', border: [false, false, false, false],
                  margin: [30, 30, 0, 0]
                },
                {
                  text: `Page ${currentPage.toString()}`, fontSize: 9, color: '#575A5D',
                  alignment: 'right', border: [false, false, false, false], margin: [0, 30, 30, 0]
                }
                ]
              ]
            },
          };
        },
        styles: {
          avenirBook: {
            font: 'AvenirBook',
            normal: true
          },
          avenirLight: {
            font: 'AvenirLight',
            fontSize: 18,
            normal: true
          },
          avenirMedium: {
            font: 'AvenirMedium',
            normal: true
          },
          programTable: {
            border: [true, true, true, true], borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#00ffff'],
            fillColor: '#F7F7F7'
          },
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5]
          },
          tableExample: {
            margin: [5, 5, 15, 15]
          },
          tableHeader: {
            bold: true,
            fontSize: 13,
            color: 'black'
          }
        }
      };
      const docDefinition = JSON.parse(JSON.stringify(documentDefinition));
      docDefinition.footer = documentDefinition.footer;
      docDefinition.header = documentDefinition.header;
      const content = [
        this.generateTransfereeSection(downloadData.clientDetails, downloadData.transfereeDetails), this.cardSpacing(),
        this.workOrderDetails(downloadData), this.cardSpacing(),
        this.propertyDetails(downloadData), this.cardSpacing(),
        this.moveDateDetails(downloadData)
      ];
      docDefinition.content = content;
      if (['open', 'in-progress', 'paused'].includes(downloadData.status)) {
        docDefinition.watermark = { text: 'DRAFT', opacity: 0.1, fontSize: 175, angle: 325};
      }
      return docDefinition;
    }

    generateTransfereeSection(clientInfo, transfereeInfo) {
      let clientName: string;
      let orderRefNum: any;
      let fullName: string;
      let clientId: any;
  
      if (clientInfo && transfereeInfo) {
        clientName = (clientInfo.hasOwnProperty('clientEntityName')) ? clientInfo.clientEntityName : 'NA';
        orderRefNum = transfereeInfo.hasOwnProperty('orderRefNum') ? transfereeInfo.orderRefNum: 'NA';
        fullName = transfereeInfo.nameDetails.firstName + ' ' + transfereeInfo.nameDetails.lastName;
        clientId = (clientInfo.hasOwnProperty('clientNumber')) ? clientInfo.clientNumber : 'NA';
      }
      //transferee.orderRequestId
  
      
      const currentDate = new Date();
      const generatedDate= formatDate(currentDate, 'MMMM dd, yyyy', 'en-US');
  
      const transfereeDetails = {
        table: {
          headerRows: 0, widths: ['30%', '37%', '33%'], style: 'programTable',
          body: [
            // Transferee Details
            [
              // Transferee Name
              {
                text: `Robert Angel`, alignment: 'left', color: '#000000', lineHeight: 1, margin: [0, 3, 0, 0],
                border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
              },
              // Client Number
              {
                text: `Client: Demo Client`, alignment: 'left', color: '#575A5D', lineHeight: 1,
                border: [false, false, false, false], fontSize: 9, margin: [0, 3, 0, 0], style: 'avenirBook'
              },
              // Generated Date
              {
                text: `Generated: ${generatedDate}`, alignment: 'right', color: this.labelColor, lineHeight: 1,
                border: [false, false, false, false], fontSize: 9, margin: [0, 3, 0, 0], style: 'avenirBook',
              }
            ],
            [
              // Order#
              {
                text: `MOV-r493db5r`, alignment: 'left', color: this.labelColor, lineHeight: 1, margin: [0, 2, 0, 5],
                border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
              },
              // Client ID
              {
                text: `ID#: 4708`, alignment: 'left', color: '#575A5D', lineHeight: 1,
                border: [false, false, false, false], fontSize: 9, margin: [0, 2, 0, 5], style: 'avenirBook'
              },
              {
                text: ` `, alignment: 'center', color: '#19305A', lineHeight: 1,
                fontSize: 9, border: [false, false, false, false], margin: [0, 2, 0, 5], style: 'avenirBook'
              }
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) { return 0.75; },
          vLineWidth: function (i, node) { return 0.75; }
        }
      };
      return transfereeDetails;
    }

    workOrderDetails(data) {
      let bookingPartyId = data.bookingDetails.supplierID ? data.bookingDetails.supplierID  : ` `;
      let bookingPartyName = ` `;
      data.bookingAgent && data.bookingAgent.forEach(ele => {
        if (ele) {
          bookingPartyName = ele.partyName ? ele.partyName : ` `;
        }
      });
      const bookingAgent = data.bookingDetails.bookingAgent ? data.bookingDetails.bookingAgent : ``
      //const status = data.submittedDate ? 'Submitted' : 'Draft';
      const date = data.submittedDate ? `on ${formatDate(data.submittedDate, 'yyyy-MM-dd', 'en-US')}` : `on ${formatDate(data.bookingDetails.statusDate, 'yyyy-MM-dd', 'en-US')}`;
      const status = data.bookingDetails.status ? data.bookingDetails.status.charAt(0).toUpperCase() + data.bookingDetails.status.slice(1) : ``;
      const details = {
        table: {
          headerRows: 0, widths: ['50%', '30%', '20%'], style: 'programTable',
          body: [
            //header
            [
              { text: `Work Order Details: ${data.supplierID}`, border: [true, true, false, false], borderColor: [this.workOrderBorderColor, this.workOrderBorderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.labelLineHeight },
              { text: 'Booking Agent', border: [false, true, false, false], borderColor: ['',this.workOrderBorderColor, '', ''], fontSize: this.labelFont, color: this.labelColor, style: 'avenirLight', lineHeight: this.labelLineHeight },
              { text: 'Status', border: [false, true, true, false], borderColor: ['',this.workOrderBorderColor, this.workOrderBorderColor, ''], fontSize: this.labelFont, color: this.labelColor, style: 'avenirLight', lineHeight: this.labelLineHeight}
            ],
            //row 1
            [
              { text: ``, border: [true, false, false, false], borderColor: [this.workOrderBorderColor, '', '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.labelLineHeight},
              { text: `${bookingAgent}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
              { text: `${status}`, border: [false, false, true, false], borderColor: ['', '', this.workOrderBorderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
            ],
            [
              { text: `Offering: ${data.offering}`, margin: [0,-6,0,],  border: [true, false, false, true], borderColor: [this.workOrderBorderColor, '', '', this.workOrderBorderColor], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: '0.7' },
              { text: `${bookingPartyId}`, border: [false, false, false, true], borderColor: ['', '', '', this.workOrderBorderColor],  color: this.fieldValueColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: `${date}`, border: [false, false, true, true], borderColor: ['', '', this.workOrderBorderColor, this.workOrderBorderColor],  color: this.fieldValueColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.lineHeight }
            ],
          ]
        },
        layout: {
          hLineWidth: function (i, node) { return 0.75; },
          vLineWidth: function (i, node) { return 0.75; }
        }
      };
      return details;
    }

    propertyDetails(data) {
      const providerName = data.bookingDetails.providerName ? data.bookingDetails.providerName : `` ;
      const propertyName = data.bookingDetails.propertyName ? data.bookingDetails.propertyName : `` ;
      const apartmentType = data.bookingDetails.apartmentType ? data.bookingDetails.apartmentType : `` ;
      const rent = data.bookingDetails.rentPaidBy ? data.bookingDetails.rentPaidBy: `` ;
      const country = data.bookingDetails.address && data.bookingDetails.address.country ? data.bookingDetails.address.country : ``;
      const address = data.bookingDetails.address && data.bookingDetails.address.streetLine1 ? data.bookingDetails.address.streetLine1 : ``;
      const city = data.bookingDetails.address && data.bookingDetails.address.city ? data.bookingDetails.address.city : ``;
      const state = data.bookingDetails.address && data.bookingDetails.address.state   ? data.bookingDetails.address.state : ``;
      const postalCode = data.bookingDetails.address && data.bookingDetails.address.postalCode  ? data.bookingDetails.address.postalCode : ``;

      const details = {
        table: {
          headerRows: 0, widths: ['40%', '30%', '30%'], style: 'programTable',
          body: [
            //header
            [
              { text: 'Property Information', border: [true, true, false, false], borderColor: [this.borderColor, this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
              { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
              { text: '', border: [false, true, true, false], borderColor: ['', this.borderColor, this.borderColor, ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight }
            ],
            //row 1
            [
              { text: 'Provider Name', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
              { text: 'Property Name', border: [false, false, false, false], borderColor: ['', '', '', ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
              { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
            ],
            [
              { text: `${providerName}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: `${propertyName}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
            ],
            //row 2
            [
              { text: 'Apartment Type', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
              { text: 'Rent Paid By', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
              { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            ],
            [
              { text: `${apartmentType}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: `${rent}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
            ],
            //row 3
            [
              { text: 'Country', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
              { text: '', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
              { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            ],
            [
              { text: `${country}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: ``, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
            ],
            //row 4
            [
              { text: 'Address', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
              { text: '', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
              { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            ],
            [
              { text: `${address}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
              { text: ``, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            ],
            //row 5
            [
              { text: 'City', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
              { text: 'State', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
              { text: 'Postal Code', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            ],
            [
              { text: `${city}`, border: [true, false, false, true], borderColor: [ this.borderColor, '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: `${state}`, border: [false, false, false, true], borderColor: ['', '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: `${postalCode}`, border: [false, false, true, true], borderColor: ['', '', this.borderColor, this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            ],
          ]
        },
        layout: {
          hLineWidth: function (i, node) { return 0.75; },
          vLineWidth: function (i, node) { return 0.75; }
        }
      };
      return details;
    }

    moveDateDetails(data) {
      const moveInDate = data.bookingDetails.moveDates && data.bookingDetails.moveDates.moveIn ? moment(data.bookingDetails.moveDates.moveIn).format('YYYY-MM-DD') : '';
      const moveOutDate = data.bookingDetails.moveDates && data.bookingDetails.moveDates.moveOut  ? moment(data.bookingDetails.moveDates.moveOut).format('YYYY-MM-DD') : '';
      const details = {
        table: {
          headerRows: 0, widths: ['40%', '60%'], style: 'programTable',
          body: [
            //header
            [
              { text: 'Move Dates', border: [true, true, false, false], borderColor: [this.borderColor, this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
              { text: '', border: [false, true, true, false], borderColor: ['',this.borderColor, this.borderColor, ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight }
            ],
            //row 1
            [
              { text: 'Move In Estimate', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
              { text: 'Move Out Estimate', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
            ],
            [
              { text: `${moveInDate}`, border: [true, false, false, true], borderColor: [this.borderColor, '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
              { text: `${moveOutDate}`, border: [false, false, true, true], borderColor: ['', '', this.borderColor, this.borderColor ], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
            ],
        
          ]
        },
        layout: {
          hLineWidth: function (i, node) { return 0.75; },
          vLineWidth: function (i, node) { return 0.75; }
        }
      };
      return details;
    }
  
    cardSpacing() {
      const space = {
        table: {
          body: [
            [
              {
                text: '', margin: [0, 2, 0, 2], border: [false, false, false, false],
              }
            ]
          ]
        },
        layout: {
          hLineWidth: function (i, node) { return 0.75; },
          vLineWidth: function (i, node) { return 0.75; }
        }
      };
      return space;
    }   
}
