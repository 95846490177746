import { Injectable } from '@angular/core';
import { BaseClientService } from '../../../../../src/app/core/services/base-client.service';
import { map, catchError } from 'rxjs/operators';
import { Country } from '../../../../../src/app/core/models/country.model';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { taskData, timelineData } from '../../../../../src/app/core/data/app-data';
import { Tasks } from '../../../../../src/app/core/models/tasks.model';
import { AppDataService } from '../../../core/services/app-data.service';

export interface TimeLine {
  date?: string;
  description: string;
  source: string;
}

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private _windowRef: any = [];
  /** Instance of BehaviourSubject of type Timeline */
  timelineDetails = new BehaviorSubject <{ timeLines: Array<TimeLine>, count: number } >(null);

  constructor(private readonly baseClientService: BaseClientService, private appDataService: AppDataService) { }

  getTimeLine(orderRequestId: string): Observable<{ timeLines: Array<TimeLine>, count: number }> {
    // return this.baseClientService.get(`/v1/timeline?orderRequestId=${orderRequestId}`).pipe(
    //   map(r => r.body), catchError((err, source) => {
    //     const empty = null;
    //     console.log('Failed to TimeLine Data: ', err);
    //     return of(empty);
    //   })
    // );
    this.timelineDetails.next(this.appDataService.timelineStubData.getValue());
    return of (this.appDataService.timelineStubData.getValue());
  }

  /**
     * To get updated timeline data
     * @param orderRequestId : Order Request Id
  */
  getUpdatedTimeline(orderRequestId) {
      this.getTimeLine(orderRequestId).subscribe(data => {
          if (data) {
              data.timeLines.reverse(); // Job start date to be displayed as last task in timeline
              this.timelineDetails.next(data);
          }
      });
  }


  getTasks(): Observable<  Tasks > {
    // return this.baseClientService.get(`/v1/tasks`)
    //   .pipe(
    //     map(r => r.body), catchError((err, source) => {
    //       const empty = null;
    //       console.log('Failed to TimeLine Data: ', err);
    //       return of(empty);
    //     })
    //   );
       return of (this.appDataService.taskStubData.getValue());
  }

   /** Returns a token for CartusOnline authentication */
   getColToken(): Observable<any> {
    return this.baseClientService
      .get<any>('/v1/navigateToCOL', 'get the CartusOnline token')
      .pipe(
        map(r => r),
        catchError((err, source) => {
          const empty: any = null;
          return of(empty);
        })
      );
  }

  public get windowRef() {
    return this._windowRef;
  }

  public set windowRef(value: any) {
    if (value) {
      this._windowRef.push(value);
    }
  }

}
