import { Component, OnInit, Injector, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProgramManagementService, Benefit, ProgramRequest, ProgramBenefitUpdateRequest } from '../../services/program-management.service';
import { candidateProfile } from '../../../../core/models/candidate.model';
import {CategoryMappingHelper} from '../../../../core/data/CategoryMapping';

export type BenefitCategory = 'Before Move' | 'Shipping' | 'Travel & Transport' | 'After Arrival' | 'Ongoing' | 'Allowance' ;

export const CategoryMapping = CategoryMappingHelper.getCategoryMapping();;

@Component({
  selector: 'app-view-program-details',
  templateUrl: './view-program-details-component.html',
  styleUrls: ['./view-program-details-component.scss']
})
export class ViewProgamDetailsComponent implements OnInit {

/** title for pop up */
candidateProfile: candidateProfile = {} as candidateProfile;
/** benefitlist from the api */
originalBenefitsList: Array<Benefit> = [];
/** text searched in search box */
searchValue: string = null;
/** client id */
 clientId: string = '';
/** benefitlist which is grouped and used for binding */
benefitsList: Array<{ group: string, items: Array<Benefit> }>;
/** program name from candidate profile details */
programName: string = '';
/** transferee name */
transfereeName:string = '';
readonly BenefitCategories = CategoryMappingHelper.getBenefitCategories();

  constructor(
              private readonly spinner: NgxSpinnerService,
              public dialogRef: MatDialogRef<ViewProgamDetailsComponent>,
              @Inject(MAT_DIALOG_DATA) public data: candidateProfile,
              private injector: Injector,
              private readonly programService: ProgramManagementService,
              private toastrService: ToastrService
              ) { }

  ngOnInit() {
    this.candidateProfile = this.data;
    //this.spinner.show();
    this.clientId = sessionStorage.getItem('cc_clientPartyId') ? sessionStorage.getItem('cc_clientPartyId') : sessionStorage.getItem('clientId');//((this.userRole ==='client-contact-initiator')? sessionStorage.getItem('cc_clientPartyId') : sessionStorage.getItem('clientId')) ;
    this.programName = this.data.programDetails ? this.data.programDetails.programName : '';
    //this.transfereeName = (this.data.nameDetails.firstName) + ' ' + (this.data.nameDetails.lastName);
    this.loadProgramBenefits();
  }

  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(0);
  }

  viewProgram() {
    this.dialogRef.close(1);
  }

  /**
   * To load transferee offerings
   */
  loadProgramBenefits() {
    this.spinner.show();
      const clientid = this.clientId;
      const programName = this.programName;
      this.programService.getTransfereeViewProgramBenefits(clientid, programName, this.data.orderRequestId, true).subscribe(data => {
        if (data) {
          data.benefits = data.benefits.sort((a, b) => a.displayName.localeCompare(b.displayName));
          this.originalBenefitsList = JSON.parse(JSON.stringify(data.benefits));
          this.benefitsList = this.groupBenefits(this.originalBenefitsList);
          this.spinner.hide();
        } else {
          this.toastrService.error('Error fetching Benefits', 'Error');
          this.spinner.hide();
        }
      }, () => {
        this.toastrService.error('Error fetching Benefits', 'Error');
        this.spinner.hide();
      });
  }

  groupBenefits(benefits: Array<Benefit>): Array<{ group: string, items: Array<Benefit> }> {
    benefits = this.updateCategoryNames(benefits);
    const benefitsByGroup = Object.values(CategoryMapping).map(category => {
      return {
        group: category,
        items: benefits.filter(benefit => benefit.category === category)
      };
    });
    return benefitsByGroup;
  }

  textSearchBenefits(searchText) {
    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      const benefitList = this.originalBenefitsList;
      const searchBenefits = benefitList.filter(benefit =>
        benefit.displayName.toLowerCase().search(searchText.toLowerCase()) !== -1);
      this.benefitsList = this.groupBenefits(searchBenefits);
      this.searchValue = searchText;
    }
  }

  textSearch(event) {
    if (event.key === 'Backspace' && event.target.value.length === 0) {
      this.textSearchBenefits(event.target.value);
    }
  }

  clearTextSearch() {
    this.benefitsList = this.groupBenefits(this.originalBenefitsList);
    this.searchValue = '';
  }

  updateCategoryNames(benefits: Array<Benefit>) {
    const benefitCategories = [...this.BenefitCategories.map(i => i.toLowerCase())];
    let categoryMapping = {};
    Object.keys(CategoryMapping).map(i => {
      categoryMapping[i.toLowerCase()] = CategoryMapping[i];
    });
    categoryMapping['allowances'] = 'Allowance';
    return benefits.map(benefit => {
      if ((benefitCategories).includes(benefit.category.toLowerCase())) {
        benefit.category = categoryMapping[benefit.category.toLocaleLowerCase()];
      }
      return benefit;
    });
  }
}
