export const milestoneData = {
    "fileNumber":"5196914",
    "count":2,
    "initiatorFirstName":"Susan",
    "initiatorLastName":"Brown",
    "data":[
       {
          "milestoneDate":"2021-02-02",
          "milestoneDesc":"Authorized"
       },
       {
          "milestoneDate":"2021-02-02",
          "milestoneDesc":"Transfer Date"
       }
    ]
 }