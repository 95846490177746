import { Injectable } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';
import { HttpParams } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GetClientOptions } from './client.service';

@Injectable({
  providedIn: 'root'
})
export class ClientContactsService {

  private clientIdSub = new BehaviorSubject('');
  clientId = this.clientIdSub.asObservable();
  private clientContactSub = new BehaviorSubject({});
  clientContact = this.clientContactSub.asObservable();

  constructor(private baseClientService: BaseClientService, private logSvc: RemoteLoggingService) {

   }
  getClientContacts(clientDetails, options: GetClientOptions = null): Observable<{ clientContacts: Array<any>, count: number }> {
    // localhost:4100/v1/admin/client-contact?clientId=clientIdValue&sortBy&sortDirection=testing&searchText=tesint
    let httpParams: HttpParams = new HttpParams();
    this.clientIdSub.next(clientDetails);
    if (options) {
      if (options.sortBy) { httpParams = httpParams.append('sortBy', options.sortBy); }
      if (options.sortDirection) { httpParams = httpParams.append('sortDirection', options.sortDirection); }
      if (options.searchText) { httpParams = httpParams.append('searchText', options.searchText); }
      if (options.division) { httpParams = httpParams.append('division', options.division.toString()); }
    }

    return this.baseClientService
      .getOne<any>(`${options ? 
        `v1/admin/client-contact?clientId=${clientDetails}&${httpParams.toString()}` :
        'v1/admin/client-contact?clientId=' + clientDetails}`)
      .pipe(
        map(r => {
          this.clientContactSub.next(r.body);
          return r.body;
        }),
        catchError(err => {
        this.logSvc.logError(err);
        const empty = null;
          return of(empty);
        })
      );
  }

}
