<!-- Account Manager / Consultant view / AO / AR-->
<ng-container *ngIf="(userRole === 'consultant' || userRole === 'client-account-manager' || userRole === 'application-reliability-analyst' || userRole === 'application-owner') && currentViewType == 'transfereeList'">
    <app-client-details [clientDetails]="clientDetails" (closePanel)="close()"></app-client-details>
    <div>

    <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="clearExistingFilters($event)">
        <mat-tab label="Transferees">
            <ng-container *ngTemplateOutlet="searchBlockForTransferee"></ng-container>
            <ng-container *ngTemplateOutlet="listOfTransferees"></ng-container>
        </mat-tab>
        <mat-tab label="Cost Projections">
            <ng-container *ngTemplateOutlet="searchBlockForCPTransferee"></ng-container>
            <ng-container *ngTemplateOutlet="costProjectionTransferees"></ng-container>
        </mat-tab>
        <mat-tab label="Client Contacts">
            <ng-container *ngTemplateOutlet="searchBlockforClientContacts"></ng-container>
            <app-client-contacts-list 
                [searchKeyword]="searchFilterText"
                (sortList)="getClientContactsList(selectedClientID, $event)"
                [clientContacts]="clientContacts" 
                [clientContactCount]= clientContactCount></app-client-contacts-list>
        </mat-tab>
        <mat-tab label="Programs"> 
            <app-program-list [clientId]="selectedClientID" [clientDetails]="clientDetails"></app-program-list> 
        </mat-tab>
    </mat-tab-group>
    </div>
</ng-container>

<!-- Program administrator-->
<ng-container *ngIf="(userRole === 'program-admin') && currentViewType == 'transfereeList'">
    <app-client-details [clientDetails]="clientDetails" (closePanel)="close()"></app-client-details>
    <div>

    <mat-tab-group [selectedIndex]="selectedTabIndex" (selectedTabChange)="clearExistingFilters($event)">
        <mat-tab label="Programs"> 
            <app-program-list [clientId]="selectedClientID" [clientDetails]="clientDetails"></app-program-list> 
        </mat-tab> 
    </mat-tab-group>
    </div>
</ng-container>

<!-- Client Contact view -->
<ng-container *ngIf="userRole === 'client-contact-initiator' && currentViewType === 'transfereeList'">
    <mat-tab-group #tabGroup [selectedIndex]="selectedTabIndex" (selectedTabChange)="clearExistingFilters($event)">
        <mat-tab label="Transferees">
            <ng-container *ngTemplateOutlet="searchBlockForTransferee"></ng-container>
            <ng-container *ngTemplateOutlet="listOfTransferees"></ng-container>
        </mat-tab>
        <mat-tab label="Cost Projections">
            <ng-container *ngTemplateOutlet="searchBlockForCPTransferee"></ng-container>
            <ng-container *ngTemplateOutlet="costProjectionTransferees"></ng-container>
        </mat-tab>
        <mat-tab label="Programs"> 
            <app-program-list [clientId]="(cc_clientPartyId)" ></app-program-list> 
        </mat-tab>
    </mat-tab-group>
</ng-container>

<!-- File Setup Specialist view -->
<ng-container *ngIf="userRole === 'file-setup-specialist' && currentViewType === 'transfereeList'">
    <app-client-details [clientDetails]="clientDetails" (closePanel)="close()"></app-client-details>
    <div>
        <mat-tab-group #tabGroup [selectedIndex]="selectedTabIndex" (selectedTabChange)="clearExistingFilters($event)">
            <mat-tab label="Transferees">
                <ng-container *ngTemplateOutlet="searchBlockForTransferee"></ng-container>
                <ng-container *ngTemplateOutlet="listOfTransferees"></ng-container>
            </mat-tab>
            <mat-tab label="Cost Projections">
                <ng-container *ngTemplateOutlet="searchBlockForCPTransferee"></ng-container>
                <ng-container *ngTemplateOutlet="costProjectionTransferees"></ng-container>
            </mat-tab>
            <mat-tab label="Client Contacts">
                <ng-container *ngTemplateOutlet="searchBlockforClientContacts"></ng-container>
                <app-client-contacts-list 
                [searchKeyword]="searchFilterText"
                    (sortList)="getClientContactsList(selectedClientID, $event)"
                    [clientContacts]="clientContacts" 
                    [clientContactCount]= clientContactCount></app-client-contacts-list>
            </mat-tab>
            <mat-tab label="Programs"> 
                <app-program-list [clientId]="selectedClientID" [clientDetails]="clientDetails"></app-program-list> 
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-container>

<!-- Account Manager / Program Mgr / Consultant / FSU / ARE View in Client List -->
<ng-container *ngIf="(userRole === 'consultant' || userRole === 'program-admin' || userRole === 'client-account-manager' || userRole === 'file-setup-specialist' || userRole === 'application-reliability-analyst' || userRole === 'application-owner') && currentViewType === 'clientList'">
    <ng-container *ngTemplateOutlet="searchBlockClients"></ng-container>
    <ng-container *ngTemplateOutlet="listOfClients"></ng-container>
</ng-container>


<!-- Search Block for Client List -->
<ng-template #searchBlockClients>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="search">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search within table for..."  [(ngModel)]="searchValue" (keyup.enter)="textSearchClients($event.target.value)" (keyup.backspace)="textSearchClients($event.target.value)" role="searchbox">
            <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch()" *ngIf="searchValue" aria-label="Clear Search">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <!-- <button mat-icon-button class="filter-icon" matTooltip="Filter results"><span tabindex="0" aria-hidden="true" class="material-icons" (click)="getListFilters()">filter_list</span></button> -->
        <!-- <button mat-icon-button class="filter-column" fxHide><span class="material-icons" role="none">view_column</span></button> -->
    </div>
</ng-template>

<!-- Search Block for Transferee -->
<ng-template #searchBlockForTransferee>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="group" *ngIf = "!transfereeDetailView">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search within table for..."  
                [(ngModel)]="searchValue" 
                (keyup)="textSearch($event)"
                (keyup.enter)="textSearchList($event.target.value)"
                aria-label="Search within table for..." 
                role="text">
                    <!-- (keyup.enter)="textSearchCandidateProfiles($event.target.value)"  -->
                    <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch()" *ngIf="searchValue" role="button">
                        <mat-icon>close</mat-icon>
                    </button>
        </mat-form-field>

        <!-- Show only if Selected Tab is Transferee List -->
        <button *ngIf="selectedTabIndex === 0" 
            mat-icon-button class="filter-icon" 
            matTooltip="Filter results" 
            (click)="getListFilters()" 
            role="button" aria-label="Open Filter Modal">
            <span tabindex="0" aria-hidden="true" class="material-icons">filter_list</span>
        </button>
        <button tabindex="0" mat-icon-button class="selection-icon" aria-label="Select data view table list" matTooltip="Column Selection results" (click)="dataViewSelectionModal()">
            <mat-icon >menu</mat-icon>
          </button>
        <!-- <button mat-icon-button class="filter-column" fxHide><span class="material-icons">view_column</span></button> -->
        <app-admin-filter-chip [filterData]="filterDetailsListItems" (filterOptions)="getUpdatedFilterOptions($event, 'move')"></app-admin-filter-chip>
    </div> 
    
    
</ng-template>

<!--Search block for CP transfree-->

<ng-template #searchBlockForCPTransferee>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="group" *ngIf = "!transfereeDetailView">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search within table for..."  
                [(ngModel)]="searchCPValue" 
                (keyup)="textCpSearch($event)"
                (keyup.enter)="textSearchList($event.target.value)"
                aria-label="Search within table for..." 
                role="text">
                    <!-- (keyup.enter)="textSearchCandidateProfiles($event.target.value)"  -->
                    <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch()" *ngIf="searchCPValue" role="button">
                        <mat-icon>close</mat-icon>
                    </button>
        </mat-form-field>

        <!-- Show only if Selected Tab is Transferee List -->
        <button *ngIf="selectedTabIndex === 0" 
            mat-icon-button class="filter-icon" 
            matTooltip="Filter results" 
            (click)="getListFilters()" 
            role="button" aria-label="Open Filter Modal">
            <span tabindex="0" aria-hidden="true" class="material-icons">filter_list</span>
        </button>
        <button tabindex="0" mat-icon-button class="selection-icon" aria-label="Select data view table list" matTooltip="Column Selection results" (click)="dataViewSelectionModal()">
            <mat-icon>menu</mat-icon>
          </button>
        <!-- <button mat-icon-button class="filter-column" fxHide><span class="material-icons">view_column</span></button> -->
        <app-admin-filter-chip [filterData]="filterDetailsListItems" (filterOptions)="getUpdatedFilterOptions($event, 'cp')"></app-admin-filter-chip>
    </div> 
    
    
</ng-template>


<!--Search block for CP transfree ends here-->

<ng-template #searchBlockforClientContacts>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" role="group">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix class="lg-only" role="none">
                <mat-icon>search</mat-icon>
            </span>
            <input matInput tabindex="0" placeholder="Search within table for..."  
                [(ngModel)]="searchCPValue" 
                (keyup)="textSearch($event)"
                (keyup.enter)="textSearchList($event.target.value)"
                aria-label="Search within table for..." 
                role="text">
                    <!-- (keyup.enter)="textSearchCandidateProfiles($event.target.value)"  -->
                    <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch()" *ngIf="searchValue" role="button">
                        <mat-icon aria-label="close">close</mat-icon>
                    </button>
        </mat-form-field>

        <!-- Show only if Selected Tab is Transferee List -->
        <button *ngIf="selectedTabIndex === 0" 
            mat-icon-button class="filter-icon" 
            matTooltip="Filter results" 
            (click)="getListFilters()" 
            role="button" aria-label="Open Filter Modal" tabindex="0">
            <span aria-hidden="true" class="material-icons">filter_list</span>
        </button>
        <!-- <button mat-icon-button class="filter-column" fxHide><span class="material-icons">view_column</span></button> -->
    </div>
</ng-template>

<!-- List of Transferees for Client Contact Only -->
<ng-template #listOfTransferees>
    <div class="kpi-section">
        
    </div>
    <!-- Transferee List Table -->
    <!-- (searchCandidates)="callSearchFilter($event)"
        [filterObj]="filterDetailsListItems" -->
    <app-transferee-list 
        
        [searchKeyword]="searchFilterText"
        [candidateList]="candidates" 
        [candidatesCount]="candidatesCount" 
        [userRole]="userRole" 
        (transfereeDetailView) = "getTransfereeDetailView($event)"
        (refreshList)="getCandidatesList()" 
        (sortList)="getCandidatesList($event)" 
        (deactivateTransferees)="deactivateTransferee($event)"
        [clientDetails]="clientDetails">
    </app-transferee-list>

    <!-- <div mat-elevation-z8>
        <mat-card class="empty-results">
            <div fxLayout="column" fxLayoutAlign="space-around center" >
                <p>0 results matching your criteria</p>
                (click)="clearCriteria()"
                <a>Clear Criteria</a>
            </div>
        </mat-card>
    </div> -->

</ng-template>

<!-- Client List Table for Account Manager & Program Admin Only -->
<ng-template #listOfClients>
    <app-client-list 
        [searchKeyword]="searchFilterText"
        [clientList]="clientList" 
        (refreshList)="getClientsList()" 
        (sortList)="getClientsList($event)" 
        (openSelectedList)="openSelectedList($event)">
    </app-client-list>
</ng-template> 

<!-- List of Transferees for cost-projection -->
<ng-template #costProjectionTransferees>
    <div class="kpi-section">

    </div>
    <!-- Transferee List Table -->
    <!-- <app-transferee-list 
        (searchCandidates)="callSearchFilter($event, 'cp')"
        [isCostProjection] = "true"
        [filterObj]="filterDetailsListItems"
        [searchKeyword]="searchCPValue"
        [candidateList]="costCandidates" 
        [candidatesCount]="costCandidatesCount" 
        [userRole]="userRole" 
        (changeTab) = "changeTab($event)"
        (cpTransfereeDetailView) = "getTransfereeDetailView($event)"
        (cpRefreshList)="getCostProjCandidatesList($event)"
        (cpsortList)="getCostProjCandidatesList($event)" 
        [clientDetails]="clientDetails">
        
    </app-transferee-list> -->
    <app-transferee-list 
        [isCostProjection] = "true"
        [searchKeyword]="searchFilterText"
        [candidateList]="costCandidates" 
        [candidatesCount]="costCandidatesCount" 
        [userRole]="userRole" 
        (cpTransfereeDetailView) = "getTransfereeDetailView($event)"
        (cpRefreshList)="getCostProjCandidatesList()" 
        (cpsortList)="getCostProjCandidatesList($event)" 
        (deactivateTransferees)="deactivateTransferee($event)"
        [clientDetails]="clientDetails">
    </app-transferee-list>

</ng-template>
