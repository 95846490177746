import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { HttpParams } from "@angular/common/http";
import { EventEmitter, Injectable } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';


export interface GetConfigurableField  {
	includeDeActivated?:string;
	clientPartyId?: string;
	includeNotAuthorized?:string;
}

export interface ConfigurableField {
	displayName: string;
	type?: string;
	valueList?: string[];
	_specType?: string;
	value?: any;
}

export const fieldMapping = [
	{
		"DataItemTypDescr": "Text",
		"dataType": "string",
		"defaultValue": "",
		"base_specType": "scopedsimpleAttribute"
	},
	{
		"DataItemTypDescr": "Multiple Select",
		"dataType": "string",
		"defaultValue": [],
		"base_specType": "scopedlistboundMultiAttribute "
	},
	{
		"DataItemTypDescr": "Single Select",
		"dataType": "string",
		"defaultValue": "",
		"base_specType": "scopedlistboundSingleAttribute"
	},
	{
		"DataItemTypDescr": "Date",
		"dataType": "Date",
		"defaultValue": "",
		"base_specType": "scopedsimpleAttribute"
	},
	{
		"DataItemTypDescr": "Country",
		"dataType": "string",
		"defaultValue": "",
		"base_specType": "scopedsimpleAttribute"
	},
	{
		"DataItemTypDescr": "Number",
		"dataType": "number",
		"defaultValue": "",
		"base_specType": "scopedsimpleAttribute"
	},
	{
		"DataItemTypDescr": "Currency",
		"dataType": {currencyCode:"string", currencyAmount:"number"},
		"defaultValue": {currencyCode: "", currencyAmount: null },
		"base_specType": "scopedsimpleAttribute"
	},
	{
		"DataItemTypDescr": "Integer",
		"dataType": "number",
		"defaultValue": "",
		"base_specType": "scopedsimpleAttribute"
	}
];

@Injectable({
  providedIn: 'root'
})

export class ConfigurableFieldService {
	clientList = new BehaviorSubject<Array<any>>(null);
  
	constructor(private baseClientService: BaseClientService, private logSvc: RemoteLoggingService) { }
  
	getConfigurableFields(clientPartyId: string, includeDeActivated: boolean, includeNotAuthorized: boolean): Observable<ConfigurableField[]> {
/* 		let httpParams = new HttpParams();
		httpParams = httpParams.set('clientPartyId', clientPartyId);
		httpParams = httpParams.set('includeDeActivated', `${includeDeActivated}`);
		httpParams = httpParams.set('includeNotAuthorized', `${includeNotAuthorized}`);
		return this.baseClientService
			.getOne<any>(`${'v1/admin/configurable-field?' + httpParams}`)
			.pipe(
				map(r => r.body),
				catchError(err => {
					this.logSvc.logError(err);
					const emptyArray = null;
					return of(emptyArray);
				})
			); */
      return of(configurableField);
	}

  
  }

  //Stub data for configurable-field Api.
export const configurableField  =
[
	{
		"_id": "6087b4f81d3cc700090927c6",
		"_specType": "scopedListboundSingleAttribute",
		"displayName": "Benefit Builder Policy",
		"description": "NA",
		"isAuthorizationRequired": true,
		"activationDate": "2021-03-25T18:30:00.000Z",
		"valueList": [
			"Core-Flex: Long-Term Accompanied",
			"Core-Flex: Long-Term Accompanied Repat",
			"Core-Flex: Long-Term Unaccompanied",
			"Core-Flex: Long-Term Unaccompanied Repat"
		],
		"type": "Single Select"
	},
	{
		"_id": "60b4774a142ce30008653999",
		"_specType": "scopedSimpleAttribute",
		"displayName": "Anticipated End Date",
		"description": "Anticipated End Date",
		"isAuthorizationRequired": true,
		"activationDate": "2021-05-31T00:00:00.000Z",
		"expirationDate": "2021-10-31T00:00:00.000Z",
		"type": "Date"
	}
]
