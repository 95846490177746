import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Client } from '../../../core/models/client.model';
import { UserSessionService } from '../../../core/services/user-session.service';

/** client component to display the list of clients for a account manager */
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientsComponent implements OnInit {
/** To paginate in a mat table */
@ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
/** To sort the mat table columns */
@ViewChild(MatSort, {static: true}) sort: MatSort;
/** clientList value passed from the parent component*/
@Input() clientList: Client[];
/** seletcedClient value passed to the parent component*/
@Output() selectedClient = new EventEmitter();
/** showLogout to pass the showLogout to parent component*/
@Output() showLogout: EventEmitter<any> = new EventEmitter();
/** displayed coulms to display the columns in the mat table */
displayedColumns: string[] = ['clientName', 'clientNo'];
/** dataSource variable to store the response converted to mat table datasource */
dataSource: MatTableDataSource<Client>;
/** searchValue to pass the searched value on the icon click method */
searchValue: string;
/**store the filter value */
filterText = '';

showError = false;

isSearchCleared = true;

/** Base constructor method
 * @param userSessionService UserSessionService injection
 */
constructor(private userSessionService: UserSessionService) { }

/** Init method to call the methods on page load */
ngOnInit() {
  if (this.clientList) {
    this.getDataSource();
  }
  setTimeout(() => {
    this.showLogout.emit(true);
  });
}

/** getDataSource method to load the table data source, paginator and sort */
getDataSource() {
  this.dataSource = new MatTableDataSource<Client>(this.clientList);
  this.dataSource.paginator = this.paginator;
  this.sort.disableClear = true; // Disable unsorted
  this.dataSource.sortingDataAccessor = (data, attribute) => data[attribute];
  const sortState: Sort = {active: 'clientName', direction: 'asc'};
  this.sort.active = sortState.active;
  this.sort.direction = sortState.direction;
  this.dataSource.sort = this.sort;
}

/** Get the client selected from client list grid */
selectClient(client) {
  sessionStorage.setItem('clientNo', client.clientNo);
  sessionStorage.setItem('clientName', client.clientName);
  this.selectedClient.emit(client);
  this.showLogout.emit(false);
}

/** applySearch method to get the filtered results based on entered value */
applySearch(filterValue: string) {
  filterValue = filterValue.trim();
  this.searchValue = filterValue;
  if (filterValue.length > 2 || (!this.isSearchCleared && filterValue === '')) {
    if (filterValue.length > 2) {
      this.isSearchCleared = false;
    } else {
      this.isSearchCleared = true;
    }
    this.showError = false;
    this.filterText = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  } else {
    this.showError = true;
    this.getDataSource();
  }
}

toggleError() {
  this.showError = false;
}

/** clear method to clear the search results entered */
clearSearchTextResults() {
  this.searchValue = '';
  this.filterText = '';
  this.getDataSource();
}
}
