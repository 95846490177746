<div class="dialog-container" role="region">
  <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px"
    fxLayoutGap.xs="0em" role="heading">
    <h2 class="divisions-title"> Deactivate Program</h2>
    <a class="close-icon" (click)="close()" title="close" role="button" aria-label="Close Program Modal"
      title="Close Program Modal">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-dialog-content class="dialog-content">
        <p>You are about to deactivate the following programs:</p>
            <div *ngFor="let program of data.programList" class="program-list">
                <input matInput readonly value="{{program}}">
            </div>
        <p class="aware-text"> <b> Please be aware:</b></p>
         <ul>
            <li>You will not be able to assign new transferees to these programs.</li>
            <li>These programs will remain assigned to any transferees who were previously assigned to it.</li>
            <li>The program will be set to expire or deactivated by default from tomorrow.</li>
            <li>If you wish to deactivate or expire the program in future date, please set the expiration date at individual program level by going inside the program details.</li>
        </ul>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button class="text-button rest-btn" (click)="close()">CANCEL</button>
        <button mat-button class="contained-button" (click)='deactivate()'>Deactivate</button>
    </mat-dialog-actions>
</div>