<!-- <ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner> -->
<div class="loginContainer FooterAlign" fxFlex="100%" fxLayout fxLayout.xs="column" fxLayout.sm="column">
  <app-left-panel fxFlex="50%" fxHide.xs fxHide.sm></app-left-panel>
  <div fxFlex="50%" class="loginContentBlock">
    <mat-card class="loginDetailsBlock">
      <mat-card-content>
        <div *ngIf="appId == 1" class="logo" fxLayoutAlign="center center">
          <img id="bbLogo" src="../../../../assets/imgs/BenefitsBuilderlogo.svg" alt="Benefits Builder">
        </div>
        <div *ngIf="appId == 2" class="logo" fxLayoutAlign="center center">
          <img id="moveProLogo" src="../../../../assets/imgs/MovePro360-Color.svg" alt="MovePro360">
        </div>
        <div *ngIf="appId == 3" class="logo" fxLayoutAlign="center center">
          <img id="alphaLogo" src="../../../../assets/imgs/logo-mobilify-stacked.svg" alt="Mobilify">
        </div>
        <div *ngIf="appId == 4" class="logo" fxLayoutAlign="center center">
          <img id="alphaLogo" src="../../../../assets/imgs/MovePro360-Color.svg" alt="Admin">
        </div>
        <div *ngIf="appId == null"class="logo" fxLayoutAlign="center center">
          <img id="alphaLogo" src="../../../../assets/landingpage_assets/Cartus_logo.JPG" alt="Cartus">
        </div>
        <div class="intro-message body-copy" ngClass.sm="med-margin">
          <div *ngIf="appId == 2">{{templateString.MOVEPRO_TXT}}</div>
        </div>

        <div id="widget">
            <main id="okta-sign-in" class="auth-container main-container no-beacon">
              <div class="okta-sign-in-header auth-header">
                <div class="beacon-container"></div>
              </div>
              <div class="auth-content">
                <div class="auth-content-inner">
                  <div class="primary-auth">
                    <form novalidate="novalidate" id="form1" class="primary-auth-form o-form o-form-edit-mode" #f = ngForm (ngSubmit)="onLogin(f)">
                      <div class="o-form-content o-form-theme clearfix">
                        <h2 class="okta-form-title o-form-head">Enter your credentials to log in</h2>
                        <div class="o-form-error-container"></div>
                        <div class="o-form-fieldset-container">
                          <div class="o-form-fieldset o-form-label-top margin-btm-5">
                            <div class="okta-form-label o-form-label"><label for="okta-signin-username">Username&nbsp;</label></div>
                            <div class="o-form-input"><span data-se="o-form-input-username" class="o-form-input-name-username o-form-control okta-form-input-field input-fix"><input type="text" placeholder="" name="username" id="okta-signin-username" value="" aria-label="" autocomplete="username" required="" [(ngModel)]="username"></span></div>
                          </div>
                          <div class="o-form-fieldset o-form-label-top margin-btm-30">
                            <div class="okta-form-label o-form-label"><label for="okta-signin-password">Password&nbsp;</label></div>
                            <div class="o-form-input"><span data-se="o-form-input-password" class="o-form-input-name-password o-form-control okta-form-input-field input-fix"><input type="password" placeholder="" name="password" id="okta-signin-password" value="" aria-label="" autocomplete="current-password" required="" [(ngModel)]="password"></span></div>
                          </div>
                        </div>
                      </div>
                      <div class="o-form-button-bar" >
                          <input class="button button-primary" type="submit" value="Log In" id="okta-signin-submit" [disabled]="username === '' && password === '' ">
                      </div>
                    </form>
                    <div class="auth-footer">
                      <a href="#" data-se="needhelp" aria-expanded="false" aria-controls="help-links-container" class="link help js-help">Need help signing in?</a>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
      </mat-card-content>
    </mat-card>
    <app-footer></app-footer>
  </div>
</div>
