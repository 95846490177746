import { Tasks } from "../models/tasks.model"
import { BenefitsData } from "../../../../src/app/public/benefits/services/benefit.service";
import { TransfereeNeedsAssessment } from "../models/candidateneeds-assessment.model";



export class bbAppData {

    taskData : Tasks;
    needAssessmentData;
    timelineData;
    benefitsData : BenefitsData;

}

let currentDate: any = new Date();
let startDate: any=new Date();
startDate.setDate(startDate.getDate() + 10);

export const familydata=
[
  {
    "nameDetails": {
      "title": "Mr.",
      "firstName": "chrishtopher",
      "lastName": "Henry"
    },
    "phoneDetailsList": [
      {
        "_id": "6026743ba045140008783a4b",
        "textingAvailable": false,
        "primary": true,
        "usageType": "business",
        "phoneDialCode": 91,
        "phoneNumber": "9238733832377",
        "locationType": "departure"
      }
    ],
    "emailDetailsList": [],
    "id": "6026743cde3e3d00081e9b8c",
    "dateOfBirth": null,
    "relationshipType": "Partner"
  }
]
export  const consentinfodata= {
  "legalName": "RZ ",
  "status": true,
  "code": "UPDATE_SUCCESS"
}
export const carsestok =
 
    'eyJraWQiOiI1Zmd6ekpYVnREVW5QdWRmclNiNFE1ZWh6SktnWEZOZkFKa0xMTFh0OWpjIiwiYWxnIjoiUlMyNTYifQ.eyJ2ZXIiOjEsImp0aSI6IkFULlZ5UXVSMjFTTkpwdlRrLTl2eFZMT1JSOTFkSzBoa0NoWFZDZUNEMTU2LTgiLCJpc3MiOiJodHRwczovL2NhcnR1cy5va3RhcHJldmlldy5jb20vb2F1dGgyL2F1c3B5ZTJudXpTR0owS2plMGg3IiwiYXVkIjoiY2FydHVzVXNlcnMiLCJpYXQiOjE2MTI5NDIxNzYsImV4cCI6MTYxMjk0NTc3NiwiY2lkIjoiMG9hcHl0ejdtZXVNVzBydm4waDciLCJ1aWQiOiIwMHV2ZjNqb3M2M0lnc3NRbjBoNyIsInNjcCI6WyJvcGVuaWQiXSwic3ViIjoiMjE0M1RyYW5zZmVyZWUudGVzdDEyM0B0ZXN0LmNvbSJ9.SNt9G7f3XpGI9gYPm2ukCV9IwFiDrpvWoT9afvo_QSCEktbhlnsJk8v7sOZEdyJdwEms_N2TaVcR4jz9HEX91syD2YWVrWiEOUOzdNu3E62VX_6eGVzrZxt60fMELJ5ONkcPcQGGcChACd0COW1sgfx44ROL08ukYreK09qtsiMRJmhaDrIf6-dX70h4Nv-Sv-t0pPysjUiu9hG4K3uKDcjcwERmmIgZ1tUgJilwpZ5iQcCOFYnu-uHyypz0rD53F_ZJ1x2pouYx6TEAdn36UcJCeXAnyvKVfsTr4h6L4rhgTzbCuwdmFRVFMOCaCUuOpOnisUNvo-g8QjKm78miZw'


export const taskData : Tasks =    
{
  "policyCall": {
    "consultantDetails": {
      "firstName": "Michele",
      "lastName": "Sachetti",
      "emailDetailsList": [
        {
          "emailAddress": "michele.sachetti@cartus.com"
        }
      ],
      "phoneDetailsList": [
        {
          "phoneNumber": "2032051387",
          "phoneDialingCode": "1"
        }
      ],
      "timeZone": "America/New_York"
    },
    "status": "not started",
    "policyCallRequired": false,
    "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
    "custFileId": 878821
  }
}
export const cronofyToken = {
       "token":"5lNEtblCxZo2pGZELXbvh1ZEtXjswFBwOktdWDENKMTfjUB8T43m1oEhb8gV35UPJ721jyx-DPz-9cNtLwBIFw"
}

export const transfereeNeedsAssessmentData  : TransfereeNeedsAssessment = {
  "nameDetails": {
    "title": "Mr.",
    "firstName": "Robert",
    "lastName": "Williams"
  },
  "departureAddr": {
    "streetLine1": "1261, West 79th Street, South Los Angeles",
    "city": "Los Angeles",
    "state": "California",
    "country": "United States",
    "postalCode": "90044"
  },
  "destinationAddr": {
    "streetLine1": "2000, Anvil Block Road",
    "city": "Forest Park",
    "state": "Georgia",
    "country": "United States",
    "postalCode": "30297"
  },
  "phoneDetailsList": [
    {
      "_id": "6018f83b81fbdd00097a1162",
      "textingAvailable": true,
      "primary": false,
      "usageType": "personal",
      "phoneDialCode": 91,
      "phoneNumber": "9591962156"
    }
  ],
  "emailDetailsList": [
    {
      "_id": "6018f83b81fbdd00097a1163",
      "primary": true,
      "usageType": "business",
      "emailAddress": "robertWilliam@gmail.com"
    }
  ],
  "pointDetails": {
    "points": 80,
    "selectedPoints": 3
  },
  "benefitDetails": {
    "coreBenefits": 12,
    "confirmedBenefits" : 13
  },
 
  "orderRequestId": "6018f84613437200093e29e3",
  "clientEntityName": "ABC Demo Company Ltd",
  "clientId": "3966",
  "programName": "High Value US Inbound Program",
  "orderRefNum": "MOV-waaw3qdz",
  "jobStartDate" : startDate.toDateString(),
  "confirmStatus": true,
  "spousePartnerRelocating": true,
  "childrenDependentsRelocating": false,
  "totalNumberOfRelocatingMembers": 2
}
export const informativetextdata =[
  {
    "key": "landing-content",
    "textHeader": "Welcome to Benefits Builder",
    "mainContent": "Welcome to your Cartus BenefitsBuilder hub! Please take a few minutes to complete the Essential Information questionnaire. This information will be used to create your benefits selection package. Upon completion, you will be brought to your main Dashboard where you can review the benefit details and make arrangements to discuss your options and relocation needs with your Cartus Consultant.",
    "helpQuestion": "Quick Guide",
    "helpContent": "Any help you might need will appear in this area during this process."
  },
  {
    "key": "profile-content",
    "helpQuestion": "Quick Guide",
    "helpContent": "Having a spouse/partner and/or children accompanying you on your relocation may impact the benefits offered, especially regarding travel costs, meals and hotel arrangements."
  },
  {
    "key": "locations-content",
    "helpQuestion": "Quick Guide",
    "helpContent": "Where you are departing from and where you are going to can have an effect on the benefits you are eligible for."
  },
  {
    "key": "phone-email-content",
    "helpQuestion": "Quick Guide",
    "helpContent": "Having alternate means to contact you ensures we can always reach you whenever necessary."
  }
]
export const countryinfodata =
[
  {
    "countryName": "Afghanistan",
    "countryDialingCode": 93,
    "countryiso3CharCode": "AFG",
    "countryiso2CharCode": "AF",
    "currency": {
      "description": "Afghanistan Afghan",
      "code": "AFN"
    }
  },
  {
    "countryName": "Albania",
    "countryDialingCode": 355,
    "countryiso3CharCode": "ALB",
    "countryiso2CharCode": "AL",
    "currency": {
      "description": "Albanian Lek",
      "code": "ALL"
    }
  },
  {
    "countryName": "Algeria",
    "countryDialingCode": 213,
    "countryiso3CharCode": "DZA",
    "countryiso2CharCode": "DZ",
    "currency": {
      "description": "Algerian Dinar",
      "code": "DZD"
    }
  },
  {
    "countryName": "American Samoa",
    "countryDialingCode": 684,
    "countryiso3CharCode": "ASM",
    "countryiso2CharCode": "AS",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Andorra",
    "countryDialingCode": 376,
    "countryiso3CharCode": "AND",
    "countryiso2CharCode": "AD",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Angola",
    "countryDialingCode": 244,
    "countryiso3CharCode": "AGO",
    "countryiso2CharCode": "AO",
    "currency": {
      "description": "Angolan Kwanza",
      "code": "AOA"
    }
  },
  {
    "countryName": "Anguilla",
    "countryDialingCode": 1264,
    "countryiso3CharCode": "AIA",
    "countryiso2CharCode": "AI",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Antarctica",
    "countryDialingCode": 672,
    "countryiso3CharCode": "ATA",
    "countryiso2CharCode": "AQ",
    "currency": {
      "description": "Norwegian Krone",
      "code": "NOK"
    }
  },
  {
    "countryName": "Antigua and Barbuda",
    "countryDialingCode": 1268,
    "countryiso3CharCode": "ATG",
    "countryiso2CharCode": "AG",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Argentina",
    "countryDialingCode": 54,
    "countryiso3CharCode": "ARG",
    "countryiso2CharCode": "AR",
    "currency": {
      "description": "Argentine Peso",
      "code": "ARS"
    }
  },
  {
    "countryName": "Armenia",
    "countryDialingCode": 374,
    "countryiso3CharCode": "ARM",
    "countryiso2CharCode": "AM",
    "currency": {
      "description": "Armenia Dram",
      "code": "AMD"
    }
  },
  {
    "countryName": "Aruba",
    "countryDialingCode": 297,
    "countryiso3CharCode": "ABW",
    "countryiso2CharCode": "AW",
    "currency": {
      "description": "Aruban Florin",
      "code": "AWG"
    }
  },
  {
    "countryName": "Australia",
    "countryDialingCode": 61,
    "countryiso3CharCode": "AUS",
    "countryiso2CharCode": "AU",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Austria",
    "countryDialingCode": 43,
    "countryiso3CharCode": "AUT",
    "countryiso2CharCode": "AT",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Azerbaijan",
    "countryDialingCode": 994,
    "countryiso3CharCode": "AZE",
    "countryiso2CharCode": "AZ",
    "currency": {
      "description": "Azerbaijani Manat",
      "code": "AZN"
    }
  },
  {
    "countryName": "Bahamas",
    "countryDialingCode": 997,
    "countryiso3CharCode": "BHS",
    "countryiso2CharCode": "BS",
    "currency": {
      "description": "Bahamas Dollar",
      "code": "BSD"
    }
  },
  {
    "countryName": "Bahrain",
    "countryDialingCode": 973,
    "countryiso3CharCode": "BHR",
    "countryiso2CharCode": "BH",
    "currency": {
      "description": "Bahraini Dinar",
      "code": "BHD"
    }
  },
  {
    "countryName": "Bangladesh",
    "countryDialingCode": 880,
    "countryiso3CharCode": "BGD",
    "countryiso2CharCode": "BD",
    "currency": {
      "description": "Bangladeshi Taka",
      "code": "BDT"
    }
  },
  {
    "countryName": "Barbados",
    "countryDialingCode": 246,
    "countryiso3CharCode": "BRB",
    "countryiso2CharCode": "BB",
    "currency": {
      "description": "Barbados Dollar",
      "code": "BBD"
    }
  },
  {
    "countryName": "Belarus",
    "countryDialingCode": 375,
    "countryiso3CharCode": "BLR",
    "countryiso2CharCode": "BY",
    "currency": {
      "description": "Belarussian Ruble - Do Not Use",
      "code": "BYR"
    }
  },
  {
    "countryName": "Belgium",
    "countryDialingCode": 32,
    "countryiso3CharCode": "BEL",
    "countryiso2CharCode": "BE",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Belize",
    "countryDialingCode": 501,
    "countryiso3CharCode": "BLZ",
    "countryiso2CharCode": "BZ",
    "currency": {
      "description": "Belize Dollar",
      "code": "BZD"
    }
  },
  {
    "countryName": "Benin",
    "countryDialingCode": 229,
    "countryiso3CharCode": "BEN",
    "countryiso2CharCode": "BJ",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Bermuda",
    "countryDialingCode": 441,
    "countryiso3CharCode": "BMU",
    "countryiso2CharCode": "BM",
    "currency": {
      "description": "Bermudian Dollar",
      "code": "BMD"
    }
  },
  {
    "countryName": "Bhutan",
    "countryDialingCode": 975,
    "countryiso3CharCode": "BTN",
    "countryiso2CharCode": "BT",
    "currency": {
      "description": "Bhutan Ngultrum",
      "code": "BTN"
    }
  },
  {
    "countryName": "Bolivia",
    "countryDialingCode": 591,
    "countryiso3CharCode": "BOL",
    "countryiso2CharCode": "BO",
    "currency": {
      "description": "Bolivia Boliviano",
      "code": "BOB"
    }
  },
  {
    "countryName": "Bosnia and Herzegovina",
    "countryDialingCode": 387,
    "countryiso3CharCode": "BIH",
    "countryiso2CharCode": "BA"
  },
  {
    "countryName": "Botswana",
    "countryDialingCode": 267,
    "countryiso3CharCode": "BWA",
    "countryiso2CharCode": "BW",
    "currency": {
      "description": "Botswana Pula",
      "code": "BWP"
    }
  },
  {
    "countryName": "Bouvet Island",
    "countryDialingCode": 284,
    "countryiso3CharCode": "BVI",
    "countryiso2CharCode": "BV",
    "currency": {
      "description": "Norwegian Krone",
      "code": "NOK"
    }
  },
  {
    "countryName": "Brazil",
    "countryDialingCode": 55,
    "countryiso3CharCode": "BRA",
    "countryiso2CharCode": "BR",
    "currency": {
      "description": "Brazilian Real",
      "code": "BRL"
    }
  },
  {
    "countryName": "British Indian Ocean Territory",
    "countryDialingCode": 246,
    "countryiso3CharCode": "IOT",
    "countryiso2CharCode": "IO",
    "currency": {
      "description": "British Pound",
      "code": "GBP"
    }
  },
  {
    "countryName": "British Virgin Islands",
    "countryDialingCode": 284,
    "countryiso3CharCode": "VGB",
    "countryiso2CharCode": "VG",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Brunei Darussalam",
    "countryDialingCode": 673,
    "countryiso3CharCode": "BRN",
    "countryiso2CharCode": "BN",
    "currency": {
      "description": "Brunei Dollar",
      "code": "BND"
    }
  },
  {
    "countryName": "Bulgaria",
    "countryDialingCode": 359,
    "countryiso3CharCode": "BGR",
    "countryiso2CharCode": "BG",
    "currency": {
      "description": "Bulgarian Lev",
      "code": "BGN"
    }
  },
  {
    "countryName": "Burkina Faso",
    "countryDialingCode": 226,
    "countryiso3CharCode": "BFA",
    "countryiso2CharCode": "BF",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Myanmar",
    "countryDialingCode": 95,
    "countryiso3CharCode": "MMR",
    "countryiso2CharCode": "MM",
    "currency": {
      "description": "Myanmar Kyat",
      "code": "MMK"
    }
  },
  {
    "countryName": "Burundi",
    "countryDialingCode": 257,
    "countryiso3CharCode": "BDI",
    "countryiso2CharCode": "BI",
    "currency": {
      "description": "Burundi Franc",
      "code": "BIF"
    }
  },
  {
    "countryName": "Cambodia",
    "countryDialingCode": 855,
    "countryiso3CharCode": "KHM",
    "countryiso2CharCode": "KH",
    "currency": {
      "description": "Cambodian Riel",
      "code": "KHR"
    }
  },
  {
    "countryName": "Cameroon",
    "countryDialingCode": 237,
    "countryiso3CharCode": "CMR",
    "countryiso2CharCode": "CM",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Canada",
    "countryDialingCode": 1,
    "countryiso3CharCode": "CAN",
    "countryiso2CharCode": "CA",
    "currency": {
      "description": "Canadian Dollar",
      "code": "CAD"
    }
  },
  {
    "countryName": "Cape Verde",
    "countryDialingCode": 238,
    "countryiso3CharCode": "CPV",
    "countryiso2CharCode": "CV",
    "currency": {
      "description": "Cape Verde Escudo",
      "code": "CVE"
    }
  },
  {
    "countryName": "Cayman Islands",
    "countryDialingCode": 345,
    "countryiso3CharCode": "CYM",
    "countryiso2CharCode": "KY",
    "currency": {
      "description": "Cayman Islands Dollar",
      "code": "KYD"
    }
  },
  {
    "countryName": "Central African Republic",
    "countryDialingCode": 236,
    "countryiso3CharCode": "CAF",
    "countryiso2CharCode": "CF",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Chad",
    "countryDialingCode": 235,
    "countryiso3CharCode": "TCD",
    "countryiso2CharCode": "TD",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Chile",
    "countryDialingCode": 56,
    "countryiso3CharCode": "CHL",
    "countryiso2CharCode": "CL",
    "currency": {
      "description": "Chilean Peso",
      "code": "CLP"
    }
  },
  {
    "countryName": "China",
    "countryDialingCode": 86,
    "countryiso3CharCode": "CHN",
    "countryiso2CharCode": "CN",
    "currency": {
      "description": "Chinese Yuan Renminbi",
      "code": "CNY"
    }
  },
  {
    "countryName": "Christmas Island",
    "countryDialingCode": 6724,
    "countryiso3CharCode": "CXR",
    "countryiso2CharCode": "CX",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Cocos (Keeling) Islands",
    "countryDialingCode": 2877,
    "countryiso3CharCode": "CCK",
    "countryiso2CharCode": "CC",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Colombia",
    "countryDialingCode": 57,
    "countryiso3CharCode": "COL",
    "countryiso2CharCode": "CO",
    "currency": {
      "description": "Colombian Peso",
      "code": "COP"
    }
  },
  {
    "countryName": "Comoros",
    "countryDialingCode": 269,
    "countryiso3CharCode": "COM",
    "countryiso2CharCode": "KM",
    "currency": {
      "description": "Comoros Franc",
      "code": "KMF"
    }
  },
  {
    "countryName": "Congo, Republic of the",
    "countryDialingCode": 242,
    "countryiso3CharCode": "COG",
    "countryiso2CharCode": "CG",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Congo, Democratic Republic of the",
    "countryDialingCode": 243,
    "countryiso3CharCode": "COD",
    "countryiso2CharCode": "CD",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Cook Islands",
    "countryDialingCode": 682,
    "countryiso3CharCode": "COK",
    "countryiso2CharCode": "CK",
    "currency": {
      "description": "New Zealand Dollar",
      "code": "NZD"
    }
  },
  {
    "countryName": "Costa Rica",
    "countryDialingCode": 506,
    "countryiso3CharCode": "CRI",
    "countryiso2CharCode": "CR",
    "currency": {
      "description": "Costa Rican Colon",
      "code": "CRC"
    }
  },
  {
    "countryName": "Côte D'ivoire",
    "countryDialingCode": 225,
    "countryiso3CharCode": "CDR",
    "countryiso2CharCode": "CI",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Croatia",
    "countryDialingCode": 385,
    "countryiso3CharCode": "HRV",
    "countryiso2CharCode": "HR",
    "currency": {
      "description": "Croatian Kuna",
      "code": "HRK"
    }
  },
  {
    "countryName": "Cuba",
    "countryDialingCode": 53,
    "countryiso3CharCode": "CUB",
    "countryiso2CharCode": "CU",
    "currency": {
      "description": "Cuban Peso",
      "code": "CUP"
    }
  },
  {
    "countryName": "Cyprus",
    "countryDialingCode": 357,
    "countryiso3CharCode": "CYP",
    "countryiso2CharCode": "CY",
    "currency": {
      "description": "Cyprus Pound",
      "code": "CYP"
    }
  },
  {
    "countryName": "Czech Republic",
    "countryDialingCode": 420,
    "countryiso3CharCode": "CZE",
    "countryiso2CharCode": "CZ",
    "currency": {
      "description": "Czech Koruna",
      "code": "CZK"
    }
  },
  {
    "countryName": "Denmark",
    "countryDialingCode": 45,
    "countryiso3CharCode": "DNK",
    "countryiso2CharCode": "DK",
    "currency": {
      "description": "Danish Krone",
      "code": "DKK"
    }
  },
  {
    "countryName": "Djibouti",
    "countryDialingCode": 253,
    "countryiso3CharCode": "DJI",
    "countryiso2CharCode": "DJ",
    "currency": {
      "description": "Djibouti Franc",
      "code": "DJF"
    }
  },
  {
    "countryName": "Dominica",
    "countryDialingCode": 767,
    "countryiso3CharCode": "DMA",
    "countryiso2CharCode": "DM",
    "currency": {
      "description": "Dominican Peso",
      "code": "DOP"
    }
  },
  {
    "countryName": "Dominican Republic",
    "countryDialingCode": 809,
    "countryiso3CharCode": "DOM",
    "countryiso2CharCode": "DO",
    "currency": {
      "description": "Dominican Peso",
      "code": "DOP"
    }
  },
  {
    "countryName": "Dubai",
    "countryDialingCode": 971,
    "countryiso3CharCode": "DUB",
    "countryiso2CharCode": "AE",
    "currency": {
      "description": "United Arab Emirates Dirham",
      "code": "AED"
    }
  },
  {
    "countryName": "Ecuador",
    "countryDialingCode": 593,
    "countryiso3CharCode": "ECU",
    "countryiso2CharCode": "EC",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Egypt",
    "countryDialingCode": 20,
    "countryiso3CharCode": "EGY",
    "countryiso2CharCode": "EG",
    "currency": {
      "description": "Egyptian Pound",
      "code": "EGP"
    }
  },
  {
    "countryName": "El Salvador",
    "countryDialingCode": 503,
    "countryiso3CharCode": "SLV",
    "countryiso2CharCode": "SV",
    "currency": {
      "description": "El Salvador Colon",
      "code": "SVC"
    }
  },
  {
    "countryName": "Equatorial Guinea",
    "countryDialingCode": 240,
    "countryiso3CharCode": "GNQ",
    "countryiso2CharCode": "GQ",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Eritrea",
    "countryDialingCode": 291,
    "countryiso3CharCode": "ERI",
    "countryiso2CharCode": "ER",
    "currency": {
      "description": "ERN",
      "code": "ERN"
    }
  },
  {
    "countryName": "Estonia",
    "countryDialingCode": 372,
    "countryiso3CharCode": "EST",
    "countryiso2CharCode": "EE",
    "currency": {
      "description": "Estonian Kroon",
      "code": "EEK"
    }
  },
  {
    "countryName": "Ethiopia",
    "countryDialingCode": 251,
    "countryiso3CharCode": "ETH",
    "countryiso2CharCode": "ET",
    "currency": {
      "description": "Ethiopia Birr",
      "code": "ETB"
    }
  },
  {
    "countryName": "Falkland Islands (Malvinas)",
    "countryDialingCode": 500,
    "countryiso3CharCode": "FLK",
    "countryiso2CharCode": "FK",
    "currency": {
      "description": "Falkland Islands Pound",
      "code": "FKP"
    }
  },
  {
    "countryName": "Faroe Islands",
    "countryDialingCode": 298,
    "countryiso3CharCode": "FRO",
    "countryiso2CharCode": "FO",
    "currency": {
      "description": "Danish Krone",
      "code": "DKK"
    }
  },
  {
    "countryName": "Fiji",
    "countryDialingCode": 679,
    "countryiso3CharCode": "FJI",
    "countryiso2CharCode": "FJ",
    "currency": {
      "description": "Fiji Dollar",
      "code": "FJD"
    }
  },
  {
    "countryName": "Finland",
    "countryDialingCode": 358,
    "countryiso3CharCode": "FIN",
    "countryiso2CharCode": "FI",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "France",
    "countryDialingCode": 33,
    "countryiso3CharCode": "FRA",
    "countryiso2CharCode": "FR",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "French Guiana",
    "countryDialingCode": 594,
    "countryiso3CharCode": "GUF",
    "countryiso2CharCode": "GF",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "French Polynesia",
    "countryDialingCode": 689,
    "countryiso3CharCode": "PYF",
    "countryiso2CharCode": "PF",
    "currency": {
      "description": "Polynesian Franc",
      "code": "XPF"
    }
  },
  {
    "countryName": "French Southern Territories",
    "countryDialingCode": 228,
    "countryiso3CharCode": "ATF",
    "countryiso2CharCode": "TF"
  },
  {
    "countryName": "Gabon",
    "countryDialingCode": 241,
    "countryiso3CharCode": "GAB",
    "countryiso2CharCode": "GA",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Gambia",
    "countryDialingCode": 220,
    "countryiso3CharCode": "GMB",
    "countryiso2CharCode": "GM",
    "currency": {
      "description": "Gambian Dalasi",
      "code": "GMD"
    }
  },
  {
    "countryName": "Georgia",
    "countryDialingCode": 995,
    "countryiso3CharCode": "GEO",
    "countryiso2CharCode": "GE",
    "currency": {
      "description": "Georgian Lari",
      "code": "GEL"
    }
  },
  {
    "countryName": "Germany",
    "countryDialingCode": 49,
    "countryiso3CharCode": "DEU",
    "countryiso2CharCode": "DE",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Ghana",
    "countryDialingCode": 233,
    "countryiso3CharCode": "GHA",
    "countryiso2CharCode": "GH",
    "currency": {
      "description": "Ghana Cedi",
      "code": "GHS"
    }
  },
  {
    "countryName": "Gibraltar",
    "countryDialingCode": 350,
    "countryiso3CharCode": "GIB",
    "countryiso2CharCode": "GI",
    "currency": {
      "description": "Gibraltar Pound",
      "code": "GIP"
    }
  },
  {
    "countryName": "Greece",
    "countryDialingCode": 30,
    "countryiso3CharCode": "GRC",
    "countryiso2CharCode": "GR",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Greenland",
    "countryDialingCode": 299,
    "countryiso3CharCode": "GRL",
    "countryiso2CharCode": "GL",
    "currency": {
      "description": "Danish Krone",
      "code": "DKK"
    }
  },
  {
    "countryName": "Grenada",
    "countryDialingCode": 473,
    "countryiso3CharCode": "GRD",
    "countryiso2CharCode": "GD",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Guadeloupe",
    "countryDialingCode": 590,
    "countryiso3CharCode": "GLP",
    "countryiso2CharCode": "GP",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Guam",
    "countryDialingCode": 671,
    "countryiso3CharCode": "GUM",
    "countryiso2CharCode": "GU",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Guatemala",
    "countryDialingCode": 502,
    "countryiso3CharCode": "GTM",
    "countryiso2CharCode": "GT",
    "currency": {
      "description": "Guatemalan Quetzal",
      "code": "GTQ"
    }
  },
  {
    "countryName": "Guernsey",
    "countryDialingCode": 44,
    "countryiso3CharCode": "GGY",
    "countryiso2CharCode": "GG",
    "currency": {
      "description": "British Pound",
      "code": "GBP"
    }
  },
  {
    "countryName": "Guinea",
    "countryDialingCode": 224,
    "countryiso3CharCode": "GIN",
    "countryiso2CharCode": "GN",
    "currency": {
      "description": "Guinea Franc",
      "code": "GNF"
    }
  },
  {
    "countryName": "Guinea-Bissau",
    "countryDialingCode": 245,
    "countryiso3CharCode": "GNB",
    "countryiso2CharCode": "GW",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Guyana",
    "countryDialingCode": 592,
    "countryiso3CharCode": "GUY",
    "countryiso2CharCode": "GY",
    "currency": {
      "description": "Guyanan Dollar",
      "code": "GYD"
    }
  },
  {
    "countryName": "Haiti",
    "countryDialingCode": 509,
    "countryiso3CharCode": "HTI",
    "countryiso2CharCode": "HT",
    "currency": {
      "description": "Haitian Gourde",
      "code": "HTG"
    }
  },
  {
    "countryName": "Honduras",
    "countryDialingCode": 504,
    "countryiso3CharCode": "HND",
    "countryiso2CharCode": "HN",
    "currency": {
      "description": "Honduran Lempira",
      "code": "HNL"
    }
  },
  {
    "countryName": "Hong Kong",
    "countryDialingCode": 852,
    "countryiso3CharCode": "HKG",
    "countryiso2CharCode": "HK",
    "currency": {
      "description": "Hong Kong Dollar",
      "code": "HKD"
    }
  },
  {
    "countryName": "Hungary",
    "countryDialingCode": 36,
    "countryiso3CharCode": "HUN",
    "countryiso2CharCode": "HU",
    "currency": {
      "description": "Hungarian Forint",
      "code": "HUF"
    }
  },
  {
    "countryName": "Iceland",
    "countryDialingCode": 354,
    "countryiso3CharCode": "ISL",
    "countryiso2CharCode": "IS",
    "currency": {
      "description": "Iceland Krona",
      "code": "ISK"
    }
  },
  {
    "countryName": "India",
    "countryDialingCode": 91,
    "countryiso3CharCode": "IND",
    "countryiso2CharCode": "IN",
    "currency": {
      "description": "Indian Rupee",
      "code": "INR"
    }
  },
  {
    "countryName": "Indonesia",
    "countryDialingCode": 62,
    "countryiso3CharCode": "IDN",
    "countryiso2CharCode": "ID",
    "currency": {
      "description": "Indonesian Rupiah",
      "code": "IDR"
    }
  },
  {
    "countryName": "Iran, Islamic Republic Of",
    "countryDialingCode": 98,
    "countryiso3CharCode": "IRN",
    "countryiso2CharCode": "IR",
    "currency": {
      "description": "Iranian Rial",
      "code": "IRR"
    }
  },
  {
    "countryName": "Iraq",
    "countryDialingCode": 964,
    "countryiso3CharCode": "IRQ",
    "countryiso2CharCode": "IQ",
    "currency": {
      "description": "Iraqi Dinar",
      "code": "IQD"
    }
  },
  {
    "countryName": "Ireland",
    "countryDialingCode": 353,
    "countryiso3CharCode": "IRL",
    "countryiso2CharCode": "IE",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Isle Of Man",
    "countryDialingCode": 44,
    "countryiso3CharCode": "IMN",
    "countryiso2CharCode": "IM",
    "currency": {
      "description": "British Pound",
      "code": "GBP"
    }
  },
  {
    "countryName": "Israel",
    "countryDialingCode": 972,
    "countryiso3CharCode": "ISR",
    "countryiso2CharCode": "IL",
    "currency": {
      "description": "Israeli New Shekel",
      "code": "ILS"
    }
  },
  {
    "countryName": "Italy",
    "countryDialingCode": 39,
    "countryiso3CharCode": "ITA",
    "countryiso2CharCode": "IT",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Ivory Coast",
    "countryDialingCode": 225,
    "countryiso3CharCode": "CIV",
    "countryiso2CharCode": "CI",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Jamaica",
    "countryDialingCode": 876,
    "countryiso3CharCode": "JAM",
    "countryiso2CharCode": "JM",
    "currency": {
      "description": "Jamaican Dollar",
      "code": "JMD"
    }
  },
  {
    "countryName": "Japan",
    "countryDialingCode": 81,
    "countryiso3CharCode": "JPN",
    "countryiso2CharCode": "JP",
    "currency": {
      "description": "Japanese Yen",
      "code": "JPY"
    }
  },
  {
    "countryName": "Jersey",
    "countryDialingCode": 44,
    "countryiso3CharCode": "XJE",
    "countryiso2CharCode": "JE",
    "currency": {
      "description": "British Pound",
      "code": "GBP"
    }
  },
  {
    "countryName": "Jordan",
    "countryDialingCode": 962,
    "countryiso3CharCode": "JOR",
    "countryiso2CharCode": "JO",
    "currency": {
      "description": "Jordanian Dinar",
      "code": "JOD"
    }
  },
  {
    "countryName": "Kazakhstan",
    "countryDialingCode": 7312,
    "countryiso3CharCode": "KAZ",
    "countryiso2CharCode": "KZ",
    "currency": {
      "description": "Kazakhstan Tenge",
      "code": "KZT"
    }
  },
  {
    "countryName": "Kenya",
    "countryDialingCode": 254,
    "countryiso3CharCode": "KEN",
    "countryiso2CharCode": "KE",
    "currency": {
      "description": "Kenyan Schilling",
      "code": "KES"
    }
  },
  {
    "countryName": "Kiribati",
    "countryDialingCode": 686,
    "countryiso3CharCode": "KIR",
    "countryiso2CharCode": "KI",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Kuwait",
    "countryDialingCode": 965,
    "countryiso3CharCode": "KWT",
    "countryiso2CharCode": "KW",
    "currency": {
      "description": "Kuwaiti Dinar",
      "code": "KWD"
    }
  },
  {
    "countryName": "Kyrgyzstan",
    "countryDialingCode": 996,
    "countryiso3CharCode": "KGZ",
    "countryiso2CharCode": "KG",
    "currency": {
      "description": "Kyrgyzstanian Som",
      "code": "KGS"
    }
  },
  {
    "countryName": "Laos",
    "countryDialingCode": 856,
    "countryiso3CharCode": "LAO",
    "countryiso2CharCode": "LA",
    "currency": {
      "description": "Lao Kip",
      "code": "LAK"
    }
  },
  {
    "countryName": "Latvia",
    "countryDialingCode": 371,
    "countryiso3CharCode": "LVA",
    "countryiso2CharCode": "LV",
    "currency": {
      "description": "Latvian Lats",
      "code": "LVL"
    }
  },
  {
    "countryName": "Lebanon",
    "countryDialingCode": 961,
    "countryiso3CharCode": "LBN",
    "countryiso2CharCode": "LB",
    "currency": {
      "description": "Lebanese Pound",
      "code": "LBP"
    }
  },
  {
    "countryName": "Lesotho",
    "countryDialingCode": 266,
    "countryiso3CharCode": "LSO",
    "countryiso2CharCode": "LS",
    "currency": {
      "description": "Lesotho Loti",
      "code": "LSL"
    }
  },
  {
    "countryName": "Liberia",
    "countryDialingCode": 231,
    "countryiso3CharCode": "LBR",
    "countryiso2CharCode": "LR",
    "currency": {
      "description": "Liberian Dollar",
      "code": "LRD"
    }
  },
  {
    "countryName": "Libya",
    "countryDialingCode": 218,
    "countryiso3CharCode": "LBY",
    "countryiso2CharCode": "LY",
    "currency": {
      "description": "Libyan Dinar",
      "code": "LYD"
    }
  },
  {
    "countryName": "Liechtenstein",
    "countryDialingCode": 4175,
    "countryiso3CharCode": "LIE",
    "countryiso2CharCode": "LI",
    "currency": {
      "description": "Swiss Franc",
      "code": "CHF"
    }
  },
  {
    "countryName": "Lithuania",
    "countryDialingCode": 370,
    "countryiso3CharCode": "LTU",
    "countryiso2CharCode": "LT",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Luxembourg",
    "countryDialingCode": 352,
    "countryiso3CharCode": "LUX",
    "countryiso2CharCode": "LU",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Macau",
    "countryDialingCode": 853,
    "countryiso3CharCode": "MAC",
    "countryiso2CharCode": "MO",
    "currency": {
      "description": "Macau Pataca",
      "code": "MOP"
    }
  },
  {
    "countryName": "Macedonia",
    "countryDialingCode": 389,
    "countryiso3CharCode": "MKD",
    "countryiso2CharCode": "MK",
    "currency": {
      "description": "Macedonia Denar",
      "code": "MKD"
    }
  },
  {
    "countryName": "Madagascar",
    "countryDialingCode": 261,
    "countryiso3CharCode": "MDG",
    "countryiso2CharCode": "MG",
    "currency": {
      "description": "Malagasy Franc",
      "code": "MGF"
    }
  },
  {
    "countryName": "Malawi",
    "countryDialingCode": 265,
    "countryiso3CharCode": "MWI",
    "countryiso2CharCode": "MW",
    "currency": {
      "description": "Malawi Kwacha",
      "code": "MWK"
    }
  },
  {
    "countryName": "Malaysia",
    "countryDialingCode": 60,
    "countryiso3CharCode": "MYS",
    "countryiso2CharCode": "MY",
    "currency": {
      "description": "Malaysian Ringgit",
      "code": "MYR"
    }
  },
  {
    "countryName": "Maldives",
    "countryDialingCode": 960,
    "countryiso3CharCode": "MDV",
    "countryiso2CharCode": "MV",
    "currency": {
      "description": "Maldive Rufiyaa",
      "code": "MVR"
    }
  },
  {
    "countryName": "Mali",
    "countryDialingCode": 223,
    "countryiso3CharCode": "MLI",
    "countryiso2CharCode": "ML",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Malta",
    "countryDialingCode": 356,
    "countryiso3CharCode": "MLT",
    "countryiso2CharCode": "MT",
    "currency": {
      "description": "Maltese Lira",
      "code": "MTL"
    }
  },
  {
    "countryName": "Marshall Islands",
    "countryDialingCode": 692,
    "countryiso3CharCode": "MHL",
    "countryiso2CharCode": "MH",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Martinique",
    "countryDialingCode": 596,
    "countryiso3CharCode": "MTQ",
    "countryiso2CharCode": "MQ",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Mauritania",
    "countryDialingCode": 222,
    "countryiso3CharCode": "MRT",
    "countryiso2CharCode": "MR",
    "currency": {
      "description": "Mauritanian Ouguiya",
      "code": "MRO"
    }
  },
  {
    "countryName": "Mauritius",
    "countryDialingCode": 230,
    "countryiso3CharCode": "MUS",
    "countryiso2CharCode": "MU",
    "currency": {
      "description": "Mauritius Rupee",
      "code": "MUR"
    }
  },
  {
    "countryName": "Mayotte",
    "countryDialingCode": 2696,
    "countryiso3CharCode": "MYT",
    "countryiso2CharCode": "YT",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Mexico",
    "countryDialingCode": 52,
    "countryiso3CharCode": "MEX",
    "countryiso2CharCode": "MX",
    "currency": {
      "description": "Mexican Peso",
      "code": "MXN"
    }
  },
  {
    "countryName": "Micronesia",
    "countryDialingCode": 691,
    "countryiso3CharCode": "FSM",
    "countryiso2CharCode": "FM",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Moldova",
    "countryDialingCode": 373,
    "countryiso3CharCode": "MDA",
    "countryiso2CharCode": "MD",
    "currency": {
      "description": "Romanian Leu",
      "code": "MDL"
    }
  },
  {
    "countryName": "Monaco",
    "countryDialingCode": 377,
    "countryiso3CharCode": "MCO",
    "countryiso2CharCode": "MC",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Mongolia",
    "countryDialingCode": 976,
    "countryiso3CharCode": "MNG",
    "countryiso2CharCode": "MN",
    "currency": {
      "description": "Mongolian Tugrik",
      "code": "MNT"
    }
  },
  {
    "countryName": "Montenegro",
    "countryDialingCode": 382,
    "countryiso3CharCode": "MON",
    "countryiso2CharCode": "ME",
    "currency": {
      "description": "Yugoslav Dinar",
      "code": "YUM"
    }
  },
  {
    "countryName": "Montserrat",
    "countryDialingCode": 1664,
    "countryiso3CharCode": "MSR",
    "countryiso2CharCode": "MS",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Morocco",
    "countryDialingCode": 212,
    "countryiso3CharCode": "MAR",
    "countryiso2CharCode": "MA",
    "currency": {
      "description": "Moroccan Dirham",
      "code": "MAD"
    }
  },
  {
    "countryName": "Mozambique",
    "countryDialingCode": 258,
    "countryiso3CharCode": "MOZ",
    "countryiso2CharCode": "MZ",
    "currency": {
      "description": "Mozambique New Metical",
      "code": "MZN"
    }
  },
  {
    "countryName": "Myanmar (Burma)",
    "countryDialingCode": 95,
    "countryiso3CharCode": "BUR",
    "countryiso2CharCode": "MM",
    "currency": {
      "description": "Myanmar Kyat",
      "code": "MMK"
    }
  },
  {
    "countryName": "Namibia",
    "countryDialingCode": 264,
    "countryiso3CharCode": "NAM",
    "countryiso2CharCode": "NA",
    "currency": {
      "description": "Namibian Dollar",
      "code": "NAD"
    }
  },
  {
    "countryName": "Nauru",
    "countryDialingCode": 674,
    "countryiso3CharCode": "NRU",
    "countryiso2CharCode": "NR",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Nepal",
    "countryDialingCode": 977,
    "countryiso3CharCode": "NPL",
    "countryiso2CharCode": "NP",
    "currency": {
      "description": "Nepalese Rupee",
      "code": "NPR"
    }
  },
  {
    "countryName": "Netherlands",
    "countryDialingCode": 31,
    "countryiso3CharCode": "NLD",
    "countryiso2CharCode": "NL",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Netherlands Antilles",
    "countryDialingCode": 599,
    "countryiso3CharCode": "ANT",
    "countryiso2CharCode": "AN",
    "currency": {
      "description": "Netherlands Antillian Guilder",
      "code": "ANG"
    }
  },
  {
    "countryName": "New Caledonia",
    "countryDialingCode": 687,
    "countryiso3CharCode": "NCL",
    "countryiso2CharCode": "NC",
    "currency": {
      "description": "Polynesian Franc",
      "code": "XPF"
    }
  },
  {
    "countryName": "New Zealand",
    "countryDialingCode": 64,
    "countryiso3CharCode": "NZL",
    "countryiso2CharCode": "NZ",
    "currency": {
      "description": "New Zealand Dollar",
      "code": "NZD"
    }
  },
  {
    "countryName": "Nicaragua",
    "countryDialingCode": 505,
    "countryiso3CharCode": "NIC",
    "countryiso2CharCode": "NI",
    "currency": {
      "description": "Nicaraguan Cordoba Oro",
      "code": "NIO"
    }
  },
  {
    "countryName": "Niger",
    "countryDialingCode": 227,
    "countryiso3CharCode": "NER",
    "countryiso2CharCode": "NE",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Nigeria",
    "countryDialingCode": 234,
    "countryiso3CharCode": "NGA",
    "countryiso2CharCode": "NG",
    "currency": {
      "description": "Nigerian Naira",
      "code": "NGN"
    }
  },
  {
    "countryName": "Niue",
    "countryDialingCode": 683,
    "countryiso3CharCode": "NIU",
    "countryiso2CharCode": "NU",
    "currency": {
      "description": "New Zealand Dollar",
      "code": "NZD"
    }
  },
  {
    "countryName": "Norfolk Island",
    "countryDialingCode": 6723,
    "countryiso3CharCode": "NFK",
    "countryiso2CharCode": "NF",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "North Korea",
    "countryDialingCode": 850,
    "countryiso3CharCode": "PRK",
    "countryiso2CharCode": "KP",
    "currency": {
      "description": "North Korean Won",
      "code": "KPW"
    }
  },
  {
    "countryName": "Northern Mariana Islands",
    "countryDialingCode": 670,
    "countryiso3CharCode": "MNP",
    "countryiso2CharCode": "MP",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Norway",
    "countryDialingCode": 47,
    "countryiso3CharCode": "NOR",
    "countryiso2CharCode": "NO",
    "currency": {
      "description": "Norwegian Krone",
      "code": "NOK"
    }
  },
  {
    "countryName": "Oman",
    "countryDialingCode": 968,
    "countryiso3CharCode": "OMN",
    "countryiso2CharCode": "OM",
    "currency": {
      "description": "Omani Rial",
      "code": "OMR"
    }
  },
  {
    "countryName": "Pakistan",
    "countryDialingCode": 92,
    "countryiso3CharCode": "PAK",
    "countryiso2CharCode": "PK",
    "currency": {
      "description": "Pakistan Rupee",
      "code": "PKR"
    }
  },
  {
    "countryName": "Palau",
    "countryDialingCode": 680,
    "countryiso3CharCode": "PLW",
    "countryiso2CharCode": "PW",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Panama",
    "countryDialingCode": 507,
    "countryiso3CharCode": "PAN",
    "countryiso2CharCode": "PA",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Papua New Guinea",
    "countryDialingCode": 675,
    "countryiso3CharCode": "PNG",
    "countryiso2CharCode": "PG",
    "currency": {
      "description": "Papua New Guinea Kina",
      "code": "PGK"
    }
  },
  {
    "countryName": "Paraguay",
    "countryDialingCode": 595,
    "countryiso3CharCode": "PRY",
    "countryiso2CharCode": "PY",
    "currency": {
      "description": "Paraguay Guarani",
      "code": "PYG"
    }
  },
  {
    "countryName": "Peru",
    "countryDialingCode": 51,
    "countryiso3CharCode": "PER",
    "countryiso2CharCode": "PE",
    "currency": {
      "description": "Peruvian Nuevo Sol",
      "code": "PEN"
    }
  },
  {
    "countryName": "Philippines",
    "countryDialingCode": 63,
    "countryiso3CharCode": "PHL",
    "countryiso2CharCode": "PH",
    "currency": {
      "description": "Philippine Peso",
      "code": "PHP"
    }
  },
  {
    "countryName": "Poland",
    "countryDialingCode": 48,
    "countryiso3CharCode": "POL",
    "countryiso2CharCode": "PL",
    "currency": {
      "description": "Polish Zloty",
      "code": "PLN"
    }
  },
  {
    "countryName": "Portugal",
    "countryDialingCode": 351,
    "countryiso3CharCode": "PRT",
    "countryiso2CharCode": "PT",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Puerto Rico",
    "countryDialingCode": 787,
    "countryiso3CharCode": "PRI",
    "countryiso2CharCode": "PR",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Qatar",
    "countryDialingCode": 974,
    "countryiso3CharCode": "QAT",
    "countryiso2CharCode": "QA",
    "currency": {
      "description": "Qatari Rial",
      "code": "QAR"
    }
  },
  {
    "countryName": "Reunion",
    "countryDialingCode": 262,
    "countryiso3CharCode": "REU",
    "countryiso2CharCode": "RE",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Romania",
    "countryDialingCode": 40,
    "countryiso3CharCode": "ROU",
    "countryiso2CharCode": "RO",
    "currency": {
      "description": "New Romanian Lei",
      "code": "RON"
    }
  },
  {
    "countryName": "Russia",
    "countryDialingCode": 7,
    "countryiso3CharCode": "RUS",
    "countryiso2CharCode": "RU",
    "currency": {
      "description": "Russian Ruble",
      "code": "RUB"
    }
  },
  {
    "countryName": "Rwanda",
    "countryDialingCode": 250,
    "countryiso3CharCode": "RWA",
    "countryiso2CharCode": "RW",
    "currency": {
      "description": "Rwanda Franc",
      "code": "RWF"
    }
  },
  {
    "countryName": "San Marino",
    "countryDialingCode": 378,
    "countryiso3CharCode": "SMR",
    "countryiso2CharCode": "SM",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Sao Tome and Principe",
    "countryDialingCode": 239,
    "countryiso3CharCode": "STP",
    "countryiso2CharCode": "ST",
    "currency": {
      "description": "Sao Tome and Principe Dobra",
      "code": "STD"
    }
  },
  {
    "countryName": "Saudi Arabia",
    "countryDialingCode": 966,
    "countryiso3CharCode": "SAU",
    "countryiso2CharCode": "SA",
    "currency": {
      "description": "Saudi Riyal",
      "code": "SAR"
    }
  },
  {
    "countryName": "Senegal",
    "countryDialingCode": 221,
    "countryiso3CharCode": "SEN",
    "countryiso2CharCode": "SN",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Republic of Serbia",
    "countryDialingCode": 381,
    "countryiso3CharCode": "SRB",
    "countryiso2CharCode": "RS",
    "currency": {
      "description": "New Serbian Dinar",
      "code": "RSD"
    }
  },
  {
    "countryName": "Seychelles",
    "countryDialingCode": 248,
    "countryiso3CharCode": "SYC",
    "countryiso2CharCode": "SC",
    "currency": {
      "description": "Seychelles Rupee",
      "code": "SCR"
    }
  },
  {
    "countryName": "Sierra Leone",
    "countryDialingCode": 232,
    "countryiso3CharCode": "SLE",
    "countryiso2CharCode": "SL",
    "currency": {
      "description": "Sierra Leone Leone",
      "code": "SLL"
    }
  },
  {
    "countryName": "Singapore",
    "countryDialingCode": 65,
    "countryiso3CharCode": "SGP",
    "countryiso2CharCode": "SG",
    "currency": {
      "description": "Singapore Dollar",
      "code": "SGD"
    }
  },
  {
    "countryName": "Slovakia",
    "countryDialingCode": 421,
    "countryiso3CharCode": "SVK",
    "countryiso2CharCode": "SK",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Slovenia",
    "countryDialingCode": 386,
    "countryiso3CharCode": "SVN",
    "countryiso2CharCode": "SI",
    "currency": {
      "description": "Slovenian Tolar",
      "code": "SIT"
    }
  },
  {
    "countryName": "Solomon Islands",
    "countryDialingCode": 677,
    "countryiso3CharCode": "SLB",
    "countryiso2CharCode": "SB",
    "currency": {
      "description": "Solomon Islands Dollar",
      "code": "SBD"
    }
  },
  {
    "countryName": "Somalia",
    "countryDialingCode": 252,
    "countryiso3CharCode": "SOM",
    "countryiso2CharCode": "SO",
    "currency": {
      "description": "Somali Schilling",
      "code": "SOS"
    }
  },
  {
    "countryName": "South Africa",
    "countryDialingCode": 27,
    "countryiso3CharCode": "ZAF",
    "countryiso2CharCode": "ZA",
    "currency": {
      "description": "South African Rand",
      "code": "ZAR"
    }
  },
  {
    "countryName": "South Georgia and The South Sandwich Islands",
    "countryDialingCode": 500,
    "countryiso3CharCode": "SGS",
    "countryiso2CharCode": "GS"
  },
  {
    "countryName": "South Korea",
    "countryDialingCode": 82,
    "countryiso3CharCode": "KOR",
    "countryiso2CharCode": "KR",
    "currency": {
      "description": "Korean Won",
      "code": "KRW"
    }
  },
  {
    "countryName": "Spain",
    "countryDialingCode": 34,
    "countryiso3CharCode": "ESP",
    "countryiso2CharCode": "ES",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Sri Lanka",
    "countryDialingCode": 94,
    "countryiso3CharCode": "LKA",
    "countryiso2CharCode": "LK",
    "currency": {
      "description": "Sri Lanka Rupee",
      "code": "LKR"
    }
  },
  {
    "countryName": "Saint Helena",
    "countryDialingCode": 290,
    "countryiso3CharCode": "SHN",
    "countryiso2CharCode": "SH",
    "currency": {
      "description": "St. Helena Pound",
      "code": "SHP"
    }
  },
  {
    "countryName": "Saint Kitts and Nevis",
    "countryDialingCode": 869,
    "countryiso3CharCode": "KNA",
    "countryiso2CharCode": "KN",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Saint Lucia",
    "countryDialingCode": 758,
    "countryiso3CharCode": "LCA",
    "countryiso2CharCode": "LC",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Saint Pierre and Miquelon",
    "countryDialingCode": 508,
    "countryiso3CharCode": "SPM",
    "countryiso2CharCode": "PM",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "St. Vincent and The Grenadines",
    "countryDialingCode": 784,
    "countryiso3CharCode": "VCT",
    "countryiso2CharCode": "VC",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Sudan",
    "countryDialingCode": 249,
    "countryiso3CharCode": "SDN",
    "countryiso2CharCode": "SD",
    "currency": {
      "description": "Sudanese Dinar",
      "code": "SDD"
    }
  },
  {
    "countryName": "Suriname",
    "countryDialingCode": 597,
    "countryiso3CharCode": "SUR",
    "countryiso2CharCode": "SR",
    "currency": {
      "description": "SRG",
      "code": "SRG"
    }
  },
  {
    "countryName": "Svalbard and Jan Mayen",
    "countryDialingCode": 47,
    "countryiso3CharCode": "SVD",
    "countryiso2CharCode": "SJ",
    "currency": {
      "description": "Norwegian Krone",
      "code": "NOK"
    }
  },
  {
    "countryName": "Swaziland",
    "countryDialingCode": 268,
    "countryiso3CharCode": "SWZ",
    "countryiso2CharCode": "SZ",
    "currency": {
      "description": "Swaziland Lilangeni",
      "code": "SZL"
    }
  },
  {
    "countryName": "Sweden",
    "countryDialingCode": 46,
    "countryiso3CharCode": "SWE",
    "countryiso2CharCode": "SE",
    "currency": {
      "description": "Swedish Krona",
      "code": "SEK"
    }
  },
  {
    "countryName": "Switzerland",
    "countryDialingCode": 41,
    "countryiso3CharCode": "CHE",
    "countryiso2CharCode": "CH",
    "currency": {
      "description": "Swiss Franc",
      "code": "CHF"
    }
  },
  {
    "countryName": "Syria",
    "countryDialingCode": 963,
    "countryiso3CharCode": "SYR",
    "countryiso2CharCode": "SY",
    "currency": {
      "description": "Syrian Pound",
      "code": "SYP"
    }
  },
  {
    "countryName": "Taiwan",
    "countryDialingCode": 886,
    "countryiso3CharCode": "TWN",
    "countryiso2CharCode": "TW",
    "currency": {
      "description": "Taiwanese Dollar",
      "code": "TWD"
    }
  },
  {
    "countryName": "Tajikistan",
    "countryDialingCode": 992,
    "countryiso3CharCode": "TJK",
    "countryiso2CharCode": "TJ",
    "currency": {
      "description": "Tajikistani Somoni",
      "code": "TJS"
    }
  },
  {
    "countryName": "Tanzania",
    "countryDialingCode": 255,
    "countryiso3CharCode": "TZA",
    "countryiso2CharCode": "TZ",
    "currency": {
      "description": "Tanzanian Schilling",
      "code": "TZS"
    }
  },
  {
    "countryName": "Thailand",
    "countryDialingCode": 66,
    "countryiso3CharCode": "THA",
    "countryiso2CharCode": "TH",
    "currency": {
      "description": "Thai Baht",
      "code": "THB"
    }
  },
  {
    "countryName": "Togo",
    "countryDialingCode": 228,
    "countryiso3CharCode": "TGO",
    "countryiso2CharCode": "TG",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Tokelau",
    "countryDialingCode": 690,
    "countryiso3CharCode": "TKL",
    "countryiso2CharCode": "TK",
    "currency": {
      "description": "New Zealand Dollar",
      "code": "NZD"
    }
  },
  {
    "countryName": "Tonga",
    "countryDialingCode": 676,
    "countryiso3CharCode": "TON",
    "countryiso2CharCode": "TO",
    "currency": {
      "description": "Tongan Pa'anga",
      "code": "TOP"
    }
  },
  {
    "countryName": "Trinidad and Tobago",
    "countryDialingCode": 868,
    "countryiso3CharCode": "TTO",
    "countryiso2CharCode": "TT",
    "currency": {
      "description": "Trinidad and Tobago Dollar",
      "code": "TTD"
    }
  },
  {
    "countryName": "Tunisia",
    "countryDialingCode": 216,
    "countryiso3CharCode": "TUN",
    "countryiso2CharCode": "TN",
    "currency": {
      "description": "Tunisian Dinar",
      "code": "TND"
    }
  },
  {
    "countryName": "Turkey",
    "countryDialingCode": 90,
    "countryiso3CharCode": "TUR",
    "countryiso2CharCode": "TR",
    "currency": {
      "description": "Turkish Lira",
      "code": "TRY"
    }
  },
  {
    "countryName": "Turkmenistan",
    "countryDialingCode": 993,
    "countryiso3CharCode": "TKM",
    "countryiso2CharCode": "TM",
    "currency": {
      "description": "Turkmen Manat",
      "code": "TMM"
    }
  },
  {
    "countryName": "Turks and Caicos Islands",
    "countryDialingCode": 1649,
    "countryiso3CharCode": "TCA",
    "countryiso2CharCode": "TC",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Tuvalu",
    "countryDialingCode": 688,
    "countryiso3CharCode": "TUV",
    "countryiso2CharCode": "TV",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Uganda",
    "countryDialingCode": 256,
    "countryiso3CharCode": "UGA",
    "countryiso2CharCode": "UG",
    "currency": {
      "description": "Uganda Shilling",
      "code": "UGX"
    }
  },
  {
    "countryName": "Ukraine",
    "countryDialingCode": 380,
    "countryiso3CharCode": "UKR",
    "countryiso2CharCode": "UA",
    "currency": {
      "description": "Ukraine Hryvnia",
      "code": "UAH"
    }
  },
  {
    "countryName": "United Arab Emirates",
    "countryDialingCode": 971,
    "countryiso3CharCode": "ARE",
    "countryiso2CharCode": "AE",
    "currency": {
      "description": "United Arab Emirates Dirham",
      "code": "AED"
    }
  },
  {
    "countryName": "United Kingdom",
    "countryDialingCode": 44,
    "countryiso3CharCode": "GBR",
    "countryiso2CharCode": "GB",
    "currency": {
      "description": "British Pound",
      "code": "GBP"
    }
  },
  {
    "countryName": "United States",
    "countryDialingCode": 1,
    "countryiso3CharCode": "USA",
    "countryiso2CharCode": "US",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Uruguay",
    "countryDialingCode": 598,
    "countryiso3CharCode": "URY",
    "countryiso2CharCode": "UY",
    "currency": {
      "description": "Peso Uruguayo",
      "code": "UYU"
    }
  },
  {
    "countryName": "Uzbekistan",
    "countryDialingCode": 998,
    "countryiso3CharCode": "UZB",
    "countryiso2CharCode": "UZ",
    "currency": {
      "description": "Uzbekistan Sum",
      "code": "UZS"
    }
  },
  {
    "countryName": "Vanuatu",
    "countryDialingCode": 678,
    "countryiso3CharCode": "VUT",
    "countryiso2CharCode": "VU",
    "currency": {
      "description": "Vanuatu Vatu",
      "code": "VUV"
    }
  },
  {
    "countryName": "Vatican City",
    "countryDialingCode": 379,
    "countryiso3CharCode": "VAT",
    "countryiso2CharCode": "VA",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Venezuela",
    "countryDialingCode": 58,
    "countryiso3CharCode": "VEN",
    "countryiso2CharCode": "VE",
    "currency": {
      "description": "Venezuelan Bolívar Soberano",
      "code": "VES"
    }
  },
  {
    "countryName": "Vietnam",
    "countryDialingCode": 84,
    "countryiso3CharCode": "VNM",
    "countryiso2CharCode": "VN",
    "currency": {
      "description": "Vietnamese Dong",
      "code": "VND"
    }
  },
  {
    "countryName": "U.S. Virgin Islands",
    "countryDialingCode": 340,
    "countryiso3CharCode": "VIR",
    "countryiso2CharCode": "VI"
  },
  {
    "countryName": "Wallis and Futuna",
    "countryDialingCode": 681,
    "countryiso3CharCode": "WLF",
    "countryiso2CharCode": "WF",
    "currency": {
      "description": "Polynesian Franc",
      "code": "XPF"
    }
  },
  {
    "countryName": "Palestinian Territory, Occupied",
    "countryDialingCode": 970,
    "countryiso3CharCode": "WBN",
    "countryiso2CharCode": "PS",
    "currency": {
      "description": "Israeli New Shekel",
      "code": "ILS"
    }
  },
  {
    "countryName": "Western Sahara",
    "countryDialingCode": 125,
    "countryiso3CharCode": "ESH",
    "countryiso2CharCode": "EH",
    "currency": {
      "description": "Moroccan Dirham",
      "code": "MAD"
    }
  },
  {
    "countryName": "Western Samoa",
    "countryDialingCode": 685,
    "countryiso3CharCode": "ZZZ",
    "countryiso2CharCode": "ZZ",
    "currency": {
      "description": "Samoan Tala",
      "code": "WST"
    }
  },
  {
    "countryName": "Yemen",
    "countryDialingCode": 967,
    "countryiso3CharCode": "YEM",
    "countryiso2CharCode": "YE",
    "currency": {
      "description": "Yemeni Rial",
      "code": "YER"
    }
  },
  {
    "countryName": "Yugoslavia",
    "countryDialingCode": 38,
    "countryiso3CharCode": "YUG",
    "countryiso2CharCode": "YU",
    "currency": {
      "description": "Yugoslav Dinar",
      "code": "YUM"
    }
  },
  {
    "countryName": "Zambia",
    "countryDialingCode": 260,
    "countryiso3CharCode": "ZMB",
    "countryiso2CharCode": "ZM",
    "currency": {
      "description": "Zambian Kwacha",
      "code": "ZMK"
    }
  },
  {
    "countryName": "Zimbabwe",
    "countryDialingCode": 263,
    "countryiso3CharCode": "ZWE",
    "countryiso2CharCode": "ZW",
    "currency": {
      "description": "Zimbabwe Dollar",
      "code": "ZWD"
    }
  },
  {
    "countryName": "Aland Islands",
    "countryDialingCode": 358,
    "countryiso3CharCode": "ALA",
    "countryiso2CharCode": "AX"
  },
  {
    "countryName": "Bonaire, St. Eustatius and Saba",
    "countryDialingCode": 5993,
    "countryiso3CharCode": "BES",
    "countryiso2CharCode": "BQ"
  },
  {
    "countryName": "Curacao",
    "countryDialingCode": 599,
    "countryiso3CharCode": "CUW",
    "countryiso2CharCode": "CW"
  },
  {
    "countryName": "Saint Maarten",
    "countryDialingCode": 721,
    "countryiso3CharCode": "SXM",
    "countryiso2CharCode": "SX"
  },
  {
    "countryName": "South Sudan",
    "countryDialingCode": 211,
    "countryiso3CharCode": "SSD",
    "countryiso2CharCode": "SS"
  },
  {
    "countryName": "St. Barthelemy",
    "countryDialingCode": 590,
    "countryiso3CharCode": "BLM",
    "countryiso2CharCode": "BL"
  },
  {
    "countryName": "St. Martin",
    "countryDialingCode": 721,
    "countryiso3CharCode": "MAF",
    "countryiso2CharCode": "MF"
  },
  {
    "countryName": "Timor-Leste",
    "countryDialingCode": 670,
    "countryiso3CharCode": "TLS",
    "countryiso2CharCode": "TL"
  },
  {
    "countryName": "Republic of Kosovo",
    "countryDialingCode": 381,
    "countryiso3CharCode": "UNK",
    "countryiso2CharCode": "XK",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  }
]

   
export const timelineData  =
{
   
    "timeLines": [
      {
        "date": startDate,
        "description": "This date is the day your company told us you begin working.",
        "source": "Job Start Date"
      },
      {
        "date": currentDate,
        "description": "This is the date of your policy call with your consultant.",
        "source": "Schedule Policy Call"
      },
      {
        "description": "This is the date on which your policy call with your consultant was completed.",
        "source": "Policy Call Completed"
      }
    ],
    "count": 3
  }

export const benefitsData : BenefitsData  =
{
  "availableBenefits": [
    {
      "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
      "productSpecificationReference": "PRD-HmPrchReimb",
      "displayName": "Home Purchase Reimbursement",
      "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
      "category": "After You Arrive",
      "points": 5
    },
    {
      "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Cash Out",
      "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
      "category": "Allowances",
      "points": 0,
      "tierConfigs": [
        {
          "maxSelection": 2,
          "pointValue": 250,
          "minRange": 1,
          "maxRange": 2
        },
        {
          "maxSelection": 3,
          "pointValue": 300,
          "minRange": 3,
          "maxRange": 5
        },
        {
          "maxSelection": 4,
          "pointValue": 100,
          "minRange": 6,
          "maxRange": 9
        }
      ]
    },
    {
      "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
      "productSpecificationReference": "PRD-HmFndng",
      "displayName": "Home Finding - 6 days/5 nights",
      "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
      "category": "Before you Move",
      "points": 3
    },
    {
      "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
      "productSpecificationReference": "PRD-BrkrFndrFee",
      "displayName": "Rental Assistance and Finder's Fee",
      "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
      "category": "After You Arrive",
      "points": 0,
      "rangeMin": 1,
      "rangeMax": 12,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500
    },
    {
      "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
      "productSpecificationReference": "PRD-SlfMv",
      "displayName": "Self-organized Move",
      "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
      "category": "Shipping",
      "points": 3,
      "exclusions": [
        "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
        "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
        "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
        "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
        "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
      ]
    },
    {
      "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
      "productSpecificationReference": "PRD-RentalSvcs",
      "displayName": "Full Day Rental Services",
      "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
      "category": "After You Arrive",
      "points": 4,
      "exclusions": [
        "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
      ]
    },
    {
      "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
      "productSpecificationReference": "PRD-RentalSvcs",
      "displayName": "Half Day Rental Services",
      "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
      "category": "After You Arrive",
      "points": 2,
      "exclusions": [
        "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
      ]
    },
    {
      "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
      "productSpecificationReference": "PRD-ReturnTrpBndl",
      "displayName": "Return Trip Home - Total 3 trips",
      "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
      "category": "Travel and Transport",
      "points": 3,
      "maxMul": 3
    },
    {
      "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
      "productSpecificationReference": "PRD-TmpHsng",
      "displayName": "Temporary Living Accommodations - up to 120 additional days",
      "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
      "category": "After You Arrive",
      "points": 0,
      "rangeMin": 15,
      "rangeMax": 150,
      "rangeIncrementValue": 15,
      "rangeIncrementUnit": "Days",
      "pointsPerIncrement": 4
    },
    {
      "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
      "productSpecificationReference": "PRD-HmSlReimb",
      "displayName": "Home Sale Reimbursement",
      "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
      "category": "Before You Move",
      "points": 0,
      "rangeMin": 1,
      "rangeMax": 15,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500
    },
    {
      "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
      "productSpecificationReference": "PRD-DupHsngBndl",
      "displayName": "Duplicate Housing - Total 3 months",
      "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
      "category": "After you Arrive",
      "points": 4,
      "maxMul": 3
    },
    {
      "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
      "productSpecificationReference": "PRD-SlfStrg",
      "displayName": "Self-organized Storage",
      "description": "Reimbursement of self storage for 30 days.",
      "category": "Shipping",
      "points": 3,
      "exclusions": [
        "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
        "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
      ]
    },
    {
      "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
      "productSpecificationReference": "PRD-FrnRntl",
      "displayName": "Furniture Rental",
      "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
      "category": "After You Arrive",
      "points": 0,
      "rangeMin": 1,
      "rangeMax": 3,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "Number of Months",
      "pointsPerIncrement": 4
    },
    {
      "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
      "productSpecificationReference": "PRD-CarRntl",
      "displayName": "Car Rental",
      "description": "Reimbursement for economy class car rental including child seats, if needed.",
      "category": "Travel and Transport",
      "points": 3
    },
    {
      "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
      "productSpecificationReference": "PRD-VhclShpmtBndl",
      "displayName": "Automobile Shipment - up to 3 automobiles",
      "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
      "category": "Shipping",
      "points": 4,
      "maxMul": 3
    },
    {
      "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
      "productSpecificationReference": "PRD-SpouseCareerSupp",
      "displayName": "Spouse/Partner Career Support",
      "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
      "category": "After You Arrive",
      "points": 4
    },
    {
      "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
      "productSpecificationReference": "PRD-PetTrnsprtBndl",
      "displayName": "Pet Transportation Service - Total 2 Pets",
      "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
      "category": "Shipping",
      "points": 4,
      "maxMul": 2
    },
    {
      "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
      "productSpecificationReference": "PRD-StrgInTrnst",
      "displayName": "Storage in Transit - Up To 180 Days",
      "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
      "category": "Shipping",
      "points": 0,
      "rangeMin": 15,
      "rangeMax": 180,
      "rangeIncrementValue": 15,
      "rangeIncrementUnit": "Days",
      "pointsPerIncrement": 2,
      "exclusions": [
        "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
      ],
      "prerequisites": [
        "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
        "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
      ]
    },
    {
      "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
      "productSpecificationReference": "PRD-LossOnSale",
      "displayName": "Loss on Sale",
      "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
      "category": "Before You Move",
      "points": 3
    }
  ],
  "selectedBenefits": [],
  "confirmedBenefits": [
    {
      "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Cost of Living Allowance ",
      "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
      "category": "After You Arrive",
      "points": "Guaranteed",
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
            "reference": "ATTR-BBTsks1",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25d110b0b0009db444e",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Relocation Allowance",
      "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
      "category": "Allowances",
      "points": "Guaranteed",
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
            "reference": "ATTR-BBTsks1",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25dbd79ad0009c42507",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
      "productSpecificationReference": "PRD-DscrdDnt",
      "displayName": "Household Goods - Pre-Move Discard & Donate",
      "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
      "category": "Shipping",
      "points": "Guaranteed",
      "exclusions": [
        "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
      ],
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
            "reference": "ATTR-BBTsks1",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25dd25d320008a52a8c",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Household Goods - Professional Move / Cartus Insured",
      "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
      "category": "Shipping",
      "points": 3,
      "exclusions": [
        "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
      ],
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
            "reference": "ATTR-BBTsks1",
            "status": false
          },
          {
            "displayName": "Task 2",
            "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
            "reference": "ATTR-BBTsks2",
            "status": false
          },

        ],
        "allTaskComplete": false,
        "allHighValueTaskOptOut": false,
        "allHighValueTaskComplete": false
      },
      "productId": "6059b2f6faf8060008cec28d",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
      "productSpecificationReference": "PRD-TmpHsng",
      "displayName": "Temporary Living Accommodations - 30 Days",
      "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
      "category": "After You Arrive",
      "points": "Guaranteed",
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
            "reference": "ATTR-BBTsks1",
            "status": false
          },
          {
            "displayName": "Task 2",
            "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
            "reference": "ATTR-BBTsks2",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25ddf5d790008643c21",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
      "productSpecificationReference": "PRD-TmpHsng",
      "displayName": "Temporary Living Accommodations - Pre Move",
      "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
      "category": "Before You Move",
      "points": "Guaranteed",
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
            "reference": "ATTR-BBTsks1",
            "status": false
          },
          {
            "displayName": "Task 2",
            "description": "Please contact your Cartus Consultant if your dates need to be changed.",
            "reference": "ATTR-BBTsks2",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25db631d70009eb62e8",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
      "productSpecificationReference": "PRD-PlcyCnsl",
      "displayName": "Cartus Counseling and Administration",
      "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
      "category": "Before You Move",
      "points": "Guaranteed",
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
            "reference": "ATTR-BBTsks1",
            "status": false
          },
          {
            "displayName": "Task 2",
            "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
            "reference": "ATTR-BBTsks2",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25d577f500008c913ca",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
      "productSpecificationReference": "PRD-ReloAcctng",
      "displayName": "Expense Management",
      "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
      "category": "Before You Move",
      "points": "Guaranteed",
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "When CartusOnline is available, please set up your banking information.",
            "reference": "ATTR-BBTsks1",
            "status": false
          },
          {
            "displayName": "Task 2",
            "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
            "reference": "ATTR-BBTsks2",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25da3d6af00095306f1",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
      "productSpecificationReference": "PRD-FnlTrnsprt",
      "displayName": "Final Transportation to New Location",
      "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
      "category": "Travel and Transport",
      "points": "Guaranteed",
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
            "reference": "ATTR-BBTsks1",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25d8bb7310009bbfe8e",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
      "productSpecificationReference": "PRD-HomeSale",
      "displayName": "Home Sale Buyer Value & Marketing Assistance",
      "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
      "category": "Before You Move",
      "points": "Guaranteed",
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
            "reference": "ATTR-BBTsks1",
            "status": false
          },
          {
            "displayName": "Task 2",
            "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
            "reference": "ATTR-BBTsks2",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25ffaf8060008cec284",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
      "productSpecificationReference": "PRD-HomeSale",
      "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
      "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
      "category": "Before You Move",
      "points": "Guaranteed",
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
            "reference": "ATTR-BBTsks1",
            "status": false
          },
          {
            "displayName": "Task 2",
            "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
            "reference": "ATTR-BBTsks2",
            "status": false
          },
          {
            "displayName": "Task 3",
            "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
            "reference": "ATTR-BBTsks3",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25fe2ce7a00083b9831",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
      "productSpecificationReference": "PRD-StrgInTrnst",
      "displayName": "Household Goods - Storage 1st 30 Days",
      "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
      "category": "shipping",
      "points": "Guaranteed",
      "exclusions": [
        "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
        "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
      ],
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
            "reference": "ATTR-BBTsks1",
            "status": true
          }
        ],
        "allTaskComplete": true
      },
      "productId": "6059b25da1f96500083dda83",
      "confirmedDate": "2021-03-23"
    },
    {
      "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
      "productSpecificationReference": "PRD-HomeSaleIncntv",
      "displayName": "Home Sale Incentive ",
      "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
      "category": "Before You Move",
      "points": "Guaranteed",
      "prerequisites": [
        "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
      ],
      "taskInfo": {
        "tasks": [
          {
            "displayName": "Task 1",
            "description": "Your Cartus Consultant will review your company's program with you.",
            "reference": "ATTR-BBTsks1",
            "status": false
          }
        ],
        "allTaskComplete": false
      },
      "productId": "6059b25defa96f00096febee",
      "confirmedDate": "2021-03-23"
    }
  ],
  "points": 80
}

  export const taskInfo = {
    "tasks":[
       {
          "displayName":"Task 1",
          "description":"Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference":"ATTR-BBTsks1",
          "status":false
       },
       {
          "displayName":"Task 2",
          "description":"You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference":"ATTR-BBTsks2",
          "status":false
       }
    ],
    "allTaskComplete":false
 }

 export const newBBData : any = 
{
  "BBData": [
    {
      "orderRequestDetails": [
          {
              "orderRequestId": "60124a5de1352200098cfef0",
              "nameDetails": {
                  "title": "Dr.",
                  "firstName": "Robert",
                  "lastName": "Angel"
              },
              "phoneDetailsList": [
                  {
                      "_id": "60124a5799d733000915423a",
                      "textingAvailable": true,
                      "primary": true,
                      "usageType": "personal",
                      "phoneDialCode": 93,
                      "phoneNumber": "587855845"
                  }
              ],
              "emailDetailsList": [
                  {
                      "_id": "60124a5799d733000915423b",
                      "primary": true,
                      "usageType": "business",
                      "emailAddress": "robert.210128100192@test.com"
                  }
              ],
              "clientEntityName": "ABC Demo Company Ltd",
              "clientId": "3966",
              "familydata": [
                  {
                      "nameDetails": {
                          "title": "Mrs.",
                          "firstName": "Rebecca",
                          "lastName": "Angel"
                      },
                      "phoneDetailsList": [
                          {
                              "_id": "6026743ba045140008783a4b",
                              "textingAvailable": false,
                              "primary": true,
                              "usageType": "business",
                              "phoneDialCode": 91,
                              "phoneNumber": "9238733832377",
                              "locationType": "departure"
                          }
                      ]
                  }
              ],
              "taskData": {
                  "policyCall": {
                      "consultantDetails": {
                          "firstName": "Michele",
                          "lastName": "Sachetti",
                          "emailDetailsList": [
                              {
                                  "emailAddress": "michele.sachetti@cartus.com"
                              }
                          ],
                          "phoneDetailsList": [
                              {
                                  "phoneNumber": "2032051387",
                                  "phoneDialingCode": "1"
                              }
                          ],
                          "timeZone": "America/New_York"
                      },
                      "status": "not started",
                      "policyCallRequired": false,
                      "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
                      "custFileId": 878821
                  }
              },
              "timeLines": [
                  {
                    "description": "This date is the day your company told us you begin working.",
                    "source": "Job Start Date",
                    "date": "2021-11-13"
                  },
                  {
                    "description": "This is the date of your policy call with your consultant.",
                    "source": "Schedule Policy Call",
                    "date": "2021-11-08"
                  },
                  {
                    "description": "This is the date on which your policy call with your consultant was completed.",
                    "source": "Policy Call Completed",
                    "date": "2021-11-08"
                  }
                ],
              "createdAt": "2021-11-01",
              "programName": "High Value US Inbound Program",
              "orderRefNum": "MOV-waaw3qdz",
              "confirmStatus": true,
              "spousePartnerRelocating": true,
              "childrenDependentsRelocating": false,
              "totalNumberOfRelocatingMembers": 2,
              "departureAddr": {
                  "streetLine1": "333 Washington Avenue",
                  "city": "Belleville",
                  "state": "New Jersey",
                  "country": "United States",
                  "postalCode": "07109"
              },
              "destinationAddr": {
                  "streetLine1": "23359, California 1, Eastern Malibu",
                  "city": "Malibu",
                  "state": "California",
                  "country": "United States",
                  "postalCode": "90265"
              },
              "benefitDetails": {
                  "coreBenefits": 36,
                  "confirmedBenefits": 13
                },
                "pointDetails": {
                  "points": 50,
                  "selectedPoints": 0,
                  "confirmedPoints": 15
                },
                "benefitData": {
                  "availablebenefits": [
                    {
                      "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
                      "productSpecificationReference": "PRD-HmPrchReimb",
                      "displayName": "Home Purchase Reimbursement",
                      "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
                      "category": "After You Arrive",
                      "points": 5
                    },
                    {
                      "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
                      "productSpecificationReference": "PRD-MiscAllwnc",
                      "displayName": "Cash Out",
                      "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
                      "category": "Allowances",
                      "points": 0,
                      "tierConfigs": [
                        {
                          "maxSelection": 2,
                          "pointValue": 250,
                          "minRange": 1,
                          "maxRange": 2
                        },
                        {
                          "maxSelection": 3,
                          "pointValue": 300,
                          "minRange": 3,
                          "maxRange": 5
                        },
                        {
                          "maxSelection": 4,
                          "pointValue": 100,
                          "minRange": 6,
                          "maxRange": 9
                        }
                      ]
                    },
                    {
                      "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
                      "productSpecificationReference": "PRD-HmFndng",
                      "displayName": "Home Finding - 6 days/5 nights",
                      "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
                      "category": "Before you Move",
                      "points": 3
                    },
                    {
                      "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
                      "productSpecificationReference": "PRD-BrkrFndrFee",
                      "displayName": "Rental Assistance and Finder's Fee",
                      "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
                      "category": "After You Arrive",
                      "points": 0,
                      "rangeMin": 1,
                      "rangeMax": 12,
                      "rangeIncrementValue": 1,
                      "rangeIncrementUnit": "USD",
                      "perPointCurrency": 500
                    },
                    {
                      "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
                      "productSpecificationReference": "PRD-SlfMv",
                      "displayName": "Self-organized Move",
                      "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
                      "category": "Shipping",
                      "points": 3,
                      "exclusions": [
                        "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
                        "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
                        "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
                        "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                        "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
                      ]
                    },
                    {
                      "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
                      "productSpecificationReference": "PRD-RentalSvcs",
                      "displayName": "Full Day Rental Services",
                      "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
                      "category": "After You Arrive",
                      "points": 4,
                      "exclusions": [
                        "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
                      ]
                    },
                    {
                      "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
                      "productSpecificationReference": "PRD-RentalSvcs",
                      "displayName": "Half Day Rental Services",
                      "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
                      "category": "After You Arrive",
                      "points": 2,
                      "exclusions": [
                        "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
                      ]
                    },
                    {
                      "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
                      "productSpecificationReference": "PRD-ReturnTrpBndl",
                      "displayName": "Return Trip Home - Total 3 trips",
                      "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
                      "category": "Travel and Transport",
                      "points": 3,
                      "maxMul": 3
                    },
                    {
                      "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
                      "productSpecificationReference": "PRD-TmpHsng",
                      "displayName": "Temporary Living Accommodations - up to 120 additional days",
                      "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
                      "category": "After You Arrive",
                      "points": 0,
                      "rangeMin": 15,
                      "rangeMax": 150,
                      "rangeIncrementValue": 15,
                      "rangeIncrementUnit": "Days",
                      "pointsPerIncrement": 4
                    },
                    {
                      "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
                      "productSpecificationReference": "PRD-HmSlReimb",
                      "displayName": "Home Sale Reimbursement",
                      "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
                      "category": "Before You Move",
                      "points": 0,
                      "rangeMin": 1,
                      "rangeMax": 15,
                      "rangeIncrementValue": 1,
                      "rangeIncrementUnit": "USD",
                      "perPointCurrency": 500
                    },
                    {
                      "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
                      "productSpecificationReference": "PRD-DupHsngBndl",
                      "displayName": "Duplicate Housing - Total 3 months",
                      "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
                      "category": "After you Arrive",
                      "points": 4,
                      "maxMul": 3
                    },
                    {
                      "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
                      "productSpecificationReference": "PRD-SlfStrg",
                      "displayName": "Self-organized Storage",
                      "description": "Reimbursement of self storage for 30 days.",
                      "category": "Shipping",
                      "points": 3,
                      "exclusions": [
                        "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                        "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
                      ]
                    },
                    {
                      "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
                      "productSpecificationReference": "PRD-FrnRntl",
                      "displayName": "Furniture Rental",
                      "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
                      "category": "After You Arrive",
                      "points": 0,
                      "rangeMin": 1,
                      "rangeMax": 3,
                      "rangeIncrementValue": 1,
                      "rangeIncrementUnit": "Number of Months",
                      "pointsPerIncrement": 4
                    },
                    {
                      "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
                      "productSpecificationReference": "PRD-CarRntl",
                      "displayName": "Car Rental",
                      "description": "Reimbursement for economy class car rental including child seats, if needed.",
                      "category": "Travel and Transport",
                      "points": 3
                    },
                    {
                      "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
                      "productSpecificationReference": "PRD-VhclShpmtBndl",
                      "displayName": "Automobile Shipment - up to 3 automobiles",
                      "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
                      "category": "Shipping",
                      "points": 4,
                      "maxMul": 3
                    },
                    {
                      "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
                      "productSpecificationReference": "PRD-SpouseCareerSupp",
                      "displayName": "Spouse/Partner Career Support",
                      "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
                      "category": "After You Arrive",
                      "points": 4
                    },
                    {
                      "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
                      "productSpecificationReference": "PRD-PetTrnsprtBndl",
                      "displayName": "Pet Transportation Service - Total 2 Pets",
                      "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
                      "category": "Shipping",
                      "points": 4,
                      "maxMul": 2
                    },
                    {
                      "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                      "productSpecificationReference": "PRD-StrgInTrnst",
                      "displayName": "Storage in Transit - Up To 180 Days",
                      "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
                      "category": "Shipping",
                      "points": 0,
                      "rangeMin": 15,
                      "rangeMax": 180,
                      "rangeIncrementValue": 15,
                      "rangeIncrementUnit": "Days",
                      "pointsPerIncrement": 2,
                      "exclusions": [
                        "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
                      ],
                      "prerequisites": [
                        "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
                        "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
                      ]
                    },
                    {
                      "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
                      "productSpecificationReference": "PRD-LossOnSale",
                      "displayName": "Loss on Sale",
                      "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
                      "category": "Before You Move",
                      "points": 3
                    },
                    {
                      "reference": "OFF-AccntModBBIntl-90e0bef4-18bc-4a2c-8e11-23b1f965edf6",
                      "productSpecificationReference": "PRD-AccntMod",
                      "displayName": "Accent Modification: 12 Hours",
                      "description": "Intensive program designed to improve clarity and intelligibility of speech for advanced level English as a second language speakers. Programs focus on preparing for immediate business tasks or high stakes situations. A specialized accent modification coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.\n            * Technical speech gap analysis and customized learning plan developed by the coach.\n            * Addresses pronunciation of sounds, intonation, and rhythm within the new cultural context.\n            * Flexible scheduling and online delivery.",
                      "category": "Ongoing",
                      "points": 7
                    },
                    {
                      "reference": "OFF-BsnCommEnglBBIntl20-21af8cdb-7e49-4749-a3ea-aaac91efec53",
                      "productSpecificationReference": "PRD-BusnComm",
                      "displayName": "Business English: 20 Hours",
                      "description": "Comprehensive language skill development for the workplace for all levels. Learners are matched with an experienced corporate language coach familiar with the local business culture. \n            * Flexible scheduling managed online through mobile device offers convenience for busy assignees.\n            * All sessions delivered virtually through our digital delivery platform.\n            * Course is customized, with primary focus on the learner's actual work communication.\n            * Commonly targeted goal areas include: developing relationships, small talk, networking, phone calls, meetings, emails, understanding accents, influencing and presentations.",
                      "category": "Ongoing",
                      "points": 3
                    },
                    {
                      "reference": "OFF-BusnCommAdvBBIntl-28d35d1d-8c15-4d81-8db2-e8c0768d38b2",
                      "productSpecificationReference": "PRD-BusnComm",
                      "displayName": "Advanced Business Communication Skills Coaching: 20 hours",
                      "description": "Designed for highly proficient speakers who have mastered fluency and business communication skills for work but who would like to adapt one’s style for maximum efficiency and impact. The program can be customized to address any of the following: creating more impactful presentation style feedback and practice, influencing and persuasion techniques, improving speech/voice clarity, and flexing communication styles to local culture and systems, as well as addressing many other challenges.",
                      "category": "Ongoing",
                      "points": 3
                    },
                    {
                      "reference": "OFF-BusnEnglWrtngBBIntl20-ed0d38d2-1db0-430a-b922-b21a47a089e2",
                      "productSpecificationReference": "PRD-BusnComm",
                      "displayName": "Business English Writing: 20 Hours",
                      "description": "Intensive program designed to address specific skill gaps for C1+ advanced level English speakers. A specialized Business English Writing Coach incorporates actual work content to promote the ability to confidently engage and influence local and global audiences.",
                      "category": "Ongoing",
                      "points": 5
                    },
                    {
                      "reference": "OFF-GlbLdrshpCommBBIntl-2af5419d-6f94-422c-bd98-f175f6e564ab",
                      "productSpecificationReference": "PRD-BusnComm",
                      "displayName": "Global Leadership Communication Coaching: 12 Hours",
                      "description": "Intensive program to address language skill and executive style gaps for C1+ advanced level English speakers using English as a second language. The program focuses on preparing for immediate business tasks or high stakes situations. A specialized corporate communications coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.",
                      "category": "Ongoing",
                      "points": 9
                    },
                    {
                      "reference": "OFF-PrsntSkillsBusnEnglBBIntl12-50fb95d3-1caa-4b0e-8c67-83a381523280",
                      "productSpecificationReference": "PRD-BusnComm",
                      "displayName": "Presentation Skills Coaching for Business English: 12 Hours",
                      "description": "Intensive program designed to address specific skill gaps for C1+ advanced level English speakers. Programs focus on preparing for immediate business tasks or high stakes situations. A specialized corporate communications coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.\n            * Gap analysis and customized learning plan developed by the coach\n            * Flexible scheduling and online delivery",
                      "category": "Ongoing",
                      "points": 6
                    },
                    {
                      "reference": "OFF-CltrlTrnsSuppYouthBB-abe2b5a1-01c1-4a70-aa18-c73bddc9fd19",
                      "productSpecificationReference": "PRD-CltrlTrnsSupp",
                      "displayName": "Youth Cultural Transition - Core Support",
                      "description": "For children, adapting and integrating into school and daily life in a new country can be challenging - they must learn new social norms, behaviors,languages, and routines.\n             Contact us to discuss how this program can be tailored for your children (a face-to-face delivery option may be available).\n             Prepare and support your children for a smooth through a program with our highly experienced and engaging youth transition coaches,who will facilitate an interactive and customized program based on your children's ages and interests, \n             allowing them to:  \n            * Learn about the culture, geography, food, games, and language of the new country. \n            * Participate in activities, videos, and open discussion to learn about themselves and the host culture. \n            * Build confidence by learning strategies to manage culture shock, fitting in, and making new friends.",
                      "category": "Ongoing",
                      "points": 4
                    },
                    {
                      "reference": "OFF-PropMaintBBIntl-ac73445a-dc43-4d38-a384-947d93e4e82a",
                      "productSpecificationReference": "PRD-PropMaint",
                      "displayName": "Property Maintenance",
                      "description": "You will be reimbursed for the expenses relating to property maintenance support for your residence in the home location. Examples of potential expenses: lawn care, utilities, and insurance.",
                      "category": "Ongoing",
                      "points": 4
                    }
                  ],
                  "selectedbenefits": [],
                  "confirmedbenefits": [
                    {
                      "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
                      "productSpecificationReference": "PRD-MiscAllwnc",
                      "displayName": "Cost of Living Allowance ",
                      "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
                      "category": "After You Arrive",
                      "points": "Guaranteed",
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25d110b0b0009db444e",
                      "confirmedDate": "2021-11-06"
                    },
                    {
                      "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
                      "productSpecificationReference": "PRD-MiscAllwnc",
                      "displayName": "Relocation Allowance",
                      "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
                      "category": "Allowances",
                      "points": "Guaranteed",
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25dbd79ad0009c42507",
                      "confirmedDate": "2021-11-04"
                    },
                    {
                      "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
                      "productSpecificationReference": "PRD-DscrdDnt",
                      "displayName": "Household Goods - Pre-Move Discard & Donate",
                      "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
                      "category": "Shipping",
                      "points": "Guaranteed",
                      "exclusions": [
                        "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
                      ],
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25dd25d320008a52a8c",
                      "confirmedDate": "2021-11-02"
                    },
                    {
                      "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
                      "productSpecificationReference": "PRD-HHG",
                      "displayName": "Household Goods - Professional Move / Cartus Insured",
                      "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
                      "category": "Shipping",
                      "points": 3,
                      "exclusions": [
                        "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
                      ],
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          },
                          {
                            "displayName": "Task 2",
                            "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                            "reference": "ATTR-BBTsks2",
                            "status": false
                          },
                          {
                            "displayName": "High-value goods Insurance",
                            "description": "A list of items of high value and total amount identified for insurance purposes",
                            "highValueItemTypeList": [
                              {
                                "name": "Clothing/fur/accessories",
                                "group": "Category 3"
                              },
                              {
                                "name": "Luggage",
                                "group": "Category 3"
                              },
                              {
                                "name": "Small household item/appliance",
                                "group": "Category 3"
                              },
                              {
                                "name": "Camera equipment",
                                "group": "Category 3"
                              },
                              {
                                "name": "Computers/related equipment",
                                "group": "Category 3"
                              },
                              {
                                "name": "Tools/shop equipment",
                                "group": "Category 3"
                              },
                              {
                                "name": "Musical instrument",
                                "group": "Category 3"
                              },
                              {
                                "name": "Exercise equipment",
                                "group": "Category 3"
                              },
                              {
                                "name": "CDs/DVDs/Albums/Video Games",
                                "group": "Category 3"
                              },
                              {
                                "name": "Other/items of unusual value",
                                "group": "Category 3"
                              },
                              {
                                "name": "Bicycle",
                                "group": "Category 3"
                              },
                              {
                                "name": "Electronics (tv/stereo)",
                                "group": "Category 2"
                              },
                              {
                                "name": "Appliance",
                                "group": "Category 2"
                              },
                              {
                                "name": "Mattress set",
                                "group": "Category 2"
                              },
                              {
                                "name": "Art/painting/sculpture",
                                "group": "Category 2"
                              },
                              {
                                "name": "Antique",
                                "group": "Category 2"
                              },
                              {
                                "name": "Grandfather/specialty clock",
                                "group": "Category 2"
                              },
                              {
                                "name": "Carpet",
                                "group": "Category 2"
                              },
                              {
                                "name": "China/crystal/silver",
                                "group": "Category 2"
                              },
                              {
                                "name": "Antique furniture",
                                "group": "Category 1"
                              },
                              {
                                "name": "Motorcycle",
                                "group": "Category 1"
                              },
                              {
                                "name": "Jet ski",
                                "group": "Category 1"
                              },
                              {
                                "name": "Boat",
                                "group": "Category 1"
                              },
                              {
                                "name": "Furniture",
                                "group": "Category 1"
                              }
                            ],
                            "highValueItems": []
                          }
                        ],
                        "allTaskComplete": false,
                        "allHighValueTaskOptOut": false,
                        "allHighValueTaskComplete": false
                      },
                      "productId": "6059b2f6faf8060008cec28d",
                      "confirmedDate": "2021-10-31"
                    },
                    {
                      "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
                      "productSpecificationReference": "PRD-TmpHsng",
                      "displayName": "Temporary Living Accommodations - 30 Days",
                      "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
                      "category": "After You Arrive",
                      "points": "Guaranteed",
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          },
                          {
                            "displayName": "Task 2",
                            "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                            "reference": "ATTR-BBTsks2",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25ddf5d790008643c21",
                      "confirmedDate": "2021-10-29"
                    },
                    {
                      "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
                      "productSpecificationReference": "PRD-TmpHsng",
                      "displayName": "Temporary Living Accommodations - Pre Move",
                      "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
                      "category": "Before You Move",
                      "points": "Guaranteed",
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          },
                          {
                            "displayName": "Task 2",
                            "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                            "reference": "ATTR-BBTsks2",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25db631d70009eb62e8",
                      "confirmedDate": "2021-10-27"
                    },
                    {
                      "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
                      "productSpecificationReference": "PRD-PlcyCnsl",
                      "displayName": "Cartus Counseling and Administration",
                      "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
                      "category": "Before You Move",
                      "points": "Guaranteed",
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          },
                          {
                            "displayName": "Task 2",
                            "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                            "reference": "ATTR-BBTsks2",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25d577f500008c913ca",
                      "confirmedDate": "2021-10-25"
                    },
                    {
                      "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
                      "productSpecificationReference": "PRD-ReloAcctng",
                      "displayName": "Expense Management",
                      "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
                      "category": "Before You Move",
                      "points": "Guaranteed",
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "When CartusOnline is available, please set up your banking information.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          },
                          {
                            "displayName": "Task 2",
                            "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                            "reference": "ATTR-BBTsks2",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25da3d6af00095306f1",
                      "confirmedDate": "2021-10-23"
                    },
                    {
                      "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
                      "productSpecificationReference": "PRD-FnlTrnsprt",
                      "displayName": "Final Transportation to New Location",
                      "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
                      "category": "Travel and Transport",
                      "points": "Guaranteed",
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25d8bb7310009bbfe8e",
                      "confirmedDate": "2021-10-21"
                    },
                    {
                      "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
                      "productSpecificationReference": "PRD-HomeSale",
                      "displayName": "Home Sale Buyer Value & Marketing Assistance",
                      "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
                      "category": "Before You Move",
                      "points": "Guaranteed",
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          },
                          {
                            "displayName": "Task 2",
                            "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                            "reference": "ATTR-BBTsks2",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25ffaf8060008cec284",
                      "confirmedDate": "2021-10-19"
                    },
                    {
                      "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
                      "productSpecificationReference": "PRD-HomeSale",
                      "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
                      "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
                      "category": "Before You Move",
                      "points": "Guaranteed",
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          },
                          {
                            "displayName": "Task 2",
                            "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                            "reference": "ATTR-BBTsks2",
                            "status": false
                          },
                          {
                            "displayName": "Task 3",
                            "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                            "reference": "ATTR-BBTsks3",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25fe2ce7a00083b9831",
                      "confirmedDate": "2021-10-17"
                    },
                    {
                      "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
                      "productSpecificationReference": "PRD-StrgInTrnst",
                      "displayName": "Household Goods - Storage 1st 30 Days",
                      "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
                      "category": "shipping",
                      "points": "Guaranteed",
                      "exclusions": [
                        "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
                        "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
                      ],
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                            "reference": "ATTR-BBTsks1",
                            "status": true
                          }
                        ],
                        "allTaskComplete": true
                      },
                      "productId": "6059b25da1f96500083dda83",
                      "confirmedDate": "2021-10-15"
                    },
                    {
                      "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
                      "productSpecificationReference": "PRD-HomeSaleIncntv",
                      "displayName": "Home Sale Incentive ",
                      "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
                      "category": "Before You Move",
                      "points": "Guaranteed",
                      "prerequisites": [
                        "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
                      ],
                      "taskInfo": {
                        "tasks": [
                          {
                            "displayName": "Task 1",
                            "description": "Your Cartus Consultant will review your company's program with you.",
                            "reference": "ATTR-BBTsks1",
                            "status": false
                          }
                        ],
                        "allTaskComplete": false
                      },
                      "productId": "6059b25defa96f00096febee",
                      "confirmedDate": "2021-10-13"
                    }
                  ],
                  "points": 25
                },
                "taskInfoData": [
                  {
                    "displayName": "Task 1",
                    "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                    "reference": "ATTR-BBTsks1",
                    "status": false
                  },
                  {
                    "displayName": "Task 2",
                    "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                    "reference": "ATTR-BBTsks2",
                    "status": false
                  }
                ],
              "jobStartDate": "2021-11-13"
          },
          {
            "orderRequestId": "60119c2b9663ef0008b13671",
            "nameDetails": {
              "title": "Dr.",
              "firstName": "Robert",
              "lastName": "Angel"
          },
            "phoneDetailsList": [
              {
                "_id": "60119c235415a1000904620c",
                "textingAvailable": true,
                "primary": true,
                "usageType": "personal",
                "phoneDialCode": 93,
                "phoneNumber": "587855845"
              }
            ],
            "emailDetailsList": [
              {
                "_id": "60119c245415a1000904620d",
                "primary": true,
                "usageType": "business",
                "emailAddress": "nicholas.210127220138@test.com"
              }
            ],
            "familydata": [
              {
                "nameDetails": {
                  "title": "Dr.",
                  "firstName": "Elon",
                  "lastName": "Mask"
                },
                "phoneDetailsList": [
                  {
                    "_id": "6026743ba045140008783a4b",
                    "textingAvailable": false,
                    "primary": true,
                    "usageType": "business",
                    "phoneDialCode": 91,
                    "phoneNumber": "9238733832377",
                    "locationType": "departure"
                  }
                ]
              }
            ],
            "taskData": {
              "policyCall": {
                "consultantDetails": {
                  "firstName": "Michele",
                  "lastName": "Sachetti",
                  "emailDetailsList": [
                    {
                      "emailAddress": "michele.sachetti@cartus.com"
                    }
                  ],
                  "phoneDetailsList": [
                    {
                      "phoneNumber": "2032051387",
                      "phoneDialingCode": "1"
                    }
                  ],
                  "timeZone": "America/New_York"
                },
                "status": "not started",
                "policyCallRequired": false,
                "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
                "custFileId": 878821
              }
            },
            "timeLines": [
              {
                "description": "This date is the day your company told us you begin working.",
                "source": "Job Start Date",
                "date": "2021-11-13"
              },
              {
                "description": "This is the date of your policy call with your consultant.",
                "source": "Schedule Policy Call",
                "date": "2021-11-08"
              },
              {
                "description": "This is the date on which your policy call with your consultant was completed.",
                "source": "Policy Call Completed",
                "date": "2021-11-08"
              }
            ],
            "clientEntityName": "ABC Demo Company Ltd",
            "clientId": "3966",
            "programName": "High Value US Inbound Program",
            "orderRefNum": "MOV-waaw3qdz",
            "confirmStatus": true,
            "spousePartnerRelocating": true,
            "childrenDependentsRelocating": false,
            "totalNumberOfRelocatingMembers": 2,
            "departureAddr": {
              "streetLine1": "",
              "city": "Platteville",
              "state": "Colorado",
              "country": "United States",
              "postalCode": "79912"
            },
            "destinationAddr": {
              "streetLine1": "23359, California 1, Eastern Malibu",
              "city": "New Haven",
              "state": "Connecticut",
              "country": "United States",
              "postalCode": "90265"
            },
            "benefitData": {
              "availablebenefits": [
                {
                  "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
                  "productSpecificationReference": "PRD-HmPrchReimb",
                  "displayName": "Home Purchase Reimbursement",
                  "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
                  "category": "After You Arrive",
                  "points": 5
                },
                {
                  "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
                  "productSpecificationReference": "PRD-MiscAllwnc",
                  "displayName": "Cash Out",
                  "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
                  "category": "Allowances",
                  "points": 0,
                  "tierConfigs": [
                    {
                      "maxSelection": 2,
                      "pointValue": 250,
                      "minRange": 1,
                      "maxRange": 2
                    },
                    {
                      "maxSelection": 3,
                      "pointValue": 300,
                      "minRange": 3,
                      "maxRange": 5
                    },
                    {
                      "maxSelection": 4,
                      "pointValue": 100,
                      "minRange": 6,
                      "maxRange": 9
                    }
                  ]
                },
                {
                  "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
                  "productSpecificationReference": "PRD-HmFndng",
                  "displayName": "Home Finding - 6 days/5 nights",
                  "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
                  "category": "Before you Move",
                  "points": 3
                },
                {
                  "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
                  "productSpecificationReference": "PRD-BrkrFndrFee",
                  "displayName": "Rental Assistance and Finder's Fee",
                  "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
                  "category": "After You Arrive",
                  "points": 0,
                  "rangeMin": 1,
                  "rangeMax": 12,
                  "rangeIncrementValue": 1,
                  "rangeIncrementUnit": "USD",
                  "perPointCurrency": 500
                },
                {
                  "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
                  "productSpecificationReference": "PRD-SlfMv",
                  "displayName": "Self-organized Move",
                  "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
                  "category": "Shipping",
                  "points": 3,
                  "exclusions": [
                    "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
                    "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
                    "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
                    "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                    "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
                  ]
                },
                {
                  "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
                  "productSpecificationReference": "PRD-RentalSvcs",
                  "displayName": "Full Day Rental Services",
                  "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
                  "category": "After You Arrive",
                  "points": 4,
                  "exclusions": [
                    "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
                  ]
                },
                {
                  "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
                  "productSpecificationReference": "PRD-RentalSvcs",
                  "displayName": "Half Day Rental Services",
                  "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
                  "category": "After You Arrive",
                  "points": 2,
                  "exclusions": [
                    "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
                  ]
                },
                {
                  "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
                  "productSpecificationReference": "PRD-ReturnTrpBndl",
                  "displayName": "Return Trip Home - Total 3 trips",
                  "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
                  "category": "Travel and Transport",
                  "points": 3,
                  "maxMul": 3
                },
                {
                  "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
                  "productSpecificationReference": "PRD-TmpHsng",
                  "displayName": "Temporary Living Accommodations - up to 120 additional days",
                  "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
                  "category": "After You Arrive",
                  "points": 0,
                  "rangeMin": 15,
                  "rangeMax": 150,
                  "rangeIncrementValue": 15,
                  "rangeIncrementUnit": "Days",
                  "pointsPerIncrement": 4
                },
                {
                  "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
                  "productSpecificationReference": "PRD-HmSlReimb",
                  "displayName": "Home Sale Reimbursement",
                  "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
                  "category": "Before You Move",
                  "points": 0,
                  "rangeMin": 1,
                  "rangeMax": 15,
                  "rangeIncrementValue": 1,
                  "rangeIncrementUnit": "USD",
                  "perPointCurrency": 500
                },
                {
                  "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
                  "productSpecificationReference": "PRD-DupHsngBndl",
                  "displayName": "Duplicate Housing - Total 3 months",
                  "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
                  "category": "After you Arrive",
                  "points": 4,
                  "maxMul": 3
                },
                {
                  "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
                  "productSpecificationReference": "PRD-SlfStrg",
                  "displayName": "Self-organized Storage",
                  "description": "Reimbursement of self storage for 30 days.",
                  "category": "Shipping",
                  "points": 3,
                  "exclusions": [
                    "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                    "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
                  ]
                },
                {
                  "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
                  "productSpecificationReference": "PRD-FrnRntl",
                  "displayName": "Furniture Rental",
                  "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
                  "category": "After You Arrive",
                  "points": 0,
                  "rangeMin": 1,
                  "rangeMax": 3,
                  "rangeIncrementValue": 1,
                  "rangeIncrementUnit": "Number of Months",
                  "pointsPerIncrement": 4
                },
                {
                  "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
                  "productSpecificationReference": "PRD-CarRntl",
                  "displayName": "Car Rental",
                  "description": "Reimbursement for economy class car rental including child seats, if needed.",
                  "category": "Travel and Transport",
                  "points": 3
                },
                {
                  "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
                  "productSpecificationReference": "PRD-VhclShpmtBndl",
                  "displayName": "Automobile Shipment - up to 3 automobiles",
                  "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
                  "category": "Shipping",
                  "points": 4,
                  "maxMul": 3
                },
                {
                  "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
                  "productSpecificationReference": "PRD-SpouseCareerSupp",
                  "displayName": "Spouse/Partner Career Support",
                  "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
                  "category": "After You Arrive",
                  "points": 4
                },
                {
                  "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
                  "productSpecificationReference": "PRD-PetTrnsprtBndl",
                  "displayName": "Pet Transportation Service - Total 2 Pets",
                  "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
                  "category": "Shipping",
                  "points": 4,
                  "maxMul": 2
                },
                {
                  "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                  "productSpecificationReference": "PRD-StrgInTrnst",
                  "displayName": "Storage in Transit - Up To 180 Days",
                  "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
                  "category": "Shipping",
                  "points": 0,
                  "rangeMin": 15,
                  "rangeMax": 180,
                  "rangeIncrementValue": 15,
                  "rangeIncrementUnit": "Days",
                  "pointsPerIncrement": 2,
                  "exclusions": [
                    "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
                  ],
                  "prerequisites": [
                    "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
                    "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
                  ]
                },
                {
                  "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
                  "productSpecificationReference": "PRD-LossOnSale",
                  "displayName": "Loss on Sale",
                  "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
                  "category": "Before You Move",
                  "points": 3
                }
              ],
              "selectedbenefits": [],
              "confirmedbenefits": [
                {
                  "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
                  "productSpecificationReference": "PRD-MiscAllwnc",
                  "displayName": "Cost of Living Allowance ",
                  "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
                  "category": "After You Arrive",
                  "points": "Guaranteed",
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25d110b0b0009db444e",
                  "confirmedDate": "2021-11-06"
                },
                {
                  "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
                  "productSpecificationReference": "PRD-MiscAllwnc",
                  "displayName": "Relocation Allowance",
                  "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
                  "category": "Allowances",
                  "points": "Guaranteed",
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25dbd79ad0009c42507",
                  "confirmedDate": "2021-11-04"
                },
                {
                  "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
                  "productSpecificationReference": "PRD-DscrdDnt",
                  "displayName": "Household Goods - Pre-Move Discard & Donate",
                  "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
                  "category": "Shipping",
                  "points": "Guaranteed",
                  "exclusions": [
                    "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
                  ],
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25dd25d320008a52a8c",
                  "confirmedDate": "2021-11-02"
                },
                {
                  "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
                  "productSpecificationReference": "PRD-HHG",
                  "displayName": "Household Goods - Professional Move / Cartus Insured",
                  "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
                  "category": "Shipping",
                  "points": 3,
                  "exclusions": [
                    "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
                  ],
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      },
                      {
                        "displayName": "Task 2",
                        "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                        "reference": "ATTR-BBTsks2",
                        "status": false
                      },
                      {
                        "displayName": "High-value goods Insurance",
                        "description": "A list of items of high value and total amount identified for insurance purposes",
                        "highValueItemTypeList": [
                          {
                            "name": "Clothing/fur/accessories",
                            "group": "Category 3"
                          },
                          {
                            "name": "Luggage",
                            "group": "Category 3"
                          },
                          {
                            "name": "Small household item/appliance",
                            "group": "Category 3"
                          },
                          {
                            "name": "Camera equipment",
                            "group": "Category 3"
                          },
                          {
                            "name": "Computers/related equipment",
                            "group": "Category 3"
                          },
                          {
                            "name": "Tools/shop equipment",
                            "group": "Category 3"
                          },
                          {
                            "name": "Musical instrument",
                            "group": "Category 3"
                          },
                          {
                            "name": "Exercise equipment",
                            "group": "Category 3"
                          },
                          {
                            "name": "CDs/DVDs/Albums/Video Games",
                            "group": "Category 3"
                          },
                          {
                            "name": "Other/items of unusual value",
                            "group": "Category 3"
                          },
                          {
                            "name": "Bicycle",
                            "group": "Category 3"
                          },
                          {
                            "name": "Electronics (tv/stereo)",
                            "group": "Category 2"
                          },
                          {
                            "name": "Appliance",
                            "group": "Category 2"
                          },
                          {
                            "name": "Mattress set",
                            "group": "Category 2"
                          },
                          {
                            "name": "Art/painting/sculpture",
                            "group": "Category 2"
                          },
                          {
                            "name": "Antique",
                            "group": "Category 2"
                          },
                          {
                            "name": "Grandfather/specialty clock",
                            "group": "Category 2"
                          },
                          {
                            "name": "Carpet",
                            "group": "Category 2"
                          },
                          {
                            "name": "China/crystal/silver",
                            "group": "Category 2"
                          },
                          {
                            "name": "Antique furniture",
                            "group": "Category 1"
                          },
                          {
                            "name": "Motorcycle",
                            "group": "Category 1"
                          },
                          {
                            "name": "Jet ski",
                            "group": "Category 1"
                          },
                          {
                            "name": "Boat",
                            "group": "Category 1"
                          },
                          {
                            "name": "Furniture",
                            "group": "Category 1"
                          }
                        ],
                        "highValueItems": []
                      }
                    ],
                    "allTaskComplete": false,
                    "allHighValueTaskOptOut": false,
                    "allHighValueTaskComplete": false
                  },
                  "productId": "6059b2f6faf8060008cec28d",
                  "confirmedDate": "2021-10-31"
                },
                {
                  "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
                  "productSpecificationReference": "PRD-TmpHsng",
                  "displayName": "Temporary Living Accommodations - 30 Days",
                  "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
                  "category": "After You Arrive",
                  "points": "Guaranteed",
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      },
                      {
                        "displayName": "Task 2",
                        "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                        "reference": "ATTR-BBTsks2",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25ddf5d790008643c21",
                  "confirmedDate": "2021-10-29"
                },
                {
                  "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
                  "productSpecificationReference": "PRD-TmpHsng",
                  "displayName": "Temporary Living Accommodations - Pre Move",
                  "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
                  "category": "Before You Move",
                  "points": "Guaranteed",
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      },
                      {
                        "displayName": "Task 2",
                        "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                        "reference": "ATTR-BBTsks2",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25db631d70009eb62e8",
                  "confirmedDate": "2021-10-27"
                },
                {
                  "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
                  "productSpecificationReference": "PRD-PlcyCnsl",
                  "displayName": "Cartus Counseling and Administration",
                  "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
                  "category": "Before You Move",
                  "points": "Guaranteed",
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      },
                      {
                        "displayName": "Task 2",
                        "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                        "reference": "ATTR-BBTsks2",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25d577f500008c913ca",
                  "confirmedDate": "2021-10-25"
                },
                {
                  "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
                  "productSpecificationReference": "PRD-ReloAcctng",
                  "displayName": "Expense Management",
                  "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
                  "category": "Before You Move",
                  "points": "Guaranteed",
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "When CartusOnline is available, please set up your banking information.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      },
                      {
                        "displayName": "Task 2",
                        "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                        "reference": "ATTR-BBTsks2",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25da3d6af00095306f1",
                  "confirmedDate": "2021-10-23"
                },
                {
                  "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
                  "productSpecificationReference": "PRD-FnlTrnsprt",
                  "displayName": "Final Transportation to New Location",
                  "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
                  "category": "Travel and Transport",
                  "points": "Guaranteed",
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25d8bb7310009bbfe8e",
                  "confirmedDate": "2021-10-21"
                },
                {
                  "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
                  "productSpecificationReference": "PRD-HomeSale",
                  "displayName": "Home Sale Buyer Value & Marketing Assistance",
                  "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
                  "category": "Before You Move",
                  "points": "Guaranteed",
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      },
                      {
                        "displayName": "Task 2",
                        "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                        "reference": "ATTR-BBTsks2",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25ffaf8060008cec284",
                  "confirmedDate": "2021-10-19"
                },
                {
                  "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
                  "productSpecificationReference": "PRD-HomeSale",
                  "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
                  "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
                  "category": "Before You Move",
                  "points": "Guaranteed",
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      },
                      {
                        "displayName": "Task 2",
                        "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                        "reference": "ATTR-BBTsks2",
                        "status": false
                      },
                      {
                        "displayName": "Task 3",
                        "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                        "reference": "ATTR-BBTsks3",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25fe2ce7a00083b9831",
                  "confirmedDate": "2021-10-17"
                },
                {
                  "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
                  "productSpecificationReference": "PRD-StrgInTrnst",
                  "displayName": "Household Goods - Storage 1st 30 Days",
                  "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
                  "category": "shipping",
                  "points": "Guaranteed",
                  "exclusions": [
                    "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
                    "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
                  ],
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                        "reference": "ATTR-BBTsks1",
                        "status": true
                      }
                    ],
                    "allTaskComplete": true
                  },
                  "productId": "6059b25da1f96500083dda83",
                  "confirmedDate": "2021-10-15"
                },
                {
                  "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
                  "productSpecificationReference": "PRD-HomeSaleIncntv",
                  "displayName": "Home Sale Incentive ",
                  "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
                  "category": "Before You Move",
                  "points": "Guaranteed",
                  "prerequisites": [
                    "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
                  ],
                  "taskInfo": {
                    "tasks": [
                      {
                        "displayName": "Task 1",
                        "description": "Your Cartus Consultant will review your company's program with you.",
                        "reference": "ATTR-BBTsks1",
                        "status": false
                      }
                    ],
                    "allTaskComplete": false
                  },
                  "productId": "6059b25defa96f00096febee",
                  "confirmedDate": "2021-10-13"
                }
              ],
              "points": 120
            },
            "taskInfoData": [
              {
                "displayName": "Task 1",
                "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                "reference": "ATTR-BBTsks1",
                "status": false
              },
              {
                "displayName": "Task 2",
                "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                "reference": "ATTR-BBTsks2",
                "status": false
              }
            ],
            "benefitDetails": {
              "confirmedBenefits": 23,
              "coreBenefits": 22
            },
            "pointDetails": {
              "points": 120,
              "selectedPoints": 6
            },
            "jobStartDate": "2021-11-13"
          },
          
      ]
    },
    {
      "orderRequestDetails": [
        {
          "orderRequestId": "60119c2b9663ef0008b13671",
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Kevin",
            "lastName": "William"
          },
          "phoneDetailsList": [
            {
              "_id": "60119c235415a1000904620c",
              "textingAvailable": true,
              "primary": true,
              "usageType": "personal",
              "phoneDialCode": 93,
              "phoneNumber": "587855845"
            }
          ],
          "emailDetailsList": [
            {
              "_id": "60119c245415a1000904620d",
              "primary": true,
              "usageType": "business",
              "emailAddress": "nicholas.210127220138@test.com"
            }
          ],
          "familydata": [
            {
              "nameDetails": {
                "title": "Dr.",
                "firstName": "Elon",
                "lastName": "Mask"
              },
              "phoneDetailsList": [
                {
                  "_id": "6026743ba045140008783a4b",
                  "textingAvailable": false,
                  "primary": true,
                  "usageType": "business",
                  "phoneDialCode": 91,
                  "phoneNumber": "9238733832377",
                  "locationType": "departure"
                }
              ]
            }
          ],
          "taskData": {
            "policyCall": {
              "consultantDetails": {
                "firstName": "Michele",
                "lastName": "Sachetti",
                "emailDetailsList": [
                  {
                    "emailAddress": "michele.sachetti@cartus.com"
                  }
                ],
                "phoneDetailsList": [
                  {
                    "phoneNumber": "2032051387",
                    "phoneDialingCode": "1"
                  }
                ],
                "timeZone": "America/New_York"
              },
              "status": "not started",
              "policyCallRequired": false,
              "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
              "custFileId": 878821
            }
          },
          "timeLines": [
            {
              "description": "This date is the day your company told us you begin working.",
              "source": "Job Start Date",
              "date": "2021-11-13"
            },
            {
              "description": "This is the date of your policy call with your consultant.",
              "source": "Schedule Policy Call",
              "date": "2021-11-08"
            },
            {
              "description": "This is the date on which your policy call with your consultant was completed.",
              "source": "Policy Call Completed",
              "date": "2021-11-08"
            }
          ],
          "clientEntityName": "ABC Demo Company Ltd",
          "clientId": "3966",
          "programName": "High Value US Inbound Program",
          "orderRefNum": "MOV-waaw3qdz",
          "confirmStatus": true,
          "spousePartnerRelocating": true,
          "childrenDependentsRelocating": false,
          "totalNumberOfRelocatingMembers": 2,
          "departureAddr": {
            "streetLine1": "",
            "city": "Platteville",
            "state": "Colorado",
            "country": "United States",
            "postalCode": "79912"
          },
          "destinationAddr": {
            "streetLine1": "23359, California 1, Eastern Malibu",
            "city": "New Haven",
            "state": "Connecticut",
            "country": "United States",
            "postalCode": "90265"
          },
          "benefitData": {
            "availablebenefits": [
              {
                "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
                "productSpecificationReference": "PRD-HmPrchReimb",
                "displayName": "Home Purchase Reimbursement",
                "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
                "category": "After You Arrive",
                "points": 5
              },
              {
                "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
                "productSpecificationReference": "PRD-MiscAllwnc",
                "displayName": "Cash Out",
                "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
                "category": "Allowances",
                "points": 0,
                "tierConfigs": [
                  {
                    "maxSelection": 2,
                    "pointValue": 250,
                    "minRange": 1,
                    "maxRange": 2
                  },
                  {
                    "maxSelection": 3,
                    "pointValue": 300,
                    "minRange": 3,
                    "maxRange": 5
                  },
                  {
                    "maxSelection": 4,
                    "pointValue": 100,
                    "minRange": 6,
                    "maxRange": 9
                  }
                ]
              },
              {
                "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
                "productSpecificationReference": "PRD-HmFndng",
                "displayName": "Home Finding - 6 days/5 nights",
                "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
                "category": "Before you Move",
                "points": 3
              },
              {
                "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
                "productSpecificationReference": "PRD-BrkrFndrFee",
                "displayName": "Rental Assistance and Finder's Fee",
                "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
                "category": "After You Arrive",
                "points": 0,
                "rangeMin": 1,
                "rangeMax": 12,
                "rangeIncrementValue": 1,
                "rangeIncrementUnit": "USD",
                "perPointCurrency": 500
              },
              {
                "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
                "productSpecificationReference": "PRD-SlfMv",
                "displayName": "Self-organized Move",
                "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
                "category": "Shipping",
                "points": 3,
                "exclusions": [
                  "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
                  "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
                  "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
                  "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                  "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
                ]
              },
              {
                "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
                "productSpecificationReference": "PRD-RentalSvcs",
                "displayName": "Full Day Rental Services",
                "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
                "category": "After You Arrive",
                "points": 4,
                "exclusions": [
                  "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
                ]
              },
              {
                "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
                "productSpecificationReference": "PRD-RentalSvcs",
                "displayName": "Half Day Rental Services",
                "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
                "category": "After You Arrive",
                "points": 2,
                "exclusions": [
                  "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
                ]
              },
              {
                "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
                "productSpecificationReference": "PRD-ReturnTrpBndl",
                "displayName": "Return Trip Home - Total 3 trips",
                "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
                "category": "Travel and Transport",
                "points": 3,
                "maxMul": 3
              },
              {
                "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
                "productSpecificationReference": "PRD-TmpHsng",
                "displayName": "Temporary Living Accommodations - up to 120 additional days",
                "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
                "category": "After You Arrive",
                "points": 0,
                "rangeMin": 15,
                "rangeMax": 150,
                "rangeIncrementValue": 15,
                "rangeIncrementUnit": "Days",
                "pointsPerIncrement": 4
              },
              {
                "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
                "productSpecificationReference": "PRD-HmSlReimb",
                "displayName": "Home Sale Reimbursement",
                "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
                "category": "Before You Move",
                "points": 0,
                "rangeMin": 1,
                "rangeMax": 15,
                "rangeIncrementValue": 1,
                "rangeIncrementUnit": "USD",
                "perPointCurrency": 500
              },
              {
                "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
                "productSpecificationReference": "PRD-DupHsngBndl",
                "displayName": "Duplicate Housing - Total 3 months",
                "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
                "category": "After you Arrive",
                "points": 4,
                "maxMul": 3
              },
              {
                "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
                "productSpecificationReference": "PRD-SlfStrg",
                "displayName": "Self-organized Storage",
                "description": "Reimbursement of self storage for 30 days.",
                "category": "Shipping",
                "points": 3,
                "exclusions": [
                  "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                  "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
                ]
              },
              {
                "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
                "productSpecificationReference": "PRD-FrnRntl",
                "displayName": "Furniture Rental",
                "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
                "category": "After You Arrive",
                "points": 0,
                "rangeMin": 1,
                "rangeMax": 3,
                "rangeIncrementValue": 1,
                "rangeIncrementUnit": "Number of Months",
                "pointsPerIncrement": 4
              },
              {
                "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
                "productSpecificationReference": "PRD-CarRntl",
                "displayName": "Car Rental",
                "description": "Reimbursement for economy class car rental including child seats, if needed.",
                "category": "Travel and Transport",
                "points": 3
              },
              {
                "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
                "productSpecificationReference": "PRD-VhclShpmtBndl",
                "displayName": "Automobile Shipment - up to 3 automobiles",
                "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
                "category": "Shipping",
                "points": 4,
                "maxMul": 3
              },
              {
                "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
                "productSpecificationReference": "PRD-SpouseCareerSupp",
                "displayName": "Spouse/Partner Career Support",
                "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
                "category": "After You Arrive",
                "points": 4
              },
              {
                "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
                "productSpecificationReference": "PRD-PetTrnsprtBndl",
                "displayName": "Pet Transportation Service - Total 2 Pets",
                "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
                "category": "Shipping",
                "points": 4,
                "maxMul": 2
              },
              {
                "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                "productSpecificationReference": "PRD-StrgInTrnst",
                "displayName": "Storage in Transit - Up To 180 Days",
                "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
                "category": "Shipping",
                "points": 0,
                "rangeMin": 15,
                "rangeMax": 180,
                "rangeIncrementValue": 15,
                "rangeIncrementUnit": "Days",
                "pointsPerIncrement": 2,
                "exclusions": [
                  "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
                ],
                "prerequisites": [
                  "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
                  "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
                ]
              },
              {
                "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
                "productSpecificationReference": "PRD-LossOnSale",
                "displayName": "Loss on Sale",
                "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
                "category": "Before You Move",
                "points": 3
              }
            ],
            "selectedbenefits": [],
            "confirmedbenefits": [
              {
                "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
                "productSpecificationReference": "PRD-MiscAllwnc",
                "displayName": "Cost of Living Allowance ",
                "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
                "category": "After You Arrive",
                "points": "Guaranteed",
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25d110b0b0009db444e",
                "confirmedDate": "2021-11-06"
              },
              {
                "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
                "productSpecificationReference": "PRD-MiscAllwnc",
                "displayName": "Relocation Allowance",
                "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
                "category": "Allowances",
                "points": "Guaranteed",
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25dbd79ad0009c42507",
                "confirmedDate": "2021-11-04"
              },
              {
                "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
                "productSpecificationReference": "PRD-DscrdDnt",
                "displayName": "Household Goods - Pre-Move Discard & Donate",
                "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
                "category": "Shipping",
                "points": "Guaranteed",
                "exclusions": [
                  "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
                ],
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25dd25d320008a52a8c",
                "confirmedDate": "2021-11-02"
              },
              {
                "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
                "productSpecificationReference": "PRD-HHG",
                "displayName": "Household Goods - Professional Move / Cartus Insured",
                "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
                "category": "Shipping",
                "points": 3,
                "exclusions": [
                  "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
                ],
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    },
                    {
                      "displayName": "Task 2",
                      "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                      "reference": "ATTR-BBTsks2",
                      "status": false
                    },
                    {
                      "displayName": "High-value goods Insurance",
                      "description": "A list of items of high value and total amount identified for insurance purposes",
                      "highValueItemTypeList": [
                        {
                          "name": "Clothing/fur/accessories",
                          "group": "Category 3"
                        },
                        {
                          "name": "Luggage",
                          "group": "Category 3"
                        },
                        {
                          "name": "Small household item/appliance",
                          "group": "Category 3"
                        },
                        {
                          "name": "Camera equipment",
                          "group": "Category 3"
                        },
                        {
                          "name": "Computers/related equipment",
                          "group": "Category 3"
                        },
                        {
                          "name": "Tools/shop equipment",
                          "group": "Category 3"
                        },
                        {
                          "name": "Musical instrument",
                          "group": "Category 3"
                        },
                        {
                          "name": "Exercise equipment",
                          "group": "Category 3"
                        },
                        {
                          "name": "CDs/DVDs/Albums/Video Games",
                          "group": "Category 3"
                        },
                        {
                          "name": "Other/items of unusual value",
                          "group": "Category 3"
                        },
                        {
                          "name": "Bicycle",
                          "group": "Category 3"
                        },
                        {
                          "name": "Electronics (tv/stereo)",
                          "group": "Category 2"
                        },
                        {
                          "name": "Appliance",
                          "group": "Category 2"
                        },
                        {
                          "name": "Mattress set",
                          "group": "Category 2"
                        },
                        {
                          "name": "Art/painting/sculpture",
                          "group": "Category 2"
                        },
                        {
                          "name": "Antique",
                          "group": "Category 2"
                        },
                        {
                          "name": "Grandfather/specialty clock",
                          "group": "Category 2"
                        },
                        {
                          "name": "Carpet",
                          "group": "Category 2"
                        },
                        {
                          "name": "China/crystal/silver",
                          "group": "Category 2"
                        },
                        {
                          "name": "Antique furniture",
                          "group": "Category 1"
                        },
                        {
                          "name": "Motorcycle",
                          "group": "Category 1"
                        },
                        {
                          "name": "Jet ski",
                          "group": "Category 1"
                        },
                        {
                          "name": "Boat",
                          "group": "Category 1"
                        },
                        {
                          "name": "Furniture",
                          "group": "Category 1"
                        }
                      ],
                      "highValueItems": []
                    }
                  ],
                  "allTaskComplete": false,
                  "allHighValueTaskOptOut": false,
                  "allHighValueTaskComplete": false
                },
                "productId": "6059b2f6faf8060008cec28d",
                "confirmedDate": "2021-10-31"
              },
              {
                "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
                "productSpecificationReference": "PRD-TmpHsng",
                "displayName": "Temporary Living Accommodations - 30 Days",
                "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
                "category": "After You Arrive",
                "points": "Guaranteed",
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    },
                    {
                      "displayName": "Task 2",
                      "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                      "reference": "ATTR-BBTsks2",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25ddf5d790008643c21",
                "confirmedDate": "2021-10-29"
              },
              {
                "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
                "productSpecificationReference": "PRD-TmpHsng",
                "displayName": "Temporary Living Accommodations - Pre Move",
                "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
                "category": "Before You Move",
                "points": "Guaranteed",
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    },
                    {
                      "displayName": "Task 2",
                      "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                      "reference": "ATTR-BBTsks2",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25db631d70009eb62e8",
                "confirmedDate": "2021-10-27"
              },
              {
                "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
                "productSpecificationReference": "PRD-PlcyCnsl",
                "displayName": "Cartus Counseling and Administration",
                "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
                "category": "Before You Move",
                "points": "Guaranteed",
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    },
                    {
                      "displayName": "Task 2",
                      "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                      "reference": "ATTR-BBTsks2",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25d577f500008c913ca",
                "confirmedDate": "2021-10-25"
              },
              {
                "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
                "productSpecificationReference": "PRD-ReloAcctng",
                "displayName": "Expense Management",
                "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
                "category": "Before You Move",
                "points": "Guaranteed",
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "When CartusOnline is available, please set up your banking information.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    },
                    {
                      "displayName": "Task 2",
                      "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                      "reference": "ATTR-BBTsks2",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25da3d6af00095306f1",
                "confirmedDate": "2021-10-23"
              },
              {
                "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
                "productSpecificationReference": "PRD-FnlTrnsprt",
                "displayName": "Final Transportation to New Location",
                "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
                "category": "Travel and Transport",
                "points": "Guaranteed",
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25d8bb7310009bbfe8e",
                "confirmedDate": "2021-10-21"
              },
              {
                "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
                "productSpecificationReference": "PRD-HomeSale",
                "displayName": "Home Sale Buyer Value & Marketing Assistance",
                "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
                "category": "Before You Move",
                "points": "Guaranteed",
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    },
                    {
                      "displayName": "Task 2",
                      "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                      "reference": "ATTR-BBTsks2",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25ffaf8060008cec284",
                "confirmedDate": "2021-10-19"
              },
              {
                "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
                "productSpecificationReference": "PRD-HomeSale",
                "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
                "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
                "category": "Before You Move",
                "points": "Guaranteed",
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    },
                    {
                      "displayName": "Task 2",
                      "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                      "reference": "ATTR-BBTsks2",
                      "status": false
                    },
                    {
                      "displayName": "Task 3",
                      "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                      "reference": "ATTR-BBTsks3",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25fe2ce7a00083b9831",
                "confirmedDate": "2021-10-17"
              },
              {
                "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
                "productSpecificationReference": "PRD-StrgInTrnst",
                "displayName": "Household Goods - Storage 1st 30 Days",
                "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
                "category": "shipping",
                "points": "Guaranteed",
                "exclusions": [
                  "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
                  "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
                ],
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                      "reference": "ATTR-BBTsks1",
                      "status": true
                    }
                  ],
                  "allTaskComplete": true
                },
                "productId": "6059b25da1f96500083dda83",
                "confirmedDate": "2021-10-15"
              },
              {
                "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
                "productSpecificationReference": "PRD-HomeSaleIncntv",
                "displayName": "Home Sale Incentive ",
                "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
                "category": "Before You Move",
                "points": "Guaranteed",
                "prerequisites": [
                  "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
                ],
                "taskInfo": {
                  "tasks": [
                    {
                      "displayName": "Task 1",
                      "description": "Your Cartus Consultant will review your company's program with you.",
                      "reference": "ATTR-BBTsks1",
                      "status": false
                    }
                  ],
                  "allTaskComplete": false
                },
                "productId": "6059b25defa96f00096febee",
                "confirmedDate": "2021-10-13"
              }
            ],
            "points": 120
          },
          "taskInfoData": [
            {
              "displayName": "Task 1",
              "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
              "reference": "ATTR-BBTsks1",
              "status": false
            },
            {
              "displayName": "Task 2",
              "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
              "reference": "ATTR-BBTsks2",
              "status": false
            }
          ],
          "benefitDetails": {
            "confirmedBenefits": 23,
            "coreBenefits": 22
          },
          "pointDetails": {
            "points": 120,
            "selectedPoints": 6
          },
          "jobStartDate": "2021-11-13"
        },
        {
          "orderRequestId": "60119c2b9663ef0008b13671",
          "nameDetails": {
              "title": "Dr.",
              "firstName": "Robert",
              "lastName": "Angel"
          },
          "phoneDetailsList": [
              {
                  "_id": "60124a5799d733000915423a",
                  "textingAvailable": true,
                  "primary": true,
                  "usageType": "personal",
                  "phoneDialCode": 93,
                  "phoneNumber": "587855845"
              }
          ],
          "emailDetailsList": [
              {
                  "_id": "60124a5799d733000915423b",
                  "primary": true,
                  "usageType": "business",
                  "emailAddress": "robert.210128100192@test.com"
              }
          ],
          "clientEntityName": "ABC Demo Company Ltd",
          "clientId": "3966",
          "familydata": [],
          "taskData": {
              "policyCall": {
                  "consultantDetails": {
                      "firstName": "Michele",
                      "lastName": "Sachetti",
                      "emailDetailsList": [
                          {
                              "emailAddress": "michele.sachetti@cartus.com"
                          }
                      ],
                      "phoneDetailsList": [
                          {
                              "phoneNumber": "2032051387",
                              "phoneDialingCode": "1"
                          }
                      ],
                      "timeZone": "America/New_York"
                  },
                  "status": "not started",
                  "policyCallRequired": false,
                  "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
                  "custFileId": 878821
              }
          },
          "timeLines": [
              {
                  "description": "This date is the day your company told us you begin working.",
                  "source": "Job Start Date"
              },
              {
                  "description": "This is the date of your policy call with your consultant.",
                  "source": "Schedule Policy Call"
              },
              {
                  "description": "This is the date on which your policy call with your consultant was completed.",
                  "source": "Policy Call Completed"
              }
          ],
          "createdAt":"2021-11-10",
          "programName": "High Value US Inbound Program",
          "orderRefNum": "MOV-waaw3qdz",
          "confirmStatus": true,
          "spousePartnerRelocating": false,
          "childrenDependentsRelocating": false,
          "totalNumberOfRelocatingMembers": 1,
          "departureAddr": {
              "streetLine1": "4502, Medical Drive, Northwest Side",
              "city": "San Antonio",
              "state": "Texas",
              "country": "United States",
              "postalCode": "78229"
            },
            "destinationAddr": {
              "streetLine1": "67, Great Titchfield Street,",
              "city": " London, Greater London, United Kingdom",
              "state": "England",
              "country": "United Kingdom",
              "postalCode": "W1W 7PT"
            },
            "benefitData": {
              "availablebenefits": [
                {
                  "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
                  "productSpecificationReference": "PRD-HmPrchReimb",
                  "displayName": "Home Purchase Reimbursement",
                  "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
                  "category": "After You Arrive",
                  "points": 5
                },
                {
                  "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
                  "productSpecificationReference": "PRD-MiscAllwnc",
                  "displayName": "Cash Out",
                  "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
                  "category": "Allowances",
                  "points": 0,
                  "tierConfigs": [
                    {
                      "maxSelection": 2,
                      "pointValue": 250,
                      "minRange": 1,
                      "maxRange": 2
                    },
                    {
                      "maxSelection": 3,
                      "pointValue": 300,
                      "minRange": 3,
                      "maxRange": 5
                    },
                    {
                      "maxSelection": 4,
                      "pointValue": 100,
                      "minRange": 6,
                      "maxRange": 9
                    }
                  ]
                },
                {
                  "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
                  "productSpecificationReference": "PRD-HmFndng",
                  "displayName": "Home Finding - 6 days/5 nights",
                  "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
                  "category": "Before you Move",
                  "points": 3
                },
                {
                  "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
                  "productSpecificationReference": "PRD-BrkrFndrFee",
                  "displayName": "Rental Assistance and Finder's Fee",
                  "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
                  "category": "After You Arrive",
                  "points": 0,
                  "rangeMin": 1,
                  "rangeMax": 12,
                  "rangeIncrementValue": 1,
                  "rangeIncrementUnit": "USD",
                  "perPointCurrency": 500
                },
                {
                  "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
                  "productSpecificationReference": "PRD-SlfMv",
                  "displayName": "Self-organized Move",
                  "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
                  "category": "Shipping",
                  "points": 3,
                  "exclusions": [
                    "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
                    "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
                    "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
                    "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                    "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
                  ]
                },
                {
                  "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
                  "productSpecificationReference": "PRD-RentalSvcs",
                  "displayName": "Full Day Rental Services",
                  "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
                  "category": "After You Arrive",
                  "points": 4,
                  "exclusions": [
                    "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
                  ]
                },
                {
                  "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
                  "productSpecificationReference": "PRD-RentalSvcs",
                  "displayName": "Half Day Rental Services",
                  "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
                  "category": "After You Arrive",
                  "points": 2,
                  "exclusions": [
                    "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
                  ]
                },
                {
                  "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
                  "productSpecificationReference": "PRD-ReturnTrpBndl",
                  "displayName": "Return Trip Home - Total 3 trips",
                  "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
                  "category": "Travel and Transport",
                  "points": 3,
                  "maxMul": 3
                },
                {
                  "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
                  "productSpecificationReference": "PRD-TmpHsng",
                  "displayName": "Temporary Living Accommodations - up to 120 additional days",
                  "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
                  "category": "After You Arrive",
                  "points": 0,
                  "rangeMin": 15,
                  "rangeMax": 150,
                  "rangeIncrementValue": 15,
                  "rangeIncrementUnit": "Days",
                  "pointsPerIncrement": 4
                },
                {
                  "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
                  "productSpecificationReference": "PRD-HmSlReimb",
                  "displayName": "Home Sale Reimbursement",
                  "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
                  "category": "Before You Move",
                  "points": 0,
                  "rangeMin": 1,
                  "rangeMax": 15,
                  "rangeIncrementValue": 1,
                  "rangeIncrementUnit": "USD",
                  "perPointCurrency": 500
                },
                {
                  "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
                  "productSpecificationReference": "PRD-DupHsngBndl",
                  "displayName": "Duplicate Housing - Total 3 months",
                  "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
                  "category": "After you Arrive",
                  "points": 4,
                  "maxMul": 3
                },
                {
                  "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
                  "productSpecificationReference": "PRD-SlfStrg",
                  "displayName": "Self-organized Storage",
                  "description": "Reimbursement of self storage for 30 days.",
                  "category": "Shipping",
                  "points": 3,
                  "exclusions": [
                    "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                    "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
                  ]
                },
                {
                  "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
                  "productSpecificationReference": "PRD-FrnRntl",
                  "displayName": "Furniture Rental",
                  "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
                  "category": "After You Arrive",
                  "points": 0,
                  "rangeMin": 1,
                  "rangeMax": 3,
                  "rangeIncrementValue": 1,
                  "rangeIncrementUnit": "Number of Months",
                  "pointsPerIncrement": 4
                },
                {
                  "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
                  "productSpecificationReference": "PRD-CarRntl",
                  "displayName": "Car Rental",
                  "description": "Reimbursement for economy class car rental including child seats, if needed.",
                  "category": "Travel and Transport",
                  "points": 3
                },
                {
                  "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
                  "productSpecificationReference": "PRD-VhclShpmtBndl",
                  "displayName": "Automobile Shipment - up to 3 automobiles",
                  "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
                  "category": "Shipping",
                  "points": 4,
                  "maxMul": 3
                },
                {
                  "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
                  "productSpecificationReference": "PRD-SpouseCareerSupp",
                  "displayName": "Spouse/Partner Career Support",
                  "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
                  "category": "After You Arrive",
                  "points": 4
                },
                {
                  "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
                  "productSpecificationReference": "PRD-PetTrnsprtBndl",
                  "displayName": "Pet Transportation Service - Total 2 Pets",
                  "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
                  "category": "Shipping",
                  "points": 4,
                  "maxMul": 2
                },
                {
                  "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
                  "productSpecificationReference": "PRD-StrgInTrnst",
                  "displayName": "Storage in Transit - Up To 180 Days",
                  "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
                  "category": "Shipping",
                  "points": 0,
                  "rangeMin": 15,
                  "rangeMax": 180,
                  "rangeIncrementValue": 15,
                  "rangeIncrementUnit": "Days",
                  "pointsPerIncrement": 2,
                  "exclusions": [
                    "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
                  ],
                  "prerequisites": [
                    "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
                    "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
                  ]
                },
                {
                  "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
                  "productSpecificationReference": "PRD-LossOnSale",
                  "displayName": "Loss on Sale",
                  "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
                  "category": "Before You Move",
                  "points": 3
                },
                {
                  "reference": "OFF-AccntModBBIntl-90e0bef4-18bc-4a2c-8e11-23b1f965edf6",
                  "productSpecificationReference": "PRD-AccntMod",
                  "displayName": "Accent Modification: 12 Hours",
                  "description": "Intensive program designed to improve clarity and intelligibility of speech for advanced level English as a second language speakers. Programs focus on preparing for immediate business tasks or high stakes situations. A specialized accent modification coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.\n            * Technical speech gap analysis and customized learning plan developed by the coach.\n            * Addresses pronunciation of sounds, intonation, and rhythm within the new cultural context.\n            * Flexible scheduling and online delivery.",
                  "category": "Ongoing",
                  "points": 7
                },
                {
                  "reference": "OFF-BsnCommEnglBBIntl20-21af8cdb-7e49-4749-a3ea-aaac91efec53",
                  "productSpecificationReference": "PRD-BusnComm",
                  "displayName": "Business English: 20 Hours",
                  "description": "Comprehensive language skill development for the workplace for all levels. Learners are matched with an experienced corporate language coach familiar with the local business culture. \n            * Flexible scheduling managed online through mobile device offers convenience for busy assignees.\n            * All sessions delivered virtually through our digital delivery platform.\n            * Course is customized, with primary focus on the learner's actual work communication.\n            * Commonly targeted goal areas include: developing relationships, small talk, networking, phone calls, meetings, emails, understanding accents, influencing and presentations.",
                  "category": "Ongoing",
                  "points": 3
                },
                {
                  "reference": "OFF-BusnCommAdvBBIntl-28d35d1d-8c15-4d81-8db2-e8c0768d38b2",
                  "productSpecificationReference": "PRD-BusnComm",
                  "displayName": "Advanced Business Communication Skills Coaching: 20 hours",
                  "description": "Designed for highly proficient speakers who have mastered fluency and business communication skills for work but who would like to adapt one’s style for maximum efficiency and impact. The program can be customized to address any of the following: creating more impactful presentation style feedback and practice, influencing and persuasion techniques, improving speech/voice clarity, and flexing communication styles to local culture and systems, as well as addressing many other challenges.",
                  "category": "Ongoing",
                  "points": 3
                },
                {
                  "reference": "OFF-BusnEnglWrtngBBIntl20-ed0d38d2-1db0-430a-b922-b21a47a089e2",
                  "productSpecificationReference": "PRD-BusnComm",
                  "displayName": "Business English Writing: 20 Hours",
                  "description": "Intensive program designed to address specific skill gaps for C1+ advanced level English speakers. A specialized Business English Writing Coach incorporates actual work content to promote the ability to confidently engage and influence local and global audiences.",
                  "category": "Ongoing",
                  "points": 5
                },
                {
                  "reference": "OFF-GlbLdrshpCommBBIntl-2af5419d-6f94-422c-bd98-f175f6e564ab",
                  "productSpecificationReference": "PRD-BusnComm",
                  "displayName": "Global Leadership Communication Coaching: 12 Hours",
                  "description": "Intensive program to address language skill and executive style gaps for C1+ advanced level English speakers using English as a second language. The program focuses on preparing for immediate business tasks or high stakes situations. A specialized corporate communications coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.",
                  "category": "Ongoing",
                  "points": 9
                },
                {
                  "reference": "OFF-PrsntSkillsBusnEnglBBIntl12-50fb95d3-1caa-4b0e-8c67-83a381523280",
                  "productSpecificationReference": "PRD-BusnComm",
                  "displayName": "Presentation Skills Coaching for Business English: 12 Hours",
                  "description": "Intensive program designed to address specific skill gaps for C1+ advanced level English speakers. Programs focus on preparing for immediate business tasks or high stakes situations. A specialized corporate communications coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.\n            * Gap analysis and customized learning plan developed by the coach\n            * Flexible scheduling and online delivery",
                  "category": "Ongoing",
                  "points": 6
                },
                {
                  "reference": "OFF-CltrlTrnsSuppYouthBB-abe2b5a1-01c1-4a70-aa18-c73bddc9fd19",
                  "productSpecificationReference": "PRD-CltrlTrnsSupp",
                  "displayName": "Youth Cultural Transition - Core Support",
                  "description": "For children, adapting and integrating into school and daily life in a new country can be challenging - they must learn new social norms, behaviors,languages, and routines.\n             Contact us to discuss how this program can be tailored for your children (a face-to-face delivery option may be available).\n             Prepare and support your children for a smooth through a program with our highly experienced and engaging youth transition coaches,who will facilitate an interactive and customized program based on your children's ages and interests, \n             allowing them to:  \n            * Learn about the culture, geography, food, games, and language of the new country. \n            * Participate in activities, videos, and open discussion to learn about themselves and the host culture. \n            * Build confidence by learning strategies to manage culture shock, fitting in, and making new friends.",
                  "category": "Ongoing",
                  "points": 4
                },
                {
                  "reference": "OFF-PropMaintBBIntl-ac73445a-dc43-4d38-a384-947d93e4e82a",
                  "productSpecificationReference": "PRD-PropMaint",
                  "displayName": "Property Maintenance",
                  "description": "You will be reimbursed for the expenses relating to property maintenance support for your residence in the home location. Examples of potential expenses: lawn care, utilities, and insurance.",
                  "category": "Ongoing",
                  "points": 4
                }
              ],
              "selectedbenefits": [],
              "confirmedbenefits": [],
              "points": 0
            },
            "taskInfoData": [
              {
                "displayName": "Task 1",
                "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                "reference": "ATTR-BBTsks1",
                "status": false
              },
              {
                "displayName": "Task 2",
                "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                "reference": "ATTR-BBTsks2",
                "status": false
              }
            ],     
            "benefitDetails": {
              "coreBenefits": 36,
              "confirmedBenefits": 0
            },
            "pointDetails": {
              "points": 25,
              "selectedPoints": 2,
              "confirmedPoints": 0
            },   
          "jobStartDate": "2021-11-13"
        }  
      ]
    } 
  ]
}