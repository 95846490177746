import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NeedsAssessmentSharedService } from '../../../../../src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { transfereeNeedsAssessmentData } from '../../data/app-data';
import { TransfereeNeedsAssessment } from '../../models/candidateneeds-assessment.model';
import { AppDataService } from '../../services/app-data.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private readonly spinner: NgxSpinnerService,
    private readonly route: Router,
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    private appDataService: AppDataService
  ) {
  }

  ngOnInit() {
    this.spinner.show();
    this.needsAssessmentShared.transfereeNeedsAssessmentDetails.next(this.appDataService.transfereeNeedsAssementStubData.getValue());
    const needsAssessment = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.getValue();
    if (needsAssessment) {
      this.spinner.hide();
      if (needsAssessment.confirmStatus) {
        this.route.navigate(['/bb/dashboard']);
      } else {
        this.route.navigate(['/needs-assessment']);
      }
    } else {
      this.spinner.hide();
      this.route.navigate(['/logout']);
    }
  }
}
