import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of, empty } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UserContext } from '../models/user-context.model';
import { HttpParams } from '@angular/common/http';
import { Contexts } from '../models/contexts.model';
import {contexts, userContacts, userContext} from '../../core/data/data';

/** user context service to fetch user context details */
@Injectable({
  providedIn: 'root'
})
export class UserContextService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(
    private readonly baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService
  ) { }

  private _windowRef: any = [];

  public get windowRef() {
    return this._windowRef;
  }

  public set windowRef(value: any) {
    if (value) {
      this._windowRef.push(value);
    }
  }
  /**
   * get contexts method to get the user context List
  */
  getContexts(reqObj): Observable<UserContext> {
    // return this.baseClient
    //   .post<UserContext>(`v1/contexts`, reqObj,  'get the contexts List')
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: UserContext = null;
    //       this.logSvc.logError(err);
    //       return of(empty);
    //     })
    //   );
    return of (JSON.parse(JSON.stringify(contexts)));
  }

  getUserContacts(): Observable<any> {
    // return this.baseClient.getOne<any>(`v1/userContacts`, 'get User Contacts').pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     this.logSvc.logError(err);
    //     return of(empty);
    //   })
    // );
    return of (JSON.parse(JSON.stringify(userContacts)));
  }

  /**
   * get service method to get the user context details
   */
  getUserContext(): Observable<Contexts> {
    return of(JSON.parse(JSON.stringify(contexts)));
  }

  /**
   * get service method to get the user context details
   */
  setUserContext(userContext: Contexts): void {
    sessionStorage.setItem('UserContext', JSON.stringify(contexts));
  }
}
