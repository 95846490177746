import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BenefitService, Benefit } from '../../services/benefit.service';
import { Subscription } from 'rxjs';
import { MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import pdfMake from 'pdfmake/build/pdfmake';
import { ProgramReportGenerationService } from '../../services/program-report-generation.service';
import { formatDate } from '@angular/common';
import { NeedsAssessmentSharedService } from '../../../needs-assessment/services/needs-assessment-shared.service';
import { TasksInfoService } from '../../../../../../src/app/core/services/tasks-info.service';
import { Tasks } from '../../../../../../src/app/core/models/tasks.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatDialog } from '@angular/material/dialog';
import { CollaborationService } from './../../../../core/services/collaboration.service';
import { CollabMessage, CollabEvents } from './../../../../core/services/collaboration-events.service';
import { TransfereeNeedsAssessment } from '../../../../../../src/app/core/models/candidateneeds-assessment.model';
import { AppDataService } from '../../../../core/services/app-data.service'
@Component({
    selector: 'app-review-selected-benefits',
    templateUrl: './review.component.html',
    styleUrls: ['./review.component.scss']
})
export class ReviewSelectedBenefitsComponent implements OnInit, OnDestroy {
    /** Subscription for benefit data */
    benefitSubscription: Subscription;
    /** To store list of selected benefits */
    selectedBenefits: Array<Benefit> = [];
    /** To store list of selected benefits */
    confirmedBenefits: Array<Benefit> = [];
    /** To check if the benefits are available */
    isLoaded = false;
    formattedDate;
    /** To store task details */
    taskDetails: Tasks;
    /** flag to check pending policy call */
    pendingPolicyCall = true;

    collabEvents: Subscription;

    finalBenefits: Array<Benefit> = [];

    isConsultant = true;
    /**Flag to indicate all benefits are confirmed */
	isAllBenefitsConfirmed = false;
    selectedPoints: number = 0;

    /**
     * @param router Inject router
     * @param tasksService Inject TasksInfoService
     * @param benefitService Inject benefit service
     */
    constructor(private router: Router,
        private readonly tasksService: TasksInfoService,
        private dialog: MatDialog,
        private readonly needsAssessmentService: NeedsAssessmentSharedService,
        private readonly benefitService: BenefitService,
        private readonly spinner: NgxSpinnerService,
        private readonly toastr: MatSnackBar,
        private readonly pdfgenerator: ProgramReportGenerationService,
        private readonly needAssessmentSharedSvc: NeedsAssessmentSharedService,
        private readonly collaboration: CollaborationService
    ) { }

    ngOnInit() {
        this.getBenefits();
        this.loadPendingTasks();
        this.observeCollabEvents();
        this.getUserType();
    }
    benefitSelectDeleteAction(selectedPoints) {
        this.selectedPoints = selectedPoints;
    }
    observeCollabEvents() {
        this.collabEvents = this.collaboration.socketSubscriber$.subscribe((message: CollabMessage) => {
            if (!message) { return; }

            try {
                if (message.data.event && message.data.event === CollabEvents.BENEFIT_ACTION_REVIEW_DOWNLOAD) {
                    this.openDownloadToastr(false);
                    this.downloadPDF('review', false);
                }
                if (message.data.event && message.data.event === CollabEvents.BENEFIT_NAV_AVAILABLE) {
                    this.router.navigate(['/bb/benefits/list']);
                }
            } catch (error) {
                console.error('Collab Error ', error);
            }
        });
    }

    /**
     * Decides whether the User is Consultant or Transferee
     */
    getUserType() {
        (this.collaboration.userType() === 'consultant') ?
            this.isConsultant = true : this.isConsultant = false;
    }

    /**
     * To go back to list of benefits screen
     */
    goToList() {
        this.router.navigate(['/bb/benefits/list']);

        // Send Message
        this.collaboration.sendMessage({
            event: CollabEvents.BENEFIT_NAV_AVAILABLE,
            data: null
        });
    }

    /**
     * To get list of benefits
     */
    getBenefits() {
        this.benefitSubscription = this.benefitService.benefitsData.subscribe(data => {
            if (!data) {
                this.benefitService.getBenefits().subscribe(data => {
                    if (data) {
                        data.availableBenefits = this.benefitService.updateCategoryNames(data.availableBenefits).
                            sort((a, b) => a.displayName.localeCompare(b.displayName));
                        // if logged in user is not consultant remove consultant only card set to hidden
                        data.availableBenefits = this.benefitService.reOrderAvailableBenefits(data.availableBenefits);
                        data.selectedBenefits = this.benefitService.updateCategoryNames(data.selectedBenefits).
                            sort((a, b) => a.displayName.localeCompare(b.displayName));
                        data.confirmedBenefits = this.benefitService.updateCategoryNames(data.confirmedBenefits).
                            sort((a, b) => a.displayName.localeCompare(b.displayName));
                        
                        const guaranteedBenefits = data.selectedBenefits.filter(benefit => benefit.points === 'Guaranteed');
                        data.selectedBenefits = guaranteedBenefits.concat(data.selectedBenefits.
                            filter(benefit => benefit.points !== 'Guaranteed'));
                        
                        const confirmedGuaranteedBenefits = data.confirmedBenefits.filter(
                            benefit => benefit.points === 'Guaranteed');
                        data.confirmedBenefits = confirmedGuaranteedBenefits.concat(data.confirmedBenefits.
                            filter(benefit => benefit.points !== 'Guaranteed'));

                        const benefits = this.benefitService.benefitsData.getValue() || {};
                        this.benefitService.benefitsData.next(Object.assign(benefits, data));
                        this.isLoaded = true;
                    } else {
                        this.openErrorToastr();
                    }
                }, () => {
                    this.openErrorToastr();
                });
            } else {



                data.confirmedBenefits ? this.confirmedBenefits = data.confirmedBenefits : 0;
                data.selectedBenefits ? this.selectedBenefits = data.selectedBenefits : 0;
                data.selectedBenefits ? this.selectedBenefits = this.benefitService.splitBenefits(data.selectedBenefits) : 0;
                data.selectedBenefits ? this.selectedBenefits = this.benefitService.splitHybridBenefit(this.selectedBenefits) : 0;
                this.selectedBenefits = this.benefitService.sortBenefitByGroup(this.selectedBenefits);
                // All Loaded...
                this.isLoaded = true;
            }
        });
        const needAssessmentPointData = this.needsAssessmentService.transfereeNeedsAssessmentDetails.getValue();
        this.selectedPoints = needAssessmentPointData && needAssessmentPointData.pointDetails && needAssessmentPointData.pointDetails.selectedPoints ? (needAssessmentPointData.pointDetails.selectedPoints || 0) : 0;
    }

    /**
     * To display error and action to try again
     */
    openErrorToastr() {
        const toaster = this.toastr.open('Error fetching benefits', 'Try again?', {
            duration: 5000,
        });
        toaster.onAction().subscribe(_ => this.getBenefits());
    }

    /**
        * Load notification for pending tasks
        */
    loadPendingTasks() {
        this.tasksService.taskInformation.subscribe(tasks => {
            if (tasks && tasks.policyCall && tasks.policyCall.policyCallRequired) {
                this.pendingPolicyCall = tasks && tasks.policyCall.status !== 'completed' ? true : false;
            } else {
                this.pendingPolicyCall = false;
            }
        });
    }

    updateConfirmedBenefits() {
        const benefits = this.benefitService.benefitsData.getValue() || {};
        this.benefitService.benefitsData.next(Object.assign(benefits, benefits));
    }

    /**
     * To confirm selected benefits
     */
    confirmSelectedBenefits(event?: Event) {
        // this.benefitService.confirmBenefit(this.selectedBenefits).subscribe(res => {
        try {
            if (event) {
                event.stopImmediatePropagation();
            }
            this.spinner.show();
            let selectedBenefits = JSON.parse(JSON.stringify(this.benefitService.mergeHybridBenefit(this.benefitService.benefitsData.getValue().selectedBenefits)));
            selectedBenefits = selectedBenefits.map((benefit) => {
                benefit.selectedbenefitCount ? delete benefit.selectedbenefitCount : null;
                benefit.confirmedCashOutPoints ? delete benefit.confirmedCashOutPoints : null;
                return benefit;
            });
            this.benefitService.confirmBenefit(selectedBenefits).subscribe(res => {
                if (res) {

                    // get needs assessment data with updated points
                    //this.needsAssessmentService.transfereeNeedsAssessmentDetails.next(null);
                    this.needsAssessmentService.getCandidateNeedsAssessmentDetails();
                    this.updateConfirmedBenefits();
                    this.spinner.hide();
                    this.openConfirmDialog();

                } else {
                    this.toastr.open(
                        'Error confirming benefits',
                        'Try again?', {
                        duration: 5000,
                    }).onAction().subscribe(_ => this.confirmSelectedBenefits(event));
                }
            }, (error) => {
                this.spinner.hide();
                this.toastr.open(
                    error.message ? error.message : 'Error confirming benefit',
                    error.message ? null : 'Try again?', {
                    duration: 5000,
                }).onAction().subscribe(_ => this.confirmSelectedBenefits(event));
            });

        } catch (error) {
            this.spinner.hide();
            console.error(error);
        }

    }

    /**
     * To open confirmation dialog on completion of benefit confirmation
     */
    openConfirmDialog() {
        // tslint:disable-next-line: no-use-before-declare
        const dialogRef = this.dialog.open(ConfirmBenefitsDialogComponent, {
            id: 'dialog2',
            width: 'auto',
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result && result.action === 'later') {
                dialogRef.close();
                this.goToList();
            } else {
                dialogRef.close();

                // Navigate to Confirmed Message
                this.collaboration.sendMessage({
                    event: CollabEvents.BENEFIT_NAV_CONFIRMED,
                    data: null
                });

                this.router.navigate(['/bb/benefits/confirmed']);
            }
        });
    }

    /**
     * Shows toastr messsage before download
     * @param isCollab if the action taken is in Collaboration Mode
     */
    openDownloadToastr(isCollab = false) {
        // tslint:disable-next-line: max-line-length
        const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
        const toaster = this.toastr.open(message, isCollab ? null : 'DOWNLOAD', {
            duration: isCollab ? 50000 : 100000,
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            panelClass: ['toaster']
        });
        toaster.onAction().subscribe(_ => this.downloadPDF('review'));
    }

    /**
     * To download program as PDF
     */
    downloadPDF(status, isCollab = false) {
        const transfereeName = this.needAssessmentSharedSvc.transfereeNeedsAssessmentDetails.getValue().nameDetails.firstName + '_' +
            this.needAssessmentSharedSvc.transfereeNeedsAssessmentDetails.getValue().nameDetails.lastName;
        const currentDate = new Date();
        this.formattedDate = formatDate(currentDate, 'MMMM_dd_yyyy_HH_mm_ss', 'en-US');
        // tslint:disable-next-line: max-line-length
        const selectedBenefits = JSON.parse(JSON.stringify(this.selectedBenefits));
        pdfMake.createPdf(this.pdfgenerator.generateDocumentDefinition(selectedBenefits, this.confirmedBenefits, status)).
            download(`${transfereeName}_${this.formattedDate}_draft`);

        if (!isCollab) {
            // Send Message
            this.collaboration.sendMessage({
                event: CollabEvents.BENEFIT_ACTION_CONFIRMED_DOWNLOAD,
                data: null
            });
        }

    }

    /**
     * To check if all points are utilized and benefits are confirmed
     */
    checkIfBenefitsAreConfirmed() {
        this.needsAssessmentService.transfereeNeedsAssessmentDetails.subscribe((data: TransfereeNeedsAssessment) => {
            if (!data) {
                return;
            }
             // when all benefits are confirmed
             this.isAllBenefitsConfirmed = data.pointDetails.confirmedPoints === data.pointDetails.points ?  true : false;
        });
    }

    ngOnDestroy(): void {
        if (this.collabEvents) { this.collabEvents.unsubscribe(); }
    }

}


/**
 * Component to display confirm dialog box on successfull confirmation of benefits
 */
@Component({
    selector: 'app-selected-benefits-confirm',
    styleUrls: ['./review.component.scss'],
    template: `<div mat-dialog-content class='confirm-modal'> <span class="check"><mat-icon>done</mat-icon></span>
    <span class="confirm-popup-text">Selected benefits are confirmed</span><div>
  <div mat-dialog-content><span class="confrim-cont-text">Please go-ahead and review the tasks for your Confirmed Benefits</span><div>
  <div class="button-wrapper" fxLayoutAlign="space-between center">
   <button mat-button (click)='goBack()' class="btn-later">Later</button>
   <button mat-button cdkFocusInitial (click)='scheduleTasks()' class="btn-schedule">Review Tasks</button>
 </div>`,
})
export class ConfirmBenefitsDialogComponent {
    constructor(
        public dialog: MatDialogRef<ConfirmBenefitsDialogComponent>,
        public benefitService: BenefitService
    ) {
        dialog.disableClose = true;
    }

    goBack(): void {
        this.benefitService.movebenefitFromSelectToConfirm();
        this.dialog.close({
            action: 'later'
        });
    }
    scheduleTasks(): void {
        this.benefitService.movebenefitFromSelectToConfirm();
        let benefits = this.benefitService.benefitsData.getValue();
        this.dialog.close({
            action: 'schedule'
        });
    }
}
