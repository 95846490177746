/** mock data for getting value list */
export const valueList =  {
    
    "DeliveryTeamModel": {
        "values": [
            "Home Sale",
            "International Comp",
            "International Logistics",
            "Limited Benefits",
            "Traditional"
        ]
    },
    "TraditionalAuthTimeZone": {
        "values": [
            "(GMT-05:00) Eastern Standard Time",
            "(GMT) Western European Time",
            "(GMT+05:30) India Standard Time",
            "(GMT+08:00) Singapore Time"
        ]
    },
    "InitialContactBy": {
        "values": [
            "Consultant",
            "Welcome Center",
            "Auto Invite to COL"
        ]
    },
    "EmployeeType": {
        "values": [
            "Candidate",
            "College Graduate",
            "Experienced New Hire",
            "Inexperienced New Hire",
            "New Hire",
            "Non New Hire",
            "Transfer"
        ]
    }

}


/**mock data for cci list */

export const cciList = [
    {
      "id": "5df3dd2b4c3c75b4645b84c8",
      "name": "Jo Zame"
    },
    {
      "id": "5df3dd2b4c3c751d175b84c6",
      "name": "Jo Zamev"
    },
    {
      "id": "60e53da93813fc0008b1b0a3",
      "name": "James Bond"
    },
    {
      "id": "5df3dd28bc43a2cf5709bbaf",
      "name": "Jo Zamen"
    },
    {
      "id": "5e459fc8288fd300081b469c",
      "name": "Jane Adamski"
    },
    {
      "id": "60938a6e9270b7000899caef",
      "name": "Bat aContact"
    },
    {
      "id": "60d4ab3eb08f8c000879c28d",
      "name": "Bat aContact"
    },
    {
      "id": "61af0c0e769e2900097807b7",
      "name": "Bat aContact"
    },
    {
      "id": "5df3dd29ed650fa21f856daa",
      "name": "Dave Dashboardtwo"
    },
    {
      "id": "60df1875bb013e0008ac79ff",
      "name": "Bat aContact"
    },
    {
      "id": "609a3feaccbad000097a507e",
      "name": "Bat aContacttest"
    },
    {
      "id": "61e6c6071e45e70009de8bbf",
      "name": "TSTJane TSTBennet"
    },
    {
      "id": "5df51984eb1bc8a5b68c6af9",
      "name": "Annathe Smitha"
    },
    {
      "id": "61af0c0c769e2900097807ae",
      "name": "Bat TestCCIAbc"
    },
    {
      "id": "61d6f61bd513d30009234729",
      "name": "TSTMotor TSTCycle"
    },
    {
      "id": "61e6c6701e45e70009de8bc8",
      "name": "TSTMary TSTBennet"
    },
    {
      "id": "5df3dd2a710593bb2cd94405",
      "name": "Veronica Danbury"
    },
    {
      "id": "5df5197feb1bc8ce718c6ad6",
      "name": "Natasha Turner"
    },
    {
      "id": "609002263f1bc700091eec53",
      "name": "Bat aContactabce"
    },
    {
      "id": "5df51982eb1bc89d138c6aee",
      "name": "Susan Brown"
    },
    {
      "id": "61e6c5891e45e70009de8bb6",
      "name": "TSTElizabeth TSTBennet"
    },
    {
      "id": "5df3dd2a83d6e1273438c5fb",
      "name": "Madison Danbury"
    },
    {
      "id": "5df3dd2969892811d582b536",
      "name": "Dave Dashboardone"
    },
    {
      "id": "5df51989eb1bc8310b8c6b0a",
      "name": "Neil Analytics"
    },
    {
      "id": "61d6f6d0d513d3000923472d",
      "name": "TSTMary TSTPoppins"
    },
    {
      "id": "5df5198e4eb46e61df193109",
      "name": "Jane Adams"
    },
    {
      "id": "60ed74d60013f500097336f7",
      "name": "Bat aContact"
    },
    {
      "id": "5e5ee8f5c78aa40008670d36",
      "name": "ClientContact One"
    },
    {
      "id": "6089577f60693b00093d638c",
      "name": "Bat aContact"
    },
    {
      "id": "5e459fd4288fd300081b46a4",
      "name": "Jane A Adams"
    },
    {
      "id": "5df3dd2a71059324c6d94407",
      "name": "Jennifer Adamsu"
    },
    {
      "id": "60d49cfeb861090008cf8c64",
      "name": "Bat aContact"
    },
    {
      "id": "60ddedf2a60997000893555d",
      "name": "Sam aContact"
    },
    {
      "id": "5df51980eb1bc843fc8c6ae5",
      "name": "Cheri ACOLreg"
    },
    {
      "id": "61d6f5b2e2d9cf0009d8225a",
      "name": "TSTBon TSTJour"
    },
    {
      "id": "5df519904eb46e27b1193117",
      "name": "qa refresh"
    },
    {
      "id": "60d434b8288a9e0008aa59ed",
      "name": "Bat TestCCIA"
    },
    {
      "id": "5df3dd282205ea28fa8c2500",
      "name": "Janet Adams"
    },
    {
      "id": "60a3701c64e1830009532da1",
      "name": "Bat aContact"
    },
    {
      "id": "60d43ac5407b2d0008d90347",
      "name": "Bat TestContactNew"
    },
    {
      "id": "60d430be312f7400089d38fa",
      "name": "Bat aContact"
    }
  ]

  export const contracts = [
    {
      // "contractDetails": {
      "baseContract": "505",
      "contractId": "4",
      "contractDescription": "Demo Global Contract"
      // }
    },
    {
      //"contractDetails":{
      "baseContract": "504",
      "contractId": "4.1",
      "contractDescription": "Demo Global Contract"
      //}
    },
    {
      //"contractDetails":{
      "baseContract": "503",
      "contractId": "3.1",
      "contractDescription": "Demo Global Contract"
      //}
    },
    {
     //"contractDetails":{
      "baseContract": "501",
      "contractId": "3",
      "contractDescription": "Demo Client Contract"
      //}
    },
    {
      //"contractDetails":{
      "baseContract": "502",
      "contractId": "5",
      "contractDescription": "Demo Global Contract"
      //}
    }
  ]


  export const moveTypes = [
    "Candidate Program",
    "Executive Homeowner",
    "Expat Long Term",
    "Homebound Repat",
    "Homesale Homeowner",
    "LT International Transfer",
    "Lump Sum Only",
    "One Way Perm",
    "Renter",
    "Repat",
    "Travel or Assignment Calendar",
    "US Direct Reimbursement",
    "US Domestic Non-Homeowner",
    "US Outbound International Moves",
    "US Trainee 1"
  ]