
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { ConfigurableFieldComponent ,HighlightSearch} from './components/configurable-field/configurable-field.component'
import {MatIconModule, MatInputModule, MatCardModule, MatAutocompleteModule, MatSelectModule} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';

@NgModule({
  declarations: [ConfigurableFieldComponent, HighlightSearch],
  imports: [
    MatCardModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatDatepickerModule, 
    MatNativeDateModule
  ],
  exports: [ConfigurableFieldComponent],
})
export class SharedTransfereeProgramModule { }
