import { Component, OnInit, Input, ViewChild, Output, EventEmitter, HostListener, SimpleChanges, OnChanges } from '@angular/core';
import { OverlayRef } from 'ngx-toastr';
import { candidateProfile } from './../../../../core/models/candidate.model';
import { MatTableDataSource, PageEvent, SortDirection, MatSort, MatPaginator, MatDialog, MatSortHeaderIntl } from '@angular/material';
import { SelectionModel } from '@angular/cdk/collections';
import { DateConversionService } from './../../../../core/services/date-conversion.service';
import { KEY_CODE } from './../../../../public/dashboard/components/employee-details/employee-details.component';
import { AddEditTransfereeComponent } from '../add-edit-transferee/add-edit-transferee.component';
import { AdminClient } from './../../../../core/models/admin-client.model';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent implements OnInit, OnChanges {

  /** overlayRef to hold overlay config */
  overlayRef: OverlayRef;

  @Input() clientList: Array<AdminClient> = [];

  /** displayedColumns variable array to store the displayed columns */
  displayedColumns: string[] = ['clientEntityName', 'location', 'totalClientContacts', 'totalCandidates', 'totalActivePrograms'];

  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<any>;

  /** selection variable to store the collection of selections made in mat table */
  selection: SelectionModel<any>;

  /** page event variable to handle the page events */
  pageEvent: PageEvent;

  /**Direction varialbe to get sort direction */
  direction: SortDirection;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /**store the filter value */
  filterText = '';

  /** To get the search text entered in table search */
  @Input() searchKeyword;

  /** To get the boolean value to display no results message */
  @Input() clearResults;

  /** Output the getClearCriteriaChange boolean value */
  @Output() getClearCriteriaChange = new EventEmitter<boolean>();

  @Output() openSelectedList = new EventEmitter<{ client: any, type: string }>();

  /** To get the boolean value on whether the search box has been triggered for results.
   * This determines whether to display the highlighted element in the Milestone column and hide
   * the non-highlighted element */
  @Input() searchTriggered;

  /** sortDirection to hold the asc or desc direction value */
  sortDirection: 'ASC' | 'DESC' | null;

  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: 'clientEntityName' | 'location' | null;

  /** sortOptions to hold the sort column name and sort direction */
  sortOptions = {};

  /** Output the sortOptions */
  @Output() sortList = new EventEmitter<{
    sortBy: string,
    sortDirection: string
  }>();

  @Output() refreshList = new EventEmitter<boolean>();

  constructor(
    public dialog: MatDialog,
    private readonly matSortService: MatSortHeaderIntl,
    public readonly dateConversionService: DateConversionService,
    ) {
    this.dataSource = new MatTableDataSource<any>(this.clientList);
    this.selection = new SelectionModel<any>(true, []);
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 10;
    // tslint:disable-next-line: arrow-return-shorthand
    this.matSortService.sortButtonLabel = (id: string) => { return `"Change sorting for " ${id}`; };
  }


  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.paginator.nextPage();
    }
    // tslint:disable-next-line: deprecation
    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.paginator.previousPage();
    }
  }

  ngOnInit() {
    const paginatorIntl = this.paginator._intl;
    paginatorIntl.nextPageLabel = 'Next';
    paginatorIntl.previousPageLabel = 'Previous';

    this.paginator.pageSize = this.pageEvent.pageSize;
    this.dataSource.paginator = this.paginator;

    setTimeout(() => {
      this.paginator.length = this.clientList.length;
      this.paginator.pageIndex = this.pageEvent.pageIndex;
    }, 10);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clientList && changes.clientList.currentValue) {
      this.dataSource.data = changes.clientList.currentValue;
      // this.paginator.pageSize = changes.clientList.currentValue.length;
      this.dataSource.paginator = this.paginator;
      this.paginator.length = changes.clientList.currentValue.length;
    }
  }

  selectClient(client: AdminClient, type: 'clientName' | 'clientContact' | 'candidates' | 'programs') {
    sessionStorage.setItem('clientId', client.clientId);
    this.openSelectedList.emit({ client, type });
  }

  // localhost:4100/v1/admin/candidate?sortBy=fullName&sortDirection=DESC
  /**
   * Sort table based on Column name.
   * @param columnName Column name to be sorted.
   */
  applySort(columnName: string) {
    this.initSort(columnName);
    this.sortList.emit({
      sortBy: columnName,
      sortDirection: this.sortDirection
    });
  }

  /**
   * Init Sort ..
   * @param columnName Column name to be sorted.
   */
  initSort(columnName: string): void {
    if (!this.sortDirection && !this.sortColumnName) {
      this.sortDirection = 'ASC';
      this.sortColumnName = columnName as any;
      return;
    }
    // tslint:disable-next-line: triple-equals
    this.sortDirection == 'ASC' ? this.sortDirection = 'DESC' : this.sortDirection = 'ASC';
    this.sortColumnName = columnName as any;
  }
}
