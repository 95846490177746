import { Component, EventEmitter, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from './login/login.service';
import { ConnectionService } from 'ng-connection-service';
import { NetworkService } from 'projects/BenefitBuilder/src/app/core/services/network.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'mp360-demo-app';


  constructor(private router: Router, private loginService: LoginService,private networkservice:NetworkService, private swUpdate: SwUpdate) {
  }
  ngOnInit(){
    if (this.swUpdate.isEnabled) {

      this.swUpdate.available.subscribe(() => {

          if(confirm("New version available. Load New Version?")) {

              window.location.reload();
          }
      });
  }  
  }
}
