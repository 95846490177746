import { Component, OnInit, ViewChild, Input, EventEmitter, Output, Inject } from '@angular/core';
import { candidateProfile } from './../../../../../core/models/candidate.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDatepickerInputEvent, MatDialog, MatChip } from '@angular/material';
import { UserTypeService, UserType } from './../../../services/user-type.service';
import { CandidateProfileService } from './../../../services/candidate-profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { AppConfigService } from './../../../../../core/services/app-config.service';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ProgramManagementService } from '../../../services/program-management.service';
import { ScheduledPolicyCallModalComponent } from '../components/scheduled-policy-call-modal/scheduled-policy-call-modal.component';
import { UnconfirmBenefitsModalComponent } from '../components/unconfirm-benefits-modal/unconfirm-benefits-modal.component';
import { UnlockSubmittedHighValueGoodsModalComponent } from '../components/unlock-submitted-high-value-goods-modal/unlock-submitted-high-value-goods-modal.component';
import { formatNumber } from '@angular/common';
import { AdminClient } from './../../../../../core/models/admin-client.model';
import { ProgramHvgReportGenerationService } from '../../../services/program-hvg-report-generation.service';
import { TransfereeBenefitSelectionReportGenService } from '../../../services/transferee-benefit-selection-report-gen.service';
import pdfMake from 'pdfmake/build/pdfmake';
import { formatDate } from '@angular/common';
import { LocationsService } from '../../../services/locations.service';
import { AddEditTransfereeComponent } from '../../../components/add-edit-transferee/add-edit-transferee.component';

import { MilestoneService } from 'projects/BBAdmin/src/app/core/services/milestone.service';
import { Milestones } from 'projects/BBAdmin/src/app/core/models/milestones.model';
import { LinkedListData } from 'projects/BBAdmin/src/app/core/models/linked-list-data.model';
import { DateConversionService } from 'projects/BBAdmin/src/app/core/services/date-conversion.service';
import { SentimentService } from 'projects/BBAdmin/src/app/core/services/sentiment.service';
import { Sentiment } from 'projects/BBAdmin/src/app/core/models/sentiment.model';
import { Sentiments } from 'projects/BBAdmin/src/app/core/models/sentiments.model';
import { MovePhaseService } from 'projects/BBAdmin/src/app/core/services/move-phase.service';
import { ProviderService } from 'projects/BBAdmin/src/app/core/services/provider.service';
import { UpdateDepatureDestinationComponent } from '../update-depature-destination/update-depature-destination.component';
import { WorkDetailPopupComponent } from '../work-detail-popup/work-detail-popup.component';
import { BookingDetailsPopupComponent } from '../booking-details-popup/booking-details-popup.component';
export const workOrderDelWarningMsg = 'Are you sure you want to cancel the Work Order?';
export const CategoryMapping = {
  'Before You Move': 'Before Move',
  'Shipping': 'Shipping',
  'Travel and Transport': 'Travel & Transport',
  'After You Arrive': 'After Arrival',
  'Ongoing': 'Ongoing Support',
  'Relocation Allowances': 'Allowance'
};

export interface Benefit {
  reference?: string;
  productSpecificationReference?: string;
  category?: string;
  displayName?: string;
  description?: string;
  points?: any;
  suppress?: boolean;
  productId?: string;
  hvgDetail?: HVGDetail;
  confirmedDate?: string;
}

export interface HVGDetail {
  allHighValueTaskOptOut?: boolean;
  allHighValueTaskComplete?: boolean;
  highValueItems?: HighValueItem[];
  date?: string;
  status?: string;
  totalReplacementValue?: any;
}

export interface HighValueItem {
  id: string;
  name: string;
  replacementValue: number;
  description: string;
  serialNumber: string;
  dateAdded: string;
}

@Component({
  selector: 'app-transferee-detail',
  templateUrl: './transferee-detail.component.html',
  styleUrls: ['./transferee-detail.component.scss']
})
export class TransfereeDetailComponent implements OnInit {
  public show: boolean;
  public showEmail: boolean;
  public showSN: boolean;
  matExpansionHeaderHeight = '40px';
  isRevealed: boolean;
  consultantinfo: any = {};
  configFields: any = [];
  orderId: string;
  benefits?: any = [];
  benefitsGroupBy: any;
  confirmedPoints?: any;
  businessUnit: any;
  benefitsList: Array<{ group: string, items: Array<Benefit> }>;
  readonly BenefitCategories = ['Before You Move', 'Shipping', 'Travel and Transport', 'After You Arrive', 'Ongoing', 'Relocation Allowances', 'Allowances'];

  /** user role */
  userRole: UserType;

  /**edit point flag */
  editPoint: boolean;
  /**program points */
  points: number;
  /** total family members relocating */
  totalFamilyMembersRelo: number;
  jobStartEdit: false;
  /** Store program array  */
  programValues: any[];

  /** benefits with High Value Goods  */
  benefitsHVG: Benefit[];

  @Input() transferee: any;
  @Output() close = new EventEmitter<boolean>();
  @Output() dateChange: EventEmitter<MatDatepickerInputEvent<any>>;

  /** To display client details in download report*/
  @Input() clientDetails: AdminClient;

  estimatedMoveStartDate: any;
  estimatedMoveEndDate: any;
  hasFamilyDetails = false;

  initaljobStartDate = new FormControl();
  DefaultjobStartDate = 'NA';
  isInProgress = false;
  editProgramName = false;
  editPolicyCallDetails = false;
  timeZone: string;
  policyCallDate: any;
  policyCallTime: any;
  displayPolicyCallTime: any;
  smart_invite_id: string;
  programName: FormControl;
  moveDetailList: any[]= null;
  canUnconfirmBenefit = false;
  candidateDetails: any = {};
  workOrderDetails?: any;
  tempCountryList: any;
  formattedDate;
  candidatePartyId: string;
  isTransactionShow: boolean =false;
  currentChipOrderRequestId : any;
  isVisible: boolean;
  currOrderRequestId: any;
 
  /** holds the data for the Linked list component */
  milestonesData: LinkedListData[] = [];
  /** holds the sentiment data for the Linked list component */
  sentimentData: LinkedListData[] = [];
  preferredCurr : string = "";
  fileNumber: any = null;
  movePhaseAndRiskData:any;
  /**to store transaction amount and currency value from employee-transaction component */
  transactionAmount: any = 0;
  transactionCurrency: any;
  providerCount = 0 ;
  isProvider = false;
  isSearch = false;
  searchProvider; 

  accordinData:any =[];
  cancelledId:any;
  chkForStatus:any;
  displayedColumns: string[] = ['orderno', 'supplier', 'agent','modification', 'status', 'action'];
  mock: any =[];
  orderStatus: any;
  bookingStatus :any;
  bookingData: any;

  constructor(
    private userTypeService: UserTypeService,
    private spinner: NgxSpinnerService,
    private candidateProfileService: CandidateProfileService,
    private appConfig: AppConfigService,
    private toastr: ToastrService,
    private readonly transfereeSelectionPdfGen: TransfereeBenefitSelectionReportGenService,
    private readonly toaster: MatSnackBar,
    // public dialogRef: MatDialogRef<TransfereeDetailComponent>,
    public dialog: MatDialog,
    public live: LiveAnnouncer,
    private programManagementService: ProgramManagementService,
    private readonly cookieService: CookieService,
    private readonly hvgPdfGenerator: ProgramHvgReportGenerationService,
    private readonly locationService: LocationsService,
    private readonly milestoneSvc: MilestoneService,
    private readonly dateSvc: DateConversionService,
    private readonly sentimentSvc: SentimentService,
    private readonly movePhaseService: MovePhaseService,
    private readonly providerSvc: ProviderService,
  ) { }

  ngOnInit() {
    
    this.editPoint = false;
    this.userRole = this.userTypeService.userType;
    if (this.transferee) {
      this.orderId = this.transferee.orderRequestId;
      this.points = this.transferee.programDetails.points;
      this.programName = new FormControl(this.transferee.programDetails.programName);
      this.getTransfereeDetail(this.orderId);
      if (this.userRole === 'file-setup-specialist') {
        this.getProgram(this.transferee.programDetails.divisionPartyId)
      }
      this.isInProgress = this.transferee.moveStatus === 'In Progress'
        && !(this.benefitsList && this.benefitsList.length > 0) ? true : false;
    }
  }

  // getTransferee Details
  getTransfereeDetail(orderRequestId: string) {
    this.candidateProfileService.getTransferee(orderRequestId, this.transferee).subscribe((res) => {
      //for work order accordian
      if (res.benifits) {
        res.benifits.forEach(element => {
          if (element.tempLiving) {
            let worktable: any = {};
            worktable.displayName = element.displayName;
            if (element.supplierInfo.supplierOrderRequests && element.supplierInfo.supplierOrderRequests.length && element.supplierInfo.supplierOrderRequests.length > 1) {
              element.supplierInfo.supplierOrderRequests.sort((order1, order2)=>{
                let ordera : any = new Date(order1.createdAtDate),
                    orderb : any = new Date(order2.createdAtDate);
                    return orderb - ordera;
              })
            }
            const supplierOrd: any = element.supplierInfo.supplierOrders ? element.supplierInfo.supplierOrders : [];
            const supplierOrdReq = element.supplierInfo.supplierOrderRequests ? element.supplierInfo.supplierOrderRequests : [];
            if (supplierOrdReq.length) {
              supplierOrdReq.forEach(ele => {
                ele['isSupplierOrd'] = true;
              })
            }
           // element.supplierInfo['mergedSupOrdAndOrd'] = supplierOrd.concat(supplierOrdReq);
            element.supplierInfo['mergedSupOrdAndOrd'] = supplierOrdReq.concat(supplierOrd);
            //console.log(element.supplierInfo['mergedSupOrdAndOrd']);
            worktable.supplierInfo = element.supplierInfo;
            worktable.hybridTempLiving = element.hybridTempLiving;
            worktable.incrementalCurrencyUnit = element.incrementalCurrencyUnit;
            worktable.rangeIncrementUnit = element.rangeIncrementUnit;
            worktable.reimbursement = (element.incrementalCurrency) ? true : false;
            this.accordinData.push(worktable);
          }
        });
      }
      this.currOrderRequestId = orderRequestId;
      res.candidateDetails.fileNumber? this.fileNumber = res.candidateDetails.fileNumber: this.fileNumber = null;
      if(this.fileNumber && this.currOrderRequestId) {
        this.getmovePhaseAndRiskData(this.fileNumber , this.currOrderRequestId);
      }
      if (res.candidateDetails && res.candidateDetails.authorizationDate) {
        res.candidateDetails['authorizationDate'] = res.candidateDetails.authorizationDate;
      }
      this.candidateDetails = res.candidateDetails;
      this.candidatePartyId = res.candidateDetails.candidateId;
      this.moveDetailList === null? this.getTransfereeMoveDetails(this.candidatePartyId): null;
      if (this.candidateDetails && this.candidateDetails.ccinitiatorNameDetails) {
        this.candidateDetails.ccinitiatorNameDetails['fullName'] = `${this.candidateDetails.ccinitiatorNameDetails.firstName} ${this.candidateDetails.ccinitiatorNameDetails.lastName}`;
      };
      if (this.candidateDetails && this.candidateDetails.createdByNameDetails) {
        this.candidateDetails.createdByNameDetails['fullName'] = `${this.candidateDetails.createdByNameDetails.firstName} ${this.candidateDetails.createdByNameDetails.lastName}`;
      };
      this.totalFamilyMembersRelo = res.candidateDetails.totalNumberOfRelocatingMembers || 0;
      sessionStorage.setItem('car-ses-oid', this.orderId);
      if (document.location.hostname === 'localhost') {
        this.cookieService.set('transferee-context', res.candidateDetails.candidateId, 1, '/', 'localhost', false, 'Lax');
      } else {
        this.cookieService.set('transferee-context', res.candidateDetails.candidateId, null, null, '.cartus.com', true, 'Lax');
      }
      this.hasFamilyDetails = true;

      this.transferee = res.candidateDetails;
      this.consultantinfo = res.consultantinfo;
      this.configFields = res.candidateDetails.additionalDetails;
      this.consultantinfo.hasOwnProperty('timeZone') ? this.timeZone = this.consultantinfo.timeZone : null;
      this.consultantinfo.hasOwnProperty('smart_invite_id') ? this.smart_invite_id = this.consultantinfo.smart_invite_id : null;
      this.candidateDetails.hasOwnProperty('policyCallDetails') && this.candidateDetails.policyCallDetails.hasOwnProperty('policyCallDate')
        ? this.policyCallDate = this.candidateDetails.policyCallDetails.policyCallDate : null;
      if (this.consultantinfo.hasOwnProperty('start')) {
        this.policyCallTime = new Date(this.consultantinfo.start).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
        this.displayPolicyCallTime = moment(this.consultantinfo.start).format('hh:mm A');
      }
      if (this.transferee.additionalDetails && this.transferee.additionalDetails.jobStartDate) {
        this.initaljobStartDate = new FormControl(new Date(this.transferee.additionalDetails.jobStartDate));
        this.setJobStartDate(this.transferee.additionalDetails.jobStartDate);
      }
      this.confirmedPoints = res.hasOwnProperty('confirmedPoints') ? res.confirmedPoints : 0;
      // tslint:disable-next-line: no-unused-expression
      const benefits = res.hasOwnProperty('benifits') ? res.benifits : [];
      this.setHvgBenefits(benefits);
      if (this.userRole === 'file-setup-specialist' && Array.isArray(benefits) && benefits.length > 0) {
        this.canUnconfirmBenefit = true;
      }

      this.benefits = benefits;
      this.benefitsList = this.groupBenefits(benefits);
      if(this.fileNumber) {
        this.milestoneSvc.getMilestonesByFileNum(this.fileNumber ,this.currOrderRequestId).subscribe((milestone: Milestones) => {
          this.createMilestonesData(milestone);
        });
        //sentiments
      this.sentimentSvc.getSentimentsByFileNum(this.fileNumber ,this.currOrderRequestId).subscribe((sentiment: Sentiments) => {
        this.createSentimentData(sentiment.timeLine);
      });
      //providers
    this.providerSvc.getRecentProvidersByFileNum(this.fileNumber, this.preferredCurr)
    .subscribe((providers) => {
      this.providerCount = providers.suppliers.length;
    });

      }
    });
    

  }

  

  /** setup the key dates data for the linked list component */
 createMilestonesData(milestones: Milestones): void {
  if (milestones.data) {
    milestones.data.forEach(element => {
      let ownerName = '';

        if (element.milestoneDesc === 'Authorized') {
          ownerName = '(by ' + milestones.initiatorFirstName + ' ' + milestones.initiatorLastName + ' )';
        } else {
          ownerName = '';
        }
        this.milestonesData.push({
          value: this.dateSvc.convertToYYYYMMDD(element.milestoneDate),
          valueClass: 'active',
          columns: [{
            label: element.milestoneDesc,
            subLabel: ownerName
          }]
        } as LinkedListData);
      });
    }
  }

  /** setup the sentiment data for the linked list component */
  createSentimentData(data: Sentiment[]): void {
    if (data) {
      data.sort((a, b) =>
        this.dateSvc.convertToEpoch(a.sentimentCompletedDate) -
        this.dateSvc.convertToEpoch(b.sentimentCompletedDate)
      );
      data.reverse().forEach(element => {
        this.sentimentData.push({
          value: this.dateSvc.convertToYYYYMMDD(element.sentimentCompletedDate),
          valueClass: 'active',
          columns: [
          {
            label: element.sentimentName
          },
          {
            label: element.sentimentLevel,
            type: 'star'
          }
          ]
        } as LinkedListData);
      });
    }
  }
      /**Function to open the mat Dialog on click of add */
      openDialog(isTraditional) {
        const transfereeData = {
          nameDetails: this.transferee.nameDetails,
          emailDetailsList: this.transferee.emailDetailsList,
          phoneDetailsList: this.transferee.phoneDetailsList
        };
        const dialogRef = this.dialog.open(AddEditTransfereeComponent, {
          data: {
                  isTraditional: isTraditional,
                  associateTransferee: true,
                  candidate: transfereeData,
                  candidatePartyId: this.candidatePartyId
                },
          panelClass: 'dialog-full-width',
          id: 'addEditDialog'
        });
        dialogRef.afterClosed().subscribe(data => {
          if (data) { this.getTransfereeMoveDetails(this.candidatePartyId); }
        });
      }
    
      onChipClick(chipRef: MatChip, candidate: any) {
        this.isTransactionShow = false;
        this.isProvider = false;
        let previousChipSelection = this.currentChipOrderRequestId;
        if (candidate.moveStatus === 'In Progress' || candidate.moveStatus === 'Benefits Confirmed') {
          this.isVisible = true;
          // this.currOrderRequestId !== candidate.orderRequestId? this.getTransfereeDetail(candidate.orderRequestId): null;
        } else {
          this.currentChipOrderRequestId = candidate.orderRequestId;
          this.isVisible = false;
          const dialogRef = this.dialog.open(AddEditTransfereeComponent, {
            id: 'EditDialog',
            data: {
              candidate: candidate,
              editTransferee: true,
            },
            panelClass: 'dialog-full-width'
          });
          dialogRef.afterClosed().subscribe(res => {
            if(res) {
              this.getTransfereeMoveDetails(this.candidatePartyId);
            }
            this.currentChipOrderRequestId = previousChipSelection;
            this.isVisible = true;
          });
        }
      }

  setHvgBenefits(benefits: Benefit[]) {
    this.benefitsHVG = benefits.filter(ele => ele.hasOwnProperty('hvgDetail'));
    if (this.benefitsHVG.length > 0) {
      this.benefitsHVG.forEach(ele => {
        if (ele.hvgDetail.hasOwnProperty('allHighValueTaskOptOut') || ele.hvgDetail.hasOwnProperty('allHighValueTaskComplete')) {
          this.setStatusOfHVGBenefit(ele);
        }
        if (ele.hvgDetail.hasOwnProperty('highValueItems')) {
          this.setTotalReplacementValue(ele);
        }
      });
    }
  };

  setStatusOfHVGBenefit(benefit: Benefit) {
    status = (!benefit.hvgDetail.allHighValueTaskComplete && !benefit.hvgDetail.allHighValueTaskOptOut) ? `In Progress` :
      benefit.hvgDetail.allHighValueTaskComplete ? `Submitted` : `Opted Out`;
    benefit.hvgDetail['status'] = status;
  }

  setTotalReplacementValue(benefit: Benefit) {
    if (benefit.hvgDetail.hasOwnProperty('highValueItems')) {
      const value = benefit.hvgDetail.highValueItems.reduce((a, { replacementValue }) => a + replacementValue, 0);
      benefit.hvgDetail['totalReplacementValue'] = value ? formatNumber(Math.ceil(value), 'en-US') : 0;
    } else {
      benefit.hvgDetail['totalReplacementValue'] = 0;
    }
  }

  formatReplacementValue(value) {
    return `${formatNumber(Math.ceil(value), 'en-US')}`
  }

  openDownloadToastr(downloadFor?: string) {
    // tslint:disable-next-line: max-line-length
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toaster.open(message, 'DOWNLOAD', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    });
    downloadFor === 'benefitBuilderDetail'? toaster.onAction().subscribe(_ => this.downloadPdf()): toaster.onAction().subscribe(_ => this.downloadHvgPdf());
  };

  downloadHvgPdf() {
    const currentDate = new Date();
    this.formattedDate = formatDate(currentDate, 'MMMM_dd_yyyy_HH_mm_ss', 'en-US');
    this.spinner.show();
    if (this.benefitsHVG.length) {
      pdfMake.createPdf(this.hvgPdfGenerator.generateDocumentDefinition(this.benefitsHVG, this.clientDetails, this.transferee, this.orderId)).download(`${this.formattedDate}_${this.transferee.nameDetails.firstName}`);
      this.spinner.hide();
    } else {
      this.toastr.error('Error fetching Benefits', 'Error');
      this.spinner.hide();
    }
  };

  /**
   * download benefit builder details -- transferee benefits selection as pdf
   */
   downloadPdf() {
    const currentDate = new Date();
    this.formattedDate = formatDate(currentDate, 'MMMM_dd_yyyy', 'en-US');
    this.spinner.show();
    if(this.benefitsList && this.benefitsList.length) {
      pdfMake.createPdf(this.transfereeSelectionPdfGen.generateDocumentDefinition(this.benefitsList, this.clientDetails, this.transferee,this.confirmedPoints)).download(`${this.formattedDate}_${this.transferee.nameDetails.firstName}`);
      this.spinner.hide();
    } else {
      this.toastr.error('Error fetching Benefits', 'Error');
      this.spinner.hide();
    }
  };

  getProgram(division) {
    this.programValues = this.candidateProfileService.getProgram(division);
    /*  this.candidateProfileService.getProgram(division).subscribe(res => {
       //this.programValues = res.cc_divisionPrograms;
       this.programValues = res.cc_divisionPrograms;
     }); */
  }

  toggle() {
    this.show = !this.show;
  }

  toggleEmail() {
    this.showEmail = !this.showEmail;
  }

  toggleSN() {
    this.showSN = !this.showSN;
  }

  reveal() {
    this.isRevealed = !this.isRevealed;
  }

  closeTransferee() {
    this.totalFamilyMembersRelo = 0;
    this.close.emit(true);
  }

  launchBB() {
    this.spinner.show();
    const userContext = JSON.parse(sessionStorage.getItem('UserContext'));
    const name = `${userContext.firstName || 'NA'} ${userContext.lastName || 'NA'}`;
    this.candidateProfileService.getSharedSessionCode(this.orderId, name).subscribe(data => {
      if (data) {
        this.spinner.hide();
        const urlToOpen = this.appConfig.configData.bbUIUrl + '#/shared/' + data.session;
        window.open(urlToOpen, '_blank');
      } else {
        this.spinner.hide();
        this.toastr.error('Error generating shared session');
      }
    });
  }

  makePolicyCallComplete() {
    this.candidateProfileService.makePolicyCallComplete(this.orderId).subscribe((res) => {
      // tslint:disable-next-line: no-shadowed-variable
      this.candidateProfileService.getTransferee(this.orderId, this.transferee).subscribe((res) => {
        this.transferee = res.candidateDetails;
        this.consultantinfo = res.consultantinfo;
        const benefits = res.benifits;
        res.confirmedPoints ? this.confirmedPoints = res.confirmedPoints : null;
        this.benefitsList = this.groupBenefits(benefits);
        this.spinner.hide();
      });
      this.spinner.hide();
    });
  }

  groupBenefits(benefits: Array<Benefit>): Array<{ group: string, items: Array<Benefit> }> {
    benefits = this.updateCategoryNames(benefits);
    const benefitsByGroup = Object.values(CategoryMapping).map(category => {
      return {
        group: category,
        items: benefits.filter(benefit => benefit.category === category)
      };
    });
    return benefitsByGroup;
  }

  updateCategoryNames(benefits: Array<Benefit>) {
    const benefitCategories = [...this.BenefitCategories.map(i => i.toLowerCase())];
    let categoryMapping = {};
    Object.keys(CategoryMapping).map(i => {
      categoryMapping[i.toLowerCase()] = CategoryMapping[i];
    });
    categoryMapping['allowances'] = 'Allowance';
    return benefits.map(benefit => {
      if ((benefitCategories).includes(benefit.category.toLowerCase())) {
        benefit.category = categoryMapping[benefit.category.toLocaleLowerCase()];
      }
      return benefit;
    });
  }

  editPointValidation() {
    // tslint:disable-next-line: triple-equals
    if (this.points) {
      let message: string;
      this.editPoint = !this.editPoint;
      // const program = this.programValues.find( prog => prog.programName = this.addCandidateForm.value.programDetails.programName);
      if (!this.editPoint && this.transferee.programDetails.points !== +this.points && +this.points > +this.transferee.programDetails.points) {
        if (+this.transferee.programDetails.points * 0.25 + +this.transferee.programDetails.points <= +this.points) {
          message = 'You have entered points exceeding 25% of Program standard';
          this.toastr.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        }
        this.candidateProfileService.updatePoints(this.orderId, { points: +this.points }).subscribe(res =>
          this.transferee.programDetails.points = this.points
        );
      } else {
        this.points = this.transferee.programDetails.points;
      }
    }
  }
  updateTotalRelocatingMembers(event) {
    this.transferee.totalNumberOfRelocatingMembers = this.totalFamilyMembersRelo = event + 1;
  }

  // transferee move details
  getTransfereeMoveDetails(candidateId) {
    // this.spinner.show();
    let clientId;
    if(this.userRole === 'client-contact-initiator') {
      clientId = sessionStorage.getItem("cc_clientPartyId");
    } else {
      clientId = this.clientDetails.clientId;
    }
    this.candidateProfileService.getMoveDetails(candidateId, clientId,this.transferee).subscribe((res) => {
      if (res) {
        this.moveDetailList = res;
        /**to get country code to display in transferee move details chip */
        this.locationService.countryList.subscribe(countryListData => {
          if (countryListData.length > 0 && this.moveDetailList.length > 0) {
            this.moveDetailList.forEach(data => {
              if(data.departureAddr) {
                this.tempCountryList = countryListData.find(ele => ele.countryName === data.departureAddr.country)
                data.departureAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
              }
              if(data.destinationAddr) {
                this.tempCountryList = countryListData.find(ele => ele.countryName === data.destinationAddr.country)
                data.destinationAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
              }
            });
          }
        });
      }
    }, (err) => {
        this.toastr.error('Error Fetching Move Details.', null, {
          timeOut: 4000
        });      
    });
  }



  editProgramDetails() {
    this.editProgramName = true;
    this.transferee.programDetails.editProgram = true;
    if (this.transferee.programDetails.editProgram && this.transferee.programDetails.programName !== this.programName.value) {
      this.candidateProfileService.updateProgramDetails(this.orderId, {
        programName: this.programName.value,
        clientId: sessionStorage.getItem('clientId')
      }).subscribe((res) => {
        if (res.code && res.code === 'PROGRAM_NAME_UPDATE_SUCCESS') {
          this.transferee.programDetails.programName = this.programName.value;
          this.benefitsList = this.groupBenefits([]);
          this.confirmedPoints = 0;
          this.editProgramName = false;
          this.transferee.programDetails.editProgram = false;
        } else {
          this.programName.setValue(this.transferee.programDetails.programName);
          this.toastr.error('Error updating program name');
        }
      });
    }

  }

  updatePoints(e) {
    const program: any = this.programValues.filter((item) => item.programName === this.programName.value);
    this.transferee.programDetails.points = program[0].points;
    this.points = program[0].points;
  }

  setJobStartDate(value) {
    this.DefaultjobStartDate = moment(value).format('MM/DD/YYYY');
  }

  jobStartDateChange(val, e) {
    const existingJobStartDate = this.DefaultjobStartDate;
    this.setJobStartDate(e.target.value);
    this.candidateProfileService.updateJobStartDate(this.orderId, { jobStartDate: `${moment(e.target.value).format().split('T')[0]}T00:00:00.000Z` }).subscribe(
      (res) => {
        if (res.code && res.code === 'UPDATE_SUCCESS') {
          this.setJobStartDate(e.target.value);
        } else {
          this.DefaultjobStartDate = existingJobStartDate;
          this.toastr.error('Error updating Job start Date');
        }
      });
  }

  /**
   * Invoked on click of dismiss/close
   */
  unconfirmBenefitDialog() {
    // tslint:disable-next-line: no-use-before-declare
    const dialogRef = this.dialog.open(TWarningDialogComponent, {
      id: 'dialog2',
      width: 'auto',
      data: {
        message: 'Are you sure you want to Unconfirm all the benefits?'
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.unconfirmBenefits();
      }
    });

  }

  /**
   * To unconfirmed selected benefit
   * @param benefit benefit to be unconfirmed
   */
  unconfirmSelectedBenefit(benefit: Benefit) {
    const dialogRef = this.dialog.open(UnconfirmBenefitsModalComponent, {
      id: 'dialog-unconfirm',
      width: 'auto',
      data: benefit,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result.action && result.action === 'delete') {
        this.unconfirmBenefitById(benefit.productId, benefit);
      }
    });
  }
  /**
   * To unconfirm benefit by id
   * @param productId benefit product Id
   */
  unconfirmBenefitById(productId: string, unconfirmBenefit: Benefit) {
    this.spinner.show();
    this.candidateProfileService.unconfirmSelectedBenefit(this.orderId, productId, unconfirmBenefit, this.confirmedPoints).subscribe(res => {
      if (res) {
        this.benefits = [];
        this.benefitsList.map(benefitGroup => {
          benefitGroup.items = benefitGroup.items.filter(benefit => !res.unconfirmedBenefits.includes(benefit.reference));
          return benefitGroup;
        });
        // Update Points.
        if (!(this.confirmedPoints > res.updatedConfirmedPoints)) {
          if (this.transferee.moveStatus === 'Benefits Confirmed') {
            this.transferee.moveStatus = 'In Progress';
          }
        }
        // benefitsHVG list updating after unconfirming benefit
        this.benefitsHVG = [...this.benefitsHVG.filter((ele) => {
          return ele.productId !== productId
        })];
        this.isInProgress = this.transferee.moveStatus === 'In Progress'
          && !(this.benefitsList && this.benefitsList.length > 0) ? true : false;
        this.confirmedPoints = res.updatedConfirmedPoints;
        this.spinner.hide();
        this.toastr.info('Benefit has been unconfirmed successfully', null, {
          timeOut: 4000
        });
      } else {
        this.spinner.hide();
        this.toastr.error('Failed to unconfirm benefits. Try again.', null, {
          timeOut: 4000
        });
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error('Failed to unconfirm benefits. Try again.', null, {
        timeOut: 4000
      });
    });
  }

  unconfirmBenefits() {
    this.spinner.show();
    this.candidateProfileService.unconfirmBenefits(this.orderId).subscribe(res => {
      if (res) {
        this.benefits = [];
        this.benefitsList = this.groupBenefits([]);
        this.setHvgBenefits(this.benefits);
        this.spinner.hide();
        this.canUnconfirmBenefit = true;
        this.toastr.info('Benefits has been unconfirmed successfully', null, {
          timeOut: 4000
        });
        this.editProgramName = true;
        this.transferee.programDetails.editProgram = true;

        // Update Points.
        this.confirmedPoints = 0;
        this.isInProgress = true;
      } else {
        this.spinner.hide();
        this.toastr.error('Failed to unconfirm benefits. Try again.', null, {
          timeOut: 4000
        });
      }
    }, (err) => {
      this.spinner.hide();
      this.toastr.error('Failed to unconfirm benefits. Try again.', null, {
        timeOut: 4000
      });
    });
  }

  unlockSubmittedHighValueGoodsPopup(item: any) {
    if (this.userRole === 'consultant') {
      let dialogRef = this.dialog.open(UnlockSubmittedHighValueGoodsModalComponent, {
        data: {
          benefit: item
        },
        panelClass: 'DataviewSelectionModal',
        autoFocus: false,
        restoreFocus: false
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.code === 'HVG_UNLOCK_UPDATE_SUCCESS') {
          const resHVGBenifit = this.benefitsHVG.find(benefit => benefit.productId === item.productId);
          resHVGBenifit.hvgDetail.hasOwnProperty('allHighValueTaskOptOut') ? resHVGBenifit.hvgDetail.allHighValueTaskOptOut = false : null;
          resHVGBenifit.hvgDetail.hasOwnProperty('allHighValueTaskComplete') ? resHVGBenifit.hvgDetail.allHighValueTaskComplete = false : null;
          !resHVGBenifit.hvgDetail.hasOwnProperty('highValueItems') ? resHVGBenifit.hvgDetail['highValueItems'] = [] : null;
          this.setTotalReplacementValue(resHVGBenifit);
          this.setHvgBenefits(this.benefits);
        }
      });
    }
  }
  openSchedulePolicyCallModal() {
    const dialogRef = this.dialog.open(ScheduledPolicyCallModalComponent, {
      data: {
        timeZone: this.timeZone,
        policyCallDate: this.policyCallDate,
        policyCallTime: this.policyCallTime,
        orderId: this.orderId,
        partyid: this.candidateDetails.candidateId,
        smart_invite_id: this.smart_invite_id,
        candidateDetails: this.candidateDetails,
        consultantinfo: this.consultantinfo,
      },
      panelClass: 'makeItMiddle',
      autoFocus: false,
      restoreFocus: false,
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.policyCallDate = moment(result.start).format('YYYY-MM-DD');
        this.policyCallTime = new Date(result.start).toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
        this.displayPolicyCallTime = moment(result.start).format('hh:mm A');
      }
    });
  }

  /* get Move Phase and Move Risk Data */
  getmovePhaseAndRiskData(filenumber, orderRequestId: string) {
    this.movePhaseService.getSelectedMovePhaseDetails(filenumber, orderRequestId)
      .subscribe(
        data => {
          if (data) {
            this.movePhaseAndRiskData = data;
          }
        })
  }

  getTransactionAmt(transactionAmt) {
    this.transactionAmount = transactionAmt;
  }
  displayTransactionAmtValue(value) {
    return `${formatNumber(Math.ceil(value), 'en-US')}`;
  }
  getTransactionCurrency(transactionCur) {
    this.transactionCurrency = transactionCur;
  }
  leftArrowClick() {
    this.isVisible = true;
    this.isTransactionShow = false;
    this.isProvider = false;
  }
   /**Transaction */
   viewTransaction() {
    this.isVisible = false;
    this.isTransactionShow = true;
    //this.isTransactionShow = !this.isTransactionShow;
  }
  viewProviders() {
    this.isVisible = false;
    //this.isProvider = true;
    this.isProvider = !this.isProvider;
  }
  openSearch(){
    this.isSearch =true;
  }
  closeSearch(){
    this.isSearch =false;
  }
  clearTextSearch() {
    this.searchProvider = '';
  }
  getProviderCount(count) {
    this.providerCount = count;
  }

  openDialoge() {
    const dialogRef = this.dialog.open(UpdateDepatureDestinationComponent, {
      width: '52vw',
      data: {
        departureAddr: this.transferee.departureAddr,
        destinationAddr: this.transferee.destinationAddr,
        currOrderRequestId: this.currOrderRequestId
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.transferee.departureAddr = data.departureAddr;
        this.transferee.destinationAddr = data.destinationAddr;
        this.moveDetailList[0].departureAddr = data.departureAddr;
        this.moveDetailList[0].destinationAddr = data.destinationAddr;
        this.getTransfereeMoveDetails(this.candidatePartyId);
      }
    });
  }
  /**work order popup */
  checkForDisable(index) {
    const firstIndex = this.accordinData[index].supplierInfo.mergedSupOrdAndOrd[0].status ==='cancelled';
    const firstIndexInProgress = ['in-progress','paused','pending'].includes(this.accordinData[index].supplierInfo.mergedSupOrdAndOrd[0].status);
    const modifactionstatusCheck = this.checkForModificationCancelStatus(index);
    if(firstIndex ) return false; // first index not cancell status
    if(firstIndexInProgress) return true;
    if(!firstIndexInProgress && modifactionstatusCheck) return false; // first index not cancell status but modification contains reject status
    return true;
  }
  checkForModificationCancelStatus(index) {
    if (this.accordinData[index].supplierInfo.supplierOrders && this.accordinData[index].supplierInfo.supplierOrders.length) {
      const supOrdLen = this.accordinData[index].supplierInfo.supplierOrders.length;
      for (let supOrd = 0; supOrd < supOrdLen; supOrd++) {
        if (this.accordinData[index].supplierInfo.supplierOrders[supOrd].modifications && this.accordinData[index].supplierInfo.supplierOrders[supOrd].modifications.length) {
          const rejectModIndex = this.accordinData[index].supplierInfo.supplierOrders[supOrd].modifications.findIndex(mod => mod.status === "Rejected");
          if (rejectModIndex > -1) {
            return false;
          }
        }
      }
    }
    return true;
  }
  
  checkForModificationStatus(element) {
    let flag = false;
    if(element.modification > 0 && element.modifications.length) {
      element.modifications.forEach(mod => {
        if(!mod.hasOwnProperty('status')) {
          flag = true;
        }
      });
    }
    return flag;
  }
 /**work order popup */
  workDetailPoup(modificationClicked,id, index,checkStatus, woi?:any, supplierOrderRequest?:any,refernce?:any,modification?:any) {
    let benefitData;
    let suppRefernce;
    let suppData;
    benefitData = this.accordinData[index];
    if(supplierOrderRequest != 'undefined') {
      this.orderStatus = this.accordinData[index].supplierInfo.mergedSupOrdAndOrd.find(order => order.supplierRequestId === id || order.fromRequest === id).status;
    }
    if(supplierOrderRequest != 'undefined'&&!supplierOrderRequest && refernce != null ){
      suppRefernce = refernce ;
    }
    suppData = this.accordinData[index].supplierInfo.mergedSupOrdAndOrd.find(orderId => orderId.supplierRequestId == id || orderId.fromRequest === id).modifications;
    this.candidateProfileService.getWorkorderDetails(id,supplierOrderRequest)
      .subscribe(
        data => {
          if (data) {
            this.candidateProfileService.getCurrencies().subscribe(res => {
              let curencieRes = res.values
              if (res.values.length > 0) {
                this.workOrderDetails = data;
                this.spinner.hide();
                let dataObj = {
                    details: this.workOrderDetails,
                    orderID: id,
                    offering: benefitData.displayName,
                    curencieRes: curencieRes,
                    clientDetails: this.clientDetails,
                    transfereeDetails: this.transferee,
                    orderStatusDate: data.orderStatusDate,
                    // orderStatus:this.orderStatus,
                    checkForStatus:checkStatus,
                    checkHybridTempliving: benefitData.hybridTempLiving,
                    reimbursement: benefitData.reimbursement,
                    supplierData:suppData,
                    rangeIncrementUnit: benefitData.rangeIncrementUnit,
                    incrementalCurrencyUnit: benefitData.incrementalCurrencyUnit,
                    chckForModification : !supplierOrderRequest,
                    modificationClicked : modificationClicked
                }
                checkStatus =='addOnCancelled' ? dataObj['orderStatus']= 'Open' : dataObj['orderStatus']= this.orderStatus  ;
                suppRefernce? dataObj['supplierRef']=suppRefernce : null;
                modification? dataObj['modification']=modification : null;
                const dialogRef = this.dialog.open(WorkDetailPopupComponent, {
                  width: '60vw',
                  data: dataObj,
                  disableClose: true
                })
                dialogRef.afterClosed().subscribe(data => {
                  if (data.data.status == 'status' || data.data.status == 'null' || data.data.sts == 'null' || data.data.sts == 'status') {
                    if (this.accordinData[index].supplierInfo.supplierOrderRequests) {
                      this.accordinData[index].supplierInfo.supplierOrderRequests[woi].status = data.data.suppOrderStatus;
                      this.accordinData[index].supplierInfo.supplierOrderRequests[woi].statusDate = data.data.currDate;
                    }
                    if(data && data.data && data.data.submitWorkOrder) {
                      this.accordinData[index].supplierInfo.supplierOrderRequests[woi].isSupplierOrd = false;
                      this.accordinData[index].supplierInfo.supplierOrderRequests[woi].modification = 1;
                    }
                    if (data && data.data && data.data.req) {
                      this.prepareNeedsAssessmentData(data.data.req);
                      this.prepareHousePrefData(data.data.req);
                      this.prepareCustomerRelocationData(data.data.req);
                    }
                    if (data && data.data && data.data.workOrder) {
                      data.data.submitWorkOrder ? this.accordinData[index].supplierInfo.supplierOrderRequests[woi].isSupplierOrd = false  : true;
                      //this.accordinData[index].supplierInfo.supplierOrderRequests[woi].status = 'cancelled';
                      let tempData = this.accordinData[index].supplierInfo.mergedSupOrdAndOrd;
                      let finalData = [...tempData, ...data.data.workOrder]
                      this.accordinData[index].supplierInfo.mergedSupOrdAndOrd = finalData;
                    }
                    //this.accordinData = [];
                    //this.getTransfereeDetail(this.orderId);
                  }
                });
              }
            });
          }
        }
      )
  }

  prepareCustomerRelocationData(data) {
      this.workOrderDetails.customerRelocationDetails.filePriority = data.relocationDetailsReq.filePriority;
      this.workOrderDetails.customerRelocationDetails.serviceDeliveryLocation = data.relocationDetailsReq.serviceDeliveryLocation;
      this.workOrderDetails.customerRelocationDetails.financialResponsibility = data.relocationDetailsReq.financialResponsibility;
      this.workOrderDetails.customerRelocationDetails.splitBillComments = data.relocationDetailsReq.splitBillComments;
      this.workOrderDetails.customerRelocationDetails.provider = data.relocationDetailsReq.provider;
      this.workOrderDetails.customerRelocationDetails.clientDirectedProviderComment = data.relocationDetailsReq.clientDirectedProviderComment;
      this.workOrderDetails.customerRelocationDetails.requiresResidencyLetter = data.relocationDetailsReq.requiresResidencyLetter;
      this.workOrderDetails.customerRelocationDetails.rush = data.relocationDetailsReq.rush;
      this.workOrderDetails.customerRelocationDetails.daysAuthorized = data.relocationDetailsReq.daysAuthorized;
      this.workOrderDetails.customerRelocationDetails.clientBudgetAmount = data.relocationDetailsReq.clientBudgetAmount;
      this.workOrderDetails.customerRelocationDetails.clientBudgetCurrency = data.relocationDetailsReq.clientBudgetCurrency;
  }

  prepareNeedsAssessmentData(data) {
    let preparePetsInfo = [];
    if (data.assessNeedsFormValues.petsInformation) {
      data.assessNeedsFormValues.petsInformation.forEach(i => {
          let tempPetDetails = {
            breed: i.breed,
            name: i.name,
            type: i.type,
            weight: i.weight,
          }
          preparePetsInfo.push(tempPetDetails);
        })
    }
    this.workOrderDetails.needsAssessment.departureLocation = data.assessNeedsFormValues.departureLocation;
    this.workOrderDetails.needsAssessment.petsInformation = preparePetsInfo;
    this.workOrderDetails.petDepositApproved = data.assessNeedsFormValues.petDepositApproved;
    this.workOrderDetails.travelHistory = data.assessNeedsFormValues.travelHistory;
    // this.workOrderDetails.accompanyingMembers = data.req.assessNeedsFormValues.accompanyingMembers;
  }

  prepareHousePrefData(data) {

    this.workOrderDetails.housingPreference.desiredLocation.country = data.payload.desiredLocation.country;
    this.workOrderDetails.housingPreference.desiredLocation.state = data.payload.desiredLocation.state;
    this.workOrderDetails.housingPreference.desiredLocation.city = data.payload.desiredLocation.city;
    this.workOrderDetails.housingPreference.desiredLocation.streetLine1 = data.payload.desiredLocation.streetLine1;
    this.workOrderDetails.housingPreference.desiredLocation.postalCod = data.payload.desiredLocation.postalCode;

    this.workOrderDetails.housingPreference.alternateLocation.country = data.payload.alternateLocation.country;
    this.workOrderDetails.housingPreference.alternateLocation.state = data.payload.alternateLocation.state;
    this.workOrderDetails.housingPreference.alternateLocation.city = data.payload.alternateLocation.city;
    this.workOrderDetails.housingPreference.alternateLocation.streetLine1 = data.payload.alternateLocation.streetLine1;
    this.workOrderDetails.housingPreference.alternateLocation.postalCode = data.payload.alternateLocation.postalCode;

    this.workOrderDetails.housingPreference.destinationOfficeLocation.country = data.payload.destinationOfficeLocation.country;
    this.workOrderDetails.housingPreference.destinationOfficeLocation.state = data.payload.destinationOfficeLocation.state;
    this.workOrderDetails.housingPreference.destinationOfficeLocation.city = data.payload.destinationOfficeLocation.city;
    this.workOrderDetails.housingPreference.destinationOfficeLocation.streetLine1 = data.payload.destinationOfficeLocation.streetLine1;
    this.workOrderDetails.housingPreference.destinationOfficeLocation.postalCode = data.payload.destinationOfficeLocation.postalCode;

    this.workOrderDetails.housingPreference.priorities = data.payload.priorities;

    this.workOrderDetails.housingPreference.additionalInstructions = data.payload.additionalInstructions;
    this.workOrderDetails.housingPreference.moveInDate = data.payload.moveInDate;
    this.workOrderDetails.housingPreference.moveOutDate = data.payload.moveOutDate;
    this.workOrderDetails.housingPreference.numberApartments = data.payload.numberApartments;
    this.workOrderDetails.housingPreference.numberBedrooms = data.payload.numberBedrooms;
    this.workOrderDetails.housingPreference.babyCotRequired = data.payload.babyCotRequired;
    this.workOrderDetails.housingPreference.acceptableCommute = data.payload.acceptableCommute;

    this.workOrderDetails.housingPreference.housekeepingInfo.housekeepingFrequency = data.payload.housekeepingInfo.housekeepingFrequency;
    this.workOrderDetails.housingPreference.housekeepingInfo.comments = data.payload.housekeepingInfo.comments;

    this.workOrderDetails.housingPreference.parkingInfo.parkingApproved = data.payload.parkingInfo.parkingApproved;
    this.workOrderDetails.housingPreference.parkingInfo.comments = data.payload.parkingInfo.comments;

    this.workOrderDetails.housingPreference.accommodationType = data.payload.accommodationType;
    this.workOrderDetails.housingPreference.furnished = data.payload.furnished;
    this.workOrderDetails.housingPreference.kitchenRequired = data.payload.kitchenRequire;
    this.workOrderDetails.housingPreference.lengthOfStay = data.payload.lengthOfStay;
    this.workOrderDetails.housingPreference.numberOfBathrooms = data.payload.numberOfBathrooms;

  }

  add(i, benefit) {
    let id;
    const index = benefit.supplierInfo.mergedSupOrdAndOrd.findIndex(ele => ele.status == 'cancelled');
    if (index >= 0) {
      id = benefit.supplierInfo.mergedSupOrdAndOrd.find(ele => ele.status == 'cancelled').fromRequest || benefit.supplierInfo.mergedSupOrdAndOrd.find(ele => ele.status == 'cancelled').supplierRequestId;
    } else {
      id = benefit.supplierInfo.supplierOrders.find(ele => ele.status != 'cancelled').fromRequest;
    }
    this.workDetailPoup(false,id,i,'addOnCancelled', 0, true);
  }

  bookingDetailPopup(id, index, woi, reference?:any){
    let dispName;
    dispName = this.accordinData[index].displayName;
    this.candidateProfileService.getBookingDetails(id).subscribe(data=>{    
      if(data){   
        this.bookingData = data
      }
      const dialogRef = this.dialog.open(BookingDetailsPopupComponent, {
        width: '60vw',
        data: {
              supID: reference,
              bookingDetail:data,
              id:id,
              clientDetails: this.clientDetails,
              transfereeDetails: this.transferee,
              offering: dispName,
              statusDate: data.statusDate
        },
        disableClose: true
      })
      dialogRef.afterClosed().subscribe(data => {
        if (data.data.status == 'status') {
           this.accordinData[index].supplierInfo.supplierOrderRequests[woi].status =  data.data.suppOrderStatus;
           if (data && data.data && data.data.bookingDetails) {
            this.prepareBookingDetailsData(data.data.bookingDetails);
          }
          // this.accordinData = [];
          // this.getTransfereeDetail(this.orderId);
        }
      });
    })
  }

  prepareBookingDetailsData(data) {

    this.bookingData.propertyName = data.propertyName;
    this.bookingData.providerName = data.providerName;
    this.bookingData.rentPaidBy = data.rentPaidBy;
    this.bookingData.apartmentType = data.apartmentType;
    
    this.bookingData.address.city = data.address.city;
    this.bookingData.address.state = data.address.state;
    this.bookingData.address.country = data.address.country;
    this.bookingData.address.postalCode = data.address.postalCode;
    this.bookingData.address.streetLine1 = data.address.streetLine1;

    this.bookingData.moveDates.moveIn = data.moveDates.moveIn;
    this.bookingData.moveDates.moveOut = data.moveDates.moveOut;
  }

  isDeleteEnable(benefit){
    let disableDelete = true
    benefit.supplierInfo && benefit.supplierInfo.mergedSupOrdAndOrd ? benefit.supplierInfo.mergedSupOrdAndOrd.forEach(element => {
      if(element.status !== 'cancelled') {
        disableDelete = false;
        return false;
      }
    }) : null;
    return disableDelete;
  }

  deleteWorkorder(id, index, woi,supplierOrderRequest) {
    const dialogRef = this.dialog.open(DWarningDialogComponent, {
      id: 'dialog3',
      width: 'auto',
      autoFocus: false,
      data: {
        message: workOrderDelWarningMsg
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.action === 'delete') {
        dialogRef.close();
      if (result && result.data && result.data.status ) {
        this.accordinData[index].supplierInfo.supplierOrderRequests[woi].status = 'cancelled' ;
      }
        // this.accordinData = [];
        // this.candidateProfileService.deleteWorkOrder(id, supplierOrderRequest).subscribe(data => {
        //   this.getTransfereeDetail(this.orderId);
        // });
      }
    });

  }

}
  


/**
 * Component to display warning dialog box on dismiss
 */
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'warning-dialog',
  styleUrls: ['./transferee-detail.component.scss'],
  template: `
  <style>
      button:focus  { text-decoration: underline; }
      div:focus { outline: none !important }
  </style>
  <div class="warning-popup" mat-dialog-content tabindex = "0"><span aria-hidden = 'true'>{{ data.message }}</span><div>
  <div>
   <button class="edit-ok" mat-button tabindex = 0 (click)='onNoClick()'>Yes</button>
   <button class="edit-cancel" mat-button tabindex = 0 mat-dialog-close>Cancel</button>
 </div>`
  //  <style>button:focus  {    text-decoration: underline;}
  //         div:focus { outline: none !important}
  //         .warning-popup{padding: 10px 30px !important;}
  //   </style>
})
export class TWarningDialogComponent {

  constructor(
    public dialog: MatDialogRef<TWarningDialogComponent>,
    public live: LiveAnnouncer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    this.live.announce(this.data.message);
  }

  onNoClick(): void {
    this.dialog.close({
      action: 'dismiss'
    });
  }

}

/**
 * Component to display warning dialog box on delete workorder
 */
 @Component({
  selector: 'app-warning-dialog',
  template: `<div mat-dialog-content>{{ data.message }}<div>
    <div class="button-wrapper">
     <button mat-button (click)='onDelete()' class="delete-card">Yes</button>
     <button mat-button [mat-dialog-close]='true' cdkFocusInitial class="cancel">No</button>
   </div>`,
})
export class DWarningDialogComponent {
  constructor(
    public dialog: MatDialogRef < DWarningDialogComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  onDelete(): void {
    this.dialog.close({
      data: {
        status: 'status'
      },
      action: 'delete'
    });
  }
}

