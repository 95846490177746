export const userContacts = {
  "phone": [
    {
      "phoneNumber": "9483186756",
      "phoneDialingCode": "",
      "phoneNumberDesc": "business"
    }
  ],
  "email": [
    {
      "emailAddress": "M.William@demo.com",
      "emailAddressDesc": "business"
    }
  ]
}