import { Injectable, HostListener, NgZone } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { AppConfigService } from './app-config.service';
import { CollaborationService } from './collaboration.service';
import { CollabEvents } from './collaboration-events.service';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {

  // Mouse Coordinates
	lastY: any;
  lastX: any;

  idleTimeoutMinutes = 15;
  /** Idle timeout cookie name */
  idleCookieName = 'lastAction';
  /** Idle timeout keep-alive interval */
  pingInterval = 0;
  /** Idle timeout poll interval */
  pollInterval = 1000;
  /** Idle timeout check time */
  idleCheckSeconds = 60;
  /** NodeJS Timeout */
  lastTimeOut: any;

  constructor(
    private cookieService: CookieService,
    private _ngZone: NgZone,
    private router: Router,
    private appConfig: AppConfigService,
    private collaboration: CollaborationService
  ) {
    this.startIdleTimeCountDown();
  }

  /** Listen for mouse events */
	@HostListener('document:mousemove', ['$event'])
	onMouseMove(e: any) {
	  if (e.pageX !== this.lastX || e.pageY !== this.lastY) {
		this.lastX = e.pageX;
		this.lastY = e.pageY;
		this.refreshIdleCookie();
	  }
	}

	/** Listen for keypress events */
	@HostListener('document:keypress', ['$event'])
	onKeyPress() {
	  this.refreshIdleCookie();
	}

	refreshIdleCookie() {
		const idleExpireMs = new Date().getTime() + (this.idleTimeoutMinutes * 60000) - 500;
		if (document.location.hostname === 'localhost') {
		  this.cookieService.set(this.idleCookieName, idleExpireMs.toString()); // Developer
		} else {
		  this.cookieService.set(
			this.idleCookieName,
			idleExpireMs.toString(),
			1,
			'/',
			'.cartus.com',
			true
		  );
		}
  }

  /** Start idle timeout feature */
  startIdleTimeCountDown() {
    if (this.lastTimeOut) {
      clearTimeout(this.lastTimeOut);
    }
    this.refreshIdleCookie();
    this._ngZone.runOutsideAngular(() => {
      this.lastTimeOut = setTimeout(this.checkIdle.bind(this), this.pollInterval);
    });
  }

  /** Check idle timeout status */
  checkIdle() {
    return;
    const sesTok = this.cookieService.get('car-ses-tok');
    const idleExpireMs = parseInt(this.cookieService.get(this.idleCookieName), 10);
    const currTimeMs = new Date().getTime();
    if (!sesTok || currTimeMs > idleExpireMs) {
      this.logout();
    } else {
      this.pingInterval += this.pollInterval;
      if (this.pingInterval === 1000 * this.idleCheckSeconds) {
        this.pingInterval = 0;
      }
      this._ngZone.runOutsideAngular(() => {
        this.lastTimeOut = setTimeout(this.checkIdle.bind(this), this.pollInterval);
      });
    }
  }

  logout() {

    this.collaboration.sendMessage({
      event: CollabEvents.USER_LOGGED_OUT,
      data: null
    });

    this.collaboration.close();

    setTimeout(() => {
      this.cookieService.deleteAll();
      this.cookieService.deleteAll(null, '.cartus.com');
      sessionStorage.clear();
      const logoutURL = this.appConfig.getConfig('logout');

      this.router.navigate(['/externalRedirect', { externalUrl: logoutURL }], {
        skipLocationChange: true
      });
    }, 1000);

  }

}
