
<p *ngIf="!isNeedsAssessmentCompleted"> Transferee has not completed essential information yet. </p>
<div fxLayout="column" fxLayoutAlign.xs="speace-around center" class="family-info-wrapper"
  *ngIf="retrievedFamilyDetails && isNeedsAssessmentCompleted">


  <div fxLayout="row" class="scroll-content" fxLayout.xs="column" fxLayoutAlign.xs="unset unset"
    fxLayoutAlign.sm="space-between unset"
    *ngIf="(familyDetails && familyDetails.length) || (newFamilyInformation && newFamilyInformation > 0) ">

    <!-- Family member card with details updated-->
    <ng-container *ngFor="let member of familyDetails; trackBy:trackByFn ; index as i">
      <ng-container [ngTemplateOutlet]="familyMemberCard" [ngTemplateOutletContext]="{member:member,index:i}">
      </ng-container>
    </ng-container>

    <!-- Family member empty cards-->
    <ng-container *ngIf="newFamilyInformation && newFamilyInformation > 0">
      <ng-container *ngFor="let val of [].constructor(newFamilyInformation); index as i">
        <ng-container [ngTemplateOutlet]="familyMemberCard"
          [ngTemplateOutletContext]="{index:(familyDetails.length+i)}"> </ng-container>
      </ng-container>
    </ng-container>

  </div>
 <div fxFlex="48%" fxFlex.md="48%" *ngIf="updatedFamilyLength < 15 && !isFamilyReadOnly">
    <button class="add-family-member" fxLayoutAlign="start flex-end"  title="Add New Member" (click)="addFamilyMember()"
      aria-label="add new member">
      <mat-icon class="add-person" aria-hidden="false">person_add</mat-icon>
      <span class="add-member" role="button">Add New Member</span>
    </button>
  <!-- </div> -->
</div>

<!-- Family information wrapper card template-->
<ng-template #familyMemberCard let-member='member' let-index='index'>
  <div fxFlex="23%" fxFlex.xs="100%" fxFlex.md="38%" fxFlex.sm="45%"
    [ngClass]="(index+1)%4==0?'family-card-wrapper':'family-card-wrapper m-r-3'">
    <mat-card fxFlex="100%" fxLayout="column" role="region" tabindex="0"
      [attr.aria-label]="member? (member.nameDetails.title + member.nameDetails.firstName + member.nameDetails.lastName + ' Details') : 'Add Member Information'">

      <div fxLayout="row" fxLayoutAlign="flex-end center" class="edit-div">
        <button *ngIf="!isFamilyReadOnly" title="Edit Family Member" aria-label="edit family member"
          (click)="member ? editFamilyMember(index) : addFamilyMember()" class="edit-btn">
        </button>
      </div>
      <!-- Family info with details updated -->
      <ng-container *ngIf="member" [ngTemplateOutlet]="familyCardWithDetails"
        [ngTemplateOutletContext]="{member:member}"></ng-container>

      <!-- Family info empty card template -->
      <ng-container *ngIf="!member" [ngTemplateOutlet]="familyCardNoDetails"></ng-container>

      <div fxFlex="100%" fxLayout="row" [ngClass]="hasFamilyDetails?'delete-wrapper m-t-50':'delete-wrapper m-t-21'"
        fxLayoutAlign="end end">
        <button *ngIf="!isFamilyReadOnly" type="submit" title="Delete Family Member" (click)="deleteFamilyMember(index)"
          fxLayout="row" fxLayoutAlign="center end" aria-label="delete family member">
          <mat-icon class="delete-icon" aria-hidden="true">delete</mat-icon>
          <span class="delete-text" fxHide.xs=true role="none">Delete</span>
        </button>
      </div>
    </mat-card>
  </div>
</ng-template>
<!-- Family information wrapper card template-->

<!-- Family info empty card template -->
<ng-template #familyCardNoDetails>
  <div class="title" fxFlex="100%" fxLayoutAlign="center center" fxLayout="column" fxLayoutAlign.xs="start start">
    <img class="person-icon" aria-hidden="false" aria-label="person icon"
      src="../../../../../../../assets/images/family-user.svg" alt="user" />
  </div>
  <div class="details" fxFlex="100%" fxLayoutAlign="center center" fxLayout="column" fxLayoutAlign.sm="start start"
    fxLayoutAlign.xs="start start">
    <p class="add-member-info">Add Member Information</p>
    <div class="placeholder"></div>
    <div class="placeholder"></div>
    <div class="placeholder"></div>
  </div>
</ng-template>
<!-- Family info empty card template -->


<!-- Family info with details updated -->
<ng-template #familyCardWithDetails let-member='member'>
  <div class="title" fxFlex="100%" fxLayoutAlign="center center" fxLayout="column" fxLayoutAlign.xs="start start">
    <span class="user-initials"
      *ngIf="member.nameDetails">{{member.nameDetails.firstName.charAt(0) | titlecase}}{{member.nameDetails.lastName.charAt(0) | titlecase}}</span>
    <p class="relationship" role="note">{{member.relationshipType}}</p>
  </div>
  <div class="details" fxFlex="100%" fxLayoutAlign="center center" fxLayout="column" fxLayoutAlign.sm="start start">
    <p *ngIf="member" class="member" role="note">{{member.nameDetails.title}} {{member.nameDetails.firstName}}
      {{member.nameDetails.lastName}}
    </p>

    <p class="dob" *ngIf="dateOfBirth && dateOfBirth !== null" role="note">DOB: {{member.nameDetails.dateOfBirth}}</p>
    <p class="dob" *ngIf="!dateOfBirth || dateOfBirth == null" role="note">DOB: NA</p>

    <ng-container *ngIf="member.phoneDetailsList && member.phoneDetailsList.length">
      <ng-container *ngFor="let phone of member.phoneDetailsList | getPreferredPhone:phone">
        <p class="phone-no" *ngIf="phone" role="note">
          <app-sensitive-info-mask type="phone" [value]="phone.phoneNumber" [dialingCode]="phone.phoneDialCode">
          </app-sensitive-info-mask>
        </p>

        <p class="phone-no" *ngIf="!phone" role="note"> NA </p>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="!member.phoneDetailsList || !member.phoneDetailsList.length">
      <p class="phone-no" role="note"> NA </p>
    </ng-container>
    <ng-container *ngIf="member.emailDetailsList && member.emailDetailsList.length">
      <ng-container *ngFor="let email of member.emailDetailsList | getPreferredPhone:email">
        <p class="email" *ngIf="email" role="note">
          <app-sensitive-info-mask type="email" [value]="email.emailAddress">
          </app-sensitive-info-mask>
        </p>
        <p class="email" *ngIf="!email" role="note">NA</p>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="!member.emailDetailsList || !member.emailDetailsList.length">
      <p class="email" role="note"> NA </p>
    </ng-container>
  </div>
</ng-template>
<!-- Family info with details added -->