import moment from 'moment';

export const getDatefromCurrentDate = (numberOfdays, isFuture) => {
  let currentDate: any = new Date();
  if (isFuture) {
    return moment(currentDate.setDate(currentDate.getDate() + numberOfdays)).format('YYYY-MM-DD');
    //logic to add number of days for current date;
  } else {
    return moment(currentDate.setDate(currentDate.getDate() - numberOfdays)).format('YYYY-MM-DD')//logic to reduce number of days for currentdate
  }
}

export const programListData = {
  "clientId": "5df51953eb1bc880568c6a93",
  "divisionList": [
    {
      "division_id": "5df51959eb1bc893788c6a96",
      "division_name": "People & Culture",
      "division_number": "032"
    },
    {
      "division_id": "5df51958eb1bc86aa78c6a95",
      "division_name": "Research and Development",
      "division_number": "033"
    },
    {
      "division_id": "5df5195aeb1bc8ced68c6a97",
      "division_name": "International Division",
      "division_number": "034"
    },
    {
      "division_id": "5df5195deb1bc8a9f68c6a99",
      "division_name": "Government Division",
      "division_number": "035"
    },
    {
      "division_id": "5df5195ceb1bc8c70f8c6a98",
      "division_name": "US Domestic Division",
      "division_number": "036"
    }
  ],
  "programs": [
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "Expat - Long Term Program",
      "baseProgramName": "Expat - Long Term",
      "id": "602aa62a98b3720021abb971",
      "clientPolicy": "Global Test Policy",
      "contractId": 501,
      "totalPoints": 75,
      "policyCallRequired": true,
      "isCartusInsured": true,
      "programActivationDate": `${getDatefromCurrentDate(3, false)}`,
      "programExpirationDate": `${getDatefromCurrentDate(60, true)}T00:00:000Z`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "One Way Program",
      "baseProgramName": "Expat - Long Term",
      "id": "602bcabc8dfbdb0021a13574",
      "clientPolicy": "Global Test Policy",
      "contractId": 501,
      "totalPoints": 76,
      "policyCallRequired": true,
      "isCartusInsured": true,
      "programActivationDate": `${getDatefromCurrentDate(2, false)}`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "Repat Program",
      "baseProgramName": "Expat - Long Term",
      "id": "602bce018dfbdb0021a13575",
      "clientPolicy": "Global Test Policy",
      "contractId": 501,
      "totalPoints": 76,
      "policyCallRequired": true,
      "isCartusInsured": true,
      "draft": false,
      "programActivationDate": `${getDatefromCurrentDate(4, false)}`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "Intern 1 Program_Not-Insured",
      "baseProgramName": "Intern 1",
      "id": "5fdcbfac7f278e00205966b2",
      "clientPolicy": "78H - Homeowner",
      "contractId": 501,
      "totalPoints": 3,
      "policyCallRequired": false,
      "isCartusInsured": false,
      "draft": false,
      "programActivationDate": `${getDatefromCurrentDate(3, false)}`,
      "programExpirationDate": `${getDatefromCurrentDate(75, true)}T00:00:000Z`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "Executive Renter Program",
      "baseProgramName": "Executive Renter",
      "id": "5fdcba0d7f278e00205966af",
      "clientPolicy": "78H - Homeowner",
      "contractId": 501,
      "totalPoints": 45,
      "policyCallRequired": true,
      "isCartusInsured": true,
      "draft": false,
      "programActivationDate": `${getDatefromCurrentDate(5, false)}`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "Expat - Long Term_Not-Insured",
      "baseProgramName": "Expat - Long Term",
      "id": "602bd33b8dfbdb0021a13576",
      "clientPolicy": "Global Test Policy",
      "contractId": 501,
      "totalPoints": 76,
      "policyCallRequired": false,
      "isCartusInsured": false,
      "programActivationDate": `${getDatefromCurrentDate(3, false)}`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "Executive Homeowner Program",
      "baseProgramName": "Executive Homeowner",
      "id": "601421bafc26ec00214deae0",
      "clientPolicy": "78H - Homeowner",
      "contractId": 501,
      "totalPoints": 55,
      "policyCallRequired": true,
      "isCartusInsured": true,
      "draft": false,
      "programActivationDate": `${getDatefromCurrentDate(1, false)}`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "Standard Renter Program",
      "baseProgramName": "Expat - Long Term",
      "id": "60142246fc26ec00214deae1",
      "clientPolicy": "Global Test Policy",
      "contractId": 501,
      "totalPoints": 66,
      "policyCallRequired": true,
      "isCartusInsured": true,
      "draft": false,
      "programActivationDate": `${getDatefromCurrentDate(3, false)}`,
      "programExpirationDate": `${getDatefromCurrentDate(90, true)}T00:00:000Z`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "One Way Program_Not-Insured",
      "baseProgramName": "Expat - Long Term",
      "id": "6009ca8a4cacd40020610dd5",
      "clientPolicy": "Global Test Policy",
      "contractId": 501,
      "totalPoints": 66,
      "policyCallRequired": true,
      "isCartusInsured": false,
      "draft": false,
      "programActivationDate": `${getDatefromCurrentDate(2, false)}`,
      "programExpirationDate": `${getDatefromCurrentDate(50, true)}T00:00:000Z`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "Standard Homeowner_Not-Insured",
      "baseProgramName": "Standard Homeowner",
      "id": "5fa2580c8e79100020a6d605",
      "clientPolicy": "Candidate",
      "contractId": 23,
      "totalPoints": 23,
      "policyCallRequired": true,
      "isCartusInsured": false,
      "programActivationDate": `${getDatefromCurrentDate(3, false)}`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "High Value US Inbound Program",
      "baseProgramName": "Executive Renter",
      "id": "5fd129351e314200203a386a",
      "clientPolicy": "78H - Homeowner",
      "contractId": 501,
      "totalPoints": 25,
      "policyCallRequired": true,
      "isCartusInsured": true,
      "draft": false,
      "programActivationDate": `${getDatefromCurrentDate(6, false)}`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "High Value_Not-insured",
      "baseProgramName": "Executive Homeowner",
      "id": "5f9c2222b9ea470020778d57",
      "clientPolicy": "78H - Homeowner",
      "contractId": 501,
      "totalPoints": 35,
      "policyCallRequired": true,
      "isCartusInsured": false,
      "programActivationDate": `${getDatefromCurrentDate(3, false)}`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "US Inbound Program",
      "baseProgramName": "Executive Renter",
      "id": "5fd0ff5a1e314200203a3869",
      "clientPolicy": "78H - Homeowner",
      "contractId": 501,
      "totalPoints": 22,
      "policyCallRequired": true,
      "isCartusInsured": true,
      "programActivationDate": `${getDatefromCurrentDate(4, false)}`
    },
    {
      "divisions": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "US Outbound Program",
      "baseProgramName": "Executive Renter",
      "id": "5fc6735dd6246a00203fc53e",
      "clientPolicy": "78H - Homeowner",
      "contractId": 501,
      "totalPoints": 25,
      "policyCallRequired": true,
      "isCartusInsured": true,
      "programActivationDate": `${getDatefromCurrentDate(1, false)}`
    },
    {
      "divisions": [
        {
          "division_id": "5df51958eb1bc8e7f38c6a94",
          "division_name": "Demo Client",
          "division_number": "000"
        },
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "High Value Homeowner_Not-Insured",
      "baseProgramName": "Executive Homeowner",
      "id": "5f4e9e66beb10400207e2dc1",
      "clientPolicy": "Points Policy",
      "contractId": 506,
      "totalPoints": 50,
      "policyCallRequired": true,
      "isCartusInsured": false,
      "programActivationDate": `${getDatefromCurrentDate(3, false)}`,
      "programExpirationDate": `${getDatefromCurrentDate(90, true)}T00:00:000Z`
    },
    {
      "divisions": [
        {
          "division_id": "5df51958eb1bc8e7f38c6a94",
          "division_name": "Demo Client",
          "division_number": "000"
        },
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "Standard Renter_Deactivated",
      "baseProgramName": "Standard Renter",
      "id": "5f2bea82a846530020395721",
      "clientPolicy": "Domestic Site assignments",
      "contractId": 434,
      "totalPoints": 50,
      "programExpirationDate": "2020-10-29T00:00:00.000Z",
      "policyCallRequired": true,
      "isCartusInsured": false
    },
    {
      "divisions": [
        {
          "division_id": "5df51958eb1bc8e7f38c6a94",
          "division_name": "Demo Client",
          "division_number": "000"
        },
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "name": "Executive Homeowner_Deactivated",
      "baseProgramName": "Executive Homeowner",
      "id": "5f2240a602f79700209a67ec",
      "clientPolicy": "Canadian Tier 2 homeowner",
      "contractId": 12,
      "totalPoints": 23,
      "programExpirationDate": "2020-11-05T00:00:00.000Z",
      "policyCallRequired": true,
      "isCartusInsured": false
    },
  ],
  "count": 37
}