import { Component, OnInit, Inject, Input } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { Benefit } from './../../../../../benefits/services/benefit.service';
import { CookieService } from 'ngx-cookie-service';
import { MatDialog } from '@angular/material';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-benefits-detail-modal',
  templateUrl: './benefits-detail-modal.component.html',
  styleUrls: ['./benefits-detail-modal.component.scss']
})
export class BenefitsDetailModalComponent implements OnInit {

  benefit: Benefit;
  cashoutValue = 0;
  cashoutInvaild = false;
  disableDecrement = true;
  disableIncrement = false;
  maxCashoutValue = 0;
  minCashoutValue = 0;
  isCashoutDetail = false;
  cashoutDollarValue = 500;
  minIncrementCount = 1;
  /** Flag to check if logged in user is consultant or transferee */
  isConsultant = false;
  /** List of points valueto be displayed in cashout dropdown */
  cashoutPointsList: Array < number >= [];
  totalCashoutselections = 0;
  /**Total number of available selections */
  availableCashoutSelection = 0;
  points=[];
  //[{"days":"15 days","points":"2 points"},{"days":"30 days","points":"4 points"},{"days":"45 days","points":"6 points"},{"days":"60 days","points":"8 points"}];
  deleteDropdown;
  checkDeleteHybrid:boolean= true;

  constructor(
      private bottomSheetRef: MatBottomSheetRef < BenefitsDetailModalComponent > ,
      @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
      private readonly cookieService: CookieService
  ) {
    this.benefit = this.data.hasOwnProperty('benefit') ? this.data.benefit : this.data;
      if (this.benefit.tierConfigs) {
          if (this.benefit.selectedCashOutPoints) {
              this.benefit.advCashOutSelectedPoints = this.benefit.selectedCashOutPoints;
              this.benefit.advCashOutPointValue = this.benefit.selectedCashOutValue;
          }
          this.generateCashoutPointsList(this.benefit);
          this.isCashoutDetail = true;          
      } else if (this.benefit.pointsPerIncrement) { // for increment card
          this.minIncrementCount = (this.benefit.rangeMin / this.benefit.rangeIncrementValue);
          this.minIncrementCount = ((this.benefit.selectedIncrementCount || 0) + (this.benefit.confirmedIncrementCount || 0)) <
              this.minIncrementCount ? this.minIncrementCount : 1;
      } else if ( this.benefit.perPointCurrency) { // for dollar per point increment card
        this.minIncrementCount = (this.benefit.rangeMin / this.benefit.rangeIncrementValue);
        this.minIncrementCount = ((this.benefit.selectedDollarIncrementCount || 0) + (this.benefit.confirmedDollarIncrementCount || 0)) <
            this.minIncrementCount ? this.minIncrementCount : 1;
    }
      if (this.cookieService.get('transferee-context')) {
          this.isConsultant = true;
    }
    if(this.benefit.selectedIncrementCount > 0) {
      for(let i = 0; i< this.benefit.selectedIncrementCount; i++) {
        this.points[i]= {
          'point':this.benefit.pointsPerIncrement*(i+1),
          'days':this.benefit.rangeIncrementValue*(i+1),
          'index':i+1
        }
  
      }
      this.deleteDropdown = new FormControl(this.points[0].days);
      console.log(this.points);
      
    }
  }

  ngOnInit() {
    (this.benefit.selectedbenefitCount || this.benefit.selectedbenefitCount === 0 ) ?
    this.benefit.selectedCount = this.benefit.selectedbenefitCount  : null;
  }

  deleteBenefit(benefit: Benefit, deleteAllHybrid?: boolean) {
    this.bottomSheetRef.dismiss({
      type: 'delete',
      benefit: benefit,
      deleteAllHybrid: deleteAllHybrid ? true : false
    });
  }

  deleteHybridBenefit(benefit: Benefit, selectedCount: any) {
    // this.checkDeleteHybrid
    // if(!this.checkDeleteHybrid){
      selectedCount && benefit.hybridTempLiving ? benefit['hybridDelCount'] = selectedCount : null;
      this.deleteBenefit(benefit);
    
    
  }
  /**
   * To close dialog and pass benefit details to be added to selected benefits
   * @param benefit Benefit details
   */
  selectBenefit(benefit: Benefit) {
    this.bottomSheetRef.dismiss({
      type: 'add',
      benefit: benefit
    });
  }

  closeDetails() {
    this.bottomSheetRef.dismiss();
  }

  /**
   * To update the count of selected benefit
   * @param benefit benefit selected
   * @param selectedCount count of multiples of benefits selected
   */
  updateMultiplesOfBenefit(benefit: Benefit, selectedCount) {
    benefit['selectedbenefitCount'] = (benefit.perPointCurrency ? benefit.selectedDollarIncrementCount : benefit.selectedCount || 0 );
    benefit.selectedCount = (benefit.selectedCount || 0 )+ selectedCount;
    this.selectBenefit(benefit);
  }

    /**
   * To update the count of selected cashout benefit
   * @param benefit benefit selected
   * @param selectedCount count of multiples of benefits selected
   */
  updateMultiplesOfCashoutBenefit(benefit: Benefit, selectedPoints : number, selectedCount: number) {
    benefit['selectedCashOutPoints'] = (benefit.advCashOutSelectedPoints || 0 );
    if(benefit.pointsPerSelection) {
      benefit.advCashOutSelectedPoints = (((benefit.advCashOutSelectedPoints || 0 )+ (selectedCount * benefit.pointsPerSelection)));
    } else {
      benefit.advCashOutSelectedPoints = (benefit.advCashOutSelectedPoints || 0 )+ selectedCount;
    }
    benefit['selectedCashOutValue'] = (benefit.advCashOutPointValue || 0 );
    benefit.advCashOutPointValue = (benefit.advCashOutPointValue || 0 ) + selectedPoints;
    this.selectBenefit(benefit);
  }

  /**
   * To update the count of selected benefit
   * @param benefit benefit selected
   * @param selectedIndex index of benefits selected
   */
  updateSelectedIncrementalBenefit(benefit: Benefit, selectedIndex) {
   benefit['selectedbenefitCount'] =  selectedIndex + this.minIncrementCount;

  //  this.minIncrementCount = benefit.selectedbenefitCount < this.minIncrementCount ? this.minIncrementCount : 1;

     this.selectBenefit(benefit);
  }

  checkForCashoutValid(){
    if(this.cashoutValue>this.maxCashoutValue){
      this.cashoutInvaild=true;
    }
    else{
      this.cashoutInvaild=false;
    }
  }

  /**
   * To activate or hide consultant only benefit
   * @param benefit Benefit
   * @param type Type of action
   */
  activatehideBenefit(benefit: Benefit, type: 'activate'| 'hide'| 'activateSelect' | 'hideRemove') {
    this.bottomSheetRef.dismiss({
      type: type,
      benefit: benefit
    });
  }

  /**
   * To generate the list of cahout points and dollar value based on tiers
   * @param benefit Benefit 
   */
  generateCashoutPointsList(benefit: Benefit) {
      let benefitTiers = JSON.parse(JSON.stringify(benefit.tierConfigs));
      // if a benefit has been selected or confirmed
      const totalPoints = ((benefit.advCashOutSelectedPoints || 0) + (benefit.confirmedAdvCashOutPoints || 0)) / benefit.pointsPerSelection;
      if (totalPoints > 0) {
          benefitTiers = benefitTiers.filter(tier => {
              this.totalCashoutselections += tier.maxSelection;
              if (totalPoints >= tier.maxRange) {
              } else if (totalPoints >= tier.minRange && totalPoints < tier.maxRange) {
                  tier.minRange = totalPoints + 1;
                  return tier;
              } else return tier;
          });
      } else {
          this.totalCashoutselections = benefit.tierConfigs[benefit.tierConfigs.length - 1].maxRange;
      }
      for (let i = 0; i < benefitTiers.length; i++) {
          let currentRange = benefitTiers[i].minRange;
          while (currentRange <= benefitTiers[i].maxRange) {
              this.cashoutPointsList.push((this.cashoutPointsList[currentRange - 2 - (totalPoints || 0)] || 0) + benefitTiers[i].pointValue);
              currentRange++;
          }
      }
      benefit.pointsPerSelection? benefit['rangeMax'] = benefit.tierConfigs[benefit.tierConfigs.length - 1].maxRange * benefit.pointsPerSelection: benefit['rangeMax'] = this.totalCashoutselections;
      this.availableCashoutSelection = this.totalCashoutselections - totalPoints;
  }
}
