import { Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, Inject, Output, EventEmitter, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserTypeService, UserType } from '../../services/user-type.service';

@Component({
  selector: 'app-filter-program-modal',
  templateUrl: './filter-program-modal.component.html',
  styleUrls: ['./filter-program-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FilterProgramModalComponent implements OnInit {


  selectedStatus: boolean[];
  statuses: string[] = ['Active', 'Draft', 'Deactivated'];
  expirationStartDate: Date;
  expirationEndDate: Date;
  policyChecked: boolean;
  isCartusInsuredChecked: boolean;
  addPolicyToFilter: boolean;
  addIsCartusInsuredFilter: boolean;
  /** user role */
  userRole: UserType;

  constructor(
    public dialogRef: MatDialogRef<FilterProgramModalComponent>,
    private userTypeService: UserTypeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
 
  ngOnInit() {
    this.selectedStatus = [...this.data.selectedStatus];
    this.expirationStartDate = this.data.expirationStartDate;
    this.expirationEndDate = this.data.expirationEndDate;
    this.policyChecked = this.data.policyChecked;
    this.isCartusInsuredChecked = this.data.isCartusInsuredChecked;
    this.addPolicyToFilter = this.data.addPolicyToFilterFlag;
    this.addIsCartusInsuredFilter = this.data.addIsCartusInsuredFilterFlag;
    this.userRole = this.userTypeService.userType;
   }

  onSubmit() {
    let statusPayload = [];
    statusPayload = [...this.statuses.map((ele, index) => this.selectedStatus[index] ? ele.toLowerCase() : null)]
    this.dialogRef.close(Object.assign({}, {
      selectedStatus: [...statusPayload],
      date: {
        expirationStartDate: this.expirationStartDate ? this.expirationStartDate : null,
        expirationEndDate: this.expirationEndDate ? this.expirationEndDate : null
      },
      policyChecked: this.policyChecked,
      isCartusInsuredChecked: this.isCartusInsuredChecked,
      selectedStatusFlags: [...this.selectedStatus],
      expirationStartDateFlag: this.expirationStartDate,
      expirationEndDateFlag:this.expirationEndDate,
      policyCheckedFlag: this.policyChecked,
      isCartusInsuredCheckedFlag: this.isCartusInsuredChecked,
      addPolicyToFilterFlag: this.addPolicyToFilter,
      addIsCartusInsuredFilterFlag: this.addIsCartusInsuredFilter,
      reset:false
    }));
  }

  close() {
    this.dialogRef.close(Object.assign({}, {
      reset:true
    }));
  }
}
