import { Component, OnInit, OnDestroy, ViewChild, HostListener, NgZone, ViewEncapsulation } from '@angular/core';
import { NavListComponent } from './public/components/nav-list/nav-list.component';
import { Router, RoutesRecognized, NavigationEnd, NavigationStart } from '@angular/router';
import { Title, DomSanitizer } from '@angular/platform-browser';
import { Subscription, of } from 'rxjs';
import { take } from 'rxjs/operators';
import { AppConfigService } from './core/services/app-config.service';
import { CookieService } from 'ngx-cookie-service';
import { UserSessionService } from './core/services/user-session.service';
import { environment } from '../environments/environment';
import Timeout = NodeJS.Timeout;
import { UserContextService } from './core/services/user-context.service';
import { UserContext } from './core/models/user-context.model';
import { MatTableDataSource } from '@angular/material/table';
import { Contexts } from './core/models/contexts.model';
import { HttpClient } from '@angular/common/http';
import { GlobalSearchService } from './core/services/global-search.service';
import { UserPreferencesService } from './core/services/user-preferences.service';
import { Client } from './core/models/client.model';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { UserTypeService, UserType } from './public/administration/services/user-type.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material';
import { LogoutModalComponent } from './public/components/logout-modal/logout-modal.component';
import { ActivatedRoute } from '@angular/router';
import { MoveProDashboadDataService } from './core/data/move-pro-dashboad-data.service';

/** Base application component - builds the site structure */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  /** Navigation component - desktop view */
  @ViewChild('desktopNavList', { static: true }) public desktopNavList: NavListComponent;
  /** Navigation component - mobile view */
  @ViewChild('mobileNavList', { static: true }) public mobileNavList: NavListComponent;
  /** Tracks if nav on desktop is minimized */
  public sidebarMinimized: boolean;
  /** nav api subscription */
  private navSub: Subscription;
  /** Environment configuration, using this to shut off animation for tests */
  public env: any;
  /** Temp: to hide desktop side navbar */
  public isSidebarDisplay: boolean;
  /** Is context selected */
  public contextSelected: boolean;
  /** Show context selection */
  public displayContextSelection: boolean;
  /** Whether to show template elements */
  public showGlobalTemplate: boolean;
  /** Idle timeout minutes */
  idleTimeoutMinutes = 15;
  /** Idle timeout cookie name */
  idleCookieName = 'lastAction';
  /** Idle timeout keep-alive interval */
  pingInterval = 0;
  /** Idle timeout poll interval */
  pollInterval = 1000;
  /** Idle timeout check time */
  idleCheckSeconds = 60;
  /** Idle timeout last mouse X */
  lastX = 0;
  /** Idle timeout last mouse Y */
  lastY = 0;
  /** NodeJS Timeout */
  lastTimeOut: Timeout;
  /**contextsList to store the contexts from the API */
  contextsList: UserContext;
  /** displayedColumns variable array to store the displayed columns */
  displayedColumns: string[] = ['company', 'companyAddr'];
  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<Contexts>;
  /** logoutDialogRef to access the Dialog */
  logoutDialogRef: MatDialogRef<LogoutModalComponent>;
  userRole: UserType;
  clientPartyId: UserType;

  public noContextError = false;

  public termsOfUseUrl: string;
  public privacyNotice: string;

  private userInputTimeout: number;
  private requestSub: Subscription;
  public filteredOptions;
  public responseMessage = '';
  public totalCount = 0;
  public filterText = '';
  public query = '';
  public originalText = '';

  // showMsg is to show/hide the message on UI screen
  public showMsg = false;
  public showCustomMsg = false;
  public responseMessage1 = '';
  public responseMessage2 = '';
  public responseMessage3 = '';
  // showResults is to show/hide the results if the character count is less than 2
  public showResults = false;
  /** showLogout used to determine whether the page displays the logout link */
  showLogout = false;
  /**isViewer variable to hold the isViewer flag value */
  isViewer: Boolean;
  /**isViewer secondary variable to hold the isViewer flag value */
  viewerFlag: Boolean;
  /** clients array holds the list of active clients returned by api */
  clients: Client[];
  /** getClientsSub to hold the subscription of get clients service*/
  getClientsSub: Subscription;
  /** clientContactFlag to display the client contact grid */
  clientContactFlag: boolean;
  selectedClient: Client;
  occupyFullWidth: Boolean = false;
  refreshTokenInterval: any;
  refreshTokenTimeout = 19;
  refreshSessionTimeout = 60;

  /** UserType for the App only for Benefits Builder -> Other, Account Mgr, Product Manager, Consultant */
  userType: UserType;

  /** FOR BB ONLY -> Since not sure if the user will be navigating to Dashboard OR Administration.
   * Will be used to temporary store the Logged in user Context.
   */
  possibleAdminData: Contexts;

  /** FOR BB ONLY -  */
  isAdminOpen: boolean;

  /** Decides whether to show admin link */
  isAdminVisible: boolean;

  /** initialSortColumn to hold the column name for inital sorting. */
  initialSortColumn = 'company';

  /** initialSortDirection to hold the column name for inital sorting. */
  initialSortDirection: 'asc';

  /** logoutImg to hold the logoutImg states */
  logoutImg = {
    src: '',
    srcOn: '../assets/icons/logout_dkblue.svg',
    srcOut: '../assets/icons/logout.svg',
    mobileSrcOut: '../assets/icons/logout-white.svg'
  };

  /** Component instantiation */
  constructor(
    public readonly router: Router,
    private route: ActivatedRoute,
    private readonly appConfig: AppConfigService,
    private readonly titleService: Title,
    private cookieService: CookieService,
    private userSessionService: UserSessionService,
    private userContextService: UserContextService,
    private _ngZone: NgZone,
    private http: HttpClient,
    private globalSearchService: GlobalSearchService,
    private readonly userPreferencesService: UserPreferencesService,
    private liveAnnouncer: LiveAnnouncer,
    private userTypeService: UserTypeService,
    public dialog: MatDialog,
    private moveProDashboadDataService: MoveProDashboadDataService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
  ) {
    matIconRegistry.addSvgIcon(
      'person-one',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/person-one.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'person-two',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/person-two.svg'
      )
    );
    matIconRegistry.addSvgIcon(
      'person-four',
      domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/candidate-assessment/person-four.svg'
      )
    );
   }

  refreshIdleCookie() {
    const idleExpireMs = new Date().getTime() + (this.idleTimeoutMinutes * 60000) - 500;
    if (document.location.hostname === 'localhost') {
      this.cookieService.set(this.idleCookieName, idleExpireMs.toString()); // Developer
    } else {
      this.cookieService.set(
        this.idleCookieName,
        idleExpireMs.toString(),
        1,
        '/',
        '.cartus.com',
        true
      );
    }
  }

  ngOnInit() {    
    if (!this.userSessionService.isTokenValid()) {
      this.userSessionService.clearLoginInfo();
			this.router.navigate(['/login']);
      return;
    }
    this.env = environment; // The file referenced here is dynamic depending on build config
    this.router.url.indexOf('transferee') > 0 ? this.isAdminOpen = true : this.router.url.indexOf('dashboard') > 0 ? this.isAdminOpen = false : this.isAdminOpen = true;
    // this.userSessionService.refreshSession();


    this.termsOfUseUrl = this.appConfig.getConfig('login') + '#/termsOfUse';
    this.privacyNotice = this.appConfig.getConfig('login') + '#/privacyNotice';

    this.navSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.clearSearch();
      }

      if (event instanceof NavigationEnd) {
        const data = this.route.snapshot.firstChild.data;
        let title = event.url.indexOf('transferee') > 0 ? 'Administration' : event.url.indexOf('dashboard') > 0 ? 'Home' : '';
        this.titleService.setTitle('MovePro360 - ' + title);

        this.showGlobalTemplate = data.showGlobalTemplate;
        // ONLY FOR BB
        if (data && title && title === 'Administration') {
          this.isAdminOpen = true;
        } else if (data && title && title === 'Home') {
          this.isAdminOpen = false;
        }

        // Google Analytics
        if ((<any>window).ga) {
          (<any>window).ga('set', 'page', window.location.pathname + '#' + event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }
      }
    });
    if ((<any>window).ga) {
      const googleAnalyticsConfig: { [key: string]: string } = this.appConfig.getConfig('googleAnalytics');
      (<any>window).ga('create', googleAnalyticsConfig.key, 'auto'); // Start Google Analytics session
    }
    this.showGlobalTemplate = true;
    this.contextSelected = true;
    this.displayContextSelection = false;
    this.isSidebarDisplay = false;
    this.getUserRoles();

    const userType = this.userTypeService.userType;
    if (userType) {
      this.userType = userType;
      if (this.userType && (this.userType === 'client-account-manager' || this.userType === 'consultant' || this.userType === 'program-admin' ||
        this.userType === 'file-setup-specialist' || this.userType === 'application-reliability-analyst' || this.userType === 'application-owner')) {
        this.viewerFlag = true;
      }
    }

    this.postInit();
  }

  /** Component Angular initialization lifecycle hook */
  /* ngOnInit() {
    console.log('ngOnInit:');
    this.env = environment; // The file referenced here is dynamic depending on build config

    // this.userSessionService.refreshSession();


    this.termsOfUseUrl = this.appConfig.getConfig('login') + '#/termsOfUse';
    this.privacyNotice = this.appConfig.getConfig('login') + '#/privacyNotice';

    this.navSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.clearSearch();
      }
      if (event instanceof RoutesRecognized) { // We're outside router-outlet, hence must use this event to see route data
        const data = event.state.root.firstChild.data;
        // this.selectNavItemById(data.navId); since we are hiding the nav bar Commenting the line for time being.
        this.titleService.setTitle('MovePro360 - ' + data.title);

        this.showGlobalTemplate = data.showGlobalTemplate;
        // ONLY FOR BB
        if (data && data.title && data.title === 'Administration') {
          this.isAdminOpen = true;
        }

      }

      if (event instanceof NavigationEnd) {
        // Google Analytics
        if ((<any>window).ga) {
          (<any>window).ga('set', 'page', window.location.pathname + '#' + event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }
      }
    });
    if ((<any>window).ga) {
      const googleAnalyticsConfig: { [key: string]: string } = this.appConfig.getConfig('googleAnalytics');
      (<any>window).ga('create', googleAnalyticsConfig.key, 'auto'); // Start Google Analytics session
    }
    this.showGlobalTemplate = false;
    if (sessionStorage.getItem('car-ses-con')) {
      this.contextSelected = true;
    } else {
      this.contextSelected = false;
    }
    // this.contextSelected = false;
    this.displayContextSelection = false;
    this.isSidebarDisplay = false;
    if (!!this.appConfig.getConfig('mocked')) { // Run e2e without real session
      if (sessionStorage.getItem('car-ses-con')) {
        this.postInit();
      } else {
        if (sessionStorage.getItem('car-ses-subcon')) {
          this.postInit();
        } else {
          this.contextSelected = false;
          this.getUserContext();
        }
        // Below commented lines to be removed after R2 release
        // this.contextSelected = false;
        // this.getUserContext();
      }
    } else {
      console.log('in else')
      const sessionCookie: string = this.cookieService.get('car-ses-tok');
      if (sessionCookie == null || sessionCookie.length === 0) {
        this.logout();
      } else {

        // Session Exists. Need to check what type of User is Logged in ?
        const context = sessionStorage.getItem('car-ses-con');
        if (context) {
          this.getUserRoles();
        }

        const userType = this.userTypeService.userType;
        if (userType) {
          this.userType = userType;
          if (this.userType && (this.userType === 'client-account-manager' || this.userType === 'consultant' || this.userType === 'program-admin' ||
          this.userType === 'file-setup-specialist' || this.userType === 'application-reliability-analyst' || this.userType === 'application-owner')) {
            this.viewerFlag = true;
          }
        }

        // if (sessionStorage.getItem('car-ses-con')) {
        //   sessionStorage.getItem('car-ses-vie') === 'true' ? this.isViewer = true : this.isViewer = false;
        //   this.postInit();
        // } else {
        //   if (sessionStorage.getItem('car-ses-subcon')) {
        //     this.postInit();
        //   } else {
        //     this.getUserContext();
        //   }
        //   // Below commented line to be removed after R2 release
        //   // this.getUserContext();
        // }
        this.postInit();

      // }
    }
    this.refreshsessionInApp();
  } */

  public refreshsessionInApp() {
    this.refreshTokenInterval = setInterval(() => {
      this.userSessionService.refreshSession().then((freshToken) => {
        this.cookieService.set(
          'car-ses-tok',
          freshToken.tokens.accessToken.value,
          null, // We are relying on Okta session expiration
          '/',
          '.cartus.com',
          true
        );

        this.cookieService.set('car-token-claims',
          freshToken.tokens.idToken.claims ? JSON.stringify(freshToken.tokens.idToken.claims) : undefined,
          null,
          '/',
          '.cartus.com',
          true);

        this.cookieService.set('car-token-expiresat',
          freshToken.tokens.idToken.expiresAt,
          null,
          '/',
          '.cartus.com',
          true);

        this.cookieService.set(
          'car-token-idtoken',
          freshToken.tokens.idToken.idToken,
          null,
          '/',
          '.cartus.com',
          true
        );
      })
        .catch((err) => {
          console.log('error in freshToken :', err);
        });
    }, 60000 * this.refreshTokenTimeout);
  }

  /** getUserContext method to get the partyId and to find whether he is a account manager
   *  or client contact and calls the appropriate service */
  getUserContext() {
    this.userSessionService.getContexts().toPromise().then(async (parties) => {
      if (!parties || parties.length === 0) {
        this.logout();
      } else {
        sessionStorage.setItem('parties_count', parties.length.toString());
        const partyIds = [];
        const reqObj = {};
        parties.forEach(element => {
          partyIds.push(element._id);
        });
        reqObj['partyId'] = partyIds;
        await this.userContextService.getContexts(reqObj).toPromise().then(async (res: UserContext) => {
          this.contextsList = res;
          if (this.contextsList === null) {
            this.noContextError = true;
          } else {
            if (parties.length === 1) {
              sessionStorage.setItem('car-ses-con', partyIds[0]);

              // Get Roles
              this.getUserRoles().then(() => {
                if (this.userType !== 'consultant' && this.userType !== 'program-admin' && this.userType !== 'file-setup-specialist'
                  && this.userType !== 'application-owner') {
                  this.getClientsSub = this.userSessionService.getClients(partyIds[0]).subscribe(result => {
                    this.clients = result.contexts;
                    if (this.clients.length === 0 && result.partyRoles === '') {
                      this.logout('5');
                    } else if (this.clients.length === 0 || this.clients === null) {
                      // Federated clients logic
                      this.userContextService.setUserContext(this.contextsList.contexts[0]);
                      sessionStorage.setItem('car-ses-con', this.contextsList.contexts[0].partyId);
                      this.isViewer = false;
                      this.viewerFlag = this.isViewer;
                      sessionStorage.setItem('car-ses-vie', JSON.stringify(this.isViewer));
                      this.postInit();
                    } else {
                      this.isViewer = true;
                      this.viewerFlag = this.isViewer;
                      sessionStorage.setItem('car-ses-vie', JSON.stringify(this.isViewer));
                    }
                  });
                } else {
                  this.isViewer = true;
                  this.viewerFlag = this.isViewer;
                  sessionStorage.setItem('car-ses-vie', JSON.stringify(this.isViewer));
                }
              });

              // Adding this on the possibility that User might select Admin route.
              this.possibleAdminData = this.contextsList.contexts[0];
            } else {
              this.isViewer = false;
              this.viewerFlag = this.isViewer;
              this.showLogout = true;
              sessionStorage.setItem('car-ses-vie', JSON.stringify(this.isViewer));
              this.dataSource = new MatTableDataSource<Contexts>(this.contextsList.contexts);
              this.displayContextSelection = true;
            }
          }
        });
      }
    });

  }

  /** Get the contextSelected from User Context list grid */
  selectContext(context) {
    this.userContextService.setUserContext(context);
    sessionStorage.setItem('car-ses-con', context.partyId);
    this.postInit(true);
    this.getUserRoles();
    setTimeout(() => {
      if (this.userType && this.userType === 'client-contact-initiator') {
        this.isAdminVisible = false;
      }
    }, 2000);
  }

  /** getSelectedClient method to get the selected client from the child component */
  getSelectedClient(selectedClient) {
    this.selectedClient = selectedClient;
    this.clientContactFlag = true;
  }

  /** getClients method to get the client select page */
  getClients() {
    this.clientContactFlag = false;
  }

  /** getSelectedClientContact method to get the selectedClientContact from the child component */
  getSelectedClientContact(clientContact) {
    const userContext: Contexts = {
      clientNo: sessionStorage.getItem('clientNo'),
      clientLegalName: sessionStorage.getItem('clientName'),
      clientAddrLine1: '',
      clientAddrLine2: '',
      clientCityName: '',
      clientCountryName: '',
      clientPostalCode: '',
      clientStProvName: '',
      clientPhoneNo: clientContact.clientPhoneNo,
      clientEmailId: clientContact.clientEmailId,
      firstName: clientContact.firstName,
      lastName: clientContact.lastName,
      multiCurrencyFlag: false,
      partyId: clientContact.clientContactID
    };
    this.userContextService.setUserContext(userContext);
    sessionStorage.setItem('car-ses-subcon', clientContact.clientContactID);
    this.postInit();
  }

  /** Listen for mouse events */
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: any) {
    if (e.pageX !== this.lastX || e.pageY !== this.lastY) {
      this.lastX = e.pageX;
      this.lastY = e.pageY;
      this.refreshIdleCookie();
    }
  }

  /** Listen for keypress events */
  @HostListener('document:keypress', ['$event'])
  onKeyPress() {
    this.refreshIdleCookie();
  }

  /** Start idle timeout feature */
  startIdleTimeCountDown() {
    if (this.lastTimeOut) {
      clearTimeout(this.lastTimeOut);
    }
    this.refreshIdleCookie();
    this._ngZone.runOutsideAngular(() => {
      this.lastTimeOut = setTimeout(this.checkIdle.bind(this), this.pollInterval);
    });
  }

  /** Check idle timeout status */
  checkIdle() {
    return;
    const sesTok = !!this.appConfig.getConfig('mocked') ? 'a' : this.cookieService.get('car-ses-tok');
    const idleExpireMs = parseInt(this.cookieService.get(this.idleCookieName), 10);
    const currTimeMs = new Date().getTime();
    if (!sesTok || currTimeMs > idleExpireMs) {
      this.logout('4');
    } else {
      this.pingInterval += this.pollInterval;
      if (this.pingInterval === 1000 * this.idleCheckSeconds) {
        this.pingInterval = 0;
      }
      this._ngZone.runOutsideAngular(() => {
        this.lastTimeOut = setTimeout(this.checkIdle.bind(this), this.pollInterval);
      });
    }
  }

  getSearchResults(query) {
    if (this.userInputTimeout) {
      window.clearTimeout(this.userInputTimeout);
    }
    this.userInputTimeout = window.setTimeout(() => {
      this.generateSuggestions(query);
    }, 500);
  }

  showError(q: string) {
    this.occupyFullWidth = true;
    this.originalText = q;
    q = q.trim();
    if (q.length === 0 || q === null) {
      this.responseMessage = 'Please enter at least 2 characters';
      this.showMsg = true;
      this.showResults = false;
      this.showCustomMsg = false;
      this.liveAnnouncer.announce(this.responseMessage);
    }
  }

  hideError(q: string) {
    this.originalText = q;
    q = q.trim();
    if (q.length === 0 || q === null) {
      this.responseMessage = 'Please enter at least 2 characters';
      this.showMsg = false;
      this.showResults = false;
      this.showCustomMsg = false;
      this.liveAnnouncer.announce(this.responseMessage);
      this.occupyFullWidth = false;
    }
  }

  private isNullOrWhitespace(strToCheck) {
    const whitespaceChars = '\s';
    return (strToCheck === null || whitespaceChars.indexOf(strToCheck) !== -1);
  }

  private generateSuggestions(text: string) {
    this.filteredOptions = [];
    this.query = text;
    if (text.length === 0 || text === null || text === '') {
      this.responseMessage = 'Please enter at least 2 characters';
      this.showMsg = true;
      this.showResults = false;
      this.showCustomMsg = false;
      this.liveAnnouncer.announce(this.responseMessage);
    } else if (text.length < 2) {
      this.responseMessage = 'Please enter at least 2 characters';
      this.showMsg = true;
      this.showResults = false;
      this.showCustomMsg = false;
      this.liveAnnouncer.announce(this.responseMessage);
    } else {
      if (this.requestSub) {
        this.requestSub.unsubscribe();
      }
      this.requestSub = this.globalSearchService.getResults(text).subscribe(res => {

        this.totalCount = res.totalCount;

        if (this.totalCount === 0) {
          this.showMsg = true;
          this.showCustomMsg = false;
          this.showResults = false;
          this.responseMessage = 'There are no matching results, please try again';
          this.liveAnnouncer.announce(this.responseMessage);
        } else if (this.totalCount > 0 && this.totalCount < 101) {
          this.filteredOptions = res.data;
          this.responseMessage = res.message;
          this.showResults = true;
          this.showMsg = false;
          this.showCustomMsg = false;
        } else if (this.totalCount > 100) {
          this.filteredOptions = res.data;
          this.responseMessage = res.message;
          this.showResults = true;
          this.showCustomMsg = true;
          this.showMsg = false;

          const overCount = this.totalCount - 100;

          this.responseMessage1 = 'This has returned too many matching results, please refine your search';
          this.responseMessage2 = 'Below we are showing the first 100 results';
          this.responseMessage3 = '(' + overCount + ' additional matches are not being shown)';
          this.liveAnnouncer.announce(this.responseMessage1 + this.responseMessage2 + this.responseMessage3);
        }
      });
    }
  }

  clearSearch() {
    this.filterText = '';
    this.query = '';
    this.showMsg = false;
    this.showResults = false;
    this.responseMessage = '';
    this.showCustomMsg = false;
  }


  /** Selects desktop and mobile navigation item based on id */
  selectNavItemById(id: number) {
    this.desktopNavList.selectById(id);
    this.mobileNavList.selectById(id);
  }

  /** Finishes component initialization on both first load and already established session */
  private postInit(contextSwitch: boolean = false) {
    this.contextSelected = true;
    this.showLogout = false;
    //this.router.navigate(['bbadmin/administration/transferee']);
    this.viewerFlag = false;
    this.startIdleTimeCountDown();
  }

  /** Toggles compressed display of desktop template and navigation */
  sidebarToggle() {
    this.sidebarMinimized = !this.sidebarMinimized;
    this.desktopNavList.minimize(this.sidebarMinimized);
  }

  /** logout of the application.  Logout URL handled by configuration */
  logout(code: string = '') {
    clearInterval(this.refreshTokenInterval);
    sessionStorage.clear();
    let codeUrl = '';
    if (code.length > 0) {
      codeUrl = '&code=' + code;
    }
    const logoutURL = this.appConfig.getConfig('login') + '/#/logout?appId=1' + codeUrl;
    if (this.userContextService.windowRef && this.userContextService.windowRef.length > 0) {
      this.userContextService.windowRef.forEach(ref => {
        ref.location.href = 'https://onlinedev.cartus.com/resp/Public#/logout';
      });
    }
    this.router.navigate(['/externalRedirect', { externalUrl: logoutURL }], {
      skipLocationChange: true
    });
  }

  /** opens the Cartus Online URL after receiving token.  URL handled via configuration */
  openCartusOnline(): void {
    const colUrl = this.appConfig.getConfig('cartusOnline').toString();
    this.requestSub = this.userSessionService.getColToken()
      .pipe(take(1))
      .subscribe(res => {
        this.userContextService.windowRef = window.open(colUrl, '_blank');
      });
  }

  /** opens the Administration URL */
  openAdministation(): void {
    this.viewerFlag = false;
    this.contextSelected = true;
    if (this.possibleAdminData) {
      this.userContextService.setUserContext(this.possibleAdminData);
    }
    this.router.navigate(['/bbadmin/administration/transferee']);
    this.startIdleTimeCountDown();
  }

  openDashboard() {
    if (this.router.url === '/administration') {
      sessionStorage.clear();
      this.router.navigate(['/bbadmin/administration/dashboard']).then(() => {
        window.location.reload();
      });
    } else {
      this.router.navigate(['/bbadmin/administration/dashboard']);
    }
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.navSub) {
      this.navSub.unsubscribe();
    }
    if (this.getClientsSub) {
      this.getClientsSub.unsubscribe();
    }
  }

  /** To Clear the initalSort variables */
  clearInitialSort(): void {
    this.initialSortColumn = null;
    this.initialSortDirection = null;
  }

  getUserRoles() {
    const bbUserTypes = ['client-contact-initiator', 'file-setup-specialist', 'application-owner', 'application-reliability-analyst', 'client-account-manager', 'program-admin', 'consultant'];
    const nonBBUserTypes = ['client-contact']; // this role is not for BB.
    return new Promise<void>((resolve, reject) => {
      this.userTypeService.getUserRoles().subscribe(data => {
        // tslint:disable-next-line: no-shadowed-variable
        let role = data.loginUserRoleCapabilities.find(role => bbUserTypes.includes(role.roleName));
        if (!role) {
          role = data.loginUserRoleCapabilities.find(role => nonBBUserTypes.includes(role.roleName));
        }
        sessionStorage.setItem('cc_clientPartyId', role.clientPartyId);
        if (role.roleName !== 'client-contact') {
          if (role) {
            this.isAdminVisible = true;
            this.userType = role.roleName;
            this.userTypeService.userType = role.roleName;
            this.userTypeService.userCapabilities = role;

            setTimeout(() => {
              if (this.userType === 'program-admin' || this.userType === 'consultant' || this.userType === 'file-setup-specialist'
                || this.userType === 'application-owner') {
                this.openAdministation();
              }
            }, 500);
            resolve();
          } else {
            // User has no access to Admin for now.
            this.isAdminVisible = false;
          }

        } else {
          this.isAdminVisible = false;
          this.userType = role.roleName;
          this.userTypeService.userType = role.roleName;
          this.userTypeService.userCapabilities = role;
          resolve();
        }
      });
    });
  }

  setLogoutDisplay(event) {
    this.showLogout = event; // event is emitted value
  }

  /** filterModal method to open the Filter modal popup */
  logoutModal(): void {
    this.logoutDialogRef = this.dialog.open(LogoutModalComponent, {
      panelClass: 'logoutModal',
      disableClose: true,
      maxWidth: '100%',
      autoFocus: false,
    });
    this.logoutDialogRef.afterClosed().subscribe(result => {
    });
  }

}
