import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Observable } from 'rxjs';
import { PowerBiReportConfig } from '../../models/powerbi-report-config.model';
import { PowerBiVisualConfig } from '../../models/powerbi-visual-config.model';
import { Chart } from 'chart.js';
import moment from 'moment';
import { MatDialog } from '@angular/material';
import { ResizedEvent } from 'angular-resize-event';

declare var $: any

/** This component renders Power BI reports */
@Component({
  selector: 'app-powerbi-report-container',
  templateUrl: './powerbi-report-container.component.html',
  styleUrls: ['./powerbi-report-container.component.scss']
})
export class PowerBiReportContainerComponent implements OnInit {
  /** Report config */
  @Input() configs$: Observable<PowerBiReportConfig[] | PowerBiVisualConfig[]>;
  @ViewChild('authByTime', { static: false }) authByTimeChart: ElementRef;
  @ViewChild('last12MonthChart', { static: false }) last12MonthChart: ElementRef;
  @ViewChild('serviceResultByTime', { static: false }) serviceResultByTimeChart: ElementRef;
  @ViewChild('authByTimeYtd', { static: false }) authByTimeYtdChart: ElementRef;
  @ViewChild('authByTimeMtd', { static: false }) authByTimeMtdChart: ElementRef;
  @ViewChild('serviceResultYtd', { static: false }) serviceResultYtdChart: ElementRef;
  @ViewChild('serviceResultMtd', { static: false }) serviceResultMtdChart: ElementRef;
  @ViewChild('serviceResultLast12Months', { static: false }) serviceResultLast12MonthsChart: ElementRef;

  pieChartHeight: Number;
  pieChartWidth: Number;
  showAuthByTimeYtd: boolean = true;
  showAuthByTimeMtd: boolean = false;
  showLast12months: boolean = false;
  showProgramCostYtd: boolean = true;
  showProgramCostMtd: boolean = false;
  showProgramCostLast12Months: boolean = false;
  showServiceResultYtd: boolean = true;
  showServiceResultMtd: boolean = false;
  showServiceResultLast12Months: boolean = false;
  selectedDynamicData: Object = {};
  authDynamicData: Object = {
    'ytd': {
      currentPeriod: 96,
      priorPeriod: 157,
      change: '-38.9%',
      currentPeriodIntl: '72',
      currentPeriodDom: 24,
      priorPeriodIntl: 85,
      priorPeriodDom: 72
    },
    'mtd': {
      currentPeriod: 18,
      priorPeriod: 72,
      change: '-75%',
      currentPeriodIntl: 10,
      currentPeriodDom: 8,
      priorPeriodIntl: 12,
      priorPeriodDom: 60
    },
    'last12Month': {
      currentPeriod: 845,
      priorPeriod: 867,
      change: '-2.5%',
      currentPeriodIntl: 240,
      currentPeriodDom: 605,
      priorPeriodIntl: 232,
      priorPeriodDom: 635
    }
  }

  programCostDynamicData: Object = {
    'ytd': {
      currentPeriod: '8,632',
      priorPeriod: '9,870',
      change: '-13%',
      currentPeriodIntl: '3,821',
      currentPeriodDom: '4,811',
      priorPeriodIntl: '6,215',
      priorPeriodDom: '3,655'
    },
    'mtd': {
      currentPeriod: '2,067',
      priorPeriod: '1,320',
      change: '63%',
      currentPeriodIntl: '87',
      currentPeriodDom: '1,980',
      priorPeriodIntl: '74',
      priorPeriodDom: '1,246',
      isPositiveChange:true
    },
    'last12Month': {
      currentPeriod: '1.8M',
      priorPeriod: '2.6M',
      change: '-31.8%',
      currentPeriodIntl: '970.8K',
      currentPeriodDom: '829.2K',
      priorPeriodIntl: '2.1M',
      priorPeriodDom: '501,207'
    }
  }

  serviceDynamicData: Object = {
    'ytd': {
      currentPeriod: '97%',
      priorPeriod: '95.5%',
      change: '1.5%',
      currentPeriodIntl: '96.2%',
      currentPeriodDom: '97.85%',
      priorPeriodIntl: '90.9%',
      priorPeriodDom: '100%'
    },
    'mtd': {
      currentPeriod: '96%',
      priorPeriod: '93.2%',
      change: '2.8%',
      currentPeriodIntl: '95%',
      currentPeriodDom: '97%',
      priorPeriodIntl: '90.2%',
      priorPeriodDom: '96.2%'
    },
    'last12Month': {
      currentPeriod: '98.5%',
      priorPeriod: '97.5%',
      change: '1%',
      currentPeriodIntl: '100%',
      currentPeriodDom: '97%',
      priorPeriodIntl: '95%',
      priorPeriodDom: '100%'
    }
  }

  /** Component instantiation */
  constructor(
    public dialog: MatDialog
  ) { }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() { }

  ngAfterViewInit() {
    this.createChart();
  }

  getLastNMonths(monthsRequired, format) {
    let months = [];
    for (let i = monthsRequired; i >= 1; i--) {
      months.push(moment().subtract(i, 'months').format(format))
    }
    return months;
  }

  createChart() {
    const ctx = this.authByTimeChart.nativeElement.getContext('2d');
    const authByTimeChart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: this.getLastNMonths(12, 'MMM[-]YYYY'),
        datasets: [{
          label: 'Current',
          data: [28, 25, 24, 37, 17, 16, 33, 46, 20, 17, 60, 30],
          borderColor: "#3486C4",
          fill: false,
          pointBackgroundColor: '#3486C4',
          tension: 0,
          radius: 4
        }, {
          label: 'Prior',
          data: [66, 47, 55, 34, 40, 41, 34, 37, 41, 40, 30, 18],
          fill: false,
          borderColor: '#DCDDDE',
          pointBackgroundColor: '#DCDDDE',
          tension: 0,
          radius: 4
        }]
      },
      options: {
        responsive: true,
        scales: {
          xAxes: [{
            id: 'x-axis',
            gridLines: {
              display: false
            },
            ticks: {

            }
          }],
          yAxes: [{
            id: 'y-axis',
            gridLines: {
              display: true
            },
            ticks: {
              stepSize: 10,
              min: 10
            }
          }]
        },
        title: {
          display: true,
          position: 'top',
          text: 'Authorizations by Time'
        },
        legend: {
          align: 'start',
          position: 'bottom',
          labels: {
            boxWidth: 20,
            usePointStyle: true
          }
        },
        tooltips: {
          mode: 'index',
          intersect: false
        }
      }
    });

    const serviceResultByTimeCtx = this.serviceResultByTimeChart.nativeElement.getContext('2d');
    const serviceResultByTimeChart = new Chart(serviceResultByTimeCtx, {
      type: 'line',
      data: {
        labels: this.getLastNMonths(12, 'MMM[-]YYYY'),
        datasets: [{
          label: 'Current',
          data: [100, 100, 100, 85.7, 100, 100, 85, 90, 77, 100, 85, 95],
          borderColor: "#3486C4",
          fill: false,
          pointBackgroundColor: '#3486C4',
          tension: 0,
          radius: 4
        }, {
          label: 'Prior',
          data: [100, 100, 100, 100, 90, 100, 100, 100, 100, 90.9, 77.8, 100],
          fill: false,
          borderColor: '#DCDDDE',
          pointBackgroundColor: '#DCDDDE',
          tension: 0,
          radius: 4
        }]
      },
      options: {
        responsive: true,
        scales: {
          xAxes: [{
            id: 'x-axis',
            gridLines: {
              display: false
            },
            ticks: {

            }
          }],
          yAxes: [{
            id: 'y-axis',
            gridLines: {
              display: true
            },
            ticks: {
              stepSize: 10,
              min: 70,
              callback: function (value) {
                return value + '%';
              }
            }
          }]
        },
        title: {
          display: true,
          position: 'top',
          text: 'Favorable Service Results by Time'
        },
        legend: {
          align: 'start',
          position: 'bottom',
          labels: {
            boxWidth: 20,
            usePointStyle: true
          }
        },
        tooltips: {
          mode: 'index',
          intersect: false
        }
      }
    });
  }

  openFullscreen(templateRef, selectedPage) {
    let dialogRef = this.dialog.open(templateRef, {
      autoFocus: false,
      closeOnNavigation: true,
      maxWidth: '100vw',
      maxHeight: '100vh',
      height: '100%',
      width: '100%',
      panelClass: 'dialog-container-custom'
    });
    dialogRef.afterOpened().subscribe(result => {
      if (selectedPage === 'authByTime') {
        this.showAuthByTimeYtd = true;
        this.showAuthByTimeMtd = false;
        this.showLast12months = false;
        this.showAuthByTimeYtdChart();
        this.selectedDynamicData = this.authDynamicData['ytd'];
      } else if (selectedPage === 'programCost') {
        this.showProgramCostYtd = true;
        this.showProgramCostMtd = false;
        this.showProgramCostLast12Months = false;
        this.selectedDynamicData = this.programCostDynamicData['ytd'];
      } else {
        this.showServiceResultYtd = true;
        this.showServiceResultMtd = false;
        this.showServiceResultLast12Months = false;
        this.showServiceResultYtdChart();
        this.selectedDynamicData = this.serviceDynamicData['ytd']
      }
    })

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  showTab(selectedTab) {
    if (selectedTab === 'last12monthsTab') {
      this.showAuthByTimeYtd = false;
      this.showAuthByTimeMtd = false;
      this.showLast12months = true;
      this.showLast12MonthChart();
      this.selectedDynamicData = this.authDynamicData['last12Month'];
    } else if (selectedTab === 'ytdTab') {
      this.showAuthByTimeYtd = true;
      this.showAuthByTimeMtd = false;
      this.showLast12months = false;
      this.showAuthByTimeYtdChart();
      this.selectedDynamicData = this.authDynamicData['ytd'];
    } else {
      this.showAuthByTimeYtd = false;
      this.showAuthByTimeMtd = true;
      this.showLast12months = false;
      this.showAuthByTimeMtdChart();
      this.selectedDynamicData = this.authDynamicData['mtd'];
    }
  }

  showProgramCostTab(selectedTab) {
    if (selectedTab === 'last12monthsTab') {
      this.showProgramCostYtd = false;
      this.showProgramCostMtd = false;
      this.showProgramCostLast12Months = true;
      this.selectedDynamicData = this.programCostDynamicData['last12Month'];
    } else if (selectedTab === 'ytdTab') {
      this.showProgramCostYtd = true;
      this.showProgramCostMtd = false;
      this.showProgramCostLast12Months = false;
      this.selectedDynamicData = this.programCostDynamicData['ytd'];
    } else {
      this.showProgramCostYtd = false;
      this.showProgramCostMtd = true;
      this.showProgramCostLast12Months = false;
      this.selectedDynamicData = this.programCostDynamicData['mtd'];
    }
  }

  showServiceResultTab(selectedTab) {
    if (selectedTab === 'last12monthsTab') {
      this.showServiceResultYtd = false;
      this.showServiceResultMtd = false;
      this.showServiceResultLast12Months = true;
      this.showServiceResultLast12MonthsChart();
      this.selectedDynamicData = this.serviceDynamicData['last12Month']
    } else if (selectedTab === 'ytdTab') {
      this.showServiceResultYtd = true;
      this.showServiceResultMtd = false;
      this.showServiceResultLast12Months = false;
      this.showServiceResultYtdChart();
      this.selectedDynamicData = this.serviceDynamicData['ytd']
    } else {
      this.showServiceResultYtd = false;
      this.showServiceResultMtd = true;
      this.showServiceResultLast12Months = false;
      this.showServiceResultMtdChart();
      this.selectedDynamicData = this.serviceDynamicData['mtd']
    }
  }

  showLast12MonthChart() {
    setTimeout(() => {
      const last12MonthCtx = this.last12MonthChart.nativeElement.getContext('2d');
      const last12MonthChart = new Chart(last12MonthCtx, {
        type: 'line',
        data: {
          labels: this.getLastNMonths(12, 'MMM[-]YYYY'),
          datasets: [{
            label: 'Current',
            data: [28, 25, 24, 37, 17, 16, 30, 50, 45, 25, 42, 20],
            borderColor: "#3486C4",
            fill: false,
            pointBackgroundColor: '#3486C4',
            tension: 0,
            radius: 4
          }, {
            label: 'Prior',
            data: [41, 40, 30, 23, 41, 40, 30, 23, 41, 40, 30, 23],
            fill: false,
            borderColor: '#DCDDDE',
            pointBackgroundColor: '#DCDDDE',
            tension: 0,
            radius: 4
          }]
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [{
              id: 'x-axis',
              gridLines: {
                display: false
              },
              ticks: {

              }
            }],
            yAxes: [{
              id: 'y-axis',
              gridLines: {
                display: true
              },
              ticks: {
                stepSize: 10,
                min: 10
              }
            }]
          },
          title: {
            display: true,
            position: 'top',
            text: 'Authorizations by Time'
          },
          legend: {
            align: 'start',
            position: 'bottom',
            labels: {
              boxWidth: 20,
              usePointStyle: true
            }
          }
        }
      });
    }, 1000)
  }

  showAuthByTimeYtdChart() {
    setTimeout(() => {
      const authByTimeYtdCtx = this.authByTimeYtdChart.nativeElement.getContext('2d');
      const authByTimeYtdChart = new Chart(authByTimeYtdCtx, {
        type: 'line',
        data: {
          labels: this.getLastNMonths(4, 'MMM[-]YYYY'),
          datasets: [{
            label: 'Current',
            data: [40, 35, 20, 30],
            borderColor: "#3486C4",
            fill: false,
            pointBackgroundColor: '#3486C4',
            tension: 0,
            radius: 4
          }, {
            label: 'Prior',
            data: [41, 40, 30, 23],
            fill: false,
            borderColor: '#DCDDDE',
            pointBackgroundColor: '#DCDDDE',
            tension: 0,
            radius: 4
          }]
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [{
              id: 'x-axis',
              gridLines: {
                display: false
              },
              ticks: {}
            }],
            yAxes: [{
              id: 'y-axis',
              gridLines: {
                display: true
              },
              ticks: {
                stepSize: 5,
                min: 20
              }
            }]
          },
          title: {
            display: true,
            position: 'top',
            text: 'Authorizations by Time'
          },
          legend: {
            align: 'start',
            position: 'bottom',
            labels: {
              boxWidth: 20,
              usePointStyle: true
            }
          }
        }
      });
    }, 1000)
  }

  showAuthByTimeMtdChart() {
    setTimeout(() => {
      const authByTimeMtdCtx = this.authByTimeMtdChart.nativeElement.getContext('2d');
      const authByTimeMtdChart = new Chart(authByTimeMtdCtx, {
        type: 'line',
        data: {
          labels: this.getLastNMonths(1, 'MMM[-]YYYY'),
          datasets: [{
            label: 'Current',
            data: [23],
            borderColor: "#3486C4",
            fill: false,
            pointBackgroundColor: '#3486C4',
            tension: 0,
            radius: 4
          }, {
            label: 'Prior',
            data: [18],
            fill: false,
            borderColor: '#DCDDDE',
            pointBackgroundColor: '#DCDDDE',
            tension: 0,
            radius: 4
          }]
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [{
              id: 'x-axis',
              gridLines: {
                display: false
              },
              ticks: {}
            }],
            yAxes: [{
              id: 'y-axis',
              gridLines: {
                display: true
              },
              ticks: {
                stepSize: 2,
                min: 16
              }
            }]
          },
          title: {
            display: true,
            position: 'top',
            text: 'Authorizations by Time'
          },
          legend: {
            align: 'start',
            position: 'bottom',
            labels: {
              boxWidth: 20,
              usePointStyle: true
            }
          }
        }
      });
    }, 1000)
  }

  showServiceResultYtdChart() {
    setTimeout(() => {
      const serviceResultYtdCtx = this.serviceResultYtdChart.nativeElement.getContext('2d');
      const serviceResultYtdChart = new Chart(serviceResultYtdCtx, {
        type: 'line',
        data: {
          labels: this.getLastNMonths(3, 'MMM[-]YYYY'),
          datasets: [{
            label: 'Current',
            data: [100, 100, 97],
            borderColor: "#3486C4",
            fill: false,
            pointBackgroundColor: '#3486C4',
            tension: 0,
            radius: 4
          }, {
            label: 'Prior',
            data: [100, 100, 95],
            fill: false,
            borderColor: '#DCDDDE',
            pointBackgroundColor: '#DCDDDE',
            tension: 0,
            radius: 4
          }]
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [{
              id: 'x-axis',
              gridLines: {
                display: false
              },
              ticks: {}
            }],
            yAxes: [{
              id: 'y-axis',
              gridLines: {
                display: true
              },
              ticks: {
                stepSize: 2,
                min: 92,
                callback: function (value) {
                  return value + '%';
                }
              }
            }]
          },
          title: {
            display: true,
            position: 'top',
            text: 'Favorable Service Results by Time'
          },
          legend: {
            align: 'start',
            position: 'bottom',
            labels: {
              boxWidth: 20,
              usePointStyle: true
            }
          }
        }
      });
    }, 1000)
  }

  showServiceResultMtdChart() {
    setTimeout(() => {
      const serviceResultMtdCtx = this.serviceResultMtdChart.nativeElement.getContext('2d');
      const serviceResultMtdChart = new Chart(serviceResultMtdCtx, {
        type: 'line',
        data: {
          labels: this.getLastNMonths(1, 'MMM[-]YYYY'),
          datasets: [{
            label: 'Current',
            data: [96],
            borderColor: "#3486C4",
            fill: false,
            pointBackgroundColor: '#3486C4',
            tension: 0,
            radius: 4
          }, {
            label: 'Prior',
            data: [93],
            fill: false,
            borderColor: '#DCDDDE',
            pointBackgroundColor: '#DCDDDE',
            tension: 0,
            radius: 4
          }]
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [{
              id: 'x-axis',
              gridLines: {
                display: false
              },
              ticks: {}
            }],
            yAxes: [{
              id: 'y-axis',
              gridLines: {
                display: true
              },
              ticks: {
                stepSize: 2,
                min: 92,
                callback: function (value) {
                  return value + '%';
                }
              }
            }]
          },
          title: {
            display: true,
            position: 'top',
            text: 'Favorable Service Results by Time'
          },
          legend: {
            align: 'start',
            position: 'bottom',
            labels: {
              boxWidth: 20,
              usePointStyle: true
            }
          }
        }
      });
    }, 1000)
  }

  showServiceResultLast12MonthsChart() {
    setTimeout(() => {
      const serviceResultLast12MonthsCtx = this.serviceResultLast12MonthsChart.nativeElement.getContext('2d');
      const serviceResultLast12MonthsChart = new Chart(serviceResultLast12MonthsCtx, {
        type: 'line',
        data: {
          labels: this.getLastNMonths(12, 'MMM[-]YYYY'),
          datasets: [{
            label: 'Current',
            data: [98, 97, 95, 95.5, 96, 97, 95, 100, 95, 100, 100, 91],
            borderColor: "#3486C4",
            fill: false,
            pointBackgroundColor: '#3486C4',
            tension: 0,
            radius: 4
          }, {
            label: 'Prior',
            data: [98, 95, 96, 100, 96, 97, 97.5, 100, 96, 100, 100, 97],
            fill: false,
            borderColor: '#DCDDDE',
            pointBackgroundColor: '#DCDDDE',
            tension: 0,
            radius: 4
          }]
        },
        options: {
          responsive: true,
          scales: {
            xAxes: [{
              id: 'x-axis',
              gridLines: {
                display: false
              },
              ticks: {}
            }],
            yAxes: [{
              id: 'y-axis',
              gridLines: {
                display: true
              },
              ticks: {
                stepSize: 5,
                min: 85,
                callback: function (value) {
                  return value + '%';
                }
              }
            }]
          },
          title: {
            display: true,
            position: 'top',
            text: 'Favorable Service Results by Time'
          },
          legend: {
            align: 'start',
            position: 'bottom',
            labels: {
              boxWidth: 20,
              usePointStyle: true
            }
          }
        }
      });
    }, 1000)
  }

  openBoxMenu(event) {
    $(event.currentTarget).next('.boxMenu').addClass('open').bind("mouseleave", function () {
      $(this).removeClass('open');
    });
  }

  onResized(event: ResizedEvent) {        
    this.pieChartHeight = event.newHeight;
    this.pieChartWidth = event.newWidth; 
    console.log( `${this.pieChartHeight}, ${this.pieChartWidth} `)   ;
  }

}
