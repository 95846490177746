/** interface for phone details */
export interface emailDetails {
  _id?: string,
  primary?: boolean; 
  emailAddress:string;
  usageType:string;
  delete?: boolean;
}

export const emailTypes: Array<string> = ['Personal', 'Business'];
