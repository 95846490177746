<span style="max-height: 25px">
    <a routerLink="/users">
    <img src="../../../assets/landingpage_assets/Cartus_logo.JPG" style="padding-left:20px;">
    </a>
</span>
<button mat-button class="logout-btn" (click)="logout()">Logout</button>
<div class="settings menu" fxLayout="row">
    <mat-icon [matMenuTriggerFor]="settingmenu" class="settings-btn" aria-label="settings icon" *ngIf="isEnabled()">settings</mat-icon>
    <mat-menu #settingmenu="matMenu" class="mat-menu">
      <button mat-menu-item class="menu-items" (click) = "navigateUser()">
        <mat-icon>group</mat-icon>
        <span>Users</span>
      </button>
      <button mat-menu-item  class="menu-items">
        <mat-icon>groups</mat-icon>
        <span>Clients</span>
      </button>
    </mat-menu>
</div>

<div class="header">
    <h2>Clients</h2>
    <div class="add-btn">
        <button class="user-water" *ngIf = "isEnabled()" (click)="addEditClients()"></button>
    </div>
    <div class="back-btn">
        <mat-icon style="color: #202124 ;" (click)="logout()">arrow_back</mat-icon>
    </div>
</div>

<div class="client-details" style="padding: 80px;">
    <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>
            <!-- ClientId Column -->
            <ng-container matColumnDef="clientId">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Client ID </th>
                <td mat-cell *matCellDef="let element" (click) = "editClients(element)">
                    <a *ngIf="isEnabled()">{{element.clientId || "N/A"}}</a>
                    <p *ngIf="!isEnabled()">{{element.clientId || "N/A"}}</p>
                </td>
            </ng-container>

            <!-- ClientName Column -->
            <ng-container matColumnDef="clientName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Client Name </th>
                <td mat-cell *matCellDef="let element">{{element.clientName || "N/A"}}</td>
            </ng-container>

            <!-- DefaultClient Column -->
            <ng-container matColumnDef="noOfUsers">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> No. Of Users </th>>
                <td mat-cell *matCellDef="let element"> {{element.noOfUsers}} </td>
            </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="delete">
                <th width="8%" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button type="delete" class="delete-btn" fxLayout="row" aria-label="delete user" *ngIf="isDeleteEnabled(element)" (click)="onDelete(element)">
                        <mat-icon style="color: #575A5D;">delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[10,25,50]"></mat-paginator>
    </div>
</div>