import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Party } from '../models/party.model';
import { AppConfigService } from './app-config.service';
import { ClientView } from '../models/client-view.model';
import { UserContext } from '../models/user-context.model';
import { ClientContactsView } from '../models/client-contacts-view.model';
import { HttpParams } from '@angular/common/http';
import * as OktaAuth from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import {userContext} from '../../core/data/data';
import moment from 'moment';
export const tokenkey = 'jwtToken';
export const subscriptionkey = 'subscriptionEnddate'
export const sessiondurationkey = 'sessionduration'
export const sessiondurationtypekey = 'sessiondurationtype'
export const lastlogindatekey = 'lastlogindate'
/** This service is used for managing user session as well as aiding access to legacy COL */
@Injectable({
  providedIn: 'root'
})
export class UserSessionService {
  private authClient: OktaAuth;

  /** standard constructor for services */
  constructor(
    private readonly baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private readonly appConfig: AppConfigService,
    private readonly cookieService: CookieService
  ) {
    this.authClient = new OktaAuth({
      clientId: this.appConfig.getConfig('oktaClientId'),
      issuer: this.appConfig.getConfig('oktaUrl'),
      authorizeUrl: `${this.appConfig.getConfig('oktaUrl')}/v1/authorize`,
      redirectUri: this.appConfig.getConfig('oktaRedirectUri'),
      postLogoutRedirectUri: this.appConfig.getConfig('oktaRedirectUri'),
      tokenManager: {
        storage: 'sessionStorage'
      },
    });
  }

  /** Returns an array of party contexts */
  getContexts(): Observable<Array<Party>> {
    // if (this.appConfig.getConfig('environment') === 'developer') {
    //   return this.baseClient.getArr<Party>('v1/auth/appContext', 'get the UserConfig data').pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: Party[] = [];
    //       this.logSvc.logError(err);
    //       return of(empty);
    //     })
    //   );
    // } else {
    //   const url: string = <string>this.appConfig.getConfig('authapi');
    //   return this.baseClient.getExt<Party[]>(url + 'v1/auth/appContext', 'getting user contexts').pipe(
    //     map(r => r.body),
    //     catchError((err) => {
    //       this.logSvc.logError(err);
    //       const emptyArray: Party[] = [];
    //       return of(emptyArray);
    //     })
    //   );
    // }
    return of (JSON.parse(JSON.stringify(userContext._id)));
  }

  /** Returns a token for CartusOnline authentication */
  getColToken(): Observable<any> {
    return this.baseClient
      .getOne<any>('v1/navigateToCOL', 'get the CartusOnline token', null, true)
      .pipe(
        map(r => r),
        catchError((err, source) => {
          const empty: any = null;
          this.logSvc.logError(err);
          return of(empty);
        })
      );
  }

  /** getClients method fetches clients available for the particular partyid passed */
  getClients(partyId: string): Observable<ClientView> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        'partyId': partyId
      }
    });
    return this.baseClient.getOne<ClientView>('v1/roles', 'get the clients data', params).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: ClientView = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  /** getClientContacts method fetches client contacts available for the particular client from api  */
  getClientContacts(clientPartyId: string): Observable<ClientContactsView> {
    const params: HttpParams = new HttpParams({
      fromObject: {
        'clientPartyId': clientPartyId
      }
    });
    return this.baseClient.getOne<ClientContactsView>('v1/clientContacts', 'get the client contacts data', params).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: ClientContactsView = null;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  public refreshSession() {
    const idToken = this.cookieService.get('car-token-idtoken');
    const claims = this.cookieService.get('car-token-claims');
    const expiresAt = Number(this.cookieService.get('car-token-expiresat'));
    const accessToken = this.cookieService.get('car-ses-tok');
    const tokenToRenew = {
      idToken: idToken,
      accessToken: accessToken,
      claims: claims ? JSON.parse(claims) : undefined,
      expiresAt: expiresAt,
      scopes: ['openid', 'email'],
      authorizeUrl: `${this.appConfig.getConfig('oktaUrl')}/v1/authorize`,
      issuer: this.appConfig.getConfig('oktaUrl'),
      clientId: this.appConfig.getConfig('oktaClientId')
    };
    return this.authClient.token.renew(tokenToRenew);
  }

  getJwtToken() {
    return localStorage.getItem(tokenkey);
  }
  getSubscription() {
    return localStorage.getItem(subscriptionkey);
  }
  getSessionDuration() {
    return localStorage.getItem(sessiondurationkey);
  }
  getSessionDurationType() {
    return localStorage.getItem(sessiondurationtypekey);
  }  
  getLastLoginDate() {
    return localStorage.getItem(lastlogindatekey);
  }
  clearLoginInfo(){
    localStorage.removeItem(tokenkey);
    localStorage.removeItem(subscriptionkey);
    localStorage.removeItem(sessiondurationtypekey);
    localStorage.removeItem(sessiondurationkey);
    localStorage.removeItem(lastlogindatekey);
  }
  isTokenValid() {
    let isValid = false;
    if(this.isSubscriptionValid()) {
      let token = this.getJwtToken();    
      if(token) {
        if(!this.istokenExpired()){
          isValid = true;
        }
      }
    }
  

    return isValid;
  }

  istokenExpired() {
    let lastLogindate = this.getLastLoginDate(); 
    let loginDate = lastLogindate.includes('GMT') ? lastLogindate :
    (lastLogindate.includes('T') ? lastLogindate.split('T')[0] : lastLogindate);      
    if(moment(moment().format('MM/DD/YYYY')).isSame(moment(loginDate).format('MM/DD/YYYY'))) {
      return false;
    }
    return true;
  }

  isSubscriptionValid() { 
    let isSubscribed = false;
    let subscriptionEndDate = this.getSubscription();
    if(subscriptionEndDate) {      
      let subscribeddates = subscriptionEndDate.includes('T') ? subscriptionEndDate.split('T')[0]: subscriptionEndDate;      
      if(moment(moment().format('MM/DD/YYYY')).isSameOrBefore(moment(subscribeddates).format('MM/DD/YYYY'))) {
        isSubscribed = true;
      }
    } else {
      isSubscribed = true;
    }
    return isSubscribed;
  }
}
