
export const fileStatuses = [
    {
      "fileStatusDesc": "Service Delivery Complete"
    },
    {
      "fileStatusDesc": "Hold"
    },
    {
      "fileStatusDesc": "Open"
    },
    {
      "fileStatusDesc": "Pending"
    },
    {
      "fileStatusDesc": "Update"
    },
    {
      "fileStatusDesc": "Pending Cancelled"
    },
    {
      "fileStatusDesc": "Canceled"
    }
  ]