import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[character-only]'
})
export class CharacterOnlyDirective {
  private readonly _el: NgControl;
  constructor(ngControl: NgControl) { 
    this._el = ngControl;
  }

  @HostListener('input', ['$event.target.value']) onInputChange(value: string) {
    this._el.control.patchValue(value.replace(/[^A-Za-zÀ-úა-ჰ一-蠼赋'.-]*/g, ''));
  }

}
