import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})
export class MoveProDashboadBackendService {

  constructor(private http: HttpClient, private loginService: LoginService) { }

  getDashBoadEmployeeDetails(): Observable<any> {
    var headers = new HttpHeaders().set("Authorization", this.loginService.getJwtToken());
    return this.http.get<any>(environment.dashboard, {headers: headers})
  }
}
