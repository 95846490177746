import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { PdfFontsService } from './pdf-fonts.service';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class WorkOrderPdfGenerationService {
  borderColor = '#AAAAAA';
  labelColor = '#19305A';
  labelFont = 8;
  labelLineHeight = 0.5;
  fieldValueColor = '#22222';
  fieldValueFont = 11;
  sectionHeaderFont = 10;
  lineHeight = 1.5;
  workOrderBorderColor = '#bde1ff';
  businessEmail: any;
  businessNum: any;

  constructor(
    private readonly pdfMakeFonts: PdfFontsService,
  ) { 
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
    this.pdfMakeFonts.loadLogos();
  }

  generateDocumentDefinition(downloadData) {
    const documentDefinition = {
      info: { title: 'Program Details', subject: 'Details of programs listed' },
      pageOrientation: 'portrait',
      pageSize: 'LETTER',
      pageMargins: [40, 110, 40, 110],
      header: (currentPage) => {
        return {
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [{
                image: this.pdfMakeFonts.bbLogob64, width: 120, height: 40, alignment: 'left',
                color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [30, 35, 0, 0],
              },
              {
                image: this.pdfMakeFonts.cartusLogob64, width: 120, height: 40, alignment: 'right',
                color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [0, 35, 20, 0],
              }
              ]
            ]
          },
        };
      },
      footer: (currentPage, pageCount) => {
        const year = new Date().getFullYear();
        return {
          table: {
            headerRows: 1, widths: ['60%', '40%'],
            body: [
              [{
                text: `© ${year} Cartus Corporation | All rights reserved. 
             Cartus and the Cartus logo are registered trademarks of Cartus Corporation
             `, fontSize: 9, color: '#575A5D', alignment: 'left', border: [false, false, false, false],
                margin: [30, 30, 0, 0]
              },
              {
                text: `Page ${currentPage.toString()}`, fontSize: 9, color: '#575A5D',
                alignment: 'right', border: [false, false, false, false], margin: [0, 30, 30, 0]
              }
              ]
            ]
          },
        };
      },
      styles: {
        avenirBook: {
          font: 'AvenirBook',
          normal: true
        },
        avenirLight: {
          font: 'AvenirLight',
          fontSize: 18,
          normal: true
        },
        avenirMedium: {
          font: 'AvenirMedium',
          normal: true
        },
        programTable: {
          border: [true, true, true, true], borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#00ffff'],
          fillColor: '#F7F7F7'
        },
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10]
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        tableExample: {
          margin: [5, 5, 15, 15]
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: 'black'
        }
      }
    };
    const docDefinition = JSON.parse(JSON.stringify(documentDefinition));
    docDefinition.footer = documentDefinition.footer;
    docDefinition.header = documentDefinition.header;
    const content = [
      this.generateTransfereeSection(downloadData.clientDetails, downloadData.transfereeDetails, downloadData.orderId), this.cardSpacing(),
      this.workOrderDetails(downloadData), this.cardSpacing(),
      this.cartusConsultantInfo(downloadData.cartusConsultantInfo), this.cardSpacing(),
      this.customerDetails(downloadData), this.cardSpacing(),
      this.policyDetails(downloadData.policyDetails), this.cardSpacing(),
      this.relocationDetails(downloadData.relocationDetails, downloadData.currencieList), this.cardSpacing(),
      this.customerNeedsAssessment(downloadData.customerNeedsAssessment, downloadData.familyDetails), this.cardSpacing(), this.cardSpacing(),
      this.housingInfo(downloadData.housingInfo), this.cardSpacing(),
      this.financialDetails(downloadData.financialDetails), this.cardSpacing(),
      downloadData.modification ? this.modificationBlock(downloadData.modification) : null,
    ];
    docDefinition.content = content;
    if (!downloadData.isDraft) {
      docDefinition.watermark = { text: 'DRAFT', opacity: 0.1, fontSize: 175, angle: 325};
    }
    return docDefinition;
  }

  generateTransfereeSection(clientInfo, transfereeInfo, orderId) {
    let clientName: string;
    let orderRefNum: any;
    let fullName: string;
    let clientId: any;
    let atlasCustId: string;

    if (clientInfo && transfereeInfo) {
      clientName = (clientInfo.hasOwnProperty('clientEntityName')) ? clientInfo.clientEntityName : 'NA';
      orderRefNum = transfereeInfo.hasOwnProperty('orderRefNum') ? transfereeInfo.orderRefNum: 'NA';
      fullName = transfereeInfo.nameDetails.firstName + ' ' + transfereeInfo.nameDetails.lastName;
      clientId = (clientInfo.hasOwnProperty('clientNumber')) ? clientInfo.clientNumber : 'NA';
      atlasCustId = orderId ? orderId: "NA";
    }
    //transferee.orderRequestId

    
    const currentDate = new Date();
    const generatedDate= formatDate(currentDate, 'MMMM dd, yyyy', 'en-US');

    const transfereeDetails = {
      table: {
        headerRows: 0, widths: ['30%', '37%', '33%'], style: 'programTable',
        body: [
          // Transferee Details
          [
            // Transferee Name
            {
              text: `Robert Angel`, alignment: 'left', color: '#000000', lineHeight: 1, margin: [0, 3, 0, 0],
              border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
            },
            // Client Number
            {
              text: `Client: Demo Client`, alignment: 'left', color: '#575A5D', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 3, 0, 0], style: 'avenirBook'
            },
            // Generated Date
            {
              text: `Generated: ${generatedDate}`, alignment: 'right', color: this.labelColor, lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 3, 0, 0], style: 'avenirBook',
            }
          ],
          // [
          //   // Atlas Customer ID
          //   {
          //     text: `Atlas Customer ID: ${atlasCustId}`, alignment: 'left', color: '#575A5D', lineHeight: 1, margin: [0, -2, 0, 0],
          //     border: [false, false, false, false], fontSize: 9, style: 'avenirBook', colSpan:3
          //   },
          //   {
          //     text: ``, alignment: 'left', color: '#000000', lineHeight: 1, margin: [0, 0, 0, 0],
          //     border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
          //   },
          //   {
          //     text: ``, alignment: 'right', color: '#19305A', lineHeight: 1,
          //     border: [false, false, false, false], fontSize: 9, margin: [0, 0, 0, 0], style: 'avenirBook',
          //   }
          // ],
          [
            // Order#
            {
              text: `MOV-r493db5r`, alignment: 'left', color: this.labelColor, lineHeight: 1, margin: [0, 2, 0, 5],
              border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
            },
            // Client ID
            {
              text: `ID#: 4708`, alignment: 'left', color: '#575A5D', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 2, 0, 5], style: 'avenirBook'
            },
            {
              text: ` `, alignment: 'center', color: '#19305A', lineHeight: 1,
              fontSize: 9, border: [false, false, false, false], margin: [0, 2, 0, 5], style: 'avenirBook'
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return transfereeDetails;
  }

  workOrderDetails(data) {
    let bookingPartyId = ` `;
    let bookingPartyName = ` `;
    let supplierID = '';
    data.bookingAgent && data.bookingAgent.forEach(ele => {
      if (ele) {
        bookingPartyId = ele.partyId ? ele.partyId : ` `;
        bookingPartyName = ele.partyName ? ele.partyName : ` `;
        supplierID = ele.supplierID ? ele.supplierID : '';
      }
    });
    const id = !data.checkId ? data.orderId :  data.supplierId;
    const status = data.status ? data.status.charAt(0).toUpperCase() + data.status.slice(1) : ``;
    const date = data.submittedDate ? `${formatDate(data.submittedDate, 'yyyy-MM-dd', 'en-US')}` : ``;
    const orderStatusDate = data.orderStatusDate ? `on ${data.orderStatusDate}`: '';
    const details = {
      table: {
        headerRows: 0, widths: ['48%', '20%', '15%', '18%'], style: 'programTable',
        body: [
          //header
          [
            { text: `Work Order Details: ${id}`, border: [true, true, false, false], borderColor: [this.workOrderBorderColor, this.workOrderBorderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.labelLineHeight },
            { text: 'Booking Agent', border: [false, true, false, false], borderColor: ['',this.workOrderBorderColor, '', ''], fontSize: this.labelFont, color: this.labelColor, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Status', border: [false, true, false, false], borderColor: ['',this.workOrderBorderColor, '', ''], fontSize: this.labelFont, color: this.labelColor, style: 'avenirLight', lineHeight: this.labelLineHeight},
            { text: 'Actual Submitted On', border: [false, true, true, false], borderColor: ['',this.workOrderBorderColor, this.workOrderBorderColor, ''], fontSize: this.labelFont, color: this.labelColor, style: 'avenirLight', lineHeight: this.labelLineHeight}
          ],
          //row 1
          [
            { text: ``, border: [true, false, false, false], borderColor: [this.workOrderBorderColor, '', '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.labelLineHeight},
            { text: `${bookingPartyName}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: `${status}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: `${date}`, border: [false, false, true, false], borderColor: ['', '', this.workOrderBorderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
          ],
          [
            { text: `Offering: ${data.offering}`, margin: [0,-6,0,],  border: [true, false, false, true], borderColor: [this.workOrderBorderColor, '', '', this.workOrderBorderColor], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: '0.7' },
            { text: supplierID ? `ID: ${supplierID}` : '', border: [false, false, false, true], borderColor: ['', '', '', this.workOrderBorderColor],  color: this.fieldValueColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: orderStatusDate, border: [false, false, false, true], borderColor: ['', '', '', this.workOrderBorderColor],  color: this.fieldValueColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: ``, border: [false, false, true, true], borderColor: ['', '', this.workOrderBorderColor, this.workOrderBorderColor],  color: this.fieldValueColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.lineHeight }
          ],
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return details;
  }

  cartusConsultantInfo(cartusConsultantInfo) {
    const firstName = cartusConsultantInfo.firstName ? cartusConsultantInfo.firstName : ``;
    const lastName = cartusConsultantInfo.lastName? cartusConsultantInfo.lastName : ``;
    const location = cartusConsultantInfo.timeZone ? cartusConsultantInfo.timeZone : ``;
    const phoneCode = cartusConsultantInfo.phoneDetailsList !== undefined && cartusConsultantInfo.phoneDetailsList.length > 0 ? cartusConsultantInfo.phoneDetailsList[0].phoneDialingCode : ``;
    const phoneNumber =  cartusConsultantInfo.phoneDetailsList !== undefined && cartusConsultantInfo.phoneDetailsList.length > 0 ? cartusConsultantInfo.phoneDetailsList[0].phoneNumber : ``;
    const phoneVal = phoneCode && phoneNumber ? `(${phoneCode}) ${phoneNumber}`: ``;
    const email = cartusConsultantInfo.emailDetailsList !== undefined && cartusConsultantInfo.emailDetailsList.length > 0 ? cartusConsultantInfo.emailDetailsList[0].emailAddress : ``;
    const cartusConsultantInfoDetails = {
      table: {
        headerRows: 0, widths: ['40%', '60%'], style: 'programTable',
        body: [
          //header
          [
            { text: 'Cartus Consultant Info', border: [true, true, false, false], borderColor: [this.borderColor, this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
            { text: '', border: [false, true, true, false], borderColor: ['',this.borderColor, this.borderColor, ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight }
          ],
          //row 1
          [
            { text: 'Consultant', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Consultant Location/Region', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor, fontSize: 8, style: 'avenirLight', lineHeight: this.labelLineHeight }
          ],
          [
            { text: `${firstName} ${lastName}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${location}`, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],  color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
          ],
          //row 2
          [
            { text: 'Consultant Phone #', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Consultant Email', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
          ],
          [
            { text: `${phoneVal}`, border: [true, false, false, true], borderColor: [this.borderColor, '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${email}`, border: [false, false, true, true], borderColor: ['', '', this.borderColor, this.borderColor ], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
          ],
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return cartusConsultantInfoDetails;
  }

  customerDetails(data) {
    const firstName = data.customerDetails.customerFirstName ? data.customerDetails.customerFirstName : ``;
    const lastName  = data.customerDetails.customerSecondName ? data.customerDetails.customerSecondName : ``;
    data.customerDetails.phoneDetailsList.forEach(ele => {
      if((ele["usageType"] == "business")) {
        this.businessNum = ele["phoneNumber"]
      }
      else {
        this.businessNum = ``;
      }
    });
    data.customerDetails.emailDetailsList.forEach(element => {
      if (element["usageType"] == "business") {
        this.businessEmail = element["emailAddress"];
      }
      else {
        this.businessEmail = ``;
      }
    });
    const country = data.customerDetails.departureAddr.country ? data.customerDetails.departureAddr.country : ``;
    const city = data.customerDetails.departureAddr.city ? data.customerDetails.departureAddr.city : ``;
    const state = data.customerDetails.departureAddr.state ? data.customerDetails.departureAddr.state : ``;
    const street = data.customerDetails.departureAddr.streetLine1 ? data.customerDetails.departureAddr.streetLine1 : ``;
    const postalCode = data.customerDetails.departureAddr.postalCode ? data.customerDetails.departureAddr.postalCode : ``;
    const spouseName = data.spouseName ? data.spouseName : ``;

    const customerDet = {
      table: {
        headerRows: 0, widths: ['40%', '40%', '20%'], style: 'programTable',
        body: [
          //header
          [
            { text: 'Customer Details', border: [true, true, false, false], borderColor: [this.borderColor, this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
            { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
            { text: '', border: [false, true, true, false], borderColor: ['', this.borderColor, this.borderColor, ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight }
          ],
          //row 1
          [
            { text: 'Customer First Name', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Customer Last Name', border: [false, false, false, false], borderColor: ['', '', '', ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
          ],
          [
            { text: `${firstName}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${lastName}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
          ],
          //row 2
          [
            { text: 'Customer Work Phone #', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Customer Work Email', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
            { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
          ],
          [
            { text: `${this.businessNum}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${this.businessEmail}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
          ],
          //row 3
          [
            { text: 'Departure Country', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Departure Street Details', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
            { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
          ],
          [
            { text: `${country}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${street}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
          ],
          //row 4
          [
            { text: 'Departure City', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Departure State/Provinces', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Departure Postal Code', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
          ],
          [
            { text: `${city}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${state}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${postalCode}`, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
          ],
          //row 5
          [
            { text: 'Spouse/Partner Name', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
            { text: '', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
          ],
          [
            { text: `${spouseName}`, border: [true, false, false, true], borderColor: [ this.borderColor, '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: '', border: [false, false, false, true], borderColor: ['', '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: '', border: [false, false, true, true], borderColor: ['', '', this.borderColor, this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
          ],
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return customerDet;
  }

  policyDetails(policyDetails) {
  const clientName = policyDetails.clientName ? policyDetails.clientName : ``;
  const clientNumber = policyDetails.clientNumber ? policyDetails.clientNumber : ``;
  const fileNumber = policyDetails.fileNumber ? policyDetails.fileNumber : ``;
  const geographicOrigin = policyDetails.geographicOrigin ? policyDetails.geographicOrigin : ``;
  const orderReference = policyDetails.orderReference ? policyDetails.orderReference : ``;
  const policyName = policyDetails.policyName ? policyDetails.policyName : ``;
    const policyDetailsInfo = {
      table: {
        headerRows: 0, widths: ['35%', '30%', '35%'], style: 'programTable',
        body: [
           //header
           [
            { text: 'Policy Details', border: [true, true, false, false], borderColor: [this.borderColor, this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
            { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
            { text: '', border: [false, true, true, false], borderColor: ['', this.borderColor, this.borderColor, ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight }
          ],
          //row 1
          [
            { text: 'Client Name', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Client Number', border: [false, false, false, false], borderColor: ['', '', '', ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Geographic Origin', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
          ],
          [
            { text: `${clientName}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${clientNumber}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${geographicOrigin}`, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
          ],
          //row 2
          [
            { text: 'File Number', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: 'Order Reference', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
            { text: 'Policy Name', border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
          ],
          [
            { text: `${fileNumber}`, border: [true, false, false, true], borderColor: [ this.borderColor, '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${orderReference}`, border: [false, false, false, true], borderColor: ['', '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
            { text: `${policyName}`, border: [false, false, true, true], borderColor: ['', '', this.borderColor, this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
          ],
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return policyDetailsInfo;
  }

  relocationDetails(relocationDetails, currencieList) {
    const filePriority = relocationDetails.filePriority ? relocationDetails.filePriority : 'Normal';
    const rush = relocationDetails.rush ? 'Rush' : 'Standard';
    const daysAuthorized = relocationDetails.daysAuthorized ? relocationDetails.daysAuthorized : '';
    const clientBudgetAmount = relocationDetails.clientBudgetAmount ? relocationDetails.clientBudgetAmount : '';
    const splitBillComments = relocationDetails.splitBillComments ? relocationDetails.splitBillComments : '';
    const splitBillCommentsLabel = relocationDetails.splitBillComments ? 'Split Bill Comments': '';
    const monetaryAmount = relocationDetails.clientContribution && relocationDetails.clientContribution.monetaryAmount  ? relocationDetails.clientContribution.monetaryAmount : '';
    const monetaryAmountLabel = relocationDetails.clientContribution && relocationDetails.clientContribution.monetaryAmount ? 'Client’s Value (USD)' : '';
    const requiresResidencyLetter = relocationDetails.requiresResidencyLetter ? 'Yes' : 'No';
    const provider = relocationDetails.provider ? relocationDetails.provider : '';
    const clientDirectedProviderComment = relocationDetails.clientDirectedProviderComment ? relocationDetails.clientDirectedProviderComment : '';
    const relocationFinancialResponsibility = relocationDetails.financialResponsibility ? relocationDetails.financialResponsibility: '';
    const serviceDeliveryLocationVal = this.checkLocationFilter(relocationDetails.serviceDeliveryLocation, relocationDetails.serviceDeliveryLocationValueList)
    const budgetCur = relocationDetails.clientBudgetCurrency ? this.checkCurrencyFilter(relocationDetails.clientBudgetCurrency, currencieList) : ``;
      const detail = {
      unbreakable: true,
      stack: [
        {
          table: {
            headerRows: 0, widths: ['40%', '30%', '30%'], style: 'programTable',
            body: [
              [
                {
                  text: 'Relocation Details', border: [true, true, false, false], borderColor: [this.borderColor, this.borderColor, '', ''],
                  fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: 1, margin: [0, 5, 0, 5]
                },
                { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, '', ''], },
                { text: '', border: [false, true, true, false], borderColor: ['', this.borderColor, this.borderColor, ''] }
              ],
              [
                {
                  text: `File Priority`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                },
                {
                  text: ``, border: [false, false, false, false],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                },
                {
                  text: `Days  Authorized`, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                }
              ],
              [
                {
                  text: `${filePriority}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                },
                {
                  text: `${rush}`, border: [false, false, false, false],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                },
                {
                  text: `${daysAuthorized}`, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                }
              ],
              [
                {
                  text: `Service Delivery Location`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                },
                { text: ``, border: [false, false, false, false], },
                { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''] }
              ],
              [
                {
                  text: `${serviceDeliveryLocationVal}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                },
                { text: ``, border: [false, false, false, false], },
                { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
              ],
              [
                {
                  text: `Client Budget Amount (Nightly)`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                },
                {
                  text: `Client Budget Currency`, border: [false, false, false, false],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                },
                {
                  text: `Financial Responsibility`, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                }
              ],
              [
                {
                  text: `${clientBudgetAmount}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                },
                {
                  text: `${budgetCur}`, border: [false, false, false, false],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                },
                {
                  text: `${relocationFinancialResponsibility}`, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                }
              ],
              /** if Financial Responsibility is split bill  */
              [
                {
                  text: `${splitBillCommentsLabel}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                },
                { text: `${monetaryAmountLabel}`, border: [false, false, false, false], borderColor: ['', '', '', ''],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
                { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
              ],
              [
                {
                  text: `${splitBillComments}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                },
                { text: `${monetaryAmount}`, border: [false, false, false, false], 
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight},
                { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
              ],
              [
                {
                  text: `Requires Residency Letter`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                },
                { text: ``, border: [false, false, false, false] },
                { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
              ],
              [
                {
                  text: `${requiresResidencyLetter}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                },
                { text: ``, border: [false, false, false, false], },
                { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
              ],
              [
                {
                  text: `Provider`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                },
                { text: ``, border: [false, false, false, false], },
                { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
              ],
              [
                {
                  text: `${provider}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                },
                { text: ``, border: [false, false, false, false], },
                { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
              ],
              [
                {
                  text: `Client Directed Provider Comments (optional)`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
                  color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
                },
                { text: ``, border: [false, false, false, false], },
                { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
              ],
              [
                {
                  text: `${clientDirectedProviderComment}`, border: [true, false, false, true], borderColor: [this.borderColor, '', '', this.borderColor],
                  color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
                },
                { text: ``, border: [false, false, false, true], borderColor: ['', '', '', this.borderColor], },
                { text: ``, border: [false, false, true, true], borderColor: ['', '', this.borderColor, this.borderColor], }
              ],
            ]
          },
          layout: {
            hLineWidth: function (i, node) { return 0.75; },
            vLineWidth: function (i, node) { return 0.75; }
          }
        }
      ]
    };
    return detail;
  }

  customerNeedsAssessment(customerNeedsAssessment, familyDetails) {
    console.log(customerNeedsAssessment);
    let needAssessData = 
      
        
        {
          unbreakable: true,
          stack: [
            {
              table: {            
                widths: ['100%'],
                body: [
                      [{text:  'Customer Needs Assessment',border: [true, true, true, false], borderColor: [this.borderColor, this.borderColor, this.borderColor, ''] , fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight}],
                            [{text: 'Family Members Traveling With Transferee',border: [true, false, true, false], borderColor: [this.borderColor,'', this.borderColor, ''], color: this.labelColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.lineHeight }],
                       
                            [{text: 'Pets',border: [true, false, true, false], borderColor: [this.borderColor, '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight}],
                            [{text: customerNeedsAssessment.petsInformation.length > 0 ? 'Yes' : 'No' ,border: [true, false, true, false], borderColor: [this.borderColor,'', this.borderColor, ''],  color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }],
                            [customerNeedsAssessment.petsInformation.length > 0 ? this.generatePetTable(customerNeedsAssessment.petsInformation): {text : '',border: [true, false, true, false], borderColor: [this.borderColor,'', this.borderColor, '']}],
                            [{text: 'Pet Deposit Approved ',border: [true, false, true, false], borderColor: [this.borderColor,'', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight}],
                            [{text: customerNeedsAssessment.petDepositApproved ? 'Yes' : 'No',border: [true, false, true, false], borderColor: [this.borderColor,'', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight}],
                            [{text: 'Country Assignee is Travelling From (Departure Location) ',border: [true, false, true, false], borderColor: [this.borderColor,'', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight}],
                            [{text: customerNeedsAssessment.departureLocation,border: [true, false, true, false], borderColor: [this.borderColor,'', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight}],
                            
                            [{text: 'Travel History ', border: [true, false, true, false], borderColor: [this.borderColor,'', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont , style:'avenirLight', lineHeight: this.labelLineHeight}],
                            [{text: customerNeedsAssessment.travelHistory.toString(),border: [true, false, true, true], borderColor: [this.borderColor,'', this.borderColor, this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight}],
                      
                ]
              },
            }
          ]
          
          
        }
        this.generateFamilySection(familyDetails).forEach((obj, index) => {
          index > 0 ?
          needAssessData.stack[0].table.body.splice(2+index, 0, obj): needAssessData.stack[0].table.body.splice(2, 0, obj);
        });
      
    console.log(needAssessData);
    return needAssessData;
  }

  generateFamilyCard(familyCardResponse, i) {
    
    let familyCard; 
    for(let j = i; j< i+3 && j <= (familyCardResponse.length - 1)/3 ; j++){
      familyCard = [
        {border: [true, false, true, false], borderColor: [this.borderColor,'', this.borderColor, ''],
        style: 'tableExample',
        table: {
          body: [[]

          ]}}];
    familyCardResponse.forEach((member,index) => {
      if (index < 3*(i+1) && index >= i*3) {
        familyCard[0].table.body[0].push(  
      [
        {
          table:{ 

body: [
                  [{text: member.nameDetails.title + " " + member.nameDetails.firstName + " " + member.nameDetails.lastName +'\n',border: [false, false, false, false],  color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight'}],
                  [{text: member.age ? ((member.age.currentAge <= 1) ? ('Child (Age: Under '+ member.age.currentAge +')\n') : ('Child (Age: ' + member.age.currentAge +')\n')): member.relationshipType,border: [false, false, false, false],  color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirMedium'}],
                  [{text: (member.isTravelling ? '√ ' : 'X ') + 'Travelling with me\n',border: [false, false, false, false], color: '#575A5D', fontSize: this.fieldValueFont, style: 'avenirLight'}]
            ]
          },
          layout: {
                  hLineColor: 'red',
                  vLineColor: function(i, node) {
                      return (i === 0 || i === node.table.widths.length) ? 'red' : 'blue';
                  },
                  paddingLeft: function(i, node) { return 20; },
                  paddingRight: function(i, node) { return 20; },
                  paddingTop: function(i, node) { return 10; },
                  paddingBottom: function(i, node) { return 10; }
          }
        } 
     ],
      {text : ' ', border: [false, false, false, false]}
        ); 
      }
    });
    }
    console.log(familyCard);
    return familyCard;
  }

  generateFamilySection(familyCardResponse){
    let familySection = [];
    
    for(let i = 0; i <= (familyCardResponse.length-1)/3  ; i++) {
      //familyCardResponse.forEach((member,i) => {
        //if (i < 3) {
          
    familySection.push(this.generateFamilyCard(familyCardResponse, i));
        //}
      //});
    }
    console.log(familySection);
    return familySection;
  }

  generatePetTable(petsInformation) {
    console.log(petsInformation);
    let petsInformationTable = {table: {
      body: [
        [
          
          {text : '',border: [false, false, false, false]},
          {text : 'Pet Name',border: [false, false, false, false], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight}, 
          {text : 'Pet Type', border: [false, false, false, false], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
          {text : petsInformation.findIndex(ele => ele.type == 'Dog') >= 0 ? 'Pet Breed' : '',border: [false, false, false, false], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight}, 
          {text : petsInformation.findIndex(ele => ele.type == 'Other') >= 0 ? 'Other Pet Type' : '',border: [false, false, false, false], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight}, 
          {text : 'Weight', border: [false, false, false, false], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
        ],
      ]
    },border: [true, false, true, false], borderColor: [this.borderColor, '', this.borderColor, '']};
    petsInformation.forEach((element, key) => {
      petsInformationTable.table.body.push([
        {text : '(' + (key + 1) + ')',border: [false, false, false, false],  color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight},
        {text : element.name, border: [false, false, false, false], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight},
        {text : element.type, border: [false, false, false, false], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight},
        {text : element.type == 'Dog' ? element.breed : '', border: [false, false, false, false], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight},
        {text : element.type == 'Other' ? element.other : '', border: [false, false, false, false], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight},
        {text : element.weight, border: [false, false, false, false], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight},
      ]);
    });console.log(petsInformationTable);
    return petsInformationTable;
  }

  housingInfo(housingInfo) {
    const desiredLocation = housingInfo.desiredLocation ? this.setContryDisplayName(housingInfo.desiredLocation) : ``;
    const alternateLocation = housingInfo.alternateLocation ? this.setContryDisplayName(housingInfo.alternateLocation) : ``;
    const destinationLocation = housingInfo.destinationOfficeLocation ? this.setContryDisplayName(housingInfo.destinationOfficeLocation) : ``;
    const accommodationType = housingInfo.accommodationType ? housingInfo.accommodationType : '';
    const comments = housingInfo.housekeepingInfo && housingInfo.housekeepingInfo.comments ?  housingInfo.housekeepingInfo.comments : '';
    const moveInDate = housingInfo.moveInDate ? moment(housingInfo.moveInDate).format('YYYY-MM-DD') : '';
    const moveOutDate = housingInfo.moveOutDate ? moment(housingInfo.moveOutDate).format('YYYY-MM-DD') : '';
    const lengthOfStay = housingInfo.lengthOfStay ? housingInfo.lengthOfStay : '';
    const numberOfApartments = housingInfo.numberApartments ? housingInfo.numberApartments : '';
    const numberOfBathrooms = housingInfo.numberOfBathrooms ? housingInfo.numberOfBathrooms : '';
    const numberOfBedrooms = housingInfo.numberBedrooms ? housingInfo.numberBedrooms : '';
    const parkingComments = housingInfo.parkingComments ? housingInfo.parkingComments : '';
    const ParkingCommentsLabel = housingInfo.parkingComments ? `Parking Comments`: '';
    const acceptableCommute = housingInfo.acceptableCommute ? housingInfo.acceptableCommute : '';
    const additionalInstructions = housingInfo.additionalInstructions ? housingInfo.additionalInstructions : '';    
    const financialResponsibility = housingInfo.financialResponsibility ? housingInfo.financialResponsibility : '';
    const financialResponsibilityLabel = housingInfo.financialResponsibility ?`Parking Financial Responsibility`: '';
    const houseKeeping = housingInfo.houseKeeping ? 'No' : 'Yes';
    const housekeepingFrequency = housingInfo.housekeepingInfo && housingInfo.housekeepingInfo.housekeepingFrequency ? housingInfo.housekeepingInfo.housekeepingFrequency : '';     
    const furnished = housingInfo.furnished ? 'Yes' : 'No';
    const kitchenRequired = housingInfo.kitchenRequired ? 'Yes' : 'No';
    const babyCotRequired = housingInfo.babyCotRequired ? 'Yes' : 'No';
    const parking = housingInfo.parking ? 'Yes' : 'No';
    const priorities = [];
    housingInfo.priorities.forEach((ele , index) => {
      priorities[index] = ele ? ele : `None`;
    });
    const housingInfoSection = {
      
      table: {
        headerRows: 0, widths: ['60%', '20%', '20%'], style: 'programTable',
        body: [
          [
            {
              text: 'Housing Info', border: [true, true, false, false], borderColor: [this.borderColor, this.borderColor, '', ''],
              fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: 1, margin: [0, 5, 0, 5]
            },
            { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, '', ''], },
            { text: '', border: [false, true, true, false], borderColor: ['', this.borderColor, this.borderColor, ''] }
          ],
          [
            {
              text: `Desired Location`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            {
              text: ``, border: [false, false, false, false],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            {
              text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            }
          ],
          [
            {
              text: `${desiredLocation}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            {
              text: ``, border: [false, false, false, false],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            {
              text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            }
          ],
          [
            {
              text: `Alternative Location`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
          ],
          [
            {
              text: `${alternateLocation}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            {
              text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            }
          ],
          [
            {
              text: `Destination Office Location`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            {
              text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            }
          ],
          [
            {
              text: `${destinationLocation}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `Type of Accommodation`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            {
              text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            }
          ],
          [
            {
              text: `${accommodationType}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `HouseKeeping?`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
          ],
          [
            {
              text: `${houseKeeping}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            {
              text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            }
          ],
          [
            {
              text: `House keeping`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
          ],
          [
            {
              text: `${housekeepingFrequency}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `HouseKeeping Comments`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
          ],
          [
            {
              text: `${comments}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `Move In`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: `Move Out`, border: [false, false, false, false],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
            { text: `Length of Stay`, border: [false, false, true, false], borderColor: ['', '',this.borderColor, ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight}
          ],
          [
            {
              text: `${moveInDate}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            {
              text: `${moveOutDate}`, border: [false, false, false, false],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            {
              text: `${lengthOfStay}`, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            }
          ],
          [
            {
              text: `Number Of Bedrooms`,  border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            {
              text: `Number Of Bathrooms`, border: [false, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            {
              text: `Number Of Apartments`, border: [false, false, true, false], borderColor: ['', '',this.borderColor,  ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            }
          ],
          [
            {
              text: `${numberOfBedrooms}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            {
              text: `${numberOfBathrooms}`, border: [false, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            {
              text: `${numberOfApartments}`, border: [false, false, true, false], borderColor: ['', '',this.borderColor,  ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            }
          ],
          [
            {
              text: `Furnishings?`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            {
              text: `Kitchen Facility Required?`, border: [false, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            {
              text: `Baby Cot Needed?`, border: [false, false, true, false], borderColor: ['', '',this.borderColor,  ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            }
          ],
          [
            {
              text: `${furnished}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            {
              text: `${kitchenRequired}`, border: [false, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            {
              text: `${babyCotRequired}`, border: [false, false, true, false], borderColor: ['', '',this.borderColor,  ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            }
          ],
          [
            {
              text: `Parking?`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
          ],
          [
            {
              text: `${parking}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          // this.dynamicLabelDisplay(housingInfo.parking ? 'Parking Financial Responsibility' : null),          
          // this.dynamicFieldDisplay(housingInfo.parking ? housingInfo.parking : null),
          // this.dynamicLabelDisplay(housingInfo.parkingComments? 'Parking Comments' : null),          
          // this.dynamicFieldDisplay(housingInfo.parkingComments ? housingInfo.parkingComments : null),
          [
            {
              text: `${financialResponsibilityLabel}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
          ],
          [
            {
              text: `${financialResponsibility}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `${ParkingCommentsLabel}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
          ],
          [
            {
              text: `${parkingComments}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `Acceptable Commutes (minutes)`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
          ],
          [
            {
              text: `${acceptableCommute}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `Priorities`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
          ],
          [
            {
              text: `(1)  ${priorities[0]}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `(2)  ${priorities[1]}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `(3)  ${priorities[2]}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `(4)  ${priorities[3]}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `(5)  ${priorities[4]}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          [
            {
              text: `Additional Instructions(Optional)`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],
              color: this.labelColor, margin: [0, 3, 0, 0], fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight
            },
            { text: ``, border: [false, false, false, false], },
            { text: ``, border: [false, false, true, false], borderColor: ['', '', this.borderColor, ''], }
          ],
          [
            {
              text: `${additionalInstructions}`, border: [true, false, false, true], borderColor: [this.borderColor, '', '', this.borderColor],
              color: this.fieldValueColor, margin: [0, 3, 0, 0], fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight
            },
            { text: ``, border: [false, false, false, true], borderColor: ['', '', '', this.borderColor], },
            { text: ``, border: [false, false, true, true], borderColor: ['', '', this.borderColor, this.borderColor], }
          ],
          
        ]
      }
    }
    return housingInfoSection;
  }

  setContryDisplayName(location) {
    let value='';
    location['streetLine1'] ? value = value + location['streetLine1'] : null;
        location['streetLine1'] && (location['city'] || location['state'] || location['postalCode'] || location['country']) ? value = value + ' , '  : null;
    location['city'] ? value = value + location['city'] : null;
        location['city'] && (location['state'] || location['postalCode'] || location['country']) ? value = value + ' , ' : null;
    location['state'] ? value = value + location['state'] : null;
        location['state'] && (location['postalCode'] || location['country'])? value = value + ' , ' : null;
    location['postalCode'] ? value = value + location['postalCode'] : null;
        location['postalCode'] && location['country']  ? value = value + ' , ' : null;
    location['country'] ? value = value + location['country'] : null;
    return value;
  }

  checkLocationFilter(value, valueList) {
    const val = valueList.find(ele => ele.country == value);
    return `${val.type} - ${val.country}`;
  }

  checkCurrencyFilter(type,currList){
    const val = currList.find(ele => ele.value == type);
    return `${val.value} - ${val.displayValue}` ;
  }

  financialDetails(financialDetails) {
    const vatCountry = financialDetails.VATCountry ? financialDetails.VATCountry : ``;
    const vatNumber = financialDetails.VATRegistration ? financialDetails.VATRegistration : `NA`;
    const format = financialDetails.mailingAddress.invoiceFormat == 'physical' ? 'Physical' : financialDetails.mailingAddress.invoiceFormat == 'digital' ? 'Digital' : 'NA';
    const remitToAddr = {
      'businessName' : financialDetails.remitToAddress &&  financialDetails.remitToAddress.businessName ? financialDetails.remitToAddress.businessName : ``,
      'line1' : financialDetails.remitToAddress && financialDetails.remitToAddress.line1 ? financialDetails.remitToAddress.line1 : ``,
      'line2' : financialDetails.remitToAddress && financialDetails.remitToAddress.line2 ? financialDetails.remitToAddress.line2 : ``,
      'city' : financialDetails.remitToAddress && financialDetails.remitToAddress.city ? financialDetails.remitToAddress.city : ``,
      'subnationalDivision' : financialDetails.remitToAddress && financialDetails.remitToAddress.subnationalDivision ? financialDetails.remitToAddress.subnationalDivision : ``,
      'country' : financialDetails.remitToAddress && financialDetails.remitToAddress.country ? financialDetails.remitToAddress.country : ``,
      'postalCode' : financialDetails.remitToAddress && financialDetails.remitToAddress.postalCode ? financialDetails.remitToAddress.postalCode : ``
    }
    const orderRef = {
      'businessName' : financialDetails.fiscalRepresentative && financialDetails.fiscalRepresentative.businessName ? financialDetails.fiscalRepresentative.businessName : `NA`,
      'line1' : financialDetails.fiscalRepresentative && financialDetails.fiscalRepresentative.line1 ? financialDetails.fiscalRepresentative.line1 : ``,
      'line2' : financialDetails.fiscalRepresentative && financialDetails.fiscalRepresentative.line2 ? financialDetails.fiscalRepresentative.line2 : ``,
      'city' : financialDetails.fiscalRepresentative && financialDetails.fiscalRepresentative.city ? financialDetails.fiscalRepresentative.city : ``,
      'subnationalDivision' : financialDetails.fiscalRepresentative && financialDetails.fiscalRepresentative.subnationalDivision ? financialDetails.fiscalRepresentative.subnationalDivision : ``,
      'country' : financialDetails.fiscalRepresentative && financialDetails.fiscalRepresentative.country ? financialDetails.fiscalRepresentative.country : ``,
      'postalCode' : financialDetails.fiscalRepresentative && financialDetails.fiscalRepresentative.postalCode ? financialDetails.fiscalRepresentative.postalCode : ``
    }
    const standardAddr = {
      'businessName' :  financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.standardMailAddress ? financialDetails.mailingAddress.physicalInvoicing.standardMailAddress.businessName : financialDetails.mailingAddress.digitalInvoicing ? financialDetails.mailingAddress.digitalInvoicing.emailAddress : ``,
      'line1' : financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.standardMailAddress ? financialDetails.mailingAddress.physicalInvoicing.standardMailAddress.line1 : ``,
      'line2' :  financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.standardMailAddress ? financialDetails.mailingAddress.physicalInvoicing.standardMailAddress.line2 : ``,
      'city' : financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.standardMailAddress ? financialDetails.mailingAddress.physicalInvoicing.standardMailAddress.city : ``,
      'subnationalDivision' : financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.standardMailAddress ? financialDetails.mailingAddress.physicalInvoicing.standardMailAddress.subnationalDivision : ``,
      'country' : financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.standardMailAddress ? financialDetails.mailingAddress.physicalInvoicing.standardMailAddress.country : ``,
      'postalCode' : financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.standardMailAddress ? financialDetails.mailingAddress.physicalInvoicing.standardMailAddress.postalCode : ``
    }
    
    const expressAddr = {
      'businessName' :  financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.expressMailAddress ? financialDetails.mailingAddress.physicalInvoicing.expressMailAddress.businessName :  ``,
      'line1' : financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.expressMailAddress ? financialDetails.mailingAddress.physicalInvoicing.expressMailAddress.line1 : ``,
      'line2' :  financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.expressMailAddress ? financialDetails.mailingAddress.physicalInvoicing.expressMailAddress.line2 : ``,
      'city' : financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.expressMailAddress ? financialDetails.mailingAddress.physicalInvoicing.expressMailAddress.city : ``,
      'subnationalDivision' : financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.expressMailAddress ? financialDetails.mailingAddress.physicalInvoicing.expressMailAddress.subnationalDivision : ``,
      'country' : financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.expressMailAddress ? financialDetails.mailingAddress.physicalInvoicing.expressMailAddress.country : ``,
      'postalCode' : financialDetails.mailingAddress && financialDetails.mailingAddress.physicalInvoicing && financialDetails.mailingAddress.physicalInvoicing.expressMailAddress ? financialDetails.mailingAddress.physicalInvoicing.expressMailAddress.postalCode : ``
    }
    const FinancialDetailsInfo = {
      table: {
        headerRows: 0, widths: ['40%', '25%', '30%', '5%'], style: 'programTable',
        body: [
          //header
        [
           { text: 'Financial Details', border: [true, true, false, false], borderColor: [this.borderColor, this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
           { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
           { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, this.borderColor, ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
           { text: '', border: [false, true, true, false], borderColor: ['', this.borderColor, this.borderColor, ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight }
         ],
         //row 1
         [
           { text: 'Cartus VAT Country', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
           { text: 'Cartus VAT Registration #', border: [false, false, false, false], borderColor: ['', '', '', ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
           { text: 'Invoice Format', border: [true, true, true, false], borderColor: [this.borderColor, this.borderColor, this.borderColor, ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
           { text: '', border: [true, false, true, false], borderColor: ['', '', this.borderColor, ''],  color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
         ],
         [
           { text: `${vatCountry}`, border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
           { text: `${vatNumber}`, border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
           { text: `${format}`, border: [true, false, true, false], borderColor: [this.borderColor, '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
           { text: '', border: [true, false, true, false], borderColor: ['', '', this.borderColor, ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
         ],
         //row 2
         [
           { text: 'Remit to Address', border: [true, false, false, false], borderColor: [this.borderColor, '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
           { text: 'Fiscal Representative', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight},
           { text: 'Mailing Address', border: [true, false, true, false], borderColor: [this.borderColor, '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
           { text: '', border: [true, false, true, false], borderColor: [this.borderColor, '', this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
         ],
         [
           { text: `${remitToAddr.businessName} \n ${remitToAddr.line1} ${remitToAddr.line2} \n ${remitToAddr.city} \n ${remitToAddr.subnationalDivision} \n ${remitToAddr.country} \n ${remitToAddr.postalCode}`, border: [true, false, false, false], borderColor: [ this.borderColor, '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: '0.7' },
           { text: `${orderRef.businessName} \n ${orderRef.line1} ${orderRef.line2} \n ${orderRef.city} \n ${orderRef.subnationalDivision} \n ${orderRef.country} \n ${orderRef.postalCode}`, border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: '0.7' },
           { text: `${standardAddr.businessName} \n ${standardAddr.line1} ${orderRef.line2} \n ${standardAddr.city} \n ${standardAddr.subnationalDivision} \n ${standardAddr.country} \n ${standardAddr.postalCode}`, border: [true, false, true, false], borderColor: [this.borderColor, '', this.borderColor, this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: '0.7' },
           { text: '', border: [true, false, true, false], borderColor: [this.borderColor, '', this.borderColor, this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
         ],
         //row 3
         [
           { text: '', border: [true, false, false, false], borderColor: [ this.borderColor, '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: '0.7' },
           { text: '', border: [false, false, false, false], borderColor: ['', '', '', ''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: '0.7' },
           { text: `${expressAddr.businessName} \n ${expressAddr.line1} ${orderRef.line2} \n ${expressAddr.city} \n ${expressAddr.subnationalDivision} \n ${expressAddr.country} \n ${expressAddr.postalCode}`, border: [true, false, true, true], borderColor: [this.borderColor, '', this.borderColor, this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: '0.7' },
           { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor,''], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
         ],
         [
          { text: '', border: [true, false, false, true], borderColor: [ this.borderColor, '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: '0.7' },
          { text: '', border: [false, false, false, true], borderColor: ['', '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: '0.7' },
          { text: '', border: [false, false, false, true], borderColor: ['', '', this.borderColor, this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: '0.7' },
          { text: '', border: [false, false, true, true], borderColor: ['', '', this.borderColor, this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
        ],
       ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return FinancialDetailsInfo;
  }

  cardSpacing() {
    const space = {
      table: {
        body: [
          [
            {
              text: '', margin: [0, 2, 0, 2], border: [false, false, false, false],
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return space;
  }   

  modificationBlock(modification) {
    let modContent = [];
    const header = [
      { text: 'Order Modifications', border: [true, true, false, false], borderColor: [this.borderColor, this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
      { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
      { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
      { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
      { text: '', border: [false, true, true, false], borderColor: ['', this.borderColor, this.borderColor, ''], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
    ];
    modContent.push(header);
    // let tableHeader;
    
    if (modification.modificationData && modification.modificationData.length) {
      modContent.push([
        { text: `${modification.checkHybridTempliving ? 'Days Added':'Amount Added'}`, border: [true, false, false, true], borderColor: [this.borderColor, this.borderColor, '', this.borderColor], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: 1 },
        { text: `${modification.checkHybridTempliving ? 'Total Length of Stay':'Total Amount Reimbursed'}`, border: [false, false, false, true], borderColor: ['', this.borderColor, this.borderColor, this.borderColor], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: 1 },
        { text: `${modification.checkHybridTempliving ? 'New Move Out Date':''}`, border: [false, false, false, true], borderColor: ['', this.borderColor, this.borderColor,this.borderColor], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: 1 },
        { text: `Date of Modification`, border: [false, false, false, true], borderColor: ['', this.borderColor, this.borderColor,this.borderColor], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: 1 },
        { text: `Status`, border: [false, false, true, true], borderColor: ['', this.borderColor, this.borderColor, this.borderColor], color: this.labelColor , fontSize: this.labelFont, style: 'avenirLight', lineHeight: 1 }
      ]);

      for (let i = 0; i < modification.modificationData.length; i++) {
        modContent.push([
          { text: `${modification.checkHybridTempliving ? modification.modificationData[i].daysAdded ? modification.modificationData[i].daysAdded + ' ' +  modification.rangeIncrementUnit : '' : 
            modification.modificationData[i].amountAdded ? modification.modificationData[i].amountAdded + ' ' + modification.incrementalCurrencyUnit : ''}`, 
            border: [true, false, false, true], borderColor: [this.borderColor, '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
          { text: `${modification.checkHybridTempliving ? modification.modificationData[i].totalLengthofStay ? modification.modificationData[i].totalLengthofStay + ' ' +  modification.rangeIncrementUnit : '' :
            modification.modificationData[i].totalAmountReimbursed ? modification.modificationData[i].totalAmountReimbursed + ' ' + modification.incrementalCurrencyUnit : ''}`, 
            border: [false, false, false, true], borderColor: ['', '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
          { text: `${modification.checkHybridTempliving && modification.modificationData[i].newMoveOutDate ? moment(modification.modificationData[i].newMoveOutDate).format('YYYY-MM-DD') : ''}`, 
            border: [false, false, false, true], borderColor: ['', '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
          { text: `${modification.modificationData[i].dateOfModification ? moment(modification.modificationData[i].dateOfModification).format('YYYY-MM-DD') : ''}`, 
            border: [false, false, false, true], borderColor: ['', '', '', this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight },
          { text: `${modification.modificationData[i].status && modification.modificationData[i].status !== 'Set Status' ? modification.modificationData[i].status : ''}`, 
            border: [false, false, true, true], borderColor: ['', '', this.borderColor, this.borderColor], color: this.fieldValueColor, fontSize: this.fieldValueFont, style: 'avenirLight', lineHeight: this.lineHeight }
        ])
      }

      // return modContent;
      const modBlock = {
        table: { headerRows: 0, widths: ['20%', '25%', '15%' , '20%' , '20%'], style: 'programTable', body: modContent }
      }
      return modBlock;
    } else {
      return this.noModificationBlock();
    }
  }

  noModificationBlock() {
    const header = [
      { text: 'Order Modifications', border: [true, true, false, false], borderColor: [this.borderColor, this.borderColor, '', ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
      { text: '', border: [false, true, false, false], borderColor: ['', this.borderColor, this.borderColor, ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight },
      { text: '', border: [false, true, true, false], borderColor: ['', this.borderColor, this.borderColor, ''], fontSize: this.sectionHeaderFont, color: '#000000', style: 'avenirMedium', lineHeight: this.lineHeight }
    ];
    const lastSpaceAdjust = [
      { text: '', border: [true, false, false, true], borderColor: [this.borderColor, '', '', this.borderColor], color: this.labelColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: 1 },
      { text: '', border: [false, false, false, true], borderColor: ['', '', '', this.borderColor], color: this.labelColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: 1 },
      { text: '', border: [false, false, true, true], borderColor: ['', '', this.borderColor, this.borderColor], color: this.labelColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: 1 }
    ];
    let noModBlock = [];
      noModBlock.push(header);
      noModBlock.push([
        { text: '', border: [true, false, false, false], borderColor: [this.borderColor, '', '', this.borderColor], color: this.labelColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
        { text: 'No Modifications Recorded', border: [false, false, false, false], borderColor: ['', '', '', this.borderColor], color: this.labelColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight },
        { text: '', border: [false, false, true, false], borderColor: ['', '', this.borderColor, this.borderColor], color: this.labelColor, fontSize: this.labelFont, style: 'avenirLight', lineHeight: this.labelLineHeight }
      ]);
      noModBlock.push(lastSpaceAdjust);
      const noneModBlock = {
        table: { headerRows: 0, widths: ['40%', '30%', '30%'], style: 'programTable', body: noModBlock }
      }
      return noneModBlock;
  }
}