<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column" fxFlex="100%">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle"> {{ formTitle }}</div>
        <a  class="close-icon" (click)="onDismiss()" [ngClass]="{'disable':formDisable==true}" title="close"  fxLayoutAlign="end" >
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <form fxLayout="column" class="middle-section" (ngSubmit)= "addClient()" [formGroup] = "clientForm" >
        <div class="middle-section" style = "justify-content: space-between;">
            <mat-dialog-content>
                <div>
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em">
                        <!--Client Name field-->
                        <div fxFlex="85%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Client Name</mat-label>
                                <input matInput placeholder="Client Name" formControlName="clientName" character-only minLength="1"
                                    maxlength="50" required>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!-- Buttons -->
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em">
                        <button fxFlex="20%"  type="button" class="mat-button text-button" (click)="onDismiss()" id="cancel">CANCEL</button>
                        <button fxFlex="20%" type="submit" class="mat-button contained-button send-invite" id="save" *ngIf = "!editClientEnable" [disabled] ="!clientForm.get('clientName').valid">SAVE</button>
                        <button fxFlex="20%" type="button" class="mat-button contained-button send-invite" id="update" *ngIf = "editClientEnable" (click)="updateClient()">UPDATE</button>
                    </div>
            </mat-dialog-content>
        </div>
    </form>
</div>

