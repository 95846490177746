import { Component, OnInit ,Input, Inject, Output,EventEmitter, ViewEncapsulation, ViewChild} from '@angular/core';
//import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { ProgramManagementService } from '../../services/program-management.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { RemoteLoggingService } from './../../../../core/services/remote-logging.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatInputModule, MatSelectChange, MatSelectionList, MatOption, MatListOption } from '@angular/material';

@Component({
  selector: 'app-division-association',
  templateUrl: './division-association.component.html',
  styleUrls: ['./division-association.component.scss'],
  encapsulation: ViewEncapsulation.None
}) 
export class DivisionAssociationComponent implements OnInit {
  showhidepregnant = false;
  value = 'Clear me';
  divisionForm: FormGroup;
  division: any;
  clientDivisions: Array<any>;
  clientDivisionsList: Array<any>;
  selectDivisionsList: Array<any> = [];
  selectedDivision: Array<any> = [];
  selectDivisionFlag = false;
  addedDivision: any;
  arr: FormArray;
  @ViewChild('divisionSelect', {static: true}) divisionSelect: MatListOption;
  @ViewChild('allSelect', {static: true}) allSelect: MatListOption;
  @Output() publishDivisions = new EventEmitter<String>();

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private programService: ProgramManagementService,
    private spinner: NgxSpinnerService,
    private logSrv: RemoteLoggingService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<DivisionAssociationComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.generateForm();
   }

  ngOnInit() {
    this.getClientDivisions();
  }

  generateForm() {
    this.divisionForm = new FormGroup({
      addDivisions: new FormArray([]),
    });
    
  }
  checkForExistingData(allDivisions) {
    if (this.data.program.divisions && this.data.program.divisions.length > 0) {
        if(this.data.program.divisions.length === allDivisions.length) {
          this.allSelect.selected = true;
        } else {
          this.selectDivisionFlag = true;
          this.divisionSelect.selected = true;
        }
    }
  }
addDivisionArrayElement(val) {
  this.addDivisions.push(new FormControl(val,Validators.required));
  this.addDivisions.updateValueAndValidity();
  
}
  get addDivisions(): FormArray {
    return this.divisionForm.get('addDivisions') as FormArray;
  }

  getClientDivisions() {
    this.programService.getClientDivisions(this.data.clientId).subscribe(data => {
      if (data) {
        this.clientDivisionsList = this.clientDivisions = data.divisions;
        this.selectDivisionsList = [...this.clientDivisionsList,...[]];
        this.checkForExistingData (this.clientDivisionsList);
        if ( this.data.program && this.data.program.divisions.length > 0) {
          this.data.program.divisions.forEach( element => {
            this.addDivisionArrayElement(element);
              const index = this.selectDivisionsList.findIndex( div => div.division_id === element.division_id);
              if (index > -1) {
                this.selectDivisionsList.splice(index, 1);
             }
            });
        }
        this.addDivisionArrayElement('');
      } else {
        this.logSrv.logError('Failed to load Client Divisions');
      }
    }, () => {
      this.logSrv.logError('Failed to load Client Divisions');
    });
  }

  selectClick(selected: boolean) {
    if (selected) {
      this.allSelect.selected = false;
      this.selectDivisionFlag = true;
      this.divisionSelect.selected = true;
    }
    else{
      this.selectDivisionFlag = false;
      this.divisionSelect.selected = false;
    }
  }

  selectAll(selected: boolean) {
    if (selected) {
      this.divisionSelect.selected = false;
      this.selectDivisionFlag = false;

    }
  }

  AssociateDivisions() {
    this.addDivisions.push(new FormControl('', Validators.required));
    this.addDivisions.value.forEach(element => {
      this.selectDivisionsList = this.selectDivisionsList.filter(division => division.division_id !== element.division_id);
    });
  }

  pushBackToList(index: number) {
    const findIndex = this.selectDivisionsList.findIndex(x => 
      x.division_id === this.addDivisions.value[index].division_id);
    let removeValue;
      if (this.addDivisions.length !== 1) {
        removeValue = this.addDivisions.value[index];
        this.addDivisions.removeAt(index);
      }
    if ( findIndex < 0 && index <= this.addDivisions.length - 1) {
      
      this.selectDivisionsList.push( removeValue );
      this.addDivisions.length > 1 ? this.selectDivisionsList.sort((a, b) => a.division_name.localeCompare(b.division_name)) : null;
    } else {
      this.addDivisions.push(new FormControl('', Validators.required));
    
    }
  }

  checkAddButtonVisibility(index: number) {
    if (index === this.addDivisions.length - 1) {
      return true;
    }
    return false;
  }

  checkRemoveButtonVisibility(index: number) {
    if (index === 0 && this.addDivisions.length === 1) {
      return false;
    }
    return true;
  }

  selectionType(event) {
    if (event.option._value === 'All') {
      this.selectDivisionFlag = false;
      this.divisionSelect.selected = false;
    } else {
      this.selectDivisionFlag = true;
    }
  }

  save() {
    const divisionsList = this.divisionForm.get('addDivisions').value;
    let divisions = [];
    divisionsList.forEach((element: any) => {
          if(element){
            divisions.push(element);
          }
      });

    let allDivisions = [];
    this.clientDivisionsList.forEach((element: any) => {
      if (element !== "" ) {
        allDivisions.push(element);
      }
    });

    let divList = this.selectDivisionFlag ? divisions : allDivisions;
    // this.data.program ? divList.push(...this.data.program.divisions) : null;
    const divIdList = [];
    divList.forEach(element => {
      if (element) {
        divIdList.push(element.division_id);
      }
    });
    const returnData = {
      "program_id" : this.data.program ? this.data.program.id : '',
	    "divisions" : divIdList
    }

    if(this.data.program && this.data.program.id){
      this.programService.associateDivisions(returnData).subscribe(data => {
        if (data.divisionsUpdated) {
          this.publishedDivision(divList);
        } else {
          this.logSrv.logError('Failed to update Divisions');
        }
      }, () => {
        this.logSrv.logError('Failed to load update Divisions');
      });
    } else {
      this.publishedDivision(divList);
    }
  }

  publishedDivision(divisions){
    this.dialogRef.close(divisions);
  }

  close() {
    this.selectedDivision = []
    this.dialogRef.close([]);
  }
}
