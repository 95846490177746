<div class="dialog-container" role="region">
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px" fxLayoutGap.xs="0em" role="heading">
        <h2 class="divisions-title"> Divisions:</h2>
        <a class="close-icon" (click)="close()" title="close" role="button" aria-label="Close Program Modal" title="Close Program Modal">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <mat-dialog-content style="width: 550px; display: block;">
        <div>
            <mat-selection-list (selectionChange)="selectionType($event)">
                <mat-list-option #allSelect value="All"  (click)="selectAll(true)">
                    All Divisions
                </mat-list-option>
            </mat-selection-list>
        </div>
        <div>
            <mat-selection-list #divisionSelectList>
                <mat-list-option #divisionSelect value="select" (click)="selectClick(true)">
                    Select Divisions
                </mat-list-option>  
            </mat-selection-list>
        </div>
        <form class="middle-section" [formGroup]="divisionForm">
            <div *ngIf="selectDivisionFlag" formArrayName="addDivisions" class="division-input">
                <div class="dynamic-input form-group" fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" *ngFor="let divisions of addDivisions.controls; index as ind">
                    <mat-form-field class="example-form-field" *ngIf="ind < clientDivisionsList.length">
                        <mat-label>Divisions</mat-label>                                    
                        <!-- <mat-select [formControlName]="ind" [disabled] = "ind <= addDivisions.value.length-2" *ngIf = "ind <= addDivisions.value.length-2">
                            <mat-option [value]="division" *ngFor="let division of clientDivisionsList let i=index; let l=last" style="font-size: 14px;">
                                {{ division.division_name}} ({{ division.division_number }})
                            </mat-option>
                        </mat-select> -->
                        <input matInput [readonly]="true" [formControlName]="ind" style="font-size: 14px;" 
                        value="{{ divisions.value.division_name}} ({{ divisions.value.division_number }})" 
                        *ngIf = "ind <= addDivisions.value.length-2">
                        <mat-select [formControlName]="ind" [disabled] = "ind <= addDivisions.value.length-2" *ngIf="ind > addDivisions.value.length-2">
                            <mat-option [value]="division" *ngFor="let division of selectDivisionsList let i=index; let l=last" style="font-size: 14px;">
                                {{ division.division_name}} ({{ division.division_number }})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button class="minus" *ngIf="checkRemoveButtonVisibility(ind) && ind < clientDivisionsList.length" (click)="pushBackToList(ind)"></button>
                    <button class="plus" *ngIf="checkAddButtonVisibility(ind) && ind < clientDivisionsList.length" [disabled]="addDivisions.status === 'INVALID'" (click)="AssociateDivisions()"></button>
                    <br />
                </div>
            </div>
        </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button class="text-button rest-btn" (click)="close()">CANCEL</button>
        <button mat-button class="contained-button" [disabled] = "!(allSelect._selected || addDivisions.value.length !== 1)" (click)='save()'>Save</button>
    </mat-dialog-actions>
</div>