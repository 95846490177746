import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { EmployeeDetailsService } from '../../../../core/services/employee-details.service';
import { trigger, state, style, transition, animate, group } from '@angular/animations';
import { EmployeeInfo } from '../../../../core/models/employee-info.model';
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
import { UserPreference } from '../../../../core/models/user-preference.model';
import { truncate } from 'node:fs';

/** This is the animation for the phone and email displays */
const SlideInOutAnimation = [
  trigger('slideInOut', [
      state('in', style({
          'max-height': '500px', 'height': 'auto', 'opacity': '1', 'visibility': 'visible'
      })),
      state('out', style({
        'max-height': '0', 'height': '0', 'opacity': '0', 'visibility': 'hidden'
      })),
      transition('in => out', [group([
          animate('400ms ease-in-out', style({
              'opacity': '0'
          })),
          animate('600ms ease-in-out', style({
              'max-height': '0px',
              'height': '0px'
          })),
          animate('700ms ease-in-out', style({
              'visibility': 'hidden'
          }))
      ]
      )]),
      transition('out => in', [group([
          animate('1ms ease-in-out', style({
              'visibility': 'visible'
          })),
          animate('300ms ease-in-out', style({
              'max-height': '500px',
              'height': 'auto'
          })),
          animate('800ms ease-in-out', style({
              'opacity': '1'
          }))
      ]
      )])
  ]),
];
/** This component displays employee name, Id, divisionName, email and phone. */
@Component({
  selector: 'app-employee-header',
  templateUrl: './employee-header.component.html',
  styleUrls: ['./employee-header.component.scss'],
  animations: [SlideInOutAnimation]
})
export class EmployeeHeaderComponent implements OnInit, OnDestroy {
  /** holds subscription to the data */
  sub: Subscription;

  /** holds subscription to the employeeInfo data */
  employee$: Observable<EmployeeInfo>;

  /** holda the state of the animation for the phone section, in or out, out by default (no display) */
  phoneAnimationState = 'out';

  /** holda the state of the animation for the email section, in or out, out by default (no display) */
  emailAnimationState = 'out';

  /** hold file number of employee */
  fileNumber: any;

  /** hold favorite user flag of employee */
  favUser: number = 0

   /** Base constructor method
  * @param empDetailSvc EmployeeDetailsService injection
  * @param route: ActivatedRoute injection
  */
  constructor(
    private readonly empDetailSvc: EmployeeDetailsService,
    private readonly route: ActivatedRoute,
    private readonly userPreferenceService : UserPreferencesService
  ) { }
   /** get the employee header data */
  ngOnInit() {
    this.favUser = 0;
    this.sub = this.route.params.subscribe(params => {
      this.fileNumber = +params.id;
      
      this.userPreferenceService.getPreference('favorites_list', false, true)
        .subscribe (
          (response) => {
            if (response) {
              let favouritesdata;
              if(sessionStorage.getItem('favorites_list')) {
                favouritesdata = (JSON.parse(sessionStorage.getItem('favorites_list')));
              }
              if ( favouritesdata && favouritesdata.updateList && favouritesdata.updateList.value ){
                favouritesdata.updateList.value.forEach(element => {
                  if( element == this.fileNumber) {
                    this.favUser = 1;
                  }
                });
              } 
              this.employee$ = this.empDetailSvc.getEmployeeInfo(this.fileNumber, this.favUser);
            }
          }
        );
    });


  }

  /** toggles the phone display between out (no display) and in (display).  The animation handles the transistion between these states */
  togglePhoneDisplay() {
    this.phoneAnimationState = this.phoneAnimationState === 'out' ? 'in' : 'out';
  }

  /** toggles the email display between out (no display) and in (display).  The animation handles the transistion between these states */
  toggleEmailDisplay() {
    this.emailAnimationState = this.emailAnimationState === 'out' ? 'in' : 'out';
  }

  /** update favorite flag for employee */
  updateFavorite() {
    let favoritesList = {
      settingId : "",
      updateList : {
                      appName : "", 
                      name : "",
                      value : []

      }
      }; 
      
      const favoriteParam = {
            favoriteData: [
              {
                favUser: this.favUser == 0 ? 1 : 0,
                fileNumber: this.fileNumber
              }
            ]
          };
    this.empDetailSvc.updateFavorite(favoriteParam)
      .subscribe((response) => {
            if(response){
              this.favUser = this.favUser == 0 ? 1 : 0;
              favoritesList = {
                  settingId : response._id,
                  updateList : {
                                  appName : response.appName,
                                  name : response.name,
                                  value : response.value
                                }};
              
              const preference: UserPreference = {
                Key: 'favorites_list',
                Value: favoritesList,
                Persist: false
              };
              this.userPreferenceService.setPreference(preference);
          }
          });
      
    
  }

  /** cleanup the subscription and anything else necessary */
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

}
