import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { LoginService } from 'src/app/login/login.service';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  appId = 0;
  logindata:{
    username : string,
    password : string
  }; 
  username = '';
  password = '';
  @ViewChild('f',null) loginform: NgForm;
  logo: string;
  isAuthenticated: boolean = false;
  message:string = "";
  templateString = {
    BNNER_TXT: `Technology That Moves You`,
    MOVEPRO_TXT: `Welcome to MovePro360 ─ your centralized mobility hub and single source of truth ` +
    `for all your relocation and global talent mobility goals. Optimize move outcomes, anticipate ` +
    `needs, access predictive insights, and much more.`
  };
  constructor(
    private router: Router,
    private loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private readonly spinner: NgxSpinnerService,
    private readonly toastrService: ToastrService,
    ) {}

  ngOnInit() {
    this.activatedRoute.params.subscribe((par: Params) => {
      this.appId = par['id']
      this.setDefaultvalue();
      if (this.isLoginNotRequired()) {
        if (typeof this.appId == 'undefined') {
          this.router.navigateByUrl('/home')
        }
      } else {
        this.loginService.clearLoginInfo();
        if (typeof this.appId != 'undefined') {
            this.router.navigate(['/login']);
        }
      }
    })
  }
  setDefaultvalue(){
    if(this.appId == 1){
      this.logindata = {
        username:'R.William@demo.com',
        password:'Welcome123$'
      } 
    } else if(this.appId == 2 || this.appId == 4){
      this.logindata = {
        username:'M.William@demo.com',
        password:'Welcome123$'
      }
    }
    setTimeout(() => { 
      this.loginform.setValue(this.logindata);
    },0);
  }
  onLogin(loginForm: NgForm) {
    
    if (this.isLoginNotRequired()) {
      this.navigateToDemoApp();
    } else {
      this.loginService.isAuthenticatedUser = false;
      this.checkUserAuthentication(this.loginform.value.username, this.loginform.value.password);    
    }
  }
isLoginNotRequired() {
  return this.loginService.isTokenValid();
}
checkUserAuthentication(userName: string, password: string) {
  this.spinner.show();
  this.loginService.authenticateUserFromBackend(userName,password).subscribe(
    data =>{
      this.spinner.hide();
      if(data.message == "User logged in successfully!") {
        if(data.loggedInUser && data.loggedInUser.jwtToken) {
          this.loginService.storeJwtToken(data.loggedInUser.jwtToken);
        }
        if (data.loggedInUser && data.loggedInUser.subscriptionEndDate) {
          this.loginService.storeSubscription(data.loggedInUser.subscriptionEndDate);
        }
        if(data.loggedInUser && data.loggedInUser.sessionDurationNo && data.loggedInUser.sessionDurationType) {
          this.loginService.storeSessionDuration(data.loggedInUser.sessionDurationNo, data.loggedInUser.sessionDurationType);
        }
        let loggedInDate =  new Date();
        this.loginService.storeLastLoginDate(loggedInDate)
        
        this.navigateToDemoApp();
      }
      if(data.hasOwnProperty('error')) {
        this.message = "Invalid Credentials. Please Retry!!!"  
        this.showErrorMessage();      
      }
    },
    err =>{
      this.spinner.hide();
      if (err.error) {
        this.message = err.error.error.message;
      } else {
        this.message = "Invalid Credentials. Please Retry!!! error bolock"      
      }
      this.showErrorMessage();
    }
  );
}

navigateToDemoApp(){
  if (this.isLoginNotRequired()) {
    if (typeof this.appId == 'undefined') {
      this.router.navigateByUrl('/home')
    }
    else {
     
      if (this.appId == 1) {
        this.router.navigateByUrl('/bb/dashboard');
      } else if (this.appId == 4) {
        this.router.navigate(['/bbadmin/administration/transferee']);
      } else if (this.appId == 3) {
        this.router.navigate(['/mobilify']);
      } else if (this.appId == 2) {
        this.router.navigate(['/bbadmin/administration/dashboard']);
      }
      else {
        alert('unsucessful login')
        this.router.navigateByUrl('/login')
      }
    }
  }
  else {
    this.loginform.reset();
    this.loginService.isAuthenticatedUser = false;
    this.router.navigateByUrl('/login')
  }
}  
showErrorMessage() {
  let errorMessage = this.message;
  if(this.message.toLowerCase().includes('expired')) {
    errorMessage = 'Your Subscription For DemoApp Got Expired';
  }
  this.toastrService.error(errorMessage, 'Authentication Failed',  {
    closeButton: false,
    timeOut: 5000,
    enableHtml: true,
    disableTimeOut: false,
  })
}

  
}


