<div class="dialog-container" role="region">
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px"
      fxLayoutGap.xs="0em" role="heading">
      <h2 class="divisions-title"> Unlock Submitted High Value Goods </h2>
      <a class="close-icon" (click)="close()" title="close" role="button" aria-label="Close Program Modal"
        title="Close Program Modal">
        <mat-icon>close</mat-icon>
      </a>
    </div>
    <mat-dialog-content class="dialog-content">
          <p class="content-head">You are about to unlock the High Value Goods form for the following benefit:</p>
              <div class="program-list">
                  <input matInput readonly value="{{data.benefit?.displayName}}">
              </div>
          <p class="aware-text"> <b> Please be aware:</b></p>
           <ul>
              <li>The Transferee will need to complete and resubmit the form.</li>
              <li>All related notifications and triggers will be reset.</li>
           </ul>
      </mat-dialog-content>
      <mat-dialog-actions class="dialog-actions" align="end">
          <button mat-button class="text-button rest-btn" (click)="close()">CANCEL</button>
          <button mat-button class="contained-button" (click)="unlockForm()" >Unlock Form</button>
      </mat-dialog-actions>
  </div>