<div class="benefits-detail-wrapper" fxLayout="column" fxLayoutAlign="space-evenly stretch" role="none">
    <span class="benefit-note" *ngIf="benefit && !benefit.selected && benefit.consultantOnlyCard && !benefit.activated">Benefit Currently Not Visible To Transferee</span>
    <span class="benefit-note" *ngIf="benefit && benefit.selected && benefit.consultantOnlyCard && benefit.activated && isConsultant">Benefit Currently Visible To Transferee</span>
    <div class="card-modal-header" fxLayoutAlign="space-between center" role="group">        
        <button class="close-btn" (click)="closeDetails()" aria-label="Close Benefit Popup" title="close popup">
            <img class="close" src="../../../../../assets/images/close-icon.svg" alt="close popup" >
        </button>

        <img class="home-sale-img" 
            [src]="benefit.icon ? '../../../../../assets/icons/bb_icons/' + benefit.icon : '../../../../../assets/images/enabled_generic_benefit_icon.svg'" 
            alt="{{benefit.displayName}}" title="{{benefit.displayName}}" />
        
        <div class="home-sale-info" fxLayout="row" fxHide.xs fxShow role="none" fxLayoutGap="25px">
            <div fxLayout="column">
                <p class="home-sale-text" role="heading">{{ benefit.displayName }}</p>
                <p class="home-sale-points" *ngIf="(benefit.points === 'Guaranteed')&&(!isCashoutDetail)">0 Points</p>
                <p class="home-sale-points" *ngIf="(benefit.points !== 'Guaranteed')&&(!isCashoutDetail) && (!benefit.pointsPerIncrement) && (!benefit.perPointCurrency)">{{ benefit.points }} Points</p>
                <p class="home-sale-points" *ngIf="benefit.tierConfigs && benefit.selected ">{{benefit.advCashOutSelectedPoints}} Point(s):  {{benefit.advCashOutPointValue | currency: ' ' : 'symbol':'1.0-0'}} USD</p>
                <p class="home-sale-points" *ngIf="benefit?.pointsPerIncrement && !benefit?.hybridTempLiving">{{ benefit.pointsPerIncrement}}
                    Point(s): {{benefit.rangeIncrementValue}} {{benefit.rangeIncrementUnit}} </p>               
                <p class="home-sale-points" *ngIf="benefit?.perPointCurrency">{{ benefit.rangeIncrementValue}}
                    Point(s):<span *ngIf="benefit?.rangeIncrementValue > 1">s</span> for {{benefit.perPointCurrency}}
                    {{benefit.rangeIncrementUnit}}</p> 
                    <p class="home-sale-points" *ngIf="benefit?.pointsPerIncrement && points.length > 0 && data.delete && benefit?.hybridTempLiving">
                        <mat-form-field>
                            <mat-select [formControl]="deleteDropdown" (click)="checkDeleteHybrid= false">
                               <mat-option (click)="!checkDeleteHybrid?deleteHybridBenefit(benefit,list.index): null" *ngFor="let list of points"
                                    [value]="list.days"><span>{{list.days}} {{benefit.rangeIncrementUnit}}</span>&nbsp;-&nbsp;<span
                                        class="mr-l">{{list.point}} Points</span></mat-option>
                            </mat-select>
                        </mat-form-field>
                    </p>
            </div>            
        </div>

        <div class="home-sale-buttons" fxLayout="column" role="none">
            <button class="add-benefits-button" fxLayoutAlign="space-around center" 
                fxLayout="row" aria-label="Add Benefit" 
                (click)="selectBenefit(benefit)" [disabled]="cashoutInvaild"
                *ngIf="(( !benefit.tierConfigs &&
                ((benefit && !benefit.selected && benefit.points !== 'Guaranteed') && !benefit.maxMul && !benefit.pointsPerIncrement && !benefit.perPointCurrency))) && !(benefit.consultantOnlyCard && !benefit.activated && isConsultant)">                
                    <mat-icon class="add-icon" aria-label="Add Benefit">add</mat-icon> 
                    Add Benefit
            </button>
            <!--Add benefit button for multiplicity cashout-->
            <button mat-button [matMenuTriggerFor]="menu" class="add-benefits-button" *ngIf="(benefit && !benefit.selected && benefit.points !== 'Guaranteed') && benefit.maxMul  && !(benefit.consultantOnlyCard && !benefit.activated && isConsultant)"
                fxLayoutAlign="space-around center" fxLayout="row" aria-label="Add Benefit">
                <mat-icon class="add-icon" aria-label="Add Benefit">add</mat-icon>
                Add Benefit
                <mat-icon class="add-arrow-icon" aria-label="Add Benefit">arrow_drop_down</mat-icon>
            </button>
            <!-- Add benefit button for advanced cashout-->
            <button mat-button [matMenuTriggerFor]="cashoutMenu" class="add-benefits-button" *ngIf="(benefit && !benefit.selected && benefit.points !== 'Guaranteed') && benefit.tierConfigs  && !(benefit.consultantOnlyCard && !benefit.activated && isConsultant)"
                fxLayoutAlign="space-around center" fxLayout="row" aria-label="Add Benefit">
                <mat-icon class="add-icon" aria-label="Add Benefit">add</mat-icon>
                Add Benefit
                <mat-icon class="add-arrow-icon" aria-label="Add Benefit">arrow_drop_down</mat-icon>
            </button>

            <mat-menu #cashoutMenu="matMenu">
                <ng-container *ngIf="(benefit && !benefit.selected && benefit.points !== 'Guaranteed') && benefit.tierConfigs">
                <ng-container *ngFor="let val of cashoutPointsList ; index as i" >
                <button mat-menu-item fxLayoutAlign="space-between center" (click)="updateMultiplesOfCashoutBenefit(benefit,cashoutPointsList[i],i+1)" >
                    <span *ngIf="!benefit?.pointsPerSelection" role="none">Add x {{i+1}}</span>
                    <span *ngIf="benefit?.pointsPerSelection" role="none">Add {{(i+1)*benefit.pointsPerSelection}} Points</span>
                    <span class="point-value" role="none">{{cashoutPointsList[i] |  number}} USD</span>
                </button>
            </ng-container>
            </ng-container>
            </mat-menu>

            <!-- Activate Benefit button for deactivated cards-->
            <button class="add-benefits-button activate-benefit" fxLayoutAlign="space-around center" 
                fxLayout="row" aria-label="Activate Benefit" 
                (click)="activatehideBenefit(benefit,'activate')"
                *ngIf="benefit && !benefit.selected && benefit.consultantOnlyCard && !benefit.activated">                
                    <mat-icon class="add-icon" aria-label="Activate Benefit">visibility</mat-icon> 
                    Activate Benefit
            </button>
            <!-- Activate and select benefit button for deactivated cards-->
            <button class="hide-benefits-button active-select" fxLayoutAlign="space-around center" 
                fxLayout="row" aria-label="Activate & select" 
                (click)="activatehideBenefit(benefit,'activateSelect')"
                *ngIf="benefit && !benefit.selected && benefit.consultantOnlyCard && !benefit.activated && isConsultant">                
                <mat-icon class="add-icon" aria-label="Add Benefit">add</mat-icon> 
                    Activate & Select
            </button>

            <!-- Hide benefit button for activated cards-->
            <button class="hide-benefits-button" fxLayoutAlign="space-around center" 
                fxLayout="row" aria-label="Hide Benefit" 
                (click)="activatehideBenefit(benefit,'hide')" *ngIf="benefit && !benefit.selected && benefit.consultantOnlyCard && benefit.activated && isConsultant"  >         
                <mat-icon class="add-icon" aria-label="Activate Benefit">visibility</mat-icon> 
                    Hide Benefit
            </button>
            <mat-menu #menu="matMenu">
                <ng-container *ngIf="(benefit && !benefit.selected && benefit.points !== 'Guaranteed') && benefit.maxMul">
                <ng-container *ngFor="let val of [].constructor(benefit.maxMul - ((benefit.selectedCount || 0 ) + (benefit.confirmedCount || 0))); index as i" >
                <button mat-menu-item fxLayoutAlign="space-between center" (click)="updateMultiplesOfBenefit(benefit,i+1)" >
                    <span role="none">Add x {{i+1}}</span>
                    <span class="point-value" role="none">{{(i+1) * benefit.points}} Points</span>
                </button>
            </ng-container>
            </ng-container>
            </mat-menu>


            <button mat-button [matMenuTriggerFor]="incrementalMenu" class="add-benefits-button" *ngIf="(benefit && !benefit.selected && benefit.points !== 'Guaranteed' && !benefit.maxMul) && !benefit.tierConfigs && (benefit.pointsPerIncrement || benefit.perPointCurrency) && !(benefit.consultantOnlyCard && !benefit.activated && isConsultant)"
            fxLayoutAlign="space-around center" fxLayout="row" aria-label="Add Benefit">
            <mat-icon class="add-icon" aria-label="Add Benefit">add</mat-icon>
            Add Benefit
            <mat-icon class="add-arrow-icon" aria-label="Add Benefit">arrow_drop_down</mat-icon>
        </button>
        <mat-menu #incrementalMenu="matMenu" class="incrementMenu">
            <ng-container *ngIf="(benefit && !benefit.selected && benefit.points !== 'Guaranteed' && !benefit.maxMul) && (benefit.pointsPerIncrement || benefit.perPointCurrency)">
            <ng-container *ngFor="let val of [].constructor((benefit.rangeMax / benefit.rangeIncrementValue) - ((minIncrementCount)-1+((benefit.perPointCurrency ? benefit.selectedDollarIncrementCount : benefit.selectedIncrementCount) || 0 ) + ((benefit.perPointCurrency ? benefit.confirmedDollarIncrementCount : benefit.confirmedIncrementCount) || 0))); index as i" >
            <button mat-menu-item fxLayoutAlign="space-between center" (click)="updateSelectedIncrementalBenefit(benefit,i)" >
                <!-- <span role="none">Add {{(i+1) * benefit.rangeIncrementValue}} {{benefit.rangeIncrementUnit}}</span> -->
                <span role="none">Add {{(i+minIncrementCount) * (benefit.pointsPerIncrement ? benefit.rangeIncrementValue : benefit.perPointCurrency)}} {{benefit.rangeIncrementUnit}}</span>
                <span class="point-value" role="none">{{(i+minIncrementCount) * (benefit.pointsPerIncrement ? benefit.pointsPerIncrement : benefit.rangeIncrementValue)}} {{(i+minIncrementCount) * benefit.rangeIncrementValue > 1 ?'Points' : 'Point'}}</span>
            </button>
        </ng-container>
        </ng-container>
        </mat-menu>
        <div fxLayout="column">
            <button class="delete-benefits-button" 
            [ngClass]="benefit && benefit.consultantOnlyCard && benefit.activated && isConsultant?'delete-benefits-button hide-select':'delete-benefits-button'"
                fxLayoutAlign="space-around center" 
                fxLayout="row" [attr.aria-label]="'Remove Benefit' + benefit.displayName"
                *ngIf="benefit && benefit.selected && 
                ((benefit.points !== 'Guaranteed'))" (click)="deleteBenefit(benefit, benefit.hybridTempLiving? true : false)">
                    <mat-icon class="delete-icon" title="Remove Benefit" aria-label="Remove Benefit">delete</mat-icon> 
                    {{benefit.hybridTempLiving ? 'Remove All' : 'Remove'}}
            </button>

            <button class="delete-benefits-button hide-select" fxLayoutAlign="space-around center" 
                fxLayout="row" aria-label="Remove & Hide Benefit" 
                (click)="activatehideBenefit(benefit,'hideRemove')"
                *ngIf="benefit && benefit.selected && benefit.consultantOnlyCard && benefit.activated && isConsultant">                
               <mat-icon class="remove-icon" aria-label="Hide Benefit">visibility</mat-icon> 
               Remove & Hide
            </button>
        </div>
            <div fxLayout="row" *ngIf="benefit.points === 'Guaranteed'" role="none" class="core-benefit">
                <img src="../../../../../assets/images/core-benefits.svg" alt="core-benefits-icon" title="Core Benefit"/>
                <p class="selected-benefits-type">Core Benefits</p>
            </div>
            <!-- <button class="add-favourite-button" fxLayoutAlign="space-around center" fxLayout="row">
                <img src="../../../../../assets/images/like-icon.svg" alt="like-icon"/> 
                Add as Favourite
            </button> -->
        </div>
    </div>
    <div class="home-sale-info" fxLayout="column" fxHide fxShow.xs role="none" fxLayoutGap.xs="5px">        
        <div fxLayout="column" role="none">
            <p class="home-sale-text" role="heading">{{ benefit.displayName }}</p>
            <p class="home-sale-points" *ngIf="(benefit.points === 'Guaranteed')&&(!isCashoutDetail)">Guaranteed</p>
            <p class="home-sale-points" *ngIf="(benefit.points !== 'Guaranteed')&&(!isCashoutDetail) && (!benefit.pointsPerIncrement) && (!benefit.perPointCurrency)">{{ benefit.points }} Points</p>
            <!-- <p class="home-sale-points" *ngIf="isCashoutDetail">1 Point(s): {{cashoutDollarValue}} USD</p> -->
            <p class="home-sale-points" *ngIf="benefit.pointsPerIncrement">{{ benefit.pointsPerIncrement}} Point(s): {{benefit.rangeIncrementValue}} {{benefit.rangeIncrementUnit}} </p>                
            <p class="home-sale-points" *ngIf="benefit.perPointCurrency">{{ benefit.perPointCurrency}} Point(s): {{benefit.rangeIncrementValue}} {{benefit.rangeIncrementUnit}} </p>                
        </div>
    </div>
    <div class="home-sale-para" [attr.aria-label]="'Benefit description ' + benefit.description" role="none">
        <p [innerHTML]="benefit.description"></p>
    </div>
    <!-- No of available selection for multiplicity-->
    <div class="selections" *ngIf="(benefit && !benefit.selected && benefit.points !== 'Guaranteed') && benefit.maxMul">
        <span>#</span>
         of available selections:
        <span>{{benefit.maxMul - (benefit.selectedCount || 0  + benefit.confirmedCount || 0 )}}</span>
    </div>

    <!-- No of available selection for cashout-->
    <div class="selections" *ngIf="(benefit && !benefit.selected && benefit.points !== 'Guaranteed') && benefit.tierConfigs">
        <span>#</span>
         of available selections:
        <span>{{availableCashoutSelection}}</span>
    </div>

    <!-- No of available selection for incremental-->
    <div class="selections" *ngIf="(benefit && !benefit.selected && benefit.points !== 'Guaranteed' && !benefit.maxMul) && (benefit.pointsPerIncrement)">
        <span>#</span>
         of available {{benefit.rangeIncrementUnit}}:
        <span>{{((benefit.rangeMax / benefit.rangeIncrementValue) - ((minIncrementCount)-1+( benefit.selectedIncrementCount || 0 ) + (benefit.confirmedIncrementCount || 0))) * (benefit.rangeIncrementValue)}}</span>
    </div>

    <!-- No of available selection for Dollar per point incremental card-->
    <div class="selections" *ngIf="(benefit && !benefit.selected && benefit.points !== 'Guaranteed' && !benefit.maxMul) && ( benefit.perPointCurrency)">
        <span>#</span>
         of available {{benefit.rangeIncrementUnit}}:
        <span>{{(benefit.rangeMax * benefit.perPointCurrency) - ((minIncrementCount-1+(benefit.selectedDollarIncrementCount || 0 ) + (benefit.confirmedDollarIncrementCount || 0)) * benefit.perPointCurrency)}}</span>
    </div>
</div>