<div class="policy-modal">
  <button class="close-btn" (click)="closeDialog()"><img class="close" src="../../../../../assets/images/close-icon.svg" alt="close"/></button>
    <h1>Schedule Policy Call with Cartus Consultant</h1>
      <p class="policy-para">
        Please review your Consultant's calendar and select a mutually convenient time to discuss your company’s policy and benefits with your Cartus Consultant.  Please allow at least one hour for this call.
      </p>
      <ng-container *ngIf="data.timezone === localTimeZone">
        <p class="times">You and the Consultant are in the same TimeZone.</p>
      </ng-container>

      <ng-container *ngIf="data.timezone !== localTimeZone">
        <p class="times">Consultant time zone is {{ data.timezone }}</p>
        <p class="times">Your time zone is {{ localTimeZone }} ({{ hourOffset }} hrs)</p>
      </ng-container>
      

      <div fxLayout="row" fxLayout.sm="column" fxLayout.xs="column" fxLayoutAlign="space-around center" role="region">
        <div fxFlex="5" role="none"></div>
          
            <div class="time-slot"
              fxFlex="70" fxFlex.xs="100" fxLayout="column" 
              fxLayoutAlign="center center" role="group"
              *ngFor="let element of counter; let i = index">
             <!--policy modal -->
             <div class="time-slot-card" fxLayout="column" fxFlex.xs="100" aria-label="Time Slot 1" role="none">
                <div id="date-slots" *ngIf="dateshow">
                      <div style="text-align: center;">
                        <p class="day-header">Select a day</p>
                      </div>
                    <div class="btn-grp">
                      <div  style="text-align: center;" *ngFor="let item of dateArray">
                        <button class="select-date-btn" (click)="showtimeSlots($event.target.innerText)">{{item| date:'fullDate'}}</button>
                      </div>
                    </div>
                 </div>

                 <div id="times-slots" *ngIf="timeshow">
                    <div class="header-wrapper" style="display: flex;" >
                      <button class="arrow-up-btn" (click)="showdateSlots()">
                        <mat-icon aria-hidden="false" aria-label="arrow upward icon" class="arrow-up-icon">arrow_upward</mat-icon>
                      </button>
                        <p>{{selecteddate}}</p>
                    </div>                  
                   <div class="btn-grp">
                      <div  style="text-align: center;" *ngFor="let item of timeArray">
                        <button class="select-date-btn" (click)="showscheduleSlot($event.target.innerText)">{{item}}</button>
                      </div>
                   </div>
                 </div>

               <div id="schedule-slot" *ngIf="finalshow">
                  <div class="header-wrapper" style="display: flex;" >
                    <button class="arrow-up-btn" (click)="showtimeSlots1()">
                      <mat-icon aria-hidden="false" aria-label="arrow upward icon" class="arrow-up-icon">arrow_upward</mat-icon>
                    </button>
                    <p>{{selecteddate}}</p>
                  </div>
                  <div class="btn-grp" style="text-align: center;">
                    <div class="time-div" style="text-align: center;">
                      <p>{{selectedtime}}</p>
                    </div>
                    <div>
                      <button class="schedule-btn" (click)="schedulePolicyCall()">Schedule</button>
                    </div>
                    <div>
                       <button class="cancel-btn" (click)="closeDialog()">Cancel</button>
                    </div>
                  </div>
                </div>
             </div>
              
          </div>

        <div fxFlex="5" role="none"></div>
          
      </div>
</div>