import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatMenuTrigger, MatTableDataSource, MatSort } from '@angular/material';
import { MatDialog } from '@angular/material';

import { loggedInUserData } from '../../login/login.service' 
import { ManageUsersService } from '../../login/manage-users.service'
import { ToastrService } from 'ngx-toastr';
import { UserComponent } from 'src/app/user/user.component';


export interface userList {
  userName: string;
  email: string;
  userType: string;
  firstName: string;
  lastName: string;
  clientName: number;
  subscriptionEndDate: any; 
}

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss']
})
export class ManageUsersComponent implements OnInit {
  displayedColumns: string[] = ['userName', 'email', 'userType', 'firstName', 'lastName', 'clientName', 'subscriptionEndDate', 'delete'];
  dataSource: any = new MatTableDataSource();
  
  @ViewChild(MatMenuTrigger, {
    static: false
  }) trigger: MatMenuTrigger;

  @ViewChild(MatSort, {
    static: true
  }) sort: MatSort;

  
  constructor(private router:Router,public dialog: MatDialog, private manageUsersService: ManageUsersService,private readonly toastrService: ToastrService) { }

  ngOnInit() { 
    this.subscribeManageUser();
    this.fetchAllUsersData();
  }

  fetchAllUsersData()  {

    this.manageUsersService.getAllAdminUsers().subscribe( data =>{
      if(data) {
        this.manageUsersService.manageUserList.next(data);
       }
    })
  }

  subscribeManageUser() {
    this.manageUsersService.manageUserList.subscribe(
      data => {
        if(data ){
          this.mapAllUsersData(data);
        }
      }
    )
  }


  isDeleteEnabled(user) {
     let isDeleteEnabled = true;
     let currentUser = JSON.parse(localStorage.getItem(loggedInUserData));
     if(currentUser.userName == user.userName) {
       isDeleteEnabled = false;
     }
     /* else if(user.userType.toLowerCase() == 'superadmin') {
       isDeleteEnabled = false;
     } */
     return isDeleteEnabled;
  }

  mapAllUsersData(userDatafromApi) {
    let data : userList[] = [];
    for (let i = 0; i < userDatafromApi.adminUsers.length; i++) {
      data.push(this.prepareAllUserData(userDatafromApi.adminUsers[i]));
    }
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
  }

  logout() {
    this.router.navigate(['/home']);
  }

  onDelete(users){
      this.manageUsersService.deleteUsers(users.userName).subscribe(res =>{ 
         this.showSuccessToast(res.message);
         this.fetchAllUsersData();
      })
  }

  prepareAllUserData(data) {
    let tempData = {
      "userName" : data.userName,
      "email" : data.email.toLowerCase(),
      "userType" : data.userType,
      "firstName" : data.firstName,
      "lastName" : data.lastName,
      "clientName": data.clientName,
      "subscriptionEndDate" : data.subscriptionEndDate
  }
  return tempData
}

addUser(d?: any){
  let dialogRef = this.dialog.open(UserComponent, {
    width: '750px',
    disableClose: true,
  data:d
  });
}

onEditUser(element) {
  let data = element;
  let userType = (data.userType).toLowerCase();
  let isEditEnable = {"isEditEnable": true};
  data = {...element,...isEditEnable};
  this.addUser(data);
}

onBackToHome() {
  this.router.navigate(['/home']);
}
showSuccessToast(message: string) {
  this.toastrService.success(message, 'success', {
    timeOut: 3000,
    closeButton: true
  })
}

isUserEditable(user) {
  let isUserEditable = true;
  let currentloggedInUser = JSON.parse(localStorage.getItem(loggedInUserData));
  let incomingUserType = user.userType.toLowerCase();
   if(currentloggedInUser.userType.toLowerCase() == 'admin' && incomingUserType == 'superadmin') {
    isUserEditable = false;
  }
  return isUserEditable;
}
navigateClient() {
  this.router.navigate(['/clients']);
}
}
