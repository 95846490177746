import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of } from 'rxjs';
import { KeyValuePair } from '../models/key-value-pair.model';
import { map, catchError } from 'rxjs/operators';
import {movePhase} from '../data/employeeInfo';

/** MovePhaseService to fetch the list of move phases from API */
@Injectable({
  providedIn: 'root'
})
export class MovePhaseService {

  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(private readonly baseClient: BaseClientService, private readonly logSvc: RemoteLoggingService) { }

  /** getMovePhases() to fetch move phase details from Api */
  getMovePhases(): Observable<KeyValuePair[]> {
    // return this.baseClient
    //   .getArr<KeyValuePair>(`v1/movephase`, 'get the move phase details')
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const emptyArray: KeyValuePair[] = [];
    //       this.logSvc.logError(err);
    //       return of(emptyArray);
    //     })
    //   );
    return of (movePhase);
  }
  getSelectedMovePhaseDetails(filenumber, orderRequestId: string): Observable<any> {
    /* return this.baseClient
       .getArr<KeyValuePair>(`v1/moveRisk/${filenumber}?context=benefits-builder&orderRequestId=${orderRequestId}`,'get move risk details of a transferee',)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const emptyArray: KeyValuePair[] = [];
          this.logSvc.logError(err);
          return of(emptyArray);
        })
      ); */
      return of(movePhaseAndRiskData);
  }
}

export const movePhaseAndRiskData =
{
    "fileNumber": 9999999,
    "effectiveTransferDate": "2019-07-01",
    "estimatedMoveDate": "2021-07-17",
    "estimatedMoveDateEarly": "2020-11-25",
    "estimatedMoveDateLate": "2021-09-11",
    "moveRiskVal": 3,
    "movePhase": {
        "id": 3,
        "altText": "Arriving in New Location"
    },
    "moveRiskCompletionDt": "2020-07-15"
}
