import { Component, OnInit, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { HeaderComponent } from './core/components/header/header.component';
import { NeedsAssessmentSharedService } from './public/needs-assessment/services/needs-assessment-shared.service';
import { TransfereeNeedsAssessment } from './core/models/candidateneeds-assessment.model';
import { IdleTimeoutService } from './core/services/idle-timeout.service';
import { CollaborationService } from './core/services/collaboration.service';
import { CollabMessage, CollabEvents } from './core/services/collaboration-events.service';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { transfereeNeedsAssessmentData } from './core/data/app-data';
import {AppDataService} from './core/services/app-data.service'
import { AuthenticationService } from './core/services/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

	@ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
	@ViewChild(HeaderComponent, { static: false }) header: HeaderComponent;

	/** To enable background image */
	showNeedsAssessmentBG: boolean;
	leftNavigation: boolean;
	initials = '';
	username = '';

	/** For footer to hide and show */
	isBenefitsActive: boolean;

	/** Flag to display notification bar for pending tasks */
	displayTaskNotification = false;
	/** Flag to indicate if needs assessment is completed */
	needsAssessmentCompleted: boolean;

	orderRequestId: string = null;
	/** Flag to notify header component to display notification section */
	openNotificationMenu : boolean;
	/** To store the list of notifications */
	notificationList : Array<{header,reference, message}>;

	/** Collaboration Subscription */
	collabSubscription: Subscription;

	constructor(
		private readonly router: Router,
		private readonly needsAssessmentShared: NeedsAssessmentSharedService,
		private readonly idleTimeoutService: IdleTimeoutService,
		private readonly collaborationService: CollaborationService,
		private readonly cookieService: CookieService,
		private appDataService: AppDataService,
		private authService:AuthenticationService) {
			router.events.subscribe(event => {
				if (event instanceof NavigationEnd) {
					if ((event.url).includes('needs-assessment')) {
						this.showNeedsAssessmentBG = true;
						this.needsAssessmentCompleted = false;
					} else {
						this.showNeedsAssessmentBG = false;
						this.needsAssessmentCompleted = true;
					}
					if ((event.url).includes('benefits')) {
						this.isBenefitsActive = true;
					} else {
						this.isBenefitsActive = false;
					}
				}
			});
	}

	/** Component Angular initialization lifecycle hook */
	ngOnInit() {
		//this.deleteDocumentLoader();		
		if(!this.authService.isTokenValid()) {
			this.authService.clearLoginInfo();
			this.router.navigate(['/login']);
		} else {		
		this.needsAssessmentShared.transfereeNeedsAssessmentDetails.next(this.appDataService.transfereeNeedsAssementStubData.getValue());
		this.loadNeedsAssessment();
		this.collaborationService.connect();
		//this.observeCollab();
		}
	}

	@HostListener('window:beforeunload', ['$event'])
	beforeunloadHandler(event) {
		this.sendLogoutMessage();
	}

	observeCollab(event) {
		// this.collabSubscription = this.collaborationService.socketSubscriber$.subscribe((message: CollabMessage) => {
		// 	if (!message) { return; }

		// 	if (message.data && message.data.event &&
		// 		(message.data.event === CollabEvents.USER_JOINED || message.data.event === CollabEvents.USER_LOGGED_OUT)) {
		// 		setTimeout(() => {
		// 			const userType = this.cookieService.get('transferee-context') ? 'Consultant' : 'Transferee';
		// 			if (message.data.event === CollabEvents.USER_JOINED && userType.toLowerCase() !== JSON.parse(message.data.data)) {
		// 				console.log('User online', message.data.data);
		// 				document.getElementById('collab-session').style.display = 'block';
		// 				document.getElementById('collab-text').textContent = `${userType === 'Consultant' ? 'Transferee' : 'Consultant'} is online`;
		// 			}
		// 			if (message.data.event === CollabEvents.USER_LOGGED_OUT && userType.toLowerCase() !== JSON.parse(message.data.data)) {
		// 				console.log('User offline', message.data.data);
		// 				document.getElementById('collab-session').style.display = 'none';
		// 			}
		// 		}, 5000);
		// 	}

		// });
		let userType = event;
		if (userType === 'Offline'){
			document.getElementById('collab-session').style.display = 'none';
		}
		else {
			document.getElementById('collab-session').style.display = 'block';
			document.getElementById('collab-text').textContent = `${userType === 'Consultant' ? 'Transferee' : 'Consultant'} is online`;
		}
	}

	deleteDocumentLoader() {
		window.document.getElementById('app_loading').remove();
	}

	sideNavHandle() {
		this.initials = this.header.initials;
		this.username = this.header.username;
		this.sidenav.open();
	}

	logout() {
		this.header.logout();
	}

	isLinkActive(url): boolean {
		return this.router.url.includes(url);
	}

	navigateTo(url) {
		this.router.navigate([url]);
		this.sidenav.close();
	}


	/**
	 * To open notification section and display them
	 * @param notificationList List of notifications
	 */
	openNotification(notificationList){
		this.notificationList = notificationList;
		this.openNotificationMenu = true;
	}
	/**
	 * To close notification section
	 */
	notificationClosed(){
		this.openNotificationMenu = false;
	}

	/**
	 * To load needs assessment from sessionStorage and update to shared service
	 */
	loadNeedsAssessment() {
		const needsAssessmentData: TransfereeNeedsAssessment = JSON.parse(sessionStorage.getItem('needs-assessment'));
		sessionStorage.removeItem('needs-assessment');
		if (needsAssessmentData) {
			this.needsAssessmentShared.updateCandidateNeedsAssesment(needsAssessmentData);
			this.orderRequestId = needsAssessmentData.orderRequestId;
			sessionStorage.setItem('car-ses-oid', this.orderRequestId);
			if (!needsAssessmentData.confirmStatus) {
				this.router.navigate(['/needs-assessment']);
			}
		} else {
			this.router.navigate(['/logout']);
		}
	}

	updateNotification(value) {
		if(value && value.length > 0){
			this.displayTaskNotification = true;
			this.notificationList  = value;
		}else{
			this.displayTaskNotification = false;
			this.notificationList  = [];
		}
		this.notificationList = [].concat(this.notificationList);

	}

	/**
	 * Intentionally deleting everything.
	 * User will login each time
	 */
	sendLogoutMessage() {

		this.collaborationService.sendMessage({
			event: CollabEvents.USER_LOGGED_OUT,
			data: null
		});

		this.collaborationService.close();

		/* this.cookieService.delete('transferee-context', null);
		this.cookieService.delete('transferee-context', null, '.cartus.com'); */
	}

	ngOnDestroy(): void {
		if (this.collabSubscription) { this.collabSubscription.unsubscribe(); }
	}

    /**
     * To redirect user back to benefits screen
     */
    goToBenefits() {
		this.router.navigate(['/bb/benefits/list']);
		this.sidenav.close();
    }

    /**
     * To redirect user to confirmed benefits screen
     */
    goToConfirmedBenefits() {
		this.router.navigate(['/bb/benefits/confirmed']);
		this.sidenav.close();
	}

    /**
     * To redirect user to review selected benefits screen
     */
    goToReviewSelectedBenefits() {
		this.router.navigate(['/bb/benefits/review']);
		this.sidenav.close();
    }
}
