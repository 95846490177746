export const contexts = {
  "clientNo": "3966",
  "clientLegalName": "ABC Demo Company Ltd",
  "clientAddrLine1": "770 Cochituate Rd.",
  "clientAddrLine2": "Suite 500",
  "clientCityName": "Framingham",
  "clientStProvName": "Massachusetts",
  "clientCountryName": "United States Of America",
  "clientPostalCode": "01701",
  "firstName": "Marlon",
  "lastName": "Williams",
  "multiCurrencyFlag": false,
  "partyId": "5ef07c7cacc6800007f117d0"
}

export const userContext = {
  "nationalities": [

  ],
  "partyType": "person",
  "children": [

  ],
  "contactMechanisms": [
    "5ef07c798700cd0008b5be1b",
    "5ef07c798700cd0008b5be1c"
  ],
  "_id": "5ef07c7cacc6800007f117d0",
  "parentId": "5e1f3ffc4999eb6a2e93980a",
  "currentName": {
    "names": [
      "Initiator",
      "One"
    ]
  },
  "roles": [
    {
      "_id": "5efde1c9aa7d3893882c5baa",
      "roleId": "5efde0720e92c4f633544ca9"
    },
    {
      "_id": "5efde26caa7d3893882c5bab",
      "roleId": "5efde22d6cb2b800074ad726"
    },
    {
      "_id": "5f282763d6a78fb1786c36a9",
      "roleId": "5f282734c5014f512e6f3e95"
    },
    {
      "_id": "5fec6113e62be891c79a857c",
      "roleId": "5ef07c7f6de6fb000869ba14",
      "fromDate": "2021-01-12T09:28:56.117Z",
      "updatedAt": "2021-01-12T09:28:58.626Z"
    }
  ],
  "previousNames": [

  ],
  "passports": [

  ],
  "createdAt": "2020-06-22T09:40:12.421Z",
  "updatedAt": "2021-01-12T09:28:58.626Z",
  "__v": 6,
  "identityProviderId": "00us90xdosG612X7O0h7",
  "selfRegDate": "2020-06-22T09:41:46.188Z",
  "name": "Initiator One",
  "id": "5ef07c7cacc6800007f117d0"
}

export const userPreference = [
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        "accountCategory",
        "accountSubCategory",
        "invoiceNo",
        "transactionDesc"
      ],
      "_id": "5edfc6e986e1ba4a48931f6c",
      "name": "FinancialViewColumnDisplay",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "Checkbox",
      "default": [
        "accountCategory",
        "accountSubCategory",
        "billAmount",
        "invoiceDate",
        "invoiceNo",
        "transactionDesc"
      ],
      "description": "MovePro360 All Transactions page Default View"
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        "aggregationName",
        "activeEmployees",
        "currentYtdCosts",
        "currentYtdCurr",
        "pastYtdCosts",
        "pastYtdCurr",
        "changeOfTotalCost",
        "moveAtRiskCount",
        "moveOnTrackCount"
      ],
      "_id": "5edfc6e986e1ba4a48931f67",
      "name": "AggregateViewColumnSort",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "select",
      "default": [
        {
          "sortColumnName": "aggregationName",
          "sortOrder": "asc"
        }
      ],
      "description": "Default Sort order and direction for the Aggregated view"
    }
  },
  {
    "preferencesetting": {
      "_id": "5f4e5831b1775700082bd9cc",
      "appName": "MovePro360",
      "name": "EmployeeListViewColumnOrder",
      "value": [
        "starred",
        "fullName",
        "moveAtRisk",
        "division",
        "fileStatusDesc",
        "movePhase",
        "policy",
        "departure",
        "destination",
        "providers",
        "sentiment",
        "effectiveTransferDate",
        "milestone",
        "totalCost"
      ],
      "partyId": "5ef07c7cacc6800007f117d0",
      "createdBy": "5ef07c7cacc6800007f117d0",
      "updatedBy": "5ef07c7cacc6800007f117d0",
      "__v": 1
    },
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        "division",
        "fileStatusDesc",
        "movePhase",
        "policy",
        "departure",
        "destination",
        "providers",
        "sentiment",
        "effectiveTransferDate",
        "milestone",
        "totalCost",
        "moveAtRisk"
      ],
      "_id": "5edfc6e986e1ba4a48931f6e",
      "name": "EmployeeListViewColumnOrder",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "Dragdrop",
      "default": [
        "starred",
        "fullName",
        "division",
        "fileStatusDesc",
        "movePhase",
        "policy",
        "departure",
        "destination",
        "providers",
        "sentiment",
        "effectiveTransferDate",
        "milestone",
        "totalCost",
        "moveAtRisk"
      ],
      "description": "MovePro360 Dashboard Employee List Default Column Order"
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        25,
        50,
        75
      ],
      "_id": "5edfc6e986e1ba4a48931f74",
      "name": "EmployeeListViewPageSize",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "Dropdown",
      "default": [
        25
      ],
      "description": "Page size for the Employee List view"
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        "departure",
        "destination",
        "division",
        "effectiveTransferDate",
        "fileStatusDesc",
        "fullName",
        "milestone",
        "movePhase",
        "moveAtRisk",
        "policy",
        "providers",
        "sentiment",
        "starred",
        "totalCost"
      ],
      "_id": "5edfc6e986e1ba4a48931f68",
      "name": "EmployeeListViewColumnSort",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "select",
      "default": [
        {
          "sortColumnName": "lastName",
          "sortOrder": "asc"
        }
      ],
      "description": "Default Sort and direction or for the Employee List view"
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        25,
        50,
        75
      ],
      "_id": "5edfc6e986e1ba4a48931f73",
      "name": "AggregateViewPageSize",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "Dropdown",
      "default": [
        25
      ],
      "description": "Page size for the Aggregated view"
    }
  },
  {
    "preferencesetting": {
      "_id": "5f4e5e669591200007198473",
      "appName": "MovePro360",
      "name": "EmployeeListViewColumnDisplay",
      "value": [
        "departure",
        "destination",
        "division",
        "effectiveTransferDate",
        "fullName",
        "moveAtRisk",
        "movePhase",
        "policy",
        "providers",
        "milestone",
        "sentiment",
        "starred",
        "totalCost"
      ],
      "partyId": "5ef07c7cacc6800007f117d0",
      "createdBy": "5ef07c7cacc6800007f117d0",
      "updatedBy": "5ef07c7cacc6800007f117d0",
      "__v": 13
    },
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        "departure",
        "destination",
        "division",
        "effectiveTransferDate",
        "fileStatusDesc",
        "moveAtRisk",
        "movePhase",
        "policy",
        "providers",
        "milestone",
        "sentiment",
        "starred",
        "totalCost"
      ],
      "_id": "5edfc6e986e1ba4a48931f6b",
      "name": "EmployeeListViewColumnDisplay",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "Checkbox",
      "default": [
        "departure",
        "destination",
        "division",
        "effectiveTransferDate",
        "fileStatusDesc",
        "fullName",
        "moveAtRisk",
        "movePhase",
        "policy",
        "providers",
        "milestone",
        "sentiment",
        "starred",
        "totalCost"
      ],
      "description": "MovePro360 Dashboard Employee List Default View"
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        "departureCountry",
        "destinationCountry",
        "division",
        "employeeList",
        "policy"
      ],
      "_id": "5edfc6e986e1ba4a48931f6a",
      "name": "Dashboard",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "Dropdown",
      "default": "employeeList",
      "description": "MovePro360 Dashboard Default View"
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        "accountCategory",
        "accountSubCategory",
        "invoiceNo",
        "transactionDesc"
      ],
      "_id": "5edfc6e986e1ba4a48931f70",
      "name": "AggregatedViewColumnOrder",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "Dragdrop",
      "default": [
        "aggregationName",
        "activeEmployees",
        "currentYtdCosts",
        "currentYtdCurr",
        "pastYtdCosts",
        "pastYtdCurr",
        "changeOfTotalCost",
        "moveAtRiskCount",
        "moveOnTrackCount"
      ],
      "description": "MovePro360 Dashboard aggregated view Default  Column Order "
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        25,
        50,
        75
      ],
      "_id": "5edfc6e986e1ba4a48931f75",
      "name": "FinancialViewPageSize",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "Dropdown",
      "default": [
        25
      ],
      "description": "Page size for the All Transactions view"
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        "activeEmployees",
        "aggregationName",
        "changeOfTotalCost",
        "currentYtdCosts",
        "currentYtdCurr",
        "moveAtRiskCount",
        "moveOnTrackCount",
        "pastYtdCosts",
        "pastYtdCurr"
      ],
      "_id": "5edfc6e986e1ba4a48931f6d",
      "name": "AggregatedViewDisplay",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "Checkbox",
      "default": [
        "activeEmployees",
        "aggregationName",
        "changeOfTotalCost",
        "currentYtdCosts",
        "currentYtdCurr",
        "moveAtRiskCount",
        "moveOnTrackCount",
        "pastYtdCosts",
        "pastYtdCurr"
      ],
      "description": "MovePro360 Dashboard Aggregated View"
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        "invoiceDate",
        "accountCategory",
        "accountSubCategory",
        "transactionDesc",
        "invoiceNo",
        "billAmount"
      ],
      "_id": "5edfc6e986e1ba4a48931f69",
      "name": "FinancialViewColumnSort",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "select",
      "default": [
        {
          "sortColumnName": "invoiceDate",
          "sortOrder": "desc"
        }
      ],
      "description": "Default Sort and direction or for the Employee List view"
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        "accountCategory",
        "accountSubCategory",
        "invoiceNo",
        "transactionDesc"
      ],
      "_id": "5edfc6e986e1ba4a48931f6f",
      "name": "FinancialViewColumnOrder",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "Dragdrop",
      "default": [
        "invoiceDate",
        "accountCategory",
        "accountSubCategory",
        "transactionDesc",
        "invoiceNo",
        "billAmount"
      ],
      "description": "MovePro360 All Transactions Default Column Order"
    }
  },
  {
    "preferencesetting": {
      "_id": "60470bc027d78b000831d4ad",
      "appName": "MovePro360",
      "name": "preferredCurrency",
      "value": "USD",
      "partyId": "5ef07c7cacc6800007f117d0",
      "createdBy": "5ef07c7cacc6800007f117d0",
      "updatedBy": "5ef07c7cacc6800007f117d0",
      "__v": 1
    },
    "preferenceconfig": {
      "kind": "preference",
      "options": [
        {
          "currencyAbbr": "AUD",
          "currencyName": "Australian Dollar"
        },
        {
          "currencyAbbr": "BRL",
          "currencyName": "Brazilian Real"
        },
        {
          "currencyAbbr": "CAD",
          "currencyName": "Canadian Dollar"
        },
        {
          "currencyAbbr": "EUR",
          "currencyName": "Euro"
        },
        {
          "currencyAbbr": "GBP",
          "currencyName": "British Pound"
        },
        {
          "currencyAbbr": "USD",
          "currencyName": "US Dollar"
        }
      ],
      "_id": "5edfc6e986e1ba4a48931f71",
      "name": "preferredCurrency",
      "appName": "MovePro360",
      "isGlobal": true,
      "fieldType": "Dropdown",
      "default": [

      ],
      "description": "MovePro Dashboard Employee Record currency"
    }
  },
  {
    "preferenceconfig": {
      "kind": "preference",
      "options": [

      ],
      "_id": "5edfc6e986e1ba4a48931f72",
      "name": "employeeFavorites",
      "appName": "MovePro360",
      "isGlobal": false,
      "fieldType": "selectbox",
      "default": [

      ],
      "description": "MovePro Employee Favorites"
    }
  }
]