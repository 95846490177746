<div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="10px" fxLayoutGap.xs="0em">Booking Details <br />Work Order:{{data.supID}}
    <span class="close-icon" (click)="onClose()" title="close">
        <mat-icon>close</mat-icon>
    </span>
</div>
<!-- <form fxLayout="column" class="middle-section" [formGroup]="addBookingForm"> -->
    <mat-dialog-content>
        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="none">
            <div>
                <div class="booking-agent">Booking Agent</div>
                <div class="agent">{{data.bookingDetail.bookingAgent}}</div>
                <div class="agent-bottom" *ngIf = "data.bookingDetail.supplierID">ID: {{data.bookingDetail.supplierID}}</div>
            </div>
            <div>
                <div class="booking-agent">Status</div>
                <div class="agent">{{data.bookingDetail.status | titlecase}}</div>
                <div class="agent-bottom" *ngIf = "data.bookingDetail?.statusDate">on {{ data.bookingDetail.statusDate }}</div>
                <!-- <p>on : </p> -->
            </div>
        </div>
        <div tabindex="0" class="main-panel">
            <mat-accordion class=" candidate-review-panel move-departure-panel" multi="true">
                <mat-expansion-panel class="move-detail-panel" [expanded]="true">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'">
                        <div fxflex="34%" style="color:#4b85ff ;" class="panel-heading">Property Information</div>
                    </mat-expansion-panel-header>
                    <form fxLayout="column" class="middle-section" [formGroup]="propertyInfoForm">
                        <div fxLayout="row" fxflex="100%" fxLayoutGap="20px" fxLayoutAlign="none">
                            <!--Provider Name column-->
                                <mat-form-field fxFlex="40%" class="client-budget">
                                    <mat-label>Provider Name</mat-label>
                                    <input matInput formControlName="providerName">
                                </mat-form-field>
                            <!--Property Name Column-->
                                <mat-form-field fxFlex="40%" class="client-budget">
                                    <mat-label>Property Name</mat-label>
                                    <input matInput formControlName="propertyName">
                                </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="none">
                            <!--Apartment type Column-->
                                <mat-form-field fxFlex="40%" class="client-budget">
                                    <mat-label>Apartment Type</mat-label>
                                    <input matInput formControlName="apartmentType">
                                </mat-form-field>
                            <!--Rent Paid By Column-->
                                <mat-form-field fxFlex="40%" class="client-budget">
                                    <mat-label>Rent Paid By</mat-label>
                                    <input matInput formControlName="rentPaidBy">
                                </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="40px" fxLayoutAlign="none">
                            <!--Country Column Name-->
                                <mat-form-field  fxFlex="40%">
                                    <mat-label>Country</mat-label>
                                    <input type="text" aria-label="Country" id="deptCountry" matInput
                                        #autoCompleteCountryInput formControlName="country"
                                        [matAutocomplete]="autoCountry" (blur)="onFocusOutDepartureCountryCode()" (change)="onCountryChange()">
                                    <mat-autocomplete #autoCountry="matAutocomplete" [panelWidth]="293">
                                        <mat-option [value]="option.countryName"
                                            *ngFor="let option of filteredCountryOptions | async">
                                            {{ option.countryName }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="40px"  fxLayoutAlign="none" >
                                <mat-form-field fxFlex="80%">
                                    <mat-label>Address</mat-label>
                                    <input matInput formControlName="streets" *ngIf="!departCountryCode"
                                    placeholder="Address" />
                                    <input matInput placeholder="Address" *ngIf="departCountryCode"
                                        matGoogleMapsAutocomplete [country]="departCountryCode" type="address"
                                        (onAutocompleteSelected)="onAutocompleteSelected($event)" (change)="onAddressChange()"
                                        formControlName="streets">
                                </mat-form-field>
                        </div>
                        <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="none" >
                            <!--City Column -->
                                <mat-form-field *ngIf="hasDepartureStreet" fxFlex="25%">
                                    <mat-label>City</mat-label>
                                    <input matInput formControlName="cities" placeholder="City" /> 
                                </mat-form-field>
                                <mat-form-field *ngIf="!hasDepartureStreet" fxFlex="25%">
                                    <mat-label>City</mat-label>
                                    <input matInput matGoogleMapsAutocomplete [country]="departCountryCode"
                                        type="City"
                                        (onAutocompleteSelected)="onDepartureCityAutocompleteSelected($event)"
                                        formControlName="cities" placeholder="City">
        
                                </mat-form-field>
                            <!--State Column-->
                                <mat-form-field class="state-fields" fxFlex="20%">
                                            <mat-label>State/Province</mat-label>
                                            <input matInput formControlName="states"
                                                *ngIf="hasDepartureStreet || hasDepartureCity" placeholder="State" />
                                            <input matInput formControlName="states"
                                                *ngIf="!hasDepartureStreet && !hasDepartureCity"
                                                matGoogleMapsAutocomplete [country]="departCountryCode" type="region"
                                                (onAutocompleteSelected)="onDepartureStateAutocompleteSelected($event)"
                                                (onLocationSelected)="onLocationSelected($event)" placeholder="State" />
                                            
                                </mat-form-field>
                            <!--Postal Column-->
                                <mat-form-field class="postal-code-fields" fxFlex="20%">
                                        <mat-label>Postal Code</mat-label>
                                        <input matInput placeholder="Postal Code"
                                            *ngIf="hasDepartureStreet ||hasDepartureCity || hasDepartureState"
                                            formControlName="postalCodes" minLength="1" maxlength="15" />

                                        <input matInput placeholder="Postal Code"
                                            *ngIf="!hasDepartureStreet && !hasDepartureCity && !hasDepartureState"
                                            matGoogleMapsAutocomplete [country]="departCountryCode" type="region"
                                            (onAutocompleteSelected)="onDeparturePostalCodeAutocompleteSelected($event)"
                                            (onLocationSelected)="onLocationSelected($event)"
                                            formControlName="postalCodes" minLength="1" maxlength="15">
                                </mat-form-field>
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion class=" candidate-review-panel move-departure-panel" multi="true">
                <mat-expansion-panel class="move-detail-panel" [expanded]="true">
                    <mat-expansion-panel-header [collapsedHeight]="'auto'">
                        <div fxflex="34%" style="color:#4b85ff ;" class="panel-heading">Move Dates</div>
                    </mat-expansion-panel-header>
                    <form fxLayout="column" class="middle-section" [formGroup]="moveDateForm">
                        <div fxLayout="row" fxLayoutGap="10px">
                            <!--Move In Estimate column-->
                                <mat-form-field fxFlex="20%">
                                    <mat-label>Move In Estimate</mat-label>
                                    <input matInput [matDatepicker]="moveInDate"  [min]="minDate" datePickerFormat="YYYY-MM-DD" (dateChange)="moveInDateChange()" formControlName="moveInDate">
                                    <mat-datepicker-toggle matSuffix [for]="moveInDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #moveInDate></mat-datepicker>
                                </mat-form-field>
                                <span class="margin-dash">-</span>
                            <!--Move Out Estimate Column-->
                                <mat-form-field fxFlex="20%">
                                    <mat-label>Move Out Estimate</mat-label>
                                    <input matInput [matDatepicker]="moveOutDate" [min]="minMoveDate" datePickerFormat="YYYY-MM-DD" formControlName="moveOutDate">
                                    <mat-datepicker-toggle matSuffix [for]="moveOutDate">
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #moveOutDate></mat-datepicker>
                                </mat-form-field>
                                
                        </div>
                    </form>
                </mat-expansion-panel>
            </mat-accordion>
        </div>
    </mat-dialog-content>
<!-- </form> -->
<div fxLayout="row" fxLayoutAlign="space-between end">
    <div fxFlex="30%">
        <button class="download-btn" mat-button (click)="openDownloadToastr()">
            <mat-icon class="download-icon close-icon" >save_alt</mat-icon><span class="btn-txt">Download</span>
        </button>
    </div>
    <div>
        <button mat-button class="cancel-btn" (click)="onCancel()">Cancel</button>
        <button mat-button class="submit-btn"  (click)="onSumit('submit', data.id)">Submit</button>
    </div>
</div>
