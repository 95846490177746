import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { Router } from '@angular/router';
import { ApplicationdataService, bbUrl } from 'src/app/core/applicationdata.service';
import { MoveProDashboadDataService } from 'projects/BBAdmin/src/app/core/data/move-pro-dashboad-data.service';
import { LoginService, loggedInUserData } from '../../login/login.service';
import { BbAdminDataService } from '../../../../projects/BBAdmin/src/app/core/data/bb-admin-data.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent implements OnInit {
  
  @ViewChild(MatMenuTrigger, {
    static: false
  }) trigger: MatMenuTrigger;

  detailCard_bb = false;
  detailCard_mobilify = false;
  detailCard_move = true;
  detailCard_admin = false;
  detailCard_landing = true;
  detailDashBoard : DetailDashBoardContent;
  navigatePath;
  loggedInUser = "";
  loggedInUserType = "user";
  dashBoardContents: DetailDashBoardContent[] = [
    {
      appId: 0,
      FullName: "Benefits Builder",
      ShortName: "BenefitB",
      description: "Powered by MovePro360®, Benefits Builder offers relocating employees choice and control during their move while enabling clients to manage relocation budgets more precisely.",
      iconUrl: "../../../assets/landingpage_assets/BBIcon_small.JPG",
      routePath: ""
    },
    {
      appId: 1,
      FullName: "CartusOnline",
      ShortName: "COL",
      description: "Open CartusOnline to review more information and complete expense related activities.",
      iconUrl: "../../../assets/images/cartusOnline-color.svg",
      routePath: ""
    },
    {
      appId: 2,
      FullName: "MovePro360",
      ShortName: "MovePro",
      description: "MovePro360 offers you a comprehensive view of your entire mobility program while integrating data from key suppliers—a true single source of truth.",
      iconUrl: "../../../assets/imgs/MovePro360-Color.svg",
      routePath: ""
    },
    {
      appId: 3,
      FullName: "Admin Page",
      ShortName: "Admin",
      description: "The Admin Page offers numerous unique features and views, including a robust collection of key performance indicators (KPIs).",
      iconUrl: "../../../assets/imgs/MovePro360-Color.svg",
      routePath: ""
    }

  ];
  constructor(private router: Router,
    private loginService: LoginService,
    private appService: ApplicationdataService,
    private bbadmindataService: BbAdminDataService,
    private moveProBackendDataService: MoveProDashboadDataService,
    private translate: TranslateService,
    public dialog: MatDialog) {
    translate.setDefaultLang('en');
  }
  ngOnInit(): void {
    if (!this.loginService.isTokenValid()) {
      this.logout();
    } else {
      this.getBBDataFromBackend();
      this.initializeBbAdmin();
      this.initializeDashBoadEmployee();
    }
    this.fetchLoggedInUser(JSON.parse(localStorage.getItem(loggedInUserData)));
    this.loadDetailCard(2);
    this.onClick(2,true);
  }

  getBBDataFromBackend() {
    this.appService.makeRequest(bbUrl).subscribe((data) => {
      if (data && data.BBData) {
        let bbData = this.appService.getBBDataFirst((data.BBData as Array<any>));
        this.appService.assignBBdata(bbData);
      }

    })
  }
 
  initializeBbAdmin() {
    this.bbadmindataService.gettransfereeListDatafromApi();
  }

  initializeDashBoadEmployee() {
    this.moveProBackendDataService.getEmployeeDatafromApi();
  }
  onClick(appId,isLandingPage = false) {
    isLandingPage ? this.detailCard_landing = true : this.detailCard_landing = false;
    this.resetOtherTile(appId);
    switch (appId) {
      case 0: {
        this.loadDetailCard(0);
        this.navigatePath = "/login/1";
        break;
      }
      case 1: {
        this.loadDetailCard(1);
        this.navigatePath = "/login/3";
        break
      }
      case 2: {
        this.loadDetailCard(2);
        this.navigatePath = "/login/2";
        break
      }
      case 3: {
        this.loadDetailCard(3);
        this.navigatePath = "/login/4";
        break
      }
    }
  }

  resetOtherTile(appId: number) {
    if (appId == 0) {
      this.detailCard_mobilify = false;
      this.detailCard_move = false;
      this.detailCard_admin = false;
    }
    else if (appId == 1) {
      this.detailCard_bb = false;
      this.detailCard_move = false;
      this.detailCard_admin = false;
    }
    else if (appId == 2) {
      this.detailCard_bb = false;
      this.detailCard_mobilify = false;
      this.detailCard_admin = false;
    }
    else {
      this.detailCard_bb = false;
      this.detailCard_mobilify = false;
      this.detailCard_move = false;
    }
  }

  loadDetailCard(appId: number) {
    this.detailDashBoard = this.dashBoardContents[appId];
  }
  onClickDetail() {
    this.router.navigate([this.navigatePath]);
  }
  logout() {
    this.loginService.clearLoginInfo();
    this.router.navigate(['/login']);
  }
  onClickUsers() {
    this.router.navigate(['/users']);
  }
  fetchLoggedInUser(data: any){
    this.loggedInUser = data.firstName + " " + data.lastName;
    this.loggedInUserType = data.userType;  
  }
  onClickClients() {
    this.router.navigate(['/clients']);
  }
}

class DetailDashBoardContent {
  appId: number;
  FullName: string;
  ShortName: string;
  description: string;
  iconUrl: string;
  routePath: string;
}
