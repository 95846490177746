import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModuleWithProviders, NgModule, NO_ERRORS_SCHEMA,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HashLocationStrategy, LocationStrategy, CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatCardModule } from '@angular/material/card';
import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatFormFieldModule, MatFormFieldControl  } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserContextComponent } from './public/components/user-context/user-context.component';
import { NavListComponent } from './public/components/nav-list/nav-list.component';
import { DashboardModule } from './public/dashboard/dashboard.module';
import { ProviderModule } from './public/provider/provider.module';
import { ExternRouteComponent } from './public/components/extern-route/extern-route.component';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AvatarModule } from './core/components/avatar/avatar.module';
import { CookieService } from 'ngx-cookie-service';
import { BreadCrumbService } from './core/services/breadcrumb.service';
import { HighlightSearchPipe } from '../app/public/dashboard/pipes/highlight-search/highlight-search.pipe';
import { TruncatePipe } from '../app/public/dashboard/pipes/truncate/truncate.pipe';
import { ToastrModule } from 'ngx-toastr';
import { TermsPrivacyModule } from './public/terms-privacy/terms-privacy.module';
import { GlobalSearchComponent } from './public/components/global-search/global-search.component';
import { PageNotFoundComponent } from './public/components/page-not-found/page-not-found.component';
import { LoaderHttpInterceptor } from './core/services/http-interceptor.service';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ClientsComponent } from './public/components/clients/clients.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import { ClientContactsComponent } from './public/components/client-contacts/client-contacts.component';
import { AdministrationModule } from './public/administration/administration.module';
import { CdkColumnDef } from '@angular/cdk/table';
import { LogoutModalComponent } from './public/components/logout-modal/logout-modal.component';
import {MatListModule, MatSnackBarModule } from '@angular/material';
import { ProgramModule } from './public/administration/modules/program/program.module';
import { TransfereeModule } from './public/administration/modules/transferee/transferee.module';
import { ClientContactDetailsComponent } from './public/components/client-contact-details/client-contact-details.component';
import { CostModelComponent } from './public/cost-model/cost-model.component';
import { AddCostModelComponent } from './public/cost-model/add-cost-model/add-cost-model.component';
import { DeleteCostModelComponent } from './public/cost-model/delete-cost-model/delete-cost-model.component';
import { InvitationSentComponent } from './public/components/invitation-sent/invitation-sent.component';
import { HeaderStatusComponent } from './public/components/header-status/header-status.component';
import { SharedTradionalOrderModule } from './shared/shared-tradional-order.module';
import { FormatTimePipe, ToastIdleTimeoutComponent } from './public/toast-idle-timeout/toast-idle-timeout.component';
@NgModule({
  declarations: [
    AppComponent,
    UserContextComponent,
    NavListComponent,
    ExternRouteComponent,
    GlobalSearchComponent,
    PageNotFoundComponent,
    ClientsComponent,
    ClientContactsComponent,
    LogoutModalComponent,
    ClientContactDetailsComponent,
    CostModelComponent,
    AddCostModelComponent,
    DeleteCostModelComponent,
    InvitationSentComponent,
    HeaderStatusComponent,
    ToastIdleTimeoutComponent,
    FormatTimePipe
  ],
  imports: [
    BrowserModule,
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    environment.animation ? BrowserAnimationsModule : NoopAnimationsModule,
    HttpClientModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCardModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatTableModule,
    MatButtonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatIconModule,
    DashboardModule,
    ProviderModule,
    TermsPrivacyModule,
    AdministrationModule,
    ProgramModule,
    MatSnackBarModule,
    TransfereeModule,
    NgxSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    AvatarModule,
    MatTooltipModule,
    MatListModule,
    MatDialogModule,
    ToastrModule.forRoot({
      timeOut: 10000,
      positionClass: 'toast-bottom-center',
      maxOpened: 3,
      preventDuplicates: true,
      // autoDismiss: true,
      // newestOnTop: true
    }),
    SharedTradionalOrderModule,
    AppRoutingModule // I must be last!! https://angular.io/guide/router#module-import-order-matters
  ],
  providers: [
    CdkColumnDef,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderHttpInterceptor,
      multi: true,
    },
    CookieService,
    BreadCrumbService,
    HighlightSearchPipe,
    TruncatePipe
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  bootstrap: [
    AppComponent
  ],
  entryComponents: [
    LogoutModalComponent,
    ClientContactDetailsComponent,
    AddCostModelComponent,
    ToastIdleTimeoutComponent
  ]
})
export class AppModule { }

@NgModule({})
export class BBAdminSharedModule{
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers: [
        CdkColumnDef,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderHttpInterceptor,
          multi: true,
        },
        CookieService,
        BreadCrumbService,
        HighlightSearchPipe,
        TruncatePipe
      ]
    }
  }
}
