import { Component, OnInit, HostBinding, ViewEncapsulation,Input} from '@angular/core';
import * as constants from '../../../core/models/constants';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  /**property to print copyright string */
  readonly copyright = constants.copyright;
  showNeedsAssessmentBG:boolean;


  constructor(private readonly router: Router, ) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if ((event.url).includes('needs-assessment')) {
          this.showNeedsAssessmentBG = true;
        }
      }
    });
  }

  ngOnInit() {
    this.showNeedsAssessmentBG = this.router.url.includes('needs-assessment') ? true: false
  }

}
