import { Injectable } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, catchError, filter } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { ICONS_MAPPING } from '../../../core/models/icons.model';
import { clientpoliciesdata, programBenefitData, standardprogramdata, contractSeriesdata } from '../../../core/data/data';
import moment from 'moment';
import { BbAdminDataService } from '../../../core/data/bb-admin-data.service';
export interface GetClientOptions {
  sortBy?: string;
  sortDirection?: string;
  searchText?: string;
  searchProperties?: any[];
  filterObj?: any;
  itemsPerPage?: number,
  pageNumber?: number
}

export interface Benefit {
  reference: string;
  productSpecificationReference: string;
  category: string;
  consultantBenefitInfo?: { consultant: boolean, qualifyingInput?: number };
  displayName: string;
  description: string;
  points: number;
  suppress?: boolean;
  isNewlyAdded?: boolean,
  isNewlyAddedRef? :boolean;
  multiplicity?: boolean;
  maxSelection: number;
  prerequisites?: Array<any>;
  exclusions?: Array<any>;
  prerequisiteRule?: Array<any>;
  excludeRule?: Array<any>;
  scopes?: any;
  rangeMax?: number;
  rangeMin?: number;
  incremental?: boolean;
  rangeIncrementValue?: number;
  rangeIncrementUnit?: string;
  incrementalTimespan?: boolean;
  tierConfigs?: Array<any>;
  cashOut?: boolean;
  advCashOut?: boolean;
  icon?: string;
  advCashOutV2?: boolean;
  pointsPerSelection?: number;
  perPointCurrency?: number;
  incrementalCurrency?: boolean;
  isCloned?: boolean;
  clonedCopy?: boolean;
  clonedFrom?: string;
  parentClonedCopy?: boolean
  editTitle?: boolean; //flag for ui only not for api
  editDescription?: boolean; //flag for ui only not for api
  hybridTempLiving?:boolean;
  preMaxSelection?: any; // temp variable
  preRangeIncrementValue?: any; //temp variable
  preRangeIncrementUnit?: any; //temp variable
  preCoreUnitsPerSelection?: any; //temp variable
  preRangeMax?: any; //temp
  preRangeMin?: any; //temp
  coreUnitsPerSelection?:number;
  productSubDetails :{
    internationalProduct?: {
      productName?: string;
      subProductName?: string;
      requiresRevision?:boolean;
    },
    usDomesticProduct?: {
      productName?:string;
      subProductName?: string;
      requiresRevision?:boolean;

    }
  }
}

export interface ProgramRequest {
  cartusClientId: string;
  standardProgramName: string;
  clientId: string;
  programName: string;
  goal?: string;
  points: string | number;
  policyName: any;
  contractId: any;
  benefits: Array<Benefit>;
  divisions: Array<any>;
  programExpirationDate?: string;
  policyCallRequired: boolean;
  draft?: boolean;
  isCartusInsured?: boolean;
  copyProgramDetails?: {
    programName: string;
    clientId: string;
  };
}
export interface ProgramBenefitUpdateRequest {
  benefits: Array<Benefit>;
  draft?: boolean;
  copyProgramDetails?: {
    programName: string;
    clientId: string;
  };
}
export interface ProgramList {
  clientId: string;
  divisionList: Array<{
    division_id: string;
    division_name: string;
  }>;
  programs: Array<{
    name: string;
    clientPolicy: string;
    totalPoints: any;
    divisions: Array<{
      division_id: string,
      entityName?: string
    }>
  }>;
  count: number;
}

@Injectable({
  providedIn: 'root'
})
export class ProgramManagementService {
  private programListSub = new BehaviorSubject<any[]>([]);
  programList: Observable<any[]> = this.programListSub.asObservable();
  
  /** benefitList Observable */
  public benefitListSub = new BehaviorSubject<{ benefits: Array<Benefit> }>({benefits:[]});
  benefitList: Observable<{ benefits: Array<Benefit> }> = this.benefitListSub.asObservable();
  programListData;

  constructor(private baseClientService: BaseClientService,
    private logSvc: RemoteLoggingService,
    private bbadmindataService: BbAdminDataService
  ) {
    this.programListData = this.bbadmindataService.programListDataSubject.getValue();
  }

  getProgramsList(clientId: string, options: GetClientOptions = null): Observable<ProgramList> {
    let httpParams: HttpParams = new HttpParams();
    if (options) {
      if (options.sortBy) { httpParams = httpParams.append('sortBy', options.sortBy); }
      if (options.sortDirection) { httpParams = httpParams.append('sortDirection', options.sortDirection); }
      if (options.searchText) { httpParams = httpParams.append('searchText', options.searchText); }
      if (options.searchProperties) { httpParams = httpParams.append('searchProperties', options.searchProperties.toString()); }
      if (options.hasOwnProperty('itemsPerPage')) { httpParams = httpParams.append('itemsPerPage', options.itemsPerPage.toString()); }
      if (options.hasOwnProperty('pageNumber')) { httpParams = httpParams.append('pageNumber', (options.pageNumber + 1).toString()); }
    }
    if (options.sortBy) {
      return this.filterProgram(clientId, { filterObj: {} }, options);
    }
    return of(this.programListData);
  }

  getStandardPrograms(): Observable<{ clientId: string, programs: Array<{ name: string }>, count: number }> {
    // return this.baseClientService
    //   .post<any>(`v1/admin/program?standardPrograms=true`, {})
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //       this.logSvc.logError(err);
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    return of(standardprogramdata);
  }

  getClientPolicies(clientId: string): Observable<{ count: number, policies: Array<string> }> {
    // return this.baseClientService
    //   .getOne<any>(`v1/admin/client/policy?clientId=${clientId}`)
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //       this.logSvc.logError(err);
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    return of(clientpoliciesdata);
  }

  getBaseContract(clientId: string): Observable<any> {
    // return this.baseClientService
    //   .getOne<any>(`v1/admin/contract-list?clientId=${clientId}`)
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //       this.logSvc.logError(err);
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
      return of(contractSeriesdata);
  }

  checkDuplicatePrograms(clientId: string, templateName: string): Observable<{ clientId: string, programs: Array<string>, count: number }> {
    // return this.baseClientService
    //   .post<any>(`v1/admin/program?clientId=${clientId}&programName=${templateName}`, {})
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //       this.logSvc.logError(err);
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    return of(
      {
        "clientId": "5e1f3ffc4999eb6a2e93980a",
        "programs": [],
        "count": 0
      })
  }

  getProgramBenefits(clientId: string, programName: string, clientProgram?: boolean): Observable<{ benefits: Array<Benefit> }> {
    const clientProgramParam = clientProgram ? '&clientProgram=true' : '';
    // return this.baseClientService
    //   .getOne<any>(`v1/admin/program/benefit?clientId=${clientId}&programName=${programName}${clientProgramParam}`)
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //       this.logSvc.logError(err);
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );

    return of(JSON.parse(JSON.stringify(programBenefitData)));
  }
  getTransfereeViewProgramBenefits(clientId: string, programName: string, orderRequestId: string, clientProgram?: boolean): Observable<{ benefits: Array<Benefit> }> {
    const clientProgramParam = clientProgram ? '&clientProgram=true&transfereeView=true&orderRequestId=' : '';
    // return this.baseClientService
    //   .getOne<any>(`v1/admin/program/benefit?clientId=${clientId}&programName=${programName}${clientProgramParam}${orderRequestId}`)
    //   .pipe(
    //     map(r => {
    //       this.benefitListSub.next(r.body);
    //       return r.body
    //     }),
    //     catchError(err => {
    //       this.logSvc.logError(err);
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    return of(JSON.parse(JSON.stringify(programBenefitData)));
  }
  getClientDivisions(clientId: string): Observable<{ count: number, "divisions": Array<string> }> {
    return this.baseClientService
      .getOne<any>(`v1/admin/client/division?clientId=${clientId}`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  publishProgram(data: ProgramRequest, copyProgram = false) {
    data = this.removeIconFromReq(data);
    const copyUrlParam = copyProgram ? `?copyProgram=true` : ``;
    const url = `v1/admin/program${copyUrlParam}`;
    return this.baseClientService
      .post<ProgramRequest>(url, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  updateProgram(data: any) {
    data = this.removeIconFromReq(data);
    // return this.baseClientService
    //   .put<ProgramRequest>(`v1/admin/program`, data)
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //       this.logSvc.logError(err);
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    return of(data);
  }

  updateBenefitForProgram(data: any, programId: string, copyProgram = false, onlyCopy = false) {
    data = this.removeIconFromReq(data);
    const copyUrlParam = copyProgram && onlyCopy ? `?onlyCopyProgram=true` :
      (copyProgram && !onlyCopy ? `?copyProgram=true` : ``);
    const url = `v1/admin/program/${programId}/benefits${copyUrlParam}`;
    return this.baseClientService
      .put<ProgramRequest>(url, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  associateDivisions(data: any) {
    data = this.removeIconFromReq(data);
    return this.baseClientService
      .put<any>(`v1/admin/program/division`, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  /**
  * used to get filtered programs
  * @param clientId
  * @param data object for with filtering criteria
  */
  filterProgram(clientId: string, data: any, options?: any): Observable<ProgramList> {
    let httpParams: HttpParams = new HttpParams();
    if (options) {
      if (options.sortBy) { httpParams = httpParams.append('sortBy', options.sortBy); }
      if (options.sortDirection) { httpParams = httpParams.append('sortDirection', options.sortDirection); }
      if (options.searchText) { httpParams = httpParams.append('searchText', options.searchText); }
      if (options.searchProperties) { httpParams = httpParams.append('searchProperties', options.searchProperties.toString()); }
      if (options.hasOwnProperty('itemsPerPage')) { httpParams = httpParams.append('itemsPerPage', options.itemsPerPage.toString()); }
      if (options.hasOwnProperty('pageNumber')) { httpParams = httpParams.append('pageNumber', (options.pageNumber + 1).toString()); }
    }
    // return this.baseClientService
    //   .post<any>(`v1/admin/program?clientId=${clientId}&${httpParams.toString()}`, data)
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //       this.logSvc.logError(err);
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    let parentList = this.programListData.programs.map(ele => {
      if (ele.hasOwnProperty('programExpirationDate')) {
        const trimmedDate = ele.programExpirationDate.split('T')[0];
        ele.programExpirationDate = trimmedDate;
        if (moment(moment().format('MM/DD/YYYY')).isBefore(moment(ele.programExpirationDate.split('T')[0]).format('MM/DD/YYYY'))) {
          if (ele.draft) {
            return ({ ...ele, status: 'Draft', expiryDate: trimmedDate });
          }
          return ({ ...ele, status: 'Active', expiryDate: trimmedDate });
        }
        if (moment(moment().format('MM/DD/YYYY')).isSameOrAfter(moment(ele.programExpirationDate.split('T')[0]).format('MM/DD/YYYY'))) {
          return ({ ...ele, status: 'Deactivated', expiryDate: trimmedDate });
        }
      }
      else {
        if (ele.draft) {
          return ({ ...ele, status: 'Draft', expiryDate: 'N/A' });
        }
        return ({ ...ele, status: 'Active', expiryDate: 'N/A' });
      }
    });
    let filterData = {
      programs: [],
      count: this.programListData.count,
      clientId: clientId,
      divisionList: this.programListData.divisionList
    }
    if (options && options.searchText) {
      let searchtext = options.searchText;
      let searchdata = [];
      parentList.forEach(element => {
        if (element.name.toLowerCase().includes(searchtext.toLowerCase())) {
          searchdata.push(element);
        }
      });
      filterData.programs = searchdata;
      filterData.count = searchdata.length;
      filterData.clientId = clientId;
      filterData.divisionList = this.programListData.divisionList;
      if (searchdata.length > 0) {
        parentList = searchdata;
      }
    }
    /**Sorting of each row starts */
    if (options.sortBy && options.sortDirection || data.filterObj) {
      /**Sorting of each column by section starts */
      if (data.filterObj.selectedStatus ||
        data.filterObj.hasOwnProperty('policyCallRequired') ||
        data.filterObj.hasOwnProperty('isCartusInsured') ||
        data.filterObj.hasOwnProperty('fromDate') ||
        data.filterObj.hasOwnProperty('toDate')) {

        /**Sorting of each column by section status starts */
        if (data.filterObj.selectedStatus) {
          let filterstatus = data.filterObj.selectedStatus;
          filterData.programs = [];
          parentList.forEach(ele => {
            filterstatus.forEach(element => {
              if (ele.status === 'Active' && element === 'active') {
                JSON.parse(JSON.stringify(filterData.programs.push(ele)));
              } else if (ele.status === 'Draft' && element === 'draft') { /** According to actual data may need changes */
                JSON.parse(JSON.stringify(filterData.programs.push(ele)));
              } else if (ele.status === 'Deactivated' && element === 'deactivated') {
                JSON.parse(JSON.stringify(filterData.programs.push(ele)));
              }
            });
          });
          if (filterData.programs && filterData.programs.length > 0) {
            parentList = filterData.programs;
          }
        }
        /**Sorting of each column by section status ends */
        /**Sorting of each column by section policyCallRequired and isCartusInsured starts */
        if (data.filterObj.hasOwnProperty('policyCallRequired') ||
          data.filterObj.hasOwnProperty('isCartusInsured') ||
          data.filterObj.hasOwnProperty('fromDate') ||
          data.filterObj.hasOwnProperty('toDate')) {
          let filterprograms = [];
          if (data.filterObj.hasOwnProperty('policyCallRequired')) {
            filterprograms = parentList.filter((ele) => {
              if (data.filterObj.hasOwnProperty('policyCallRequired') && ele.policyCallRequired && data.filterObj.policyCallRequired) {
                return ele;
              } else if (data.filterObj.hasOwnProperty('policyCallRequired') && !ele.policyCallRequired && !data.filterObj.policyCallRequired) {
                return ele;
              }
            });
            filterprograms.length > 0 ? (parentList = filterprograms) : null;
          }
          if (data.filterObj.hasOwnProperty('isCartusInsured')) {
            filterprograms = parentList.filter((ele) => {
              if (data.filterObj.hasOwnProperty('isCartusInsured') && ele.isCartusInsured && data.filterObj.isCartusInsured) {
                return ele;
              } else if (data.filterObj.hasOwnProperty('isCartusInsured') && !ele.isCartusInsured && !data.filterObj.isCartusInsured) {
                return ele;
              }
            });
            filterprograms.length > 0 ? (parentList = filterprograms) : null;
          }
          /**Sorting of each column by section policyCallRequired and isCartusInsured ends */
          //To do logic for start date and end date;
          if (data.filterObj.hasOwnProperty('fromDate') || data.filterObj.hasOwnProperty('toDate')) {
            filterprograms = parentList.filter((ele) => {
              if (data.filterObj.hasOwnProperty('fromDate') && data.filterObj.hasOwnProperty('toDate')) {
                if (new Date(ele.programExpirationDate) >= new Date(data.filterObj.fromDate) &&
                  new Date(ele.programExpirationDate) <= new Date(data.filterObj.toDate)) {
                  return ele;
                }
              } else if (data.filterObj.hasOwnProperty('fromDate') && !data.filterObj.hasOwnProperty('toDate')) {
                if (new Date(ele.programExpirationDate) >= new Date(data.filterObj.fromDate) ||
                  !ele.hasOwnProperty('programExpirationDate')) {
                  return ele;
                }
              }
            });
            filterprograms.length > 0 ? (parentList = filterprograms) : null;
          }
          filterprograms.length > 0 ? (filterData.programs = filterprograms) : null;
        }
        if (options.sortBy) {
          return this.sortProgramList(parentList, options, clientId);
        }
        return of(filterData);
      }
      else {
        return this.sortProgramList(parentList, options, clientId);
      }
      /**Sorting of each column by section ends */
    }
    /**Sorting of each row ends */
    else {
      return of(filterData);
    }
  }

  sortProgramList(parentList, options, clientId) {
    switch (options.sortBy) {
      case 'points':
        let points = {
          programs: JSON.parse(
            JSON.stringify((options.sortDirection === 'ASC')
              ? (parentList.sort((a, b) => a.totalPoints > b.totalPoints ? 1 : -1)) :
              (parentList.sort((a, b) => b.totalPoints > a.totalPoints ? 1 : -1)))),
          count: this.programListData.count,
          clientId: clientId,
          divisionList: this.programListData.divisionList
        }
        return of(points);
      case 'programName': let programName = {
        programs: JSON.parse(
          JSON.stringify((options.sortDirection === 'ASC')
            ? (parentList.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)) :
            (parentList.sort((a, b) => b.name.toLowerCase() > a.name.toLowerCase() ? 1 : -1)))),
        count: this.programListData.count,
        clientId: clientId,
        divisionList: this.programListData.divisionList
      }
        return of(programName);
      case 'policyCallRequired': let policyCallRequired = {
        programs: JSON.parse(
          JSON.stringify((options.sortDirection === 'ASC')
            ? (parentList.sort((a, b) => a.policyCallRequired > b.policyCallRequired ? 1 : -1)) :
            (parentList.sort((a, b) => b.policyCallRequired > a.policyCallRequired ? 1 : -1)))),
        count: this.programListData.count,
        clientId: clientId,
        divisionList: this.programListData.divisionList
      }
        return of(policyCallRequired);
      case 'isCartusInsured': let isCartusInsured = {
        programs: JSON.parse(
          JSON.stringify((options.sortDirection === 'ASC')
            ? (parentList.sort((a, b) => a.isCartusInsured > b.isCartusInsured ? 1 : -1)) :
            (parentList.sort((a, b) => b.isCartusInsured > a.isCartusInsured ? 1 : -1)))),
        count: this.programListData.count,
        clientId: clientId,
        divisionList: this.programListData.divisionList
      }
        return of(isCartusInsured);
      case 'status': let status = {
        programs: JSON.parse(
          JSON.stringify((options.sortDirection === 'ASC')
            ? (parentList.sort((a, b) => a.status > b.status ? 1 : -1)) :
            (parentList.sort((a, b) => b.status > a.status ? 1 : -1)))),
        count: this.programListData.count,
        clientId: clientId,
        divisionList: this.programListData.divisionList
      }
        return of(status);
      case 'toDate': let toDate = {
        programs: JSON.parse(
          JSON.stringify((options.sortDirection === 'ASC')
            ? (parentList.sort((a, b) => a.expiryDate > b.expiryDate ? 1 : -1)) && (parentList.sort((a, b) => +new Date(a.expiryDate) - +new Date(b.expiryDate))) :
            (parentList.sort((a, b) => b.expiryDate > a.expiryDate ? 1 : -1)) && (parentList.sort((a, b) => +new Date(b.expiryDate) - +new Date(a.expiryDate))))),
        count: this.programListData.count,
        clientId: clientId,
        divisionList: this.programListData.divisionList
      }
        return of(toDate);
    }
  }
  /**
  * used to deactivate program/programs
  * @param request array of program id's to be deactivated
  */
  deactivateProgram(request): Observable<any> {
    return this.baseClientService
      .put<any>(`v1/admin/program/deactivate-programs`, request)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          this.logSvc.logError('Failed to deactivate program' + err);
          return null;
        })
      );
  }
  mapIconToBenefit(benefitName: string) {
    const icon = ICONS_MAPPING.find(item => benefitName.includes(item.reference));
    //console.log(" ICON: ", icon);
    return icon ? icon.icon : null;
  }

  removeIconFromReq(data: any) {
    if (data.hasOwnProperty('benefits')) {
      data.benefits.forEach(ele => {
        ele.hasOwnProperty('icon') ? delete ele.icon : null;
      });
    }
    return data;
  }

  getProgramDetail(clientId: string, templateName: string): Observable<{ clientId: string, programs: Array<string>, count: number }> {
    // return this.baseClientService
    //   .post<any>(`v1/admin/program?clientId=${clientId}&programName=${templateName}`, {})
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //       this.logSvc.logError(err);
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    let data = this.programListData;
    let filteredProgram = this.programListData.programs.filter(ele => {
      if (ele.name.toLowerCase() == templateName.toLowerCase()) {
        return ele;
      }
    })
    let response = {
      clientId: clientId,
      programs: filteredProgram,
      count: filteredProgram.length
    }
    return of(response);
  }
  updateProduct(data: any) {
    data = this.removeIconFromReq(data);
    return this.baseClientService
      .put<ProgramRequest>(`v1/admin/program`, data)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      );
  }

  getNewLegacy(): Observable<any> {
    // return this.baseClientService
    // .getOne<any>(`v1/admin/value-list?key=LegacyProductSubProduct`)
    // .pipe(
    //   map(r => r.body),
    //   catchError(err => {
    //     this.logSvc.logError(err);
    //     const empty = null;
    //     return of(empty);
    //   })
    // )
    return of({
      "values": [
        {
          "productName": "Banking Services",
          "subProductName": "Client Paid"
        },
        {
          "productName": "Banking Services",
          "subProductName": "Employee Paid"
        },
        {
          "productName": "Banking Services",
          "subProductName": "Project"
        },
        {
          "productName": "Banking Services",
          "subProductName": "Project 1"
        },
        {
          "productName": "BenefitsBuilder",
          "subProductName": "Points"
        },
        {
          "productName": "BenefitsBuilder",
          "subProductName": "Value"
        },
        {
          "productName": "Buyer Value Option",
          "subProductName": "Inspection"
        },
        {
          "productName": "Buyer Value Option",
          "subProductName": "Sale Closing"
        },
        {
          "productName": "Buyer Value Option",
          "subProductName": "Title Search"
        },
        {
          "productName": "Candidate Program",
          "subProductName": "Benefit Summary"
        },
        {
          "productName": "Candidate Program",
          "subProductName": "File Creation and Cost Projection Generation"
        },
        {
          "productName": "Candidate Program",
          "subProductName": "File Creation Only"
        },
        {
          "productName": "Candidate Program",
          "subProductName": "File Creation, Pre-Acceptance"
        },
        {
          "productName": "Candidate Program",
          "subProductName": "Tier 1"
        },
        {
          "productName": "Candidate Program",
          "subProductName": "Tier 10"
        }
      ]
    })
  }

}
