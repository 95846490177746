import { Injectable } from '@angular/core';
import { OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BbAdminBackendService } from 'src/app/login/bb-admin-backend.service';
import { transfereeListData, programListData, COST_MODEL_LIST } from './data';
import { sessionbbadminData } from 'src/app/login/login.service';


@Injectable({
  providedIn: 'root'
})
export class BbAdminDataService implements OnInit {

  constructor(private bbAdminBackendService: BbAdminBackendService) { 

    if (localStorage.getItem(sessionbbadminData)) {
      this.mapTransfereeData(JSON.parse(localStorage.getItem(sessionbbadminData)));
      this.mapProgramData(JSON.parse(localStorage.getItem(sessionbbadminData)));
      this.mapCostmodelData(JSON.parse(localStorage.getItem(sessionbbadminData)));
    } else {
      this.gettransfereeListDatafromApi();
    }
  }

  transfereeListDataSubject = new BehaviorSubject<any>(transfereeListData);
  programListDataSubject = new BehaviorSubject<any>(programListData);
  costModelDataSubject = new BehaviorSubject<any>(COST_MODEL_LIST);
  ngOnInit() {

  }

  gettransfereeListDatafromApi() {
    this.bbAdminBackendService.getBbAdminData().subscribe(
      data => {
        localStorage.setItem(sessionbbadminData, JSON.stringify(data));
        this.mapTransfereeData(JSON.parse(localStorage.getItem(sessionbbadminData)));
        this.mapProgramData(JSON.parse(localStorage.getItem(sessionbbadminData)));
        this.mapCostmodelData(JSON.parse(localStorage.getItem(sessionbbadminData)));
      })
  }

  mapTransfereeData(tranfereeDatafromApi) {
    let data = [];
    let cpData = [];
    for (let i = 0; i < tranfereeDatafromApi.transfereeList.length; i++) {
      data.push(this.prepareTransfereeListSData(tranfereeDatafromApi.transfereeList[i]));
    }
    for (let i = 0; i < tranfereeDatafromApi.costProjectionList.length; i++) {
      cpData.push(this.prepareTransfereeListSData(tranfereeDatafromApi.costProjectionList[i]));
    }
    let transfereeListData = { "candidates": data, "count": data.length, "costProjection": cpData, "cpCount": cpData.length }
    this.transfereeListDataSubject.next(transfereeListData);
  }

  mapProgramData(programDatafromApi) {
    this.programListDataSubject.next(this.prepareProgramListData(programDatafromApi.programList[0]));
  }

  prepareTransfereeListSData(data) {
    let tempData =
    {
      "orderRequestId": data._id,
      "nameDetails": {
        "title": data.nameDetails.title,
        "firstName": data.nameDetails.firstName,
        "lastName": data.nameDetails.lastName
      },
      "phoneDetailsList": [
        {
          "_id": data.phoneDetailsList[0]._id,
          "textingAvailable": data.phoneDetailsList[0].textingAvailable,
          "primary": data.phoneDetailsList[0].primary,
          "usageType": data.phoneDetailsList[0].usageType,
          "phoneDialCode": data.phoneDetailsList[0].phoneDialCode,
          "phoneNumber": data.phoneDetailsList[0].phoneNumber
        }
      ],
      "emailDetailsList": [
        {
          "_id": data.emailDetailsList[0]._id,
          "primary": data.emailDetailsList[0].primary,
          "usageType": data.emailDetailsList[0].usageType,
          "emailAddress": data.emailDetailsList[0].emailAddress
        }
      ],
      "moveStatus": data.moveStatus,
      "moveStatusDate": data.moveStatusDate,
      "authorizationDate": data.authorizationDate,
      "authorizationType":data.authorizationType,
      "divisionPartyId": data.programDetails.divisionPartyId,
      //moveType: data.moveType,
      "timeZone": data.timeZone,
      "initialContactBy": data.initialContactBy,
      "deliveryTeamModel": data.deliveryTeamModel,
      "cliAuthSubTime": data.cliAuthSubTime,
      "cliAuthSubDt": data.cliAuthSubDt,
      "authReadytoProcessTime": data.authReadytoProcessTime,
      "authReadytoProcessDt": data.authReadytoProcessDt,
      "assignedPolicy": data.assignedPolicy,
      "executiveOfficer": data.executiveOfficer,
      "jobStartDate": data.jobStartDate,
      "additionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": data.programDetails.divisionPartyId,
        "programName": data.programDetails.programName,
        "points": data.programDetails.points,
        "pointOverride": data.programDetails.pointOverride,
        "originalPoints": data.programDetails.originalPoints
      },
      "authorizationDetails": {
        "baseContract": data.authorizationDetails.baseContract,
        "cciPartyId": data.authorizationDetails.cciPartyId,
        "currency": []
      },
      "departureAddr": {
        "streetLine1": data.departureAddr.streetLine1,
        "city": data.departureAddr.city,
        "state": data.departureAddr.state,
        "country": data.departureAddr.country,
        "postalCode": data.departureAddr.postalCode
      },
      "departure": data.departureAddr.country + data.departureAddr.state + data.departureAddr.city,
      "destinationAddr": {
        "streetLine1": data.destinationAddr.streetLine1,
        "city": data.destinationAddr.city,
        "state": data.destinationAddr.state,
        "country": data.destinationAddr.country,
        "postalCode": data.destinationAddr.postalCode
      },
      "destination": data.destinationAddr.country+ data.destinationAddr.state  + data.destinationAddr.city,
      "optionalDetails": {
        "costCenter": data.optionalDetails.costCenter,
        "poBillingInvoice": data.optionalDetails.poBillingInvoice,
        "employeeID": data.optionalDetails.employeeID,
        "jobStartDate": data.jobStartDate,
        "priority": data.optionalDetails.priority,
        "employeeType": data.optionalDetails.employeeType,
        "defaultHomeCountry": data.optionalDetails.defaultHomeCountry,
        "defaultHostCountry": data.optionalDetails.defaultHostCountry,
        "assignmentStartDate": data.optionalDetails.assignmentStartDate,
        "assignmentEndDate": data.optionalDetails.assignmentEndDate,
    },
    }
    return tempData;
  }

  prepareProgramListData(data) {
    let tempData = {
      "clientId": "5df51953eb1bc880568c6a93",
      "divisionList": [
        {
          "division_id": "5df51959eb1bc893788c6a96",
          "division_name": "People & Culture",
          "division_number": "032"
        },
        {
          "division_id": "5df51958eb1bc86aa78c6a95",
          "division_name": "Research and Development",
          "division_number": "033"
        },
        {
          "division_id": "5df5195aeb1bc8ced68c6a97",
          "division_name": "International Division",
          "division_number": "034"
        },
        {
          "division_id": "5df5195deb1bc8a9f68c6a99",
          "division_name": "Government Division",
          "division_number": "035"
        },
        {
          "division_id": "5df5195ceb1bc8c70f8c6a98",
          "division_name": "US Domestic Division",
          "division_number": "036"
        }
      ],
      "programs": data.programs,
      "count": data.programs.length
    }
    return tempData;
  }

  mapCostmodelData(data) { 
    if (data.costmodelsList && data.costmodelsList[0] && data.costmodelsList[0].costmodel)
    this.costModelDataSubject.next(this.prepareForCostModel(data.costmodelsList[0].costmodel));
  }
  prepareForCostModel(data) {
    let costModelData = {
      status: 200,
      statusText: 'OK',
      url: 'https://costmodeltst01.cartus.com/costmodel?clientContactId=5de2cd7d65236577b9223541&sortField=updatedDate&sortDir=DESC&limit=20',
      ok: true,
      type: 4,
      body: {
        "costmodels":data,
        "totalCostModel":data.length
      }
    }
    return costModelData;
  }
}


