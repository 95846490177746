import { BaseClientService } from './base-client.service';
import { Injectable } from '@angular/core';
import { CostModel, CostModelDetails } from '../models/cost-model';
import { formatDate } from '@angular/common';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of } from 'rxjs';
import { map, catchError, delay } from 'rxjs/operators';
import { COST_MODEL_LIST, LEVEL_MAPPING } from '../data/cost-model-data';
import { APIResponse } from '../models/response.model';
import { LoggerService } from './logger.service';
import { urlType } from '../models/urlType';
import { CandidateProfilesService } from './candidate-profiles.service';
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';
import { Query } from 'mongoose';
import { BbAdminDataService } from '../data/bb-admin-data.service';

@Injectable({
  providedIn: 'root'
})

export class CostModelsService {
  costModelList: CostModel[] = COST_MODEL_LIST.body.costmodels;

  costModelGet: CostModelDetails = {
    totalCostModel: 20,
    costmodels: this.costModelList
  };

  /**
   * Base constructor
   * @param baseClientService Instance of BaseClient Service
   * @param logSvc Instance of log Service
   */
  tempCostmodelData: any;
  tempLevelmappingData: any;

  constructor(private readonly baseClientService: BaseClientService, private readonly logSvc: RemoteLoggingService,
    private readonly customLogger: LoggerService, private readonly candidateProfileSvc: CandidateProfilesService,
    private bbAdminBackendService: BbAdminDataService) {
    this.tempCostmodelData = this.bbAdminBackendService.costModelDataSubject.getValue();
  }



  /**
   * Return the cost models as json list and loop to display in the table
   * @param queryString queryString
   */
  getCostModels(queryString: string):
    Observable<any> {
    // return this.baseClientService.getArr<any>(`/costmodel?${queryString}`,
    //   '').pipe(  //,urlType.costmodel
    //     map(r => r),
    //     catchError(err => {
    //       this.customLogger.error('Failed to get cost models', err);
    //       const emptyArray: CostModelDetails = null;
    //       return of(emptyArray);
    //     })
    //   );
    this.bbAdminBackendService.costModelDataSubject.subscribe(data => {
      this.tempCostmodelData = data;
    });
    let query: any = {};
    let parentList: any = this.tempCostmodelData;
    let filterData: any = {
      status: 200,
      body: {
        costmodels: [],
        totalCostModel: 0
      }
    }
    query = this.getUrlParams(queryString);
    if (query && (query.hasOwnProperty('searchText') ||
      (query.hasOwnProperty('sortDir') && query.hasOwnProperty('sortField')) ||
      (query.hasOwnProperty('limit')))) {
      if (query && query.hasOwnProperty('searchText')) {
        let searchtext = query.searchText.replace('%20', ' ');
        let searchdata = [];
        parentList.body.costmodels.forEach(element => {
          if (element.costModelName.toLowerCase().includes(searchtext.toLowerCase())) {
            searchdata.push(element);
          } else if (element.levelName.toLowerCase().trim().includes(searchtext.toLowerCase())) {
            searchdata.push(element);
          } else if (element.deptFullAdd.toLowerCase().includes(searchtext.toLowerCase())) {
            searchdata.push(element);
          } else if (element.destFullAdd.toLowerCase().includes(searchtext.toLowerCase())) {
            searchdata.push(element);
          }
        });
        if (searchdata.length > 0) {
          filterData.body.costmodels = searchdata;
          filterData.body.totalCostModel = searchdata.length;
        } else {
          filterData.body.costmodels = parentList.body.costmodels;
          filterData.body.totalCostModel = parentList.body.costmodels.length;
        }
        if (query.hasOwnProperty('sortDir') && query.hasOwnProperty('sortField')) {
          filterData.body = this.sortCostModel(filterData, query.sortDir, query.sortField);
          return of(filterData);
        }
        return of(filterData);
      }
      else if (query.hasOwnProperty('sortDir') && query.hasOwnProperty('sortField')) {
        parentList.body = this.sortCostModel(parentList, query.sortDir, query.sortField);
        return of(parentList);
      }
      return of(parentList);
    }
    else {
      return of(this.tempCostmodelData);
    }
  }

  getUrlParams(queryString) {
    const hashes = queryString.slice(queryString.indexOf('?') + 1).split('&')
    const params = {}
    hashes.map(hash => {
      const [key, val] = hash.split('=')
      params[key] = val
    })
    return params;
  }

  sortCostModel(parentList, sortDir, sortFeild) {
    switch (sortFeild) {
      case 'costModelName':
        let costModelName = {
          costmodels: (sortDir === 'ASC')
            ? (parentList.body.costmodels.sort((a, b) => a.costModelName > b.costModelName ? 1 : -1)) :
            (parentList.body.costmodels.sort((a, b) => b.costModelName > a.costModelName ? 1 : -1)),
          totalCostModel: parentList.body.totalCostModel
        }
        return (costModelName);
      case 'level':
        let level = {
          costmodels: (sortDir === 'ASC')
            ? (parentList.body.costmodels.sort((a, b) => a.level > b.level ? 1 : -1)) :
            (parentList.body.costmodels.sort((a, b) => b.level > a.level ? 1 : -1)),
          totalCostModel: parentList.body.totalCostModel
        }
        return (level);
      case 'departure':
        let departure = {
          costmodels: (sortDir === 'ASC')
            ? (parentList.body.costmodels.sort((a, b) => a.deptState > b.deptState ? 1 : -1)) &&
            (parentList.body.costmodels.sort((a, b) => a.deptCity > b.deptCity ? 1 : -1)) &&
            (parentList.body.costmodels.sort((a, b) => a.deptFullAdd > b.deptFullAdd ? 1 : -1)) :
            (parentList.body.costmodels.sort((a, b) => b.deptState > a.deptState ? 1 : -1)) &&
            (parentList.body.costmodels.sort((a, b) => b.deptCity > a.deptCity ? 1 : -1)) &&
            (parentList.body.costmodels.sort((a, b) => b.deptFullAdd > a.deptFullAdd ? 1 : -1)),
          totalCostModel: parentList.body.totalCostModel
        }
        return (departure);
      case 'destination':
        let destination = {
          costmodels: (sortDir === 'ASC')
            ? (parentList.body.costmodels.sort((a, b) => a.destState > b.destState ? 1 : -1)) &&
            (parentList.body.costmodels.sort((a, b) => a.destCity > b.destCity ? 1 : -1)) &&
            (parentList.body.costmodels.sort((a, b) => a.destFullAdd > b.destFullAdd ? 1 : -1)) :
            (parentList.body.costmodels.sort((a, b) => b.destState > a.destState ? 1 : -1)) &&
            (parentList.body.costmodels.sort((a, b) => b.destCity > a.destCity ? 1 : -1)) &&
            (parentList.body.costmodels.sort((a, b) => b.destFullAdd > a.destFullAdd ? 1 : -1)),
          totalCostModel: parentList.body.totalCostModel
        }
        return (destination);
    }
  }

  limitCostModel(query, parentList) {
    let filterData: any = {
      status: 200,
      body: {
        costmodels: [],
        totalCostModel: 0
      }
    }
    let limitData = [];
    let loopLimit: any = 0;
    (query.limit <= parentList.body.costmodels.length) ? (loopLimit = query.limit) : (loopLimit = parentList.body.costmodels.length);
    for (let i = 0; i < loopLimit; i++) {
      limitData.push(parentList.body.costmodels[i]);
    }
    if (limitData.length > 0) {
      filterData.body.costmodels = limitData;
      filterData.body.totalCostModel = limitData.length;
    } else {
      filterData.body.costmodels = parentList.body.costmodels;
      filterData.body.totalCostModel = parentList.body.costmodels.length;
    }
    return (filterData);
  }

  /**
   * Used to add a CostModel
   * @param request cost model details which is to be added
   */
  createCostModel(request: CostModel): Observable<CostModel> {
    return this.baseClientService.post<any>('/costmodel', request, '').pipe(  //, urlType.costmodel
      map(r => r.body),
      catchError((err, source) => {
        const empty: APIResponse = null;
        this.customLogger.error('Failed to add cost models', err);
        return of(empty);
      })
    );
  }

  /**
   * Used to save CostModel
   * @param request cost model details which is to be saved
   */
  saveCostModel(costModelId, request: CostModel): Observable<CostModel> {
    // return this.baseClientService.put<any>(`/costmodel/${costModelId}`, request, '').pipe(  //, urlType.costmodel
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     return this.candidateProfileSvc.catchBlock('Failed to update cost model', err, 'string');

    //   })
    // );

    const len = Math.ceil(Math.log10(costModelId + 1));
    let newCostModelData: any = {};
    if (len === 9 && !request.hasOwnProperty('oldCostModelId')) {
      newCostModelData = this.addNewCostModelList(costModelId, request);
      this.tempCostmodelData.body.costmodels.push(newCostModelData);
      this.tempCostmodelData.body.totalCostModel = this.tempCostmodelData.body.totalCostModel + 1;
      this.bbAdminBackendService.costModelDataSubject.next(this.tempCostmodelData);
      return of(newCostModelData);
    } else {
      this.tempCostmodelData.body.costmodels.filter((ele) => {
        if (ele.costModelId === costModelId) {
          this.updateCostModelList(ele, request);
        }
      });
      this.bbAdminBackendService.costModelDataSubject.next(this.tempCostmodelData);
      return of(this.tempCostmodelData.body.costmodels);
    }
  }

  deleteCostModel(deleteCostModelDetails): Observable<any> {
    return this.baseClientService
      .put<APIResponse>('/costmodel/delete', deleteCostModelDetails, '')  //, urlType.costmodel
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: any = null;
          this.customLogger.error('Failed to delete cost model', err);
          return of(empty);
        })
      );
  }

  updateCostModelList(ele, request) {
    let costEstimates = [];
    LEVEL_MAPPING.map((level) => {
      if (level.level === request.level) {
        costEstimates = level.costEstimates;
      }
    });
    ele.costModelName = request.costModelName ? request.costModelName : ele.costModelName;
    ele.costModelId = ele.costModelId;
    ele.clientId = ele.clientId;
    ele.clientContactId = ele.clientContactId;
    ele.costEstimates = costEstimates;
    ele.destState = request.destState ? request.destState : ele.destState;
    ele.destCity = request.destCity ? request.destCity : ele.destCity;
    ele.deptState = request.deptState ? request.deptState : ele.deptState;
    ele.deptCity = request.deptCity ? request.deptCity : ele.deptCity;
    ele.level = request.level ? request.level : ele.level;
    ele.createdDate = ele.createdDate;
    ele.updatedDate = new Date().toISOString().substring(0, 10);
  }


  addNewCostModelList(costModelId, request) {
    let costEstimates = [];
    LEVEL_MAPPING.filter((level) => {
      if (level.level == request.level) {
        costEstimates = level.costEstimates;
      }
    });
    let updatedData = {
      "costModelName": request.costModelName,
      "costModelId": costModelId,
      "clientId": "5e5edd467df4620008652e1a",
      "clientContactId": "5e5eddde7df4620008652e1c",
      "clientName": "",
      "clientContactName": "",
      "costEstimates": costEstimates,
      "taxGrossRate": 0.42146410803127227,
      "destState": request.destState,
      "destCity": request.destCity,
      "deptState": request.deptState,
      "deptCity": request.deptCity,
      "level": request.level,
      "createdDate": new Date().toISOString().substring(0, 10),
      "updatedDate": new Date().toISOString().substring(0, 10)
    }
    return (updatedData);
  }
}
