<div class="admin-container" role="group">
    <div class="admin-content-container" role="none">

        <mat-card class="mat-card-client-details" fxLayout fxLayoutAlign="start start" fxLayoutAlign.xs="start start" style="margin-top: 10px;">
            <div class="help-icon-wrapper">
                <span>?</span>
            </div>
            <div class="client-detail" role="table">
                <div fxLayout class="additional-detail" fxLayoutAlign="start center" role="cell">
                    <span class="detail-header" role="none">Program Name: </span>
                </div>
                <div class="client-name" role="cell" *ngIf="program.programName" tabindex="0" (keyup.enter)="editProgramDetails()" (mouseover)="editProgramName = true"
                  (mouseout)="editProgramName = false" (focus)="editProgramName = true" (focusout)="editProgramName = false">
                  <span role="text">{{ program.programName }}</span>
                  <mat-icon tabindex="0" class="edit-program-name" *ngIf="validForDeactivation" [ngStyle]="editProgramName? {'visibility': 'visible'} : {'visibility': 'hidden'} "
                    (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                </div>
            </div>
            <div class="download-report" role="none">
                <button mat-button matSuffix mat-icon-button aria-label="Download Program Detail" title="Download Program Detail" role="button" (click)="openDownloadToastr()">
          <mat-icon alt="Download Program Detail">system_update_alt</mat-icon>
          <span> Download Report </span>
        </button>
            </div>
            <div class="close" role="none">
                <button mat-button matSuffix mat-icon-button aria-label="Close Program Detail" title="Close Program Detail" role="button" (click)="closeProgram()">
          <mat-icon alt="Close Program Detail">close</mat-icon>
        </button>
            </div>
        </mat-card>

         <!--Accordion for Divisions -->
        <mat-accordion class="client-details-panel">
           
            <mat-expansion-panel class="candidate-detail">
                <mat-expansion-panel-header class="division-expansion">
                    <span class="panel-heading" role="none">Divisions</span>
                    <span class="divisionFont" *ngIf="program.divisions && program.divisions.length > 0" role="none">Divisions Assigned: {{ program.divisions.length }}</span>
                    <span class="divisionFont" *ngIf="program.divisions.length === 0" role="none">Divisions Assigned: None</span>
                </mat-expansion-panel-header>
                <mat-panel-description class="division-description" fxLayout="column" [fxLayoutAlign]="program.divisions && program.divisions.length === 0?'center center':'unset unset'">
                    <ng-container *ngIf="program.divisions.length === 0 && (userRole === 'program-admin')">
                        <div><span>You must assign Divisions to publish this program for use</span></div>
                        <div><a  [ngClass]="!validForDeactivation ? 'division-disabled' : '' " (click)="validForDeactivation ? assignDivision():''">Assign Divisions</a></div>
                    </ng-container>
                    <!-- If divisions are assigned-->
                    <ng-container *ngIf="program.divisions && program.divisions.length > 0">
                        <div fxLayout="row" fxLayoutAlign="space-between baseline" fxFlex="100%">
                            <div fxFlex="20%" class="left-content" fxLayout="column" fxLayoutGap="20px">
                                <!-- <mat-icon class="search-icon" aria-label="search for assigned division by name">search</mat-icon> -->
                                <!-- <input placeholder="Search for assigned divisions by name" class="search-division" /> -->
                                <button *ngIf="(userRole === 'program-admin')" [disabled]="!validForDeactivation" mat-button class="edit-division" (click)="assignDivision(program)">Edit Divisions</button>
                            </div>
                            <div class="right-content" fxFlex="75%" fxLayout="column">
                                <p class="assigned-div-title">Assigned divisions: ({{program.divisions.length || 0}})</p>
                                <div fxLayout="row">
                                    <div class="division-panel-body">
                                        <ng-container *ngFor="let divisionData of program.divisions;let i=index">
                                            <div>{{divisionData.division_name}} ({{ divisionData.division_number }})</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    
                </mat-panel-description>
            </mat-expansion-panel>
        </mat-accordion>

        <!--Accordion for Program Details -->
        <mat-accordion class="client-details-panel" >
            <mat-expansion-panel class="candidate-detail">
                <mat-expansion-panel-header class="division-expansion">
                    <span class="panel-heading" role="none">Program Details</span>
                </mat-expansion-panel-header>
                <mat-panel-description>
                    <div class="program-details" role="group">
                        <div fxLayout="row" fxLayoutAlign="start start" role="row" fxLayoutGap="15px">
                            <div fxFlex="20" role="cell">
                                <div class="field" 
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editTotalPoints = true" (mouseout) = "editTotalPoints = false" 
                                    (focus) = "editTotalPoints = true" (focusout) = "editTotalPoints = false" 
                                    role="listitem" aria-label="Total Points for Assigned Transferees {{ program.totalPoints }}">
                                    <div class="label" fxLayout = "row" role="none">Total Points for Assigned Transferees
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program.totalPoints && !program.draft && validForDeactivation" [ngStyle]= "editTotalPoints? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                    <div class="value" fxLayout = "row" *ngIf="program.totalPoints" role="none">{{ program.totalPoints }}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program.draft && validForDeactivation" [ngStyle]= "editTotalPoints? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="40" role="cell">
                                <div class="field" role="none" aria-label="Program Template {{ program.templateName }}">
                                    <div class="label" role="none">Program Template</div>
                                    <div class="value" *ngIf="program.templateName" role="none">{{ program.templateName }}</div>
                                </div>
                            </div>
                            <div fxFlex="30" role="cell">
                                <div class="field" role="none" aria-label="Program Activation Date {{ program.programActivationDate || 'N/A' }}">
                                    <div class="label" role="none">Program Activation Date</div>
                                    <div class="value" role="none">{{ program.programActivationDate ? (program.programActivationDate | date:'MM/dd/yyyy') : 'N/A'}}</div>
                                </div>
                            </div>
                            <div fxFlex="20" role="cell">
                                <div class="field" tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editIsCartusInsured = true" (mouseout) = "editIsCartusInsured = false" 
                                    (focus) = "editIsCartusInsured = true" (focusout) = "editIsCartusInsured = false" 
                                    role="none" aria-label="Program Expiration (Optional) {{ program.programExpirationDate }}">
                                    <div class="label" role="none">Cartus Insured Shipping</div>
                                    <div class="value" fxLayout = "row" role="none">{{ program.isCartusInsured ? 'Insured by Cartus' : 'Not Insured by Cartus'}}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program.draft && validForDeactivation" [ngStyle]= "editIsCartusInsured? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="start start" role="row" fxLayoutGap="15px">
                            <div fxFlex="20" role="cell">
                                <div class="field" role="none" 
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editContract = true" (mouseout) = "editContract = false" 
                                    (focus) = "editContract = true" (focusout) = "editContract = false"
                                    aria-label="Legacy Contract {{ program.contractSeries }}">
                                    <div class="label" fxLayout = "row" role="none">Legacy Contract
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program.contractSeries && userRole === 'program-admin' && program.draft && validForDeactivation" [ngStyle]= "editContract? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                    <div class="value" fxLayout = "row" *ngIf="program.contractSeries" role="none">{{ program.contractSeries }}
                                        <mat-icon tabindex="0" class="edit-expiration-date" [ngStyle]= "editContract? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="40" role="cell">
                                <div class="field"
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editPolicy = true" (mouseout) = "editPolicy = false" 
                                    (focus) = "editPolicy = true" (focusout) = "editPolicy = false" 
                                    role="none" aria-label="Policy {{ program.atlasPolicy }}">
                                    <div class="label" fxLayout = "row" role="none">Policy
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program.atlasPolicy && userRole === 'program-admin' && program.draft && validForDeactivation" [ngStyle]= "editPolicy? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                    <div class="value" fxLayout = "row" *ngIf="program.atlasPolicy" role="none">{{ program.atlasPolicy }}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program.draft && validForDeactivation" [ngStyle]= "editPolicy? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            
                            <div fxFlex="20" role="cell">
                                <div class="field" tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editExpiration = true" (mouseout) = "editExpiration = false" 
                                    (focus) = "editExpiration = true" (focusout) = "editExpiration = false" 
                                    role="none" aria-label="Program Expiration (Optional) {{ program.programExpirationDate }}">
                                    <div class="label" role="none">Program Expiration (Optional)</div>
                                    <div class="value" fxLayout = "row" role="none">{{ program.programExpirationDate ? (program.programExpirationDate | date:'MM/dd/yyyy') : 'N/A'}}
                                        <mat-icon tabindex="0" class="edit-expiration-date" [ngStyle]= "editExpiration? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div fxFlex="20" role="cell">
                                <div class="field" role="none" 
                                    tabindex="0" (keyup.enter)="editProgramDetails()"
                                    (mouseover) = "editpolicyCallRequired = true" (mouseout) = "editpolicyCallRequired = false" 
                                    (focus) = "editpolicyCallRequired = true" (focusout) = "editpolicyCallRequired = false" >
                                    <div class="label" role="none">Policy Call Confirm</div>
                                    <div class="value" fxLayout = "row" role="none">{{ program.policyCallRequired ? 'Required' : 'Not Required'}}
                                        <mat-icon tabindex="0" class="edit-expiration-date" *ngIf = "!program.draft && validForDeactivation" [ngStyle]= "editpolicyCallRequired? {'visibility': 'visible'} : {'visibility': 'hidden'} " (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-panel-description>
            </mat-expansion-panel>
        </mat-accordion>

    </div>
</div>

<div class="search-block" role="search" fxLayout.xs="row" fxLayoutAlign.xs="center center">
    <mat-form-field class="table-search" appearance="outline">
        <span matPrefix class="lg-only" role="none">
        <mat-icon alt="search">search</mat-icon>
    </span>
        <input matInput tabindex="0" placeholder="Search for benefits by name" [(ngModel)]="searchValue" (keyup) = "textSearch($event)" (keyup.enter)="textSearchBenefits($event.target.value)" role="searchbox">
        <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="clearTextSearch()" *ngIf="searchValue" aria-label="Clear Search">
        <mat-icon alt="close">close</mat-icon>
    </button>
    </mat-form-field>
    <!--<button mat-icon-button class="filter-icon" matTooltip="Filter results"><span tabindex="0" aria-hidden="true" class="material-icons">filter_list</span></button> -->
</div>
<div class="benefit-list" role="listbox" >
    <ng-container *ngFor="let benefitGroup of benefitsList; let j=index" >
        <h3 [attr.aria-label]="'Move Phase: ' + benefitGroup.group" *ngIf = "benefitGroup.items.length > 0 ">Move Phase: <span role="none">{{ benefitGroup.group | titlecase }}</span></h3>
        <ul cdkDropList id="{{benefitGroup.group}}" (cdkDropListDropped)="drop($event)" [cdkDropListData]="benefitGroup.group"  [cdkDropListConnectedTo]="connectedTo">
            <li *ngFor="let benefit of benefitGroup.items; let i= index" cdkDrag (cdkDragStarted)="dragStarted($event,i,j)" [ngStyle]="benefit.clonedCopy ? {'border-color':'blue','border-style':'solid'}:''" [ngClass]="{ 'suppress': benefit.suppress }">              
                <div class="drag-placeholder" *cdkDragPlaceholder></div>
                <div fxFlex="2" role="cell" class="drag-handle" >
                    <!-- <span class="dot-handler" cdkDragHandle>::</span> -->
                    <mat-icon class="dot-handler" cdkDragHandle>drag_indicator</mat-icon>
                </div> 
                <mat-card class="mat-elevation-z3 drag-card" fxFlex="calc(33%-25px)" fxFlex.sm="calc(50%-25px)" [ngClass]="{ 'consultant-only': benefit.consultantBenefitInfo?.consultant && benefit?.points !== 'Guaranteed','isNewelyAdded':benefit.isNewlyAddedRef}">
                    <mat-card-content>
                        <div fxLayout="row" fxLayoutAlign.xs="center center" role="row" fxLayoutGap="15px">
                            <div fxFlex="10" fxLayout="column" fxLayoutGap="10px" role="cell" class="img-icon">
                            <!-- <img src="../../../../../../assets/images/120x120.png" alt="Icon here" role="figure" class="mat-elevation-z3"> -->
                            <mat-chip class="temp-chip" *ngIf="benefit?.tempLiving">Supplier Orders</mat-chip>
                            <!-- <img src="../../../../../../assets/images/120x120.png" alt="Icon here" role="figure" class="mat-elevation-z3"> -->
                            <img [src]="benefit.icon ? '../../../../../assets/icons/bb_icons/' + benefit.icon : '../../../../../assets/icons/bb_icons/enabled_generic_benefit_icon.svg'"
                                alt="benefit" role="figure" class="mat-elevation-z3">
                                <div *ngIf="benefit?.hybridTempLiving" style="border: 0.2px solid gray;">
                                    <mat-chip *ngIf="benefit?.incremental &&!isChipEditable[i] &&!benefit.isNewlyAddedRef " style="margin-bottom: 5px;"
                                        class="chip-style">
                                        <div class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>Core {{benefit?.rangeIncrementUnit}}
                                            Per
                                            Selection </div>
                                        <div>{{benefit?.coreUnitsPerSelection}}</div>
                                        <button tabindex="0" mat-icon-button
                                            *ngIf="benefit?.incremental"
                                            (click)="enabledChipToEdit(i,j)">
                                            <!-- (click)="enabledChipToEdit(i,j)" -->
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <mat-chip class="chip-style" (mouseover)="editRaneIncrementVal[i] = true"
                                        *ngIf="benefit?.incremental &&!isChipEditable[i] &&!benefit.isNewlyAddedRef " color="#452D7C" selected>
                                        <div class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>Flex {{ benefit?.rangeIncrementUnit
                                            }} Per
                                            Selection </div>
                                        <div> {{ benefit?.rangeIncrementValue }}</div>
                                        <button tabindex="0" mat-icon-button
                                            *ngIf="benefit?.incremental"
                                            (click)="enabledChipToEdit(i,j); rangeIncrementValue = benefit.rangeIncrementValue">
                                            <!-- (click)="enabledChipToEdit(i,j)" -->
                                
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <div class="chip-outer-box" *ngIf="benefit?.incremental && isChipEditable[i]">
                                        <div class="timeframeOuter">
                                            <span class="timeframeSpan"> Timeframe </span>
                                            <button tabindex="0" mat-icon-button class="matIconStyle" (click)="closeHybrid(i, j)"
                                                style="margin-right:10px">
                                                <mat-icon>cancel</mat-icon>
                                            </button>
                                            <button tabindex="0" mat-icon-button class="matIconStyle"
                                                (click)="closeRangeIncrementValueMenu(benefit, index); isChipEditable[i]=false;">
                                                <mat-icon style="color: #3865f8;">check_circle</mat-icon>
                                            </button>
                                        </div>
                                        <div>
                                            <mat-form-field appearance="fill" style="width: 100%;">
                                                <mat-select matNativeControl [formControl]="timeSpanValue" [(ngModel)]="benefit.rangeIncrementUnit"
                                                    name="timeFrame">
                                                    <mat-option value="Days" selected="selected">Days</mat-option>
                                                    <mat-option value="Months">Months</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-chip style="margin-top: -10px;" class="StyleChip">Core {{benefit.rangeIncrementUnit}} per selection
                                                <input type="number" number-only class="inputSelection" [(ngModel)]="benefit.coreUnitsPerSelection"
                                                    (keyup.backspace)="onMatChipKeyPressCore($event,benefit.coreUnitsPerSelection,j,i)">
                                            </mat-chip>
                                            <mat-chip class="StyleChip">Flex {{benefit.rangeIncrementUnit}} per selection
                                                <input type="number" class="inputSelection" number-only tabindex="0" required name="rangeIncrementVal"
                                                    id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel"
                                                    (keyup.backspace)="onMatChipKeyPress($event,rangeIncrementValue,j,i)">
                                            </mat-chip>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="!benefit?.hybridTempLiving">
                                    <mat-chip (mouseover)="editRaneIncrementVal[i] = true"
                                        *ngIf="benefit?.incremental &&!isChipEditable[i] &&!benefit.isNewlyAddedRef" color="#452D7C" selected>
                                        <div class="rangeIncrementUnit" [class.tn-focus]='edit-point-edit'>{{ benefit?.rangeIncrementUnit }} Per
                                            Selection </div>
                                        <div> {{ benefit?.rangeIncrementValue }}</div>
                                        <button tabindex="0" mat-icon-button class="edit-point" (focusout)="editRaneIncrementVal[i] = false"
                                            [ngStyle]="editRaneIncrementVal[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                                            [ngClass]="{'edit-point-edit': editRaneIncrementVal[i]}"
                                            *ngIf="benefit?.incremental"
                                            (click)="enabledChipToEdit(i,j); rangeIncrementValue = benefit.rangeIncrementValue">
                                            <mat-icon>edit</mat-icon>
                                        </button>
                                    </mat-chip>
                                    <mat-chip class="chip-form style-chip " *ngIf="benefit?.incremental && isChipEditable[i]">
                                        <form style="display: flex;flex-flow: row wrap;align-items: center;">
                                            <mat-form-field style="width: 33%; font-size: 13px;" *ngIf="benefit?.incremental">
                                                <mat-select [formControl]="timeSpanValue" [(ngModel)]="benefit.rangeIncrementUnit" ngDefaultControl>
                                                    <mat-option *ngFor="let data of timespanValues" style="font-size: 15px;" [value]="data"
                                                        name="timeSpanValue" class="form-control">
                                                        {{ data }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <mat-form-field [ngStyle]="benefit?.incremental?{'width': '10%','margin-left':'3%'} : {'width': '20%'}">
                                                <input matInput number-only ngDefaultControl tabindex="0" number required name="rangeIncrementVal"
                                                    id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" 
                                                    (keyup.backspace)="onMatChipKeyPress($event,rangeIncrementValue,j,i)">
                                            </mat-form-field>
                                            <button tabindex="0" mat-icon-button class="edit-point"
                                                (focusout)="editRaneIncrementVal[i] = false; isChipEditable[i]=false;"
                                                [ngStyle]="editRaneIncrementVal[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                                                [ngClass]="{'edit-point-edit': editRaneIncrementVal[i]}"
                                                *ngIf="benefit?.incremental"
                                                (click)="closeRangeIncrementValueMenu(benefit, index); isChipEditable[i]=false;">
                                                <mat-icon>edit</mat-icon>
                                            </button>
                                        </form>
                                    </mat-chip>
                                </div>
                        <mat-chip class="matchip-css style-chip " (mouseover) = "editPerPointCurrency[i] = true"  *ngIf="benefit?.incrementalCurrency &&!benefit.isNewlyAddedRef" color="#452D7C" selected><div class="dollarPerPointUnit" [class.tn-focus]='edit-point-edit'>Value Per Point </div><div class="dollar-text"> {{ benefit?.perPointCurrency }}</div> <div class="left-margin-style dollar-text">{{benefit?.rangeIncrementUnit}}</div>
                                <button tabindex="0" mat-icon-button class="edit-point" (focusout) = "editPerPointCurrency[i] = false; "
                                    [ngStyle]= "editPerPointCurrency[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editPerPointCurrency[i]}"
                                    *ngIf = "(benefit?.incrementalCurrency)"
                                    [matMenuTriggerFor]="perPointCurrencyMenu"
                                    (menuOpened)="openRangeIncrementValueMenu()"
                                    >
                                    <mat-icon>edit</mat-icon> 
                                </button>
                                <mat-menu #rangeIncrementValueMenu="matMenu" xPosition="before" overlapTrigger="true"
                                (closed) = "$event == undefined && (rangeIncrementVal.dirty || rangeIncrementVal.touched) ? benefit.rangeIncrementValue = rangeIncrementValue : null; closeRangeIncrementValueMenu(benefit, index); rangeIncrementValue = null;editRaneIncrementVal[i] = false;">
                                    <input matInput number-only tabindex="0" number required placeholder="Enter Range Increment Value" name="rangeIncrementVal" id="rangeIncrementVal_{{i}}" [(ngModel)]="rangeIncrementValue" #rangeIncrementVal="ngModel" class="form-control input-point"
                                        (click)="$event.stopPropagation()">
                                </mat-menu>

                                <mat-menu #perPointCurrencyMenu="matMenu" xPosition="before" overlapTrigger="true"
                                    (closed) = "$event == undefined && (perPointCurrencyVal.dirty || perPointCurrencyVal.touched) ? benefit.perPointCurrency = perPointCurrency : null; closePerPointCurrencyMenu(benefit); perPointCurrency = null; editPerPointCurrency[i] = false;">
                                    <input matInput number-only tabindex="0" number required placeholder="Enter Per point Value" name="perPointCurrencyVal" id="perPointCurrencyVal_{{i}}" [(ngModel)]="perPointCurrency"  #perPointCurrencyVal="ngModel"  class="form-control input-point" 
                                        (click)="$event.stopPropagation();">
                                </mat-menu>
                            </mat-chip>

                            </div>
                            
                            <div *ngIf="!benefit.isNewlyAddedRef" fxFlex="45" role="cell" class="benefit-detail" [ngStyle]= "benefit.suppress ? {'pointer-events': 'none'} : ''">
                                <div fxLayout="row" fxLayoutGap="15px">                                   
                                    <div fxFlex="70" role="cell" [attr.aria-label]="'Benefit Title: ' + benefit.displayName" class="title-container">
                                      <h5>Title</h5>
                                      <h3 tabindex="0">                                       
                                        <span *ngIf="!benefit.editTitle">{{ benefit.displayName || 'N/A' | titlecase }}</span>
                                        <input matInput tabindex="0" fxflex="80%" name="titleVal" id="titleVal_{{i}}"
                                          [(ngModel)]="benefit.displayName" #titleVal="ngModel" #title *ngIf="benefit.editTitle" class="form-control" 
                                          [class.tn-focus]='edit-point-edit'>                                
                                        <button fxFlex="20%" tabindex="0" mat-icon-button aria-label="edit title"
                                          title="edit title" class="edit-point"
                                          [ngClass]="{'edit-point-edit': benefit.editTitle}"
                                          *ngIf="(( !program.isNew || (!program.isNew && program.draft)) && validForDeactivation)"
                                          (click)="editProgramTitle(benefit)">                                          
                                          <mat-icon>edit</mat-icon>
                                        </button>
                                      </h3>
                                    </div>
                                    <div fxFlex="30" role="cell" [attr.aria-label]="'Total Points: ' + benefit.points"
                                        (mouseover) = "editPoint[i] = true;" fxLayout = "row" fxLayoutGap="1em" (mouseout) = "editPoint[i] = false">
                                        <div fxFlex="auto">
                                            <h5>Points</h5>
                                            <h3 fxLayout = "row" tabindex="0" [ngStyle]= "!(pointVal.touched || pointVal.dirty) || benefit.points ? {'color': '#424242'} : {'color': '#f00'} "
                                        (focus) = "editPoint[i] = true; " >   
                                        <span fxFlex="80%"> {{  (benefit.points || (benefit.points ===0 && (!benefit?.advCashOutV2 && !benefit?.advCashOut) )) ? (benefit.points === 'Guaranteed'? 'Core': benefit.points): (benefit?.advCashOutV2 || benefit?.advCashOut) ? 'Logic':'' }} </span>
                                        <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i]}"
                                                *ngIf = "validForDeactivation && ( !program.isNew || (!program.isNew && program.draft)) && !benefit?.advCashOutV2  && !benefit?.advCashOut && !benefit?.incremental && !benefit?.incrementalCurrency && !benefit?.multiplicity || ((!program.isNew && program.draft) && (benefit?.multiplicity && editMultiplicity[i]) || (benefit?.incremental && editIncremental[i]))" [matMenuTriggerFor]="pointMenu" (menuOpened)="openPointMenu()">
                                                <mat-icon>edit</mat-icon> 
                                            </button>

                                            <!-- Advance point logic for editing -->
                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                *ngIf = "validForDeactivation && ( !program.isNew || (!program.isNew && program.draft)) && (benefit?.advCashOutV2 || benefit?.advCashOut)" (click) = "openPointsLogicModal(benefit)">
                                                <mat-icon>edit</mat-icon> 
                                            </button>

                                            <!-- Advance point logic for view -->
                                            <!-- <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false" mat-icon-button class="edit-point" 
                                                [ngStyle]= "editPoint[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'}" [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                *ngIf = "( !program.isNew && !program.draft)  && benefit.advCashOut" (click) = "openPointsLogicModal(benefit)">
                                                <mat-icon>remove_red_eye</mat-icon> 
                                            </button> -->

                                            <button fxFlex="20%" tabindex="0" (focusout) = "editPoint[i] = false; editMultiplicity[i] = false; editIncremental[i] = false;" mat-icon-button class="edit-point" 
                                                [ngStyle]= "editPoint[i] || (editMultiplicity[i] || editIncremental[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} " [ngClass]="{'edit-point-edit': editMultiplicity[i] || editIncremental[i] }"
                                                *ngIf = "validForDeactivation && ( !program.isNew || (!program.isNew && program.draft)) && ((benefit?.multiplicity && !editMultiplicity[i]) || (benefit?.incremental && !editIncremental[i]))" (click) = "editMultiplicity[i] = true;editIncremental[i] = true; editRangeMax(benefit); openPointMenu()">
                                                <mat-icon>edit</mat-icon> 
                                            </button>
                                            
                                            <!-- <button fxFlex="20%" tabindex="0"  mat-icon-button class="edit-point" (focusout) = "!editIncrementalCurrency[i] ? editPoint[i] = false: null;"
                                                [ngStyle]= "(editPoint[i] || editIncrementalCurrency[i] ) ? {'visibility': 'visible'} : {'visibility': 'hidden'} "
                                                [ngClass]="{'edit-point-edit': editIncrementalCurrency[i]}"
                                                *ngIf = "validForDeactivation && ( program.isNew || (!program.isNew && program.draft)) && ((benefit?.incrementalCurrency))" 
                                                (click) = "editIncrementalCurrency[i]=!editIncrementalCurrency[i]; editMaxSelection(benefit, editIncrementalCurrency[i]); ">
                                                <mat-icon>edit</mat-icon>
                                            </button> -->
                                            <mat-menu #pointMenu="matMenu" xPosition="before" overlapTrigger="true" 
                                                (closed) = "$event == undefined && (pointVal.dirty || pointVal.touched) ? benefit.points = points : null; closePointMenu(benefit); points = null;editMultiplicity[i] = false;editIncremental[i] = false">
                                                <button mat-menu-item *ngIf = "!(benefit?.multiplicity || benefit?.incremental || benefit?.incrementalCurrency) && validForDeactivation" (click) = "benefit.points = 'Guaranteed'">Core</button>
                                                <input matInput number-only tabindex="0" number required placeholder="Enter Points" name="pointVal" id="pointVal_{{i}}" [(ngModel)]="points" #pointVal="ngModel" class="form-control input-point"
                                                (click)="$event.stopPropagation()">
                                            </mat-menu>
                                            </h3>
                                        </div>
                                        <div fxFlex="auto" *ngIf = "benefit?.multiplicity || benefit?.incremental || benefit?.advCashOut || benefit?.advCashOutV2 || benefit?.incrementalCurrency">
                                            <h5>Max Selections</h5>
                                            <h3 fxLayout = "row" tabindex="0" [ngStyle]= "benefit?.incremental ? benefit.maxSelection == 0 ? {'color': '#f00'} : {'color': '#424242'} : benefit?.incrementalCurrency ? (benefit?.incrementalCurrency && (benefit?.rangeMax / benefit?.rangeIncrementValue === 0)) ? {'color': '#f00'} : {'color': '#424242'} : (!benefit.maxSelection || benefit.maxSelection == 0) ? {'color': '#f00'} : {'color': '#424242'}">   
                                                <span fxFlex="80%" *ngIf = "!(editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]) && validForDeactivation"> {{  (benefit.maxSelection || benefit.maxSelection ===0) ? benefit.maxSelection : benefit?.incremental || benefit.incrementalCurrency ? (benefit?.rangeMax / benefit?.rangeIncrementValue === 0 || benefit?.rangeIncrementValue === '' || benefit?.rangeIncrementValue === "0" ? '' : benefit?.rangeMax / benefit?.rangeIncrementValue) : (benefit?.advCashOutV2 || benefit?.advCashOut) ? 'N/A':''}} </span>
                                                <input type="number" matInput number-only number tabindex="0" fxflex="80%" name="maxSelection" id="maxSelection_{{i}}" #maxSelection = "ngModel"
                                                [(ngModel)]="benefit.maxSelection" *ngIf="editMultiplicity[i] || editIncremental[i] || editIncrementalCurrency[i]" (focusout) = "editMultiplicity[i] = false;editIncremental[i] = false; editPoint[i] = false; editIncrementalCurrency[i] = false; closePointMenu(benefit); benefit?.incrementalCurrency? editMaxSelection(benefit, editIncrementalCurrency[i]): null"  class="form-control ">
                                                
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                                <div fxLayout="row" role="cell"
                                  [attr.aria-label]="'Description: ' + benefit.description">
                                  <div fxFlex="100" class="description-container"> <!--(mouseover)="editDesc[i] = true" (mouseout)="editDesc[i] = false"-->
                                    <h5>Description</h5>
                                    <p fxLayout="row" tabindex="0">
                                      <span fxflex="80%" *ngIf="!benefit.editDescription">{{benefit.description}}</span>
                                      <textarea matInput tabindex="0" fxflex="80%" name="descVal" id="descVal_{{i}}"
                                        [(ngModel)]="benefit.description" #descVal="ngModel" *ngIf="benefit.editDescription && validForDeactivation"
                                        class="form-control" [class.tn-focus]='edit-point-edit'></textarea>
                                      <button fxFlex="20%" tabindex="0"  mat-icon-button aria-label="edit description"
                                        title="edit description" class="edit-point"
                                        [ngClass]= "{'edit-point-edit': benefit.editDescription}"                                        
                                        *ngIf="(!program.isNew || (!program.isNew && program.draft)) && validForDeactivation"
                                        (click)="editProgramDescription(benefit)">
                                        <mat-icon>edit</mat-icon>
                                      </button>
                                    </p>
                                  </div>
                                </div>
                            </div>
                            <div *ngIf="!benefit.isNewlyAddedRef"  fxFlex="40"  class="benefit-logic" (focus)="editDisplayLogic[i] = true" (focusout)="editDisplayLogic[i] = false" (mouseover) = "editDisplayLogic[i] = true" (mouseout) = "editDisplayLogic[i] = false" fxLayoutGap="4px" [ngStyle]= "benefit.suppress ? {'pointer-events': 'none'} : ''">
                                <!-- benefit.exclusions?.length > 0 || benefit.prerequisites?.length > 0 ||  -->
                                <div fxLayout="row" fxLayoutAlign="start start">
                                    <img src="../../../../../../assets/images/account_tree.svg" alt="Icon here" role="figure">
                                    <h3 fxFlex="55%"> Benefit Display Logic</h3>                                     
                                    <mat-checkbox 
                                    fxFlex="30%" 
                                    [disabled]="!validForDeactivation ||!( userRole === 'program-admin' && (program.draft || program.isNew))"
                                    (click)="!validForDeactivation ? null : openConsultantModal(benefit)"  
                                    [(ngModel)] = "benefit.consultantBenefitInfo && benefit.consultantBenefitInfo.consultant " 
                                    *ngIf = "!benefit?.advCashOutV2 && !benefit?.advCashOut && !benefit?.incremental && !benefit?.multiplicity && !(benefit?.points === 'Guaranteed') && !benefit?.hybridTempLiving  
                                    " >
                                    Consultant-Only Benefit</mat-checkbox>
                                </div>
                                <div fxLayout = "row" fxLayoutGap="4px" class="scopes">
                                    
                                    <div fxFlex = "48">
                                        <h4><span fxFlex = "80" *ngIf="(benefit.scopes && 
                                            ((benefit.scopes.spouse_partner && benefit.scopes.spouse_partner.spousePartnerRelocating) ||
                                            (benefit.scopes.valid_complex_rules && benefit.scopes.valid_complex_rules.length >0) || 
                                            (benefit.scopes.departure_locations  ) || (benefit.scopes.destination_locations))
                                            )">Restrict this benefit to: </span></h4>
                                        <div  *ngIf="benefit.scopes">
                                            <!---For Transfereeswith Spouse-->
                                            <div *ngIf="benefit.scopes.spouse_partner &&
                                             benefit.scopes.spouse_partner.spousePartnerRelocating"><span>Transferees with Spouse</span></div>
                                            <!---For ComplexRules-->
                                            <div *ngIf="benefit.scopes.valid_complex_rules && benefit.scopes.valid_complex_rules.length > 0">
                                                <span *ngFor="let element of benefit.scopes.valid_complex_rules">
                                                    {{element.name}}<br/>
                                                </span>
                                            </div>
                                            <!---For Departure Locations-->
                                            <div *ngIf="benefit.scopes.departure_locations">
                                                <div *ngIf="benefit.scopes.departure_locations.includes && benefit.scopes.departure_locations.includes.length > 0">
                                                    <span class = "rule">Departure Location Includes  </span><br/>
                                                    <span *ngFor="let element of benefit.scopes.departure_locations.includes | sortBy: 'asc' ; let i = index">{{element }}
                                                        {{i < benefit.scopes.departure_locations.includes?.length -1 ? ', ' : ''}}
                                                        <!-- <span *ngIf="benefit.scopes.departure_locations.includes.length >1">,</span> -->
                                                    </span>
                                                </div>
                                                <div *ngIf="benefit.scopes.departure_locations.excludes && benefit.scopes.departure_locations.excludes.length > 0">
                                                    <span class = "rule">Departure Location Excludes  </span><br/>
                                                    <span *ngFor="let element of benefit.scopes.departure_locations.excludes | sortBy: 'asc' ; let i = index">{{element }}
                                                        {{i < benefit.scopes.departure_locations.excludes?.length -1 ? ', ' : ''}}
                                                        <!-- <span *ngIf="benefit.scopes.departure_locations.excludes.length >1">,</span> -->
                                                    </span>
                                                </div>
                                            </div>
                                            <!---For Destination Locations-->
                                            <div *ngIf="benefit.scopes.destination_locations">
                                                <div *ngIf="benefit.scopes.destination_locations.includes && benefit.scopes.destination_locations.includes.length > 0">
                                                    <span class = "rule">Destination Location Includes  </span><br/>
                                                    <span *ngFor="let element of benefit.scopes.destination_locations.includes | sortBy: 'asc' ; let i = index">{{element }}
                                                        {{i < benefit.scopes.destination_locations.includes?.length -1 ? ', ' : ''}}
                                                        <!-- <span *ngIf="benefit.scopes.destination_locations.includes.length >1">,</span> -->
                                                    </span>
                                                </div>
                                                <div *ngIf="benefit.scopes.destination_locations.excludes && benefit.scopes.destination_locations.excludes.length > 0">
                                                    <span class = "rule">Destination Location Excludes  </span><br/>
                                                    <span *ngFor="let element of benefit.scopes.destination_locations.excludes | sortBy: 'asc' ; let i = index">{{element }}
                                                        {{i < benefit.scopes.destination_locations.excludes?.length -1 ? ', ' : ''}}
                                                        <!-- <span *ngIf="benefit.scopes.destination_locations.excludes.length >1">,</span> -->
                                                    </span>
                                                </div>
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                    <div fxFlex = "47">
                                        <div  *ngIf = "((benefit.exclusions && benefit.exclusions?.length > 0 )|| (benefit.prerequisiteRule && benefit.prerequisiteRule?.length > 0))">
                                            <h4><span fxFlex = "80">If this benefit is selected: </span>
                                            
                                            </h4>
                                            <div *ngIf="benefit.prerequisiteRule && benefit.prerequisiteRule?.length > 0"><span class = "rule">Enable  </span> <span *ngFor="let element of benefit.prerequisiteRule; let i = index">{{element.displayName}}{{i < benefit.prerequisiteRule?.length -1 ? ', ' : '.'}}</span></div>
                                            <div *ngIf="benefit.exclusions && benefit.exclusions?.length > 0"><span class = "rule">Disable  </span> <span *ngFor="let element of benefit.excludeRule; let i = index">{{element}}{{i < benefit.excludeRule.length -1 ? ', ' : '.'}} </span></div>
                                        </div> 
                                    </div>
                                    <div fxFlex = "4"  *ngIf = "userRole === 'program-admin' && validForDeactivation && ( program.isNew || (!program.isNew && program.draft)) && 
                                    ( ((benefit.exclusions && benefit.exclusions?.length > 0) || (benefit.prerequisiteRule && benefit.prerequisiteRule?.length > 0)) || 
                                    (benefit.scopes && 
                                    ((benefit.scopes.spouse_partner && benefit.scopes.spouse_partner.spousePartnerRelocating) ||
                                    (benefit.scopes.valid_complex_rules && benefit.scopes.valid_complex_rules.length >0) || 
                                    (benefit.scopes.departure_locations  ) || (benefit.scopes.destination_locations))
                                    ))">
                                        <button tabindex="0"  mat-icon-button fxFlex = "20"
                                        [ngStyle]= "editDisplayLogic[i] ? {'visibility': 'visible'} : {'visibility': 'hidden'} "
                                       (click) = "openDialog(benefit)">
                                            <mat-icon>edit</mat-icon> 
                                        </button>
                                    </div>
                                </div>
                                <!-- !((benefit.exclusions && benefit.exclusions?.length > 0) || (benefit.prerequisites && benefit.prerequisites?.length > 0)) && -->
                                <button (click)="openDialog(benefit)" class = "edit-display-logic" 
                                *ngIf="(userRole === 'program-admin' && validForDeactivation && ( program.isNew || (!program.isNew && program.draft)) && 
                               ( !((benefit.exclusions && benefit.exclusions?.length > 0) || (benefit.prerequisiteRule && benefit.prerequisiteRule?.length > 0)) && 
                                !(benefit.scopes && 
                                        ((benefit.scopes.spouse_partner && benefit.scopes.spouse_partner.spousePartnerRelocating) ||
                                        (benefit.scopes.valid_complex_rules && benefit.scopes.valid_complex_rules.length >0) || 
                                        (benefit.scopes.departure_locations  ) || (benefit.scopes.destination_locations))
                                        )))"><mat-icon>edit</mat-icon> <span>Edit</span></button>
                                        
                            </div>
                            <div fxFlex="99" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="10px" *ngIf="benefit.isNewlyAddedRef"
                                class="isNewelyAdded2">
                                <div>
                                    <h5 class="titleNewCard">Title</h5>
                                    <h3 class="displayNameNewCard">
                                        <span>{{ benefit.displayName || 'N/A' | titlecase }}</span>
                                    </h3>
                                </div>
                                <div class="notIncludedMessage">
                                    Benefit Not Included
                                </div>
                                <div fxLayout="row">
                                    <mat-icon style="color: green;">error</mat-icon>
                                    <span style="padding: 0.2em;">Benefit recently added</span>
                                </div>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
                <div fxFlex="5" role="cell">
                    <button class="isNewelyAdded1" *ngIf="!benefit.clonedCopy && benefit.isNewlyAddedRef" mat-button matSuffix
                        aria-label="Delete Benefit" role="button" (click)="deleteisNewlyAdded(benefit)">
                        <mat-icon>delete_outline</mat-icon>
                    </button>
                    <mat-card *ngIf="!benefit.isNewlyAddedRef"
                        style="height: 60% !important;width:30% !important ;margin-top: 0% !important;">
                        <button *ngIf="!benefit.clonedCopy && !benefit.isNewlyAddedRef" mat-button matSuffix mat-icon-button
                            aria-label="Delete Benefit"
                            [ngStyle]="!benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null" role="button"
                            (click)="deleteBenefit(benefit)">
                            <mat-icon>{{ benefit.suppress ? 'delete_outline': 'delete' }}</mat-icon>
                        </button>
                        <button mat-button matSuffix mat-icon-button aria-label="Delete Benefit" role="button"
                            *ngIf="benefit.clonedCopy"
                            [ngStyle]="benefit.clonedCopy ? {'padding-right': '14px','padding-top': '0px'} : null"
                            (click)="discardBenefit(benefit, j, i)">
                            <mat-icon>clear</mat-icon>
                        </button>
                        <br>
                        <button *ngIf="!((!(!benefit.clonedCopy && !benefit.suppress)) || !validForDeactivation)  && !benefit.isNewlyAddedRef"  [disabled] = "benefit.parentClonedCopy" mat-button matSuffix mat-icon-button  aria-label="Delete Benefit" [ngStyle]="!benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null" role="button" (click)="cloneBenefit(benefit, focusable)">
                            <!-- <mat-icon>{{ benefit.suppress ? 'delete_outline': 'delete' }}</mat-icon> -->
                            <mat-icon tabindex="0" *ngIf="!benefit.clonedCopy">content_copy</mat-icon>
                        </button>
                        <br>
                        <button [disabled] = "benefit.parentClonedCopy" mat-button matSuffix mat-icon-button  aria-label="Delete Benefit" [ngStyle]="!benefit.clonedCopy ? {'padding-right': '14px','padding-top': '10px'} : null" role="button" (click)="benefitSetting(benefit, index)">
                            <!-- <mat-icon>{{ benefit.suppress ? 'delete_outline': 'delete' }}</mat-icon> -->
                            <mat-icon *ngIf="!benefit.clonedCopy">settings</mat-icon>
                        </button>
                        <button *ngIf="benefit.clonedCopy" mat-button matSuffix mat-icon-button  aria-label="Save Benefit" role="button" style="padding-right: 14px;padding-top: 10px;" (click)="cloneBenefit(benefit)" #focusable>
                            <!-- <mat-icon>{{ benefit.suppress ? 'delete_outline': 'delete' }}</mat-icon> -->
                            <mat-icon>done</mat-icon>
                        </button>
                    </mat-card>
                
                </div>
            </li>
        </ul>
    </ng-container>
</div>

<div class="actions" *ngIf="userRole == 'program-admin'">
    <button [disabled]="!validForDeactivation"  *ngIf="!(program.draft && !program.isNew) && !program.isNew && userRole == 'program-admin'" class="mat-button publish-btn contained-button" (click)="saveCopy()" role="button" aria-label="Copy Program" >Copy Program</button>
    <button 
    style="margin-right:0px;"
    *ngIf="((program.draft && !program.isNew) || program.isNew) && userRole == 'program-admin'"
    [disabled]="(program.draft && draftDisable) || (program.isNew && publishDisable) || !validForDeactivation || publishDisable" 
    mat-button 
    ole="button" 
    aria-label="Save Draft"
     (click)="draftProgram()"
    class="text-button main-btn"
    >Save Draft
   </button>
   <button *ngIf="((program.draft && !program.isNew) || program.isNew) && userRole == 'program-admin'" [disabled]="(program.isNew && publishDisable)|| (userRole !== 'program-admin') || !validForDeactivation"  class="copy-program-menu"   mat-icon-button  [matMenuTriggerFor] = "copyProgramMenu"> <mat-icon class="add-arrow-icon" aria-label="more options">arrow_drop_down</mat-icon></button>
    <mat-menu #copyProgramMenu="matMenu" xPosition="before">
        <button 
        mat-menu-item 
        ole="button" 
        aria-label="Copy Program"
         (click)="saveCopyAndDraft()"
        class="text-button copy-btn"
        >Save & Copy Program</button>
    </mat-menu>
  <button *ngIf="userRole == 'program-admin'" [disabled]="(!program.isNew && !program.draft) || (publishDisable) || !validForDeactivation" class="mat-button publish-btn contained-button" role="button" aria-label="Publish for Use" (click)="publishProgram()">Publish for Use</button>
  <button *ngIf="(userRole === 'program-admin')"[disabled]="!validForDeactivation || (validForDeactivation && program.isNew)" class="mat-button publish-btn contained-button" role="button" aria-label="Deactivate Program" (click)="deactivateProgram()">Deactivate Program</button>
  <button *ngIf="(userRole === 'program-admin'&& program.draft)"[disabled]="!validForDeactivation || (validForDeactivation && program.isNew)" class="mat-button publish-btn contained-button" role="button" aria-label="Deactivate Program" (click)="deleteProgram()">Delete Program</button>
</div>