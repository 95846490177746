import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NeedsAssessmentSharedService } from '../../../../../src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { BenefitService, CategoryMapping } from '../../../public/benefits/services/benefit.service';
import { LocationsService } from '../../../public/needs-assessment/services/locations.service';

@Component({
  selector: 'app-dialer',
  templateUrl: './dialer.component.html',
  styleUrls: ['./dialer.component.scss']
})
export class DialerComponent implements OnInit, OnDestroy {

  observeNeedsAssessment: Subscription;

  showDialer: boolean;
  moveData: any;
  tempCountryList: any;

  benefits:any;

  dialerCountOptions = {
    startVal: 0,
    duration: 5,
    useEasing: true
  };

  // tslint:disable-next-line: no-inferrable-types
  totalPoints: number = 0;

  // tslint:disable-next-line: no-inferrable-types
  totalPointsSelected: number = 0;
  totalPointsConfirmed: number = 0;
  filters: Array<any> = [];

  constructor(
    private needsAssessmentService: NeedsAssessmentSharedService,
    private benefitService: BenefitService,
    private readonly locationService: LocationsService
  ) { }
    
  ngOnInit() {
    let benefits;
      this.benefitService.benefitsData.subscribe((data) => {
        if(data){
          benefits = JSON.parse(JSON.stringify(data));
          this.filterCategory(benefits);

        } else {
          this.benefitService.getBenefits().subscribe(data => {
            if (data) {
              benefits = JSON.parse(JSON.stringify(data));
              this.filterCategory(benefits);	
            }
            });
        }
      }); 
    this.observePoints();
  }

  filterCategory(data) {
    if (data) {
      
    let filters = Object.keys(CategoryMapping).map((item) => {
			return CategoryMapping[item] ;
		});
      data.availableBenefits = this.benefitService.updateCategoryNames(data.availableBenefits);
      data.availableBenefits = data.availableBenefits.filter(benefit => benefit.points !== 'Guaranteed');
      data.selectedBenefits = this.benefitService.updateCategoryNames(data.selectedBenefits);
      data.selectedBenefits = data.selectedBenefits.filter(benefit => benefit.points !== 'Guaranteed');
      data.confirmedBenefits = this.benefitService.updateCategoryNames(data.confirmedBenefits)      
      data.confirmedBenefits = data.confirmedBenefits.filter(benefit => benefit.points !== 'Guaranteed');
      const originalCatagories = filters;
      let availableCatagories = data.availableBenefits.map((item) => item.category);
      let selectedCatagories = data.selectedBenefits.map((item) => item.category);
      let confirmedCatagories = data.confirmedBenefits.map((item) => item.category);
      selectedCatagories = [ ...new Set(selectedCatagories.concat(confirmedCatagories.concat(availableCatagories))) ];
      setTimeout(() => {
        this.filters = filters.filter((ele) => selectedCatagories.includes(ele));
      }, 1000);
      
    } 
  }

  observePoints() {
    this.observeNeedsAssessment = this.needsAssessmentService.transfereeNeedsAssessmentDetails.subscribe(data => {
      this.moveData = data;
      if (!data) { return; }
      if (data.pointDetails) {
        this.totalPoints = data.pointDetails.points || 0;
        this.totalPointsConfirmed = data.pointDetails.confirmedPoints || 0
        this.totalPointsSelected = (data.pointDetails.selectedPoints || 0) + (data.pointDetails.confirmedPoints || 0);
      }
    });

    this.locationService.countryList.subscribe(countryListData => {
          if( countryListData.length > 0 && this.moveData && this.moveData.departureAddr) {
            this.tempCountryList = countryListData.find(ele => ele.countryName === this.moveData.departureAddr.country)
            this.moveData.departureAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
          }
          if(countryListData.length > 0 && this.moveData.destinationAddr) {
            this.tempCountryList = countryListData.find(ele => ele.countryName === this.moveData.destinationAddr.country)
            this.moveData.destinationAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
          }
    });
  }

  ngOnDestroy(): void {
    if (this.observeNeedsAssessment) { this.observeNeedsAssessment.unsubscribe(); }
  }

}
