import { Injectable, Injector } from '@angular/core';
import { TransfereeNeedsAssessment } from '../../../core/models/candidateneeds-assessment.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { TransfereeNeedsAssessmentService } from './transferee-needs-assessment.service';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidationErrors, FormArray } from '@angular/forms';
import { ProfileForm, LocationForm, ContactForm } from '../model';
import { CommonUtilityService } from '../../../../../src/app/core/services/common-utility.service';
import { phoneDetails } from '../../../../../src/app/core/models/phone.model';
import { emailDetails } from '../../../../../src/app/core/models/email.model';
import { Spinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocationsService } from './locations.service';
import { Locations } from '../../../../../src/app/core/models/locations.model';
import { ContactFormValue } from '../components/contact-details/contact-details.component';
import { phoneTypes as staticPhoneTypes, emailTypes as staticEmailTypes } from '../../../../../src/app/core/models/constants';

// tslint:disable-next-line: interface-over-type-literal
type ResetStates = {
  departureStateList: Array<any>,
  destinationStateList: Array<any>
};
// tslint:disable-next-line: interface-over-type-literal
type updatedKeyInterface = {
  group: 'departureAddr' | 'destinationAddr',
  type: 'city' | 'state'
};

@Injectable({
  providedIn: 'root'
})
export class NeedsAssessmentSharedService {

  /** Instance of BehaviorSubject of type TransfereeNeedsAssessment */
  transfereeNeedsAssessmentDetails = new BehaviorSubject<TransfereeNeedsAssessment>(null);

  /** TransfereeNeedsAssessment as Promise */
  needsAssessmentDetails;
  candidateDetails = this.transfereeNeedsAssessmentDetails.asObservable();
  moveDetails = new BehaviorSubject<any>(null);
  /**
  * Base Constructor
  */
  constructor(
    private readonly transfereeSrvc: TransfereeNeedsAssessmentService,
    private injector: Injector
  ) { }

  /**
  * Function to Update the TransfereeNeedsAssessment subject
  * @param data updated TransfereeNeedsAssessment
  */
  updateCandidateNeedsAssesment(data: TransfereeNeedsAssessment) {
    this.transfereeNeedsAssessmentDetails.next(data);
  }

  /**
  * Function to Get transferee needs-Assessment and update Shared-Service
  */
  async getCandidateNeedsAssessmentDetails() {
    let transfereeData = this.transfereeNeedsAssessmentDetails.getValue();
    const selectedPoints = transfereeData.pointDetails.selectedPoints;
    transfereeData.benefitDetails.confirmedBenefits = (transfereeData.pointDetails.confirmedPoints) ? (transfereeData.pointDetails.confirmedPoints + selectedPoints) : selectedPoints;
    transfereeData.pointDetails.confirmedPoints = (transfereeData.pointDetails.confirmedPoints) ? (transfereeData.pointDetails.confirmedPoints + selectedPoints) : selectedPoints;
    transfereeData.pointDetails.selectedPoints = 0;
    this.transfereeNeedsAssessmentDetails.next(transfereeData);
    return;
    if (!this.transfereeNeedsAssessmentDetails.getValue()) {
      const response = await this.transfereeSrvc.getTransfereeNeedsAssessment().toPromise();
      this.updateCandidateNeedsAssesment(response);
      return this.needsAssessmentDetails = response;
    } else {
      return this.needsAssessmentDetails = this.transfereeNeedsAssessmentDetails.getValue();
    }
  }

  /**
   * Generate Profile Personal Form
   **/
  generateProfileForm(profileFormData: ProfileForm): FormGroup {
    const isNull = this.injector.get(CommonUtilityService).isNullOrUndefinedSafeCheck;
    const fb = this.injector.get(FormBuilder);
    const profileForm: FormGroup = fb.group({
      nameDetails: fb.group({
        title: [isNull(profileFormData.nameDetails, 'title'), Validators.required, this.noEmptySpaceValidator],
        firstName: [isNull(profileFormData.nameDetails, 'firstName'), [Validators.required,
          Validators.minLength(1), Validators.maxLength(50)], this.noEmptySpaceValidator],
        lastName: [isNull(profileFormData.nameDetails, 'lastName'), [Validators.required,
          Validators.minLength(2), Validators.maxLength(50)], this.noEmptySpaceValidator]
      }),
      dependents: fb.group({
        totalNumberOfRelocatingMembers: [isNull(profileFormData, 'totalNumberOfRelocatingMembers'), [Validators.required,
          Validators.max(100), Validators.min(1)]],
        spousePartnerRelocating: [isNull(profileFormData, 'spousePartnerRelocating') !== '' ?
        profileFormData.spousePartnerRelocating : false],
        childrenDependentsRelocating: [isNull(profileFormData, 'childrenDependentsRelocating') !== '' ?
        profileFormData.childrenDependentsRelocating : false]
      }, { validators: this.customDependentValidator })
    });
    return profileForm;
  }

  /**
   * Generate Location Form
   * @param locationFormData formdata
   */
  generateLocationForm(locationFormData: LocationForm): FormGroup {
    const isNull = this.injector.get(CommonUtilityService).isNullOrUndefinedSafeCheck;
    const fb = this.injector.get(FormBuilder);
    const locationForm: FormGroup = fb.group({
      departureAddr: fb.group({
        streetLine1: [isNull(locationFormData.departureAddr, 'streetLine1')],
        city: [isNull(locationFormData.departureAddr, 'city'), [Validators.required]],
        state: [isNull(locationFormData.departureAddr, 'state'), [Validators.required]],
        country: [isNull(locationFormData.departureAddr, 'country'), [Validators.required]],
        postalCode: [isNull(locationFormData.departureAddr, 'postalCode'), [Validators.required,
          Validators.minLength(1), Validators.maxLength(15)]]
      }), destinationAddr: fb.group({
        streetLine1: [isNull(locationFormData.destinationAddr, 'streetLine1')],
        city: [isNull(locationFormData.destinationAddr, 'city'), [Validators.required]],
        state: [isNull(locationFormData.destinationAddr, 'state'), [Validators.required]],
        country: [isNull(locationFormData.destinationAddr, 'country'), [Validators.required]],
        postalCode: [isNull(locationFormData.destinationAddr, 'postalCode'), [Validators.required,
          Validators.minLength(1), Validators.maxLength(15)]]
      })
    });
    return locationForm;
  }

  /**
   * Generates Contact Form with phone and email details
   * @param contactFormData
   */
  generateContactForm(contactFormData: ContactForm): FormGroup {
    const fb = this.injector.get(FormBuilder);
    const contactForm = fb.group({
      phoneDetails: fb.array(this._generatePhoneGroup(contactFormData.phoneDetailsList)),
      emailDetails: fb.array(this._generateEmailGroup(contactFormData.emailDetailsList))
    });
    return contactForm;
  }

  /**
   * Generates phone array for Contact Form
   * @param phoneDetails phonedetails data
   */
  private _generatePhoneGroup(phoneDetails: Array<phoneDetails>): Array<FormGroup> {
    const fb = this.injector.get(FormBuilder);
    const isNull = this.injector.get(CommonUtilityService).isNullOrUndefinedSafeCheck;
    if (phoneDetails) {

      phoneDetails = [...phoneDetails];
      phoneDetails.forEach((phoneDetail, index) => {
        let phoneType = null;
        phoneDetail.textingAvailable ? phoneType = 'Mobile' : null;

        if (!phoneDetail.textingAvailable) {
          (phoneDetail.usageType == 'business' && phoneDetail.locationType == 'departure') ? phoneType = 'Departure Business' : null;
          (phoneDetail.usageType == 'business' && phoneDetail.locationType == 'destination') ? phoneType = 'Destination Business' : null;
          (phoneDetail.usageType == 'personal' && phoneDetail.locationType == 'departure') ? phoneType = 'Departure Residence' : null;
          (phoneDetail.usageType == 'personal' && phoneDetail.locationType == 'destination') ? phoneType = 'Destination Residence' : null;
        }

        delete phoneDetail.usageType;

        // Extra check to ensure there is only one occurence of type even if backend send same.
        if (index !== 0) {
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          (typeof phoneDetails.find(phone => phone.type === phoneType) == 'undefined') ? phoneDetail.type = phoneType : null;
        } else {
          phoneDetail.type = phoneType;
        }
      });

      const phoneDetailsArray: Array<FormGroup> = phoneDetails.map(phoneDetail => {
        return fb.group({
          _id: [isNull(phoneDetail, '_id')],
          primary: [isNull(phoneDetail, 'primary')],
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          type: [{ value: isNull(phoneDetail, 'type'), disabled: true }, [Validators.required]],
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          phoneDialCode: [isNull(phoneDetail, 'phoneDialCode'), [Validators.required]],
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          phoneNumber: [isNull(phoneDetail, 'phoneNumber'), [Validators.required, Validators.minLength(7),
            Validators.maxLength(18), Validators.min(1000000), Validators.max(999999999999999999),
            Validators.pattern('^[0-9]*$')]]
        });
      });

      // Extra Check
      phoneDetailsArray.map(phone => {
        if (!phone.getRawValue().type) { phone.enable({ onlySelf: true, emitEvent: false }); }
      });

      phoneDetailsArray;

      return phoneDetailsArray;
    }
    //  else {
    //   const phoneDetailsArray = new Array(fb.group({
    //     _id: [''],
    //     primary: [''],
    //     type: ['', []],
    //     phoneDialCode: ['', []],
    //     phoneNumber: ['', [Validators.minLength(7), Validators.maxLength(18),
    //       Validators.min(1000000), Validators.max(999999999999999999), Validators.pattern('^[0-9]*$')]]
    //   }
    //     // , {
    //     //     validators: this.requiredPhoneValidator
    //     // }
    //   ));
    //   return phoneDetailsArray;
    // }
  }

  /**
   * Generates email array for contact details form
   * @param emailDetails email list
   */
  private _generateEmailGroup(emailDetails: Array<emailDetails>): Array<FormGroup> {
    const fb = this.injector.get(FormBuilder);
    const isNull = this.injector.get(CommonUtilityService).isNullOrUndefinedSafeCheck;
    if (emailDetails) {
      const emailDetailsArray = emailDetails.map(emailDetail => {
        return fb.group({
          _id: [isNull(emailDetail, '_id')],
          primary: [isNull(emailDetail, 'primary')],
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          emailAddress: [isNull(emailDetail, 'emailAddress'), [Validators.required,
            Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]],
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          usageType: [isNull(emailDetail, 'usageType'), [Validators.required]],
        });
      });

      // Disabled if type is business...
      emailDetailsArray.map(email => {
        if (email.getRawValue().usageType) { email.controls.usageType.disable(); }
      });

      return emailDetailsArray;
    }
    // else {
    //   const emailDetailsArray = new Array(fb.group({
    //     _id: [''],
    //     primary: [''],
    //     emailAddress: ['', [, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+[a-zA-Z0-9-]*\\.[a-zA-Z]{2,3}$')]],
    //     usageType: [''],
    //   }
    //     // , {
    //     //     validators: this.requiredEmailValidator
    //     // }
    //   ));
    //   return emailDetailsArray;
    // }
  }

  private noEmptySpaceValidator(input: AbstractControl): ValidationErrors | null {
    return new Promise((resolve) => {
      if (input.value && input.value.trim() === '') {
        resolve({ required: true });
      } else {
        resolve(null);
      }
    });
  }

  /**
   * Validate spouse/dependents
   * @param locationFormData formGroup
   */
  customDependentValidator(group: FormGroup) {
    const relocatingMembers = group.controls['totalNumberOfRelocatingMembers'].value;
    const spousePartner = group.controls['spousePartnerRelocating'].value;
    const childrenDependent = group.controls['childrenDependentsRelocating'].value;
    if (relocatingMembers <= 1 || (relocatingMembers == 2 && childrenDependent === true)) {
      group.controls['spousePartnerRelocating'].patchValue(false, {
        onlySelf: true,
        emitEvent: false
      });
    }
    if (relocatingMembers <= 1 || (relocatingMembers == 2 && spousePartner === true)) {
      group.controls['childrenDependentsRelocating'].patchValue(false, {
        onlySelf: true,
        emitEvent: false
      });
    }
    /** Form Validation */
    if (relocatingMembers > 1 && (spousePartner != true && childrenDependent != true)) {
      return {
        'customDependentValidator': true
      };
    } else {
      return null;
    }
  }

  // /**
  //  * Validate if all values are entered
  //  * @param phoneformGroup formGroup
  //  */
  // requiredPhoneValidator(group: FormGroup) {
  //   const phoneDialCode = group.controls['phoneDialCode'].value;
  //   const phoneNumber = group.controls['phoneNumber'].value;
  //   const type = group.controls['type'].value;
  //   if (((phoneDialCode && phoneNumber && type) || (!phoneDialCode && !phoneNumber && !type))) {
  //     return null;
  //   } else {
  //     return {
  //       'requiredPhoneValidator': true
  //     };
  //   }
  // }

  // /**
  //  * Validate if all values are entered
  //  * @param emailformGroup formGroup
  //  */
  // requiredEmailValidator(group: FormGroup) {
  //   const emailAddress = group.controls['emailAddress'].value;
  //   const usageType = group.controls['usageType'].value;
  //   if ((emailAddress && usageType) || (!emailAddress && !usageType)) {
  //     return null;
  //   } else {
  //     return {
  //       'requiredEmailValidator': true
  //     };
  //   }
  // }

  /**
	 * State/Province required for locations in the United States of America and Canada only
	 */
  modifyLocationFormValidations(addressForm: FormGroup, departureCountryCode: string, destinationCountryCode: string) {
    if (!['US', 'CA'].includes(departureCountryCode)) {
      (addressForm.controls.departureAddr as FormGroup).controls.state.clearValidators();
      (addressForm.controls.departureAddr as FormGroup).controls.postalCode.clearValidators();
      (addressForm.controls.departureAddr as FormGroup).controls.postalCode.setValidators([Validators.minLength(1),
        Validators.maxLength(15)]);

    }
    if (!['US', 'CA'].includes(destinationCountryCode)) {
      (addressForm.controls.destinationAddr as FormGroup).controls.state.clearValidators();
      (addressForm.controls.destinationAddr as FormGroup).controls.postalCode.clearValidators();
      (addressForm.controls.destinationAddr as FormGroup).controls.postalCode.setValidators([Validators.minLength(1),
        Validators.maxLength(15)]);
    }
  }

  /**
	 * Will determine which field has been updated in Address Form (for Address and Review Component).
	 * @param newFormValue AddressForm new values
	 * @param oldFormValue AddressForm old values
	 */
  findUpdatedKey(newFormValue: LocationForm, oldFormValue: LocationForm): updatedKeyInterface {
    let updatedKey = null;

    Object.keys(newFormValue.departureAddr).map(key => {
      if (newFormValue.departureAddr[key] !== oldFormValue.departureAddr[key]) {
        // tslint:disable-next-line: rxjs-no-unsafe-scope
        updatedKey = key;
      }
    });

    if (updatedKey) { return { group: 'departureAddr', type: updatedKey }; }

    Object.keys(newFormValue.destinationAddr).map(key => {
      if (newFormValue.destinationAddr[key] !== oldFormValue.destinationAddr[key]) {
        // tslint:disable-next-line: rxjs-no-unsafe-scope
        updatedKey = key;
      }
    });

    if (updatedKey) { return { group: 'destinationAddr', type: updatedKey }; }

    return null;
  }

  /**
   * Shared function for Location Search based on city or state...
   * @param addressForm
   * @param updatedKey
   * @param updatedLocationFormValue
   * @param departureCountryCode
   * @param destinationCountryCode
   * @param spinner
   * @param locationService
   */
  locationSearch(addressForm: FormGroup, updatedKey: updatedKeyInterface, updatedLocationFormValue: any,
    departureCountryCode: string, destinationCountryCode: string): Observable<Locations> {

    // tslint:disable-next-line: deprecation
    return Observable.create(_observer => {

      const spinner = this.injector.get(NgxSpinnerService);
      const locationService = this.injector.get(LocationsService);

      // if empty then don't run...
      if (!updatedLocationFormValue[updatedKey.group][updatedKey.type]) { return; }

      const searchType = updatedKey.type === 'city' ? 'cities' : 'states';
      const searchText: string = updatedLocationFormValue[updatedKey.group as any][updatedKey.type];

      // Search only if search text length is greater than 3;
      if (searchText.length < 3) { return; }

      spinner.show();

      locationService.locationSearch(searchType, updatedLocationFormValue[updatedKey.group as any][updatedKey.type], {
        country: updatedKey.group === 'departureAddr' ? departureCountryCode : destinationCountryCode,
        cityLongName: updatedKey.group == 'departureAddr' ? updatedLocationFormValue.departureAddr.city :
        updatedLocationFormValue.destinationAddr.city,
        cityShortName: updatedKey.group == 'departureAddr' ? updatedLocationFormValue.departureAddr.city :
        updatedLocationFormValue.destinationAddr.city
      }).subscribe(data => {

        spinner.hide();

        // Empty response..
        if (!data) {
          if (updatedKey.group == 'departureAddr') {
            (updatedKey.type == 'city') ?
              (addressForm.controls.departureAddr as FormGroup).controls.city.setValue('') :
              (addressForm.controls.departureAddr as FormGroup).controls.state.setValue('');
          } else { // destinationAddr
            (updatedKey.type == 'city') ?
              (addressForm.controls.destinationAddr as FormGroup).controls.city.setValue('') :
              (addressForm.controls.destinationAddr as FormGroup).controls.state.setValue('');
          }
          addressForm.markAllAsTouched();

          _observer.next(null);
          _observer.complete();
        }

        console.log('Response ', data);

        _observer.next(data);
        _observer.complete();

      }, () => {

        _observer.next(null);
        _observer.complete();

      }, () => spinner.hide());


    });
  }

  /** Create Phone Email Sub List used by needs assessment and add family information */
  initPhoneEmailList(contactFormValue: ContactFormValue, phoneTypesList: Array<Array<string>>,
    emailTypeList: Array<Array<string>>): { phoneTypes: Array<Array<string>>, emailTypes: Array<Array<string>> } {

    const usedPhoneTypes = [];
    const usedEmailTypes = [];

    contactFormValue.phoneDetails.forEach((phone, index) => {
      phoneTypesList[index] = [...staticPhoneTypes];
      if (index == 0) {
        usedPhoneTypes.push(phone.type);
      } else {
        phoneTypesList[index] = phoneTypesList[index].filter(phoneType => !usedPhoneTypes.includes(phoneType));
      }
    });

    contactFormValue.emailDetails.forEach((email, index) => {
      emailTypeList[index] = [...staticEmailTypes];
      if (index == 0) {
        usedEmailTypes.push(email.usageType);
      } else {
        emailTypeList[index] = emailTypeList[index].filter(emailType => !usedEmailTypes.includes(emailType));
      }
    });

    return { phoneTypes: phoneTypesList, emailTypes: emailTypeList };

  }

  /**
   * Shared function for is phone value entred is valid or not
   * @param event any
   * @param index Index of the phone array
   * @param contactForm reference of the contact form.
   */
  changePhone(event: any, index: number, contactForm: FormGroup) {
    let phoneValue = event.target.value;
    phoneValue = phoneValue.replace(/[^0-9]*/g, '');
    if (!isNaN(parseInt(phoneValue))) {
      (contactForm.controls.phoneDetails as FormArray).controls[index].patchValue({
        phoneNumber: parseInt(phoneValue)
      });
    } else {
      contactForm.setErrors({ invalid: true });
      contactForm.controls.phoneDetails.setErrors({ invalid: true });
    }

    // Extra check for Validation check. Min and Max check
    if (phoneValue.length > 18 || phoneValue.length < 7) {
      event.path[4].classList.add('mat-form-field-invalid');
      event.path[4].classList.add('ng-invalid');
      contactForm.setErrors({ invalid: true });
      contactForm.controls.phoneDetails.setErrors({ invalid: true });
    } else {
      event.path[4].classList.remove('mat-form-field-invalid');
      event.path[4].classList.remove('ng-invalid');
      if (isNaN(parseInt(phoneValue))) {
        contactForm.setErrors(null);
        contactForm.controls.phoneDetails.setErrors(null);
      }
    }
  }

  /**
   * Converting Phone Details List for API to understand as there is no .type key used by needs assessment and add family information
   * @param phoneDetails List of Phones
   */
  updatePhoneType(phoneDetails: Array<phoneDetails>): Array<phoneDetails> {
    phoneDetails.forEach(phoneDetail => {

      phoneDetail.type === 'Mobile' ? (phoneDetail.usageType = 'personal', phoneDetail.textingAvailable = true) : false;
      phoneDetail.type == 'Departure Business' ? (phoneDetail.usageType = 'business', phoneDetail.locationType = 'departure') : null;
      phoneDetail.type == 'Destination Business' ? (phoneDetail.usageType = 'business', phoneDetail.locationType = 'destination') : null;
      phoneDetail.type == 'Departure Residence' ? (phoneDetail.usageType = 'personal', phoneDetail.locationType = 'departure') : null;
      phoneDetail.type == 'Destination Residence' ? (phoneDetail.usageType = 'personal', phoneDetail.locationType = 'destination') : null;
      delete phoneDetail['type'];

      // Convert phone number to string
      phoneDetail.phoneNumber = (phoneDetail.phoneNumber).toString();

      // Delete Phone Id if its newly added
      (phoneDetail._id === '') ? delete phoneDetail._id : null;
    });

    return phoneDetails;
  }

   /**
   * Delete empty Id's for newly created emails
   * @param emailDetails List of emails
   */
  updateEmailDetails(emailDetails: Array<emailDetails>): Array<emailDetails> {
    emailDetails.forEach(emailDetail => {
      // Delete email Id if its newly added
      (emailDetail._id === '') ? delete emailDetail._id : null;
    });
    return emailDetails;
  }


  /**
   * Add row to Mobile and Email
   * @param type location a new row to be added
   * @param contactForm Contact Form
   * @param newIndex index of the location
   * @param phoneTypesList Phone List
   * @param emailTypesList Email List
   */
  addNewPhoneEmailRow(type: 'phone' | 'email', contactForm: FormGroup, newIndex: number, phoneTypesList: any, emailTypesList: any) {
    const fb = this.injector.get(FormBuilder);
    if (type == 'phone') {

      // need to find used phone types and filter from existing.
      const existingPhoneTypes: Array<string> = ((contactForm.controls.phoneDetails as FormArray).
      getRawValue() as any).map(phone => phone.type);

      // updating phoneTypes
      phoneTypesList[newIndex] = [...staticPhoneTypes];
      phoneTypesList[newIndex] = phoneTypesList[newIndex].filter(phone => !existingPhoneTypes.includes(phone));

      (contactForm.controls.phoneDetails as FormArray).push(fb.group({
        primary: false,
        type: [null, [Validators.required]],
        phoneDialCode: [null, [Validators.required]],
        phoneNumber: [null, [Validators.required, Validators.minLength(7), Validators.maxLength(18),
          Validators.min(1000000), Validators.max(999999999999999999), Validators.pattern('^[0-9]*$')]]
      }));
    } else {

      // need to find used phone types and filter from existing.
      const existingEmailTypes: Array<string> = ((contactForm.controls.emailDetails as FormArray).
      getRawValue() as any).map(email => email.usageType);

      // updating emailTypes
      emailTypesList[newIndex] = [...staticEmailTypes];
      emailTypesList[newIndex] = emailTypesList[newIndex].filter(email => !existingEmailTypes.includes(email));

      (contactForm.controls.emailDetails as FormArray).push(fb.group({
        primary: false,
        emailAddress: [null, [Validators.required, Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]],
        usageType: [null, [Validators.required]],
      }));
    }
  }

  /**
   * Remove row from Mobile or Email and insert the deleted email/phone type to the list of email/phone
   * used by needs assessment and add family information
   * @param type location of the row
   * @param index index of the location
   * @param contactForm Contact Form
   * @param phoneTypes Phone List
   * @param emailTypes Email List
   */
  deletePhoneEmailRow(type: 'phone' | 'email', index: number, contactForm: FormGroup, phoneTypes: any,
  emailTypes: any): Array<Array<string>> {
    if (type === 'phone') {
      const missingPhoneType: string = (contactForm.getRawValue() as ContactFormValue).phoneDetails[index].type;
      if (missingPhoneType) {
        phoneTypes = phoneTypes.map(phoneType => {
          phoneType.push(missingPhoneType);
          return [...new Set(phoneType)];
        });
      }
      (contactForm.controls.phoneDetails as FormArray).removeAt(index);
      return phoneTypes;
    } else {
      const missingEmailType: string = (contactForm.getRawValue() as ContactFormValue).emailDetails[index].usageType;
      if (missingEmailType) {
        emailTypes = emailTypes.map(emailType => {
          emailType.push(missingEmailType);
          return [...new Set(emailType)];
        });
      }
      (contactForm.controls.emailDetails as FormArray).removeAt(index);
      return emailTypes;
    }
  }

  /**
   * Will update the selected benefit count with new point number
   * @param numberToAdd Amount of point to be added
   * @param type decides whether its a add or subtract operation
   */
  updateBenefitCount(numberToAdd: number, type: 'add' | 'subtract' | 'equal') {
    const needsAssessment = {...this.transfereeNeedsAssessmentDetails.getValue()};
    if (needsAssessment.pointDetails) {
      if (type === 'add') {
        needsAssessment.pointDetails.selectedPoints = (needsAssessment.pointDetails.selectedPoints || 0 ) + numberToAdd;
      } else if (type === 'subtract') {
        needsAssessment.pointDetails.selectedPoints = needsAssessment.pointDetails.selectedPoints - numberToAdd;
      } else {
        needsAssessment.pointDetails.selectedPoints = numberToAdd;
      }
    } else {
        needsAssessment.pointDetails = {
        selectedPoints: numberToAdd
      };
    }

    // Update needsassessment again.
    this.transfereeNeedsAssessmentDetails.next(needsAssessment);
  }

}

