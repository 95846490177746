import { Component, OnInit, Inject, ViewChild} from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { LocationsService } from '../../administration/services/locations.service';
import { Country } from '../../../core/models/country.model';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { Observable } from 'rxjs';
import { ClientContactRegistration, phone } from '../../../core/models/client-contact-registration.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import {map, startWith} from 'rxjs/operators';
import { ClientContactService } from '../../../core/services/client-contact.service';
import { DatePipe } from '@angular/common';

/** response for sendInvite to client contact service call */
export const sendInviteResponse = `Invitation Sent Successfully`;
/** response for resend invite to client contact service call */
export const resendInviteResponse = `Invitation Resent Successfully`;
/** response for client contact save draft */
export const saveDraftResponse = `Saved As Draft Successfully`;
/** response for client contact save updates */
export const saveUpdatesResponse = `Saved Updates Successfully`;

@Component({
  selector: 'app-client-contact-details',
  templateUrl: './client-contact-details.component.html',
  styleUrls: ['./client-contact-details.component.scss']
})

export class ClientContactDetailsComponent implements OnInit {

  /**Form group name */
  updateClientContactForm: FormGroup;
  /**Countries retrieved from service */
  countryList: Array < Country > ;
  /** Dynamic text to be displayed in form title */
  formTitle: string;
  /** existing client contact information */
  editClientContactRegistration: ClientContactRegistration = {}  as ClientContactRegistration;
  /** List of filtered options by autocomplete phone code field */
  filteredOptions: Observable < Country[] > ;

  @ViewChild('autoCompleteInput', {
    read: MatAutocompleteTrigger,
    static: true
  }) autoComplete: MatAutocompleteTrigger;

  /**
   * 
   * @param formBuilder FormBuilder
   * @param locationService LocationService
   * @param dialogRef DialogRef
   * @param data Input from list of client contacts
   * @param spinner Spinner
   * @param clientContactService Client Contact Service
   * @param dialog Dialog
   * @param toastrService Toastr
   * @param live Live
   */
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly locationService: LocationsService,
    public dialogRef: MatDialogRef < ClientContactDetailsComponent > ,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly spinner: NgxSpinnerService,
    private readonly clientContactService: ClientContactService,
    public dialog: MatDialog,
    private readonly toastrService: ToastrService,
    public live: LiveAnnouncer,
    private datePipe: DatePipe
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.spinner.show();
    this.editClientContactRegistration = this.data.client;
    this.updateClientContactForm = this.populateForm(this.editClientContactRegistration, this.data.company);
    this.formTitle = 'Edit Client Contact';
    // populate phone dial code dropdown
    this.locationService.countryList.subscribe(countryList => {
      this.filteredOptions = this.updateClientContactForm.get('phoneDialingCode').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryList = countryList;
      }
    });
    this.spinner.hide();
  }

  /**
   * Filter in dropdown by values
   * @param value value in autocomplete
   */
  private _filter(value): Country[] {
    if (value) {
      const filterValue = typeof value === 'number' ? value : value.toLowerCase();
      const countryList = this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
      return countryList;
    } else {
      return this.countryList;
    }
  }

  /**
   * Will instantiate the form and populate values to form with existing data
   * @param editData contains existing client contact information
   */
  populateForm(editData, company): FormGroup {
    const check = this.isNullCheck;
    const addForm: FormGroup = this.formBuilder.group({
      nameDetails: this.formBuilder.group({
        firstName: [editData ? check(editData, 'firstName') : ''],
        lastName: [editData ? check(editData, 'lastName') : '']
      }),
      email: [editData && editData.email && editData.email.length > 0 ? check(editData.email[0], 'emailAddress') : '',
        [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]
      ],
      phoneDialingCode: [editData && editData.phone && editData.phone.length > 0 ? check(editData.phone[0], 'phoneDialingCode') : '', [Validators.required, Validators.pattern('^[0-9]*$')]],
      phoneNumber: [editData && editData.phone && editData.phone.length > 0 ? check(editData.phone[0], 'phoneNumber') : '',
        [Validators.required, Validators.maxLength(18), Validators.minLength(7),
          Validators.pattern('^[0-9]*$'), Validators.max(999999999999999999)
        ]
      ],
      role: [editData && editData.role ? check(editData, 'role') : '', [Validators.required]],
      company: [company ? company : ''],
    });

    if (editData && editData.status && editData.status === 'Withdrawn') {
      Object.keys(addForm.controls).forEach(group => addForm.controls[group].disable());
    }
    return addForm;
  }

  /**
   * Custom error messages for Email, phone number and phone dial code to verify special character or empty errors
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(formControl, fieldName) {
    if (this.updateClientContactForm.get(formControl).hasError('required')) {
      return ('You must enter ' + fieldName);
    } else if (this.updateClientContactForm.get(formControl).hasError('pattern') || this.updateClientContactForm.get(formControl).hasError('minlength') ||
      this.updateClientContactForm.get(formControl).hasError('maxlength') || this.updateClientContactForm.get(formControl).hasError('email')) {
      return ('You must enter a valid ' + fieldName);
    } else {
      return '';
    }


  }

  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  isNullCheck(obj: Object, key: string) {
    try {
      return ((obj[key] || obj[key] === false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }

  displayFn(country: any): string {
    return country ? '+ ' + country : '';
  }

  /**
   * Click on Save Draft button, check if the values of the fields provided are valid except required validation
   * @param formGroup FormGroup
   */
  isFormValidToSaveDraft(formGroup: FormGroup) {
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid && !controls[name].hasError('required')) {
        this.updateClientContactForm.controls[name].markAsTouched();
        return false;
      }
    }
    return true;
  }
  /**
   * To create request object to be sent to save draft/updates and send/resend invite
   * @param updatedFormDetails updated form details
   */
  createRequestObject(updatedFormDetails) {
    const {
      atlasStatus,
      fullName,
      ...saveRequestObj
    } = this.editClientContactRegistration;

    updatedFormDetails.email ? saveRequestObj.email[0].emailAddress = updatedFormDetails.email : null;
    if (updatedFormDetails.phoneNumber) {
      const phone: phone = {};
      updatedFormDetails.phoneNumber ? phone['phoneNumber'] = updatedFormDetails.phoneNumber : null;
      updatedFormDetails.phoneDialingCode ? phone['phoneDialingCode'] = updatedFormDetails.phoneDialingCode : null;
      saveRequestObj.phone && saveRequestObj.phone[0] && saveRequestObj.phone[0].phoneNumberDesc ? phone['phoneNumberDesc'] = saveRequestObj.phone && saveRequestObj.phone[0] && saveRequestObj.phone[0].phoneNumberDesc : null;
      saveRequestObj.phone && saveRequestObj.phone[0] ? saveRequestObj.phone[0] = phone : saveRequestObj.phone = [phone];
    }

    if (saveRequestObj.role !== updatedFormDetails.role) {
      saveRequestObj.role = updatedFormDetails.role;
      saveRequestObj.roleChanged = true;
    } else {
      saveRequestObj.roleChanged = false;
    }
    
    return saveRequestObj;
  }

  /**
   * To Save client contact Details as Draft and save updates
   * @param actionType Type of action
   */
  saveClientContactDetails(actionType: 'draft' | 'update') {
    this.spinner.show();
    if (this.isFormValidToSaveDraft(this.updateClientContactForm)) {
      const updateClientContactDetails = this.createRequestObject(this.updateClientContactForm.value);
      this.clientContactService.updateClientContact(updateClientContactDetails).subscribe(
        (response) => {
          this.spinner.hide();
          if (response) {
            this.flashAndCloseDialog(actionType === 'draft' ? saveDraftResponse : saveUpdatesResponse, true, updateClientContactDetails);
            this.spinner.hide();
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }else{
      this.spinner.hide();
    }

  }

  /**
   * To send and resend invite to client contact
   */
  sendResendInvite() {
    if (this.updateClientContactForm.valid) {
      this.spinner.show();
      const updateClientContactDetails = this.createRequestObject(this.updateClientContactForm.value);
      updateClientContactDetails.sendInvite = this.editClientContactRegistration.status == 'Invitation Sent' ? false : true;
      this.clientContactService.sendInviteToClientContact(updateClientContactDetails).subscribe(
        (response) => {
          this.spinner.hide();
          if (response) {
            let response;
            if (updateClientContactDetails.status === 'Invitation Not Sent') {
              updateClientContactDetails.status = 'Invitation Sent';
              updateClientContactDetails.statusDate = this.datePipe.transform(new Date(), "yyyy-MM-dd")
              response = sendInviteResponse;
            } else {
              response = resendInviteResponse;
            }
            this.flashAndCloseDialog(response, true, updateClientContactDetails);
          }
        },
        err => {
          this.spinner.hide();
        }
      );
    }
  }

  /**
   * To flash toastr with status message and close dialog on success
   *@param message string to be displayed in toastr
   *@param success success/failure
   */
  flashAndCloseDialog(message: string, success, requestObj) {
    if (success) {
      this.toastrService.info(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
      this.live.announce(message);
      this.dialogRef.close(requestObj);
    } else {
      this.toastrService.error(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
    }
  }

  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(null)
  }

}
