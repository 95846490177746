
<div class="providerDetails" tabindex="0" *ngIf="providers$ | async; let providers">
  <div *ngFor="let provider of providers.suppliers | filter:searchProvider">
    <mat-divider></mat-divider>
    <div class="providerTitleBlk" fxflex fxLayout="row" fxFill>
      <div fxFlex.gt-xs="70" fxFlex.lt-sm="50" >
        <span class="hidemefromUsers" id="supplierName"[innerHTML]="provider.supplierName |searchHighlight :searchProvider">{{provider.supplierName.split('##')[0]}}</span>
          <span id="supplierName" class="subTitle"[innerHTML]="provider.supplierName |searchHighlight :searchProvider ">{{provider.supplierName.split('##')[0]}}</span>
      </div>
      <div class="textAlignEnd" fxFlex.gt-xs="30" fxFlex.lt-sm="50">
        <span aria-label="Total Paid" class="smallText">Total Paid:</span>
        <span [attr.aria-label]="numbersService.formatForCurrency(provider.transAmt, provider.transactionCurrCode)">{{numbersService.formatForCurrency(provider.transAmt, provider.transactionCurrCode)}}</span>
      </div>
    </div>
    <div *ngFor="let invoices of provider.invoices; let i = index">
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <div class="panelHeader" fxflex fxLayout="row" fxFill>
              <div fxFlex.gt-sm="50" fxFlex.lt-md="50">
                <span aria-label="Invoice">Invoice</span>
                <span attr.aria-label="{{'#' + invoices.apInvId}}" [innerHTML]="invoices.apInvId |searchHighlight:searchProvider">#{{invoices.apInvId}}</span>
              </div>
          </div>
        </mat-expansion-panel-header>
        <div class="providerSpacing" fxflex fxLayout="row" fxFill>
          <div fxFlex="30" tabindex="0" fxFlex.lt-lg="50">
            <span aria-label="Date of Invoice" class="smallText">Date of Invoice:</span>
            <span [attr.aria-label]="dateConversionService.convertToDisplayDate(invoices.apInvDt)">{{dateConversionService.convertToDisplayDate(invoices.apInvDt)}}</span>
          </div>
          <div fxFlex="70" tabindex="0" fxFlex.lt-lg="50">
            <span aria-label="Date Paid" class="smallText">Date Paid:</span>
            <span [attr.aria-label]="dateConversionService.convertToDisplayDate(invoices.apPaymentDocDate)">{{dateConversionService.convertToDisplayDate(invoices.apPaymentDocDate)}}</span>
          </div>
        </div>
        <div *ngFor="let list of invoices.list; let j = index">
          <div *ngIf="j != 0">
            <mat-divider class="matDivision"></mat-divider>
          </div>
          <div class="providerBgColor" fxflex fxLayout="row" fxLayout.lt-md="column" fxFill>
            <div fxFlex="30" fxFlex.lt-lg="50">
              <div tabindex="0">
                <span aria-label="Expense" class="smallText">Expense{{j == 0 ? '' : ' ' + (j + 1)}}:</span>
                <span [attr.aria-label]="numbersService.formatForCurrency(list.incurredAmt, list.incurredCurrCode)">{{list.incurredAmt ? numbersService.formatForCurrency(list.incurredAmt, list.incurredCurrCode) : numbersService.formatForCurrency(list.originalAmt, list.originalCurrCode)}}</span> 
                <span [attr.aria-label]="numbersService.formatForCurrency(list.incurredAmt, list.incurredCurrCode)" *ngIf="list.incurredAmt" class = "preferredIncurredCurr">{{numbersService.formatForCurrency(list.originalAmt, list.originalCurrCode)}}</span> 
              </div>
              <div tabindex="0">
                <span aria-label="Service Dates" class="smallText">Service Dates:</span>
                <span [attr.aria-label]="dateConversionService.convertToDisplayDate(list.incurredFromDt) - dateConversionService.convertToDisplayDate(list.incurredToDt)">{{dateConversionService.convertToDisplayDate(list.incurredFromDt)}} - {{dateConversionService.convertToDisplayDate(list.incurredToDt)}}</span>
              </div>
              <div tabindex="0">
                <span aria-label="Expense Type" class="smallText">Expense Type:</span>
                <span [attr.aria-label]="list.expenditureTypeDesc">{{list.expenditureTypeDesc}}</span>
              </div>
            </div>
            <div fxFlex="70" fxFlex.lt-lg="50">
              <div tabindex="0" class="descBlk">
                <span aria-label="Description" class="smallText">Description:</span>
                <span [attr.aria-label]="list.distributionDescForPaymentEntry">{{list.distributionDescForPaymentEntry}}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
  </div>
</div> 