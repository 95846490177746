export const sentimentData = [
   {
      "fileNumber": 4109145,
      "timeLine": [
         {
            "sentimentName": "Policy Counseling Satisfaction",
            "sentimentLevel": 1,
            "sentimentCompletedDate": "2020-01-28"
         },
         {
            "sentimentName": "Household Goods Packing Satisfaction",
            "sentimentLevel": 2,
            "sentimentCompletedDate": "2020-01-28"
         },
         {
            "sentimentName": "Household Goods Delivery Satisfaction",
            "sentimentLevel": 3,
            "sentimentCompletedDate": "2020-01-28"
         },
         {
            "sentimentName": "Relocation Satisfaction",
            "sentimentLevel": 5,
            "sentimentCompletedDate": "2020-01-28"
         }
      ],
      "totalSentiments": 4
   },
   {
      "fileNumber": 3189467,
      "timeLine": [],
      "totalSentiments": 0
   }
]

export const providerData = [
   {
      "fileNumber": 4109145,
      "suppliers": [],
      "count": 0
   },
   {
      "fileNumber": 3189467,
      "suppliers": [],
      "count": 0
   },
   {
      "fileNumber": 3050317,
      "suppliers": [
         {
            "supplierName": "IMPACT GROUP, LLC",
            "incurredAmt": 3500,
            "incurredCurrCode": "USD",
            "apPaymentDocDate": "2019-12-30"
         },
         {
            "supplierName": "MOVING SYSTEMS LLC",
            "incurredAmt": 8006.72,
            "incurredCurrCode": "USD",
            "apPaymentDocDate": "2019-11-22"
         },
         {
            "supplierName": "MOVING SYSTEMS LLC",
            "incurredAmt": 6089.21,
            "incurredCurrCode": "USD",
            "apPaymentDocDate": "2019-11-19"
         },
         {
            "supplierName": "OAKWOOD WORLDWIDE",
            "incurredAmt": 3246.5600000000004,
            "incurredCurrCode": "USD",
            "apPaymentDocDate": "2019-10-29"
         },
         {
            "supplierName": "IOR GLOBAL SERVICES",
            "incurredAmt": 2800,
            "incurredCurrCode": "USD",
            "apPaymentDocDate": "2019-10-24"
         }
      ],
      "count": 5
   },
   {
      "fileNumber": 3185511,
      "suppliers": [
         {
            "supplierName": "FORENOM APS",
            "incurredAmt": 3720.8199999999997,
            "incurredCurrCode": "USD",
            "apPaymentDocDate": "2020-10-06"
         }
      ],
      "count": 1
   },
   {
      "fileNumber": 3181523,
      "suppliers": [
         {
            "supplierName": "EMCO SOLUTIONS INC",
            "incurredAmt": 1500,
            "incurredCurrCode": "USD",
            "apPaymentDocDate": "2020-10-08"
         }
      ],
      "count": 2
   },
   {
      "fileNumber": 3039191,
      "suppliers": [
         {
            "supplierName": "MOVING SYSTEMS LLC",
            "incurredAmt": 10241.12,
            "incurredCurrCode": "USD",
            "apPaymentDocDate": "2020-01-22"
         },
         {
            "supplierName": "IOR GLOBAL SERVICES",
            "incurredAmt": 2450,
            "incurredCurrCode": "USD",
            "apPaymentDocDate": "2019-12-13"
         },
         {
            "supplierName": "MOVING SYSTEMS LLC",
            "incurredAmt": 4007,
            "incurredCurrCode": "USD",
            "apPaymentDocDate": "2019-12-13"
         }
      ],
      "count": 3
   }
]

export const providerInvoiceData = [
   {
      "fileNumber": 3185511,
      "suppliers": [
         {
            "supplierName": "FORENOM APS",
            "transAmt": 3720.8199999999997,
            "transactionCurrCode": "USD",
            "invoices": [
               {
                  "apPaymentDocDate": "2018-04-25",
                  "apInvId": "PAC002702",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 3720.8199999999997,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-04-24",
                        "incurredToDt": "2018-04-24",
                        "expenditureTypeDesc": "AIR SHIPMENT",
                        "distributionDescForPaymentEntry": "Air Shipment, Actual Wt - 500 lbs, Billed Wt - 500  lbs, Pack Dt - 12/26/2017, Load Dt - 12/26/2017,  Delivery Dt - 01/20/2018, Origin Loc - Tokyo, -, JPN  , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               },
               {
                  "apPaymentDocDate": "2018-06-18",
                  "apInvId": "PAC002701",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 469.92,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "Household Goods Insurance",
                        "distributionDescForPaymentEntry": "Unirisc Insurance,Insured Value -$15,664, Surf"
                     },
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 72,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "SURFACE SHIPMENT",
                        "distributionDescForPaymentEntry": "Crating - Carrier"
                     },
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 3800,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "SURFACE SHIPMENT",
                        "distributionDescForPaymentEntry": "Surface Shipment, Actual Wt - 979 lbs, Billed Wt -  1,000 lbs, Pack Dt - 12/26/2017, Load Dt -  12/26/2017, Delivery Dt - 02/03/2018, Origin Loc -  Tokyo, -, JPN , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               }
            ]
         }
      ],
      "count": 1
   },
   {
      "fileNumber": 3181523,
      "suppliers": [
         {
            "supplierName": "EMCO SOLUTIONS INC",
            "transAmt": 1500,
            "transactionCurrCode": "USD",
            "invoices": [
               {
                  "apPaymentDocDate": "2018-04-25",
                  "apInvId": "PAC002702",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 200,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-04-24",
                        "incurredToDt": "2018-04-24",
                        "expenditureTypeDesc": "AIR SHIPMENT",
                        "distributionDescForPaymentEntry": "Air Shipment, Actual Wt - 500 lbs, Billed Wt - 500  lbs, Pack Dt - 12/26/2017, Load Dt - 12/26/2017,  Delivery Dt - 01/20/2018, Origin Loc - Tokyo, -, JPN  , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               },
               {
                  "apPaymentDocDate": "2018-06-18",
                  "apInvId": "PAC002701",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 250,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "Household Goods Insurance",
                        "distributionDescForPaymentEntry": "Unirisc Insurance,Insured Value -$15,664, Surf"
                     },
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 750,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "SURFACE SHIPMENT",
                        "distributionDescForPaymentEntry": "Crating - Carrier"
                     },
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 300,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "SURFACE SHIPMENT",
                        "distributionDescForPaymentEntry": "Surface Shipment, Actual Wt - 979 lbs, Billed Wt -  1,000 lbs, Pack Dt - 12/26/2017, Load Dt -  12/26/2017, Delivery Dt - 02/03/2018, Origin Loc -  Tokyo, -, JPN , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               }
            ]
         }
      ],
      "count": 1
   },
   {
      "fileNumber": 3039191,
      "suppliers": [
         {
            "supplierName": "MOVING SYSTEMS LLC",
            "transAmt": 10241.12,
            "transactionCurrCode": "USD",
            "invoices": [
               {
                  "apPaymentDocDate": "2018-04-25",
                  "apInvId": "PAC002702",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 10241.12,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-04-24",
                        "incurredToDt": "2018-04-24",
                        "expenditureTypeDesc": "AIR SHIPMENT",
                        "distributionDescForPaymentEntry": "Air Shipment, Actual Wt - 500 lbs, Billed Wt - 500  lbs, Pack Dt - 12/26/2017, Load Dt - 12/26/2017,  Delivery Dt - 01/20/2018, Origin Loc - Tokyo, -, JPN  , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               }
            ]
         },
         {
            "supplierName": "IOR GLOBAL SERVICES",
            "transAmt": 2450,
            "transactionCurrCode": "USD",
            "invoices": [
               {
                  "apPaymentDocDate": "2018-06-18",
                  "apInvId": "PAC002701",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 450,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "Household Goods Insurance",
                        "distributionDescForPaymentEntry": "Unirisc Insurance,Insured Value -$15,664, Surf"
                     },
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 1000,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "SURFACE SHIPMENT",
                        "distributionDescForPaymentEntry": "Crating - Carrier"
                     },
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 1000,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "SURFACE SHIPMENT",
                        "distributionDescForPaymentEntry": "Surface Shipment, Actual Wt - 979 lbs, Billed Wt -  1,000 lbs, Pack Dt - 12/26/2017, Load Dt -  12/26/2017, Delivery Dt - 02/03/2018, Origin Loc -  Tokyo, -, JPN , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               }
            ]
         },
         {
            "supplierName": "MOVING SYSTEMS LLC",
            "transAmt": 4007,
            "transactionCurrCode": "USD",
            "invoices": [
               {
                  "apPaymentDocDate": "2018-04-25",
                  "apInvId": "PAC002702",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 4007,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-04-24",
                        "incurredToDt": "2018-04-24",
                        "expenditureTypeDesc": "AIR SHIPMENT",
                        "distributionDescForPaymentEntry": "Air Shipment, Actual Wt - 500 lbs, Billed Wt - 500  lbs, Pack Dt - 12/26/2017, Load Dt - 12/26/2017,  Delivery Dt - 01/20/2018, Origin Loc - Tokyo, -, JPN  , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               }
            ]
         }
      ],
      "count": 3
   },
   {
      "fileNumber": 3050317,
      "suppliers": [
         {
            "supplierName": "IMPACT GROUP, LLC",
            "transAmt": 3500,
            "transactionCurrCode": "USD",
            "invoices": [
               {
                  "apPaymentDocDate": "2018-04-25",
                  "apInvId": "PAC002702",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 3500,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-04-24",
                        "incurredToDt": "2018-04-24",
                        "expenditureTypeDesc": "AIR SHIPMENT",
                        "distributionDescForPaymentEntry": "Air Shipment, Actual Wt - 500 lbs, Billed Wt - 500  lbs, Pack Dt - 12/26/2017, Load Dt - 12/26/2017,  Delivery Dt - 01/20/2018, Origin Loc - Tokyo, -, JPN  , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               }
            ]
         },
         {
            "supplierName": "MOVING SYSTEMS LLC",
            "transAmt": 8006.72,
            "transactionCurrCode": "USD",
            "invoices": [
               {
                  "apPaymentDocDate": "2018-06-18",
                  "apInvId": "PAC002701",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 3000,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "Household Goods Insurance",
                        "distributionDescForPaymentEntry": "Unirisc Insurance,Insured Value -$15,664, Surf"
                     },
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 3000,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "SURFACE SHIPMENT",
                        "distributionDescForPaymentEntry": "Crating - Carrier"
                     },
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 2006.72,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "SURFACE SHIPMENT",
                        "distributionDescForPaymentEntry": "Surface Shipment, Actual Wt - 979 lbs, Billed Wt -  1,000 lbs, Pack Dt - 12/26/2017, Load Dt -  12/26/2017, Delivery Dt - 02/03/2018, Origin Loc -  Tokyo, -, JPN , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               }
            ]
         },
         {
            "supplierName": "MOVING SYSTEMS LLC",
            "transAmt": 6089.21,
            "transactionCurrCode": "USD",
            "invoices": [
               {
                  "apPaymentDocDate": "2018-04-25",
                  "apInvId": "PAC002702",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 6089.21,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-04-24",
                        "incurredToDt": "2018-04-24",
                        "expenditureTypeDesc": "AIR SHIPMENT",
                        "distributionDescForPaymentEntry": "Air Shipment, Actual Wt - 500 lbs, Billed Wt - 500  lbs, Pack Dt - 12/26/2017, Load Dt - 12/26/2017,  Delivery Dt - 01/20/2018, Origin Loc - Tokyo, -, JPN  , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               }
            ]
         },
         {
            "supplierName": "OAKWOOD WORLDWIDE",
            "transAmt": 3246.5600000000004,
            "transactionCurrCode": "USD",
            "invoices": [
               {
                  "apPaymentDocDate": "2018-06-18",
                  "apInvId": "PAC002701",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 1000,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "Household Goods Insurance",
                        "distributionDescForPaymentEntry": "Unirisc Insurance,Insured Value -$15,664, Surf"
                     },
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 1000,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "SURFACE SHIPMENT",
                        "distributionDescForPaymentEntry": "Crating - Carrier"
                     },
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 100246.5600000000004,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-06-14",
                        "incurredToDt": "2018-06-14",
                        "expenditureTypeDesc": "SURFACE SHIPMENT",
                        "distributionDescForPaymentEntry": "Surface Shipment, Actual Wt - 979 lbs, Billed Wt -  1,000 lbs, Pack Dt - 12/26/2017, Load Dt -  12/26/2017, Delivery Dt - 02/03/2018, Origin Loc -  Tokyo, -, JPN , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               }
            ]
         },
         {
            "supplierName": "IOR GLOBAL SERVICES",
            "transAmt": 2800,
            "transactionCurrCode": "USD",
            "invoices": [
               {
                  "apPaymentDocDate": "2018-04-25",
                  "apInvId": "PAC002702",
                  "apInvDt": "2018-03-26",
                  "list": [
                     {
                        "incurredAmt": null,
                        "incurredCurrCode": null,
                        "originalAmt": 2800,
                        "originalCurrCode": "USD",
                        "incurredFromDt": "2018-04-24",
                        "incurredToDt": "2018-04-24",
                        "expenditureTypeDesc": "AIR SHIPMENT",
                        "distributionDescForPaymentEntry": "Air Shipment, Actual Wt - 500 lbs, Billed Wt - 500  lbs, Pack Dt - 12/26/2017, Load Dt - 12/26/2017,  Delivery Dt - 01/20/2018, Origin Loc - Tokyo, -, JPN  , Dest Loc - Singapore, -, SGP"
                     }
                  ]
               }
            ]
         }
      ],
      "count": 5
   }
]
export const movePhase = [
   {
      "key": "1",
      "value": "Pre-Policy Consultation"
   },
   {
      "key": "2",
      "value": "Preparing to Relocate"
   },
   {
      "key": "3",
      "value": "Arriving in New Location"
   },
   {
      "key": "4",
      "value": "Living in New Location"
   },
   {
      "key": "5",
      "value": "Settled in New Location"
   },
   {
      "key": "0",
      "value": "N/A"
   }
]

export const totalCost = [
   {
      "count": 5,
      "fileNumber": 4109145,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,      
      "results":[
         {
            "accountCategory":"Miscellaneous Costs",
            "accountSubCategory":"SPOUSE ASSISTANCE FEE",
            "transactionDesc":"Spousal Assistance",
            "invoiceDate":"2019-12-30",
            "billAmount":600,
            "billCurrency":"USD",
            "invoiceNo":"1009094334",
            "transactionAmount":600,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"Household Goods Insurance",
            "transactionDesc":"Unirisc Insurance,Insured Value -$13,488, Air",
            "invoiceDate":"2019-11-25",
            "billAmount":250,
            "billCurrency":"USD",
            "invoiceNo":"1009037488",
            "transactionAmount":250,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"PERMANENT STORAGE",
            "transactionDesc":"Permanent Storage, Billing Period From Dt  09/06/2019 - Billing Period To Dt 09/27/2019",
            "invoiceDate":"2019-11-25",
            "billAmount":800,
            "billCurrency":"USD",
            "invoiceNo":"1009037488",
            "transactionAmount":800,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"PERMANENT STORAGE",
            "transactionDesc":"Permanent Storage, Billing Period From Dt  08/12/2019 - Billing Period To Dt 08/28/2019",
            "invoiceDate":"2019-11-25",
            "billAmount":350,
            "billCurrency":"USD",
            "invoiceNo":"1009037488",
            "transactionAmount":350,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"AIR SHIPMENT",
            "transactionDesc":"Air Shipment, Actual Wt - 562 lbs, Billed Wt - 562  lbs",
            "invoiceDate":"2019-11-25",
            "billAmount":200,
            "billCurrency":"USD",
            "invoiceNo":"1009037488",
            "transactionAmount":200,
            "transactionCurrCode":"USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3189467,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 395,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 395,
      "totalTaxAssistance": 0,
      "totalExpense": 395,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 395,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 395,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 2,
      "fileNumber": 3194588,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 3000,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 3000,
      "totalTaxAssistance": 0,
      "totalExpense": 3000,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2000,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 2000,
            "transactionCurrCode": "USD"
         },
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"Household Goods Insurance",
            "transactionDesc":"Unirisc Insurance,Insured Value -$1,000, Surf",
            "invoiceDate":"2020-01-27",
            "billAmount":1000,
            "billCurrency":"USD",
            "invoiceNo":"1009146166",
            "transactionAmount":1000,
            "transactionCurrCode":"USD"
         },
      ]
   },
   {
      "count": 1,
      "fileNumber": 3193177,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 395,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 395,
      "totalTaxAssistance": 0,
      "totalExpense": 395,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 395,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 395,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3193392,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 395,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 395,
      "totalTaxAssistance": 0,
      "totalExpense": 395,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 395,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 395,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 3,
      "fileNumber": 3053832,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 4610,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 4610,
      "totalTaxAssistance": 0,
      "totalExpense": 4610,
      "results": [
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"SURFACE SHIPMENT",
            "transactionDesc":"Surface Shipment, Actual Wt - 544 lbs, Billed Wt -  1,000 lbs",
            "invoiceDate":"2020-01-27",
            "billAmount":610,
            "billCurrency":"USD",
            "invoiceNo":"1009146166",
            "transactionAmount":610,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Intercultural Training",
            "accountSubCategory":"CC Training - AM",
            "transactionDesc":"Cross-Cultural Program - Country Overview - Service - Shweta Gupta: Customer",
            "invoiceDate":"2020-01-27",
            "billAmount":1500,
            "billCurrency":"USD",
            "invoiceNo":"1009146166",
            "transactionAmount":1500,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"Household Goods Insurance",
            "transactionDesc":"Unirisc Insurance,Insured Value -$2,500, Surf",
            "invoiceDate":"2020-01-27",
            "billAmount":2500,
            "billCurrency":"USD",
            "invoiceNo":"1009146166",
            "transactionAmount":2500,
            "transactionCurrCode":"USD"
         },
      ]
   },
   {
      "count": 3,
      "fileNumber": 3050317,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 65597.1,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 65597.1,
      "totalTaxAssistance": 0,
      "totalExpense": 65597.1,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 20597.1,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 20597.1,
            "transactionCurrCode": "USD"
         },
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"SURFACE SHIPMENT",
            "transactionDesc":"Surface Shipment, Actual Wt - 544 lbs, Billed Wt -  1,000 lbs",
            "invoiceDate":"2020-01-27",
            "billAmount":40000,
            "billCurrency":"USD",
            "invoiceNo":"1009146166",
            "transactionAmount":40000,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Rent and Utilities",
            "accountSubCategory":"LOCAL PROPERTY TAX/COUNCIL TAX",
            "transactionDesc":"Employee City/County/Council Taxes",
            "invoiceDate":"2021-05-10",
            "billAmount":5000,
            "billCurrency":"USD",
            "invoiceNo":"1009597191",
            "transactionAmount":5000,
            "transactionCurrCode":"USD"
         },
      ]
   },
   {
      "count": 1,
      "fileNumber": 3185511,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 6035.82,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 6035.82,
      "totalTaxAssistance": 0,
      "totalExpense": 6035.82,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 6035.82,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 6035.82,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3186165,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 3950,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 3950,
      "totalTaxAssistance": 0,
      "totalExpense": 3950,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 3950,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 3950,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3189682,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 1395,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 1395,
      "totalTaxAssistance": 0,
      "totalExpense": 1395,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 1395,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 1395,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3181263,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 395,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 395,
      "totalTaxAssistance": 0,
      "totalExpense": 395,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 395,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 395,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3189124,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 395,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 395,
      "totalTaxAssistance": 0,
      "totalExpense": 395,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 395,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 395,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3185964,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 2295,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2295,
      "totalTaxAssistance": 0,
      "totalExpense": 2295,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2295,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 2295,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3194694,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 0,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 0,
      "totalTaxAssistance": 0,
      "totalExpense": 0,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 0,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 0,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3181523,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 12715,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 12715,
      "totalTaxAssistance": 0,
      "totalExpense": 12715,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 12715,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 12715,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3039191,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 43112.02,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 43112.02,
      "totalTaxAssistance": 0,
      "totalExpense": 43112.02,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 43112.02,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 43112.02,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3183842,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 5215,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 5215,
      "totalTaxAssistance": 0,
      "totalExpense": 5215,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 5215,
            "billCurrency": "USD",
            "invoiceNo": "1009564655",
            "transactionAmount": 5215,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 1,
      "fileNumber": 3293467,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 3950,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 3950,
      "totalTaxAssistance": 0,
      "totalExpense": 3950,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - APAC",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 3950,
            "billCurrency": "USD",
            "invoiceNo": "1009564652",
            "transactionAmount": 3950,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "count": 3,
      "fileNumber": 3273467,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 12400,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 12400,
      "totalTaxAssistance": 0,
      "totalExpense": 12400,
      "results": [
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"SURFACE SHIPMENT",
            "transactionDesc":"Surface Shipment, Actual Wt - 544 lbs, Billed Wt -  1,000 lbs",
            "invoiceDate":"2020-01-27",
            "billAmount":2400,
            "billCurrency":"USD",
            "invoiceNo":"1009146112",
            "transactionAmount":2400,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Intercultural Training",
            "accountSubCategory":"CC Training - AM",
            "transactionDesc":"Cross-Cultural Program - Country Overview - Service - Shweta Gupta: Customer",
            "invoiceDate":"2020-01-27",
            "billAmount":6500,
            "billCurrency":"USD",
            "invoiceNo":"1009146326",
            "transactionAmount":6500,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"Household Goods Insurance",
            "transactionDesc":"Unirisc Insurance,Insured Value -$2,500, Surf",
            "invoiceDate":"2020-01-27",
            "billAmount":3500,
            "billCurrency":"USD",
            "invoiceNo":"1029146166",
            "transactionAmount":3500,
            "transactionCurrCode":"USD"
         },

         
      ]
   },
   {
      "count": 2,
      "fileNumber": 3389467,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 14500,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 14500,
      "totalTaxAssistance": 0,
      "totalExpense": 14500,
      "results": [
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"SURFACE SHIPMENT",
            "transactionDesc":"Surface Shipment, Actual Wt - 544 lbs, Billed Wt -  1,000 lbs",
            "invoiceDate":"2020-01-27",
            "billAmount":8500,
            "billCurrency":"USD",
            "invoiceNo":"1009146112",
            "transactionAmount":8500,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Intercultural Training",
            "accountSubCategory":"CC Training - AM",
            "transactionDesc":"Cross-Cultural Program - Country Overview - Service - Shweta Gupta: Customer",
            "invoiceDate":"2020-01-27",
            "billAmount":6000,
            "billCurrency":"USD",
            "invoiceNo":"1009146326",
            "transactionAmount":6000,
            "transactionCurrCode":"USD"
         }
      ]
   },
   {
      "count": 3,
      "fileNumber": 3277467,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 54630,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 54630,
      "totalTaxAssistance": 0,
      "totalExpense": 54630,
      "results": [
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"SURFACE SHIPMENT",
            "transactionDesc":"Surface Shipment, Actual Wt - 544 lbs, Billed Wt -  1,000 lbs",
            "invoiceDate":"2020-01-27",
            "billAmount":28100,
            "billCurrency":"USD",
            "invoiceNo":"1009146112",
            "transactionAmount":28100,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Intercultural Training",
            "accountSubCategory":"CC Training - AM",
            "transactionDesc":"Cross-Cultural Program - Country Overview - Service - Shweta Gupta: Customer",
            "invoiceDate":"2020-01-27",
            "billAmount":4030,
            "billCurrency":"USD",
            "invoiceNo":"1009146326",
            "transactionAmount":4030,
            "transactionCurrCode":"USD"
         },
         {
            "accountCategory":"Household Goods",
            "accountSubCategory":"Household Goods Insurance",
            "transactionDesc":"Unirisc Insurance,Insured Value -$2,500, Surf",
            "invoiceDate":"2020-01-27",
            "billAmount":22500,
            "billCurrency":"USD",
            "invoiceNo":"1029146166",
            "transactionAmount":22500,
            "transactionCurrCode":"USD"
         },

         
      ]
   },
   {
      "count": 1,
      "fileNumber": 3387467,
      "totalNumofTransactions": 1,
      "totalCountOfAllTransactions": 395,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 395,
      "totalTaxAssistance": 0,
      "totalExpense": 395,      
      "results":[
         {
            "accountCategory":"Miscellaneous Costs",
            "accountSubCategory":"SPOUSE ASSISTANCE FEE",
            "transactionDesc":"Spousal Assistance",
            "invoiceDate":"2019-12-30",
            "billAmount":395,
            "billCurrency":"USD",
            "invoiceNo":"1009094334",
            "transactionAmount":395,
            "transactionCurrCode":"USD"
         }
      ]
   },
   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109141,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
        {
          "accountCategory": "Cartus Fees",
          "accountSubCategory": "Intl Expense Processing Fee - EMEA",
          "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
          "invoiceDate": "2020-10-19",
          "billAmount": 2200,
          "billCurrency": "USD",
          "invoiceNo": "1009564512",
          "transactionAmount": 3180285,
          "transactionCurrCode": "USD"
        }
      ]
   },
   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109142,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
        {
          "accountCategory": "Cartus Fees",
          "accountSubCategory": "Intl Expense Processing Fee - EMEA",
          "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
          "invoiceDate": "2020-10-19",
          "billAmount": 2200,
          "billCurrency": "USD",
          "invoiceNo": "1009564512",
          "transactionAmount": 3180285,
          "transactionCurrCode": "USD"
        }
      ]
   },
   {
      "totalNumofTransactions": 1,
      "fileNumber": 3189468,
      "totalCountOfAllTransactions": 395,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 395,
      "totalTaxAssistance": 0,
      "totalExpense": 395,
      "results": [
        {
          "accountCategory": "Cartus Fees",
          "accountSubCategory": "Intl Expense Processing Fee - APAC",
          "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
          "invoiceDate": "2020-10-19",
          "billAmount": 395,
          "billCurrency": "USD",
          "invoiceNo": "1009564655",
          "transactionAmount": 395,
          "transactionCurrCode": "USD"
        }
      ]
   },
   
   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109143,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109144,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109145,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109147,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109148,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109149,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109150,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109151,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109150,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109151,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },
   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109152,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109152,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   },

   {
      "totalNumofTransactions": 1,
      "fileNumber": 4109152,
      "totalCountOfAllTransactions": 2200,
      "numberOfRecentTransactions": 0,
      "transactionCurrCode": "USD",
      "totalRecentTransaction": 2200,
      "totalTaxAssistance": 0,
      "totalExpense": 2200,
      "results": [
         {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
         }
      ]
   }
]