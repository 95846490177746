import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from '../login/login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  createUserUrl = environment.createUser;
  updateUserUrl = environment.updateUser;

  constructor(private http: HttpClient, private loginService: LoginService) { }

  registerUser(newUserData: any): Observable<any> {
    if (newUserData) {
      var headers = new HttpHeaders().set("Authorization", this.loginService.getJwtToken());
      return this.http.post<any>(this.createUserUrl, this.createBody(newUserData), { headers: headers });
    }
    else {
      console.log("Error: No form Data");
    }
  }

  updateUser(newUserData: any): Observable<any> {
    if (newUserData) {
      var headers = new HttpHeaders().set("Authorization", this.loginService.getJwtToken());
      return this.http.post<any>(this.updateUserUrl, this.createBody(newUserData), { headers: headers });
    }
    else {
      console.log("Error: No form Data");
    }
  }

  createBody(newUserData: any) {
    let finalBody;
    let userType = "user"
    if (newUserData.userType) {
      userType = newUserData.userType.toLowerCase();
    }
    if (newUserData.isAdmin) {
      userType = "admin";
    }
    let password = newUserData.password;
    let body =
    {
      "firstName": newUserData.firstName,
      "lastName": newUserData.lastName,
      "email": newUserData.email,
      "userName": newUserData.userName.toLowerCase(),
      "userType": userType,
      "clientId": newUserData.clientId
    }
    if (userType.toLowerCase() != "superadmin") {
      let subDate = {"subscriptionEndDate": this.dateConvert(newUserData.subscriptionEndDate)}
     body = {...body, ...subDate};
    }
    if(password.length> 0 ){
      body = {...body, ...{"password": password}};
    }
    return body;
  }

  dateConvert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
}
