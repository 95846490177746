import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { TransfereeNeedsAssessment } from '../../core/models/candidateneeds-assessment.model';
import { catchError, map, delay } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { transfereeNeedsAssessmentData } from '../data/app-data';
import { NeedsAssessmentSharedService } from '../../public/needs-assessment/services/needs-assessment-shared.service';
import { AppDataService } from './app-data.service';
@Injectable({
    providedIn: 'root'
})
export class PersonalInfoService {

    /** Instance of BehaviorSubject of type TransfereeNeedsAssessment for personal Information*/
    personalInformationDetails = new BehaviorSubject < TransfereeNeedsAssessment > (null);
    /** Personal Info as Promise */
    personalInformation;
    personalInfo = this.personalInformationDetails.asObservable();

    constructor(private readonly baseClientService: BaseClientService,
                private readonly needsAssessmentShared: NeedsAssessmentSharedService,
                private appDataService: AppDataService
               ) {
        this.getPersonalInfoDetails();
    }
    /**
     * Function to Update the personalInformationDetails  subject
     * @param data updated personalInformationDetails  
     */
    updatePersonalInfo(data: TransfereeNeedsAssessment) {
        this.personalInformationDetails.next(data);
    }

    /**
     * Function to Get personalInformationDetails and update Shared-Service
     */
    getPersonalInfoDetails() {
        if (!this.personalInformationDetails.getValue()) {
            this.getPersonalInformationDetails().subscribe(data => {
                if (data) {
                    this.updatePersonalInfo(data);
                  }
            });
        } else {
            return this.personalInformation = this.personalInformationDetails.getValue();
        }
    }

    /**
     * API call to Add personal information data
     */
    addPersonalInformationDetails(
        assessmentDetail: TransfereeNeedsAssessment
    ): Observable < any > {
        // return this.baseClientService
        //     .put < TransfereeNeedsAssessment > (`/v1/profile`, assessmentDetail)
        //     .pipe(
        //         map(r => r.body),
        //         catchError((err, source) => {
        //             const empty: TransfereeNeedsAssessment = null;
        //             console.log('Failed to add personal details', err);
        //             return of(empty);
        //         })
        //     );
        // return of(profiledata);
        return of (this.needsAssessmentShared.transfereeNeedsAssessmentDetails.getValue());
    }

    /**
     * API call to Get personal information data data
     */
    getPersonalInformationDetails(): Observable < TransfereeNeedsAssessment > {
        // return this.baseClientService
        //     .getById < TransfereeNeedsAssessment > (`/v1/profile`)
        //     .pipe(
        //         map(r => r.body),
        //         catchError(err => {
        //             console.log('Failed to get personal details', err);
        //             const emptyResp: TransfereeNeedsAssessment = null;
        //             return of(emptyResp);
        //         })
        //     );
        // return of(profiledata);
        return of (this.needsAssessmentShared.transfereeNeedsAssessmentDetails.getValue(),this.appDataService.transfereeNeedsAssementStubData.getValue());
    }
}
