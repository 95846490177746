<div class="dialog-container" role="region">
  <!-- Title of pop-up -->
  <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px" fxLayoutGap.xs="0em" role="heading">
      <h2 class="divisions-title">Edit Benefit Logic</h2>
      <a class="close-icon" (click)="close()" title="close" role="button" aria-label="Close Program Modal" title="Close Program Modal">
          <mat-icon>close</mat-icon>
      </a>
  </div>
  <!-- Title of pop-up end here -->

  <div class="pop-up-conent">
      <!--dialog-title start  -->
      <div class="dialog-title-container">
        <div class="dialog-wrapper">
          <div class="dialog-title-card">
            <mat-card class="mat-elevation-z8">{{data.benefit.displayName}}</mat-card>
          </div>
          <div class="dialog-title-desc">
            <p>{{data.benefit.description}}</p>
          </div>
        </div>
      </div>
      <!--dialog-title end here  -->
    
      <!--Restrict box start  temporary comment -->
      <div class="restrict-card">
        <mat-card >
          <mat-card-header>
              <mat-card-title>Restrict this benefit to...</mat-card-title>
          </mat-card-header>
          <form  class="middle-section" [formGroup]="restrictionForm">
            <div formArrayName="restrictionArray">
              <div *ngFor="let restrictSection of restrictionArray?.controls let restrictSectionIndex = index" [formGroupName]="restrictSectionIndex" >
                <mat-card-content style="background:#f8f8f8; ">
                  <!-- first select input start -->
                    <div class="middle-section">
                      <div class="division-input">
                        <mat-form-field class="example-form-field" >
                            <mat-label>Restrictions</mat-label> 
                            <mat-select *ngIf="restrictionArray?.controls.length-1 === restrictSectionIndex && !restrictDisableFlag" formControlName="restriction" [disabled]="restrictionArray?.controls.length-1 !== restrictSectionIndex" (selectionChange)= 'addEmptyaddedRestriction(restrictSectionIndex,restrictSection,restriction)'>
                              <mat-option [value]="division.name"  *ngFor="let division of restrictionList" style="font-size: 14px;" >
                                  {{ division.name}} 
                              </mat-option>
                            </mat-select>
                            <input matInput *ngIf="restrictionArray?.controls.length-1 !== restrictSectionIndex || restrictDisableFlag" [value]="restrictSection.get('restriction').value" type="text" [readonly] = "true" >
                        </mat-form-field>
                        <button  class="minus restrict-minus" (click)="clearRestrictions(restrictSectionIndex,restrictSection)"></button>
                      </div>
                      <div *ngIf="checkDescriptionAvailability(restrictSectionIndex)" class="info-section" fxLayout="row" fxLayoutAlign="center center">
                        <img class="info-con" alt="info" src="../../../../../../../assets/images/info-24px .svg"/>
                        <div class="info-desc">{{getDescription(restrictSectionIndex)}}</div>
                      </div>
                    </div>
                    <!-- first select input end -->
          
                    <!-- preciding select input start -->
                    <div *ngIf = "checkCountrySelectAvailability(restrictSectionIndex)" formArrayName="addedRestrictionArray" class="division-input"> <!--restrictionArray.controls[restrictSectionIndex].addedRestrictionArray.controls-->
                      <div class="dynamic-input form-group" fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" *ngFor="let restrictItem of addedRestrictionArray(restrictSectionIndex).controls; index as ind; let k = index">
                          <mat-form-field class="example-form-field">
                            <mat-label>{{getRestrictionType(restrictSectionIndex).value.includes('Departure')?'Departure country':'Destination country'}}</mat-label>
                             <mat-select formControlName="{{ind}}" [disabled]="k <=restrictSection.controls['addedRestrictionArray'].controls.length-2" *ngIf="k <= restrictSection.controls['addedRestrictionArray'].controls.length-2">
                                  <mat-option [value]="country.countryName" *ngFor="let country of allCountryList let i=index; let l=last" style="font-size: 14px;">
                                      {{ country.countryName}} 
                                  </mat-option>
                              </mat-select>
                              <mat-select formControlName="{{ind}}" [disabled]="k <= restrictSection.controls['addedRestrictionArray'].controls.length-2" *ngIf="k > restrictSection.controls['addedRestrictionArray'].controls.length-2">
                                  <mat-option [value]="country.countryName" *ngFor="let country of getCountryListWRTType(restrictSection) let i=index; let l=last" style="font-size: 14px;">
                                      {{ country.countryName}} 
                                  </mat-option>
                              </mat-select>
                          </mat-form-field>
                          <button class="minus" *ngIf="!(ind === 0 && restrictSection.controls['addedRestrictionArray'].controls.length === 1 && restrictSection.get('addedRestrictionArray').controls[0].value == '')" (click)="pushBackToListCountry(ind,restrictItem,restrictSectionIndex,restrictSection)"></button>
                          <button class="plus" *ngIf="ind === restrictSection.controls['addedRestrictionArray'].controls.length - 1" [disabled]="false" (click)="associateCountry(restrictSectionIndex,restrictItem,restrictSection)"></button>
                          <br />
                      </div>
                    </div>
                    <!-- preciding select input end -->
                </mat-card-content>
              </div>
        </div>
        </form>
        </mat-card>        
        <button mat-button class="text-button main-btn"  [disabled] = "disableRestriction()" (click)="addRestrictionSection()">+ Add Restriction</button>
      </div>
      <!--Restrict box end  -->
    
      <!--Next Restrict box start  -->
      <div class="restrict-card" style="padding-top: 12px;">
        <mat-card >
          <mat-card-header>
              <mat-card-title>If this benefit is selected</mat-card-title>
          </mat-card-header>
         
          
            
            <form  class="middle-section" [formGroup]="ruleForm">
              <!-- first select input start -->
              <div formArrayName="ruleArray">
                <div *ngFor="let ruleSection of getRuleSections(ruleForm); let j = index" [formGroupName]="j" >
                  <mat-card-content style="background:#f8f8f8; ">
                  <div class="middle-section">
                <div class="division-input">
                  <mat-form-field class="example-form-field" >
                      <mat-label>Rule</mat-label> 
                      <mat-select formControlName="rule" *ngIf = "j > 0" (selectionChange)= addBenefitFormField(j)>
                        <mat-option [value]="rule.name" *ngFor="let rule of ruleList" style="font-size: 14px;">
                            {{ rule.name}} 
                        </mat-option>
                      </mat-select>
                      <mat-select formControlName="rule" *ngIf ="j === 0"  (selectionChange)= addBenefitFormField(j)>
                        <mat-option [value]="rule.name" *ngFor="let rule of allRuleList" style="font-size: 14px;">
                            {{ rule.name}} 
                        </mat-option>
                      </mat-select>
                  </mat-form-field>
                  <button class="minus restrict-minus" [disabled] = "ruleSection.status === 'INVALID'" (click)="clearRestrictions2(j)"></button>
                </div>
                </div>
                <!-- first select input end -->
    
                <!-- preciding select input start -->
                <!--<form *ngIf="rule.value" class="middle-section" [formGroup]="ruleForm"> -->
                <div  *ngIf = "ruleSection.get('rule').value" formArrayName="addedRule" class="division-input">
                <div [formGroupName]="k" class="dynamic-input form-group" fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" *ngFor="let divisions of getaddedRule(ruleSection); index as ind ; let k = index">
                    <mat-form-field class="example-form-field">
                        <mat-label>Linked Benefit</mat-label>                                    
                        <input matInput formControlName="ind" [value]="ruleSection.value.addedRule[k].ind.displayName" type="text" [readonly] = "k <= ruleSection.value.addedRule.length-2" *ngIf = "k <= ruleSection.value.addedRule.length-1 && ruleSection.value.addedRule[k].ind.displayName">
                        <mat-select formControlName="ind" [compareWith]="compareBenefits" [disabled] = "k <= ruleSection.value.addedRule.length-2" *ngIf ="ruleSection.value.addedRule[k].ind === ''" (selectionChange) = "addBenefit($event)">
                            <mat-option [value]="benefit" *ngFor="let benefit of data.benefitList; let i=index; let l=last" style="font-size: 14px;">
                                {{ benefit.displayName}} 
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <button class="minus" *ngIf="checkRemoveButtonVisibility2(k, ruleSection)" (click)="pushBackToList(k, j)"></button>
                    <button class="plus" *ngIf="checkAddButtonVisibility2(k, ruleSection)" [disabled]="ruleSection.controls.addedRule.status === 'INVALID'" (click)="associateBenefit(k, j)"></button>
                    <br />
                </div>
                </div>
                </mat-card-content>
              </div>
               </div>

              </form>
               <!-- preciding select input end -->
        </mat-card>
        <button mat-button class="text-button main-btn" (click) = "addRuleSection()" [disabled] = "ruleList.length === 1 || ruleForm.status === 'INVALID' || ruleForm.get('ruleArray').value.length === 2">+ Add Rule</button>
      </div>
      <!--Restrict box end  -->
      
  </div>
  <!-- pop up action buttons start -->
  <mat-dialog-actions  fxLayout="row" fxLayoutAlign="end end">
      <button mat-button class="text-button rest-btn" (click)="close()">CANCEL</button>
      <!-- <button mat-button class="contained-button" (click)='save()' [disabled] = "!this.restrictionForm.get('restrictionArray').valid && disableSave">Save</button> -->
      <button mat-button class="contained-button" (click)='save()' [disabled] = "!this.restrictionForm.get('restrictionArray').valid">Save</button>
  </mat-dialog-actions>
  <!-- pop up action buttons end -->

</div>



