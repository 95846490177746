import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/login/login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {
  saveClientUrl = environment.creatClient;
  updateClientUrl = environment.updateClient;
  constructor(private http: HttpClient, private loginService: LoginService) { }

  /* Save new Client */
  saveClient(newclientData): Observable<any> {
    if (newclientData) {
      var headers = new HttpHeaders().set("Authorization", this.loginService.getJwtToken());
      return this.http.post<any>(this.saveClientUrl, this.createBody(newclientData), { headers: headers });
    }
    else {
      console.log("Error: No form Data");
    }
  }

  /* Update Client */
  updateClient(updatedClientData): Observable<any> {
    if (updatedClientData) {
      var headers = new HttpHeaders().set("Authorization", this.loginService.getJwtToken());
      return this.http.post<any>(this.updateClientUrl, this.createBody(updatedClientData), { headers: headers });
    }
    else {
      console.log("Error: No form Data");
    }
  }

  createBody(clientData) {
    let body = {
      "clientName": this.titleCase(clientData.clientName)
    }
    if(clientData.clientId) {
      body = {...body, ...{"clientId": clientData.clientId}}
    }
    return body;
  }
  
  titleCase(str) {
    let formatedClientName = str.replace(/(\w)(\w*)/g,function(g0,g1,g2){return g1.toUpperCase() + g2;});
    return formatedClientName;
  }

}
