import { Component, OnInit, Inject, Input, Output, ViewEncapsulation, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { AddFamilyInfoDialogComponent } from '../../../transferee/components/add-family-info-dialog/add-family-info-dialog.component';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatAutocompleteTrigger, MatSnackBar } from '@angular/material';
import { FamilyMember } from '../../../../../../core/models/family-member.model';
import { FamilyInfoService } from '../../../../services/family-info.service';
import { CandidateProfileService } from '../../../../services/candidate-profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserType, UserTypeService } from '../../../../services/user-type.service';
import { EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationsService } from '../../../../services/locations.service';
import { Country } from '../../../../../../core/models/country.model';
import { Observable, Subscription } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { interval } from 'rxjs/internal/observable/interval';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

@Pipe({
  name: 'highlight'
})
export class HighlightSearch implements PipeTransform {

  transform(value: string, search: string): string {
    const valueStr = value + '';
    return valueStr.replace(new RegExp('(?![^&;]+;)(?!<[^<>]*)(' + search + ')(?![^<>]*>)(?![^&;]+;)', 'gi'), '<strong class="highlight-search">$1</strong>');
  }

}

@Component({
  selector: 'app-configurable-field',
  templateUrl: './configurable-field.component.html',
  styleUrls: ['./configurable-field.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ConfigurableFieldComponent implements OnInit {

  configurableFieldList: Array<any> = [];
  configurableFieldLists: Array<any> = [];
  multipleOriginalDropDowns: any = [];
  editDetails = false;
  AdditionalDetailsFormGroup: FormGroup;
  showMore = false;
  userRole: UserType;
  searchValue: string;
  searchText: string = '';
  @Input() configField: any = [];
  @Input() orderId: any;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger, static: true }) autoComplete: MatAutocompleteTrigger;
  /**Countries retrieved from service */
  countryList: Array<Country>;
  filteredCountryOptions: Observable<Country[]>;
  filteredCurrencyCode: Observable<Country[]>;
  /**Currency retrieved from service */
  currencyList: Array<Country> = [];
  filteredSingleSelect: any [] = [];
  disableSaveBtn: boolean = true;
  configurableFields: any;
 
  /**
   * @param dialog Inject dialog object
   * @param spinner Inject spinner
   */
  constructor(
    private readonly spinner: NgxSpinnerService,
    private locationService: LocationsService,
    private _formbuilder: FormBuilder,
    private CandidateProfileService: CandidateProfileService,
    private userTypeService: UserTypeService,
    private datePipe: DatePipe,
    private readonly toastr: MatSnackBar

  ) { }

  ngOnInit() {
    this.locationService.countryList.subscribe(countryList => { 
      if(countryList.length > 0) {
        const currList = [];
        countryList.forEach((curr) => {
          curr.currency? currList.push(curr): null;
        });
        currList.sort((a, b) =>  a.currency &&  b.currency ? a.currency.code.localeCompare(b.currency.code) : null);
        this.currencyList = currList.filter((e, i) => currList.findIndex(a => a.currency && e.currency ? a.currency.code === e.currency.code : null) === i);
      }
    });
    this.configField.forEach((ele) => {
      if (ele.type === "Date") {
        ele.value = this.setDateWithOffset(ele.value);
      }
    });
    let group = {}
    this.AdditionalDetailsFormGroup = this._formbuilder.group({
      configField: this._formbuilder.array([])
    })
    this.configurableFieldList = [...this.configField];
    this.userRole = this.userTypeService.userType;
    this.configurableFieldList.forEach((input_template, i) => {
      if(input_template.type === 'Currency') {
        this.AdditionalDetailsFormGroup.addControl(input_template.displayName, new FormGroup({
          currencyCode: new FormControl('', [Validators.required]),
          currencyAmount: new FormControl('', [Validators.required, Validators.max(9999999999999999), Validators.min(0)]),   
        }));
        let observable: any;
        observable = this.AdditionalDetailsFormGroup.get([input_template.displayName, 'currencyCode']).valueChanges
          .pipe(
            startWith(''),
            map(value => this.singleSelect_filter(value,this.currencyList.map(ele => ele.currency.code)))
          );
          this.filteredSingleSelect.push({ [input_template.displayName]: observable });
      } else {
        this.AdditionalDetailsFormGroup.addControl(input_template.displayName,new FormControl(''));
      }
      // this.AdditionalDetailsFormGroup = new FormGroup(group);
      if(input_template.type === 'Currency' && input_template.value) {
        this.AdditionalDetailsFormGroup.get([input_template.displayName, 'currencyCode']).setValue(input_template.value.currencyCode);
        this.AdditionalDetailsFormGroup.get([input_template.displayName, 'currencyAmount']).setValue(input_template.value.currencyAmount);
      }
      if (input_template.value) {
        this.AdditionalDetailsFormGroup.get(input_template.displayName).setValue(input_template.value);
      } else {
        this.configurableFieldList[i].value = null;
      }
      if (input_template.type === 'Country') {
        this.locationService.countryList.subscribe(countryList => {
          if (countryList.length > 0) {
            countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
            this.countryList = countryList;
          }
        });
        this.filteredCountryOptions = this.AdditionalDetailsFormGroup.get(input_template.displayName).valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
      if (input_template.type === 'Multiple Select') {
        this.multipleOriginalDropDowns.push(Object.assign({}, { [input_template.displayName]: input_template.dropDownValues }))
      }
    });

  }
  setDateWithOffset(date) {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    return newDate;
   }

  getSelections(event: any, displayName: string) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.AdditionalDetailsFormGroup.get(displayName).setValue([...this.AdditionalDetailsFormGroup.get(displayName).value, event.source.value]);
      } else {
        const index = this.AdditionalDetailsFormGroup.get(displayName).value.findIndex(
          element => element == event.source.value
        );
        this.AdditionalDetailsFormGroup.get(displayName).value.splice(index, 1);
      }
    }
  }

  openedChanges(isOpen: boolean, displayName: string) {
    if (!isOpen) this.filterValue("", displayName);
  }

  filterValue(filter: string, displayName: string) {
    this.configurableFieldList.find(ele => ele.displayName === displayName).dropDownValues = this.multipleOriginalDropDowns.find(ele => Object.keys(ele).includes(displayName))[displayName].filter(x =>
      x.toLowerCase().includes(filter.toLowerCase())
    );
  }

  public onSaveClick() {
    let body = [];
    const entries = Object.entries(this.AdditionalDetailsFormGroup.value);
  //   /** will be reverted back as per isAuthorizationRequired api changes are upto.
  //   this.configField.forEach(element => {
  //     if(element.isAuthorizationRequired) {
  //       if (this.AdditionalDetailsFormGroup.controls[element.displayName].value) {
  //         if (element.type === "Currency" && 
  //               (this.AdditionalDetailsFormGroup.controls[element.displayName].value.currencyAmount !== element.value.currencyAmount || 
  //               this.AdditionalDetailsFormGroup.controls[element.displayName].value.currencyCode !== element.value.currencyCode)) {
  //           body.push({ "displayName": element.displayName, "value": this.AdditionalDetailsFormGroup.controls[element.displayName].value })
  //         } else {
  //           if (element.type !== "Currency" && this.AdditionalDetailsFormGroup.controls[element.displayName].value !== element.value) {
  //             body.push({ "displayName": element.displayName, "value": this.AdditionalDetailsFormGroup.controls[element.displayName].value })
  //           }
  //         }
  //       }
  //     } else {
  //       if (element.type === "Currency" && 
  //             (this.AdditionalDetailsFormGroup.controls[element.displayName].value.currencyAmount !== element.value.currencyAmount || 
  //               this.AdditionalDetailsFormGroup.controls[element.displayName].value.currencyCode !== element.value.currencyCode)) {
            
  //         body.push({ "displayName": element.displayName, 
  //                     "value": this.AdditionalDetailsFormGroup.controls[element.displayName].value? this.AdditionalDetailsFormGroup.controls[element.displayName].value: element.value, 
  //                     "reset": !this.AdditionalDetailsFormGroup.controls[element.displayName].value? true: false })
  //       } else {
  //         if (element.type !== "Currency" && (element.value && (this.AdditionalDetailsFormGroup.controls[element.displayName].value !== element.value)) ||  ( (!element.hasOwnProperty('value') || !element.value) && this.AdditionalDetailsFormGroup.controls[element.displayName].value)) {
  //           body.push({ "displayName": element.displayName, 
  //                       "value": this.AdditionalDetailsFormGroup.controls[element.displayName].value?this.AdditionalDetailsFormGroup.controls[element.displayName].value: element.value, 
  //                       "reset": !this.AdditionalDetailsFormGroup.controls[element.displayName].value? true: false })
  //         }
  //       }
  //     }
  //   });
  //   */
    this.configField.forEach(element => {
      if (this.AdditionalDetailsFormGroup.controls[element.displayName].value) {
        if (element.type === "Currency" && (this.AdditionalDetailsFormGroup.controls[element.displayName].value.currencyAmount !== element.value.currencyAmount || this.AdditionalDetailsFormGroup.controls[element.displayName].value.currencyCode !== element.value.currencyCode)) {
          body.push({ "displayName": element.displayName, "value": this.AdditionalDetailsFormGroup.controls[element.displayName].value })
        } else {
          if (element.type !== "Currency" && this.AdditionalDetailsFormGroup.controls[element.displayName].value !== element.value) {
            body.push({ "displayName": element.displayName, "value": this.AdditionalDetailsFormGroup.controls[element.displayName].value })
          }
        }
      }
    });

    body.forEach(ele => {
      this.configField.find(curr => ele.displayName === curr.displayName).type === "Date" ? ele.value = moment(ele.value).format('YYYY-MM-DD') : null;
    })
    body.length ? this.CandidateProfileService.updateCustomFields(this.orderId, body).subscribe(res => {
      if (res.code === "ADDITIONAL_DETAIL_UPDATE_SUCCESS") {
        body.forEach(ele => {
          ele.reset?  this.configurableFieldList.find(curr => curr.displayName === ele.displayName).value = null
          :this.configurableFieldList.find(curr => curr.displayName === ele.displayName).value = ele.value;
        });
      }
      this.editDetails = false;
    }) : this.toastr.open('Please update data and try Saving', '',{
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    });;
  }
  
  onDismiss() {
    this.editDetails = false;
    this.configurableFieldList.forEach(input_template => {
      this.AdditionalDetailsFormGroup.get(input_template.displayName).setValue(input_template.value)
    })
  }


  /*The getter method helps to get the controls of formgroup */
  get Clickdata() {
    return this.AdditionalDetailsFormGroup.get('configField') as FormArray;
  }

  //Helps to Search with the name typed inside the Search filed with the list of custom data available.
  filter(query: string) {
    this.configurableFieldList = query ? this.configField.filter(configField => (configField.displayName.toLowerCase()).includes(query.toLowerCase())) : this.configField;
  }

  //Helps to clear the value typed inside the Search field
  clearTextSearch() {
    this.searchValue = '';
    // this.filter("");
    this.configurableFieldList = this.configField;
  }

  clearSearch() {
    this.searchText = '';
    this.filterValue;
  }


  private _filter(value): Country[] {
    if (value) {
      const filterValue = typeof value === 'number' ? value : value.toLowerCase();
      const countryList = this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
      return countryList;
    } else {
      return this.countryList;
    }
  }

  /** currency code filter function */
  private currency_filter(value): Country[] {
    if(value) {
      const filterValue = typeof value === 'number' ? value : 
                          value.code? value.code.toLowerCase(): value;
      const currencyList = this.currencyList.filter(Option => Option.currency.code.toLocaleLowerCase().indexOf(filterValue) === 0);
      return currencyList;
    } else {
      return this.currencyList;
    }
  }

  displayCurrencyCodeFn(code): string {
    return code? code: '';
  }

  private _getFilterObservableWrtDisplayName(displayName) {
    return this.filteredSingleSelect.find(ele => Object.keys(ele).includes(displayName))[displayName];
  }

  private singleSelect_filter(value,valueList): any[] {
    if(value) {
      const filteredValueList = valueList.filter(Option => Option.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      return filteredValueList;
    } else {
      return valueList;
    }
  }

  /**to check -- both currency code and amount are entered  */
  private isCurrencyValid() {
    let controlNamesWithCurrencyList: any =  Object.entries(this.AdditionalDetailsFormGroup.value);
    controlNamesWithCurrencyList = controlNamesWithCurrencyList.filter(ele => {
      return this.configField.find(curr =>curr.type ==="Currency" && curr.displayName === ele[0] )
    });
    let isCurrencyValid = controlNamesWithCurrencyList.map(curr => {
      if((curr[1].currencyAmount && !curr[1].currencyCode) || (!curr[1].currencyAmount && curr[1].currencyCode)) {
        return false;
      }
    }).includes(false);
    if(!isCurrencyValid) {
      return true
    }
    return false;
  }

}
