import { BaseClientService } from './base-client.service';
import { Injectable } from '@angular/core';
import { Location } from '../models/location';
import { Observable, of } from 'rxjs';
import { RemoteLoggingService } from './remote-logging.service';
import { map, catchError } from 'rxjs/operators';
import { Address } from '../models/address_cost_model.model';
import { locationData, cityData } from '../models/constants';
import { LoggerService } from './logger.service';
import { stateList, ADDRESS_DATA } from '../data/cost-model-data';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  /**
   * base constructor
   * @param baseClientService baseclient service
   * @param logService remote logging service
   */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logService: RemoteLoggingService,
    private readonly customLogger: LoggerService
  ) { }
  /**mock list for location data */
  locationList = locationData;
  /**mock city list */
  cityList = cityData;
  /** Return the candidate json list and loop to display in the table */
  getCities(): Observable<Array<string>> {
    return of(this.cityList);
    // return this.baseClientService.get<string>('endpoints').pipe(
    //   map(r => r.body),
    //   catchError(err => {
    //     this.logService.logger('', 'Failed to fetch cities', err);
    //     const emptyArray: string[] = [];

    //     return of(emptyArray);
    //   })
    // );
  }

  getStates(): any[] {
    return stateList;
  }

  /** Return the candidate json list and loop to display in the table */
  getLocations(): Observable<Array<Location>> {
    // return this.baseClientService.getArr<Location>('/endpoints').pipe(
    //   map(r => r.body),
    //   catchError(err => {
    //     this.customLogger.error('Failed to fetch locations', err);
    //     const emptyArray: Location[] = [];
    //     return of(emptyArray);
    //   })
    // );
    return of (this.locationList);
  }


  /**
   * used to fetch places data
   * @param searchTerm place search term
   */
  getAddresses(searchTerm: string): Observable<Array<Address>> {
    // return this.baseClientService.getArr<Address>(`/candidate/location/domestic/city/${searchTerm}`).pipe(
    //   map(r => r.body),
    //   catchError(err => {
    //     this.customLogger.error('Failed to fetch locations', err);
    //     const emptyArray: Address[] = [];
    //     return of(emptyArray);
    //   })
    // );
    return of (ADDRESS_DATA);
  }

}
