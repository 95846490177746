// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  registerUser: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/registerAdmin',
  login: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/login',
  usersList: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/allAdminUsers',
  createUser: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/registerAdmin',
  updateUser: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/updateAdminUser',
  deleteUser: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/deleteUser',
  clientList: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/getClients',
  creatClient: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/createClient',
  updateClient: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/updateClient',
  deleteClient: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/deleteClient',
  bbdata: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/benefitBuilder',
  bbadmin: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/allAdmin',
  dashboard: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/employeeDetails',
  landing: 'https://apigwusw2dev01.cartus.com/demoApp/api/v1/landingPage'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
