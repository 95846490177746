import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of, empty } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { EmployeeDetailsView } from './../models/employee-details-view.model';
import { AggregationFetchparameter } from '../models/aggregation-fetch-parameter.model';
import { EmployeeInfo } from '../models/employee-info.model';
import { HttpParams } from '@angular/common/http';
import { MoveProDashboadDataService } from 'projects/BBAdmin/src/app/core/data/move-pro-dashboad-data.service';

/** EmployeeDetailsService to fetch the data from api and display in a table */
@Injectable({
  providedIn: 'root'
})
export class EmployeeDetailsService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  tempEmployeeData = { "data": new Array };
  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private moveProDashboadDataService: MoveProDashboadDataService
  ) { 
    this.tempEmployeeData = this.moveProDashboadDataService.employeeDataSubject.getValue();
  }
  

  /** getEmployeeDetails method to get the reponse from the api using base client service */
  getEmployeeDetails(aggregationFetchparameter: AggregationFetchparameter): Observable<EmployeeDetailsView> {
    let filters: any;

    if ('filters' in aggregationFetchparameter && aggregationFetchparameter.filters.favorites == null) {
      aggregationFetchparameter.filters.favorites = '';
    }

    if (aggregationFetchparameter.filters) {
      filters = (aggregationFetchparameter.filters);
    }
    let text = '';
    if (aggregationFetchparameter.searchText) {
      text = aggregationFetchparameter.searchText;
    }

    // let sortColumnName = 'lastName';
    // let sortOrder = 'asc';

    // if (!('sortObject' in aggregationFetchparameter) || aggregationFetchparameter.sortObject.length == 0) {
    //   aggregationFetchparameter.sortObject = [
    //     { sortColumnName: 'lastName', sortOrder: 'asc' },
    //     { sortColumnName: 'firstName', sortOrder: 'asc' },
    //     { sortColumnName: 'fileNumber', sortOrder: 'asc' }
    //   ];
    // }

    const body = {
      itemsPerPage: aggregationFetchparameter.itemsPerPage,
      pageNumber: aggregationFetchparameter.pageNumber,
      // sortColumnName: aggregationFetchparameter.sortObject[0].sortColumnName,
      // sortOrder: aggregationFetchparameter.sortObject[0].sortOrder,
      sortObject: aggregationFetchparameter.sortObject,
      searchText: text,
      filters: filters,
      favorites: aggregationFetchparameter.favorites,
      currencyAbbr: aggregationFetchparameter.preferredCurr
    };
    aggregationFetchparameter.preferredCurr ? body.currencyAbbr = aggregationFetchparameter.preferredCurr : delete body.currencyAbbr;
    // return this.baseClient.post<EmployeeDetailsView>(`v1/employeeDetails`, body, 'get employee details').pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     const empty: EmployeeDetailsView = null;
    //     this.logSvc.logError(err);
    //     return of(empty);
    //   })
    // );
    this.moveProDashboadDataService.employeeDataSubject.subscribe(
      data => {
        this.tempEmployeeData = data;
      }
    )
    let parentList = this.tempEmployeeData.data;
    let employeeDatas = {
      data: [],
      count: 0,
    };
    if ( aggregationFetchparameter.searchText) {
            let searchText = aggregationFetchparameter.searchText;
            let searchlist = [];
            parentList.forEach( element => {
              let currentNme = `${element.lastName},${element.firstName}`;
              let depaturearr = `${element.departureCountry},${element.departureCity},${element.departureState}`;
              let destinationarr = `${element.destinationCountry},${element.destinationCity},${element.destinationState}`;
              if (
                currentNme.toLowerCase().includes(searchText.toLowerCase()) ||
                depaturearr.toLowerCase().includes(searchText.toLowerCase()) ||
                destinationarr.toLowerCase().includes(searchText.toLowerCase())||
                element.policyName.toLowerCase().includes(searchText.toLowerCase())||
                element.divisionName.toLowerCase().includes(searchText.toLowerCase())||
                element.milestoneDesc.toLowerCase().includes(searchText.toLowerCase())
        ) {
              searchlist.push(element);
        }
      })
      if (searchlist.length > 0) {
        parentList = searchlist;
      } else {
        employeeDatas.data = searchlist;
        employeeDatas.count = searchlist.length;
        if (aggregationFetchparameter.sortColumnName && aggregationFetchparameter.sortOrder) {
          return this.sortTransfereeList(employeeDatas.data,aggregationFetchparameter);
        }
        return of(JSON.parse(JSON.stringify(employeeDatas)));
      }
    }
    if (
      aggregationFetchparameter.filters &&
      aggregationFetchparameter.filters.policy &&
      aggregationFetchparameter.filters.policy.length > 0
    ) {
      let policyArray = [];
      let policyKey = aggregationFetchparameter.filters.policy;
      parentList.forEach((ele) => {
        policyKey.forEach((element) => {
          if (ele.policyName.includes(element)) {
            policyArray.push(ele);
          }
        });
      });
      if (policyArray.length > 0) {
        parentList = policyArray;
      } else {
        employeeDatas.data = policyArray;
        employeeDatas.count = policyArray.length;
        if (aggregationFetchparameter.sortColumnName && aggregationFetchparameter.sortOrder) {
          return this.sortTransfereeList(employeeDatas.data, aggregationFetchparameter);
        }
        return of(JSON.parse(JSON.stringify(employeeDatas)));
      }
    }
    if (
      aggregationFetchparameter.filters &&
      aggregationFetchparameter.filters.fileStatusDesc &&
      aggregationFetchparameter.filters.fileStatusDesc.length > 0
    ) {
      let fileArray = [];
      let fileKey = aggregationFetchparameter.filters.fileStatusDesc;
      parentList.forEach((ele) => {
        fileKey.forEach((element) => {
          if (ele.fileStatusDesc.includes(element)) {
            fileArray.push(ele);
          }
        });
      });
      if (fileArray.length > 0) {
        parentList = fileArray;
      } else {
        employeeDatas.data = fileArray;
        employeeDatas.count = fileArray.length;
        return of(JSON.parse(JSON.stringify(employeeDatas)));
      }
    }
    if (
      aggregationFetchparameter.filters &&
      aggregationFetchparameter.filters.movePhase &&
      aggregationFetchparameter.filters.movePhase.length > 0
    ) {
      let movePhaseArrays = [];
      let movephaseKey = aggregationFetchparameter.filters.movePhase;
      parentList.forEach((ele) => {
        movephaseKey.forEach((element) => {
          if (
            element.value === "N/A" &&
            ele.movePhase.id === null &&
            ele.movePhase.altText.length === 0
          ) {
            movePhaseArrays.push(ele);
          } else if (ele.movePhase.id === parseInt(element.key)) {
            movePhaseArrays.push(ele);
          }
        });
      });
      if (movePhaseArrays.length > 0) {
        parentList = movePhaseArrays;
      } else {
        employeeDatas.data = movePhaseArrays;
        employeeDatas.count = movePhaseArrays.length;
        if (aggregationFetchparameter.sortColumnName && aggregationFetchparameter.sortOrder) {
          return this.sortTransfereeList(employeeDatas.data, aggregationFetchparameter);
        }
        return of(JSON.parse(JSON.stringify(employeeDatas)));
      }
    }
    if (
      aggregationFetchparameter.filters &&
      aggregationFetchparameter.filters.division &&
      aggregationFetchparameter.filters.division.length > 0
    ) {
      let divisionArray = [];
      let divisionKey = aggregationFetchparameter.filters.division;
      parentList.forEach((ele) => {
        divisionKey.forEach((element) => {
          if (
            ele.divisionNumber.includes(element.divisionNumber) ||
            ele.divisionName.includes(element.divisionName)
          ) {
            divisionArray.push(ele);
          }
        });
      });
      if (divisionArray.length > 0) {
        parentList = divisionArray;
      } else {
        employeeDatas.data = divisionArray;
        employeeDatas.count = divisionArray.length;
        return of(JSON.parse(JSON.stringify(employeeDatas)));
      }
    }
    if (
      aggregationFetchparameter.filters &&
      aggregationFetchparameter.filters.departure &&
      aggregationFetchparameter.filters.departure.length > 0
    ) {
      let filterdepartureList = [];
      let filtersearches = [];
      if (
        aggregationFetchparameter.filters.departure[0].values &&
        Array.isArray(aggregationFetchparameter.filters.departure[0].values)
      ) {
        aggregationFetchparameter.filters.departure[0].values.forEach((ele) => {
          filtersearches.push(ele);
        });
      } else if (aggregationFetchparameter.filters.departure[0].values) {
        filtersearches.push(
          aggregationFetchparameter.filters.departure[0].values
        );
      }
      if (
        aggregationFetchparameter.filters.departure[1].values &&
        Array.isArray(aggregationFetchparameter.filters.departure[1].values)
      ) {
        aggregationFetchparameter.filters.departure[1].values.forEach((ele) => {
          filtersearches.push(ele);
        });
      } else if (aggregationFetchparameter.filters.departure[1].values) {
        filtersearches.push(
          aggregationFetchparameter.filters.departure[1].values
        );
      }
      if (
        aggregationFetchparameter.filters.departure[2].values &&
        Array.isArray(aggregationFetchparameter.filters.departure[2].values)
      ) {
        aggregationFetchparameter.filters.departure[2].values.forEach((ele) => {
          filtersearches.push(ele);
        });
      } else if (aggregationFetchparameter.filters.departure[2].values) {
        filtersearches.push(
          aggregationFetchparameter.filters.departure[2].values
        );
      }
      if (filtersearches.length > 0) {
        parentList.forEach((element) => {
          let depaturearr = `${element.departureCountry},${element.departureState},${element.departureCity}`;
          filtersearches.forEach((ele) => {
            if (depaturearr.toLowerCase().includes(ele.toLowerCase())) {
              filterdepartureList.push(element);
            }
          });
        });
        let departureSet = new Set(filterdepartureList);
        let dispalydeparture = [...departureSet];
        if (dispalydeparture.length > 0) {
          parentList = dispalydeparture;
        } else {
          employeeDatas.data = dispalydeparture;
          employeeDatas.count = dispalydeparture.length;
          if (aggregationFetchparameter.sortColumnName && aggregationFetchparameter.sortOrder) {
            return this.sortTransfereeList(employeeDatas.data, aggregationFetchparameter);
          }
          return of(JSON.parse(JSON.stringify(employeeDatas)));
        }
      }
    }
    if (
      aggregationFetchparameter.filters &&
      aggregationFetchparameter.filters.destination &&
      aggregationFetchparameter.filters.destination.length > 0
    ) {
      let destinationfilterlist = [];
      let filtersearches = [];
      if (
        aggregationFetchparameter.filters.destination[0].values &&
        Array.isArray(aggregationFetchparameter.filters.destination[0].values)
      ) {
        aggregationFetchparameter.filters.destination[0].values.forEach(
          (ele) => {
            filtersearches.push(ele);
          }
        );
      } else if (aggregationFetchparameter.filters.destination[0].values) {
        filtersearches.push(
          aggregationFetchparameter.filters.destination[0].values
        );
      }
      if (
        aggregationFetchparameter.filters.destination[1].values &&
        Array.isArray(aggregationFetchparameter.filters.destination[1].values)
      ) {
        aggregationFetchparameter.filters.destination[1].values.forEach(
          (ele) => {
            filtersearches.push(ele);
          }
        );
      } else if (aggregationFetchparameter.filters.destination[1].values) {
        filtersearches.push(
          aggregationFetchparameter.filters.destination[1].values
        );
      }
      if (
        aggregationFetchparameter.filters.destination[2].values &&
        Array.isArray(aggregationFetchparameter.filters.destination[2].values)
      ) {
        aggregationFetchparameter.filters.destination[2].values.forEach(
          (ele) => {
            filtersearches.push(ele);
          }
        );
      } else if (aggregationFetchparameter.filters.destination[2].values) {
        filtersearches.push(
          aggregationFetchparameter.filters.destination[2].values
        );
      }
      if (filtersearches.length > 0) {
        parentList.forEach((element) => {
          let destinationarr = `${element.destinationCountry},${element.destinationState},${element.destinationCity}`;
          filtersearches.forEach((ele) => {
            if (destinationarr.toLowerCase().includes(ele.toLowerCase())) {
              destinationfilterlist.push(element);
            }
          });
        });
        let destinationSet = new Set(destinationfilterlist);
        let displaydestination = [...destinationSet];
        if (displaydestination.length > 0) {
          parentList = displaydestination;
        } else {
          employeeDatas.data = displaydestination;
          employeeDatas.count = displaydestination.length;
          if (aggregationFetchparameter.sortColumnName && aggregationFetchparameter.sortOrder) {
            return this.sortTransfereeList(employeeDatas.data, aggregationFetchparameter);
          }
          return of(JSON.parse(JSON.stringify(employeeDatas)));
        }
      }
    }
    if (
      aggregationFetchparameter.filters &&
      aggregationFetchparameter.filters.transferStartDate
    ) {
      let daterangedata = [];
      let Startdate = aggregationFetchparameter.filters.transferStartDate;
      let Enddate = aggregationFetchparameter.filters.transferEndDate;
      let currentdate = Date.parse(Date());

      parentList.forEach((ele) => {
        if (Enddate === null && Date.parse(ele.effectiveTransferDate) >= Date.parse(Startdate) &&
          Date.parse(ele.effectiveTransferDate) <= currentdate) {
          daterangedata.push(ele);
        }
        else if (
          (Date.parse(ele.effectiveTransferDate) >= Date.parse(Startdate) &&
            Date.parse(ele.effectiveTransferDate) <= Date.parse(Enddate))
        ) {
          daterangedata.push(ele);
        }
      });

      if (daterangedata.length > 0) {
        parentList = daterangedata;
      } else {
        employeeDatas.data = daterangedata;
        employeeDatas.count = daterangedata.length;
        if (aggregationFetchparameter.sortColumnName && aggregationFetchparameter.sortOrder) {
          return this.sortTransfereeList(employeeDatas.data, aggregationFetchparameter);
        }
        return of(JSON.parse(JSON.stringify(employeeDatas)));
      }
    }
    if (
      (aggregationFetchparameter.filters &&
        aggregationFetchparameter.filters.minTotalCost) ||
        (
          aggregationFetchparameter.filters &&
          aggregationFetchparameter.filters.minTotalCost === 0
        )
    ) {
      let totalCostData = [];
      let minCost = parseInt(aggregationFetchparameter.filters.minTotalCost);
      let maxCost = aggregationFetchparameter.filters.maxTotalCost;
      let futureCost = 1000000;
      parentList.forEach((ele) => {
        if (
          maxCost === null &&
          ele.totalCost >= minCost &&
          ele.totalCost <= futureCost
        ) {
          totalCostData.push(ele);
        } else if (
          ele.totalCost >= minCost &&
          ele.totalCost <= parseInt(maxCost)
        ) {
          totalCostData.push(ele);
        }
      });

      if (totalCostData.length > 0) {
        parentList = totalCostData;
      } else {
        employeeDatas.data = totalCostData;
        employeeDatas.count = totalCostData.length;
        if (aggregationFetchparameter.sortColumnName && aggregationFetchparameter.sortOrder) {
          return this.sortTransfereeList(employeeDatas.data, aggregationFetchparameter);
        }
        return of(JSON.parse(JSON.stringify(employeeDatas)));
      }
    }
    if (
      aggregationFetchparameter.filters &&
      aggregationFetchparameter.filters.favorites === 1) {
    let  favArray = []
      parentList.forEach(ele => {
        if (ele.favUser === 1) {
          favArray.push(ele);
        }
      });
      if (favArray.length > 0) {
        parentList = favArray;
      } else {
        employeeDatas.data = favArray;
        employeeDatas.count = favArray.length;
        if (aggregationFetchparameter.sortColumnName && aggregationFetchparameter.sortOrder) {
          return this.sortTransfereeList(employeeDatas.data, aggregationFetchparameter);
        }
        return of(JSON.parse(JSON.stringify(employeeDatas)));
      }
    }
    employeeDatas.data = parentList;
    employeeDatas.count = parentList.length;
    if (aggregationFetchparameter.sortColumnName && aggregationFetchparameter.sortOrder) {
      return this.sortTransfereeList(employeeDatas.data, aggregationFetchparameter);
    }
    return of(JSON.parse(JSON.stringify(employeeDatas)));
  }
  sortTransfereeList(parentList, aggregationFetchParameter) {
    switch (aggregationFetchParameter.sortColumnName) {
      case "lastName":
        let lastName = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.lastName.toLowerCase() <
                    b.lastName.toLowerCase()
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.lastName.toLowerCase() <
                    a.lastName.toLowerCase()
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(lastName)));
      case "moveRisk":
        let moveRisk = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.moveRisk <
                    b.moveRisk
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.moveRisk <
                    a.moveRisk
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(moveRisk)));
      case "divisionNumber":
        let divisionNumber = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.divisionNumber <
                    b.divisionNumber
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.divisionNumber <
                    a.divisionNumber
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(divisionNumber)));
      case "movePhaseId":
        let movePhaseId = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.movePhase.id <
                    b.movePhase.id
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.movePhase.id <
                    a.movePhase.id
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(movePhaseId)));
      case "policyName":
        let policyName = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.policyName.toLowerCase() <
                    b.policyName.toLowerCase()
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.policyName.toLowerCase() <
                    a.policyName.toLowerCase()
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(policyName)));
      case "departureCountry":
        let country =
          aggregationFetchParameter.sortOrder === "asc"
            ? parentList.sort((a, b) =>
              b.departure.toLowerCase() <
                a.departure.toLowerCase()
                ? 1
                : -1
            )
            : parentList.sort((a, b) =>
              a.departure.toLowerCase() <
                b.departure.toLowerCase()
                ? 1
                : -1
            );
        let departure = {
          data: JSON.parse(JSON.stringify(country)),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(departure)));
      case "destinationCountry":
        let countryDes =
          aggregationFetchParameter.sortOrder === "asc"
            ? parentList.sort((a, b) =>
              b.destination.toLowerCase() <
                a.destination.toLowerCase()
                ? 1
                : -1
            )
            : parentList.sort((a, b) =>
              a.destination.toLowerCase() <
                b.destination.toLowerCase()
                ? 1
                : -1
            );
        let destination = {
          data: JSON.parse(JSON.stringify(countryDes)),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(destination)));
      case "providerCount":
        let providerCount = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.providerCount <
                    b.providerCount
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.providerCount <
                    a.providerCount
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(providerCount)));
      case "sentimentLevel":
        let sentimentLevel = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.sentimentLevel <
                    b.sentimentLevel
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.sentimentLevel <
                    a.sentimentLevel
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(sentimentLevel)));
      case "effectiveTransferDate":
        let effectiveTransferDate = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.effectiveTransferDate < b.effectiveTransferDate ? 1 : -1
                ) &&
                parentList.sort(
                  (a, b) =>
                    +new Date(a.effectiveTransferDate) -
                    +new Date(b.effectiveTransferDate)
                )
                : parentList.sort((a, b) =>
                  b.effectiveTransferDate < a.effectiveTransferDate ? 1 : -1
                ) &&
                parentList.sort(
                  (a, b) =>
                    +new Date(b.effectiveTransferDate) -
                    +new Date(a.effectiveTransferDate)
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(effectiveTransferDate)));
      case "milestones":
        let milestoneDate = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.milestoneDate < b.milestoneDate ? 1 : -1
                ) &&
                parentList.sort(
                  (a, b) =>
                    +new Date(a.milestoneDate) -
                    +new Date(b.milestoneDate)
                )
                : parentList.sort((a, b) =>
                  b.milestoneDate < a.milestoneDate ? 1 : -1
                ) &&
                parentList.sort(
                  (a, b) =>
                    +new Date(b.milestoneDate) -
                    +new Date(a.milestoneDate)
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(milestoneDate)));
      case "totalCost":
        let totalCost = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.totalCost <
                    b.totalCost
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.totalCost <
                    a.totalCost
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(totalCost)));
      case "fileStatusDesc":
        let fileStatusDesc = {
          data: JSON.parse(
            JSON.stringify(
              aggregationFetchParameter.sortOrder === "asc"
                ? parentList.sort((a, b) =>
                  a.fileStatusDesc.toLowerCase() <
                    b.fileStatusDesc.toLowerCase()
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.fileStatusDesc.toLowerCase() <
                    a.fileStatusDesc.toLowerCase()
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(JSON.parse(JSON.stringify(fileStatusDesc)));
    }
  }

  /** get the employee information baased on the file number */
  getEmployeeInfo(fileNumber: number, favUser: number): Observable<EmployeeInfo> {
    // return this.baseClient.getOne<EmployeeInfo>(`v1/empInfo/${fileNumber}?favUser=${favUser}`, 'getting user').pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     const empty: EmployeeInfo = null;
    //     this.logSvc.logError(err);
    //     return of(empty);
    //   })
    // );
    let response = [];
    let employeeInfo = {};
   // employeeData.data.filter((ele) => {
      this.tempEmployeeData.data.filter((ele) => {
      if (ele.fileNumber == fileNumber) {
        response.push(ele);
      }
    });
    employeeInfo = {
      "firstName": response[0].firstName,
      "lastName": response[0].lastName,
      "fileNumber": response[0].fileNumber,
      "clientEmployeeId": response[0].clientEmployeeId,
      "movePhase": {
        "movePhaseId": response[0].movePhase.id,
        "movePhaseDesc": response[0].movePhase.altText
      },
      "policyName": response[0].policyName,
      "departureCity": response[0].departureCity,
      "departureState": response[0].departureState,
      "departureCountry": response[0].departureCountry,
      "destinationCity": response[0].destinationCity,
      "destinationState": response[0].destinationState,
      "destinationCountry": response[0].destinationCountry,
      "totalCost": response[0].totalCost,
      "totalCostCurrency": response[0].totalCostCurr,
      "fileStatusDesc": response[0].fileStatusDesc,
      "accompanyingMemberCount": 4,
      "moveTypeDesc": "Long Term Expat",
      "divisionName": response[0].divisionName,
      "divisionNumber": response[0].divisionNumber,
      "consultant": {
        "consFirstName": "Bev",
        "consLastName": "Hillier",
        "consultantEmail": [
          {
            "consEmailAddr": "beverley.hillier@cartus.com"
          }
        ],
        "consultantPhone": [
          {
            "consPhoneNumber": "1793756480",
            "consPhoneDialingCode": "44"
          }
        ]
      },
      "email": [
        {
          "emailAddress": response[0].firstName + '.' + response[0].lastName + '@gmail.Com',
          "emailAddressDesc": "Home Email Address"
        },
        {
          "emailAddress": response[0].firstName + '.' + response[0].lastName + '@demo.Com',
          "emailAddressDesc": "Business Email Address"
        }
      ],
      "phone": [
        {
          "phoneNumber": "030987884",
          "phoneDialingCode": "971",
          "phoneNumberDesc": "Mobile/Cell"
        }
      ]
    }
    return of(JSON.parse(JSON.stringify(employeeInfo)));
  }


  /** update favorite based on the file number */
  updateFavorite(favoriteParam: any): Observable<any> {
    // return this.baseClient.put<any>(`v1/favorites`, favoriteParam, 'update favorite').pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     this.logSvc.logError(err);
    //     // tslint:disable-next-line: deprecation
    //     return of(empty);
    //   })
    // );
    let favourites = [];
    //employeeData.data.filter((ele) => {
    this.tempEmployeeData.data.filter((ele) => {
      if (ele.fileNumber === favoriteParam.favoriteData[0].fileNumber) {
        ele.favUser = favoriteParam.favoriteData[0].favUser;
      }
      if (ele.favUser === 1) {
        favourites.push(ele.fileNumber);
      }
    });
    let response = {
      appName: "MovePro360",
      createdBy: "5e5ee8f5c78aa40008670d36",
      name: "employeeFavorites",
      partyId: "5e5ee8f5c78aa40008670d36",
      updatedBy: "5e5ee8f5c78aa40008670d36",
      value: favourites,
      __v: 10,
      _id: "6041cfc7915f7c0008b93174"
    }
    return of(JSON.parse(JSON.stringify(response)));
  }

  /** get favorite List */
  getFavoriteList(): Observable<any> {
    return this.baseClient.getOne<any>(`v1/favoritesList`, 'get favorite list').pipe(
      map(r => r.body),
      catchError((err, source) => {
        this.logSvc.logError(err);
        // tslint:disable-next-line: deprecation
        return of(empty);
      })
    );
  }
}

