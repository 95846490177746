import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { FamilyMember } from './../../../core/models/family-member.model';
import { phoneDetails } from './../../../core/models/phone.model';
import { BaseClientService } from '../../../core/services/base-client.service';
import { catchError, map, delay } from 'rxjs/operators';
import { FormGroup, FormArray } from '@angular/forms';
import { familyData } from '../../../core/data/data';

export interface ContactFormValue {
  phoneDetails: Array < {
    primary: boolean,
    type: string,
    phoneDialCode: number,
    phoneNumber: number
  } > ;
  emailDetails: Array < {
    primary: boolean,
    emailAddress: string,
    usageType: string
  } > ;
}

export const phoneTypes: Array < string > = ['Mobile', 'Departure Business', 'Departure Residence',
  'Destination Business', 'Destination Residence'
];
export const emailTypes: Array < string > = ['personal', 'business'];

@Injectable({
  providedIn: 'root'
})

export class FamilyInfoService {
  /** Instance of BehaviorSubject of Array of Family member for family information*/
//  familyInformation = new BehaviorSubject < Array < FamilyMember > > (null);
//  familyInfo = this.familyInformation.asObservable();

  /** Instance of BehaviorSubject of type Family member for family information*/
 // familyMemberInformation = new BehaviorSubject < FamilyMember > (null);

  /**
   * @param baseClientService Inject base client service
   */
  constructor(private readonly baseClientService: BaseClientService) {
    // this.getFamilyMemberInformation();
  }


  /**
   * Function to Update the familyinformation subject
   * @param data updated familyinformation
   */
  // updateFamilyInfo(data: Array < FamilyMember > ) {
  //   this.familyInformation.next(data);
  // }

  // /**
  //  * Function to Get family member information and update Shared-Service
  //  */
  // getFamilyMemberInformation() {
  //   if (!this.familyInformation.getValue()) {
  //     this.getFamilyMemberInfo().subscribe(data => {
  //       if (data) {
  //         this.updateFamilyInfo(data);
  //       }
  //     });
  //   } else {
  //     return this.familyInformation.getValue();
  //   }
  // }

  /**
   * Get Family information using endpoint
   */
  getFamilyMemberInfo(): Observable<Array<FamilyMember>> {
    // return this.baseClientService
    //   .getArr < FamilyMember > (`v1/profile/family`, null, null, true)
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //       console.log('Failed to get family details', err);
    //       const emptyResp: FamilyMember[] = null;
    //       return of(emptyResp);
    //     })
    //   );
    return of(familyData);
  }
  /**
   * Add Family Member information
   */
  adddFamilyMemberDetails(
    familyMemberDetail: FamilyMember
  ): Observable < any > {
    return this.baseClientService
      .post < FamilyMember > (`v1/profile/family/`, familyMemberDetail, null, null, true)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: FamilyMember = null;
          console.log('Failed to add family details', err);
          return of(empty);
        })
      );
  }
  /**
   * Update Family Member information
   */
  updateFamilyMemberDetails(
    familyMemberId, familyMemberDetail: FamilyMember
  ): Observable < any > {
    return this.baseClientService
      .put < FamilyMember > (`v1/profile/family/${familyMemberId}`, familyMemberDetail, null, null, true)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty: FamilyMember = null;
          console.log('Failed to update family details', err);
          return of(empty);
        })
      );
  }
  /**
   * Delete Family information using endpoint
   */
  deleteFamilyMemberInfo(familyMemberId) {
    return this.baseClientService
      .delete(`v1/profile/family/${familyMemberId}`, null, null, true)
      .pipe(
        map(r => r.status),
        catchError(err => {
          console.log('Failed to delete family details', err);
          const emptyResp: FamilyMember = null;
          return of(emptyResp);
        })
      );
  }
  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  isNullOrUndefinedSafeCheck(obj: Object, key: string) {
    try {
      return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }

  /** Create Phone Email Sub List used by needs assessment and add family information */
  initPhoneEmailList(contactFormValue: ContactFormValue, phoneTypesList: Array < Array < string >> ,
    emailTypeList: Array < Array < string >> ): {
    phoneTypes: Array < Array < string >> ,
    emailTypes: Array < Array < string >>
  } {

    const usedPhoneTypes = [];
    const usedEmailTypes = [];

    contactFormValue.phoneDetails.forEach((phone, index) => {
      phoneTypesList[index] = [...phoneTypes];
      if (index == 0) {
        usedPhoneTypes.push(phone.type);
      } else {
        phoneTypesList[index] = phoneTypesList[index].filter(phoneType => !usedPhoneTypes.includes(phoneType));
      }
    });

    contactFormValue.emailDetails.forEach((email, index) => {
      emailTypeList[index] = [...emailTypes];
      if (index == 0) {
        usedEmailTypes.push(email.usageType);
      } else {
        emailTypeList[index] = emailTypeList[index].filter(emailType => !usedEmailTypes.includes(emailType));
      }
    });

    return {
      phoneTypes: phoneTypesList,
      emailTypes: emailTypeList
    };

  }
  /**
   * Remove row from Mobile or Email and insert the deleted email/phone type to the list of email/phone
   * used by needs assessment and add family information
   * @param type location of the row
   * @param index index of the location
   * @param contactForm Contact Form
   * @param phoneTypes Phone List
   * @param emailTypes Email List
   */
  deletePhoneEmailRow(type: 'phone' | 'email', index: number, contactForm: FormGroup, phoneTypes: any,
    emailTypes: any): Array < Array < string >> {
    if (type === 'phone') {
      const missingPhoneType: string = (contactForm.getRawValue() as ContactFormValue).phoneDetails[index].type;
      if (missingPhoneType) {
        phoneTypes = phoneTypes.map(phoneType => {
          phoneType.push(missingPhoneType);
          return [...new Set(phoneType)];
        });
      }
      (contactForm.controls.phoneDetails as FormArray).removeAt(index);
      return phoneTypes;
    } else {
      const missingEmailType: string = (contactForm.getRawValue() as ContactFormValue).emailDetails[index].usageType;
      if (missingEmailType) {
        emailTypes = emailTypes.map(emailType => {
          emailType.push(missingEmailType);
          return [...new Set(emailType)];
        });
      }
      (contactForm.controls.emailDetails as FormArray).removeAt(index);
      return emailTypes;
    }
  }

  /**
   * Converting Phone Details List for API to understand as there is no .type key used by needs assessment and add family information
   * @param phoneDetails List of Phones
   */
  updatePhoneType(phoneDetails: Array < phoneDetails > ): Array < phoneDetails > {
    phoneDetails.forEach(phoneDetail => {

      phoneDetail.type === 'Mobile' ? (phoneDetail.usageType = 'personal', phoneDetail.textingAvailable = true) : false;
      phoneDetail.type == 'Departure Business' ? (phoneDetail.usageType = 'business', phoneDetail.locationType = 'departure') : null;
      phoneDetail.type == 'Destination Business' ? (phoneDetail.usageType = 'business', phoneDetail.locationType = 'destination') : null;
      phoneDetail.type == 'Departure Residence' ? (phoneDetail.usageType = 'personal', phoneDetail.locationType = 'departure') : null;
      phoneDetail.type == 'Destination Residence' ? (phoneDetail.usageType = 'personal', phoneDetail.locationType = 'destination') : null;
      delete phoneDetail['type'];

      // Convert phone number to string
      phoneDetail.phoneNumber = (phoneDetail.phoneNumber).toString();

      // Delete Phone Id if its newly added
      (phoneDetail._id === '') ? delete phoneDetail._id: null;
    });

    return phoneDetails;
  }
}
