export const tempLivingData = {
        "policyDetails": {
          "clientName": "Demo Client",
          "clientNumber": "4708",
          "policyName": "Global Test Policy",
          "orderReference": "MOV-20220425-3000",
          "fileNumber": "295912220",
          "geographicOrigin": "US"
        },
        "customerDetails": {
          "customerFirstName": "Robert",
          "customerSecondName": "Angel",
          "emailDetailsList": [
            {
              "_id": "62663651111ed5000857db70",
              "contactType": "email",
              "primary": true,
              "usageType": "business",
              "emailAddress": "robert.angel@gmail.com"
            }
          ],
          "phoneDetailsList": [
            {
              "_id": "6266364f111ed5000857db6e",
              "textingAvailable": true,
              "contactType": "phone",
              "primary": true,
              "usageType": "personal",
              "phoneDialCode": 44,
              "phoneNumber": "587855845"
            },
            {
              "_id": "62663650111ed5000857db6f",
              "textingAvailable": false,
              "contactType": "phone",
              "primary": false,
              "usageType": "business",
              "phoneDialCode": 1,
              "phoneNumber": "6754321",
              "locationType": "departure"
            }
          ],
          "departureAddr": {
            "city": "Boston",
            "state": "Massachusetts",
            "country": "United States",
            "postalCode": "02114",
            "streetLine1": "55, Fruit Street, West End"
          }
        },
        "consultantDetails": {
          "emailDetailsList": [
            {
              "emailAddress": "Data.Refresh@Client-D.Com"
            }
          ],
          "timeZone": "America/New_York",
          "firstName": "Jacquelyn",
          "lastName": "Rae",
          "phoneDetailsList": [
            {
              "phoneNumber": "5845803430",
              "phoneDialingCode": "1"
            }
          ]
        },
        "needsAssessment": {
          "departureLocation": "United States",
          "petsInformation": [],
          "petDepositApproved": false,
          "travelHistory": [
            "Afghanistan",
            "Aland Islands"
          ],
          "assessNeedsDraft": true,
          "assessNeedsSubmittedDate": null,
          "accompanyingMembers": [
            // {
            //   "nameDetails": {
            //     "title": "Mr",
            //     "firstName": "Ron",
            //     "lastName": "Frost"
            //   },
            //   "phoneDetailsList": [],
            //   "emailDetailsList": [],
            //   "id": "62a0669976f60e0009473c82",
            //   "dateOfBirth": null,
            //   "relationshipType": "Child",
            //   "age": {
            //     "currentAge": 25,
            //     "recordedAtDate": "2022-06-08"
            //   },
            //   "isTravelling": true
            // },
            // {
            //   "nameDetails": {
            //     "title": "Mrs.",
            //     "firstName": "Jane",
            //     "lastName": "Sloan"
            //   },
            //   "phoneDetailsList": [],
            //   "emailDetailsList": [],
            //   "id": "62a0665e76f60e0009473c81",
            //   "dateOfBirth": null,
            //   "relationshipType": "Aunt",
            //   "isTravelling": false
            // }
          ]
        },
        "financialDetails": {
          "VATCountry": "Germany",
          "VATRegistration": "DE 814 155 800",
          "remitToAddress": {
            "businessName": "Cartus Corporation",
            "careOf": null,
            "line1": "40 Apple Ridge Road",
            "line2": null,
            "line3": null,
            "city": "Danbury",
            "subnationalDivision": "CT",
            "country": "USA",
            "postalCode": "06810"
          },
          "mailingAddress": {
            "invoiceFormat": "physical",
            "physicalInvoicing": {
              "standardMailAddress": {
                "businessName": "Cartus Vendor Processing Center",
                "careOf": null,
                "line1": "PO Box 1438",
                "line2": null,
                "line3": null,
                "city": "Hartford",
                "subnationalDivision": "CT",
                "country": "USA",
                "postalCode": "06143"
              },
              "expressMailAddress": {
                "businessName": "Cartus Vendor Processing Center",
                "careOf": null,
                "line1": "758 Rainbow Rd.",
                "line2": "Attn: Imaging Room 3",
                "line3": "Ext. 1549",
                "city": "Windsor",
                "subnationalDivision": "CT",
                "country": "USA",
                "postalCode": "06095"
              }
            }
          }
        },
        "customerRelocationDetails": {
          "filePriority": "Normal",
          "serviceDeliveryLocation": "Germany",
          "serviceDeliveryLocationValueList": [
            {
              "type": "Destination",
              "city": "Hamburg",
              "country": "Germany",
              "state": "Hamburg"
            },
            {
              "type": "Departure",
              "city": "Boston",
              "country": "United States",
              "state": "Massachusetts"
            }
          ],
          "financialResponsibility": null,
          "splitBillComments": null,
          "financialResponsibilityValueList": [
            "Customer",
            "Direct Bill",
            "Split Bill"
          ],
          "providerValueList": [
            "Cartus",
            "Client Directed",
            "Client Preferred"
          ],
          "provider": null,
          "clientDirectedProviderComment": null,
          "requiresResidencyLetter": false,
          "rush": false,
          "daysAuthorized": null,
          "clientBudgetAmount": null,
          "clientBudgetCurrency": null
        },
        "housingPreference": {
          "desiredLocation": {
            "streetLine1": null,
            "city": null,
            "country": null,
            "state": null,
            "postalCode": null
          },
          "alternateLocation": {
            "streetLine1": null,
            "city": null,
            "country": null,
            "state": null,
            "postalCode": null,
          },
          "destinationOfficeLocation": {
            "streetLine1": null,
            "city": null,
            "country": null,
            "state": null,
            "postalCode": null
          },
          "priorities": [
            "Assigned Parking Available",
            "Requested Dates Available",
            "Meets Special Requests",
            "Pet Friendly",
            "Requested # of Bedrooms"
          ],
          "numberBedroomsValueList": [
            "Studio",
            "1 Bedroom",
            "2 Bedroom",
            "3 Bedroom"
          ],
          "prioritiesValueList": [
            "None",
            "Assigned Parking Available",
            "Meets Special Requests",
            "Notice to Vacate - 14 days",
            "Parking Lot Available",
            "Pet Friendly",
            "Requested # of Bedrooms",
            "Requested Dates Available",
            "Requested Floor Level - 1st",
            "Requested Floor Level - Top",
            "Requested Location",
            "Within Budget Limit"
          ],
          "additionalInstructions": null,
          "moveInDate": null,
          "moveOutDate": null,
          "numberApartments": null,
          "numberBedrooms": null,
          "babyCotRequired": false,
          "acceptableCommute": null,
          "housekeepingInfo": {
            "housekeepingFrequency": null,
            "comments": null,
            "housekeepingElemValueList": [
              "None",
              "Weekly",
              "Bi-Weekly",
              "Monthly"
            ]
          },
          "parkingInfo": {
            "parkingApproved": false,
            "financialResponsibilityValueList": [
              "Transferee",
              "Direct Bill",
              "Cartus"
            ],
            "comments": null
          },
          "accommodationTypeValueList": [
            "Company managed housing",
            "Corporate housing",
            "Hotel"
          ],
          "accommodationType": null,
          "furnished": true,
          "kitchenRequired": true,
          "lengthOfStay": null,
          "numberOfBathrooms": null,
          "numberOfBathroomsValueList": [
            1,
            2,
            3,
            4
          ]
        },
        "orderStatus": "in-progress",
        "orderStatusDate": "2022-05-05",
        "bookingAgent": [
          {
            "partyId": "619b39c1c2a30a056080d3e8",
            "partyName": "Dwellworks Living",
            "supplierID": "352142"
          }
        ],
        "supplierOrderSubmittedDate": "2022-05-04"
}

export const bookingDetailsData = {
  "propertyName": null,
  "providerName": null,
  "rentPaidBy": null,
  "apartmentType": null,
  "address": {
    "city": null,
    "state": null,
    "country": null,
    "postalCode": null,
    "streetLine1": null
  },
  "moveDates": {
    "moveIn": "2022-05-31 00:00:00",
    "moveOut": "2022-06-30 00:00:00"
  },
  "status": "in-progress",
  "statusDate": "2022-05-23",
  "bookingAgent": "Dwellworks Living",
  "supplierID": "352142"
}

export const orderPriority = {
  "values": [
      "Exec VIP",
      "Normal",
      "Premier Executive",
      "VIP"
  ]
}