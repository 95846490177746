import { Injectable } from '@angular/core';
import { TransfereeNeedsAssessment } from '../../../core/models/candidateneeds-assessment.model';
import { BaseClientService } from '../../../core/services/base-client.service';
import { catchError, map, delay } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

// import { LoggerService } from './logger.service';
import { urlType } from '../../../core/models/urlType';
import { transfereeNeedsAssessmentData } from '../../../core/data/app-data';
import { AppDataService } from '../../../core/services/app-data.service';
@Injectable({
  providedIn: 'root'
})
export class TransfereeNeedsAssessmentService {
  constructor(private readonly baseClientService: BaseClientService, private appDataService: AppDataService) { }
  /**
   * API call to Add Transferee NeedsAssessment data
   */
  addTransfereeNeedsAssessmentDetails(
    assessmentDetail: TransfereeNeedsAssessment
  ): Observable<any> {
    // return this.baseClientService
    //   .put<TransfereeNeedsAssessment>(`/v1/needsassessment`, assessmentDetail)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: TransfereeNeedsAssessment = null;
    //       console.log('Failed to add transferee details', err);
    //       return of(empty);
    //     })
    //   );
    return of(true);
  }

  /**
   * API call to Get Transferee NeedsAssessment data
   */
  getTransfereeNeedsAssessment(): Observable<TransfereeNeedsAssessment> {
    // return this.baseClientService
    //   .getById<TransfereeNeedsAssessment>(`/v1/needsassessment`)
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //       // this.customLogger.error('Failed to get transferee details', err);
    //       console.log('Failed to get transferee details', err);
    //       const emptyResp: TransfereeNeedsAssessment = null;
    //       return of(emptyResp);
    //     })
    //   );
    return of(this.appDataService.transfereeNeedsAssementStubData.getValue());
  }
}

