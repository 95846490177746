import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Output, EventEmitter } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { employeeData } from 'projects/BBAdmin/src/app/core/data/employeeData';
import { MoveProDashboadDataService } from 'projects/BBAdmin/src/app/core/data/move-pro-dashboad-data.service';
import { environment } from 'src/environments/environment';
export const tokenkey = 'jwtToken';
export const subscriptionkey = 'subscriptionEnddate'
export const sessiondurationkey = 'sessionduration'
export const sessiondurationtypekey = 'sessiondurationtype'
export const lastlogindatekey = 'lastlogindate'
export const sessionbbadminData = 'AllAdminData'
export const sessionemployeeData = 'employeeData'
export const loggedInUserData = 'loggedInUserData'
@Injectable({
  providedIn: 'root'
})
export class LoginService {
 public  isAuthenticatedUser: boolean = false;
   constructor(private http: HttpClient) { }

   authenticateUserFromBackend(userName: string, password: string) :Observable<any>{     
     let body = '{"userName":"'+userName+'","password": "'+password+'"}';
     const headers = new HttpHeaders ({'Content-Type': 'application/json'});
     const url = environment.login;
    return this.http.post<any>(url,body, {headers: headers}).pipe(
      map(
        data => {
          if(data.message == "User logged in successfully!") {
            this.isAuthenticatedUser = true;    
            this.storeLoggedInUserData(data);      
          }
          return data;
        }
      )
    );
  }
  
  storeJwtToken(token) {
    localStorage.setItem(tokenkey,token);
  }

  getJwtToken() {
    return localStorage.getItem(tokenkey);
  }

  storeSubscription(expiryDate) {
    localStorage.setItem(subscriptionkey,expiryDate);
  }

  getSubscription() {
    return localStorage.getItem(subscriptionkey);
  }

  storeSessionDuration(sessionvalue, sessionEndType) {
    localStorage.setItem(sessiondurationkey,sessionvalue);
    localStorage.setItem(sessiondurationtypekey,sessionEndType);
  }

  getSessionDuration() {
    return localStorage.getItem(sessiondurationkey);
  }
  getSessionDurationType() {
    return localStorage.getItem(sessiondurationtypekey);
  }

  storeLastLoginDate(date) {
    localStorage.setItem(lastlogindatekey,date);
  }
  getLastLoginDate() {
    return localStorage.getItem(lastlogindatekey);
  }
  clearLoginInfo(){
    localStorage.removeItem(tokenkey);
    localStorage.removeItem(subscriptionkey);
    localStorage.removeItem(sessiondurationtypekey);
    localStorage.removeItem(sessiondurationkey);
    localStorage.removeItem(lastlogindatekey);
    localStorage.removeItem(sessionbbadminData);
    localStorage.removeItem(sessionemployeeData);
    localStorage.removeItem(loggedInUserData);
  }
  isTokenValid() {
    let isValid = false;
    if(this.isSubscriptionValid()) {
      let token = this.getJwtToken();    
      if(token) {
        if(!this.istokenExpired()){
          isValid = true;
        }
      }
    }
  

    return isValid;
  }

  istokenExpired() {
    let lastLogindate = this.getLastLoginDate(); 
    let loginDate = lastLogindate.includes('GMT') ? lastLogindate :
    (lastLogindate.includes('T') ? lastLogindate.split('T')[0] : lastLogindate);      
    if(moment(moment().format('MM/DD/YYYY')).isSame(moment(loginDate).format('MM/DD/YYYY'))) {
      return false;
    }
    return true;
  }

  isSubscriptionValid() { 
    let isSubscribed = false;
    let subscriptionEndDate = this.getSubscription();
    if(subscriptionEndDate) {      
      let subscribeddates = subscriptionEndDate.includes('T') ? subscriptionEndDate.split('T')[0]: subscriptionEndDate;      
      if(moment(moment().format('MM/DD/YYYY')).isSameOrBefore(moment(subscribeddates).format('MM/DD/YYYY'))) {
        isSubscribed = true;
      }
    } else {
      isSubscribed = true;
    }
    return isSubscribed;
  }

  storeLoggedInUserData(data) {
    localStorage.setItem(loggedInUserData,JSON.stringify(data.loggedInUser.loggedInUser[0]));
  }
} 
