<div class="dialog-container" role="region">

    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px" fxLayoutGap.xs="0em" role="heading">
        {{data.program ? 'Edit' : 'Add New'}} Program
        <a class="close-icon" (click)="onDismiss()" title="close" role="button" aria-label="Close Program Modal" title="Close Program Modal">
            <mat-icon>close</mat-icon>
        </a>
    </div>

    <mat-dialog-content style="width: 550px">
        <form fxLayout="column" class="middle-section" [formGroup]="programForm">
            <!--First Name field-->
            <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                <mat-form-field>
                    <mat-label>Program Name</mat-label>
                    <input matInput placeholder="Program Name" [readonly] = "data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false" formControlName="programName" role="text">
                </mat-form-field>
            </div>
            <!-- Program Template -->
            <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                <mat-form-field>
                    <mat-label>Program Template</mat-label>
                    <mat-select formControlName="templateName"[disabled] = "data.program" >
                        <mat-option [value]="program.name" *ngFor="let program of programList" style="font-size: 14px;">
                            {{ program.name }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div fxLayout = "row" >
            <!-- Points -->
            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                <mat-form-field>
                    <mat-label>Total Points for Assigned Transferees</mat-label>
                    <input matInput number-only placeholder="Total Points for Assigned Transferees" [readonly] = "data.program && isDrafted || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false)"  formControlName="totalPoints" role="none" aria-label="Total Points for Assigned Transferees">
                </mat-form-field>
            </div>
            <!-- Program Expiration Date-->
            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                <mat-form-field>
                    <mat-label>Program Expiration (Optional)</mat-label>
                    <input matInput [min]="minDate" aria-label="Program Expiration Date" 
                    [matDatepicker]="programExpirationDate" formControlName="programExpirationDate"
                    (keypress) = "$event.charCode == 8 || $event.charCode == 46">
                    <mat-datepicker-toggle matSuffix [for]="programExpirationDate"></mat-datepicker-toggle>
                    <mat-datepicker #programExpirationDate></mat-datepicker>
                </mat-form-field>
            </div>
            </div>
            
            <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                <mat-form-field>
                    <mat-label>Base Contract </mat-label>
                    <mat-select formControlName="contractSeries" multiple aria-label="Contract Series" role="text">
                        <mat-option *ngFor="let contract of contractSeriesList" [value]="contract">
                            {{contract}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <mat-chip-list>
                <mat-chip *ngFor="let series of programForm.controls.contractSeries.value" class="contract-chip" [removable]="true"
                    (removed)="onSeriesRemoved(series)">
                    {{ series }}
                    <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
            </mat-chip-list>

            <div formArrayName="atlasPolicy">
                <div *ngFor="let policy of programForm.get('atlasPolicy')['controls']; let i = index">
                    <div [formGroupName]='i'>
                        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none">
                            <div fxFlex="93%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" role="none">
                                <mat-form-field>
                                    <mat-label>Atlas Policy</mat-label>
                                    <input type="text" aria-label="Atlas Policy" matInput #autoCompleteCci
                                        formControlName="atlasPolicyName" (click)="getFilteredPolicies(i)"
                                        (keyup)="getFilteredPolicies(i) " (keyup)="atlasPolicyFilter($event,i)"
                                        (keydown.enter)="getFilteredPolicies(i)" [matAutocomplete]="auto">
                                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayAtlasPolicy"
                                        [disabled]="data.program && !isDrafted || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false && data.program.atlasPolicy)">
                                        <mat-option (click)="filterName.value = ''"
                                            *ngFor="let policy of clientPolicies[i]; let policyIndex = index" [value]="policy"
                                            style="font-size: 14px;">
                                            {{ policy }}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div fxFlex="7%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" role="none">
                                <button mat-icon-button (click)="removePolicy(i)"
                                    [ngClass]="this.programForm.controls.atlasPolicy.length === 1?'mat-delete-btn disabled-delete':'mat-delete-btn'"
                                    [disabled]="this.programForm.controls.atlasPolicy.length === 1">
                                    <mat-icon class="delete_outline">delete_outline</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button mat-button class="text-button main-btn" (click)="addAtlasPolicy()">+ Add </button>

            <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="row" fxFlex.xs="100%" role="none" fxLayoutGap="15px">
                <div fxLayout = "column" fxFlex="50%">
                    <mat-slide-toggle [disabled] = "data.program && isDrafted || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false)" formControlName = "policyCallRequired">Policy Call Confirm</mat-slide-toggle>
                    <span class="policyCallRequired">{{programForm.value.policyCallRequired ? 'Policy Call Required' : 'Policy Call Not Required'}}</span>
                </div>
                <div fxLayout = "column" fxFlex="50%">
                    <mat-slide-toggle [disabled] = "(data.program && isDrafted) || (data.hasOwnProperty('validForDeactivation') && data.validForDeactivation==false)" formControlName = "isCartusInsured">Cartus Insured Shipping</mat-slide-toggle>
                    <span class="policyCallRequired">{{programForm.value.isCartusInsured ? 'Insured by Cartus' : 'Not Insured by Cartus'}}</span>
                </div>
            </div>            
        </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
        <button type="button" class="mat-button text-button" (click)="onDismiss()" color="primary" role="button" aria-label="Cancel and Close Modal">CANCEL</button>
        <button [disabled]="programForm.invalid" class="mat-button contained-button" (click)="submitProgramForm()" role="button" aria-label="Proceed to Next screen in Program Creation">{{data.program? 'Save Changes': 'Next'}}</button>
    </mat-dialog-actions>

</div>