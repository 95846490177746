import { StepHelp } from './step-help.model';
import { TooltipContent } from './tooltip-content.model';
/*to store the type of residence*/
export const currentResidenceOwn = 'Own';
/*to store the type of residence*/
export const currentResidenceRent = 'Rent';
/*variable stores the onePerson value to 1*/
export const peopleRelocationOnePerson = '1';
/*variable to store twoPeople value to 2*/
export const peopleRelocationTwoPeople = '2';
/* variable to declare the number of persons to 3*/
export const peopleRelocationThreePeople = '3';
/*variable to declare the number of persons to 4 or more*/
export const peopleRelocationFourPlusPeople = '4+';
/** Variable to store yes */
export const relocationRelocYes = 'Yes';
/** Variable to store no */
export const relocationRelocNo = 'No';
/** Variable to store label House */
export const typeOfHomeHouse = 'House';
/** Variable to store label Apartment */
export const typeOfHomeApartment = 'Apartment/Condo/Co-Op';
/** Variable to store label 'Townhouse'*/
export const typeOfHomeTownhouse = 'Townhouse';
/* Title to display the dialog window page title */
export const transfereeDetailsTitle = 'Transferee Details';
/**Flag mode for Create */
export const transfereeDetailsMode = 'create';
/**Flag mode for Create */
export const addCandidateMode = 'create';
/** Title to display the dialog window page title */
export const addCandidateTitle = 'Add Candidate';
/** Title for Candidtae Assesment */
export const candidateAssesmentTitle = 'Candidate Assessment';
/** Title for Recommended Budget */
export const recommendedBudgetTitle = 'Recommended Budget';
/** Title for Edit Candidate */
export const editCandidateTitle = 'Edit Candidate';
/**Flag mode for Create */
export const addCostModelMode = 'create';
/* Title to display the dialog window page title */
export const addCostModelTitle = 'Create Cost Model';
/** Title for Edit Cost Model */
export const editCostModelTitle = 'Edit Cost Model';
/** Title for Delete Cost Model */
export const deleteCostModelTitle = 'Delete Cost Model';
/*stores the city values as Danbury default*/
export const addressDetailsDefaultCity = 'Danbury, CT';
/*Number of days to be added with date to calculate start and end estimated move date */
export const moveDate = 14;
/**stores relocate count option details for relocation status no*/
export const relocateOptionNo = ['1'];
/**stores relocate count option details for relocation status yes*/
export const relocateOptionYes = ['2', '3', '4+'];
/**stores no of rooms max limit*/
export const maxRoomLimit = 99;
/**stores no of dependents moving max limit*/
export const maxdependentLimit = 99;
/**stores no of family members moving max limit*/
export const maxFamilyMemLimit = 99;
/** stores common questions */
const questions = ['What if I need help?', 'Why are we asking?'];
/** stores name title values */
export const titleOfNames = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Miss'];
/** stores relationship name values */
export const relationshipNames = ['Civil Partner', 'Fiancé', 'Partner', 'Significant Other', 'Spouse', 'Child', 'Dependent',
  'Grandparent', 'Parent', 'Sibling'];
/*stores right pane content for every step as an array*/
export const stepHelp: StepHelp[] = [
  {
    step: 0,
    question: questions[0],
    value:
      'Any help you might need will appear in this area during this process.'
  },
  {
    step: 1,
    question: questions[1],
    value: 'Family size can impact the relocation budget and services.'
  },
  {
    step: 2,
    question: questions[1],
    value:
      `Your family size may impact the relocation budget and services offered,
       especially regarding travel costs, meals and hotel arrangements.`
  },
  {
    step: 3,
    question: questions[1],
    value:
      'Your home location will allow the professional movers to select the best team to perform the packing and loading of your home items.'
  },
  {
    step: 4,
    question: questions[1],
    value:
      'You do not need to provide the exact new home address. Select your new office location if you have not made up your mind.'
  },
  {
    step: 5,
    question: questions[1],
    value:
      'You may be eligible to earn cash back rewards if you are selling your current home or planning to buy one in your new location.'
  },
  {
    step: 6,
    question: questions[1],
    value:
      'The kind of home you live in may impact the type of equipment needed to provide you with the highest level of service.'
  },
  {
    step: 7,
    question: questions[1],
    value:
      `This is used to assess the shipment size based on the furniture typically found in each room.
       Please include normal living spaces (kitchen, dining room, bedrooms, etc.)
       as well as any storage spaces such as the attic, basement, shed and garage.`
  },
  { step: 8, question: '', value: '' }
];
export const disclaimer = `Disclaimer: The video library is an education tool to explain some key benefits provided by Cartus.  Not every benefit may be offered by your client.  Please review your available benefits and if you need more information, check to see if a video on that benefit is available.`;
/*stores tooltip content for every form field as an array*/
export const tooltTip: TooltipContent[] = [
  {
    key: 'roomscount',
    question: questions[1],
    value:
      `This is used to assess the shipment size based on the furniture typically found in each room.
       Please include normal living spaces (kitchen, dining room, bedrooms, etc.)
       as well as any storage spaces such as the attic, basement, shed and garage.`
  },
  {
    key: 'movedate',
    question: questions[1],
    value: `Your estimated move date is the date you need to be out of your current home.
     Please allow time for the professional van line to pack your belongings and to load them on a truck.
     We require a minimum of 2 weeks lead time from today's date`
  }
];

/** Stores Schedule Move Message */
export const scheduleMoveMesssage = `Ready to schedule your professional move? Please review the following information
and provide any updated details. This information will be sent to our van line partner who will contact you
to coordinate your final move activities.`;
/** Title for Manage Move */
export const relocationBudgetTitle = 'Relocation Budget';
/** Variable to store yes */
export const ProfessionalVanLineMove = 'Professional Van Line Move';
/** Variable to store yes */
export const recommendedServices = 'Cash out Recommendations';
/** Variable to store Remaining */
export const remaining = 'Remaining';
/** Variable to store % */
export const percentage = '%';

export const sheduleMyMoveTileTittle = 'Schedule My Move';
export const sheduleMyMoveTileContent = 'Begin working with our Professional Van Line provider to secure your moving dates';
export const sheduleMyMoveBtnContent = 'Schedule Move';

export const cashOutTileTittle = 'Remaining Budget';
export const cashOutTileTittleTileContent = 'Amount Available for Cash Out:';
export const cashOutTileTittleBtnContent = 'Cash Out Now';
export const currency = 'USD';

/** variable to store client id */
export const clientContactID = '5d8b16401c9d440000f9bdec';
/** stores static flex services names and description */
export const flexServicesDesc = [
  {
    id: 'travelToNewLocation',
    name: 'Travel to New Location',
    desc: `The Travel to New Location estimate is the final trip from the old home to the new location
     based on the number of people traveling.  The estimate calculates meals, 1-2 nights for hotel stay
      and transportation costs either driving or by air.`
  },
  {
    id: 'temporaryLiving',
    name: 'Temporary Living',
    desc: `Temporary Living providers can offer options for when you can't move into your new residence immediately.`
  },
  {
    id: 'homeFinding',
    name: 'Home Finding',
    desc: `A Home Finding Trip covers expenses like airfare, lodging, rental car, and meals
     incurred on a trip to explore your new destination to look at homes, schools, and the community.`
  },
  {
    id: 'returnTripHome',
    name: 'Return Trip Home',
    desc: `Return Trip Home is useful for you if you report to your new work location prior to your final move.`
  },
  {
    id: 'otherMiscServices',
    name: 'Other Misc Services',
    desc: `Other Miscellaneous Services typically needed by relocating employees may include,
     but are not limited to, Vehicle Shipment, Pet Shipment, Career Assistance, and Rental Assistance.`
  },
  {
    id: 'Storage',
    name: 'Storage',
    desc: `When you're not ready to move into your new house right away, consider storing your items with
     our professional van line movers at one of their facilities.
     (If you decide to go with a self-storage facility, you will need to arrange transportation
       of items to your new home, as this is not covered in your professional van line move.
        You must inspect and submit insurance claims at the time of delivery to the self-storage facility.)`
  }
];
/** stores static core service name and description */
export const coreServiceDesc = {
  name: 'Professional Van Line',
  desc: `A Professional Van Line will ship your household goods and personal effects from your
   departure to your destination, which includes delivery and unloading of your goods at the new location.
    Full replacement insurance coverage is provided.`
};
/** stores tax not included description */
export const taxNotIncluded =
  `<span class="imp-txt uppercase">Warning:</span> <span class="notice-body">Reimbursement for
   relocation expenses is considered taxable income by the government. You will be responsible for the
    taxes associated to this relocation payment. You may want to consider holding 35% aside to cover
     any tax implications or speaking with a tax professional.</span>`;
/** stores tax included description */
export const taxIncluded =
  `<span class="imp-txt uppercase">Notice:</span> <span class="notice-body">Reimbursement for
   relocation expenses is considered taxable income by the government. Discuss with your company's HR
    or Payroll department to understand the tax implications.</span>`;
/** stores timeline dates names and descriptions */
export const timelineDateDescriptions = [
  {
    name: `Job Start Date`,
    desc: `This date is the date your company told us you begin working in your new location.`
  },
  {
    name: `Pack Date`,
    desc: `This is the date your move professionals will begin the process to document and pack your belongings. See FAQ for move details.`
  },
  {
    name: `Load Date`,
    desc: `This is the date your move professionals will load your belongings on the moving truck.`
  },
  {
    name: `Storage In Date`,
    desc: `This is the date your items arrive at the storage facility.
    Our van line partner can provide the storage facility name and address.`
  },
  {
    name: `Storage Out Date`,
    desc: `This is the date your items will be taken out of storage for the final delivery to your new location.`
  },
  {
    name: `Delivery Date`,
    desc: `This is the date your household goods delivery is expected to arrive. Your move coordinator will
    confirm the actual delivery date. See FAQ for move details.`
  }
];

export const stateList = [
  { 'name': 'Alaska', 'shortName': 'AK' },
  { 'name': 'Alabama', 'shortName': 'AL' },
  { 'name': 'Arkansas', 'shortName': 'AR' },
  { 'name': 'Arizona', 'shortName': 'AZ' },
  { 'name': 'California', 'shortName': 'CA' },
  { 'name': 'Colorado', 'shortName': 'CO' },
  { 'name': 'Connecticut', 'shortName': 'CT' },
  { 'name': 'District of Columbia', 'shortName': 'DC' },
  { 'name': 'Delaware', 'shortName': 'DE' },
  { 'name': 'Florida', 'shortName': 'FL' },
  { 'name': 'Georgia', 'shortName': 'GA' },
  { 'name': 'Hawaii', 'shortName': 'HI' },
  { 'name': 'Iowa', 'shortName': 'IA' },
  { 'name': 'Idaho', 'shortName': 'ID' },
  { 'name': 'Illinois', 'shortName': 'IL' },
  { 'name': 'Indiana', 'shortName': 'IN' },
  { 'name': 'Kansa', 'shortName': 'KS' },
  { 'name': 'Kentucky', 'shortName': 'KY' },
  { 'name': 'Lousiana', 'shortName': 'LA' },
  { 'name': 'Massachusetts', 'shortName': 'MA' },
  { 'name': 'Maryland', 'shortName': 'MD' },
  { 'name': 'Maine', 'shortName': 'ME' },
  { 'name': 'Michigan', 'shortName': 'MI' },
  { 'name': 'Minnesota', 'shortName': 'MN' },
  { 'name': 'Missouri', 'shortName': 'MO' },
  { 'name': 'Mississippi', 'shortName': 'MS' },
  { 'name': 'Montana', 'shortName': 'MT' },
  { 'name': 'North Carolina', 'shortName': 'NC' },
  { 'name': 'North Dakota', 'shortName': 'ND' },
  { 'name': 'Nebraska', 'shortName': 'NE' },
  { 'name': 'New Hampshire', 'shortName': 'NH' },
  { 'name': 'New Jersey', 'shortName': 'NJ' },
  { 'name': 'New Mexico', 'shortName': 'NM' },
  { 'name': 'Nevada', 'shortName': 'NV' },
  { 'name': 'New York', 'shortName': 'NY' },
  { 'name': 'Ohio', 'shortName': 'OH' },
  { 'name': 'Oklahoma', 'shortName': 'OK' },
  { 'name': 'Oregon', 'shortName': 'OR' },
  { 'name': 'Pennsylvania', 'shortName': 'PA' },
  { 'name': 'Rhode Island', 'shortName': 'RI' },
  { 'name': 'South Carolina', 'shortName': 'SC' },
  { 'name': 'South Dakota', 'shortName': 'SD' },
  { 'name': 'Tennessee', 'shortName': 'TN' },
  { 'name': 'Texas', 'shortName': 'TX' },
  { 'name': 'Utah', 'shortName': 'UT' },
  { 'name': 'Virginia', 'shortName': 'VA' },
  { 'name': 'Vermont', 'shortName': 'VT' },
  { 'name': 'Washington', 'shortName': 'WA' },
  { 'name': 'Wisconsin', 'shortName': 'WI' },
  { 'name': 'West Virginia', 'shortName': 'WV' },
  { 'name': 'Wyoming', 'shortName': 'WY' }
];

/** stores repeatedly using variables  */
const myProfileTitle = 'My Profile';
/**used to store tittle for relocation budget*/
export const MyProfileComponentTemplateString = {
  HEADING: myProfileTitle,
  PROFILE_CARD_TITLE: myProfileTitle,
  DETAILS_CARD_TITLE: 'Move Details',
};

/** cashout api success response message */
export const cashoutSuccessResponseMsg =
  'We have received your payment request. It may take up to 48 hours to receive the funds in your account';
/** cashout api failure response message */
export const cashoutFailureResponseMsg = 'We are facing issues in creating your payment request. Please try again later';
/** get rewards api success response message */
export const rewardsSuccessResponseMsg = `Request Sent`;
/** getrewards api failure response message */
export const rewardsFailureResponseMsg = `We are unable to process your request at this time. Please try again later.`;
/** Default Country */
export const country = 'US';
/** Date format */
export const dateFormat = 'yyyy-MM-dd';
/** Timeline checklist tsakcodes */
export const myTaskList: { [key: string]: string } = {
  Alpha_Move_MonthBeforeMove: 'At Least 1 Month Before the Move',
  Alpha_Move_TwoWeeksBeforeMove: 'At Least 2 Weeks Before the Move',
  Alpha_Move_LastWeekBeforeMove: 'Last Week Before the Move',
  Alpha_Move_DayBeforeMove: 'Day Before the Move',
  Alpha_Move_DayMoveOut: 'Day of Move-Out',
  Alpha_Move_DayMoveIn: 'Day of Move-In',
  Alpha_Move_AfterYouSettled: `After You're Settled`
};
export const myTaskListTitle = 'General Information FAQ';
/** API Failure Response Msg */
export const apiFailureResponseMsg = `We are unable to process your request at this time. Please try again later.`;
/** question type */
export const questionType = 'para';
/** Check check list title Task*/
export const checklistTitle = 'My Tasks';
/**used to store tittle for relocation budget*/
export const FaqComponentTemplateString = {
  HEADING: 'FAQ',
  HEADING_SUMMARY: `Please search below to find answers to common questions. If you require additional assistance, please contact the\
  response team at (800) 331-7356 or <span class="link">MobilifyResponseTeam@Cartus.com</span>`,
  PROFILE_CARD_TITLE: 'My Profile',
  DETAILS_CARD_TITLE: 'Move Details',
  NO_RESULTS: `Sorry!! we did not find any content related to your search`
};

/** Stores faq */
export const FAQ_CONTENT = [
  {
    title: 'General Information FAQ',
    questions: [
      {
        title: 'When can I request my Cash Out funds?',
        answer: [
          {
            content: `The funds are available to you at any point following your authorization from your corporate sponsor.
               You will receive a notification inviting you back into the <Alpha> tool to manage your relocation.`,
            type: 'para',
          },
          // {
          //   content: `The cost of reasonably restoring the property to its pre-move condition`,
          //   type: 'list'
          // },
          // {
          //   content: `The cost of replacing the property with “like kind and quality” property`,
          //   type: 'list'
          // },
          // {
          //   content: `The insurance company has the right to inspect all damages, and to salvage the item
          //   if they pay you full replacement value. The claim payments will be based on the lesser of the following amounts:`,
          //   type: 'para'
          // },
        ]
      },
      {
        title: 'What are typical relocation costs?',
        answer: [
          {
            content: 'Typical costs incurred may include some of the following items:',
            type: 'list'
          },
          {
            content: 'Round trip transportation for a house-hunting trip.',
            type: 'list'
          },
          {
            content: 'Transportation for you and your family to your new work location.',
            type: 'list'
          },
          {
            content: 'Temporary storage if you cannot move into your new home immediately.',
            type: 'list'
          },
          {
            content: 'Shipment of your vehicle(s)',
            type: 'list'
          },
          {
            content: 'Spousal employment assistance',
            type: 'list'
          },
          {
            content: 'Lease cancellation',
            type: 'list'
          },
        ]
      },
      {
        title: 'How do I know what relocation benefits I need?',
        answer: [
          {
            content: `<Alpha> guarantees you a professional household goods move with insurance.
             You may receive a flexible spend allowance which you can then use toward any costs you incur during the relocation process.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'Are there tax implications to my relocation?',
        answer: [
          {
            content: 'We recommend that you review any tax implications with a tax professional.',
            type: 'para'
          },
        ]
      },
      {
        title: 'How do I schedule my move with the professional van line?',
        answer: [
          {
            content: 'Select the Schedule My Move button from your dashboard.',
            type: 'para'
          },
        ]
      },
      {
        title: 'How far ahead should I book my van line move?',
        answer: [
          {
            content: `Sooner is always better when planning your move so that the crew and truck are reserved.
             If you wait too long, you may not get your preferred dates.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'Will the moving company visit my home prior to showing up to pack and load my belongings?',
        answer: [
          {
            content: `The assessment by the van line will be a virtual survey when available.
             You will be given instructions on how to work with them through this guided survey.
             Show them everything including closet contents and storage areas.
             If items are found on move day that were not disclosed during the survey process,
             the costs will be out of pocket costs to you.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'Should I disassemble my furniture?',
        answer: [
          {
            content: `The movers will disassemble your furniture and they will reassemble it at your new home. This is covered by insurance.
             If you perform the assembly and disassembly yourself, it may not be covered for lost or missing pieces.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'Should I disconnect my appliances?',
        answer: [
          {
            content: `If you have gas appliances, you should make arrangements to have these disconnected by the gas company.
             You should disconnect freezers and refrigerators that you are moving in advance.
             The movers will advise you which items they will disconnect for you.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'What if I need to stay in temporary housing longer than planned because I can’t locate permanent housing?',
        answer: [
          {
            content: `You need to coordinate storage with the van line or make arrangements for self storage.
             If you decide to move your belongings to a self storage unit,
             you should carefully read the requirements in the insurance documents provided.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'Will the movers unpack all my household goods?',
        answer: [
          {
            content: `The movers will remove items from the boxes and place them on a clear surface.
             Mover unpacking does not include put away services. The movers will reassemble any furniture they disassembled.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'What if I need additional assistance?',
        answer: [
          {
            content: `If you require additional assistance, please reach out to our response team at (800) 331-7356
            or MobilifyResponseTeam@Cartus.com.`,
            type: 'para'
          },
        ]
      },
    ]
  },
  {
    title: 'Insurance FAQ',
    questions: [
      {
        title: 'How do I determine the value of my belongings?',
        answer: [
          {
            content: `The insurance policy requires a minimum base valuation. Determining the total insured value of a shipment starts by
            using the shipment weight X a rate per pound (this is the minimum base insured value) and adding any High Value Items. The high
            value items are listed on the Declaration of High Value Form to ensure your shipment is properly insured. Your employer is
            paying for the basic insurance using the estimated weight as well as the high value items you declared when you completed your
            needs assessment. The Declaration of High Value Form must be returned to the mailbox noted on the form (not to the van
            line/freight forwarder) prior to your shipment load date. Should you suffer a loss or damage, claims are settled based on
            replacement value up to the described base value limits. If your shipment contains any items that exceed these limits you
            must return the form. In the situation of a covered loss, once the form has been received by Cartus, the maximum amount paid
            out will be the insured value stated.`,
            type: 'para'
          },
          // {
          //   content: 'lst item 1',
          //   type: 'list'
          // }
        ]
      },
      {
        title: 'What type of items are considered high value?',
        answer: [
          {
            content: 'Furniture, antique furniture, motorcycles, boats, and jet skis with a replacement value in excess of $10,000 USD',
            type: 'list'
          },
          {
            content: `High value electronics, appliances, mattress sets, objects of art, paintings, antiques, grandfather
             and other specialty clocks, carpets, china, crystal, and silver in excess of $5,000 USD`,
            type: 'list'
          },
          {
            content: `High value clothing, accessories, luggage, small household items/appliances (lamps/coffee makers, etc.),
             camera equipment,computers and related equipment, tools/shop equipment, musical instruments, exercise equipment,
             bicycles, collections of CDs, DVDs, albums, and other like goods or items of unusual value in excess of $2,500 USD`,
            type: 'list'
          },
          {
            content: `Listing an item does not guarantee coverage if goods are specifically excluded from the insurance policy,
             such as jewelry (including watches and cuff links), cash, coins, sports memorabilia etc.`,
            type: 'para'
          }
        ]
      },
      {
        title: 'How do I determine the value of these items?',
        answer: [
          {
            content: `The value of your personal belongings is determined by the cost to replace them in the destination country/location,
             should a loss occur. Calculate the value of the item at today’s replacement value, not your original purchase price.
             The value you place on this form will be the maximum value you will be paid should you suffer a total loss.
             Be aware that failure to declare an item of high value, or return the form prior to loading, will limit recovery under
             the policy for an individual item to the maximum amount. Cars are not eligible to be moved on the moving van.
             Motorcycles and boats (under a specific size) are insured for book value.
             The value of motorcycles and boats is included as part of the total shipment weight calculation and does not need to be
             declared on high value form unless the value exceeds $10,000.`,
            type: 'para'
          }
        ]
      },
      {
        title: 'What are my responsibilities prior to the movers arriving at my old home location?',
        answer: [
          {
            content: 'The following are your responsibilities prior to the movers arriving at your old home location:',
            type: 'para'
          },
          {
            content: `Make sure you have returned your High Value Form to the email address provided prior to the movers arriving.
             Do not give the form to your mover and do not fill out the movers high value form.`,
            type: 'list'
          },
          {
            content: `Important documents, jewelry and valuables (money, stamps, card collections, etc.) are not covered by
             insurance and should not be shipped with your household goods. Please secure these items during your pack & load.`,
            type: 'list'
          },
          {
            content: 'Before packing begins you may want to document your household goods for your records by taking photos or video.',
            type: 'list'
          },
          {
            content: 'It is your responsibility to review the inventories at origin before signing them and note any discrepancies.',
            type: 'list'
          },
          {
            content: `Do a walk through and check all closets and cupboards before the moving company leaves your home.
             Make sure no items are left behind; items left behind are not the mover’s responsibility and may not be claimed as missing.`,
            type: 'list'
          },
          {
            content: `Property damage is the responsibility of the mover and must be documented while they are present.
             During the walk through mark on the driver’s paperwork if there is any damage caused to your home.`,
            type: 'list'
          },
        ]
      },
      {
        title: 'What are my responsibilities in my new location when preparing for delivery?',
        answer: [
          {
            content: 'The following are your responsibilities in your new location when preparing for delivery:',
            type: 'para'
          },
          {
            content: `If possible have two or more people available for delivery.
             As the boxes are carried into your home, one person will need to check off the inventory sheets
             and another person can direct the movers where to place boxes. This may be overwhelming,
             but it is imperative for insurance purposes that you document at delivery missing items on drivers paperwork.`,
            type: 'list'
          },
          {
            content: `Note any obvious damage, opened, re-taped or missing boxes on the inventories at the time of delivery.
             Failure to do this will result in a denied claim if anything is missing.`,
            type: 'list'
          },
          {
            content: `If the truck is empty and you still show boxes or items that have not been checked off,
             bring it to the attention of the driver. If they are unable to locate the missing item(s) carton make sure that
             it is noted on the driver’s copy of the paperwork and that the documents are signed by both you and the driver.`,
            type: 'list'
          },
          {
            content: `Do not discard any damaged items or attempt to have them repaired or replaced.
             Failure to make an item available for inspection will nullify a claim.`,
            type: 'list'
          },
          {
            content: `Do not move items to a different location after delivery.
             Items must be available for inspection at original destination.`,
            type: 'list'
          },
          {
            content: `Do a walk through and check for anything you want to point out to the driver.
             Property damage must be noted before the moving company leaves your home. This damage is handled by the mover directly.`,
            type: 'list'
          },
          {
            content: 'Be sure to fully unpack your goods as only one claim may be filed per shipment mode.',
            type: 'list'
          },
        ]
      },
      {
        title: 'What is covered under the Cartus Insurance Program?',
        answer: [
          {
            content: `The insurance policy covers personal effects and property to be used in a “dwelling” or standard to use
             in a household while in the custody of the carrier. Items used for business, owned by an employer,
             or used to run a side/home based business are not covered.
             The following details coverage under the Cartus arranged insurance program.`,
            type: 'list'
          },
          {
            content: `All risk of physical loss or damage by external causes,
             including fire and smoke, vandalism, flood and earthquake, collision, etc.`,
            type: 'list'
          },
          {
            content: 'Mold and mildew (limitation applies when due to climatic condition/changes in atmospheric temperature)',
            type: 'list'
          },
          {
            content: 'Climatic – damage resulting from changes in atmosphere/temperature extremes (limited to $25,000).',
            type: 'list'
          },
          {
            content: 'Car and boat claims are adjusted based on book value.',
            type: 'list'
          },
          {
            content: `Mechanical or damage to electronic equipment (TVs, laptops, DVD’s, etc.)
             where there is clear evidence of mover mishandling or breakage caused by transit is covered.
             Worn and/or defective parts (including recalls) due to age or normal use will not be covered
             under the terms of the insurance policy.`,
            type: 'list'
          },
          {
            content: `Temporary storage in an approved mover's warehouse:`,
            type: 'list'
          },
          {
            content: `For Domestic US Shipments, 365 days of storage`,
            type: 'list'
          },
          {
            content: `For International Shipments, 180 days of storage`,
            type: 'list'
          },
          {
            content: `If your household goods are at the Cartus mover’s warehouse for longer than the periods described above,
             arrangements will need to be made for insurance. There are several things that may occur: a)
             your employer may approve additional storage & insurance; b) if additional storage is not approved you can elect
             the continuation of insurance through Cartus and pay the monthly premium via credit card; c)
             you can source your own insurance outside Cartus.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'What is excluded from Insurance Coverage?',
        answer: [
          {
            content: `While coverage is considered to be comprehensive
             the items listed below are the most notable exclusions to the policy: `,
            type: 'para'
          },
          {
            content: 'Jewelry (including costume jewelry), watches, cuff links, precious stones, coins, currency',
            type: 'list'
          },
          {
            content: `Important papers such as awards, certificates, bills, stocks, deeds, passports,
             professional papers, evidence of debt, and other personal documents`,
            type: 'list'
          },
          {
            content: `Stamp or coin collections, sports memorabilia including trading cards,
             other collections not specifically declared prior to your move`,
            type: 'list'
          },
          {
            content: `Irreplaceable or items of sentimental value; coverage limited to actual value
             (example: an album of wedding photos would be covered for the value of the actual album, not the sentimental value)`,
            type: 'list'
          },
          {
            content: 'Loss of data on disks and tapes (recommend back up hard drive and move separately)',
            type: 'list'
          },
          {
            content: 'Boats over 20 ft.',
            type: 'list'
          },
          {
            content: 'Damage caused by insects, moths, vermin or inherent vice',
            type: 'list'
          },
          {
            content: 'Wrinkled or soiled clothing',
            type: 'list'
          },
          {
            content: 'Ordinary wear and tear, age or normal use',
            type: 'list'
          },
          {
            content: `Items used for business purposes, salesman's samples, merchandise for sale or exhibition`,
            type: 'list'
          },
          {
            content: `Automobiles and motorcycles, unless exceptions are noted at time of delivery for external damage`,
            type: 'list'
          },
          {
            content: `Missing, damaged or broken items from owner packed cartons (PBOs)`,
            type: 'list'
          },
          {
            content: `Missing items that are not noted on the delivery inventories at destination`,
            type: 'list'
          },
          {
            content: `Missing items not noted on origin inventory as being shipped`,
            type: 'list'
          },
          {
            content: `Marred, scratched, chipped or dented items coded as "conditions unknown" (CU) at origin by mover`,
            type: 'list'
          },
          {
            content: `Plants or frozen and perishable foods`,
            type: 'list'
          },
          {
            content: `Spoilage or leakage of alcoholic beverages – Alcohol must be disclosed in advance for breakage coverage`,
            type: 'list'
          },
          {
            content: `Goods delivered from or to self-storage facilities, (those delivering to self-storage will constitute final
               delivery and only exceptions taken at delivery to the warehouse will be honored—100 days to file)`,
            type: 'list'
          },
          {
            content: `Any perils or loss associated with war or nuclear exposures`,
            type: 'list'
          },
          {
            content: `Shipments to or from any country listed that may have any sanction, prohibition, or restriction under
             United Nations resolutions or trade or economic sanctions, laws or regulations of any jurisdiction`,
            type: 'list'
          },
          {
            content: `Property damage`,
            type: 'list'
          },
          {
            content: `Consider separating items not covered by insurance from the rest of your goods and plan to hand carry
             them to your destination or make alternative shipping arrangements.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'How do I file a claim?',
        answer: [
          {
            content: `Property damage to your residence (walls, floors, shrubs, etc.)
             is the responsibility of the movers and they should be notified immediately.`,
            type: 'para'
          },
          {
            content: `For damage to your household goods, do not file your claim with the
             mover/freight forwarder, but rather with UNIRISC as described below:`,
            type: 'para'
          },
          {
            content: 'Access the UNIRISC webiste at www.unirisc.com',
            type: 'list'
          },
          {
            content: 'On the UNIRISC home page, click the "Submit a Claim" button',
            type: 'list'
          },
          {
            content: 'Click "Register" (you may only register one time)',
            type: 'list'
          },
          {
            content: 'Complete the "Create a New Account" information',
            type: 'list'
          },
          {
            content: 'Enter (RC5699) for the Company Code',
            type: 'list'
          },
          {
            content: 'Click "Register and Sign In Now"',
            type: 'list'
          },
          {
            content: 'You will be taken to the home page where you can click on the "Claim Form" button on the top middle of the page',
            type: 'list'
          },
          {
            content: 'Please proceed with entering your claim following the directions given. Missing information may delay your claim.',
            type: 'list'
          },
          {
            content: `Important: The maximum time to file your claim for damage and missing items is 100 days from delivery date.
             Your claim should be a statement of all loss and/or damage resulting from your move. Please make sure you have
             unpacked all cartons and checked all furnishings for damage before you submit your claim. The settlement firm
             will investigate the claim, select repair firms and pay for repairs.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'What happens when I file a claim?',
        answer: [
          {
            content: `The insurance company has the right to inspect all damages, and to salvage the item
             if they pay you full replacement value. The claim payments will be based on the lesser of the following amounts:`,
            type: 'para'
          },
          {
            content: 'The cost of reasonably restoring the property to its pre-move condition',
            type: 'list'
          },
          {
            content: 'The cost of replacing the property with “like kind and quality” property',
            type: 'list'
          },
          {
            content: 'The actual cash value not to exceed the amount declared on your high value form',
            type: 'list'
          },
          {
            content: `Please note that until instructed by UNIRISC do not discard damaged items,
             do not attempt to repair items or replace them in advance, do not remove the items from the delivery location.`,
            type: 'para'
          },
        ]
      },
      {
        title: 'What information is required to file a claim?',
        answer: [
          {
            content: 'The following information is required to file a claim:',
            type: 'para'
          },
          {
            content: 'Your Alpha Move ID - This identifier is unique to your move (MOV-1a2s3d3f)',
            type: 'list'
          },
          {
            content: `The inventory number - Enter the item number from the mover’s inventory
             that corresponds to the article damaged or lost.`,
            type: 'list'
          },
          {
            content: `Description of article - Identify each item including its brand name,
             if applicable (i.e.: Ethan Allen dresser, Schwinn Imperial bicycle).`,
            type: 'list'
          },
          {
            content: `Nature and extent of damage - Describe in detail
             (i.e.: right leg scratched, left arm rubbed and Chipped, picture tube broken, etc.)`,
            type: 'list'
          },
          {
            content: 'Replacement cost - Enter the cost of replacing this item with like kind and quality.',
            type: 'list'
          },
          {
            content: 'Date of purchase - List the date of purchase of the item. If the item was a gift, list the date it was given to you.',
            type: 'list'
          },
          {
            content: `Amount claimed - You must enter an amount you are claiming as compensation for every item.
             If you prefer an item be repaired, do not enter an amount, instead write “repair” in this column.
              Keep in mind that the insurer has the right to repair rather than replace damaged articles.
               Please attach any receipts, estimates, etc. which will assist in validating the claim.`,
            type: 'list'
          },
        ]
      },
    ]
  }
];
/**used to store tittle for relocation budget*/
export const REWARDS_DETAILS_TEMPLATE_STRING = {
  HEADING: `Learn More About Our Real Estate Rewards Program`,
  HEADING_SUMMARY: `Get Cash Back* in your pocket up to $6,000 when you buy or sell a home through our
  premier network of agents. Receive personal attention and guidance throughout the entire purchase and/or
  sales process.`,
};
/** Title to display copyright*/
export const copyright = `© 2000-${new Date().getFullYear()} Cartus Corporation`;
/** Error response message */
export const unableToProcessRequest = `We are unable to process your request at this time. Please try again later.`;
/** Loading message */
export const loadingMessage = 'Loading, please wait...';
/** holds step details */
export const stepKeys = ['landing-content', 'profile-content', 'locations-content', 'phone-email-content'];

// Needs Assessment
export const phoneTypes: Array<string> = ['Mobile', 'Departure Business', 'Departure Residence',
  'Destination Business', 'Destination Residence'];
export const emailTypes: Array<string> = ['personal', 'business'];

/** Explore destination card title */
export const destinationCardTitle = 'Explore Your Destination';
/** Explore destination card image titles  */
export const destinationCardImage = [
  { imageUri: 'neighbor', imageTitle: 'Neighborhoods' },
  { imageUri: 'schools', imageTitle: 'Schools' },
  { imageUri: 'thingsToDo', imageTitle: 'Things To Do' },
];
/** URL's for explore destination */
export const exploreDestURL = {
  destinationUS: 'http://www.onboardnavigator.com/1.5/webContent/OBWC_Results.aspx?AID=106',
  destinationNonUS: 'https://www.globallocalliving.com/direct?c=cartus_gll'
};

/** Family card delete warning message */
export const familyMemDelWarningMsg = 'Are you sure you want to delete?';
/** Messages to be displayed in notification bar for completion of tasks */
export const notificationMessage = {
  family: {
    header: 'Family Information',
    reference: 'family info',
    message: 'Please update your family details as soon as possible.'
  },
  policyCall:
  {
    header: 'Policy Call With Your Consultant',
    message: 'Please schedule your policy call with your consultant as soon as possible.'
  },
  scheduleBenefit:
  {
    header: 'Review Benefit',
    message: 'Please review the High Insurance details related to your '
  } ,
  tempLivingBenefit:
  {
    header: 'Review Benefit',
    message: 'Please complete the Temporary Housing Needs Assessment in your Confirmed Benefits Task.'
  }
};

/***icons */
export const ICONS_MAPPING = [
  {
    'reference': 'OFF-HHGIntlAirBBAcmp',
    'icon': 'illustration-airShipmentFamily.svg'
  },
  {
    'reference': 'OFF-HHGIntlAirBBUnacmp',
    'icon': 'illustration-airShipmentSingle.svg'
  },
  {
    'reference': 'OFF-BusnCommAdvBBIntl',
    'icon': 'illustration-advBusinessSkills.svg'
  },
  {
    'reference': 'OFF-GlbLdrshpCommBBIntl',
    'icon': 'illustration-globalLeadershipEnglish.svg'
  },
  {
    'reference': 'OFF-AccntModBBIntl',
    'icon': 'illustration-accentModification.svg'
  },
  {
    'reference': 'OFF-BsnCommEnglBBIntl20',
    'icon': 'illustration-businessEnglish.svg'
  },
  {
    'reference': 'OFF-PrsntSkillsBusnEnglBBIntl12',
    'icon': 'illustration-presentationSkillsCoaching.svg'
  },
  {
    'reference': 'OFF-AddtlStrgBB',
    'icon': 'illustration-StorageInTransit.svg'
  },
  {
    'reference': 'OFF-AutoLossBB',
    'icon': 'illustration-lossOnSale.svg'
  },
  {
    'reference': 'OFF-BrkrFndrFeeBB',
    'icon': 'illustration-rentalFindersFee.svg'
  },
  {
    'reference': 'OFF-BrkrFndrFeeIntlBB',
    'icon': 'illustration-rentalFindersFee.svg'
  },
  {
    'reference': 'OFF-CarRntlBB',
    'icon': 'illustration-carRental.svg'
  },
  {
    'reference': 'OFF-CarRntlIntlBB',
    'icon': 'illustration-carRental.svg'
  },
  {
    'reference': 'OFF-CashOut10BB',
    'icon': 'illustration-cashOut.svg'
  },
  {
    'reference': 'OFF-CashOut10IntlBB',
    'icon': 'illustration-cashOut.svg'
  },
  {
    'reference': 'OFF-CashOut3BB',
    'icon': 'illustration-cashOut.svg'
  },
  {
    'reference': 'OFF-CashOut4BB',
    'icon': 'illustration-cashOut.svg'
  },
  {
    'reference': 'OFF-CashOut5BB',
    'icon': 'illustration-cashOut.svg'
  },
  {
    'reference': 'OFF-CashOut7BB',
    'icon': 'illustration-cashOut.svg'
  },
  {
    'reference': 'OFF-CltrlTrngBB',
    'icon': 'illustration-culturalTraining.svg'
  },
  {
    'reference': 'OFF-CltrlTrngOnlnBB',
    'icon': 'illustration-onlineCulturalTool.svg'
  },
  {
    'reference': 'OFF-CorpHsngBB',
    'icon': 'illustration-tempLiving.svg'
  },
  {
    'reference': 'OFF-CostOfLvngBB',
    'icon': 'illustration-costOfLiving.svg'
  },
  {
    'reference': 'OFF-DestSvcsBndlBB',
    'icon': 'illustration-servicesBundle.svg'
  },
  {
    'reference': 'OFF-DestSvcs3DayBndlBB',
    'icon': 'illustration-servicesBundle.svg'
  },
  {
    'reference': 'OFF-DestSvcs4DayBndlBB',
    'icon': 'illustration-servicesBundle.svg'
  },
  {
    'reference': 'OFF-DsrdDntCrBB',
    'icon': 'illustration-HHGDonate.svg'
  },
  {
    'reference': 'OFF-DupHsngBB',
    'icon': 'illustration-duplicateHousisng.svg'
  },
  {
    'reference': 'OFF-DupHousingBB',
    'icon': 'illustration-duplicateHousisng.svg'
  },
  {
    'reference': 'OFF-DupHsngBndl2BB',
    'icon': 'illustration-duplicateHousisng.svg'
  },
  {
    'reference': 'OFF-DupHsngBndl3BB',
    'icon': 'illustration-duplicateHousisng.svg'
  },
  {
    'reference': 'OFF-ExcsBagBB',
    'icon': 'illustration-excessBaggage.svg'
  },
  {
    'reference': 'OFF-ExcsBagReimbBB',
    'icon': 'illustration-excessBaggage.svg'
  },
  {
    'reference': 'OFF-FnlTrnsprtBB',
    'icon': 'illustration-FinalTransport.svg'
  },
  {
    'reference': 'OFF-FrnRntlBB',
    'icon': 'illustration-furnitureRental.svg'
  },
  {
    'reference': 'OFF-FurnAllow',
    'icon': 'illustration-furnitureAllowance.svg'
  },
  {
    'reference': 'OFF-HHGBB',
    'icon': 'illustration-hhgProMove.svg'
  },
  {
    'reference': 'OFF-HHGFlxBB',
    'icon': 'illustration-hhgProMove.svg'
  },
  {
    'reference': 'OFF-HHGIntlAirBB',
    'icon': 'illustration-airShipment.svg'
  },
  {
    'reference': 'OFF-HHGIntlAirBB-INS',
    'icon': 'illustration-airShipment.svg'
  },
  {
    'reference': 'OFF-AirfareUpgradeBndlBBRpt',
    'icon': 'illustration-returnTripUpgrade.svg'
  },
  {
    'reference': 'OFF-HHGIntlSea20BB',
    'icon': 'illustration-seaShipment.svg'
  },
  {
    'reference': 'OFF-HHGIntlSea40BB',
    'icon': 'illustration-seaShipment.svg'
  },
  {
    'reference': 'OFF-HHGIntlSeaBB',
    'icon': 'illustration-seaShipment.svg'
  },
  {
    'reference': 'OFF-HmFndng32BB',
    'icon': 'illustration-homeFinding.svg'
  },
  {
    'reference': 'OFF-HmFndngRtrn43BB',
    'icon': 'illustration-homeFinding.svg'
  },
  {
    'reference': 'OFF-HmFndngRtrn32BB',
    'icon': 'illustration-homeFinding.svg'
  },
  {
    'reference': 'OFF-HmFndng43BB',
    'icon': 'illustration-homeFinding.svg'
  },
  {
    'reference': 'OFF-HmFndng54BB',
    'icon': 'illustration-homeFinding.svg'
  },
  {
    'reference': 'OFF-HmFndng65BB',
    'icon': 'illustration-homeFinding.svg'
  },
  {
    'reference': 'OFF-HmFndngBB',
    'icon': 'illustration-homeFinding.svg'
  },
  {
    'reference': 'OFF-HmFndngBBRpt32',
    'icon': 'illustration-homeFinding.svg'
  },
  {
    'reference': 'OFF-HmPrchReimbBB',
    'icon': 'illustration-HomePurchaseReimbursement.svg'
  },
  {
    'reference': 'OFF-HmSlReimbBB',
    'icon': 'illustration-HomeSaleReimbursement.svg'
  },
  {
    'reference': 'OFF-HmSlReimbIntlBB',
    'icon': 'illustration-HomeSaleReimbursement.svg'
  },
  {
    'reference': 'OFF-HomePurchaseReimbursementBB',
    'icon': 'illustration-HomePurchaseReimbursement.svg'
  },
  {
    'reference': 'OFF-HomeSaleBVMABB',
    'icon': 'illustration-HomeSale.svg'
  },
  {
    'reference': 'OFF-HomeSaleGBOMABB',
    'icon': 'illustration-HomeSaleBuyOut.svg'
  },
  {
    'reference': 'OFF-HomeSaleIncntvBB',
    'icon': 'illustration-HomeSaleIncentive.svg'
  },
  {
    'reference': 'OFF-ImmgrtnSvcsBB',
    'icon': 'illustration-immigrationServices.svg'
  },
  {
    'reference': 'OFF-LngTrngBB',
    'icon': 'illustration-languageTraining.svg'
  },
  {
    'reference': 'OFF-LngTrngOnlnBB',
    'icon': 'illustration-onlineLanguageTraining.svg'
  },
  {
    'reference': 'OFF-LossOnSaleBB',
    'icon': 'illustration-lossOnSale.svg'
  },
  // {
  //   'reference': 'OFF-LsNegtnBB',
  //   'icon': 'illustration-leaseCancellation.svg'
  // },
  {
    'reference': 'OFF-LsTermAddtlMntBB',
    'icon': 'illustration-leaseTermination.svg'
  },
  {
    'reference': 'OFF-LsTermBB',
    'icon': 'illustration-leaseTermination.svg'
  },
  {
    'reference': 'OFF-LsTermBndl',
    'icon': 'illustration-leaseTermination.svg'
  },
  {
    'reference': 'OFF-MiscAllwncBBRpt',
    'icon': 'illustration-returnTrip.svg'
  },
  {
    'reference': 'OFF-MiscAllwnc10BB',
    'icon': 'illustration-relocationAllowance.svg'
  },
  {
    'reference': 'OFF-MiscAllwnc10IntlBB',
    'icon': 'illustration-relocationAllowance.svg'
  },
  {
    'reference': 'OFF-MiscAllwnc5IntlBB',
    'icon': 'illustration-relocationAllowance.svg'
  },
  {
    'reference': 'OFF-MiscAllwnc15BB',
    'icon': 'illustration-relocationAllowance.svg'
  },
  {
    'reference': 'OFF-MiscAllwnc75BB',
    'icon': 'illustration-relocationAllowance.svg'
  },
  {
    'reference': 'OFF-MiscAllwncBB',
    'icon': 'illustration-relocationAllowance.svg'
  },
  {
    'reference': 'OFF-MiscAllwnc2IntlBB',
    'icon': 'illustration-relocationAllowance.svg'
  },
  {
    'reference': 'OFF-MiscAllwnc2IntlBBReferences',
    'icon': 'illustration-relocationAllowance.svg'
  },
  {
    'reference': 'OFF-PetTrnsprtBB',
    'icon': 'illustration-petTransport.svg'
  },
  {
    'reference': 'OFF-PetTrnsprtBndlBB',
    'icon': 'illustration-petTransport.svg'
  },
  {
    'reference': 'OFF-PetTrnsprtAllwncBBReferences',
    'icon': 'illustration-petTransport.svg'
  },
  {
    'reference': 'OFF-PetTrnsprtReimbBB',
    'icon': 'illustration-petTransportReimbursement.svg'
  },
  {
    'reference': 'OFF-PlcyCnslBB',
    'icon': 'illustration-policyConsulting.svg'
  },
  {
    'reference': 'OFF-PlcyCnslIntlBB',
    'icon': 'illustration-policyConsulting.svg'
  },
  {
    'reference': 'OFF-PrvwTrpBB',
    'icon': 'illustration-previewTrip.svg'
  },
  {
    'reference': 'OFF-PreAssignPreTranfrFour',
    'icon': 'illustration-previewTrip.svg'
  },
  {
    'reference': 'OFF-PreAssignPreTranfrSix',
    'icon': 'illustration-previewTrip.svg'
  },
  {
    'reference': 'OFF-ReloAcctngBB',
    'icon': 'illustration-expenseManagement.svg'
  },
  {
    'reference': 'OFF-ReloAcctngIntlBB',
    'icon': 'illustration-expenseManagement.svg'
  },
  {
    'reference': 'OFF-RentalSvcsFullBB',
    'icon': 'illustration-fullDayServices.svg'
  },
  {
    'reference': 'OFF-RentalSvcsHalfBB',
    'icon': 'illustration-halfDayServices.svg'
  },
  {
    'reference': 'OFF-ReturnTrpBB',
    'icon': 'illustration-returnTrip.svg'
  },
  {
    'reference': 'OFF-ReturnTrpBndl2BB',
    'icon': 'illustration-returnTrip.svg'
  },
  {
    'reference': 'OFF-ReturnTrpBndl3BB',
    'icon': 'illustration-returnTrip.svg'
  },
  {
    'reference': 'OFF-SchlFndgBB',
    'icon': 'illustration-educationAssistance.svg'
  },
  {
    'reference': 'OFF-ShpmntSeaBB',
    'icon': 'illustration-seaShipment.svg'
  },
  {
    'reference': 'OFF-ShpmtAirBB',
    'icon': 'illustration-airShipment.svg'
  },
  {
    'reference': 'OFF-SlfMvBB',
    'icon': 'illustration-selfMove.svg'
  },
  {
    'reference': 'OFF-SlfStrgBB',
    'icon': 'illustration-selfStorage.svg'
  },
  {
    'reference': 'OFF-SpouseCareerSuppBB',
    'icon': 'illustration-partnerCareerSupport.svg'
  },
  {
    'reference': 'OFF-SpouseCareerSuppIntlBB',
    'icon': 'illustration-partnerCareerSupport.svg'
  },
  {
    'reference': 'OFF-StlngInBB',
    'icon': 'illustration-settlingIn.svg'
  },
  {
    'reference': 'OFF-StrgInTrnst60BB',
    'icon': 'illustration-StorageInTransit.svg'
  },
  {
    'reference': 'OFF-StrgInTrnst180BB',
    'icon': 'illustration-StorageInTransit.svg'
  },
  {
    'reference': 'OFF-StrgInTrnstBBIntl30',
    'icon': 'illustration-StorageInTransit.svg'
  },
  {
    'reference': 'OFF-StrgInTrnstBBIntl6190',
    'icon': 'illustration-StorageInTransit.svg'
  },
  {
    'reference': 'OFF-StrgInTrnstBBIntl3160',
    'icon': 'illustration-StorageInTransit.svg'
  },
  {
    'reference': 'OFF-StrgInTrnstBB',
    'icon': 'illustration-hhgStorage.svg'
  },
  {
    'reference': 'OFF-TaxBrfngBB',
    'icon': 'illustration-taxBriefing.svg'
  },
  {
    'reference': 'OFF-TaxPrepBB',
    'icon': 'illustration-taxPrep.svg'
  },
  {
    'reference': 'OFF-TmpHsng105BB',
    'icon': 'illustration-tempLiving.svg'
  },
  {
    'reference': 'OFF-TmpHsng120BB',
    'icon': 'illustration-tempLiving.svg'
  },
  {
    'reference': 'OFF-TmpHsng30BB',
    'icon': 'illustration-tempLiving.svg'
  },
  {
    'reference': 'OFF-TmpHsng45BB',
    'icon': 'illustration-tempLiving.svg'
  },
  {
    'reference': 'OFF-TmpHsngBB',
    'icon': 'illustration-tempLiving.svg'
  },
  {
    'reference': 'OFF-TmpHsngCrIntlBB',
    'icon': 'illustration-tempLiving.svg'
  },
  {
    'reference': 'OFF-TmpHsngFlxIntlBB',
    'icon': 'illustration-tempLiving.svg'
  },
  {
    'reference': 'OFF-TmpHsngReimbIntlBB',
    'icon': 'illustration-tempLivingReimbursement.svg'
  },
  {
    'reference': 'OFF-VhclShpmtBndl3BB',
    'icon': 'illustration-carShipment.svg'
  },
  {
    'reference': 'OFF-VhclShpmtBndlBB',
    'icon': 'illustration-carShipment.svg'
  },
  {
    'reference': 'OFF-VhclShpmtBndlIntlBB',
    'icon': 'illustration-carShipment.svg'
  },
  {
    'reference': 'OFF-MiscExpnsAllwncBB',
    'icon': 'illustration-miscExpenses.svg'
  },
  {
    'reference': 'OFF-FlyToIntrnshpBB',
    'icon': 'illustration-flyingToDestination.svg'
  },
  {
    'reference': 'OFF-DrvToIntrnshpBB',
    'icon': 'illustration-driveToDestination.svg'
  },
  {
    'reference': 'OFF-CrplToIntrnshpBB',
    'icon': 'illustration-carpooling.svg'
  },
  {
    'reference': 'OFF-CommtAllwncIntrnBB',
    'icon': 'illustration-miscExpenses.svg'
  },
  {
    'reference': 'OFF-CarRntlIntrnBB',
    'icon': 'illustration-longtermCarRental.svg'
  },
  {
    'reference': 'OFF-CorpHsngIntrnBB',
    'icon': 'illustration-tempLiving.svg'
  },
  {
    'reference': 'OFF-HsngAllwncIntrnBB',
    'icon': 'illustration-tempLivingbyEmp.svg'
  },
  {
    'reference': 'OFF-HstDprtAsstBB',
    'icon': 'illustration-HostCountryDepartureAssistance.svg'
  },
  {
    'reference': 'OFF-DeptrAsstBB',
    'icon': 'illustration-HostCountryDepartureAssistance.svg'
  },
  {
    'reference': 'OFF-FnlTrnsprtRtnBB',
    'icon': 'illustration-HomeSaleReimbursement.svg'
  },
  {
    'reference': 'OFF-RetunrTravlExpns',
    'icon': 'illustration-HomeSaleReimbursement.svg'
  },
  {
    'reference': 'OFF-LsTermRtrnBB',
    'icon': 'illustration-leaseTermination.svg'
  },
  {
    'reference': 'OFF-LsTermBBRpt',
    'icon': 'illustration-leaseTermination.svg'
  },
  {
    'reference': 'OFF-CltrlTrnsSuppBB2Hr',
    'icon': 'illustration-culturalTraining.svg'
  },
  {
    'reference': 'OFF-CltrlTrnsSuppCoreBB',
    'icon': 'illustration-culturalTraining.svg'
  },
  {
    'reference': 'OFF-CltrlTrnsSuppBB4Hr',
    'icon': 'illustration-culturalTraining.svg'
  },
  {
    'reference': 'OFF-RepatCoachBB',
    'icon': 'illustration-RepatriationCoaching.svg'
  },
  {
    'reference': 'OFF-CltrlTrngCoreSuppBB',
    'icon': 'illustration-culturalTraining.svg'
  },
  {
    'reference': 'OFF-HlthCrReimbBB',
    'icon': 'illustration-healthcare.svg'
  },
  {
    'reference': 'OFF-PreDcsnOrntnBB',
    'icon': 'illustration-fullDayServices.svg'
  },
  {
    'reference': 'OFF-PreDcsnOrntn1DayBB',
    'icon': 'illustration-fullDayServices.svg'
  },
  {
    'reference': 'OFF-PreDcsnOrntn2DayBB',
    'icon': 'illustration-fullDayServices.svg'
  },
  {
    'reference': 'OFF-CltrlTrnsSuppExtBB',
    'icon': 'illustration-culturalTrainingExt.svg'
  },
  {
    'reference': 'OFF-CltrlTrnsSuppYouthBB',
    'icon': 'illustration-culturalTraining.svg'
  },
  {
    'reference': 'OFF-HHGIntlAirCoreBB',
    'icon': 'illustration-languageTraining.svg'
  },
  {
    'reference': 'OFF-HHGIntlCoreBB',
    'icon': 'illustration-hhgProMove.svg'
  },
  {
    'reference': 'OFF-HHGIntlCoreBB-INS',
    'icon': 'illustration-hhgProMove.svg'
  },
  {
    'reference': 'OFF-LngTrngBB40Hr',
    'icon': 'illustration-languageTraining.svg'
  },
  {
    'reference': 'OFF-CostOfLvngDiffIntlBB',
    'icon': 'illustration-LivingCostDifferential.svg'
  },
  {
    'reference': 'OFF-DrvrsLcsnsExchMultiBBIntl',
    'icon': 'illustration-licenseExchange.svg'
  },
  {
    'reference': 'OFF-PropMgmtBBIntl',
    'icon': 'illustration-propertyManagement.svg'
  },
  {
    'reference': 'OFF-SchlFndgEdCmplBB',
    'icon': 'illustration-EducationComplete.svg'
  },
  {
    'reference': 'OFF-SchlFndgEdCmplBBIntl',
    'icon': 'illustration-EducationComplete.svg'
  },
  {
    'reference': 'OFF-DualUtilityBBIntl',
    'icon': 'illustration-dualUtilities.svg'
  },
  {
    'reference': 'OFF-PrkngReimbBBIntl',
    'icon': 'illustration-parkingAllowance.svg'
  },
  {
    'reference': 'OFF-LocHrdshpPremBBIntl',
    'icon': 'illustration-hardshipPremium.svg'
  },
  {
    'reference': 'OFF-RAndRBBIntl',
    'icon': 'illustration-restAndRecuperation.svg'
  },
  {
    'reference': 'OFF-WireFeeReimbBBIntl',
    'icon': 'illustration-wireFeeReimbursement.svg'
  },
  {
    'reference': 'OFF-HomeLeaveReimbBB',
    'icon': 'illustration-HomeLeaveReimbursement.svg'
  },
  {
    'reference': 'OFF-HomeLeaveReimbBBIntl',
    'icon': 'illustration-HomeLeaveReimbursement.svg'
  },
  {
    'reference': 'OFF-HomeLeaveAllowBBIntl',
    'icon': 'illustration-HomeLeaveReimbursement.svg'
  },
  {
    'reference': 'OFF-HomeLeaveExchBB',
    'icon': 'illustration-HomeLeaveExchange.svg'
  },
  {
    'reference': 'OFF-HomeLeaveExchBBIntl',
    'icon': 'illustration-HomeLeaveExchange.svg'
  },
  {
    'reference': 'OFF-NonAccompDepTrvlBBIntl',
    'icon': 'illustration-dependentTravel.svg'
  },
  {
    'reference': 'OFF-NonAccompDepTrvlBB',
    'icon': 'illustration-dependentTravel.svg'
  },
  {
    'reference': 'OFF-ApplncReimbBBIntl',
    'icon': 'illustration-appliances.svg'
  },
  {
    'reference': 'OFF-AirfareReimbBndlBBIntl',
    'icon': 'illustration-AirfareExpSupport.svg'
  },
  {
    'reference': 'OFF-AirfareUpgradeBndlBBIntl',
    'icon': 'illustration-airfareUpgrade.svg'
  },
  {
    'reference': 'OFF-EduSuppBndlBBIntl',
    'icon': 'illustration-dependentEdSupport.svg'
  },
  {
    'reference': 'OFF-HstHsngAllwncSuppBBIntlFlx',
    'icon': 'illustration-HostHousingAllowance.svg'
  },
  {
    'reference': 'OFF-HHGIntlAirBBUnacmp-INS',
    'icon': 'illustration-airShipmentSingle.svg'
  },
  {
    'reference': 'OFF-HHGIntlAirBBAcmp-INS',
    'icon': 'illustration-airShipmentFamily.svg'
  },
  {
    'reference': 'OFF-DrvrsLcsnsExchBBIntl',
    'icon': 'illustration-licenseExchange.svg'
  },
  {
    'reference': 'OFF-DestSvcsLTBndlBB',
    'icon': 'illustration-servicesBundle.svg'
  },
  {
    'reference': 'OFF-CndtAssmtCoreBB',
    'icon': 'illustration-candidateAssessment.svg'
  },
  {
    'reference': 'OFF-CndtAssmtFlxBB',
    'icon': 'illustration-candidateAssessment.svg'
  },
  {
    'reference': 'OFF-DestSvcsLTDepBndlBB',
    'icon': 'illustration-servicesBundle.svg'
  },
  {
    'reference': 'OFF-HHGIntlFlxBB',
    'icon': 'illustration-hhgProMove.svg'
  },
  {
    'reference': 'OFF-EdAssistBB',
    'icon': 'illustration-educationAssistance.svg'
  },
  {
    'reference': 'OFF-CltrlTrngExtSuppBB',
    'icon': 'illustration-culturalTrainingExt.svg'
  },
  {
    'reference': 'OFF-BusnEnglWrtngBBIntl20',
    'icon': 'illustration-businessEnglishWriting.svg'
  },
  {
    'reference': 'OFF-HstHsngAllwncSuppBBIntlCr',
    'icon': 'illustration-HostHousingAllowance.svg'
  },
  {
    'reference': 'OFF-CashOutBBAdv',
    'icon': 'illustration-cashOut.svg'
  },
  {
    'reference': 'OFF-EduSuppBBIntl',
    'icon': 'illustration-dependentEdSupport.svg'
  },
  {
    'reference': 'OFF-FrnRntlBBIntl',
    'icon': 'illustration-furnitureRental.svg'
  },
  {
    'reference': 'OFF-FurnAllowIntl',
    'icon': 'illustration-furnitureAllowance.svg'
  },
  {
    'reference': 'OFF-SchlFndgEdCmplBBIntl1Day',
    'icon': 'illustration-EducationComplete.svg'
  },
  {
    'reference': 'OFF-SchlFndgEdCmplBBIntl2Day',
    'icon': 'illustration-EducationComplete.svg'
  },
  {
    'reference': 'OFF-PetTrnsprtAllwncBB',
    'icon': 'illustration-petTransport.svg'
  },
  {
    'reference': 'OFF-AutoDispBndlBB',
    'icon': 'illustration-carDisposition.svg'
  },
  {
    'reference': 'OFF-HHGIntlSeaBB-INS',
    'icon': 'illustration-seaShipment.svg'
  },
  {
    'reference': 'OFF-HHGIntlSea20BB-INS',
    'icon': 'illustration-seaShipment.svg'
  },
  {
    'reference': 'OFF-HHGIntlSea40BB-INS',
    'icon': 'illustration-seaShipment.svg'
  },
  {
    'reference': 'OFF-HHGIntlFlxBB-INS',
    'icon': 'illustration-hhgProMove.svg'
  },
  {
    'reference': 'OFF-PetTrnsprtBBIntlCore',
    'icon': 'illustration-petTransportReimbursement.svg'
  },
  {
    'reference': 'OFF-LsTermIntlCoreBB',
    'icon': 'illustration-leaseTermination.svg'
  },
  {
    'reference': 'OFF-NonAccompDepTrvlReimbBBIntl',
    'icon': 'illustration-dependentTravel.svg'
  },
  {
    'reference': 'OFF-EduSuppReimbBBIntl',
    'icon': 'illustration-dependentEdSupport.svg'
  },
  {
    'reference': 'OFF-PropMaintBBIntl',
    'icon': 'illustration-propertyManagement.svg'
  },
  {
    'reference': 'OFF-AutoLeaseBBIntl',
    'icon': 'illustration-carRental.svg'
  },
  {
    'reference': 'OFF-AutoDispBBIntl',
    'icon': 'illustration-leaseTermination.svg'
  },
  {
    'reference': 'OFF-DsrdDntFlxBB',
    'icon': 'illustration-HHGDonate.svg'
  },
  {
    'reference': 'OFF-HHGFlxBB-INS',
    'icon': 'illustration-hhgProMove.svg'
  },
  {
    'reference': 'OFF-ChildCareReimbBBIntl',
    'icon': 'illustration-childCareReimbursement.svg'
  },
  {
    'reference': 'OFF-DrvrsLicenseReimbBBIntl',
    'icon': 'illustration-licenseExchange.svg'
  },
  {
    'reference': 'OFF-OngoingAllowBBIntl',
    'icon': 'illustration-expatPremium.svg'
  },
  {
    'reference': 'OFF-FamSocAsstBBIntl',
    'icon': 'illustration-familyAssistance.svg'
  },
  {
    'reference': 'OFF-HomeCtryParentReimbBBIntl',
    'icon': 'illustration-parentReimbursement.svg'
  },
  {
    'reference': 'OFF-LngTrngBBIntlHybrd',
    'icon': 'illustration-languageTraining.svg'
  },
  {
    'reference': 'OFF-LngTrngBBIntlHybrdDep',
    'icon': 'illustration-languageTraining.svg'
  },
  {
    'reference': 'OFF-LngTrngBBIntlHybrdSpouse',
    'icon': 'illustration-languageTraining.svg'
  },
  {
    'reference': 'OFF-SlfStrgReimbBBIntl',
    'icon': 'illustration-selfStorage.svg'
  },
  {
    'reference': 'OFF-ParentReimbBBIntl',
    'icon': 'illustration-singleParentSupport.svg'
  },
  {
    'reference': 'OFF-CareerTransitionReimbBBIntl',
    'icon': 'illustration-partnerCareerSupport.svg'
  },  
  {
    'reference': 'OFF-ApplAllowBB',
    'icon': 'illustration-appliances.svg'
  },
  {
    'reference': 'OFF-ChildCareBndlBBIntl',
    'icon': 'illustration-childCareReimbursement.svg'
  },
  {
    'reference': 'OFF-CompletionBonusBB',
    'icon': 'illustration-completionBonus.svg'
  },
  {
    'reference': 'OFF-DangerPayBB',
    'icon': 'illustration-hardshipPremium.svg'
  },
  {
    'reference': 'OFF-FamilyAllowBB',
    'icon': 'illustration-parentReimbursement.svg'
  },
  {
    'reference': 'OFF-AirfareUpgrdBusnClsBBIntl',
    'icon': 'illustration-airfareUpgrade.svg'
  },
  {
    'reference': 'OFF-HouseFittingBB',
    'icon': 'illustration-HostHousingAllowance.svg'
  },
  {
    'reference': 'OFF-HouseHuntUpgrdBB',
    'icon': 'illustration-previewTrip.svg'
  },
  {
    'reference': 'OFF-LangTrainSpouseBBIntl',
    'icon': 'illustration-languageTraining.svg'
  },
  {
    'reference': 'OFF-MobPremBB',
    'icon': 'illustration-relocationAllowance.svg'
  },
  {
    'reference': 'OFF-PropMgmtBB',
    'icon': 'illustration-halfDayServices.svg'
  },
  {
    'reference': 'OFF-UtilAllowBB',
    'icon': 'illustration-UtilityAllowance.svg'
  },
  {
    'reference': 'OFF-VIPTaxBBIntl',
    'icon': 'illustration-taxBriefing.svg'
  },
  {
    'reference': 'OFF-PrvwTrpReimbBB',
    'icon': 'illustration-previewTrip.svg'
  },
  {
    'reference': 'OFF-FinalTripReimbBB',
    'icon': 'illustration-FinalTransport.svg'
  },
  {
    'reference': 'OFF-ReturnTrpReimbBB',
    'icon': 'illustration-returnTrip.svg'
  },
  {
    'reference': 'OFF-GoWiderGoFarBB',
    'icon': 'illustration-goWiderGoFarther.svg'
  },
  {
    'reference': 'OFF-LsNegtnBB',
    'icon': 'illustration-leaseNegotiation.svg'
  },
  {
    'reference': 'OFF-TravelCoordBB',
    'icon': 'illustration-travelCoordination.svg'
  },
  {
    'reference': 'OFF-TravelContributBndlBB',
    'icon': 'illustration-returnTrip.svg'
  },
  {
    'reference': 'OFF-DupHousingReimbBB',
    'icon': 'illustration-duplicateHousisng.svg'
  },
  {
    'reference': 'OFF-HmFndngReimbBB',
    'icon': 'illustration-homeFinding.svg'
  },
  {
    'reference': 'OFF-TaxAssistBB',
    'icon': 'illustration-taxPrep.svg'
  },
  {
    'reference': 'OFF-IncentRentBB',
    'icon': 'illustration-incentToRent.svg'
  },
  {
    'reference': 'OFF-AcclimationSvcsBB',
    'icon': 'illustration-partnerCareerSupport.svg'
  },
  {
    'reference': 'OFF-SpouseAcclimationSvcsBB',
    'icon': 'illustration-partnerCareerSupport.svg'
  },
  {
    'reference': 'OFF-MarketAsstBB',
    'icon': 'illustration-marketingAssistance.svg'
  },
  {
    'reference': 'OFF-PutAwayQuickBB',
    'icon': 'illustration-putAwaySvc.svg'
  },
  {
    'reference': 'OFF-CleanSvcBB',
    'icon': 'illustration-cleaningSvc.svg'
  },
  {
    'reference': 'OFF-WineShpmtBndlBB',
    'icon': 'illustration-wineShipment.svg'
  },
  {
    'reference': 'OFF-HHGBB-INS',
    'icon': 'illustration-hhgProMove.svg'
  }
];

export const benefitQualifyingQuestion = {
  1: 'The transferee meets the minimum requirements for this product',
  2: 'The client has approved the transferee for this product'
};
