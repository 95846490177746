import { Injectable } from '@angular/core';
import { PdfFontsService } from './pdf-fonts.service';
import { formatDate, formatNumber } from '@angular/common';
import { Benefit, HVGDetail, HighValueItem } from '../../../core/models/benefit.transferee.model';

@Injectable({
  providedIn: 'root'
})
export class ProgramHvgReportGenerationService {
  programBorderColor = '#CCCCCC';
  formattedDate;

  /**
   * @param pdfMakeFonts Inject PDF Fonts service
   */
  constructor(
    private readonly pdfMakeFonts: PdfFontsService,
  ) { 
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
    this.pdfMakeFonts.loadLogos();
  }

  /**
   * Generate Document Definition for PDF to be generated
   * @param hvgBenefits Benefits with High Value items Details
   * @param clientInfo Client Details
   * @param transferee transferee Details
   */
  generateDocumentDefinition(hvgBenefits, clientInfo, transfereeInfo, orderId) {
    const documentDefinition = {
      info: { title: 'Program Details', subject: 'Details of programs listed' },
      pageOrientation: 'portrait',
      pageSize: 'LETTER',
      pageMargins: [40, 110, 40, 110],
      header: (currentPage) => {
        return {
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [{
                image: this.pdfMakeFonts.bbLogob64, width: 120, height: 40, alignment: 'left',
                color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [30, 35, 0, 0],
              },
              {
                image: this.pdfMakeFonts.cartusLogob64, width: 120, height: 40, alignment: 'right',
                color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [0, 35, 20, 0],
              }
              ]
            ]
          },
        };
      },
      footer: (currentPage, pageCount) => {
        return {
          table: {
            headerRows: 1, widths: ['60%', '40%'],
            body: [
              [{
                text: `© 2020 Cartus Corporation | All rights reserved. 
             Cartus and the Cartus logo are registered trademarks of Cartus Corporation
             `, fontSize: 9, color: '#575A5D', alignment: 'left', border: [false, false, false, false],
                margin: [30, 30, 0, 0]
              },
              {
                text: `Page ${currentPage.toString()}`, fontSize: 9, color: '#575A5D',
                alignment: 'right', border: [false, false, false, false], margin: [0, 30, 30, 0]
              }
              ]
            ]
          },
        };
      },
      styles: {
        avenirBook: {
          font: 'AvenirBook',
          normal: true
        },
        avenirLight: {
          font: 'AvenirLight',
          fontSize: 18,
          normal: true
        },
        avenirMedium: {
          font: 'AvenirMedium',
          normal: true
        },
        programTable: {
          border: [true, true, true, true], borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#00ffff'],
          fillColor: '#F7F7F7'
        }
      }
    };
    const docDefinition = JSON.parse(JSON.stringify(documentDefinition));
    docDefinition.footer = documentDefinition.footer;
    docDefinition.header = documentDefinition.header;
    const content = [
      this.generateTransfereeSection(clientInfo, transfereeInfo, orderId),
      this.insuranceDetailsHeader(),
      this.generateBenefit(hvgBenefits)
    ];
    docDefinition.content = content;
    return docDefinition;
  }

  generateBenefit(hvgBenefits: Benefit[]) {
    console.log(hvgBenefits);
    let benefitArray = [];
    if (hvgBenefits) {
      let index = 0;
      let totalPrice = 0;
      hvgBenefits.forEach(hvgbenefit => {

        benefitArray = benefitArray.concat(this.shipmentSection(hvgbenefit.displayName, hvgbenefit.hvgDetail.status, hvgbenefit.confirmedDate,
          hvgbenefit.hvgDetail.status === 'Opted Out'? hvgbenefit.hvgDetail.date :  hvgbenefit.hvgDetail.totalReplacementValue ))
        if (hvgbenefit.hvgDetail && !hvgbenefit.hvgDetail.allHighValueTaskOptOut) {

          if (hvgbenefit.hvgDetail.highValueItems && hvgbenefit.hvgDetail.highValueItems.length > 0) {
            let itemIndex = 0;
            hvgbenefit.hvgDetail.highValueItems.forEach((hvgItem) => {
              benefitArray = benefitArray.concat(this.itemCards(hvgItem.name, `$${formatNumber(Math.ceil(hvgItem.replacementValue), 'en-US')}`,
                                                                hvgItem.description? hvgItem.description: '', 
                                                                hvgItem.serialNumber? hvgItem.serialNumber: '', 
                                                                hvgItem.dateAdded? hvgItem.dateAdded: ''));
              if (itemIndex < hvgbenefit.hvgDetail.highValueItems.length) {
                benefitArray = benefitArray.concat(this.cardSpacing())
              }

            })
          } else if (hvgbenefit.hvgDetail.highValueItems && hvgbenefit.hvgDetail.highValueItems.length === 0) {
            benefitArray = benefitArray.concat(this.boxCard())
            benefitArray = benefitArray.concat(this.cardSpacing())
          }

        } else if (hvgbenefit.hvgDetail .allHighValueTaskOptOut) {
          benefitArray = benefitArray.concat(this.cardSpacing());
        }
        index = index + 1;
        if (index < hvgBenefits.length) {
           benefitArray = benefitArray.concat(this.lineDiveder());
        }



      });
    }
   return benefitArray;
  };

  /**
   * Generate Transferee section
   */
  generateTransfereeSection(clientInfo, transfereeInfo, orderId) {
    let clientName: string;
    let orderRefNum: any;
    let fullName: string;
    let clientId: any;
    let atlasCustId: string;

    if (clientInfo && transfereeInfo) {
      clientName = (clientInfo.hasOwnProperty('clientEntityName')) ? clientInfo.clientEntityName : 'NA';
      orderRefNum = transfereeInfo.hasOwnProperty('orderRefNum') ? transfereeInfo.orderRefNum: 'NA';
      fullName = transfereeInfo.nameDetails.firstName + ' ' + transfereeInfo.nameDetails.lastName;
      clientId = (clientInfo.hasOwnProperty('clientNumber')) ? clientInfo.clientNumber : 'NA';
      atlasCustId = orderId ? orderId: "NA";
    }
    //transferee.orderRequestId

    
    const currentDate = new Date();
    this.formattedDate = formatDate(currentDate, 'MMMM dd, yyyy', 'en-US');

    const transfereeDetails = {
      table: {
        headerRows: 0, widths: ['30%', '37%', '33%'], style: 'programTable',
        body: [
          // Transferee Details
          [
            // Transferee Name
            {
              text: fullName, alignment: 'left', color: '#000000', lineHeight: 1, margin: [0, 3, 0, 0],
              border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
            },
            // Client Number
            {
              text: `Client: ${clientName}`, alignment: 'left', color: '#575A5D', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 3, 0, 0], style: 'avenirBook'
            },
            // Generated Date
            {
              text: `Generated: ${this.formattedDate}`, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 3, 0, 0], style: 'avenirBook',
            }
          ],
          [
            // Atlas Customer ID
            {
              text: `Atlas Customer ID: ${atlasCustId}`, alignment: 'left', color: '#575A5D', lineHeight: 1, margin: [0, -2, 0, 0],
              border: [false, false, false, false], fontSize: 9, style: 'avenirBook', colSpan:3
            },
            {
              text: ``, alignment: 'left', color: '#000000', lineHeight: 1, margin: [0, 0, 0, 0],
              border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
            },
            {
              text: ``, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 0, 0, 0], style: 'avenirBook',
            }
          ],
          [
            // Order#
            {
              text: orderRefNum, alignment: 'left', color: '#000000', lineHeight: 1, margin: [0, 2, 0, 5],
              border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
            },
            // Client ID
            {
              text: `ID#: ${clientId}`, alignment: 'left', color: '#575A5D', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 2, 0, 5], style: 'avenirBook'
            },
            {
              text: ` `, alignment: 'center', color: '#19305A', lineHeight: 1,
              fontSize: 9, border: [false, false, false, false], margin: [0, 2, 0, 5], style: 'avenirBook'
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return transfereeDetails;
  }

  /** insurance title */
  insuranceDetailsHeader() {
    const displayText = "Insurance Details";
    const line = {
      table: {
        headerRows: 0, widths: ['2%', '96%', '2%'], style: 'programTable',
        body: [
          [
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, false, false],
              borderColor: ['', '', '', ''], fontSize: 11, margin: [0, 5, 0, 5], style: 'avenirMedium'
            },
            {
              text: displayText, alignment: 'center', color: '#002060', lineHeight: 1,
              border: [false, false, false, false], decoration: 'underline',
              decorationcolor: '#002060',
              fontSize: 20, margin: [0, 8, 0, 0], style: 'avenirMedium'
            },
            {
              text: ` `, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, false, false],
              margin: [0, 5, 0, 0], style: 'avenirMedium'
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return line;
  }

  /**
   * shipment type block 
   * */
  shipmentSection(shipmentType, statusCheck, confirmedOn, total) {
    const totalOrOpt = statusCheck === 'Opted Out' ? `Opt-Out On` : `Total`;
    const totalOrOptVal = statusCheck !== 'Opted Out' ? `$${total}` : total;
    const shipmentCard = {
      unbreakable: true,
      stack: [
        {
          table: {
            headerRows: 0, widths: ['20%', '50%', '15%', '15%'], style: 'programTable',
            body: [
              [
                {
                  text: `Shipment Type: `, color: '#575A5D', border: [false, false, false, false],
                  alignment: 'left', fontsize: 18, margin: [0, 5, 0, 0], style: 'avenirBook',
                },
                {
                  text: `${shipmentType}`, style: 'avenirMedium', border: [false, false, false, false],
                  fontsize: 18, margin: [0, 5, 0, 0],
                },
                {
                  text: `Status: `, alignment: 'right', color: '#575A5D', fontsize: 18, border: [false, false, false, false],
                  margin: [0, 5, 0, 0], style: 'avenirBook',
                },
                {
                  text: `${statusCheck}`, style: 'avenirMedium', border: [false, false, false, false],
                  fontsize: 18, margin: [0, 5, 0, 0], alignment: 'right',
                }
              ],
              [
                {
                  text: `Confirmed On: `, color: '#575A5D', border: [false, false, false, false],
                  alignment: 'left', fontsize: 18, margin: [0, 2, 0, 2], style: 'avenirBook',
                },
                {
                  text: `${confirmedOn}`, style: 'avenirMedium', border: [false, false, false, false],
                  fontsize: 18, margin: [0, 2, 0, 2],
                },
                {
                  text: `${totalOrOpt}: `, color: '#575A5D', border: [false, false, false, false],
                  alignment: 'right', fontsize: 18, margin: [0, 2, 0, 2], style: 'avenirBook',
                },
                {
                  text: `${totalOrOptVal}`,
                  style: 'avenirMedium', border: [false, false, false, false],
                  fontsize: 18, margin: [0, 2, 0, 2], alignment: 'right',
                },
              ]
            ]
          }
        }
      ]
    };
    return shipmentCard;
  }

  /*
  * Item Card Block
  */
  itemCards(itemName, repVal, id1, id2, date) {
    const displayCard = {
      unbreakable: true,
      stack: [
        {
          table: {
            headerRows: 0, widths: ['50%', '30%', '20%'], style: 'programTable',
            body: [
              [
                {
                  text: `${itemName}`, style: 'avenirMedium', border: [true, true, false, false], alignment: 'left',
                  fillColor: '#F7F7F7', fontsize: 18, margin: [0, 5, 0, 5], borderColor: [this.programBorderColor, this.programBorderColor, '', '']
                },
                {
                  text: `Replacement Value: ${repVal}`, color: '#575A5D', style: 'avenirMedium', border: [false, true, true, false], alignment: 'right', colSpan: 2,
                  fillColor: '#F7F7F7', fontsize: 18, margin: [0, 5, 0, 5], borderColor: ['', this.programBorderColor, this.programBorderColor, '']
                },
                {
                  text: ``, fontsize: 18, style: 'avenirMedium', border: [false, true, true, false], alignment: 'left',
                  fillColor: '#F7F7F7', margin: [2, 5, 0, 5], borderColor: ['', this.programBorderColor, this.programBorderColor, '']
                }
              ],
              [
                {
                  text: 'Description of Item', color: '#575A5D', style: 'avenirMedium', border: [true, false, false, false], avenirLight: 'left',
                  fillColor: '#F7F7F7', fontsize: 18, borderColor: [this.programBorderColor, '', '', ''], margin: [0, 0, 0, 0]
                },
                {
                  text: 'ID Number', color: '#575A5D', style: 'avenirMedium', border: [false, false, false, false],
                  fillColor: '#F7F7F7', fontsize: 18, margin: [0, 0, 0, 0], alignment: 'center'
                },
                {
                  text: 'Date Added', color: '#575A5D', style: 'avenirMedium', border: [false, false, true, false],
                  fillColor: '#F7F7F7', fontsize: 18, borderColor: ['', '', this.programBorderColor, ''], alignment: 'center'
                }
              ],
              [
                {
                  text: `${id1}`, style: 'avenirMedium', border: [true, false, false, true], alignment: 'left',
                  fillColor: '#F7F7F7', fontsize: 18, margin: [0, 0, 0, 5], borderColor: [this.programBorderColor, '', '', this.programBorderColor]
                },
                {
                  text: `${id2}`, fontsize: 18, alignment: 'center', style: 'avenirMedium', border: [false, false, false, true],
                  fillColor: '#F7F7F7', margin: [0, 0, 0, 5], borderColor: ['', '', '', this.programBorderColor],
                },
                {
                  text: `${date}`, fontsize: 18, alignment: 'center', style: 'avenirMedium', border: [false, false, true, true],
                  fillColor: '#F7F7F7', margin: [0, 0, 0, 5], borderColor: ['', '', this.programBorderColor, this.programBorderColor]
                }
              ]
            ]
          },
          layout: {
            hLineWidth: function (i, node) { return 0.75; },
            vLineWidth: function (i, node) { return 0.75; }
          }
        }
      ]
    };
    return displayCard;
  }
  /**
   * line diverder 
   */
  lineDiveder() {
    const line = {
      table: {
        headerRows: 0, widths: ['50%', '50%'], style: 'programTable',
        body: [
          [
            {
              text: ``, style: 'avenirMedium', border: [false, true, false, false], fontsize: '0.5', margin: [0, 5, 0, 0],
              borderColor: [this.programBorderColor, this.programBorderColor, this.programBorderColor, this.programBorderColor]
            },
            {
              text: ``, style: 'avenirMedium', border: [false, true, false, false], fontsize: '0.5', margin: [0, 5, 0, 0],
              borderColor: [this.programBorderColor, this.programBorderColor, this.programBorderColor, this.programBorderColor]
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return line;
  }
  /**
   * space between item cards
   */
  cardSpacing() {
    const space = {
      table: {
        body: [
          [
            {
              text: '', margin: [0, 2, 0, 2], border: [false, false, false, false],
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return space;
  }
  /**
   * none added block
   */
  boxCard() {
    const box = {
      unbreakable: true,
      stack: [
        {
          table: {
            headerRows: 0, widths: ['20%', '60%', '20%'], style: 'programTable',
            body: [
              [
                {
                  text: '', style: 'avenirMedium', border: [true, true, false, true], decoration: 'dashed',
                  fontsize: 18, margin: [0, 2, 0, 2], color: '#575A5D', borderColor: [this.programBorderColor, this.programBorderColor, '', this.programBorderColor]
                },
                {
                  text: 'None Added', color: '#575A5D', style: 'avenirMedium', border: [false, true, false, true],
                  fontsize: 18, margin: [0, 2, 0, 2], alignment: 'center', borderColor: ['', this.programBorderColor, '', this.programBorderColor,]
                },
                {
                  text: '', style: 'avenirMedium', border: [false, true, true, true],
                  fontsize: 18, margin: [0, 2, 0, 2], color: '#575A5D', borderColor: ['', this.programBorderColor, this.programBorderColor, this.programBorderColor]
                }
              ]
            ]
          }
        }
      ],
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; },
        hLineStyle: function (i, node) {
          return { dash: { length: 2, space: 1 } };
        },
        vLineStyle: function (i, node) {
          return { dash: { length: 2 } };
        }
      }
    };
    return box;
  }
}
