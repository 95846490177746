import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { BaseClientService } from '../../../app/core/services/base-client.service';
import { catchError, map } from 'rxjs/operators';
import { RemoteLoggingService } from '../../../app/core/services/remote-logging.service';
import { urlType } from '../models/urlType';




/** user service to post details of login to the server
 * header
 */
@Injectable({
    providedIn: 'root'
})
export class LoggedInUserService {
    /**
     * base constructor
     */
    constructor(
        protected baseClient: BaseClientService,
        private readonly logSvc: RemoteLoggingService,
    ) { }

    getLoggedInUserDetails(): Observable<any> {
        if (sessionStorage.getItem('car-ses-con')) {
            return of({userId: sessionStorage.getItem('car-ses-con')});
        } else {
            // return this.baseClient.getById<any>('user/context', '').pipe(  //, urlType.accessmgmt
            //     map(r => r.body),
            //     catchError((err, source) => {
            //         const empty: any = null;
            //         this.logSvc.logError(err);
            //         return of(empty);
            //     })
            // );
            let response = {"userId":"5e5eddde7df4620008652e1c","name":"Finance Contact5"};
            return of (response);
        }
    }

    getLoggedInUserInformation(): Observable<any> {
        return this.baseClient.getById<any>(`/userProfile/${sessionStorage.getItem('car-ses-con')}`, '').pipe( //, urlType.accessmgmt
            map(r => r.body),
            catchError((err, source) => {
                const empty: any = null;
                this.logSvc.logError(err);
                return of(empty);
            })
        );
    }
}
