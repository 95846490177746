<form class="dialog-container" [formGroup]="pointForm">
    <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="10px"
        fxLayoutGap.xs="0em" role="heading">
        <h2 class="divisions-title">Advanced Point Logic</h2>
        <a class="close-icon" (click)="close()" title="close" role="button" aria-label="Close Program Modal"
            title="Close Program Modal">
            <mat-icon>close</mat-icon>
        </a>
    </div>

    <div class="pop-up-title">
        <div class="dialog-title-container">
            <div class="dialog-Wrapper">
                <div class="dialog-title-card">
                    <mat-card class="mat-elevation-z8 benefit-title">{{data.benefit?.displayName}}</mat-card>
                </div>
                <div class="dialog-title-Desc">
                    {{data.benefit?.description}}
                </div>
            </div>
        </div>
    </div>

    <!--Points per selection-->
    <div *ngIf="data.benefit?.pointsPerSelection && data.benefit?.advCashOutV2">
        <mat-card class="points-per-selection">
            <mat-card-content fxLayout="row" class="points">
                <h4 fxFlex="20%"> Points Per Selection </h4>
                <div class="points-input"><input formControlName = "pointsPerSelection" matInput number-only tabindex="0" min="1" aria-label="Points Per Selection"></div>
            </mat-card-content>
        </mat-card>
    </div>

    <!-- middle Section  header-->
    <div class="middle-card-section">
        <div class="card">
            <mat-card>
                <mat-card-header class="card-header">
                    <mat-card-title>
                        <div fxLayout="row">
                            <h4 fxFlex="25%">Max Selections</h4>
                            <h4 fxFlex="17%" class="selection-range-style">Selection Range</h4>
                            <h4 fxFlex="18%">Value Per Point (USD)</h4>
                            <h4 class="total-value-style" fxFlex="20%">Total Value to Transferee</h4>
                            <mat-icon *ngIf="!readonlyMode" class="delete-icon" fxFlex="6.5%">delete</mat-icon>
                        </div>
                    </mat-card-title>
                </mat-card-header>
            </mat-card>
        </div>
    </div>

    <!-- middle Section main content -->
    <div class="scroll">
        <mat-card>
            <mat-card-content>
                <div  class="middle-section" >
                
                <div *ngIf = "pointForm.controls" formArrayName="pointsArray">
                    <div *ngFor="let pointSection of getPointSections(pointForm); let j = index" fxLayout="row" [formGroupName]="j">
                        <!--<mat-form-field class="example-form-field" fxFlex="10%"> -->
                        <mat-select fxFlex="14%" formControlName="maxSelection" class="selection-max">
                            <mat-option *ngFor="let item of numList" [value]="item">{{item}}</mat-option>
                        </mat-select>
                        
                        <div class="range-selection" fxFlex="10%">
                            <p *ngIf = "pointSection.get('maxSelection').value">{{ calculateRange(pointSection.get('maxSelection').value, j) }}</p>
                        </div>

                    <div class="value-point-style" fxFlex="14%">
                            <input matInput   class = "value-input" min="0" type="number" formControlName = "value" >
                    
                    </div>

                    <div class="total-value" fxFlex="30%">
                        <p>{{ calculatePoints(j) }} USD</p>
                    </div>

                    <div *ngIf="!readonlyMode" class="active-delete-style" fxFlex="6.5%">
                        <mat-icon (click) = "deletePoints(j)">delete_outline</mat-icon>
                    </div>
                </div>
                </div>
            </div>
            </mat-card-content>
        </mat-card>
    </div>
    <button  mat-button class="text-button main-btn" [disabled] = "disableAdd()" (click)="getPointsSections()">+ Add</button>
    

    <mat-dialog-actions fxLayout="row" fxLayoutAlign="end end" >
        <button mat-button class="text-button rest-Btn" (click)="close()">CANCEL </button>
        <button mat-button class="contained-button" [disabled] = "pointForm.status === 'INVALID'" (click)='save()'>Save</button>
    </mat-dialog-actions>
</form>
