import { Injectable } from '@angular/core';
import { PdfFontsService } from './pdf-fonts.service';
import { formatDate } from '@angular/common';
import { Benefit, CategoryMapping } from './benefit.service';
import { NeedsAssessmentSharedService } from '../../needs-assessment/services/needs-assessment-shared.service';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})

export class ProgramReportGenerationService {
  /** Personal information form group */
  personalInfoForm: FormGroup;
  needsAssessment;

  readonly BenefitCategories = ['Before You Move', 'Shipping', 'Travel and Transport', 'After You Arrive', 'Relocation Allowances'];
  programBorderColor = '#D0E1EE';
  formattedDate;
  currentStatus;

  /**
   * @param pdfMakeFonts Inject PDF Fonts service
   * @@param needAssessmentSharedSvc Inject Need Assessment service
   */
  constructor(
    private readonly pdfMakeFonts: PdfFontsService,
    private readonly needAssessmentSharedSvc: NeedsAssessmentSharedService
  ) {
    pdfMakeFonts.defineFonts();
    pdfMakeFonts.assignFonts();
    this.pdfMakeFonts.loadLogos();
  }

  /**
   * Generate Document Definition for PDF to be generated
   * @param selectedBenefits Selected Benefits
   * @param confirmedBenefits Confirmed Benefits
   * @param status 'review'|'confirmed'
   */
  generateDocumentDefinition(selectedBenefits, confirmedBenefits, status) {
    this.needsAssessment = this.needAssessmentSharedSvc.transfereeNeedsAssessmentDetails.getValue();
    this.currentStatus = status;
    const documentDefinition = {
      info: { title: 'Program Details', subject: 'Details of programs listed' },
      pageOrientation: 'portrait',
      pageSize: 'LETTER',
      pageMargins: [40, 110, 40, 110],
      header: (currentPage) => {
        return {
          table: {
            headerRows: 1,
            widths: ['50%', '50%'],
            body: [
              [{
                image: this.pdfMakeFonts.bbLogob64, width: 120, height: 40, alignment: 'left',
                color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [30, 35, 0, 0],
              },
              {
                image: this.pdfMakeFonts.cartusLogob64, width: 120, height: 40, alignment: 'right',
                color: '#FFFFFF', fillColor: '#FFFFFF', border: [false, false, false, false], margin: [0, 35, 20, 0],
              }
              ]
            ]
          },
        };
      },
      footer: (currentPage, pageCount) => {
        const year = new Date().getFullYear();
        return {
          table: {
            headerRows: 1, widths: ['60%', '40%'],
            body: [
              [{
                text: `© ${ new Date().getFullYear() } Cartus Corporation | All rights reserved.
             Cartus and the Cartus logo are registered trademarks of Cartus Corporation
             `, fontSize: 9, color: '#575A5D', alignment: 'left', border: [false, false, false, false],
                margin: [30, 30, 0, 0]
              },
              {
                text: `Page ${currentPage.toString()}`, fontSize: 9, color: '#575A5D',
                alignment: 'right', border: [false, false, false, false], margin: [0, 30, 30, 0]
              }
              ]
            ]
          },
        };
      },
      styles: {
        avenirBook: {
          font: 'AvenirBook',
          normal: true
        },
        avenirLight: {
          font: 'AvenirLight',
          fontSize: 18,
          normal: true
        },
        avenirMedium: {
          font: 'AvenirMedium',
          normal: true
        },
        programTable: {
          border: [true, true, true, true], borderColor: ['#ff00ff', '#00ffff', '#ff00ff', '#00ffff'],
          fillColor: '#2065F8'
        }
      }
    };
    const docDefinition = JSON.parse(JSON.stringify(documentDefinition));
    docDefinition.footer = documentDefinition.footer;
    docDefinition.header = documentDefinition.header;
    if (this.currentStatus === 'review') {
      let showHideConfirm = confirmedBenefits.length > 0 ? this.reviewOrConfirm('confirmed') : null;
      docDefinition.watermark = { text: 'DRAFT', opacity: 0.1, fontSize: 175, angle: 325 };
      const content = [this.generateTransfereeSection(), this.generateProgramDetails(),
      this.reviewOrConfirm(status),
      this.generateBenefitsforReview(selectedBenefits), showHideConfirm,
      this.generateBenefitsforConfirmed(confirmedBenefits)];
      docDefinition.content = content;
      return docDefinition;
    } else {
      const content = [this.generateTransfereeSection(), this.generateProgramDetails('confirmed'),
      this.reviewOrConfirm('confirmed'), this.generateBenefitsforConfirmed(confirmedBenefits)];
      docDefinition.content = content;
      return docDefinition;
    }
  }

  /**
   * Generate Transferee section
   */
  generateTransfereeSection() {
    let clientName: string;
    let orderRefNum: any;
    let fullName: string;
    let clientId: any;
    if (this.needsAssessment) {
      clientName = (this.needsAssessment.hasOwnProperty('clientEntityName')) ? this.needsAssessment.clientEntityName : 'NA';
      orderRefNum = (this.needsAssessment.hasOwnProperty('orderRefNum')) ? this.needsAssessment.orderRefNum : 'NA';
      fullName = this.needsAssessment.nameDetails.firstName + ' ' + this.needsAssessment.nameDetails.lastName;
      clientId = (this.needsAssessment.hasOwnProperty('clientId')) ? this.needsAssessment.clientId : 'NA';
    }
    const currentDate = new Date();
    this.formattedDate = formatDate(currentDate, 'MMMM dd, yyyy', 'en-US');

    const transfereeDetails = {
      table: {
        headerRows: 0, widths: ['34%', '33%', '33%'], style: 'programTable',
        body: [
          // Transferee Details
          [
            // Transferee Name
            {
              text: fullName, alignment: 'left', color: '#000000', lineHeight: 1, margin: [0, 10, 0, 0],
              border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
            },
            // Client Number
            {
              text: `Client: ${clientName}`, alignment: 'left', color: '#575A5D', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 10, 0, 0], style: 'avenirBook'
            },
            // Generated Date
            {
              text: `Generated: ${this.formattedDate}`, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 10, 0, 0], style: 'avenirBook',
            }
          ],
          [
            // Order#
            {
              text: orderRefNum, alignment: 'left', color: '#19305A', lineHeight: 1, margin: [0, 2, 0, 5],
              border: [false, false, false, false], fontSize: 9, style: 'avenirBook',
            },
            // Client ID
            {
              text: `ID#: ${clientId}`, alignment: 'left', color: '#575A5D', lineHeight: 1,
              border: [false, false, false, false], fontSize: 9, margin: [0, 2, 0, 5], style: 'avenirBook'
            },
            // Total points value
            {
              text: ` `, alignment: 'center', color: '#19305A', lineHeight: 1,
              fontSize: 9, border: [false, false, false, false], margin: [0, 2, 0, 5], style: 'avenirBook'
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return transfereeDetails;
  }

  /**
   * Generate Program Information section
   * @param status 'review'|'confirmed'
   */
  generateProgramDetails(status?:string) {
    let totalPoints, selectedPoints, confirmedPoints, programName, pointsUsed, remainingPoints;

    if (this.needsAssessment) {
      totalPoints = this.needsAssessment.pointDetails && (this.needsAssessment.pointDetails.hasOwnProperty('points')) ?
        this.needsAssessment.pointDetails.points : 0;
      selectedPoints = this.needsAssessment.pointDetails && (this.needsAssessment.pointDetails.hasOwnProperty('selectedPoints')) ?
        this.needsAssessment.pointDetails.selectedPoints : 0;
      confirmedPoints = this.needsAssessment.pointDetails && (this.needsAssessment.pointDetails.hasOwnProperty('confirmedPoints')) ?
        this.needsAssessment.pointDetails.confirmedPoints : 0;
      programName = this.needsAssessment && (this.needsAssessment.hasOwnProperty('programName')) ?
        this.needsAssessment.programName : 'NA';
      pointsUsed = status && status==='confirmed' ? confirmedPoints : selectedPoints + confirmedPoints;
      remainingPoints = totalPoints - pointsUsed;
    }
    const programDetails = {
      table: {
        headerRows: 0, widths: ['60%', '30%', '10%'], style: 'programTable',
        body: [
          [
            // Program Name
            {
              text: programName, alignment: 'left', color: '#19305A', lineHeight: 1, margin: [0, 10, 0, 0],
              border: [true, true, false, false], borderColor: [this.programBorderColor, this.programBorderColor, '', ''],
              fontSize: 18, style: 'avenirLight',
            },
            // Total Points Header
            {
              text: `Total Points:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, true, false, false],
              borderColor: ['', this.programBorderColor, '', ''], fontSize: 11, margin: [0, 18, 0, 0], style: 'avenirMedium'
            },
            // Total points value
            {
              text: totalPoints, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, true, true, false], borderColor: ['', this.programBorderColor, this.programBorderColor, ''],
              fontSize: 18, margin: [0, 10, 10, 0], style: 'avenirLight',
            }
          ],
          [
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [true, false, false, false],
              borderColor: [this.programBorderColor, '', '', ''], fontSize: 11, margin: [0, 2, 0, 5],
            },
            // Selected Points Header
            {
              text: `Points Used:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, false, false, false],
              borderColor: ['', '', '', ''], fontSize: 11, margin: [0, 2, 0, 0], style: 'avenirMedium'
            },
            // Selected points value
            {
              text: pointsUsed, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, true, false], borderColor: ['', '', this.programBorderColor, ''],
              fontSize: 14, margin: [0, 2, 10, 0], style: 'avenirLight',
            }
          ],
          [
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [true, false, false, true],
              borderColor: [this.programBorderColor, '', '', this.programBorderColor], fontSize: 11, margin: [0, 2, 0, 5],
            },
            // Remaining Points Header
            {
              text: `Points Remaining:`, alignment: 'right', color: '#000000', lineHeight: 1, border: [false, false, false, true],
              borderColor: ['', '', '', this.programBorderColor], fontSize: 11, margin: [0, 2, 0, 0], style: 'avenirMedium'
            },
            // Remaining points value
            {
              text: remainingPoints, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, true, true], borderColor: ['', '', this.programBorderColor, this.programBorderColor],
              fontSize: 14, margin: [0, 2, 10, 0], style: 'avenirLight',
            }
          ],
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return programDetails;
  }

  /**
 * Generate Document Definition for PDF to be generated
 * @param status 'review'|'confirmed'
 */
  reviewOrConfirm(status) {

    const displayText = (status === 'review') ? 'Unconfirmed Benefits Selected' : 'Confirmed Benefits';
    const line = {
      table: {
        headerRows: 0, widths: ['2%', '96%', '2%'], style: 'programTable',
        body: [
          [
            {
              text: ` `, alignment: 'left', color: '#000000', lineHeight: 1, border: [false, false, false, false],
              borderColor: ['', '', '', ''], fontSize: 11, margin: [0, 5, 0, 5], style: 'avenirMedium'
            },
            //  Benefits Selected
            {
              text: displayText, alignment: 'center', color: '#002060', lineHeight: 1,
              border: [false, false, false, false], decoration: 'underline',
              decorationcolor: '#002060',
              fontSize: 14, margin: [0, 5, 0, 0], style: 'avenirMedium'
            },
            {
              text: ` `, alignment: 'right', color: '#19305A', lineHeight: 1,
              border: [false, false, false, false], borderColor: ['', '', '', ''],
              margin: [0, 5, 0, 0], style: 'avenirMedium'
            }
          ]
        ]
      },
      layout: {
        hLineWidth: function (i, node) { return 0.75; },
        vLineWidth: function (i, node) { return 0.75; }
      }
    };
    return line;

  }

  /**
   * Generate Move phase header section of PDF
   * @param movePhase move phase values
   */
  generateMovePhaseHeader(movePhase) {
    const moveHeader = {
      table: {
        widths: ['14%', '86%'],
        body: [
          [{ text: '', margin: [0, 5, 0, 5], border: [false, false, false, false] },
          { text: '', margin: [0, 5, 0, 5], border: [false, false, false, false] },
          ],
          [{
            text: 'Move Phase: ', style: 'avenirMedium', border: [false, false, false, false],
            fillColor: '#EEECE9', fontsize: 11, margin: [0, 5, 0, 5], color: '#575A5D',
          },
          {
            text: movePhase[0].toUpperCase() + movePhase.slice(1), style: 'avenirMedium',
            border: [false, false, false, false], color: '#4F85C4',
            fontsize: 11, fillColor: '#EEECE9', margin: [0, 5, 0, 5],
          }
          ]
        ]
      }
    };
    return moveHeader;
  }

  /**
   * Generate Benefits section of PDF
   * @param benefitDetails Individual benefit details
   * @param status 'review'|'confirmed'
   */
  generateBenefitCard(benefitDetails, status) {
    // tslint:disable-next-line: prefer-const
    let individualBenefit = [];
    const displayText = (status === 'review') ? ' ' : 'Confirmed: ';
    let finalText;
    let dollarValue;
    let finalPoints;

    benefitDetails.forEach(value => {
      let hybridFinalText = '';
      if (value.category === 'core' || value.points.toString().includes('Guaranteed')) {
        finalText = 'Core';
      } else if (value.tierConfigs) {
        if (status === 'review') {
        dollarValue = value.advCashOutPointValue;
        finalPoints = value.advCashOutSelectedPoints;
      } else {
        dollarValue = value.confirmedAdvCashOutValue;
        finalPoints = value.confirmedAdvCashOutPoints;
      }
        finalText = finalPoints + ' Point(s): ' + '\n' + dollarValue.toLocaleString() + ' USD';
      } else if (value.pointsPerIncrement) {
          if (status === 'review') {
            finalText = value.pointsPerIncrement + ' Point(s): ' + (value.rangeIncrementValue)
            + ' ' + value.rangeIncrementUnit ;
          } else {
            finalText = (value.confirmedIncrementCount * value.pointsPerIncrement) + ' Point(s): ' + (value.rangeIncrementValue * value.confirmedIncrementCount)
            + ' ' + value.rangeIncrementUnit ;
          }
      } else if (value.perPointCurrency) {
        if (status === 'review') {
          finalText = (value.rangeIncrementValue ) + ' Point(s): ' + (value.perPointCurrency)
          + ' ' + value.rangeIncrementUnit ;
        } else {
          finalText = (value.confirmedDollarIncrementCount * value.rangeIncrementValue) + ' Point(s): ' + (value.perPointCurrency * value.confirmedDollarIncrementCount)
          + ' ' + value.rangeIncrementUnit ;
        }
      } else if (value.confirmedCount) {
        finalText = value.points * value.confirmedCount + ' Points';
        value.displayName = value.displayName + ' x ' + value.confirmedCount;
      } else {
        finalText = value.points + ' Points';
      }
      individualBenefit.push([
        {
          text: value.displayName, border: [false, false, false, false],
          fontSize: 11, margin: [0, 5, 0, 2], color: '#19305A', style: 'avenirMedium'
        },
        {
          text: finalText,
          style: 'avenirMedium', border: [false, false, false, false], color: '#19305A', fontSize: 11,
          alignment: 'right', margin: [0, 8, 0, 2],
        }
      ]);

      const confirmedDate = value.hasOwnProperty('confirmedDate') ? value.confirmedDate : '';
      if (value.description) {
        individualBenefit.push([
          {
            text: value.description, border: [false, false, false, false], fontSize: 9,
            margin: [0, 0, 0, 2], color: '#000000', style: 'avenirBook'
          },
          {
            text: displayText + `${confirmedDate}`,
            border: [false, false, false, false], color: '#19305A',
            fontSize: 9, alignment: 'right', margin: [0, 0, 0, 2], style: 'avenirMedium'
          }
        ]);
      }
      individualBenefit.push([
        {
          text: '', margin: [0, 3, 0, 3], border: [false, false, false, false]
        },
        {
          text: '', margin: [0, 3, 0, 3], border: [false, false, false, false]
        },
      ]);
    });


    if (status == 'review') {
      const benefitCard = {
        table: { widths: ['85%', '15%'], body: individualBenefit }
      };
      return benefitCard;
    } else {
      const benefitCard = {
        table: { widths: ['80%', '20%'], body: individualBenefit }
      };
      return benefitCard;
    }
  }

  /**
   * Generate Benefits Section (Move Phase Header and benefits)
   * @param selectedBenefits Selected Benfits
   */
  generateBenefitsforReview(selectedBenefits) {

    let benefitsArray = [];
    let benefitsList;
    if (selectedBenefits) {
      benefitsList = this.groupBenefits(selectedBenefits).map(category => {
        return {
          group: category.group,
          items: category.items
        };
      }).filter(category => category.items.length !== 0);
    }

    benefitsList.forEach(function (value, key) {
      benefitsArray = benefitsArray.concat(this.generateMovePhaseHeader(value.group));
      benefitsArray = benefitsArray.concat(this.generateBenefitCard(value.items, 'review'));
    }.bind(this));
    return benefitsArray;
  }

  generateBenefitsforConfirmed(confirmedBenefits) {
    let benefitsArray = [];
    let benefitsList;
    if (confirmedBenefits) {
      benefitsList = this.groupBenefits(confirmedBenefits).map(category => {
        return {
          group: category.group,
          items: category.items
        };
      }).filter(category => category.items.length !== 0);
    }

    benefitsList.forEach(function (value, key) {
      benefitsArray = benefitsArray.concat(this.generateMovePhaseHeader(value.group));
      benefitsArray = benefitsArray.concat(this.generateBenefitCard(value.items, 'confirmed'));
    }.bind(this));
    return benefitsArray;
  }

  groupBenefits(benefits: Array<Benefit>): Array<{ group: string, items: Array<Benefit> }> {
    benefits = this.updateCategoryNames(benefits);
    const benefitsByGroup = Object.values(CategoryMapping).map(category => {
      return {
        group: category,
        items: benefits.filter(benefit => benefit.category === category)
        // points: benefits.filter(benefit => (benefit.points.toString() === 'Guaranteed')).
        // concat(benefits.filter(benefit => benefit.points.toString() !== 'Guaranteed'))
      };
    });
    return benefitsByGroup;
  }

  /**
   * Update category for benefits
   * @param benefits Benefits
   */
  updateCategoryNames(benefits: Array<Benefit>) {
    return benefits.map(benefit => {
      if (this.BenefitCategories.includes(benefit.category)) {
        benefit.category = CategoryMapping[benefit.category];
      }
      return benefit;
    });
  }
  convertToMultiplicity(selectedBenefits, frequency): any {
    let dups = [];
    let arr = selectedBenefits.filter(function(el) {
      if(el.hybridTempLiving) {
        dups.push(el.displayName);
        return true
      }
      else if (dups.indexOf(el.displayName) == -1) {
        dups.push(el.displayName);
        return true;
      }
      return false;
    });
    let data = [];
    for (const item of frequency) {
      if (item[1] > 1) {
        let obj = {};
        obj[`key`] = item[0];
        obj[`value`] = item[1];
        data.push(obj);
      }
    }
    for(const item of arr) {
      for(const i of data) {
        if(item.displayName === i.key && !item.hybridTempLiving) {
          item.displayName = item.displayName + ' x ' + i.value;
        }
      }
    }
    return arr;
  }

}
