import { Location } from '../../core/models/location';
import { CostModel } from './../../core/models/cost-model';
import { Level } from './../../core/models/level';
import { Candidate, CandidateProfiles } from './../../core/models/candidate_cost_model';
import { APIResponse, APIResponseDuplicate } from './../../core/models/response_cost_model.model';
import { Address } from './../../core/models/address_cost_model.model';
import { CostEstimate } from './../../core/models/cost-estimate';

/** stores level mapping list */
export const LEVEL_MAPPING = [
    {
        "level": 'Level 1 (Over 150,000 USD)',
        "costEstimates": [
            {
                "familySize": 1,
                "coreServices": {
                    "estimatedSubTotalMaximumCost": 10128,
                    "estimatedSubTotalMinimumCost": 3581
                },
                "flexServices": {
                    "estimatedSubTotalMinimumCost": 13275,
                    "estimatedSubTotalMaximumCost": 39741
                },
                "estimatedTotalMaximumCost": 49869,
                "estimatedTotalMinimumCost": 16856
            },
            {
                "familySize": 2,
                "coreServices": {
                    "estimatedSubTotalMaximumCost": 18181,
                    "estimatedSubTotalMinimumCost": 6285
                },
                "flexServices": {
                    "estimatedSubTotalMinimumCost": 14873,
                    "estimatedSubTotalMaximumCost": 46730
                },
                "estimatedTotalMaximumCost": 64911,
                "estimatedTotalMinimumCost": 21158
            },
            {
                "familySize": 4,
                "coreServices": {
                    "estimatedSubTotalMaximumCost": 24030,
                    "estimatedSubTotalMinimumCost": 10135
                },
                "flexServices": {
                    "estimatedSubTotalMinimumCost": 16100,
                    "estimatedSubTotalMaximumCost": 59162
                },
                "estimatedTotalMaximumCost": 83192,
                "estimatedTotalMinimumCost": 26235
            }
        ],
    },
    {
        "level": 'Level 2 (75,000 - 150,000 USD)',
        'costEstimates': [
            {
                'familySize': 1,
                'coreServices': {
                    'estimatedSubTotalMaximumCost': 11261,
                    'estimatedSubTotalMinimumCost': 4525
                },
                'flexServices': {
                    'estimatedSubTotalMinimumCost': 8932,
                    'estimatedSubTotalMaximumCost': 26829
                },
                'estimatedTotalMaximumCost': 38090,
                'estimatedTotalMinimumCost': 13457
            },
            {
                'familySize': 2,
                'coreServices': {
                    'estimatedSubTotalMaximumCost': 20171,
                    'estimatedSubTotalMinimumCost': 8752
                },
                'flexServices': {
                    'estimatedSubTotalMinimumCost': 10441,
                    'estimatedSubTotalMaximumCost': 33846
                },
                'estimatedTotalMaximumCost': 54017,
                'estimatedTotalMinimumCost': 19193
            },
            {
                'familySize': 4,
                'coreServices': {
                    'estimatedSubTotalMaximumCost': 24740,
                    'estimatedSubTotalMinimumCost': 12296
                },
                'flexServices': {
                    'estimatedSubTotalMinimumCost': 11994,
                    'estimatedSubTotalMaximumCost': 40055
                },
                'estimatedTotalMaximumCost': 64795,
                'estimatedTotalMinimumCost': 24290
            }
        ]
    },
    {
        "level": "Level 3 (Less than 75,000 USD)",
        "costEstimates": [
            {
                "familySize": 1,
                "coreServices": {
                    "estimatedSubTotalMaximumCost": 8161,
                    "estimatedSubTotalMinimumCost": 3626
                },
                "flexServices": {
                    "estimatedSubTotalMinimumCost": 3092,
                    "estimatedSubTotalMaximumCost": 11913
                },
                "estimatedTotalMaximumCost": 20074,
                "estimatedTotalMinimumCost": 6718
            },
            {
                "familySize": 2,
                "coreServices": {
                    "estimatedSubTotalMaximumCost": 15111,
                    "estimatedSubTotalMinimumCost": 7099
                },
                "flexServices": {
                    "estimatedSubTotalMinimumCost": 4065,
                    "estimatedSubTotalMaximumCost": 16395
                },
                "estimatedTotalMaximumCost": 31506,
                "estimatedTotalMinimumCost": 11164
            },
            {
                "familySize": 4,
                "coreServices": {
                    "estimatedSubTotalMaximumCost": 18817,
                    "estimatedSubTotalMinimumCost": 10169
                },
                "flexServices": {
                    "estimatedSubTotalMinimumCost": 5790,
                    "estimatedSubTotalMaximumCost": 20628
                },
                "estimatedTotalMaximumCost": 39445,
                "estimatedTotalMinimumCost": 15959
            }
        ]
    }
];
/** stores cost model mock list */
export const COST_MODEL_LIST = {
    status: 200,
    statusText: 'OK',
    url: 'https://costmodeltst01.cartus.com/costmodel?clientContactId=5de2cd7d65236577b9223541&sortField=updatedDate&sortDir=DESC&limit=20',
    ok: true,
    type: 4,
    body: {
        "costmodels": [
            {
                "costModelName": "Business Analyst",
                "costModelId": "5e7a56531275510008fdc1cd",
                "clientId": "5e5edd467df4620008652e1a",
                "clientContactId": "5e5eddde7df4620008652e1c",
                "clientName": "",
                "clientContactName": "",
                "costEstimates": [
                    {
                        "familySize": 1,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 8021,
                            "estimatedSubTotalMinimumCost": 2545
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 5028,
                            "estimatedSubTotalMaximumCost": 14279
                        },
                        "estimatedTotalMaximumCost": 22300,
                        "estimatedTotalMinimumCost": 7573
                    },
                    {
                        "familySize": 2,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 14970,
                            "estimatedSubTotalMinimumCost": 4939
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 6625,
                            "estimatedSubTotalMaximumCost": 19399
                        },
                        "estimatedTotalMaximumCost": 34369,
                        "estimatedTotalMinimumCost": 11564
                    },
                    {
                        "familySize": 4,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 18659,
                            "estimatedSubTotalMinimumCost": 7882
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 8801,
                            "estimatedSubTotalMaximumCost": 24563
                        },
                        "estimatedTotalMaximumCost": 43222,
                        "estimatedTotalMinimumCost": 16683
                    }
                ],
                "taxGrossRate": 0.42146410803127227,
                "destState": "FL",
                "destCity": "Miami",
                "deptState": "CT",
                "deptCity": "Danbury",
                "level": "Level 2 (75,000 - 150,000 USD)",
                "createdDate": "2020-03-24T18:49:55.125Z",
                "updatedDate": "2020-03-24T18:50:04.060Z"
            },
            {
                "costModelName": "Technical Analyst",
                "costModelId": "5e83917cd0989200087aaebc",
                "clientId": "5e5edd467df4620008652e1a",
                "clientContactId": "5e5eddde7df4620008652e1c",
                "clientName": "",
                "clientContactName": "",
                "costEstimates": [
                    {
                        "familySize": 1,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 12176,
                            "estimatedSubTotalMinimumCost": 5171
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 3543,
                            "estimatedSubTotalMaximumCost": 12535
                        },
                        "estimatedTotalMaximumCost": 24711,
                        "estimatedTotalMinimumCost": 8714
                    },
                    {
                        "familySize": 2,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 22462,
                            "estimatedSubTotalMinimumCost": 10225
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 5056,
                            "estimatedSubTotalMaximumCost": 17620
                        },
                        "estimatedTotalMaximumCost": 40082,
                        "estimatedTotalMinimumCost": 15281
                    },
                    {
                        "familySize": 4,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 27903,
                            "estimatedSubTotalMinimumCost": 14756
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 7234,
                            "estimatedSubTotalMaximumCost": 22377
                        },
                        "estimatedTotalMaximumCost": 50280,
                        "estimatedTotalMinimumCost": 21990
                    }
                ],
                "taxGrossRate": 0.62999185004075,
                "destState": "OR",
                "destCity": "Boardman",
                "deptState": "FL",
                "deptCity": "Venice",
                "level": "Level 2 (75,000 - 150,000 USD)",
                "createdDate": "2020-03-31T18:52:44.461Z",
                "updatedDate": "2020-03-31T18:53:24.880Z"
            },
            {
                "costModelName": "Developer",
                "costModelId": "5e83b4d5d0b0800007ff201e",
                "clientId": "5e5edd467df4620008652e1a",
                "clientContactId": "5e5eddde7df4620008652e1c",
                "clientName": "",
                "clientContactName": "",
                "costEstimates": [
                    {
                        "familySize": 1,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 8161,
                            "estimatedSubTotalMinimumCost": 3626
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 3092,
                            "estimatedSubTotalMaximumCost": 11913
                        },
                        "estimatedTotalMaximumCost": 20074,
                        "estimatedTotalMinimumCost": 6718
                    },
                    {
                        "familySize": 2,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 15111,
                            "estimatedSubTotalMinimumCost": 7099
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 4065,
                            "estimatedSubTotalMaximumCost": 16395
                        },
                        "estimatedTotalMaximumCost": 31506,
                        "estimatedTotalMinimumCost": 11164
                    },
                    {
                        "familySize": 4,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 18817,
                            "estimatedSubTotalMinimumCost": 10169
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 5790,
                            "estimatedSubTotalMaximumCost": 20628
                        },
                        "estimatedTotalMaximumCost": 39445,
                        "estimatedTotalMinimumCost": 15959
                    }
                ],
                "taxGrossRate": 0.62999185004075,
                "destState": "OR",
                "destCity": "Boardman",
                "deptState": "FL",
                "deptCity": "Venice",
                "level": "Level 3 (Less than 75,000 USD)",
                "createdDate": "2021-04-25T21:23:30.273Z",
                "updatedDate": "2021-04-25T21:28:00.477Z"
            },
            {
                "costModelName": "QA",
                "costModelId": "5e9dcc8011a8dd0008df7ec7",
                "clientId": "5e5edd467df4620008652e1a",
                "clientContactId": "5e5eddde7df4620008652e1c",
                "clientName": "",
                "clientContactName": "",
                "costEstimates": [
                    {
                        "familySize": 1,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 8012,
                            "estimatedSubTotalMinimumCost": 2536
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 5030,
                            "estimatedSubTotalMaximumCost": 14272
                        },
                        "estimatedTotalMaximumCost": 22284,
                        "estimatedTotalMinimumCost": 7566
                    },
                    {
                        "familySize": 2,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 14953,
                            "estimatedSubTotalMinimumCost": 4922
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 6625,
                            "estimatedSubTotalMaximumCost": 19388
                        },
                        "estimatedTotalMaximumCost": 34341,
                        "estimatedTotalMinimumCost": 11547
                    },
                    {
                        "familySize": 4,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 18636,
                            "estimatedSubTotalMinimumCost": 7859
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 8802,
                            "estimatedSubTotalMaximumCost": 24549
                        },
                        "estimatedTotalMaximumCost": 43185,
                        "estimatedTotalMinimumCost": 16661
                    }
                ],
                "taxGrossRate": 0.42146410803127227,
                "destState": "FL",
                "destCity": "Miami",
                "deptState": "CT",
                "deptCity": "Danbury",
                "level": "Level 2 (75,000 - 150,000 USD)",
                "createdDate": "2020-04-20T16:23:25.807Z",
                "updatedDate": "2020-04-20T16:23:43.531Z"
            },
            {
                "costModelName": "Vice President ",
                "costModelId": "60a2a100f35a400009455791",
                "clientId": "5e5edd467df4620008652e1a",
                "clientContactId": "5e5eddde7df4620008652e1c",
                "clientName": "",
                "clientContactName": "",
                "costEstimates": [
                    {
                        "familySize": 1,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 10128,
                            "estimatedSubTotalMinimumCost": 3581
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 13275,
                            "estimatedSubTotalMaximumCost": 39741
                        },
                        "estimatedTotalMaximumCost": 49869,
                        "estimatedTotalMinimumCost": 16856
                    },
                    {
                        "familySize": 2,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 18181,
                            "estimatedSubTotalMinimumCost": 6285
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 14873,
                            "estimatedSubTotalMaximumCost": 46730
                        },
                        "estimatedTotalMaximumCost": 64911,
                        "estimatedTotalMinimumCost": 21158
                    },
                    {
                        "familySize": 4,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 24030,
                            "estimatedSubTotalMinimumCost": 10135
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 16100,
                            "estimatedSubTotalMaximumCost": 59162
                        },
                        "estimatedTotalMaximumCost": 83192,
                        "estimatedTotalMinimumCost": 26235
                    }
                ],
                "taxGrossRate": 0.6329232928589399,
                "destState": "NY",
                "destCity": "New York",
                "deptState": "CA",
                "deptCity": "Los Angeles",
                "level": "Level 1 (Over 150,000 USD)",
                "createdDate": "2021-05-17T16:59:42.092Z",
                "updatedDate": "2021-05-17T17:00:22.961Z"
            },
            {
                "costModelName": "Vice President ",
                "costModelId": "60a2a100f35a400009455791",
                "clientId": "5e5edd467df4620008652e1a",
                "clientContactId": "5e5eddde7df4620008652e1c",
                "clientName": "",
                "clientContactName": "",
                "costEstimates": [
                    {
                        "familySize": 1,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 158450,
                            "estimatedSubTotalMinimumCost": 75453
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 70836 ,
                            "estimatedSubTotalMaximumCost": 148756
                        },
                        "estimatedTotalMaximumCost": 246640 ,
                        "estimatedTotalMinimumCost": 517944
                    },
                    {
                        "familySize": 2,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 187945,
                            "estimatedSubTotalMinimumCost": 89498
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 85225,
                            "estimatedSubTotalMaximumCost": 178973
                        },
                        "estimatedTotalMaximumCost": 283117,
                        "estimatedTotalMinimumCost": 308777
                    },
                    {
                        "familySize": 4,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 212272,
                            "estimatedSubTotalMinimumCost": 106137
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 94238,
                            "estimatedSubTotalMaximumCost": 167400
                        },
                        "estimatedTotalMaximumCost": 324216,
                        "estimatedTotalMinimumCost": 648432
                    }
                ],
                "taxGrossRate": 0.6329232928589399,
                "destState": "JPY",
                "destCity": "Tokyo",
                "deptState": "UK",
                "deptCity": "London",
                "level": "Level 1 (Over 150,000 USD)",
                "createdDate": "2021-05-17T16:59:42.092Z",
                "updatedDate": "2021-05-17T17:00:22.961Z"
            },
            {
                "costModelName": "Business Analyst",
                "costModelId": "60a2a528ae85630008c486c6",
                "clientId": "5e5edd467df4620008652e1a",
                "clientContactId": "5e5eddde7df4620008652e1c",
                "clientName": "",
                "clientContactName": "",
                "costEstimates": [
                    {
                        "familySize": 1,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 4636,
                            "estimatedSubTotalMinimumCost": 2100
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 3861,
                            "estimatedSubTotalMaximumCost": 14758
                        },
                        "estimatedTotalMaximumCost": 19394,
                        "estimatedTotalMinimumCost": 5961
                    },
                    {
                        "familySize": 2,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 8814,
                            "estimatedSubTotalMinimumCost": 3542
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 4172,
                            "estimatedSubTotalMaximumCost": 18643
                        },
                        "estimatedTotalMaximumCost": 27457,
                        "estimatedTotalMinimumCost": 7714
                    },
                    {
                        "familySize": 4,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 11845,
                            "estimatedSubTotalMinimumCost": 5287
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 5327,
                            "estimatedSubTotalMaximumCost": 23315
                        },
                        "estimatedTotalMaximumCost": 35160,
                        "estimatedTotalMinimumCost": 10614
                    }
                ],
                "taxGrossRate": 0.42146410803127227,
                "destState": "TX",
                "destCity": "Dallas",
                "deptState": "IL",
                "deptCity": "Chicago",
                "level": "Level 3 (Less than 75,000 USD)",
                "createdDate": "2021-05-17T17:17:27.969Z",
                "updatedDate": "2021-05-17T17:17:49.356Z"
            },
            {
                "costModelName": "Director Relocation",
                "costModelId": "60a2a5baae85630008c486c7",
                "clientId": "5e5edd467df4620008652e1a",
                "clientContactId": "5e5eddde7df4620008652e1c",
                "clientName": "",
                "clientContactName": "",
                "costEstimates": [
                    {
                        "familySize": 1,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 9986,
                            "estimatedSubTotalMinimumCost": 4608
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 8532,
                            "estimatedSubTotalMaximumCost": 23897
                        },
                        "estimatedTotalMaximumCost": 33883,
                        "estimatedTotalMinimumCost": 13140
                    },
                    {
                        "familySize": 2,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 18485,
                            "estimatedSubTotalMinimumCost": 8087
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 9729,
                            "estimatedSubTotalMaximumCost": 28804
                        },
                        "estimatedTotalMaximumCost": 47289,
                        "estimatedTotalMinimumCost": 17816
                    },
                    {
                        "familySize": 4,
                        "coreServices": {
                            "estimatedSubTotalMaximumCost": 23828,
                            "estimatedSubTotalMinimumCost": 13279
                        },
                        "flexServices": {
                            "estimatedSubTotalMinimumCost": 16059,
                            "estimatedSubTotalMaximumCost": 37786
                        },
                        "estimatedTotalMaximumCost": 61614,
                        "estimatedTotalMinimumCost": 29338
                    }
                ],
                "taxGrossRate": 0.42146410803127227,
                "destState": "FL",
                "destCity": "Miami",
                "deptState": "OR",
                "deptCity": "Portland",
                "level": "Level 2 (75,000 - 150,000 USD)",
                "createdDate": "2021-05-17T17:19:54.237Z",
                "updatedDate": "2021-05-17T17:20:09.752Z"
            }
        ],
        "totalCostModel": 8
    }
};
/** stores location mock data */
export const LOCATION: Location[] = [
    { name: 'NJ, Nutley', id: 'desitnation1' },
    { name: 'GA, Atlanta', id: 'desitnation2' },
    { name: 'TX, Dallas', id: 'desitnation3' },
    { name: 'NJ, Jersey City', id: 'desitnation4' },
    { name: 'TX, Houston', id: 'desitnation1' },
    { name: 'NY, New York City', id: 'desitnation2' },
    { name: 'NY, Fushing', id: 'desitnation3' },
    { name: 'NJ, Edison', id: 'desitnation4' },
    { name: 'NJ, Newark', id: 'desitnation1' },
    { name: 'TX, Austin', id: 'desitnation2' }
];

/** stores level list mock data */
export const LEVEL_LIST: Level[] = [
    {
        levelId: 'L1',
        levelName: 'Level 1 (Over 150,000 USD)',
        levelDescription: 'Level 1 - Salary Range'
    },
    {
        levelId: 'L2',
        levelName: 'Level 2 (75,000 - 150,000 USD)',
        levelDescription: 'Level 2 - Salary Range'
    },
    {
        levelId: 'L3',
        levelName: 'Level 3 (Less than 75,000 USD)',
        levelDescription: 'Level 3 - Salary Range'
    }
];

/** stores cost model selected mock values */
export const MODEL_NAMES: string[] = [
    'select',
    'modelName',
    'level.levelName',
    'departure',
    'destination',
    'status',
];
/** stores mock address */
export const ADDRESS_DATA: Address[] = [
    {
        "fullAddress": "Austin, TX, USA",
        "streetAddress": "",
        "city": "Austin",
        "state": "TX",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Atlanta, GA, USA",
        "streetAddress": "",
        "city": "Atlanta",
        "state": "GA",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Arlington, OR, USA",
        "streetAddress": "",
        "city": "Arlington",
        "state": "OR",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Auburn, WA, USA",
        "streetAddress": "",
        "city": "Auburn",
        "state": "WA",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Astoria, OR, USA",
        "streetAddress": "",
        "city": "Astoria",
        "state": "OR",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "New York, NY, USA",
        "streetAddress": "",
        "city": "New York",
        "state": "NY",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Nashville, TN, USA",
        "streetAddress": "",
        "city": "Nashville",
        "state": "TN",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "New Orleans, LA, USA",
        "streetAddress": "",
        "city": "New Orleans",
        "state": "LA",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "North Bend, WA, USA",
        "streetAddress": "",
        "city": "North Bend",
        "state": "WA",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Newport, OR, USA",
        "streetAddress": "",
        "city": "Newport",
        "state": "OR",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Moses Lake, WA, USA",
        "streetAddress": "",
        "city": "Moses Lake",
        "state": "WA",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Miami, FL, USA",
        "streetAddress": "",
        "city": "Miami",
        "state": "FL",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Missoula, MT, USA",
        "streetAddress": "",
        "city": "Missoula",
        "state": "MT",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Medford, OR, USA",
        "streetAddress": "",
        "city": "Medford",
        "state": "OR",
        "zipcode": null,
        "country": "USA"
    },
    {
        "fullAddress": "Moab, UT, USA",
        "streetAddress": "",
        "city": "Moab",
        "state": "UT",
        "zipcode": null,
        "country": "USA"
    }
];
/**states mock data */
export const stateList = [
    { 'name': 'Alabama', 'shortName': 'AL' },
    { 'name': 'Alaska', 'shortName': 'AK' },
    { 'name': 'Arizona', 'shortName': 'AZ' },
    { 'name': 'Arkansas', 'shortName': 'AR' },
    { 'name': 'California', 'shortName': 'CA' },
    { 'name': 'Colorado', 'shortName': 'CO' },
    { 'name': 'Connecticut', 'shortName': 'CT' },
    { 'name': 'Delaware', 'shortName': 'DE' },
    { 'name': 'District of Columbia', 'shortName': 'DC' },
    { 'name': 'Florida', 'shortName': 'FL' },
    { 'name': 'Georgia', 'shortName': 'GA' },
    { 'name': 'Hawaii', 'shortName': 'HI' },
    { 'name': 'Idaho', 'shortName': 'ID' },
    { 'name': 'Illinois', 'shortName': 'IL' },
    { 'name': 'Indiana', 'shortName': 'IN' },
    { 'name': 'Iowa', 'shortName': 'IA' },
    { 'name': 'Kansa', 'shortName': 'KS' },
    { 'name': 'Kentucky', 'shortName': 'KY' },
    { 'name': 'Lousiana', 'shortName': 'LA' },
    { 'name': 'Maine', 'shortName': 'ME' },
    { 'name': 'Maryland', 'shortName': 'MD' },
    { 'name': 'Massachusetts', 'shortName': 'MA' },
    { 'name': 'Michigan', 'shortName': 'MI' },
    { 'name': 'Minnesota', 'shortName': 'MN' },
    { 'name': 'Mississippi', 'shortName': 'MS' },
    { 'name': 'Missouri', 'shortName': 'MO' },
    { 'name': 'Montana', 'shortName': 'MT' },
    { 'name': 'Nebraska', 'shortName': 'NE' },
    { 'name': 'Nevada', 'shortName': 'NV' },
    { 'name': 'New Hampshire', 'shortName': 'NH' },
    { 'name': 'New Jersey', 'shortName': 'NJ' },
    { 'name': 'New Mexico', 'shortName': 'NM' },
    { 'name': 'New York', 'shortName': 'NY' },
    { 'name': 'North Carolina', 'shortName': 'NC' },
    { 'name': 'North Dakota', 'shortName': 'ND' },
    { 'name': 'Ohio', 'shortName': 'OH' },
    { 'name': 'Oklahoma', 'shortName': 'OK' },
    { 'name': 'Oregon', 'shortName': 'OR' },
    { 'name': 'Pennsylvania', 'shortName': 'PA' },
    { 'name': 'Rhode Island', 'shortName': 'RI' },
    { 'name': 'South Carolina', 'shortName': 'SC' },
    { 'name': 'South Dakota', 'shortName': 'SD' },
    { 'name': 'Tennessee', 'shortName': 'TN' },
    { 'name': 'Texas', 'shortName': 'TX' },
    { 'name': 'Utah', 'shortName': 'UT' },
    { 'name': 'Vermont', 'shortName': 'VT' },
    { 'name': 'Virginia', 'shortName': 'VA' },
    { 'name': 'Washington', 'shortName': 'WA' },
    { 'name': 'West Virginia', 'shortName': 'WV' },
    { 'name': 'Wisconsin', 'shortName': 'WI' },
    { 'name': 'Wyoming', 'shortName': 'WY' }
];