<div class="filter-modal-wrap">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title">
        <h2>Filter Table</h2><button tabindex="0" mat-icon-button mat-dialog-close aria-label="Close" matTooltip="Close"><span
                class="material-icons close-icon">close</span></button>
    </div>
    <div class="mat-dialog-content">
        <div fxLayout fxLayout.lt-md="column" fxLayoutAlign="space-between center" fxLayoutAlign.gt-md="flex-start"
         fxLayoutGap.gt-md="25px" style="height: 17em;">
            <div *ngIf = "data.displayedColumns.includes('status')" class="movePhase-filter">
                <h6 id="movePhaseLbl">Status:</h6>
                <app-admin-filter-selection 
                    labelId="movePhaseLbl"
                    (selectedDataSetChange)="selectedDataSetChange('movePhases', $event)"
                    [dataSet]="movePhases" [selectedDataSet]="selectedMovePhases" [PlaceHolder]="'Status'"></app-admin-filter-selection>
            </div>
            <div *ngIf = "data.displayedColumns.includes('departure')" class="departure-filter">
                <h6 id="departureLbl">Departure:</h6>
                <app-admin-filter-selection 
                    [filterOptions]="locationFilterOptions" 
                    labelId="departureLbl"
                    (selectedDataSetChange)="selectedDataSetChange('departure', $event)" (search)="getLocations('departure', $event)"
                    [dataSet]="departure" [PlaceHolder]="'City, State, or Country'"></app-admin-filter-selection>
            </div>
            <div class="destination-filter" *ngIf = "data.displayedColumns.includes('destination')">
                <h6 id="destinationLbl">Destination:</h6>
                <app-admin-filter-selection 
                    [filterOptions]="locationFilterOptions" 
                    labelId="destinationLbl"
                    (selectedDataSetChange)="selectedDataSetChange('destination', $event)" (search)="getLocations('destination', $event)"
                    [dataSet]="destination" [PlaceHolder]="'City, State, or Country'"></app-admin-filter-selection>
            </div>
        </div>
        <div fxLayout fxLayout.lt-md="column" fxLayoutAlign="flex-start" fxLayoutAlign.gt-md="flex-start"
            fxLayoutGap.gt-md="25px">
            <div 
            
             class="movePhase-filter">
                <h6 id="authorizationTypeLbl">Authorization Type:</h6>
                <app-admin-filter-selection 
                    labelId="authorizationTypeLbl"
                    (selectedDataSetChange)="selectedDataSetChange('authorizationType', $event)"
                    [dataSet]="authorizationType" [selectedDataSet]="selectedAuthorizationType" [PlaceHolder]="'Authorization Type'"></app-admin-filter-selection>
            </div>
            <div class="selection-block" fxFlex="50%" fxLayout="column" fxLayoutAlign="flex-start"
                fxLayoutGap.gt-md="25px">
                <div class="range-block">
                    <div class="relative-block" id="statusTransferDate">
                        <h6>Status Date Range:</h6>
                        <div class="inline-blk startDate date">
                            <mat-form-field>
                                <input matInput [matDatepicker]="startDate" placeholder="Start Date"
                                    [(ngModel)]="statusStartDate" (dateChange)="selectedDataSetChange('startDate', $event.value)">
                                <mat-datepicker-toggle matSuffix [for]="startDate" matTooltip="Calendar">
                                </mat-datepicker-toggle>
                                <mat-datepicker #startDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="hyphenField inline-blk">-</div>
                        <div class="inline-blk date">
                            <mat-form-field>
                                <input matInput [matDatepicker]="endDate" placeholder="End Date"
                                    [(ngModel)]="statusEndDate" (dateChange)="selectedDataSetChange('endDate', $event.value)" [min] = 'startDate'>
                                <mat-datepicker-toggle matSuffix [for]="endDate" matTooltip="Calendar">
                                </mat-datepicker-toggle>
                                <mat-datepicker #endDate></mat-datepicker>

                            </mat-form-field>
                        </div>
                        <mat-error>
                            {{ getErrorMessage() }}
                        </mat-error>
                    </div>
                </div>
            </div>

            <!-- Authorization date range picker-->
            <div *ngIf="showAuthorizationDataRange"class="selection-block" fxFlex="50%" fxLayout="column" fxLayoutAlign="flex-start"
                fxLayoutGap.gt-md="25px">
                <div class="range-block">
                    <div class="relative-block" id="statusTransferDate">
                        <h6>Authorization Date Range:</h6>
                        <div class="inline-blk startDate date">
                            <mat-form-field>
                                <input matInput [matDatepicker]="authStartDate" placeholder="Start Date"
                                    [(ngModel)]="authorizationStartDate" (dateChange)="selectedAuthorizationDataSetChange('authorizationStartDate', $event.value)">
                                <mat-datepicker-toggle matSuffix [for]="authStartDate" matTooltip="Calendar">
                                </mat-datepicker-toggle>
                                <mat-datepicker #authStartDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="hyphenField inline-blk">-</div>
                        <div class="inline-blk date">
                            <mat-form-field>
                                <input matInput [matDatepicker]="authEndDate" placeholder="End Date"
                                    [(ngModel)]="authorizationEndDate" (dateChange)="selectedAuthorizationDataSetChange('authorizationEndDate', $event.value)" [min] = 'authStartDate'>
                                <mat-datepicker-toggle matSuffix [for]="authEndDate" matTooltip="Calendar">
                                </mat-datepicker-toggle>
                                <mat-datepicker #authEndDate></mat-datepicker>

                            </mat-form-field>
                        </div>
                        <mat-error>
                            {{ getErrorMessageForAuthorizationDateRange() }}
                        </mat-error>
                    </div>
                </div>
            </div>

        </div> 



    </div>
    <div class="mat-dialog-actions">
        <div class="filter-buttons" fxLayout="row" fxLayoutAlign="end center">
            <button mat-button class="text-button" id="reset" (click)="onReset()"> Cancel </button>
            <button mat-button class="contained-button" id="save" [disabled] = "disableFilter || disableFilterwithAuthorizationDate" (click)="onSubmit()"> OK</button>
        </div>
    </div>
</div> 