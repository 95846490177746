import { Component, OnInit, ViewEncapsulation, EventEmitter, Output } from '@angular/core';
import { UserContextService } from '../../../core/services/user-context.service';
import { AppConfigService } from '../../../core/services/app-config.service';
import { Router } from '@angular/router';
import { Contexts } from '../../../core/models/contexts.model';
import {A11yModule, LiveAnnouncer} from '@angular/cdk/a11y';
import { UserTypeService } from '../../administration/services/user-type.service';
import { MatSnackBar,
      MatSnackBarHorizontalPosition,
      MatSnackBarVerticalPosition } from '@angular/material';
import { ToastIdleTimeoutComponent } from '../../toast-idle-timeout/toast-idle-timeout.component';

/**user context component to display user context details */
@Component({
  selector: 'app-user-context',
  templateUrl: './user-context.component.html',
  styleUrls: ['./user-context.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserContextComponent implements OnInit {

  /** response variable for unit testing */
  userContext: Contexts;
  /** isEnableContext flag to check whether the user context is enabled */
  isEnableContext: boolean;
  /** isViewer flag used to check whether the logged in user is user or account manager */
  isViewer: boolean;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  /** user context constructor method
   * @param userContextService UserContextService injection
   */
  constructor(
    private readonly userContextService: UserContextService,
    private readonly appConfig: AppConfigService,
    private readonly router: Router,
    private liveAnnouncer: LiveAnnouncer,
    private userTypeService: UserTypeService,
    private toastr: MatSnackBar,
  ) {
    this.isEnableContext = false;
   }

  /** Base init method */
  ngOnInit() {
    const contextCount = sessionStorage.getItem('parties_count');
    if (contextCount && parseInt(contextCount, 10) > 1) {
      this.isEnableContext = true;
    }
    this.userContextService.getUserContext().toPromise().then((context) => {
      this.userContext = context;
    });
    this.isViewer = JSON.parse(sessionStorage.getItem('car-ses-vie'));
    if (this.isViewer) {
      this.isEnableContext = true;
    }

    // Extra Check for Benefit Builder Admin
    this.checkContextForBB();

  }

  /**
   * Extra check for Benefit Builder
   * If sessionStorage has car-user-type === 'client-account-manager' | 'program-admin (Account Mgr/Product Mgr)
   * The user is not a viewer.
   */
  checkContextForBB() {
    const isACM_PM = this.userTypeService.userType;
    if (isACM_PM && (isACM_PM === 'client-account-manager' || isACM_PM === 'program-admin' || isACM_PM === 'consultant' ||
    isACM_PM === 'file-setup-specialist' || isACM_PM === 'application-reliability-analyst' || isACM_PM === 'application-owner') &&
      this.router.url === '/administration' ) {
      this.isViewer = false;
      this.isEnableContext = false;
    }
  }

  /** logout of the application.  Logout URL handled by configuration */
  logout() {
    sessionStorage.clear();
    // if (this.userContextService.windowRef && this.userContextService.windowRef.length > 0) {
    //   this.userContextService.windowRef.forEach(ref => {
    //     ref.location.href = 'https://onlinedev.cartus.com/resp/Public#/logout';
    //   });
    // }
    // const logoutURL = this.appConfig.getConfig('login') + '/#/logout?appId=1';
    // this.router.navigate(['/externalRedirect', { externalUrl: logoutURL }], {
    //   skipLocationChange: true,
    // });
    this.router.navigate(['/home']);
  }

  settings(){
    this.router.navigate(['/bbadmin/administration/settings/' + this.userContext.partyId ,{  }], {
      skipLocationChange: true,
    });
  }
 
  changeContext() {
    sessionStorage.removeItem('car-ses-subcon');
    sessionStorage.removeItem('car-ses-con');
    this.windowReload();
  }
  windowReload() {
    window.location.reload();
  }

  announceContext(): void {
    this.liveAnnouncer.announce(
    this.userContext.firstName + ' ' +
    this.userContext.clientLegalName + ' ' +
    this.userContext.clientNo);
  }

  openPopup() {
    this.toastr.openFromComponent(ToastIdleTimeoutComponent, {
        // duration:120000,
        verticalPosition:this.verticalPosition,
        horizontalPosition: this.horizontalPosition,		  
    });
}
}
