import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { TransactionData } from '../../../../core/models/transaction-data.model';
import { RecentTransaction } from '../../../../core/models/recent-transaction.model';
import { Subscription } from 'rxjs';
import { TransactionService } from '../../../../core/services/transaction.service';
import { LinkedListData } from '../../../../core/models/linked-list-data.model';
import { Router } from '@angular/router';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { ModalData } from '../../../../core/services/popup-position.service';
import { NumbersService } from '../../../../core/services/numbers.service';
// import { NgxSpinnerService } from 'ngx-spinner';
import {A11yModule, LiveAnnouncer} from '@angular/cdk/a11y';

/** EmployeeRecentTransactionsComponent to set the Recent Transactions of Employee */
@Component({
  selector: 'app-employee-recent-transactions',
  templateUrl: './employee-recent-transactions.component.html',
  styleUrls: ['./employee-recent-transactions.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeRecentTransactionsComponent implements OnInit, OnDestroy {
  /** recentTransactions  variable to store the response data */
  recentTransactionsSub: Subscription;

  /** holds the data for the Linked list component */
  keyDatesData: LinkedListData[] = [];

  /** taxAssistance variable to hold the tax Assistance*/
  taxAssistance: any;

  /** totalExpense variable to hold the total Expense*/
  totalExpense: any;

  interfaceTitle = 'Recent Transactions';

  /** Base constructor method
   * @param transactionService TransactionService injection
   * @param dateSvc Date converstion service  injection
   * @Inject ModalData to get the data from parent component
   * @param data to get the data through ModalData
   * @param router Router injection
   */
  constructor(
    private readonly transactionService: TransactionService,
    private readonly dateSvc: DateConversionService,
    private readonly numberService: NumbersService,
    private readonly liveAnnouncer: LiveAnnouncer,
    @Inject(ModalData) public data: any,
    private readonly router: Router
  ) {
    liveAnnouncer.announce(this.interfaceTitle);
  }

  /** viewAll function to view All cost transaction */
  viewAll(): void {
    sessionStorage.removeItem('sort_options_transaction');
    sessionStorage.removeItem('page_event_transaction');
    this.router.navigate(['/bbadmin/administration/emp-cost-transactions/' + this.data.fileId]);
    this.close();
  }

  /** To close the dialog */
  close() {
    this.data.overlayRef.dispose();
  }

  /** get the recent transaction data and setup the key dates data */
  ngOnInit() {
      this.createKeyDatesData(this.data.transactions);
  }

  /** setup the key dates data for the linked list component */
  createKeyDatesData(data: RecentTransaction): void {
    // tslint:disable-next-line: prefer-const
    if(data.totalTaxAssistance != 0 && data.totalTaxAssistance){
      this.taxAssistance = this.numberService.formatForCurrency(data.totalTaxAssistance, data.transactionCurrCode);
      this.totalExpense = this.numberService.formatForCurrency(data.totalExpense, data.transactionCurrCode);;
    }
    let sortedList: TransactionData[] = data.results;
    sortedList.sort((a, b) =>
      this.dateSvc.convertToEpoch(a.invoiceDate) -
      this.dateSvc.convertToEpoch(b.invoiceDate)
    );
    sortedList.reverse();
    sortedList.slice(0, 5).forEach(element => {
      element.billAmount = this.numberService.formatForCurrency(element.billAmount, element.billCurrency);
      this.keyDatesData.push({
        value: this.dateSvc.convertToDisplayDate(element.invoiceDate),
        valueClass: 'active',
        columns: [
          {
            label: element.accountCategory,
            subLabel: element.accountSubCategory
          },
          {
            label: element.billAmount
          }
        ]
      } as LinkedListData);
    });
  }

  /** cleanup the subscription and anything else necessary */
  ngOnDestroy(): void {
    if (this.recentTransactionsSub) {
      this.recentTransactionsSub.unsubscribe();
    }
  }
}
