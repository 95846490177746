import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AdministrationRoutingModule } from './administration-routing.module';
import { AdministrationComponent } from './administration.component';
import { AddEditTransfereeComponent } from './components/add-edit-transferee/add-edit-transferee.component';
import { WarningDialogComponent } from './components/add-edit-transferee/add-edit-transferee.component';
import { CharacterOnlyDirective } from './../../core/directives/character-only.directive';
import { CharNumSpecialDirective } from './../../core/directives/char-num-special.directive';
import { SharedModule } from './../../core/shared.module';
import { MatRadioModule } from '@angular/material/radio';
import { ViewProgamDetailsComponent } from './components/view-program-details/view-program-details-component';

import {
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatIconModule,
  MatDialogModule,
  MatSelectModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatDatepickerModule,
  MatCardModule,
  MatTableModule,
  MatTooltipModule,
  MatSortModule,
  MatPaginatorModule,
  MatNativeDateModule,
  MatCheckboxModule,
  MatMenuModule,
  MatDividerModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatListModule,
  MatTabsModule,
  MatSlideToggleModule,} from '@angular/material';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TransfereeListComponent } from './components/transferee-list/transferee-list.component';
// import { MatMomentDateModule, MomentDateModule } from '@angular/material-moment-adapter';
import { ListFilterModalComponent } from './components/transferee-list-filter/list-filter-modal/list-filter-modal.component';
import { AdminFilterSelectionComponent } from './components/transferee-list-filter/filter-selection/filter-selection.component';
import { ClientListComponent } from './components/client-list/client-list.component';
import { ClientDetailsComponent } from './components/client-details/client-details.component';
import { ClientContactsListComponent } from './components/client-contacts-list/client-contacts-list.component';
import { ProgramListComponent } from './components/program-list/program-list.component';
import { ListComponent } from './components/list/list.component';
import { ProgramModalComponent, ProgramCloseWarningComponent } from './components/program-modal/program-modal.component';
import { SharedTransfereeProgramModule } from './modules/shared/shared.module';
import { ProgramModule } from './modules/program/program.module';
import { TransfereeModule } from './modules/transferee/transferee.module';
import { AdminDataviewSelectionModalComponent } from './components/admin-dataview-selection-modal/admin-dataview-selection-modal.component';
import { DivisionAssociationComponent } from './components/division-association/division-association.component';
import { CopyProgramModalComponent } from './components/copy-program-modal/copy-program-modal.component';
import { DeactivateProgramModalComponent } from './components/deactivate-program-modal/deactivate-program-modal.component';
//import { SearchHighlightPipe } from './pipes/search-highlight.pipe';
import { AdminFilterChipComponent } from './components/admin-filter-chip/admin-filter-chip.component';
import { FilterProgramModalComponent } from './components/filter-program-modal/filter-program-modal.component';
import { ConsultantOnlyModalComponent } from './components/consultant-only-modal/consultant-only-modal.component';
import { ShowOverrideHistoryComponent } from './components/show-override-history/show-override-history.component';
import { NameInitialsPipe } from './pipes/name-initials.pipe';
import { ArrayIterationOrderPipe } from './pipes/array-iteration-order.pipe';
import { UnconfirmBenefitsModalComponent } from './modules/transferee/components/unconfirm-benefits-modal/unconfirm-benefits-modal.component';
import { AgmCoreModule } from '@agm/core';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import {environment} from '../../../environments/environment';
import { PointsLogicModalComponent } from './modules/program/components/points-logic-modal/points-logic-modal.component';
import { TransfereeOfferingsListComponent } from './components/transferee-offerings-list/transferee-offerings-list.component';
import { SharedTradionalOrderModule } from '../../shared/shared-tradional-order.module';

import { SearchDuplicateCheckComponent } from './components/search-duplicate-check/search-duplicate-check.component';
import { DupCheckPopupComponent } from './components/dup-check-popup/dup-check-popup.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@NgModule({
  schemas: [ NO_ERRORS_SCHEMA ],
  declarations: [
    AdministrationComponent,
    AddEditTransfereeComponent,
    WarningDialogComponent,
    CharacterOnlyDirective,
    CharNumSpecialDirective,
    TransfereeListComponent,
    ListFilterModalComponent,
    AdminFilterSelectionComponent,
    ClientDetailsComponent,
    ClientContactsListComponent,
    ClientListComponent,
    ClientDetailsComponent,
    ProgramListComponent,
    ListComponent,
    ProgramModalComponent,
    ProgramCloseWarningComponent,
    AdminDataviewSelectionModalComponent,
    DivisionAssociationComponent,
    CopyProgramModalComponent,
    AdminFilterChipComponent,
    FilterProgramModalComponent,
    DeactivateProgramModalComponent,
   // SearchHighlightPipe,
    ConsultantOnlyModalComponent,
    PointsLogicModalComponent,
    ShowOverrideHistoryComponent,
    NameInitialsPipe,
    ArrayIterationOrderPipe,
    SearchDuplicateCheckComponent,
    DupCheckPopupComponent,
    ViewProgamDetailsComponent,
    TransfereeOfferingsListComponent
  ],
  imports: [
    CommonModule,
    AdministrationRoutingModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    // MomentDateModule,
    MatTabsModule,
    SharedModule,
    SharedTransfereeProgramModule ,
    ProgramModule,
    TransfereeModule,
    MatRadioModule,
    SharedTradionalOrderModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAPS_API_KEY,
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule
  ],
  providers: [
    DatePipe,
    MatNativeDateModule,
    // MatMomentDateModule
  ],
  entryComponents: [
    AddEditTransfereeComponent,
    WarningDialogComponent,
    ListFilterModalComponent,
    ProgramModalComponent,
    ProgramCloseWarningComponent,
    DivisionAssociationComponent,
    AdminDataviewSelectionModalComponent,
    FilterProgramModalComponent,
    CopyProgramModalComponent,
    DeactivateProgramModalComponent,
    ConsultantOnlyModalComponent,
    PointsLogicModalComponent,
    ShowOverrideHistoryComponent,
    UnconfirmBenefitsModalComponent,
    SearchDuplicateCheckComponent,
    DupCheckPopupComponent,
    ViewProgamDetailsComponent
  ]
})
export class AdministrationModule { }
