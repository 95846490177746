import { getDatefromCurrentDate } from "./programListData";

export const transfereeBenefitsConfirmedData = {
  "consultantinfo": {},
  "benifits": [
    {
      "displayName": "Pet Transportation",
      "category": "Shipping",
      "confirmedDate": getDatefromCurrentDate(2, false),
      "reference": "OFF-PetTrnsprtBB-80b8bfc8-14cd-4654-9025-b844bd51d2e1",
      "productId": "601ce8a29914910007ed96b1",
      "points": 3
    },
    {
      "displayName": "Household Goods - Professional Move",
      "category": "Shipping",
      "confirmedDate": getDatefromCurrentDate(3, false),
      "reference": "OFF-HHGFlxBB-INS-b0add1c1-2c7e-4b5e-8a91-37eec0e973ba",
      "productId": "601ce8a2f8e0490008f43fe9",
      "points": 3,
      "hvgDetail": {
        "allHighValueTaskOptOut": true,
        "date": "2021-02-09"
      }
    },
    {
      "displayName": "Automobile Shipment - up to 3 automobiles",
      "category": "Shipping",
      "confirmedDate": getDatefromCurrentDate(4, false),
      "reference": "OFF-VhclShpmtBndl3BB-a0dd0db3-8206-48fd-8496-857f7e2e13f0",
      "productId": "602b71b812c71900080b2c8a",
      "points": 12
    },
    {
      "displayName": "Temporary Living Accommodations - up to 120 additional days",
      "category": "After You Arrive",
      "confirmedDate": getDatefromCurrentDate(4, false),
      "reference": "OFF-TmpHsng120BB-94808c2c-383b-4d4d-930b-0df5b789bf7a",
      "productId": "602b71b8b82d530008ba8c65",
      "points": "4 Point(s): 15 \n Days"
    },
    {
      "displayName": "Cost of Living Allowance ",
      "category": "Relocation Allowances",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-CostOfLvngBB-2ed8f5bf-3809-4bfb-b856-f9712d7bffa6",
      "productId": "5f8fffb043c02f00082f6b91",
      "points": "Guaranteed"
    },
    {
      "displayName": "Relocation Allowance",
      "category": "Relocation Allowances",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-MiscAllwnc15BB-080947e6-d1b6-4def-80ad-8054ba6f2662",
      "productId": "5f8fffb07b1ae800076f098c",
      "points": "Guaranteed"
    },
    {
      "displayName": "Cartus Counseling and Administration",
      "category": "Before You Move",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-PlcyCnslBB-a37a58a1-4f1a-470e-83e0-148856b187da",
      "productId": "5f8fffb095a68800070a5b08",
      "points": "Guaranteed"
    },
    {
      "displayName": "Temporary Living Accommodations - Pre Move",
      "category": "After You Arrive",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-CorpHsngBB-1f09ed7f-0215-487c-b0a8-18497102a2b4",
      "productId": "5f8fffb0f8f4e10007a416e0",
      "points": "Guaranteed"
    },
    {
      "displayName": "Temporary Living Accommodations - 30 Days",
      "category": "After You Arrive",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-TmpHsngBB-536eac16-9649-4b80-a086-d1078b502dfd",
      "productId": "5f8fffb10030760007f1dbe9",
      "points": "Guaranteed"
    },
    {
      "displayName": "Household Goods - Storage 1st 30 Days",
      "category": "shipping",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-StrgInTrnstBB-913bc9c4-daec-4921-8454-f9cfef79e71e",
      "productId": "5f8fffb1073b6800074dfd4f",
      "points": "Guaranteed"
    },
    {
      "displayName": "Household Goods - Discard & Donate",
      "category": "Shipping",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-DsrdDntCrBB-5bcd40e9-ed32-40d7-be07-0956ca73d14f",
      "productId": "5f8fffb144080500072598f8",
      "points": "Guaranteed"
    },
    {
      "displayName": "Home Sale Reimbursement",
      "category": "Before You Move",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-HmSlReimbBB-2e2db4ea-8f19-41a0-9c3b-0c3749715ab3",
      "productId": "5f8fffb145f0610007a86938",
      "points": "Guaranteed"
    },
    {
      "displayName": "Final Transportation to New Location",
      "category": "Travel and Transport",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-FnlTrnsprtBB-0eee7ccd-acad-4110-b178-085314636533",
      "productId": "5f8fffb15ac9aa0008d1c1ab",
      "points": "Guaranteed"
    },
    {
      "displayName": "Rental Assistance and Finder's Fee",
      "category": "After You Arrive",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-BrkrFndrFeeBB-09e8fa3f-2140-4b58-8a35-fe7860fd7e49",
      "productId": "5f8fffb1748cf70007049f97",
      "points": "Guaranteed"
    },
    {
      "displayName": "Cultural Transition - Core Support",
      "category": "Ongoing",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-CltrlTrnsSuppCoreBB-fbe368c6-8e74-4764-9c01-c64280ee0c1d",
      "productId": "608264997950be0009fe4d43",
      "points": "Guaranteed" 
    },
    {
      "displayName": "Expense Management",
      "category": "Before You Move",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-ReloAcctngIntlBB-224f264b-478b-4e13-89a9-c862e7e0ac4e",
      "productId": "5f8fffb1802db80008df9879",
      "points": "Guaranteed"
    },
    {
      "displayName": "Home Sale Buyer Value & Marketing Assistance",
      "category": "Before You Move",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-HomeSaleBVMABB-e993292d-3686-4199-98c6-15b680d305a9",
      "productId": "5f8fffb1e372bc00073d9d44",
      "points": "Guaranteed"
    },
    {
      "displayName": "Household Goods - Professional Move",
      "category": "Shipping",
      "confirmedDate": getDatefromCurrentDate(6, false),
      "reference": "OFF-HHGBB-0bc762f9-6598-44fa-a29f-cb10f03fa736",
      "productId": "5f8fffb1e69cb3000819aeba",
      "points": "Guaranteed"
    }
  ],
  "confirmedPoints": 22,
  "candidateDetails": {
    "candidateId": "5f893f2eb16c070008c7ce08",
    "orderRefNum": "MOV-jdpk6rag",
    "phoneDetailsList": [
      {
        "_id": "5f893f2a6b0aec000898e9cc",
        "textingAvailable": true,
        "primary": true,
        "usageType": "personal",
        "phoneDialCode": 91,
        "phoneNumber": "8908462287"
      }
    ],
    "emailDetailsList": [
      {
        "_id": "5f893f2a6b0aec000898e9cd",
        "primary": true,
        "usageType": "business",
        "emailAddress": "souyam.kasta@mobilitydba.com"
      }
    ],
    "createdByNameDetails": {
      "firstName": "Initiator",
      "lastName": "One"
    },
    "nameDetails": {
      "title": "Ms.",
      "firstName": "TestTransferee",
      "lastName": "Address"
    },
    "ccinitiatorNameDetails": {
      "firstName": "Initiator",
      "lastName": "One"
    },
    "additionalDetails": {
      "costCenter": "",
      "jobStartDate": "",
      "employeeID": "",
      "poBillingInvoice": ""
    },
    "invitationSentDate": "2020-10-16T06:35:42.832Z",
    "programDetails": {
      "programName": "Without Policy Call 1",
      "points": 30,
      "policyCallRequired": false
    },
    "totalNumberOfRelocatingMembers": 2,
    "departureAddr": {
      "streetLine1": "6780, Mayfield Road",
      "city": "Mayfield Heights",
      "state": "Ohio",
      "country": "United States",
      "postalCode": "44124"
    },
    "destinationAddr": {
      "streetLine1": "6735, Trippel Road, Cedar Grove",
      "city": "Theodore",
      "state": "Alabama",
      "country": "United States",
      "postalCode": "36582"
    },
    "policyCallDetails": {},
    "divisionName": "People & Culture",
    "divisionNumber": "032"
  }
}