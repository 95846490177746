import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Benefit } from '../../transferee-detail/transferee-detail.component';
@Component({
  selector: 'app-unconfirm-benefits-modal',
  templateUrl: './unconfirm-benefits-modal.component.html',
  styleUrls: ['./unconfirm-benefits-modal.component.scss']
})
export class UnconfirmBenefitsModalComponent implements OnInit {
  /**
   * @param dialogRef DialogRef
   * @param data Data to popup
   */
  constructor(
    public dialogRef: MatDialogRef < UnconfirmBenefitsModalComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: Benefit,
  ) {}

  ngOnInit() {}
  /**
   * To dismiss/cancel the copy program popup
   */
  onDismiss() {
    this.dialogRef.close();
  }
  /**
   * Confirm removal to parent component
   */
  confirmRemoval() {
    this.dialogRef.close({
      action: 'delete'
    });
  }


}
