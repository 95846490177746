<div fxLayout.lt-md="column-reverse" fxLayout.md="column-reverse" fxLayout.gt-md="row" class="transactionMatCard"
  [ngClass]="taxAssistance?'nostyle':'display-inlines'">
  <h2 fxFlex.gt-md="30" fxFlex.sm="100" [ngClass]="taxAssistance?'nostyle':'transactionLeft'"
    class="transation-heading">All Transactions <button mat-icon-button class="selection-icon" aria-label="Select data view table list" matTooltip="selection results" (click)="financialDataViewSelectionModal()">
      <mat-icon>menu</mat-icon>
    </button></h2>
  <div fxLayout.lt-md="column" fxLayout.gt-md="row" fxFlex.gt-md="50" fxFlex.sm="100">
    <mat-card class="mat-card-chip all-transaction-card" *ngIf="taxAssistance" fxFlex.sm="100" fxFlex.gt-md="50">Total
      Expenses<span class="amount"> {{totalExpense}}</span></mat-card>
    <mat-card class="mat-card-chip all-transaction-card" *ngIf="taxAssistance" fxFlex.sm="100" fxFlex.gt-md="50">Tax
      Assistance<span class="amount"> {{taxAssistance}}</span></mat-card>
  </div>
  <mat-card class="mat-card-chip totalCost all-transaction-card" [ngClass]="taxAssistance?'nostyle':'totalCostRight'"
    fxFlex.sm="100" fxFlex.gt-md="50">Total Costs<span class="amount"> {{totalTransactionAmt}}</span></mat-card>
</div>



<div class="mat-elevation-z8 sticky-col" >
  <div class="employeeTransactionsView table-scroll" id="employeeTransactionsView">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" cdkDropListGroup matSort
      [matSortDisableClear]="true" class="transactionTable" [matSortActive]="initialSortColumn" [matSortDirection]="initialSortDirection">
      
      <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.field"
        [stickyEnd]="column.sortName === 'billAmount' ? true : false">
        <div *ngIf="column.sortName === 'billAmount' ; else draggableColumns">
          <th mat-header-cell *matHeaderCellDef [@.disabled]="initialSortColumn === column.sortName"
            [ngClass]="{'initialColumnSelected': initialSortColumn === column.sortName }"
            (click)="applySort(column.sortName)"
            [start]="initialSortColumn === column.field ? initialSortDirection : null" mat-sort-header>
            {{ column.header }}
          </th>
        </div>

        <ng-template #draggableColumns>
          <th mat-header-cell *matHeaderCellDef mat-sort-header [@.disabled]="initialSortColumn === column.sortName"
            [ngClass]="{'initialColumnSelected': initialSortColumn === column.sortName }" cdkDropList
            cdkDropListLockAxis="x" cdkDragBoundary=".transactionTable" cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="dragDrop($event, i)" (cdkDragStarted)="dragStarted($event, i)"
            (click)="applySort(column.sortName)" cdkDrag class="selectedColumn"
            [start]=" initialSortColumn === column.field ? initialSortDirection : null">
            {{ column.header }}
            <div class="dragPreview" *cdkDragPreview [innerHTML]="column.header"> {{ column.header }}</div>
          </th>
        </ng-template>

        <ng-template>
          <th mat-header-cell *matHeaderCellDef mat-sort-header [@.disabled]="initialSortColumn === column.sortName"
            [ngClass]="{'initialColumnSelected': initialSortColumn === column.sortName }" cdkDropList
            cdkDropListLockAxis="x" cdkDragBoundary=".transactionTable" cdkDropListOrientation="horizontal"
            (cdkDropListDropped)="dragDrop($event, i)" (cdkDragStarted)="dragStarted($event, i)"
            (click)="applySort(column.sortName)" cdkDrag class="selectedColumn"
            [start]=" initialSortColumn === column.field ? initialSortDirection : null">
            {{ column.header }}
            <div fxLayout="row" fxFlex="0 1 150px" fxLayoutAlign="center" class="dragPreview" *cdkDragPreview
              [innerHTML]="column.header"> {{ column.header }}</div>
          </th>
        </ng-template>

        
        <td mat-cell *matCellDef="let element; let row = index"
          [ngClass]="{'colDrag': dragColumnName === column.sortName}">

          <!-- Bill Date Column -->
          <div *ngIf="column.field === 'invoiceDate'">
            {{ element.invoiceDate === null ? '' : dateSvc.convertToDisplayDate(element.invoiceDate) }}
          </div>

          <!-- Account Category Column -->
          <div *ngIf="column.field === 'accountCategory'">
            {{element.accountCategory}}
          </div>

          <!-- Sub Category  Column -->
          <div *ngIf="column.field === 'accountSubCategory'">
            {{element.accountSubCategory}}
          </div>

          <!-- Transaction Description Column -->
          <div *ngIf="column.field === 'transactionDesc'">
            {{element.transactionDesc}}
          </div>

          <!-- Invoice Amount Column -->
          <div *ngIf="column.field === 'invoiceNo'">
            {{element.invoiceNo}}
          </div>

          <!-- Bill Amount Column -->

          <div *ngIf="column.field === 'billAmount'" sticky>
            {{element.billAmount}}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div fxLayout="row" class="table-footer">
    <div fxFlex="0 0 auto" fxLayoutAlign="start center">
      <app-download-menu-button [reportName]="transactionsReport" [reportColName] = "displayedColumns"></app-download-menu-button>
    </div>
    <div fxFlex="64%"></div>
    <div fxFlex>
      <mat-paginator [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"></mat-paginator>
    </div>
  </div>
</div>
