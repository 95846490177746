<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
        fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle">Update Location Details</div>
        <a href="#" class="close-icon" (click)="onDismiss($event)" [ngClass]="{'disable':formDisable==true}"
            title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <form fxLayout="column" class="middle-section" [formGroup]="addCandidateForm">
        <div class="middle-section">
            <mat-dialog-content>
                <div>
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="addcandidate-toppadding"
                        formGroupName="addressForm" class="location-details" fxLayout.xs="column" fxLayoutGap="1em"
                        fxLayoutGap.xs="0px">
                        <mat-card fxFlex="45%" fxLayoutAlign="start" fxLayout="column" fxFlex.xs="40%"
                            formGroupName="departureAddr" class="address">
                            <mat-card-title class="custom card-title" fxLayout="row" fxLayoutAlign="start center">
                                <img src="../../assets/images/arrival.svg" alt="arrival" class="arrival-icon" />
                                Departure Location
                            </mat-card-title>
                            <mat-card-content>
                                <div fxLayout="column" class="m-t-10">
                                    <mat-form-field>
                                        <mat-label>Country</mat-label>
                                        <input type="text" aria-label="Country" matInput #autoCompleteCountryInput
                                            formControlName="country" [matAutocomplete]="autoCountry"
                                            (blur)="onFocusOutDepartureCountryCode()">
                                        <mat-autocomplete #autoCountry="matAutocomplete" [panelWidth]="293">
                                            <mat-option [value]="option.countryName"
                                                *ngFor="let option of filteredCountryOptions | async">
                                                {{ option.countryName }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error>
                                            {{ getErrorMessage('addressForm.departureAddr.country', 'Country') }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="column" class="m-t-10" formArrayName="streets">
                                    <div *ngFor="let street of streets.controls; index as idx">
                                        <mat-form-field>
                                            <mat-label>Street Address</mat-label>
                                            <input matInput [formControlName]="idx" *ngIf="hasDepartureStreet"
                                                placeholder="Street Address (Optional)" />
                                            <input matInput placeholder="Street Address (Optional)"
                                                *ngIf="!hasDepartureStreet" matGoogleMapsAutocomplete
                                                [country]="departCountryCode" type="address"
                                                (onAutocompleteSelected)="onAutocompleteSelected($event)"
                                                [formControlName]="idx">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="column" class="m-t-10" formArrayName="cities">
                                    <div *ngFor="let city of cities.controls; index as cityIdx">
                                        <mat-form-field *ngIf="hasDepartureStreet">
                                            <mat-label>City</mat-label>
                                            <input matInput [formControlName]="cityIdx" placeholder="City" />

                                            <mat-error>
                                                {{ getErrorMessage('addressForm.departureAddr.cities', 'City',0) }}
                                            </mat-error>
                                        </mat-form-field>
                                        <mat-form-field *ngIf="!hasDepartureStreet">
                                            <mat-label>City</mat-label>
                                            <input matInput matGoogleMapsAutocomplete [country]="departCountryCode"
                                                type="cities"
                                                (onAutocompleteSelected)="onDepartureCityAutocompleteSelected($event)"
                                                [formControlName]="cityIdx" placeholder="City">
                                            <mat-error>
                                                {{ getErrorMessage('addressForm.departureAddr.cities', 'City',0) }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" class="m-t-10">
                                    <div formArrayName="states" fxFlex="50%">
                                        <div *ngFor="let state of states.controls; index as stateIdx">
                                            <mat-form-field class="state-fields">
                                                <mat-label>State/Province</mat-label>
                                                <input matInput [formControlName]="stateIdx"
                                                    *ngIf="hasDepartureStreet || hasDepartureCity"
                                                    placeholder="State" />
                                                <input matInput [formControlName]="stateIdx"
                                                    *ngIf="!hasDepartureStreet && !hasDepartureCity"
                                                    matGoogleMapsAutocomplete [country]="departCountryCode"
                                                    type="region"
                                                    (onAutocompleteSelected)="onDepartureStateAutocompleteSelected($event)"
                                                    (onLocationSelected)="onLocationSelected($event)"
                                                    placeholder="State" />
                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.departureAddr.states', 'State',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div formArrayName="postalCodes" fxFlex="45%">
                                        <div *ngFor="let postal of postalCodes.controls; index as postalIdx">
                                            <mat-form-field class="postal-code-fields">
                                                <mat-label>Postal Code</mat-label>
                                                <input matInput placeholder="Postal Code"
                                                    *ngIf="hasDepartureStreet ||hasDepartureCity || hasDepartureState"
                                                    [formControlName]="postalIdx" minLength="1" maxlength="15" />

                                                <input matInput placeholder="Postal Code"
                                                    *ngIf="!hasDepartureStreet && !hasDepartureCity && !hasDepartureState"
                                                    matGoogleMapsAutocomplete [country]="departCountryCode"
                                                    type="region"
                                                    (onAutocompleteSelected)="onDeparturePostalCodeAutocompleteSelected($event)"
                                                    (onLocationSelected)="onLocationSelected($event)"
                                                    [formControlName]="postalIdx" minLength="1" maxlength="15">
                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.departureAddr.postalCodes', 'Postal
                                                    Code',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <div fxFlex="5%" fxShow="true" fxHide.xs fxLayoutAlign="center center">
                            <mat-icon class="material-icons modal-rightnavarrow">chevron_right</mat-icon>
                        </div>
                        <mat-card class="address" fxFlex="45%" fxFlex.xs="40%" fxLayout="column"
                            formGroupName="destinationAddr">
                            <mat-card-title class="card-title p-t-40 p-l-0" fxLayout="row" fxLayoutAlign="start center">
                                <img src="../../assets/images/departure.svg" alt="departure" class="departure-icon" />
                                Destination Location
                            </mat-card-title>
                            <mat-card-content>
                                <div fxLayout="column" class="m-t-10">
                                    <mat-form-field>
                                        <mat-label>Country</mat-label>
                                        <input type="text" aria-label="Country" matInput #autoCompleteDestCountryInput
                                            formControlName="country" (blur)="onFocusOutDestinationCountryCode()"
                                            [matAutocomplete]="autoDestCountry">
                                        <mat-autocomplete #autoDestCountry="matAutocomplete" [panelWidth]="293">
                                            <mat-option [value]="option.countryName"
                                                *ngFor="let option of filteredDestCountryOptions | async">
                                                {{ option.countryName }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <mat-error>
                                            {{ getErrorMessage('addressForm.destinationAddr.country', 'Country') }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="column" class="m-t-10" formArrayName="destiStreets">
                                    <div *ngFor="let street of destiStreets.controls; index as destiStreetIdx">
                                        <mat-form-field>
                                            <mat-label>Street Address</mat-label>
                                            <input matInput [formControlName]="destiStreetIdx"
                                                *ngIf="hasDestinationStreet" placeholder="Street Address (Optional)" />
                                            <input matInput placeholder="Street Address (Optional)"
                                                *ngIf="!hasDestinationStreet" matGoogleMapsAutocomplete
                                                [country]="destiCountryCode" type="address"
                                                (onAutocompleteSelected)="onDestinationStreetAutocompleteSelected($event)"
                                                [formControlName]="destiStreetIdx">
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="column" class="m-t-10" formArrayName="destiCities">
                                    <div *ngFor="let city of destiCities.controls; index as destiCityIdx">
                                        <mat-form-field>
                                            <mat-label>City</mat-label>
                                            <input matInput [formControlName]="destiCityIdx"
                                                *ngIf="hasDestinationStreet" placeholder="City" />
                                            <input matInput [formControlName]="destiCityIdx"
                                                *ngIf="!hasDestinationStreet" matGoogleMapsAutocomplete
                                                [country]="destiCountryCode" type="cities"
                                                (onAutocompleteSelected)="onDestinationCityAutocompleteSelected($event)"
                                                placeholder="City" />
                                            <mat-error>
                                                {{ getErrorMessage('addressForm.destinationAddr.destiCities', 'City',0)
                                                }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div fxLayout="row" class="m-t-10">
                                    <div formArrayName="destiStates" fxFlex="50%">
                                        <div *ngFor="let state of destiStates.controls; index as destiStateIdx">
                                            <mat-form-field class="state-fields">
                                                <mat-label>State/Province</mat-label>
                                                <input matInput [formControlName]="destiStateIdx"
                                                    *ngIf="hasDestinationStreet || hasDestinationCity"
                                                    placeholder="State" />
                                                <input matInput [formControlName]="destiStateIdx"
                                                    *ngIf="!hasDestinationStreet && !hasDestinationCity"
                                                    matGoogleMapsAutocomplete [country]="destiCountryCode" type="region"
                                                    (onAutocompleteSelected)="onDestinationStateAutocompleteSelected($event)"
                                                    placeholder="State" />
                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.destinationAddr.destiStates',
                                                    'State',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div formArrayName="destiPostalCodes" fxFlex="45%">
                                        <div
                                            *ngFor="let postalCode of destiPostalCodes.controls; index as destiPostalCodeIdx">
                                            <mat-form-field class="postal-code-fields">
                                                <mat-label>Postal Code</mat-label>
                                                <input matInput placeholder="Postal Code"
                                                    *ngIf="hasDestinationStreet || hasDestinationCity || hasDestinationState"
                                                    [formControlName]="destiPostalCodeIdx" minLength="1"
                                                    maxlength="15" />
                                                <input matInput placeholder="Postal Code"
                                                    *ngIf="!hasDestinationStreet && !hasDestinationCity && !hasDestinationState"
                                                    matGoogleMapsAutocomplete [country]="destiCountryCode" type="region"
                                                    (onAutocompleteSelected)="onDestinationPostalCodeAutocompleteSelected($event)"
                                                    [formControlName]="destiPostalCodeIdx" minLength="1"
                                                    maxlength="15" />
                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.destinationAddr.destiPostalCodes',
                                                    'Postal Code',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </mat-dialog-content>
        </div>
        <mat-dialog-actions class="statusBtn add-dialogbtn" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px"
            fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
            <button fxFlex="25%" type="button" class="mat-button text-button" (click)="onDismiss($event)"
                color="primary" id="cancel">CANCEL</button>
            <button mat-button style="width: 160px;" class="contained-button width-button"
                disabled (click)="saveData('saveChanges')">Update</button>
        </mat-dialog-actions>
    </form>
</div>
