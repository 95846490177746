import { Component, OnInit, OnDestroy, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DashboardViewService } from '../../../../core/services/dashboard-view.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AggregationList } from '../../../../core/models/aggregation-list.model';
import { UserConfig } from '../../../../core/models/user-config.model';

/** Dashboard View modal to set the Aggreate view */
@Component({
  selector: 'app-dashboard-view-modal',
  templateUrl: './dashboard-view-modal.component.html',
  styleUrls: ['./dashboard-view-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DashboardViewModalComponent implements OnInit, OnDestroy {
  /** aggregationList variable to store the aggregation view list */
  aggregationList: AggregationList[];

  /** aggregationDashboardBy variable to store the selected aggregation view value */
  aggregateDashboardBy: string;

  /** dashboardViewForm variable to store the fromGroup */
  dashboardViewForm: FormGroup;

  /** aggregationSelect variable to access the formControl */
  aggregationSelect: string;

  /** aggregation api subscription */
  private aggregationSub: Subscription;

  /** for machine reading of mat-select state */
  isExpanded: Boolean = false;

  /** for machine reading to retreive the selectemItem value */
  selectedItem: string;

  /** Base constructor method
   * @param dashboardViewService DashboardViewService injection
   * @param dialogRef MatDialogRef<DashboardViewModalComponent> injection
   * @Inject MAT_DIALOG_DATA to get the data from the parent component
   * @param data to get the data through MAT_DIALOG_DATA
   */
  constructor(private readonly dashboardViewService: DashboardViewService,
    public dialogRef: MatDialogRef<DashboardViewModalComponent>, @Inject(MAT_DIALOG_DATA) public data: UserConfig) { }

  /** OnSubmit function to submit the modal popup */
  onSubmit(): void {
    this.data.aggregateDashboardBy = this.formData.value;
    this.dialogRef.close(this.data.aggregateDashboardBy);
  }

  /** get Form data from the Modal */
  get formData() {
    return this.dashboardViewForm.get('aggregationSelect');
  }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() {
    this.dashboardViewForm = new FormGroup({
      aggregationSelect: new FormControl(this.data.aggregateDashboardBy)
    });

   /** Aggregation Subscription */
   this.aggregationSub = this.dashboardViewService.getAggregationList().subscribe(res  => {
      this.aggregationList = res;
      this.selectedItem = this.findAggregate(this.data.aggregateDashboardBy);
    });
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.aggregationSub) {
      this.aggregationSub.unsubscribe();
    }
  }

  /**
   * @desc Processes event emitted when the select panel has been toggled.
   * @params e
   */
  onExpand(e: boolean): void {
    this.isExpanded = e;
  }

  /**
   * @desc Proccess event emitted when the selected value has been changed by the user.
   * @params e
   */
  onSelect(e: any): void {
    this.selectedItem = this.findAggregate(e.value);
  }

  /**
   * @desc findAggregate function returns corresponding viewValue
   * @params aggregateValue
   */
  findAggregate(aggregateValue: string): string {
    for (const item of this.aggregationList) {
      if (item.value === aggregateValue) {
        return item.viewValue;
      }
    }
    return null;
  }

}
