import { Component, OnInit, Input, Inject} from '@angular/core';
import { NeedsAssessmentSharedService } from '../../../../../../src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { TransfereeNeedsAssessment } from '../../../../../../src/app/core/models/candidateneeds-assessment.model';
import { Subscription } from 'rxjs/Rx';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatRadioChange } from '@angular/material';
import { FamilyMember } from '../../../../../../src/app/core/models/family-member.model';
import { Router } from '@angular/router';
@Component({
    selector: 'app-complete-family-view',
    templateUrl: './complete-family-view.component.html',
    styleUrls: ['./complete-family-view.component.scss']
})
export class CompleteFamilyViewComponent implements OnInit {
    /** Current logged in user name */
    username: string;
    /** Total number of family members relocating */
    familyMembersRelocating: number;
    /** To store updated family information */
    familyInformation: Array < FamilyMember > ;

    /**
     * @param needsAssessmentShared needs assessment shared service
     * @param dialogRef inject dialogRef
     * @param data inject data from family card
     * @param router inject router
     */
    constructor(private readonly needsAssessmentShared: NeedsAssessmentSharedService,
        public dialogRef: MatDialogRef < CompleteFamilyViewComponent > ,
        @Inject(MAT_DIALOG_DATA, ) public data,
        private router: Router,
    ) {}

    ngOnInit() {
        this.loadFamilyData(this.data);
    }

    /**
     * To retrieve family related information to be displayed
     * @param familyData family data received from family card
     */
    loadFamilyData(familyData) {
        this.familyInformation = familyData.family;
        this.familyMembersRelocating = familyData.count;
        this.username = familyData.username;
    }

    /**
     * Redirect user to update family information page
     * @param evt event
     */
    updateFamilyMembers(evt) {
        this.closeDialog(evt);
        this.router.navigate(['/profile-info', '1']);
    }

    /**
     * To close the dialog
     * @param evt event
     */
    closeDialog(evt) {
        evt.preventDefault();
        this.dialogRef.close();
    }
}
