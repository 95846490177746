import { RemoteLoggingService } from './remote-logging.service';
import { BaseClientService } from './base-client.service';
import { Injectable } from '@angular/core';
import { Level } from '../models/level';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, BehaviorSubject, Subject } from 'rxjs';
import { levelData, emptyLevelData } from '../models/constants';
import { LoggerService } from './logger.service';
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';

@Injectable({
  providedIn: 'root'
})
export class LevelService {
  /**mock level data */
  levelList = levelData;
  /**empty level data */
  emptyLevel = emptyLevelData;

  level$ = new BehaviorSubject<Level[]>(this.levelList);
  levelList$: Observable<any> = this.level$.asObservable();
  /**base constructor
   * @param baseClientService injects base client service
   * @param logService injects remote logging service
   */
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logService: RemoteLoggingService,
    private readonly customLogger: LoggerService
  ) { }

  /** Return the candidate json list and loop to display in the table */
  getLevels(): Level[] {
    return this.levelList;
  }
  /**
   *  Return the level Id
   * @param levelName level name for which level Id is to be retrieved
   */
  getLevelId(levelID): Observable<Level> {
    for (const level of this.levelList) {
      // look for the entry with a matching `code` value
      if (level.levelId === levelID) {
        return of(level);
      }
    }
    return of(this.emptyLevel[0]);
  }

  /**
   * Used to get level details
   */
  getLevelDetails(): Observable<any> {
    // return this.baseClientService.getArr<any>(`/candidate/level`).pipe(
    //   map(r => r.body),
    //   catchError(err => {
    //     this.customLogger.error('Failed to get candidate budget details', err);
    //     const empty: Level = null;
    //     return of(empty);
    //   })
    // );
    return of (this.levelList);
  }
}
