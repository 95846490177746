<header class="header-desktop">
    <a href="/" class="html-logo"><img src="../../../../assets/images/MovePro360-White.svg"></a>
</header>
<main>
    <div class="main-content">
        <h1>
            Page Not Found
        </h1>
        <p>
            The page you are looking for is not found.
        </p>

        <p>Below are few useful links </p>
        <ul>
            <li><a href="#/dashboard">My Dashboard</a></li>
        </ul>
    </div>
</main>

<footer><div class="footer-content"><div class="footer-links"><a href="#/terms-of-use" target="_blank" class="">Terms of Use</a><span class="footer-link-divider">|</span><a href="#/privacy-statement" target="_blank" class="">Privacy Statement</a></div><div class="copyrights">© 2000-2020 Cartus Corporation</div></div></footer>