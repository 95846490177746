<div class ="transferee-detail" *ngIf = "!detailView">
    <h2 *ngIf="!isCostProjection">
        {{ paginator?.length }} Authorized Transferees 
    </h2>
    <h2 *ngIf="isCostProjection">
        {{ paginatorCp?.length }} Cost Projections 
    </h2>
   <!--  <div class="add-btn" *ngIf="(userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist')">
        <button class="user-water" tabindex="0" aria-label="Add Transferee" (click)="openDialog()" (keyup.enter)="openDialog()">
    </button>
    </div> -->
    <div class="add-btn" *ngIf="(userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist')">
        <button class="user-water" tabindex="0" aria-label="Add Transferee" (click)="openDialoge()" (keyup.enter)="openDialoge()">
      <mat-icon>add</mat-icon>
    </button>
    </div>
<!-- <ngx-spinner type="ball-spin-clockwise-fade" size="medium" bdColor="rgb(148,148,148, 0.6)"></ngx-spinner> -->
<div [style.display]="(clearResults) ? 'none' : 'block' " class="emp-details">
    <div class="table-scroll">
        <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
            <!-- Selection column -->
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef width="1%">
                    <mat-checkbox aria-label="Select All/ Deselect All" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox  aria-label="Select/Deselect" (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <!-- fullname Column -->
            <ng-container matColumnDef="fullname" >
                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Full Name" (click)="applySort('fullName')"> Full Name </th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.nameDetails">
                        <!-- routerLink="/empInfo/{{element.fileNumber}}" -->
                        <a (click)="editDialog(element)" tabindex="0">
                            <span [innerHTML]="element.nameDetails.lastName | searchHighlight: searchKeyword"></span>, 
                            <span [innerHTML]="element.nameDetails.firstName | searchHighlight: searchKeyword"></span>                            
                        </a>

                    </div>
                </td>
            </ng-container>

            <!-- Program Column -->
            <ng-container matColumnDef="program">
                <th width="15%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Program" (click)="applySort('programName')"> Program </th>
                <td mat-cell *matCellDef="let element" >
                    <p tabindex="0" aria-label="test" *ngIf="(userRole !== 'client-contact-initiator') && element.programDetails && element.programDetails.programName" [innerHTML]="element.programDetails.programName | searchHighlight: searchKeyword"></p>
                    <a (click)="viewProgramDetails(element)" *ngIf="(userRole === 'client-contact-initiator')" tabindex="0">
                        <p tabindex="0" aria-label="test" *ngIf="element.programDetails && element.programDetails.programName" [innerHTML]="element.programDetails.programName | searchHighlight: searchKeyword"></p>
                    </a>
                </td>
            </ng-container>

            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <th width="20%" mat-header-cell *matHeaderCellDef >
                    <p *ngIf="!isCostProjection" style="margin-bottom: 0px;"> Authorization Type </p>
                    <p *ngIf="isCostProjection"  style="margin-bottom: 0px;"> Cost Projection Type </p>
                 </th>
                <td mat-cell *matCellDef="let element" >
                    <p tabindex="0" aria-label="test" *ngIf="element.authorizationType" 
                        [innerHTML]="element.authorizationType === 'BenefitsBuilder'? 'Benefits Builder' : element.authorizationType | searchHighlight: searchKeyword"></p>
                </td>
            </ng-container>

            <!-- Points assigned Column -->
            <ng-container matColumnDef="pointsAssigned">
                <th width="4%" mat-header-cell *matHeaderCellDef> Points Assigned </th>
                <td mat-cell *matCellDef="let element" >
                    <div *ngIf="element.programDetails">
                        <p tabindex="0" *ngIf="element.programDetails.points" fxLayout="row" fxLayoutAlign="unset center">{{element.programDetails.points}}
                            <mat-icon style="cursor: pointer;" *ngIf="element.programDetails.pointOverride" class="error-icon" aria-label="points overridden" (click)="showOverrideHistory(element)">error</mat-icon>
                        </p>
                        <!-- <p class="points-remaining">remaining:45</p> -->
                    </div>
                </td>
            </ng-container>

            <!-- Departure Column -->
            <ng-container matColumnDef="departure">
                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Departure" (click)="applySort('departure')">
                    Departure </th>
                <td mat-cell *matCellDef="let element">
                    <div tabindex="0" *ngIf="element.departureAddr">
                        <span [innerHTML]="element.departureAddr.country | searchHighlight: searchKeyword"></span><br/>                        
                        <span [innerHTML]="element.departureAddr.state?(element.departureAddr.state | searchHighlight: searchKeyword ):''"></span><br/>
                        <span [innerHTML]="element.departureAddr.city? (element.departureAddr.city | searchHighlight: searchKeyword ): ''"></span>
                        
                    </div>
                </td>
            </ng-container>

            <!-- Destination Column -->
            <ng-container matColumnDef="destination">
                <th width="20%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Destination" (click)="applySort('destination')">Destination</th>
                <td mat-cell *matCellDef="let element">
                    <div tabindex="0" *ngIf="element.destinationAddr">
                        <span [innerHTML]="element.destinationAddr.country | searchHighlight: searchKeyword"></span><br />
                        <span [innerHTML]="element.destinationAddr.state? (element.destinationAddr.state | searchHighlight: searchKeyword) : ''"></span> <br />
                        <span [innerHTML]="element.destinationAddr.city? (element.destinationAddr.city | searchHighlight: searchKeyword) : ''"> </span>
                        
                    </div>
                </td>
            </ng-container>

            <!-- Status Column -->
            <ng-container matColumnDef="status">
                <th  width="20%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Status" (click)="applySort('status')">Status Info</th>
                <td tabindex="0" mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.moveStatus === 'Withdrawn' ? ('Deactivated' | searchHighlight: searchKeyword ): element.moveStatus | searchHighlight: searchKeyword"></span>
                    <div  *ngIf="element.moveStatusDate" style="font-size: 12px;">on {{element.moveStatusDate}}</div>
                </td>
            </ng-container>

            <!-- Authorization Date Column -->
            <ng-container matColumnDef="authorizationDate">
                <th  width="20%" mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Authorization Date" (click)="applySort('authorizationDate')">Authorization Date</th>
                <td tabindex="0" mat-cell *matCellDef="let element">
                    {{ element.hasOwnProperty('authorizationDate')  ?  (element.authorizationDate | date:"yyyy-MM-dd") :'N/A' }}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
    <div fxLayout="row" class="table-footer" fxLayoutAlign="end center" fxLayoutAlign.xs="start start">
        <div fxFlex="66%" fxLayoutAlign="start center">
            <!-- <app-download-menu-button tabindex="0" [reportName]="employeeReport"></app-download-menu-button> -->

            <button class="deactivate-btn" *ngIf="selection.selected.length > 0" (click)="deactivateTransferees.emit(selection.selected)">Deactivate Transferee</button>

        </div>
        <!-- <div fxFlex="64%"></div> -->
        <div fxFlex="34%" fxFlex.xs="100%">
            <!-- (page)="onPageChange($event)" -->
            <mat-paginator #MatPaginator="matPaginator" *ngIf="!isCostProjection" [pageSizeOptions]="[10, 25, 50, 75]"></mat-paginator>
            <mat-paginator #MatPaginatorCp="matPaginator" *ngIf="isCostProjection" [pageSizeOptions]="[10, 25, 50, 75]"></mat-paginator>
        </div>
    </div>
</div>
</div>
<div *ngIf="detailView">
    <app-transferee-detail 
    (close)="closeTransferee()" 
    [transferee]="transferee" 
    [clientDetails]="clientDetails">
    </app-transferee-detail>
</div>