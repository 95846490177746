import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, ActivatedRouteSnapshot } from '@angular/router';
import { ExternRouteComponent } from './public/components/extern-route/extern-route.component';
// TODO: import { AuthGuardService } from './core/services/security/auth-guard.service';
import { PageNotFoundComponent } from './public/components/page-not-found/page-not-found.component';
/** external URL provider injector */
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
import { AppComponent } from './app.component';


const routes: Routes = [
  {
    path: 'bbadmin', component: AppComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./public/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { navId: 1, title: 'Home', showGlobalTemplate: true },
        // canActivate: [AuthGuardService]
      },
      {
        path: 'provider',
        loadChildren: './public/provider/provider.module#ProviderModule'
      },
      {
        path: 'terms-privacy',
        loadChildren: './public/terms-privacy/terms-privacy.module#TermsPrivacyModule'
      },
      {
        path: 'administration',
        // loadChildren: () => import('./public/administration/administration.module').then(m => m.AdministrationModule),
        loadChildren: './public/administration/administration.module#AdministrationModule',
        data: { navId: 1, title: 'Administration', showGlobalTemplate: true },
      },
      {
        path: 'externalRedirect',
        resolve: {
          url: externalUrlProvider
        },
        component: ExternRouteComponent
      },
      {
          path: '**',
          component: PageNotFoundComponent,
          data: { navId: 101, title: 'File Not Found', showGlobalTemplate: false }
      }
    ]
  },
  // {
  //   path: 'externalRedirect',
  //   resolve: {
  //     url: externalUrlProvider
  //   },
  //   component: ExternRouteComponent
  // },

  // TODO: Page not found
  // {
  //   path: '**',
  //   loadChildren: './public/page-not-found/page-not-found.module#PageNotFoundModule',
  //   canActivate: [AuthGuardService]
  // }
];

@NgModule({
  providers: [
    {
      provide: externalUrlProvider,
      useValue: (route: ActivatedRouteSnapshot) => {
        const externalURL = route.paramMap.get('externalUrl');
        window.location.replace(externalURL);
      }
    }
  ],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
