<!-- <div class="cdk-visually-hidden" aria-live="polite" aria-atomic="true">{{sortStatement}}</div>
<ng-template (valueChange)="isDisabled($event)">
</ng-template> -->
<div class="cost-model">
  <div class="middle-section">
    <section>
      <app-header-status aria-live="assertive" aria-atomic="true" (costModelEvent)="updateDataSource($event)"
        [disable]="disableButton"></app-header-status>
    </section>
    <section class="search_table">
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <span fxFlex="60%" fxLayoutAlign="start">
          <mat-form-field appearance="outline" class="search-within-table search-box" style="width: 80% !important;">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput id="searchInput" (input)="applyFilter($event.target.value)"
              placeholder="Search within table for..." width="80%" fxFlex.sm="65" aria-label="Search within table">
          </mat-form-field>
        </span>
      </div>
    </section>
    <p></p>
    <section class="content_table cost-model-tbl">
      <div>
        <section class="mat-elevation-z8">
          <div *ngIf="ELEMENT_DATA.length>0 && dataSource.filteredData.length>0" style="overflow:auto;">
            <table mat-table [dataSource]="dataSource" summary="Cost models" aria-describedby="table-cost-models"
              matSort [matSortActive]="sortBy" matSortDirection="desc" (matSortChange)="sortData($event)">

              <!-- Select Checkbox Column -->
              <ng-container matColumnDef="select" sticky>
                <th mat-header-cell id="select" *matHeaderCellDef>
                  <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()">
                  </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                  <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
                  </mat-checkbox>
                </td>
              </ng-container>

              <!-- Model Name Column -->
              <ng-container matColumnDef="costModelName" sticky>
                <th mat-header-cell id="modelName" *matHeaderCellDef mat-sort-header> Cost Model Name </th>
                <td mat-cell *matCellDef="let element" class="cLinks" (click)="open($event, element)">
                  <a href="#" class="client-table-links" (click)="open($event, element)">
                    <span class="text-as-link cost-model-col-name"
                      [innerHTML]="element.costModelName | highlightSearch: filterText"></span><br />
                  </a>
                  <a href="#" class="client-table-links" (click)="open($event, element)">
                    <span class="text-as-link cost-model-last-update">updated:
                      {{element.updatedDate | date:'yyyy-MM-dd'}}</span>
                  </a>
                </td>
              </ng-container>

              <!-- Client Column -->
              <ng-container matColumnDef="client">
                <th mat-header-cell id="levelName" *matHeaderCellDef mat-sort-header> Client </th>
                <td mat-cell *matCellDef="let element"><span
                    [innerHTML]="element.clientName? (element.clientName | highlightSearch: filterText):''">{{element.clientName}}</span><br>
                  <span class="small no-wrap"
                    [innerHTML]="element.clientContactNameFormat? (element.clientContactNameFormat | highlightSearch: filterText):''">{{element.clientContactNameFormat}}</span>
                </td>
              </ng-container>

              <!-- Level Column -->
              <ng-container matColumnDef="level">
                <th mat-header-cell id="levelName" *matHeaderCellDef mat-sort-header> Level </th>
                <td mat-cell *matCellDef="let element">
                  <span [innerHTML]="element.levelName | highlightSearch: filterText"></span><br />
                  <span class="level-description no-wrap" [innerHTML]="element.levelDes| highlightSearch: filterText"></span>
              </ng-container>

              <!-- Departure Column -->
              <ng-container matColumnDef="deptState">
                <th mat-header-cell id="departure" *matHeaderCellDef mat-sort-header="departure"> Departure </th>
                <td mat-cell *matCellDef="let element" [innerHTML]="((element.deptCity != undefined) && (element.deptState != undefined))?
                (element.deptFullAdd | highlightSearch: filterText):''">
                </td>
              </ng-container>

              <!-- Destination Column -->
              <ng-container matColumnDef="destState">
                <th mat-header-cell id="destination" *matHeaderCellDef mat-sort-header="destination"> Destination </th>
                <td mat-cell *matCellDef="let element" [innerHTML]="((element.destCity != undefined) && (element.destState != undefined))?
                (element.destFullAdd| highlightSearch: filterText):''">
                </td>
              </ng-container>

              <!-- Status Column -->
              <ng-container matColumnDef="status">
                <th mat-header-cell id="status" *matHeaderCellDef> Status </th>
                <td mat-cell *matCellDef="let element">
                  <div class="notification-important">

                    <div *ngIf="element.status === 'Expired'" (click)="open($event, element)">
                      <span [innerHTML]="element.status | highlightSearch: filterText"></span><br />
                      <span class="cost-model-last-update">{{element.expiryDate | date:'yyyy-MM-dd'}}</span>
                    </div>

                    <span *ngIf="element.status !== 'Expired'"
                      [innerHTML]="element.status | highlightSearch: filterText">
                    </span>
                  </div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"
                [ngClass]="{highlighted: selection.isSelected(row)}"></tr>
            </table>
          </div>
          <div [hidden]="!(ELEMENT_DATA.length>0 && dataSource.filteredData.length>0)">
            <mat-paginator [length]="pageInfo.totalCount" #paginator [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 20]"
              ></mat-paginator>
          </div>
        </section>
      </div>
    </section>
    <div [hidden]="!dataSource || (ELEMENT_DATA?.length>0 && dataSource.filteredData?.length>0)" class="invalidData"
      role="alert">
      No records found
    </div>
  </div>
  <div *ngIf="selection.selected.length>0" class="candidate-highlight-whitebg">
    <div class="statusBtn">
      <button mat-button class="mat-button contained-button" id="withdrawBtn" (click)="deleteCostModels()"
        [disabled]="disableButton">Delete
        {{selection.selected.length}} <span *ngIf="selection.selected.length===1"
          class=" hide-xs cost-model-btn-txt">Cost Model</span><span *ngIf="selection.selected.length>1"
          class=" hide-xs cost-model-btn-txt">Cost Models</span></button>
      <button mat-button *ngIf="selection.selected.length>1" class="mat-button contained-button" id="withdrawBtn"
        (click)="navigateToCompareCostModels()">Compare
        {{selection.selected.length}} <span class="hide-xs cost-model-btn-txt">Cost Models</span></button>
    </div>
  </div>
</div>