<div mat-raised-button class="selected-benefit-card-list" fxLayout="column" fxLayoutAlign="space-evenly none"
    role="group" *ngIf="benefit" (click)="displayBenefitDetails(benefit, $event);" tabIndex="0" (keyup)="keyUpOnBenefitItem(benefit, $event)"
    [attr.aria-label]="(benefit.disable? 'Disabled':'') +'Benfit title -' + benefit.displayName + ', Allocated' + benefit.points + ' Points'">
    <div class="selected-card" fxLayout="row" fxLayoutAlign="space-between center"
        [style.border-bottom-color]="benefitService.assignBenefitColor(benefit)">
        <div fxFlex.lt-sm="50%" fxFlex="15" class="selected-card-left" role="img"><img
                [src]="benefit.icon ? '../../../../../assets/icons/bb_icons/' + benefit.icon : '../../../../../assets/images/enabled_generic_benefit_icon.svg'" title="Benefit" alt="benefit"></div>
        <div fxFlex.lt-sm="100%" fxFlex="70" class="selected-card-center" role="heading">
            <div class="setting-in" role="heading">
                <p class="settling-in">{{ benefit.displayName }}</p>
                
                    <p *ngIf="benefit.points === 'Guaranteed' && !benefit.tierConfigs && !benefit.pointsPerIncrement && !benefit.perPointCurrency" class="points">0 Points</p>
                    <p *ngIf="benefit.points !== 'Guaranteed' && !benefit.tierConfigs && !benefit.pointsPerIncrement && !benefit.perPointCurrency" class="points">{{ benefit.points }} Points</p>        
                    <p *ngIf="benefit.tierConfigs" class="points">{{ benefit.advCashOutSelectedPoints }} Point(s): {{benefit.advCashOutPointValue | currency: ' ' : 'symbol':'1.0-0'}} USD </p>						
                    <p *ngIf="benefit.pointsPerIncrement && !benefit.hybridTempLiving" class="points">{{ benefit.pointsPerIncrement }} Point(s): {{benefit.rangeIncrementValue}} {{benefit.rangeIncrementUnit}}</p>  
                    <p *ngIf="benefit.perPointCurrency" class="points">{{ benefit.rangeIncrementValue }} Point(s): {{benefit.perPointCurrency}} {{benefit.rangeIncrementUnit}}</p>  
                    <p *ngIf="benefit.hybridTempLiving && benefit.pointsPerIncrement && !benefit.coreUnits" class="points">{{benefit.pointsPerIncrement * benefit.selectedIncrementCount}} Point(s): {{benefit.rangeIncrementValue * benefit.selectedIncrementCount}} {{benefit.rangeIncrementUnit}}</p>
                    <p *ngIf="benefit.hybridTempLiving && benefit.coreUnits" class="points">{{benefit.coreUnits}} {{benefit.coreTimeUnit}} - 0 Points</p>
                </div>
            <p class="card-para" [class.show]="show">{{ benefit.description}}</p>
        </div>
        <div fxFlex.lt-sm="50%" fxLayout="row" fxLayoutAlign="center center" fxFlex="15" class="selected-card-right"
            role="none">
            <img *ngIf="benefit.points==='Guaranteed'" class="core-benefits-img" alt="core-benefits" title="Core Benefit"
                src="../../../../../assets/images/Group_3141.svg">
            <p *ngIf="benefit.points==='Guaranteed'" class="core-benefits">Core<br /> Benefits</p>
            <button mat-button role="button" (click)="deleteBenefit(benefit, $event);" [attr.aria-label]="'Remove Benefit' + benefit.displayName"
                class="mat-delete-icon" *ngIf="benefit.points !='Guaranteed'">
                <mat-icon aria-hidden="false" [attr.aria-label]="'Remove Benefit' + benefit.displayName" title="Remove Benefit" class="grey-color">delete
                </mat-icon>
            </button>
        </div>
    </div>
    <!-- <span class="seemore" (click)="show = ! show">{{ show ? 'Less': 'More' }}</span> -->
</div>