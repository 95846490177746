<div fxLayout fxLayout.xs="column-reverse" fxLayoutAlign="space-between center" fxLayoutAlign.xs="center center"
    [ngClass]="showNeedsAssessmentBG?'footer-wrapper linear-background':'footer-wrapper grey-background'" >
    <div  fxFlex="40%" class="footerWrapper">
        <div class="footer-text"><a
            target="_blank"     href="https://submit-irm.trustarc.com/services/validation/0080a1bc-5f59-48fc-993e-83efc1029056">Do Not Sell
                My Personal Information</a></div>
        <div class="footerlink">
            <ul>
                <li><a  target="_blank" href=" https://go.cartus.com/#/termsOfUse">Terms of Use</a></li>
                <li><a target="_blank" href="https://go.cartus.com/#/privacyNotice">Privacy Policy</a></li>
            </ul>
        </div>
    </div>
    <div class="copyRights">© 2000-2021 Cartus Corporation</div>
</div>

