<!-- Notification div -->
<!-- <div fxLayout="column" class="notification-container">
<div class = "notification-div" fxLayout="row" role="note" *ngIf='message'>
	<img src="../../../../assets/images/bell-icon.svg" alt="bell with the message" focusable="false" />
	<p>{{message}} <span tabindex="0" class="notification-click" *ngIf="isTransferee" (click)="navigateUserToTask()">Click Here</span></p>
</div>
<div *ngIf = "messagePendingHVB?.length > 0 && isTransferee">
<div class = "notification-div" fxLayout="row" role="note" *ngFor = "let messageHVB of messagePendingHVB">
	<img src="../../../../assets/images/bell-icon.svg" alt="bell with the message" focusable="false" />
	<p>{{messageHVB.message}} <span tabindex="0" class="notification-click" (click)="navigateUserToBenefit()">Click Here</span></p>
</div>
</div>
</div> -->

<div style=" position: absolute !important;" fxLayout="column" *ngIf="notificationList?.length > 0"
	class="notification-container">
	<div class="notification-div" fxLayout="row" role="note">
		<img src="../../../../assets/images/bell-icon.svg" alt="bell with the message" focusable="false" />
		<p>{{'bb.notification.part1' | translate: {'count': notificationList?.length || '0' } }} <span tabindex="0"
				class="notification-click" *ngIf="isTransferee" (click)="openNotifications()">{{ 'bb.notification.part2' |
				translate }}</span> {{ 'bb.notification.part3' | translate }}</p>
	</div>
</div>