export const employeeData = {
   "data": [
      {
         "favUser": 1,
         "firstName": "Robert",
         "lastName": "Angel",
         "fileNumber": 4109145,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "59335",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "033",
         "divisionName": "Research and Development",
         "policyName": "Global Test Policy",
         "departureCity": "Belleville",
         "departureState": "New Jersey",
         "departureCountry": "United States",
         "destinationCity": "Malibu",
         "destinationState": "California",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-11-01",
         "totalCost": 2200,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 5,
         "sentimentName": "Relocation Satisfaction",
         "moveRisk": 4,
         "milestoneDate": "",
         "milestoneDesc": "",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-11-15"
      },
      {
         "favUser": 0,
         "firstName": "Elon",
         "lastName": "Mask",
         "fileNumber": 3189467,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "110530",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "034",
         "divisionName": "International Division",
         "policyName": "Lump Sum Program",
         "departureCity": "El Paso",
         "departureState": "Texas",
         "departureCountry": "United States",
         "destinationCity": "Malibu",
         "destinationState": "California",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-09-28",
         "totalCost": 395,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "Policy Counseling Satisfaction",
         "moveRisk": 4,
         "milestoneDate": "2020-09-30",
         "milestoneDesc": "Repay Agreement",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-10-12"
      },
      {
         "favUser": 0,
         "firstName": "Arthur",
         "lastName": "Tyler",
         "fileNumber": 3194588,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "034",
         "divisionName": "International Division",
         "policyName": "Lump Sum Program",
         "departureCity": "Tulalip Bay",
         "departureState": "Washington",
         "departureCountry": "United States",
         "destinationCity": "Malibu",
         "destinationState": "California",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-11-10",
         "totalCost": 3000,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "Relocation Satisfaction",
         "moveRisk": 4,
         "milestoneDate": "2020-11-10",
         "milestoneDesc": "Transfer Date",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-11-24"
      },
      {
         "favUser": 0,
         "firstName": "Willie",
         "lastName": "Aiden",
         "fileNumber": 3193177,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "58252",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "036",
         "divisionName": "US Domestic Division",
         "policyName": "Lump Sum Program",
         "departureCity": "Tulalip Bay",
         "departureState": "Washington",
         "departureCountry": "United States",
         "destinationCity": "Malibu",
         "destinationState": "California",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-10-08",
         "totalCost": 395,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "",
         "moveRisk": 4,
         "milestoneDate": "2020-10-08",
         "milestoneDesc": "Authorized",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-10-22"
      },
      {
         "favUser": 0,
         "firstName": "Mark",
         "lastName": "Ethan",
         "fileNumber": 3193392,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "109326",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "035",
         "divisionName": "Government Division",
         "policyName": "Lump Sum – India Domestic",
         "departureCity": "Torrance",
         "departureState": "California",
         "departureCountry": "United States",
         "destinationCity": "El Paso",
         "destinationState": "Texas",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-11-01",
         "totalCost": 395,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "Relocation Satisfaction",
         "moveRisk": 4,
         "milestoneDate": "2020-11-01",
         "milestoneDesc": "Transfer Date",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-11-15"
      },
      {
         "favUser": 0,
         "firstName": "Mark",
         "lastName": "Nicholas",
         "fileNumber": 3053832,
         "fileStatusDesc": "Service Delivery Complete",
         "clientEmployeeId": "53009",
         "movePhase": {
            "id": 3,
            "altText": "Arriving in New Location"
         },
         "divisionNumber": "035",
         "divisionName": "Government Division",
         "policyName": "Lump Sum Program",
         "departureCity": "Hilton Head Island",
         "departureState": "South Carolina",
         "departureCountry": "United States",
         "destinationCity": "New Haven",
         "destinationState": "Connecticut",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2021-07-01",
         "totalCost": 4610,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "",
         "moveRisk": 3,
         "milestoneDate": "2019-05-10",
         "milestoneDesc": "Repay Agreement",
         "estimatedMoveDate": "2020-06-12",
         "estimatedMoveDateEarly": "2020-05-08",
         "estimatedMoveDateLate": "2020-07-17",
         "moveRiskCompletionDt": ""
      },
      {
         "favUser": 0,
         "firstName": "Logan",
         "lastName": "Christopher",
         "fileNumber": 3050317,
         "fileStatusDesc": "Service Delivery Complete",
         "clientEmployeeId": "56493",
         "movePhase": {
            "id": 3,
            "altText": "Arriving in New Location"
         },
         "divisionNumber": "035",
         "divisionName": "Government Division",
         "policyName": "Intl' Perm Transfer Plan – 2017 - Executive",
         "departureCity": "Tulalip Bay",
         "departureState": "Washington",
         "departureCountry": "United States",
         "destinationCity": "Platteville",
         "destinationState": "Colorado",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2021-07-01",
         "totalCost": 65597.1,
         "totalCostCurr": "USD",
         "providerCount": 5,
         "sentimentLevel": 0,
         "sentimentName": "",
         "moveRisk": 3,
         "milestoneDate": "2019-10-12",
         "milestoneDesc": "Shipment Delivered (Surface)",
         "estimatedMoveDate": "2020-07-12",
         "estimatedMoveDateEarly": "2020-06-07",
         "estimatedMoveDateLate": "2020-08-16",
         "moveRiskCompletionDt": ""
      },
      {
         "favUser": 0,
         "firstName": "Kevin",
         "lastName": "William",
         "fileNumber": 3185511,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "018386",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "032",
         "divisionName": "People & Culture",
         "policyName": "Assignment Plan - Executive – 2017",
         "departureCity": "Platteville",
         "departureState": "Colorado",
         "departureCountry": "United States",
         "destinationCity": "New Haven",
         "destinationState": "Connecticut",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-10-01",
         "totalCost": 6035.82,
         "totalCostCurr": "USD",
         "providerCount": 1,
         "sentimentLevel": 0,
         "sentimentName": "Relocation Satisfaction",
         "moveRisk": 4,
         "milestoneDate": "2020-10-01",
         "milestoneDesc": "Transfer Date",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-10-15"
      },
      {
         "favUser": 0,
         "firstName": "Dennis",
         "lastName": "John",
         "fileNumber": 3186165,
         "fileStatusDesc": "Service Delivery Complete",
         "clientEmployeeId": "53009",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "034",
         "divisionName": "International Division",
         "policyName": "Lump Sum Program",
         "departureCity": "Torrance",
         "departureState": "California",
         "departureCountry": "United States",
         "destinationCity": "Hilton Head Island",
         "destinationState": "South Carolina",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-10-01",
         "totalCost": 3950,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "Relocation Satisfaction",
         "moveRisk": 4,
         "milestoneDate": "2020-10-01",
         "milestoneDesc": "Transfer Date",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-10-15"
      },
      {
         "favUser": 0,
         "firstName": "Christopher",
         "lastName": "Joshua",
         "fileNumber": 3189682,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "33138",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "036",
         "divisionName": "US Domestic Division",
         "policyName": "Assignment Repat Policy",
         "departureCity": "Torrance",
         "departureState": "California",
         "departureCountry": "United States",
         "destinationCity": "Malibu",
         "destinationState": "California",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-09-30",
         "totalCost": 1395,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "",
         "moveRisk": 4,
         "milestoneDate": "2020-09-30",
         "milestoneDesc": "Transfer Date",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-10-14"
      },
      {
         "favUser": 0,
         "firstName": "Benjamin",
         "lastName": "Michael",
         "fileNumber": 3181263,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "76907",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "032",
         "divisionName": "People & Culture",
         "policyName": "Lump Sum Program",
         "departureCity": "Tulalip Bay",
         "departureState": "Washington",
         "departureCountry": "United States",
         "destinationCity": "Belleville",
         "destinationState": "New Jersey",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2021-02-01",
         "totalCost": 395,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "",
         "moveRisk": 4,
         "milestoneDate": "2020-08-14",
         "milestoneDesc": "Repay Agreement",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2021-02-15"
      },
      {
         "favUser": 0,
         "firstName": "Antony",
         "lastName": "David",
         "fileNumber": 3189124,
         "fileStatusDesc": "Service Delivery Complete",
         "clientEmployeeId": "44023",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "032",
         "divisionName": "People & Culture",
         "policyName": "Lump Sum Program",
         "departureCity": "New Haven",
         "departureState": "Connecticut",
         "departureCountry": "United States",
         "destinationCity": "Platteville",
         "destinationState": "Colorado",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-11-01",
         "totalCost": 395,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "Relocation Satisfaction",
         "moveRisk": 4,
         "milestoneDate": "2020-11-01",
         "milestoneDesc": "Transfer Date",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-11-15"
      },
      {
         "favUser": 0,
         "firstName": "Jackson",
         "lastName": "Jayden",
         "fileNumber": 3185964,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "23477",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "036",
         "divisionName": "US Domestic Division",
         "policyName": "Short Term Assignment Plan - 2017 - Executive",
         "departureCity": "Mayfield Heights",
         "departureState": "Ohio",
         "departureCountry": "United States",
         "destinationCity": "Theodore",
         "destinationState": "Alabama",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-12-01",
         "totalCost": 2295,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "Relocation Satisfaction",
         "moveRisk": 4,
         "milestoneDate": "2020-12-01",
         "milestoneDesc": "Transfer Date",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-12-15"
      },
      {
         "favUser": 0,
         "firstName": "Alicia",
         "lastName": "Aaron",
         "fileNumber": 3194694,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "48222",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "033",
         "divisionName": "Research and Development",
         "policyName": "Assignment Plan – Repat – 2017 & Onwards",
         "departureCity": "Seattle",
         "departureState": "Washington",
         "departureCountry": "United States",
         "destinationCity": "New York",
         "destinationState": "New York",
         "destinationCountry": "United States",
         "effectiveTransferDate": "2020-10-31",
         "totalCost": 0,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "",
         "moveRisk": 4,
         "milestoneDate": "2020-10-31",
         "milestoneDesc": "Transfer Date",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-11-14"
      },
      {
         "favUser": 0,
         "firstName": "Julian",
         "lastName": "URBANSKE",
         "fileNumber": 3181523,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "42331",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "035",
         "divisionName": "Government Division",
         "policyName": "Intl' Perm Transfer Plan – 2017 - Executive",
         "departureCity": "London",
         "departureState": "",
         "departureCountry": "United Kingdom",
         "destinationCity": "Purchase",
         "destinationState": "New York",
         "destinationCountry": "United States Of America",
         "effectiveTransferDate": "2020-10-01",
         "totalCost": 12715,
         "totalCostCurr": "USD",
         "providerCount": 2,
         "sentimentLevel": 0,
         "sentimentName": "",
         "moveRisk": 4,
         "milestoneDate": "2020-10-01",
         "milestoneDesc": "Transfer Date",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-10-15"
      },
      {
         "favUser": 0,
         "firstName": "Shweta",
         "lastName": "Wang",
         "fileNumber": 3039191,
         "fileStatusDesc": "Service Delivery Complete",
         "clientEmployeeId": "56497",
         "movePhase": {
            "id": 3,
            "altText": "Arriving in New Location"
         },
         "divisionNumber": "034",
         "divisionName": "International Division",
         "policyName": "Intl' Perm Transfer Policy – 2017 - Non Executive",
         "departureCity": "Sri Ganganagar",
         "departureState": "Rajashtha",
         "departureCountry": "India",
         "destinationCity": "New York",
         "destinationState": "New York",
         "destinationCountry": "United States Of America",
         "effectiveTransferDate": "2021-07-01",
         "totalCost": 43112.02,
         "totalCostCurr": "USD",
         "providerCount": 3,
         "sentimentLevel": 0,
         "sentimentName": "",
         "moveRisk": 3,
         "milestoneDate": "2019-12-07",
         "milestoneDesc": "Shipment Delivered (Surface)",
         "estimatedMoveDate": "2020-08-06",
         "estimatedMoveDateEarly": "2020-07-02",
         "estimatedMoveDateLate": "2020-09-10",
         "moveRiskCompletionDt": ""
      },
      {
         "favUser": 0,
         "firstName": "Sidney",
         "lastName": "Zhang",
         "fileNumber": 3183842,
         "fileStatusDesc": "Open",
         "clientEmployeeId": "38087",
         "movePhase": {
            "id": null,
            "altText": ""
         },
         "divisionNumber": "032",
         "divisionName": "People & Culture",
         "policyName": "Short Term Assignment Plan - 2017 - Executive",
         "departureCity": "Purchase",
         "departureState": "New York",
         "departureCountry": "United States Of America",
         "destinationCity": "Beer Sheva'",
         "destinationState": "",
         "destinationCountry": "Israel",
         "effectiveTransferDate": "2020-11-01",
         "totalCost": 5215,
         "totalCostCurr": "USD",
         "providerCount": 0,
         "sentimentLevel": 0,
         "sentimentName": "",
         "moveRisk": 4,
         "milestoneDate": "2020-11-01",
         "milestoneDesc": "Transfer Date",
         "estimatedMoveDate": "",
         "estimatedMoveDateEarly": "",
         "estimatedMoveDateLate": "",
         "moveRiskCompletionDt": "2020-11-15"
      }
   ],
   "count": 17
}