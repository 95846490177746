import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger, MatSnackBar } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CandidateProfileService } from '../../../services/candidate-profile.service';
import { Country } from '../../../../../core/models/country.model';
import { LocationsService } from '../../../services/locations.service';
import PlaceResult = google.maps.places.PlaceResult;
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BookingDetailsPdfGenerationService } from '../../../services/booking-details-pdf-generation.service';
import pdfMake from 'pdfmake/build/pdfmake';
import { formatDate } from '@angular/common';

type updatedKeyInterface = { group: 'departureAddr' | 'destinationAddr', type: 'country' | 'city' | 'state' | 'streetLine1' | 'streets' | 'cities' | 'states' };
@Component({
  selector: 'app-booking-details-popup',
  templateUrl: './booking-details-popup.component.html',
  styleUrls: ['./booking-details-popup.component.scss']
})
export class BookingDetailsPopupComponent implements OnInit {
  propertyInfoForm: FormGroup;
  addBookingForm: FormGroup;
  moveDateForm: FormGroup;
  countryList: Array<Country>;
  countryNameList: any[];
  /**Currency retrieved from service */
  currencyList: Array<Country> = [];
  hasDestinationState: boolean;
  hasDestinationCity: boolean;
  hasDestinationStreet: boolean;
  hasDepartureStreet: boolean = false;
  hasDepartureState: boolean = false;
  hasDepartureCity: boolean = false;
  filteredCountryOptions: Observable<Country[]>;
  /**BookingDetail form */
  bookingDetailsFormValue: FormGroup;
  departCountryCode = '';
  minDate = new Date();
  minMoveDate;
  @ViewChild('autoCompleteCountryInput', { read: MatAutocompleteTrigger, static: true }) autoCompleteCountry: MatAutocompleteTrigger;
  constructor(public dialogRef: MatDialogRef<BookingDetailsPopupComponent>,
    private formBuilder: FormBuilder,
    private readonly locationService: LocationsService,
    private readonly spinner: NgxSpinnerService,
    private candidateProfileService: CandidateProfileService,
    private readonly pdfgenerator: BookingDetailsPdfGenerationService,
    private readonly toaster: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }
  ngOnInit() {
    this.propertyInfoForm = this.generatePropertyForm(this.data.bookingDetail);
    this.generateMoveform(this.data.bookingDetail);
    this.locationService.countryList.subscribe(countryList => {
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryList = countryList;
        const currList = [];
        this.filteredCountryOptions = this.propertyInfoForm.get('country').valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
      }
    });
  }

  private _filter(value): Country[] {
    if (value) {
      const filterValue = typeof value === 'number' ? value : value.toLowerCase();
      const countryList = this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
      return countryList;
    } else {
      return this.countryList;
    }
  }

  onAutocompleteSelected(result: PlaceResult) {
    this.onAddressChange();
    this.updateAddresValue(result, 'street');
  }
  onDepartureCityAutocompleteSelected(result: PlaceResult) {
    this.updateAddresValue(result, 'city');
  }
  onDepartureStateAutocompleteSelected(result: PlaceResult) {
    this.updateAddresValue(result, 'state');
  }
  onDeparturePostalCodeAutocompleteSelected(result: PlaceResult) {
    this.updateAddresValue(result, 'postalcode');
  }

  updateAddresValue(result: PlaceResult, fieldTriggered: string) {
    let streetLine = '';
    const addressForm: FormGroup = (this.propertyInfoForm as FormGroup);
    let isCity = false;
    result.address_components.forEach(element => {
      if (element.types[0] === 'postal_code') {
        // this.destiPostalCodes.setValue([element.long_name]);
        this.propertyInfoForm.get('postalCodes').setValue(element.long_name);
      } else if (element.types[0] === 'administrative_area_level_1' &&
        (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'state' || fieldTriggered === 'postalcode')) {
        // this.destiStates.setValue([element.long_name]);
        this.propertyInfoForm.get('states').setValue(element.long_name);
        this.hasDestinationState = true;
      } else if (element.types[0] === 'locality' && (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'postalcode')) {
        // this.destiCities.setValue([element.long_name]);
        this.propertyInfoForm.get('cities').setValue(element.long_name);
        isCity = true;
        this.hasDestinationCity = true;
      } else {
        if (!isCity && fieldTriggered === 'street') {
          streetLine = streetLine.length > 0 ?
            `${streetLine}, ${element.long_name}` : element.long_name;
          this.hasDestinationStreet = true;
          // this.propertyInfoForm.get('streets').setValue(streetLine);
        }
      }
    });
    this.propertyInfoForm.get('streets').setValue(streetLine);
    // this.destiCities.setValue([streetLine]);
  }

  generatePropertyForm(editData) {
    const addForm = this.formBuilder.group({
      providerName: [editData ? editData.providerName : ''],
      propertyName: [editData ? editData.propertyName : ''],
      apartmentType: [editData ? editData.apartmentType : ''],
      rentPaidBy: [editData ? editData.rentPaidBy : ''],
      country: [editData.address ? editData.address.country : ''],
      cities: [editData.address ? editData.address.city : ''],
      streets: [editData.address ? editData.address.streetLine1 : ''],
      states: [editData.address ? editData.address.state : ''],
      postalCodes: [editData.address ? editData.address.postalCode : '']
    });
    return addForm;
  }

  generateMoveform(editData) { formatDate(new Date(editData.moveDates.moveOut),'yyyy-MM-dd', 'en_US') 
    this.moveDateForm = this.formBuilder.group({
      moveInDate: [editData && editData.moveDates && editData.moveDates.moveIn ? this.setDateWithOffset(editData.moveDates.moveIn) : ''],
      moveOutDate: [editData && editData.moveDates && editData.moveDates.moveOut ? this.setDateWithOffset(editData.moveDates.moveOut) : '']
    })
  }

  onAddressChange() {
    this.propertyInfoForm.controls.states.reset();
    this.propertyInfoForm.controls.cities.reset();
    this.propertyInfoForm.controls.postalCodes.reset();
  }

  onCountryChange() {
    this.departCountryCode = '';
    this.propertyInfoForm.controls.streets.reset();
    this.onAddressChange();

  }

  onCancel(): void {
    this.dialogRef.close({
      action: 'dismiss'
    });
  }

  onClose(): void {
    this.dialogRef.close({
      action: 'dismiss'
    });
  }

  onFocusOutDepartureCountryCode() {
    const countryObj = this.countryList.find(country => (this.propertyInfoForm.value.country).toLowerCase().
      includes(country.countryName.toLowerCase()));
    const countryCode = countryObj ? countryObj.countryiso2CharCode : null;
    const index = this.countryList.findIndex(
      (country) => (this.propertyInfoForm.value.country).toLowerCase() ===
        country.countryName.toLowerCase());
    if (index === -1) {
      this.propertyInfoForm.controls.country.setErrors({ 'incorrect': true });
    }
    //this.propertyInfoForm.get('country').setValue(countryObj.countryName);
    this.departCountryCode = countryCode.toLowerCase();
  }
  onSumit(status,id){
    this.spinner.show();
     let bookingData={
       "providerName":  this.propertyInfoForm.get('providerName').value?this.propertyInfoForm.get('providerName').value: null,
       "propertyName" :  this.propertyInfoForm.get('propertyName').value?this.propertyInfoForm.get('propertyName').value: null,
       "apartmentType" : this.propertyInfoForm.get('apartmentType').value?this.propertyInfoForm.get('apartmentType').value: null,
       "rentPaidBy":     this.propertyInfoForm.get('rentPaidBy').value?this.propertyInfoForm.get('rentPaidBy').value: null,
       "moveDates": {
        "moveIn": this.moveDateForm.get('moveInDate').value ? `${moment(this.moveDateForm.get('moveInDate').value).format().split('T')[0]}T00:00:00.000Z` : null, 
        "moveOut": this.moveDateForm.get('moveOutDate').value ? `${moment(this.moveDateForm.get('moveOutDate').value).format().split('T')[0]}T00:00:00.000Z` : null,
      },
       "address":{
        "streetLine1": this.propertyInfoForm.get('streets').value?this.propertyInfoForm.get('streets').value: null,
        "city": this.propertyInfoForm.get('cities').value?this.propertyInfoForm.get('cities').value: null,
        "state": this.propertyInfoForm.get('states').value?this.propertyInfoForm.get('states').value: null,
        "country": this.propertyInfoForm.get('country').value?this.propertyInfoForm.get('country').value: null,
        "postalCode": this.propertyInfoForm.get('postalCodes').value?this.propertyInfoForm.get('postalCodes').value: null,

       }
      
     }
    this.candidateProfileService.updateBookingDetails(bookingData,id).subscribe(data => {
     if(data){
      // this.spinner.hide();
     }
     
    this.dialogRef.close({
      data:{
        status:'status',
        suppOrderStatus:data.supplierOrderStatus,
        bookingDetails: bookingData
        
      },
      action: 'dismiss'
    });
    this.spinner.hide();
    })
    
  }

  moveInDateChange() {
    let day = new Date(this.moveDateForm.get('moveInDate').value);
    this.minMoveDate = new Date(day);
    this.minMoveDate.setDate(day.getDate() + 1);
  }

  setDateWithOffset(date) {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    return newDate;
  }

   /**download toaster */
   openDownloadToastr() {
    // tslint:disable-next-line: max-line-length
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toaster.open(message, 'DOWNLOAD', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    });
    toaster.onAction().subscribe(_ => this.downloadPdf());
  };

  /**pdf download service call */
  downloadPdf() {
    // const currentDate = new Date();
    // const formattedDate = formatDate(currentDate, 'MMMM_dd_yyyy_HH_mm_ss', 'en-US');
    // open, pending, cancelled, in-progress, paused, complete, stopped, closed
    const name = `${this.data.supID}`;
    let pdfName = `TempLiving-${name}_BookingDetails`;

    const downloadData = {
      supplierID: this.data.supID,
      bookingDetails: this.data.bookingDetail,
      clientDetails: this.data.clientDetails,
      transfereeDetails: this.data.transfereeDetails,
      offering: this.data.offering,
      bookingAgent: this.data.bookingAgent
    }
    pdfMake.createPdf(this.pdfgenerator.generateDocumentDefinition(downloadData)).download(`${pdfName}`);
  }
}
