import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppDataService } from 'projects/BenefitBuilder/src/app/core/services/app-data.service';
import { tokenkey } from '../login/login.service';
import { environment } from 'src/environments/environment';
import { newBBData } from 'projects/BenefitBuilder/src/app/core/data/app-data';

export const bbUrl = environment.bbdata;
export const bbAdminUrl = environment.bbadmin;
export const bbdatakey = "bbdata";

@Injectable({
  providedIn: 'root'
})
export class ApplicationdataService {
  token : string;
  constructor(private http: HttpClient, private bbAppDataService: AppDataService  ) { 
    this.token = localStorage.getItem(tokenkey);
  }

  getRequestHeaders() {
  let headers = new HttpHeaders({
    'Authorization': this.token
  });
  return headers;
 }

  makeRequest(url):Observable<any> { 
    const options = {
      headers: this.getRequestHeaders()
    }   
    return this.http.get(url,options)
    .pipe(
        catchError((err, source) => {
        const empty  = null;
        return of(empty);
        })
    );
    // return of(newBBData);
  }

  getBBDataForUser(firstname, lastname, datas:Array<any> ) {
    let data = datas.filter (ele => {
      if(ele.transfereeDetails && ele.transfereeDetails.benefitBuilder && ele.transfereeDetails.name 
        && ele.transfereeDetails.name.firstName === firstname && ele.transfereeDetails.name.lastName === lastname) {
          return ele.transfereeDetails;
        }
    })
    return data;
  }

  getBBDataFirst(datas:Array<any>) {
    let data = datas.length >1 ? datas[18] : datas[18];
    this.bbAppDataService.bbdata.next(data);
    this.setbbdata(JSON.stringify(data));
    return data;
  }
  setbbdata(data) {
    localStorage.setItem(bbdatakey,data);
  }
  
  assignBBdata(benefitBuilderData) {
    this.bbAppDataService.InitializeStubData(benefitBuilderData);
  }
}



