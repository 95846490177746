export const countryInfoData: any[] = [
  {
    "countryName": "Afghanistan",
    "countryDialingCode": 93,
    "countryiso3CharCode": "AFG",
    "countryiso2CharCode": "AF",
    "currency": {
      "description": "Afghanistan Afghan",
      "code": "AFN"
    }
  },
  {
    "countryName": "Albania",
    "countryDialingCode": 355,
    "countryiso3CharCode": "ALB",
    "countryiso2CharCode": "AL",
    "currency": {
      "description": "Albanian Lek",
      "code": "ALL"
    }
  },
  {
    "countryName": "Algeria",
    "countryDialingCode": 213,
    "countryiso3CharCode": "DZA",
    "countryiso2CharCode": "DZ",
    "currency": {
      "description": "Algerian Dinar",
      "code": "DZD"
    }
  },
  {
    "countryName": "American Samoa",
    "countryDialingCode": 684,
    "countryiso3CharCode": "ASM",
    "countryiso2CharCode": "AS",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Andorra",
    "countryDialingCode": 376,
    "countryiso3CharCode": "AND",
    "countryiso2CharCode": "AD",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Angola",
    "countryDialingCode": 244,
    "countryiso3CharCode": "AGO",
    "countryiso2CharCode": "AO",
    "currency": {
      "description": "Angolan Kwanza",
      "code": "AOA"
    }
  },
  {
    "countryName": "Anguilla",
    "countryDialingCode": 1264,
    "countryiso3CharCode": "AIA",
    "countryiso2CharCode": "AI",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Antarctica",
    "countryDialingCode": 672,
    "countryiso3CharCode": "ATA",
    "countryiso2CharCode": "AQ",
    "currency": {
      "description": "Norwegian Krone",
      "code": "NOK"
    }
  },
  {
    "countryName": "Antigua and Barbuda",
    "countryDialingCode": 1268,
    "countryiso3CharCode": "ATG",
    "countryiso2CharCode": "AG",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Argentina",
    "countryDialingCode": 54,
    "countryiso3CharCode": "ARG",
    "countryiso2CharCode": "AR",
    "currency": {
      "description": "Argentine Peso",
      "code": "ARS"
    }
  },
  {
    "countryName": "Armenia",
    "countryDialingCode": 374,
    "countryiso3CharCode": "ARM",
    "countryiso2CharCode": "AM",
    "currency": {
      "description": "Armenia Dram",
      "code": "AMD"
    }
  },
  {
    "countryName": "Aruba",
    "countryDialingCode": 297,
    "countryiso3CharCode": "ABW",
    "countryiso2CharCode": "AW",
    "currency": {
      "description": "Aruban Florin",
      "code": "AWG"
    }
  },
  {
    "countryName": "Australia",
    "countryDialingCode": 61,
    "countryiso3CharCode": "AUS",
    "countryiso2CharCode": "AU",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Austria",
    "countryDialingCode": 43,
    "countryiso3CharCode": "AUT",
    "countryiso2CharCode": "AT",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Azerbaijan",
    "countryDialingCode": 994,
    "countryiso3CharCode": "AZE",
    "countryiso2CharCode": "AZ",
    "currency": {
      "description": "Azerbaijani Manat",
      "code": "AZN"
    }
  },
  {
    "countryName": "Bahamas",
    "countryDialingCode": 997,
    "countryiso3CharCode": "BHS",
    "countryiso2CharCode": "BS",
    "currency": {
      "description": "Bahamas Dollar",
      "code": "BSD"
    }
  },
  {
    "countryName": "Bahrain",
    "countryDialingCode": 973,
    "countryiso3CharCode": "BHR",
    "countryiso2CharCode": "BH",
    "currency": {
      "description": "Bahraini Dinar",
      "code": "BHD"
    }
  },
  {
    "countryName": "Bangladesh",
    "countryDialingCode": 880,
    "countryiso3CharCode": "BGD",
    "countryiso2CharCode": "BD",
    "currency": {
      "description": "Bangladeshi Taka",
      "code": "BDT"
    }
  },
  {
    "countryName": "Barbados",
    "countryDialingCode": 246,
    "countryiso3CharCode": "BRB",
    "countryiso2CharCode": "BB",
    "currency": {
      "description": "Barbados Dollar",
      "code": "BBD"
    }
  },
  {
    "countryName": "Belarus",
    "countryDialingCode": 375,
    "countryiso3CharCode": "BLR",
    "countryiso2CharCode": "BY",
    "currency": {
      "description": "Belarussian Ruble - Do Not Use",
      "code": "BYR"
    }
  },
  {
    "countryName": "Belgium",
    "countryDialingCode": 32,
    "countryiso3CharCode": "BEL",
    "countryiso2CharCode": "BE",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Belize",
    "countryDialingCode": 501,
    "countryiso3CharCode": "BLZ",
    "countryiso2CharCode": "BZ",
    "currency": {
      "description": "Belize Dollar",
      "code": "BZD"
    }
  },
  {
    "countryName": "Benin",
    "countryDialingCode": 229,
    "countryiso3CharCode": "BEN",
    "countryiso2CharCode": "BJ",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Bermuda",
    "countryDialingCode": 441,
    "countryiso3CharCode": "BMU",
    "countryiso2CharCode": "BM",
    "currency": {
      "description": "Bermudian Dollar",
      "code": "BMD"
    }
  },
  {
    "countryName": "Bhutan",
    "countryDialingCode": 975,
    "countryiso3CharCode": "BTN",
    "countryiso2CharCode": "BT",
    "currency": {
      "description": "Bhutan Ngultrum",
      "code": "BTN"
    }
  },
  {
    "countryName": "Bolivia",
    "countryDialingCode": 591,
    "countryiso3CharCode": "BOL",
    "countryiso2CharCode": "BO",
    "currency": {
      "description": "Bolivia Boliviano",
      "code": "BOB"
    }
  },
  {
    "countryName": "Bosnia and Herzegovina",
    "countryDialingCode": 387,
    "countryiso3CharCode": "BIH",
    "countryiso2CharCode": "BA"
  },
  {
    "countryName": "Botswana",
    "countryDialingCode": 267,
    "countryiso3CharCode": "BWA",
    "countryiso2CharCode": "BW",
    "currency": {
      "description": "Botswana Pula",
      "code": "BWP"
    }
  },
  {
    "countryName": "Bouvet Island",
    "countryDialingCode": 284,
    "countryiso3CharCode": "BVI",
    "countryiso2CharCode": "BV",
    "currency": {
      "description": "Norwegian Krone",
      "code": "NOK"
    }
  },
  {
    "countryName": "Brazil",
    "countryDialingCode": 55,
    "countryiso3CharCode": "BRA",
    "countryiso2CharCode": "BR",
    "currency": {
      "description": "Brazilian Real",
      "code": "BRL"
    }
  },
  {
    "countryName": "British Indian Ocean Territory",
    "countryDialingCode": 246,
    "countryiso3CharCode": "IOT",
    "countryiso2CharCode": "IO",
    "currency": {
      "description": "British Pound",
      "code": "GBP"
    }
  },
  {
    "countryName": "British Virgin Islands",
    "countryDialingCode": 284,
    "countryiso3CharCode": "VGB",
    "countryiso2CharCode": "VG",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Brunei Darussalam",
    "countryDialingCode": 673,
    "countryiso3CharCode": "BRN",
    "countryiso2CharCode": "BN",
    "currency": {
      "description": "Brunei Dollar",
      "code": "BND"
    }
  },
  {
    "countryName": "Bulgaria",
    "countryDialingCode": 359,
    "countryiso3CharCode": "BGR",
    "countryiso2CharCode": "BG",
    "currency": {
      "description": "Bulgarian Lev",
      "code": "BGN"
    }
  },
  {
    "countryName": "Burkina Faso",
    "countryDialingCode": 226,
    "countryiso3CharCode": "BFA",
    "countryiso2CharCode": "BF",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Myanmar",
    "countryDialingCode": 95,
    "countryiso3CharCode": "MMR",
    "countryiso2CharCode": "MM",
    "currency": {
      "description": "Myanmar Kyat",
      "code": "MMK"
    }
  },
  {
    "countryName": "Burundi",
    "countryDialingCode": 257,
    "countryiso3CharCode": "BDI",
    "countryiso2CharCode": "BI",
    "currency": {
      "description": "Burundi Franc",
      "code": "BIF"
    }
  },
  {
    "countryName": "Cambodia",
    "countryDialingCode": 855,
    "countryiso3CharCode": "KHM",
    "countryiso2CharCode": "KH",
    "currency": {
      "description": "Cambodian Riel",
      "code": "KHR"
    }
  },
  {
    "countryName": "Cameroon",
    "countryDialingCode": 237,
    "countryiso3CharCode": "CMR",
    "countryiso2CharCode": "CM",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Canada",
    "countryDialingCode": 1,
    "countryiso3CharCode": "CAN",
    "countryiso2CharCode": "CA",
    "currency": {
      "description": "Canadian Dollar",
      "code": "CAD"
    }
  },
  {
    "countryName": "Cape Verde",
    "countryDialingCode": 238,
    "countryiso3CharCode": "CPV",
    "countryiso2CharCode": "CV",
    "currency": {
      "description": "Cape Verde Escudo",
      "code": "CVE"
    }
  },
  {
    "countryName": "Cayman Islands",
    "countryDialingCode": 345,
    "countryiso3CharCode": "CYM",
    "countryiso2CharCode": "KY",
    "currency": {
      "description": "Cayman Islands Dollar",
      "code": "KYD"
    }
  },
  {
    "countryName": "Central African Republic",
    "countryDialingCode": 236,
    "countryiso3CharCode": "CAF",
    "countryiso2CharCode": "CF",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Chad",
    "countryDialingCode": 235,
    "countryiso3CharCode": "TCD",
    "countryiso2CharCode": "TD",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Chile",
    "countryDialingCode": 56,
    "countryiso3CharCode": "CHL",
    "countryiso2CharCode": "CL",
    "currency": {
      "description": "Chilean Peso",
      "code": "CLP"
    }
  },
  {
    "countryName": "China",
    "countryDialingCode": 86,
    "countryiso3CharCode": "CHN",
    "countryiso2CharCode": "CN",
    "currency": {
      "description": "Chinese Yuan Renminbi",
      "code": "CNY"
    }
  },
  {
    "countryName": "Christmas Island",
    "countryDialingCode": 6724,
    "countryiso3CharCode": "CXR",
    "countryiso2CharCode": "CX",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Cocos (Keeling) Islands",
    "countryDialingCode": 2877,
    "countryiso3CharCode": "CCK",
    "countryiso2CharCode": "CC",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Colombia",
    "countryDialingCode": 57,
    "countryiso3CharCode": "COL",
    "countryiso2CharCode": "CO",
    "currency": {
      "description": "Colombian Peso",
      "code": "COP"
    }
  },
  {
    "countryName": "Comoros",
    "countryDialingCode": 269,
    "countryiso3CharCode": "COM",
    "countryiso2CharCode": "KM",
    "currency": {
      "description": "Comoros Franc",
      "code": "KMF"
    }
  },
  {
    "countryName": "Congo, Republic of the",
    "countryDialingCode": 242,
    "countryiso3CharCode": "COG",
    "countryiso2CharCode": "CG",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Congo, Democratic Republic of the",
    "countryDialingCode": 243,
    "countryiso3CharCode": "COD",
    "countryiso2CharCode": "CD",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Cook Islands",
    "countryDialingCode": 682,
    "countryiso3CharCode": "COK",
    "countryiso2CharCode": "CK",
    "currency": {
      "description": "New Zealand Dollar",
      "code": "NZD"
    }
  },
  {
    "countryName": "Costa Rica",
    "countryDialingCode": 506,
    "countryiso3CharCode": "CRI",
    "countryiso2CharCode": "CR",
    "currency": {
      "description": "Costa Rican Colon",
      "code": "CRC"
    }
  },
  {
    "countryName": "Côte D'ivoire",
    "countryDialingCode": 225,
    "countryiso3CharCode": "CDR",
    "countryiso2CharCode": "CI",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Croatia",
    "countryDialingCode": 385,
    "countryiso3CharCode": "HRV",
    "countryiso2CharCode": "HR",
    "currency": {
      "description": "Croatian Kuna",
      "code": "HRK"
    }
  },
  {
    "countryName": "Cuba",
    "countryDialingCode": 53,
    "countryiso3CharCode": "CUB",
    "countryiso2CharCode": "CU",
    "currency": {
      "description": "Cuban Peso",
      "code": "CUP"
    }
  },
  {
    "countryName": "Cyprus",
    "countryDialingCode": 357,
    "countryiso3CharCode": "CYP",
    "countryiso2CharCode": "CY",
    "currency": {
      "description": "Cyprus Pound",
      "code": "CYP"
    }
  },
  {
    "countryName": "Czech Republic",
    "countryDialingCode": 420,
    "countryiso3CharCode": "CZE",
    "countryiso2CharCode": "CZ",
    "currency": {
      "description": "Czech Koruna",
      "code": "CZK"
    }
  },
  {
    "countryName": "Denmark",
    "countryDialingCode": 45,
    "countryiso3CharCode": "DNK",
    "countryiso2CharCode": "DK",
    "currency": {
      "description": "Danish Krone",
      "code": "DKK"
    }
  },
  {
    "countryName": "Djibouti",
    "countryDialingCode": 253,
    "countryiso3CharCode": "DJI",
    "countryiso2CharCode": "DJ",
    "currency": {
      "description": "Djibouti Franc",
      "code": "DJF"
    }
  },
  {
    "countryName": "Dominica",
    "countryDialingCode": 767,
    "countryiso3CharCode": "DMA",
    "countryiso2CharCode": "DM",
    "currency": {
      "description": "Dominican Peso",
      "code": "DOP"
    }
  },
  {
    "countryName": "Dominican Republic",
    "countryDialingCode": 809,
    "countryiso3CharCode": "DOM",
    "countryiso2CharCode": "DO",
    "currency": {
      "description": "Dominican Peso",
      "code": "DOP"
    }
  },
  {
    "countryName": "Dubai",
    "countryDialingCode": 971,
    "countryiso3CharCode": "DUB",
    "countryiso2CharCode": "AE",
    "currency": {
      "description": "United Arab Emirates Dirham",
      "code": "AED"
    }
  },
  {
    "countryName": "Ecuador",
    "countryDialingCode": 593,
    "countryiso3CharCode": "ECU",
    "countryiso2CharCode": "EC",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Egypt",
    "countryDialingCode": 20,
    "countryiso3CharCode": "EGY",
    "countryiso2CharCode": "EG",
    "currency": {
      "description": "Egyptian Pound",
      "code": "EGP"
    }
  },
  {
    "countryName": "El Salvador",
    "countryDialingCode": 503,
    "countryiso3CharCode": "SLV",
    "countryiso2CharCode": "SV",
    "currency": {
      "description": "El Salvador Colon",
      "code": "SVC"
    }
  },
  {
    "countryName": "Equatorial Guinea",
    "countryDialingCode": 240,
    "countryiso3CharCode": "GNQ",
    "countryiso2CharCode": "GQ",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Eritrea",
    "countryDialingCode": 291,
    "countryiso3CharCode": "ERI",
    "countryiso2CharCode": "ER",
    "currency": {
      "description": "ERN",
      "code": "ERN"
    }
  },
  {
    "countryName": "Estonia",
    "countryDialingCode": 372,
    "countryiso3CharCode": "EST",
    "countryiso2CharCode": "EE",
    "currency": {
      "description": "Estonian Kroon",
      "code": "EEK"
    }
  },
  {
    "countryName": "Ethiopia",
    "countryDialingCode": 251,
    "countryiso3CharCode": "ETH",
    "countryiso2CharCode": "ET",
    "currency": {
      "description": "Ethiopia Birr",
      "code": "ETB"
    }
  },
  {
    "countryName": "Falkland Islands (Malvinas)",
    "countryDialingCode": 500,
    "countryiso3CharCode": "FLK",
    "countryiso2CharCode": "FK",
    "currency": {
      "description": "Falkland Islands Pound",
      "code": "FKP"
    }
  },
  {
    "countryName": "Faroe Islands",
    "countryDialingCode": 298,
    "countryiso3CharCode": "FRO",
    "countryiso2CharCode": "FO",
    "currency": {
      "description": "Danish Krone",
      "code": "DKK"
    }
  },
  {
    "countryName": "Fiji",
    "countryDialingCode": 679,
    "countryiso3CharCode": "FJI",
    "countryiso2CharCode": "FJ",
    "currency": {
      "description": "Fiji Dollar",
      "code": "FJD"
    }
  },
  {
    "countryName": "Finland",
    "countryDialingCode": 358,
    "countryiso3CharCode": "FIN",
    "countryiso2CharCode": "FI",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "France",
    "countryDialingCode": 33,
    "countryiso3CharCode": "FRA",
    "countryiso2CharCode": "FR",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "French Guiana",
    "countryDialingCode": 594,
    "countryiso3CharCode": "GUF",
    "countryiso2CharCode": "GF",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "French Polynesia",
    "countryDialingCode": 689,
    "countryiso3CharCode": "PYF",
    "countryiso2CharCode": "PF",
    "currency": {
      "description": "Polynesian Franc",
      "code": "XPF"
    }
  },
  {
    "countryName": "French Southern Territories",
    "countryDialingCode": 228,
    "countryiso3CharCode": "ATF",
    "countryiso2CharCode": "TF"
  },
  {
    "countryName": "Gabon",
    "countryDialingCode": 241,
    "countryiso3CharCode": "GAB",
    "countryiso2CharCode": "GA",
    "currency": {
      "description": "CFA Franc BEAC",
      "code": "XAF"
    }
  },
  {
    "countryName": "Gambia",
    "countryDialingCode": 220,
    "countryiso3CharCode": "GMB",
    "countryiso2CharCode": "GM",
    "currency": {
      "description": "Gambian Dalasi",
      "code": "GMD"
    }
  },
  {
    "countryName": "Georgia",
    "countryDialingCode": 995,
    "countryiso3CharCode": "GEO",
    "countryiso2CharCode": "GE",
    "currency": {
      "description": "Georgian Lari",
      "code": "GEL"
    }
  },
  {
    "countryName": "Germany",
    "countryDialingCode": 49,
    "countryiso3CharCode": "DEU",
    "countryiso2CharCode": "DE",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Ghana",
    "countryDialingCode": 233,
    "countryiso3CharCode": "GHA",
    "countryiso2CharCode": "GH",
    "currency": {
      "description": "Ghana Cedi",
      "code": "GHS"
    }
  },
  {
    "countryName": "Gibraltar",
    "countryDialingCode": 350,
    "countryiso3CharCode": "GIB",
    "countryiso2CharCode": "GI",
    "currency": {
      "description": "Gibraltar Pound",
      "code": "GIP"
    }
  },
  {
    "countryName": "Greece",
    "countryDialingCode": 30,
    "countryiso3CharCode": "GRC",
    "countryiso2CharCode": "GR",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Greenland",
    "countryDialingCode": 299,
    "countryiso3CharCode": "GRL",
    "countryiso2CharCode": "GL",
    "currency": {
      "description": "Danish Krone",
      "code": "DKK"
    }
  },
  {
    "countryName": "Grenada",
    "countryDialingCode": 473,
    "countryiso3CharCode": "GRD",
    "countryiso2CharCode": "GD",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Guadeloupe",
    "countryDialingCode": 590,
    "countryiso3CharCode": "GLP",
    "countryiso2CharCode": "GP",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Guam",
    "countryDialingCode": 671,
    "countryiso3CharCode": "GUM",
    "countryiso2CharCode": "GU",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Guatemala",
    "countryDialingCode": 502,
    "countryiso3CharCode": "GTM",
    "countryiso2CharCode": "GT",
    "currency": {
      "description": "Guatemalan Quetzal",
      "code": "GTQ"
    }
  },
  {
    "countryName": "Guernsey",
    "countryDialingCode": 44,
    "countryiso3CharCode": "GGY",
    "countryiso2CharCode": "GG",
    "currency": {
      "description": "British Pound",
      "code": "GBP"
    }
  },
  {
    "countryName": "Guinea",
    "countryDialingCode": 224,
    "countryiso3CharCode": "GIN",
    "countryiso2CharCode": "GN",
    "currency": {
      "description": "Guinea Franc",
      "code": "GNF"
    }
  },
  {
    "countryName": "Guinea-Bissau",
    "countryDialingCode": 245,
    "countryiso3CharCode": "GNB",
    "countryiso2CharCode": "GW",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Guyana",
    "countryDialingCode": 592,
    "countryiso3CharCode": "GUY",
    "countryiso2CharCode": "GY",
    "currency": {
      "description": "Guyanan Dollar",
      "code": "GYD"
    }
  },
  {
    "countryName": "Haiti",
    "countryDialingCode": 509,
    "countryiso3CharCode": "HTI",
    "countryiso2CharCode": "HT",
    "currency": {
      "description": "Haitian Gourde",
      "code": "HTG"
    }
  },
  {
    "countryName": "Honduras",
    "countryDialingCode": 504,
    "countryiso3CharCode": "HND",
    "countryiso2CharCode": "HN",
    "currency": {
      "description": "Honduran Lempira",
      "code": "HNL"
    }
  },
  {
    "countryName": "Hong Kong",
    "countryDialingCode": 852,
    "countryiso3CharCode": "HKG",
    "countryiso2CharCode": "HK",
    "currency": {
      "description": "Hong Kong Dollar",
      "code": "HKD"
    }
  },
  {
    "countryName": "Hungary",
    "countryDialingCode": 36,
    "countryiso3CharCode": "HUN",
    "countryiso2CharCode": "HU",
    "currency": {
      "description": "Hungarian Forint",
      "code": "HUF"
    }
  },
  {
    "countryName": "Iceland",
    "countryDialingCode": 354,
    "countryiso3CharCode": "ISL",
    "countryiso2CharCode": "IS",
    "currency": {
      "description": "Iceland Krona",
      "code": "ISK"
    }
  },
  {
    "countryName": "India",
    "countryDialingCode": 91,
    "countryiso3CharCode": "IND",
    "countryiso2CharCode": "IN",
    "currency": {
      "description": "Indian Rupee",
      "code": "INR"
    }
  },
  {
    "countryName": "Indonesia",
    "countryDialingCode": 62,
    "countryiso3CharCode": "IDN",
    "countryiso2CharCode": "ID",
    "currency": {
      "description": "Indonesian Rupiah",
      "code": "IDR"
    }
  },
  {
    "countryName": "Iran, Islamic Republic Of",
    "countryDialingCode": 98,
    "countryiso3CharCode": "IRN",
    "countryiso2CharCode": "IR",
    "currency": {
      "description": "Iranian Rial",
      "code": "IRR"
    }
  },
  {
    "countryName": "Iraq",
    "countryDialingCode": 964,
    "countryiso3CharCode": "IRQ",
    "countryiso2CharCode": "IQ",
    "currency": {
      "description": "Iraqi Dinar",
      "code": "IQD"
    }
  },
  {
    "countryName": "Ireland",
    "countryDialingCode": 353,
    "countryiso3CharCode": "IRL",
    "countryiso2CharCode": "IE",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Isle Of Man",
    "countryDialingCode": 44,
    "countryiso3CharCode": "IMN",
    "countryiso2CharCode": "IM",
    "currency": {
      "description": "British Pound",
      "code": "GBP"
    }
  },
  {
    "countryName": "Israel",
    "countryDialingCode": 972,
    "countryiso3CharCode": "ISR",
    "countryiso2CharCode": "IL",
    "currency": {
      "description": "Israeli New Shekel",
      "code": "ILS"
    }
  },
  {
    "countryName": "Italy",
    "countryDialingCode": 39,
    "countryiso3CharCode": "ITA",
    "countryiso2CharCode": "IT",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Ivory Coast",
    "countryDialingCode": 225,
    "countryiso3CharCode": "CIV",
    "countryiso2CharCode": "CI",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Jamaica",
    "countryDialingCode": 876,
    "countryiso3CharCode": "JAM",
    "countryiso2CharCode": "JM",
    "currency": {
      "description": "Jamaican Dollar",
      "code": "JMD"
    }
  },
  {
    "countryName": "Japan",
    "countryDialingCode": 81,
    "countryiso3CharCode": "JPN",
    "countryiso2CharCode": "JP",
    "currency": {
      "description": "Japanese Yen",
      "code": "JPY"
    }
  },
  {
    "countryName": "Jersey",
    "countryDialingCode": 44,
    "countryiso3CharCode": "XJE",
    "countryiso2CharCode": "JE",
    "currency": {
      "description": "British Pound",
      "code": "GBP"
    }
  },
  {
    "countryName": "Jordan",
    "countryDialingCode": 962,
    "countryiso3CharCode": "JOR",
    "countryiso2CharCode": "JO",
    "currency": {
      "description": "Jordanian Dinar",
      "code": "JOD"
    }
  },
  {
    "countryName": "Kazakhstan",
    "countryDialingCode": 7312,
    "countryiso3CharCode": "KAZ",
    "countryiso2CharCode": "KZ",
    "currency": {
      "description": "Kazakhstan Tenge",
      "code": "KZT"
    }
  },
  {
    "countryName": "Kenya",
    "countryDialingCode": 254,
    "countryiso3CharCode": "KEN",
    "countryiso2CharCode": "KE",
    "currency": {
      "description": "Kenyan Schilling",
      "code": "KES"
    }
  },
  {
    "countryName": "Kiribati",
    "countryDialingCode": 686,
    "countryiso3CharCode": "KIR",
    "countryiso2CharCode": "KI",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Kuwait",
    "countryDialingCode": 965,
    "countryiso3CharCode": "KWT",
    "countryiso2CharCode": "KW",
    "currency": {
      "description": "Kuwaiti Dinar",
      "code": "KWD"
    }
  },
  {
    "countryName": "Kyrgyzstan",
    "countryDialingCode": 996,
    "countryiso3CharCode": "KGZ",
    "countryiso2CharCode": "KG",
    "currency": {
      "description": "Kyrgyzstanian Som",
      "code": "KGS"
    }
  },
  {
    "countryName": "Laos",
    "countryDialingCode": 856,
    "countryiso3CharCode": "LAO",
    "countryiso2CharCode": "LA",
    "currency": {
      "description": "Lao Kip",
      "code": "LAK"
    }
  },
  {
    "countryName": "Latvia",
    "countryDialingCode": 371,
    "countryiso3CharCode": "LVA",
    "countryiso2CharCode": "LV",
    "currency": {
      "description": "Latvian Lats",
      "code": "LVL"
    }
  },
  {
    "countryName": "Lebanon",
    "countryDialingCode": 961,
    "countryiso3CharCode": "LBN",
    "countryiso2CharCode": "LB",
    "currency": {
      "description": "Lebanese Pound",
      "code": "LBP"
    }
  },
  {
    "countryName": "Lesotho",
    "countryDialingCode": 266,
    "countryiso3CharCode": "LSO",
    "countryiso2CharCode": "LS",
    "currency": {
      "description": "Lesotho Loti",
      "code": "LSL"
    }
  },
  {
    "countryName": "Liberia",
    "countryDialingCode": 231,
    "countryiso3CharCode": "LBR",
    "countryiso2CharCode": "LR",
    "currency": {
      "description": "Liberian Dollar",
      "code": "LRD"
    }
  },
  {
    "countryName": "Libya",
    "countryDialingCode": 218,
    "countryiso3CharCode": "LBY",
    "countryiso2CharCode": "LY",
    "currency": {
      "description": "Libyan Dinar",
      "code": "LYD"
    }
  },
  {
    "countryName": "Liechtenstein",
    "countryDialingCode": 4175,
    "countryiso3CharCode": "LIE",
    "countryiso2CharCode": "LI",
    "currency": {
      "description": "Swiss Franc",
      "code": "CHF"
    }
  },
  {
    "countryName": "Lithuania",
    "countryDialingCode": 370,
    "countryiso3CharCode": "LTU",
    "countryiso2CharCode": "LT",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Luxembourg",
    "countryDialingCode": 352,
    "countryiso3CharCode": "LUX",
    "countryiso2CharCode": "LU",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Macau",
    "countryDialingCode": 853,
    "countryiso3CharCode": "MAC",
    "countryiso2CharCode": "MO",
    "currency": {
      "description": "Macau Pataca",
      "code": "MOP"
    }
  },
  {
    "countryName": "Macedonia",
    "countryDialingCode": 389,
    "countryiso3CharCode": "MKD",
    "countryiso2CharCode": "MK",
    "currency": {
      "description": "Macedonia Denar",
      "code": "MKD"
    }
  },
  {
    "countryName": "Madagascar",
    "countryDialingCode": 261,
    "countryiso3CharCode": "MDG",
    "countryiso2CharCode": "MG",
    "currency": {
      "description": "Malagasy Franc",
      "code": "MGF"
    }
  },
  {
    "countryName": "Malawi",
    "countryDialingCode": 265,
    "countryiso3CharCode": "MWI",
    "countryiso2CharCode": "MW",
    "currency": {
      "description": "Malawi Kwacha",
      "code": "MWK"
    }
  },
  {
    "countryName": "Malaysia",
    "countryDialingCode": 60,
    "countryiso3CharCode": "MYS",
    "countryiso2CharCode": "MY",
    "currency": {
      "description": "Malaysian Ringgit",
      "code": "MYR"
    }
  },
  {
    "countryName": "Maldives",
    "countryDialingCode": 960,
    "countryiso3CharCode": "MDV",
    "countryiso2CharCode": "MV",
    "currency": {
      "description": "Maldive Rufiyaa",
      "code": "MVR"
    }
  },
  {
    "countryName": "Mali",
    "countryDialingCode": 223,
    "countryiso3CharCode": "MLI",
    "countryiso2CharCode": "ML",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Malta",
    "countryDialingCode": 356,
    "countryiso3CharCode": "MLT",
    "countryiso2CharCode": "MT",
    "currency": {
      "description": "Maltese Lira",
      "code": "MTL"
    }
  },
  {
    "countryName": "Marshall Islands",
    "countryDialingCode": 692,
    "countryiso3CharCode": "MHL",
    "countryiso2CharCode": "MH",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Martinique",
    "countryDialingCode": 596,
    "countryiso3CharCode": "MTQ",
    "countryiso2CharCode": "MQ",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Mauritania",
    "countryDialingCode": 222,
    "countryiso3CharCode": "MRT",
    "countryiso2CharCode": "MR",
    "currency": {
      "description": "Mauritanian Ouguiya",
      "code": "MRO"
    }
  },
  {
    "countryName": "Mauritius",
    "countryDialingCode": 230,
    "countryiso3CharCode": "MUS",
    "countryiso2CharCode": "MU",
    "currency": {
      "description": "Mauritius Rupee",
      "code": "MUR"
    }
  },
  {
    "countryName": "Mayotte",
    "countryDialingCode": 2696,
    "countryiso3CharCode": "MYT",
    "countryiso2CharCode": "YT",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Mexico",
    "countryDialingCode": 52,
    "countryiso3CharCode": "MEX",
    "countryiso2CharCode": "MX",
    "currency": {
      "description": "Mexican Peso",
      "code": "MXN"
    }
  },
  {
    "countryName": "Micronesia",
    "countryDialingCode": 691,
    "countryiso3CharCode": "FSM",
    "countryiso2CharCode": "FM",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Moldova",
    "countryDialingCode": 373,
    "countryiso3CharCode": "MDA",
    "countryiso2CharCode": "MD",
    "currency": {
      "description": "Romanian Leu",
      "code": "MDL"
    }
  },
  {
    "countryName": "Monaco",
    "countryDialingCode": 377,
    "countryiso3CharCode": "MCO",
    "countryiso2CharCode": "MC",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Mongolia",
    "countryDialingCode": 976,
    "countryiso3CharCode": "MNG",
    "countryiso2CharCode": "MN",
    "currency": {
      "description": "Mongolian Tugrik",
      "code": "MNT"
    }
  },
  {
    "countryName": "Montenegro",
    "countryDialingCode": 382,
    "countryiso3CharCode": "MON",
    "countryiso2CharCode": "ME",
    "currency": {
      "description": "Yugoslav Dinar",
      "code": "YUM"
    }
  },
  {
    "countryName": "Montserrat",
    "countryDialingCode": 1664,
    "countryiso3CharCode": "MSR",
    "countryiso2CharCode": "MS",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Morocco",
    "countryDialingCode": 212,
    "countryiso3CharCode": "MAR",
    "countryiso2CharCode": "MA",
    "currency": {
      "description": "Moroccan Dirham",
      "code": "MAD"
    }
  },
  {
    "countryName": "Mozambique",
    "countryDialingCode": 258,
    "countryiso3CharCode": "MOZ",
    "countryiso2CharCode": "MZ",
    "currency": {
      "description": "Mozambique New Metical",
      "code": "MZN"
    }
  },
  {
    "countryName": "Myanmar (Burma)",
    "countryDialingCode": 95,
    "countryiso3CharCode": "BUR",
    "countryiso2CharCode": "MM",
    "currency": {
      "description": "Myanmar Kyat",
      "code": "MMK"
    }
  },
  {
    "countryName": "Namibia",
    "countryDialingCode": 264,
    "countryiso3CharCode": "NAM",
    "countryiso2CharCode": "NA",
    "currency": {
      "description": "Namibian Dollar",
      "code": "NAD"
    }
  },
  {
    "countryName": "Nauru",
    "countryDialingCode": 674,
    "countryiso3CharCode": "NRU",
    "countryiso2CharCode": "NR",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Nepal",
    "countryDialingCode": 977,
    "countryiso3CharCode": "NPL",
    "countryiso2CharCode": "NP",
    "currency": {
      "description": "Nepalese Rupee",
      "code": "NPR"
    }
  },
  {
    "countryName": "Netherlands",
    "countryDialingCode": 31,
    "countryiso3CharCode": "NLD",
    "countryiso2CharCode": "NL",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Netherlands Antilles",
    "countryDialingCode": 599,
    "countryiso3CharCode": "ANT",
    "countryiso2CharCode": "AN",
    "currency": {
      "description": "Netherlands Antillian Guilder",
      "code": "ANG"
    }
  },
  {
    "countryName": "New Caledonia",
    "countryDialingCode": 687,
    "countryiso3CharCode": "NCL",
    "countryiso2CharCode": "NC",
    "currency": {
      "description": "Polynesian Franc",
      "code": "XPF"
    }
  },
  {
    "countryName": "New Zealand",
    "countryDialingCode": 64,
    "countryiso3CharCode": "NZL",
    "countryiso2CharCode": "NZ",
    "currency": {
      "description": "New Zealand Dollar",
      "code": "NZD"
    }
  },
  {
    "countryName": "Nicaragua",
    "countryDialingCode": 505,
    "countryiso3CharCode": "NIC",
    "countryiso2CharCode": "NI",
    "currency": {
      "description": "Nicaraguan Cordoba Oro",
      "code": "NIO"
    }
  },
  {
    "countryName": "Niger",
    "countryDialingCode": 227,
    "countryiso3CharCode": "NER",
    "countryiso2CharCode": "NE",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Nigeria",
    "countryDialingCode": 234,
    "countryiso3CharCode": "NGA",
    "countryiso2CharCode": "NG",
    "currency": {
      "description": "Nigerian Naira",
      "code": "NGN"
    }
  },
  {
    "countryName": "Niue",
    "countryDialingCode": 683,
    "countryiso3CharCode": "NIU",
    "countryiso2CharCode": "NU",
    "currency": {
      "description": "New Zealand Dollar",
      "code": "NZD"
    }
  },
  {
    "countryName": "Norfolk Island",
    "countryDialingCode": 6723,
    "countryiso3CharCode": "NFK",
    "countryiso2CharCode": "NF",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "North Korea",
    "countryDialingCode": 850,
    "countryiso3CharCode": "PRK",
    "countryiso2CharCode": "KP",
    "currency": {
      "description": "North Korean Won",
      "code": "KPW"
    }
  },
  {
    "countryName": "Northern Mariana Islands",
    "countryDialingCode": 670,
    "countryiso3CharCode": "MNP",
    "countryiso2CharCode": "MP",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Norway",
    "countryDialingCode": 47,
    "countryiso3CharCode": "NOR",
    "countryiso2CharCode": "NO",
    "currency": {
      "description": "Norwegian Krone",
      "code": "NOK"
    }
  },
  {
    "countryName": "Oman",
    "countryDialingCode": 968,
    "countryiso3CharCode": "OMN",
    "countryiso2CharCode": "OM",
    "currency": {
      "description": "Omani Rial",
      "code": "OMR"
    }
  },
  {
    "countryName": "Pakistan",
    "countryDialingCode": 92,
    "countryiso3CharCode": "PAK",
    "countryiso2CharCode": "PK",
    "currency": {
      "description": "Pakistan Rupee",
      "code": "PKR"
    }
  },
  {
    "countryName": "Palau",
    "countryDialingCode": 680,
    "countryiso3CharCode": "PLW",
    "countryiso2CharCode": "PW",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Panama",
    "countryDialingCode": 507,
    "countryiso3CharCode": "PAN",
    "countryiso2CharCode": "PA",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Papua New Guinea",
    "countryDialingCode": 675,
    "countryiso3CharCode": "PNG",
    "countryiso2CharCode": "PG",
    "currency": {
      "description": "Papua New Guinea Kina",
      "code": "PGK"
    }
  },
  {
    "countryName": "Paraguay",
    "countryDialingCode": 595,
    "countryiso3CharCode": "PRY",
    "countryiso2CharCode": "PY",
    "currency": {
      "description": "Paraguay Guarani",
      "code": "PYG"
    }
  },
  {
    "countryName": "Peru",
    "countryDialingCode": 51,
    "countryiso3CharCode": "PER",
    "countryiso2CharCode": "PE",
    "currency": {
      "description": "Peruvian Nuevo Sol",
      "code": "PEN"
    }
  },
  {
    "countryName": "Philippines",
    "countryDialingCode": 63,
    "countryiso3CharCode": "PHL",
    "countryiso2CharCode": "PH",
    "currency": {
      "description": "Philippine Peso",
      "code": "PHP"
    }
  },
  {
    "countryName": "Poland",
    "countryDialingCode": 48,
    "countryiso3CharCode": "POL",
    "countryiso2CharCode": "PL",
    "currency": {
      "description": "Polish Zloty",
      "code": "PLN"
    }
  },
  {
    "countryName": "Portugal",
    "countryDialingCode": 351,
    "countryiso3CharCode": "PRT",
    "countryiso2CharCode": "PT",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Puerto Rico",
    "countryDialingCode": 787,
    "countryiso3CharCode": "PRI",
    "countryiso2CharCode": "PR",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Qatar",
    "countryDialingCode": 974,
    "countryiso3CharCode": "QAT",
    "countryiso2CharCode": "QA",
    "currency": {
      "description": "Qatari Rial",
      "code": "QAR"
    }
  },
  {
    "countryName": "Reunion",
    "countryDialingCode": 262,
    "countryiso3CharCode": "REU",
    "countryiso2CharCode": "RE",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Romania",
    "countryDialingCode": 40,
    "countryiso3CharCode": "ROU",
    "countryiso2CharCode": "RO",
    "currency": {
      "description": "New Romanian Lei",
      "code": "RON"
    }
  },
  {
    "countryName": "Russia",
    "countryDialingCode": 7,
    "countryiso3CharCode": "RUS",
    "countryiso2CharCode": "RU",
    "currency": {
      "description": "Russian Ruble",
      "code": "RUB"
    }
  },
  {
    "countryName": "Rwanda",
    "countryDialingCode": 250,
    "countryiso3CharCode": "RWA",
    "countryiso2CharCode": "RW",
    "currency": {
      "description": "Rwanda Franc",
      "code": "RWF"
    }
  },
  {
    "countryName": "San Marino",
    "countryDialingCode": 378,
    "countryiso3CharCode": "SMR",
    "countryiso2CharCode": "SM",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Sao Tome and Principe",
    "countryDialingCode": 239,
    "countryiso3CharCode": "STP",
    "countryiso2CharCode": "ST",
    "currency": {
      "description": "Sao Tome and Principe Dobra",
      "code": "STD"
    }
  },
  {
    "countryName": "Saudi Arabia",
    "countryDialingCode": 966,
    "countryiso3CharCode": "SAU",
    "countryiso2CharCode": "SA",
    "currency": {
      "description": "Saudi Riyal",
      "code": "SAR"
    }
  },
  {
    "countryName": "Senegal",
    "countryDialingCode": 221,
    "countryiso3CharCode": "SEN",
    "countryiso2CharCode": "SN",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Republic of Serbia",
    "countryDialingCode": 381,
    "countryiso3CharCode": "SRB",
    "countryiso2CharCode": "RS",
    "currency": {
      "description": "New Serbian Dinar",
      "code": "RSD"
    }
  },
  {
    "countryName": "Seychelles",
    "countryDialingCode": 248,
    "countryiso3CharCode": "SYC",
    "countryiso2CharCode": "SC",
    "currency": {
      "description": "Seychelles Rupee",
      "code": "SCR"
    }
  },
  {
    "countryName": "Sierra Leone",
    "countryDialingCode": 232,
    "countryiso3CharCode": "SLE",
    "countryiso2CharCode": "SL",
    "currency": {
      "description": "Sierra Leone Leone",
      "code": "SLL"
    }
  },
  {
    "countryName": "Singapore",
    "countryDialingCode": 65,
    "countryiso3CharCode": "SGP",
    "countryiso2CharCode": "SG",
    "currency": {
      "description": "Singapore Dollar",
      "code": "SGD"
    }
  },
  {
    "countryName": "Slovakia",
    "countryDialingCode": 421,
    "countryiso3CharCode": "SVK",
    "countryiso2CharCode": "SK",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Slovenia",
    "countryDialingCode": 386,
    "countryiso3CharCode": "SVN",
    "countryiso2CharCode": "SI",
    "currency": {
      "description": "Slovenian Tolar",
      "code": "SIT"
    }
  },
  {
    "countryName": "Solomon Islands",
    "countryDialingCode": 677,
    "countryiso3CharCode": "SLB",
    "countryiso2CharCode": "SB",
    "currency": {
      "description": "Solomon Islands Dollar",
      "code": "SBD"
    }
  },
  {
    "countryName": "Somalia",
    "countryDialingCode": 252,
    "countryiso3CharCode": "SOM",
    "countryiso2CharCode": "SO",
    "currency": {
      "description": "Somali Schilling",
      "code": "SOS"
    }
  },
  {
    "countryName": "South Africa",
    "countryDialingCode": 27,
    "countryiso3CharCode": "ZAF",
    "countryiso2CharCode": "ZA",
    "currency": {
      "description": "South African Rand",
      "code": "ZAR"
    }
  },
  {
    "countryName": "South Georgia and The South Sandwich Islands",
    "countryDialingCode": 500,
    "countryiso3CharCode": "SGS",
    "countryiso2CharCode": "GS"
  },
  {
    "countryName": "South Korea",
    "countryDialingCode": 82,
    "countryiso3CharCode": "KOR",
    "countryiso2CharCode": "KR",
    "currency": {
      "description": "Korean Won",
      "code": "KRW"
    }
  },
  {
    "countryName": "Spain",
    "countryDialingCode": 34,
    "countryiso3CharCode": "ESP",
    "countryiso2CharCode": "ES",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Sri Lanka",
    "countryDialingCode": 94,
    "countryiso3CharCode": "LKA",
    "countryiso2CharCode": "LK",
    "currency": {
      "description": "Sri Lanka Rupee",
      "code": "LKR"
    }
  },
  {
    "countryName": "Saint Helena",
    "countryDialingCode": 290,
    "countryiso3CharCode": "SHN",
    "countryiso2CharCode": "SH",
    "currency": {
      "description": "St. Helena Pound",
      "code": "SHP"
    }
  },
  {
    "countryName": "Saint Kitts and Nevis",
    "countryDialingCode": 869,
    "countryiso3CharCode": "KNA",
    "countryiso2CharCode": "KN",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Saint Lucia",
    "countryDialingCode": 758,
    "countryiso3CharCode": "LCA",
    "countryiso2CharCode": "LC",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Saint Pierre and Miquelon",
    "countryDialingCode": 508,
    "countryiso3CharCode": "SPM",
    "countryiso2CharCode": "PM",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "St. Vincent and The Grenadines",
    "countryDialingCode": 784,
    "countryiso3CharCode": "VCT",
    "countryiso2CharCode": "VC",
    "currency": {
      "description": "East Caribbean Dollar",
      "code": "XCD"
    }
  },
  {
    "countryName": "Sudan",
    "countryDialingCode": 249,
    "countryiso3CharCode": "SDN",
    "countryiso2CharCode": "SD",
    "currency": {
      "description": "Sudanese Dinar",
      "code": "SDD"
    }
  },
  {
    "countryName": "Suriname",
    "countryDialingCode": 597,
    "countryiso3CharCode": "SUR",
    "countryiso2CharCode": "SR",
    "currency": {
      "description": "SRG",
      "code": "SRG"
    }
  },
  {
    "countryName": "Svalbard and Jan Mayen",
    "countryDialingCode": 47,
    "countryiso3CharCode": "SVD",
    "countryiso2CharCode": "SJ",
    "currency": {
      "description": "Norwegian Krone",
      "code": "NOK"
    }
  },
  {
    "countryName": "Swaziland",
    "countryDialingCode": 268,
    "countryiso3CharCode": "SWZ",
    "countryiso2CharCode": "SZ",
    "currency": {
      "description": "Swaziland Lilangeni",
      "code": "SZL"
    }
  },
  {
    "countryName": "Sweden",
    "countryDialingCode": 46,
    "countryiso3CharCode": "SWE",
    "countryiso2CharCode": "SE",
    "currency": {
      "description": "Swedish Krona",
      "code": "SEK"
    }
  },
  {
    "countryName": "Switzerland",
    "countryDialingCode": 41,
    "countryiso3CharCode": "CHE",
    "countryiso2CharCode": "CH",
    "currency": {
      "description": "Swiss Franc",
      "code": "CHF"
    }
  },
  {
    "countryName": "Syria",
    "countryDialingCode": 963,
    "countryiso3CharCode": "SYR",
    "countryiso2CharCode": "SY",
    "currency": {
      "description": "Syrian Pound",
      "code": "SYP"
    }
  },
  {
    "countryName": "Taiwan",
    "countryDialingCode": 886,
    "countryiso3CharCode": "TWN",
    "countryiso2CharCode": "TW",
    "currency": {
      "description": "Taiwanese Dollar",
      "code": "TWD"
    }
  },
  {
    "countryName": "Tajikistan",
    "countryDialingCode": 992,
    "countryiso3CharCode": "TJK",
    "countryiso2CharCode": "TJ",
    "currency": {
      "description": "Tajikistani Somoni",
      "code": "TJS"
    }
  },
  {
    "countryName": "Tanzania",
    "countryDialingCode": 255,
    "countryiso3CharCode": "TZA",
    "countryiso2CharCode": "TZ",
    "currency": {
      "description": "Tanzanian Schilling",
      "code": "TZS"
    }
  },
  {
    "countryName": "Thailand",
    "countryDialingCode": 66,
    "countryiso3CharCode": "THA",
    "countryiso2CharCode": "TH",
    "currency": {
      "description": "Thai Baht",
      "code": "THB"
    }
  },
  {
    "countryName": "Togo",
    "countryDialingCode": 228,
    "countryiso3CharCode": "TGO",
    "countryiso2CharCode": "TG",
    "currency": {
      "description": "CFA Franc BCEAO",
      "code": "XOF"
    }
  },
  {
    "countryName": "Tokelau",
    "countryDialingCode": 690,
    "countryiso3CharCode": "TKL",
    "countryiso2CharCode": "TK",
    "currency": {
      "description": "New Zealand Dollar",
      "code": "NZD"
    }
  },
  {
    "countryName": "Tonga",
    "countryDialingCode": 676,
    "countryiso3CharCode": "TON",
    "countryiso2CharCode": "TO",
    "currency": {
      "description": "Tongan Pa'anga",
      "code": "TOP"
    }
  },
  {
    "countryName": "Trinidad and Tobago",
    "countryDialingCode": 868,
    "countryiso3CharCode": "TTO",
    "countryiso2CharCode": "TT",
    "currency": {
      "description": "Trinidad and Tobago Dollar",
      "code": "TTD"
    }
  },
  {
    "countryName": "Tunisia",
    "countryDialingCode": 216,
    "countryiso3CharCode": "TUN",
    "countryiso2CharCode": "TN",
    "currency": {
      "description": "Tunisian Dinar",
      "code": "TND"
    }
  },
  {
    "countryName": "Turkey",
    "countryDialingCode": 90,
    "countryiso3CharCode": "TUR",
    "countryiso2CharCode": "TR",
    "currency": {
      "description": "Turkish Lira",
      "code": "TRY"
    }
  },
  {
    "countryName": "Turkmenistan",
    "countryDialingCode": 993,
    "countryiso3CharCode": "TKM",
    "countryiso2CharCode": "TM",
    "currency": {
      "description": "Turkmen Manat",
      "code": "TMM"
    }
  },
  {
    "countryName": "Turks and Caicos Islands",
    "countryDialingCode": 1649,
    "countryiso3CharCode": "TCA",
    "countryiso2CharCode": "TC",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Tuvalu",
    "countryDialingCode": 688,
    "countryiso3CharCode": "TUV",
    "countryiso2CharCode": "TV",
    "currency": {
      "description": "Australian Dollar",
      "code": "AUD"
    }
  },
  {
    "countryName": "Uganda",
    "countryDialingCode": 256,
    "countryiso3CharCode": "UGA",
    "countryiso2CharCode": "UG",
    "currency": {
      "description": "Uganda Shilling",
      "code": "UGX"
    }
  },
  {
    "countryName": "Ukraine",
    "countryDialingCode": 380,
    "countryiso3CharCode": "UKR",
    "countryiso2CharCode": "UA",
    "currency": {
      "description": "Ukraine Hryvnia",
      "code": "UAH"
    }
  },
  {
    "countryName": "United Arab Emirates",
    "countryDialingCode": 971,
    "countryiso3CharCode": "ARE",
    "countryiso2CharCode": "AE",
    "currency": {
      "description": "United Arab Emirates Dirham",
      "code": "AED"
    }
  },
  {
    "countryName": "United Kingdom",
    "countryDialingCode": 44,
    "countryiso3CharCode": "GBR",
    "countryiso2CharCode": "GB",
    "currency": {
      "description": "British Pound",
      "code": "GBP"
    }
  },
  {
    "countryName": "United States",
    "countryDialingCode": 1,
    "countryiso3CharCode": "USA",
    "countryiso2CharCode": "US",
    "currency": {
      "description": "US Dollar",
      "code": "USD"
    }
  },
  {
    "countryName": "Uruguay",
    "countryDialingCode": 598,
    "countryiso3CharCode": "URY",
    "countryiso2CharCode": "UY",
    "currency": {
      "description": "Peso Uruguayo",
      "code": "UYU"
    }
  },
  {
    "countryName": "Uzbekistan",
    "countryDialingCode": 998,
    "countryiso3CharCode": "UZB",
    "countryiso2CharCode": "UZ",
    "currency": {
      "description": "Uzbekistan Sum",
      "code": "UZS"
    }
  },
  {
    "countryName": "Vanuatu",
    "countryDialingCode": 678,
    "countryiso3CharCode": "VUT",
    "countryiso2CharCode": "VU",
    "currency": {
      "description": "Vanuatu Vatu",
      "code": "VUV"
    }
  },
  {
    "countryName": "Vatican City",
    "countryDialingCode": 379,
    "countryiso3CharCode": "VAT",
    "countryiso2CharCode": "VA",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  },
  {
    "countryName": "Venezuela",
    "countryDialingCode": 58,
    "countryiso3CharCode": "VEN",
    "countryiso2CharCode": "VE",
    "currency": {
      "description": "Venezuelan Bolívar Soberano",
      "code": "VES"
    }
  },
  {
    "countryName": "Vietnam",
    "countryDialingCode": 84,
    "countryiso3CharCode": "VNM",
    "countryiso2CharCode": "VN",
    "currency": {
      "description": "Vietnamese Dong",
      "code": "VND"
    }
  },
  {
    "countryName": "U.S. Virgin Islands",
    "countryDialingCode": 340,
    "countryiso3CharCode": "VIR",
    "countryiso2CharCode": "VI"
  },
  {
    "countryName": "Wallis and Futuna",
    "countryDialingCode": 681,
    "countryiso3CharCode": "WLF",
    "countryiso2CharCode": "WF",
    "currency": {
      "description": "Polynesian Franc",
      "code": "XPF"
    }
  },
  {
    "countryName": "Palestinian Territory, Occupied",
    "countryDialingCode": 970,
    "countryiso3CharCode": "WBN",
    "countryiso2CharCode": "PS",
    "currency": {
      "description": "Israeli New Shekel",
      "code": "ILS"
    }
  },
  {
    "countryName": "Western Sahara",
    "countryDialingCode": 125,
    "countryiso3CharCode": "ESH",
    "countryiso2CharCode": "EH",
    "currency": {
      "description": "Moroccan Dirham",
      "code": "MAD"
    }
  },
  {
    "countryName": "Western Samoa",
    "countryDialingCode": 685,
    "countryiso3CharCode": "ZZZ",
    "countryiso2CharCode": "ZZ",
    "currency": {
      "description": "Samoan Tala",
      "code": "WST"
    }
  },
  {
    "countryName": "Yemen",
    "countryDialingCode": 967,
    "countryiso3CharCode": "YEM",
    "countryiso2CharCode": "YE",
    "currency": {
      "description": "Yemeni Rial",
      "code": "YER"
    }
  },
  {
    "countryName": "Yugoslavia",
    "countryDialingCode": 38,
    "countryiso3CharCode": "YUG",
    "countryiso2CharCode": "YU",
    "currency": {
      "description": "Yugoslav Dinar",
      "code": "YUM"
    }
  },
  {
    "countryName": "Zambia",
    "countryDialingCode": 260,
    "countryiso3CharCode": "ZMB",
    "countryiso2CharCode": "ZM",
    "currency": {
      "description": "Zambian Kwacha",
      "code": "ZMK"
    }
  },
  {
    "countryName": "Zimbabwe",
    "countryDialingCode": 263,
    "countryiso3CharCode": "ZWE",
    "countryiso2CharCode": "ZW",
    "currency": {
      "description": "Zimbabwe Dollar",
      "code": "ZWD"
    }
  },
  {
    "countryName": "Aland Islands",
    "countryDialingCode": 358,
    "countryiso3CharCode": "ALA",
    "countryiso2CharCode": "AX"
  },
  {
    "countryName": "Bonaire, St. Eustatius and Saba",
    "countryDialingCode": 5993,
    "countryiso3CharCode": "BES",
    "countryiso2CharCode": "BQ"
  },
  {
    "countryName": "Curacao",
    "countryDialingCode": 599,
    "countryiso3CharCode": "CUW",
    "countryiso2CharCode": "CW"
  },
  {
    "countryName": "Saint Maarten",
    "countryDialingCode": 721,
    "countryiso3CharCode": "SXM",
    "countryiso2CharCode": "SX"
  },
  {
    "countryName": "South Sudan",
    "countryDialingCode": 211,
    "countryiso3CharCode": "SSD",
    "countryiso2CharCode": "SS"
  },
  {
    "countryName": "St. Barthelemy",
    "countryDialingCode": 590,
    "countryiso3CharCode": "BLM",
    "countryiso2CharCode": "BL"
  },
  {
    "countryName": "St. Martin",
    "countryDialingCode": 721,
    "countryiso3CharCode": "MAF",
    "countryiso2CharCode": "MF"
  },
  {
    "countryName": "Timor-Leste",
    "countryDialingCode": 670,
    "countryiso3CharCode": "TLS",
    "countryiso2CharCode": "TL"
  },
  {
    "countryName": "Republic of Kosovo",
    "countryDialingCode": 381,
    "countryiso3CharCode": "UNK",
    "countryiso2CharCode": "XK",
    "currency": {
      "description": "Euro",
      "code": "EUR"
    }
  }
]