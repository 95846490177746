import { Component, OnInit, ViewChild, Input, HostListener } from '@angular/core';
import { MatSnackBar, MatTableDataSource, PageEvent, SortDirection, MatSort, MatPaginator, MatDialog, MatDialogRef } from '@angular/material';
import { ProgramModalComponent } from '../program-modal/program-modal.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ProgramManagementService, Benefit } from '../../services/program-management.service';
import { DetailComponent, Program } from '../../modules/program/detail/detail.component';
import { AdminClient } from './../../../../core/models/admin-client.model';
import { ToastrService } from 'ngx-toastr';
import pdfMake from 'pdfmake/build/pdfmake';
import { ProgramReportGenerationService } from './../../services/program-report-generation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserTypeService, UserType } from '../../services/user-type.service';
import { DivisionAssociationComponent } from './../../components/division-association/division-association.component';
import { FilterProgramModalComponent } from './../../components/filter-program-modal/filter-program-modal.component';
import * as moment from 'moment';
import { AdminDataviewSelectionModalComponent } from '../admin-dataview-selection-modal/admin-dataview-selection-modal.component';
import * as programColumns from '../../../../core/data/programDataView.json';
import * as transfereeColumnDisplay from '../../../../core/data/transfereeColumnDisplay.json';
import { DeactivateProgramModalComponent } from './../../components/deactivate-program-modal/deactivate-program-modal.component';
import { SelectionModel } from '@angular/cdk/collections';
import { CopyProgramModalComponent } from '../copy-program-modal/copy-program-modal.component';
import { ClientService } from '../../../administration/services/client.service';

export interface GetProgramOptions {
  sortBy?: string;
  searchProperties?: any[];
  sortDirection?: string;
  searchText?: string;
  filterObj?:any;
  itemsPerPage?: number,
  pageNumber?:number
}
export const CategoryMapping = {
  'Before You Move': 'Before Move',
  'Shipping': 'Shipping',
  'Travel and Transport': 'Travel & Transport',
  'After You Arrive': 'After Arrival',
  'Relocation Allowances': 'Allowance'
};

/**Quick access keyboard code for table next, last and escape*/
export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}
@Component({
  selector: 'app-program-list',
  templateUrl: './program-list.component.html',
  styleUrls: ['./program-list.component.scss']
})
export class ProgramListComponent implements OnInit {
  /** displayedColumns variable array to store the displayed columns */
  displayedColumns: string[] = [];
  displayedColumnsForCCI: string[] = [];
  columnsToShow: string[] = [];
  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<any>;
  sortOptions: any = {};

  // tslint:disable-next-line: no-inferrable-types
  programLength: number = 0;

  divisionList: Array<any> = [];

  /** page event variable to handle the page events */
  pageEvent: PageEvent;

  /**Direction varialbe to get sort direction */
  direction: SortDirection;

  @Input() clientId: string;

  /** To display client details in download report*/
  @Input() clientDetails: AdminClient;

  /** Is Detail View */
  detailView: boolean;

  selectedProgram: any;
  benefitsList: Array<{
    group: string,
    items: Array<Benefit>
  }>;

  searchValue: string;
  filterText: string;

  /** Contains the Filter Option for the program Tab */
  programFilterOptions: GetProgramOptions = {};

  divisionSelection: MatDialogRef<DivisionAssociationComponent>;

  //  dataviewSelection to access the dialog
  dataviewSelection: MatDialogRef<AdminDataviewSelectionModalComponent>;

  programListDataView: string[] = ['ProgramListViewColumnDisplay'];

  /** To sort the mat table columns */
  @ViewChild(MatSort, {
    static: false
  }) sort: MatSort;

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, {
    static: false
  }) paginator: MatPaginator;
  
  @ViewChild(DetailComponent, {
    static: false
  }) detailComponent: DetailComponent;

  /** sortDirection to hold the asc or desc direction value */
  sortDirection: 'ASC' | 'DESC' | null;

  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: 'clientEntityName' | 'location' | 'status' | null;

  readonly BenefitCategories = ['Before You Move', 'Shipping', 'Travel and Transport', 'After You Arrive', 'Relocation Allowances'];

  /** Stores data to be rendered in view Program page */
  selectedProgramDetail: Program = {} as Program;

  /** user role */
  userRole: UserType;

  /** filterprogramDialogRef to access the Dialog */
  filterDialogRef: MatDialogRef<FilterProgramModalComponent>;
  /** selection variable to store the collection of selections made in mat table */
  selection: SelectionModel<any>;

  /** warning messages */
  maximumSelectedWarningMessage = 'Maximum 10 records are allowed to deactivate at a time.';
  invalidProgramForDeactivationMessage = 'Only those Program with status Active and Draft can be deactivated.';
  invalidProgramForCopyMessage = 'Only those Program with status Active and Draft can be copied.';

  /**transferee list column details */
  programColumnsList: any = (programColumns as any).default;

  /**transferee column display details */
  columnsDisplay: any = (transfereeColumnDisplay as any).default;

  /** to add search properties of program list */
  colDisplay: any[];

  /** FilterProgram flags */
  selectedStatus: boolean[] = [false, false, false];
  expirationStartDate: Date = null;
  expirationEndDate: Date = null;
  policyChecked: boolean = false;
  isCartusInsuredChecked: boolean = false;
  addPolicyToFilter: boolean = false;
  isCartusInsuredFilter: boolean = false;

  /** To get the current filter status */
  filterObj = {
    filterObj: {}
  };

  constructor(
    public dialog: MatDialog,
    private readonly programService: ProgramManagementService,
    private toastrService: ToastrService,
    private readonly pdfgenerator: ProgramReportGenerationService,
    private spinner: NgxSpinnerService,
    private userTypeService: UserTypeService,
    private readonly toastr: MatSnackBar,
    private readonly clientService: ClientService
  ) {
    this.dataSource = new MatTableDataSource<any>([]);
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 10;
    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    this.colDisplay = [];
  }

  ngOnInit() {
    this.userRole = this.userTypeService.userType;
    this.clientService.tabIndex$.subscribe(data => {
      if(data) {
        this.programService.getProgramDetail(this.clientId, data).subscribe(data => {
        this.selectedProgram = data.programs[0]; 
        this.viewProgram(this.selectedProgram); });
      }
    });
    //this.columnsToShow = (this.userRole === 'client-contact-initiator') ? this.displayedColumnsForCCI : this.displayedColumns ;
    this.userRole === 'program-admin' || this.userRole === 'client-account-manager' ? this.programListDataView = ['ProgramListViewColumnDisplayforProgramAdmn'] : null;
    this.userRole === 'client-contact-initiator' ? this.programListDataView = ['ProgramListViewColumnDisplayForCCi'] : null;
    if (this.displayedColumns.length === 0) {
      const colConfig = this.columnsDisplay.filter((item) => item.preferencesetting.name == this.programListDataView[0]);
      this.displayedColumns = colConfig[0].preferencesetting.value;
    }
    this.columnsToShow = this.displayedColumns;
    this.programColumnsList.forEach((item) => {
      if (this.displayedColumns.includes(item.field) && item.filter) {
        this.colDisplay = this.colDisplay.concat(item.filter);
      }
    });
    this.selection = new SelectionModel<any>(true, []);
    if (this.userRole === 'program-admin' && !this.displayedColumns.includes('select')) {
      this.displayedColumns.unshift('select');
    }
    if (this.displayedColumns.includes('status') && !(this.userRole === 'program-admin')) {
      this.filterObj.filterObj['selectedStatus'] = ['active'];
      this.selectedStatus[0] = true;
      this.getFilteredProgram(this.clientId, this.filterObj);
    } else {
      this.getProgramsList(this.clientId);
    }
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.paginator.length = this.programLength;
      this.paginator.pageIndex = this.pageEvent.pageIndex;
      this.paginator.pageSize = this.pageEvent.pageSize;
      const paginatorIntl = this.paginator._intl;
      paginatorIntl.nextPageLabel = 'Next';
      paginatorIntl.previousPageLabel = 'Previous';
    }, 10);
  }

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    // tslint:disable-next-line: deprecation
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.paginator.nextPage();
    }
    // tslint:disable-next-line: deprecation
    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.paginator.previousPage();
    }
  }
  /**
   * To add new program
   */
  addNewProgram() {
    const dialogRef = this.dialog.open(ProgramModalComponent, {
      panelClass: 'dialog-full-width',
      data: {
        clientId: this.clientId
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.detailView = true;
        this.selectedProgram = data;
      }
    });
  }
  /**
   * On closing a program
   */
  closeProgram() {
    this.detailView = false;
    this.selectedProgram = null;
    if (Object.keys(this.filterObj.filterObj).length) {
      this.getFilteredProgram(this.clientId, this.filterObj);
    } else {
      this.getProgramsList(this.clientId);
    }
  }

  openDownloadToastr(element) {
    // tslint:disable-next-line: max-line-length
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toastr.open(message, 'DOWNLOAD', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    });
    toaster.onAction().subscribe(_ => this.loadProgramBenefits(element));
  }

  /**
   * To load Program benefits
   * @param program Program Details
   */
  loadProgramBenefits(program) {
    this.spinner.show();
    this.programService.getProgramBenefits(this.clientId, program.name, true).subscribe(data => {
      if (data) {
        const guaranteedBenefits = data.benefits.filter(benefit => benefit.points.toString() === 'Guaranteed');
        data.benefits = guaranteedBenefits.concat(data.benefits.filter(benefit => benefit.points.toString() !== 'Guaranteed'));
        const benefitsList = this.groupBenefits(data.benefits).map(category => {
          return {
            group: category.group,
            items: category.items.filter(item => !item.suppress),
            points: category.items.filter(benefit => benefit.points.toString() === 'Guaranteed').
              concat(category.items.filter(benefit => benefit.points.toString() !== 'Guaranteed'))
          };
        }).filter(category => category.items.length !== 0);

        pdfMake.createPdf(this.pdfgenerator.generateDocumentDefinition(program, program.divisions,
          benefitsList, this.clientDetails)).download(program.name);
        this.spinner.hide();
      } else {
        this.toastrService.error('Error fetching Benefits', 'Error');
        this.spinner.hide();
      }
    }, () => {
      this.toastrService.error('Error fetching Benefits', 'Error');
      this.spinner.hide();
    }
    );
  }

  /**
   * Group benefits by category
   * @param benefits benefit details
   */
  groupBenefits(benefits: Array<Benefit>): Array<{
    group: string,
    items: Array<Benefit>
  }> {
    benefits = this.updateCategoryNames(benefits);
    const benefitsByGroup = Object.values(CategoryMapping).map(category => {
      return {
        group: category,
        items: benefits.filter(benefit => benefit.category === category)
      };
    });
    return benefitsByGroup;
  }
  /**
   * Update category for benefits
   * @param benefits Benefits
   */
  updateCategoryNames(benefits: Array<Benefit>) {
    const benefitCategories = [...this.BenefitCategories.map(i => i.toLowerCase())];
    const categoryMapping = {};
    Object.keys(CategoryMapping).map(i => {
      categoryMapping[i.toLowerCase()] = CategoryMapping[i];
    });
    return benefits.map(benefit => {
      if ((benefitCategories).includes(benefit.category.toLowerCase())) {
        benefit.category = categoryMapping[benefit.category.toLocaleLowerCase()];
      }
      return benefit;
    });
    /* return benefits.map(benefit => {
      if (this.BenefitCategories.includes(benefit.category)) {
        benefit.category = CategoryMapping[benefit.category];
      }
      return benefit;
    }); */
  }
  /**
   * Search for Programs
   * @param searchText Search Text Value
   */
  textSearchPrograms(searchText: string) {
    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      this.searchValue = searchText;
      this.filterText = this.searchValue;
      const newOptions = Object.assign(this.programFilterOptions, {
        searchText
      });
      this.programFilterOptions = newOptions;
      this.programFilterOptions.searchProperties = this.colDisplay;
      this.programFilterOptions['itemsPerPage'] = this.paginator.pageSize;
      this.programFilterOptions['pageNumber'] = 0;
      if (Object.keys(this.filterObj.filterObj).length) {
        this.getFilteredProgram(this.clientId, this.filterObj,this.programFilterOptions);
      } else {
        this.getProgramsList(this.clientId, this.programFilterOptions);
      }
    }
  }

  textSearch(event) {
    if (event.key === 'Backspace' && event.target.value.length === 0) {
      this.textSearchPrograms(event.target.value);
    }
  }

  /**
   * Gets the List of Client Contacts;
   * @param clientId client id  selected by user
   */
  getProgramsList(clientId?: string, options: GetProgramOptions = null) {
    if (!options) {
      options = {
        itemsPerPage: 10,
        pageNumber: 0
      };
    } else {
      options.hasOwnProperty('itemsPerPage') ? null : options['itemsPerPage'] = this.paginator.pageSize;
      options.hasOwnProperty('pageNumber') ? null : options['pageNumber'] = this.paginator.pageIndex;

      if (this.sortOptions && this.sortOptions.hasOwnProperty('sortColumnName') && this.sortOptions.hasOwnProperty('sortDirection')) {
        options['sortBy'] = this.sortOptions.sortColumnName;
        options['sortDirection'] = this.sortOptions.sortDirection;
      }
    }
    if (options && !options.searchText && this.searchValue) {
      options.searchText = this.searchValue;
      options['searchProperties'] = this.colDisplay;
    }
      
    this.programService.getProgramsList(clientId || this.clientId, options).subscribe(data => {
      if (data && data.programs) {
        this.dataSource.data = data.programs;
        const dataSourceWithStatusFlag = this.dataSource.data.map(ele => {
          if (ele.hasOwnProperty('programExpirationDate')) {
            const trimmedDate = ele.programExpirationDate.split('T')[0];
            ele.programExpirationDate = trimmedDate;
            if (moment(moment().format('MM/DD/YYYY')).isBefore(moment(ele.programExpirationDate.split('T')[0]).format('MM/DD/YYYY'))) {
              if (ele.draft) {
                return ({ ...ele, status: 'Draft' });
              }
              return ({ ...ele, status: 'Active' });
            }
            if (moment(moment().format('MM/DD/YYYY')).isSameOrAfter(moment(ele.programExpirationDate.split('T')[0]).format('MM/DD/YYYY'))) {
              return ({ ...ele, status: 'Deactivated' });
            }
          }
          else {
            if (ele.draft) {
              return ({ ...ele, status: 'Draft' });
            }
            return ({ ...ele, status: 'Active' });
          }
        });
        this.dataSource.data = [...dataSourceWithStatusFlag];
        this.selection.clear();
        this.programLength = data.programs.length;
        this.divisionList = data.divisionList;
        // tslint:disable-next-line: no-unused-expression
        this.paginator.pageSize = options.itemsPerPage;
        this.paginator.pageIndex = options.pageNumber;
        let pageIndex = options.pageNumber;
        this.dataSource.paginator = this.paginator;
        this.paginator ? setTimeout(() => {
          this.paginator.pageIndex = pageIndex;
          this.paginator.length = this.programLength;
        }) : null;
        this.selection.clear();
      } else {
        this.toastrService.error('Error retreiving programs list', 'Error');
      }
    }, () => {
      this.toastrService.error('Error fetching Benefits', 'Error');
      this.spinner.hide();
    });
  }

  /**
   * Clear Text search
   */
  clearTextSearch() {
    this.searchValue = '';
    if (Object.keys(this.filterObj.filterObj).length) {
      this.getFilteredProgram(this.clientId, this.filterObj);
    } else {
      this.getProgramsList(this.clientId);
    }
    this.filterText = this.searchValue;
  }

  /**
   * Init Sort ..
   * @param columnName Column name to be sorted.
   */

  initSort(columnName: string): void {
    if (this.sortColumnName === columnName) {
      if (this.sortDirection === 'ASC') {
        this.sortDirection = 'DESC';
      } else {
        this.sortDirection = 'ASC';
      }
    } else {
      this.sortColumnName = columnName as any;
      this.sortDirection = 'ASC';
    }
    this.sortOptions['sortColumnName'] = this.sortColumnName;
    this.sortOptions['sortDirection'] = this.sortDirection;
    this.programFilterOptions = {
      sortBy: this.sortColumnName,
      searchProperties: this.colDisplay,
      sortDirection: this.sortDirection
    };
    if (Object.keys(this.filterObj.filterObj).length) {
      this.getFilteredProgram(this.clientId, this.filterObj, {
        itemsPerPage: this.paginator.pageSize,
        pageNumber: this.paginator.pageIndex
      });
    } else {
      this.getProgramsList(this.clientId, {
        itemsPerPage: this.paginator.pageSize,
        pageNumber: this.paginator.pageIndex
      });
    }
  }

  /**
   * To display program details
   * @param program selected program information
   */
  viewProgram(program) {
    this.selectedProgramDetail['programName'] = program.name;
    this.selectedProgramDetail['selectedClientId'] = this.clientId;
    this.selectedProgramDetail['atlasPolicy'] = program.clientPolicy;
    this.selectedProgramDetail['contractSeries'] = program.contractId;
    this.selectedProgramDetail['totalPoints'] = program.totalPoints;
    program.baseProgramName ? this.selectedProgramDetail['templateName'] = program.baseProgramName : null;
    this.selectedProgramDetail['isNew'] = false;
    this.selectedProgramDetail['divisions'] = program.divisions;
    this.selectedProgramDetail['id'] = program.id;
    this.selectedProgramDetail['programExpirationDate'] = program.programExpirationDate;
    program.programActivationDate ? this.selectedProgramDetail['programActivationDate'] = program.programActivationDate : this.selectedProgramDetail['programActivationDate'] = '';
    this.selectedProgramDetail['policyCallRequired'] = program.policyCallRequired;
    this.selectedProgramDetail['draft'] = program.draft ? true : false;
    this.selectedProgramDetail['isCartusInsured'] = program.isCartusInsured,
      this.selectedProgram = this.selectedProgramDetail;
    this.detailView = true;
    this.detailComponent ? this.detailComponent.ngOnInit() : null;
  }

  assignDivision(selectedProg?: any) {
    this.divisionSelection = this.dialog.open(DivisionAssociationComponent, {
      data: {
        clientId: this.clientId,
        program: selectedProg
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.divisionSelection.afterClosed().subscribe((publishDivision: any) => {
      if (publishDivision) {
        if (Object.keys(this.filterObj.filterObj).length) {
          this.getFilteredProgram(this.clientId, this.filterObj, {
            itemsPerPage: this.paginator.pageSize,
            pageNumber: this.paginator.pageIndex
          });
        } else {
          this.getProgramsList(this.clientId,{
                itemsPerPage: this.paginator.pageSize,
                pageNumber: this.paginator.pageIndex
              });
        }
      }
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** filterProgramModal method to open the Filter program modal popup */
  filterProgramModal(): void {
    this.filterDialogRef = this.dialog.open(FilterProgramModalComponent, {
      data: {
        dataKey: this.displayedColumns,
        selectedStatus: [...this.selectedStatus],
        expirationStartDate: this.expirationStartDate,
        expirationEndDate:this.expirationEndDate,
        policyChecked:this.policyChecked,
        isCartusInsuredChecked:this.isCartusInsuredChecked,
        addPolicyToFilterFlag: this.addPolicyToFilter,
        addIsCartusInsuredFilterFlag: this.isCartusInsuredFilter,
      },
      panelClass: 'makeItMiddle',
      autoFocus: false,
      restoreFocus: false,
    });
    this.filterDialogRef.afterClosed().subscribe(data => {
      if (data) {
        if (data.reset) {
          this.getProgramsList(this.clientId);
          this.selectedStatus = [false, false, false];
          this.expirationStartDate = null;
          this.expirationEndDate = null;
          this.policyChecked = false;
          this.isCartusInsuredChecked = false;
          this.addPolicyToFilter = false;
          this.isCartusInsuredFilter = false;
          this.filterObj.filterObj = {};
        } else {
          this.filterObj.filterObj = {};
          if (data && data.selectedStatus) {
            if (data.selectedStatus.includes('draft')) {
              data.selectedStatus = data.selectedStatus.filter(ele => !(ele === 'draft'));
              this.filterObj.filterObj['draft'] = true;
            }
            data.selectedStatus = [...data.selectedStatus.filter(ele => ele !== null)];
          }
          if (data && data.selectedStatus && data.selectedStatus.length > 0) {
            this.filterObj.filterObj['selectedStatus'] = [...data.selectedStatus.filter(ele => ele !== null)];
          }
          if (data && (data.date.expirationStartDate || data.date.expirationEndDate)) {
            if (data.date.expirationStartDate) {
              data.date.expirationStartDate ? this.filterObj.filterObj['fromDate'] = new Date(data.date.expirationStartDate).toISOString().split('T')[0].concat('T00:00:00.000Z') : this.filterObj.filterObj['fromDate'] = '';
            }
            if (data.date.expirationEndDate) {
              data.date.expirationEndDate ? this.filterObj.filterObj['toDate'] = new Date(data.date.expirationEndDate).toISOString().split('T')[0].concat('T00:00:00.000Z') : this.filterObj.filterObj['toDate'] = '';
            }
          }
        if (data && data.addPolicyToFilterFlag) {
          this.filterObj.filterObj['policyCallRequired'] = data.policyChecked;
        }

        if (data && (data.addIsCartusInsuredFilterFlag)) {
          this.filterObj.filterObj['isCartusInsured'] = data.isCartusInsuredChecked;
        }
        this.paginator.pageIndex = 0;
        this.getFilteredProgram(this.clientId, this.filterObj, Object.assign({},{itemsPerPage:this.paginator.pageSize,pageNumber:0}));
        this.selectedStatus = [...data.selectedStatusFlags];
        this.expirationStartDate = data.expirationStartDateFlag; 
        this.expirationEndDate = data.expirationEndDateFlag;
          this.policyChecked = data.policyCheckedFlag;
          this.isCartusInsuredChecked = data.isCartusInsuredCheckedFlag;
          this.addPolicyToFilter = data.addPolicyToFilterFlag;
          this.isCartusInsuredFilter = data.addIsCartusInsuredFilterFlag;
        }
      }
    });
  }

  getFilteredProgram(clientId: any, filterObj: any,options?:any) {
    if (!options) {
      options = {
        itemsPerPage: 10,
        pageNumber: 0
      };
      if (this.sortOptions && this.sortOptions.hasOwnProperty('sortColumnName') && this.sortOptions.hasOwnProperty('sortDirection')) {
        options['sortBy'] = this.sortOptions.sortColumnName;
        options['sortDirection'] = this.sortOptions.sortDirection;
      }
    } else {
      options.hasOwnProperty('itemsPerPage') ? null : options['itemsPerPage'] = this.paginator.pageSize;
      options.hasOwnProperty('pageNumber') ? null : options['pageNumber'] = this.paginator.pageIndex;

      if (this.sortOptions && this.sortOptions.hasOwnProperty('sortColumnName') && this.sortOptions.hasOwnProperty('sortDirection')) {
        options['sortBy'] = this.sortOptions.sortColumnName;
        options['sortDirection'] = this.sortOptions.sortDirection;
      }
    }
    if (options && !options.searchText && this.searchValue) {
      options.searchText = this.searchValue;
      options['searchProperties'] = this.colDisplay;
    }
    this.programService.filterProgram(this.clientId, filterObj,options).subscribe(data => {
      if (data && data.programs) {
        this.dataSource.data = data.programs;
        const dataSourceWithStatusFlag = this.dataSource.data.map(ele => {
          if (ele.hasOwnProperty('programExpirationDate')) {
            const trimmedDate = ele.programExpirationDate.split('T')[0];
            ele.programExpirationDate = trimmedDate;
            if (moment(moment().format('MM/DD/YYYY')).isBefore(moment(ele.programExpirationDate.split('T')[0]).format('MM/DD/YYYY'))) {
              if (ele.draft) {
                return ({ ...ele, status: 'Draft' });
              }
              return ({ ...ele, status: 'Active' });
            }
            if (moment(moment().format('MM/DD/YYYY')).isSameOrAfter(moment(ele.programExpirationDate.split('T')[0]).format('MM/DD/YYYY'))) {
              return ({ ...ele, status: 'Deactivated' });
            }
          }
          else {
            if (ele.draft) {
              return ({ ...ele, status: 'Draft' });
            }
            return ({ ...ele, status: 'Active' });
          }
        });
        this.dataSource.data = [...dataSourceWithStatusFlag];
        this.programLength = data.programs.length;
        this.divisionList = data.divisionList;
      
        setTimeout(() => {
          this.dataSource.paginator = this.paginator;
          this.paginator.length = this.programLength;
          this.paginator.pageIndex = this.pageEvent.pageIndex;
          this.paginator.pageSize = this.pageEvent.pageSize;
          const paginatorIntl = this.paginator._intl;
          paginatorIntl.nextPageLabel = 'Next';
          paginatorIntl.previousPageLabel = 'Previous';
        }, 10);


        
      } else {
        this.toastrService.error('Error retreiving programs list', 'Error');
      }
    }, () => {
      this.toastrService.error('Error fetching Benefits', 'Error');
      this.spinner.hide();
    });
  }

  /**
    * To display informative messages on screen
    * @param message message to be displayed
    */
  displayToastr(message) {
    this.toastrService.info(message, null, {
      closeButton: true,
      enableHtml: true,
      disableTimeOut: false,
    });
  }


  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /**
   * To copy selected program from program list
   * @param selectedProgram Selected program from program list
   */
  copyProgram(selectedProgram) {
    if (selectedProgram[0].status === 'Deactivated') {
      this.displayToastr(this.invalidProgramForCopyMessage);
    } else {
      const dialogRef = this.dialog.open(CopyProgramModalComponent, {
        panelClass: 'dialog-full-width',
        data: {
          programName: selectedProgram[0].name,
          clientName: this.clientDetails.clientEntityName,
          clientNumber: this.clientDetails.clientNumber
        }
      });

      dialogRef.afterClosed().subscribe(copyDetails => {
        if (copyDetails) {
          let onlyCopy = true;
          const copiedProgram = { copyProgramDetails: copyDetails };
          if (selectedProgram[0].status === 'Draft') {
            copiedProgram['draft'] = true;
            copiedProgram['benefits'] = [];
            onlyCopy = false;
          }
          this.programService.updateBenefitForProgram(copiedProgram, selectedProgram[0].id, true, onlyCopy).subscribe((data) => {
            if (data) {
              const toastmsg = 'Program Copied';
              this.toastrService.info(toastmsg, null, {
                progressBar: true,
                timeOut: 2000
              });
              this.spinner.hide();
              setTimeout(() => {
                this.closeProgram();
              }, 2000);
            } else {
              this.spinner.hide();
              const toastmsg = 'Error copying program, please try again';
              this.toastrService.error(toastmsg);
            }
          },
            () => {
              this.spinner.hide();
              const toastmsg = 'Error copying program, please try again';
              this.toastrService.error(toastmsg);
            });
        }
      });
    }


  }

  /**
  * To deactivate program
  */
  deactivateProgram() {
    let dialogRef;
    if (this.selection.selected.length > 10 || this.selection.selected.map(ele => ele.status).includes('Deactivated')) {
      if (this.selection.selected.map(ele => ele.status).includes('Deactivated')) {
        this.displayToastr(this.invalidProgramForDeactivationMessage);
      } else {
        this.displayToastr(this.maximumSelectedWarningMessage);
      }
    } else {
      dialogRef = this.dialog.open(DeactivateProgramModalComponent, {
        data: {
          programList: this.selection.selected.map(ele => ele.name),
          programIdList: this.selection.selected.map(ele => ele.id)
        },
        panelClass: 'DataviewSelectionModal',
        autoFocus: false,
        restoreFocus: false
      });
      dialogRef.afterClosed().subscribe((data: any) => {
        if (data.isSuccessful) {
          if (this.dataSource.data) {
            let updatedDeactivatedData = [...this.dataSource.data];
            data.deactivatedProgramData.programIdList.forEach(ele => {
              updatedDeactivatedData.forEach(curr => {
                if (curr.id === ele) {
                  curr.programExpirationDate = data.data;
                  if (moment(moment().format('MM/DD/YYYY')).isBefore(moment(data.data))) {
                    if (ele.draft) {
                      return ({ ...ele, status: 'Draft' });
                    }
                    return ({ ...ele, status: 'Active' });
                  }
                  if (moment(moment().format('MM/DD/YYYY')).isSameOrAfter(moment(data.data))) {
                    return ({ ...ele, status: 'Deactivated' });
                  }
                }
              })

            });
            this.dataSource.data = [...updatedDeactivatedData];
          }
          this.selection.clear();
          this.displayToastr("Deactivation successful");
        }
      });
    }
  }

  /** dataview selection method to open modal popup */
  dataViewSelectionModal() {
    this.dataviewSelection = this.dialog.open(AdminDataviewSelectionModalComponent, {
      data: {
        dataKey: this.programListDataView
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.dataviewSelection.afterClosed().subscribe(displayedColumn => {
      if (displayedColumn && displayedColumn.length > 0) {
        if (this.userRole === 'program-admin' && !displayedColumn.includes('select')) {
          displayedColumn.unshift('select');
        }
        this.colDisplay = [];
        this.displayedColumns = [...displayedColumn];
        this.columnsToShow = this.displayedColumns;
        this.programColumnsList.forEach((item) => {
          if (this.displayedColumns.includes(item.field) && item.filter) {
            this.colDisplay = this.colDisplay.concat(item.filter);
          }
        });
      }
    });
  }

  // onPageChange(e) {
  //   let options ={
  //     itemsPerPage: this.paginator.pageSize,
  //     pageNumber: this.paginator.pageIndex
  //   }
  //   if (Object.keys(this.filterObj.filterObj).length) {
  //     this.getFilteredProgram(this.clientId, this.filterObj,options );
  //   } else {
  //     this.getProgramsList(this.clientId,options);
  //   }
  // }
}
