import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AggregationFilter } from './../../../../core/models/aggregation-filter.model';
import * as empColumns from '../../../../core/data/empListDataView.json';
import * as financialColumns from '../../../../core/data/financialDataView.json';
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
@Component({
  selector: 'app-dataview-selection-modal',
  templateUrl: './dataview-selection-modal.component.html',
  styleUrls: ['./dataview-selection-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DataviewSelectionModalComponent implements OnInit {

  defaultColumn = [];
  optionsValue = [];
  current_selected: any;
  displayedColumns = [];
  columnOrder: any = [];
  selectedOptions: any;
  selectedColumnList: any;

  empColumnsList: any = (empColumns as any).default;
  financialColumnsList: any = (financialColumns as any).default;
  /** Base constructor method
    * @param dialogRef MatDialogRef<FilterModalComponent> injection

    */

  constructor(public dialogRef: MatDialogRef<DataviewSelectionModalComponent>,
    private readonly userPreferenceService: UserPreferencesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {

    /**Default table columns API  */
    if (this.data.dataKey == 'EmployeeListViewColumnDisplay') {
      this.selectedColumnList = this.empColumnsList;
    } else {
      this.selectedColumnList = this.financialColumnsList;
    }
    this.userPreferenceService.getPreference('user_config', false, false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name == this.data.dataKey) {
              this.defaultColumn = item.preferenceconfig.default;
              this.optionsValue = item.preferenceconfig.options;
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                this.displayedColumns = item.preferencesetting.value;
              } else {
                this.displayedColumns = item.preferenceconfig.default;
              }
            }
          });
          this.displayedColumns.forEach(item => {
            const index = this.selectedColumnList.findIndex(x => x.field == item);

            if (index > -1) {
              this.selectedColumnList[index].checked = true;
            }

          });
        }
      });
      this.defaultColumn.forEach((item) => {
       const column = this.selectedColumnList.filter((value) => {
          return value.field == item;
        });
        this.columnOrder.push({
          header: column[0].header,
          checked: column[0].checked,
          field: column[0].field
        })
      })

  }


  isDisabledColumns(columnName) {
    const defaultIndex = this.defaultColumn.findIndex(x => x == columnName);
    if (defaultIndex > -1) {
      const optionIndex = this.optionsValue.findIndex(x => x == columnName);
    if (optionIndex == -1) {
      return true;
    }
  }
return false;
  }

/** Onselection of check box */
  onSelection(e) {
    this.current_selected = e.option.value;
    // tslint:disable-next-line: triple-equals
    const colOrderindex = this.columnOrder.findIndex(x => x.field == e.option.value.field);
    const index = this.displayedColumns.indexOf(e.option.value.field);
    this.selectedColumnList.forEach((arrayItem) => {
      if (arrayItem.header == e.option.value.header) {
        arrayItem.checked = e.option.selected;
        if (e.option.selected == true) {
          if (index == -1) {
            this.displayedColumns.push(e.option.value.field);
            this.columnOrder[colOrderindex].checked = true;
          }
        } else {
          if (index !== -1) {
            this.displayedColumns.splice(index, 1);
            this.columnOrder[colOrderindex].checked = false;
          }

        }

      }
    });
  }

/** Secleting the table click on OK button columns */
  selectedListItems() {
    this.dialogRef.close(this.displayedColumns);
  }

  /*** Reset the table columns and display the default columns */

  reset() {
    this.columnOrder.forEach(item => { item.checked = true; });
    this.defaultColumn.forEach(item => {
      const index = this.columnOrder.findIndex(x => x.field == item);
      if (index > -1) {
        this.columnOrder[index].checked = true;
      }
    });
    this.selectedColumnList.forEach(item => {
      const index = this.columnOrder.findIndex(x => x.field == item);
      if (index > -1) {
        this.columnOrder[index].checked = true;
      }
    });
    this.displayedColumns = this.defaultColumn;
  }

  close() {
    this.dialogRef.close([]);
  }
}
