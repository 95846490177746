import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { PowerBiReportConfig } from '../../models/powerbi-report-config.model';
import { PowerBiVisualConfig } from '../../models/powerbi-visual-config.model';
import { MAT_DIALOG_DATA } from '@angular/material';

/** This component renders a Power BI report */
@Component({
  selector: 'app-powerbi-modal',
  templateUrl: './powerbi-modal.component.html',
  styleUrls: ['./powerbi-modal.component.scss']
})
export class PowerBiModalComponent implements OnInit {
    /** Power BI configuration */
    public config: PowerBiReportConfig | PowerBiVisualConfig;

    /** Base constructor method
    * @Inject MAT_DIALOG_DATA to get the data from the parent component
    * @param data to get the data through MAT_DIALOG_DATA
    */
    constructor (
        @Inject(MAT_DIALOG_DATA) public data: PowerBiReportConfig | PowerBiVisualConfig
    ) { }

    /** Component Angular initialization lifecycle hook */
    ngOnInit() {
      this.config = this.data;
    }
}
