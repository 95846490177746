<div [ngClass]="{'typeahead-checkbox-list' : filterOptions.enableSearch}">
  <div class="typeahead-block" *ngIf="filterOptions.enableSearch">
    <mat-checkbox fxHide *ngIf="filterOptions.type === 'policy'"></mat-checkbox>
    <mat-form-field appearance="outline">
      <input type="text" placeholder="{{ PlaceHolder }}" [(ngModel)]="searchText" (ngModelChange)="fetchResult()"
        matInput>
      <button tabindex="-1" style="max-width:30px;" class="close" mat-button matSuffix mat-icon-button
        aria-label="Clear" matTooltip="Clear" *ngIf="searchText" (click)="clearInputText()">
        <mat-icon>close</mat-icon>
      </button>
    </mat-form-field>
    <button tabindex="-1" mat-button matSuffix mat-icon-button aria-label="Search" matTooltip="Search" (click)="fetchResult()">
      <mat-icon>search</mat-icon>
    </button>
  </div>
  <div class="selection-list-box">

    <!-- Location Type Filter -->
    <ng-container *ngIf="filterOptions.type == 'locations'; else default">
      <div class="checkbox-list-section">
        <div class="sections-list" [attr.tabindex]="dataSet.length ? 0 : -1">
          <p class="no-data" *ngIf="!dataSet || dataSet.length == 0">Please Enter a City, State, or Country</p>
          <ng-container *ngIf="dataSet">
            <ng-container *ngFor="let data of dataSet">
              <p class="no-data" *ngIf="data.values.length == 0">Please Enter a City, State, or Country</p>
              <ng-container *ngIf="data.values && data.values.length > 0">
                <h4 id="{{data.label}}" matSubheader>{{data.label | titlecase }} : {{searchText | titlecase}}</h4>
                <mat-selection-list id="{{data.label}}SelectionList" #muliptleList [attr.aria-labelledby]="labelId"
                  tabindex="-1" (selectionChange)="setSelectedOptions($event, data.name)">
                  <mat-list-option *ngFor="let item of data.values" [value]="{ 'label': data.label, 'value': item}">
                    <p mat-line>{{item}} </p>
                  </mat-list-option>
                </mat-selection-list>
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        <div class="checked-box-count">
          <span>{{selectedLength}}</span> selected
          <a *ngIf="selectedLength > 0" tabindex="0" (keyup.enter)="clearAll()" (click)="clearAll()">Clear All</a>
        </div>
      </div>
    </ng-container>

    <!-- Other Default Filter -->
    <ng-template #default>
      <div class="checkbox-list">
        <ng-container *ngIf="dataSet">
          <mat-selection-list #checkBoxList [attr.aria-labelledby]="labelId" (selectionChange)="setSelectedOptions($event)">
            <mat-list-option tabindex="0" *ngFor="let item of dataSet" [value]="item.value">
              {{item.value}}
            </mat-list-option>
          </mat-selection-list>
        </ng-container>
        <div class="checked-box-count">
          <span>{{selectedLength}}</span> selected
          <a *ngIf="selectedLength > 0" tabindex="0" (keyup.enter)="clearAll()" (click)="clearAll()">Clear All</a>
        </div>
      </div>
    </ng-template>
  </div>
</div>
