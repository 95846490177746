<div class="move-risk" *ngIf="moveRiskData !== null"
  [fxLayout]="moveRiskData?.moveRiskLevel === 1 || moveRiskData?.moveRiskLevel === 3 ? 'row' : 'column'"
  fxLayoutAlign="center">
  <div [fxLayout]="moveRiskData?.viewType === 'default' ? 'column' : 'row'" [matTooltip]="moveRiskData?.moveRiskName"
    [attr.aria-label]="moveRiskData?.moveRiskName"
    [matTooltipDisabled]="!moveRiskData?.moveRiskName || 
      moveRiskData?.moveRiskLevel !== 3 &&
      moveRiskData?.moveRiskLevel !== 1 &&
      moveRiskData?.moveRiskLevel !== 4"
    [fxLayoutAlign]="moveRiskData?.viewType === 'default' ? 'center center' : 'flex-start'">
    <!-- Employee Details View -->
    <div *ngIf="moveRiskData?.viewType === 'default'; else otherViews" [ngSwitch]="moveRiskData?.moveRiskLevel">
      <!-- On Track -->
      <div class="moveRiskImgemp" *ngSwitchCase=+3>
        <img  (click)="openMoveRiskModal($event,moveRiskData)"
          src="../../../../assets/images/moveRisk_onTrack.svg" />
      </div>
      <!-- Off-Track -->
      <div class="moveRiskImgemp" *ngSwitchCase=+1>
        <img  (click)="openMoveRiskModal($event,moveRiskData)"
          src="../../../../assets/images/moveRisk_offTrack.svg" />
      </div>
      <!-- Risk Complete -->
      <div class="moveRiskImgemp" *ngSwitchCase=+4>
        <img (click)="openMoveRiskCompleteModal($event,moveRiskData)" src="../../../../assets/images/moveRisk_complete.svg" />
      </div>
      <!-- Text -->
      <div class="smallTextempn-a" fxLayout="row" *ngSwitchDefault>
        <span class="smallTextn-aemp"> N/A </span>
      </div>
      <div *ngIf="moveRiskData.moveRiskLevel != 4" class="smallTextemp"
        [innerHTML]="moveRiskData.moveRiskLevel === 3 ? 'On Track' : moveRiskData.moveRiskLevel === 1 ? 'Off Track': '' ">
      </div>
      <div *ngIf="moveRiskData.moveRiskLevel === 4" class="smallTextemprc"
      [innerHTML]="moveRiskData.moveRiskLevel === 4 ? 'Risk Complete' : '' ">
    </div>
    </div>
  </div>

  <!-- Other Views -->
  <ng-template #otherViews>
    <!-- Aggrgate View -->
    <ng-container *ngIf="moveRiskData?.viewType === 'aggregate'">
      <div fxLayout="row" fxFlexAlign="start" class="aggregate">
        <div fxLayout="column" fxLayoutAlign="space-between" [matTooltip]="
         'On Track: ' + (moveRiskData?.moveOnTrackCount | number)
         " [attr.aria-label]="
         'On Track: ' + (moveRiskData?.moveOnTrackCount | number)
         ">
          <!-- On Track -->
          <ng-container *ngIf="moveRiskData?.moveOnTrackCount !== 0; else onTrackGray">
            <!-- Colored Image -->
            <img class="img-color" src="../../../../assets/images/moveRisk_onTrack.svg" />
          </ng-container>
          <ng-template #onTrackGray>
            <!-- Gray Image -->
            <img class="img-gray" src="../../../../assets/images/atRisk_onTrackGray.svg" />
          </ng-template>
          <!-- Text -->
          <span class="smallText" [innerHTML]="
            'On Track: ' + (moveRiskData?.moveOnTrackCount | number)
            "></span>
        </div>
        <!-- Off-Track -->
        <div fxLayout="column" fxLayoutAlign="space-between" [matTooltip]="
      'Off Track: ' + (moveRiskData?.moveAtRiskCount | number)
      " [attr.aria-label]="
      'Off Track: ' + (moveRiskData?.moveAtRiskCount | number)
      ">
          <!-- Colored Image -->
          <ng-container *ngIf="moveRiskData?.moveAtRiskCount !== 0; else offTrackGray">
            <img class="img-color" src="../../../../assets/images/moveRisk_offTrack.svg" />
          </ng-container>
          <!-- Gray Image -->
          <ng-template #offTrackGray>
            <img class="img-gray" src="../../../../assets/images/moveRisk_offTrackGray.svg" />
          </ng-template>
          <!-- Text -->
          <span class="smallText" [innerHTML]="
         'Off Track: ' + (moveRiskData?.moveAtRiskCount | number)
         "></span>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between" [matTooltip]="
      'Risk Complete: ' + (moveRiskData?.moveRiskCompleteCount | number)
      " [attr.aria-label]="
      'Risk Complete: ' + (moveRiskData?.moveRiskCompleteCount | number)
      ">
          <!-- Colored Image -->
          <ng-container *ngIf="
         moveRiskData?.moveRiskCompleteCount !== 0;
         else moveRiskCompleteGray
         ">
            <img class="img-color-mrc" src="../../../../assets/images/moveRiskComplete.svg" />
          </ng-container>
          <!-- Gray Image -->
          <ng-template #moveRiskCompleteGray>
            <img class="img-gray-mrc" src="../../../../assets/images/moveRiskCompleteGray.svg" />
          </ng-template>
          <!-- Text -->
          <span class="smallText mrcc" [innerHTML]="
         'Risk Complete: ' + (moveRiskData?.moveRiskCompleteCount | number)
         "></span>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between"
          [matTooltip]="'Risk N/A: ' + (moveRiskData?.moveRiskNACount | number)" [attr.aria-label]="
      'Risk N/A: ' + (moveRiskData?.moveRiskNACount | number)
      ">
          <!-- Colored Image -->
          <ng-container *ngIf="moveRiskData?.moveRiskNACount !== 0; else moveRiskNAGray">
            <p class="natxt">N/A</p>
            <!-- <img
            class="img-color"
            src="../../../../assets/images/moveRiskNA.svg"
            /> -->
          </ng-container>
          <!-- Gray Image -->
          <ng-template #moveRiskNAGray>
            <p class="mrn-agray">N/A</p>
            <!-- <img
            class="img-gray"
            src="../../../../assets/images/moveRiskNA.svg"
            /> -->
          </ng-template>
          <!-- Text -->
          <span class="smallText" [innerHTML]="
         'Risk N/A: ' + (moveRiskData?.moveRiskNACount | number)
         "></span>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>

