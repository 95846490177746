import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Milestones } from '../models/milestones.model';
import { HttpParams } from '@angular/common/http';
import { milestoneData } from '../data/milestoneData'
import { employeeData } from '../data/employeeData';
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';

/** MilestoneService to fetch Milestone details from Api and to display in the Milestone List*/
@Injectable({
  providedIn: 'root'
})
export class MilestoneService {

  /** Base constructor method
     * @param baseClient BaseClientService injection
     * @param logSvc RemoteLoggingService injection
     */
  constructor(private baseClient: BaseClientService, private logSvc: RemoteLoggingService,
    private moveProDashboadDataService: MoveProDashboadDataService
    ) { }

  /** getMilestonesByFileNum() to fetch Milestone details from Api */
  getMilestonesByFileNum(fileNumber: number, itemsPerPage: number = 0, currentEmployee?): Observable<Milestones> {
    let params: HttpParams;
    if (itemsPerPage > 0) {
      params = new HttpParams({
        fromObject: {
          'itemsPerPage': itemsPerPage.toString()
        }
      });
    }
    // return this.baseClient
    //   .getOne<Milestones>(`v1/milestone/${fileNumber}`, 'get the milestone details')
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: Milestones = null;
    //       this.logSvc.logError(err);
    //       return of(empty);
    //     })
    //   );
    let tempEmployeeData = {"data": new Array};
    this.moveProDashboadDataService.employeeDataSubject.subscribe(
      data => {
       tempEmployeeData = data;
      })
 
    let milestonedatas = [];
   // let currentEmployeelist = employeeData.data.filter((ele) =>{
      let currentEmployeelist =  tempEmployeeData.data.filter((ele) =>{
      if (ele.fileNumber === fileNumber) {
        return ele;
      }
    });
    currentEmployee = currentEmployeelist.length > 0 ? currentEmployeelist[0]: null;
    if (currentEmployee && currentEmployee.milestoneDesc) {
      if (currentEmployee.milestoneDesc === 'Authorized') {
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.milestoneDate,
            milestoneDesc: currentEmployee.milestoneDesc
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Transfer Date"
          });
      } else if (currentEmployee.milestoneDesc === 'Privacy Consent') {
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.milestoneDate,
            milestoneDesc: currentEmployee.milestoneDesc
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Authorized"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Initial Contact"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Policy Counseling"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Repay Agreement"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Transfer Date"
          });
      } else if (currentEmployee.milestoneDesc === 'Repay Agreement') {
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.milestoneDate,
            milestoneDesc: currentEmployee.milestoneDesc
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Privacy Consent"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Transfer Date"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Initial Contact"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Policy Counseling"
          });
      }else if (currentEmployee.milestoneDesc === 'Transfer Date') {
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.milestoneDate,
            milestoneDesc: currentEmployee.milestoneDesc
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Repay Agreement"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Initial Contact"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Privacy Consent"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Authorized"
          });
      }else if (currentEmployee.milestoneDesc === 'Shipment Delivered (Surface)') {
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.milestoneDate,
            milestoneDesc: currentEmployee.milestoneDesc
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Shipment Delivered (Air)"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Shipment Packed (Air)"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Shipment Packed (Surface)"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Repay Agreement"
          });
      }else if (currentEmployee.milestoneDesc === 'Initial Contact') {
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.milestoneDate,
            milestoneDesc: currentEmployee.milestoneDesc
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Authorized"
          });
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.effectiveTransferDate,
            milestoneDesc: "Transfer Date"
          });
      } else {
        milestonedatas.push(
          {
            milestoneDate: currentEmployee.milestoneDate,
            milestoneDesc: currentEmployee.milestoneDesc
          });
      }

      milestoneData.data = milestonedatas;
    }
    
    return of(JSON.parse(JSON.stringify(milestoneData)));
  }
}
