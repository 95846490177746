export const standardprogramdata  = 
{
    "clientId": "5dec2e33da74cb5f087e88f9",
    "programs": [
      {
        "name": "Executive Homeowner",
      },
      {
        "name": "Executive Renter"
      },
      {
        "name": "Expat - Long Term"
      },
      {
        "name": "Intern 1"
      },
      {
        "name": "One Way"
      },
      {
        "name": "Repat"
      },
      {
        "name": "Standard Homeowner"
      },
      {
        "name": "Standard Renter"
      },
      {
        "name": "US Inbound"
      },
      {
        "name": "US Outbound"
      }
    ],
    "count": 10
  }