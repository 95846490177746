import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AddEditClientsComponent } from '../clients/add-edit-clients/add-edit-clients.component';
import { MatMenuTrigger, MatTableDataSource, MatSort, MatPaginator,MatDialog } from '@angular/material';
import { loggedInUserData } from '../../login/login.service';
import { ManageClientsService } from '../../login/manage-clients.service';
import { ToastrService } from 'ngx-toastr';

export interface clientList {
  clientId: number;
  clientName: string;
  noOfUsers: number;
}

@Component({
  selector: 'app-manage-clients',
  templateUrl: './manage-clients.component.html',
  styleUrls: ['./manage-clients.component.scss']
})
export class ManageClientsComponent implements OnInit {
  displayedColumns: string[] = ['clientId', 'clientName', 'noOfUsers', 'delete'];
  dataSource: any = new MatTableDataSource();
  loggedInUserType:string;

  @ViewChild(MatMenuTrigger, {
    static: false
  }) trigger: MatMenuTrigger;

  @ViewChild(MatSort, {
    static: true
  }) sort: MatSort;

  @ViewChild(MatPaginator, {
    static: true
  }) paginator : MatPaginator;

  constructor(private router:Router,
              private manageClientService: ManageClientsService,
              public dialog: MatDialog,
              private readonly toastrService: ToastrService) { }

  ngOnInit() {
    this.fetchClientsData();
    this.subscribeClientList();
  }

  fetchClientsData()  {
    this.manageClientService.getClients().subscribe( data =>{
      if(data) {
        this.manageClientService.manageClientList.next(data);
       }
    })
  }

  subscribeClientList() {
    this.manageClientService.manageClientList.subscribe(
      data =>{
        if(data) {
          this.mapClientsData(data);
        }
      }
    )
  }
  onDelete(client){
    this.manageClientService.deleteClients(client.clientId).subscribe(res =>{ 
       this.showSuccessToast(res.message);
       this.fetchClientsData();
    })
  }

  isEnabled() {
    let isEnabled = false;
    let currentUser = JSON.parse(localStorage.getItem(loggedInUserData));
    if(currentUser.userType.toLowerCase() == 'superadmin') {
      isEnabled = true
    }
    return isEnabled;
  }

  isDeleteEnabled(client) {
    let isDeleteEnabled = true;
    let currentUser = JSON.parse(localStorage.getItem(loggedInUserData));
    if(client.noOfUsers > 0 ) {
      isDeleteEnabled = false;
    }
    else if(currentUser.userType.toLowerCase() == 'admin') {
      isDeleteEnabled = false;
    }
    return isDeleteEnabled;
  }

  logout() {
    this.router.navigate(['/home']);
  }

  addEditClients(d?: any) {
    let dialogRef = this.dialog.open(AddEditClientsComponent, {
      width: '400px',
      disableClose: true,
      data: d
    });
  }
  editClients(element: any) {
    let data = element;
    if( data){
      data= {...data,...{"editClientEnable": true}} ;
      this.addEditClients(data);
    }
  }
  mapClientsData(clientsDatafromApi) {
    let data : clientList[] = [];
    for (let i = 0; i < clientsDatafromApi.clients.length; i++) {
      data.push(this.prepareClientsData(clientsDatafromApi.clients[i]));
    }
    this.dataSource = new MatTableDataSource(data);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  prepareClientsData(data){
     let tempData = {
       "clientId" : data.clientId,
       "clientName" : data.clientName,
       "noOfUsers" : data.noOfUsers
     }
     return tempData;
  }

  showSuccessToast(message: string) {
    this.toastrService.success(message, 'success', {
      timeOut: 3000,
      closeButton: true
    })
  }

  navigateUser() {
    this.router.navigate(['/users']);
  }
}
