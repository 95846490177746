import { Component, OnInit, Inject, Input } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { TasksInfoService } from '../../../../../../src/app/core/services/tasks-info.service';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment-timezone';
import { DashboardService } from '../../services/dashboard.service.service';
import { NeedsAssessmentSharedService } from '../../../../../../src/app/public/needs-assessment/services/needs-assessment-shared.service';
@Component({
  selector: 'app-policy-modal',
  templateUrl: './policy-modal.component.html',
  styleUrls: ['./policy-modal.component.scss']
})
export class PolicyModalComponent implements OnInit {

  counter = [0];
  selecteddate : any;
  selectedtime : any;
  dateArray= [];
  timeArray: any = ["9:30AM-10:30AM","10:30AM-11:30AM","11:30AM-12:30PM","12:30PM-1:30PM",
                    "1:30PM-2:30PM", "2:30PM-3:30PM","3:30PM-4:30PM","4:40PM-5:30PM"];
  dateshow:boolean = false;
  timeshow:boolean = false;
  finalshow:boolean =false;
 
 
  readonly hourOffset = this.getHourOffset();
  readonly localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<PolicyModalComponent>,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService,
    private readonly needsAssessmentShared: NeedsAssessmentSharedService,
    private readonly taskInfoService: TasksInfoService,
    private readonly toastr: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {    }
  
  ngOnInit() {

    // this.dateArray.push(new Date());
    for(var i=0;i<7; i++){
      var temp =new Date();
      temp.setDate(temp.getDate() + i);
      this.dateArray.push(temp);
    }
    
      this.dateshow = true;

  }

  getHourOffset() {
    const now = moment();
    const localOffset = now.utcOffset();
    now.tz(this.data.timezone);
    const centralOffset = now.utcOffset();
    const diffInHours = (localOffset - centralOffset) / 60;
    return (diffInHours > 0) ? `+${diffInHours}` : diffInHours;
  }

  // slotSelectedHandler(data: { slot: { start: string, end: string }, counter: number }) {
  //   if (!data) { return; }
  //   this.schedulePolicyCall(data.slot.start, data.slot.end);
  // }

  // schedulePolicyCall(start: any, end: any) {
  //   this.spinner.show();
  //   this.taskInfoService.schedulePolicyCall(start, end).subscribe(res => {
  //     if (res) {
  //       this.dashboardService.getUpdatedTimeline(this.data.orderRequestID);
  //       this.spinner.hide();
  //       this.dialogRef.close(true);
  //       this.toastr.open('Thank you! You can begin reviewing your benefit cards prior to the call with your Consultant.', null, {
  //         duration: 5000
  //       });
  //     } else {
  //       this.spinner.hide();
  //       this.toastr.open('Error sending policy invite', null, {
  //         duration: 5000
  //       });
  //     }
  //   });
  // }

  closeDialog(): void {
    this.dialogRef.close();
  }
  
  schedulePolicyCall(){
    this.dialogRef.close(true);
    let timeline = this.dashboardService.timelineDetails.getValue();
    timeline.timeLines[1].date = new Date(this.selecteddate).toString();
    this.dashboardService.timelineDetails.next(timeline);

    let task =  this.taskInfoService.taskInformation.getValue();
    task.policyCall.status = 'scheduled';
    this.taskInfoService.taskInformation.next(task);
    this.toastr.open('Thank you! You can begin reviewing your benefit cards prior to the call with your Consultant.', null, {
      duration: 5000
    });
  }
   
   

  showtimeSlots(seldate){
    this.timeshow = true;
    this.dateshow = false;
    this.finalshow = false;
    this.selecteddate= seldate;
  
  }

  showtimeSlots1(){
    this.timeshow = true;
    this.dateshow = false;
    this.finalshow = false;
  }
  
  showscheduleSlot(seltime){
    this.timeshow = false;
    this.dateshow = false;
    this.finalshow = true;
    this.selectedtime = seltime;
  }

  showdateSlots(){
    this.dateshow = true;
    this.timeshow = false;
    this.finalshow = false;
  }

}