import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { SearchResult } from '../../core/models/search-result.model';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { employeeData } from '../data/data';
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {

  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(private baseClient: BaseClientService, private logSvc: RemoteLoggingService, private moveProDashboadDataService: MoveProDashboadDataService) { }

  getResults(q: string): Observable<SearchResult> {
    // return this.baseClient
    //   .getOne<SearchResult>(`v1/globalsearch?searchText=${q}`, 'get the search results')
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: SearchResult = null;
    //       this.logSvc.logError(err);
    //       return of(empty);
    //     })
    //   );
    let tempEmployeeData = {"data": new Array};
    tempEmployeeData = this.moveProDashboadDataService.employeeDataSubject.getValue();
    let data = [];
    let response = {
      totalCount: 0,
      message: '',
      query: '',
      data: data
    };
    let searchData = [];
    searchData = tempEmployeeData.data.filter((ele) => {
      if (ele.firstName.toLowerCase().includes(q) || ele.lastName.toLowerCase().includes(q) || (ele.fileNumber.toString() == q)) {
        data.push({
          firstName: ele.firstName,
          lastName: ele.lastName,
          fileNumber: ele.fileNumber,
        });
        return ele;
      }
    });
    if (searchData.length > 0) {
      response = {
        totalCount: searchData.length,
        message: '',
        query: '',
        data: data
      };
    } else {
      response = {
        data: [],
        message: "There are no matching results, please try again",
        query: '',
        totalCount: 0}
    }
    return of(response);
  }
}
