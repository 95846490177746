import { Component, OnInit, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ProviderService } from '../../../../core/services/provider.service';
import { RecentProvider } from '../../../../core/models/recent-provider.model';
import { LinkedListData } from '../../../../core/models/linked-list-data.model';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ModalData } from '../../../../core/services/popup-position.service';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { NumbersService } from '../../../../core/services/numbers.service';

/** Navigation list component */
@Component({
  selector: 'app-provider-payment-summary',
  templateUrl: './provider-payment-summary.component.html',
  styleUrls: ['./provider-payment-summary.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProviderPaymentSummaryComponent implements OnInit, OnDestroy {

  /** holds subscription of service calls for later cleanup */
  public sub: Subscription;

  /** holds the data for the Linked list component */
  keyDatesData: LinkedListData[] = [];

  /** Base Constructor method
   * @param providerService ProviderService injection
   * @Inject ModalData to get the data from parent component
   * @param data to get the data through ModalData
   * @param router Router injection
   */
  constructor(private readonly providerService: ProviderService,
    private readonly dateSvc: DateConversionService,
    private readonly numberService: NumbersService,
    @Inject(ModalData) public data: any,
    private readonly router: Router) { }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() {
    this.createKeyDatesData(this.data.invoices);
  }

  /** setup the key dates data for the linked list component */
  createKeyDatesData(providers: RecentProvider): void {
    // tslint:disable-next-line: prefer-const
    providers.suppliers.sort((a, b) =>
      this.dateSvc.convertToEpoch(a.apPaymentDocDate) -
      this.dateSvc.convertToEpoch(b.apPaymentDocDate)
    );
    providers.suppliers.reverse();
    providers.suppliers.slice(0, 5).forEach(element => {
      element.incurredAmt = this.numberService.formatForCurrency(element.incurredAmt, element.incurredCurrCode);
      this.keyDatesData.push({
        value: this.dateSvc.convertToDisplayDate(element.apPaymentDocDate),
        valueClass: 'active',
        columns: [
          {
            label: element.supplierName.split('##')[0]
          },
          {
            label: element.incurredAmt
          }
        ]
      } as LinkedListData);
    });
  }

  /** Navigate user to full provider payment view */
  viewAll(): void {
    this.router.navigate([`/bbadmin/administration/provider/${this.data.fileId}`]);
    this.close();
  }

  /** To close the dialog */
  close() {
    this.data.overlayRef.dispose();
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
