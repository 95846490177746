<ngx-spinner type="ball-spin-clockwise-fade" size="medium"></ngx-spinner>
<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle">{{ title }}</div>
    <a href="#" class="close-icon" (click)="onNoClick($event)" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <mat-dialog-content class="middle-section" style= "margin: 0px 0px !important;">
    <form fxLayout="column" [ngClass]="{'costmodel-scroll-div': isTogglePanelCount>0}" [formGroup]="addCostModelForm">
      <div *ngIf="!deleteDialog">
        <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
          fxLayoutGap.xs="0em">
          <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <input #CostModelName matInput placeholder="Cost Model Name" formControlName="ModelName">
              <mat-error>
                {{ errors['CostModelName'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="45%" fxLayout.xs="column" fxFlex.xs="100%">
            <mat-form-field>
              <mat-label>Level</mat-label>
              <mat-select formControlName="Level" id="level" (selectionChange)="onInputChange(); onLevelChange($event)">
                <mat-option *ngFor="let level of levels" [value]="level.levelName">
                  {{level.levelName}}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ errors['Level'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" fxLayoutGap.xs=".3em"
          class="addcandidate-toppadding">
          <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="40%">
            <mat-form-field>
              <input type="text" (keydown.tab)="onDeptTabOut()" placeholder="Departure" id="departureName" matInput
                formControlName="Departure" [matAutocomplete]="departureList"
                (input)="onDepartureInput(addCostModelForm.controls['Departure'].value)" (change)="onInputChange()">
              <mat-spinner *ngIf="showdDepartureSpinner" matSuffix strokeWidth="1" [diameter]="20"></mat-spinner>
              <mat-autocomplete id="selectDeparture" #departureList="matAutocomplete"
                (closed)='onDepartureSelectionClosed()' (optionSelected)='onDepartureSelected()'>
                <mat-option *ngFor="let departure of departures" [value]="departure.city +', '+ departure.state">
                  {{departure.city +', '+ departure.state}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="addCostModelForm.controls['Departure'].hasError('required')">
                {{ errors['Departure'] }}
              </mat-error>
              <mat-error *ngIf="addCostModelForm.controls['Departure'].hasError('invalidAddress')">
                {{ errors['NoResults'] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxFlex="5%" fxFlex.xs="15%">
            <mat-icon class="material-icons modal-rightnavarrow">chevron_right</mat-icon>
          </div>
          <div fxFlex="45%" fxFlex.xs="40%">
            <mat-form-field>
              <input type="text" (keydown.tab)="onDestTabOut()" placeholder="Destination" id="destinationName" matInput
                formControlName="Destination" [matAutocomplete]="destinationList"
                (input)="onDestinationInput(addCostModelForm.controls['Destination'].value)" (change)="onInputChange()">
              <mat-spinner *ngIf="showdDestinationSpinner" matSuffix strokeWidth="1" [diameter]="20"></mat-spinner>
              <mat-autocomplete id="selectDestination" #destinationList="matAutocomplete"
                (closed)='onDestinationSelectionClosed()' (optionSelected)='onDestinationSelected()'>
                <mat-option *ngFor="let destination of destinations"
                  [value]="destination.city +', '+ destination.state">
                  {{destination.city +', '+ destination.state}}
                </mat-option>
              </mat-autocomplete>
              <mat-error *ngIf="addCostModelForm.controls['Destination'].hasError('required')">
                {{ errors['Destination'] }}
              </mat-error>
              <mat-error *ngIf="addCostModelForm.controls['Destination'].hasError('sameAddress')">
                {{ errors['DeptDestSame'] }}
              </mat-error>
              <mat-error *ngIf="addCostModelForm.controls['Destination'].hasError('invalidAddress')">
                {{ errors['NoResults'] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-spinner *ngIf="showLoader" class="estimate-cost-model" strokeWidth="3" [diameter]="50"></mat-spinner>
        <span tabindex="-1" class="cdk-visually-hidden" aria-atomic="true" aria-hidden="true" #costPredictionTab>New
          Cost estimates
          generated</span>
        <div *ngIf="displayPredictedRelocationCost&&!showLoader&&(generateButton||data.costModels)"
          class="predicted-relocation-cost" id="predicted_relocation_cost">
          <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
            fxLayoutGap.xs="0em">
            <div fxFlex="100%" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-accordion [multi]="true">
                <div [@.disabled]="disableAnimation">
                  <mat-expansion-panel *ngFor="let estimate of costModelEstimates;let i=index"
                    (opened)="togglePanel('opened')" (closed)="togglePanel('closed')">
                    <mat-expansion-panel-header>
                      <mat-panel-title class="title">
                        <span> {{estimate.familySizeDesc}} </span>
                        <mat-icon matPrefix *ngIf="i===0" svgIcon="person-one"></mat-icon>
                        <mat-icon matPrefix *ngIf="i===1" svgIcon="person-two"></mat-icon>
                        <mat-icon matPrefix *ngIf="i===2" class="person-four" svgIcon="person-four"></mat-icon>
                      </mat-panel-title>
                      <mat-panel-description fxLayoutAlign="end center">
                        {{(estimate.estimatedTotalMinimumCost)?(estimate.estimatedTotalMinimumCost | number:'.0-0') : 0 }}
                        -
                        {{(estimate.estimatedTotalMaximumCost)?(estimate.estimatedTotalMaximumCost | number:'.0-0') : 0 }}
                        {{currency}}
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em"
                      fxLayoutGap.xs="0em">
                      <div fxFlex="60%" fxLayoutAlign="start" fxFlex.xs="50%">
                        <div class="core-services">
                          <p>CORE</p>
                          <p>Professional Van Line Move</p>
                          <p>(includes insurance)</p>
                        </div>
                      </div>
                      <div fxFlex="40%" fxLayoutAlign="end" fxFlex.xs="50%">
                        <div class="core-services-amount">
                          <p>
                            {{(estimate.coreServices.minCostInsurance)?(estimate.coreServices.minCostInsurance | number:'.0-0') : 0}}
                            -
                            {{(estimate.coreServices.maxCostInsurance)?(estimate.coreServices.maxCostInsurance | number:'.0-0') : 0}}
                            {{currency}}</p>
                        </div>
                      </div>
                    </div>
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em"
                      fxLayoutGap.xs="0em">
                      <div fxFlex="60%" fxLayoutAlign="start" fxFlex.xs="50%">
                        <div class="core-services">
                          <p>FLEXIBLE SPEND</p>
                          <p>Multiple Probable Services</p>
                        </div>
                      </div>
                      <div fxFlex="40%" fxLayoutAlign="end" fxFlex.xs="50%">
                        <div class="core-services-amount">
                          <p>
                            {{(estimate.flexServices.estimatedSubTotalMinimumCost)?(estimate.flexServices.estimatedSubTotalMinimumCost | number:'.0-0'): 0}}
                            -
                            {{(estimate.flexServices.estimatedSubTotalMaximumCost)?(estimate.flexServices.estimatedSubTotalMaximumCost | number:'.0-0'): 0 }}
                            {{currency}}</p>
                        </div>
                      </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayoutGap.xs="0em">
                      <div fxFlex="50%" fxLayoutAlign="start" fxFlex.xs="100%">
                        <div class="tax-assistance">
                          <p>TAX ASSISTANCE</p>
                        </div>
                      </div>
                      <div fxFlex="50%" fxLayoutAlign="end" fxFlex.xs="100%">
                        <div class="tax-assistance-amount">
                          <p> {{(estimate.minTaxAmount)?(estimate.minTaxAmount | number:'.0-0'): 0}}
                            -
                            {{(estimate.maxTaxAmount)?(estimate.maxTaxAmount | number:'.0-0'): 0 }}
                            {{currency}}</p>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </div>
              </mat-accordion>
            </div>
          </div>
        </div>
      </div>
      <!-- <div *ngIf="!displayPredictedRelocationCost||data.costModels||(!generateButton && !data.costModels) "
        class="generate-btn-container">
        <button mat-raised-button id="generate-btn"
          [disabled]="!addCostModelForm.valid || isDestinationValid|| isDepartureValid || (data.costModels && data.costModels.costModelId && !this.addCostModelForm.controls['ModelName'].value) || data.disableButton"
          (click)="showPredictedRelocationCost()" class="contained-button">{{generateOrUpdateCostModel}}</button>
        <span class="cdk-visually-hidden" tabindex="-1" aria-hidden="true" #costModelFields>Loading cost estimates,
          please
          wait...</span>
      </div>
      <div *ngIf="deleteDialog">
        <div fxFlex="100%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
          <p> Please click on delete to confirm the deletion of the following model(s):<br>
            {{ this.data.costModels.costModelName  }}
            cost model. </p>
        </div>
      </div> -->
    </form>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div *ngIf="data.costModels" class="costmodel-lastsave">
      <span class="save-txt"> Cost Model Last Saved:</span>
      <span class="save-date">{{data.costModels.updatedDate | date:'yyy-MM-dd'}}</span>
    </div>
    <div class="statusBtn">
      <button mat-button class="text-button" id="cancel_cost_model" (click)="onNoClick($event)">CANCEL</button>
      <button mat-raised-button id="save_cost_model"
        (click)="saveCostModel()" 
        [disabled]="!addCostModelForm.valid || isDestinationValid|| isDepartureValid || (data.costModels && data.costModels.costModelId && !this.addCostModelForm.controls['ModelName'].value) || data.disableButton"
        class="contained-button">Save</button>
      <!-- <button *ngIf="deleteDialog" class="mat-button contained-button" (click)="deleteCostModel()"
        id="invite">Delete</button> -->
    </div>
  </mat-dialog-actions>
</div>