<div class="userContextContainer" *ngIf="userContext">
    <div [matMenuTriggerFor]="settingMenu" class="mat-menu">
    <div class="userImageSection" *ngIf="!this.isViewer" >
        <app-avatar  [firstName]="userContext.firstName" [lastName]="userContext.lastName"></app-avatar>
    </div>
    <div tabindex="0"  fxLayout="row" fxLayoutAlign="center center" class="userImageSection viewerIcon" *ngIf="this.isViewer" >
        <mat-icon>visibility</mat-icon>
    </div>
    <div class="userNameSection" fxHide.lt-md>
        <button mat-button class="menu-style" aria-hidden="true" [matMenuTriggerFor]="settingMenu">
            <div class="menuName">
                <a href="javascript:" (focus)="announceContext()" role="button" aria-label="menu clickable">
                    <span aria-hidden="true">{{userContext.firstName}}</span>
                    <span class="client-name" aria-hidden="true">{{userContext.clientLegalName}}</span>
                    <span class="client-id" aria-hidden="true">{{userContext.clientNo}}</span>
                </a>
            </div>
            <div class="menuIconBlock" [ngClass]="{'auto': !userContext.clientLegalName && !userContext.clientNo }">
                <mat-icon class="expandMore">arrow_drop_down</mat-icon>
                <mat-icon class="expandLess">arrow_drop_up</mat-icon>
            </div>
        </button>
    </div>
    <div fxHide.gt-sm class="mobile-userContext">
        <button class="mobile-menu-style" mat-button>
            <div class="menuIconBlock">
                <mat-icon class="expandMore">expand_more</mat-icon>
                <mat-icon class="expandLess">expand_less</mat-icon>
            </div>
        </button>
    </div>
    </div>
    <mat-menu class="settingMenu" #settingMenu="matMenu">
        <button mat-menu-item (click)="settings()"><mat-icon>settings</mat-icon> Settings</button>
        <button mat-menu-item *ngIf="isEnableContext" (click)="changeContext()"><mat-icon>people</mat-icon>Select Account</button>
        <button mat-menu-item (click)="logout()"><mat-icon>exit_to_app</mat-icon> Log Out</button>
        <button mat-menu-item (click)="openPopup()"><mat-icon>alarm</mat-icon> Idle Timeout</button>
        <!-- <button mat-menu-item fxHide><mat-icon>settings</mat-icon> Settings</button> -->
    </mat-menu>
</div>
