import { Component, OnInit, EventEmitter, Output, Input, Inject, ViewChild, ElementRef, Renderer2, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatSnackBar, MatMenuTrigger, MatButton, MatInput } from '@angular/material';
import { ProgramManagementService, Benefit, ProgramRequest, ProgramBenefitUpdateRequest } from '../../../services/program-management.service';
import { ToastrService } from 'ngx-toastr';
import { ProgramCloseWarningComponent, ProgramModalComponent } from '../../../components/program-modal/program-modal.component';
import { CopyProgramModalComponent } from '../../../components/copy-program-modal/copy-program-modal.component';
import { AdminClient } from './../../../../../core/models/admin-client.model';
import pdfMake from 'pdfmake/build/pdfmake';
import { ProgramReportGenerationService } from './../../../services/program-report-generation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserTypeService, UserType } from './../../../services/user-type.service';
import { DivisionAssociationComponent } from '../../../components/division-association/division-association.component';
import { EditBenefitDialogComponent } from '../components/edit-benefit-dialog/edit-benefit-dialog.component'
import { FormControl } from '@angular/forms';
import { DeactivateProgramModalComponent } from '../../../components/deactivate-program-modal/deactivate-program-modal.component';
import { ConsultantOnlyModalComponent } from '../../../components/consultant-only-modal/consultant-only-modal.component';
import * as moment from 'moment';
import { PointsLogicModalComponent } from '../components/points-logic-modal/points-logic-modal.component';
import { Subscription } from 'rxjs';
import { CategoryMappingHelper } from '../../../../../core/data/CategoryMapping';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { BenefitSettingDialogComponent } from '../components/benefit-setting-dialog/benefit-setting-dialog.component';

export interface Program {
  id: string;
  programName: string;
  templateName: string;
  totalPoints: number;
  contractSeries: any;
  atlasPolicy: string;
  selectedClientId: string;
  cartusClientId?: string;
  isNew?: boolean;
  programExpirationDate?: string;
  programActivationDate?: string;
  policyCallRequired?: boolean;
  divisions?: Array<{
    division_id: string;
    division_name: string
  }>;
  editTitle?: boolean; // Flag to set the benefit title in edit mode
  editDescription?: boolean; // Flag to set the benefit Description in edit mode
  draft?: boolean;//Flag for draft
  isCartusInsured?: boolean; // indicate whether household goods insurance and high value items insurance is provided
}

export const CategoryMapping = CategoryMappingHelper.getCategoryMapping();;

@Component({
  selector: 'app-program-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class DetailComponent implements OnInit {

  @Input() program: Program;

  /** To be displayed in download report */
  @Input() clientDetails: AdminClient;

  @Output() close = new EventEmitter<boolean>();

  programDivisions: Array<any> = [];

  searchValue: string = null;

  benefitsList: Array<{ group: string, items: Array<Benefit> }>;

  originalBenefitsList: Array<Benefit> = [];
  initialBenefitsList: Array<Benefit> = [];


  /** To store list of divisions */
  divisions;

  /** user role */
  userRole: UserType;

  readonly BenefitCategories = CategoryMappingHelper.getBenefitCategories();

  assignedDivision: number = null;

  cloneBenefitCount: number = 1;

  // tslint:disable-next-line: no-inferrable-types
  hideDivisionCount: boolean = true;

  //  dataviewSelection to access the dialog
  divisionSelection: MatDialogRef<DivisionAssociationComponent>;

  deactivateModalRef: MatDialogRef<DeactivateProgramModalComponent>;

  validForDeactivation: boolean = true;

  connectedTo = []; //for drag and drop connection
  itemDragged: any;
  disableDrag: boolean = true;


  // to capture edit flex point
  points: any;
  rangeIncrementValue: number;
  // rangeIncrementUnit :string;
  timeSpanValue = new FormControl();
  publishDisable: boolean;
  draftDisable: boolean;
  perPointCurrency: number;
  panelOpenState = false;
  publishDivisionFlag = false;
  editExpiration = false;
  editPolicy = false;
  editpolicyCallRequired = false;
  editContract = false;
  editTotalPoints = false;
  editPoint: Array<boolean> = [];
  editDisplayLogic: Array<boolean> = [];
  editMultiplicity: Array<boolean> = [];
  editIncremental: Array<boolean> = [];
  editIncrementalCurrency: Array<boolean> = [];
  editPerPointCurrency: Array<boolean> = [];
  editIsCartusInsured = false;
  editRaneIncrementVal: Array<boolean> = [];
  @ViewChild(MatMenuTrigger, { static: false }) matMenuTrigger: MatMenuTrigger;
  @ViewChild('pointVal', { static: true }) pointVal: ElementRef;
  @ViewChildren('focusable') focusable: QueryList<MatButton>;
  @ViewChild('perPointCurrencyVal', { static: true }) perPointCurrencyVal: ElementRef;
  @ViewChildren('title') title: QueryList<ElementRef>;
  @ViewChild('rangeIncrementVal', { static: true }) rangeIncrementVal: ElementRef;
  @ViewChildren('rangeIncrementUnit') rangeIncrementUnit: QueryList<ElementRef>;
  updatedBenefits: Array<Benefit> = [];
  benefitService: any;
  benefit: any;
  clientSubscription: Subscription;
  isNewView = false;
  timespanValues: string[] = ['Hours', 'Days', 'Weeks', 'Months', 'Years'];
  timeSpanValues: string[] = ['Days','Months'];
  isChipEditable: Array<boolean> = [];
  newelyAddedBenefits = [];
  constructor(
    public dialog: MatDialog,
    private readonly programService: ProgramManagementService,
    private toastrService: ToastrService,
    private userTypeService: UserTypeService,
    private readonly pdfgenerator: ProgramReportGenerationService,
    private spinner: NgxSpinnerService,
    private readonly toastr: MatSnackBar
  ) { }

  ngOnInit() {
    let closeFlag = false;
    this.userRole = this.userTypeService.userType;
    this.editPoint.forEach((i, index) => {
      i = false;
      this.editMultiplicity[index] = false;
      this.editDisplayLogic[index] = false;
      this.editIncremental[index] = false;
      this.editRaneIncrementVal[index] = false;
      this.editIncrementalCurrency[index] = false;
      this.editPerPointCurrency[index] = false;
      this.isChipEditable[index] = false;
    });
    // this.close.subscribe(val => closeFlag = val)
    // this.clientSubscription = this.programService.programBenefitData.subscribe(data => {
    //   if (data.benefits.length > 0 && !closeFlag) {
    //     data.benefits = data.benefits.sort((a, b) => a.displayName.localeCompare(b.displayName));
    //     data.benefits.forEach((item) => {
    //       item.icon = this.programService.mapIconToBenefit(item.reference);
    //     });
    //     this.originalBenefitsList = JSON.parse(JSON.stringify(data.benefits));
    //     this.initialBenefitsList = JSON.parse(JSON.stringify(data.benefits));
    //     this.configureProgramBenefits(data.benefits, this.program);
    //   } else if (!closeFlag) {
    //     this.loadProgramBenefits(this.program);
    //   }
    // });
    this.close.subscribe(val => {if(val)closeFlag = val});
    this.clientSubscription = this.programService.benefitList.subscribe(data => {
      if (data.benefits.length > 0 && !closeFlag) {
        data.benefits = data.benefits.sort((a, b) => a.displayName.localeCompare(b.displayName));
        data.benefits.forEach((item) => {
          // to add NewlyAddedRef for newlyadded card  to api and delete before sending 
          if (item.hasOwnProperty('isNewlyAdded')) {
           item['isNewlyAddedRef'] = true;
          }
          if (item.hasOwnProperty('productSubDetails')) {
            if (item.productSubDetails && item.productSubDetails.hasOwnProperty('internationalProduct')) {
              if (item.productSubDetails && !item.productSubDetails.internationalProduct.hasOwnProperty('requiresRevision')) {
                item.productSubDetails.internationalProduct.requiresRevision = false;
              }
            } else {
              let req =
              {
                "productName": "",
                "subProductName": "",
                "requiresRevision": false
              }
              item.productSubDetails.internationalProduct = req;
            }
            if (item.productSubDetails && item.productSubDetails.hasOwnProperty('usDomesticProduct')) {
              if (!item.productSubDetails.usDomesticProduct.hasOwnProperty('requiresRevision')) {
                item.productSubDetails.usDomesticProduct.requiresRevision = false;
              }
            } else {
              let req =
              {
                "productName": "",
                "subProductName": "",
                "requiresRevision": false
              }
              item.productSubDetails.usDomesticProduct = req;
            }
          } else {
            let req = {
              "internationalProduct": {
                "productName": "",
                "subProductName": "",
                "requiresRevision": false
              },
              "usDomesticProduct": {
                "productName": "",
                "subProductName": "",
                "requiresRevision": false
              }
            }
            item.productSubDetails = req;
          }
        });
        this.originalBenefitsList = JSON.parse(JSON.stringify(data.benefits));
        this.initialBenefitsList = JSON.parse(JSON.stringify(data.benefits));
        this.configureProgramBenefits(data.benefits, this.program);
      } else if (!closeFlag) {
        this.loadProgramBenefits(this.program);
      }
    });
    this.loadProgramBenefits(this.program);
    this.loadDivision();
    if (this.program && this.program.programExpirationDate) {
      const trimmedDate = (moment(this.program.programExpirationDate)).format('MM/DD/YYYY');
      (moment(moment().format('MM/DD/YYYY')).isSameOrAfter(moment(trimmedDate).format('MM/DD/YYYY'))) ? this.validForDeactivation = false : this.validForDeactivation = true;
    }
  }

  /** Rearranges original benefit array with updated category of dragged item*/
  drop(event: CdkDragDrop<string[]>) {
    this.originalBenefitsList.filter(ele => {
      if (ele.reference === this.itemDragged.reference && ele.category !== event.container.id) {
        ele.category = event.container.id;
      }
    })
    this.updatedBenefits = this.originalBenefitsList;
    this.benefitsList = this.groupBenefits(this.originalBenefitsList);
  }
  /** Captures index of dragged item */
  dragStarted(event, itemIndex: number, benefitIndex: number) {

    this.disableDrag = false;
    event.source.element.nativeElement.setAttribute('style', 'border:3px solid #ffae4e; flex-direction: row;box-sizing: border-box;display: flex;');
    this.itemDragged = this.benefitsList[benefitIndex].items[itemIndex];
  }
  loadDivision() {
    if (this.program && this.program.divisions && this.program.divisions.length > 0) {
      this.programDivisions = this.program.divisions;
    }
  }
  assignDivision(selectedProg?: any) {
    this.divisionSelection = this.dialog.open(DivisionAssociationComponent, {
      data: {
        clientId: this.program.selectedClientId,
        program: this.program
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.divisionSelection.afterClosed().subscribe((publishDivision: any) => {
      if (publishDivision.length > 0) {
        this.programDivisions = publishDivision;
        this.program.divisions = publishDivision;
      }
      if (this.publishDivisionFlag) {
        if (this.program.atlasPolicy && this.program.contractSeries) {
          this.publishProgram();
        } else {
          this.toastrService.error(`${this.program.atlasPolicy ? null : 'Policy'}${this.program.atlasPolicy && this.program.contractSeries ? null : ' and '}${this.program.contractSeries ? null : 'Legacy Contract'} is required to publish the Program.`);
        }
      }
    });
  }

  openDownloadToastr() {
    // tslint:disable-next-line: max-line-length
    const message = 'Downloading files with sensitive information is a security risk, please clear your cache afterwards';
    const toaster = this.toastr.open(message, 'DOWNLOAD', {
      duration: 10000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['toasterMessage']
    });
    toaster.onAction().subscribe(_ => this.downloadProgram());
  }
  getNonDeletedComplex_Rules(benefit: any) {
    let validComplexRule = [];
    validComplexRule = benefit.scopes ? benefit.scopes.complex_rules.filter((item) => {
      if (item.action !== 'delete') {
        return item;
      }
    }) : null;
    return validComplexRule;
  }
  configureProgramBenefits(benefitList, program) {
    let benefits: Benefit[] = this.loadRule(benefitList);
    const guaranteedBenefits = benefits.filter(benefit => benefit.points.toString() === 'Guaranteed');
    benefitList = guaranteedBenefits.concat(benefits.filter(benefit => benefit.points.toString() !== 'Guaranteed'));
    if (program.isNew || program.draft) {
      benefitList.forEach((item) => {
        item.icon = this.programService.mapIconToBenefit(item.reference);
        !item.consultantBenefitInfo ? item.consultantBenefitInfo = { consultant: false } : null;
        let maxSelection = 0;
        if ((item.advCashOut || item.advCashOutV2) && item.hasOwnProperty('tierConfigs')) {
          item.tierConfigs.forEach((point) => maxSelection = point.maxSelection + maxSelection);
          item.maxSelection = maxSelection;
        }
        //item.scopes.valid_complex_rules = this.getNonDeletedComplex_Rules(item);
      });
      this.disableDrag = false;
    }
    else {
      benefitList.forEach((item) => {
        item.icon = this.programService.mapIconToBenefit(item.reference);
      });
    }
    this.benefitsList = this.groupBenefits(benefitList);
  }

  loadProgramBenefits(program: Program) {
    if (program) {
      const clientid = program.isNew ? program.cartusClientId : program.selectedClientId;
      const programName = program.isNew ? program.templateName : program.programName;
      // setTimeout(() => {
      //   this.spinner.show();
      // }, 1000, true);
      this.programService.getProgramBenefits(clientid, programName, program.isNew ? false : true).subscribe(data => {
        if (data) {
          data.benefits = data.benefits.sort((a, b) => a.displayName.localeCompare(b.displayName));
          data.benefits.forEach((item) => {
            if(item.hasOwnProperty('productSubDetails')){
              if(item.productSubDetails.hasOwnProperty('internationalProduct')){
                if(!item.productSubDetails.internationalProduct.hasOwnProperty('requiresRevision')){
              item.productSubDetails.internationalProduct.requiresRevision = false;
            }
              }
            }
            if(item.hasOwnProperty('productSubDetails')){
              if(item.productSubDetails.hasOwnProperty('usDomesticProduct')){
                if(!item.productSubDetails.usDomesticProduct.hasOwnProperty('requiresRevision')){
              item.productSubDetails.usDomesticProduct.requiresRevision = false;
            }
              }
            }

            item.icon = this.programService.mapIconToBenefit(item.reference);
          });
          this.originalBenefitsList = JSON.parse(JSON.stringify(data.benefits));
          this.initialBenefitsList = JSON.parse(JSON.stringify(data.benefits));
          this.configureProgramBenefits(data.benefits, program);

          /*let benefits : Benefit[]= this.loadRule(data.benefits);
          const guaranteedBenefits = data.benefits.filter(benefit => benefit.points.toString() === 'Guaranteed');
          data.benefits = guaranteedBenefits.concat(data.benefits.filter(benefit => benefit.points.toString() !== 'Guaranteed'));
          if (program.isNew || program.draft) {
            data.benefits.forEach((item) => {
              !item.consultantBenefitInfo ? item.consultantBenefitInfo = {consultant : false} :null;
            });
          }
          this.benefitsList = this.groupBenefits(benefits);*/
          this.spinner.hide();
        } else {
          this.toastrService.error('Error fetching Benefits', 'Error');
          this.spinner.hide();
        }
      }, () => {
        this.toastrService.error('Error fetching Benefits', 'Error');
        this.spinner.hide();
      });
    }
  }

  loadRule(benefitList) {
    benefitList.forEach((benefit, key) => {
      if ((benefit.exclusions && benefit.exclusions.length > 0) || (benefit.prerequisites && benefit.prerequisites.length > 0)) {
        benefitList[key].excludeRule = [];
        benefit.exclusions ? benefit.exclusions.forEach(exclude => {
          const benefitName = benefitList[benefitList.findIndex(item => exclude === item.reference)].displayName;
          benefitList[key].excludeRule && benefitList[key].excludeRule.length > 0 && benefitList[key].excludeRule.length ?
            // (!benefitList[key].excludeRule.includes(benefitName) ?
            //   benefitList[key].excludeRule.push(
            //     benefitName) : null) :           
            benefitList[key].excludeRule.push(benefitName) :
            benefitList[key].excludeRule = [benefitName];
        }) : benefitList[key].excludeRule = [];
        //benefitList[key].prerequisiteRule = [];
        benefit.prerequisites ? benefit.prerequisites.forEach(prerequisite => {
          const parentIndex = benefitList.findIndex(item => prerequisite === item.reference);
          const benefitIndex = benefitList[parentIndex].prerequisiteRule ? benefitList[parentIndex].prerequisiteRule.findIndex(rule => rule.reference === benefitList[key].reference) : null;
          benefitList[parentIndex].prerequisiteRule && benefitList[parentIndex].prerequisiteRule.length > 0 &&
            benefitIndex === -1 ? benefitList[parentIndex].prerequisiteRule.push(benefitList[key]) :
            //benefitList[parentIndex].prerequisiteRule? benefitList[parentIndex].prerequisiteRule.splice(benefitList[parentIndex].prerequisiteRule.findIndex(item => prerequisite === item.reference), 1):
            benefitList[parentIndex].prerequisiteRule = [benefitList[key]];
        }) : null;
      } else {
        benefit.exclusions && benefit.exclusions.length === 0 ? benefitList[key].excludeRule = [] : null;
        benefit.prerequisites && benefit.prerequisites.length === 0 ? benefitList[key].prerequisiteRule = [] : null;
      }

      benefit.scopes ? benefit.scopes.valid_complex_rules = this.getNonDeletedComplex_Rules(benefit) : null;
    });
    return benefitList;
  }

  groupBenefits(benefits: Array<Benefit>): Array<{ group: string, items: Array<Benefit> }> {
    benefits = this.updateCategoryNames(benefits);
    const benefitsByGroup = Object.values(CategoryMapping).map(category => {
      this.connectedTo.push(category);
      return {
        group: category,
        items: benefits.filter(benefit => benefit.category === category).sort((a, b) => a.displayName.localeCompare(b.displayName))
      };

    });
    return benefitsByGroup;
  }

  textSearchBenefits(searchText) {
    if (searchText && searchText.length >= 3 || searchText.length === 0) {
      const benefitList = this.loadRule(this.originalBenefitsList);
      const searchBenefits = benefitList.filter(benefit =>
        benefit.displayName.toLowerCase().search(searchText.toLowerCase()) !== -1);
      this.benefitsList = this.groupBenefits(searchBenefits);
      this.searchValue = searchText;
    }
  }

  textSearch(event) {
    if (event.key === 'Backspace' && event.target.value.length === 0) {
      this.textSearchBenefits(event.target.value);
    }
  }

  clearTextSearch() {
    this.benefitsList = this.groupBenefits(this.originalBenefitsList);
    this.searchValue = '';
  }
  updateToOldCategoryNames(benefit) {
    let position = Object.values(CategoryMapping).findIndex(ele => {
      return (ele.toLowerCase() === benefit.category.toLowerCase())
    });
    benefit.category = (Object.keys(CategoryMapping)[position]);
    if (benefit.category === 'Relocation Allowances') {
      benefit.category = 'Allowances'
    }
    return benefit;
  }
  updateCategoryNames(benefits: Array<Benefit>) {
    const benefitCategories = [...this.BenefitCategories.map(i => i.toLowerCase())];
    let categoryMapping = {};
    Object.keys(CategoryMapping).map(i => {
      categoryMapping[i.toLowerCase()] = CategoryMapping[i];
    });
    categoryMapping['allowances'] = 'Allowance';
    return benefits.map(benefit => {
      if ((benefitCategories).includes(benefit.category.toLowerCase())) {
        benefit.category = categoryMapping[benefit.category.toLocaleLowerCase()];
      }
      return benefit;
    });
    /* return benefits.map(benefit => {
      if (this.BenefitCategories.includes(benefit.category)) {
        benefit.category = CategoryMapping[benefit.category];
      }
      return benefit;
    }); */
  }

  publishProgram() {
    if (this.program.atlasPolicy && this.program.contractSeries) {
      const oldCategories: Array<string> = Object.keys(CategoryMapping);
      const newCategories: Array<string> = Object.values(CategoryMapping);
      const divisions = this.programDivisions.map((element: any) => {
        return (element.division_id);
      });
      const cashOutBenefit = this.originalBenefitsList.find(item => (item.advCashOut || item.advCashOutV2) && !item.suppress);
      if (divisions.length === 0 && !this.publishDivisionFlag) {
        this.assignDivision(this.program);
        this.publishDivisionFlag = true;
      } else if (cashOutBenefit && (!cashOutBenefit.tierConfigs || cashOutBenefit.tierConfigs.length === 0)) {
        this.toastrService.error(`Please configure the cash out logic for <br> Cash Out Card before Publishing for Use.`, '', { enableHtml: true });
      } else {
        if (this.program.draft) {
          this.updateBenefitForProgram(this.updatedBenefits, false); // for publishing drafted program
          return;
        }
        this.spinner.show();

        const benefitList: Array<Benefit> = Array.prototype.concat.apply([], [...this.benefitsList
          .map(group => group.items.map(benefit => benefit))
        ]);

        const suppressList = benefitList.filter(benefit => benefit.suppress).map(benefit => benefit.reference);
        let originalBenefitList = JSON.parse(JSON.stringify(this.originalBenefitsList));
        originalBenefitList = originalBenefitList.map(benefit => {
          if (suppressList.includes(benefit.reference)) {
            benefit.suppress = true;
          }
          benefit.points = isNaN(benefit.points) ? benefit.points : parseFloat(benefit.points.toString());
          delete benefit.icon;
          if (benefit.multiplicity || benefit.advCashOut) {
            benefit.maxSelection = isNaN(benefit.maxSelection) ? benefit.maxSelection : parseFloat(benefit.maxSelection.toString());
          } else {
            delete benefit.maxSelection;
          }
          if (benefit.incrementalTimespan) {
            delete benefit.incrementalTimespan;
          }
          if (benefit.consultantBenefitInfo && !benefit.consultantBenefitInfo.consultant) {
            delete benefit.consultantBenefitInfo;
          }
          if (this.program.isNew) {
            this.updateToOldCategoryNames(benefit);
          }
          if (benefit.preRangeIncrementValue) {
            delete benefit.preRangeIncrementValue;
          }
          if (benefit.preRangeIncrementUnit) {
            delete benefit.preRangeIncrementUnit;
          }
          if (benefit.preCoreUnitsPerSelection) {
            delete benefit.preCoreUnitsPerSelection;
          }
          benefit.preMaxSelection ? delete benefit.preMaxSelection : null;
          benefit.prerequisiteRule ? delete benefit.prerequisiteRule : null;
          benefit.excludeRule ? delete benefit.excludeRule : null;
          benefit.scopes && benefit.scopes.valid_complex_rules ? delete benefit.scopes.valid_complex_rules : null;
          return benefit;
        });

        const newProgram: ProgramRequest = {
          cartusClientId: this.program.cartusClientId,
          clientId: this.program.selectedClientId,
          // tslint:disable-next-line: radix
          contractId: this.program.contractSeries,          // tslint:disable-next-line: radix
          points: parseInt(this.program.totalPoints.toString()),
          policyName: this.program.atlasPolicy,
          programName: this.program.programName,
          standardProgramName: this.program.templateName,
          benefits: originalBenefitList,
          divisions: divisions,
          policyCallRequired: this.program.policyCallRequired,
          isCartusInsured: this.program.isCartusInsured
        };
        if (this.program.programExpirationDate) {
          newProgram.programExpirationDate = this.program.programExpirationDate;
        }

        this.programService.publishProgram(newProgram).subscribe(data => {
          if (data) {
            this.toastrService.info('Program set to active', null, {
              progressBar: true,
              timeOut: 2000
            });
            this.spinner.hide();
            setTimeout(() => {
              this.program.isNew = false;
              this.closeProgram();
            }, 2000);
          } else {
            this.spinner.hide();
            this.toastrService.error('Error publishing program, please try again');
          }
        }, () => {
          this.spinner.hide();
          this.toastrService.error('Error publishing program, please try again');
        });
      }
    } else {
      this.toastrService.error(`${this.program.atlasPolicy ? null : 'Policy'}${this.program.atlasPolicy &&
        this.program.contractSeries ? null : ' and '}${this.program.contractSeries ? null : 'Legacy Contract'} is required to publish the Program.`);
    }

  }


  closeProgram() {
    if (this.program && this.program.isNew) {
      const dialog = this.dialog.open(ProgramCloseWarningComponent, {
        width: '300px'
      });
      dialog.afterClosed().subscribe(data => {
        if (data) {
          this.close.emit(true);
          //this.programService.benefitListSub.next({ benefits: [] });
        }
      });
    } else {
      this.close.emit(true);
      //this.programService.benefitListSub.next({ benefits: [] });

    }
  }

  /**
   * To download program as PDF
   */
  downloadProgram() {
    if (this.benefitsList) {
      const filteredBenefits = this.benefitsList.map(category => {
        return {
          group: category.group,
          items: category.items.filter(item => !item.suppress),
          points: category.items.filter(benefit => benefit.points.toString() === 'Guaranteed').
            concat(category.items.filter(benefit => benefit.points.toString() !== 'Guaranteed'))
        };
      }).filter(category => category.items.length !== 0);
      pdfMake.createPdf(this.pdfgenerator.generateDocumentDefinition(this.program, this.program.divisions,
        filteredBenefits, this.clientDetails)).download(this.program.programName);
    }
  }

  deleteisNewlyAdded(benefit: Benefit) {
      benefit.isNewlyAddedRef = !benefit.isNewlyAddedRef;
      // this.addBenefitToUpdatedList(benefit);
      // this.updateProductDetails(benefit, 'isNewlyAddedRef');
  }


  deleteBenefit(benefit: Benefit) {
    //if (this.program.isNew || this.program.draft) {
      benefit.suppress = !benefit.suppress;
      this.updateProductDetails(benefit, 'suppress');
      this.addBenefitToUpdatedList(benefit);
   // }
  }
  groupIndex: number = 0;
  itemIndex: number = 0;

  cloneBenefit(benefit: Benefit, el) {
    this.groupIndex = this.benefitsList.findIndex(xs => xs.group === benefit.category)
    this.itemIndex = this.benefitsList[this.groupIndex].items.findIndex((list: Benefit) => list.reference === benefit.reference)
    if (benefit.clonedCopy) {
      delete this.benefitsList[this.groupIndex].items[this.itemIndex].clonedCopy;
      delete this.benefitsList[this.groupIndex].items[this.itemIndex - 1].parentClonedCopy;
      this.addBenefitToUpdatedList(this.benefitsList[this.groupIndex].items[this.itemIndex]);
    } else {
      var re = "_BBClone";
      let cloneBenefit = JSON.parse(JSON.stringify(benefit));
      this.benefitsList[this.groupIndex].items.splice(this.itemIndex + 1, 0, {
        ...cloneBenefit,
        displayName: "Clone of " + benefit.displayName,
        reference: benefit.reference.includes("_BBClone_") ? benefit.reference.slice(0, -1) + (+benefit.reference.slice(-1) + 1) : benefit.reference + "_BBClone_" + this.cloneBenefitCount,
        isCloned: true,
        clonedCopy: true,
        editTitle: true,
        clonedFrom: benefit.reference.includes("_BBClone_") ? benefit.reference.slice(0, benefit.reference.lastIndexOf(re)) : benefit.reference
      });
      this.cloneBenefitCount++;

      this.benefitsList[this.groupIndex].items[this.itemIndex].parentClonedCopy = true;
      this.originalBenefitsList.push(this.benefitsList[this.groupIndex].items[this.itemIndex + 1]);

      setTimeout(() => {
        this.focusable.last._elementRef.nativeElement.focus();
        console.log(this.title);
        this.title.last.nativeElement.focus();
        console.log(this.title);
      }, 0);
    }
    this.publishDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
      (item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')) || item.clonedCopy);
    }).length > 0
  }

  discardBenefit(benefit, j, i) {
    this.cloneBenefitCount--;
    this.benefitsList[j].items.splice(i, 1);
    delete this.benefitsList[j].items[this.benefitsList[j].items.findIndex(item => benefit.clonedFrom === item.reference)].parentClonedCopy;
    this.originalBenefitsList.splice(this.originalBenefitsList.findIndex(item => benefit.reference === item.reference), 1);
    this.publishDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
      item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2') && item.clonedCopy);
    }).length > 0
  }
  /**
   * To open edit program popup to update program title and program expiration date
   */
  editProgramDetails() {
    let editData;
    !this.validForDeactivation
      ? editData = {
        clientId: this.program.selectedClientId,
        program: this.program,
        validForDeactivation: this.validForDeactivation
      }
      : editData = {
        clientId: this.program.selectedClientId,
        program: this.program,
      };

    const dialogRef = this.dialog.open(ProgramModalComponent, {
      panelClass: 'dialog-full-width',
      data: editData
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.program.contractSeries = [];
        this.program.programExpirationDate = data.programExpirationDate !== '' ? data.programExpirationDate : null;
        this.program.policyCallRequired = data.policyCallRequired;
        this.program.programName = data.programName;
        this.program.atlasPolicy = data.atlasPolicy;
        this.program.totalPoints = data.totalPoints;
        this.program.contractSeries = data.contractSeries;
        this.program.contractSeries = [...data.contractSeries];
        this.program.isCartusInsured = data.isCartusInsured;
      }
      if (this.program && this.program.programExpirationDate) {
        (moment(moment().format('MM/DD/YYYY')).isSameOrAfter(moment(this.program.programExpirationDate).format('MM/DD/YYYY'))) ? this.validForDeactivation = false : this.validForDeactivation = true;
      }
    });
  }

  closePointMenu(benefit) {
    const index = this.originalBenefitsList.findIndex((item) => item.reference === benefit.reference);
    this.originalBenefitsList[index].points = benefit.points !== 'Guaranteed' && benefit.points && benefit.points !== 0 ?
      Number(benefit.points) : benefit.points;
    this.originalBenefitsList[index].maxSelection = +benefit.maxSelection;
    this.publishDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
      (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
    }).length > 0;
    this.draftDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
      item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2'));
    }).length > 0;
    if (this.program.draft) {
      this.addBenefitToUpdatedList(benefit);
    }
    if (benefit.incremental || benefit.incrementalCurrency) {
      if (benefit.maxSelection <= 0 || benefit.maxSelection == "") {
        benefit.maxSelection = 0;
        this.publishDisable = true;
        this.draftDisable = true;
      } else {
        this.draftDisable = false;
      }
      benefit.rangeMax = (benefit.maxSelection * benefit.rangeIncrementValue);
      this.originalBenefitsList.map(ele => {
        if (ele.reference === benefit.reference) {
          ele.rangeMax = benefit.rangeMax;
        }
        return ele;
      })
      this.addBenefitToUpdatedList(benefit);
    }
  }

  openPointMenu() {
    this.publishDisable = true;
    this.draftDisable = true;
  }

  closeRangeIncrementValueMenu(benefit, index) {
    benefit.rangeMax = this.rangeIncrementValue * benefit.preMaxSelection;
    benefit.rangeIncrementValue = this.rangeIncrementValue;
    let indexOfBenefit = this.originalBenefitsList.findIndex(item => item.reference === benefit.reference);
    if (((this.program.draft || this.program.isNew) && (Number(benefit.rangeIncrementValue) > 0) ||
      (Number(benefit.rangeIncrementValue) > 0 && benefit.incrementalTimespan && this.timeSpanValue.value))) {
        benefit.rangeIncrementValue = Number(benefit.rangeIncrementValue);
        benefit.rangeMin > 0 ? benefit.rangeMin = Number(benefit.rangeIncrementValue) : null;
        let maxSelection = benefit.rangeMax / Number(this.rangeIncrementValue);
        benefit.rangeMax = maxSelection * Number(benefit.rangeIncrementValue);
        benefit.incrementalTimespan? benefit.rangeIncrementUnit = this.timeSpanValue.value: null;
        // benefit.rangeIncrementUnit = this.timeSpanValue.value;
        this.originalBenefitsList[indexOfBenefit] = JSON.parse(JSON.stringify(benefit));


      // }
      // if(this.timeSpanValue.value) {
      //   benefit.rangeIncrementUnit = this.timeSpanValue.value;
      // }
      if (this.program.draft) {
        this.addBenefitToUpdatedList(benefit);
      }
    } else {
      benefit.rangeIncrementValue = (benefit.rangeMin > 0) ? benefit.rangeMin : this.originalBenefitsList[indexOfBenefit].rangeIncrementValue;
      const toastmsg = 'Please enter value greater than 0';
      this.toastrService.info(toastmsg, null, {
        progressBar: true,
        timeOut: 2000
      });
    }
    // this.draftDisable = false;
    this.draftDisable = this.updatedBenefits.filter((item) => {
      return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
      (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
    }).length > 0;
    // this.publishDisable = false;
    // this.publishDisable = this.originalBenefitsList.filter((item) => {
    //   return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
    //     (item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut')) || item.clonedCopy);
    // }).length > 0;
    this.publishDisable = this.originalBenefitsList.filter((item) => {
      return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
      (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
    }).length > 0;
    benefit.preMaxSelection = 1;
    this.rangeIncrementValue = 0;
    this.isChipEditable[index] = false;
  }

  openRangeIncrementValueMenu() {
    // if(this.program.draft){
    this.publishDisable = true;
    this.draftDisable = true;
    // }    
  }

  closePerPointCurrencyMenu(benefit) {
    benefit.perPointCurrency = Number(benefit.perPointCurrency);
    if (this.program.draft || this.program.isNew) {
      if (Number(benefit.perPointCurrency) > 0) {
        this.originalBenefitsList.map(ele => {
          if (ele.reference === benefit.reference) {
            ele.perPointCurrency = benefit.perPointCurrency;
          }
          return ele;
        });
        this.addBenefitToUpdatedList(benefit);
      } else {
        this.originalBenefitsList.map(ele => {
          if (ele.reference === benefit.reference) {
            benefit.perPointCurrency = ele.perPointCurrency;
          }
        });
        const toastmsg = 'Please enter value greater than 0';
        this.toastrService.info(toastmsg, null, {
          progressBar: true,
          timeOut: 2000
        });
      }
      this.draftDisable = false;
      this.publishDisable = this.originalBenefitsList.filter((item) => {
        return (item.multiplicity ? !item.maxSelection || (item.points !== 0 && !item.points) :
        (item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')) || item.clonedCopy);
      }).length > 0
    }
  }

  /**
   * To update rangeMax value of benefit object
   * @param benefit benefit
   * @param rangeMax modified field
   */
  editRangeMax(benefit) {
    if (benefit.incremental) {
      benefit.maxSelection = benefit.rangeMax / benefit.rangeIncrementValue;
      this.addBenefitToUpdatedList(benefit);
    }
  }

  /**
   * @param benefit benefit
   * @param flag editIncrementCurrency[i] value
   * called on icon click for Dollar Per Point card
   */
  editMaxSelection(benefit, flag) {
    if (benefit.incrementalCurrency) {
      isNaN(benefit.rangeMax) ? benefit.rangeMax = 0 : null;
      benefit.maxSelection = benefit.rangeMax / benefit.rangeIncrementValue;
      (benefit.rangeMax / benefit.rangeIncrementValue === 0) ? benefit.maxSelection = 0 :
        this.addBenefitToUpdatedList(benefit);

      let checkLength = this.updatedBenefits.filter((item) => {
        return (item.multiplicity ? !(item.maxSelection) || (item.points !== 0 && !item.points) :
        (item.perPointCurrency ? (+item.maxSelection <= 0) : item.points !== 0 && !item.points && !item.hasOwnProperty('advCashOut') && !item.hasOwnProperty('advCashOutV2')));
      }) //.length > 0;
      !flag ? this.publishDisable = checkLength.length > 0 : this.publishDisable = true;
      !flag ? this.draftDisable = checkLength.length > 0 : this.draftDisable = true;
    }
  }

  /**
   * To update product object
   * @param benefit benefit
   * @param field modified field
   */
  updateProductDetails(benefit, field: 'title' | 'desc' | 'points' | 'consultantBenefitInfo' | 'rules' | 'restriction' | 'maxSelection' | 'suppress' | 'isNewlyAdded' | 'isNewlyAddedRef' |'productSubDetails') {
    const index = this.originalBenefitsList.findIndex((item) => item.reference === benefit.reference);
    if (field === 'title') {
      this.originalBenefitsList[index].displayName = benefit.displayName;
    } else if (field === 'desc') {
      this.originalBenefitsList[index].description = benefit.description;
    } else if (field === 'consultantBenefitInfo') {
      this.originalBenefitsList[index].consultantBenefitInfo = benefit.consultantBenefitInfo;
    } else if (field === 'rules') {
      benefit.exclusions ? this.originalBenefitsList[index].exclusions = [...benefit.exclusions] : null;
      benefit.prerequisites ? this.originalBenefitsList[index].prerequisites = [...benefit.prerequisites] : null;
      benefit.prerequisiteRule ? this.originalBenefitsList[index].prerequisiteRule = [...benefit.prerequisiteRule] : null;
      benefit.excludeRule ? this.originalBenefitsList[index].excludeRule = [...benefit.excludeRule] : null;
    } else if (field === 'restriction') {
      benefit.scopes ? (benefit.scopes.valid_complex_rules = this.getNonDeletedComplex_Rules(benefit)) : null;
      benefit.scopes ? this.originalBenefitsList[index].scopes = { ...benefit.scopes } : null;
    } else if (field === 'maxSelection' && (benefit.advCashOut || benefit.advCashOutV2)) {
      benefit.points = 0;
      this.originalBenefitsList[index].tierConfigs = benefit.tierConfigs;
      benefit.maxSelection ? this.originalBenefitsList[index].maxSelection = benefit.maxSelection : null;
      benefit.pointsPerSelection ? this.originalBenefitsList[index].pointsPerSelection = benefit.pointsPerSelection: null;
    } else if (field === 'suppress') {
      this.originalBenefitsList[index].suppress = benefit.suppress;
    } else if (field === 'isNewlyAdded') {
      this.originalBenefitsList[index].isNewlyAdded = benefit.isNewlyAdded;
    } else if (field === 'isNewlyAddedRef') {
      this.originalBenefitsList[index].isNewlyAddedRef = benefit.isNewlyAddedRef;
    } else if (field === 'productSubDetails') {
      this.originalBenefitsList[index].productSubDetails = benefit.productSubDetails;
    } else {
      this.originalBenefitsList[index].points = benefit.points;
    }
    
  }

  /**
   * To be invoked on click of edit icon for description
   * @param benefit benefit
   */
  editProgramDescription(benefit) {
    if (benefit.editDescription) {
      this.updateProductDetails(benefit, 'desc');
      delete benefit.editDescription;
      if (this.program.draft) {
        this.addBenefitToUpdatedList(benefit);
      }
      if (benefit.points) {
        this.draftDisable = false;
        this.publishDisable = false;
      }
    } else {
      benefit.editDescription = true;
      this.draftDisable = true;
      this.publishDisable = true;
    }
  }
  /**
   * To be invoked on click of edit icon for title
   * @param index index of benefit
   * @param benefit benefit
   */
  editProgramTitle(benefit) {
    if (benefit.editTitle) {
      this.updateProductDetails(benefit, 'title');
      delete benefit.editTitle
      if (this.program.draft) {
        this.addBenefitToUpdatedList(benefit);
      }
    } else {
      benefit.editTitle = true;
    }
  }


  openDialog(benefit: any) {
    //const prerequisitesBenefit = this.originalBenefitsList.filter(item => { //console.log(item.prerequisites.includes(benefit.reference), item);
    //  item.prerequisites && item.prerequisites.includes(benefit.reference) && item.reference !== benefit.reference});

    benefit.scopes.hasOwnProperty('complex_rules')
      ? benefit.scopes.valid_complex_rules.forEach(ele => {
        benefit.scopes.complex_rules.findIndex(rule => ele.id === rule.id) >= 0 ? null : benefit.scopes.complex_rules.push(ele);
      })
      : null;
    const prerequisites = [];
    benefit.prerequisiteRule && benefit.prerequisiteRule.length > 0 ? benefit.prerequisiteRule.forEach(item => {
      const index = this.originalBenefitsList.findIndex(benefitRef => benefitRef.reference === item.reference);
      prerequisites.push(this.originalBenefitsList[index]);
    }) : null;
    //prerequisitesBenefit.length > 0 ? prerequisitesBenefit[0].prerequisites.forEach(ref => prerequisites.push(ref)) : null;
    const benefitList = this.originalBenefitsList.filter(item => item.reference !== benefit.reference && !isNaN(item.points));
    benefitList.sort((a, b) => a.displayName.localeCompare(b.displayName));
    const dialogRef = this.dialog.open(EditBenefitDialogComponent, {
      panelClass: 'dialog-full-width',
      autoFocus: false,
      data: {
        benefit: { ...benefit },
        benefitList: [...benefitList],
        allBenefitList: [...this.originalBenefitsList],
        prerequisites: prerequisites
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        //data.benefit.prerequisiteRule.sort((a, b) => a.displayName.localeCompare(b.displayName));
        benefit = { ...data.benefit };
        if (this.program.draft) {
          this.addBenefitToUpdatedList(benefit);
        }
        this.updateProductDetails(benefit, 'rules');
        this.updateProductDetails(benefit, 'restriction');
        //this.configureProgramBenefits(this.originalBenefitsList, this.program);

        if (data.prerequisites.length !== prerequisites.length) {
          benefit.prerequisiteRule = [];
          data.prerequisites.length > 0 ? data.prerequisites.forEach((item) => {
            const parentBenefit = this.originalBenefitsList.find(benefitObj => benefitObj.reference === item);
            /** to set prerequisite rule for benefit */
            benefit.prerequisiteRule && parentBenefit.displayName ?
              benefit.prerequisiteRule.push(parentBenefit) : null;
            benefit.prerequisiteRule.sort((a, b) => a.displayName.localeCompare(b.displayName));
            // (benefit.prerequisiteRule? benefit.prerequisiteRule.splice(benefit.prerequisiteRule.findIndex(rule => rule.displayName == parentBenefit.displayName), 1): benefit.prerequisiteRule = []);
            /** to set parent prerequisite */
            parentBenefit.prerequisites && parentBenefit.prerequisites.length > 0 &&
              !parentBenefit.prerequisites.includes(benefit.reference) ?
              parentBenefit.prerequisites.push(benefit.reference) : parentBenefit.prerequisites = [benefit.reference];
            /** to remove prerequisite from benefit */
            const missingPrerequiiste = prerequisites.filter(prerequisite => data.prerequisites.indexOf(prerequisite.reference) < 0);
            missingPrerequiiste.length > 0 ? missingPrerequiiste.forEach(missprerequisite => {
              const missingPrerequiisteBenefit = this.originalBenefitsList.find(benefitObj => benefitObj.reference === missprerequisite.reference);
              missingPrerequiisteBenefit.prerequisites = missingPrerequiisteBenefit.prerequisites.filter(prerequisiteItem => prerequisiteItem !== benefit.reference);
              this.program.draft ? this.addBenefitToUpdatedList(missingPrerequiisteBenefit) : null;
              this.updateProductDetails(missingPrerequiisteBenefit, 'rules');
            }) : null;

            if (this.program.draft) {
              this.addBenefitToUpdatedList(benefit);
              this.addBenefitToUpdatedList(parentBenefit);
            }
            this.updateProductDetails(benefit, 'rules');
            this.updateProductDetails(parentBenefit, 'rules');

          }) : prerequisites.forEach((item) => {
            const parentBenefit = this.originalBenefitsList.find(benefitObj => benefitObj.reference === item.reference);
            /** to set prerequisite rule for benefit */
            benefit.prerequisiteRule && benefit.prerequisiteRule.length > 0 ?
              benefit.prerequisiteRule = [] : null;
            /**to set parent prerequisite */
            parentBenefit.prerequisites && parentBenefit.prerequisites.length > 0 ?
              parentBenefit.prerequisites.splice(parentBenefit.prerequisites.findIndex(benefitObj => benefitObj === benefit.reference), 1) : null;
            if (this.program.draft) {
              this.addBenefitToUpdatedList(benefit);
              this.addBenefitToUpdatedList(parentBenefit);
            }
            this.updateProductDetails(benefit, 'rules');
            this.updateProductDetails(parentBenefit, 'rules');

          });
        }
        this.configureProgramBenefits(this.originalBenefitsList, this.program);
      }
    });
  }

  /**
   * To copy program
   */
  saveCopyAndDraft() {
    const dialogRef = this.dialog.open(CopyProgramModalComponent, {
      panelClass: 'dialog-full-width',
      data: {
        programName: this.program.programName,
        clientName: this.clientDetails.clientEntityName,
        clientNumber: this.clientDetails.clientNumber
      }
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.draftProgram(data);
      }
    });
  }

  saveCopy() {
    const dialogRef = this.dialog.open(CopyProgramModalComponent, {
      panelClass: 'dialog-full-width',
      data: {
        programName: this.program.programName,
        clientName: this.clientDetails.clientEntityName,
        clientNumber: this.clientDetails.clientNumber
      }
    });

    dialogRef.afterClosed().subscribe(copyDetails => {
      if (copyDetails) {
        const copiedProgram = { copyProgramDetails: copyDetails };
        this.programService.updateBenefitForProgram(copiedProgram, this.program.id, true, true).subscribe((data) => {
          if (data) {
            const toastmsg = 'Program Copied';
            this.toastrService.info(toastmsg, null, {
              progressBar: true,
              timeOut: 2000
            });
            this.spinner.hide();
            setTimeout(() => {
              this.program.isNew = false;
              this.closeProgram();
            }, 2000);
          } else {
            this.spinner.hide();
            const toastmsg = 'Error copying program, please try again';
            this.toastrService.error(toastmsg);
          }
        },
          () => {
            this.spinner.hide();
            const toastmsg = 'Error copying program, please try again';
            this.toastrService.error(toastmsg);
          });
      }
    });
  }

  /**
   * To be invoked save draft button clicked while program creation
   */
  draftProgram(copyProgramDetails = null, onlyCopy = false) {
    const hasCopy = copyProgramDetails ? true : false;
    const oldCategories: Array<string> = Object.keys(CategoryMapping);
    const newCategories: Array<string> = Object.values(CategoryMapping);
    const divisions = this.programDivisions.map((element: any) => {
      return (element.division_id);
    });
    if (this.program.draft) {
      this.updateBenefitForProgram(this.updatedBenefits, this.program.draft, copyProgramDetails);
      return;
    }
    this.spinner.show();


    // const benefitList: Array<Benefit> = Array.prototype.concat.apply([], [...this.benefitsList
    //   .map(group => group.items.map(benefit => benefit))
    // ]);
    const benefitList = JSON.parse(JSON.stringify(this.updatedBenefits));
    const suppressList = benefitList.filter(benefit => benefit.suppress).map(benefit => benefit.reference);
    const originalBenefitList = this.originalBenefitsList.map(benefit => {
      if (suppressList.includes(benefit.reference)) {
        benefit.suppress = true;
      }
      benefit.points = isNaN(benefit.points) ? benefit.points : parseFloat(benefit.points.toString());
      if (benefit.multiplicity || benefit.advCashOut || benefit.advCashOutV2) {
        benefit.maxSelection = isNaN(benefit.maxSelection) ? benefit.maxSelection : parseFloat(benefit.maxSelection.toString());
      } else {
        delete benefit.maxSelection;
      }
      if (this.program.isNew && (benefit.category === 'Relocation Allowances')) {
        benefit.category = 'Allowances';
      }
      if (this.program.isNew && (benefit.category === 'Before Move' ||
        benefit.category === 'Shipping' ||
        benefit.category === 'Travel & Transport' ||
        benefit.category === 'After Arrival' ||
        benefit.category === 'Ongoing Support' ||
        benefit.category === 'Allowance')) {
        this.updateToOldCategoryNames(benefit);
      }
      if (benefit.incrementalTimespan) {
        delete benefit.incrementalTimespan;
      }
      if (benefit.consultantBenefitInfo && !benefit.consultantBenefitInfo.consultant) {
        delete benefit.consultantBenefitInfo;
      }
      if (benefit.preRangeIncrementValue) {
        delete benefit.preRangeIncrementValue;
      }
      if (benefit.preRangeIncrementUnit) {
        delete benefit.preRangeIncrementUnit;
      }
      if (benefit.preCoreUnitsPerSelection) {
        delete benefit.preCoreUnitsPerSelection;
      }
      benefit.preMaxSelection ? delete benefit.preMaxSelection : null;
      benefit.editDescription || benefit.editDescription == false ? delete benefit.editDescription : null;
      benefit.editTitle || benefit.editTitle == false ? delete benefit.editTitle : null;
      benefit.prerequisiteRule ? delete benefit.prerequisiteRule : null;
      benefit.excludeRule ? delete benefit.excludeRule : null;
      benefit.scopes && benefit.scopes.valid_complex_rules ? delete benefit.scopes.valid_complex_rules : null;
      return benefit;
    });
    const newProgram: ProgramRequest = {
      cartusClientId: this.program.cartusClientId,
      clientId: this.program.selectedClientId,
      contractId: parseInt((this.program.contractSeries).toString()),
      points: parseInt(this.program.totalPoints.toString()),
      policyName: this.program.atlasPolicy,
      programName: this.program.programName,
      standardProgramName: this.program.templateName,
      benefits: JSON.parse(JSON.stringify(originalBenefitList)),
      divisions: divisions,
      policyCallRequired: this.program.policyCallRequired,
      isCartusInsured: this.program.isCartusInsured,
      draft: true
    };
    //removing maxSelection from request object
    newProgram.benefits = [...newProgram.benefits.map(benefit => {
      if (benefit.advCashOut || benefit.advCashOutV2) {
        delete benefit.maxSelection;
      }
      if (benefit.incrementalTimespan) {
        delete benefit.incrementalTimespan;
      }
      if (benefit.preRangeIncrementValue) {
        delete benefit.preRangeIncrementValue;
      }
      if (benefit.preRangeIncrementUnit) {
        delete benefit.preRangeIncrementUnit;
      }
      if (benefit.preCoreUnitsPerSelection) {
        delete benefit.preCoreUnitsPerSelection;
      }
      benefit.preMaxSelection ? delete benefit.preMaxSelection : null;
      return benefit;
    })];
    if (copyProgramDetails) {
      newProgram.copyProgramDetails = copyProgramDetails;
    }
    if (this.program.programExpirationDate) {
      newProgram.programExpirationDate = this.program.programExpirationDate;
    }

    this.programService.publishProgram(newProgram, hasCopy).subscribe(data => {
      if (data) {
        const toastMsg = hasCopy ? 'Program Copied' : 'Program set to draft';
        this.toastrService.info(toastMsg, null, {
          progressBar: true,
          timeOut: 2000
        });
        this.spinner.hide();
        setTimeout(() => {
          this.program.isNew = false;
          this.closeProgram();
        }, 2000);
      } else {
        this.spinner.hide();
        this.toastrService.error('Error drafting program, please try again');
      }
    }, () => {
      this.spinner.hide();
      this.toastrService.error('Error drafting program, please try again');
    });
  }

  updateBenefitForProgram(updatedBenefits = [], isDraft: boolean = false, copyProgramDetails = null) {
    const hasCopy = copyProgramDetails ? true : false;
    const updatedlist = updatedBenefits.map((data) => {
      delete data.editTitle;
      delete data.editDescription;
      data.points = isNaN(data.points) ? data.points : parseFloat(data.points);
      delete data.editPoint;
      if (data.multiplicity || data.advCashOut || data.advCashOutV2) {
        data.maxSelection = isNaN(data.maxSelection) ? data.maxSelection : parseFloat(data.maxSelection);
      } else {
        delete data.maxSelection;
      }
      const index = this.originalBenefitsList.findIndex(benefit => benefit.reference === data.reference);
      if (data.consultantBenefitInfo && !data.consultantBenefitInfo.consultant &&
        this.originalBenefitsList[index].consultantBenefitInfo && !this.originalBenefitsList[index].consultantBenefitInfo.consultant) {
        delete data.consultantBenefitInfo.qualifyingInput;
      }
      //changing the category to the old one
      this.updateToOldCategoryNames(data);
      data.prerequisiteRule ? delete data.prerequisiteRule : null;
      data.excludeRule ? delete data.excludeRule : null;
      data.scopes && data.scopes.valid_complex_rules ? delete data.scopes.valid_complex_rules : null;
      return data;
    });
    const requestObj: ProgramBenefitUpdateRequest = {
      draft: isDraft,
      benefits: JSON.parse(JSON.stringify(updatedlist))
    };
    //removing maxSelection only for cash out card from request object
    requestObj.benefits = [...requestObj.benefits.map(benefit => {
      if (benefit.advCashOut || benefit.advCashOutV2) {
        delete benefit.maxSelection;
      }
      if (benefit.incrementalTimespan) {
        delete benefit.incrementalTimespan;
      }
      if (benefit.preRangeIncrementValue) {
        delete benefit.preRangeIncrementValue;
      }
      if (benefit.preRangeIncrementUnit) {
        delete benefit.preRangeIncrementUnit;
      }
      if (benefit.preCoreUnitsPerSelection) {
        delete benefit.preCoreUnitsPerSelection;
      }
      if (benefit.preCoreUnitsPerSelection) {
        delete benefit.preCoreUnitsPerSelection;
      }
      benefit.preMaxSelection ? delete benefit.preMaxSelection : null;
      return benefit;
    })];
    if (copyProgramDetails) {
      requestObj.copyProgramDetails = copyProgramDetails;
    }
    this.spinner.show();
    this.programService.updateBenefitForProgram(requestObj, this.program.id, hasCopy).subscribe((data) => {
      if (data) {
        const toastmsg = isDraft ? (hasCopy ? 'Program Copied' : 'Program set to draft') : 'Program set to active';
        this.toastrService.info(toastmsg, null, {
          progressBar: true,
          timeOut: 2000
        });
        this.spinner.hide();
        setTimeout(() => {
          this.program.isNew = false;
          this.closeProgram();
        }, 2000);
      } else {
        this.spinner.hide();
        const toastmsg = isDraft ? 'Error drafting program, please try again' : 'Error publishing program, please try again';
        this.toastrService.error(toastmsg);
      }
    },
      () => {
        this.spinner.hide();
        const toastmsg = isDraft ? 'Error drafting program, please try again' : 'Error publishing program, please try again';
        this.toastrService.error(toastmsg);
      });
  }

  addBenefitToUpdatedList(benefit) {
    const index = this.updatedBenefits.findIndex((data) => data.reference === benefit.reference);
    if (index !== -1) {
      this.updatedBenefits[index] = benefit;
    } else {
      this.updatedBenefits.push(benefit);
    }
  }

  /**
  * Get Deactivate program
  */
  deactivateProgram() {
    this.deactivateModalRef = this.dialog.open(DeactivateProgramModalComponent, {
      data: {
        programList: [this.program.programName],
        programIdList: [this.program.id],
        label: 'Deactivate'
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.deactivateModalRef.afterClosed().subscribe((data: any) => {
      if (data.isSuccessful) {
        this.program.programExpirationDate = data.data;
        this.displayToastr("Deactivated successfully");
      }
    });
  }

  /**
   * To display informative messages on screen
   * @param message message to be displayed
   */
  displayToastr(message) {
    this.toastrService.info(message, null, {
      closeButton: true,
      enableHtml: true,
      disableTimeOut: false,
    });
  }

  openConsultantModal(benefit: any) {
    if ((benefit.consultantBenefitInfo && !benefit.consultantBenefitInfo.consultant) || !benefit.consultantBenefitInfo) {
      const dialogRef = this.dialog.open(ConsultantOnlyModalComponent, {
        panelClass: 'dialog-full-width',
        autoFocus: false,
        disableClose: true,
        data: {
          benefit: benefit
        }
      });

      dialogRef.afterClosed().subscribe(data => {
        if (data) {
          benefit.consultantBenefitInfo = data;
          if (this.program.draft) {
            this.addBenefitToUpdatedList(benefit);
          }
          this.updateProductDetails(benefit, 'consultantBenefitInfo');
        }
      });
    } else if (benefit.consultantBenefitInfo) {
      benefit.consultantBenefitInfo.qualifyingInput = 0;
      this.addBenefitToUpdatedList(benefit);
      this.updateProductDetails(benefit, 'consultantBenefitInfo');
    }
  }

  openPointsLogicModal(benefit: any) {
    const dialogRef = this.dialog.open(PointsLogicModalComponent, {
      panelClass: 'dialog-full-width',
      autoFocus: false,
      data: {
        benefit: { ...benefit },
        program: this.program
      }
    });
    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        benefit.maxSelection = data.benefit.maxSelection;
        benefit.tierConfigs = data.benefit.tierConfigs;
        benefit.pointsPerSelection? benefit.pointsPerSelection = data.benefit.pointsPerSelection: null;
        if (this.program.draft) {
          this.addBenefitToUpdatedList(benefit);
        }
        this.updateProductDetails(benefit, 'maxSelection');
        this.configureProgramBenefits(this.originalBenefitsList, this.program);
      }
    });
  }

  deleteProgram(): void {
    this.deactivateModalRef = this.dialog.open(DeactivateProgramModalComponent, {
      data: {
        programList: [this.program.programName],
        programIdList: [this.program.id],
        label: 'Delete'
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.deactivateModalRef.afterClosed().subscribe((data: any) => {
      if (data.isSuccessful) {
        this.displayToastr("Deleted successfully");
        this.close.emit(true);
      }
    });
  }
  public enabledChipToEdit(i,j?:any) {
    if (this.program.draft || this.program.isNew) {
      this.publishDisable = true;
      this.draftDisable = true;
    }
     //this.maxSelection = this.benefit.rangeMax / this.rangeIncrementValue;
     this.isChipEditable[i] = true;
     if(j && this.benefitsList[j].items[i].hybridTempLiving ){
       this.benefitsList[j].items[i]['preRangeIncrementValue'] =this.benefitsList[j].items[i]['rangeIncrementValue'];
       this.benefitsList[j].items[i]['preRangeIncrementUnit'] =this.benefitsList[j].items[i]['rangeIncrementUnit'];
       this.benefitsList[j].items[i]['preCoreUnitsPerSelection'] =this.benefitsList[j].items[i]['coreUnitsPerSelection'];
     }
     this.benefitsList[j].items[i]['incremental'] ? this.benefitsList[j].items[i]['preMaxSelection'] = this.benefitsList[j].items[i].rangeMax/this.benefitsList[j].items[i]['rangeIncrementValue'] : null;
  }
  // public disableChip(index) {
  //   this.isChipEditable[index] = false;
  // }
  closeHybrid(i, j) {
    this.isChipEditable[i] = !this.isChipEditable[i];
    this.benefitsList[j].items[i].rangeIncrementValue = this.benefitsList[j].items[i].preRangeIncrementValue;
    this.benefitsList[j].items[i].rangeIncrementUnit = this.benefitsList[j].items[i].preRangeIncrementUnit;
    this.benefitsList[j].items[i].coreUnitsPerSelection = this.benefitsList[j].items[i].preCoreUnitsPerSelection;
    delete this.benefitsList[j].items[i].preRangeIncrementValue;
    delete this.benefitsList[j].items[i].preRangeIncrementUnit;
    delete this.benefitsList[j].items[i].preCoreUnitsPerSelection;
    if ((this.program.draft || this.program.isNew) && !this.isChipEditable[i]) {
      this.publishDisable = false;
      this.draftDisable = false;
    }

  }

  /** to enable backspace for input in mat-chip */
  onMatChipKeyPress(event, value, j, i) {
    // if(Math.floor(this.benefitsList[j].items[i].rangeIncrementValue  / 10) > 0) {
    //   this.benefitsList[j].items[i].rangeIncrementValue = Math.floor(this.benefitsList[j].items[i].rangeIncrementValue  / 10);
    // }
    if (value) {
      value = value.toString();
      value = value.slice(0, -1);
      this.rangeIncrementValue = value;
      // this.benefitsList[j].items[i].rangeIncrementValue = value;

    }
  }

  onMatChipKeyPressCore(event, value, j, i) {
    if (value) {
      value = value.toString();
      value = value.slice(0, -1);
      this.benefitsList[j].items[i].coreUnitsPerSelection = value;
    }
  }

  benefitSetting(benefit: Benefit) {
    const dialogRef = this.dialog.open(BenefitSettingDialogComponent, {
      data: {
        program: this.program,
        validForDeactivation: this.validForDeactivation,
        clientId: this.program.selectedClientId,
        description : benefit.description ,
        displayName : benefit.displayName,
        internationalProductName :benefit.productSubDetails.internationalProduct.productName,
        usDomesticProductName :benefit.productSubDetails.usDomesticProduct.productName,
        internationalSubProductName :benefit.productSubDetails.internationalProduct.subProductName,
        usDomesticSubProductName  :benefit.productSubDetails.usDomesticProduct.subProductName,
        internationlRevision :benefit.productSubDetails.internationalProduct.requiresRevision,
        usDomesticRevision :benefit.productSubDetails.usDomesticProduct.requiresRevision,
      },
      width: '35vw', 
      height:'44.5vw',
      autoFocus: false,
      restoreFocus: false
    });
    dialogRef.afterClosed().subscribe(data =>{
      if (data) {
        benefit.productSubDetails.internationalProduct.productName = data.data.productSubDetails.internationalProduct.productName;
        benefit.productSubDetails.internationalProduct.subProductName = data.data.productSubDetails.internationalProduct.subProductName;
        benefit.productSubDetails.internationalProduct.requiresRevision = data.data.productSubDetails.internationalProduct.requiresRevision;
        benefit.productSubDetails.usDomesticProduct.productName = data.data.productSubDetails.usDomesticProduct.productName
        benefit.productSubDetails.usDomesticProduct.subProductName = data.data.productSubDetails.usDomesticProduct.subProductName
        benefit.productSubDetails.usDomesticProduct.requiresRevision = data.data.productSubDetails.usDomesticProduct.requiresRevision;
        if (this.program.draft) {
          this.addBenefitToUpdatedList(benefit);
        }
        this.updateProductDetails(benefit, 'productSubDetails');
        this.configureProgramBenefits(this.originalBenefitsList, this.program);
      }
    })
   }
  
}



