import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProviderComponent } from './provider.component';
import { ProviderRoutingModule } from './provider-routing.module';
//import { EmployeeProvidersComponent } from './components/employee-providers/employee-providers.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DashboardModule } from '../dashboard/dashboard.module';
import { SharedTradionalOrderModule } from '../../shared/shared-tradional-order.module';

@NgModule({
  declarations: [ProviderComponent,
    // EmployeeProvidersComponent
    ],
  imports: [
    CommonModule,
    ProviderRoutingModule,
    MatExpansionModule,
    MatDividerModule,
    FlexLayoutModule,
    DashboardModule,
    SharedTradionalOrderModule
  ]
})
export class ProviderModule { }
