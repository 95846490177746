import { Component, OnInit, Input, Inject, Injector } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProgramManagementService } from '../../services/program-management.service';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { RemoteLoggingService } from './../../../../core/services/remote-logging.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { UserType, UserTypeService } from '../../services/user-type.service';
import { DatePipe } from '@angular/common';
import moment from 'moment';
@Component({
  selector: 'app-program-modal',
  templateUrl: './program-modal.component.html',
  styleUrls: ['./program-modal.component.scss']
})

export class ProgramModalComponent implements OnInit {

  programForm: FormGroup;

  selectedClientId: string;

  cartusClientId: string;

  _subsList: Subscription;

  programList: Array<{ name: string }> = [];

  clientPolicies: Array<any> = [];
  /** For program Expiration Date manipulation */
  programExpirationDate: any;

  userRole: UserType;

  minDate: Date = new Date();

  originalClientPolicies: Array<any> = [];
  atlasPolicyFilterData: any;
  contractSeries = [];
  contractSeriesList: Array<any> = [];
  copyclientPolicies: any[];
  isDrafted: false;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private programService: ProgramManagementService,
    private spinner: NgxSpinnerService,
    private logSrv: RemoteLoggingService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<ProgramModalComponent>,
    private userTypeService: UserTypeService,
    public datepipe: DatePipe,
    public injector: Injector,

    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.selectedClientId = data.clientId;
    this.generateProgramForm();
  }

  ngOnInit() {
    this.userRole = this.userTypeService.userType;
    this.getStandardPrograms();
    this.getClientPolicies();
    this.getBaseContract();
    this.minDate = new Date(this.minDate.setDate(this.minDate.getDate() + 1));
    if (this.data.program) {
      this.data.program.draft ? this.isDrafted = this.data.program.draft : null;
    }
  }


  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  isNullCheck(obj: Object, key: string) {
    try {
      // tslint:disable-next-line: triple-equals
      return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }

  generateProgramForm() {
    const check = this.isNullCheck;
    this.programForm = this.fb.group({
      programName: [this.data ? check(this.data.program, 'programName') : '', [Validators.required, Validators.maxLength(49)]],
      templateName: [this.data ? check(this.data.program, 'templateName') : '', Validators.required],
      totalPoints: [this.data ? check(this.data.program, 'totalPoints') : '', [Validators.required, Validators.max(99999)]],
      contractSeries: [ this.data ? check(this.data.program, 'contractSeries') :'', [Validators.required, Validators.max(999)]],
      programExpirationDate: [this.data ? check(this.data.program, 'programExpirationDate') : null],
      atlasPolicy: new FormArray( this.generateAtlasPoliciesGroup()),
      policyCallRequired: [this.data && check(this.data.program, 'policyCallRequired') !== '' ?
      this.data.program.policyCallRequired : true],
      isCartusInsured: [this.data && check(this.data.program, 'isCartusInsured') !== '' ?
      this.data.program.isCartusInsured : false]
    });
  }

  generateAtlasPoliciesGroup(): Array<FormGroup> {
    const check = this.isNullCheck;
    const fb = this.injector.get(FormBuilder);
    let policyData;
    if (this.data.program && this.data.program.atlasPolicy != undefined) {
      policyData = this.data.program.atlasPolicy.map(element => {
        return fb.group({
          atlasPolicyName: [element, Validators.required]
        })
      })
      return policyData
    }
    else {
      return [fb.group({
        atlasPolicyName: ['', Validators.required]
      })];
    }
  }

  getStandardPrograms() {
    this.spinner.show();
    this.programService.getStandardPrograms().subscribe(data => {
      this.spinner.hide();
      if (data) {
        this.cartusClientId = data.clientId;
        this.programList = data.programs;
      } else {
        this.programList = [];
        this.logSrv.logError('Failed to load Standard Programs');
      }
    }, () => {
      this.logSrv.logError('Failed to load Standard Programs');
    });
  }

  getFilteredPolicies(curIndex) {
    let policyTypesList = [];
    let existingPolicies: Array<string> = ((this.programForm.controls.atlasPolicy as FormArray).
      getRawValue() as any).map((policy, index) => policy.atlasPolicyName);
    existingPolicies = existingPolicies.filter((policy, index) => index !== curIndex);
    policyTypesList[curIndex] = [...this.originalClientPolicies];
    policyTypesList[curIndex] = policyTypesList[curIndex].filter((policy, index) => !existingPolicies.includes(policy));
    this.clientPolicies[curIndex] = policyTypesList[curIndex];
    this.atlasPolicyFilterData = this.clientPolicies[curIndex];
  }

  getBaseContract() {
    this.programService.getBaseContract(this.selectedClientId).subscribe(data => {
      if (data) {
         data.forEach(element => {
          this.contractSeriesList.push(element.baseContract);
         });
      } else {
        this.logSrv.logError('Failed to load Client Policies');
      }
    }, () => {
      this.logSrv.logError('Failed to load Client Policies');
    });
  }

  getClientPolicies() {
    this.programService.getClientPolicies(this.selectedClientId).subscribe(data => {
      if (data) {
        this.originalClientPolicies = data.policies;
        if (this.programForm.get('atlasPolicy')) {
          const {
            policyTypes
          } = this.
            initPolicy(
              (this.programForm.controls.atlasPolicy as FormGroup).value
              , this.originalClientPolicies)
          this.clientPolicies = policyTypes;
        }
      } else {
        this.logSrv.logError('Failed to load Client Policies');
      }
    }, () => {
      this.logSrv.logError('Failed to load Client Policies');
    });
  }

  initPolicy(policyData, policyTypesList: Array<Array<string>>): { policyTypes: Array<Array<string>> } {
    const usedPolicyTypes = [];
    if (this.programForm.get("atlasPolicy").value[0].atlasPolicyName === "") {
      policyTypesList = [];
      policyTypesList[0] = [...this.originalClientPolicies];
      return { policyTypes: policyTypesList };
    }
    policyData.forEach((element, index) => {
      usedPolicyTypes.push(element.atlasPolicyName);
    });
    policyTypesList = [...this.originalClientPolicies];
    policyTypesList.forEach((value, index) => {
      policyTypesList[index] = this.originalClientPolicies;
      policyTypesList[index] = [...policyTypesList[index].filter(policy => !usedPolicyTypes.includes(policy))];
    });
    policyData.forEach((element, index) => {
      policyTypesList[index].push(element.atlasPolicyName);
    });
    return { policyTypes: policyTypesList };
  }


  submitProgramForm() {
    const programFormValue = this.programForm.value;
    let atlasPoliciesData = [];
    this.programForm.value.atlasPolicy.forEach(element => {
      atlasPoliciesData.push(element.atlasPolicyName);
    });
    programFormValue.atlasPolicy = atlasPoliciesData;
    this.programService.checkDuplicatePrograms(this.selectedClientId, programFormValue.programName).subscribe(data => {
      if (data && !this.data.program) {
        if (data.programs && Array.isArray(data.programs) && data.programs.length === 0) {
          this.dialogRef.close(Object.assign(programFormValue, {
            selectedClientId: this.selectedClientId,
            cartusClientId: this.cartusClientId,
            isNew: true,
            divisions: [],
            programExpirationDate: programFormValue.programExpirationDate !== '' ? `${moment( programFormValue.programExpirationDate).format().split('T')[0]}T00:00:00.000Z` : null,
            policyCallRequired: programFormValue.policyCallRequired,
            isCartusInsured: programFormValue.isCartusInsured,
            clientPolicy: atlasPoliciesData,
            contractId: programFormValue.contractSeries,

          }, ));
        } else {
          this.toastrService.error('Program name already exists', 'Error');
        }
      } else if (this.data.program && this.data.program.isNew) {
        this.dialogRef.close(Object.assign(programFormValue, {
          programExpirationDate: programFormValue.programExpirationDate !== '' ? `${moment( programFormValue.programExpirationDate).format().split('T')[0]}T00:00:00.000Z` : null,
          programName: programFormValue.programName,
          policyCallRequired: programFormValue.policyCallRequired,
          isCartusInsured: programFormValue.isCartusInsured
        }));
      } else if (this.data.program && !this.data.program.isNew) {
        if (data.programs.length > 0 && !(this.data.program.programName === programFormValue.programName)) {
          this.toastrService.error('Program name already exists', 'Error');
        } else {
          const req = {
            'programId': this.data.program.id,
            'clientId': this.selectedClientId,
            'fieldsToUpdate': {
              'programName': programFormValue.programName
            }
          };
          const isExpiryDateUpdatedEmpty = !programFormValue.programExpirationDate && this.data.program.programExpirationDate  ?
            true : false;
          isExpiryDateUpdatedEmpty || programFormValue.programExpirationDate ? req.fieldsToUpdate['programExpirationDate'] =  
            `${moment( programFormValue.programExpirationDate).format().split('T')[0]}T00:00:00.000Z`  || '' : null;
          if (this.isDrafted) {
            req.fieldsToUpdate['policyCallRequired'] = programFormValue.policyCallRequired;
            req.fieldsToUpdate['clientPolicy'] = atlasPoliciesData;
            req.fieldsToUpdate['contractId'] = programFormValue['contractSeries'] ;
            req.fieldsToUpdate['totalPoints'] = parseInt(programFormValue.totalPoints);
            req.fieldsToUpdate['isCartusInsured'] = programFormValue.isCartusInsured;
          }
          this.programService.updateProgram(req).subscribe(data => {
            this.dialogRef.close(Object.assign(programFormValue, {
              programExpirationDate: programFormValue.programExpirationDate,
              programName: programFormValue.programName
            }));
          });
        }
      } else {
        this.logSrv.logError('Error checking duplicate program');
      }
    }, () => {
      this.logSrv.logError('Error checking duplicate program');
    });
  }

  onDismiss() {
    if (this.programForm.dirty && !this.data.program) {
      // tslint:disable-next-line: no-use-before-declare
      const dialog = this.dialog.open(ProgramCloseWarningComponent, {
        width: '300px'
      });
      dialog.afterClosed().subscribe(data => {
        if (data) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  onSeriesRemoved(contractSeries: number) {
    const series = this.programForm.controls.contractSeries.value as number[];
    this.removeFirst(series, contractSeries);
    this.programForm.controls.contractSeries.setValue(series);
  }
  
  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
 
  atlasPolicyFilter(event, i) {
    const val = event.target.value.toLowerCase();
    const temp = this.atlasPolicyFilterData.filter((e) => {
      return e.toLowerCase().indexOf(val) !== -1 || !val
    })
    this.clientPolicies[i] = temp;
  }

  addAtlasPolicy() {
    const creds = this.programForm.controls.atlasPolicy as FormArray;
    creds.push(this.fb.group({
      atlasPolicyName: ['', Validators.required]
    }));
  }

  removePolicy(i) {
    (this.programForm.controls.atlasPolicy as FormArray).removeAt(i);
  }


}

@Component({
  selector: 'app-program-close-warning',
  template: `
    <h1 mat-dialog-title>Close?</h1>
    <div mat-dialog-content>Are you sure you want to cancel the Program creation?</div>
    <div mat-dialog-actions align="end" style="padding-bottom: 20px;">
      <button mat-button mat-dialog-close class="mat-button text-button">No</button>
      <button mat-button (click)="close()" class="mat-button contained-button" cdkFocusInitial>Yes</button>
    </div>
  `,
})
export class ProgramCloseWarningComponent {

  constructor(
    public dialogRef: MatDialogRef<ProgramCloseWarningComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  close() {
    this.dialogRef.close(true);
  }

}
