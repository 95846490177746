<div class="admin-container">
  <div class="admin-content-container">

    <mat-card class="mat-card-client-details" fxLayout fxLayoutAlign="start start" fxLayoutAlign.xs="start start">
      <div class="help-icon-wrapper">
        <span>?</span>
      </div>
      <div class="client-detail" fxFlex.xs="100">
        <div class="client-name" *ngIf="clientDetails">{{clientDetails.clientEntityName}}</div>
        <div fxLayout class="additional-detail" fxLayoutAlign="start center">
          <span class="detail-header">(Client ID: </span>
          <span class="detail-value" *ngIf="clientDetails"> {{ clientDetails.clientNumber}}</span>
          )
        </div>
        <div fxLayout class="additional-detail" fxLayoutAlign="start center">
          <span class="detail-header" *ngIf="clientDetails && clientDetails.clientAddress">Location: </span>
          <span class="detail-value" *ngIf="clientDetails && clientDetails.clientAddress"> {{(clientDetails.clientAddress) ? clientDetails.clientAddress.city: ''}},
            {{(clientDetails.clientAddress) ? clientDetails.clientAddress.state : ''}},  {{(clientDetails.clientAddress) ? clientDetails.clientAddress.country : ''}}</span>
        </div>
      </div>
      <div fxLayoutAlign="end start" class="close">
        <button  mat-button (click)="close()" aria-label="Back to Client List" title="Back to Client List">
          <mat-icon aria-hidden="false" aria-label="back icon">close</mat-icon>          
        </button>
      </div>
    </mat-card>


    <mat-accordion class="client-details-panel" multi="true">
      <!--Additional Details -->
      <mat-expansion-panel class="candidate-detail">
        <mat-expansion-panel-header class="division-expansion">
          <span class="panel-heading">Client Details</span>
        </mat-expansion-panel-header>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>