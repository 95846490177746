
<div fxLayout="row" 
fxFlex="100%" 
class="benefits-dialer-wrapper" 
fxLayoutAlign="space-evenly center" 
fxShow="true" 
fxShow.sm="false" 
fxShow.xs="false" role="group" tabindex="0">
<div fxLayout="column" fxFlex="50%" fxLayoutAlign="flex-start flex-start" role="group" class="total-points-category-address-div">
    <div class="total-points" role="note">
        <p class="move-details">Move Locations</p>
        <p class="move-location"> {{moveData?.departureAddr.state}} , {{moveData?.departureAddr.countryCode}} > {{moveData?.destinationAddr.state}} , {{moveData?.destinationAddr.countryCode}}
        <span class="tooltiptext">Move Locations: <br>{{moveData?.departureAddr.streetLine1}},<br> {{moveData?.departureAddr.city}}, {{moveData?.departureAddr.state}}, {{moveData?.departureAddr.countryCode}}, {{moveData?.departureAddr.postalCode}} > <br> {{moveData?.destinationAddr.streetLine1}},<br> {{moveData?.destinationAddr.city}}, {{moveData?.destinationAddr.state}}, {{moveData?.destinationAddr.countryCode}}, {{moveData?.destinationAddr.postalCode}}</span>
         </p>
    </div>
    <div fxLayout="row">
            <div class="total-points" [attr.aria-label]="'Total Points ' + totalPoints" role="note">
                <span class="rem-points-span"><p class="f-s-44">{{totalPoints - totalPointsSelected}}</p><p class="txtOfPoints">of {{totalPoints}}</p></span>
                <p>{{'bb.dialer.totalPoints' | translate}}</p>
            </div>
            <div class="legends" role="group">
                <p *ngIf = "filters.includes('Before Move')"><span class="before-move"></span><span>{{ 'bb.dialer.beforeMove' | translate }}</span></p>
                <p *ngIf = "filters.includes('Shipping')"><span class="shipping"></span><span>{{ 'bb.dialer.shipping' | translate }}</span></p>
                <p *ngIf = "filters.includes('Travel & Transport')"><span class="travel"></span><span>{{ 'bb.dialer.travel' | translate }}</span></p>
                <p *ngIf = "filters.includes('After Arrival')"><span class="arrival"></span><span>{{ 'bb.dialer.arrival' | translate }}</span></p>
                <p *ngIf = "filters.includes('Ongoing Support')"><span class="onOngoing-Support"></span><span>{{ 'bb.dialer.ongoing' | translate }}</span></p>
                <p *ngIf = "filters.includes('Allowance')"><span class="allowance"></span><span>{{ 'bb.dialer.allowance' | translate }}</span></p>
            </div>
    </div>
</div>
<div class="chart-wrapper" fxLayout="row" fxLayoutAlign="center center" role="group">
    <app-benefit-donut-chart></app-benefit-donut-chart>
    <div class="chart-text" fxLayout="row" fxLayoutAlign="center center" role="note">
        <p [attr.aria-label]="'Total Points Selected ' + totalPointsConfirmed" role="note">
            <span class="no-of-benefits" [countUp]="totalPointsConfirmed" [options]="dialerCountOptions"></span>
            <span class="points-selected">Points</span>
            <span class="points-selected">Redeemed</span>
        </p>
    </div>
</div>
</div>

<div fxFlex="100%" 
[ngClass]="showDialer ? 'dialer height180' : 'dialer height69'" 
fxShow="false" 
fxShow.sm="true" 
fxShow.xs="true" 
fxLayout="column">
    <div>
        <p class="move-details">Move Locations: {{moveData?.departureAddr.state}} , {{moveData?.departureAddr.countryCode}} > {{moveData?.destinationAddr.state}} , {{moveData?.destinationAddr.countryCode}}</p>
    </div>
    <div class="pos-rel" fxLayoutAlign="space-evenly center" [fxShow]="!showDialer" role="group">
        <span class="total-points-text" fxFlex="14%" fxFlex.sm="10%" fxFlex.xs="10%">Total points</span>
        <span class="total-points" fxFlex="15%" [countUp]="totalPoints" [options]="dialerCountOptions"></span>
        <div class="progress-bar-wrapper" fxFlex="50%" fxLayoutAlign="start center">
            <div class="progress-bar"
                [style.width]="((totalPointsSelected / totalPoints) * 100) + '%'"></div>
            <span class="progress-percent" 
                [countUp]="totalPointsSelected" 
                [options]="dialerCountOptions"></span>
        </div>
        <button class="mat-icon-wrapper" (click)="showDialer = !showDialer" aria-label="Toggle Dialer height">
            <mat-icon class="arrow-down" aria-hidden="false" aria-label="arrow icon">keyboard_arrow_down</mat-icon>
        </button>
        <p class="points-selected-text">Points Selected</p>
    </div>
    <div fxLayout="row" fxFlex="100%" 
        class="benefits-dialer-wrapper" 
        fxLayoutAlign.sm="space-between center" 
        fxLayoutAlign.xs="start end" [fxShow]="showDialer">
            <button class="mat-icon-wrapper up-icon" (click)="showDialer=!showDialer">
                <mat-icon class="arrow-up" aria-hidden="false" aria-label="arrow icon">keyboard_arrow_up</mat-icon>
            </button>
            <div fxFlex="50%" role="group" [attr.aria-label]="'Total Points ' + totalPoints" role="none">
                <div class="legends">
                    <p fxLayout="row" fxLayoutAlign="start center">
                        <span class="total-points-text" fxFlex="20%" fxFlex.sm="10%" fxFlex.xs="10%">Total points</span>
                        <span class="total-points" fxFlex="15%" [countUp]="totalPoints" [options]="dialerCountOptions"></span>
                    </p>
                    <p *ngIf = "filters.includes('Before Move')"><span class="before-move"></span><span>Before Move</span></p>
                    <p *ngIf = "filters.includes('Shipping')"><span class="shipping"></span><span>Shipping</span></p>
                    <p *ngIf = "filters.includes('Travel & Transport')"><span class="travel"></span><span>Travel & Transport</span></p>
                    <p *ngIf = "filters.includes('After Arrival')"><span class="arrival"></span><span>After Arrival</span></p>
                    <p *ngIf = "filters.includes('Ongoing Support')"><span class="onOngoing-Support"></span><span>Ongoing Support</span></p>
                    <p *ngIf = "filters.includes('Allowance')"><span class="allowance"></span><span>Allowance</span></p>
                </div>
            </div>
            <div class="chart-wrapper" fxLayout="row" fxLayoutAlign="center center" role="group" [attr.aria-label]="'Total Points Selected ' + totalPointsConfirmed">
                <app-benefit-donut-chart></app-benefit-donut-chart>
                <div class="chart-text" fxLayout="row" fxLayoutAlign="center center" role="none">
                    <p>
                        <span class="no-of-benefits" [countUp]="totalPointsConfirmed" [options]="dialerCountOptions"></span>
                        <span class="points-selected">Points</span>
                        <span class="points-selected">Redeemed</span>
                    </p>
                </div>
            </div>
    </div>
</div>