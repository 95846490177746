import { Component, OnInit, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadCrumbService } from '../../../core/services/breadcrumb.service';
import { EmployeeDetailsService } from '../../../../app/core/services/employee-details.service';
import { UserPreferencesService } from '../../../../app/core/services/user-preferences.service';
import { Observable, Subscription } from 'rxjs';
import { EmployeeInfo } from '../../../core/models/employee-info.model';
import { UserContextService } from '../../../core/services/user-context.service';


const empRoute: string = 'empInfo';
const settings: string = 'settings'

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  
  currentUrl: Subscription;
  breadcrumbs: Array<any> = [];
  public employeeDetails$: Observable<EmployeeInfo>;
  public fileNumber: number;
  public partyId:string;
  public sub: Subscription;
  public tempEmpFullName = '';
  public currentRouteValue = '';
  public empName: Subscription;

  location: any;

  /** hold favorite user flag of employee */
  favUser: number = 0;

  constructor( private readonly route: ActivatedRoute,
    private breadCrumbService: BreadCrumbService,
    private readonly userPreferenceService : UserPreferencesService,
    private readonly empDetailSvc: EmployeeDetailsService,
    private userContextServ: UserContextService) { }
  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.fileNumber = params.id;
      this.currentUrl = this.route.url.subscribe(url2 => {
        this.currentRouteValue = url2[0].path;
        if (this.currentRouteValue == empRoute) {
          this.userPreferenceService.getPreference('favorites_list', false, true)
          .subscribe (
            (response) => {
              //if (response) {
                // response.updateList.value.forEach(element => {
                //   if( element.fileNumber == this.fileNumber) {
                //     this.favUser = 1;
                //   }
                // });
                  this.empDetailSvc.getEmployeeInfo(this.fileNumber, this.favUser)
                  .subscribe(res2 => {
                    this.tempEmpFullName = res2.firstName + ' ' + res2.lastName;
                    this.breadcrumbs = this.breadCrumbService.getBreadCrumbs(this.currentRouteValue, this.fileNumber, this.tempEmpFullName);
                 });
                
              //}
            }
          );
        }
        else if (this.currentRouteValue == settings) {
          this.userContextServ.getUserContext().toPromise().then((context) => {
            this.tempEmpFullName = context.firstName + ' ' + context.lastName;
            this.breadcrumbs = this.breadCrumbService.getBreadCrumbs(this.currentRouteValue, context.partyId, this.tempEmpFullName);

          });
        }
        else{
          this.empDetailSvc.getEmployeeInfo(this.fileNumber, this.favUser)
          .subscribe(res2 => {
            this.tempEmpFullName = res2.firstName + ' ' + res2.lastName;
            this.breadcrumbs = this.breadCrumbService.getBreadCrumbs(this.currentRouteValue, this.fileNumber, this.tempEmpFullName);
          });
        }
      });

    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
    if (this.currentUrl) {
      this.currentUrl.unsubscribe();
    }
    if (this.empName) {
      this.empName.unsubscribe();
    }
  }
}
