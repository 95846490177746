import { OnInit } from '@angular/core';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MoveProDashboadBackendService } from 'src/app/login/move-pro-dashboadbackend.service';
import { employeeData } from './employeeData';
import { ADDRESS_DATA } from './cost-model-data';
import {sessionemployeeData} from 'src/app/login/login.service';
import {providerData, providerInvoiceData, sentimentData, totalCost} from '../data/employeeInfo';

@Injectable({
  providedIn: 'root'
})
export class MoveProDashboadDataService implements OnInit{

  constructor(private moveProBackendService: MoveProDashboadBackendService) {
    if (localStorage.getItem(sessionemployeeData)) {
      this.mapApiData(JSON.parse(localStorage.getItem(sessionemployeeData)));
      this.dashBoadData.next(JSON.parse(localStorage.getItem(sessionemployeeData)));
    } else {
      this.getEmployeeDatafromApi();
    }
  }

  employeeDataSubject = new BehaviorSubject<any>(employeeData);

  dashBoadData = new BehaviorSubject<any>(null);
  providerDataSubject = new BehaviorSubject<any>(providerData);
  providerInvoiceDataSubject = new BehaviorSubject<any>(providerInvoiceData);
  sentimentDataSubject = new BehaviorSubject<any>(sentimentData);
  totalCostDataSubject = new BehaviorSubject<any>(totalCost);


  

  costModelAddressDataSubject = new BehaviorSubject<any>(ADDRESS_DATA);

  ngOnInit() {
    if(this.dashBoadData.getValue()) {
      this.mapApiData(this.dashBoadData.getValue());
    }
  }
  getEmployeeDatafromApi() {
    this.moveProBackendService.getDashBoadEmployeeDetails().subscribe(
      data => {
        localStorage.setItem(sessionemployeeData, JSON.stringify(data));
        this.dashBoadData.next(data);
        this.mapApiData(JSON.parse(localStorage.getItem(sessionemployeeData)));
        localStorage.setItem(sessionemployeeData, JSON.stringify(data));
      }
    )
  }

  mapApiData(employeeDatafromApi) {
    let data = [];
    let sentiments = [];
    for(let i = 0; i<employeeDatafromApi.employeeDataList.length; i++) {
      data.push(this.prepareEmployeeData(employeeDatafromApi.employeeDataList[i]));
      this.prepareSentimentData(employeeDatafromApi.employeeDataList[i], sentiments);
    }
    let employeeData = {"data": data}
    this.employeeDataSubject.next(employeeData);
        
    if(sentiments && sentiments.length >0) {
      this.sentimentDataSubject.next(sentiments);
    }
    this.costModelAddressDataSubject.next(ADDRESS_DATA);
  }

  prepareEmployeeData(data){
    let tempEmployee = 
    {
      "favUser": data.favUser,
      "firstName":data.nameDetails.firstName,
      "lastName": data.nameDetails.lastName,
      "fileNumber": data.fileNumber,
      "fileStatusDesc": data.fileStatusDesc,
      "clientEmployeeId": data.clientEmployeeId,
      "movePhase": {
         "id": data.movePhase,
         "altText": " "
      },
      "divisionNumber": data.divisionNumber,
      "divisionName": data.divisionName,
      "policyName": data.policyName,
      "departureCity": data.departureAddr.city,
      "departureState":data.departureAddr.state,
      "departureCountry": data.departureAddr.country,
      "departure": data.departureAddr.country + data.departureAddr.city + data.departureAddr.state,
      "destinationCity": data.destinationAddr.city,
      "destinationState": data.destinationAddr.state,
      "destinationCountry": data.destinationAddr.country,
      "destination": data.destinationAddr.country + data.destinationAddr.city + data.destinationAddr.state,
      "effectiveTransferDate": data.effectiveTransferDate,
      "totalCost": data.totalCost,
      "totalCostCurr": data.totalCostCurr,
      "providerCount": data.providerCount,
      "sentimentLevel": data.sentimentLevel,
      "sentimentName":data.sentimentName,
      "moveRisk": data.moveRisk,
      "milestoneDate": data.milestoneDate,
      "milestoneDesc": data.milestoneDesc,
      "estimatedMoveDate": data.estimatedMoveDate,
      "estimatedMoveDateEarly": data.estimatedMoveDateEarly,
      "estimatedMoveDateLate": data.estimatedMoveDateLate,
      "moveRiskCompletionDt": data.moveRiskCompletionDt
   }
   return tempEmployee;
  }

  prepareSentimentData(data, sentiments) {
    if(data && data.sentimentLevel && data.sentimentTimelineData && data.sentimentTimelineData.length>0) {
      let sentiment = {
        "fileNumber" : data.fileNumber,
        "timeLine" : data.sentimentTimelineData,
        "totalSentiments": data.sentimentTimelineData.length
      }
      sentiments.push(sentiment);
    }
  }

}
