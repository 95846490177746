import { Injectable } from "@angular/core";
import { BaseClientService } from "./base-client.service";
import { RemoteLoggingService } from "./remote-logging.service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { employeeData } from "../data/employeeData";
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';

/** This service is used for returning division names for the particular client */
@Injectable({
  providedIn: "root",
})
export class DivisionService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private moveProDashboadDataService: MoveProDashboadDataService
  ) {}

  /** getDivisionByClientId() method to get the busniess unit names from the api using base client service */
  getDivisionByClientId(): Observable<string[]> {
    //   return this.baseClient.getArr<string>('v1/division', 'get division name').pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const emptyArray: string[] = [];
    //       this.logSvc.logError(err);
    //       return of(emptyArray);
    //     })
    //   );
    // }
    let tempEmployeeData = {"data": new Array};
    tempEmployeeData = this.moveProDashboadDataService.employeeDataSubject.getValue();
    let divisionsarray = [];
    let divisionLists = {
      divisionList: []
    };
    tempEmployeeData.data.forEach((ele) => {
      divisionsarray.push({
        divisionNumber: ele.divisionNumber,
        divisionName: ele.divisionName,
      });
    });
    const divisionsSet = unique(divisionsarray, [
      "divisionNumber",
      "divisionName",
    ]);
    divisionLists.divisionList = divisionsSet;
    return of(JSON.parse(JSON.stringify(divisionLists)));
  }
}
function unique(arr, keyProps) {
  const kvArray = arr.map((entry) => {
    const key = keyProps.map((k) => entry[k]).join("|");
    return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
}
