import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginService } from 'src/app/login/login.service';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ManageUsersService {

  manageUserList = new  BehaviorSubject<any>(null);
  userListUrl = environment.usersList;
  deleteUserUrl = environment.deleteUser;
  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private readonly toastrService: ToastrService) { }

    getAllAdminUsers(): Observable<any> {
      const headers = new HttpHeaders().set("Authorization", this.loginService.getJwtToken());
      

      return this.http.get<any>(this.userListUrl, { headers: headers })
      .pipe(
        catchError(err => {
          console.log('Failed to get data', err);
          this.showErrorToastMessage('Failed to get response');
          throw err;
        })
      );

    }

    deleteUsers(user): Observable<any> {
      const options = {
        headers: new HttpHeaders().set("Authorization", this.loginService.getJwtToken()),
        body: JSON.stringify({ userName : user }),
      };

      return this.http.delete(this.deleteUserUrl, options);
    }

    showErrorToastMessage(message: string) {
      this.toastrService.error(message, 'error', {
        timeOut: 3000,
      })
    }

}
