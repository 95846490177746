import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { aggregationList } from '../data/data';
import { AggregationList } from '../models/aggregation-list.model';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
/** DashboardViewService to fetch the data from api and display Aggregate view */
@Injectable({
  providedIn: 'root'
})
export class DashboardViewService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(private readonly baseClient: BaseClientService, private readonly logSvc: RemoteLoggingService) { }
  /**
   * getAggreationList() method to get the reponse from the api using base client service
   * Returns an array of AggregationList
   */
  getAggregationList(): Observable<Array<AggregationList>> {
    // return this.baseClient.getArr<AggregationList>('v1/aggregationList', 'getting aggregation list').pipe(
    //     map(r => r.body),
    //     catchError((err) => {
    //         this.logSvc.logError(err);
    //         const emptyArray: AggregationList[] = [];
    //         return of(emptyArray);
    //     })
    // );
    return of(aggregationList);
  }
}
