<span style="max-height: 25px">
    <a routerLink="/users">
    <img src="../../../assets/landingpage_assets/Cartus_logo.JPG" style="padding-left:20px;">
    </a>
</span>
<button mat-button class="logout-btn" (click)="logout()">Logout</button>
<div class="settings menu" fxLayout="row">
    <mat-icon [matMenuTriggerFor]="settingmenu" class="settings-btn" aria-label="settings icon">settings</mat-icon>
    <mat-menu #settingmenu="matMenu" class="mat-menu">
      <button mat-menu-item class="menu-items">
        <mat-icon>group</mat-icon>
        <span>Users</span>
      </button>
      <button mat-menu-item  class="menu-items" (click) = "navigateClient()">
        <mat-icon>groups</mat-icon>
        <span>Clients</span>
      </button>
    </mat-menu>
</div>


<div class="header">
    <h2>Users</h2>
    <div class="add-btn">
        <button class="user-water"  (click)="addUser()"></button>
    </div>
    <div class="back-btn">
        <mat-icon  aria-label="settings icon" (click)="onBackToHome()">arrow_back</mat-icon>
    </div>
</div>
<div class="user-details">
    <div class="table-scroll" style="padding-bottom: 50px;">
        <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
            <!-- UserId Column -->
            <ng-container matColumnDef="userName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> UserID </th>
                <td mat-cell *matCellDef="let element"><a  [ngClass]="{'iseditable' : !isUserEditable(element)}" (click) = "onEditUser(element)">{{element.userName || "N/A"}}</a></td>
            </ng-container>

             <!-- Email Column -->
             <ng-container matColumnDef="email">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Email </th>
                <td mat-cell *matCellDef="let element">{{element.email || "N/A"}}</td>
            </ng-container>

            <!-- UserType Column -->
            <ng-container matColumnDef="userType">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> User Type </th>>
                <td mat-cell *matCellDef="let element"> {{element.userType || "N/A"}} </td>
            </ng-container>

            <!-- FirstName Column -->
            <ng-container matColumnDef="firstName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> First Name </th>
                <td mat-cell *matCellDef="let element"> {{element.firstName || "N/A"}} </td>
            </ng-container>

            <!-- LastName Column -->
            <ng-container matColumnDef="lastName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Last Name </th>
                <td mat-cell *matCellDef="let element"> {{element.lastName || "N/A"}} </td>
            </ng-container>

            <!-- ClientName Column -->
            <ng-container matColumnDef="clientName">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Client Name </th>
                <td mat-cell *matCellDef="let element"> {{element.clientName || "N/A"}} </td>
            </ng-container>

            <!-- SubscriptionEndDate Column -->
            <ng-container matColumnDef="subscriptionEndDate">
                <th mat-header-cell mat-sort-header *matHeaderCellDef> Subscription End<br>Date </th>
                <td mat-cell *matCellDef="let element"> {{(element.subscriptionEndDate |  date: 'MM-dd-yyyy') || "N/A"}} </td>
            </ng-container>

            <!-- Delete Column -->
            <ng-container matColumnDef="delete" stickyEnd>
                <th width="8%" mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                    <button type="delete" class="delete-btn" *ngIf = "isDeleteEnabled(element)" (click)="onDelete(element)"
                        fxLayout="row" aria-label="delete user">
                      <mat-icon style="color: #575A5D;">delete</mat-icon>
                    </button>      
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>
</div>

