import {Component, OnInit, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import {MatSnackBar} from '@angular/material';
import { MatBottomSheet } from '@angular/material';
import { BenefitsDetailModalComponent } from '../../../list/components/benefits-detail-modal/benefits-detail-modal.component';
import { Benefit, BenefitService } from '../../../../../../../../src/app/public/benefits/services/benefit.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NeedsAssessmentSharedService } from './../../../../../needs-assessment/services/needs-assessment-shared.service';
import { CollaborationService } from './../../../../../../core/services/collaboration.service';
import { Subscription } from 'rxjs';
import { CollabMessage, CollabEvents } from './../../../../../../core/services/collaboration-events.service';
@Component({
    selector: 'app-selected-benefits',
    templateUrl: './selected-benefits.component.html',
    styleUrls: ['./selected-benefits.component.scss']
})
export class SelectedBenefitsComponent implements OnInit, OnDestroy {
    /** benefit information */
    @Input() benefit: Benefit;
	@Output() benefitSelectDeleteAction = new EventEmitter();
    show = false;

    collabEvents: Subscription;

	/**
	 * @param bottomSheet Inject Mat bottom sheet
	 * @param benefitService Inject benefit service
	 * @param spinner Inject spinner
	 * @param toastr Inject toastr to display message
     * @param needsAssessmentService Inject needs assessment service
	 */
    constructor(private bottomSheet: MatBottomSheet,
        readonly benefitService: BenefitService,
        private readonly spinner: NgxSpinnerService,
        private readonly toastr: MatSnackBar,
        private readonly needsAssessmentService: NeedsAssessmentSharedService,
        private readonly collaboration: CollaborationService) {}

    ngOnInit() {
		this.observeCollabEvents();
		this.benefit.icon = this.benefitService.mapIconToBenefit(this.benefit.reference);
    }

    observeCollabEvents() {
		this.collabEvents = this.collaboration.socketSubscriber$.subscribe((message: CollabMessage) => {
			if (!message) { return; }

			try {
				 if (
					message.data.event &&
					(message.data.event === CollabEvents.BENEFIT_ACTION_VIEW ||
					message.data.event === CollabEvents.BENEFIT_ACTION_DELETE ||
					message.data.event === CollabEvents.BENEFIT_ACTION_VIEW_CLOSE ||
					message.data.event === CollabEvents.BENEFIT_ACTION_BULK_DELETE)
				) {

					/*
						Since this component will be available 5-10 times in the DOM.
						Parse it and check if its targeting the same Benefit
					*/
					if (message.data.event === CollabEvents.BENEFIT_ACTION_BULK_DELETE) {
					    setTimeout(() => {
					        const benefits = JSON.parse(message.data.data);
					        benefits.benefits.filter((benefit: Benefit) => {
					            if (benefit.reference === this.benefit.reference) {
					                this.showCollabToastr('Benefit Deleted', benefit);
					                this.collabDeleteBenefit(benefit);
					            }
					        });
					    }, 1000);
					} else {
					const benefit = JSON.parse(message.data.data) as Benefit;
					if (benefit !== null && this.benefit.reference === benefit.reference) {
						if (message.data.event === CollabEvents.BENEFIT_ACTION_DELETE) {
							this.showCollabToastr('Benefit Deleted', benefit);
							setTimeout(() => {
							  this.deleteBenefit(benefit, null, true);
							}, 1000);
						}

						if (message.data.event === CollabEvents.BENEFIT_ACTION_VIEW) {
						    this.showCollabToastr('Opening Benefit Detail', benefit);
						    setTimeout(() => {
						        this.displayBenefitDetails(benefit, null, true);
						    }, 1000);
						}

						if (message.data.event === CollabEvents.BENEFIT_ACTION_VIEW_CLOSE) {
						    this.bottomSheet._openedBottomSheetRef.dismiss({
						        type: 'isCollab',
						        benefit
						    });
						}

					}
				}
					if (message.data.event === CollabEvents.BENEFIT_ACTION_VIEW_CLOSE) {
						if (this.bottomSheet._openedBottomSheetRef) {
							this.bottomSheet._openedBottomSheetRef.dismiss({ type: 'isCollab', benefit: null });
						}
					}

				}
			} catch (error) {
				if (message.data && message.data.event && message.data.event === CollabEvents.BENEFIT_ACTION_VIEW_CLOSE) {
					if (this.bottomSheet && this.bottomSheet._openedBottomSheetRef) {
					  this.bottomSheet._openedBottomSheetRef.dismiss({ type: 'isCollab', benefit: null });
					}
				} else {
					console.error('Collab Error ', error);
				}
			}
		});
	}

	/**
	 * To delete the selected benefit
	 * @param benefit benefit data
	 * @param event click event
	 */
	deleteBenefit(benefit: Benefit, event ? : Event, isCollab: boolean = false) {

	    this.spinner.show();
	    event ? event.stopImmediatePropagation() : null;
	    let benefitsTobeDeleted: Benefit[] = this.benefitService.getPreRequisiteDependencies([benefit]);

	    if (!benefitsTobeDeleted || benefitsTobeDeleted.length < 1) {
	        benefitsTobeDeleted = [benefit];
	    } else {
	        benefitsTobeDeleted = [...benefitsTobeDeleted, benefit];
	    }
	    // deleting selected pre requisites before the benefit
	    if (isCollab) {
	        this.collabDeleteBenefit(benefit);
	        this.showCollabToastr('Deleted benefit ', benefit);
	        this.spinner.hide();
	    } else {
			this.benefitService.deleteBenefits(benefitsTobeDeleted, benefit.hybridTempLiving && benefit.pointsPerIncrement ? true : false)
	            .subscribe(value => {
	                    if (value) {
	                        this.deleteBenefitActions(benefitsTobeDeleted, value);
	                    } else {
	                        this.toastr.open(
	                            'Error deleting benefit',
	                            'Try again?', {
	                                duration: 5000,
	                            }).onAction().subscribe(_ => this.deleteBenefit(benefit, null));
	                    }
	                    this.spinner.hide();
	                },
	                err => {
	                    this.toastr.open(
	                        err.message ? err.message : 'Error deleting benefit',
	                        err.message ? null : 'Try again?', {
	                            duration: 5000,
	                        }).onAction().subscribe(_ => this.deleteBenefit(benefit, null));
	                    this.spinner.hide();
	                });
	    }
	}

	/**
	 * To perform point calcualations and update cards in sections post delete benefit
	 * @param benefits Array Of Benefits
	 * @param result Data received after delete
	 */
	deleteBenefitActions(benefits: Array < Benefit > , result) {
	    if (benefits.length === 1) {
			const benefit = benefits[0];
			let selectedIncrementalCount = benefit.hybridTempLiving && benefit.pointsPerIncrement ? benefit.selectedIncrementCount : 1;
			let selectedDollarIncrementCount = 1;
	        if (benefit.maxMul) {
	            // tslint:disable-next-line: max-line-length
	            benefit.selectedCount = benefit.selectedbenefitCount = benefit.selectedbenefitCount ? ((benefit.selectedbenefitCount as number) - 1) :
	                (benefit.selectedCount as number - 1);
	        } else if (benefit.pointsPerIncrement) {
				selectedIncrementalCount = ((benefit.selectedIncrementCount <= (benefit.rangeMin / benefit.rangeIncrementValue))
				&& (!benefit.confirmedIncrementCount)) ?
				(benefit.rangeMin / benefit.rangeIncrementValue) : selectedIncrementalCount;
					benefit.selectedIncrementCount = benefit.selectedIncrementCount - selectedIncrementalCount;
			} else if (benefit.perPointCurrency) {
				selectedDollarIncrementCount = ((benefit.selectedDollarIncrementCount <= (benefit.rangeMin / benefit.rangeIncrementValue))
				&& (!benefit.confirmedIncrementCount)) ?
				(benefit.rangeMin / benefit.rangeIncrementValue) : selectedDollarIncrementCount;
					benefit.selectedDollarIncrementCount = benefit.selectedDollarIncrementCount - selectedDollarIncrementCount;
			}
	        delete benefit.selected;
			const benefitPoints: number =benefit.tierConfigs ? benefit.advCashOutSelectedPoints :
			 benefit.pointsPerIncrement ? (benefit.pointsPerIncrement * selectedIncrementalCount) : (benefit.perPointCurrency ? benefit.rangeIncrementValue * selectedIncrementalCount : benefit.points as number);
			// if (benefit.selectedCashOutPoints) {
			// 	benefit.selectedCashOutPoints = 0;
			// }
			if(benefit.tierConfigs){
				delete benefit.advCashOutSelectedPoints;
				delete benefit.advCashOutPointValue;
				benefit.selectedCashOutPoints = 0;
				delete benefit.selectedCashOutValue;
			}
			this.benefitService.updateBenefitsData(benefit, 'selectedBenefits', 'availableBenefits');
	        // Updating the benefit count
			//benefit.tierConfigs ?this.needsAssessmentService.updateBenefitCount(result.selectedPoints, 'equal'):
			 this.needsAssessmentService.updateBenefitCount(benefitPoints, 'subtract');
			 const needAssessmentPointData = this.needsAssessmentService.transfereeNeedsAssessmentDetails.getValue();
			 this.benefitSelectDeleteAction.emit(needAssessmentPointData.pointDetails.selectedPoints);			
			 this.spinner.hide();
			if (benefit.pointValue) {
				benefit.selectedbenefitCount = benefitPoints;
			}
			const benefitCopy = JSON.parse(JSON.stringify(benefit));
			// Send Message
			benefit.tierConfigs ? benefitCopy.points= benefitPoints : null // points to be deleted in collab mode for cashout cards
	        // Send Message
	        this.collaboration.sendMessage({
	            event: CollabEvents.BENEFIT_ACTION_DELETE,
	            data: JSON.stringify(benefitCopy)
	        });
	    } else {
	        for (let benefit of benefits) {
	            const currentBenefit = result.benefits.filter((ben) => benefit.reference === ben.reference);
	            if (benefit.maxMul) {
	                // tslint:disable-next-line: max-line-length
	                benefit.selectedCount = benefit.selectedbenefitCount = currentBenefit.selectedbenefitCount;
	            } else if (benefit.pointsPerIncrement || benefit.perPointCurrency) {
					benefit.selectedIncrementCount = 0 ;
				}
	            delete benefit.selected;
	            this.benefitService.updateBenefitsData(benefit, 'selectedBenefits', 'availableBenefits');
	        }
	        this.needsAssessmentService.updateBenefitCount(result.selectedPoints, 'equal');
			const needAssessmentPointData = this.needsAssessmentService.transfereeNeedsAssessmentDetails.getValue();
			this.benefitSelectDeleteAction.emit(needAssessmentPointData.pointDetails.selectedPoints);
	        this.spinner.hide();
	        this.collaboration.sendMessage({
	            event: CollabEvents.BENEFIT_ACTION_BULK_DELETE,
	            data: JSON.stringify({
	                benefits: benefits,
	                output: result
	            })
	        });
	    }
	}

	/**
	 * To display benefit information on click of benefit
	 */

    displayBenefitDetails(benefit: Benefit, event?: Event, isCollab: boolean = false) {

		if (event) {
			event.preventDefault();
			event.stopImmediatePropagation();
		}

		const _tempBenefit = {...benefit};
		_tempBenefit.selected = true;

		const popup = this.bottomSheet.open(BenefitsDetailModalComponent, {
			data: _tempBenefit
		});

		popup.afterDismissed().subscribe((data: { type: string, benefit: Benefit }) => {
			if (data) {
				if (data && data.type === 'isCollab') {
					data.benefit ?  this.deleteBenefit(benefit, null, true) : '';
                }
                if (data.type === 'delete') {
					this.deleteBenefit(data.benefit, null, isCollab);
				}
			}

			// Send Message
			this.collaboration.sendMessage({
				data: (data && data.benefit) ? data.benefit : null,
				event: CollabEvents.BENEFIT_ACTION_VIEW_CLOSE
			});

		});

		popup.backdropClick().subscribe(_ => {
			// Send Message
			this.collaboration.sendMessage({
			  data: null,
			  event: CollabEvents.BENEFIT_ACTION_VIEW_CLOSE
			});
		});

		if (!isCollab) {
			this.collaboration.sendMessage({
				event: CollabEvents.BENEFIT_ACTION_VIEW,
				data: JSON.stringify(benefit)
			});
		}

	}

    collabDeleteBenefit(benefit: Benefit) {
        //this.benefitService.updateBenefitsData(benefit, 'selectedBenefits', 'availableBenefits');
		//this.needsAssessmentService.updateBenefitCount(benefit.points as number, 'subtract');
		let selectedbenefitCount;
	
		const { availableBenefits } = this.benefitService.benefitsData.getValue();
		const { selectedBenefits } = this.benefitService.benefitsData.getValue();

		// Finding if the benefit already exists...
		const isBenefitAvailable = availableBenefits.find(b => benefit.reference === b.reference);
		let selectedIncrementalCount = 1;
		let selectedDollarIncrementalCount = 1;
		if (benefit.maxMul) {
			// number of selected benefits before deleting multiplicity card
			 selectedbenefitCount = selectedBenefits.find(b => benefit.reference === b.reference).selectedCount;
		}
		if (benefit.pointsPerIncrement) {
			let selectedBenefit = selectedBenefits.find(b => benefit.reference === b.reference);
			if ( selectedBenefit && (selectedBenefit.selectedIncrementCount != benefit.selectedIncrementCount)) {
				selectedIncrementalCount = (((selectedBenefits.find(b => benefit.reference === b.reference)).selectedIncrementCount <= (benefit.rangeMin / benefit.rangeIncrementValue))
				&& (!benefit.confirmedIncrementCount)) ?
				(benefit.rangeMin / benefit.rangeIncrementValue) : selectedIncrementalCount;
					benefit.selectedIncrementCount = (selectedBenefits.find(b => benefit.reference === b.reference)).selectedIncrementCount - selectedIncrementalCount;
			 } else {
				 return;
			 }
		}
		if (benefit.perPointCurrency) {
			let selectedBenefit = selectedBenefits.find(b => benefit.reference === b.reference);
			if ( selectedBenefit) {
				selectedDollarIncrementalCount = (((selectedBenefits.find(b => benefit.reference === b.reference)).selectedDollarIncrementCount <= (benefit.rangeMin / benefit.rangeIncrementValue))
				&& (!benefit.confirmedIncrementCount)) ?
				(benefit.rangeMin / benefit.rangeIncrementValue) : selectedDollarIncrementalCount;
					benefit.selectedDollarIncrementCount =  selectedDollarIncrementalCount;
			 } else {
				 return;
			 }
		}

		if (isBenefitAvailable && (benefit.maxMul && (selectedbenefitCount === benefit.selectedCount) )) {
			return;
		} else {
			let benefitPoints : number = benefit.pointValue ? benefit.selectedbenefitCount :
			benefit.pointsPerIncrement ? (benefit.pointsPerIncrement * selectedIncrementalCount) : (benefit.perPointCurrency ? benefit.rangeIncrementValue * selectedDollarIncrementalCount:benefit.points as number);
			if (benefit.pointValue) {
				delete benefit.selectedbenefitCount;
			}

			// cashout benefit
			if(benefit.tierConfigs){
				benefitPoints = benefit.points as number;
				benefit.points =0;				
			}
				this.benefitService.updateBenefitsData(benefit, 'selectedBenefits', 'availableBenefits');
				// Updating the benefit count
				this.needsAssessmentService.updateBenefitCount(benefitPoints as number, 'subtract');
				const needAssessmentPointData = this.needsAssessmentService.transfereeNeedsAssessmentDetails.getValue();
				this.benefitSelectDeleteAction.emit(needAssessmentPointData.pointDetails.selectedPoints);
								
			if (this.bottomSheet && this.bottomSheet._openedBottomSheetRef ) {
				this.bottomSheet._openedBottomSheetRef.dismiss({ type: 'isCollab', benefit: null });
			}
		}
    }

    showCollabToastr(message: string, benefit: Benefit) {
		this.toastr.open(`${message} ${benefit.displayName}`, null, {
		  duration: 3000,
		  horizontalPosition: 'right',
		  verticalPosition: 'top'
		});
	}
	keyUpOnBenefitItem(benefit: Benefit, event?: Event, isCollab: boolean = false){
		if ((event as KeyboardEvent).key === ' ' || (event as KeyboardEvent).key === 'Enter') {
		  event.preventDefault();
		  this.displayBenefitDetails(benefit,event,isCollab);
		}
	  }

    ngOnDestroy(): void {
        if (this.collabEvents) { this.collabEvents.unsubscribe(); }
    }

}
