import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import * as OktaAuth from '@okta/okta-auth-js';
import { environment } from '../../../environments/environment';
import { TokenPayload } from '../models/tokenPayload.model';
import moment from 'moment';
export const tokenkey = 'jwtToken';
export const subscriptionkey = 'subscriptionEnddate'
export const sessiondurationkey = 'sessionduration'
export const sessiondurationtypekey = 'sessiondurationtype'
export const lastlogindatekey = 'lastlogindate'
@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  /** Variable to store the token  */
  private readonly authClient: OktaAuth;
  constructor() {
    this.authClient = new OktaAuth({
      // url: environment.oktaUrl,
      clientId: environment.oktaClientId,
      grantType: 'authorization_code',
      issuer: environment.oktaUrl,
      redirectUri: environment.oktaRedirectUri
    });
  }

  /** Service call for login */
  async login(user: TokenPayload) {
    try {
      const data = {
        username: user.email,
        password: user.password
      };

      const transaction = await this.authClient.signIn(data);
      const tokens = await this.authClient.token.getWithoutPrompt({
        responseType: 'code',
        sessionToken: transaction.sessionToken
      });
      tokens.forEach(token => {
        if (token.idToken) {
          this.authClient.tokenManager.add('idToken', token);
        }
        if (token.accessToken) {
          localStorage.setItem('token', token.accessToken);
          this.authClient.tokenManager.add('accessToken', token);
        }
        return this.authClient;
      });
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
  async isAuthenticated() {
    // Checks if there is a current accessToken in the TokenManger.
    return true; //this.authClient.tokenManager.get('accessToken');
  }

  getJwtToken() {
    return localStorage.getItem(tokenkey);
  }
  getSubscription() {
    return localStorage.getItem(subscriptionkey);
  }
  getSessionDuration() {
    return localStorage.getItem(sessiondurationkey);
  }
  getSessionDurationType() {
    return localStorage.getItem(sessiondurationtypekey);
  }  
  getLastLoginDate() {
    return localStorage.getItem(lastlogindatekey);
  }
  clearLoginInfo(){
    localStorage.removeItem(tokenkey);
    localStorage.removeItem(subscriptionkey);
    localStorage.removeItem(sessiondurationtypekey);
    localStorage.removeItem(sessiondurationkey);
    localStorage.removeItem(lastlogindatekey);
  }
  isTokenValid() {
    let isValid = false;
    if(this.isSubscriptionValid()) {
      let token = this.getJwtToken();    
      if(token) {
        if(!this.istokenExpired()){
          isValid = true;
        }
      }
    }
  

    return isValid;
  }

  istokenExpired() {
    let lastLogindate = this.getLastLoginDate(); 
    let loginDate = lastLogindate.includes('GMT') ? lastLogindate :
    (lastLogindate.includes('T') ? lastLogindate.split('T')[0] : lastLogindate);      
    if(moment(moment().format('MM/DD/YYYY')).isSame(moment(loginDate).format('MM/DD/YYYY'))) {
      return false;
    }
    return true;
  }

  isSubscriptionValid() { 
    let isSubscribed = false;
    let subscriptionEndDate = this.getSubscription();
    if(subscriptionEndDate) {      
      let subscribeddates = subscriptionEndDate.includes('T') ? subscriptionEndDate.split('T')[0]: subscriptionEndDate;      
      if(moment(moment().format('MM/DD/YYYY')).isSameOrBefore(moment(subscribeddates).format('MM/DD/YYYY'))) {
        isSubscribed = true;
      }
    } else {
      isSubscribed = true;
    }
    return isSubscribed;
  }
}
/** Credetials to be sent with login service call  */
