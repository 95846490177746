import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ClientsService } from '../clients.service';
import { ManageClientsService } from 'src/app/login/manage-clients.service';


@Component({
  selector: 'app-add-edit-clients',
  templateUrl: './add-edit-clients.component.html',
  styleUrls: ['./add-edit-clients.component.scss']
})
export class AddEditClientsComponent implements OnInit {

  formTitle = "Add Client";
  clientName = this.data.clientName;
  clientId = this.data.clientId;
  editClientEnable = this.data.editClientEnable;
  clientForm: FormGroup;
  /**to disable close button when snackbar is opened */
  formDisable = false;
  constructor(public dialog: MatDialog,
    public dialogRef: MatDialogRef<AddEditClientsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly spinner: NgxSpinnerService,
    private readonly toastrService: ToastrService,
    private fb: FormBuilder,
    private clientService: ClientsService,
    private manageClientService: ManageClientsService
  ) { }

  ngOnInit() {
    this.clientForm = this.fb.group({
      clientName: this.clientName
    })
    if (this.editClientEnable) {
      this.formTitle = "Update Client";
    }
  }

  addClient() {
    this.spinner.show();
    /* api call to create new client */
    this.clientService.saveClient(this.clientForm.value).subscribe(
      data => {
        if (data.message == "Client added successfully!") {
          this.showSuccessToast("Client created successfully!")
          this.refreshClientList();
          this.dialogRef.close();
        }
      },
      error => {
        let errorMessage = error.error.error.message;
        this.showErrorToastMessage(errorMessage);
      }
    ),
      this.spinner.hide();
  }

  updateClient() {
    this.spinner.show();
    this.createUpdateBody();
    /* api call to update client */
    this.clientService.updateClient(this.createUpdateBody()).subscribe(
      data => {
        if (data.message == "Client details updated successfully!") {
          this.showSuccessToast("Client Updated Successfully!")
          this.refreshClientList();
          this.dialogRef.close();
        }
      },
      error => {
        let errorMessage = error.error.error.message;
        this.showErrorToastMessage(errorMessage);
      }
    ),
    this.spinner.hide();
  }

  createUpdateBody() {
    let body = {
      "clientId": this.clientId,
      "clientName": this.clientForm.controls.clientName.value
    }
    return body;
  }

  refreshClientList() {
    this.manageClientService.getClients().subscribe(data => {
      if (data) {
        this.manageClientService.manageClientList.next(data);
      }
    })
  }

  showSuccessToast(message: string) {
    this.toastrService.success(message, 'success', {
      timeOut: 3000,
      closeButton: true
    })
  }

  showErrorToastMessage(message: string) {
    this.toastrService.error(message, 'error', {
      timeOut: 3000,
    })
  }

  onDismiss() {
    this.clientForm.reset();
    this.dialogRef.close();
  }

}
