import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserTypeService, UserType } from './services/user-type.service';

@Component({
  selector: 'app-administration',
  templateUrl: './administration.component.html',
  styleUrls: ['./administration.component.scss']
})
export class AdministrationComponent implements OnInit {

  /** user role */
  userRole: UserType;

  /** Can have 3 types of View -> (AccMgr/PgrmMgr/FSU/AO/AR -> clientList) | (others -> transfereeList) */
  currentViewType: 'clientList' | 'transfereeList' | 'detailsView';

  /**
   * injecting dependencies
   * @param dialog object for matdialog
   */
  constructor(
    public dialog: MatDialog,
    private userTypeService: UserTypeService,
  ) {
    this.setViewType();
  }

  ngOnInit() {}

  /**
   * Decides what should the view of the component will be.
   * Client List View OR Transferee List View
   */
  setViewType(): void {
    this.userRole = this.userTypeService.userType;
    if (this.userRole === 'client-account-manager' || this.userRole === 'consultant'  || this.userRole === 'program-admin' ||
    this.userRole === 'file-setup-specialist' || this.userRole === 'application-reliability-analyst' || this.userRole === 'application-owner') {
      this.currentViewType = 'clientList';
    } else {
      this.currentViewType = 'transfereeList';
    }
  }

}
