export const aggregationList = [
    {
      "value": "departureCountry",
      "viewValue": "Departure Country"
    },
    {
      "value": "destinationCountry",
      "viewValue": "Destination Country"
    },
    {
      "value": "employeeList",
      "viewValue": "Employee List"
    },
    {
      "value": "policyName",
      "viewValue": "Policy Name"
    },
    {
      "value": "divisionName",
      "viewValue": "Division"
    }
  ]

 