<div mat-raised-button *ngFor="let benefitsGroup of benefitsList; let i = index" class="selected-benefit-card-list"
    fxLayout="column" fxLayoutAlign="space-evenly none" role="group"
    [ngClass]="benefitsGroup.group.multiplicity ? 'multiplicity-card selected-benefit-card-list' : 'selected-benefit-card-list'"
    [style.border-bottom-color]="assignBenefitColor(benefitsGroup.group)">
    <h3 [attr.aria-label]="benefitsGroup.group" *ngIf="benefitsGroup.items.length > 0">
        <span role="none">{{ benefitsGroup.group | titlecase }}</span>
    </h3>
    <ng-container *ngFor="let benefit of benefitsGroup.items; let j = index">
        <div *ngIf="!benefit.suppress && !benefit.consultantBenefitInfo?.consultant && benefitsGroup.items.length > 0" class="" fxLayout="column"
            [ngClass]="benefit.transfereeNotEligible ? 'transferee-not-eligible-card': (benefit.multiplicity ? 'multiplicity-card selected-card selected-benefit-card-list' : 'selected-benefit-card-list selected-card')"
            [style.border-bottom-color]="assignBenefitColor(benefitsGroup.group)" role="listitem">
            <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center"
                fxLayoutAlign.xs="space-between stretch" fxLayoutGap.xs="20px" role="none">
                <div fxFlex="10" fxShow.xs="false" fxShow="true" class="selected-card-left" role="img">
                    <!-- <img src="../../../../../assets/icons/bb_icons/enabled_generic_benefit_icon.svg" alt="benefit"> -->
                    <img [src]="programService.mapIconToBenefit(benefit.reference) ? '../../../../../assets/icons/bb_icons/' + programService.mapIconToBenefit(benefit.reference) : '../../../../../assets/icons/bb_icons/enabled_generic_benefit_icon.svg'"
                        alt="benefit">
                </div>
                <div fxFlex="85" fxFlex.xs="100" class="selected-card-center" role="none" fxLayout="column"
                    fxLayout="column">
                    <div fxLayout="row" role="none">
                        <div class="setting-in spacing" role="heading" aria-level="2" fxLayout.xs="row"
                            fxLayoutAlign.xs="space-between center">
                            <img fxShow.xs="true" fxShow="false"
                                src="../../../../../assets/icons/bb_icons/enabled_generic_benefit_icon.svg"
                                alt="benefit">
                            <div>
                                <div class="benefit-title"><p class="settling-in">{{ benefit.displayName}}</p> <div *ngIf = "benefit?.transfereeNotEligible" class = "report_problem"><mat-icon>report_problem</mat-icon>  Transferee Not Eligible</div></div>
                                <p *ngIf="benefit.points && benefit.points === 'Guaranteed' && !benefit.selectedCashOutPoints" class="points">0 Points</p>
                                <p *ngIf="benefit.points && benefit.points !== 'Guaranteed'" class="points">{{ benefit.points }} Points</p>
                                <p *ngIf="(benefit.points && benefit.points !== 'Guaranteed' && !benefit.selectedCashOutPoints && benefit.confirmedCount)"
                                    class="points"> {{ benefit.points * benefit.confirmedCount }} Points</p>
                                <p *ngIf="benefit.confirmedAdvCashOutPoints" class="points">{{
                                    benefit.confirmedAdvCashOutPoints }} Point(s): {{benefit.confirmedAdvCashOutValue |
                                    currency: ' ' : 'symbol':'1.0-0'}} {{benefit.confirmedAdvCashOutCurr}} </p>
                                <p *ngIf="(benefit.pointsPerIncrement && benefit.confirmedIncrementCount)"
                                    class="points">{{
                                    benefit.confirmedIncrementCount * benefit.pointsPerIncrement}} Point(s): {{
                                    benefit.rangeIncrementValue * benefit.confirmedIncrementCount}}
                                    {{benefit.rangeIncrementUnit}}</p>
                            </div>
                        </div>
                        <div class="seperator" *ngIf="benefit.points && benefit.points === 'Guaranteed'" role="none"></div>
                        <div fxLayout="row" *ngIf="benefit.points && benefit.points === 'Guaranteed'" class="selected-card-right" role="none">
                            <img class="core-benefits-img" alt="core-benefits"
                                src="../../../../../assets/images/Group_3141.svg">
                            <p class="core-benefits" fxShow.xs="false" fxShow="true">Core<br /> Benefits</p>
                        </div>
                    </div>
                    <div class="para" role="note">
                        <p class="card-para" [class.show]="show[i][j]" [class.less]="!show[i][j]">{{benefit.description}}</p>
                        
						<span class="seemore" (keyup)="keyUpSeeMore($event, i, j)" (click)="show[i][j] = ! show[i][j]" role="link" *ngIf="benefit.description.length>showChar"  [attr.aria-label]="show[i][j] ? 'Less text': 'More text'" tabindex="0">{{ show[i][j] ? 'Less': 'More' }}</span>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<div *ngIf="benefitsList.length === 0" style="text-align: center;">
    <span role="none">No Matching Benefits in Program</span>
</div>