<div fxLayout="row" fxLayoutAlign="space-between center" class="mat-dialog-title">
	<h2>Filter Table</h2>
	<button tabindex="0" mat-icon-button mat-dialog-close aria-label="Close" matTooltip="Close"><span class="material-icons close-icon">close</span></button>
</div>
<div class="mat-dialog-content" fxLayout="row" fxLayoutAlign="space-between center">
	<div *ngIf="data.dataKey.includes('status')">
		<h6>Status</h6>
		<div class="options-radio-group">
			<mat-checkbox class="option-radio-button" [(ngModel)]="selectedStatus[0]" [disabled]="(expirationStartDate || expirationEndDate)">Active</mat-checkbox>
			<mat-checkbox class="option-radio-button" [(ngModel)]="selectedStatus[1]" [disabled]="(((userRole === 'client-contact-initiator') || (userRole === 'client-contact') || (userRole === 'file-setup-specialist') || (userRole === 'consultant')) || expirationStartDate || expirationEndDate)">Draft</mat-checkbox>
			<mat-checkbox class="option-radio-button" [(ngModel)]="selectedStatus[2]" [disabled]="(expirationStartDate || expirationEndDate)">Deactivated</mat-checkbox>
		</div>
	</div>
	<div *ngIf="data.dataKey.includes('programExpirationDate')" id="expirationTransferDate">
		<h6>Expiration Date Range</h6>
		<div>
			<mat-form-field >
				<input matInput [max]="expirationEndDate" [matDatepicker]="startDate" placeholder="From Date" [(ngModel)]="expirationStartDate" [disabled]="(selectedStatus.includes(true))">
				<mat-datepicker-toggle tabindex="0" matSuffix [for]="startDate" matTooltip="Calendar"></mat-datepicker-toggle>
				<mat-datepicker #startDate></mat-datepicker>
			</mat-form-field>
		</div>
		<div>
			<mat-form-field>
				<input matInput [min]="expirationStartDate" [matDatepicker]="endDate" placeholder="To Date" [(ngModel)]="expirationEndDate" [disabled]="(selectedStatus.includes(true))">
				<mat-datepicker-toggle matSuffix [for]="endDate" matTooltip="Calendar"></mat-datepicker-toggle>
				<mat-datepicker #endDate></mat-datepicker>
			</mat-form-field>
		</div>
	</div>
</div>
<div class="mat-dialog-content" fxLayout="row" fxLayoutAlign="{{data.dataKey.includes('policyCallRequired')?'space-between center':'end none'}}">
	<div *ngIf="data.dataKey.includes('policyCallRequired')" fxLayout="column" fxLayoutAlign=" start" style="padding: 15px">
		<mat-checkbox [(ngModel)]="addPolicyToFilter" >Policy Call</mat-checkbox>
		<mat-slide-toggle [disabled]="!addPolicyToFilter" [(ngModel)]="policyChecked" value="policyChecked" class="policyCall-toggle">
			Policy Call Confirm
		</mat-slide-toggle> 
		<span>{{policyChecked ? 'Policy Call Required' : 'Policy Call Not Required'}}</span> 
	</div>

	<div *ngIf="data.dataKey.includes('isCartusInsured')" fxLayout="column" fxLayoutAlign=" start" style="padding: 15px">
		<mat-checkbox [(ngModel)]="addIsCartusInsuredFilter" >Cartus Insurance</mat-checkbox>
		<mat-slide-toggle [disabled]="!addIsCartusInsuredFilter" value="isCartusInsuredChecked" [(ngModel)]="isCartusInsuredChecked" class="policyCall-toggle">
			Cartus Insured Shipping
		</mat-slide-toggle> 
		<span>{{isCartusInsuredChecked ? 'Insured by Cartus' : 'Not Insured by Cartus'}}</span> 
	</div>
</div>
<div class="mat-dialog-actions" fxLayout="row" fxLayoutAlign="end end">
	<button mat-button class="text-button" (click)="close()"> Clear </button>
	<button mat-button class="contained-button" (click)="onSubmit()"> OK </button>
</div>