import { Component, OnInit, ViewChild, EventEmitter, Output, Input, SimpleChanges, OnChanges, HostListener } from '@angular/core';
import { MatTableDataSource, SortDirection, MatSort, MatPaginator, PageEvent } from '@angular/material';
import { AdminClientContacts } from './../../../../core/models/admin-client-contacts.model';
/**Quick access keyboard code for table next, last and escape*/
export enum KEY_CODE {
  RIGHT_ARROW = 39,
  LEFT_ARROW = 37
}
@Component({
  selector: 'app-client-contacts-list',
  templateUrl: './client-contacts-list.component.html',
  styleUrls: ['./client-contacts-list.component.scss']
})


export class ClientContactsListComponent implements OnInit, OnChanges {
  /** displayedColumns variable array to store the displayed columns */
  displayedColumns: string[] = ['fullName', 'email', 'mobilePhone', 'status'];

  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource < any > ;

  /** page event variable to handle the page events */
  pageEvent: PageEvent;

  /**Direction varialbe to get sort direction */
  direction: SortDirection;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** List of client contacts to be displayed in table */
  @Input() clientContacts: Array < AdminClientContacts > = [];

  /** Total count to be displayed */
  @Input() clientContactCount: number;

  /** Output the sortOptions */
  @Output() sortList = new EventEmitter<{
    sortBy: string,
    sortDirection: string
  }>();

  /** sortDirection to hold the asc or desc direction value */
  sortDirection: 'ASC' | 'DESC' | null;

  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: 'clientEntityName' | 'location' | null;
  /** To get the search text entered in table search */
  @Input() searchKeyword;
  
  constructor() {
    this.dataSource = new MatTableDataSource<any>(this.clientContacts);
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 25;
  }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode === KEY_CODE.RIGHT_ARROW) {
      this.paginator.nextPage();
    }
    if (event.keyCode === KEY_CODE.LEFT_ARROW) {
      this.paginator.previousPage();
    }
  }

  ngOnInit() {
    this.dataSource = new MatTableDataSource < any > (this.clientContacts);
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 25;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.clientContacts && changes.clientContacts.currentValue) {
      this.dataSource.data = changes.clientContacts.currentValue;
      this.dataSource.paginator = this.paginator;
      this.paginator ? this.paginator.length = changes.clientContacts.currentValue.length : null;
      this.paginator.pageIndex = this.pageEvent.pageIndex;
    }
  }

  /**
   * Sort table based on Column name.
   * @param columnName Column name to be sorted.
   */
  applySort(columnName: string) {
    this.initSort(columnName);
    this.sortList.emit({
      sortBy: columnName,
      sortDirection: this.sortDirection
    })
  }

  /**
   * Init Sort ..
   * @param columnName Column name to be sorted.
   */
  initSort(columnName: string): void {
    if (!this.sortDirection && !this.sortColumnName) {
      this.sortDirection = 'ASC';
      this.sortColumnName = columnName as any;
      return;
    };
    this.sortDirection == 'ASC' ? this.sortDirection = 'DESC' : this.sortDirection = 'ASC';
    this.sortColumnName = columnName as any;
  }

}
