import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LinkedListData } from '../../../../core/models/linked-list-data.model';
import { MilestoneService } from '../../../../core/services/milestone.service';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { ModalData } from '../../../../core/services/popup-position.service';
import { Milestones } from '../../../../core/models/milestones.model';

@Component({
  selector: 'app-employee-recent-milestones',
  templateUrl: './employee-recent-milestones.component.html',
  styleUrls: ['./employee-recent-milestones.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeRecentMilestonesComponent implements OnInit {

  /** holds subscription to the data */
  sub: Subscription;
  /** holds subscription to the milestone data */
  milestoneSub: Subscription;
  /** holds the data for the Linked list component */
  milestonesData: LinkedListData[] = [];

  /** Base constructor method
   * @param transactionService TransactionService injection
   * @param dateSvc Date converstion service  injection
   * @param dialogRef MatDialogRef<EmployeeRecentTransactionsComponent> injection
   * @Inject MAT_DIALOG_DATA to get the data from the parent component
   * @param data to get the data through MAT_DIALOG_DATA
   */

  constructor(
    private readonly route: ActivatedRoute,
    private readonly dateSvc: DateConversionService,
    private readonly milestoneSvc: MilestoneService,
    private readonly router: Router,
    @Inject(ModalData) public data: any
  ) { }

  /** viewAll function to view all rcent milestones */
  viewAll(): void {
    this.router.navigate(['/bbadmin/administration/empInfo/' + this.data.fileId], { queryParams: { milestoneView: 'open' } });
    this.close();
  }

  ngOnInit() {
    this.createMilestonesData(this.data.milestones);
  }

  /** setup the key dates data for the linked list component */
  createMilestonesData(milestones: Milestones): void {
    milestones.data.slice(0, 5).forEach(element => {
      let ownerName = '';
      if (element.milestoneDesc === 'Authorized') {
        ownerName = `(by ${milestones.initiatorFirstName} ${milestones.initiatorLastName})`;
      }
      this.milestonesData.push({
        value: this.dateSvc.convertToDisplayDate(element.milestoneDate),
        valueClass: 'active',
        columns: [
          {
            label: element.milestoneDesc,
            subLabel: ownerName
          }
        ]
      } as LinkedListData);
    });
  }

  /** To close the dialog */
  close() {
    this.data.overlayRef.dispose();
  }
}
