export class CategoryMappingHelper{
    public static getCategoryMapping(){
        return {
            "Before You Move": "Before Move",
            "Shipping": "Shipping",
            "Travel and Transport": "Travel & Transport",
            "After You Arrive": "After Arrival",
            "Ongoing": "Ongoing Support",
            "Relocation Allowances": "Allowance"
        }
    }

    public static getBenefitCategories(){ 
        return ['Before You Move', 'Shipping', 'Travel and Transport', 'After You Arrive', 'Relocation Allowances','Ongoing', 'Allowances'];
    }
}