import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkedListComponent } from '../../app/core/components/linked-list/linked-list.component';
import { StarRatingModule } from 'angular-star-rating';
import { MatTooltipModule } from '@angular/material';
import { MatMenuModule, MatTableModule, MatSortModule, MatPaginatorModule, MatDialogModule, MatButtonModule, MatIconModule, MatSelectModule, MatCardModule, MatExpansionModule, MatDividerModule, MatButtonToggleModule, MatFormFieldModule, MatInputModule, MatChipsModule, MatListModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatSlideToggleModule } from '@angular/material';
import { EmployeeMovePhaseComponent } from '../public/dashboard/components/employee-move-phase/employee-move-phase.component';
import { MoveRiskModalComponent } from '../public/dashboard/components/move-risk-modal/move-risk-modal.component';
import { MultiStepComponent } from '../core/components/multi-step/multi-step.component';
import { EmployeeTransactionsComponent } from '../public/dashboard/components/employee-transactions/employee-transactions.component';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPowerBiModule } from 'ngx-powerbi';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { AvatarModule } from '../core/components/avatar/avatar.module';
import { DashboardRoutingModule } from '../public/dashboard/dashboard-routing.module';
import { DownloadMenuButtonComponent } from '../core/components/download-menu-button/download-menu-button.component';
import { BreadcrumbComponent } from '../public/components/breadcrumb/breadcrumb.component';
import { EmployeeHeaderComponent } from '../public/dashboard/components/employee-header/employee-header.component';
import { EmployeeInfoComponent } from '../public/dashboard/components/employee-info/employee-info.component';
import { EmployeeProvidersComponent,FilterPipe } from '../public/provider/components/employee-providers/employee-providers.component';
import { SearchHighlightPipe } from '../public/administration/pipes/search-highlight.pipe';
import { SharedTransfereeProgramModule } from '../public/administration/modules/shared/shared.module';

/* @NgModule({
  declarations: [
    LinkedListComponent,
    MoveRiskModalComponent,
    EmployeeMovePhaseComponent,
    MultiStepComponent,
    EmployeeTransactionsComponent,
    DownloadMenuButtonComponent,
    EmployeeInfoComponent,
    BreadcrumbComponent,
    EmployeeHeaderComponent,
    EmployeeProvidersComponent,
    FilterPipe,
    SearchHighlightPipe

  ],
  imports: [
    CommonModule,
    StarRatingModule.forRoot(),
    MatTooltipModule,
    MatMenuModule,
    MatDividerModule,
    MatIconModule,
    MatCardModule,
    MatPaginatorModule,

    ReactiveFormsModule,
    FlexLayoutModule,
    A11yModule,
    MatTableModule,
    MatSortModule,
    MatDialogModule,
    MatButtonModule,
    MatSelectModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatChipsModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatCheckboxModule,
    AvatarModule,
    NgxPowerBiModule,
    NgxSpinnerModule,
    DragDropModule,
    CdkTableModule,
    CdkTreeModule,
    MatSlideToggleModule,
   // environment.animation ? BrowserAnimationsModule : NoopAnimationsModule,
    DashboardRoutingModule,
    StarRatingModule.forRoot(),
  ],
  exports: [
    LinkedListComponent,
    StarRatingModule,
    MoveRiskModalComponent,
    EmployeeMovePhaseComponent,
    MultiStepComponent,
    EmployeeTransactionsComponent,

    //EmployeeTransactionsComponent,
    DownloadMenuButtonComponent,
    EmployeeInfoComponent,
    BreadcrumbComponent,
    EmployeeHeaderComponent ,
    MultiStepComponent,
    LinkedListComponent,
    StarRatingModule,
    EmployeeMovePhaseComponent ,
    MoveRiskModalComponent,
    EmployeeProvidersComponent,
    FilterPipe,
    SearchHighlightPipe
  ]
})
export class SharedTradionalOrderModule { } */
@NgModule({
  declarations: [
    EmployeeTransactionsComponent,
    DownloadMenuButtonComponent,
    EmployeeInfoComponent,
    BreadcrumbComponent,
    EmployeeHeaderComponent ,
    MultiStepComponent,
    LinkedListComponent,
    EmployeeMovePhaseComponent,
    MoveRiskModalComponent,
    EmployeeProvidersComponent,
    SearchHighlightPipe,
    FilterPipe

  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    A11yModule,
    MatMenuModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatIconModule,
    MatCardModule,
    MatExpansionModule,
    MatDividerModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatChipsModule,
    MatIconModule,
    MatListModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    MatCheckboxModule,
    AvatarModule,
    NgxPowerBiModule,
    NgxSpinnerModule,
    DragDropModule,
    CdkTableModule,
    CdkTreeModule,
    MatSlideToggleModule,
    MatInputModule,
    //environment.animation ? BrowserAnimationsModule : NoopAnimationsModule,
    DashboardRoutingModule,
    StarRatingModule.forRoot(),
    SharedTransfereeProgramModule
  ],
  exports: [
    EmployeeTransactionsComponent,
    DownloadMenuButtonComponent,
    EmployeeInfoComponent,
    BreadcrumbComponent,
    EmployeeHeaderComponent ,
    MultiStepComponent,
    LinkedListComponent,
    StarRatingModule,
    EmployeeMovePhaseComponent,
    MoveRiskModalComponent,
    EmployeeProvidersComponent,
    SearchHighlightPipe,
    SharedTransfereeProgramModule,
    FilterPipe
    

  ]
})
export class SharedTradionalOrderModule { }

