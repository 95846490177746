import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LoginService } from 'src/app/login/login.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BbAdminBackendService {

  constructor(
    private http: HttpClient,
    private loginService: LoginService) { }

  getBbAdminData(): Observable<any> {
    const headers = new HttpHeaders().set("Authorization", this.loginService.getJwtToken());
    const url = environment.bbadmin;

    return this.http.get<any>(url, { headers: headers })
      .pipe(
        catchError(err => {
          console.log('Failed to get data', err);
          throw err;
        })
      );
  }
}
