import { ConnectionService } from 'ng-connection-service';
import { BehaviorSubject } from 'rxjs/Rx';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';



export class NetworkService {
    constructor(private connectionService:ConnectionService,  private http: HttpClient  ) {
this.Monitor();
    }
    public isNetwork = new BehaviorSubject<Boolean>(true);
    public isNetworkNeedToCheck = new BehaviorSubject<Boolean>(true);
    Monitor(){
        this.connectionService.monitor().subscribe(isConnected => {
           this.isNetwork.next(isConnected);
            });
    }

        makeRequest():Observable<any> {
            const url = `https://reqres.in/api/users?page=${new Date()}`;
            return this.http.get(url)
            .pipe( map(r => {
                        console.log(r);
                        return of(true);
                    }),
                catchError((err, source) => {
                const empty  = null;
                return of(empty);
                })
            );
        }
    }