import { Injectable } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

export type UserType = 'application-owner' | 'file-setup-specialist' | 'application-reliability-analyst' | 'client-contact' |'client-account-manager' | 'client-contact-initiator' | 'program-admin' | 'consultant';

export interface UserRole {
  clientPartyId: string;
  _id: string;
  roleName: UserType | any;
  capabilities: Array<{
    name: string;
    operation: string;
    permission: string;
    description: string
    level: string
  }>;
  friendlyName: string;
  bypassAssociation: string;
}

export interface RoleCapabilitiesRes {
  loginUserRoleCapabilities: Array<UserRole>;
}

@Injectable({
  providedIn: 'root'
})
export class UserTypeService {
  clientPartyId: string;

  constructor(private baseClientService: BaseClientService, private logSvc: RemoteLoggingService) { }


  getUserRoles(): Observable<RoleCapabilitiesRes> {
    return of({
      "loginUserRoleCapabilities": [
        {
          "_id": "5ee281c76c3be02adc4e156c",
          "clientPartyId": "5df51953eb1bc880568c6a93",
          "roleName": "client-contact-initiator",
          "capabilities": [
            {
              "name": "Self Registration",
              "operation": "write",
              "permission": "allow",
              "description": "Grants ability to send a self registration email to candidates.",
              "level": "UI"
            },
            {
              "name": "Cost Models",
              "operation": "write",
              "permission": "allow",
              "description": "Grants ability perform CRUD operation on cost models created by them.",
              "level": "UI"
            },
            {
              "name": "Manage Candidates/Transferees",
              "operation": "write",
              "permission": "allow",
              "description": "Grants ability perform CRUD operation on candidates and transferees created by them.",
              "level": "UI"
            },
            {
              "name": "View Destination",
              "operation": "write",
              "permission": "allow",
              "description": "Grants ability to view destination information.",
              "level": "UI"
            },
            {
              "name": "Orders",
              "operation": "write",
              "permission": "allow",
              "description": "Grants access to all orders",
              "level": "Core"
            },
            {
              "name": "Order Requests",
              "operation": "write",
              "permission": "allow",
              "description": "Grants access to all order requests",
              "level": "Core"
            },
            {
              "name": "Product Instances",
              "operation": "write",
              "permission": "allow",
              "description": "Grants access to product instances",
              "level": "Core"
            }
          ],
          "friendlyName": "Initiator",
          "bypassAssociation": null
        },
        {
          "_id": "5ee281c76c3be02adc4e1569",
          "clientPartyId": "5df51953eb1bc880568c6a93",
          "roleName": "client-contact",
          "capabilities": null,
          "friendlyName": "Client Contact",
          "bypassAssociation": null
        }
      ]
    })
    // return this.baseClientService
    //   .getOne<any>(`v1/admin/roles-capabilities`)
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //     this.logSvc.logError(err);
    //     const empty = null;
    //       return of(empty);
    //     })
    //   );
  }

  get userCapabilities() {
    return atob(JSON.parse(sessionStorage.getItem('car-user-cap') as any)) as any;
  }

  set userCapabilities(data: UserRole) {
    sessionStorage.setItem('car-user-cap', btoa(JSON.stringify(data as any)));
  }

  get userType(): UserType {
    return sessionStorage.getItem('car-user-type') as any;
  }

  set userType(userType: UserType) {
    sessionStorage.setItem('car-user-type', userType);
  }

}
