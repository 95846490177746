import { Inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs/Rx';
import { BenefitsData } from '../../public/benefits/services/benefit.service';
import { NeedsAssessmentSharedService } from '../../public/needs-assessment/services/needs-assessment-shared.service';
import { benefitsData, consentinfodata, countryinfodata, familydata, informativetextdata, taskData, taskInfo, timelineData, transfereeNeedsAssessmentData } from '../data/app-data';
import { TransfereeNeedsAssessment } from '../models/candidateneeds-assessment.model';
import { Tasks } from '../models/tasks.model';

export const bbdatakey = "bbdata";
/**
 * Gets the global application configuration data
 */
@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  /** Behavioural subjects for stub datas starts*/
  public benefitsStubData = new BehaviorSubject<BenefitsData>(benefitsData);

  public transfereeNeedsAssementStubData = new BehaviorSubject<TransfereeNeedsAssessment>(transfereeNeedsAssessmentData);

  public familyStubData = new BehaviorSubject<any>(familydata);

  public consentinfoStubData = new BehaviorSubject<any>(consentinfodata);

  public informativetextStubdata = new BehaviorSubject<any>(informativetextdata);

  public taskStubData = new BehaviorSubject<Tasks>(taskData);

  public countryinfoStubdata = new BehaviorSubject<any>(countryinfodata);

  public timelineStubData = new BehaviorSubject<any>(timelineData);

  public taskInfoStubdata = new BehaviorSubject<any>(taskInfo);

  public bbdata = new BehaviorSubject<any>(null);

  /** Behavioural subjects for stub datas ends*/

  public constructor(
    private injector: Injector,
  ) {
    if (this.bbdata.getValue()) {
      this.InitializeStubData(this.bbdata.getValue());
    } else {
      if (localStorage.getItem(bbdatakey)) {
        this.bbdata.next(JSON.parse(localStorage.getItem(bbdatakey)));
        this.InitializeStubData(this.bbdata.getValue());
      }
    }
  }
  /** Update Behavioural subjects for stub datas starts*/
  public UpdateBenefitsStubdata(data) {
    this.benefitsStubData.next(data);
  }
  /** Update Behavioural subjects for stub datas ends*/




  /** Initiate Behavioural subjects for stub datas starts*/
  public InitializeData() {


  }

  public InitializeStubData(bbData) {
    if (bbData) {

      this.benefitsStubData.next(bbData.orderRequestDetails[0].benefitData ? this.parseBenefitsData(bbData) : benefitsData);
      this.familyStubData.next(bbData.orderRequestDetails[0].familydata ? bbData.orderRequestDetails[0].familydata : familydata);
      this.taskStubData.next(bbData.orderRequestDetails[0].taskData ? bbData.orderRequestDetails[0].taskData : taskData);
      // this.transfereeNeedsAssementStubData.next(bbData.transfereeNeedsAssessmentData
      //   ? bbData.transfereeNeedsAssessmentData : transfereeNeedsAssessmentData);
      this.consentinfoStubData.next(bbData.orderRequestDetails[0].consentinfodata ? bbData.orderRequestDetails[0].consentinfodata : consentinfodata);
      this.countryinfoStubdata.next(bbData.orderRequestDetails[0].countryinfodata ? bbData.orderRequestDetails[0].countryinfodata : countryinfodata);
      this.informativetextStubdata.next(bbData.orderRequestDetails[0].informativetextdata ? bbData.orderRequestDetails[0].informativetextdata : informativetextdata);
      // this.timelineStubData.next(bbData.timeLines ? timelineData.timeLines = bbData.timeLines : timelineData);
      this.taskInfoStubdata.next(bbData.orderRequestDetails[0].taskInfo ? bbData.orderRequestDetails[0].taskInfo : taskInfo);
      this.parseNeedsAssementData(bbData);
      this.parseTimelinedata(bbData);
    }
  }
  /** Initiate Behavioural subjects for stub datas ends*/
  parseNeedsAssementData(bbData) {

    let needsAssementData: any = {};
    needsAssementData.orderRequestId = bbData.orderRequestDetails[0].orderRequestId;
    needsAssementData.orderRefNum = bbData.orderRequestDetails[0].orderRefNum;
    needsAssementData.clientId = bbData.orderRequestDetails[0].clientId;
    needsAssementData.clientEntityName = bbData.orderRequestDetails[0].clientEntityName;
    needsAssementData.programName = bbData.orderRequestDetails[0].programName;
    needsAssementData.nameDetails = bbData.orderRequestDetails[0].nameDetails;
    needsAssementData.departureAddr = bbData.orderRequestDetails[0].departureAddr;
    needsAssementData.destinationAddr = bbData.orderRequestDetails[0].destinationAddr;
    bbData.orderRequestDetails[0].spousePartnerRelocating ? needsAssementData.spousePartnerRelocating = bbData.orderRequestDetails[0].spousePartnerRelocating : null;
    bbData.orderRequestDetails[0].childrenDependentsRelocating ? needsAssementData.childrenDependentsRelocating : null;
    bbData.orderRequestDetails[0].totalNumberOfRelocatingMembers ? needsAssementData.totalNumberOfRelocatingMembers = bbData.orderRequestDetails[0].totalNumberOfRelocatingMembers : null;
    bbData.orderRequestDetails[0].confirmStatus ? needsAssementData.confirmStatus = bbData.orderRequestDetails[0].confirmStatus : null;
    bbData.orderRequestDetails[0].jobStartDate ? needsAssementData.jobStartDate = bbData.orderRequestDetails[0].jobStartDate : null;
    bbData.orderRequestDetails[0].children ? needsAssementData.children = bbData.orderRequestDetails[0].children : null;
    bbData.orderRequestDetails[0].phoneDetailsList ? needsAssementData.phoneDetailsList = bbData.orderRequestDetails[0].phoneDetailsList : null;
    bbData.orderRequestDetails[0].emailDetailsList ? needsAssementData.emailDetailsList = bbData.orderRequestDetails[0].emailDetailsList : null;
    bbData.orderRequestDetails[0].pointDetails ? needsAssementData.pointDetails = bbData.orderRequestDetails[0].pointDetails : null;
    bbData.orderRequestDetails[0].benefitDetails ? needsAssementData.benefitDetails = bbData.orderRequestDetails[0].benefitDetails : null;

    this.transfereeNeedsAssementStubData.next(needsAssementData);
  }

  parseTimelinedata(bbData) {
    let timeline: any = {};
    timeline.timeLines = bbData.orderRequestDetails[0].timeLines;
    timeline.count = bbData.orderRequestDetails[0].timeLines.length;
    this.timelineStubData.next(timeline);
  }

  parseBenefitsData(bbData) {
    let benefitsDataObj: BenefitsData = {
      availableBenefits: bbData.orderRequestDetails[0].benefitData.availablebenefits,
      selectedBenefits: bbData.orderRequestDetails[0].benefitData.selectedbenefits,
      confirmedBenefits: bbData.orderRequestDetails[0].benefitData.confirmedbenefits,
      points: bbData.orderRequestDetails[0].benefitData.points
    }
    return benefitsDataObj;
  }
}