// tslint:disable-next-line: max-line-length
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, QueryList, SimpleChanges, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatSelectionList, MatSelectionListChange } from '@angular/material';
import { FilterSelectionOption } from '../../../../../core/models/common/filter-selection-option.model';
import { NgModel } from '@angular/forms';
import { debounceTime, distinctUntilChanged, skip, filter, switchMap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

/** FilterSelectionComponent for the list of check boxes*/
@Component({
  selector: 'app-admin-filter-selection',
  templateUrl: './filter-selection.component.html',
  styleUrls: ['./filter-selection.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminFilterSelectionComponent implements OnInit, OnChanges {

  @Input() PlaceHolder: string;

  /** used for element's aria-labelledby */
  @Input() labelId: string; 

  searchText: string;

  @Input() filterOptions: FilterSelectionOption;

  @Input() dataSet = []

  @ViewChild('checkBoxList', { static: false }) checkBoxList: MatSelectionList;

  @ViewChildren('muliptleList') multiCheckBoxList: QueryList<MatSelectionList>;

  @Output() search: EventEmitter<string> = new EventEmitter<string>();

  @Output() selectedFilters: EventEmitter<any> = new EventEmitter<any>();

  @Output() selectedDataSetChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() selectedDataSet: Array<any> = [];
  selectedLength: number = 0;

  selectedOptions = [];

  textSearchSub: Subscription;

  /**Base Constructor */
  constructor() {
    this.filterOptions = new FilterSelectionOption({});
  }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) { 
    if (changes.dataSet && changes.dataSet.firstChange) {
      if (this.dataSet && Array.isArray(this.dataSet) && this.dataSet.length > 0) {
        setTimeout(() => {

          // Selection type Controls..
          if (this.filterOptions.type === 'locations') {
            this.dataSet.map(data => this.selectedLength = this.selectedLength + data.values.length);
            this.multiCheckBoxList.map(item => {
              item.selectAll();
            });

            const tempSelectedDataSet = [];
            this.dataSet.forEach(data => {
              if (data.values && Array.isArray(data.values) && data.values.length > 0 ) {
                data.values.forEach(d => {
                  tempSelectedDataSet.push({
                    label: data.label,
                    value: d
                  });
                });
              }
            });

            this.selectedDataSet = tempSelectedDataSet;
          }

        }, 200);
      }
    }
    if (changes.dataSet && changes.dataSet.currentValue && !changes.dataSet.firstChange) {
      if (this.dataSet.length == 0) {
        //this.clearAll();
      }
    }

    // Auto Select the Single Check List and Update the count. 
    if (changes.selectedDataSet && changes.selectedDataSet.firstChange  && this.selectedDataSet) {
      setTimeout(() => {
        this.selectedDataSet.map(selectedItem => {
          this.checkBoxList.options.find(item => item.value === selectedItem).toggle();
          ++this.selectedLength;
        });
      }, 500);
    }
  }

  fetchResult() {
    if (this.searchText.length >= this.filterOptions.searchCharLimit) {
      setTimeout(() => {
        this.search.emit(this.searchText);
      }, this.filterOptions.searchDelayInMilliSeconds);
    }
  }

  /** clearAll() varaible to clear the selected options */
  clearAll() {
    if (this.filterOptions.type === 'locations') {
      (this.multiCheckBoxList) ? this.multiCheckBoxList.forEach(item => item.deselectAll()) : null;
      this.selectedLength = 0;
      this.selectedDataSet = [];
    } else {
      (this.checkBoxList) ? this.checkBoxList.deselectAll() : null;
      this.selectedDataSet = [];
      this.selectedLength = 0;
      this.selectedOptions = [];
    }
    this.selectedDataSetChange.emit(this.selectedDataSet);
  }

  clearInputText() {
    this.searchText = '';
    this.search.emit('');
  }

  setSelectedOptions(event: MatSelectionListChange, group?: string) {
    // If selected push to selectedDataSet else remove
    const selectedValue = event.option.value;
    if (event.option.selected) {
      this.selectedDataSet.push(selectedValue);
      ++this.selectedLength;
    } else {
      if (group) {
        const index = this.selectedDataSet.findIndex(item => item.value === selectedValue.value && item.label === group);
        this.selectedDataSet.splice(index, 1);
      } else {
        this.selectedDataSet = this.selectedDataSet.filter(item => item !== selectedValue);
      }
      --this.selectedLength;
    }
    this.selectedDataSetChange.emit(this.selectedDataSet);
  }

}
