import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';

import { AdministrationComponent } from './administration.component';
import { ListComponent } from './components/list/list.component';
import { EmployeeInfoComponent } from '../dashboard/components/employee-info/employee-info.component';
import { CostTransactionsComponent } from '../dashboard/components/cost-transactions/cost-transactions.component';
import { ProviderComponent } from '../provider/provider.component';

import { CostModelComponent } from '../cost-model/cost-model.component';
import { AccountSettingsComponent } from '../dashboard/components/account-settings/account-settings.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: '',
  //   pathMatch: 'full'
  // },
  {
    path: 'transferee',
    component: AdministrationComponent,
    data: { navId: 1, title: 'Administration', showGlobalTemplate: true },
    children: [
      {
        path: '',
        component: ListComponent,
        data: { navId: 1, title: 'Administration', showGlobalTemplate: true }
      }
    ]
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    data: { navId: 1, title: 'Home', showGlobalTemplate: true },
  },
  {
    path:'settings/:id',
    component:AccountSettingsComponent,
    data: { navId: 1, title: 'Settings', showGlobalTemplate: true }
  },
  {
    path: 'empInfo/:id',
    component: EmployeeInfoComponent,
    data: { navId: 1, title: 'Employee Information', showGlobalTemplate: true }
  },
  {
    path: 'emp-cost-transactions/:id',
    component: CostTransactionsComponent,
    data: { navId: 1, title: 'All Cost Transactions of Employee', showGlobalTemplate: true }
  },
  {
    path: 'provider/:id',
    component: ProviderComponent,
    data: { navId: 1, title: 'Provider', showGlobalTemplate: true }
  },
  {
    path: 'cost-model',
    component: CostModelComponent,
    data: { navId: 1, title: 'Cost Model', showGlobalTemplate: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }


/**
 *
 * Client Contact    -     Program Admin
 * /administration
 */