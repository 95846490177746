import { getDatefromCurrentDate } from "./programListData";

export const transfereeListData = {
  "candidates": [
    {
      "_id": "6214f0e9e6a99e3b4c41146f",
      "identifier": "transferees",
      "orderRequestId": "60124a5de1352200098cfef0",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Robert",
        "lastName": "Angel"
      },
      "phoneDetailsList": [
        {
          "_id": "60124a5799d733000915423a",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60124a5799d733000915423b",
          "primary": true,
          "usageType": "business",
          "emailAddress": "robert.210128100192@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Robert",
            "lastName": "Angel"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1d",
        "programName": "High Value US Inbound Program",
        "points": 25,
        "pointOverride": false,
        "originalPoints": 25
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "333 Washington Avenue",
        "city": "Belleville",
        "state": "New Jersey",
        "country": "United States",
        "postalCode": "07109"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-04-05",
      "moveStatusDate": "2022-03-26",
      "authorizationDate": "2022-03-26"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411470",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Kevin",
        "lastName": "William"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1d",
        "programName": "Executive Homeowner Program",
        "points": 120,
        "pointOverride": false,
        "originalPoints": 120
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Platteville",
        "state": "Colorado",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "New Haven",
        "state": "Connecticut",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-04-10",
      "moveStatusDate": "2022-03-21",
      "authorizationDate": "2022-03-21"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411471",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Alisson",
        "lastName": "Becker"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Fabio",
            "lastName": "Henrique"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1e",
        "programName": "Expat - Long Term Program",
        "points": 90,
        "pointOverride": false,
        "originalPoints": 90
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "1200, Avenida Universidad, Xoco",
        "city": "Ciudad de México",
        "state": "Ciudad de México",
        "country": "Mexico",
        "postalCode": "03330"
      },
      "destinationAddr": {
        "streetLine1": "1200, Rua João Teodoro, Brás, São Paulo, Brazil",
        "city": "",
        "state": "São Paulo",
        "country": "Brazil",
        "postalCode": "03009-000"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-04-15",
      "moveStatusDate": "2022-03-16",
      "authorizationDate": "2022-03-16"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411472",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Arthur",
        "lastName": "Tyler"
      },
      "phoneDetailsList": [
        {
          "_id": "6011991b79488a00082a8de4",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "5878609523"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "6011991c79488a00082a8de5",
          "primary": true,
          "usageType": "business",
          "emailAddress": "arthur.tyler@cartus.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1e",
        "programName": "US Outbound Program",
        "points": 25,
        "pointOverride": false,
        "originalPoints": 25
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Tulalip Bay",
        "state": "Washington",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-04-20",
      "moveStatusDate": "2022-03-11",
      "authorizationDate": "2022-03-11"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411473",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Jackson",
        "lastName": "Jayden"
      },
      "phoneDetailsList": [
        {
          "_id": "5f893f2a6b0aec000898e9cc",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 91,
          "phoneNumber": "8908462287"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "5f893f2a6b0aec000898e9cd",
          "primary": true,
          "usageType": "business",
          "emailAddress": "jackson.jaydeb@mobilitydba.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "Invitation Not Sent",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1g",
        "programName": "One Way Program",
        "points": 20,
        "pointOverride": false
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Mayfield Heights",
        "state": "Ohio",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Theodore",
        "state": "Alabama",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-04-25",
      "moveStatusDate": "2022-03-06",
      "authorizationDate": "2022-03-06"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411474",
      "identifier": "transferees",
      "orderRequestId": "60124a5de1352200098cfef0",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Logan",
        "lastName": "Christopher"
      },
      "phoneDetailsList": [
        {
          "_id": "60124a5799d733000915423a",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60124a5799d733000915423b",
          "primary": true,
          "usageType": "business",
          "emailAddress": "robert.210128100192@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Robert",
            "lastName": "Angel"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1f",
        "programName": "US Inbound Program",
        "points": 22,
        "pointOverride": false
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "333 Washington Avenue",
        "city": "Tulalip Bay",
        "state": "Washington",
        "country": "United States",
        "postalCode": "07109"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Platteville",
        "state": "Colorado",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-04-30",
      "moveStatusDate": "2022-03-01",
      "authorizationDate": "2022-03-01"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411475",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Mr.",
        "firstName": "Christopher",
        "lastName": "Joshua"
      },
      "phoneDetailsList": [
        {
          "_id": "6012b5d97e122600090c6726",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "9886345125"
        },
        {
          "_id": "6012b6d97e122600090c6728",
          "textingAvailable": false,
          "primary": false,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "8765432198"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "6012b5da7e122600090c6727",
          "primary": true,
          "usageType": "business",
          "emailAddress": "christopher.joshua@test.com"
        },
        {
          "_id": "6012b6d97e122600090c6729",
          "primary": false,
          "usageType": "personal",
          "emailAddress": "test@example.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1g",
        "programName": "Executive Renter Program",
        "points": 97,
        "pointOverride": false,
        "originalPoints": 97
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Torrance",
        "state": "California",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-05-05",
      "moveStatusDate": "2022-02-24",
      "authorizationDate": "2022-02-24"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411476",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Naomi",
        "lastName": "Osaka"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Hideki",
            "lastName": "Matsui"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1e",
        "programName": "Expat - Long Term Program",
        "points": 90,
        "pointOverride": false,
        "originalPoints": 90
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "1, 29, 2-chōme, Dōgenzaka",
        "city": "Shibuya City",
        "state": "Tokyo",
        "country": "Japan",
        "postalCode": "150-0043"
      },
      "destinationAddr": {
        "streetLine1": "65, Rue de Saintonge",
        "city": "Paris",
        "state": "Île-de-France",
        "country": "France",
        "postalCode": "75003"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "Traditional",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-05-10",
      "moveStatusDate": "2022-02-19",
      "authorizationDate": "2022-02-19"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411477",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Jason",
        "lastName": "Baker"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Jason",
            "lastName": "Clark"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1e",
        "programName": "Expat - Long Term Program",
        "points": 90,
        "pointOverride": false,
        "originalPoints": 90
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "El Paso",
        "state": "Texas",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-05-15",
      "moveStatusDate": "2022-02-14",
      "authorizationDate": "2022-02-14"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411478",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Mr.",
        "firstName": "Mark",
        "lastName": "Nicholas"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1f",
        "programName": "Executive Renter Program",
        "points": 97,
        "pointOverride": false,
        "originalPoints": 97
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Hilton Head Island",
        "state": "South Carolina",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "New Haven",
        "state": "Connecticut",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-05-20",
      "moveStatusDate": "2022-02-09",
      "authorizationDate": "2022-02-09"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411479",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Sidney",
        "lastName": "Zhang"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5df51959eb1bc893788c6a96",
        "programName": "Standard Renter Program",
        "points": 66,
        "pointOverride": false,
        "originalPoints": 66
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Purchase",
        "state": "New York",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, Beer Sheva",
        "city": "Beer Sheva",
        "state": "",
        "country": "Israel",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-05-25",
      "moveStatusDate": "2022-02-04",
      "authorizationDate": "2022-02-04"
    },
    {
      "_id": "6214f0e9e6a99e3b4c41147a",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Willie",
        "lastName": "Aiden"
      },
      "phoneDetailsList": [
        {
          "_id": "60124b0799d7330009154246",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "5788555435"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60124b0799d7330009154247",
          "primary": true,
          "usageType": "business",
          "emailAddress": "aiden.willie@cartus.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1g",
        "programName": "One Way Program",
        "points": 20,
        "pointOverride": false,
        "originalPoints": 20
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Tulalip Bay",
        "state": "Washington",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-05-30",
      "moveStatusDate": "2022-01-30",
      "authorizationDate": "2022-01-30"
    },
    {
      "_id": "6214f0e9e6a99e3b4c41147b",
      "identifier": "transferees",
      "orderRequestId": "60124a5de1352200098cfef0",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Benjamin",
        "lastName": "Michael"
      },
      "phoneDetailsList": [
        {
          "_id": "6012877e9600a80008eded98",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        },
        {
          "_id": "601288849600a80008eded9b",
          "textingAvailable": false,
          "primary": false,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "8765432198"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "6012877e9600a80008eded99",
          "primary": true,
          "usageType": "business",
          "emailAddress": "benjamin.m@cartus.com"
        },
        {
          "_id": "601288849600a80008eded9a",
          "primary": false,
          "usageType": "personal",
          "emailAddress": "test@example.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Robert",
            "lastName": "Angel"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1f",
        "programName": "US Outbound Program",
        "points": 25,
        "pointOverride": false,
        "originalPoints": 25
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "333 Washington Avenue",
        "city": "Tulalip Bay",
        "state": "Washington",
        "country": "United States",
        "postalCode": "07109"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Belleville",
        "state": "New Jersey",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-06-04",
      "moveStatusDate": "2022-01-25",
      "authorizationDate": "2022-01-25"
    },
    {
      "_id": "6214f0e9e6a99e3b4c41147c",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Mr.",
        "firstName": "Antony",
        "lastName": "David"
      },
      "phoneDetailsList": [
        {
          "_id": "5fabdaf28c8d0100078f6b57",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 91,
          "phoneNumber": "8908462287"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "5fabdaf28c8d0100078f6b58",
          "primary": true,
          "usageType": "business",
          "emailAddress": "david.Antony@mobilitydba.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1c",
        "programName": "One Way Program",
        "points": 20,
        "pointOverride": false
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "New Haven",
        "state": "Connecticut",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Platteville",
        "state": "Colorado",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-06-09",
      "moveStatusDate": "2022-01-20",
      "authorizationDate": "2022-01-20"
    },
    {
      "_id": "6214f0e9e6a99e3b4c41147d",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Mr.",
        "firstName": "Alicia",
        "lastName": "Aaron"
      },
      "phoneDetailsList": [
        {
          "_id": "5f50c19f329826000720ef56",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 91,
          "phoneNumber": "8971706167"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "5f50c19f329826000720ef57",
          "primary": true,
          "usageType": "business",
          "emailAddress": "alicia.aaron@cartus.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "Deactivated",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1d",
        "programName": "Executive Renter Program",
        "points": 97,
        "pointOverride": false
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Seattle",
        "state": "Washington",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "New York",
        "state": "New York",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-06-14",
      "moveStatusDate": "2022-01-15",
      "authorizationDate": "2022-01-15"
    },
    {
      "_id": "6214f0e9e6a99e3b4c41147e",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Mr.",
        "firstName": "Shweta",
        "lastName": "Wang"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5df51959eb1bc893788c6a96",
        "programName": "Executive Homeowner Program",
        "points": 120,
        "pointOverride": false,
        "originalPoints": 120
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Sri Ganganagar",
        "state": "Rajashthan",
        "country": "India",
        "postalCode": "678000"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "New York",
        "state": "New York",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-06-19",
      "moveStatusDate": "2022-01-10",
      "authorizationDate": "2022-01-10"
    },
    {
      "_id": "6214f0e9e6a99e3b4c41147f",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Mr.",
        "firstName": "Mark",
        "lastName": "Ethan"
      },
      "phoneDetailsList": [
        {
          "_id": "60119d585415a10009046219",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "6878556841"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119d595415a1000904621a",
          "primary": true,
          "usageType": "business",
          "emailAddress": "ethan.mark@cartus.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "Benefits Confirmed",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1f",
        "programName": "Executive Renter Program",
        "points": 97,
        "pointOverride": false,
        "originalPoints": 97
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Torrance",
        "state": "California",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "El Paso",
        "state": "Texas",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-06-24",
      "moveStatusDate": "2022-01-05",
      "authorizationDate": "2022-01-05"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411480",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Julian",
        "lastName": "URBANSKE"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5df51959eb1bc893788c6a96",
        "programName": "US Outbound Program",
        "points": 25,
        "pointOverride": false
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "London",
        "state": "",
        "country": "United Kingdom",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Purchase",
        "state": "New York",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-06-29",
      "moveStatusDate": "2021-12-31",
      "authorizationDate": "2021-12-31"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411481",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Mr.",
        "firstName": "Dennis",
        "lastName": "John"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Elon",
            "lastName": "Mask"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1c",
        "programName": "Expat - Long Term Program",
        "points": 90,
        "pointOverride": false,
        "originalPoints": 90
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Torrance",
        "state": "California",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Hilton Head Island",
        "state": "South Carolina",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-07-04",
      "moveStatusDate": "2021-12-26",
      "authorizationDate": "2021-12-26"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411482",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Sergio",
        "lastName": "Gonzalez"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Sergio",
            "lastName": "Martinez"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "Invitation Not Sent",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "priority": "Normal"
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1e",
        "programName": "Expat - Long Term Program",
        "points": 90,
        "pointOverride": false,
        "originalPoints": 90
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "45, Bismarckstraße, Bezirk Charlottenburg-Wilmersdorf",
        "city": "Berlin",
        "state": "Berlin",
        "country": "Germany",
        "postalCode": "10627"
      },
      "destinationAddr": {
        "streetLine1": "1937, Avenida Santa Fe, Barrio Norte, Recoleta, Comuna 2, Argentina, AAB",
        "city": "Ramos Mejía",
        "state": "Buenos Aires",
        "country": "Argentina",
        "postalCode": "C1123"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "Traditional",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-07-09",
      "moveStatusDate": "2021-12-21",
      "authorizationDate": "2021-12-21"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411483",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Emma",
        "lastName": "Jackson"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Rande",
            "lastName": "Jackson"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1e",
        "programName": "Expat - Long Term Program",
        "points": 90,
        "pointOverride": false,
        "originalPoints": 90
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "Liverpool Street Station, London, Greater London, United Kingdom",
        "city": "London",
        "state": "England",
        "country": "United Kindom",
        "postalCode": "EC2M 7QA"
      },
      "destinationAddr": {
        "streetLine1": "37, Chao Qian Lu, Changping Qu, China",
        "city": "Baotou",
        "state": "Beijing Shi",
        "country": "China",
        "postalCode": "102249"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-07-14",
      "moveStatusDate": "2021-12-16",
      "authorizationDate": "2021-12-16"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411484",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Qing",
        "lastName": "Yuan"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Wille",
            "lastName": "Yuan"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1e",
        "programName": "Expat - Long Term Program",
        "points": 90,
        "pointOverride": false,
        "originalPoints": 90
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "370, Pu Dian Lu, Pudong Xinqu, China",
        "city": "Baotou",
        "state": "Shanghai Shi",
        "country": "China",
        "postalCode": "200020"
      },
      "destinationAddr": {
        "streetLine1": "Liverpool Street Station, London, Greater London, United Kingdom",
        "city": "London",
        "state": "England",
        "country": "United Kindom",
        "postalCode": "EC2M 7QA"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-07-19",
      "moveStatusDate": "2021-12-11",
      "authorizationDate": "2021-12-11"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411485",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13671",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "John",
        "lastName": "Mayer"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620d",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620c",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Jason",
            "lastName": "Clark"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4c",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1e",
        "programName": "Expat - Long Term Program",
        "points": 90,
        "pointOverride": false,
        "originalPoints": 90
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "El Paso",
        "state": "Texas",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-07-24",
      "moveStatusDate": "2021-12-06",
      "authorizationDate": "2021-12-06"
    },
    {
      "_id": "6214f0e9e6a99e3b4c411486",
      "identifier": "transferees",
      "orderRequestId": "60119c2b9663ef0008b13679",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Robert",
        "lastName": "Angel"
      },
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Mr.",
            "firstName": "Kevin",
            "lastName": "Angel"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date",
          "date": "2022-04-10"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call",
          "date": "2022-03-31"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed",
          "date": "2022-03-31"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1d",
        "programName": "Executive Homeowner Program",
        "points": 120,
        "pointOverride": false,
        "originalPoints": 120
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Platteville",
        "state": "Colorado",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "New Haven",
        "state": "Connecticut",
        "country": "United States",
        "postalCode": "90265"
      },
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "benefitData": {
        "avalaiblebenefits": [
          {
            "reference": "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
            "productSpecificationReference": "PRD-HmPrchReimb",
            "displayName": "Home Purchase Reimbursement",
            "description": "If you choose to purchase a new home you may be covered for non-recurring normal, and customary buyer’s closing costs per policy guidelines. If you use an Eaton preferred lender, these cost will be directly billed to Cartus.",
            "category": "After You Arrive",
            "points": 5
          },
          {
            "reference": "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cash Out",
            "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
            "category": "Allowances",
            "points": 0,
            "tierConfigs": [
              {
                "maxSelection": 2,
                "pointValue": 250,
                "minRange": 1,
                "maxRange": 2
              },
              {
                "maxSelection": 3,
                "pointValue": 300,
                "minRange": 3,
                "maxRange": 5
              },
              {
                "maxSelection": 4,
                "pointValue": 100,
                "minRange": 6,
                "maxRange": 9
              }
            ]
          },
          {
            "reference": "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
            "productSpecificationReference": "PRD-HmFndng",
            "displayName": "Home Finding - 6 days/5 nights",
            "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.",
            "category": "Before you Move",
            "points": 3
          },
          {
            "reference": "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
            "productSpecificationReference": "PRD-BrkrFndrFee",
            "displayName": "Rental Assistance and Finder's Fee",
            "description": "Reimbursement of agency fees associated with securing a lease in your new location.  Please speak with your consultant about any limitations in the fees.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 12,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
            "productSpecificationReference": "PRD-SlfMv",
            "displayName": "Self-organized Move",
            "description": "If you elect to move your household goods to the new location on your own, you will receive a lump sum payment to cover your expenses.  Policy limits may apply.  Please check your policy handbook or speak with your consultant.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
              "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Full Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget up to the approved point value.",
            "category": "After You Arrive",
            "points": 4,
            "exclusions": [
              "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896"
            ]
          },
          {
            "reference": "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
            "productSpecificationReference": "PRD-RentalSvcs",
            "displayName": "Half Day Rental Services",
            "description": "If you elect to rent in the destination, Cartus will arrange for you to view properties with a rental specialist based on your preferences and budget.",
            "category": "After You Arrive",
            "points": 2,
            "exclusions": [
              "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177"
            ]
          },
          {
            "reference": "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
            "productSpecificationReference": "PRD-ReturnTrpBndl",
            "displayName": "Return Trip Home - Total 3 trips",
            "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living.  This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
            "category": "Travel and Transport",
            "points": 3,
            "maxMul": 3
          },
          {
            "reference": "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - up to 120 additional days",
            "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 150,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
            "productSpecificationReference": "PRD-HmSlReimb",
            "displayName": "Home Sale Reimbursement",
            "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs. Each point is USD 500.",
            "category": "Before You Move",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 15,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "USD",
            "perPointCurrency": 500
          },
          {
            "reference": "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
            "productSpecificationReference": "PRD-DupHsngBndl",
            "displayName": "Duplicate Housing - Total 3 months",
            "description": "If you close on a new home prior to the sale of your existing home in the departure location, you will be reimbursed for  mortgage interest, taxes, insurance and other potential expenses outlined by policy for one residence. Capped at USD 2,000 per month.",
            "category": "After you Arrive",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
            "productSpecificationReference": "PRD-SlfStrg",
            "displayName": "Self-organized Storage",
            "description": "Reimbursement of self storage for 30 days.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
              "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e"
            ]
          },
          {
            "reference": "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
            "productSpecificationReference": "PRD-FrnRntl",
            "displayName": "Furniture Rental",
            "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
            "category": "After You Arrive",
            "points": 0,
            "rangeMin": 1,
            "rangeMax": 3,
            "rangeIncrementValue": 1,
            "rangeIncrementUnit": "Number of Months",
            "pointsPerIncrement": 4
          },
          {
            "reference": "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
            "productSpecificationReference": "PRD-CarRntl",
            "displayName": "Car Rental",
            "description": "Reimbursement for economy class car rental including child seats, if needed.",
            "category": "Travel and Transport",
            "points": 3
          },
          {
            "reference": "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
            "productSpecificationReference": "PRD-VhclShpmtBndl",
            "displayName": "Automobile Shipment - up to 3 automobiles",
            "description": "Cartus will arrange for your automobiles, up to a maximum of three, to be shipped on a car carrier from your departure location to your new location. Your consultant will advise you if there is a mileage limitation.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 3
          },
          {
            "reference": "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
            "productSpecificationReference": "PRD-SpouseCareerSupp",
            "displayName": "Spouse/Partner Career Support",
            "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
            "category": "After You Arrive",
            "points": 4
          },
          {
            "reference": "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
            "productSpecificationReference": "PRD-PetTrnsprtBndl",
            "displayName": "Pet Transportation Service - Total 2 Pets",
            "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
            "category": "Shipping",
            "points": 4,
            "maxMul": 2
          },
          {
            "reference": "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Storage in Transit - Up To 180 Days",
            "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
            "category": "Shipping",
            "points": 0,
            "rangeMin": 15,
            "rangeMax": 180,
            "rangeIncrementValue": 15,
            "rangeIncrementUnit": "Days",
            "pointsPerIncrement": 2,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "prerequisites": [
              "OFF-HHGBB-INS-39e2e8c9-10ad-4b83-b27b-6cef8488f65e",
              "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec"
            ]
          },
          {
            "reference": "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
            "productSpecificationReference": "PRD-LossOnSale",
            "displayName": "Loss on Sale",
            "description": "The Company realizes that real estate market conditions change over time, and that the sale price of your home may be less than what you paid for it. The calculation is based on the difference between your original purchase price and the outside offer (via BVO or the amended sale) or the GBO amount. The loss is covered at 80% up to USD 25,000. This is not tax assisted.",
            "category": "Before You Move",
            "points": 3
          }
        ],
        "selectedbenefits": [],
        "confirmedbenefits": [
          {
            "reference": "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Cost of Living Allowance ",
            "description": "In the event you are transferring to a location where the cost-of-living is significantly higher than it was in your old location. You may receive a cost of living allowance. Your Cartus consultant will provide additional information and the required criteria regarding this benefit.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus consultant will work with you to determine the allowance and to gather any documentation that may be required.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d110b0b0009db444e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
            "productSpecificationReference": "PRD-MiscAllwnc",
            "displayName": "Relocation Allowance",
            "description": "You will receive a miscellaneous expense allowance of USD 15,000 to cover expenses that are specific to your move not covered by your corporate policy or elected benefits. This will not be tax assisted.",
            "category": "Allowances",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will advise you when you are eligible to receive this allowance.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dbd79ad0009c42507",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
            "productSpecificationReference": "PRD-DscrdDnt",
            "displayName": "Household Goods - Pre-Move Discard & Donate",
            "description": "Prior to packing your household goods, a professional service will help to simplify your move. The organizer will assist you to identify household items that you no longer want or need at your destination home. Arrangements will be made to have those items donated or discarded as appropriate.",
            "category": "Shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "As you review items you do not want to keep, place them in a centralized area if there is space and you can move them easily.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25dd25d320008a52a8c",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
            "productSpecificationReference": "PRD-HHG",
            "displayName": "Household Goods - Professional Move / Cartus Insured",
            "description": "Cartus will select a professional moving company and will coordinate the shipment of your household goods and personal effects from your current primary residence to your new home.",
            "category": "Shipping",
            "points": 3,
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "High-value goods Insurance",
                  "description": "A list of items of high value and total amount identified for insurance purposes",
                  "highValueItemTypeList": [
                    {
                      "name": "Clothing/fur/accessories",
                      "group": "Category 3"
                    },
                    {
                      "name": "Luggage",
                      "group": "Category 3"
                    },
                    {
                      "name": "Small household item/appliance",
                      "group": "Category 3"
                    },
                    {
                      "name": "Camera equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Computers/related equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Tools/shop equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "Musical instrument",
                      "group": "Category 3"
                    },
                    {
                      "name": "Exercise equipment",
                      "group": "Category 3"
                    },
                    {
                      "name": "CDs/DVDs/Albums/Video Games",
                      "group": "Category 3"
                    },
                    {
                      "name": "Other/items of unusual value",
                      "group": "Category 3"
                    },
                    {
                      "name": "Bicycle",
                      "group": "Category 3"
                    },
                    {
                      "name": "Electronics (tv/stereo)",
                      "group": "Category 2"
                    },
                    {
                      "name": "Appliance",
                      "group": "Category 2"
                    },
                    {
                      "name": "Mattress set",
                      "group": "Category 2"
                    },
                    {
                      "name": "Art/painting/sculpture",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique",
                      "group": "Category 2"
                    },
                    {
                      "name": "Grandfather/specialty clock",
                      "group": "Category 2"
                    },
                    {
                      "name": "Carpet",
                      "group": "Category 2"
                    },
                    {
                      "name": "China/crystal/silver",
                      "group": "Category 2"
                    },
                    {
                      "name": "Antique furniture",
                      "group": "Category 1"
                    },
                    {
                      "name": "Motorcycle",
                      "group": "Category 1"
                    },
                    {
                      "name": "Jet ski",
                      "group": "Category 1"
                    },
                    {
                      "name": "Boat",
                      "group": "Category 1"
                    },
                    {
                      "name": "Furniture",
                      "group": "Category 1"
                    }
                  ],
                  "highValueItems": []
                }
              ],
              "allTaskComplete": false,
              "allHighValueTaskOptOut": false,
              "allHighValueTaskComplete": false
            },
            "productId": "6059b2f6faf8060008cec28d",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - 30 Days",
            "description": "If you need to move quickly prior to finding a home in your new location, you will be reimbursed for lodging and other covered expenses based on your corporate policy. You will be reimbursed for a car rental until your automobile is delivered.",
            "category": "After You Arrive",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus consultant will provide you with information on how to proceed with arrangements for a rental car is needed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ddf5d790008643c21",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
            "productSpecificationReference": "PRD-TmpHsng",
            "displayName": "Temporary Living Accommodations - Pre Move",
            "description": "If you are required to report to work at the new location prior to your family being able to move, Cartus will arrange for corporate housing (apartment). Corporate housing typically includes a kitchen with cookware, household items, parking, cable, internet, and laundry facilities. You may be reimbursed for transportation until your automobile is delivered.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Please contact your Cartus Consultant to discuss the needed dates for your temporary accommodations. Your Consultant will coordinate working with our supplier partner to secure temporary housing for you in your new location.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please contact your Cartus Consultant if your dates need to be changed.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25db631d70009eb62e8",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
            "productSpecificationReference": "PRD-PlcyCnsl",
            "displayName": "Cartus Counseling and Administration",
            "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will assist you throughout your relocation and provide just in time counseling for specific selected benefits as needed.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Your Cartus Consultant will coordinate the assignment of one of our trained supplier partners based on your benefit selections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d577f500008c913ca",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
            "productSpecificationReference": "PRD-ReloAcctng",
            "displayName": "Expense Management",
            "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "When CartusOnline is available, please set up your banking information.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "After discussing covered expenses with your Cartus Consultant, you will create your expense reports in CartusOnline for reimbursement.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25da3d6af00095306f1",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
            "productSpecificationReference": "PRD-FnlTrnsprt",
            "displayName": "Final Transportation to New Location",
            "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
            "category": "Travel and Transport",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Remember to make arrangements for your final move 30 days in advance for air travel and hotel accommodations at your old home location and your new location. You need to retain your receipts for submission with your expense report.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25d8bb7310009bbfe8e",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Buyer Value & Marketing Assistance",
            "description": "In coordination with your Cartus Network Broker, a buyer is secured. The offer to purchase is considered the \"Market Value\" of your home and sets the price at which Cartus will purchase the home from you. Cartus will pay the real estate commission including normal and customary seller's closing costs.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important:  The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. If needed, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25ffaf8060008cec284",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
            "productSpecificationReference": "PRD-HomeSale",
            "displayName": "Home Sale Guaranteed Buy Out & Marketing Assistance",
            "description": "This program provides assistance with selling your primary residence by working with a Cartus Network Broker.  Following the appraisal and inspection process, you will be offered a guaranteed buy out amount for your primary residence.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Important: The Cartus's world class  Broker Network will assist you in selling your home.  By clicking below, a Cartus representative will contact you, explain the broker selection process and help you select a broker.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                },
                {
                  "displayName": "Task 2",
                  "description": "Please fill out the Needs Analysis in CartusOnline.  This information is used to complete the title search on your home. Based on your responses, your consultant will schedule the appropriate inspections.",
                  "reference": "ATTR-BBTsks2",
                  "status": false
                },
                {
                  "displayName": "Task 3",
                  "description": "Your Consultant will provide you with information for the appraiser selection and discuss the appraisal process with you.  This activity will be available in CartusOnline.",
                  "reference": "ATTR-BBTsks3",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25fe2ce7a00083b9831",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
            "productSpecificationReference": "PRD-StrgInTrnst",
            "displayName": "Household Goods - Storage 1st 30 Days",
            "description": "When moving with a professional moving company, the first 30 days of storage will be included.",
            "category": "shipping",
            "points": "Guaranteed",
            "exclusions": [
              "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
              "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Cartus will coordinate the payment of the storage in transit up to the period elected. You will be responsible for any storage days beyond this period if you have not selected additional coverage as a benefit.",
                  "reference": "ATTR-BBTsks1",
                  "status": true
                }
              ],
              "allTaskComplete": true
            },
            "productId": "6059b25da1f96500083dda83",
            "confirmedDate": "2021-03-23"
          },
          {
            "reference": "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7",
            "productSpecificationReference": "PRD-HomeSaleIncntv",
            "displayName": "Home Sale Incentive ",
            "description": "If you sell your home through your Company's sponsored home sale program, you will receive an incentive. The incentive will be based on the net sale price and the time (days on the market) it took you to obtain an offer.",
            "category": "Before You Move",
            "points": "Guaranteed",
            "prerequisites": [
              "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935"
            ],
            "taskInfo": {
              "tasks": [
                {
                  "displayName": "Task 1",
                  "description": "Your Cartus Consultant will review your company's program with you.",
                  "reference": "ATTR-BBTsks1",
                  "status": false
                }
              ],
              "allTaskComplete": false
            },
            "productId": "6059b25defa96f00096febee",
            "confirmedDate": "2021-03-23"
          }
        ]
      },
      "taskInfoData": [
        {
          "displayName": "Task 1",
          "description": "Your Cartus Consultant will gather specific information regarding your current home including the number of furnished rooms as well as garages and storage.",
          "reference": "ATTR-BBTsks1",
          "status": false
        },
        {
          "displayName": "Task 2",
          "description": "You will be asked to declare items of high value and return the document. Your Consultant will provide further direction.",
          "reference": "ATTR-BBTsks2",
          "status": false
        }
      ],
      "jobStartDate": "2022-07-29",
      "moveStatusDate": "2021-12-01",
      "authorizationDate": "2021-12-01"
    }
  ],
  "count": 24,
  "costProjection":[
    {
      "identifier": "transferees",
      "favUser": 0,
      "orderRequestId": "60119c2b9663ef0008b13679",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Robert",
        "lastName": "Angel"
      },
      "fileNumber": 3185510,
      "fileStatusDesc": "Open",
      "clientEmployeeId": "018387",
      "movePhase": 0,
      "divisionNumber": "032",
      "divisionName": "People & Culture",
      "policyName": "Assignment Plan - Executive – 2017",
      "phoneDetailsList": [
        {
          "_id": "60119c235415a1000904620c",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60119c245415a1000904620d",
          "primary": true,
          "usageType": "business",
          "emailAddress": "nicholas.210127220138@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Mr.",
            "firstName": "Kevin",
            "lastName": "Angel"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed"
        }
      ],
      "moveStatus": "In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderRefNum": "MOV-waaw3qdz",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1d",
        "programName": "Executive Homeowner Program",
        "points": 120,
        "pointOverride": false,
        "originalPoints": 120
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "",
        "city": "Platteville",
        "state": "Colorado",
        "country": "United States",
        "postalCode": "79912"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "New Haven",
        "state": "Connecticut",
        "country": "United States",
        "postalCode": "90265"
      },
      "benefitsData": {
        "availableBenefits": [
          "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
          "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
          "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
          "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
          "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
          "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
          "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
          "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
          "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
          "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
          "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
          "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
          "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
          "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
          "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
          "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
          "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
          "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
          "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9"
        ],
        "selectedBenefits": [],
        "confirmedBenefits": [
          "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
          "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
          "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
          "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
          "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
          "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
          "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
          "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
          "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
          "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
          "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
          "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
          "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7"
        ]
      },
      "taskInfo": {
        "tasks": [
          "ATTR-BBTsks1",
          "ATTR-BBTsks2"
        ]
      },
      "effectiveTransferDate": "2020-10-01",
      "sentimentLevel": 0,
      "sentimentName": "Relocation Satisfaction",
      "moveRisk": 4,
      "milestoneDate": "2020-10-01",
      "milestoneDesc": "Transfer Date",
      "estimatedMoveDate": "",
      "estimatedMoveDateEarly": "",
      "estimatedMoveDateLate": "",
      "moveRiskCompletionDt": "2020-10-15",
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "sentimentTimelineData": [],
      "suppliers": [
        {
          "supplierName": "FORENOM APS",
          "incurredAmt": 3720.8199999999997,
          "incurredCurrCode": "USD",
          "apPaymentDocDate": "2020-10-06"
        }
      ],
      "TotalCost": {
        "totalNumofTransactions": 1,
        "totalCountOfAllTransactions": 6035.82,
        "numberOfRecentTransactions": 0,
        "transactionCurrCode": "USD",
        "totalRecentTransaction": 6035.82,
        "totalTaxAssistance": 0,
        "totalExpense": 6035.82,
        "results": [
          {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 6035.82,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 6035.82,
            "transactionCurrCode": "USD"
          }
        ]
      }
    },
    {
      "identifier": "transferees",
      "favUser": 1,
      "orderRequestId": "60124a5de1352200098cfef9",
      "nameDetails": {
        "title": "Dr.",
        "firstName": "Michael",
        "lastName": "Mathew"
      },
      "fileNumber": 4109141,
      "fileStatusDesc": "Open",
      "clientEmployeeId": "59336",
      "movePhase": 1,
      "divisionNumber": "033",
      "divisionName": "Research and Development",
      "policyName": "Global Test Policy",
      "phoneDetailsList": [
        {
          "_id": "60124a5799d733000915423a",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60124a5799d733000915423b",
          "primary": true,
          "usageType": "business",
          "emailAddress": "robert.210128100192@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Robert",
            "lastName": "Angel"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed"
        }
      ],
      "moveStatusDate": "2021-09-29",
      "moveStatus": "Approved",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderReference": "CP-20210924-1878",
      "relatesTo": "614dbef87f94a100096dcab1",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "type": "cost-projection",
      "currency": {
        "description": "US Dollar",
        "code": "USD"
      },
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1d",
        "programName": "High Value US Inbound Program",
        "points": 25,
        "pointOverride": false,
        "originalPoints": 25
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "333 Washington Avenue",
        "city": "Belleville",
        "state": "New Jersey",
        "country": "United States",
        "postalCode": "07109"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "benefitsData": {
        "availableBenefits": [
          "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
          "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
          "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
          "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
          "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
          "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
          "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
          "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
          "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
          "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
          "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
          "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
          "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
          "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
          "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
          "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
          "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
          "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
          "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
          "OFF-BsnCommEnglBBIntl20-21af8cdb-7e49-4749-a3ea-aaac91efec53",
          "OFF-BusnCommAdvBBIntl-28d35d1d-8c15-4d81-8db2-e8c0768d38b2",
          "OFF-BusnEnglWrtngBBIntl20-ed0d38d2-1db0-430a-b922-b21a47a089e2",
          "OFF-AccntModBBIntl-90e0bef4-18bc-4a2c-8e11-23b1f965edf6",
          "OFF-GlbLdrshpCommBBIntl-2af5419d-6f94-422c-bd98-f175f6e564ab",
          "OFF-PrsntSkillsBusnEnglBBIntl12-50fb95d3-1caa-4b0e-8c67-83a381523280",
          "OFF-CltrlTrnsSuppYouthBB-abe2b5a1-01c1-4a70-aa18-c73bddc9fd19",
          "OFF-PropMaintBBIntl-ac73445a-dc43-4d38-a384-947d93e4e82a"
        ],
        "selectedBenefits": [],
        "confirmedBenefits": [
          "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
          "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
          "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
          "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
          "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
          "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
          "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
          "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
          "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
          "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
          "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
          "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
          "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7"
        ]
      },
      "taskInfo": {
        "tasks": [
          "ATTR-BBTsks1",
          "ATTR-BBTsks2"
        ],
        "allTaskComplete": false
      },
      "effectiveTransferDate": "2020-11-01",
      "sentimentLevel": 5,
      "sentimentName": "Relocation Satisfaction",
      "moveRisk": 4,
      "milestoneDate": "",
      "milestoneDesc": "",
      "estimatedMoveDate": "",
      "estimatedMoveDateEarly": "",
      "estimatedMoveDateLate": "",
      "moveRiskCompletionDt": "2020-11-15",
      "executiveOfficer": "Yes",
      "authorizationType": "Traditional",
      "priority": "Normal",
      "confidential": false,
      "sentimentTimelineData": [
        {
          "sentimentName": "Policy Counseling Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Household Goods Packing Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Household Goods Delivery Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Relocation Satisfaction",
          "sentimentLevel": 5,
          "sentimentCompletedDate": "2020-01-28"
        }
      ],
      "suppliers": [],
      "TotalCost": {
        "totalNumofTransactions": 1,
        "totalCountOfAllTransactions": 2200,
        "numberOfRecentTransactions": 0,
        "transactionCurrCode": "USD",
        "totalRecentTransaction": 2200,
        "totalTaxAssistance": 0,
        "totalExpense": 2200,
        "results": [
          {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
          }
        ]
      }
    },
    {
      "identifier": "transferees",
      "favUser": 1,
      "orderRequestId": "60124a5de1352200098cfe10",
      "nameDetails": {
        "title": "Mr.",
        "firstName": "James",
        "lastName": "Paul"
      },
      "fileNumber": 4109142,
      "fileStatusDesc": "Open",
      "clientEmployeeId": "59336",
      "movePhase": 1,
      "divisionNumber": "033",
      "divisionName": "Research and Development",
      "policyName": "Global Test Policy",
      "phoneDetailsList": [
        {
          "_id": "60124a5799d733000915423a",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60124a5799d733000915423b",
          "primary": true,
          "usageType": "business",
          "emailAddress": "robert.210128100192@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Robert",
            "lastName": "Angel"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed"
        }
      ],
      "moveStatusDate": "2021-09-29",
      "moveStatus": "Not Approved",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderReference": "CP-20210924-1879",
      "relatesTo": "614dbef87f94a100096dcab1",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "type": "cost-projection",
      "currency": {
        "description": "US Dollar",
        "code": "USD"
      },
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1d",
        "programName": "High Value US Inbound Program",
        "points": 25,
        "pointOverride": false,
        "originalPoints": 25
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "333 Washington Avenue",
        "city": "Belleville",
        "state": "New Jersey",
        "country": "United States",
        "postalCode": "07109"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "benefitsData": {
        "availableBenefits": [
          "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
          "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
          "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
          "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
          "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
          "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
          "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
          "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
          "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
          "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
          "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
          "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
          "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
          "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
          "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
          "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
          "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
          "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
          "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
          "OFF-BsnCommEnglBBIntl20-21af8cdb-7e49-4749-a3ea-aaac91efec53",
          "OFF-BusnCommAdvBBIntl-28d35d1d-8c15-4d81-8db2-e8c0768d38b2",
          "OFF-BusnEnglWrtngBBIntl20-ed0d38d2-1db0-430a-b922-b21a47a089e2",
          "OFF-AccntModBBIntl-90e0bef4-18bc-4a2c-8e11-23b1f965edf6",
          "OFF-GlbLdrshpCommBBIntl-2af5419d-6f94-422c-bd98-f175f6e564ab",
          "OFF-PrsntSkillsBusnEnglBBIntl12-50fb95d3-1caa-4b0e-8c67-83a381523280",
          "OFF-CltrlTrnsSuppYouthBB-abe2b5a1-01c1-4a70-aa18-c73bddc9fd19",
          "OFF-PropMaintBBIntl-ac73445a-dc43-4d38-a384-947d93e4e82a"
        ],
        "selectedBenefits": [],
        "confirmedBenefits": [
          "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
          "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
          "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
          "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
          "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
          "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
          "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
          "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
          "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
          "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
          "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
          "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
          "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7"
        ]
      },
      "taskInfo": {
        "tasks": [
          "ATTR-BBTsks1",
          "ATTR-BBTsks2"
        ],
        "allTaskComplete": false
      },
      "effectiveTransferDate": "2020-11-01",
      "sentimentLevel": 5,
      "sentimentName": "Relocation Satisfaction",
      "moveRisk": 4,
      "milestoneDate": "",
      "milestoneDesc": "",
      "estimatedMoveDate": "",
      "estimatedMoveDateEarly": "",
      "estimatedMoveDateLate": "",
      "moveRiskCompletionDt": "2020-11-15",
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "priority": "Normal",
      "confidential": false,
      "sentimentTimelineData": [
        {
          "sentimentName": "Policy Counseling Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Household Goods Packing Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Household Goods Delivery Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Relocation Satisfaction",
          "sentimentLevel": 5,
          "sentimentCompletedDate": "2020-01-28"
        }
      ],
      "suppliers": [],
      "TotalCost": {
        "totalNumofTransactions": 1,
        "totalCountOfAllTransactions": 2200,
        "numberOfRecentTransactions": 0,
        "transactionCurrCode": "USD",
        "totalRecentTransaction": 2200,
        "totalTaxAssistance": 0,
        "totalExpense": 2200,
        "results": [
          {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
          }
        ]
      }
    },
    {
      "identifier": "transferees",
      "favUser": 1,
      "orderRequestId": "60124a5de1352200098cfe11",
      "nameDetails": {
        "title": "Ms.",
        "firstName": "Anna",
        "lastName": "Paul"
      },
      "fileNumber": 4109143,
      "fileStatusDesc": "Open",
      "clientEmployeeId": "59336",
      "movePhase": 1,
      "divisionNumber": "033",
      "divisionName": "Research and Development",
      "policyName": "Global Test Policy",
      "phoneDetailsList": [
        {
          "_id": "60124a5799d733000915423a",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60124a5799d733000915423b",
          "primary": true,
          "usageType": "business",
          "emailAddress": "robert.210128100192@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Robert",
            "lastName": "Angel"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed"
        }
      ],
      "moveStatusDate": "2021-09-29",
      "moveStatus": "Not Requested",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderReference": "CP-20210924-1880",
      "relatesTo": "614dbef87f94a100096dcab1",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "type": "cost-projection",
      "currency": {
        "description": "US Dollar",
        "code": "USD"
      },
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1d",
        "programName": "High Value US Inbound Program",
        "points": 25,
        "pointOverride": false,
        "originalPoints": 25
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "333 Washington Avenue",
        "city": "Belleville",
        "state": "New Jersey",
        "country": "United States",
        "postalCode": "07109"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "benefitsData": {
        "availableBenefits": [
          "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
          "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
          "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
          "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
          "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
          "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
          "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
          "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
          "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
          "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
          "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
          "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
          "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
          "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
          "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
          "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
          "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
          "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
          "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
          "OFF-BsnCommEnglBBIntl20-21af8cdb-7e49-4749-a3ea-aaac91efec53",
          "OFF-BusnCommAdvBBIntl-28d35d1d-8c15-4d81-8db2-e8c0768d38b2",
          "OFF-BusnEnglWrtngBBIntl20-ed0d38d2-1db0-430a-b922-b21a47a089e2",
          "OFF-AccntModBBIntl-90e0bef4-18bc-4a2c-8e11-23b1f965edf6",
          "OFF-GlbLdrshpCommBBIntl-2af5419d-6f94-422c-bd98-f175f6e564ab",
          "OFF-PrsntSkillsBusnEnglBBIntl12-50fb95d3-1caa-4b0e-8c67-83a381523280",
          "OFF-CltrlTrnsSuppYouthBB-abe2b5a1-01c1-4a70-aa18-c73bddc9fd19",
          "OFF-PropMaintBBIntl-ac73445a-dc43-4d38-a384-947d93e4e82a"
        ],
        "selectedBenefits": [],
        "confirmedBenefits": [
          "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
          "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
          "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
          "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
          "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
          "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
          "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
          "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
          "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
          "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
          "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
          "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
          "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7"
        ]
      },
      "taskInfo": {
        "tasks": [
          "ATTR-BBTsks1",
          "ATTR-BBTsks2"
        ],
        "allTaskComplete": false
      },
      "effectiveTransferDate": "2020-11-01",
      "sentimentLevel": 5,
      "sentimentName": "Relocation Satisfaction",
      "moveRisk": 4,
      "milestoneDate": "",
      "milestoneDesc": "",
      "estimatedMoveDate": "",
      "estimatedMoveDateEarly": "",
      "estimatedMoveDateLate": "",
      "moveRiskCompletionDt": "2020-11-15",
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "priority": "Normal",
      "confidential": false,
      "sentimentTimelineData": [
        {
          "sentimentName": "Policy Counseling Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Household Goods Packing Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Household Goods Delivery Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Relocation Satisfaction",
          "sentimentLevel": 5,
          "sentimentCompletedDate": "2020-01-28"
        }
      ],
      "suppliers": [],
      "TotalCost": {
        "totalNumofTransactions": 1,
        "totalCountOfAllTransactions": 2200,
        "numberOfRecentTransactions": 0,
        "transactionCurrCode": "USD",
        "totalRecentTransaction": 2200,
        "totalTaxAssistance": 0,
        "totalExpense": 2200,
        "results": [
          {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
          }
        ]
      }
    },
    {
      "identifier": "transferees",
      "favUser": 1,
      "orderRequestId": "60124a5de1352200098cfe12",
      "nameDetails": {
        "title": "Mr.",
        "firstName": "Brian",
        "lastName": "Wood"
      },
      "fileNumber": 4109144,
      "fileStatusDesc": "Open",
      "clientEmployeeId": "59336",
      "movePhase": 1,
      "divisionNumber": "033",
      "divisionName": "Research and Development",
      "policyName": "Global Test Policy",
      "phoneDetailsList": [
        {
          "_id": "60124a5799d733000915423a",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60124a5799d733000915423b",
          "primary": true,
          "usageType": "business",
          "emailAddress": "robert.210128100192@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Robert",
            "lastName": "Angel"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed"
        }
      ],
      "moveStatusDate": "2021-09-29",
      "moveStatus": "CP In Progress",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderReference": "CP-20210924-1881",
      "relatesTo": "614dbef87f94a100096dcab1",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "type": "cost-projection",
      "currency": {
        "description": "US Dollar",
        "code": "USD"
      },
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1d",
        "programName": "High Value US Inbound Program",
        "points": 25,
        "pointOverride": false,
        "originalPoints": 25
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "333 Washington Avenue",
        "city": "Belleville",
        "state": "New Jersey",
        "country": "United States",
        "postalCode": "07109"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "benefitsData": {
        "availableBenefits": [
          "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
          "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
          "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
          "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
          "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
          "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
          "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
          "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
          "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
          "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
          "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
          "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
          "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
          "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
          "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
          "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
          "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
          "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
          "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
          "OFF-BsnCommEnglBBIntl20-21af8cdb-7e49-4749-a3ea-aaac91efec53",
          "OFF-BusnCommAdvBBIntl-28d35d1d-8c15-4d81-8db2-e8c0768d38b2",
          "OFF-BusnEnglWrtngBBIntl20-ed0d38d2-1db0-430a-b922-b21a47a089e2",
          "OFF-AccntModBBIntl-90e0bef4-18bc-4a2c-8e11-23b1f965edf6",
          "OFF-GlbLdrshpCommBBIntl-2af5419d-6f94-422c-bd98-f175f6e564ab",
          "OFF-PrsntSkillsBusnEnglBBIntl12-50fb95d3-1caa-4b0e-8c67-83a381523280",
          "OFF-CltrlTrnsSuppYouthBB-abe2b5a1-01c1-4a70-aa18-c73bddc9fd19",
          "OFF-PropMaintBBIntl-ac73445a-dc43-4d38-a384-947d93e4e82a"
        ],
        "selectedBenefits": [],
        "confirmedBenefits": [
          "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
          "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
          "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
          "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
          "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
          "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
          "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
          "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
          "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
          "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
          "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
          "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
          "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7"
        ]
      },
      "taskInfo": {
        "tasks": [
          "ATTR-BBTsks1",
          "ATTR-BBTsks2"
        ],
        "allTaskComplete": false
      },
      "effectiveTransferDate": "2020-11-01",
      "sentimentLevel": 5,
      "sentimentName": "Relocation Satisfaction",
      "moveRisk": 4,
      "milestoneDate": "",
      "milestoneDesc": "",
      "estimatedMoveDate": "",
      "estimatedMoveDateEarly": "",
      "estimatedMoveDateLate": "",
      "moveRiskCompletionDt": "2020-11-15",
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "priority": "Normal",
      "confidential": false,
      "sentimentTimelineData": [
        {
          "sentimentName": "Policy Counseling Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Household Goods Packing Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Household Goods Delivery Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Relocation Satisfaction",
          "sentimentLevel": 5,
          "sentimentCompletedDate": "2020-01-28"
        }
      ],
      "suppliers": [],
      "TotalCost": {
        "totalNumofTransactions": 1,
        "totalCountOfAllTransactions": 2200,
        "numberOfRecentTransactions": 0,
        "transactionCurrCode": "USD",
        "totalRecentTransaction": 2200,
        "totalTaxAssistance": 0,
        "totalExpense": 2200,
        "results": [
          {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
          }
        ]
      }
    },
    {
      "identifier": "transferees",
      "favUser": 1,
      "orderRequestId": "60124a5de1352200098cfe13",
      "nameDetails": {
        "title": "Mr.",
        "firstName": "Larry",
        "lastName": "Page"
      },
      "fileNumber": 4109145,
      "fileStatusDesc": "Open",
      "clientEmployeeId": "59336",
      "movePhase": 1,
      "divisionNumber": "033",
      "divisionName": "Research and Development",
      "policyName": "Global Test Policy",
      "phoneDetailsList": [
        {
          "_id": "60124a5799d733000915423a",
          "textingAvailable": true,
          "primary": true,
          "usageType": "personal",
          "phoneDialCode": 93,
          "phoneNumber": "587855845"
        }
      ],
      "emailDetailsList": [
        {
          "_id": "60124a5799d733000915423b",
          "primary": true,
          "usageType": "business",
          "emailAddress": "robert.210128100192@test.com"
        }
      ],
      "familydata": [
        {
          "nameDetails": {
            "title": "Dr.",
            "firstName": "Robert",
            "lastName": "Angel"
          },
          "phoneDetailsList": [
            {
              "_id": "6026743ba045140008783a4b",
              "textingAvailable": false,
              "primary": true,
              "usageType": "business",
              "phoneDialCode": 91,
              "phoneNumber": "9238733832377",
              "locationType": "departure"
            }
          ]
        }
      ],
      "taskData": {
        "policyCall": {
          "consultantDetails": {
            "firstName": "Annabella",
            "lastName": "Costa",
            "emailDetailsList": [
              {
                "emailAddress": "Annabella.Costa@cartus.com"
              }
            ],
            "phoneDetailsList": [
              {
                "phoneNumber": "2032051387",
                "phoneDialingCode": "1"
              }
            ],
            "timeZone": "America/New_York"
          },
          "status": "not started",
          "policyCallRequired": false,
          "cronofyId": "acc_5eb268e4a9c09000601dbe5d",
          "custFileId": 878821
        }
      },
      "timeLines": [
        {
          "description": "This date is the day your company told us you begin working.",
          "source": "Job Start Date"
        },
        {
          "description": "This is the date of your policy call with your consultant.",
          "source": "Schedule Policy Call"
        },
        {
          "description": "This is the date on which your policy call with your consultant was completed.",
          "source": "Policy Call Completed"
        }
      ],
      "moveStatusDate": "2021-09-29",
      "moveStatus": "Requested",
      "invitationSentDate": "2020-10-23",
      "clientEntityName": "ABC Demo Company Ltd",
      "clientId": "3966",
      "programName": "High Value US Inbound Program",
      "orderReference": "CP-20210924-1882",
      "relatesTo": "614dbef87f94a100096dcab1",
      "confirmStatus": true,
      "spousePartnerRelocating": true,
      "childrenDependentsRelocating": false,
      "totalNumberOfRelocatingMembers": 2,
      "moveType": "Domestic",
      "type": "cost-projection",
      "currency": {
        "description": "US Dollar",
        "code": "USD"
      },
      "optionalDetails": {
        "costCenter": "",
        "poBillingInvoice": "",
        "employeeID": ""
      },
      "programDetails": {
        "divisionPartyId": "5e1f40cfa80a79393da72c1d",
        "programName": "High Value US Inbound Program",
        "points": 25,
        "pointOverride": false,
        "originalPoints": 25
      },
      "authorizationDetails": {
        "baseContract": 505,
        "cciPartyId": "5e5ee8f5c78aa40008670d36",
        "currency": {
          "description": "US Dollar",
          "code": "USD"
        }
      },
      "departureAddr": {
        "streetLine1": "333 Washington Avenue",
        "city": "Belleville",
        "state": "New Jersey",
        "country": "United States",
        "postalCode": "07109"
      },
      "destinationAddr": {
        "streetLine1": "23359, California 1, Eastern Malibu",
        "city": "Malibu",
        "state": "California",
        "country": "United States",
        "postalCode": "90265"
      },
      "benefitsData": {
        "availableBenefits": [
          "OFF-HmPrchReimbBB-9c6d35f5-e801-48e3-965a-dcc3d71b8730",
          "OFF-CashOutBBAdv-f55b7367-cf4a-40d7-a91d-5794bdb894c9",
          "OFF-HmFndng65BB-26f7870f-1ea1-409c-93f9-33f27830a73f",
          "OFF-BrkrFndrFeeBB-843c8e20-af73-481f-9f3b-1490b6220431",
          "OFF-SlfMvBB-4e1ab3ad-c45a-43b8-9a62-b14ea5650647",
          "OFF-RentalSvcsFullBB-a09fe113-b454-4888-8b8e-f98533e79177",
          "OFF-RentalSvcsHalfBB-a81f548f-402f-464e-8d97-fe4d3861d896",
          "OFF-ReturnTrpBndl3BB-bcb00203-6c67-4e71-8ca0-4e9525b43481",
          "OFF-TmpHsng120BB-a7911e11-4f52-43a7-a6a2-72dba96d74dd",
          "OFF-HmSlReimbBB-f6c6a665-1cd4-407d-9cc1-a32057a18607",
          "OFF-DupHsngBndl3BB-3c727ada-5baa-49d6-a178-a5502c7cd397",
          "OFF-SlfStrgBB-9f20544e-6fb1-43d6-98ec-8d610c374098",
          "OFF-FrnRntlBB-6a349302-f138-46f7-b68a-205e514cfce3",
          "OFF-CarRntlBB-2426375c-af80-43b6-a41e-3cfa0671afa4",
          "OFF-VhclShpmtBndl3BB-6e1350fa-a55b-4373-b94f-5a356822ae39",
          "OFF-SpouseCareerSuppBB-68f6317b-2a41-485d-989c-ade0adb260ba",
          "OFF-PetTrnsprtBndlBB-3028276a-e210-4487-ad37-af41a317d86e",
          "OFF-StrgInTrnst180BB-dcc236f2-a1bc-4675-9de9-dd3f4cff1b16",
          "OFF-LossOnSaleBB-c51f939e-41ea-4aa3-9cb6-4cfa012d48f9",
          "OFF-BsnCommEnglBBIntl20-21af8cdb-7e49-4749-a3ea-aaac91efec53",
          "OFF-BusnCommAdvBBIntl-28d35d1d-8c15-4d81-8db2-e8c0768d38b2",
          "OFF-BusnEnglWrtngBBIntl20-ed0d38d2-1db0-430a-b922-b21a47a089e2",
          "OFF-AccntModBBIntl-90e0bef4-18bc-4a2c-8e11-23b1f965edf6",
          "OFF-GlbLdrshpCommBBIntl-2af5419d-6f94-422c-bd98-f175f6e564ab",
          "OFF-PrsntSkillsBusnEnglBBIntl12-50fb95d3-1caa-4b0e-8c67-83a381523280",
          "OFF-CltrlTrnsSuppYouthBB-abe2b5a1-01c1-4a70-aa18-c73bddc9fd19",
          "OFF-PropMaintBBIntl-ac73445a-dc43-4d38-a384-947d93e4e82a"
        ],
        "selectedBenefits": [],
        "confirmedBenefits": [
          "OFF-CostOfLvngBB-7c716155-06e0-436a-bb91-d49f09d54a5b",
          "OFF-MiscAllwnc15BB-32db97e2-6e50-4339-a90d-3164c629d684",
          "OFF-DsrdDntCrBB-dbd78587-5dc5-44e4-9d49-ecf6b9cd53ea",
          "OFF-HHGFlxBB-INS-cc221215-abc6-439b-b5c2-dc1a1d1e22ec",
          "OFF-TmpHsngBB-0f354e4d-2585-4077-9ecf-890cdfcb8f92",
          "OFF-CorpHsngBB-2926fb26-5b1e-46ab-b209-d06573f94c9f",
          "OFF-PlcyCnslBB-46d99291-2989-4079-867f-e562cbfac9fe",
          "OFF-ReloAcctngIntlBB-df1f4909-b16a-47f5-b667-fa3ae6cb76b7",
          "OFF-FnlTrnsprtBB-c6d468b6-6995-4f3c-9cfd-13a6721960bd",
          "OFF-HomeSaleBVMABB-c3db4b16-a887-4552-887e-4e6c27071935",
          "OFF-HomeSaleGBOMABB-7690e477-8683-4d66-9617-e4d450a8c6cf",
          "OFF-StrgInTrnstBB-fea4fc35-3995-4886-b0e7-ba884f82011e",
          "OFF-HomeSaleIncntvBB-32773c4b-c7ab-4cf0-9a3c-818c56c7cfd7"
        ]
      },
      "taskInfo": {
        "tasks": [
          "ATTR-BBTsks1",
          "ATTR-BBTsks2"
        ],
        "allTaskComplete": false
      },
      "effectiveTransferDate": "2020-11-01",
      "sentimentLevel": 5,
      "sentimentName": "Relocation Satisfaction",
      "moveRisk": 4,
      "milestoneDate": "",
      "milestoneDesc": "",
      "estimatedMoveDate": "",
      "estimatedMoveDateEarly": "",
      "estimatedMoveDateLate": "",
      "moveRiskCompletionDt": "2020-11-15",
      "executiveOfficer": "Yes",
      "authorizationType": "BenefitsBuilder",
      "priority": "Normal",
      "confidential": false,
      "sentimentTimelineData": [
        {
          "sentimentName": "Policy Counseling Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Household Goods Packing Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Household Goods Delivery Satisfaction",
          "sentimentLevel": 4,
          "sentimentCompletedDate": "2020-01-28"
        },
        {
          "sentimentName": "Relocation Satisfaction",
          "sentimentLevel": 5,
          "sentimentCompletedDate": "2020-01-28"
        }
      ],
      "suppliers": [],
      "TotalCost": {
        "totalNumofTransactions": 1,
        "totalCountOfAllTransactions": 2200,
        "numberOfRecentTransactions": 0,
        "transactionCurrCode": "USD",
        "totalRecentTransaction": 2200,
        "totalTaxAssistance": 0,
        "totalExpense": 2200,
        "results": [
          {
            "accountCategory": "Cartus Fees",
            "accountSubCategory": "Intl Expense Processing Fee - EMEA",
            "transactionDesc": "Intl Lump Sum Employees (1 Disbursement) - Service",
            "invoiceDate": "2020-10-19",
            "billAmount": 2200,
            "billCurrency": "USD",
            "invoiceNo": "1009564512",
            "transactionAmount": 3180285,
            "transactionCurrCode": "USD"
          }
        ]
      }
    }
  ],
  "cpCount": 5

}