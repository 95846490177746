<div class="selected-benefits" fxLayout="column" role="none">
    <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100%"
        class="back-btn-wrapper" role="none">
        <div class="header-left" role="none">
            <button class="back-btn" (click)="goToList()" role="button" aria-label="back to benefit lists">
                <img class="backspace-icon" title="back arrow" alt="back-arrow" src="../../../assets/images/back-arrow.svg" />
            </button>
            <span class="title-style" fxFlex="100%" role="heading" aria-level="1" aria-label="Confirm Selected Benefits">Confirm Selected Benefits</span>
        </div>
        <div class="header-right" fxLayout="row" role="none">
            
            <button class="download" aria-label="Download Report" (click)="openDownloadToastr()" title="Download Report" tabindex="0">
                <img class="download-icon" 
                    src="../../../assets/images/material-file-download.svg" alt="download">
                    <span class="download-text" role="none">Download</span>
            </button> 
            <button mat-menu-item aria-label="confirm" class="btn confirm-btn" [disabled]="isAllBenefitsConfirmed"
                fxLayoutAlign="start center" (click)="confirmSelectedBenefits();">Confirm</button>
        </div>

    </div>

    <div fxLayout="row" fxLayoutAlign="space-between unset" fxLayout.xs="column" fxLayout.sm="column" role="none">
        <div fxFlex="32.8%" fxFlex.md="43%" class="left-content" fxLayout="column" fxHide.xs fxHide.sm role="none">
            <div class="dialer-wrapper" role="none">
                <app-dialer></app-dialer>
            </div>
            <!-- <div class="fav-benefits" role="group">
                <div class="fav-selected-benefits" fxLayout="row" fxLayoutAlign="unset center" role="heading">
                    <h1>Favorite Benefits</h1>
                    <span class="selected-badge" role="row">0</span>
                </div>
                <div class="no-fav-benefit" fxLayout="column" fxLayoutAlign="center center" role="row">
                    <img src="../../../assets/images/Group_2214.svg" alt="There is no favourite benefit." />
                    <p class="no-favou"> There is no favourite benefit.</p>
                </div>

            </div> -->
        </div>
        <div  fxHide=true fxHide.xs=false fxHide.sm=false class="dialer-wrapper" role="none">
            <app-dialer></app-dialer>
        </div>
        <div fxFlex="62.3%" fxFlex.md="50%" fxFlex.xs="100%" fxFlex.sm="100%" class="right-content" fxLayout="column" role="group">
            <div class="selected-benefit-text" role="heading">
                <h2  role="heading" aria-level="2" class="selected-text">My Selected Benefits</h2><span
                    class="selected-badge">{{selectedBenefits.length}}</span>
                    <span *ngIf="selectedPoints >= 0 && selectedBenefits.length > 0" class="selected-points"> - {{selectedPoints}} Point(s)</span>
            </div>
            <div class="selected-cards-main" fxLayout="column" role="none">
                <ng-container *ngIf="isLoaded else loadingSelectedBenefits">
                <app-selected-benefits *ngFor="let benefit of selectedBenefits; let i= index;" (benefitSelectDeleteAction) = benefitSelectDeleteAction($event); [benefit]="benefit">
                </app-selected-benefits>
            </ng-container>
                <!-- <app-selected-benefits></app-selected-benefits>
                <app-selected-benefits></app-selected-benefits> -->
            </div>

            <!-- <div class="fav-benefits-bottom" role="none">
                <div class="more-benefits" role="none"></div>
                <div class="more-fev-benefits" role="group">
                    <div class="fav-selected-benefits" fxLayout="row" fxLayoutAlign="unset center" role="heading">
                        <h1 tabindex="0">Favorite Benefits</h1>
                        <span class="selected-badge" role="none">0</span>
                    </div>
                    <div fxLayout="column" fxLayoutAlign="center center" role="note">
                        <img src="../../../assets/images/Group_2214.svg" alt="There is no favourite benefit." />
                        <p tabindex="0" class="no-favou"> There is no favourite benefit.</p>
                    </div>

                </div>

            </div> -->

            <!-- footer start -->
            <div fxLayout fxLayout.xs="column-reverse" fxLayout.sm="column-reverse" fxLayoutAlign="space-between center"
                fxLayoutAlign.sm="center center" fxLayoutAlign.xs="center center" class="footer-wrapper">
                <div fxFlex="30%" class="copyright" role="none">
                    <p >© 2020 Cartus Corporation</p>
                </div>
                <div  role="none">
                    <!-- <span class="terms" role="none">
                        Terms of Use
                    </span>
                    <span class="privacy" role="none">
                        Privacy Statement
                    </span>
                    <span class="contact" role="none">
                        Contact Us
                    </span> -->
                </div>
            </div>
            <!-- footer end -->

        </div>


    </div>

    <!-- Mobile View Actions -->
    <div class="fixed-bottom" fxLayout="row" role="group">
        <button class="download">
            <img class="download-icon" src="../../../assets/images/material-file-download.svg" title="Download"alt="download">
            <span class="download-text" role="none" (click)="openDownloadToastr()">Download</span>
        </button>
        <button 
            mat-menu-item aria-label="confirm" 
            class="btn confirm-btn" 
            fxLayoutAlign="start center" 
            [disabled]="pendingPolicyCall || isConsultant || isAllBenefitsConfirmed"
            (click)="confirmSelectedBenefits();">Confirm</button>
    </div>




</div>

<ng-template #loadingSelectedBenefits>    
    <div *ngFor="let item of [1,2,3]" 
        style="height: 160px; width: 100%;margin-top:16px" class="skeleton-box-dark-rounded"></div>
</ng-template>
