import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { EmptyError, Observable } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { map, startWith } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { Country } from '../../../../core/models/country.model';
import { LocationsService } from '../../services/locations.service';
import { MatTableDataSource, MatMenuTrigger } from '@angular/material';
import { CandidateProfileService} from './../../services/candidate-profile.service';
import { AddEditTransfereeComponent } from '../add-edit-transferee/add-edit-transferee.component';
import { DupCheckPopupComponent } from '../dup-check-popup/dup-check-popup.component';
import { ClientContactsService } from '../../services/client-contacts.service';
import { UserTypeService } from '../../services/user-type.service';


@Component({
  selector: 'app-search-duplicate-check',
  templateUrl: './search-duplicate-check.component.html',
  styleUrls: ['./search-duplicate-check.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchDuplicateCheckComponent implements OnInit {

  @ViewChild(MatMenuTrigger, {
    static: false
  }) menuFor: MatMenuTrigger;
  displayedColumns: string[] = ['fullName', 'email', 'departureAddr', 'destinationAddr', 'add'];
  dataSource = new MatTableDataSource();
  readonly formControl: AbstractControl;
  /**Countries retrieved from service */
  countryList: Array<Country>;
  /** List of filtered options by autocomplete phone code field */
  filteredOptions: Observable<Country[]>;
  formDisable = false;
  isEditable = false;
  titleValues: string[] = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Miss'];
  addCusForm: FormGroup;
  formTitle: string;
  fname: string;
  lname: string;
  ename: string;
  searchValue: string;
  searchfname: string;
  searchlname: string;
  searchEmail: string;
  searchEmp: string;
  PhoneCode: number;
  PhoneNumber: number;
  title: string;
  checkConfirm: Array<boolean> = [];

  constructor(
    private readonly locationService: LocationsService,
    public dialogRef: MatDialogRef<SearchDuplicateCheckComponent>,
    private readonly formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private readonly toastrService: ToastrService,
    public live: LiveAnnouncer,
    private datePipe: DatePipe,
    private candidateProfileService: CandidateProfileService,
    private readonly clientContactsService: ClientContactsService,
    private userTypeService: UserTypeService,
  ) { }

  ngOnInit() {
    this.formTitle = this.data.isCostProjection? 'Add Cost Projection': 'Add Transferee';
    this.addCusForm = this.formBuilder.group({
      firstName: [this.fname, [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]],
      lastName: [this.lname, [Validators.required, Validators.pattern('[a-zA-Z]+([a-zA-Z ]+)*')]],
      EmployeeName: [this.ename],
      email: [null, [Validators.required, Validators.email, Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]],
      title: [null, [Validators.required]],
      phoneDialingCode: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      phoneNumber: [null, [Validators.required, Validators.maxLength(18), Validators.minLength(7),
      Validators.pattern('^[0-9]*$'), Validators.max(999999999999999999)]],
    });
    this.locationService.countryList.subscribe(countryList => {
      this.filteredOptions = this.addCusForm.get('phoneDialingCode').valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryList = countryList;
      }
    });

  }
  private _filter(value): Country[] {
    if (value) {
      const filterValue = typeof value === 'number' ? value : value.toLowerCase();
      const countryList = this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
      return countryList;
    } else {
      return this.countryList;
    }
  }
  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close(null)
  }

  onDismissTable() {
    this.isEditable = false;
    this.addCusForm.enable();
  }



  public onEditClick() {
    this.dataSource.data = [];
    this.isEditable = true;
    this.addCusForm.disable();
    let reqObj = {
      name: {
        title: this.addCusForm.get('title').value, 
        firstName: this.addCusForm.get('firstName').value, 
        lastName: this.addCusForm.get('lastName').value
      }, 
      contact: {
        phoneDialingCode: '+' + this.addCusForm.get('phoneDialingCode').value, 
        phoneNumber: this.addCusForm.get('phoneNumber').value, 
        email: this.addCusForm.get('email').value
      }, 
      employeeId : this.addCusForm.get('EmployeeName').value,
      clientId: undefined
    }
    //console.log(reqObj);
    this.userTypeService.userType === 'file-setup-specialist' ?
    this.clientContactsService.clientId.subscribe(r => reqObj.clientId = r) : null;
    reqObj.employeeId == null || reqObj.employeeId == "" ? delete reqObj.employeeId: null;
    //this.dataSource.data = checkCandidateResult;
    this.candidateProfileService.getCandidateCheck(reqObj).subscribe(data => {
      this.dataSource.data = data;
     // this.dataSource.data = checkCandidateResult;
      this.dataSource.data.forEach((i, index) => {
        this.checkConfirm[index] = false;
      });
    });
  }
  openDialog(isTraditional) {
    this.userTypeService.userType === 'file-setup-specialist'? isTraditional = false: null;
    this.dialogRef.close();
    const preData = {
      nameDetails: {
        title: this.addCusForm.get('title').value, 
        firstName: this.addCusForm.get('firstName').value, 
        lastName: this.addCusForm.get('lastName').value
      }, 
      phoneDetailsList:[{
        phoneDialCode: this.addCusForm.get('phoneDialingCode').value, 
        phoneNumber: this.addCusForm.get('phoneNumber').value,
        type: 'Mobile',
        textingAvailable: true,  
      }], 
      emailDetailsList: [{
        emailAddress: this.addCusForm.get('email').value,
        usageType: "Business",
      }],
      employeeId: this.addCusForm.get('EmployeeName').value
    }
    const dialogRef = this.dialog.open(AddEditTransfereeComponent, {
      data: {
              isTraditional: isTraditional,
              associateTransferee: true,
              isCostProjection: this.data.isCostProjection,
              candidate: preData
            },
      panelClass: 'dialog-full-width',
      id: 'addEditDialog'
    });
  }

  displayFn(country: any): string {
    return country ? '+ ' + country : '';
  }

  openDialogAdd(isTraditional, transfereeData) {
    const candidatePartyId = transfereeData._id;
    delete transfereeData.empId;
    delete transfereeData._id;
    delete transfereeData.departureAddr;
    delete transfereeData.destinationAddr;
    transfereeData.nameDetails = transfereeData.name;
    delete transfereeData.name;
    transfereeData.nameDetails.title = this.addCusForm.get('title').value;
    transfereeData.nameDetails.firstName = this.addCusForm.get('firstName').value;
    transfereeData.nameDetails.lastName = this.addCusForm.get('lastName').value;
    transfereeData.phoneDetailsList[0].phoneDialCode = this.addCusForm.get('phoneDialingCode').value;
    transfereeData.phoneDetailsList[0].phoneNumber = this.addCusForm.get('phoneNumber').value;
    transfereeData.emailDetailsList[0].emailAddress = this.addCusForm.get('email').value;
    this.dialogRef.close();
    const dialogRef = this.dialog.open(AddEditTransfereeComponent, {
      data: {
              isTraditional: isTraditional, 
              associateTransferee: true,
              candidate: transfereeData,
              isCostProjection: this.data.isCostProjection,
              candidatePartyId: candidatePartyId
            },
      panelClass: 'dialog-full-width',
      id: 'addEditDialog'
    });
  }
  
  openDupcheck(i, list, transfereeData){
    const dialogRef = this.dialog.open(DupCheckPopupComponent, {
      data: list,
      panelClass: 'dialog-full-width',
    });
    dialogRef.afterClosed().subscribe(res => {
       if(res.data){
        this.checkConfirm[i] = true;
        !this.data.bbRole ?this.openDialogAdd(true, transfereeData): null;
      } 
    });
  }

  toCheckData(i,transfereeData){
    const res = this.checkFieldList(transfereeData);
    if(res.length) {
      this.openDupcheck(i, res, transfereeData);
    } else {
       !this.data.bbRole ?this.openDialogAdd(true, transfereeData): null;  
    }
  }

  showButton(i, transfereeData) {
    const res = this.checkFieldList(transfereeData);
    if(res.length) {
      return true;
    } else {
      return false;
    }
  }

  
  checkFieldList(transfereeData) {
    var fieldsList = []
    if (transfereeData.title != this.addCusForm.get('title').value) {
      fieldsList.push('Title');
    }
    if (transfereeData.name) {
      if (transfereeData.name[0].toLowerCase() != this.addCusForm.get('firstName').value.toLowerCase()) {
        fieldsList.push('First Name');
      }
      if (transfereeData.name[1].toLowerCase() != this.addCusForm.get('lastName').value.toLowerCase()) {
        fieldsList.push('Last Name');
      }
    }
    if (transfereeData.phoneDetailsList[0].phoneDialCode != this.addCusForm.get('phoneDialingCode').value) {
      fieldsList.push('Phone Dialing Code');
    }
    if (transfereeData.phoneDetailsList[0].phoneNumber != this.addCusForm.get('phoneNumber').value) {
      fieldsList.push('Phone Number');
    }
    if (transfereeData.emailDetailsList[0].emailAddress.toLowerCase() != this.addCusForm.get('email').value.toLowerCase()) {
      fieldsList.push('Email');
    }
    return fieldsList;
  }

  findIndexOfEmail(element) {
    return element.emailDetailsList.findIndex(email => email.usageType === 'business');
  }

  findIndexOfPhone(element) { 
    return element.phoneDetailsList.findIndex(phone => phone.textingAvailable && phone.primary);
  }

}

export interface PeriodicElement {
  fullName: FullName;
  email: string;
  departureAddr: DepartureAddrOrDestinationAddr;
  destinationAddr: DepartureAddrOrDestinationAddr;
}
export interface FullName {
  firstName: string;
  lastName: string;
  emp: string;
}
export interface DepartureAddrOrDestinationAddr {
  city: string;
  state: string;
  country: string;
}
