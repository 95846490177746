
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ModuleWithProviders } from '@angular/core';
import {
  HashLocationStrategy,
  LocationStrategy,
  DatePipe
} from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatTableModule,
  MatPaginatorModule,
  MatTabsModule,
  MatSortModule,
  MatTooltipModule,
  MatProgressSpinnerModule,
  MatNativeDateModule,
  MatDividerModule,
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatSidenavModule,
  MatExpansionModule,
  MatMenuModule,
  MatIconModule,
  MatCheckboxModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatToolbarModule,
  MatListModule,
  MatStepperModule,
  MatRadioModule,
  MatGridListModule,
  MatProgressBarModule,
  MatSnackBarModule,
  MatDatepickerModule,
  MatBottomSheetModule
} from '@angular/material';
import { MatCardModule } from '@angular/material/card';
import { OAuthModule } from 'angular-oauth2-oidc';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { PrivacyNoticeComponent } from './core/components/privacy-notice/privacy-notice.component';
import { NonCompatibleComponent } from './core/components/non-compatible/non-compatible.component';
import { FooterComponent } from './core/components/footer/footer.component';
import { FilterModalComponent } from './core/components/filter-modal/filter-modal.component';
import { HeaderComponent } from './core/components/header/header.component';
import { PrivacyTermsComponent } from './public/privacy-terms/privacy-terms.component';
import { LogoutComponent } from './core/components/logout/logout.component';
import { LoginComponent } from './core/components/login/login.component';
import { ExternRouteComponent } from './core/components/extern-route/extern-route.component';
import { HttpInterceptorService } from './core/services/http-interceptor.service';
import { DialogComponent } from './public/dialog/dialog.component';
import { CompleteFamilyViewComponent } from './public/dashboard/components/complete-family-view/complete-family-view.component';

// tslint:disable-next-line: max-line-length
// tslint:disable-next-line: max-line-length
import { BenefitsDetailModalComponent } from './public/benefits/modules/list/components/benefits-detail-modal/benefits-detail-modal.component';
import { AppInitService } from './core/services/app-init.service';
import { PolicyModalComponent } from './public/dashboard/components/policy-modal/policy-modal.component';
import { NotificationComponent } from './core/components/notification/notification.component'
import { ConfirmBenefitsDialogComponent } from './public/benefits/modules/review/review.component';
import { BenefitService } from './public/benefits/services/benefit.service';
import { NetworkService } from './core/services/network.service';
import { TranslateModule } from '@ngx-translate/core';
import { FormatTimePipe, ToastIdleTimeoutComponent } from './public/toast-idle-timeout/toast-idle-timeout.component';

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LogoutComponent,
    ExternRouteComponent,
    PrivacyNoticeComponent,
    NonCompatibleComponent,
    FooterComponent,
    FilterModalComponent,
    HeaderComponent,
    PrivacyTermsComponent,
    NotificationComponent,
    ToastIdleTimeoutComponent,
    FormatTimePipe
  ],
  imports: [
    OAuthModule.forRoot(),
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot(), 
    BrowserModule,
    HttpClientModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatButtonModule,
    MatSidenavModule,
    MatExpansionModule,
    MatIconModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatDialogModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatToolbarModule,
    MatListModule,
    MatStepperModule,
    MatRadioModule,
    MatGridListModule,
    MatDatepickerModule,
    MatCardModule,
    FormsModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatTableModule,
    MatPaginatorModule,
    MatTabsModule,
    MatSortModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatNativeDateModule,
    MatDividerModule,
    // MaterialModule,
    NgxSpinnerModule,
    MatSlideToggleModule,
    MatBottomSheetModule,
    ServiceWorkerModule.register(
      'ngsw-worker.js', { enabled: environment.production }
    ),
    TranslateModule,
    AppRoutingModule, // I must be last!! https://angular.io/guide/router#module-import-order-matters
  ],
  entryComponents: [
    DialogComponent,
    CompleteFamilyViewComponent,
    BenefitsDetailModalComponent,
    PolicyModalComponent,
    ConfirmBenefitsDialogComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    //AppInitService,
    BenefitService,
    NetworkService,
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeApp,
    //   deps: [
    //     AppInitService
    //   ],
    //   multi: true
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: HttpInterceptorService,
    //   multi: true,
    // },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    DatePipe,
    CookieService,
    BenefitService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
@NgModule({})
export class BenefitBuilderSharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: AppModule,
      providers: [
        //AppInitService,
        BenefitService,
        NetworkService,
        // {
        //   provide: APP_INITIALIZER,
        //   useFactory: initializeApp,
        //   deps: [
        //     AppInitService
        //   ],
        //   multi: true
        // },
        // {
        //   provide: HTTP_INTERCEPTORS,
        //   useClass: HttpInterceptorService,
        //   multi: true,
        // },
        {
          provide: LocationStrategy,
          useClass: HashLocationStrategy
        },
        DatePipe,
        CookieService,
        BenefitService
      ]
    }
  }
}