import { Component, Input } from '@angular/core';
import { ExcelExportService } from '../../services/excel-export.service';
import { Subscription, forkJoin, from } from 'rxjs';
import { UserPreferencesService } from '../../../core/services/user-preferences.service';
import { AggregationFetchparameter } from '../../../core/models/aggregation-fetch-parameter.model';
import { EmployeeDetailsService } from '../../../core/services/employee-details.service';
import { EmployeeDetailsView } from '../../../core/models/employee-details-view.model';
import { EmployeeDetails } from '../../../core/models/employee-details.model';
import { DateConversionService } from '../../../core/services/date-conversion.service';
import { TransactionService } from '../../../core/services/transaction.service';
import { TransactionData } from '../../../core/models/transaction-data.model';
import { Transaction } from '../../../core/models/transaction.model';
import { take } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import * as empColumns from '../../../core/data/empListDataView.json';
import * as financialColumns from '../../../core/data/financialDataView.json';
import { NumbersService } from '../../services/numbers.service';


@Component({
  selector: 'app-download-menu-button',
  templateUrl: './download-menu-button.component.html',
  styleUrls: ['./download-menu-button.component.scss']
})
export class DownloadMenuButtonComponent {

  /** Input the reportName from employee-details and employee-transactions component */
  @Input() reportName: string;

  /** employeeDet variable to store the response data */
  employeeDet: Subscription;

  /** employeeTransactionView variable to store the response data */
  employeeTransactionView: Subscription;

  /** reportListColName variable to provide employee data based on userPrefernce */
  @Input() reportColName: string[] = [];
  @Input() reportListColName: string[] = [];

  empColumnsList: any = (empColumns as any).default;
  financialColumnsList: any = (financialColumns as any).default;

  /** reportData variable to store the employee details data */
  reportData: EmployeeDetails[];

  /** transactionInfo will hold the employee first name and last name */
  transactionInfo: any;

  /** hold favorite user flag of employee */
  // tslint:disable-next-line: no-inferrable-types
  favUser: number = 0;

  /** store preferred currency */
  preferredCurr = '';

  /** Base constructor method
   * @param employeeService EmployeeDetailsService injection
   */

  constructor(private readonly route: ActivatedRoute,
    private readonly userPreferenceService: UserPreferencesService,
    private readonly employeeService: EmployeeDetailsService,
    private readonly transactionService: TransactionService,
    private dateConversionService: DateConversionService,
    private XlExportSvc: ExcelExportService,
    private readonly numberService: NumbersService,
    private readonly toastrService: ToastrService) { }

  /** downloadExcelReport method will get employee details to show in excel report */
  downloadExcelReport(reportName) {
    
    this.userPreferenceService
      .getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
            if (item.preferenceconfig.name == "preferredCurrency") {
            if (
              'preferencesetting' in item &&
              item.preferencesetting.value
            ) {
            item.preferencesetting.value ? this.preferredCurr = item.preferencesetting.value : null;
            } 
          }  
          });
        }
      });      
    if (reportName === 'Employee Details') {
      forkJoin([this.userPreferenceService.getPreference('aggregation_filter', false),
      this.userPreferenceService.getPreference('filter_text', false),
      this.userPreferenceService.getPreference('sort_options', false)
      ]).subscribe((response) => {
        let setEmployeeData: AggregationFetchparameter;
        setEmployeeData = {
          itemsPerPage: 3000, // Temporary fix due to timeouts
          pageNumber: 1
        };
        if (response[0]) {
          setEmployeeData.filters = response[0];
        }
        if (response[1]) {
          setEmployeeData.searchText = response[1];
        }
        if (response[2]) {
          setEmployeeData.sortColumnName = response[2].sortColumnName;
          setEmployeeData.sortOrder = response[2].sortOrder;
        }
        if(this.preferredCurr && typeof this.preferredCurr === 'string'){
          setEmployeeData.preferredCurr = this.preferredCurr;
        }

        this.showGenerateToaster('EXCEL Report Generating...');
        this.employeeDet = this.employeeService.getEmployeeDetails(setEmployeeData).subscribe((res: EmployeeDetailsView) => {
          if (res.data && res.data.length > 0) {
            res.data.forEach((employee) => {
              if (employee.clientEmployeeId === '') {
                employee.clientEmployeeId = 'N/A';
              }
              if (employee.movePhase.id) {
                employee.movePhase = employee.movePhase.id;
              } else {
                employee.movePhase = 'N/A';
              }
              if (employee.totalCost === 0) {
                employee.totalCostCurr = '';
              }
              if (!employee.effectiveTransferDate) {
                employee.effectiveTransferDate = 'N/A';
              }
              if (!employee.policyName) {
                employee.policyName = 'N/A';
              }
              if (employee.sentimentLevel > 0) {
                employee.sentimentName = employee.sentimentName;
              } else {
                employee.sentimentName = 'Not Provided';
              }
              if (!employee.milestoneDesc) {
                employee.milestoneDesc = 'N/A';
              }
              if (employee.moveRisk) {
                // switch case to determine moverisk value
                switch (employee.moveRisk) {
                  case 1: {
                    employee.moveRisk = 'Off Track';
                    break;
                  }
                  case 3: {
                    employee.moveRisk = 'On Track';
                    break;
                  }
                  case 4: {
                    employee.moveRisk = 'Risk Complete';
                    break;
                  }
                }
              } else {
                employee.moveRisk = 'N/A';
              }
            });
          }
          const employeeDetailsData = [];
          let rowCounter = 0;
          res.data.map(employee => {
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            rowCounter++;

            // tslint:disable-next-line: rxjs-no-unsafe-scope
            if (this.reportListColName && this.reportListColName.length > 0) {
              const empObj = {};
              this.reportListColName.forEach((it) => {
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                empObj['#'] = rowCounter;
                if ('starred' === it) {
                  empObj['STARRED'] = employee.favUser ? 'Yes' : 'No';

                } else if ('fullName' === it) {
                  empObj['FIRST NAME'] = employee.firstName;
                  empObj['LAST NAME'] = employee.lastName;
                  empObj['FILE NUMBER'] = employee.fileNumber;
                  empObj['EMPLOYEE ID'] = employee.clientEmployeeId;

                } else if ('movePhase' === it) {
                  empObj['MOVE PHASE'] = employee.movePhase;

                } else if ('policy' === it) {
                  empObj['POLICY NAME'] = employee.policyName;

                } else if ('fileStatusDesc' === it) {
                  empObj['FILE STATUS'] = employee.fileStatusDesc;

                } else if ('division' === it) {
                  empObj['DIVISION NAME'] = employee.divisionName;
                  empObj['DIVISION NUMBER'] = employee.divisionNumber;

                } else if ('departure' === it) {
                  empObj['DEPARTURE COUNTRY'] = employee.departureCountry;
                  empObj['DEPARTURE STATE'] = employee.departureState;
                  empObj['DEPARTURE CITY'] = employee.departureCity;

                } else if ('destination' === it) {
                  empObj['DESTINATION COUNTRY'] = employee.destinationCountry;
                  empObj['DESTINATION STATE'] = employee.destinationState;
                  empObj['DESTINATION CITY'] = employee.destinationCity;
                } else if ('providers' === it) {
                  empObj['PROVIDER COUNT'] = employee.providerCount;

                } else if ('sentiment' === it) {
                  empObj['SENTIMENT NAME'] = employee.sentimentName;
                  empObj['SENTIMENT LEVEL'] = employee.sentimentLevel;
                } else if ('effectiveTransferDate' === it) {
                  empObj['EFFECTIVE TRANSFER DATE'] = employee.effectiveTransferDate;
                } else if ('milestone' === it) {
                  empObj['MILESTONE DATE'] = employee.milestoneDate;
                  empObj['MILESTONE DESCRIPTION'] = employee.milestoneDesc;

                } else if ('totalCost' === it) {
                  empObj['TOTAL COST'] = employee.totalCost;
                  empObj['TOTAL COST CURRENCY'] = employee.totalCostCurr;
                } else if ('moveAtRisk' === it) {
                  empObj['MOVE RISK'] = employee.moveRisk;
                }
              });
              employeeDetailsData.push(empObj);
            } else {
              employeeDetailsData.push({
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                '#': rowCounter,
                'STARRED': employee.favUser ? 'Yes' : 'No',
                'FIRST NAME': employee.firstName,
                'LAST NAME': employee.lastName,
                'FILE NUMBER': employee.fileNumber,
                'EMPLOYEE ID': employee.clientEmployeeId,
                'MOVE PHASE': employee.movePhase,
                'POLICY NAME': employee.policyName,
                'FILE STATUS': employee.fileStatusDesc,
                'DIVISION NAME': employee.divisionName,
                'DIVISION NUMBER': employee.divisionNumber,
                'DEPARTURE COUNTRY': employee.departureCountry,
                'DEPARTURE STATE': employee.departureState,
                'DEPARTURE CITY': employee.departureCity,
                'DESTINATION COUNTRY': employee.destinationCountry,
                'DESTINATION STATE': employee.destinationState,
                'DESTINATION CITY': employee.destinationCity,
                'PROVIDER COUNT': employee.providerCount,
                'SENTIMENT NAME': employee.sentimentName,
                'SENTIMENT LEVEL': employee.sentimentLevel,
                'EFFECTIVE TRANSFER DATE': employee.effectiveTransferDate,
                'MILESTONE DATE': employee.milestoneDate,
                'MILESTONE DESCRIPTION': employee.milestoneDesc,
                'TOTAL COST': employee.totalCost,
                'TOTAL COST CURRENCY': employee.totalCostCurr,
                'MOVE RISK': employee.moveRisk
              });
            }
          });
          const employeeReportOptions = {
            fileName: 'MovePro360 Employees',
            totalCount: res.count
          };
          this.employeeReportData(employeeDetailsData, employeeReportOptions, setEmployeeData.itemsPerPage);
        });
      });
    } else if (reportName === 'Employee Transactions') {
      let setTransactionData: AggregationFetchparameter;
      setTransactionData = {
        itemsPerPage: 10000,
        pageNumber: 1,
        preferredCurr: this.preferredCurr
      };
      this.showGenerateToaster('EXCEL Report Generating...');
      this.employeeTransactionView = this.route.params.subscribe(params => {
        const fileNumber: number = +params.id;
        // tslint:disable-next-line: no-shadowed-variable
        this.employeeService.getEmployeeInfo(fileNumber, 0).subscribe((response) => {
          this.transactionInfo = response;
          this.transactionService.getTransactionByFileNum(fileNumber, setTransactionData).pipe(take(1))
            .subscribe((res: Transaction) => {
              if (res.results && res.results.length > 0) {
                res.results.forEach((employee) => {
                  if (employee.billAmount === 0) {
                    employee.billCurrency = '';
                  }
                  if (employee.invoiceDate) {
                    employee.invoiceDate = this.dateConversionService.convertToDisplayDate(employee.invoiceDate.toString());
                  }
                });
              }
              const employeeTransactionData = [];
              let rowCounter = 0;
              // let billAmountIndex:number = 0;
              res.results.map(employee => {
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                rowCounter++;
                // tslint:disable-next-line: rxjs-no-unsafe-scope
                if (this.reportColName && this.reportColName.length > 0) {
                  const obj = {};
                  this.reportColName.forEach((it, index) => {
                    // tslint:disable-next-line: rxjs-no-unsafe-scope
                    obj['#'] = rowCounter;
                    if ('accountCategory' === it) {
                      obj['ACCOUNT CATEGORY'] = employee.accountCategory;

                    } else if ('accountSubCategory' === it) {
                      obj['ACCOUNT SUBCATEGORY'] = employee.accountSubCategory;

                    } else if ('transactionDesc' === it) {
                      obj['TRANSACTION DESCRIPTION'] = employee.transactionDesc;
                    } else if ('billAmount' === it) {
                      obj['BILLED CURRENCY'] = employee.billCurrency;
                      obj['BILLED AMOUNT'] = employee.billAmount;

                    } else if ('invoiceDate' === it) {
                      obj['BILLED DATE'] = employee.invoiceDate;
                    } else if ('invoiceNo' === it) {
                      obj['INVOICE NUMBER'] = employee.invoiceNo;
                    }
                  });
                  employeeTransactionData.push(obj);
                } else {
                  employeeTransactionData.push({
                    // tslint:disable-next-line: rxjs-no-unsafe-scope
                    '#': rowCounter,
                    'ACCOUNT CATEGORY': employee.accountCategory,
                    'ACCOUNT SUBCATEGORY': employee.accountSubCategory,
                    'TRANSACTION DESCRIPTION': employee.transactionDesc,
                    'BILLED AMOUNT': employee.billAmount,
                    'BILLED CURRENCY': employee.billCurrency,
                    'BILLED DATE': employee.invoiceDate,
                    'INVOICE NUMBER': employee.invoiceNo
                  });
                }
              });
              const transactionReportOptions = {
                fileName: this.transactionInfo.firstName + ' ' + this.transactionInfo.lastName + ' ' + 'Transactions',
                totalCount: res.count
              };
              const totalOptions = {
                taxAssistance: res.totalTaxAssistance,
                totalExpense: res.totalExpense,
                totalAmount: res.totalBillAmount,
                totalCurrency: res.transactionCurrCode
              };
              // tslint:disable-next-line: max-line-length
              this.employeeReportData(employeeTransactionData, transactionReportOptions, setTransactionData.itemsPerPage, totalOptions);

            });
        });
      });
    } else if (reportName === 'Aggregate View') {
      alert('This is aggregate view report');
    }
  }

  /** employeeReportData will call excel-export service to generate and download report */
  employeeReportData(data: any, dataOptions: any, itemsPerPage: any, totalOptions = {}) {
    this.reportData = data;
    const date = new Date();
    const todayDate = this.dateConversionService.convertToYyyyMmDd(date);
    const reportDate = this.dateConversionService.convertToDisplayDate(todayDate.toString());
    const reportTime = formatDate(date, 'hh:mm a', 'en-US');
    const maxRecords = itemsPerPage;
    let addressCell: string, additionalRecords: number;
    if (dataOptions.totalCount > maxRecords) {
      additionalRecords = dataOptions.totalCount - maxRecords;
      this.reportData = this.reportData.slice(0, maxRecords);
      addressCell = 'A5';
    } else {
      addressCell = 'A3';
    }
    // tslint:disable-next-line: max-line-length
    const reportMessage = 'This has returned too many results, below we are showing the first ' + maxRecords + ' records. (' + additionalRecords + ' additional results are not being shown)';
    const reportOptions = {
      fileName: dataOptions.fileName,
      reportDate: reportDate,
      reportTime: reportTime,
      reportMessage: reportMessage,
      addressCell: addressCell,
      reportName: this.reportName
    };
    this.XlExportSvc.exportAsExcelFile(this.reportData, reportOptions, totalOptions);
  }

  /** showGenerateToaster will show toastr with status message */
  showGenerateToaster(message) {
    this.toastrService.info(message, null,
      {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      }
    );
  }
}
