<div class="dialog-container" role="region">
  <!-- Title of pop up -->
  <div mat-dialog-title disableTypography fxLayout="row" fxLayoutAlign="start" fxLayoutGap="20px" fxLayoutGap.xs="0em"
    role="heading">

    <span class="copy-header">Remove Selected Benefit</span>
    <a class="close-icon" (click)="onDismiss()" title="close" role="button" aria-label="Close Program Modal"
      title="Close Program Modal">
      <mat-icon>close</mat-icon>
    </a>

  </div>
  <mat-dialog-content>

    <!-- Message  -->
    <div class="message" fxFlex="100%" fxLayoutAlign="start" fxLayout="column" fxLayout.xs="column" fxFlex.xs="100%"
      role="none">
      <p>You are about to remove following confirmed benefit:</p>
      <p>{{data.displayName}}</p>
      <p> <span> <b>Please be Aware :</b></span></p>
      <ul>
        <li>This will remove the benefit from the transferee confirmed benefits</li>
        <li>All related data previously entered will be lost</li>
      </ul>

    </div>

  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button type="button" class="mat-button text-button btn" (click)="onDismiss()" color="primary" role="button"
      aria-label="Cancel and Close Modal">CANCEL</button>
    <button class="mat-button contained-button btn removal-btn" (click)="confirmRemoval()" role="button"
      aria-label="Confirm removal of button">Confirm Removal</button>
  </mat-dialog-actions>

</div>