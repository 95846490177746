import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild
} from '@angular/core';
import { CandidateProfilesService } from '../../../core/services/candidate-profiles.service';
// import { ClientServiceService } from '../../services/client-service.service';
// import { ClientContactService } from '../../services/client-contact.service';
import { CostModelsService } from '../../../core/services/cost-models.service';
import { Subscription } from 'rxjs';
import { NotificationsService } from '../../../core/services/notifications.service';
//import { AddCandidateComponent } from '../../../public/project-alpha/candidate-profile/add-candidate/add-edit-budget.component';
import { Candidate } from '../../../core/models/candidate_cost_model';
//import { Client } from '../../models/client';
//import { ClientContact } from '../../models/client-contact';
//import { AddClientComponent } from '../../../public/project-alpha/client/add-client/add-client.component';
import { MatDialog } from '@angular/material';
import { AddCostModelComponent } from '../../../public/cost-model/add-cost-model/add-cost-model.component';
//{ AddClientContactComponent } from '../../../public/project-alpha/client-contact/add-client-contact/add-client-contact.component';
import { CostModel } from '../../../core/models/cost-model';
import { MoveProDashboadDataService } from '../../../core/data/move-pro-dashboad-data.service';
import { BbAdminDataService } from '../../../core/data/bb-admin-data.service';
/**class for header status component */
@Component({
  selector: 'app-header-status',
  templateUrl: './header-status.component.html',
  styleUrls: ['./header-status.component.scss']
})
export class HeaderStatusComponent implements OnInit, OnDestroy {
  /**receive disable value based on role*/
  @Input() disable: any;
  //@Input() selectedClient: Client;
  /**event emitter to emit candidate data */
  @Output() event = new EventEmitter<Candidate>();
  /**event emitter to emit client data */
  @Output() clientEvent = new EventEmitter<any>();
  /**event emitter to emit client data */
  @Output() clientContactEvent = new EventEmitter<any>();
  /**event emitter to call updateDataSource in CostModel component */
  @Output() costModelEvent = new EventEmitter<any>();
  /**variables to store activeCandidates */
  activeCount: number;
  /**variables to store activeCandidates */
  currentClientCount: number;
  /**variable to store type */
  public type: string;
  /**variable to storre label */
  label: string;
  /**flag to load the template */
  isLoaded = false;
  /** Subscription property for subscribing services */
  private readonly subscription: Subscription = new Subscription();
  /**store disableButton value */
  disableButton = false;
  tempCostmodelData: any;
  /**
   * Heading to be focused first
   */
  @ViewChild('pageheading', { static: false }) pageheading: ElementRef;
  /**
   * injecting dependencies
   * @param candidateProfilesService object to access candidateProfilesService
   * @param dialog Matdialog object
   * @param notificationsService object to access notificationService
   */
  constructor(
    private readonly candidateProfilesService: CandidateProfilesService,
    // private readonly clientService: ClientServiceService,
    // private readonly clientContactService: ClientContactService,
    
    public dialog: MatDialog,
    private readonly notificationsService: NotificationsService,
    private readonly costModelService: CostModelsService,
    private bbAdminBackendService: BbAdminDataService
  ) { }

  /**Initialising the component */
  ngOnInit() {
    this.subscription.add(
      this.candidateProfilesService.totalCount$.subscribe(result => {
        if (!this.label) {
          setTimeout(() => {
            this.pageheading.nativeElement.focus();
          }, 2000);
        }
       // this.activeCount = result.length;
        this.type = result.type;
        this.label = result.label;
        this.isLoaded = true;
      })
    );
    this.candidateProfilesService.testSubject$.subscribe(result => {
     // this.activeCount = result.length;
      this.type = result.type;
      this.label = result.label;
      this.isLoaded = true;
    });
    this.bbAdminBackendService.costModelDataSubject.subscribe(data => {
      this.activeCount = data.body.totalCostModel;
    });
  }

  /**Function To open Add candidate dialog on click of Add button */
  openDialog(evt: MouseEvent): void {
    // evt.preventDefault();
    // const dialogRef = this.dialog.open(AddCandidateComponent, {
    //   disableClose: true,
    //   panelClass: 'dialogMainContainer',
    //   data: {
    //     disableButton: this.disable
    //   }
    // });

    // this.subscription.add(
    //   dialogRef.afterClosed().subscribe((candidate: Candidate) => {
    //     if (candidate) {
    //       this.event.emit(candidate);
    //       if (candidate.executedFunctionType === 'Create') {
    //         this.notificationsService.flashNotification(
    //           'success',
    //           'Candidate added successfully',
    //           true,
    //           'dismiss'
    //         );
    //       } else if (candidate.executedFunctionType === 'Invite') {
    //         this.notificationsService.confirmNotification('Email');
    //       }
    //     }
    //   })
    // );
  }

  /**
   * Function to Add client.It calls the API to add cient.
   */
  openDialogClient(evt: MouseEvent): void {
    // evt.preventDefault();
    // const dialogRef = this.dialog.open(AddClientComponent, {
    //   //  panelClass: 'addCostModelModal'
    //   panelClass: 'dialogMainContainer'
    // });
    // this.subscription.add(dialogRef.afterClosed().subscribe((client: Client) => {
    //   if (client) {
    //     this.clientEvent.emit(client);
    //     if (client.executedFunctionType === 'Create') {
    //       this.notificationsService.flashNotification(
    //         'success',
    //         'Client added successfully',
    //         true,
    //         'dismiss'
    //       );
    //     }
    //   }
    // }));
  }

  openDialogClientContact(evt: MouseEvent): void {
    // evt.preventDefault();
    // const dialogRef = this.dialog.open(AddClientContactComponent, {
    //   panelClass: 'dialogMainContainer',
    //   disableClose: true,
    //   data: {
    //     selectedClient: this.selectedClient,
    //     disableButton: this.disable
    //   }
    // });

    // this.subscription.add(dialogRef.afterClosed().subscribe((clientContact: ClientContact) => {
    //   const statusDate = this.formatDate(clientContact.statusDate !== undefined ?
    //     clientContact.statusDate : new Date());
    //   if (clientContact.executedFunctionType === 'Create') {
    //     clientContact.statusDate = statusDate;
    //     this.clientContactEvent.emit(clientContact);
    //     this.notificationsService.flashNotification(
    //       'success',
    //       'Added Client Contact Successfully.',
    //       true,
    //       'dismiss'
    //     );
    //   } else if (clientContact.executedFunctionType === 'Invite') {
    //     clientContact.statusDate = statusDate !== undefined ? statusDate : new Date().toISOString().substring(0, 10);
    //     this.clientContactEvent.emit(clientContact);
    //     console.log('After send invite');
    //     this.notificationsService.confirmNotification('Email');
    //   } else {
    //     clientContact.executedFunctionType = 'Create';
    //     clientContact.statusDate = statusDate !== undefined ? statusDate : new Date().toISOString().substring(0, 10);
    //     this.clientContactEvent.emit(clientContact);
    //     this.notificationsService.flashNotification(
    //       'success',
    //       'Saved Client Contact Successfully.',
    //       true,
    //       'dismiss'
    //     );
    //   }
    // }));
  }

  formatDate(date) {
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const monthVal = (newDate.getMonth() + 1);
    const month = `0${monthVal}`.slice(-2);
    const dateVal = (newDate.getDate());
    const dateStr = `0${dateVal}`.slice(-2);
    return `${year}-${month}-${dateStr}`;
  }

  /**
    * Function to Add Cost Model.It calls the API to add cost model.
    */
  openDialogCostModel(evt: MouseEvent): void {
    evt.preventDefault();
    const dialogRef = this.dialog.open(AddCostModelComponent, {
      disableClose: true,
      panelClass: 'dialogMainContainer',
      data: {
        costModelId: Math.floor(Math.random() * 1000000000),
        disableButton: this.disable
      }
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((costModel: CostModel) => {
        if (costModel) {
          costModel.status = 'Active';
          const date = new Date(costModel.updatedDate);
          costModel.expiryDate = new Date(date.setDate(date.getDate() + 30)).toISOString().substring(0, 10);
          this.notificationsService.flashNotification(
            'success',
            'Due to seasonal changes, this cost model expires in 30 days. Cost Model added successfully.',
            true,
            'dismiss'
          );
          this.bbAdminBackendService.costModelDataSubject.subscribe(data => {
            this.activeCount = data.body.totalCostModel;
          });

          this.costModelEvent.emit(costModel);
        }
      }));
  }

  isDisabled(flag) {
    this.disableButton = flag;
  }
  /** destroy the subscriptions */
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
