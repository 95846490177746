/***icons */
export const ICONS_MAPPING = [
    {
      'reference': 'OFF-HHGIntlAirBBAcmp',
      'icon': 'illustration-airShipmentFamily.svg'
    },
    {
      'reference': 'OFF-HHGIntlAirBBUnacmp',
      'icon': 'illustration-airShipmentSingle.svg'
    },
    {
      'reference': 'OFF-BusnCommAdvBBIntl',
      'icon': 'illustration-advBusinessSkills.svg'
    },
    {
      'reference': 'OFF-GlbLdrshpCommBBIntl',
      'icon': 'illustration-globalLeadershipEnglish.svg'
    },
    {
      'reference': 'OFF-AccntModBBIntl',
      'icon': 'illustration-accentModification.svg'
    },
    {
      'reference': 'OFF-BsnCommEnglBBIntl20',
      'icon': 'illustration-businessEnglish.svg'
    },
    {
      'reference': 'OFF-PrsntSkillsBusnEnglBBIntl12',
      'icon': 'illustration-presentationSkillsCoaching.svg'
    },
    {
      'reference': 'OFF-AddtlStrgBB',
      'icon': 'illustration-StorageInTransit.svg'
    },
    {
      'reference': 'OFF-AutoLossBB',
      'icon': 'illustration-lossOnSale.svg'
    },
    {
      'reference': 'OFF-BrkrFndrFeeBB',
      'icon': 'illustration-rentalFindersFee.svg'
    },
    {
      'reference': 'OFF-BrkrFndrFeeIntlBB',
      'icon': 'illustration-rentalFindersFee.svg'
    },
    {
      'reference': 'OFF-CarRntlBB',
      'icon': 'illustration-carRental.svg'
    },
    {
      'reference': 'OFF-CarRntlIntlBB',
      'icon': 'illustration-carRental.svg'
    },
    {
      'reference': 'OFF-CashOut10BB',
      'icon': 'illustration-cashOut.svg'
    },
    {
      'reference': 'OFF-CashOut10IntlBB',
      'icon': 'illustration-cashOut.svg'
    },
    {
      'reference': 'OFF-CashOut3BB',
      'icon': 'illustration-cashOut.svg'
    },
    {
      'reference': 'OFF-CashOut4BB',
      'icon': 'illustration-cashOut.svg'
    },
    {
      'reference': 'OFF-CashOut5BB',
      'icon': 'illustration-cashOut.svg'
    },
    {
      'reference': 'OFF-CashOut7BB',
      'icon': 'illustration-cashOut.svg'
    },
    {
      'reference': 'OFF-CltrlTrngBB',
      'icon': 'illustration-culturalTraining.svg'
    },
    {
      'reference': 'OFF-CltrlTrngOnlnBB',
      'icon': 'illustration-onlineCulturalTool.svg'
    },
    {
      'reference': 'OFF-CorpHsngBB',
      'icon': 'illustration-tempLiving.svg'
    },
    {
      'reference': 'OFF-CostOfLvngBB',
      'icon': 'illustration-costOfLiving.svg'
    },
    {
      'reference': 'OFF-DestSvcsBndlBB',
      'icon': 'illustration-servicesBundle.svg'
    },
    {
      'reference': 'OFF-DestSvcs3DayBndlBB',
      'icon': 'illustration-servicesBundle.svg'
    },
    {
      'reference': 'OFF-DestSvcs4DayBndlBB',
      'icon': 'illustration-servicesBundle.svg'
    },
    {
      'reference': 'OFF-DsrdDntCrBB',
      'icon': 'illustration-HHGDonate.svg'
    },
    {
      'reference': 'OFF-DupHsngBB',
      'icon': 'illustration-duplicateHousisng.svg'
    },
    {
      'reference': 'OFF-DupHousingBB',
      'icon': 'illustration-duplicateHousisng.svg'
    },
    {
      'reference': 'OFF-DupHsngBndl2BB',
      'icon': 'illustration-duplicateHousisng.svg'
    },
    {
      'reference': 'OFF-DupHsngBndl3BB',
      'icon': 'illustration-duplicateHousisng.svg'
    },
    {
      'reference': 'OFF-ExcsBagBB',
      'icon': 'illustration-excessBaggage.svg'
    },
    {
      'reference': 'OFF-ExcsBagReimbBB',
      'icon': 'illustration-excessBaggage.svg'
    },
    {
      'reference': 'OFF-FnlTrnsprtBB',
      'icon': 'illustration-FinalTransport.svg'
    },
    {
      'reference': 'OFF-FrnRntlBB',
      'icon': 'illustration-furnitureRental.svg'
    },
    {
      'reference': 'OFF-FurnAllow',
      'icon': 'illustration-furnitureAllowance.svg'
    },
    {
      'reference': 'OFF-HHGBB',
      'icon': 'illustration-hhgProMove.svg'
    },
    {
      'reference': 'OFF-HHGFlxBB',
      'icon': 'illustration-hhgProMove.svg'
    },
    {
      'reference': 'OFF-HHGIntlAirBB',
      'icon': 'illustration-airShipment.svg'
    },
    {
      'reference': 'OFF-HHGIntlAirBB-INS',
      'icon': 'illustration-airShipment.svg'
    },
    {
      'reference': 'OFF-AirfareUpgradeBndlBBRpt',
      'icon': 'illustration-returnTripUpgrade.svg'
    },
    {
      'reference': 'OFF-HHGIntlSea20BB',
      'icon': 'illustration-seaShipment.svg'
    },
    {
      'reference': 'OFF-HHGIntlSea40BB',
      'icon': 'illustration-seaShipment.svg'
    },
    {
      'reference': 'OFF-HHGIntlSeaBB',
      'icon': 'illustration-seaShipment.svg'
    },
    {
      'reference': 'OFF-HmFndng32BB',
      'icon': 'illustration-homeFinding.svg'
    },
    {
      'reference': 'OFF-HmFndngRtrn43BB',
      'icon': 'illustration-homeFinding.svg'
    },
    {
      'reference': 'OFF-HmFndngRtrn32BB',
      'icon': 'illustration-homeFinding.svg'
    },
    {
      'reference': 'OFF-HmFndng43BB',
      'icon': 'illustration-homeFinding.svg'
    },
    {
      'reference': 'OFF-HmFndng54BB',
      'icon': 'illustration-homeFinding.svg'
    },
    {
      'reference': 'OFF-HmFndng65BB',
      'icon': 'illustration-homeFinding.svg'
    },
    {
      'reference': 'OFF-HmFndngBB',
      'icon': 'illustration-homeFinding.svg'
    },
    {
      'reference': 'OFF-HmFndngBBRpt32',
      'icon': 'illustration-homeFinding.svg'
    },
    {
      'reference': 'OFF-HmPrchReimbBB',
      'icon': 'illustration-HomePurchaseReimbursement.svg'
    },
    {
      'reference': 'OFF-HmSlReimbBB',
      'icon': 'illustration-HomeSaleReimbursement.svg'
    },
    {
      'reference': 'OFF-HmSlReimbIntlBB',
      'icon': 'illustration-HomeSaleReimbursement.svg'
    },
    {
      'reference': 'OFF-HomePurchaseReimbursementBB',
      'icon': 'illustration-HomePurchaseReimbursement.svg'
    },
    {
      'reference': 'OFF-HomeSaleBVMABB',
      'icon': 'illustration-HomeSale.svg'
    },
    {
      'reference': 'OFF-HomeSaleGBOMABB',
      'icon': 'illustration-HomeSaleBuyOut.svg'
    },
    {
      'reference': 'OFF-HomeSaleIncntvBB',
      'icon': 'illustration-HomeSaleIncentive.svg'
    },
    {
      'reference': 'OFF-ImmgrtnSvcsBB',
      'icon': 'illustration-immigrationServices.svg'
    },
    {
      'reference': 'OFF-LngTrngBB',
      'icon': 'illustration-languageTraining.svg'
    },
    {
      'reference': 'OFF-LngTrngOnlnBB',
      'icon': 'illustration-onlineLanguageTraining.svg'
    },
    {
      'reference': 'OFF-LossOnSaleBB',
      'icon': 'illustration-lossOnSale.svg'
    },
    // {
    //   'reference': 'OFF-LsNegtnBB',
    //   'icon': 'illustration-leaseCancellation.svg'
    // },
    {
      'reference': 'OFF-LsTermAddtlMntBB',
      'icon': 'illustration-leaseTermination.svg'
    },
    {
      'reference': 'OFF-LsTermBB',
      'icon': 'illustration-leaseTermination.svg'
    },
    {
      'reference': 'OFF-LsTermBndl',
      'icon': 'illustration-leaseTermination.svg'
    },
    {
      'reference': 'OFF-MiscAllwncBBRpt',
      'icon': 'illustration-returnTrip.svg'
    },
    {
      'reference': 'OFF-MiscAllwnc10BB',
      'icon': 'illustration-relocationAllowance.svg'
    },
    {
      'reference': 'OFF-MiscAllwnc10IntlBB',
      'icon': 'illustration-relocationAllowance.svg'
    },
    {
      'reference': 'OFF-MiscAllwnc5IntlBB',
      'icon': 'illustration-relocationAllowance.svg'
    },
    {
      'reference': 'OFF-MiscAllwnc15BB',
      'icon': 'illustration-relocationAllowance.svg'
    },
    {
      'reference': 'OFF-MiscAllwnc75BB',
      'icon': 'illustration-relocationAllowance.svg'
    },
    {
      'reference': 'OFF-MiscAllwncBB',
      'icon': 'illustration-relocationAllowance.svg'
    },
    {
      'reference': 'OFF-MiscAllwnc2IntlBB',
      'icon': 'illustration-relocationAllowance.svg'
    },
    {
      'reference': 'OFF-MiscAllwnc2IntlBBReferences',
      'icon': 'illustration-relocationAllowance.svg'
    },
    {
      'reference': 'OFF-PetTrnsprtBB',
      'icon': 'illustration-petTransport.svg'
    },
    {
      'reference': 'OFF-PetTrnsprtBndlBB',
      'icon': 'illustration-petTransport.svg'
    },
    {
      'reference': 'OFF-PetTrnsprtAllwncBBReferences',
      'icon': 'illustration-petTransport.svg'
    },
    {
      'reference': 'OFF-PetTrnsprtReimbBB',
      'icon': 'illustration-petTransportReimbursement.svg'
    },
    {
      'reference': 'OFF-PlcyCnslBB',
      'icon': 'illustration-policyConsulting.svg'
    },
    {
      'reference': 'OFF-PlcyCnslIntlBB',
      'icon': 'illustration-policyConsulting.svg'
    },
    {
      'reference': 'OFF-PrvwTrpBB',
      'icon': 'illustration-previewTrip.svg'
    },
    {
      'reference': 'OFF-PreAssignPreTranfrFour',
      'icon': 'illustration-previewTrip.svg'
    },
    {
      'reference': 'OFF-PreAssignPreTranfrSix',
      'icon': 'illustration-previewTrip.svg'
    },
    {
      'reference': 'OFF-ReloAcctngBB',
      'icon': 'illustration-expenseManagement.svg'
    },
    {
      'reference': 'OFF-ReloAcctngIntlBB',
      'icon': 'illustration-expenseManagement.svg'
    },
    {
      'reference': 'OFF-RentalSvcsFullBB',
      'icon': 'illustration-fullDayServices.svg'
    },
    {
      'reference': 'OFF-RentalSvcsHalfBB',
      'icon': 'illustration-halfDayServices.svg'
    },
    {
      'reference': 'OFF-ReturnTrpBB',
      'icon': 'illustration-returnTrip.svg'
    },
    {
      'reference': 'OFF-ReturnTrpBndl2BB',
      'icon': 'illustration-returnTrip.svg'
    },
    {
      'reference': 'OFF-ReturnTrpBndl3BB',
      'icon': 'illustration-returnTrip.svg'
    },
    {
      'reference': 'OFF-SchlFndgBB',
      'icon': 'illustration-educationAssistance.svg'
    },
    {
      'reference': 'OFF-ShpmntSeaBB',
      'icon': 'illustration-seaShipment.svg'
    },
    {
      'reference': 'OFF-ShpmtAirBB',
      'icon': 'illustration-airShipment.svg'
    },
    {
      'reference': 'OFF-SlfMvBB',
      'icon': 'illustration-selfMove.svg'
    },
    {
      'reference': 'OFF-SlfStrgBB',
      'icon': 'illustration-selfStorage.svg'
    },
    {
      'reference': 'OFF-SpouseCareerSuppBB',
      'icon': 'illustration-partnerCareerSupport.svg'
    },
    {
      'reference': 'OFF-SpouseCareerSuppIntlBB',
      'icon': 'illustration-partnerCareerSupport.svg'
    },
    {
      'reference': 'OFF-StlngInBB',
      'icon': 'illustration-settlingIn.svg'
    },
    {
      'reference': 'OFF-StrgInTrnst60BB',
      'icon': 'illustration-StorageInTransit.svg'
    },
    {
      'reference': 'OFF-StrgInTrnst180BB',
      'icon': 'illustration-StorageInTransit.svg'
    },
    {
      'reference': 'OFF-StrgInTrnstBBIntl30',
      'icon': 'illustration-StorageInTransit.svg'
    },
    {
      'reference': 'OFF-StrgInTrnstBBIntl6190',
      'icon': 'illustration-StorageInTransit.svg'
    },
    {
      'reference': 'OFF-StrgInTrnstBBIntl3160',
      'icon': 'illustration-StorageInTransit.svg'
    },
    {
      'reference': 'OFF-StrgInTrnstBB',
      'icon': 'illustration-hhgStorage.svg'
    },
    {
      'reference': 'OFF-TaxBrfngBB',
      'icon': 'illustration-taxBriefing.svg'
    },
    {
      'reference': 'OFF-TaxPrepBB',
      'icon': 'illustration-taxPrep.svg'
    },
    {
      'reference': 'OFF-TmpHsng105BB',
      'icon': 'illustration-tempLiving.svg'
    },
    {
      'reference': 'OFF-TmpHsng120BB',
      'icon': 'illustration-tempLiving.svg'
    },
    {
      'reference': 'OFF-TmpHsng30BB',
      'icon': 'illustration-tempLiving.svg'
    },
    {
      'reference': 'OFF-TmpHsng45BB',
      'icon': 'illustration-tempLiving.svg'
    },
    {
      'reference': 'OFF-TmpHsngBB',
      'icon': 'illustration-tempLiving.svg'
    },
    {
      'reference': 'OFF-TmpHsngCrIntlBB',
      'icon': 'illustration-tempLiving.svg'
    },
    {
      'reference': 'OFF-TmpHsngFlxIntlBB',
      'icon': 'illustration-tempLiving.svg'
    },
    {
      'reference': 'OFF-TmpHsngReimbIntlBB',
      'icon': 'illustration-tempLivingReimbursement.svg'
    },
    {
      'reference': 'OFF-VhclShpmtBndl3BB',
      'icon': 'illustration-carShipment.svg'
    },
    {
      'reference': 'OFF-VhclShpmtBndlBB',
      'icon': 'illustration-carShipment.svg'
    },
    {
      'reference': 'OFF-VhclShpmtBndlIntlBB',
      'icon': 'illustration-carShipment.svg'
    },
    {
      'reference': 'OFF-MiscExpnsAllwncBB',
      'icon': 'illustration-miscExpenses.svg'
    },
    {
      'reference': 'OFF-FlyToIntrnshpBB',
      'icon': 'illustration-flyingToDestination.svg'
    },
    {
      'reference': 'OFF-DrvToIntrnshpBB',
      'icon': 'illustration-driveToDestination.svg'
    },
    {
      'reference': 'OFF-CrplToIntrnshpBB',
      'icon': 'illustration-carpooling.svg'
    },
    {
      'reference': 'OFF-CommtAllwncIntrnBB',
      'icon': 'illustration-miscExpenses.svg'
    },
    {
      'reference': 'OFF-CarRntlIntrnBB',
      'icon': 'illustration-longtermCarRental.svg'
    },
    {
      'reference': 'OFF-CorpHsngIntrnBB',
      'icon': 'illustration-tempLiving.svg'
    },
    {
      'reference': 'OFF-HsngAllwncIntrnBB',
      'icon': 'illustration-tempLivingbyEmp.svg'
    },
    {
      'reference': 'OFF-HstDprtAsstBB',
      'icon': 'illustration-HostCountryDepartureAssistance.svg'
    },
    {
      'reference': 'OFF-DeptrAsstBB',
      'icon': 'illustration-HostCountryDepartureAssistance.svg'
    },
    {
      'reference': 'OFF-FnlTrnsprtRtnBB',
      'icon': 'illustration-HomeSaleReimbursement.svg'
    },
    {
      'reference': 'OFF-RetunrTravlExpns',
      'icon': 'illustration-HomeSaleReimbursement.svg'
    },
    {
      'reference': 'OFF-LsTermRtrnBB',
      'icon': 'illustration-leaseTermination.svg'
    },
    {
      'reference': 'OFF-LsTermBBRpt',
      'icon': 'illustration-leaseTermination.svg'
    },
    {
      'reference': 'OFF-CltrlTrnsSuppBB2Hr',
      'icon': 'illustration-culturalTraining.svg'
    },
    {
      'reference': 'OFF-CltrlTrnsSuppCoreBB',
      'icon': 'illustration-culturalTraining.svg'
    },
    {
      'reference': 'OFF-CltrlTrnsSuppBB4Hr',
      'icon': 'illustration-culturalTraining.svg'
    },
    {
      'reference': 'OFF-RepatCoachBB',
      'icon': 'illustration-RepatriationCoaching.svg'
    },
    {
      'reference': 'OFF-CltrlTrngCoreSuppBB',
      'icon': 'illustration-culturalTraining.svg'
    },
    {
      'reference': 'OFF-HlthCrReimbBB',
      'icon': 'illustration-healthcare.svg'
    },
    {
      'reference': 'OFF-PreDcsnOrntnBB',
      'icon': 'illustration-fullDayServices.svg'
    },
    {
      'reference': 'OFF-PreDcsnOrntn1DayBB',
      'icon': 'illustration-fullDayServices.svg'
    },
    {
      'reference': 'OFF-PreDcsnOrntn2DayBB',
      'icon': 'illustration-fullDayServices.svg'
    },
    {
      'reference': 'OFF-CltrlTrnsSuppExtBB',
      'icon': 'illustration-culturalTrainingExt.svg'
    },
    {
      'reference': 'OFF-CltrlTrnsSuppYouthBB',
      'icon': 'illustration-culturalTraining.svg'
    },
    {
      'reference': 'OFF-HHGIntlAirCoreBB',
      'icon': 'illustration-languageTraining.svg'
    },
    {
      'reference': 'OFF-HHGIntlCoreBB',
      'icon': 'illustration-hhgProMove.svg'
    },
    {
      'reference': 'OFF-HHGIntlCoreBB-INS',
      'icon': 'illustration-hhgProMove.svg'
    },
    {
      'reference': 'OFF-LngTrngBB40Hr',
      'icon': 'illustration-languageTraining.svg'
    },
    {
      'reference': 'OFF-CostOfLvngDiffIntlBB',
      'icon': 'illustration-LivingCostDifferential.svg'
    },
    {
      'reference': 'OFF-DrvrsLcsnsExchMultiBBIntl',
      'icon': 'illustration-licenseExchange.svg'
    },
    {
      'reference': 'OFF-PropMgmtBBIntl',
      'icon': 'illustration-propertyManagement.svg'
    },
    {
      'reference': 'OFF-SchlFndgEdCmplBB',
      'icon': 'illustration-EducationComplete.svg'
    },
    {
      'reference': 'OFF-SchlFndgEdCmplBBIntl',
      'icon': 'illustration-EducationComplete.svg'
    },
    {
      'reference': 'OFF-DualUtilityBBIntl',
      'icon': 'illustration-dualUtilities.svg'
    },
    {
      'reference': 'OFF-PrkngReimbBBIntl',
      'icon': 'illustration-parkingAllowance.svg'
    },
    {
      'reference': 'OFF-LocHrdshpPremBBIntl',
      'icon': 'illustration-hardshipPremium.svg'
    },
    {
      'reference': 'OFF-RAndRBBIntl',
      'icon': 'illustration-restAndRecuperation.svg'
    },
    {
      'reference': 'OFF-WireFeeReimbBBIntl',
      'icon': 'illustration-wireFeeReimbursement.svg'
    },
    {
      'reference': 'OFF-HomeLeaveReimbBB',
      'icon': 'illustration-HomeLeaveReimbursement.svg'
    },
    {
      'reference': 'OFF-HomeLeaveReimbBBIntl',
      'icon': 'illustration-HomeLeaveReimbursement.svg'
    },
    {
      'reference': 'OFF-HomeLeaveAllowBBIntl',
      'icon': 'illustration-HomeLeaveReimbursement.svg'
    },
    {
      'reference': 'OFF-HomeLeaveExchBB',
      'icon': 'illustration-HomeLeaveExchange.svg'
    },
    {
      'reference': 'OFF-HomeLeaveExchBBIntl',
      'icon': 'illustration-HomeLeaveExchange.svg'
    },
    {
      'reference': 'OFF-NonAccompDepTrvlBBIntl',
      'icon': 'illustration-dependentTravel.svg'
    },
    {
      'reference': 'OFF-NonAccompDepTrvlBB',
      'icon': 'illustration-dependentTravel.svg'
    },
    {
      'reference': 'OFF-ApplncReimbBBIntl',
      'icon': 'illustration-appliances.svg'
    },
    {
      'reference': 'OFF-AirfareReimbBndlBBIntl',
      'icon': 'illustration-AirfareExpSupport.svg'
    },
    {
      'reference': 'OFF-AirfareUpgradeBndlBBIntl',
      'icon': 'illustration-airfareUpgrade.svg'
    },
    {
      'reference': 'OFF-EduSuppBndlBBIntl',
      'icon': 'illustration-dependentEdSupport.svg'
    },
    {
      'reference': 'OFF-HstHsngAllwncSuppBBIntlFlx',
      'icon': 'illustration-HostHousingAllowance.svg'
    },
    {
      'reference': 'OFF-HHGIntlAirBBUnacmp-INS',
      'icon': 'illustration-airShipmentSingle.svg'
    },
    {
      'reference': 'OFF-HHGIntlAirBBAcmp-INS',
      'icon': 'illustration-airShipmentFamily.svg'
    },
    {
      'reference': 'OFF-DrvrsLcsnsExchBBIntl',
      'icon': 'illustration-licenseExchange.svg'
    },
    {
      'reference': 'OFF-DestSvcsLTBndlBB',
      'icon': 'illustration-servicesBundle.svg'
    },
    {
      'reference': 'OFF-CndtAssmtCoreBB',
      'icon': 'illustration-candidateAssessment.svg'
    },
    {
      'reference': 'OFF-CndtAssmtFlxBB',
      'icon': 'illustration-candidateAssessment.svg'
    },
    {
      'reference': 'OFF-DestSvcsLTDepBndlBB',
      'icon': 'illustration-servicesBundle.svg'
    },
    {
      'reference': 'OFF-HHGIntlFlxBB',
      'icon': 'illustration-hhgProMove.svg'
    },
    {
      'reference': 'OFF-EdAssistBB',
      'icon': 'illustration-educationAssistance.svg'
    },
    {
      'reference': 'OFF-CltrlTrngExtSuppBB',
      'icon': 'illustration-culturalTrainingExt.svg'
    },
    {
      'reference': 'OFF-BusnEnglWrtngBBIntl20',
      'icon': 'illustration-businessEnglishWriting.svg'
    },
    {
      'reference': 'OFF-HstHsngAllwncSuppBBIntlCr',
      'icon': 'illustration-HostHousingAllowance.svg'
    },
    {
      'reference': 'OFF-CashOutBBAdv',
      'icon': 'illustration-cashOut.svg'
    },
    {
      'reference': 'OFF-EduSuppBBIntl',
      'icon': 'illustration-dependentEdSupport.svg'
    },
    {
      'reference': 'OFF-FrnRntlBBIntl',
      'icon': 'illustration-furnitureRental.svg'
    },
    {
      'reference': 'OFF-FurnAllowIntl',
      'icon': 'illustration-furnitureAllowance.svg'
    },
    {
      'reference': 'OFF-SchlFndgEdCmplBBIntl1Day',
      'icon': 'illustration-EducationComplete.svg'
    },
    {
      'reference': 'OFF-SchlFndgEdCmplBBIntl2Day',
      'icon': 'illustration-EducationComplete.svg'
    },
    {
      'reference': 'OFF-PetTrnsprtAllwncBB',
      'icon': 'illustration-petTransport.svg'
    },
    {
      'reference': 'OFF-AutoDispBndlBB',
      'icon': 'illustration-carDisposition.svg'
    },
    {
      'reference': 'OFF-HHGIntlSeaBB-INS',
      'icon': 'illustration-seaShipment.svg'
    },
    {
      'reference': 'OFF-HHGIntlSea20BB-INS',
      'icon': 'illustration-seaShipment.svg'
    },
    {
      'reference': 'OFF-HHGIntlSea40BB-INS',
      'icon': 'illustration-seaShipment.svg'
    },
    {
      'reference': 'OFF-HHGIntlFlxBB-INS',
      'icon': 'illustration-hhgProMove.svg'
    },
    {
      'reference': 'OFF-PetTrnsprtBBIntlCore',
      'icon': 'illustration-petTransportReimbursement.svg'
    },
    {
      'reference': 'OFF-LsTermIntlCoreBB',
      'icon': 'illustration-leaseTermination.svg'
    },
    {
      'reference': 'OFF-NonAccompDepTrvlReimbBBIntl',
      'icon': 'illustration-dependentTravel.svg'
    },
    {
      'reference': 'OFF-EduSuppReimbBBIntl',
      'icon': 'illustration-dependentEdSupport.svg'
    },
    {
      'reference': 'OFF-PropMaintBBIntl',
      'icon': 'illustration-propertyManagement.svg'
    },
    {
      'reference': 'OFF-AutoLeaseBBIntl',
      'icon': 'illustration-carRental.svg'
    },
    {
      'reference': 'OFF-AutoDispBBIntl',
      'icon': 'illustration-leaseTermination.svg'
    },
    {
      'reference': 'OFF-DsrdDntFlxBB',
      'icon': 'illustration-HHGDonate.svg'
    },
    {
      'reference': 'OFF-HHGFlxBB-INS',
      'icon': 'illustration-hhgProMove.svg'
    },
    {
      'reference': 'OFF-ChildCareReimbBBIntl',
      'icon': 'illustration-childCareReimbursement.svg'
    },
    {
      'reference': 'OFF-DrvrsLicenseReimbBBIntl',
      'icon': 'illustration-licenseExchange.svg'
    },
    {
      'reference': 'OFF-OngoingAllowBBIntl',
      'icon': 'illustration-expatPremium.svg'
    },
    {
      'reference': 'OFF-FamSocAsstBBIntl',
      'icon': 'illustration-familyAssistance.svg'
    },
    {
      'reference': 'OFF-HomeCtryParentReimbBBIntl',
      'icon': 'illustration-parentReimbursement.svg'
    },
    {
      'reference': 'OFF-LngTrngBBIntlHybrd',
      'icon': 'illustration-languageTraining.svg'
    },
    {
      'reference': 'OFF-LngTrngBBIntlHybrdDep',
      'icon': 'illustration-languageTraining.svg'
    },
    {
      'reference': 'OFF-LngTrngBBIntlHybrdSpouse',
      'icon': 'illustration-languageTraining.svg'
    },
    {
      'reference': 'OFF-SlfStrgReimbBBIntl',
      'icon': 'illustration-selfStorage.svg'
    },
    {
      'reference': 'OFF-ParentReimbBBIntl',
      'icon': 'illustration-singleParentSupport.svg'
    },
    {
      'reference': 'OFF-CareerTransitionReimbBBIntl',
      'icon': 'illustration-partnerCareerSupport.svg'
    },  
    {
      'reference': 'OFF-ApplAllowBB',
      'icon': 'illustration-appliances.svg'
    },
    {
      'reference': 'OFF-ChildCareBndlBBIntl',
      'icon': 'illustration-childCareReimbursement.svg'
    },
    {
      'reference': 'OFF-CompletionBonusBB',
      'icon': 'illustration-completionBonus.svg'
    },
    {
      'reference': 'OFF-DangerPayBB',
      'icon': 'illustration-hardshipPremium.svg'
    },
    {
      'reference': 'OFF-FamilyAllowBB',
      'icon': 'illustration-parentReimbursement.svg'
    },
    {
      'reference': 'OFF-AirfareUpgrdBusnClsBBIntl',
      'icon': 'illustration-airfareUpgrade.svg'
    },
    {
      'reference': 'OFF-HouseFittingBB',
      'icon': 'illustration-HostHousingAllowance.svg'
    },
    {
      'reference': 'OFF-HouseHuntUpgrdBB',
      'icon': 'illustration-previewTrip.svg'
    },
    {
      'reference': 'OFF-LangTrainSpouseBBIntl',
      'icon': 'illustration-languageTraining.svg'
    },
    {
      'reference': 'OFF-MobPremBB',
      'icon': 'illustration-relocationAllowance.svg'
    },
    {
      'reference': 'OFF-PropMgmtBB',
      'icon': 'illustration-halfDayServices.svg'
    },
    {
      'reference': 'OFF-UtilAllowBB',
      'icon': 'illustration-UtilityAllowance.svg'
    },
    {
      'reference': 'OFF-VIPTaxBBIntl',
      'icon': 'illustration-taxBriefing.svg'
    },
    {
      'reference': 'OFF-PrvwTrpReimbBB',
      'icon': 'illustration-previewTrip.svg'
    },
    {
      'reference': 'OFF-FinalTripReimbBB',
      'icon': 'illustration-FinalTransport.svg'
    },
    {
      'reference': 'OFF-ReturnTrpReimbBB',
      'icon': 'illustration-returnTrip.svg'
    },
    {
      'reference': 'OFF-GoWiderGoFarBB',
      'icon': 'illustration-goWiderGoFarther.svg'
    },
    {
      'reference': 'OFF-LsNegtnBB',
      'icon': 'illustration-leaseNegotiation.svg'
    },
    {
      'reference': 'OFF-TravelCoordBB',
      'icon': 'illustration-travelCoordination.svg'
    },
    {
      'reference': 'OFF-TravelContributBndlBB',
      'icon': 'illustration-returnTrip.svg'
    },
    {
      'reference': 'OFF-DupHousingReimbBB',
      'icon': 'illustration-duplicateHousisng.svg'
    },
    {
      'reference': 'OFF-HmFndngReimbBB',
      'icon': 'illustration-homeFinding.svg'
    },
    {
      'reference': 'OFF-TaxAssistBB',
      'icon': 'illustration-taxPrep.svg'
    },
    {
      'reference': 'OFF-IncentRentBB',
      'icon': 'illustration-incentToRent.svg'
    },
    {
      'reference': 'OFF-AcclimationSvcsBB',
      'icon': 'illustration-partnerCareerSupport.svg'
    },
    {
      'reference': 'OFF-SpouseAcclimationSvcsBB',
      'icon': 'illustration-partnerCareerSupport.svg'
    },
    {
      'reference': 'OFF-MarketAsstBB',
      'icon': 'illustration-marketingAssistance.svg'
    },
    {
      'reference': 'OFF-PutAwayQuickBB',
      'icon': 'illustration-putAwaySvc.svg'
    },
    {
      'reference': 'OFF-CleanSvcBB',
      'icon': 'illustration-cleaningSvc.svg'
    },
    {
      'reference': 'OFF-WineShpmtBndlBB',
      'icon': 'illustration-wineShipment.svg'
    },
    {
      'reference': 'OFF-HHGBB-INS',
      'icon': 'illustration-hhgProMove.svg'
    }
  ];
  