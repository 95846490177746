<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle"> {{ formTitle }}</div>
        <a href="#" class="close-icon" (click)="onDismiss($event)" [ngClass]="{'disable':formDisable==true}" title="close">
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <form fxLayout="column" class="middle-section" [formGroup]="addCandidateForm" *ngIf="addCandidateForm">
        <div class="middle-section">
            <mat-dialog-content>
                <div>
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em" formGroupName="nameDetails">
                        <!--Title field-->
                        <div fxFlex="10%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Title</mat-label>
                                <mat-select [disabled] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" formControlName="title">
                                    <mat-option *ngFor="let title of titleValues" [value]="title">{{title}}</mat-option>
                                </mat-select>
                                <mat-error>
                                    {{ getErrorMessage('nameDetails.title', 'Title') }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--First Name field-->
                        <div fxFlex="40%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <input matInput placeholder="First Name" [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" character-space-only formControlName="firstName" minLength="1" maxlength="50">
                                <mat-error>
                                    {{ getErrorMessage('nameDetails.firstName', 'First Name') }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <!--Last Name field-->
                        <div fxFlex="40%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <input matInput placeholder="Last Name" [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" character-space-only formControlName="lastName" minLength="2" maxlength="50">
                                <mat-error>
                                    {{ getErrorMessage('nameDetails.lastName', 'Last Name') }}
                                </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <!--new implementation for email and phone-->
                    <mat-accordion>
                        <mat-expansion-panel>
                            <mat-expansion-panel-header class='panel-heading'>
                                Contact Details
                            </mat-expansion-panel-header>
                            <div formArrayName="email">
                                <div *ngFor="let emailDetail of addCandidateForm.get('email').controls; last as islast; let index = index">
                                    <div [formGroupName]='index'>
                                        <div class="input-row" class="addcandidate-toppadding" fxLayout="row" fxLayoutAlign="space-between"
                                            fxLayoutGap="0.5em" fxLayout.xs="column" fxLayoutGap.xs="0em">
                                            <!--Email Address field-->
                                            <div fxFlex="60%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                                <mat-form-field fxFlex>
                                                    <input matInput type="text" placeholder="Email Address"
                                                        [readonly]="userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist'"
                                                        formControlName="emailAddress">
                                                    <mat-error>
                                                        {{ getErrorMessage('email', 'Email Address') }}
                                                    </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="25%" fxLayout.xs="row" fxFlex.xs="100%" fxFlexFill>
                                                <mat-form-field>
                                                    <mat-label>Type</mat-label>
                                                    <mat-select formControlName="usageType" (click)="getEmailTypes(index)"
                                                        [disabled]="userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist'">
                                                        <mat-option *ngFor="let email of emailTypes[index]; let emailIndex = index"
                                                            [value]="email">{{email}}</mat-option>
                                                    </mat-select>
                                                    <mat-error> {{ getErrorMessage('email', 'email type') }} </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="5%" fxLayout="row" fxFlexFill>
                                                <button mat-icon-button role="button"
                                                    [ngClass]="this.addCandidateForm.controls.email.controls.length === 1?'mat-delete-btn disabled-delete':'mat-delete-btn'"
                                                    (click)="deleteRow('email', index, addCandidateForm)"
                                                    [disabled]="this.addCandidateForm.controls.email.controls.length === 1">
                                                    <mat-icon class="delete-icomn-style">delete_outline</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div> <button mat-icon-button role="button"
                                                *ngIf = "islast && this.addCandidateForm.controls.email.controls.length !== 2"
                                                [disabled] = "this.addCandidateForm.controls.email.invalid"
                                                (click)="addNewRow('email', (index + 1), addCandidateForm)"
                                                class="mat-button outlined-button add-button"> + Add </button> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div formArrayName="phone">
                                <div *ngFor="let phoneDetail of addCandidateForm.get('phone').controls; last as islast; let index = index">
                                    <div [formGroupName]='index'>
                                        <div fxLayout.xs="row" fxFlex.xs="100%" fxLayout="row" fxFlexFill fxLayoutGap="0.5em"
                                            fxLayoutAlign="space-between">
                                            <div fxFlex="15%" fxFlex.xs="30%">
                                                <mat-form-field>
                                                    <mat-label>Phone Code </mat-label>
                                                    <input type="text" aria-label="Phone Code" matInput #autoCompleteInput
                                                        formControlName="phoneDialCode" [matAutocomplete]="autoPhoneCode"
                                                        [readonly]="disableAllFields || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                                    <mat-autocomplete #autoPhoneCode="matAutocomplete" [displayWith]="displayFn" [panelWidth]="293">
                                                        <mat-option [value]="option.countryDialingCode"
                                                            *ngFor="let option of filteredOptions | async">
                                                            {{ option.countryName }} (+ {{ option.countryDialingCode }})
                                                        </mat-option>
                                                    </mat-autocomplete>
                                                    <mat-error>{{ getErrorMessage('phone', 'Phone Code') }}</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="58%" fxFlex.xs="70%">
                                                <mat-form-field fxFlex>
                                                    <input matInput type="text" placeholder="Phone Number"
                                                        [readonly]="userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist'"
                                                        number-only formControlName="phoneNumber" minLength="7" maxlength="18">
                                                    <mat-error> {{ getErrorMessage('phone', 'Mobile #') }}</mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="27%" fxLayout="row" fxFlexFill>
                                                <mat-form-field>
                                                    <mat-label>Type</mat-label>
                                                    <mat-select formControlName="type" (click)="getPhoneTypes(index)">
                                                        <mat-option  *ngFor="let phone of phoneTypes[index]; let phoneIndex = index"
                                                            [value]="phone">{{phone}}
                                                        </mat-option>
                                                    </mat-select>
                                                    <mat-error> {{ getErrorMessage('phone', 'phone type') }} </mat-error>
                                                </mat-form-field>
                                            </div>
                                            <div fxFlex="5%" fxLayout="row" fxFlexFill>
                                                <button mat-icon-button role="button"
                                                    [ngClass]="this.addCandidateForm.controls.phone.controls.length === 1?'mat-delete-btn disabled-delete':'mat-delete-btn'"
                                                    (click)="deleteRow('phone', index, addCandidateForm)"
                                                    [disabled]="this.addCandidateForm.controls.phone.controls.length === 1">
                                                    <mat-icon class="delete-icomn-style">delete_outline</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                        <div> <button mat-icon-button role="button"
                                            *ngIf = "islast && this.addCandidateForm.controls.phone.controls.length !== 5"
                                            [disabled] = "this.addCandidateForm.controls.phone.invalid"
                                            (click)="addNewRow('phone', (index + 1), addCandidateForm)"
                                            class="mat-button outlined-button add-button"> + Add </button></div>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="addcandidate-toppadding" formGroupName="addressForm" class="location-details" fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                        <!-- <div fxFlex="50%" fxLayoutAlign="start" fxLayout="column" fxFlex.xs="40%" formGroupName="departureAddr"> -->
                        <mat-card fxFlex="45%" fxLayoutAlign="start" fxLayout="column" fxFlex.xs="40%" formGroupName="departureAddr" class="address">
                            <mat-card-title class="custom card-title" fxLayout="row" fxLayoutAlign="start center">
                                <img src="../../assets/images/arrival.svg" alt="arrival" class="arrival-icon" /> Departure Location
                            </mat-card-title>
                            <mat-card-content>
                                <div fxLayout="column" class="m-t-10">
                                    <mat-form-field>
                                        <mat-label>Country</mat-label>
                                        <input type="text" aria-label="Country"
                                            matInput #autoCompleteCountryInput
                                            formControlName="country"
                                            [matAutocomplete]="autoCountry" (blur)="onFocusOutDepartureCountryCode()"  [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                    <mat-autocomplete  #autoCountry="matAutocomplete" [panelWidth] = "293">
                                        <mat-option [value]="option.countryName" *ngFor="let option of filteredCountryOptions | async">
                                            {{ option.countryName }}
                                        </mat-option>
                                      </mat-autocomplete>
                                        <!--<mat-select formControlName='country' [disabled] = "userRole !== 'client-contact-initiator'">
                                            <mat-option *ngFor="let country of countryList" [value]="country.countryName">
                                                {{ country.countryName }}</mat-option>
                                        </mat-select> -->
                                        <mat-error>
                                            {{ getErrorMessage('addressForm.departureAddr.country', 'Country') }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>

                               

                                <div fxLayout="column" class="m-t-10" formArrayName="streets">
                                    <div *ngFor="let street of streets.controls; index as idx">
                                        <mat-form-field *ngIf = "!(this.editCandidateProfile?.moveStatus === 'Deactivated')">
                                            <mat-label>Street Address</mat-label>
                                            <input matInput placeholder="Street Address (Optional)"
                                            matGoogleMapsAutocomplete
                                            [country]="departCountryCode"                                        
                                            type="address"
                                            (onAutocompleteSelected)="onAutocompleteSelected($event)"
                                            [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                            [formControlName]="idx">
                                        </mat-form-field>
                                        <mat-form-field *ngIf = "(this.editCandidateProfile?.moveStatus === 'Deactivated')">
                                            <mat-label>Street Address</mat-label>
                                            <input matInput placeholder="Street Address (Optional)"
                                            [country]="departCountryCode"                                        
                                            type="address"
                                            [readonly] = "(this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Deactivated') || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist')"
                                            [formControlName]="idx">
                                        </mat-form-field>
                                    </div>
                                </div>

                              

                                <div fxLayout="column" class="m-t-10" formArrayName="cities">
                                    <div *ngFor="let city of cities.controls; index as cityIdx">
                                    <mat-form-field *ngIf="hasDepartureStreet">
                                        <mat-label>City</mat-label>
                                        <input matInput  [formControlName]="cityIdx" [readonly]="disableAllFields || !hasDepartureCountry || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                         placeholder="City"/>
                                      
                                        <mat-error>
                                            {{ getErrorMessage('addressForm.departureAddr.cities', 'City',0) }}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field *ngIf="!hasDepartureStreet">
                                        <mat-label>City</mat-label>                                       
                                         <input  matInput 
                                         matGoogleMapsAutocomplete
                                         [country]="departCountryCode"
                                         type="cities"
                                         (onAutocompleteSelected)="onDepartureCityAutocompleteSelected($event)"
                                         [formControlName]="cityIdx" [readonly]="disableAllFields || !hasDepartureCountry || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                          placeholder="City">                                       
                                        <mat-error>
                                            {{ getErrorMessage('addressForm.departureAddr.cities', 'City',0) }}
                                        </mat-error>
                                    </mat-form-field>
                                    </div>
                                </div>

                                <div fxLayout="row" class="m-t-10" >
                                    <div formArrayName="states" fxFlex="50%">
                                        <div *ngFor="let state of states.controls; index as stateIdx"> 
                                            <mat-form-field class="state-fields">
                                                <mat-label>State/Province</mat-label>
                                                <input matInput [formControlName]="stateIdx" *ngIf="hasDepartureStreet || hasDepartureCity"
                                                [readonly]="disableAllFields || !hasDepartureCountry || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                                placeholder="State"/>
                                                <input matInput [formControlName]="stateIdx" *ngIf="!hasDepartureStreet && !hasDepartureCity"
                                                matGoogleMapsAutocomplete
                                                [country]="departCountryCode"
                                                type="region"
                                                (onAutocompleteSelected)="onDepartureStateAutocompleteSelected($event)"
                                                (onLocationSelected)="onLocationSelected($event)"
                                                [readonly]="disableAllFields || !hasDepartureCountry || userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist' && editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                placeholder="State"/>
                                                
                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.departureAddr.states', 'State',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div formArrayName="postalCodes" fxFlex="45%">
                                        <div *ngFor="let postal of postalCodes.controls; index as postalIdx"> 
                                            <mat-form-field class="postal-code-fields">
                                                <mat-label>Postal Code</mat-label>
                                                <input matInput placeholder="Postal Code" *ngIf="hasDepartureStreet ||hasDepartureCity || hasDepartureState"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" [formControlName]="postalIdx" minLength= "1" maxlength="15" [formControlName]="postalIdx" minLength= "1" maxlength="15"/>

                                                <input matInput placeholder="Postal Code" *ngIf="!hasDepartureStreet && !hasDepartureCity && !hasDepartureState"
                                                matGoogleMapsAutocomplete
                                                [country]="departCountryCode"
                                                type="region"
                                                (onAutocompleteSelected)="onDeparturePostalCodeAutocompleteSelected($event)"
                                                (onLocationSelected)="onLocationSelected($event)"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" [formControlName]="postalIdx" minLength= "1" maxlength="15">
                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.departureAddr.postalCodes', 'Postal Code',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>

                                <!-- <div fxLayout="column" class="m-t-10">
                                    
                                </div> -->

                                
                            </mat-card-content>
                        </mat-card>

                        <!-- </div> -->

                        <div fxFlex="5%" fxShow="true" fxHide.xs fxLayoutAlign="center center">
                            <mat-icon class="material-icons modal-rightnavarrow">chevron_right</mat-icon>
                        </div>
                        <mat-card class="address" fxFlex="45%" fxFlex.xs="40%" fxLayout="column" formGroupName="destinationAddr">
                            <mat-card-title class="card-title p-t-40 p-l-0" fxLayout="row" fxLayoutAlign="start center">
                                <img src="../../assets/images/departure.svg" alt="departure" class="departure-icon" /> Destination Location
                            </mat-card-title>
                            <mat-card-content>
                                <div fxLayout="column" class="m-t-10">
                                    <mat-form-field>
                                        <mat-label>Country</mat-label>
                                        <input type="text" aria-label="Country"
                                            matInput #autoCompleteDestCountryInput
                                            formControlName="country" (blur)="onFocusOutDestinationCountryCode()"
                                            [matAutocomplete]="autoDestCountry"  [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                        <mat-autocomplete  #autoDestCountry="matAutocomplete" [panelWidth] = "293">
                                            <mat-option [value]="option.countryName" *ngFor="let option of filteredDestCountryOptions | async">
                                                {{ option.countryName }}
                                            </mat-option>
                                        </mat-autocomplete>
                                        <!--<mat-select formControlName='country' [disabled] = "userRole !== 'client-contact-initiator'">
                                            <mat-option *ngFor="let country of countryList" [value]="country.countryName">
                                                {{ country.countryName }}</mat-option>
                                        </mat-select> -->
                                        <mat-error>
                                            {{ getErrorMessage('addressForm.destinationAddr.country', 'Country') }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                               
                                <div fxLayout="column" class="m-t-10" formArrayName="destiStreets">
                                    <div *ngFor="let street of destiStreets.controls; index as destiStreetIdx"> 
                                        <mat-form-field *ngIf = "!(this.editCandidateProfile?.moveStatus === 'Deactivated')">
                                            <mat-label>Street Address</mat-label>
                                            <input matInput placeholder="Street Address (Optional)" 
                                            matGoogleMapsAutocomplete
                                            [country]="destiCountryCode"                                        
                                            type="address"
                                            (onAutocompleteSelected)="onDestinationStreetAutocompleteSelected($event)"                                        
                                            [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" [formControlName]="destiStreetIdx">
                                        </mat-form-field>
                                        <mat-form-field *ngIf = "(this.editCandidateProfile?.moveStatus === 'Deactivated')">
                                            <mat-label>Street Address</mat-label>
                                            <input matInput placeholder="Street Address (Optional)" 
                                            [country]="destiCountryCode"                                        
                                            type="address"
                                            [readonly] = "(this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Deactivated') || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist')" 
                                            [formControlName]="destiStreetIdx">
                                        </mat-form-field>
                                    </div>
                                </div>
                                

                                <div fxLayout="column" class="m-t-10" formArrayName="destiCities">
                                    <div *ngFor="let city of destiCities.controls; index as destiCityIdx"> 
                                        <mat-form-field>
                                            <mat-label>City</mat-label>
                                            <input matInput [formControlName]="destiCityIdx" *ngIf="hasDestinationStreet"
                                            [readonly]="disableAllFields || !hasDestinationCountry || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist')  || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"  
                                            placeholder="City"/>
                                            <input matInput [formControlName]="destiCityIdx" *ngIf="!hasDestinationStreet"
                                            matGoogleMapsAutocomplete
                                            [country]="destiCountryCode"                                        
                                            type="cities"
                                            (onAutocompleteSelected)="onDestinationCityAutocompleteSelected($event)"
                                            [readonly]="disableAllFields || !hasDestinationCountry || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                            placeholder="City"/>
                                            
                                            <mat-error>
                                                {{ getErrorMessage('addressForm.destinationAddr.destiCities', 'City',0) }}
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>

                                
                                <div fxLayout="row" class="m-t-10" >
                                    <div formArrayName="destiStates" fxFlex="50%">
                                        <div *ngFor="let state of destiStates.controls; index as destiStateIdx"> 
                                            <mat-form-field class="state-fields">
                                                <mat-label>State/Province</mat-label>
                                                <input matInput [formControlName]="destiStateIdx" *ngIf="hasDestinationStreet || hasDestinationCity"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent'  || editCandidateProfile?.moveStatus == 'Authorization Initiated' || !hasDestinationCountry || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                placeholder="State"/>
                                                <input matInput [formControlName]="destiStateIdx" *ngIf="!hasDestinationStreet && !hasDestinationCity"
                                                matGoogleMapsAutocomplete
                                                [country]="destiCountryCode"                                        
                                                type="region"
                                                (onAutocompleteSelected)="onDestinationStateAutocompleteSelected($event)"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || !hasDestinationCountry || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                placeholder="State"/>

                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.destinationAddr.destiStates', 'State',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div formArrayName="destiPostalCodes" fxFlex="45%">
                                        <div *ngFor="let postalCode of destiPostalCodes.controls; index as destiPostalCodeIdx"> 
                                            <mat-form-field class="postal-code-fields">
                                                <mat-label>Postal Code</mat-label>
                                                <input matInput placeholder="Postal Code" *ngIf="hasDestinationStreet || hasDestinationCity || hasDestinationState"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                                [formControlName]="destiPostalCodeIdx" minLength= "1" maxlength="15"/>
                                                <input matInput placeholder="Postal Code" *ngIf="!hasDestinationStreet && !hasDestinationCity && !hasDestinationState"
                                                matGoogleMapsAutocomplete
                                                [country]="destiCountryCode"                                      
                                                type="region"
                                                (onAutocompleteSelected)="onDestinationPostalCodeAutocompleteSelected($event)"
                                                [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                                [formControlName]="destiPostalCodeIdx" minLength= "1" maxlength="15"/>

                                                <mat-error>
                                                    {{ getErrorMessage('addressForm.destinationAddr.destiPostalCodes', 'Postal Code',0) }}
                                                </mat-error>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div fxLayout="column" class="m-t-10">
                                   
                                </div> -->
                            </mat-card-content>
                        </mat-card>
                    </div>
                    

                </div>
                <div>
                    <!-- <div formGroupName="programDetails"> -->
                    <!-- <div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="1em" class="addcandidate-toppadding" fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                        <mat-form-field fxFlex>
                            <mat-label>Division</mat-label>
                            <mat-select *ngIf = "userRole !== 'file-setup-specialist'" formControlName="divisionPartyId" [disabled] = "(this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Deactivated') || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Withdrawn')">
                                <mat-option   *ngFor="let division of divisionValues" [value]="division.cc_division_id" (onSelectionChange)="getProgram($event.value)">{{division.cc_division_name}} ({{ division.cc_division_number }})</mat-option>
                            </mat-select>
                            <mat-select *ngIf = "userRole === 'file-setup-specialist'" formControlName="divisionPartyId" [disabled] = "(userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Withdrawn')">
                                    <mat-option *ngFor="let division of divisionValues" [value]="division.division_id" (onSelectionChange)="getProgram($event.value)">{{division.division_name}} ({{ division.division_number }})</mat-option>
                                </mat-select>
                            <mat-error>
                                {{ getErrorMessage('divisionPartyId', 'Division') }}
                            </mat-error>
                        </mat-form-field>
                    </div> -->
                
                    <div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="1em" class="addcandidate-toppadding"
                        fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                        <mat-form-field fxFlex>
                            <mat-label>Division</mat-label>
                            <mat-select *ngIf = "userRole !== 'file-setup-specialist'" formControlName="divisionPartyId" [disabled] = "disableAllFields || ((userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))" (selectionChange)="getProgram($event.value)">
                                <mat-option   *ngFor="let division of divisionValues" [value]="division.cc_division_id">{{division.cc_division_name}} ({{ division.cc_division_number }})</mat-option>
                            </mat-select>
                            <mat-select *ngIf = "userRole === 'file-setup-specialist'" formControlName="divisionPartyId" [disabled] = "((userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated')" (selectionChange)="getProgram($event.value)">
                                <mat-option *ngFor="let division of divisionValues" [value]="division.division_id">{{division.division_name}} ({{ division.division_number }})</mat-option>
                            </mat-select>
                            <mat-error>
                                {{ getErrorMessage('divisionPartyId', 'Division') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <!-- <div  fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="addcandidate-toppadding" fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px" > -->
                        <div formGroupName="programDetails" *ngIf="!isTraditional" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="addcandidate-toppadding"
                        fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                        <mat-form-field fxFlex.gt-md="60">
                            <mat-label>Assign Program</mat-label>
                            <!-- <mat-select [disabled]="!(addCandidateForm.value.programDetails?.divisionPartyId) || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Withdrawn')" formControlName="programName"  (selectionChange)= "setPoints($event)"> -->
                                    <mat-select [disabled] = "(this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Deactivated')" formControlName="programName"  (selectionChange)= "setPoints($event)">
                                    <mat-option  *ngFor="let program of programValues" [value]="program.programName">{{program.programName}}</mat-option>
                            </mat-select>
                            <mat-error>
                                {{ getErrorMessage('programDetails.programName', 'Program') }}
                            </mat-error>
                        </mat-form-field>
                        <mat-card *ngIf = "points || addCandidateForm.value.programDetails?.points" fxFlex="25%" fxLayout="column" fxLayoutAlign="center center" class="mat-card-chip mat-card-point" 
                        [ngClass] = "(userRole != 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move') || data?.moveStatus === 'Withdrawn' ? 'mat-card-point-disabled' : 'mat-card-point'" >Points Assigned:
                            <div class="points-container" fxLayout="row" fxLayoutAlign="space-around stretch">
                                <span *ngIf = '!editPoint' fxFlex="80%" fxLayoutAlign="center center"> {{addCandidateForm.value.programDetails?.points}} </span>  
                                <input matInput class = 'points' number-only fxFlex="80%" fxLayoutAlign="center center" formControlName="points" *ngIf = 'editPoint' [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                <mat-icon *ngIf = "((userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist') && (editCandidateProfile?.moveStatus != 'Invitation Sent' && editCandidateProfile?.moveStatus != 'Authorization Initiated' && editCandidateProfile?.moveStatus != 'Requested' && this.editCandidateProfile?.moveStatus != 'Approved' && this.editCandidateProfile?.moveStatus != 'Not Approved' && editCandidateProfile?.moveStatus != 'CP In Progress' && data?.moveStatus != 'Withdrawn'))" class="edit" (click)="editPointValidation()" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }">edit</mat-icon> 
                            </div>
                            <mat-error>
                            {{ editData || this.addCandidateForm?.get('programDetails.points').touched ? getErrorMessage('programDetails.points', 'Points') : '' }}
                        </mat-error>
                        </mat-card>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutGap="1em" class="addcandidate-toppadding"
                        fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                        <mat-form-field fxFlex>
                            <mat-label>Assign Policy</mat-label>
                            <mat-select formControlName="assignedPolicy"
                            [disabled]="disableAllFields || ((userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <mat-option *ngFor="let policy of policyValues" [value]="policy">{{policy}}</mat-option>
                            </mat-select>
                            <mat-error>
                                {{ getErrorMessage('assignedPolicy', 'Policy') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div  *ngIf="isTraditional" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="1em" class="addcandidate-toppadding"
                        fxLayout.xs="column" fxLayoutGap="1em" fxLayoutGap.xs="0px">
                        <mat-form-field fxFlex.gt-md="60">
                            <mat-label>Executive Officer</mat-label>
                            <mat-select formControlName="executiveOfficer"
                            [disabled]="disableAllFields || ((userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move') )">
                                <mat-option value="Yes">Yes</mat-option>
                                <mat-option value="No">No</mat-option>
                            </mat-select>
                            <mat-error>
                                {{ getErrorMessage('executiveOfficer', 'Executive Officer') }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <div *ngIf="isTraditional">
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                        class="addcandidate-toppadding">
                        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Delivery Team Model</mat-label>
                                <mat-select formControlName="deliveryTeamModel"
                                    [disabled]="disableAllFields || ((editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                    <mat-option *ngFor="let teamModel of deliveryTeamModelValues" [value]="teamModel">{{teamModel}}</mat-option>
                                </mat-select>
                                <mat-error> {{ getErrorMessage('deliveryTeamModel', 'Delivery Team Model') }} </mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                        class="addcandidate-toppadding">
                        <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Client Authorization Submit Date</mat-label>
                                <input matInput [matDatepicker]="cliAuthSubDt" formControlName="cliAuthSubDt"
                                    [readonly]="disableAllFields || ((editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <mat-datepicker-toggle matSuffix [for]="cliAuthSubDt"
                                    [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' ||  (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                </mat-datepicker-toggle>
                                <mat-datepicker #cliAuthSubDt></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Client Authorization Submit Time</mat-label>
                                <input matInput [ngxTimepicker]="cliAuthSubTime" formControlName="cliAuthSubTime"
                                    [readonly]="disableAllFields  || ((editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <ngx-material-timepicker-toggle matSuffix [for]="cliAuthSubTime" class="timepicker-style"
                                    [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' ||  (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                </ngx-material-timepicker-toggle>
                                <ngx-material-timepicker #cliAuthSubTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                        class="addcandidate-toppadding">
                        <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Authorization Ready to Process Date</mat-label>
                                <input matInput [matDatepicker]="authReadytoProcessDt" formControlName="authReadytoProcessDt"
                                    [readonly]="disableAllFields || ((editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <mat-datepicker-toggle matSuffix [for]="authReadytoProcessDt"
                                    [disabled]="disableAllFields || ((editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                </mat-datepicker-toggle>
                                <mat-datepicker #authReadytoProcessDt></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Authorization Ready to Process Time</mat-label>
                                <input matInput [ngxTimepicker]="authReadytoProcessTime" formControlName="authReadytoProcessTime"
                                    [readonly]="disableAllFields || ((editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                <ngx-material-timepicker-toggle matSuffix [for]="authReadytoProcessTime" class="timepicker-style"
                                    [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' ||  (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                </ngx-material-timepicker-toggle>
                                <ngx-material-timepicker #authReadytoProcessTime></ngx-material-timepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                        class="addcandidate-toppadding">
                        <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Time zone</mat-label>
                                <mat-select formControlName="timeZone"
                                    [disabled]="disableAllFields || ((editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                    <mat-option [value]="zone" *ngFor="let zone of timeZoneValues">{{zone}}</mat-option>
                                </mat-select>
                                <mat-error> {{ getErrorMessage('timeZone', 'Time zone')}} </mat-error>
                            </mat-form-field>
                        </div>
                        <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Initial Contact By</mat-label>
                                <mat-select formControlName="initialContactBy"
                                    [disabled]="disableAllFields || ((editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                    <mat-option [value]="contactBy" *ngFor="let contactBy of initialContactByValues">{{contactBy}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div> 

                <mat-accordion>
                    <mat-expansion-panel formGroupName="authorizationDetails">
                        <mat-expansion-panel-header class='panel-heading'>
                            Authorization Details
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                            <div fxFlex fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Client Contact Initiator</mat-label>
                                    <input type="text"
                                    aria-label="Client Contact Initiator"
                                    matInput #autoCompleteCci
                                    formControlName="cciDetails"
                                    [matAutocomplete]="auto" [readonly]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                    <mat-autocomplete  #auto="matAutocomplete" [displayWith]="displayClientContactFn" [readonly]="editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                        <mat-option [value]="cci" *ngFor="let cci of cciValues ">
                                            {{ cci.name }}
                                        </mat-option>
                                    </mat-autocomplete>
                                    <mat-error> {{ getErrorMessage('authorizationDetails.cciDetails', 'Client Contact
                                        Initiator') }} </mat-error>
                                        <mat-icon class="drop-down-client">arrow_drop_down</mat-icon>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">

                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Contract Name</mat-label>
                                    <mat-select formControlName="contractDetails" [compareWith]="compareContractData" (selectionChange)="getContractBaseDetails($event)"
                                        [disabled]="disableAllFields || ((editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                        <mat-option [value]="contractData.contractDetails" *ngFor="let contractData of contractList">
                                            {{contractData?.contractDetails?.baseContract}}  {{contractData?.contractDetails?.contractId}}   {{contractData?.contractDetails?.contractName}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error> {{ getErrorMessage('authorizationDetails.contractDetails', 'contractDetails') }} </mat-error>
                                </mat-form-field>
                            </div>

                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Billing Currency</mat-label>
                                    <input type="text"
                                    aria-label="currency"
                                    matInput #autoCompleteCurr
                                    formControlName="currency"
                                    [matAutocomplete]="autoCurrency" [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                    <mat-autocomplete #autoCurrency ="matAutocomplete" [displayWith]="displayCurrencyFn" >
                                        <mat-option [value]="curr.currency" *ngFor="let curr of filteredCurrency | async">
                                            {{curr.currency?.code}}- {{curr.currency?.description}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" *ngIf="isTraditional"  fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Move Type</mat-label>
                                    <mat-select formControlName="moveType"
                                        [disabled]="disableAllFields || ((editCandidateProfile && editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus === 'Withdrawn') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move'))">
                                        <mat-option *ngFor="let move of moveTypeValues" [value]="move">{{move}}</mat-option>
                                    </mat-select>
                                    <mat-error> {{ getErrorMessage('authorizationDetails.moveType', 'Move Type') }} </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
                <!-- <mat-accordion>
                    <mat-expansion-panel formGroupName="additionalDetails">
                        <mat-expansion-panel-header class='panel-heading'>
                            Additional Details
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Cost Center</mat-label>
                                    <input matInput  characnumspl-only [readonly] = "(this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Deactivated') || userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist'" formControlName="costCenter" maxlength="50" >
                                    <mat-error> {{ getErrorMessage('additionalDetails.costCenter', 'Cost Center') }} </mat-error>

                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>PO Billing Invoice #</mat-label>
                                    <input matInput [readonly] = "(this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Deactivated') || userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist'" characnumspl-only formControlName="poBillingInvoice" maxlength="99">
                                    <mat-error> {{ getErrorMessage('additionalDetails.poBillingInvoice', 'PO Billing Invoice #') }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px" class="addcandidate-toppadding">

                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Employee #</mat-label>
                                    <input matInput [readonly] = "(this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Deactivated') || userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist'" characnumspl-only formControlName="employeeID" maxlength="99">
                                    <mat-error> {{ getErrorMessage('additionalDetails.employeeID', 'Employee #') }} </mat-error>

                                </mat-form-field>
                            </div>

                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Job Start Date </mat-label>
                                    <input matInput  [readonly] = "(this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Deactivated') || userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist'" [matDatepicker]="jobStartDate" formControlName="jobStartDate">
                                    <mat-datepicker-toggle matSuffix [for]="jobStartDate" [disabled] = "(this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Deactivated') || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || (this.editCandidateProfile && this.editCandidateProfile.moveStatus && this.editCandidateProfile.moveStatus === 'Withdrawn')"></mat-datepicker-toggle>
                                    <mat-datepicker #jobStartDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion> -->
                <!-- Additional Details Start -->
                <mat-accordion *ngIf="configurableFields && configurableFields.length>0" >
                    <mat-expansion-panel formGroupName="optionalDetails">
                       <mat-expansion-panel-header class='panel-heading'>
                          Additional Details
                       </mat-expansion-panel-header>
                       <mat-panel-description class="remove-margin">
                          <div fxFlex="100%" class="container">
                             <div *ngFor="let field of configurableFields">
                                <form [formGroup]="additionalDetailsFormGroup" #myform="ngForm" (ngSubmit)="onSubmit(myform)">
                                <div [ngSwitch]="field.type">
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Text'">
                                      <mat-form-field>
                                         <mat-label>{{field.displayName}}</mat-label>
                                         <input formControlName="{{field.displayName}}" aria-label="{{field.displayName}}" [value]="field.value" type="text" class="level" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                         matInput>
                                      </mat-form-field>
                                   </div>
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Number'">
                                      <mat-form-field>
                                         <mat-label>{{field.displayName}}</mat-label>
                                         <input formControlName="{{field.displayName}}" aria-label="{{field.displayName}}" [value]="field.value" type="number" class="level" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                         matInput>
                                      </mat-form-field>
                                   </div>
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Date'">
                                      <mat-form-field>
                                         <mat-label>{{field.displayName}}</mat-label>
                                         <input matInput [matDatepicker]="fieldDate" aria-label="{{field.displayName}}" formControlName="{{field.displayName}}" class="level" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                         <mat-datepicker-toggle matSuffix [for]="fieldDate"></mat-datepicker-toggle>
                                         <mat-datepicker #fieldDate></mat-datepicker>
                                      </mat-form-field>
                                   </div>
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Single Select'">
                                      <mat-form-field>
                                         <mat-label>{{field.displayName}} </mat-label>
                                         <input type="text" aria-label="{{field.displayName}}" matInput #autoCompleteSingleSelect formControlName="{{field.displayName}}" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                            [matAutocomplete]="autoSingleSelect">
                                        <mat-autocomplete #autoSingleSelect="matAutocomplete" [displayWith]="displaySelectFn">
                                            <mat-option [value]="opt" *ngFor="let opt of _getFilterObservableWrtDisplayName(field.displayName) | async">
                                                {{opt}}
                                            </mat-option>
                                        </mat-autocomplete>
                                        </mat-form-field>
                                        </div>
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Multiple Select'">
                                      <mat-form-field>
                                        <mat-label>{{field.displayName}} </mat-label>
                                        <mat-select (selectionChange)="getSelection($event,field.displayName)" 
                                            formControlName="{{field.displayName}}"
                                            aria-label="{{field.displayName}}" 
                                            [(value)]="additionalDetailsFormGroup.get(field.displayName).value"
                                            (openedChange)="openedChange($event,field.displayName)"
                                             multiple>
                                            <mat-form-field class="multiple--search-field">
                                                <input matInput placeholer="Search" (input)="filterValues($event.target.value,field.displayName)" />
                                            </mat-form-field>
                                            <mat-option (onSelectionChange)="getSelection($event,field.displayName)" *ngFor="let opt of field.valueList" [value]="opt">
                                                {{opt}}
                                            </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                   </div>
                                   <div class="addcandidate-toppadding conf-field" *ngSwitchCase="'Country'">
                                      <mat-form-field>
                                         <mat-label>{{field.displayName}} </mat-label>
                                         <input type="text" aria-label="{{field.displayName}}" matInput #autoCompleteSingleSelect formControlName="{{field.displayName}}" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                            [matAutocomplete]="autoSingleSelect">
                                        <mat-autocomplete #autoSingleSelect="matAutocomplete" [displayWith]="displaySelectFn">
                                            <mat-option [value]="opt" *ngFor="let opt of _getFilterObservableWrtDisplayName(field.displayName) | async">
                                                {{opt}}
                                            </mat-option>
                                        </mat-autocomplete>
                                      </mat-form-field>
                                   </div>
                                   <div [formGroupName]="field.displayName"  class="addcandidate-toppadding conf-field" *ngSwitchCase="'Currency'">
                                       <div class="currencyDisplayName">
                                            {{field.displayName}}
                                       </div>
                                       <div class="addcandidate-toppadding conf-field"  fxLayoutAlign="space-between">
                                        <div fxFlex="50%" class="currency-Amount">
                                            <mat-form-field>
                                                <mat-label>Currency</mat-label>
                                                <input type="text"
                                                aria-label="currency" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                matInput #autoCompleteCurrencyCode
                                                [matAutocomplete]="autoCurrencyCode" 
                                                formControlName="currencyCode">
                                                <mat-autocomplete #autoCurrencyCode ="matAutocomplete" [displayWith]="displayCurrencyCodeFn" >
                                                    <mat-option  [value]="curr" *ngFor="let curr of _getFilterObservableWrtDisplayName(field.displayName) | async">
                                                        {{curr}}
                                                    </mat-option>
                                                </mat-autocomplete>
                                            </mat-form-field>
                                        </div>
                                        <div fxflex=40% class="currency currency-Amount">
                                            <mat-form-field>
                                                <mat-label>Amount</mat-label>
                                                <input type="number" class="level"  number-only [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                                formControlName="currencyAmount"
                                                matInput>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                   </div>
                                </div>
                                </form>
                             </div>
                          </div>
                       </mat-panel-description>
                    </mat-expansion-panel>
                 </mat-accordion>
                <!-- Additional Details End -->
                <!--Optional Details-->
                <mat-accordion>
                    <mat-expansion-panel *ngIf="!isTraditional" formGroupName="optionalDetails">
                        <mat-expansion-panel-header class='panel-heading'>
                            Optional Details
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Cost Center</mat-label>
                                        <input matInput  characnumspl-only [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" formControlName="costCenter" maxlength="50">
                                    <mat-error> {{ getErrorMessage('optionalDetails.costCenter', 'Cost Center') }} </mat-error>

                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>PO Billing Invoice #</mat-label>
                                    <input matInput [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" characnumspl-only formControlName="poBillingInvoice" maxlength="99">
                                    <mat-error> {{ getErrorMessage('optionalDetails.poBillingInvoice', 'PO Billing Invoice #') }}
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>

                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">

                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Employee #</mat-label>
                                    <input matInput [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" characnumspl-only formControlName="employeeID" maxlength="99">
                                    <mat-error> {{ getErrorMessage('optionalDetails.employeeID', 'Employee #') }} </mat-error>

                                </mat-form-field>
                            </div>

                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Job Start Date </mat-label>
                                    <input matInput  [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" [matDatepicker]="jobStartDate" formControlName="jobStartDate">
                                    <mat-datepicker-toggle matSuffix [for]="jobStartDate" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"></mat-datepicker-toggle>
                                    <mat-datepicker #jobStartDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                    <mat-expansion-panel *ngIf="isTraditional" formGroupName="optionalDetailsTraditionals">
                        <mat-expansion-panel-header class='panel-heading'>
                            Optional Details
                        </mat-expansion-panel-header>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column"
                            fxLayoutGap.xs="0px" class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>File Priority</mat-label>
                                    <mat-select formControlName="priority"
                                    [disabled]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move') ">
                                        <mat-option *ngFor="let file of filePriorityValues" [value]="file">{{file}}</mat-option>
                                    </mat-select>
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.priority', 'File Priority') }} </mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Employee Type</mat-label>
                                    <mat-select 
                                    [disabled]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"
                                        formControlName="employeeType">
                                        <mat-option *ngFor="let type of employeeTypeValue" [value]="type">{{type}}</mat-option>
                                    </mat-select>
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.employeeType', 'Employee Type') }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="displayDefaultCountry()" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                            class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Default Home Country</mat-label>
                                    <input type="text" aria-label="Country" matInput 
                                        #autoCompleteCountryInput formControlName="defaultHomeCountry"
                                        [matAutocomplete]="homeCountry" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                    <mat-autocomplete  #homeCountry="matAutocomplete" [panelWidth] = "293">
                                        <mat-option [value]="option.countryName" *ngFor="let option of filteredDefaultHomeCountry | async">
                                            {{ option.countryName }}
                                        </mat-option>
                                      </mat-autocomplete>
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.defaultHomeCountry', 'Default Home Country') }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Default Host Country</mat-label>
                                    <input type="text" aria-label="Country" matInput 
                                        #autoCompleteCountryInput
                                        formControlName="defaultHostCountry"
                                        [matAutocomplete]="hostCountry" [readonly]="disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')">
                                    <mat-autocomplete  #hostCountry="matAutocomplete" [panelWidth] = "293">
                                        <mat-option [value]="option.countryName" *ngFor="let option of filteredDefaultHostCountry | async">
                                            {{ option.countryName }}
                                        </mat-option>
                                      </mat-autocomplete>
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.defaultHostCountry', 'Default Host Country') }}</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div *ngIf="displayDefaultCountry()" fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px"
                            class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Assignment Start Date</mat-label>
                                    <input matInput  [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                        [matDatepicker]="assignmentStartDate" formControlName="assignmentStartDate">
                                    <mat-datepicker-toggle matSuffix [for]="assignmentStartDate" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"></mat-datepicker-toggle>
                                    <mat-datepicker #assignmentStartDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                            <div fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Assignment End Date</mat-label>
                                    <input matInput  [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                        [matDatepicker]="assignmentEndDate" formControlName="assignmentEndDate">
                                    <mat-datepicker-toggle matSuffix [for]="assignmentEndDate" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"></mat-datepicker-toggle>
                                    <mat-datepicker #assignmentEndDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="0px" 
                            class="addcandidate-toppadding">
                            <div fxFlex="50%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Employee #</mat-label>
                                    <input matInput [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" characnumspl-only formControlName="employeeID" maxlength="99">
                                    <mat-error> {{ getErrorMessage('optionalDetailsTraditionals.defaultHostCountry', 'Default Host Country') }}</mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="displayJobStartDateTraditional()" fxFlex="50%" fxLayout.xs="column" fxFlex.xs="100%">
                                <mat-form-field>
                                    <mat-label>Job Start Date </mat-label>
                                    <input matInput [readonly] = "disableAllFields || (userRole !== 'client-contact-initiator'&& userRole !== 'file-setup-specialist') || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')" 
                                        [matDatepicker]="jobStartDate" formControlName="jobStartDate">
                                    <mat-datepicker-toggle matSuffix [for]="jobStartDate" [disabled]="disableAllFields || editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated' || editCandidateProfile?.moveStatus == 'Requested' || this.editCandidateProfile?.moveStatus == 'Approved' || this.editCandidateProfile?.moveStatus == 'Not Approved' || (editCandidateProfile?.moveStatus == 'CP In Progress' && sendInviteBtnText == 'Approve Move')"></mat-datepicker-toggle>
                                    <mat-datepicker #jobStartDate></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-dialog-content>
        </div>
        <mat-dialog-actions class="statusBtn add-dialogbtn" fxLayout="row" fxLayoutAlign="end end" fxLayoutGap="10px" fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
            <button fxFlex="25%" [disabled]="formDisable" type="button" class="mat-button text-button" (click)="onDismiss($event)" color="primary" id="cancel">{{disableAllFields ? 'CLOSE' : 'CANCEL'}}</button>
            <button fxFlex="35%" *ngIf="isSaveDraft && !disableAllFields" class="mat-button outlined-button" type="button" (click)="saveData('saveDraft')" id="addEditSave" 
             [disabled]="disableAllFields || (additionalDetailsFormGroup && (!isAdditionalValid() || !isCurrencyValid())) || !(addCandidateForm?.get('nameDetails')?.controls.firstName.valid && addCandidateForm?.get('nameDetails')?.controls.lastName.valid && addCandidateForm?.get('authorizationDetails')?.controls.cciDetails.valid) || userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist'">Save Draft</button>
             <button fxFlex="35%" *ngIf="isMoveNotApprove && isApproved && !disableAllFields && sendInviteBtnText !== 'Authorize Move' && sendInviteBtnText !== 'Invite Transferee' " class="mat-button outlined-button" type="button" (click)="saveData('moveNotApprove')" id="addEditSave" 
             [disabled]="(additionalDetailsFormGroup && (!isAdditionalValid() || !isCurrencyValid())) || !(addCandidateForm?.get('nameDetails')?.controls.firstName.valid && addCandidateForm?.get('nameDetails')?.controls.lastName.valid && addCandidateForm?.get('authorizationDetails')?.controls.cciDetails.valid) || userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist' || editCandidateProfile?.moveStatus == 'Requested'  || editCandidateProfile?.moveStatus !== 'CP In Progress' ">Move Not Approved</button>   
             <button fxFlex="36%" *ngIf="!isSaveDraft && !isMoveNotApprove && isApproved" class="mat-button outlined-button" type="button" (click)="saveData('saveChanges')" id="addEditSave" 
            [disabled]="disableAllFields || (additionalDetailsFormGroup && !(editCandidateProfile?.moveStatus == 'Invitation Sent' || editCandidateProfile?.moveStatus == 'Authorization Initiated') && !isAdditionalValid()) 
                        || !addCandidateForm.valid || userRole !== 'client-contact-initiator' && userRole != 'file-setup-specialist' && !(userRole === 'consultant' && editCandidateProfile.moveStatus === 'Invitation Sent')">Save Changes</button>
            <button fxFlex="35%" *ngIf="isApproved && !disableAllFields" [disabled]="disableAllFields  || !addCandidateForm.valid || (userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist' && !(userRole === 'consultant' && editCandidateProfile.moveStatus === 'Invitation Sent')) || editCandidateProfile?.moveStatus == 'Authorization Initiated'|| editCandidateProfile?.moveStatus == 'Requested' || (editCandidateProfile?.moveStatus && editCandidateProfile?.moveStatus !== 'CP In Progress') && editCandidateProfile?.moveStatus !== 'Not Requested'" class="mat-button contained-button send-invite" (click)="sendInvite()" id="addEditSendInvite">{{ sendInviteBtnText }}</button>
        </mat-dialog-actions>

    </form>
</div>
