import { Component, OnInit, ViewEncapsulation, Input, Inject } from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDatepickerInputEvent, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { CandidateProfileService } from '../../../services/candidate-profile.service';
import { AdminFilterSelectionComponent } from '../filter-selection/filter-selection.component';
import { FilterSelectionOption } from './../../../../../core/models/common/filter-selection-option.model';
import { DateConversionService } from './../../../../../core/services/date-conversion.service';

@Component({
  selector: 'app-transferee-filter-modal',
  templateUrl: './list-filter-modal.component.html',
  styleUrls: ['./list-filter-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ListFilterModalComponent implements OnInit {

  destination = [];
  departure = [];

  movePhases: Array<string> = [];
  selectedMovePhases: Array<string> = [];
  authorizationType: Array<any> = [{'value':'Traditional'}, {'value':'Benefits Builder'}];
  selectedAuthorizationType: Array<string> = [];

  /** FilterDetails api subscription */
  filterDetailsSub: Subscription;
  /** Status Date range */
  statusStartDate: Date;
  statusEndDate: Date;

  /** Authorization Date range */
  authorizationStartDate: Date;
  authorizationEndDate: Date;

  showAuthorizationDataRange: boolean = false;

  disableFilter = false;

  disableFilterwithAuthorizationDate = false;
  /** Contains the list of Selected filters */
  selectedFilters: {
    [filterType: string]: any
  } = {};

  locationFilterOptions: any;
  movePhaseFilterOptions: any;

  constructor(
    public filterDialogRef: MatDialogRef<AdminFilterSelectionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private candidateService: CandidateProfileService,
    private dateConversionService: DateConversionService    ) { }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() {
    // Pre setting the filter values;
    if (this.data) {
      if (this.data.filterData) {
        this.data.filterData.moveStatuses = this.data.filterData.moveStatuses.map(x => (x.label === 'Withdrawn') ? ({'label': 'Withdrawn', 'value': 'Deactivated'}) : x);
        this.movePhases = this.data.filterData.moveStatuses;
      }
      if (this.data.selectedFilters) {
        this.selectedFilters = this.data.selectedFilters;
        for (const key in this.selectedFilters) {
          if (this.selectedFilters.hasOwnProperty(key)) {
            const existingFilter: Array<{ label: string, value: string }> = this.selectedFilters[key];

            // Since key = 'destination' | 'departure' and this[key] would be this.{destination | departure}
            if (key === 'destination' || key == 'departure') {
              this[key] = this.groupExistingFilterByLabel(existingFilter) || [];
            }else if(key === 'authorizationType') {
              this.selectedAuthorizationType = this.selectedFilters['authorizationType'];
            } else {
              this.selectedMovePhases = this.selectedFilters['movePhases'];
            }
          }
        }
      }

      if (this.data.displayedColumns) {
        this.data.displayedColumns.map(x => (x === 'authorizationDate') ? this.showAuthorizationDataRange = true: this.showAuthorizationDataRange = false);
      }
    }

    this.locationFilterOptions = new FilterSelectionOption({
      enableSearch: true,
      searchCharLimit: 3,
      searchDelayInMilliSeconds: 500,
      type: 'locations'
    });

    this.movePhaseFilterOptions = new FilterSelectionOption({
      enableSearch: false,
      type: 'movephase'
    });
    let formatStartDate: any, formatEndDate: any;
    if (this.selectedFilters.startDate !== undefined && this.selectedFilters.startDate !== null) {
      formatStartDate = this.dateConversionService.convertToMmDdYyyy(this.selectedFilters.startDate.toString());
    } else {
      formatStartDate = null;
    }
    if (this.selectedFilters.endDate !== undefined && this.selectedFilters.endDate !== null) {
      formatEndDate = this.dateConversionService.convertToMmDdYyyy(this.selectedFilters.endDate.toString());
    } else {
      formatEndDate = null;
    }

    this.statusStartDate = formatStartDate ? new Date(formatStartDate) : null;
    this.statusEndDate = formatEndDate ? new Date(formatEndDate) : null;


    let formatAuthorizationStartDate: any, formatAuthorizationEndDate: any;
    if (this.selectedFilters.authorizationStartDate !== undefined && this.selectedFilters.authorizationStartDate !== null) {
      formatAuthorizationStartDate = this.dateConversionService.convertToMmDdYyyy(this.selectedFilters.authorizationStartDate.toString());
    } else {
      formatAuthorizationStartDate = null;
    }
    if (this.selectedFilters.authorizationEndDate !== undefined && this.selectedFilters.authorizationEndDate !== null) {
      formatAuthorizationEndDate = this.dateConversionService.convertToMmDdYyyy(this.selectedFilters.authorizationEndDate.toString());
    } else {
      formatAuthorizationEndDate = null;
    }

    this.authorizationStartDate = formatAuthorizationStartDate ? new Date(formatAuthorizationStartDate) : null;
    this.authorizationEndDate = formatAuthorizationEndDate ? new Date(formatAuthorizationEndDate) : null;

  }

  /**
   * Used to fill pre fill Destination and Departure if Dialog Data is present.
   * @param existingFilter
   */
  groupExistingFilterByLabel(existingFilter: Array<{ label: string, value: string }>) {

    let updatedFilter: Array<{
      label?: string,
      name?: string,
      values?: Array<string>
    }> = null;

    if (existingFilter && Array.isArray(existingFilter) && existingFilter.length > 0) {
      updatedFilter = [];
      existingFilter.map(filter => {
        // tslint:disable-next-line: triple-equals
        if (updatedFilter && updatedFilter.find(item => item.label == filter.label)) {
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          updatedFilter.find(item => filter.label === item.label).values.push(filter.value);
        } else {
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          updatedFilter.push({
            label: filter.label,
            name: filter.label,
            values: [filter.value]
          });
        }
      });
    }
    return updatedFilter;
  }

  getLocations(type: string, searchText: string) {
    if (searchText) {
      this.filterDetailsSub = this.candidateService.searchCandidateLocations(type, searchText).subscribe((res) => {
      if (res) {

        // Convert the response to the Format that Imported Component understands.
        // tslint:disable-next-line: prefer-const
        let updatedResult = [];

        Object.keys(res).map((resultType, index) => {
          if (
              res[resultType] &&
              Array.isArray(res[resultType]) &&
              (res[resultType] as Array<string>).length > 0
            ) {
              // tslint:disable-next-line: rxjs-no-unsafe-scope
              updatedResult.push({
                label: resultType,
                name: resultType,
                values: res[resultType]
              });
          }
        });
        if (type === 'departure') {
          this.departure = updatedResult;
        } else {
          this.destination = updatedResult;
        }
      }
      });
    } else {
      if (type === 'departure') {
        this.departure = [];
      } else {
        this.destination = [];
      }
    }
  }

  onReset() {
    this.selectedFilters = {};
    this.departure = [];
    this.destination = [];
    this.selectedDataSetChange("movePhases", []);
    this.selectedDataSetChange("departure", []);
    this.selectedDataSetChange("destination", []);
    this.selectedDataSetChange("authorizationType", []);
    this.filterDialogRef.close(this.selectedFilters);
  }

  onSubmit(): void {
    this.filterDialogRef.close(this.selectedFilters);
  }

  /** To trigger the status end date change event to set default date for start date */
  statusDateChange() {
    if (!this.statusStartDate && this.statusEndDate) {
      this.statusStartDate = new Date('01-01-1900');
      this.selectedFilters['startDate'] = this.dateConversionService.convertToYyyyMmDd(this.statusStartDate);
    } 
  }

  /** To trigger the status end date change event to set default date for start date */
  authorizationDateChange() {
    if (!this.authorizationStartDate && this.authorizationEndDate) {
      this.authorizationStartDate = new Date('01-01-1900');
      this.selectedFilters['authorizationStartDate'] = this.dateConversionService.convertToYyyyMmDd(this.authorizationStartDate);
    } 
  }

  getErrorMessage(){
    if ( this.statusStartDate > this.statusEndDate && this.statusEndDate) {
      this.disableFilter = true;
      return "Status start date shouldn't be greater than Status end date.";
    } else {
      this.disableFilter = false;
    }
  }

  getErrorMessageForAuthorizationDateRange(){
    if ( this.authorizationStartDate > this.authorizationEndDate && this.authorizationEndDate) {
      this.disableFilterwithAuthorizationDate = true;
      return "Authorization start date shouldn't be greater than Authorization end date.";
    } else {
      this.disableFilterwithAuthorizationDate = false;
    }
  }

  // numberOnly(event): boolean {
  //   const charCode = (event.which) ? event.which : event.keyCode;
  //   if (charCode > 31 && (charCode < 48 || charCode > 57)) {
  //     return false
  //   }
  //   return true
  // }

  // -----------------------------------------------------
  // BB related code ..

  selectedDataSetChange(source: string, newValue: any) {
    if (source === 'startDate' || source === 'endDate') {
      this.statusDateChange();
      const date = this.dateConversionService.convertToYyyyMmDd(newValue);
      newValue = date;
    }
    this.selectedFilters[source] = newValue;
  }
  
  //Authorization date range set
  selectedAuthorizationDataSetChange(source: string, newValue: any) {
    if (source === 'authorizationStartDate' || source === 'authorizationEndDate') {
      this.authorizationDateChange();
      const date = this.dateConversionService.convertToYyyyMmDd(newValue);
      newValue = date;
    }
    this.selectedFilters[source] = newValue;
  }

  // -----------------------------------------------------

  /** Component Angular destructor lifecycle hook */
  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy(): void {
    if (this.filterDetailsSub) { this.filterDetailsSub.unsubscribe(); }
  }

}
