import { NgModule } from '@angular/core';
import { Routes, RouterModule, ActivatedRoute } from '@angular/router';
import { ProviderComponent } from './provider.component';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'provider'
  // },
  {
    path: 'provider/:id',
    component: ProviderComponent,
    data: { navId: 1, title: 'Provider', showGlobalTemplate: true }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProviderRoutingModule { }
