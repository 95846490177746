import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermsOfUseComponent } from './components/terms-of-use/terms-of-use.component';
import { PrivacyStatementComponent } from './components/privacy-statement/privacy-statement.component';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   redirectTo: 'terms-of-use'
  // },
  {
    path: 'termsOfUse',
    component: TermsOfUseComponent,
    data: { navId: 1, title: 'Terms of Use', showGlobalTemplate: false }
  },
  {
    path: 'privacyStatement',
    component: PrivacyStatementComponent,
    data: { navId: 1, title: 'Privacy Statement', showGlobalTemplate: false }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TermsPrivacyRoutingModule { }
