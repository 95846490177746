<mat-card class="mat-card-candidate-contact" fxLayout="row" fxLayout fxLayoutAlign="start center"
  fxLayoutAlign.xs="start start">
  <button class="close-btn" mat-icon-button aria-label="close" (click)="closeTransferee()">
    <mat-icon>close</mat-icon>
  </button>
  <div class="candidate-short-name">
    <app-avatar [firstName]="transferee?.nameDetails.firstName" [lastName]="transferee?.nameDetails.lastName">
    </app-avatar>
  </div>
  <div class="candidate-contact-detail">
    <div class="candidate-user-name">{{transferee?.nameDetails.firstName}} {{transferee?.nameDetails.lastName}}</div>
    <!-- <div><span>Company:&nbsp;</span>candidateCompany</div> -->
    <div class="contact-detail" fxLayout fxLayout.xs="row" fxLayout.sm="column" fxLayout.md="column" fxHide.xs>
      <div fxLayout fxFlex="30" fxFlex.sm="100" fxFlex.xs="100">
        <img alt="phone-icon" (click)="toggle()" src="../../../../../../assets/images/baseline-phone-24px.svg" />
        <div class="xs-none">
          <app-sensitive-info-mask *ngIf="transferee?.phoneDetailsList[0].phoneNumber" type="phone"
            [value]="transferee?.phoneDetailsList[0].phoneNumber"
            [dialingCode]="transferee?.phoneDetailsList[0].phoneDialCode"></app-sensitive-info-mask>
        </div>
        <button fxHide.lt-md class="more-btn xs-none" (click)="toggle()" mat-icon-button
          aria-label="More details about phone number">
          <mat-icon>more_vert</mat-icon>
        </button>
        <div class="phone-numbers" *ngIf="show">
          <div class="phone-header" fxLayout="row" fxLayoutAlign="space-between start">
            <div>
              <img src="../../../../../../assets/images/baseline-phone-24px.svg" alt="phone" />
              <span>Phone Number</span>
            </div>
            <button mat-icon-button aria-label="close" (click)="toggle()">
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <div class="phone-inputs">
            <div fxLayout="row" fxLayoutGap="15px" *ngFor="let phone of transferee?.phoneDetailsList">
              <mat-form-field fxFlex="60">
                <span matPrefix> +{{phone.phoneDialCode}} &nbsp;</span>
                <input readonly type="tel" matInput [value]="phone.phoneNumber">
              </mat-form-field>
              <mat-form-field fxFlex="40">
                <input readonly matInput [value]="phone?.usageType.charAt(0).toUpperCase() + phone.usageType.slice(1)">
              </mat-form-field>

            </div>
          </div>
        </div>

      </div>
      <div fxLayout fxFlex="30" fxFlex.sm="100" fxFlex.xs="100" *ngIf="(transferee?.emailDetailsList.length!=0)">
        <img alt="email-icon" (click)="toggleEmail()" src="../../../../../../assets/images/baseline-email-24px.svg" />
        <div class="xs-none">
          <app-sensitive-info-mask type="email" [value]="transferee?.emailDetailsList[0].emailAddress">
          </app-sensitive-info-mask>
        </div>
        <button (click)="toggleEmail()" class="more-btn xs-none" mat-icon-button aria-label="More details about email">
          <mat-icon>more_vert</mat-icon>
        </button>
        <div class="phone-numbers email-address" *ngIf="showEmail">
          <div class="phone-header" fxLayout="row" fxLayoutAlign="space-between start">
            <div>
              <img src="../../../../../../assets/images/baseline-email-24px.svg" alt="email" />
              <span>Email Address</span>
            </div>
            <button mat-icon-button aria-label="close" (click)="toggleEmail()">
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <div class="phone-inputs">
            <div fxLayout="row" fxLayoutGap="15px" *ngFor="let email of transferee?.emailDetailsList">
              <mat-form-field fxFlex="60">
                <input readonly="true" type="text" matInput [value]="email.emailAddress">
              </mat-form-field>
              <mat-form-field fxFlex="40">
                <input readonly="true" matInput
                  [value]="email?.usageType.charAt(0).toUpperCase() + email.usageType.slice(1)">
              </mat-form-field>

            </div>
          </div>
        </div>

      </div>
      <div fxLayout fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign.sm="start center"
        fxLayoutAlign="end center" class="xs-none">
        <div fxLayout="row" class="launch-text" *ngIf="userRole ==='consultant'">
          <span class="launch">Launch:</span>
          <span class="b-builder" (click)="launchBB()">Benefits Builder</span>
          <!-- <span class="cartus-online">CartusONLINE</span> -->
        </div>
      </div>
      <button class="launch-btn" fxLayoutAlign="center center" fxFlex.xs="100" fxHide.xs=true
        [matMenuTriggerFor]="menu">Launch: <mat-icon>expand_more</mat-icon></button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item aria-label="Benefits Builder">Benefits Builder</button>
        <!-- <button mat-menu-item aria-label="Benefits Builder">CartusONLINE</button> -->
      </mat-menu>
    </div>
  </div>
</mat-card>

<!-- transferee details section-->

<mat-accordion class="candidate-review-panel candidate-info-panel" multi="true" *ngIf="candidateDetails.authorizationType === 'BenefitsBuilder'">
  <!--Additional Details -->
  <mat-expansion-panel class="candidate-detail">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
      fxLayout.xs="row" fxLayoutGap="10px">
      <div class="panel-heading">Transferee Details</div>
    </mat-expansion-panel-header>
    <mat-panel-description>
      <div class="container" fxLayout="column" fxLayout.xs="column" fxFlex="100%" fxLayoutGap="10px">
        <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%">
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>Business Unit:</mat-label>
            <input class="businessUnit" readonly
              [value]="(transferee?.hasOwnProperty('divisionName')) ? transferee.divisionName : 'NA'" matInput>
          </mat-form-field>
        </div>
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field consultent">
          <button class="cj-popup" mat-icon-button aria-label="User Short Name" (click)="toggleSN()"
            *ngIf="consultantinfo?.firstName">
            <app-avatar [firstName]="consultantinfo?.firstName" [lastName]="consultantinfo?.lastName"></app-avatar>

          </button>

          <mat-form-field [ngClass]="{'sonsultant-field' : consultantinfo?.firstName}">
            <mat-label>Consultant: </mat-label>
            <input 
              [value]="(consultantinfo?.hasOwnProperty('firstName')) ? (consultantinfo.firstName + ' ' + consultantinfo.lastName) : 'NA'"
              readonly class="level" matInput>
          </mat-form-field>
        </div>
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>Atlas Customer ID:</mat-label>
            <input 
              [value]="(consultantinfo?.hasOwnProperty('custFileId')) ? consultantinfo?.custFileId : 'NA'" readonly
              class="level" matInput>
          </mat-form-field>
        </div>
        
        <!-- popover-->
        <div class="phone-numbers" *ngIf="showSN">
          <div class="phone-header" fxLayout="row" fxLayoutAlign="space-between start">
            <div fxLayout="row" fxLayoutAlign="center" fxLayoutGap="6px">
              <span class="short-name" *ngIf="consultantinfo?.firstName">
                <app-avatar [firstName]="consultantinfo?.firstName" [lastName]="consultantinfo?.lastName"></app-avatar>
              </span>
              <span> {{consultantinfo? (consultantinfo?.firstName + ' ' + consultantinfo?.lastName) : 'N/A'}}</span>
            </div>
            <button mat-icon-button aria-label="close" (click)="toggleSN()">
              <mat-icon>close</mat-icon>
            </button>
          </div>

          <div class="phone-inputs" fxLayout="column" fxLayoutGap="15px">

            <div fxLayout="row" fxLayoutGap="15px">
              <img src="../../../../../../assets/images/baseline-email-24px.svg" alt="phone" />
              <span class="email"> {{consultantinfo?.emailDetailsList[0].emailAddress}}</span>
            </div>

            <div fxLayout="row" fxLayoutGap="15px">
              <img src="../../../../../../assets/images/baseline-phone-24px.svg" alt="phone" />
              <span> +{{consultantinfo?.phoneDetailsList[0].phoneDialingCode}} &nbsp;
                {{consultantinfo?.phoneDetailsList[0].phoneNumber}}</span>
            </div>
          </div>
          <div fxLayoutAlign="center center">
            <button class="close-btn" mat-button (click)="toggleSN()">Close</button>
          </div>

        </div>
        </div>
        <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%">
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field jobstartDate">           
            <mat-form-field>
              <mat-label>Job Start Date </mat-label>
              <input readonly matInput class="businessUnit" [value]="DefaultjobStartDate" *ngIf="!jobStartEdit">
              
              <input readonly matInput class="businessUnit" [matDatepicker]="jobStartDate" [hidden]="!jobStartEdit"
              [formControl]="initaljobStartDate" (dateChange)="jobStartDateChange('change', $event);jobStartEdit=false">
              <mat-datepicker-toggle matSuffix [for]="jobStartDate" *ngIf="jobStartEdit"></mat-datepicker-toggle>
              <mat-datepicker #jobStartDate></mat-datepicker>
              <button  tabindex="0" *ngIf="(userRole ==='consultant' || userRole === 'file-setup-specialist') && isInProgress"  mat-icon-button aria-label="edit job startDate" class="edit_icon" (click)="jobStartEdit=true;jobStartDate.open();"
                 >
                <mat-icon>edit</mat-icon>
              </button>
          </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>PO Billing Invoice #</mat-label>
            <input 
              [value]="(candidateDetails.additionalDetails?.hasOwnProperty('poBillingInvoice')) ? ((candidateDetails?.additionalDetails.poBillingInvoice !== '') ? candidateDetails?.additionalDetails.poBillingInvoice : 'NA') : 'NA'" readonly
              class="level" matInput>
          </mat-form-field>
        </div>
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>Cost Center</mat-label>
            <input 
              [value]="(candidateDetails.additionalDetails?.hasOwnProperty('costCenter')) ? ((candidateDetails?.additionalDetails.costCenter !== '') ? candidateDetails?.additionalDetails.costCenter : 'NA') : 'NA'" readonly
              class="level" matInput>
          </mat-form-field>
        </div>
        </div>
        <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%">
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>Employee #</mat-label>
            <input 
              [value]="(candidateDetails.additionalDetails?.hasOwnProperty('employeeID')) ? ((candidateDetails?.additionalDetails.employeeID !== '') ? candidateDetails?.additionalDetails.employeeID : 'NA') : 'NA'" readonly
              class="level" matInput>
          </mat-form-field>
        </div>
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>Client Contact Initiator</mat-label>
            <input 
              [value]="(candidateDetails?.hasOwnProperty('ccinitiatorNameDetails')) ? candidateDetails?.ccinitiatorNameDetails.fullName  : 'NA'" readonly
              class="level" matInput>
          </mat-form-field>
        </div>
        <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-form-field>
            <mat-label>Authorization Date</mat-label>
            <input 
              [value]="(candidateDetails?.hasOwnProperty('authorizationDate')) ?(candidateDetails.authorizationDate | date:'MM/dd/yyyy')  : 'NA'" readonly
              class="level" matInput>
          </mat-form-field>
        </div>
        </div>
        <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%">
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
         <mat-form-field>
            <mat-label>Created By</mat-label>
            <input 
              [value]="(candidateDetails?.hasOwnProperty('createdByNameDetails')) ? candidateDetails?.createdByNameDetails.fullName  : 'NA'" readonly
              class="level" matInput>
          </mat-form-field>
        </div>
        </div>
      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>

<div class="trans-move-detail-chip trans-move-bottom-margin" style="margin-bottom: 2px;">
  Transferee Move Details:
  <div class="trans-move-margin" fxLayout="row" fxLayoutGap="2em">
    <div class="filter-chips">
      <mat-chip-list #chipSelect aria-label="Filter Selection">
        <!-- <ng-container *ngFor="let moveDetail of moveDetailList"> -->
          <mat-chip #chipRef="matChip" class="chip-style" *ngFor="let moveDetail of moveDetailList" selected ="{{moveDetail.orderRequestId === currentChipOrderRequestId ? true : false}}"
            >
            <span (click)="onChipClick(chipRef, moveDetail)">
              <p>{{moveDetail.departureAddr?.state? moveDetail.departureAddr.state: 'N/A'}}, {{moveDetail.departureAddr?.country? moveDetail.departureAddr.countryCode: 'N/A '}}  >  {{moveDetail.destinationAddr?.state? moveDetail.destinationAddr.state: ' N/A'}}, {{moveDetail.destinationAddr?.country? moveDetail.destinationAddr.countryCode: ' N/A'}}</p>
              <p class="chip-movestatus">{{moveDetail.moveStatus}} :  {{moveDetail.moveStatusDate}}</p>
            </span>
          </mat-chip>
        <!-- </ng-container> -->
      </mat-chip-list>
    </div>
    <div class="add-Move-Detail" >
      <button [matMenuTriggerFor]="menu" class="user-water user-water-height" [ngClass] = "{'close-btn-style': menuStatus.menuOpen}" #menuStatus="matMenuTrigger"  tabindex="0" aria-label="Add">
        <mat-icon  *ngIf = "!menuStatus.menuOpen">add</mat-icon>
        <mat-icon *ngIf = "menuStatus.menuOpen">close</mat-icon>
      </button>
      <mat-menu #menu="matMenu" xPosition="before" yPosition="below">
        <button mat-menu-item (click)="openDialog(false)" (keyup.enter)="openDialog(false)">New Benefits Builder Move</button>
        <button mat-menu-item (click)="openDialog(true)" (keyup.enter)="openDialog(true)">New Traditional Move</button>
      </mat-menu>
    </div>
  </div>
</div>


<mat-accordion class="candidate-review-panel move-departure-panel" multi="true"  >
  <!--Move Details starts-->
  <mat-expansion-panel class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
      fxLayout.xs="row" fxLayoutGap="10px">
      <div fxFlex="34%" class="panel-heading">Move Details</div>
    </mat-expansion-panel-header>
    <mat-panel-description class="remove-margin"  *ngIf="candidateDetails.authorizationType === 'BenefitsBuilder'">
      <div class="container move-details" fxFlex="100%" fxLayout fxLayout.sm="column" fxLayout.xs="column"
        fxLayoutGap="32px" fxLayoutGap.xs="4px">
        <div fxFlex="60%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
          <div class="tile-flex" fxLayout.xs="row" fxLayoutGap="1">
            <div class="box-fields" fxFlex.xs="45">
              <mat-form-field class="city" fxFlex.xs="100">
                <mat-label>Departure</mat-label>
                <input matInput placeholder="Departure" readonly
                [value]="((transferee?.departureAddr?.city)?(transferee.departureAddr.city) + ', ':'') + ((transferee?.departureAddr?.state)?(transferee.departureAddr.state) + ', ':'') +( (transferee?.departureAddr?.country)?(transferee.departureAddr.country):'')">
              </mat-form-field>
            </div>
            <div class="separator" fxFlex.xs="5">
              <mat-icon class="material-icons" aria-label="chevron right">chevron_right</mat-icon>
            </div>
            <div class="box-fields" fxFlex.xs="45">
              <mat-form-field class="city" fxFlex.xs="100">
                <mat-label>Destination:</mat-label>
                <input class="city" matInput placeholder="Destination" readonly
                [value]="((transferee?.destinationAddr?.city)?(transferee.destinationAddr.city) + ', ':'') + ((transferee?.destinationAddr?.state)?(transferee.destinationAddr.state) + ', ':'') +( (transferee?.destinationAddr?.country)?(transferee.destinationAddr.country):'')">
              </mat-form-field>
            </div>
            <div fxFlex.xs="5">
              <span matTooltip="Click here to edit Details"
                class="create-style">
                <mat-icon tabindex="0" style="cursor: pointer;" (click)="openDialoge()" (keyup.enter)="openDialoge()">create
                </mat-icon>
              </span>
            </div>
          </div>
        </div>
        <div fxFlex="15%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
          <div class="tile-flex">
            <div class="box-fields">
              <mat-form-field>
                <mat-label>Total People Moving:</mat-label>
                <input class="totalPeople" matInput placeholder="Total People Moving" readonly
                  [value]="(transferee?.hasOwnProperty('totalNumberOfRelocatingMembers')) ? transferee.totalNumberOfRelocatingMembers : 'NA'">
              </mat-form-field>
            </div>
          </div>
        </div>
        <div fxFlex="35%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
          <div class="tile-flex" fxFlex.xs="100%">
            <div class="movedate" fxFlex.xs="100%" fxLayout="column" fxLayout.xs="column">
              <legend fxFlex.xs="100%"><span class="label-text">Estimated Move Date:</span></legend>
              <div fxLayout="row" fxFlex.xs="100%" fxFlexFill fxLayoutAlign="space-between">
                <div fxFlex="50%" fxFlex.xs="45%" class="startdate">
                  <mat-form-field fxFlex.xs="100%">
                    <input class="startDate" matInput readonly [value]="estimatedMoveStartDate| date:'yyyy-MM-dd'" autocomplete="on" aria-label="Start Date">
                  </mat-form-field>
                </div>
                <div fxFlex="50%" fxFlex.xs="45%" class="enddate">
                  <mat-form-field fxFlex.xs="100%">
                    <input class="endDate" matInput readonly [value]="estimatedMoveEndDate| date:'yyyy-MM-dd'" autocomplete="on" aria-label="End Date">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-panel-description>
      <mat-panel-description class="remove-margin"  *ngIf="candidateDetails.authorizationType === 'Traditional'">
        <div class="container move-details" fxFlex="100%" fxLayout fxLayout.sm="column" fxLayout.xs="column"
          fxLayoutGap="32px" fxLayoutGap.xs="4px">
          <div fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
            <div class="tile-flex" fxLayout.xs="row" fxLayoutGap="1">
              <div class="box-fields" fxFlex.xs="45">
                <mat-form-field class="city" fxFlex.xs="100">
                  <mat-label>Departure</mat-label>
                  <input matInput placeholder="Departure" readonly
                    [value]="transferee?.departureAddr?.city + ', ' + transferee?.departureAddr?.state + ', ' + transferee?.departureAddr?.country">
                </mat-form-field>
              </div>
              <div class="separator" fxFlex.xs="10">
                <mat-icon class="material-icons">chevron_right</mat-icon>
              </div>
              <div class="box-fields" fxFlex.xs="45">
                <mat-form-field class="city" fxFlex.xs="100">
                  <mat-label>Destination:</mat-label>
                  <input class="city" matInput placeholder="Destination" readonly
                    [value]="transferee?.destinationAddr?.city + ', ' + transferee?.destinationAddr?.state + ', ' + transferee?.destinationAddr?.country">
                </mat-form-field>
              </div>
            </div>
          </div>
          <div fxFlex="50%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
            <div class="tile-flex" fxFlex.xs="100%" fxLayout.xs="column">
              <div class="movedate" fxFlex.xs="100%" fxLayout="column" fxLayout.xs="column">
                <legend fxFlex.xs="50%"><span class="label-text">Estimated Move Date(Optional)</span></legend>
                <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxFlexFill fxLayoutAlign="space-between">
                  <div fxFlex="45%" fxFlex.xs="45%" class="startdate">
                    <mat-form-field fxFlex.xs="100%">
                      <input class="startDate" matInput readonly [value]="estimatedMoveStartDate| date:'yyyy-MM-dd'">
                    </mat-form-field>
                  </div>
                  <div fxFlex="53%" fxFlex.xs="55%" class="enddate">
                    <mat-form-field fxFlex.xs="100%">
                      <input class="endDate" matInput readonly [value]="estimatedMoveEndDate| date:'yyyy-MM-dd'">
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="movedate" fxFlex.xs="100%" fxLayout="column" fxLayout.xs="column">
                <legend fxFlex.xs="50%"><span class="label-text">Job Start Date</span></legend>
                <div fxLayout="row" fxLayout.xs="column" fxFlex.xs="100%" fxFlexFill fxLayoutAlign="space-between">
                  <div fxFlex="33%" fxFlex.xs="33%" class="startdate ">
                    <mat-form-field fxFlex.xs="100%">
                      <input class="startDate" matInput readonly  [value]="DefaultjobStartDate ? DefaultjobStartDate   : 'NA' " *ngIf="!jobStartEdit">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </mat-panel-description>
    <mat-panel-description class="add-margin" *ngIf="candidateDetails.authorizationType === 'Traditional'">
      <div class="container move-details" fxFlex="100%" fxLayout fxLayout.sm="column" fxLayout.xs="column"
          fxLayoutGap="32px" fxLayoutGap.xs="4px">
          <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
        <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%" class="tile-flex">
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label>Move Type</mat-label>
              <input 
                [value]="(candidateDetails?.hasOwnProperty('moveType')) ? ((candidateDetails?.moveType !== '') ? candidateDetails?.moveType : 'NA') : 'NA'" readonly
                class="level" matInput>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label>Policy</mat-label>
              <input 
                [value]="(candidateDetails?.hasOwnProperty('assignedPolicy')) ? candidateDetails?.assignedPolicy  : 'NA'" readonly
                class="level" matInput>
            </mat-form-field>
          </div>
          <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
            <mat-form-field>
              <mat-label>Authorization Type</mat-label>
              <input 
                [value]="(candidateDetails?.hasOwnProperty('authorizationType')) ?(candidateDetails.authorizationType)  : 'NA'" readonly
                class="level" matInput>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
   </mat-panel-description>
        <mat-panel-description class="add-margin" *ngIf="candidateDetails.authorizationType === 'Traditional'">
          <div class="container move-details" fxFlex="100%" fxLayout fxLayout.sm="column" fxLayout.xs="column"
              fxLayoutGap="32px" fxLayoutGap.xs="4px">
              <div fxFlex="100%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
            <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%" class="tile-flex">
              <div fxFlex="24%" fxFlex.md="24%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                <mat-form-field>
                  <mat-label>Atlas Customer ID</mat-label>
                  <input 
                    [value]="(consultantinfo?.hasOwnProperty('custFileId')) ? consultantinfo?.custFileId : 'NA'" readonly
                    class="level" matInput>
                </mat-form-field>
              </div>
              <div fxFlex="25%" fxFlex.md="25%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                <mat-form-field>
                  <mat-label>Division</mat-label>
                  <input 
                    [value]="(transferee?.hasOwnProperty('divisionName')) ? transferee.divisionName : 'NA'" readonly
                    class="level" matInput>
                </mat-form-field>
              </div>
              <div fxFlex="25%" fxFlex.md="25%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                <mat-form-field>
                  <mat-label>Cost Center</mat-label>
                  <input 
                    [value]="(candidateDetails.optionalDetails?.hasOwnProperty('costCenter')) ? ((candidateDetails?.optionalDetails.costCenter !== '') ? candidateDetails?.optionalDetails.costCenter : 'NA') : 'NA'" readonly
                    class="level" matInput>
                </mat-form-field>
              </div>
              <div fxFlex="25%" fxFlex.md="25%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                <mat-form-field>
                  <mat-label>PO Billing Invoice # </mat-label>
                  <input 
                    [value]="(candidateDetails.optionalDetails?.hasOwnProperty('poBillingInvoice')) ? ((candidateDetails?.optionalDetails.poBillingInvoice !== '') ? candidateDetails?.optionalDetails.poBillingInvoice : 'NA') : 'NA'" readonly
                    class="level" matInput>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
     </mat-panel-description>
            <mat-panel-description class="add-margin" *ngIf="candidateDetails.authorizationType === 'Traditional'">
              <div class="container move-details" fxFlex="100%" fxLayout fxLayout.sm="column" fxLayout.xs="column"
                  fxLayoutGap="32px" fxLayoutGap.xs="4px">
                  <div fxFlex="48.5%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
                <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%" class="tile-flex">
                  <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field consultent">
                    <button class="cj-popup" mat-icon-button aria-label="User Short Name" (click)="toggleSN()"
                      *ngIf="consultantinfo?.firstName">
                      <app-avatar [firstName]="consultantinfo?.firstName" [lastName]="consultantinfo?.lastName"></app-avatar>
                    </button>
                    <mat-form-field [ngClass]="{'avatar-field' : consultantinfo?.firstName}">
                      <mat-label>Consultant: </mat-label>
                      <input 
                        [value]="(consultantinfo?.hasOwnProperty('firstName')) ? (consultantinfo.firstName + ' ' + consultantinfo.lastName) : 'NA'"
                        readonly class="level" matInput>
                    </mat-form-field>
                  </div>
                  <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                    <mat-form-field  >
                      <mat-label>Consultant Phone #</mat-label>
                      <input 
                        [value]="(consultantinfo?.hasOwnProperty('phoneDetailsList')) ? (consultantinfo.phoneDetailsList[0].phoneNumber) : 'NA'"
                        readonly
                        class="level" matInput>
                    </mat-form-field>
                  </div>
                  <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                    <mat-form-field>
                      <mat-label>Consultant Email</mat-label>
                      <input 
                        [value]="(consultantinfo?.hasOwnProperty('emailDetailsList')) ? (consultantinfo.emailDetailsList[0].emailAddress) : 'NA'" readonly
                        class="level" matInput>
                    </mat-form-field>
                  </div>
                </div>
              </div>
                  <div fxFlex="48.7%" fxFlex.xs="100%" fxFlex.sm="100%" class="tile-box">
                    <div  fxLayout="row" fxLayout.xs="column" fxFlex="100%" class="tile-flex">
                      <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field consultent">
                        <button class="cj-popup" mat-icon-button aria-label="User Short Name" (click)="toggleSN()"
                          *ngIf="candidateDetails?.createdByNameDetails">
                          <app-avatar [firstName]="candidateDetails?.createdByNameDetails.firstName" [lastName]="candidateDetails?.createdByNameDetails.lastName" ></app-avatar>
                        </button>
                        <mat-form-field [ngClass]="{'avatar-field' : candidateDetails?.createdByNameDetails.fullName}">
                          <mat-label>Created By </mat-label>
                          <input 
                            [value]="(candidateDetails?.hasOwnProperty('createdByNameDetails')) ? candidateDetails?.createdByNameDetails.fullName  : 'NA'" readonly
                            readonly class="level" matInput>
                        </mat-form-field>
                      </div>
                      <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field consultent">
                        <button class="cj-popup" mat-icon-button aria-label="User Short Name" (click)="toggleSN()"
                          *ngIf="candidateDetails?.ccinitiatorNameDetails">
                          <app-avatar [firstName]="candidateDetails?.ccinitiatorNameDetails.firstName" [lastName]="candidateDetails?.ccinitiatorNameDetails.lastName" ></app-avatar>
                        </button>
                        <mat-form-field [ngClass]="{'avatar-field' : candidateDetails?.ccinitiatorNameDetails.fullName}">
                          <mat-label>Client Contact Initiator </mat-label>
                          <input 
                            [value]="(candidateDetails?.hasOwnProperty('ccinitiatorNameDetails')) ? candidateDetails?.ccinitiatorNameDetails.fullName  : 'NA'" readonly
                            readonly class="level" matInput>
                        </mat-form-field>
                      </div>
                      <div fxFlex="33%" fxFlex.md="33%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
                        <mat-form-field>
                          <mat-label>Authorization Date</mat-label>
                          <input 
                            [value]="(candidateDetails?.hasOwnProperty('authorizationDate')) ?(candidateDetails.authorizationDate | date:'yyyy-MM-dd')  : 'NA'" readonly
                            class="level" matInput>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </div>
             </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>

<!--Family Members -->
<mat-accordion class="candidate-review-panel move-departure-panel" multi="true" *ngIf="candidateDetails.authorizationType === 'BenefitsBuilder'" >
  <mat-expansion-panel class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
      fxLayout.xs="row" fxLayoutGap="10px">
      <div fxFlex="34%" class="panel-heading">Family Members</div>
    </mat-expansion-panel-header>
    <mat-panel-description class="remove-margin">
      <div fxFlex="100%">
        <app-family-info (updateMembers)="updateTotalRelocatingMembers($event)" *ngIf="hasFamilyDetails" [relocatingMembers]="totalFamilyMembersRelo">
        </app-family-info>
      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>
<!--Family Members -->

<!--Additional Details starts -->
<mat-accordion  class="candidate-review-panel move-departure-panel" multi="true">
  <mat-expansion-panel class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" >
      <div fxFlex="34%" class="panel-heading">Additional Details</div>
    </mat-expansion-panel-header>
    <mat-panel-description class="remove-margin">
      <div class="container" fxFlex="100%"  >
         <app-configurable-field 
         [configField] = "configFields"
         
          ></app-configurable-field> 

      </div>
    </mat-panel-description>
  </mat-expansion-panel>
</mat-accordion>
<!--Additional Details end -->

<!--------------------Benefits Builder Details------------------------>
<mat-expansion-panel class="benefit-builder-details candidate-review-panel" *ngIf="candidateDetails.authorizationType === 'BenefitsBuilder'" >
  <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
    fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="34%" fxFlex.xs="100%" class="panel-heading">Benefits Builder Details</div>
  </mat-expansion-panel-header>
  <mat-panel-description class="business-unit" fxLayout="column" fxLayout.xs="column" fxLayoutGap="20px"
    fxLayoutAlign.xs="center">
    <!-- <ng-container *ngIf = "(transferee?.programDetails.policyCallRequired === true && transferee.policyCallDetails?.policyCallStatus !== 'completed'); then policyCallRequired; else policyCallNotRequired"> -->
    <ng-container
      *ngIf="(transferee?.programDetails.policyCallRequired === true && transferee?.policyCallDetails?.policyCallStatus !== 'completed')">
      <ng-container *ngTemplateOutlet="policyCallRequired"></ng-container>
    </ng-container>

    <ng-container *ngIf="(transferee?.policyCallDetails?.policyCallStatus === 'completed')">
      <ng-container *ngTemplateOutlet="policyCallNotRequired"></ng-container>
    </ng-container>

    <ng-container *ngIf="(transferee?.programDetails.policyCallRequired === false && transferee?.policyCallDetails?.policyCallStatus !== 'completed')">
      <ng-container *ngTemplateOutlet="policyCallRequiredIsFalse"></ng-container>
    </ng-container>

    <ng-template #policyCallRequired>
      <div class="policy-call" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"
        fxLayoutAlign.xs="center">
        <div fxFlex="30%" fxFlex.md="30%" fxLayout = "row" fxFlex.xs="100%" class="item item-1 candidate-review-field" (mouseover)="benefits?.length > 0 ? editProgramName = false : editProgramName = true"
          (mouseout)="!transferee.programDetails.editProgram ? editProgramName = false:null" (focus)="benefits?.length > 0 ? editProgramName = false : editProgramName = true" (focusout)="!transferee.programDetails.editProgram ? editProgramName = false:null">
          <mat-form-field>
            <mat-label>Assigned Program: </mat-label>
            <mat-select id = "programName-test" [formControl] = "programName" [disabled] = "!transferee.programDetails?.editProgram" (selectionChange) = "updatePoints($event)" *ngIf="userRole === 'file-setup-specialist' && isInProgress">
              <mat-option *ngFor="let program of programValues" [value]="program.programName">{{program.programName}}</mat-option>
            </mat-select> 
            <input class="businessUnit" readonly *ngIf="userRole !== 'file-setup-specialist' || (userRole === 'file-setup-specialist' && !isInProgress) "
              [value]="(transferee?.programDetails.hasOwnProperty('programName')) ? transferee.programDetails.programName : 'NA'"
              matInput>
          </mat-form-field> 
          <mat-icon tabindex="0" class="edit-program-name" *ngIf="userRole === 'file-setup-specialist' && isInProgress" [ngStyle]="editProgramName? (transferee.programDetails.editProgram ? {'color' : '#4F85C4','visibility': 'visible'} :{'visibility': 'visible'}) : {'visibility': 'hidden'}"
              (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
        </div>
        <div fxFlex="13%" fxFlex.md="13%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-card class="points" [ngClass]="{'mat-card-point':userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist'}">
            <span class="points-text">Points Assigned:</span><br />
            <div class="points-container" fxLayout="row" fxLayoutAlign="space-around stretch">
              <span *ngIf = '!editPoint' class="points-value">{{transferee?.programDetails.points}}</span>
              <input matInput class = 'points' number-only fxFlex="80%" fxLayoutAlign="center center" [(ngModel)] = points *ngIf = 'editPoint'>
              <mat-icon *ngIf = "userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist'" class="edit" (click)="editPointValidation()" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }">edit</mat-icon> 
            </div><!--<img class="edit-icon" alt="edit" hidden src="../../../../../../assets/images/baseline-edit-24px.svg" />-->
          </mat-card>
        </div>


        <div fxFlex="30%" fxFlex.md="30%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field policy-call-details"
        (mouseover) = "editPolicyCallDetails = true" (mouseout) = "editPolicyCallDetails = false" 
        (focus) = "editPolicyCallDetails = true" (focusout) = "editPolicyCallDetails = false" 
        role="none" aria-label="Policy call details" 
        *ngIf="userRole === 'consultant' && (candidateDetails.policyCallDetails?.hasOwnProperty('policyCallStatus')) && candidateDetails.policyCallDetails.policyCallStatus ==='scheduled'">
          <mat-form-field appearance="none" class="policy-schedule-field">
            <mat-label>Policy call details </mat-label>
           <span><span class="text-inlinment">Scheduled for </span><input matInput class = 'points text-inlinment input-inlinment' [value]="displayPolicyCallTime"><span class="text-inlinment"> {{timeZone}} </span></span>
           <span class="on-inlinement">On <input matInput style="width: auto;" class = 'points' [value]="policyCallDate | date:'MM/dd/yyyy'"></span>
          </mat-form-field> 
          <mat-icon tabindex="0" class="edit-program-name cursorPointer" [ngStyle]="editPolicyCallDetails ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
               fxLayoutAlign="end" (click)="openSchedulePolicyCallModal()">edit</mat-icon>
        </div>


        <div fxFlex="13%" fxFlex.md="13%" fxFlex.xs="100%" class="item item-1 candidate-review-field"
          style="text-align:center;margin-right:10px">
          <ng-container *ngIf="userRole !== 'consultant'; else markPolicyComplete">
            <mat-card class="policy-incomplet">Policy Call Incomplete</mat-card>
          </ng-container>
          <ng-template #markPolicyComplete>
            <button aria-label="Policy call completion" class="policy-incomplet active-card"
              (click)="makePolicyCallComplete()" mat-raised-button
              matTooltip="Policy Call can only be completed by Consultant" matTooltipPosition="after">Mark Policy Call
              Complete</button>
          </ng-template>
        </div>
        <span style="font-size: 0.7em; text-align: center;width: 15%;">
          {{(transferee?.programDetails.policyCallRequired === true) ? 'Policy Call Required for Benefit Confirmation' : 'Policy Call Not Required for Benefit Confirmation'}}
        </span>
      </div>
    </ng-template>
    <ng-template #policyCallNotRequired>
      <div class="policy-call" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"
        fxLayoutAlign.xs="center">
        <div fxFlex="30%" fxLayout = "row" fxFlex.md="30%" fxFlex.xs="100%" class="item item-1 candidate-review-field" (mouseover)="benefits?.length > 0 ? editProgramName = false : editProgramName = true"
        (mouseout)="!transferee.programDetails.editProgram ? editProgramName = false:null" (focus)="benefits?.length > 0 ? editProgramName = false : editProgramName = true" (focusout)="!transferee.programDetails.editProgram ? editProgramName = false:null">
        <mat-form-field>
          <mat-label>Assigned Program: </mat-label>
          <mat-select id = "programName" [formControl] = "programName" (selectionChange) = "updatePoints($event)" [disabled] = "!transferee.programDetails?.editProgram" *ngIf="userRole === 'file-setup-specialist' && isInProgress">
            <mat-option *ngFor="let program of programValues" [value]="program.programName">{{program.programName}}</mat-option>
          </mat-select> 
          <input class="businessUnit" readonly *ngIf="userRole !== 'file-setup-specialist' || (userRole === 'file-setup-specialist' && !isInProgress) "
            [value]="(transferee?.programDetails.hasOwnProperty('programName')) ? transferee.programDetails.programName : 'NA'"
            matInput>
        </mat-form-field>  
          <mat-icon tabindex="0" class="edit-program-name" *ngIf="userRole === 'file-setup-specialist' && isInProgress" [ngStyle]="editProgramName? (transferee.programDetails.editProgram ? {'color' : '#4F85C4','visibility': 'visible'} :{'visibility': 'visible'}) : {'visibility': 'hidden'}"
              (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>   
        </div>

        <div fxFlex="20%" fxFlex.md="20%" fxFlex.xs="100%" class="item item-1 candidate-review-field">
          <mat-card class="points"><span class="points-text">Policy Call Completed On </span><br />
            <span class="points-value">{{transferee?.policyCallDetails?.policyCallDate}}</span>
          </mat-card>
        </div>
        <div fxFlex="40%" fxFlex="40%" fxFlex.md="40%" fxFlex.xs="100%" class="item item-1 candidate-review-field">

          <table class="total-budget">
            <tr>
              <td>Total Budget</td>
              <td class="total-bold" style="width: 30%" [ngClass]="userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist' ? 'card-point-cell' : 'bg-disabled'">
                <div class="points" *ngIf = "userRole === 'client-contact-initiator'|| userRole === 'file-setup-specialist'" [ngClass]="{'mat-card-point':userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist'}">
                  <div class="points-container" fxFlex = "0 0 100%" fxLayout="row">
                    <span *ngIf = '!editPoint' class="points-value" fxFlex ="80%">{{transferee?.programDetails.points}} pts</span>
                    <input matInput class = 'points' number-only fxFlex = "0 0 80%" [(ngModel)] = points *ngIf = 'editPoint'>
                    <mat-icon fxFlex = "20%" *ngIf = "userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist'" class="edit" (click)="editPointValidation()" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }">edit</mat-icon> 
                  </div><!--<img class="edit-icon" alt="edit" hidden src="../../../../../../assets/images/baseline-edit-24px.svg" />-->
                </div>
                <div *ngIf = "userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist'">
                  {{transferee?.programDetails?.points}} Pts
                  <img class="edit-icon" alt="edit" src="../../../../../../assets/images/baseline-edit-24px.svg" />
                </div> 
              </td>
            </tr>
            <tr>
              <td>Spent Amount</td>
              <td>{{confirmedPoints}} Pts
                <!-- <img class="edit-icon" alt="info" src="../../../../../../assets/images/info-24px .svg"/> -->
              </td>
            </tr>
            <tr>
              <td>Unspent Remainder</td>
              <td class="total-bold">{{transferee?.programDetails?.points - confirmedPoints}} Pts</td>
            </tr>
          </table>

        </div>
        <div *ngIf="userRole === 'file-setup-specialist'" style="padding-right: 0;" fxFlex="15%" fxFlex.md="15%" fxFlex.xs="100%" class="item item-1 candidate-review-field"  fxLayout="column" fxLayoutAlign="end end">
          <button [disabled]="!canUnconfirmBenefit" class="unconfirm-btn" (click)="unconfirmBenefitDialog($event)" >Unconfirm All Benefits</button>
        </div>
      </div>
      <div class="move-phase" fxFlex="100%" fxLayout="column">
        <div class="before-move" *ngFor="let benefit of benefitsList" fxLayout="column" fxLayoutGap="8px">
          <ng-container *ngIf="benefit.items.length > 0; else noneBenefit">
            <p> <span class="move-phase">Move Phase:</span><span class="status"> {{benefit.group}}</span></p>
            <div class="before-move-label" *ngFor='let item of benefit.items' fxLayout="row" fxLayout.xs="column"
              fxLayoutAlign="space-between center">
              <div class='confirmed-benefit'>
                <span class="title">{{item.displayName}}</span> <span class="date"><span>Confirmed On:</span>
                  {{item.confirmedDate}}</span>
                  <button  *ngIf="this.userRole === 'file-setup-specialist'" mat-button matSuffix mat-icon-button aria-label="Unconfirm Benefit" role="button" (click)="unconfirmSelectedBenefit(item)" >
                    <mat-icon>delete</mat-icon>
                  </button>
              </div>
              <div *ngIf = "!item.value && !item.currency" class="pts"> {{item.points == 'Guaranteed' ? 'Core Benefit 0' : item.points}} Pts</div>
              <div *ngIf = "item.value && item.currency" class="pts"> {{item.points === 0 ? '0 Pts': item.points }} Point(s): {{formatReplacementValue(item.value)}} {{item.currency}}</div>
            </div>
          </ng-container>
          <ng-template #noneBenefit>
            <p> <span class="move-phase">Move Phase:</span><span class="status">{{benefit.group}}</span></p>
            <div class="shipping-label" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
              None Added
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
    <ng-template #policyCallRequiredIsFalse>
      <div class="policy-call" fxFlex="100%" fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px"
        fxLayoutAlign.xs="center">
        <div fxLayout = "row" fxFlex="30%" fxFlex.md="30%" fxFlex.xs="100%" class="item item-1 candidate-review-field" (mouseover)="benefits?.length > 0 ? editProgramName = false : editProgramName = true"
          (mouseout)="!transferee.programDetails.editProgram ? editProgramName = false:null" (focus)="benefits?.length > 0 ? editProgramName = false : editProgramName = true" (focusout)="!transferee.programDetails.editProgram ? editProgramName = false:null">
          <mat-form-field>
            <mat-label>Assigned Program: </mat-label>
            <mat-select [formControl] = "programName" id = "programName" (selectionChange) = "updatePoints($event)" [disabled] = "!transferee.programDetails?.editProgram" *ngIf="userRole === 'file-setup-specialist' && isInProgress">
              <mat-option *ngFor="let program of programValues" [value]="program.programName">{{program.programName}}</mat-option>
            </mat-select> 
            <input class="businessUnit" readonly *ngIf="userRole !== 'file-setup-specialist' || (userRole === 'file-setup-specialist' && !isInProgress) "
              [value]="(transferee?.programDetails.hasOwnProperty('programName')) ? transferee.programDetails.programName : 'NA'"
              matInput>
          </mat-form-field>  
          <mat-icon tabindex="0" class="edit-program-name" *ngIf="userRole === 'file-setup-specialist' && isInProgress" [ngStyle]="editProgramName? (transferee.programDetails.editProgram ? {'color' : '#4F85C4','visibility': 'visible'} :{'visibility': 'visible'}) : {'visibility': 'hidden'}"
              (click)="editProgramDetails()" fxLayoutAlign="end">edit</mat-icon>
        </div>
        <div fxFlex="13%" fxFlex.md="13%" fxFlex.xs="100%" fxLayout="row" fxLayoutAlign=" none" class="item item-1 candidate-review-field"
        (mouseover) = "editPolicyCallDetails = true" (mouseout) = "editPolicyCallDetails = false" 
        (focus) = "editPolicyCallDetails = true" (focusout) = "editPolicyCallDetails = false" 
        role="none" aria-label="Policy call details" 
        *ngIf="userRole === 'consultant' && (candidateDetails.policyCallDetails?.hasOwnProperty('policyCallStatus')) && candidateDetails.policyCallDetails.policyCallStatus ==='scheduled'">
          <mat-form-field appearance="none">
            <mat-label>Policy call details </mat-label>
           <span>Scheduled for <input matInput class = 'points' [value]="displayPolicyCallTime"> {{timeZone}} </span>
           <span>On <input matInput class = 'points' [value]="policyCallDate"></span>
          </mat-form-field> 
          <mat-icon tabindex="0" class="edit-program-name cursorPointer" [ngStyle]="editPolicyCallDetails ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
               fxLayoutAlign="end" (click)="openSchedulePolicyCallModal()">edit</mat-icon>
        </div>
        <div fxFlex="12%" fxFlex.md="12%" fxFlex.xs="100%" class="item item-1 candidate-review-field"
          style="text-align:center;margin-right:10px">
          <ng-container *ngIf="userRole !== 'consultant'; else markPolicyComplete">
            <mat-card class="policy-incomplet">Policy Call Incomplete</mat-card>
          </ng-container>
          <ng-template #markPolicyComplete>
            <button aria-label="Policy call completion" class="policy-incomplet active-card"
              (click)="makePolicyCallComplete()" mat-raised-button
              matTooltip="Policy Call can only be completed by Consultant" matTooltipPosition="after">Mark Policy Call
              Complete</button>
          </ng-template>
          <br>
          <span style="font-size: 0.7em; text-align: center;width: 15%;">
            {{(transferee?.programDetails.policyCallRequired === true) ? 'Policy Call Required for Benefit Confirmation' : 'Policy Call Not Required for Benefit Confirmation'}}
          </span>
        </div>
        <div fxFlex="30%" fxFlex="30%" fxFlex.md="30%" fxFlex.xs="100%" class="item item-1 candidate-review-field right-space-remover">
          <table class="total-budget">
            <tr>
              <td>Total Budget</td>
              <td class="total-bold" style="width: 30%"  [ngClass]="userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist' ? 'card-point-cell' : 'bg-disabled'">
                <div class="points" *ngIf = "userRole === 'client-contact-initiator'|| userRole === 'file-setup-specialist'" [ngClass]="{'mat-card-point':userRole === 'client-contact-initiator'|| userRole === 'file-setup-specialist'}">
                  <div class="points-container" fxFleX= "0 0" fxLayout="row" fxLayoutAlign="space-around stretch">
                    <span *ngIf = '!editPoint' class="points-value" fxFlex = "80%">{{transferee?.programDetails.points}} pts</span>
                    <input matInput class = 'points' number-only fxFlex="0 0 80%" fxLayoutAlign="center center" [(ngModel)] = points *ngIf = 'editPoint'>
                    <mat-icon fxFlex="0 0 20%" *ngIf = "userRole === 'client-contact-initiator' || userRole === 'file-setup-specialist'" class="edit" (click)="editPointValidation()" fxLayoutAlign="end" [ngStyle] = "{'color':editPoint ? '#4F85C4':'#ffffff' }">edit</mat-icon> 
                  </div><!--<img class="edit-icon" alt="edit" hidden src="../../../../../../assets/images/baseline-edit-24px.svg" />-->
                </div>
                <div *ngIf = "userRole !== 'client-contact-initiator' && userRole !== 'file-setup-specialist'">
                  {{transferee?.programDetails?.points}} Pts
                  <img class="edit-icon" alt="edit" src="../../../../../../assets/images/baseline-edit-24px.svg" />
                </div>
              </td>
            </tr>
            <tr>
              <td>Spent Amount</td>
              <td>{{confirmedPoints}} Pts</td>
            </tr>
            <tr>
              <td>Unspent Remainder</td>
              <td class="total-bold">{{transferee?.programDetails?.points - confirmedPoints}} Pts</td>
            </tr>
          </table>
        </div>
        <div style="padding-right: 0;" fxFlex="14%" fxFlex.md="14%" fxFlex.xs="100%" class="item item-1 candidate-review-field"  fxLayout="column" fxLayoutAlign="end end">
          <button [disabled]="canUnconfirmBenefit" class="unconfirm-btn" (click)="unconfirmBenefitDialog($event)" >Unconfirm All Benefits</button>
        </div>
      </div>
      <div class="move-phase" fxFlex="100%" fxLayout="column">
        <div class="before-move" *ngFor="let benefit of benefitsList" fxLayout="column" fxLayoutGap="8px">
          <ng-container *ngIf="benefit.items.length > 0; else noneBenefit">
            <p>
              <span class="move-phase">Move Phase:</span>
              <span class="status"> {{benefit.group}}</span>
            </p>
            <div class="before-move-label" *ngFor='let item of benefit.items' fxLayout="row" fxLayout.xs="column"
              fxLayoutAlign="space-between center">
              <div class="confirmed-benefit">
                <span class="title">{{item.displayName}}</span> <span class="date"><span>Confirmed On:</span>
                  {{item.confirmedDate}}</span>
                  <button mat-button matSuffix mat-icon-button aria-label="Unconfirm Benefit" role="button" (click)="unconfirmSelectedBenefit(item)" >
                    <mat-icon>delete</mat-icon>
                  </button>
              </div>
              <div *ngIf = "!item.value && !item.currency" class="pts"> {{item.points == 'Guaranteed' ? 'Core Benefit 0' : item.points}} Pts</div>
              <div *ngIf = "item.value && item.currency" class="pts"> {{item.points === 0 ? '0 Pts': item.points }} Point(s): {{formatReplacementValue(item.value)}} {{item.currency}}</div>
            </div>
          </ng-container>
          <ng-template #noneBenefit>
            <p> <span class="move-phase">Move Phase:</span><span class="status">{{benefit.group}}</span></p>
            <div class="shipping-label" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="center center">
              None Added
            </div>
          </ng-template>
        </div>
      </div>
    </ng-template>
  </mat-panel-description>
  <!--Download-->
  <div class="download" >
    <div>Download:
        <mat-icon class="download-icon cursorPointer" (click)="openDownloadToastr('benefitBuilderDetail')">save_alt</mat-icon>
    </div>
  </div>
</mat-expansion-panel>

<!--insurance Details-->
<mat-expansion-panel *ngIf="this.userRole !== 'client-contact-initiator' && benefitsHVG?.length"
  class="benefit-builder-details candidate-review-panel">
  <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" fxLayout
    fxLayout="row" fxLayoutGap="10px">
    <div fxFlex="34%" fxFlex.xs="100%" class="panel-heading">Insurance Details</div>
  </mat-expansion-panel-header>
  <mat-panel-description class="business-unit">
    <div class="insurance-details" *ngFor="let hvgbenefit of benefitsHVG" fxFlex="100%">
      <!--Shipment Section-->
      <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%">
        <div>
          <span class="shipment">Shipment Type: </span>
          <span class="title-bold">{{hvgbenefit.displayName}}</span>
        </div>
        <div class="statusHGV">
          <span class="text-style">Status: </span>
          <span class="title-bold">{{ hvgbenefit.hvgDetail.status}}</span>
          <mat-icon
          *ngIf="hvgbenefit.hvgDetail.status !== 'In Progress'"
           matTooltip="{{
            userRole==='consultant' 
            ? 'Unlock Form for Transferee to continue editing' 
            : 'Form can only be unlocked by Consultant' }}"
           class="cursorPointer" 
           (click)="unlockSubmittedHighValueGoodsPopup(hvgbenefit)">lock_open</mat-icon>
        </div>
      </div>
      <div class="margintop" fxLayout="row" fxLayoutAlign="space-between center" fxFlex="100%">
        <div>
          <span class="date" style="font-size: 16px;"><span>Confirmed On:</span>
            {{hvgbenefit.confirmedDate}}</span>
        </div>
        <div>
          <span class="text-style " *ngIf="hvgbenefit.hvgDetail.status !== 'Opted Out'">Total:</span>
          <span class="text-style " *ngIf="hvgbenefit.hvgDetail.status === 'Opted Out'">Opted Out On:</span>
          <span class="title-bold"
            *ngIf="hvgbenefit.hvgDetail.status !== 'Opted Out'">${{hvgbenefit.hvgDetail.totalReplacementValue}}</span>
          <span class="date" style="font-size: 16px;"
            *ngIf="hvgbenefit.hvgDetail.status === 'Opted Out'">{{hvgbenefit.hvgDetail.date}}</span>
        </div>
      </div>
      <!--hvg items box-->
      <div *ngIf="hvgbenefit.hvgDetail.highValueItems?.length > 0" fxFlex="100%">
        <div class="hvgItems" *ngFor="let item of hvgbenefit.hvgDetail.highValueItems">
          <div fxLayout="row" fxLayoutAlign="space-between start" fxFlex="100%">
            <div class="space-left"><span class="title-bold">{{item.name}}</span></div>
            <div><span class="text-style">Replacement Value: </span>
              <span class="title-bold space-right">${{formatReplacementValue(item.replacementValue)}}</span>
            </div>
          </div>
          <div class="margintop" fxLayout="row" fxLayoutAlign="space-between" fxFlex="100%">
            <div fxLayout="column" fxLayoutAlign="space-around start" fxFlex="35%">
              <div class="space-left "> <span class="text-style"> Description of item </span> </div>
              <div class="space-left" style="margin-right: 28px;"><span class="title">{{item.description}}</span> </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="56%">
              <div><span class="text-style">ID Number</span> </div>
              <div><span class="title">{{item.serialNumber}}</span> </div>
            </div>
            <div fxLayout="column" fxLayoutAlign="start" fxFlex="9%">
              <div class="space-right "><span class="text-style">Date Added</span> </div>
              <div class="space-right"><span class="title">{{item.dateAdded}}</span> </div>
            </div>
          </div>
        </div>
      </div>
      <!--none added block-->
      <div class="move-phase" *ngIf="hvgbenefit.hvgDetail.highValueItems?.length === 0">
        <div class="shipping-label" fxLayout="row" fxLayoutAlign="center center" fxFlex="100%">
          None Added
        </div>
      </div>
      <!--line divider-->
      <div class="space"></div>
    </div>
    <!--Download-->
    <div class="download">
      <div>Download:
          <mat-icon class="download-icon cursorPointer" (click)="openDownloadToastr()">save_alt</mat-icon>
      </div>
    </div>
  </mat-panel-description>
</mat-expansion-panel>

<!-- Milestones -->
<mat-accordion  class="candidate-review-panel move-departure-panel"  multi="true" *ngIf = "candidateDetails.authorizationType === 'Traditional' ">
  <mat-expansion-panel *ngIf = " this.milestonesData?.length > 0" class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" >
      <div fxFlex="34%" class="panel-heading">Milestones</div>
    </mat-expansion-panel-header>
    <div style="width: 100%;">
    <app-linked-list [dataSet]="milestonesData"></app-linked-list>
  </div>
  </mat-expansion-panel>
</mat-accordion>
 
<!-- sentiments -->
<mat-accordion  class=" candidate-review-panel move-departure-panel" multi="true"  *ngIf="candidateDetails.authorizationType === 'Traditional' ">
  <mat-expansion-panel *ngIf = "this.sentimentData?.length > 0 " class="move-detail-panel">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight" >
      <div fxFlex="34%" class="panel-heading">Sentiment</div>
    </mat-expansion-panel-header>
    <app-linked-list [dataSet]="sentimentData"></app-linked-list>
  </mat-expansion-panel>
</mat-accordion>

<!--Move Phase and Risk starts -->
<mat-accordion *ngIf="candidateDetails.authorizationType === 'Traditional' && fileNumber && movePhaseAndRiskData" class="candidate-review-panel move-departure-panel" multi="true">
  <mat-accordion 
    class="candidate-review-panel" multi="true">
    <mat-expansion-panel class="move-detail-panel">
      <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight">
        <div fxFlex="34%" class="panel-heading">Move Phase & Risk</div>
      </mat-expansion-panel-header>
      <mat-panel-description class="remove-margin">
        <div fxFlex="100%" fxLayoutAlign="start center" fxLayoutGap="30px">
          <!--Move Phase start-->
          <div fxFlex="30%">
            <app-employee-move-phase 
            [traditional]="true"
            [selectedMovePhaseNo]= "movePhaseAndRiskData.moveRiskVal">
            </app-employee-move-phase>
          </div>
          <!--Move Phase end-->
          <!--Move Risk start-->
          <div fxFlex="30%">
            <app-move-risk-modal
             [traditional]="true"
             [movePhaseAndRiskData]="movePhaseAndRiskData">
            </app-move-risk-modal>
          </div>
           <!--Move Risk end-->
        </div>
      </mat-panel-description>
    </mat-expansion-panel>
  </mat-accordion>
</mat-accordion>
<!--Move Phase and Risk end -->

<!-- providers -->
<div class="providers" multi="true" *ngIf="candidateDetails.authorizationType === 'Traditional' && fileNumber">
  <mat-card>
     <div class="text-style" fxLayout="row" fxFlex fxLayout.xs="column" fxLayoutAlign="space-between center">
      <div>Providers : <span class="left-margin-style">{{providerCount}}</span></div>
      <div><button mat-button class="contained-button button-width hand-cursor"  (click)="viewProviders()"> View Providers
        </button></div>
    </div>  
  </mat-card>

  <div *ngIf=isProvider  fxLayout="row" fxLayout.xs="column">
   
    <div *ngIf="fileNumber" >
       <div style="display: flex !important;">
        <span> <mat-icon class="hand-cursor" (click)="leftArrowClick()">keyboard_arrow_left</mat-icon> </span>
        <h1 class="provider-heading"> <span>Providers</span></h1>
        <span> <mat-icon class="hand-cursor" *ngIf="!isSearch" (click)="openSearch()"> search </mat-icon> </span>
       </div>
     
        <mat-form-field *ngIf="isSearch" appearance="outline" class="searchInput" >
          <span matPrefix class="lg-only" role="none">
            <mat-icon class="hand-cursor" (click)="closeSearch()">search</mat-icon>
          </span>
          <input  matInput tabindex="0" placeholder="Search search with in the table for.." [(ngModel)]="searchProvider"
            aria-label="Search by name" role="text">
          <button tabindex="0" mat-button matSuffix mat-icon-button aria-label="Clear" class="close-btn"
            [hidden]="!searchProvider" (click)="clearTextSearch()" role="button">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      
      <app-employee-providers [orderRequestId]="currOrderRequestId" [fileNumber]= 3039191 [traditional]="true"
        [searchProvider]="searchProvider" (providerCount)="getProviderCount($event)">
      </app-employee-providers>
    </div>
  </div>
</div>
<!-- providers end-->
 <!--Transactions Section -->
 <div class="transaction" *ngIf="candidateDetails.authorizationType === 'Traditional' && fileNumber">
  <mat-card >
    <div class="trans-cost-row" fxLayout="row" fxFlex fxLayout.xs="column" fxLayoutAlign="space-between center">
      <div style="display: flex !important;">Total Cost:  <span class="left-margin-style">
          <span>{{displayTransactionAmtValue(transactionAmount)}}</span> <span class="cur-margin">{{transactionCurrency}}</span></span>
      </div>
      <div><button mat-button class="contained-button button-width hand-cursor" (click)="viewTransaction()">View Transactions</button></div>
    </div>
  </mat-card>
  <!-- <div class="transaction-table" [ngClass]="{'display-none': !isTransactionShow }" fxLayout="row" fxLayout.xs="column"> -->
  <div class="transaction-table" *ngIf= isTransactionShow  fxLayout="row" fxLayout.xs="column">
    <div fxFlex="1">
      <mat-icon class="user-water-height" (click)="leftArrowClick()">keyboard_arrow_left</mat-icon>
    </div>
    <!-- <div *ngIf="fileNumber && isTransactionShow" fxFlex="99"> -->
    <div  fxFlex="99">
      <app-employee-transactions [orderRequestId]="currOrderRequestId" [fileNumber]= 4109145 [traditional]="true"
        (sendTotalBillAmount)="getTransactionAmt($event)" (sendTotalBillCurrency)="getTransactionCurrency($event)">
      </app-employee-transactions>
    </div>
  </div>
</div>
<!--Transactions Section end-->

<!--Work Order-->
<mat-accordion class=" candidate-review-panel move-departure-panel main-panel  " multi="true"
  *ngIf="accordinData?.length">
  <mat-expansion-panel class="move-detail-panel" [expanded]="true">
    <mat-expansion-panel-header [collapsedHeight]="'auto'" [expandedHeight]="matExpansionHeaderHeight">
      <div fxFlex="34%" class="panel-heading">Work Orders</div>
    </mat-expansion-panel-header>
    <!--Temporary living Accomodation-->
    <mat-accordion class=" candidate-review-panel move-departure-panel border-radius" [togglePosition]="'before'"
      multi="true">
      <mat-expansion-panel class="move-detail-panel work-expansion-panel" [expanded]="true"
        *ngFor="let benefits of accordinData; let i =index">
        <mat-expansion-panel-header class="panel-color" [collapsedHeight]="'auto'"
          [expandedHeight]="matExpansionHeaderHeight">
          <div fxFlex="100%" fxLayout="row" fxLayoutAlign="space-between none" class="work-panel-heading ">
            <p style="color: #2065F8;">{{benefits.displayName}}</p>
          </div>
          <div fxLayout fxFlex="60" fxFlex.sm="100" fxFlex.xs="100" fxLayoutAlign.sm="start center"
            fxLayoutAlign="end center" class="xs-none">
            <div fxLayout="row" class="launch-text">
              <span class="launch">Added by:</span>
              <span class="added-by">{{benefits.supplierInfo.lastUpdatedBy}}</span>
              <span class="added-by">on</span>
              <span class="added-by">{{benefits.supplierInfo.lastUpdatedAt}}</span>
            </div>
          </div>
        </mat-expansion-panel-header>
        <table style="width: 100%;" mat-table [dataSource]="benefits?.supplierInfo?.mergedSupOrdAndOrd">
          <!-- reference Column -->
          <ng-container matColumnDef="orderno">
            <th mat-header-cell *matHeaderCellDef> Reference # </th>
            <td mat-cell (click)="workDetailPoup(false,element.supplierRequestId, i,'add', woi, element.isSupplierOrd ? true:false,!element.isSupplierOrd ? element.supplierOrderReference : null,!element.isSupplierOrd ? element.modification : null)" class="reference-no"  style="padding-right: 42px;"
              *matCellDef="let element; let woi = index">
              <span *ngIf="element.isSupplierOrd">
                <span *ngIf="element.status=='paused'"><a> Request order {{ benefits.supplierInfo.supplierOrderRequests.length - woi}} </a></span>
                <span *ngIf="element.status !='paused'"> Request order {{ benefits.supplierInfo.supplierOrderRequests.length - woi}}</span>
              </span>
              <span *ngIf="!element.isSupplierOrd"><a>{{element.supplierOrderReference}} </a> </span> 
            </td>
          </ng-container>
          <!-- Provider Column -->
          <ng-container matColumnDef="supplier">
            <th mat-header-cell *matHeaderCellDef> Provider Name </th>
            <td mat-cell *matCellDef="let element">{{element.providerName?element.providerName:'None'}} </td>
          </ng-container>

          <!-- Booking Agent Column -->
          <ng-container matColumnDef="agent">
            <th mat-header-cell *matHeaderCellDef> Booking Agent </th>
            <td mat-cell *matCellDef="let element"> {{element.bookingAgent?element.bookingAgent:null}} </td>
          </ng-container>
          <!--Modification-->
          <ng-container matColumnDef="modification">
            <th mat-header-cell *matHeaderCellDef> Modifications </th>
            <td mat-cell *matCellDef="let element"> <span style="color:#4b85ff;"(click)="workDetailPoup(true,element.supplierRequestId, i,'add', woi, element.isSupplierOrd ? true:false,!element.isSupplierOrd ? element.supplierOrderReference : null,!element.isSupplierOrd ? element.modification : null)">{{ !element.isSupplierOrd ? element.modification : 0 }}</span><span  *ngIf="checkForModificationStatus(element) && !element.isSupplierOrd"><mat-chip class="modification-chip">New!</mat-chip></span></td>
          </ng-container>
          <!-- status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef> Status </th>
            <td mat-cell *matCellDef="let element">{{element.status | titlecase}} <span *ngIf="element.status=='paused'"
                matTooltip="API Failure , please re-submit. If second failure, Open a help-desk ticket.">
                <mat-icon style="color: #ff0000;" class="cursorPointer">warning</mat-icon>
              </span>
              <div *ngIf = "element.statusDate" style="font-size: 0.75em;">on {{element.statusDate}}</div>
            </td>
          </ng-container>
          <!--Action column-->
          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element; let woi = index">
              <span>
                <mat-icon class="cursorPointer" matTooltip="Cancel" *ngIf="element.status=='in-progress' || element.status=='open'&&element.isSupplierOrd || element.status=='paused' && element.isSupplierOrd ||element.status=='pending' && !element.isSupplierOrd" (click)="deleteWorkorder(element.supplierRequestId,i, woi,element.isSupplierOrd ? true:false)">delete</mat-icon>
              </span>
              <!-- <mat-icon class="edit-column" *ngIf="element.status=='paused'" size="20">edit</mat-icon> -->
              <img class="action-colmn" *ngIf="element.status=='paused'" alt="edit"
                (click)="workDetailPoup(false,element.supplierRequestId, i,'add', woi, true)" matTooltip="Edit request"
                src="../../../../../../assets/icons/bb_icons/edit-24px.svg" />
              <img class="action-colmn" matTooltip="{{element.viewAction==true ? 'View Booking Details':'Enter Booking Details'}}"
                (click)="bookingDetailPopup(element.supplierRequestId,i, woi,element.supplierOrderReference)"
                *ngIf="element.status=='pending' || (!element.isSupplierOrd && element.status=='in-progress')" alt="info" src="../../../../../../assets/icons/bb_icons/booking info-24px.svg" />
              <!-- <mat-icon class="action-colmn" matTooltip="{{element.viewAction==true ? 'View Booking Details':'Enter Booking Details'}}"
                              *ngIf="element.status=='pending' || element.status=='in-progress'">info_outline</mat-icon> -->
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <button class="add-btn" [disabled]="checkForDisable(i)" (click)="add(i,benefits)" mat-button>Add</button>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-expansion-panel>
</mat-accordion>
<!--Work Order end-->