import { Component, OnInit, Injector, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { contexts } from '../../../../core/data/contextData';
import { candidateProfile } from '../../../../core/models/candidate.model';

@Component({
  selector: 'app-show-override-history',
  templateUrl: './show-override-history.component.html',
  styleUrls: ['./show-override-history.component.scss']
})
export class ShowOverrideHistoryComponent implements OnInit {

/** title for pop up */
title: string = 'Points Override Info';
candidateProfile: candidateProfile = {} as candidateProfile;
loggedInUser = contexts;

  constructor(
    private readonly spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<ShowOverrideHistoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: candidateProfile,
    private injector: Injector,
  ) { }

  ngOnInit() {
    this.candidateProfile = this.data
    //this.spinner.show();
  }

  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    this.dialogRef.close();
  }


}
