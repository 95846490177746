import { Component, OnInit, Inject, Injector, ViewChild, Output, NgZone, ElementRef, ViewContainerRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ControlContainer, FormControl, ValidationErrors, FormArray } from '@angular/forms';
import { LocationsService } from '../../services/locations.service';
import { ClientContactsService } from './../../services/client-contacts.service';
import { Country } from '../../../../core/models/country.model';
import { DatePipe, TitleCasePipe, ɵNullViewportScroller } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { debounceTime, isEmpty } from 'rxjs/operators';
import { Locations } from '../../../../core/models/locations.model';
import { candidateProfile,AdditionalDetail, candidateProfileMain } from '../../../../core/models/candidate.model';
import { AdminClientContacts } from '../../../../core/models/admin-client-contacts.model';
import { phoneDetails, phoneTypes } from '../../../../core/models/phone.model';
import { emailDetails, emailTypes } from '../../../../core/models/email.model';
import { shortAddress } from '../../../../core/models/address.model';
import { CandidateProfileService } from './../../services/candidate-profile.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ProgramManagementService } from './../../services/program-management.service';
import { MAT_DATE_FORMATS, } from '@angular/material/core';
import { UserTypeService, UserType } from '../../services/user-type.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import {map, startWith} from 'rxjs/operators';
import { validateBasis } from '@angular/flex-layout';
import { validateHorizontalPosition } from '@angular/cdk/overlay';
import PlaceResult = google.maps.places.PlaceResult;
import { MapsAPILoader } from '@agm/core';
import * as moment from 'moment';
import { AddEditSharedService } from './../../services/add-edit-shared.service';
import { ConfigurableFieldService, ConfigurableField, fieldMapping } from './../../services/configurable-field.service';
import { cc_divisionPrograms } from '../../../../core/data/divisionData';
/** response for createCandidate service call */
export const createCandidateResponse = `Added Transferee Successfully`;
/** response for updateCandidate service call */
export const updateCandidateResponse = `Updated Transferee Successfully`;
/** response for sendInviteToCandidate service call */
export const sendInviteResponse = `Authorization Initiated Successfully`;
/** response for resendInviteToCandidate service call */
export const resendInviteResponse = `Authorization Re-Initiated Successfully`;
/** response for cost projection createCandidate service call */
export const createCPCandidateResponse = `Cost Projection Requested`;
/** response for cost projection updateCandidate service call */
export const updateCPCandidateResponse = `Cost Projection Draft Saved`;
/** response for cost projection notApprove service call */
export const approveNotCPCandidateResponse = `Cost Projection Not Approved`;
/** response  cost projection sendInviteToCandidate service call */
export const sendCPInviteResponse = `Invitation Sent`;
/** Add cost projection title - bb */
export const addBBCostProjectionTitle = 'Add Cost Projection: Benefits Builder Move';
/** Add cost projection title - traditional */
export const addTradCostProjectionTitle = 'Add Cost Projection: Traditional Move';

type ResetStates = { departureStateList: Array<any>, destinationStateList: Array<any> };
type updatedKeyInterface = { group: 'departureAddr' | 'destinationAddr', type: 'country' | 'city' | 'state' | 'streetLine1' | 'streets' | 'cities' | 'states' };
// export const CUSTOM_YYYYMMDD = {
//   parse: {
//     dateInput: 'LL',
//   },
//   display: {
//     dateInput: 'YYYY-MMM-DD',
//     monthYearLabel: 'YYYY',
//     dateA11yLabel: 'LL',
//     monthYearA11yLabel: 'YYYY',
//   },
// };
@Component({
  selector: 'app-add-edit-transferee',
  templateUrl: './add-edit-transferee.component.html',
  styleUrls: ['./add-edit-transferee.component.scss'],
  // providers: [
  //   // {
  //   //   provide: DateAdapter,
  //   //   useClass: MomentDateAdapter,
  //   //   deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
  //   // },
  //    {provide: MAT_DATE_FORMATS, useValue: CUSTOM_YYYYMMDD},
  // ],
})
export class AddEditTransfereeComponent implements OnInit {
  /**Form group name */
  addCandidateForm: FormGroup;
  /**Countries retrieved from service */
  countryList: Array<Country>;
  countryNameList: any[];
  /**Currency retrieved from service */
  currencyList: Array<Country> = [];
  /**Subscription to observe location form */
  locationFormObserver: Subscription;
  /**to disable close button when snackbar is opened */
  formDisable = false;
  /** Title of form being displayed */
  title: string;
  /** User name title values */
  titleValues: string[] = ['Dr.', 'Mr.', 'Mrs.', 'Ms.', 'Miss'];
  /** For job start date manipulation */
  jobStartDate: any;
  /** List of cities to be populated in departure city list */
  departureCityList: Array<any> = [];
  /** List of cities to be populated in destination city list */
  destinationCityList: Array<any> = [];
  /** List of cities to be populated in departure state list */
  departureStateList: Array<any> = [];
  /** List of cities to be populated in destination state list */
  destinationStateList: Array<any> = [];
  /** Stores value in address form */
  addressFormValue: any;
  /** Country code of selected departure country */
  departureCountryCode: string;
  /** Country code of selected destination country */
  destinationCountryCode: string;
  /** To set departure country to read only when country is empty */
  hasDepartureCountry: boolean;
  /** To set destination country to read only when country is empty */
  hasDestinationCountry: boolean;
  /** Object to store candidate profile details */
  candidateProfile: candidateProfile = {} as candidateProfile;

  dismissWarningMsg: string = 'Dismissing will remove this transferee record ?';
  /** flag to render edit or add form */
  isEdit: boolean = false;
  /** flag to check if the invitation has been sent */
  invitationSent: boolean = false;
  /** Flag to display save draft or save changes */
  isSaveDraft: boolean = true;
  /* Flag to toggle between Approve Move and Move Not Approve Button when status is Requested and CP In Progress  */
  isMoveNotApprove:boolean= false;
    /**Flag for display cancel when status is Approved or Not Approved */
  isApproved:boolean=true;
  /** Dynamic text to be displayed in send invite button */
  sendInviteBtnText: string;
  /** Dynamic text to be displayed in form title */
  formTitle: string;
  editCandidateProfile: candidateProfile = {} as candidateProfile;
  /** Stored initial form value during load to check for changes */
  initialFormValue: any = {} as any;
  /** Store Division array  */
  divisionValues: any[];
  /** Store program array  */
  programValues: any[];
  /**edit point flag */
  editPoint: boolean;
  /**points */
  points: number;
  hasDepartureStreet: boolean;
  hasDepartureCity: boolean;
  hasDepartureState: boolean;
  hasDestinationStreet: boolean;
  hasDestinationCity: boolean;
  hasDestinationState: boolean;
  departureCountrycodes = [];
  departCountryCode = '';
  destiCountryCode = '';
  userRole: UserType;
  transferee: any;
 filteredOptions: Observable<Country[]>;
  filteredCountryOptions: Observable<Country[]>;
  filteredDestCountryOptions: Observable<Country[]>;
  filteredClientContactDetails: Observable<any[]>;
  filteredCurrency: Observable<Country[]>;
  filteredCurrencyCode: Observable<Country[]>;
  filteredDefaultHostCountry: Observable<Country[]>;
  filteredDefaultHomeCountry: Observable<Country[]>;
   // filteredSingleSelect: Observable<any[]>;
   filteredSingleSelect: any [] = [];
  @ViewChild('autoCompleteCountryInput', { read: MatAutocompleteTrigger, static: true }) autoCompleteCountry: MatAutocompleteTrigger;
  @ViewChild('autoCompleteInput', { read: MatAutocompleteTrigger, static: true }) autoComplete: MatAutocompleteTrigger;
  @ViewChild('autoCompleteDestCountryInput', { read: MatAutocompleteTrigger, static: true }) autoCompleteDestCountry: MatAutocompleteTrigger;
  @ViewChild('autoCompleteCci', { read: MatAutocompleteTrigger, static: true }) autoCompleteCci: MatAutocompleteTrigger;
  //@ViewChild('autoCompleteConsultant', { read: MatAutocompleteTrigger, static: true }) autoCompleteConsultant: MatAutocompleteTrigger;
  @ViewChild('departStreet', {static: false})
  public departStreetElementRef: ElementRef;
  cciDetails: any[];
  currency: any[];
  /** to distinguish transferee as traditional or benefit builder */
   isTraditional: boolean= false;
   isCostProjection: boolean = false;
   /**to disable all fields cost projection other then FSU*/
   disableAllFields: boolean = false;
   /** store empoyee type */
   employeeTypeValue: any[];
   /**client party id for the corrosponding transferee */
  clientPartyId: string;
  /**configurable fields for the transferee */
  configurableFields: ConfigurableField[];
  multipleOriginalDropDowns: any = [];
  additionalDetailsFormGroup:FormGroup;
  /**File Priority values for traditional transferee */
  filePriorityValues = ['Exec VIP', 'Normal', 'Premier Executive', 'Rush', 'VIP'];
  isUS_SelectedCheck: boolean = false; // to check either of depart/dest country is US
  isUS_Move: boolean = false; // to check for job start date of traditional transferee(both should be US)
  /** store policy array */
  policyValues: any[];

  deliveryTeamModelValues: any[];
  initialContactByValues: any[];
  timeZoneValues: any[];
  contractOriginalList: any[];
  contractList: any[]
  moveTypeValues;
  billiingCurrency: any[];
   /** List of phone Types for selection */
   phoneTypes: Array<Array<string>> = [
    [...phoneTypes]
  ];
  /** List of email Types for selection */
  emailTypes: Array<Array<string>> = [
    [...emailTypes]
  ];
  cciValues: any[];
  /**
   * Initializes form elements
   * @param formBuilder - property for form elements
   * @param locationService - location service
   * @param dialogRef - instance of dialog
   * @param data - data from list of transferees
   * @param spinner - inject spinner
   * @param injector - inject injector
   * @param candidateProfileService - inject service to add candidate details
   * @param dialog - dialog to be opened on dismiss
   * @param toastrService - inject toastrService
   *
   */
  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly locationService: LocationsService,
    private readonly clientContactsService: ClientContactsService,
    public dialogRef: MatDialogRef<AddEditTransfereeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: candidateProfileMain,
    private readonly spinner: NgxSpinnerService,
    private injector: Injector,
    private candidateProfileService: CandidateProfileService,
    private programManagementService: ProgramManagementService,
    public dialog: MatDialog,
    private readonly toastrService: ToastrService,
    private userTypeService: UserTypeService,
    public live: LiveAnnouncer,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private configurableFieldService : ConfigurableFieldService,
    private addEditSharedService: AddEditSharedService
  ) {
    dialogRef.disableClose = true;
  }


  ngOnInit() {
    
    this.userRole = this.userTypeService.userType;
    this.editCandidateProfile = this.data.candidate;
    this.editPoint = false;
    this.isTraditional = this.data.isTraditional;
    this.isCostProjection = this.data.isCostProjection;
    this.countryList = this.locationService.countryList.getValue();

    this.addCandidateForm = this.populateForm(this.editCandidateProfile);
    if (this.addCandidateForm.get('email')) {
      const {
        emailTypes
      } = this.addEditSharedService.
        initEmailList(
          (this.addCandidateForm.controls.email as FormGroup).value
          , this.emailTypes);
      this.emailTypes = emailTypes;
    }
    if (this.addCandidateForm.get('phone')) {
      const {
        phoneTypes
      } = this.addEditSharedService.
        initPhoneList(
          (this.addCandidateForm.controls.phone as FormGroup).value
          , this.phoneTypes)
      this.phoneTypes = phoneTypes;
    }
    // if (this.editCandidateProfile && (this.editCandidateProfile.moveStatus == 'Invitation Sent' || this.editCandidateProfile.moveStatus == 'Authorization Initiated')) {
    //   this.isSaveDraft = false;
    //   this.invitationSent = true;
    //   this.sendInviteBtnText = 'Re-Initiate Authorization';
    // } else {
    //   this.isSaveDraft = true;
    //   this.invitationSent = false;
    //   this.sendInviteBtnText = 'Initiate Authorization';
    // }
    // this.invitationSent = (this.editCandidateProfile && this.editCandidateProfile.moveStatus == 'Invitation Sent') ? true : false;

    //this.formTitle = (!this.editCandidateProfile ? 'Add Transferee' : 'Edit Transferee');
    //this.saveDraftBtnText = (!this.editCandidateProfile ? 'Save Draft' : 'Save Changes');
    // this.sendInviteBtnText = (!this.editCandidateProfile ? 'Send Invite' : 'Resend Invite');

    if(this.data.editTransferee) {  //edit modal
      if(this.data.candidate.authorizationType === 'Traditional') {
        this.formTitle = this.isCostProjection ? addTradCostProjectionTitle : 'Edit Traditional Transferee';
        this.isTraditional = true;
      } else {
        this.formTitle = this.isCostProjection ? addBBCostProjectionTitle : 'Edit Benefits Builder Transferee';
        this.isTraditional = false;
      }
    } else {    // add modal
      this.isCostProjection? this.formTitle = this.data.isTraditional? addTradCostProjectionTitle: addBBCostProjectionTitle
      : this.formTitle = this.data.isTraditional? 'Add Traditional Transferee': 'Add Benefits Builder Transferee';
    }
    if (this.editCandidateProfile && this.editCandidateProfile.moveStatus == 'Authorization Initiated') {
      this.isSaveDraft = false;
      this.invitationSent = false;
      this.sendInviteBtnText = 'Initiate Authorization';
    } else if (this.editCandidateProfile && this.editCandidateProfile.moveStatus == 'Invitation Sent') {
        this.isSaveDraft = false;
        this.invitationSent = true;
        this.sendInviteBtnText = 'Re-send Invitation';
    } else if (this.editCandidateProfile && this.editCandidateProfile.moveStatus == 'Not Requested') {
        this.isSaveDraft = true;
        this.invitationSent = false;
        this.sendInviteBtnText = 'Create Request';
    } else if(this.editCandidateProfile && (this.editCandidateProfile.moveStatus=='Requested' ||this.editCandidateProfile.moveStatus=='CP In Progress')){
        this.isSaveDraft = false;
        this.invitationSent= false;
        this.isMoveNotApprove=true;
        this.sendInviteBtnText='Approve Move';
    } else if(this.editCandidateProfile && (this.editCandidateProfile.moveStatus=='Approved' || this.editCandidateProfile.moveStatus=='Not Approved')){
      this.isSaveDraft = false;
      this.invitationSent= false;
      this.isApproved=false;
    }
    else {
      this.isSaveDraft = true;
      this.invitationSent = false;
      this.isCostProjection? this.sendInviteBtnText = 'Create Request': this.sendInviteBtnText = 'Initiate Authorization';
    }

    
    if(this.isTraditional) {
      this.candidateProfileService.getEmployeeType().subscribe(res => {
        this.employeeTypeValue = res.values;
      })
    }

    if(this.isTraditional) {
      this.candidateProfileService.getValueList().subscribe(res => {
        if(res) {
          if(res.EmployeeType){ this.employeeTypeValue = res.EmployeeType.values; }
          if(res.InitialContactBy) { this.initialContactByValues = res.InitialContactBy.values; }
          if(res.TraditionalAuthTimeZone) { this.timeZoneValues = res.TraditionalAuthTimeZone.values; }
          if(res.DeliveryTeamModel) { this.deliveryTeamModelValues = res.DeliveryTeamModel.values }
        }
      })
    }

    this.candidateProfileService.getClientContactList().subscribe(res => {
      if(res) {
          this.cciValues = res;
      }
    });

    this.candidateProfileService.getContractList().subscribe(res => {
      if(res) {
        this.contractListProcess(res);
      }
    });

    this.candidateProfileService.getMoveList().subscribe(res => {
      if(res) {
          this.moveTypeValues = res;
      }
    });
	
    this.locationSearchInit();
    this.initialFormValue = this.addCandidateForm.value; // set initial value
    this.locationService.countryList.subscribe(countryList => {
      /* this.autoComplete.autocompleteDisabled = (this.userRole !== 'client-contact-initiator' && this.userRole !== 'file-setup-specialist');
      this.autoCompleteDestCountry.autocompleteDisabled = (this.userRole !== 'client-contact-initiator' && this.userRole !== 'file-setup-specialist');
      this.autoCompleteCountry.autocompleteDisabled = (this.userRole !== 'client-contact-initiator' && this.userRole !== 'file-setup-specialist'); */
      //this.autoComplete.autocompleteDisabled = (this.userRole !== 'client-contact-initiator' && this.userRole !== 'file-setup-specialist'&& !(this.userRole === 'consultant' && this.editCandidateProfile.moveStatus === 'Invitation Sent'));
      //this.autoCompleteDestCountry.autocompleteDisabled = (this.userRole !== 'client-contact-initiator' && this.userRole !== 'file-setup-specialist');
      //this.autoCompleteCountry.autocompleteDisabled = (this.userRole !== 'client-contact-initiator' && this.userRole !== 'file-setup-specialist');
      if (countryList.length > 0) {
        countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
        this.countryList = countryList;
        const currList = [];
        countryList.forEach((curr)=> {
          curr.currency ? currList.push(curr): null;
        });
        currList.sort((a, b) =>  a.currency &&  b.currency ? a.currency.code.localeCompare(b.currency.code) : null);
        this.currencyList = currList.filter((e, i) => currList.findIndex(a => a.currency && e.currency ? a.currency.code === e.currency.code : null) === i);

       /*  this.editCandidateProfile && this.editCandidateProfile.authorizationDetails.currency?
          this.addCandidateForm.get('authorizationDetails.currency').setValue(this.editCandidateProfile.authorizationDetails.currency):{}; */
        
          this.isTraditional === false && this.editCandidateProfile && this.editCandidateProfile.authorizationDetails && this.editCandidateProfile.authorizationDetails.currency? 
          this.addCandidateForm.get('authorizationDetails.currency').setValue(this.editCandidateProfile.authorizationDetails.currency):{};
        
        //   this.filteredOptions = this.addCandidateForm.get('phoneDialCode').valueChanges
        //   .pipe(
        //   startWith(''),
        //   map(value => this._filter(value))
        // );
        this.phoneCodeValueChange();
        this.filteredCountryOptions = this.addCandidateForm.get('addressForm.departureAddr.country').valueChanges
        .pipe(
        startWith(''),
        map(value => this._filter(value))
        );
        this.filteredDestCountryOptions = this.addCandidateForm.get('addressForm.destinationAddr.country').valueChanges
        .pipe(
        startWith(''),
        map(value => this._filter(value))
        );
        this.filteredCurrency = this.addCandidateForm.get('authorizationDetails.currency').valueChanges
          .pipe(
            startWith(''),
            map(value => this.currency_filter(value))
          );
          this.filteredDefaultHomeCountry = this.addCandidateForm.get('optionalDetailsTraditionals.defaultHomeCountry').valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          ); 
        this.filteredDefaultHostCountry = this.addCandidateForm.get('optionalDetailsTraditionals.defaultHostCountry').valueChanges
          .pipe(
            startWith(''),
            map(value => this._filter(value))
          );
        if (this.addCandidateForm.value.addressForm.departureAddr.country) {
          this.departureCountryCode = this.countryList.find(country => (this.addCandidateForm.value.addressForm.departureAddr.country).
          includes(country.countryName)).countryiso2CharCode;
          this.pushToDepartureCountryCodes(this.departureCountryCode, this.editCandidateProfile);
        }
        if (this.addCandidateForm.value.addressForm.destinationAddr.country) {
          this.destinationCountryCode = this.countryList.find(country => (this.addCandidateForm.value.addressForm.destinationAddr.country).
          includes(country.countryName)).countryiso2CharCode;
          this.pushToDestinationCountryCodes(this.destinationCountryCode, this.editCandidateProfile);
        }
        this.spinner.hide();
      }
    });
    this.filteredClientContactDetails = this.addCandidateForm.get('authorizationDetails.cciDetails').valueChanges
        .pipe(
        startWith(''),
        map(value => this.clientContact_filter(value))
        );
    this.addressFormValue = this.addCandidateForm.value['addressForm'];
    if (this.data && this.userRole !== 'client-contact-initiator' && this.userRole !== 'file-setup-specialist') {
      this.candidateProfileService.getTransferee(this.data.candidate.orderRequestId,this.transferee).subscribe((res) => {
        this.transferee = res;
        this.divisionValues = [{
          'cc_division_id' : this.data.candidate.divisionPartyId,
          'cc_division_name': res.candidateDetails.divisionName,
          'cc_division_number': res.candidateDetails.divisionNumber
        }];
      });
      if( this.data.candidate.programDetails ){
        this.programValues = [{
          'programName': this.data.candidate.programDetails.programName
        }];
      }
    } else {
      if (this.userRole === 'file-setup-specialist') {
        let client;
        this.clientContactsService.clientId.subscribe(r => client = r);
        this.programManagementService.getClientDivisions(client).subscribe( res => {
          this.divisionValues = res.divisions;
        });
        this.programManagementService.getClientPolicies(client).subscribe( res => {
          this.policyValues = res.policies;
        });
      } else {
        this.candidateProfileService.getDivision('')
        .subscribe(res => {
          this.divisionValues = res;
        });
      }
      if (this.userRole === 'client-contact-initiator') {
        this.clientPartyId = sessionStorage.getItem("cc_clientPartyId");
        this.programManagementService.getClientPolicies(this.clientPartyId).subscribe( res => {
          this.policyValues = res.policies;
        });
      }
    }
    this.getProgram(1);
   // this.programValues = this.candidateProfileService.getProgram(1);

   if (this.editCandidateProfile && this.editCandidateProfile.additionalDetails && this.editCandidateProfile.additionalDetails.length > 0 ) {
     
    this.editCandidateProfile.additionalDetails.forEach((ele) => {
      if (ele.type === "Date" && ele.value) {
        ele.value = this.setDateWithOffset(ele.value);
      }
    });
    this.configurableFields = this.editCandidateProfile.additionalDetails;
    this.genereateConfigurableFieldsForm();
    if (['Invitation Sent','Authorization Initiated'].includes(this.editCandidateProfile.moveStatus)) {
      Object.keys(this.additionalDetailsFormGroup.controls).forEach(group => this.additionalDetailsFormGroup.controls[group].disable());
    }
    
  } else if (this.editCandidateProfile.additionalDetails === undefined) {
    // get client prtyId
    if (this.userRole === 'client-contact-initiator') {
      this.clientPartyId = sessionStorage.getItem("cc_clientPartyId")
    } else {
      this.clientContactsService.clientId.subscribe(data => {
        this.clientPartyId = data;
      });
    }

    this.configurableFieldService.getConfigurableFields(this.clientPartyId, false, false).subscribe(data => {
      if (data && data.length > 0 ) {
        this.configurableFields = data;
        this.genereateConfigurableFieldsForm();
      }
    });
  }
  }

  compareContractData(data1: any, data2: any) {
    return (data1.contractName === data2.contractName);
  }

  contractListProcess(res){
    this.contractOriginalList = [...res];
    this.contractList = [...res];
    res.forEach((ele, index) => {
      ele['contractDetails'] = {
        'baseContract': ele.baseContract,
        'contractId': ele.contractId,
        'contractName': ele.contractDescription,
      }
      this.contractOriginalList[index] = ele;
      this.contractList[index] = { 'contractDetails': ele.contractDetails };
    });
    if (this.editCandidateProfile && this.editCandidateProfile.authorizationDetails && this.editCandidateProfile.authorizationDetails.contractDetails && this.editCandidateProfile.authorizationDetails.contractDetails.contractName) {
      const contractNameValue = this.addCandidateForm.get('authorizationDetails.contractDetails').value.contractName;
      let data = this.contractOriginalList.find(ele => ele.contractDescription === contractNameValue);
      //this.moveTypeValues = data.moveTypes;
      this.billiingCurrency = data.billingCurrencies;
      if(this.billiingCurrency && this.countryList) {
        this.billiingCurrency.forEach((billCur, index) => {
          let currencyValue = this.currencyList.find(cur => cur.currency && cur.currency.code === billCur);
          this.billiingCurrency[index] = currencyValue;
        })
        this.billingCurrencyValueChange();
      }
    }
  }

  private _filter(value): Country[] {
    if (value) {
    const filterValue = typeof value === 'number' ? value : value.toLowerCase();
    const countryList = this.countryList.filter(option => option.countryName.toLowerCase().indexOf(filterValue) === 0);
    return countryList;
  } else {
      return this.countryList;
    }
  }

  private clientContact_filter(value): AdminClientContacts[] {
    if (value && value.name) {
    const filterValue = typeof value === 'number' ? value : value.name.toLowerCase();
    const ccList = this.cciDetails.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    return ccList;
  } else {
      return this.cciDetails;
    }
  }

  private currency_filter(value): Country[] {
    if(value) {
      const filterValue = typeof value === 'number' ? value :
                          value.code? value.code.toLowerCase(): value;
      const currencyList = this.currencyList.filter(Option => Option.currency.code.toLocaleLowerCase().indexOf(filterValue) === 0);
      return currencyList;
    } else {
      return this.currencyList;
    }
  }

  displayFn(country: any): string {
    return country ? '+ ' + country : '';
  }

  displayClientContactFn(cc): string {
    return cc && cc.name ? cc.name : '';
  }

  displayCurrencyFn(curr): string {
    return curr && curr.code? curr.code + '-' + curr.description: '';
  }

   pushToDepartureCountryCodes(countryCode, editData = null) {
    this.departCountryCode = countryCode ? countryCode.toLowerCase() : this.departCountryCode;
    if (countryCode) {
      const isRequiredvalidation = ['US', 'CA'].includes(countryCode) ? true : false;
      this.streets.removeAt(0);
      this.AddDepartureStreets(editData);
      this.cities.removeAt(0);
      this.AddDeparatureCities(editData);
      this.states.removeAt(0);
      this.AddDepartureStates(editData, isRequiredvalidation);
      this.postalCodes.removeAt(0);
      this.AddDeparaturePostalCodes(editData,isRequiredvalidation);
    }
   }

   pushToDestinationCountryCodes(countryCode, editData = null) {
     this.destiCountryCode =  countryCode ? countryCode.toLowerCase() : this.destiCountryCode;
     if (countryCode) {
      const isRequiredvalidation = ['US', 'CA'].includes(countryCode) ? true : false;
      this.destiStreets.removeAt(0);
      this.AddDestinationStreets(editData);
      this.destiCities.removeAt(0);
      this.AddDestinationCities(editData);
      this.destiStates.removeAt(0);
      this.AddDestinationStates(editData, isRequiredvalidation);
      this.destiPostalCodes.removeAt(0);
      this.AddDestinationPostalCodes(editData, isRequiredvalidation);
     }
   }


   onAutocompleteSelected(result: PlaceResult) {
   // console.log('onAutocompleteSelected: ', result);
    this.updateDepartureAddresValue(result, 'street');
  }

  updateDepartureAddresValue(result: PlaceResult, fieldTriggered: string) {
    let streetLine = '';
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    let isCity = false;

    result.address_components.forEach(element => {
      if (element.types[0] === 'postal_code') {
        this.postalCodes.setValue([element.long_name]);
      } else if (element.types[0] === 'administrative_area_level_1' &&
       (fieldTriggered === 'city'  || fieldTriggered === 'street' || fieldTriggered === 'state' || fieldTriggered === 'postalcode')) {
        this.states.setValue([element.long_name]);
        this.hasDepartureState = true;
      } else if (element.types[0] === 'locality' && (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'postalcode') ) {
        this.cities.setValue([element.long_name]);
        isCity = true;
        this.hasDepartureCity = true;
      } else {
        if (!isCity && fieldTriggered === 'street') {
          streetLine = streetLine.length > 0 ?
          `${streetLine}, ${element.long_name}` : element.long_name;
          this.hasDepartureStreet = true;
        }
      }
    });
    this.streets.setValue([streetLine]);

  }
  onLocationSelected(location: Location) {
    console.log('onLocationSelected: ', location);

  }

  onDepartureCityAutocompleteSelected(result: PlaceResult) {
    console.log('onDepartureCityAutocompleteSelected: ', result);
    this.updateDepartureAddresValue(result, 'city');
  }
  onDepartureStateAutocompleteSelected(result: PlaceResult) {
    console.log('onDepartureStateAutocompleteSelected: ', result);
    this.updateDepartureAddresValue(result, 'state');
  }

  onDeparturePostalCodeAutocompleteSelected(result: PlaceResult) {
    console.log('onDeparturePostalAutocompleteSelected: ', result);
    this.updateDepartureAddresValue(result, 'postalcode');
  }

  resetDestinationAddress() {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    (addressForm.controls.destinationAddr as FormGroup).controls.postalCode.reset();
    (addressForm.controls.destinationAddr as FormGroup).controls.state.reset();
    (addressForm.controls.destinationAddr as FormGroup).controls.city.reset();
    (addressForm.controls.destinationAddr as FormGroup).controls.streetLine1.reset();

  }
  updateDestinationAddresValue(result: PlaceResult, fieldTriggered: string) {
    //this.resetDestinationAddress();
    let streetLine = '';
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    let isCity = false;

    result.address_components.forEach(element => {
      if (element.types[0] === 'postal_code') {
        this.destiPostalCodes.setValue([element.long_name]);
      } else if (element.types[0] === 'administrative_area_level_1' &&
       (fieldTriggered === 'city'  || fieldTriggered === 'street' || fieldTriggered === 'state' || fieldTriggered === 'postalcode')) {
        this.destiStates.setValue([element.long_name]);
        this.hasDestinationState = true;
      } else if (element.types[0] === 'locality' && (fieldTriggered === 'city' || fieldTriggered === 'street' || fieldTriggered === 'postalcode') ) {
        this.destiCities.setValue([element.long_name]);
        isCity = true;
        this.hasDestinationCity = true;
      } else {
        if (!isCity && fieldTriggered === 'street') {
          streetLine = streetLine.length > 0 ?
          `${streetLine}, ${element.long_name}` : element.long_name;
          this.hasDestinationStreet = true;
        }
      }
    });

 this.destiStreets.setValue([streetLine]);
  }
  onDestinationStreetAutocompleteSelected(result: PlaceResult) {
    console.log('onDestinationStreetAutocompleteSelected: ', result);
    this.updateDestinationAddresValue(result, 'street');
  }
  onDestinationCityAutocompleteSelected(result: PlaceResult) {
    console.log('onDestinationCityAutocompleteSelected: ', result);
    this.updateDestinationAddresValue(result, 'city');
  }
  onDestinationStateAutocompleteSelected(result: PlaceResult) {
    console.log('onDepartureStateAutocompleteSelected: ', result);
    this.updateDestinationAddresValue(result, 'state');
  }

  onDestinationPostalCodeAutocompleteSelected(result: PlaceResult) {
    console.log('onDeparturePostalAutocompleteSelected: ', result);
    this.updateDestinationAddresValue(result, 'postalcode');
  }
  /**
   * Will instantiate the form and populate values to form with existing data
   * @param editData contains existing transferee information
   */
  populateForm(editData): FormGroup {
    const check = this.isNullCheck;
    const assocTransferee = (this.data.associateTransferee === true? true: false);
    editData && check(editData, 'divisionPartyId') && (this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist') ? this.getProgram(editData.divisionPartyId) : null;
    //editData && check(editData.programDetails, 'points') ? this.points = editData.programDetails.points : null;
    editData && editData.programDetails && check(editData.programDetails, 'points') ? this.points = editData.programDetails.points : null;
    const addForm: FormGroup = this.formBuilder.group({
      nameDetails: this.formBuilder.group({
        title: [
          editData ? check(editData.nameDetails, 'title') : '', Validators.required
        ],
        firstName: [editData ? check(editData.nameDetails, 'firstName') : '', [Validators.required,
          Validators.minLength(1), Validators.maxLength(50)]],
        lastName: [editData ? check(editData.nameDetails, 'lastName') : '', [Validators.required,
          Validators.minLength(2), Validators.maxLength(50)]]
      }),
      email: new FormArray(
        this._generateEmailGroup(editData.emailDetailsList)
      ),
      phone: new FormArray(
        this._generatePhoneGroup(editData.phoneDetailsList)
      ),
      addressForm: this.formBuilder.group({
        departureAddr: this.formBuilder.group({
          // streetLine1: [editData ? check(editData.departureAddr, 'streetLine1') : ''],
          // city: [editData ? check(editData.departureAddr, 'city') : '',
          //   this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ? [Validators.required, this.depCityMatch.bind(this)] : null],
          // state: [editData ? check(editData.departureAddr, 'state') : '', [Validators.minLength(3)]],
         // country: [editData ? check(editData.departureAddr, 'country') : '', [Validators.required]],
          country: [assocTransferee? '': editData ? check(editData.departureAddr, 'country') : '', [Validators.required]],
          // postalCode: [editData ? check(editData.departureAddr, 'postalCode') : '', [Validators.minLength(1), Validators.maxLength(15)]],
          cities:  new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'city') : '',
            this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ?
             [Validators.required, this.depCityMatch.bind(this)] : null)
          ]),
          streets:  new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'streetLine1') : '')
          ]) ,
          states: new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'state') : '', [Validators.minLength(3)])
          ]),
          postalCodes: new FormArray([
            new FormControl(editData ? check(editData.departureAddr, 'postalCode') : '',
            [Validators.minLength(1), Validators.maxLength(15)])
          ]),
        }),
        destinationAddr: this.formBuilder.group({
          // streetLine1 : [editData ? check(editData.destinationAddr, 'streetLine1') : ''],
          // city: [editData ? check(editData.destinationAddr, 'city') : '',
          //   this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ? [Validators.required, this.destCityMatch.bind(this)] : null],
          // state: [editData ? check(editData.destinationAddr, 'state') : '', [Validators.minLength(3)]],
          country: [editData ? check(editData.destinationAddr, 'country') : '', [Validators.required]],
          // postalCode: [editData ? check(editData.destinationAddr, 'postalCode') : '', [Validators.minLength(1), Validators.maxLength(15)]],
          destiStreets : new FormArray([
            new FormControl(editData ? check(editData.destinationAddr, 'streetLine1') : '')
          ]),
          destiCities: new FormArray([
            new FormControl(editData ? check(editData.destinationAddr, 'city') : '',
            this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ?
             [Validators.required, this.destCityMatch.bind(this)] : null)
          ]),
           destiStates: new FormArray([
             new FormControl(editData ? check(editData.destinationAddr, 'state') : '', [Validators.minLength(3)])
           ]),
           destiPostalCodes: new FormArray([
             new FormControl(editData ? check(editData.destinationAddr, 'postalCode') : '',
             [Validators.minLength(1), Validators.maxLength(15)])
           ])
        })
      }),
      divisionPartyId: this.formBuilder.control(assocTransferee? '': editData && editData.divisionPartyId? check(editData, 'divisionPartyId') : '', this.userRole === 'file-setup-specialist' || this.userRole === 'client-contact-initiator'? [Validators.required]:null),
      assignedPolicy: this.formBuilder.control(assocTransferee? '': editData && editData.assignedPolicy? check(editData, 'assignedPolicy'): '', this.isTraditional? [Validators.required]: null),
      executiveOfficer: this.formBuilder.control(assocTransferee? '': editData && editData.executiveOfficer?  check(editData, 'executiveOfficer'): '', this.isTraditional? [Validators.required]: null),
     
      /* programDetails: this.formBuilder.group({
       // divisionPartyId: [editData ? check(editData.programDetails, 'divisionPartyId') : '', this.userRole === 'file-setup-specialist'? [Validators.required]:null],
        programName: [editData ? check(editData.programDetails, 'programName') : '', Validators.required],
        points: [editData ? check(editData.programDetails, 'points') : '', [Validators.pattern('^[0-9]*$'),
        Validators.min(1), Validators.required]],
        pointOverride: [assocTransferee? '': editData ? check(editData.programDetails, 'pointOverride') : false],
        coreOfferings: [assocTransferee? '': editData && check(editData.programDetails, 'coreOfferings') ? editData.programDetails.coreOfferings : undefined]
      }), */
      programDetails: this.formBuilder.group({
        // divisionPartyId: [editData ? check(editData.programDetails, 'divisionPartyId') : '', this.userRole === 'file-setup-specialist'? [Validators.required]:null],
        programName: [assocTransferee? '': editData ? check(editData.programDetails, 'programName') : '', this.isTraditional === false? [Validators.required]: null],
        points: [assocTransferee? '': editData ? check(editData.programDetails, 'points') : '', this.isTraditional === false?[Validators.pattern('^[0-9]*$'),
        Validators.min(1), Validators.required]: null],
        // executiveOfficer: [editData ? check(editData.programDetails, 'executiveOfficer'): '', Validators.required],
        // assignPolicy: [editData ? check(editData.assignPolicy, 'assignPolicy'): '', Validators.required],
        pointOverride: [assocTransferee? '': editData ? check(editData.programDetails, 'pointOverride') : false],
        coreOfferings: [assocTransferee? '': editData && check(editData.programDetails, 'coreOfferings') ? editData.programDetails.coreOfferings : undefined]
      }),

       /** mandatory fields only for  traditional transferee--fsu */
       deliveryTeamModel: this.formBuilder.control(assocTransferee? '': editData && editData.deliveryTeamModel? check(editData, 'deliveryTeamModel'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
       cliAuthSubTime: this.formBuilder.control(assocTransferee? '': editData && editData.cliAuthSubTime? check(editData, 'cliAuthSubTime'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]: null),
       cliAuthSubDt: this.formBuilder.control(assocTransferee? '': editData && editData.cliAuthSubDt? check(editData, 'cliAuthSubDt'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
       authReadytoProcessDt: this.formBuilder.control(assocTransferee? '': editData && editData.authReadytoProcessDt? check(editData, 'authReadytoProcessDt'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
       authReadytoProcessTime: this.formBuilder.control(assocTransferee? '': editData && editData.authReadytoProcessTime? check(editData, 'authReadytoProcessTime'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
       timeZone: this.formBuilder.control(assocTransferee? '(GMT-05:00) Eastern Standard Time': editData && editData.timeZone? check(editData, 'timeZone'): '(GMT-05:00) Eastern Standard Time' , (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),
       initialContactBy: this.formBuilder.control(assocTransferee? '': editData && editData.initialContactBy? check(editData, 'initialContactBy'): '', (this.userRole === 'file-setup-specialist' && this.isTraditional)? [Validators.required]:null),

       /** optional details for bb transferee */
       optionalDetails: this.formBuilder.group({
        costCenter: [assocTransferee? '': editData ? check(editData.optionalDetails, 'costCenter') : '', Validators.maxLength(50)],
        poBillingInvoice: [assocTransferee? '': editData ? check(editData.optionalDetails, 'poBillingInvoice') : '', Validators.maxLength(99)],
        employeeID: [assocTransferee? '': editData ? check(editData.optionalDetails, 'employeeID') : '', Validators.maxLength(99)],
        jobStartDate: [assocTransferee? '': editData && editData.optionalDetails.jobStartDate ? `${check(editData.optionalDetails, 'jobStartDate')}` : ''],
      }),
      /** optional details for Traditional transferee */
      optionalDetailsTraditionals: this.formBuilder.group({
        priority: [assocTransferee? 'Normal': editData ? check(editData.optionalDetails, 'priority') : 'Normal'],
        employeeType: [assocTransferee? '': editData ? check(editData.optionalDetails, 'employeeType'): ''],
        defaultHomeCountry: [assocTransferee? '': editData ? check(editData.optionalDetails, 'defaultHomeCountry'): ''],
        defaultHostCountry: [assocTransferee? '': editData ? check(editData.optionalDetails, 'defaultHostCountry'): ''],
        assignmentStartDate: [assocTransferee? '': editData ? check(editData.optionalDetails, 'assignmentStartDate'): ''],
        assignmentEndDate: [assocTransferee? '': editData ? check(editData.optionalDetails, 'assignmentEndDate'): ''],
        jobStartDate: [assocTransferee? '': editData && editData.optionalDetails.jobStartDate ? `${check(editData.optionalDetails, 'jobStartDate')}` : ''],
        employeeID: [assocTransferee? '': editData ? check(editData.optionalDetails, 'employeeID') : '', Validators.maxLength(99)],
      }),
      authorizationDetails: this.formBuilder.group({
        // cciDetails: [editData ? check(editData.authorizationDetails, 'cciDetails') : ''],
        cciDetails: [assocTransferee? null :editData ? {} : null, this.userRole === 'file-setup-specialist'? [Validators.required]: null],
        contractDetails: [assocTransferee? null: editData? check(editData.authorizationDetails, 'contractDetails'): null],
        contractId: [editData ? check(editData.authorizationDetails, 'contractId') : '', [Validators.maxLength(15), Validators.pattern('[0-9.]+')]],
        baseContractNo: [editData ? check(editData.authorizationDetails, 'baseContract') : ''],
        currency: [editData ? check(editData.authorizationDetails, 'currency') : {}],
        moveType: this.formBuilder.control(assocTransferee? '': editData && editData.moveType? check(editData, 'moveType'): '')
      })
    });

    //editData && check(editData.programDetails, 'divisionPartyId') && (this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist') ? this.getProgram(editData.programDetails.divisionPartyId) : null;

    if (editData && editData.moveStatus && editData.moveStatus === 'Withdrawn') {
      Object.keys(addForm.controls).forEach(group => addForm.controls[group].disable());
    }

    return addForm;
  }

  get streets(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.streets as FormArray;
  }
  get cities(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.cities as FormArray;
  }
  get states(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.states as FormArray;
  }
  get postalCodes(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.departureAddr as FormGroup).controls.postalCodes as FormArray;
  }

  get destiStreets(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiStreets as FormArray;
  }
  get destiCities(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiCities as FormArray;
  }
  get destiStates(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiStates as FormArray;
  }
  get destiPostalCodes(): FormArray {
    const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
    return (addressForm.controls.destinationAddr as FormGroup).controls.destiPostalCodes as FormArray;
  }
  AddDepartureStreets(editData = null) {
    const check = this.isNullCheck;
    this.streets.push(new FormControl(editData ? check(editData.departureAddr, 'streetLine1') : ''));

    (editData && check(editData.departureAddr, 'streetLine1')) ? this.hasDepartureStreet = true :
      this.hasDepartureStreet = false;
  }
  AddDeparatureCities(editData = null) {
    const check = this.isNullCheck;
    this.cities.push(new FormControl(editData ? check(editData.departureAddr, 'city') : '',
     this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ?
    [Validators.required, this.depCityMatch.bind(this)] : null));
    (editData && check(editData.departureAddr, 'city')) ? this.hasDepartureCity = true :
      this.hasDepartureCity = false;
  }
  AddDepartureStates(editData = null, isRequiredValidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredValidation) {
      this.states.push(new FormControl(editData ? check(editData.departureAddr, 'state') : '', [Validators.minLength(3)]));
    } else {
      this.states.push(new FormControl(editData ? check(editData.departureAddr, 'state') : '',
       [Validators.minLength(3), Validators.required]));
    }
  }
  AddDeparaturePostalCodes(editData = null , isRequiredValidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredValidation) {
      this.postalCodes.push(new FormControl(editData ? check(editData.departureAddr, 'postalCode') : '',
      [Validators.minLength(1), Validators.maxLength(15)] ));
    } else {
      this.postalCodes.push(new FormControl(editData ? check(editData.departureAddr, 'postalCode') : '',
      [Validators.minLength(1), Validators.maxLength(15), Validators.required] ));
    }

  }

  AddDestinationStreets(editData = null) {
    const check = this.isNullCheck;
    this.destiStreets.push(new FormControl(editData ? check(editData.destinationAddr, 'streetLine1') : ''));
     (editData && check(editData.destinationAddr, 'streetLine1')) ? this.hasDestinationStreet = true :
      this.hasDestinationStreet = false;
  }
  AddDestinationCities(editData = null) {
    const check = this.isNullCheck;
    this.destiCities.push(new FormControl(editData ? check(editData.destinationAddr, 'city') : '', this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ?
    [Validators.required, this.destCityMatch.bind(this)] : null));
    (editData && check(editData.destinationAddr, 'city')) ? this.hasDestinationCity = true :
    this.hasDestinationCity = false;
  }
  AddDestinationStates(editData = null, isRequiredvalidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredvalidation) {
      this.destiStates.push(new FormControl(editData ? check(editData.destinationAddr, 'state') : '', [Validators.minLength(3)]));
    } else {
      this.destiStates.push(new FormControl(editData ? check(editData.destinationAddr, 'state') : '',
       [Validators.minLength(3), Validators.required]));
    }

  }
  AddDestinationPostalCodes(editData = null, isRequiredvalidation = false) {
    const check = this.isNullCheck;
    if (!isRequiredvalidation) {
      this.destiPostalCodes.push(new FormControl(editData ? check(editData.destinationAddr, 'postalCode') : '',
      [Validators.minLength(1), Validators.maxLength(15)] ));
    } else {
      this.destiPostalCodes.push(new FormControl(editData ? check(editData.destinationAddr, 'postalCode') : '',
      [Validators.minLength(1), Validators.maxLength(15), Validators.required] ));
    }
  }
  /**
   * To validate if the values provided in departure city auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private depCityMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.departureCityList && this.departureCityList.length > 0 && selection) {
      // tslint:disable-next-line: triple-equals
      if ((this.departureCityList.filter(val => val.cityLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * To validate if the values provided in destination city auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private destCityMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.destinationCityList && this.destinationCityList.length > 0 && selection) {
      // tslint:disable-next-line: triple-equals
      if ((this.destinationCityList.filter(val => val.cityLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * To validate if the values provided in departure state auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private depStateMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.departureStateList && this.departureStateList.length > 0 && selection) {
      // tslint:disable-next-line: triple-equals
      if ((this.departureStateList.filter(val => val.stateLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * To validate if the values provided in destination state auto complete fields are from the options provided
   * @param formControl - field parameter to validate against respective list
   */
  private destStateMatch(control: FormControl): ValidationErrors | null {
    const selection: any = control.value;
    if (this.destinationStateList && this.destinationStateList.length > 0 && selection) {
      // tslint:disable-next-line: triple-equals
      if ((this.destinationStateList.filter(val => val.stateLongName.toLowerCase() == selection.toLowerCase())).length < 1) {
        return {
          requireMatch: true
        };
      }
    }
    return null;
  }

  /**
   * Custom error messages for Firstname, lastname and Email to verify special character or empty errors
   * @param fieldName - field parameter to check for errors
   */
  getErrorMessage(formControl, fieldName, index= -1) {
    if (index === -1) {
      if (this.addCandidateForm.get(formControl).hasError('required') && this.userRole === 'client-contact-initiator') {
        return ('You must enter ' + fieldName);
      } else if (this.addCandidateForm.get(formControl).hasError('pattern') || this.addCandidateForm.get(formControl).hasError('minlength') ||
        this.addCandidateForm.get(formControl).hasError('maxlength') || (this.addCandidateForm.get(formControl).hasError('requireMatch')
          && this.addCandidateForm.get(formControl).value.length < 3) || this.addCandidateForm.get(formControl).hasError('min')) {
        return ('You must enter a valid ' + fieldName);
      } else if (this.addCandidateForm.get(formControl).hasError('requireMatch') &&
      this.addCandidateForm.get(formControl).value.length >= 3) {
        return ('Your ' + fieldName + ' entry must match valid options');

      }
  } else {
    const ctrl = (this.addCandidateForm.get(formControl) as FormArray).controls[0] as FormControl;
    if (ctrl.hasError('required') && this.userRole === 'client-contact-initiator') {
      return ('You must enter ' + fieldName);
    } else if ((ctrl.hasError('pattern') || ctrl.hasError('minlength') ||
      ctrl.hasError('maxlength') || ctrl.hasError('requireMatch')
        && ctrl.value.length < 3) || ctrl.hasError('min')) {
      return ('You must enter a valid ' + fieldName);
    } else if (ctrl.hasError('requireMatch') &&
    ctrl.value.length >= 3) {
      return ('Your ' + fieldName + ' entry must match valid options');

    }
  }

    return '';
  }

  /**
   * Checks for Value or will return 'empty'
   * @param value any
   */
  isNullCheck(obj: Object, key: string) {
    try {
      // tslint:disable-next-line: triple-equals
      return ((obj[key] || obj[key] == false) && obj[key] !== null) ? obj[key] : '';
    } catch (error) {
      return '';
    }
  }
  onFocusOutDepartureCountryCode(){
    const countryObj = this.countryList.find(country => (this.addCandidateForm.value.addressForm.departureAddr.country).toLowerCase().
          includes(country.countryName.toLowerCase()));
          const countryCode = countryObj ? countryObj.countryiso2CharCode :null;
          const index = this.countryList.findIndex(
            (country) => (this.addCandidateForm.value.addressForm.departureAddr.country).toLowerCase() ===
            country.countryName.toLowerCase());
          if (index === -1) {
            ((this.addCandidateForm.controls.addressForm as FormGroup).controls.departureAddr as FormGroup).controls.country.setErrors({'incorrect': true});
          } else if (countryCode === this.departureCountryCode) {
              return;
            }
            this.hasDepartureCountry = true;
            this.departureCountryCode = countryCode;
            this.pushToDepartureCountryCodes(this.departureCountryCode);
            this.modifyLocationFormValidations(this.addCandidateForm.controls.addressForm as FormGroup,
              this.departureCountryCode, this.destinationCountryCode);
  }

  onFocusOutDestinationCountryCode(){
    const countryObj = this.countryList.find(country => (this.addCandidateForm.value.addressForm.destinationAddr.country).toLowerCase().
          includes(country.countryName.toLowerCase()));
          const countryCode = countryObj ? countryObj.countryiso2CharCode :null;
          const index = this.countryList.findIndex(
            (country) => (this.addCandidateForm.value.addressForm.destinationAddr.country).toLowerCase() ===
            country.countryName.toLowerCase());
          if (index === -1) {
            ((this.addCandidateForm.controls.addressForm as FormGroup).controls.destinationAddr as FormGroup).controls.country.setErrors({'incorrect': true});
          } else if (countryCode === this.destinationCountryCode) {
            return;
          }
            this.hasDestinationCountry = true;
            this.destinationCountryCode = countryCode;
            this.pushToDestinationCountryCodes(this.destinationCountryCode);
            this.modifyLocationFormValidations(this.addCandidateForm.controls.addressForm as FormGroup,
              this.departureCountryCode, this.destinationCountryCode);
  }
  /**
   * Initialize location search observer..
   */
  locationSearchInit() {
    // tslint:disable-next-line: triple-equals
    this.hasDepartureCountry = (this.addCandidateForm.value.addressForm.departureAddr.country != '') ? true : false;
    // tslint:disable-next-line: triple-equals
    this.hasDestinationCountry = (this.addCandidateForm.value.addressForm.destinationAddr.country != '') ? true : false;
    this.locationFormObserver = (this.addCandidateForm.controls.addressForm as FormGroup).valueChanges.pipe(
      debounceTime(300)
    ).subscribe(value => {
      // need to find the updated ke-0`3y.
      const newFormValue = { ...value };
      const updatedKey: updatedKeyInterface = this.findUpdatedKey(newFormValue, this.addressFormValue);

      if (updatedKey && updatedKey.type == 'country') {
        if (this.addCandidateForm.value.addressForm.departureAddr.country != '') {
          const countryObj = this.countryList.find(country => (this.addCandidateForm.value.addressForm.departureAddr.country).
          includes(country.countryName));
          this.departureCountryCode = countryObj ? countryObj.countryiso2CharCode :null;
          this.hasDepartureCountry = true;
          if (newFormValue.departureAddr.country !== this.addressFormValue.departureAddr.country) {
            this.pushToDepartureCountryCodes(this.departureCountryCode);
          }
        }

        if (this.addCandidateForm.value.addressForm.destinationAddr.country != '') {
          const country = this.countryList.find(country => (this.addCandidateForm.value.addressForm.destinationAddr.country).
          includes(country.countryName));
          this.destinationCountryCode = country?country.countryiso2CharCode :null;
          this.hasDestinationCountry = true;
          //this.destiCountryCode = this.destinationCountryCode ? this.destinationCountryCode.toLowerCase() : this.destiCountryCode;
          if (newFormValue.destinationAddr.country !== this.addressFormValue.destinationAddr.country) {
            this.pushToDestinationCountryCodes(this.destinationCountryCode);
          }
        }
        // Modify location form validation if US and CA
        this.modifyLocationFormValidations(this.addCandidateForm.controls.addressForm as FormGroup,
          this.departureCountryCode, this.destinationCountryCode);
        this.resetStates(updatedKey);
      }
/* to remove for bb-1688
      // Location based logic should run only if city or state has been changed
      if (updatedKey && ['city', 'state'].includes(updatedKey.type)) {
        this.locationSearch((this.addCandidateForm.controls.addressForm as FormGroup), updatedKey, newFormValue,
        this.departureCountryCode, this.destinationCountryCode)
          .subscribe(data => {
            if (data) {
              const searchType = updatedKey.type === 'city' ? 'cities' : 'states';
              // tslint:disable-next-line: triple-equals
              if (searchType == 'cities') {
                // tslint:disable-next-line: triple-equals
                updatedKey.group == 'departureAddr' ? this.departureCityList = data.cities : this.destinationCityList = data.cities;
              } else { // searchType == states
                // tslint:disable-next-line: triple-equals
                updatedKey.group == 'departureAddr' ? this.departureStateList = data.states : this.destinationStateList = data.states;
              }
            }

          });
        this.resetStates(updatedKey);
      } */


      if (updatedKey && updatedKey.group === 'departureAddr') {
        if (!newFormValue.departureAddr.streets || (newFormValue.departureAddr.streets && newFormValue.departureAddr.streets.length > 0
        && newFormValue.departureAddr.streets[0] === '' && newFormValue.departureAddr.streets[0].length === 0)) {
          this.hasDepartureStreet = false;
        }

        if (!newFormValue.departureAddr.cities || (newFormValue.departureAddr.cities && newFormValue.departureAddr.cities.length > 0
          && newFormValue.departureAddr.cities[0] === '' && newFormValue.departureAddr.cities[0].length === 0)) {
          this.hasDepartureCity = false;
        }
        if (!newFormValue.departureAddr.states || ( newFormValue.departureAddr.states && newFormValue.departureAddr.states.length > 0
          && newFormValue.departureAddr.states[0] === '' && newFormValue.departureAddr.states[0].length === 0)) {
          this.hasDepartureState = false;
        }
      }

      if (updatedKey && updatedKey.group === 'destinationAddr') {
        if (!newFormValue.destinationAddr.destiStreets || (newFormValue.destinationAddr.destiStreets &&
           newFormValue.destinationAddr.destiStreets.length > 0 && newFormValue.destinationAddr.destiStreets[0] === ''
            && newFormValue.destinationAddr.destiStreets[0].length === 0)) {
            this.hasDestinationStreet = false;
          }

          if (!newFormValue.destinationAddr.destiCities ||
             (newFormValue.destinationAddr.destiCities && newFormValue.destinationAddr.destiCities.length > 0
            && newFormValue.destinationAddr.destiCities[0] === '' && newFormValue.destinationAddr.destiCities[0].length === 0)) {
            this.hasDestinationCity = false;
          }
          if (!newFormValue.destinationAddr.destiStates ||
             ( newFormValue.destinationAddr.destiStates && newFormValue.destinationAddr.destiStates.length > 0
            && newFormValue.destinationAddr.destiStates[0] === '' && newFormValue.destinationAddr.destiStates[0].length === 0)) {
            this.hasDestinationState = false;
          }
      }

      this.addressFormValue = value;

    });

  }

  /**
	 * Will automatically reset the state if there are any changes to city
	 * @param updatedKey contains the updated {key}
	 */
  resetStates(updatedKey: updatedKeyInterface) {
    setTimeout(() => {
      const addressForm: FormGroup = (this.addCandidateForm.controls.addressForm as FormGroup);
      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'departureAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.states.setValue(['']);
        this.streets.setValue(['']);
        // tslint:disable-next-line: triple-equals
        if (updatedKey.type == 'country') {
          this.cities.setValue(['']);
          this.postalCodes.setValue(['']);
          this.streets.setValue(['']);
          this.hasDepartureCity = false;
          this.hasDepartureState = false;
        }
        this.hasDepartureStreet = false;
      }

      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'destinationAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        // (addressForm.controls.destinationAddr as FormGroup).controls.state.reset();
        this.destiStates.setValue(['']);
        // (addressForm.controls.destinationAddr as FormGroup).controls.postalCode.reset();
        // tslint:disable-next-line: triple-equals
        if (updatedKey.type == 'country') {
          // (addressForm.controls.destinationAddr as FormGroup).controls.city.reset();
          // (addressForm.controls.destinationAddr as FormGroup).controls.postalCode.reset();
          this.destiCities.setValue(['']);
          this.destiPostalCodes.setValue(['']);
          this.hasDestinationCity = false;

        }
        this.hasDestinationState = false;
      }

      // Clear state list if City is updated.
      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'departureAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.departureStateList = [];
      }
      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'departureAddr' && updatedKey.type == 'country') {
        this.departureCityList = [];
      }
      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'destinationAddr' && (updatedKey.type == 'city' || updatedKey.type == 'country')) {
        this.destinationStateList = [];
      }
      // tslint:disable-next-line: triple-equals
      if (updatedKey.group == 'destinationAddr' && updatedKey.type == 'country') {
        this.destinationCityList = [];
      }

    }, 500);
  }
  /**
     * Will determine which field has been updated in Address Form (for Address and Review Component).
     * @param newFormValue AddressForm new values
     * @param oldFormValue AddressForm old values
     */
  findUpdatedKey(newFormValue, oldFormValue): updatedKeyInterface {
    let updatedKey = null;

    Object.keys(newFormValue.departureAddr).map(key => {
      if (newFormValue.departureAddr[key] !== oldFormValue.departureAddr[key]) {
        // tslint:disable-next-line: rxjs-no-unsafe-scope
        updatedKey = key;
      }
    });

    if (updatedKey) { return { group: 'departureAddr', type: updatedKey }; }

    Object.keys(newFormValue.destinationAddr).map(key => {
      if (newFormValue.destinationAddr[key] !== oldFormValue.destinationAddr[key]) {
        // tslint:disable-next-line: rxjs-no-unsafe-scope
        updatedKey = key;
      }
    });

    if (updatedKey) { return { group: 'destinationAddr', type: updatedKey }; }

    return null;
  }

  locationSearch(addressForm: FormGroup, updatedKey: updatedKeyInterface, updatedLocationFormValue: any,
    departureCountryCode: string, destinationCountryCode: string): Observable<Locations> {

    // tslint:disable-next-line: deprecation
    return Observable.create(_observer => {

      const spinner = this.injector.get(NgxSpinnerService);
      const locationService = this.injector.get(LocationsService);

      // if empty then don't run...
      if (!updatedLocationFormValue[updatedKey.group][updatedKey.type]) { return; }

      const searchType = updatedKey.type === 'city' ? 'cities' : 'states';
      const searchText: string = updatedLocationFormValue[updatedKey.group as any][updatedKey.type];

      // Search only if search text length is greater than 3;
      if (searchText.length < 3) { return; }

      spinner.show();
      // locationService.placesSearch(searchText).subscribe((data) => {
      // console.log(data);
      // });
      locationService.locationSearch(searchType, updatedLocationFormValue[updatedKey.group as any][updatedKey.type], {
        country: updatedKey.group === 'departureAddr' ? departureCountryCode : destinationCountryCode,
        cityLongName: updatedKey.group == 'departureAddr' ? updatedLocationFormValue.departureAddr.city :
        updatedLocationFormValue.destinationAddr.city,
        cityShortName: updatedKey.group == 'departureAddr' ? updatedLocationFormValue.departureAddr.city :
        updatedLocationFormValue.destinationAddr.city
      }).subscribe(data => {

        // spinner.hide();

        // Empty response..
        if (!data) {
          // tslint:disable-next-line: triple-equals
          if (updatedKey.group == 'departureAddr') {
            // tslint:disable-next-line: triple-equals
            (updatedKey.type == 'city') ?
              (addressForm.controls.departureAddr as FormGroup).controls.city.setValue('') :
              (addressForm.controls.departureAddr as FormGroup).controls.state.setValue('');
          } else { // destinationAddr
            (updatedKey.type == 'city') ?
              (addressForm.controls.destinationAddr as FormGroup).controls.city.setValue('') :
              (addressForm.controls.destinationAddr as FormGroup).controls.state.setValue('');
          }
          // addressForm.markAllAsTouched();  ------> uncomment this
          _observer.next(null);
          _observer.complete();
        }
        _observer.next(data);
        _observer.complete();

      }, () => {
        _observer.next(null);
        _observer.complete();

      }, () => console.log('hide spinner')
        // spinner.hide();
      );
    });
  }


  /**
   * State/Province & postal code required for locations in the United States of America and Canada only
   */
  modifyLocationFormValidations(addressForm: FormGroup, departureCountryCode: string, destinationCountryCode: string) {
    if (!['US', 'CA'].includes(departureCountryCode)) {
      //  (addressForm.controls.departureAddr as FormGroup).controls.state.clearValidators();
      this.states.controls[0].setValidators([Validators.minLength(3),
      this.depStateMatch.bind(this)]);
      // (addressForm.controls.departureAddr as FormGroup).controls.postalCode.clearValidators();
      this.postalCodes.controls[0].setValidators([Validators.minLength(2),
      Validators.maxLength(50)]);

    } else {
      this.states.controls[0].setValidators([Validators.required,
      this.depStateMatch.bind(this), Validators.minLength(3)]);
      this.postalCodes.controls[0].setValidators([Validators.required,
      Validators.minLength(2), Validators.maxLength(50)]);

    }
    if (!['US', 'CA'].includes(destinationCountryCode)) {
      //  (addressForm.controls.destinationAddr as FormGroup).controls.state.clearValidators();
      this.destiStates.controls[0].setValidators([this.destStateMatch.bind(this),
      Validators.minLength(3)]);
      this.destiPostalCodes.controls[0].setValidators([Validators.minLength(2),
        Validators.maxLength(50)]);
    } else {
      this.destiStates.controls[0].setValidators([Validators.required,
      this.destStateMatch.bind(this), Validators.minLength(3)]);
      this.destiPostalCodes.controls[0].setValidators([Validators.minLength(2),
        Validators.maxLength(50)]);
    }
    this.states.controls[0].updateValueAndValidity();
    this.postalCodes.controls[0].updateValueAndValidity();
    this.destiStates.controls[0].updateValueAndValidity();
    this.destiPostalCodes.controls[0].updateValueAndValidity();
  }

  /**
   * On click of send invite button inside the addCandidateForm dialog window
   */
 /*  sendInvite() {
    this.points = this.addCandidateForm.value.programDetails.points;
    if (!this.data) {
      this.saveCandidate(this.populateValues(this.data.candidate), 'sendInvite');
    } else {
      this.data.candidate.orderRequestId = this.data.candidatePartyId;
      this.saveCandidate(this.populateValues(this.data.candidate), 'reSendInvite');
    }
  } */
  sendInvite() {
    this.points = this.addCandidateForm.value.programDetails.points;
   // if (!this.data.candidate ) {
    if (this.data.candidate.departureAddr === undefined) {
      this.saveCandidate(this.populateValues(this.data.candidate), 'sendInvite');
    } else if (this.data.candidate && !this.data.candidate.moveStatus && this.isCostProjection) {
      this.saveCandidate(this.populateValues(this.data.candidate), 'saveDraft');
    } else if(this.data.candidate.moveStatus == 'CP In Progress') {
      this.saveCandidate(this.populateValues(this.data.candidate), 'approveMove')
    } /* else if(this.data.candidate && this.invitationSent == false){
      this.saveCandidate(this.populateValues(this.data.candidate), 'reAuthorize');
    }  */else {
      this.saveCandidate(this.populateValues(this.data.candidate), 'reSendInvite');
    }
  }
  /**
   *On click of Save Draft button inside the addCandidateForm dialog window
   */
  saveData(type: string) {
    /**
     * temporary commented
     *
        if (this.findInvalidControls(this.addCandidateForm) &&
        this.findInvalidControls((addressFormgrp.controls.destinationAddr) as FormGroup)
        && this.findInvalidControls(addressFormgrp.controls.departureAddr as FormGroup) &&
        this.findInvalidControls(this.addCandidateForm.controls.optionalDetails as FormGroup))
    */
  
    // this.spinner.show();
    this.points = this.addCandidateForm.value.programDetails.points;
    // tslint:disable-next-line: triple-equals
    if (type == 'saveDraft') {
      const addressFormgrp = (this.addCandidateForm.controls.addressForm as FormGroup);
        if (!this.editCandidateProfile || this.data.associateTransferee !== undefined) {
          this.saveCandidate(this.populateValues(this.data.candidate), type);
        } else {
          this.saveCandidate(this.populateValues(this.data.candidate), 'saveChanges');
        }
    } 
    else if ( type == 'moveNotApprove') {
      this.saveCandidate(this.populateValues(this.data.candidate), 'notApproveMove');
    }
    else {
      this.saveCandidate(this.populateValues(this.data.candidate), type);
    }
  }

  /**
   * Click on Save Draft button, check if the values of the fields provided are valid
   */
  findInvalidControls(formGroup: FormGroup) {
    let isValid = true;
    let isPhoneCodeReqValid = false;
    let isPhoneNumberReqValid = false;
    const controls = formGroup.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        console.log('invalid control is ===>' + JSON.stringify(controls[name]));
        if (controls[name].hasError('pattern') || controls[name].hasError('minLength') ||
        controls[name].hasError('maxLength') || controls[name].hasError('email') ||
        controls[name].hasError('requireMatch')) {
          isValid = false;
          break;
        } else if (name == 'phoneDialCode' || name == 'phoneNumber') {
          isPhoneCodeReqValid = controls['phoneDialCode'].hasError('required') ? false : true;
          isPhoneNumberReqValid = controls['phoneNumber'].hasError('required') ? false : true;
        }
      }
    }
    if (isPhoneCodeReqValid !== isPhoneNumberReqValid) {
      this.addCandidateForm.controls.phoneDialCode.markAsTouched();
      this.addCandidateForm.controls.phoneNumber.markAsTouched();
      isValid = false;
      return false;
    }
    if (isValid) {
      return true;
    } else {
      return false;
    }
  }
  /**
   * Populate form values to object for submission
   */

  populateValues(editData: candidateProfile): candidateProfile {
    this.candidateProfile = {} as candidateProfile;

    /** Profile Details */
    this.candidateProfile.nameDetails = {
      firstName: this.addCandidateForm.value.nameDetails.firstName,
      lastName: this.addCandidateForm.value.nameDetails.lastName
    };

    (this.addCandidateForm.value.nameDetails.title) ? this.candidateProfile.nameDetails.title =
    // tslint:disable-next-line: no-unused-expression
    this.addCandidateForm.value.nameDetails.title : null;

    /** Phone Details */
    if(this.addCandidateForm.value.phone) {
      const phoneDetail: phoneDetails[] = [];
      this.addCandidateForm.value.phone.forEach(ele => {
        if(ele.phoneDialCode && ele.phoneNumber) {
          let phoneObj = {
            phoneDialCode: ele.phoneDialCode,
            phoneNumber: ele.phoneNumber,
          };
          if(ele.type == 'Departure Business') { phoneObj['usageType'] = "Departure Business"; phoneObj['locationType'] ="departure" }
          if(ele.type == 'Destination Business') { phoneObj['usageType'] = "Destination Business"; phoneObj['locationType'] ="destination" }
          if(ele.type == 'Departure Residence') { phoneObj['usageType'] = "Departure Residence"; phoneObj['locationType'] ="departure" }
          if(ele.type == 'Destination Residence') { phoneObj['usageType'] = "Destination Residence"; phoneObj['locationType'] ="destination" }
          if(ele.type == "Mobile") { phoneObj['primary'] = true; phoneObj['textingAvailable']= true; phoneObj['usageType'] = "personal" }
          
          ele._id? phoneObj['_id'] = ele._id: null;
          phoneDetail.push(phoneObj);
        }
      });
      (editData && editData.phoneDetailsList && phoneDetail.length ) ?
      this.candidateProfile.phoneDetailsList = [...phoneDetail]: null;
    }
    /** email Details */
    if (this.addCandidateForm.value.email) {
      const emailDetail: emailDetails[] =[];
      this.addCandidateForm.value.email.forEach(ele => {
        if(ele.emailAddress && ele.usageType) {
          const emailObj = {
            emailAddress: ele.emailAddress,
            usageType: ele.usageType,
          }
          if(emailObj.usageType === 'Business') {
            emailObj['primary'] = true;
            emailObj.usageType = 'business';
          } 
          if(emailObj.usageType === 'Personal') {
            emailObj.usageType = 'personal';
          }
          ele._id !== null? emailObj['_id'] = ele._id: null;
          emailDetail.push(emailObj);
        }
      });
       (editData && editData.emailDetailsList && emailDetail.length ) ?
      this.candidateProfile.emailDetailsList = [...emailDetail]: null;
    }


    /**Departure  Address Details */
    const departAddress: shortAddress = {} as shortAddress;
    (this.addCandidateForm.value.addressForm.departureAddr.cities &&
       this.addCandidateForm.value.addressForm.departureAddr.cities.length>0 &&
        this.addCandidateForm.value.addressForm.departureAddr.cities[0]) ?
        departAddress.city = this.addCandidateForm.value.addressForm.departureAddr.cities[0] : null;
    (this.addCandidateForm.value.addressForm.departureAddr.country) ? departAddress.country =
    this.addCandidateForm.value.addressForm.departureAddr.country : null;
    (this.addCandidateForm.value.addressForm.departureAddr.postalCodes
       && this.addCandidateForm.value.addressForm.departureAddr.postalCodes.length > 0 &&
       this.addCandidateForm.value.addressForm.departureAddr.postalCodes[0]) ? departAddress.postalCode =
    this.addCandidateForm.value.addressForm.departureAddr.postalCodes[0] : null;
    (this.addCandidateForm.value.addressForm.departureAddr.states
      && this.addCandidateForm.value.addressForm.departureAddr.states.length > 0 &&
      this.addCandidateForm.value.addressForm.departureAddr.states[0]) ? departAddress.state =
    this.addCandidateForm.value.addressForm.departureAddr.states[0] : null;
    (this.addCandidateForm.value.addressForm.departureAddr.streets &&
      this.addCandidateForm.value.addressForm.departureAddr.streets.length > 0 &&
      this.addCandidateForm.value.addressForm.departureAddr.streets[0]) ? departAddress.streetLine1 =
    this.addCandidateForm.value.addressForm.departureAddr.streets[0] : null;
    Object.keys(departAddress).length ? this.candidateProfile.departureAddr = departAddress : null;

    /**Destination  Address Details */
    const destAddress: shortAddress = {} as shortAddress;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiCities &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiCities.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiCities[0]) ? destAddress.city =
    this.addCandidateForm.value.addressForm.destinationAddr.destiCities[0] : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.country) ? destAddress.country =
    this.addCandidateForm.value.addressForm.destinationAddr.country : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes[0]) ? destAddress.postalCode =
    this.addCandidateForm.value.addressForm.destinationAddr.destiPostalCodes[0] : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiStates &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStates.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStates[0]) ? destAddress.state =
    this.addCandidateForm.value.addressForm.destinationAddr.destiStates[0] : null;
    (this.addCandidateForm.value.addressForm.destinationAddr.destiStreets &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStreets.length > 0 &&
      this.addCandidateForm.value.addressForm.destinationAddr.destiStreets[0]) ? destAddress.streetLine1 =
    this.addCandidateForm.value.addressForm.destinationAddr.destiStreets[0] : null;
    Object.keys(destAddress).length ? this.candidateProfile.destinationAddr = destAddress : null;
    
    /** Optional Details */
    const optionalDetail = {} as any;
    optionalDetail.costCenter = this.addCandidateForm.value.optionalDetails.costCenter;
    optionalDetail.jobStartDate = moment(this.addCandidateForm.value.optionalDetails.jobStartDate).format('YYYY-MM-DD');
    !this.addCandidateForm.value.optionalDetails.jobStartDate ? optionalDetail.jobStartDate ='' : this.setDateToUTC(new Date(this.addCandidateForm.value.optionalDetails.jobStartDate));
    optionalDetail.poBillingInvoice = this.addCandidateForm.value.optionalDetails.poBillingInvoice;
    optionalDetail.employeeID = this.addCandidateForm.value.optionalDetails.employeeID;

    /** Optional Details of Traditional Transferee */
    const optionalDetailsTraditional = {} as any;
    optionalDetailsTraditional.priority = this.addCandidateForm.value.optionalDetailsTraditionals.priority;
    optionalDetailsTraditional.employeeType = this.addCandidateForm.value.optionalDetailsTraditionals.employeeType;
    optionalDetailsTraditional.employeeID = this.addCandidateForm.value.optionalDetailsTraditionals.employeeID;
    if(this.isUS_SelectedCheck) {
      optionalDetailsTraditional.defaultHomeCountry = this.addCandidateForm.value.optionalDetailsTraditionals.defaultHomeCountry;
      optionalDetailsTraditional.defaultHostCountry = this.addCandidateForm.value.optionalDetailsTraditionals.defaultHostCountry;
      optionalDetailsTraditional.assignmentStartDate = moment(this.addCandidateForm.value.optionalDetailsTraditionals.assignmentStartDate).format('YYYY-MM-DD');
      !this.addCandidateForm.value.optionalDetailsTraditionals.assignmentStartDate ? optionalDetailsTraditional.assignmentStartDate = '' : this.setDateToUTC(new Date(this.addCandidateForm.value.optionalDetailsTraditionals.assignmentStartDate));
      optionalDetailsTraditional.assignmentEndDate = moment(this.addCandidateForm.value.optionalDetailsTraditionals.assignmentEndDate).format('YYYY-MM-DD');
      !this.addCandidateForm.value.optionalDetailsTraditionals.assignmentEndDate ? optionalDetailsTraditional.assignmentEndDate = '' : this.setDateToUTC(new Date(this.addCandidateForm.value.optionalDetailsTraditionals.assignmentEndDate));
    }
    if(this.isUS_Move) {
      optionalDetailsTraditional.jobStartDate = moment(this.addCandidateForm.value.optionalDetailsTraditionals.jobStartDate).format('YYYY-MM-DD HH:mm:ss');
      !this.addCandidateForm.value.optionalDetailsTraditionals.jobStartDate ? optionalDetailsTraditional.jobStartDate ='' : this.setDateToUTC(new Date(this.addCandidateForm.value.optionalDetailsTraditionals.jobStartDate));
    }
    
     /** Program Details */
     const programDetail = {} as any;
      this.addCandidateForm.value.programDetails.divisionPartyId ? programDetail.divisionPartyId = 
      this.addCandidateForm.value.programDetails.divisionPartyId : null;
     (this.addCandidateForm.value.programDetails.programName) ? programDetail.programName =
     this.addCandidateForm.value.programDetails.programName : null;
     (this.addCandidateForm.value.programDetails.points) ? programDetail.points =  Number(this.points) : null;
     programDetail.pointOverride = this.addCandidateForm.value.programDetails.pointOverride === "" ? false : this.addCandidateForm.value.programDetails.pointOverride;
     this.addCandidateForm.value.programDetails.coreOfferings ? programDetail.coreOfferings =
     this.addCandidateForm.value.programDetails.coreOfferings : undefined;
     // Object.keys(programDetail).length ? this.candidateProfile.programDetails = programDetail : null;

    /** divisionPartyId  */
    let divPartyId: string;
    this.addCandidateForm.value.divisionPartyId[0] ? divPartyId = this.addCandidateForm.value.divisionPartyId : null;
    divPartyId ? this.candidateProfile.divisionPartyId = divPartyId: null;

    /**Traditional Or BenefitsBuilder */
    let authorizationType: string;
     /** cost projection check */
     let type: string;
    if(this.data.editTransferee) {
      authorizationType = this.data.candidate.authorizationType;
      type = this.data.candidate.type;
    }else {
      this.data.isTraditional? authorizationType = 'Traditional': authorizationType = 'BenefitsBuilder';
      this.data.isCostProjection? type = 'cost-projection': type = 'move';
    }
    authorizationType? this.candidateProfile.authorizationType = authorizationType: null;
    type? this.candidateProfile.type = type: null;

     /** assignedPolicy */
     let policy: string;
     this.addCandidateForm.value.assignedPolicy ? policy = this.addCandidateForm.value.assignedPolicy : null;
     policy? this.candidateProfile.assignedPolicy = policy: null;
    
    if (this.isTraditional ) {
      /** optional details */
      Object.keys(optionalDetailsTraditional).length ? this.candidateProfile.optionalDetails = optionalDetailsTraditional : null;
 
      /** executiveOfficer */
      let exeofficer: string;
      this.addCandidateForm.value.executiveOfficer ? exeofficer = this.addCandidateForm.value.executiveOfficer : null;
      exeofficer? this.candidateProfile.executiveOfficer = exeofficer: null;

       /**mandatory Tradional FSU Fields */
       this.addCandidateForm.value.authorizationDetails.moveType ? this.candidateProfile.moveType = this.addCandidateForm.value.authorizationDetails.moveType : null;
       this.addCandidateForm.value.timeZone ? this.candidateProfile.timeZone = this.addCandidateForm.value.timeZone : null;
       this.addCandidateForm.value.initialContactBy ? this.candidateProfile.initialContactBy = this.addCandidateForm.value.initialContactBy : null;
       this.addCandidateForm.value.deliveryTeamModel ? this.candidateProfile.deliveryTeamModel = this.addCandidateForm.value.deliveryTeamModel : null;
       this.addCandidateForm.value.cliAuthSubTime ? this.candidateProfile.cliAuthSubTime = this.addCandidateForm.value.cliAuthSubTime : null;
       this.addCandidateForm.value.cliAuthSubDt? this.candidateProfile.cliAuthSubDt = this.setDateToUTC(new Date(this.addCandidateForm.value.cliAuthSubDt)): null;
       this.addCandidateForm.value.authReadytoProcessTime ? this.candidateProfile.authReadytoProcessTime = this.addCandidateForm.value.authReadytoProcessTime : null;
       this.addCandidateForm.value.authReadytoProcessDt ? this.candidateProfile.authReadytoProcessDt = this.setDateToUTC(new Date(this.addCandidateForm.value.authReadytoProcessDt)) : null;
    } else {
      this.candidateProfile.authorizationType = 'BenefitsBuilder';
      Object.keys(optionalDetail).length ? this.candidateProfile.optionalDetails = optionalDetail : null;
      Object.keys(programDetail).length ? this.candidateProfile.programDetails = programDetail : null;
    }

    /** Authorization Details */
      let client;
      this.clientContactsService.clientId.subscribe(r => client = r);
      this.candidateProfile.authorizationDetails = {
        clientId: client,
        cciPartyId: this.addCandidateForm.value.authorizationDetails.cciDetails ?
          this.addCandidateForm.value.authorizationDetails.cciDetails.name : '',
        currency: this.addCandidateForm.value.authorizationDetails.currency ?
          this.addCandidateForm.value.authorizationDetails.currency : {},
        baseContract : this.addCandidateForm.value.authorizationDetails.baseContractNo ?
          this.addCandidateForm.value.authorizationDetails.baseContractNo : '',
        contractId : this.addCandidateForm.value.authorizationDetails.contractId ?
          this.addCandidateForm.value.authorizationDetails.contractId : '',
        contractDetails: this.addCandidateForm.value.authorizationDetails.contractDetails ?
          this.addCandidateForm.value.authorizationDetails.contractDetails : {},
      };

    /**Additional Details(configurable Fields) */
    if (this.configurableFields && this.configurableFields.length > 0) {
      let additionalDetails: AdditionalDetail[] = Object.entries(this.additionalDetailsFormGroup.value).map(([key, value]) => ({ displayName: key, value: value })) as AdditionalDetail[];
      additionalDetails = additionalDetails.map((ele: any) => {
        let matchedField = this.configurableFields.find(curr => curr.displayName === ele.displayName);
        if (matchedField.type === 'Date' && moment(ele.value).isValid()) {                // to handle Date conversions
          ele.value = moment(ele.value).format('YYYY-MM-DD HH:mm:ss');
        }
        ele.type = matchedField.type;
        return ele;
      });
      additionalDetails = additionalDetails.filter(ele => {
        let matchedField = this.configurableFields.find(curr => curr.displayName === ele.displayName);
        let defaultValue:any = fieldMapping.find(curr => curr.DataItemTypDescr === matchedField.type).defaultValue;
        // return !([null, undefined, defaultValue].includes(ele.value));
        if(matchedField.type === 'Currency') {
          return !(ele.value.currencyAmount === defaultValue.currencyAmount || ele.value.currencyAmount === null || defaultValue.currencyAmount === undefined)
        }
        return !(ele.value === defaultValue || ele.value === null || ele.value === undefined);
      });
      additionalDetails.length && !(this.editCandidateProfile && ['Invitation Sent','Authorization Initiated'].includes(this.editCandidateProfile.moveStatus)) ? this.candidateProfile.additionalDetails = additionalDetails : null;
    }

    /**to associate move details to existing transferee */
    if(this.data.candidatePartyId) {
      this.candidateProfile.candidatePartyId = this.data.candidatePartyId;
    }
    /** Existing candidate Details */
    if (editData) {
      (editData.orderRequestId) ? this.candidateProfile.orderRequestId = editData.orderRequestId : null;
      (editData.moveStatus) ? this.candidateProfile.moveStatus = editData.moveStatus : null;
      (editData.moveStatusDate) ? this.candidateProfile.moveStatusDate = editData.moveStatusDate : null;
    }

    // if (this.addCandidateForm.value.programDetails.pointOverride) {
    //   this.candidateProfile.pointsOverrideHistory = [];
    // }
    return this.candidateProfile;
  }

  /**
   * Save candidate create/update
   * @param type notifies whether to add/edit
   */
   saveCandidate(data: candidateProfile, type) {
    switch (type) {
      case 'saveDraft': {
        // if (type === 'saveDraft') {
        /* const candidateServiceSave = this.candidateProfileService.createCandidate(this.candidateProfile);
        candidateServiceSave.subscribe(
          (response) => {
            this.spinner.hide();
            if (response && !this.data) {
              this.flashAndCloseDialog(createCandidateResponse, true);
            }
          },
          err => {
            this.spinner.hide();
          }
        );
        break; */
        this.isCostProjection ? this.candidateProfile.moveStatus= "Not Requested" : this.candidateProfile.moveStatus= "Invitation Not Sent";
        this.candidateProfile.moveStatusDate = this.candidateProfileService.getTodaysDate();
        if (this.candidateProfile.pointsOverrideHistory) {
          //CHECK
          //this.candidateProfile.pointsOverrideHistory.push(this.updatePointOverideHistory());
        }
        const candidateServiceSave = this.candidateProfileService.createCandidate(this.candidateProfile, this.isCostProjection);
        this.spinner.hide();
        this.isCostProjection ? this.flashAndCloseDialog(updateCPCandidateResponse, true) : this.flashAndCloseDialog(createCandidateResponse, true);
        break;
      }
      case 'saveChanges': {
        //  else  if (type === 'saveChanges') {
         // this.candidateProfile.moveStatus= "Invitation Not Sent";
        this.candidateProfile.moveStatusDate = this.candidateProfileService.getTodaysDate();
        if (this.candidateProfile.pointsOverrideHistory) {
          //CHECK
         // this.candidateProfile.pointsOverrideHistory.push(this.updatePointOverideHistory());
        }
        const candidateServiceSave = this.candidateProfileService.updateCandidate(this.candidateProfile, this.isCostProjection);
        this.spinner.hide();
        this.isCostProjection ? this.flashAndCloseDialog(updateCPCandidateResponse, true) : this.flashAndCloseDialog(updateCandidateResponse, true);
    /*     candidateServiceSave.subscribe(
          (response) => {
            this.spinner.hide();
            if (response) {
              this.flashAndCloseDialog(updateCandidateResponse, true);
            }

          },
          err => {
            this.spinner.hide();
          }
        ); */
        break;
      }
      case 'sendInvite': {
        // else if (type === 'sendInvite') {
          this.isCostProjection ? this.candidateProfile.moveStatus= "Requested" : this.candidateProfile.moveStatus= "Authorization Initiated";
        this.candidateProfile.moveStatusDate = this.candidateProfileService.getTodaysDate();
        if (this.candidateProfile.pointsOverrideHistory) {
          //CHECK
         // this.candidateProfile.pointsOverrideHistory.push(this.updatePointOverideHistory());
        }
        const candidateServiceSave = this.candidateProfileService.createCandidate(this.candidateProfile, this.isCostProjection);
        this.spinner.hide();
        this.isCostProjection ? this.flashAndCloseDialog(createCPCandidateResponse, true) :this.flashAndCloseDialog(sendInviteResponse, true);
        break;

        /* const candidateServiceSave = this.candidateProfileService.sendInviteToCandidate(this.candidateProfile);
        candidateServiceSave.subscribe(
          (response) => {
            this.spinner.hide();
            if (response) {
              this.flashAndCloseDialog(sendInviteResponse, true);
            }
            else {
              console.log('Send invite failed');
            }

          },
          err => {
            this.spinner.hide();
          }
        );
        break; */
      }
      case 'reSendInvite': {
        this.isCostProjection ? this.candidateProfile.moveStatus= "Requested" : this.candidateProfile.moveStatus= "Authorization Initiated";
        this.candidateProfile.moveStatusDate = this.candidateProfileService.getTodaysDate();
        if (this.candidateProfile.pointsOverrideHistory) {
          //CHECK
          //this.candidateProfile.pointsOverrideHistory.push(this.updatePointOverideHistory());
        }
        const candidateServiceSave = this.candidateProfileService.updateCandidate(this.candidateProfile, this.isCostProjection);
        this.spinner.hide();
        this.isCostProjection ? this.flashAndCloseDialog(createCPCandidateResponse, true) : this.flashAndCloseDialog(resendInviteResponse, true);
        //  else if (type === 'reSendInvite') {
     /*    const candidateServiceSave = this.candidateProfileService.reSendInviteToCandidate(this.candidateProfile);
        candidateServiceSave.subscribe(
          (response) => {
            this.spinner.hide();
            if (response && this.invitationSent && this.data) {
              this.flashAndCloseDialog(resendInviteResponse, true);
            }
            else if (response && !this.invitationSent) {
              this.flashAndCloseDialog(sendInviteResponse, true);
 }
            else {
              console.log('Resend invite failed');
 }
          },
          err => {
            this.spinner.hide();
          }
        ); */
        break;
      }
      case 'approveMove' : {
        this.candidateProfile.moveStatus= "Approved";
        this.candidateProfile.moveStatusDate = this.candidateProfileService.getTodaysDate();
        if (this.candidateProfile.pointsOverrideHistory) {
          //CHECK
          //this.candidateProfile.pointsOverrideHistory.push(this.updatePointOverideHistory());
        }
        const candidateServiceSave = this.candidateProfileService.updateCandidate(this.candidateProfile, this.isCostProjection);
        this.spinner.hide();
        this.flashAndCloseDialog(sendCPInviteResponse, true);
        // const candidateServiceSave = this.candidateProfileService.approveMove(this.candidateProfile.orderRequestId,this.candidateProfile);
        // candidateServiceSave.subscribe(
        //   (response) => {
        //     this.spinner.hide();
        //       this.flashAndCloseDialog(sendCPInviteResponse, true);
        //     },
        //   err => {
        //     this.spinner.hide();
        //   }
        // );
        break;
      }
      // for move not approve scenario
      case 'notApproveMove' : {
        this.candidateProfile.moveStatus= "Not Approved";
        this.candidateProfile.moveStatusDate = this.candidateProfileService.getTodaysDate();
        if (this.candidateProfile.pointsOverrideHistory) {
          //CHECK
          //this.candidateProfile.pointsOverrideHistory.push(this.updatePointOverideHistory());
        }
        const candidateServiceSave = this.candidateProfileService.updateCandidate(this.candidateProfile, this.isCostProjection);
        this.spinner.hide();
        this.flashAndCloseDialog(approveNotCPCandidateResponse, true);
        break;
      }
    }
  }

  /* PointOveride History */
  // updatePointOverideHistory() {
  //   if (this.candidateProfile.programDetails.pointOverride) {
  //     let pointsOverrideHist = {};
  //     pointsOverrideHist = {
  //       nameDetails: {
  //         firstName: this.candidateProfile.nameDetails.firstName,
  //         lastName: this.candidateProfile.nameDetails.lastName,
  //       },
  //       points: this.candidateProfile.programDetails.points,
  //       date: this.candidateProfileService.getTodaysDate(),
  //     };
  //    // this.candidateProfile.pointsOverrideHistory.push(pointsOverrideHist);
  //     return pointsOverrideHist;
  //   }
  //   else {
  //     console.log("Error in updating points overide history");
  //     return null;
  //   }
  // }

  /**
   * Invoked on click of dismiss/close
   */
  onDismiss(evt) {
    evt.preventDefault();
    if (this.addCandidateForm.dirty || JSON.stringify(this.initialFormValue) != JSON.stringify(this.addCandidateForm.value)) {
      const dialogRef = this.dialog.open(WarningDialogComponent, {
        id: 'dialog2',
        width: 'auto',
        data: {
          message: this.dismissWarningMsg
        },
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result.action === 'dismiss') {
          this.addCandidateForm.reset();
          this.dialogRef.close();
        }
      });
    } else {
      this.addCandidateForm.reset();
      this.dialogRef.close();
      this.addCandidateForm.reset();
    }
  }

  /**
   * function to flash toastr with status message and close dialog on success
   *@param response string
   */
  flashAndCloseDialog(message: string, success) {
    if (success) {
      message == sendCPInviteResponse ? this.candidateProfile.status = 'Approved' : null;
      this.candidateProfile.status == 'Approved' && this.isTraditional? null:
      this.toastrService.info(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
      message == updateCPCandidateResponse || message == createCPCandidateResponse || message == approveNotCPCandidateResponse ? this.candidateProfileService.refreshListSubject.next('refresh') : null;
      this.live.announce(message);
      this.dialogRef.close(this.candidateProfile);
    } else {
      this.toastrService.error(message, null, {
        closeButton: true,
        enableHtml: true,
        disableTimeOut: false // User must explicitly dismiss error messages
      });
    }
  }

  getProgram(division) {
    if (this.userRole === 'file-setup-specialist') {
      this.candidateProfileService.getDivision(division).subscribe(res => {
        res.length > 0 ? this.cciDetails = res : this.cciDetails = [];
        if (this.editCandidateProfile && this.editCandidateProfile.authorizationDetails.cciPartyId && this.addCandidateForm ) {
          this.addCandidateForm.get('authorizationDetails.cciDetails').setValue(this.cciDetails.find( cci =>
            cci.id === this.editCandidateProfile.authorizationDetails.cciPartyId
          ));
        }
          this.filteredClientContactDetails = this.addCandidateForm.get('authorizationDetails.cciDetails').valueChanges
          .pipe(
          startWith(''),
          map(value => this.clientContact_filter(value))
          );
          this.spinner.hide();
        });
        if(this.addCandidateForm) {
          (this.data.candidate && this.data.candidate.authorizationDetails) || (this.userRole === 'file-setup-specialist' && !this.data.candidate) ? this.addCandidateForm.get(['authorizationDetails', 'baseContractNo']).setValue(null) : null;
        }
        this.addCandidateForm ? this.addCandidateForm.get(['programDetails', 'points']).setValue(null): null;
        this.addCandidateForm ? this.addCandidateForm.get(['programDetails', 'programName']).setValue('') : null;
    }
    this.programValues = this.candidateProfileService.getProgram(division);
      /* this.candidateProfileService.getProgram(division)
      .pipe(map(d =>{
        this.programValues = d;
      }))
        .subscribe(res => {
        //  this.programValues = res.cc_divisionPrograms;
        //this.programValues = res;
          if (this.editCandidateProfile) {
            const program = this.programValues.filter((item) => item.programName === this.editCandidateProfile.programDetails.programName);
            this.editCandidateProfile.programDetails.programName ?
            this.addCandidateForm.get(['authorizationDetails', 'baseContractNo']).setValue(program[0].contractId):'';
          }
          this.userRole !== 'file-setup-specialist' ? this.spinner.hide() : null;
        }); */
  }

  getContractBaseDetails(event) {
    if (event.value) {
      let data = this.contractOriginalList.find(ele => ele.contractDescription === event.value.contractName);
      //this.moveTypeValues = [...data.moveTypes];
      this.billiingCurrency = [...data.billingCurrencies];
      this.billiingCurrency.forEach((billCur, index) => {
        let currencyValue = this.currencyList.find(cur => cur.currency && cur.currency.code === billCur);
        this.billiingCurrency[index] = currencyValue;
      });
      this.billingCurrencyValueChange();
      if (this.isTraditional && this.userRole === 'file-setup-specialist') {
        this.addCandidateForm.get(['authorizationDetails', 'moveType']).setValue('');
        this.addCandidateForm.get(['authorizationDetails', 'contractDetails']).setValue(event.value);
      }
      this.addCandidateForm.get(['authorizationDetails', 'currency']).setValue({});
    }
  }

  editPointValidation() {
    // tslint:disable-next-line: triple-equals
    if (this.addCandidateForm.value.programDetails.points != '' && this.addCandidateForm.value.programDetails.points > 0) {
      let message: string;
      this.editPoint = !this.editPoint;
      // const program = this.programValues.find( prog => prog.programName = this.addCandidateForm.value.programDetails.programName);
      if (!this.editPoint) {
        if (+this.points * 0.25 + +this.points <= +this.addCandidateForm.value.programDetails.points) {
          message = 'You have entered points exceeding 25% of Program standard';
          this.toastrService.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        } else if (+this.points - +this.points * 0.1 >= +this.addCandidateForm.value.programDetails.points) {
          message = 'You have reduced Program standard points by 10% or more';
          this.toastrService.info(message, null, {
            closeButton: true,
            enableHtml: true,
            disableTimeOut: false
          });
        }
        if (this.addCandidateForm.value.programDetails.points !== '' && this.addCandidateForm.value.programDetails.points !== this.points) {
          this.addCandidateForm.value.programDetails.pointOverride = true;
        /*   this.candidateProfileService.updatePoints(this.candidateProfileService.orderIdGenarator(), {points: +this.addCandidateForm.value.programDetails.points}).subscribe(res =>
            this.transferee.programDetails.points = this.addCandidateForm.value.programDetails.points
          ); */
        } else {
          this.addCandidateForm.value.programDetails.pointOverride = this.data.candidate.programDetails.pointOverride;
        }
        // this.points = this.addCandidateForm.value.programDetails.points;
      }
    }
  }

  setPoints(event) {
    let program;
    program = this.programValues.find(prog => prog.programName === event.value);
    this.points = program.points;
    if(this.addCandidateForm){
    //program.contractId ? this.addCandidateForm.get(['authorizationDetails', 'contractId']).fo() : null;
    this.addCandidateForm.get(['authorizationDetails', 'baseContractNo']).setValue(program.contractId);
    this.addCandidateForm.get(['programDetails', 'points']).setValue(this.points);
    program.coreOfferings ? this.addCandidateForm.get(['programDetails', 'coreOfferings']).setValue(program.coreOfferings) : undefined;
    }// this.addCandidateForm.value.programDetails.points = this.points;
  }

    /**to display for traditional optional Default Home Country,Default Host Country,
   * Assignment Start Date, Assignment End Date if destination/departure country is not US
    */
     displayDefaultCountry() {
      let destCountry = this.addCandidateForm.value.addressForm.destinationAddr.country;
      let deprCounntry = this.addCandidateForm.value.addressForm.departureAddr.country;
      if((destCountry && destCountry !== 'United States') || (deprCounntry && deprCounntry !== 'United States')) {
        this.isUS_SelectedCheck = true;
        return true;
      }
      this.isUS_SelectedCheck = false;
      return false;
    }
  
    displayJobStartDateTraditional() {
      let destCountry = this.addCandidateForm.value.addressForm.destinationAddr.country;
      let deprCounntry = this.addCandidateForm.value.addressForm.departureAddr.country;
      if((destCountry && destCountry === 'United States') && (deprCounntry && deprCounntry === 'United States')) {
        this.isUS_Move = true;
        return true;
      }
      this.isUS_Move = false;
      return false;
    }

  setDateToUTC(date:Date){    
    return date.toISOString().split('T')[0].concat('T00:00:00.000Z');
  }

  /**billing currency value change update */
  billingCurrencyValueChange() {
    this.filteredCurrency = this.addCandidateForm.get('authorizationDetails.currency').valueChanges
      .pipe(
        startWith(''),
        map(value => this.currency_filter(value))
      );
  }


  genereateConfigurableFieldsForm() {
    let group = {}
    this.additionalDetailsFormGroup = this.formBuilder.group({
      configField: this.formBuilder.array([])
    })
    this.configurableFields.forEach(input_template => {
      if ((input_template.valueList && input_template.type !== 'Multiple Select') || input_template.type === 'Country' ) {
        group[input_template.displayName] = new FormControl('', [Validators.required, this.depValueListMatch.bind(this,input_template.displayName)]);
      } 
      else if(input_template.type === 'Currency'){
        group[input_template.displayName] =  new FormGroup({
          currencyCode: new FormControl('', [Validators.required]),
          currencyAmount: new FormControl('', [Validators.required, Validators.max(9999999999999999)]),          
        });

        this.locationService.countryList.subscribe(countryList => { 
          let observable: any;
          if(countryList.length > 0) {
            const currList = [];
            countryList.forEach((curr) => {
              curr.currency? currList.push(curr): null;
            });
            currList.sort((a, b) =>  a.currency &&  b.currency ? a.currency.code.localeCompare(b.currency.code) : null);
            this.currencyList = currList.filter((e, i) => currList.findIndex(a => a.currency && e.currency ? a.currency.code === e.currency.code : null) === i);
            observable = group[input_template.displayName].get('currencyCode').valueChanges
            .pipe(
              startWith(''),
              map(value => this.singleSelect_filter(value,this.currencyList.map(ele => ele.currency.code)))
            );
            this.filteredSingleSelect.push({ [input_template.displayName]: observable });
          }
        });
      } 
      else {
      group[input_template.displayName] = new FormControl('', [Validators.required]);
      }
      this.additionalDetailsFormGroup = new FormGroup(group);
      if (input_template.type === 'Currency' && input_template.value) {
        this.additionalDetailsFormGroup.get([input_template.displayName, 'currencyCode']).setValue(input_template.value.currencyCode);
        this.additionalDetailsFormGroup.get([input_template.displayName, 'currencyAmount']).setValue(input_template.value.currencyAmount);
      } else
      if (input_template.value) {
        this.additionalDetailsFormGroup.get(input_template.displayName).setValue(input_template.value);
      } else {
        input_template['value'] = fieldMapping.find(ele => ele.DataItemTypDescr === input_template.type).defaultValue;
        this.additionalDetailsFormGroup.get(input_template.displayName).setValue(input_template.value);
      }
     
      if (['Country', 'Single Select', 'Multiple Select'].includes(input_template.type)) {
        let observable: any;
        if (input_template.type === 'Country') {
          if (!(this.countryList && this.countryList.length)) {
            this.locationService.countryList.subscribe(countryList => {
              if (countryList.length > 0) {
                countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
                this.countryNameList = countryList.map(ele=>ele.countryName);
              }
            });
          } else {
            this.countryNameList = this.countryList.map(ele=>ele.countryName);
          }

          observable = this.additionalDetailsFormGroup.get(input_template.displayName).valueChanges
          .pipe(
            startWith(''),
            map(value => this.singleSelect_filter(value,this.countryNameList))
          );
          this.filteredSingleSelect.push({ [input_template.displayName]: observable });
        } else {
          observable = this.additionalDetailsFormGroup.get(input_template.displayName).valueChanges
            .pipe(
              startWith(''),
              map(value => this.singleSelect_filter(value, input_template.valueList))
            );
          this.filteredSingleSelect.push({ [input_template.displayName]: observable });
        }
      }
      
      if (input_template.type === 'Multiple Select') {
        this.multipleOriginalDropDowns.push(Object.assign({}, { [input_template.displayName]: input_template.valueList }));
      }      

    });
  }

  private singleSelect_filter(value,valueList): any[] {
    if(value) {
      const filteredValueList = valueList.filter(Option => Option.toLocaleLowerCase().includes(value.toLocaleLowerCase()));
      return filteredValueList;
    } else {
      return valueList;
    }
  }

   /**
   * To validate if the values provided in additional detail section which are of type 'Country' and "Single Select"
   * @param displayName displayName of field
   * @param control formControl - field parameter to validate against respective list
   */
    private depValueListMatch(displayName: any,control:any): ValidationErrors | null {
      const selection: any = control.value;
      let valueList: any;
      if (this.configurableFields.find(ele => ele.displayName === displayName).type === 'Country') {
        if (!(this.countryList && this.countryList.length)) {
          this.locationService.countryList.subscribe(countryList => {
            if (countryList.length > 0) {
              countryList.sort((a, b) => a.countryName.localeCompare(b.countryName));
              this.countryNameList = countryList.map(ele => ele.countryName);
            }
          });
        } else {
          this.countryNameList = this.countryList.map(ele => ele.countryName);
        }
        valueList = this.countryNameList;
      } else {
        valueList = this.configurableFields.find(ele => ele.displayName === displayName).valueList;
      }
      
     if (valueList && valueList.length > 0 && selection) {
       if ((valueList.filter(val => val.toLowerCase() == selection.toLowerCase())).length < 1) {
         return {
           requireMatch: true
         };
       }
     }
     return null;
   }

  setDateWithOffset(date) {
    let newDate = new Date(date);
    newDate.setMinutes(newDate.getMinutes() + newDate.getTimezoneOffset());
    return newDate;
  }

  getSelection(event: any,displayName:string) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.additionalDetailsFormGroup.get(displayName).setValue([...this.additionalDetailsFormGroup.get(displayName).value,event.source.value]);
      } else {
        const index = this.additionalDetailsFormGroup.get(displayName).value.findIndex(
          element => element == event.source.value
        );
        this.additionalDetailsFormGroup.get(displayName).value.splice(index, 1);
      }
    }
  }

  private _getFilterObservableWrtDisplayName(displayName) {
    return this.filteredSingleSelect.findIndex(ele => Object.keys(ele).includes(displayName)) !== -1 ?
      this.filteredSingleSelect.find(ele => Object.keys(ele).includes(displayName))[displayName] : new Observable();
  }
  private isAdditionalValid() {
    let controlNamesWithList:any = Object.entries(this.additionalDetailsFormGroup.value).filter(ele => {
      if (['Single Select', 'Country'].includes(this.configurableFields.find(curr => curr.displayName === ele[0]).type)) {  
        return true;
      }
    });
    controlNamesWithList = controlNamesWithList.map(ele=> ele[0]);
    let isAnyControlInvalid = controlNamesWithList.map(curr => {
      if (!this.additionalDetailsFormGroup.controls[curr].valid) {
        if (this.additionalDetailsFormGroup.controls[curr].value === '') {
          return true;
        } 
      }
       
      return this.additionalDetailsFormGroup.controls[curr].valid;
    }).includes(false);
    if(!isAnyControlInvalid){
      return true;
    }
    return false;
  }
   /** save draft btn disabled when only either of currency code or amount is entered  */
   private isCurrencyValid() {
    let controlNamesWithCurrencyList: any = Object.entries(this.additionalDetailsFormGroup.value).filter(ele => {
      if(['Currency'].includes(this.configurableFields.find(curr => curr.displayName === ele[0]).type)) {
        return true;
      }
    });
    controlNamesWithCurrencyList = controlNamesWithCurrencyList.map(ele => ele[1]);
    let isCurrencyValid = controlNamesWithCurrencyList.map(curr => {
      if((curr.currencyAmount === null && curr.currencyCode !== '') || (curr.currencyAmount !== null && curr.currencyCode === '')) {
        return false;
      }
    }).includes(false);
    if(!isCurrencyValid) {
      return true
    }
    return false;
  }

  phoneCodeValueChange() {
    this.addCandidateForm.get('phone')['controls'].forEach((phCode, index) => {
      this.filteredOptions = this.addCandidateForm.get('phone')['controls'][index].controls.phoneDialCode.valueChanges
        .pipe(
          startWith(''),
          map(value => this._filter(value))
        );
    });    
  }


  private _generateEmailGroup(emailDetails: Array<emailDetails>): Array<FormGroup> {
    const fb = this.injector.get(FormBuilder);
    const isNull = this.isNullCheck;
    if (emailDetails.length > 0) {
      emailDetails.forEach(mail => {
        mail.usageType === 'business' ? mail.usageType = 'Business': mail.usageType === 'personal'? mail.usageType = 'Personal': null;
      });
      const emailDetailsArray = emailDetails.map(emailDetail => {
        return fb.group({
          _id: [emailDetail._id? isNull(emailDetail, '_id'): null],
          emailAddress: [isNull(emailDetail, 'emailAddress'), this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ? [Validators.required,
            Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]: null],
          // tslint:disable-next-line: rxjs-no-unsafe-scope
          usageType: [emailDetail.usageType? isNull(emailDetail, 'usageType'): '', this.userRole === 'client-contact-initiator' || this.userRole === 'file-setup-specialist' ? [Validators.required]: null],
        });
      });

      return emailDetailsArray;
    } 
    else {
      const emailDetailsArray: Array<FormGroup> = [
        fb.group({
        emailAddress: [null, [Validators.required, Validators.pattern('^[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+(?:\\.[A-Za-z0-9!#$%&\'*+/=?^_‘{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])$')]],
        usageType: [null, [Validators.required]]
        })
      ]
      return emailDetailsArray;
    }
  }

  _generatePhoneGroup(phoneDetails: Array<phoneDetails>): Array<FormGroup> {
      const fb = this.injector.get(FormBuilder);
      const isNull = this.isNullCheck;
      if (phoneDetails.length > 0) {
        phoneDetails = [...phoneDetails];
        phoneDetails.forEach((phoneDetail, index) => {
          let phoneType = null;
          (phoneDetail.textingAvailable && phoneDetail.primary) ? phoneDetail['type'] = 'Mobile' : null;
  
          if (!phoneDetail.textingAvailable) {
            // (phoneDetail.usageType == 'business' && phoneDetail.locationType == 'departure') ? phoneDetail['type'] = 'Departure Business' : null;
            // (phoneDetail.usageType == 'business' && phoneDetail.locationType == 'destination') ? phoneDetail['type'] = 'Destination Business' : null;
            // (phoneDetail.usageType == 'personal' && phoneDetail.locationType == 'departure') ? phoneDetail['type'] = 'Departure Residence' : null;
            // (phoneDetail.usageType == 'personal' && phoneDetail.locationType == 'destination') ? phoneDetail['type'] = 'Destination Residence' : null;
            phoneDetail['type'] = phoneDetail.usageType;
          }
  
          delete phoneDetail.usageType;
        });
  
        const phoneDetailsArray: Array<FormGroup> = phoneDetails.map(phoneDetail => {
          return fb.group({
            _id: [phoneDetail._id? isNull(phoneDetail, '_id'): ''],
            type: [isNull(phoneDetail, 'type'), [Validators.required]],
            phoneDialCode: [isNull(phoneDetail, 'phoneDialCode'), [Validators.required]],
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            phoneNumber: [isNull(phoneDetail, 'phoneNumber'), [Validators.required, Validators.minLength(7),
              Validators.maxLength(18), Validators.min(1000000), Validators.max(999999999999999999),
              Validators.pattern('^[0-9]*$')]]
          });
        });  
        return phoneDetailsArray;
      } else {
        // to handle when phone data is not there defaulting to mobile type ( to handle cci )
        const phoneDetailsArray: Array<FormGroup> = [
          fb.group({
            type: ['Mobile', [Validators.required]],
            phoneDialCode: ['', [Validators.required]],
            // tslint:disable-next-line: rxjs-no-unsafe-scope
            phoneNumber: ['', [Validators.required, Validators.minLength(7),
            Validators.maxLength(18), Validators.min(1000000), Validators.max(999999999999999999),
            Validators.pattern('^[0-9]*$')]]
          })
        ]
        return phoneDetailsArray;
      }
    }

  addNewRow(type: 'phone' | 'email', newIndex: number, contactForm: FormGroup) {
    this.addEditSharedService.addNewPhoneEmailRow(type, contactForm, newIndex, this.phoneTypes, this.emailTypes);
    type === 'phone'? this.phoneCodeValueChange(): null;
  }

  /**to get phone drop down values dynamically */
  getPhoneTypes(curIndex) {
    let phoneTypesList= [];
    let existingPhoneTypes: Array<string> = ((this.addCandidateForm.controls.phone as FormArray).
      getRawValue() as any).map((ph, index) => ph.type);

      existingPhoneTypes = existingPhoneTypes.filter((ph, index) => index !== curIndex);

      // updating emailTypes
      phoneTypesList[curIndex] = [...phoneTypes];
      phoneTypesList[curIndex] = phoneTypesList[curIndex].filter((ph, index) => !existingPhoneTypes.includes(ph));
      this.phoneTypes[curIndex] = phoneTypesList[curIndex];
  }

  /**to get email drop down values dynamically */
  getEmailTypes(curIndex) {
    let emailTypesList = [];
    let existingEmailTypes: Array<string> = ((this.addCandidateForm.controls.email as FormArray).
      getRawValue() as any).map((email, index) => email.usageType);
    existingEmailTypes = existingEmailTypes.filter((email, index) => index !== curIndex);
    // updating emailTypes
    emailTypesList[curIndex] = [...emailTypes];
    emailTypesList[curIndex] = emailTypesList[curIndex].filter((email, index) => !existingEmailTypes.includes(email));
    this.emailTypes[curIndex] = emailTypesList[curIndex];
  }
  /**
 * Remove row from Mobile or Email
 * @param type location of the row
 * @param index index of the location
 */
  deleteRow(type: 'phone' | 'email', index: number, contactForm: FormGroup) {
    (type === 'phone') ?
      this.phoneTypes = this.addEditSharedService.deletePhoneEmailRow(type, index,
        contactForm, this.phoneTypes, this.emailTypes) :
      this.emailTypes = this.addEditSharedService.deletePhoneEmailRow(type, index,
        contactForm, this.phoneTypes, this.emailTypes);
    type === 'phone'? this.phoneCodeValueChange(): null;
  }

}


/**
 * Component to display warning dialog box on dismiss
 */
@Component({
  // tslint:disable-next-line: component-selector
  selector: 'warning-dialog',
  styleUrls: ['./add-edit-transferee.component.scss'],
  template: `
  <style>button:focus  {    text-decoration: underline;}
        div:focus { outline: none !important}
  </style>
  <div mat-dialog-content tabindex = "0"><span aria-hidden = 'true'>{{ data.message }}</span><div>
  <div>
   <button class="edit-ok" mat-button tabindex = 0 (click)='onNoClick()'>OK</button>
   <button class="edit-cancel" mat-button tabindex = 0 [mat-dialog-close]='true'>Cancel</button>
 </div>`
})
export class WarningDialogComponent {

  constructor(
    public dialog: MatDialogRef<WarningDialogComponent>,
    public live: LiveAnnouncer,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    this.live.announce(this.data.message);
  }
  onNoClick(): void {
    this.dialog.close({
      action: 'dismiss'
    });
  }

}
