import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { UserType, UserTypeService } from './../../../../services/user-type.service';

@Component({
  selector: 'app-points-logic-modal',
  templateUrl: './points-logic-modal.component.html',
  styleUrls: ['./points-logic-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PointsLogicModalComponent implements OnInit {
  numList = [];
  pointForm: FormGroup;
  tierConfigs = [];
  /** user role */
  userRole: UserType;
  readonlyMode: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PointsLogicModalComponent>,
    private userTypeService: UserTypeService,
    @Inject(MAT_DIALOG_DATA) public data
    ) { }

  ngOnInit() {
    console.log(this.data.benefit);
    this.userRole = this.userTypeService.userType;
    this.numList = Array.from({length: 10}, (v, k) => k + 1);
    this.pointForm = new FormGroup({
      pointsArray: new FormArray([
        this.initPointSection()
      ]),
      pointsPerSelection: new FormControl(this.data.benefit.advCashOutV2? this.data.benefit.pointsPerSelection: null, this.data.benefit.advCashOutV2? [ Validators.required, Validators.min(1)] : null)
    });
    if (this.data.benefit && this.data.benefit.tierConfigs && this.data.benefit.tierConfigs.length > 0) {
      this.data.benefit.tierConfigs.forEach((item, key) => {
        if (this.data.benefit.tierConfigs.length - 2 >= key) {
          const control = <FormArray>this.pointForm.get(['pointsArray']);
          control.push(this.initPointSection());
        }
          this.pointForm.get(['pointsArray', key]).setValue({
            maxSelection : item.maxSelection,
            value : item.pointValue? item.pointValue : 0
          });
        
      });
    }
    // if (this.userRole !== 'program-admin' || ( !this.data.program.isNew && !this.data.program.draft && this.userRole === 'program-admin')) {
    //   this.pointForm.disable();
    //   this.readonlyMode = true;
    // }
  }

  initPointSection() {
    return new FormGroup({
      maxSelection: new FormControl('', [Validators.required]),
      value : new FormControl('', [Validators.required])
    });
  }

  close() {
    this.dialogRef.close();
  }

  getPointSections(form) {
    //console.log(form.get('options').controls);
    return form.controls.pointsArray.controls;
  }

  save() {
    let maxSelection = 0;
    this.pointForm.get('pointsArray').value.forEach((item) => maxSelection = item.maxSelection + maxSelection);
    this.data.benefit.maxSelection = maxSelection;
    this.data.benefit.advCashOutV2? this.data.benefit.pointsPerSelection = Number(this.pointForm.get('pointsPerSelection').value): null;
    this.data.benefit.tierConfigs = this.tierConfigs;
    this.dialogRef.close(this.data);
    //this.pointForm.get(['pointsArray', this.pointForm.get('pointsArray').value.length - 1, 'maxSelection']).value
  }

  getPointsSections() {
    console.log(this.pointForm.get('pointsArray'));
    const control = <FormArray>this.pointForm.get('pointsArray');
    control.push(this.initPointSection());
  }

  calculateRange(range, index) {
    let minRange = 1;
    let maxRange = 0;
    for ( let i = 0; i <= index; i++) {
      if (index === 0) {
        minRange = 1;
        maxRange = this.pointForm.get(['pointsArray', index, 'maxSelection']).value;
      }  else if (this.pointForm.get(['pointsArray', i, 'maxSelection']).value && index > 0) {
        minRange = i < index ? this.pointForm.get(['pointsArray', i, 'maxSelection']).value + minRange  : minRange;
        maxRange = minRange + this.pointForm.get(['pointsArray', index, 'maxSelection']).value - 1;
      }
    }
    if (this.tierConfigs[index]) {
      this.tierConfigs[index].maxSelection = range;
      this.tierConfigs[index].minRange = minRange;
      this.tierConfigs[index].maxRange = maxRange;
    } else {
      this.tierConfigs.push({
        maxSelection : range,
        minRange : minRange,
        maxRange : maxRange
      });
    }
    return minRange + ' - ' + maxRange;
  }

  calculatePoints(index) {
    let value = 0;
    //let maxRange = 0;
    for ( let i = 0; i <= index; i++) {
      value = this.pointForm.get(['pointsArray', i, 'value']).value * this.pointForm.get(['pointsArray', i, 'maxSelection']).value + value;
    }
    (value>= 0) && this.tierConfigs[index] ? 
      this.tierConfigs[index].pointValue = this.pointForm.get(['pointsArray', index, 'value']).value  : null;
    return value && this.pointForm.get(['pointsArray', index, 'value']).value ? this.numberWithCommas(value) : 0;
  }

  numberWithCommas(x) {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x))
        x = x.replace(pattern, "$1,$2");
    return x;
  }
  
  deletePoints(index) {
    if ((this.userRole !== 'program-admin' || ( !this.data.program.isNew && !this.data.program.draft && this.userRole === 'program-admin'))) {
      const formArrayControl = <FormArray>this.pointForm.get(['pointsArray']);
      formArrayControl.removeAt(index);
      this.tierConfigs.splice(index, 1);
    }
  }

  disableAdd() {
    const pointLogicSection = this.pointForm.get('pointsArray').value[this.pointForm.get('pointsArray').value.length - 1];
    if (!pointLogicSection) {
      return false;
    }
    if ((pointLogicSection.maxSelection !== '') && (pointLogicSection.value !== '')) {
      return false;
    }
    return true;
  }
}
