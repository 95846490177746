import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AdminClient } from './../../../../core/models/admin-client.model';
@Component({
  selector: 'app-client-details',
  templateUrl: './client-details.component.html',
  styleUrls: ['./client-details.component.scss']
})
export class ClientDetailsComponent implements OnInit {

  /** Basic details of client to be displayed */
  @Input() clientDetails: AdminClient;

  @Output() closePanel = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() { }

  close() {
    this.closePanel.emit(true);
  }

}
