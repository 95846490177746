import { Injectable } from '@angular/core';
/** DateConversionService to convert date object to date string */
@Injectable({
  providedIn: 'root'
})

export class DateConversionService {
  /** Reference array of short month abbreviations */
  private monthAbbr: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  /** Convert number to two digit string */
  private getTwoDigitumber(n: number): string {
    return ('00' + n).slice(-2);
  }

  /** Convert Date object to date string */
  convertToYyyyMmDd(dateObj: Date): string {
    if (dateObj && Object.prototype.toString.call(dateObj) === '[object Date]') {
      const month = this.getTwoDigitumber(dateObj.getMonth() + 1);
      return `${dateObj.getFullYear()}-${month}-${this.getTwoDigitumber(dateObj.getDate())}`;
    } else {
      //console.error('Unable to convert date format.');
      return undefined;
    }
  }​

  /** Convert date string to Date object */
  convertFromYyyyMmDd(strDate: string): Date {
    if (!!strDate && strDate.length > 0) {
      const strArr: string[] = strDate.split('-');
      return new Date(+strArr[0], +strArr[1] - 1, +strArr[2]);
    } else {
      //console.error('Unable to convert date format.');
      return undefined;
    }
  }

  /** Convert date string to display date */
  convertToDisplayDate(strDate: string): string {
    if (!!strDate && strDate.length > 0) {
      const strArr: string[] = strDate.split('-');
      return this.getTwoDigitumber(+strArr[2]) + '-' + this.monthAbbr[+strArr[1] - 1] + '-' + strArr[0];
    } else {
      //console.error('Unable to convert date format.');
      return undefined;
    }
  }

  /** Convert display date string to date string */
  convertFromDisplayDate(strDate: string): string {
    if (!!strDate && strDate.length > 0) {
      const strArr: string[] = strDate.split('-');
      return this.convertToYyyyMmDd(new Date(+strArr[2], this.monthAbbr.indexOf(strArr[1]), +strArr[0]));
    } else {
      //console.error('Unable to convert date format.');
      return undefined;
    }
  }

  /** Convert date string to epoch number */
  convertToEpoch(strDate: string): number {
    if (!!strDate && strDate.length > 0) {
      return Number(this.convertFromYyyyMmDd(strDate));
    } else {
      //console.error('Unable to convert date format.');
      return undefined;
    }
  }

  /** Convert date string to MM-DD-YYYY */
  convertToMmDdYyyy(strDate: string): string {
    if (!!strDate && strDate.length > 0) {
      const strArr: string[] = strDate.split('-');
      return this.getTwoDigitumber(+strArr[1]) + '-' + strArr[2] + '-' + strArr[0];
    } else {
      //console.error('Unable to convert date format.');
      return undefined;
    }
  }

   /** Convert date string to YYYY-MM-DD */
   convertToYYYYMMDD(strDate: string): string {
    if (!!strDate && strDate.length > 0) {
      const strArr: string[] = strDate.split('-');
      return (+strArr[0]) + '-' + strArr[1] + '-' + strArr[2];
    } else {
       return undefined;
    }
  }

}
