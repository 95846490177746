import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, Observable, forkJoin, of } from 'rxjs';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TransactionService } from '../../../../core/services/transaction.service';
import { TransactionData } from '../../../../core/models/transaction-data.model';
import { ActivatedRoute } from '@angular/router';
import { take } from 'rxjs/operators';
import { Transaction } from '../../../../core/models/transaction.model';
import { AggregationFetchparameter } from '../../../../core/models/aggregation-fetch-parameter.model';
import { EmployeeDetailsService } from '../../../../core/services/employee-details.service';
import { EmployeeInfo } from '../../../../core/models/employee-info.model';
import { NumbersService } from '../../../../core/services/numbers.service';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { UserPreference } from '../../../../core/models/user-preference.model';
import { UserPreferencesService } from '../../../../core/services/user-preferences.service';
import { CdkDragStart, CdkDropList, moveItemInArray } from '@angular/cdk/drag-drop';
import { DataviewSelectionModalComponent } from './../dataview-selection-modal/dataview-selection-modal.component';
import { UserConfigService } from '../../../../core/services/user-config.service';
import * as financialColumns from '../../../../core/data/financialDataView.json';
//import { userPreference } from '../data/data';

/** Employee transaction component */
@Component({
  selector: 'app-employee-transactions',
  templateUrl: './employee-transactions.component.html',
  styleUrls: ['./employee-transactions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EmployeeTransactionsComponent implements OnInit, OnDestroy {
  /** Base constructor method
  * @param dialog MatDialog injection
  * @param transactionService TransactionService injection
  */
  constructor(
    public dialog: MatDialog,
    private readonly route: ActivatedRoute,
    private readonly userPreferenceService: UserPreferencesService,
    private readonly transactionService: TransactionService,
    private readonly numberService: NumbersService,
    private readonly dateSvc: DateConversionService,
    private empDetailSvc: EmployeeDetailsService,
    private readonly userConfigService: UserConfigService
  ) {
    this.dataSource = new MatTableDataSource();
    this.pageEvent = new PageEvent();
    this.pageEvent.pageIndex = 0;
    this.pageEvent.pageSize = 25;
  }
  /** employeeTransactionView variable to store the response data */
  employeeTransactionView: Subscription;

  /** displayedColumns variable array to store the displayed columns */
  displayedColumns: string[] = [];

  /** defaultColumn to store default columns */
  defaultColumn: string[] = [];
  financialSelction: string[] = [
    'FinancialViewColumnDisplay'
  ];

  /** dataSource variable to store the response converted to mat table datasource */
  dataSource: MatTableDataSource<TransactionData>;

  // dataviewSelection to access the dialog
  dataviewSelection: MatDialogRef<DataviewSelectionModalComponent>;

  /** totalTransactionAmt variable to hold the total transaction amount */
  totalTransactionAmt: any;

  /** totalTransactionAmtCurr variable to hold the total transaction amount currency*/
  totalTransactionAmtCurr: string;

  /** transactionArr variable to hold the grouped by category data*/
  transactionArr: TransactionData[] = [];

  /** taxAssistance variable to hold the tax Assistance*/
  taxAssistance: any;

  /** totalExpense variable to hold the total Expense*/
  totalExpense: any;

  /** To sort the mat table columns */
  @ViewChild(MatSort, { static: false }) sort: MatSort;

  /** To paginate in a mat table */
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  /** Pagination page event */
  pageEvent: PageEvent;

  /**store the filter value */
  filterText = '';

  /** searchKeyWord inputed from the parent dashboard component */
  @Input() searchKeyword;

  /** transactionsReport will hold the 'Cost Transactions' for report generation */
  transactionsReport = 'Employee Transactions';

  /** sortDirection to hold the asc or desc direction value */
  sortDirection: any;

  /** sortColumnName to hold the clicked column name for sorting */
  sortColumnName: any;

  /** sortOptions to hold the sort column name and sort direction */
  sortOptions = {};

  /** initialSortColumn to hold the column name for inital sorting. */
  initialSortColumn = 'invoiceDate';

  /** initialSortDirection to hold the column name for inital sorting. */
  initialSortDirection = 'desc';

  /** store preferred currency */
  preferredCurr = '';

  public fixed = false;

  /**traditional move order- order request ID*/
  @Input() orderRequestId: string;
  @Input() fileNumber: any;
  @Input() traditional: boolean;
  /**to send the totalBillAmount, totalBillCurrency to transferee detail component*/
  @Output() sendTotalBillAmount: EventEmitter<number> =   new EventEmitter();
  @Output() sendTotalBillCurrency: EventEmitter<string> = new EventEmitter();
  /** columns variable array to store the column fields, headers, and sortName */
  columns: any[] = [
    { field: 'invoiceDate', header: 'Billed Date', sortName: 'invoiceDate' },
    { field: 'accountCategory', header: 'Account Category', sortName: 'accountCategory', },
    { field: 'accountSubCategory', header: 'Account Sub Category', sortName: 'accountSubCategory', },
    { field: 'transactionDesc', header: 'Transaction Description', sortName: 'transactionDesc', },
    { field: 'invoiceNo', header: 'Invoice Number', sortName: 'invoiceNo' },
    { field: 'billAmount', header: 'Billed Amount', sortName: 'billAmount' },
  ];
  previousDragIndex: number;
  dragColumnName: string;
  financialColumnsList: any = (financialColumns as any).default;
  pageSizeOptions: any[];

  applySort(columnField: any) {
    this.clearInitialSort();
    let columnName: string;
    columnName = columnField;
    if (this.sortColumnName === columnName) {
      if (this.sortDirection === 'asc') {
        this.sortDirection = 'desc';
      } else {
        this.sortDirection = 'asc';
      }
    } else {
      this.sortColumnName = columnName;
      this.sortDirection = 'asc';
    }
    this.sortOptions['sortColumnName'] = this.sortColumnName;
    this.sortOptions['sortDirection'] = this.sortDirection;
    this.updateUserPreference([this.sortOptions], 'FinancialViewColumnSort');
    const aggregationFetchParameter: AggregationFetchparameter = {
      pageNumber: this.pageEvent.pageIndex,
      itemsPerPage: this.pageEvent.pageSize,
      sortColumnName: this.sortColumnName,
      sortOrder: this.sortDirection,
    };
    this.filterTransactionDetails(aggregationFetchParameter);
  }

  /** Init method to make Service call, to convert response to mat table data and to initialize sort, pagination */
  ngOnInit() {
    this.setConfiguration().subscribe(() => {
      let setTransactionData: AggregationFetchparameter = this.setTransactionDataFetchParam();
      this.taxAssistance = 0;
      forkJoin([
        this.userPreferenceService.getPreference('page_event_transaction', false),
      ]).subscribe((response) => {
        if (response[0]) {
          this.pageEvent = response[0];
          setTransactionData = {
          itemsPerPage: this.pageEvent.pageSize,
          pageNumber: this.pageEvent.pageIndex,
        };
       }
       setTransactionData.sortColumnName = this.initialSortColumn;
       setTransactionData.sortOrder = this.initialSortDirection;
       this.sortColumnName = this.initialSortColumn;
       this.sortDirection = this.initialSortDirection;
       if(this.traditional){
        const preference: UserPreference = {
          Key: 'user_config',
          Value: userPreference1,
          Persist: false,
        };
        this.userPreferenceService.setPreference(preference);
       }
       this.filterTransactionDetails(setTransactionData);
    });
    });
  }

  filterTransactionDetails(aggregationFetchParameter: AggregationFetchparameter) {
    if (this.displayedColumns.length === 0) {
      this.setConfiguration();
    }
    aggregationFetchParameter.pageNumber =
      aggregationFetchParameter.pageNumber + 1;
    aggregationFetchParameter.preferredCurr = this.preferredCurr;
    if (!this.traditional) {
    this.employeeTransactionView = this.route.params.subscribe((params) => {
      const fileNumbers: number = +params.id;
      this.transactionService
        .getTransactionByFileNum(fileNumbers, aggregationFetchParameter)
        .pipe(take(1))
        .subscribe((res: Transaction) => {
          const employeeTransactionData = res.results;
          if (employeeTransactionData && employeeTransactionData.length > 0) {
            employeeTransactionData.forEach((employee) => {
              if (employee.billAmount && employee.billCurrency) {
                employee.billAmount = this.numberService.formatForCurrency(
                  employee.billAmount,
                  employee.billCurrency
                );
              }
            });
          }
          this.dataSource = new MatTableDataSource<TransactionData>(
            employeeTransactionData
          );
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.length = res.count;
            this.paginator.pageIndex = this.pageEvent.pageIndex;
          });
          this.getTransactionSum(res);
        });
    })
  } else {
    if (this.orderRequestId && this.fileNumber) {
      const preference: UserPreference = {
        Key: 'user_config',
        Value: userPreference1,
        Persist: false,
      };
      this.userPreferenceService.setPreference(preference);
      this.transactionService
        .getTransactionByFileNum(this.fileNumber, aggregationFetchParameter)
        .pipe(take(1))
        .subscribe((res: Transaction) => {
          const employeeTransactionData = res.results;
          if (employeeTransactionData && employeeTransactionData.length > 0) {
            employeeTransactionData.forEach((employee) => {
              if (employee.billAmount && employee.billCurrency) {
                employee.billAmount = this.numberService.formatForCurrency(
                  employee.billAmount,
                  employee.billCurrency
                );
              }
            });
          }
          this.dataSource = new MatTableDataSource<TransactionData>(
            employeeTransactionData
          );
          this.dataSource.paginator = this.paginator;
          setTimeout(() => {
            this.paginator.length = res.count;
            this.paginator.pageIndex = this.pageEvent.pageIndex;
          });
          this.getTransactionSum(res);
        });
    }
  }
  }

  /** A setData method to group by category and to find the transaction total */
  getTransactionSum(transaction: Transaction) {
    let transactionTotal: TransactionData = null;
    this.totalTransactionAmt = 0;
    if (transaction) {
      transaction.results.forEach((data) => {
        const transactionObj: TransactionData = this.transactionArr.find(
          (a) => a.accountCategory === data.accountCategory
        );
        if (!transactionObj) {
          transactionTotal = {
            accountCategory: data.accountCategory,
            accountSubCategory: data.accountSubCategory,
            transactionDesc: data.transactionDesc,
            invoiceDate: data.invoiceDate,
            transactionAmount: data.transactionAmount,
            transactionCurrency: data.transactionCurrency,
            billAmount: data.billAmount,
            billCurrency: data.billCurrency,
            billedDate: data.billedDate,
            invoiceNo: data.invoiceNo,
          };
          this.transactionArr.push(transactionTotal);
        }
      });
      this.totalTransactionAmt = transaction.totalExpense;
      this.totalTransactionAmtCurr = transaction.transactionCurrCode;
      if (transaction.totalTaxAssistance != 0 && transaction.totalTaxAssistance) {
        this.taxAssistance = this.numberService.formatForCurrency(
          transaction.totalTaxAssistance,
          this.totalTransactionAmtCurr
        );
        this.totalExpense = this.numberService.formatForCurrency(
          transaction.totalExpense,
          this.totalTransactionAmtCurr
        );
      }
      this.totalTransactionAmt = this.numberService.formatForCurrency(
        this.totalTransactionAmt,
        this.totalTransactionAmtCurr
      );
      /**to send the data to transferee-detail component */
      this.sendTotalBillAmount.emit(transaction.totalExpense);
      this.sendTotalBillCurrency.emit(transaction.transactionCurrCode);
    }
  }

  /** setEmployeeDataFetchParam method to initialize object */
  setTransactionDataFetchParam() {
    this.pageEvent.pageIndex = 0;
    return {
      itemsPerPage: 25,
      pageNumber: 0,
    };
  }

  /** onPageChange method to handle page events (page size and page number) */
  onPageChange(e: PageEvent) {
    if (
      this.pageEvent &&
      e.pageSize &&
      this.pageEvent.pageSize !== this.pageEvent.pageSize
    ) {
      this.pageEvent.pageIndex = 0;
    } else {
      this.pageEvent.pageIndex = e.pageIndex;
    }
    this.pageEvent.pageSize = e.pageSize;
    const preference: UserPreference = {
      Key: 'page_event_transaction',
      Value: this.pageEvent,
      Persist: false,
    };
    this.updateUserPreference([this.pageEvent.pageSize], 'FinancialViewPageSize');
    this.userPreferenceService.setPreference(preference);
    if (this.pageEvent) {
      this.userPreferenceService
        .getPreference('user_config', false)
        .subscribe((response) => {
          const aggregationFetchParameter: AggregationFetchparameter = {
            pageNumber: this.pageEvent.pageIndex,
            itemsPerPage: this.pageEvent.pageSize,
          };
          response.forEach((item) => {
            if (item.preferenceconfig.name === 'FinancialViewColumnSort') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                aggregationFetchParameter.sortColumnName = item.preferencesetting.value[0].sortColumnName;
                aggregationFetchParameter.sortOrder = item.preferencesetting.value[0].sortDirection;
              } else {
                aggregationFetchParameter.sortColumnName = item.preferenceconfig.default[0].sortColumnName;
                aggregationFetchParameter.sortOrder = item.preferenceconfig.default[0].sortDirection;
              }
            }
          });
          this.filterTransactionDetails(aggregationFetchParameter);
        });
    }
  }
  /** To Destroy the subscribed method */
  ngOnDestroy() {
    if (this.employeeTransactionView) {
      this.employeeTransactionView.unsubscribe();
    }
  }

  /** To Clear the initalSort variables */
  clearInitialSort(): void {
    this.initialSortColumn = null;
    this.initialSortDirection = null;
  }

  /** Rearranges columns array and calls the setDisplayedColumnscolumns function */
  dragDrop(event: CdkDropList<string[]>, index: number) {
    moveItemInArray(this.columns, this.previousDragIndex, index);
    this.dragColumnName = '';
    this.setDisplayedColumns();
  }

  /** Captures previous index of dragged item */
  dragStarted(event: CdkDragStart, index: number) {
    const colName = event.source.element.nativeElement.innerText;
    const column = this.columns.find(col => col.header === colName);
    this.dragColumnName = column.sortName;
    this.previousDragIndex = index;
  }

  /** Rebuilds the displayedColumns array using the columns array order */
  setDisplayedColumns() {
    const orderCol: string[] = [];
    this.columns.forEach((column, index) => {
      column.index = index;
      orderCol.push(column.field);
    });
    this.updateUserPreference(orderCol, 'FinancialViewColumnOrder');
  }

  /** to set user preference configuration for employee list */
  setConfiguration(): Observable<any> {
    let displayColumn: string[] = [];
    let orderColumn: string[] = [];
    this.displayedColumns = [];
    this.userPreferenceService.getPreference('user_config', false)
      .subscribe((response) => {
        if (response) {
          response.forEach((item) => {
           /*  console.log('checking financial');
            console.log(item); */
            if (item.preferenceconfig.name === 'FinancialViewColumnDisplay') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                displayColumn = item.preferencesetting.value;
              } else {
                displayColumn = item.preferenceconfig.default;
              }
            } else if (item.preferenceconfig.name === 'FinancialViewColumnOrder') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                // this code below is to fix the corrupted preferences by checking if the config.default.length
                // is greater than the preferencesetting.value
                if (item.preferenceconfig.default.length > item.preferencesetting.value.length) {
                  item.preferenceconfig.default.map(row => {
                    if (!item.preferencesetting.value.includes(row)) {
                      item.preferencesetting.value.unshift(row);
                    }
                  });
                  orderColumn = item.preferencesetting.value;
                  this.updateUserPreference(orderColumn, 'FinancialViewColumnOrder');
                }
                orderColumn = item.preferencesetting.value;
              } else {
                orderColumn = item.preferenceconfig.default;
              }
            } else if (item.preferenceconfig.name === 'FinancialViewPageSize') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                this.paginator.pageSize = item.preferencesetting.value[0];
              } else {
                this.paginator.pageSize = item.preferenceconfig.default[0];
              }
            } else if (item.preferenceconfig.name === 'FinancialViewColumnSort') {
              if ('preferencesetting' in item && item.preferencesetting.value.length > 0) {
                this.initialSortColumn = item.preferencesetting.value[0].sortColumnName;
                this.initialSortDirection = item.preferencesetting.value[0].sortDirection;
              } else {
                this.initialSortColumn = item.preferenceconfig.default[0].sortColumnName;
                this.initialSortDirection = item.preferenceconfig.default[0].sortDirection;
              }
            }
            if (item.preferenceconfig.name == "preferredCurrency") {
              if (
                'preferencesetting' in item &&
                item.preferencesetting.value
              ) {
              item.preferencesetting.value ? this.preferredCurr = item.preferencesetting.value : null;
              } 
            }
          });
          this.columns = [];
          orderColumn.forEach((item, index, array) => {
            const column = this.financialColumnsList.find( col => col.field === item );
            let billamountFiled: any;
            let billAmountCol: any;
            if (item === 'billAmount') {
               billamountFiled =  item;
               billAmountCol = column;
            }
            if (index === array.length - 1) {
              this.columns.push({ field: item, header: billAmountCol.header, sortName: billAmountCol.sortName });
            } else {
              this.columns.push({ field: item, header: column.header, sortName: column.sortName });
            }
            if (displayColumn.includes(item)) {
              this.displayedColumns.push(item);
            }
          });
        }
      });
      return of(true);
  }

  /** dataview selection method to open modal popup */
  financialDataViewSelectionModal() {
    this.dataviewSelection = this.dialog.open(DataviewSelectionModalComponent, {
      data: {
        dataKey: this.financialSelction
      },
      panelClass: 'DataviewSelectionModal',
      autoFocus: false,
      restoreFocus: false
    });
    this.dataviewSelection.afterClosed().subscribe(displayedColumns => {
      if (displayedColumns && displayedColumns.length > 0) {
        this.updateUserPreference(displayedColumns, 'FinancialViewColumnDisplay');
      }
    });
  }

  updateFinancialViewColumnDisplay(displayCol, key,  userConfig) {
    userConfig.forEach((item) => {
      if (
        item.preferenceconfig.name === key
      ) {
        if ("preferencesetting" in item &&
          item.preferencesetting.value.length > 0) {
          item.preferencesetting.value = displayCol;
        } else {
          item.preferencesetting = {
            "appName": "MovePro360",
            "createdBy": "5ef07c7cacc6800007f117d0",
            "name": "EmployeeListViewColumnOrder",
            "partyId": "5ef07c7cacc6800007f117d0",
            "updatedBy": "5ef07c7cacc6800007f117d0",
            "value":displayCol            
          };

        }
      }
    });
  }
  updateUserPreference(req, key) {
    let reqObj: any;
    reqObj = {
      name: key,
      value: req,
    };
    this.userConfigService.updatePreference(reqObj).subscribe((userConfig) => {
      if (userConfig) {
        this.updateFinancialViewColumnDisplay(req,key,userConfig);
        const preference: UserPreference = {
          Key: 'user_config',
          Value: userConfig,
          Persist: false,
        };
        this.userPreferenceService.setPreference(preference);
        this.setConfiguration();
      }
    });
  }
}

export const userPreference1 =
[
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"aggregationName",
				"activeEmployees",
				"currentYtdCosts",
				"currentYtdCurr",
				"pastYtdCosts",
				"pastYtdCurr",
				"changeOfTotalCost",
				"moveAtRiskCount",
				"moveOnTrackCount"
			],
			"_id": "5ede9644a58ea603352a1e19",
			"name": "AggregateViewColumnSort",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "select",
			"default": [
				{
					"sortColumnName": "aggregationName",
					"sortOrder": "asc"
				}
			],
			"description": "Default Sort order and direction for the Aggregated view"
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"departure",
				"destination",
				"division",
				"effectiveTransferDate",
				"fileStatusDesc",
				"fullName",
				"milestone",
				"movePhase",
				"moveAtRisk",
				"policy",
				"providers",
				"sentiment",
				"starred",
				"totalCost"
			],
			"_id": "5ede9644a58ea603352a1e1a",
			"name": "EmployeeListViewColumnSort",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "select",
			"default": [
				{
					"sortColumnName": "lastName",
					"sortOrder": "asc"
				}
			],
			"description": "Default Sort and direction or for the Employee List view"
		}
	},
	{
		"preferencesetting": {
			"_id": "60e5a381baf8d20008a3949c",
			"appName": "MovePro360",
			"name": "FinancialViewColumnSort",
			"value": [
				{
					"sortColumnName": "accountCategory",
					"sortDirection": "desc"
				}
			],
			"partyId": "5ef06b187a04b900086582c8",
			"createdBy": "5ef06b187a04b900086582c8",
			"updatedBy": "5ef06b187a04b900086582c8",
			"__v": 1
		},
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"invoiceDate",
				"accountCategory",
				"accountSubCategory",
				"transactionDesc",
				"invoiceNo",
				"billAmount"
			],
			"_id": "5ede9644a58ea603352a1e1b",
			"name": "FinancialViewColumnSort",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "select",
			"default": [
				{
					"sortColumnName": "invoiceDate",
					"sortOrder": "desc"
				}
			],
			"description": "Default Sort and direction or for the Employee List view"
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"departureCountry",
				"destinationCountry",
				"division",
				"employeeList",
				"policy"
			],
			"_id": "5ede9644a58ea603352a1e1c",
			"name": "Dashboard",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "employeeList",
			"description": "MovePro360 Dashboard Default View"
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"departure",
				"destination",
				"division",
				"effectiveTransferDate",
				"fileStatusDesc",
				"moveAtRisk",
				"movePhase",
				"policy",
				"providers",
				"milestone",
				"sentiment",
				"starred",
				"totalCost"
			],
			"_id": "5ede9644a58ea603352a1e1d",
			"name": "EmployeeListViewColumnDisplay",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"departure",
				"destination",
				"division",
				"effectiveTransferDate",
				"fileStatusDesc",
				"fullName",
				"moveAtRisk",
				"movePhase",
				"policy",
				"providers",
				"milestone",
				"sentiment",
				"starred",
				"totalCost"
			],
			"description": "MovePro360 Dashboard Employee List Default View"
		}
	},
	{
		"preferencesetting": {
			"_id": "60e5a3c0baf8d20008a394a4",
			"appName": "MovePro360",
			"name": "FinancialViewColumnDisplay",
			"value": [
				"billAmount",
				"invoiceDate",
				"accountSubCategory",
				"accountCategory",
				"transactionDesc",
				"invoiceNo"
			],
			"partyId": "5ef06b187a04b900086582c8",
			"createdBy": "5ef06b187a04b900086582c8",
			"updatedBy": "5ef06b187a04b900086582c8",
			"__v": 3
		},
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"accountCategory",
				"accountSubCategory",
				"invoiceNo",
				"transactionDesc"
			],
			"_id": "5ede9644a58ea603352a1e1e",
			"name": "FinancialViewColumnDisplay",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"accountCategory",
				"accountSubCategory",
				"billAmount",
				"invoiceDate",
				"invoiceNo",
				"transactionDesc"
			],
			"description": "MovePro360 All Transactions page Default View"
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"activeEmployees",
				"aggregationName",
				"changeOfTotalCost",
				"currentYtdCosts",
				"currentYtdCurr",
				"moveAtRiskCount",
				"moveOnTrackCount",
				"pastYtdCosts",
				"pastYtdCurr"
			],
			"_id": "5ede9644a58ea603352a1e1f",
			"name": "AggregatedViewDisplay",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"activeEmployees",
				"aggregationName",
				"changeOfTotalCost",
				"currentYtdCosts",
				"currentYtdCurr",
				"moveAtRiskCount",
				"moveOnTrackCount",
				"pastYtdCosts",
				"pastYtdCurr"
			],
			"description": "MovePro360 Dashboard Aggregated View"
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"division",
				"fileStatusDesc",
				"movePhase",
				"policy",
				"departure",
				"destination",
				"providers",
				"sentiment",
				"effectiveTransferDate",
				"milestone",
				"totalCost",
				"moveAtRisk"
			],
			"_id": "5ede9644a58ea603352a1e20",
			"name": "EmployeeListViewColumnOrder",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dragdrop",
			"default": [
				"starred",
				"fullName",
				"division",
				"fileStatusDesc",
				"movePhase",
				"policy",
				"departure",
				"destination",
				"providers",
				"sentiment",
				"effectiveTransferDate",
				"milestone",
				"totalCost",
				"moveAtRisk"
			],
			"description": "MovePro360 Dashboard Employee List Default Column Order"
		}
	},
	{
		"preferencesetting": {
			"_id": "60dedd3aad3d53000948f40e",
			"appName": "MovePro360",
			"name": "FinancialViewColumnOrder",
			"value": [
				"invoiceDate",
				"accountCategory",
				"accountSubCategory",
				"transactionDesc",
				"invoiceNo",
				"billAmount"
			],
			"partyId": "5ef06b187a04b900086582c8",
			"createdBy": "5ef06b187a04b900086582c8",
			"updatedBy": "5ef06b187a04b900086582c8",
			"__v": 1
		},
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"accountCategory",
				"accountSubCategory",
				"invoiceNo",
				"transactionDesc"
			],
			"_id": "5ede9644a58ea603352a1e21",
			"name": "FinancialViewColumnOrder",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dragdrop",
			"default": [
				"invoiceDate",
				"accountCategory",
				"accountSubCategory",
				"transactionDesc",
				"invoiceNo",
				"billAmount"
			],
			"description": "MovePro360 All Transactions Default Column Order"
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"accountCategory",
				"accountSubCategory",
				"invoiceNo",
				"transactionDesc"
			],
			"_id": "5ede9644a58ea603352a1e22",
			"name": "AggregatedViewColumnOrder",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dragdrop",
			"default": [
				"aggregationName",
				"activeEmployees",
				"currentYtdCosts",
				"currentYtdCurr",
				"pastYtdCosts",
				"pastYtdCurr",
				"changeOfTotalCost",
				"moveAtRiskCount",
				"moveOnTrackCount"
			],
			"description": "MovePro360 Dashboard aggregated view Default  Column Order "
		}
	},
	{
		"preferencesetting": {
			"_id": "60e43b4862e6bc00080ca279",
			"appName": "MovePro360",
			"name": "preferredCurrency",
			"value": "USD",
			"partyId": "5ef06b187a04b900086582c8",
			"createdBy": "5ef06b187a04b900086582c8",
			"updatedBy": "5ef06b187a04b900086582c8",
			"__v": 1
		},
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				{
					"currencyAbbr": "AUD",
					"currencyName": "Australian Dollar"
				},
				{
					"currencyAbbr": "BRL",
					"currencyName": "Brazilian Real"
				},
				{
					"currencyAbbr": "CAD",
					"currencyName": "Canadian Dollar"
				},
				{
					"currencyAbbr": "CHF",
					"currencyName": "Swiss Franc"
				},
				{
					"currencyAbbr": "CNY",
					"currencyName": "Chinese Yuan Renminbi"
				},
				{
					"currencyAbbr": "CZK",
					"currencyName": "Czech Koruna"
				},
				{
					"currencyAbbr": "DKK",
					"currencyName": "Danish Krone"
				},
				{
					"currencyAbbr": "EUR",
					"currencyName": "Euro"
				},
				{
					"currencyAbbr": "GBP",
					"currencyName": "British Pound"
				},
				{
					"currencyAbbr": "HKD",
					"currencyName": "Hong Kong Dollar"
				},
				{
					"currencyAbbr": "INR",
					"currencyName": "Indian Rupee"
				},
				{
					"currencyAbbr": "JPY",
					"currencyName": "Japanese Yen"
				},
				{
					"currencyAbbr": "NOK",
					"currencyName": "Norwegian Krone"
				},
				{
					"currencyAbbr": "PLN",
					"currencyName": "Polish Zloty"
				},
				{
					"currencyAbbr": "SEK",
					"currencyName": "Swedish Krona"
				},
				{
					"currencyAbbr": "SGD",
					"currencyName": "Singapore Dollar"
				},
				{
					"currencyAbbr": "USD",
					"currencyName": "US Dollar"
				},
				{
					"currencyAbbr": "ZAR",
					"currencyName": "South African Rand"
				}
			],
			"_id": "5ede9644a58ea603352a1e23",
			"name": "preferredCurrency",
			"appName": "MovePro360",
			"isGlobal": true,
			"fieldType": "Dropdown",
			"default": [],
			"description": "MovePro Dashboard Employee Record currency"
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [],
			"_id": "5ede9644a58ea603352a1e24",
			"name": "employeeFavorites",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "selectbox",
			"default": [],
			"description": "MovePro Employee Favorites"
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				25,
				50,
				75
			],
			"_id": "5ede9644a58ea603352a1e25",
			"name": "AggregateViewPageSize",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": [
				25
			],
			"description": "Page size for the Aggregated view"
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				25,
				50,
				75
			],
			"_id": "5ede9644a58ea603352a1e26",
			"name": "EmployeeListViewPageSize",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": [
				25
			],
			"description": "Page size for the Employee List view"
		}
	},
	{
		"preferencesetting": {
			"_id": "60e5a388baf8d20008a394a0",
			"appName": "MovePro360",
			"name": "FinancialViewPageSize",
			"value": [
				25
			],
			"partyId": "5ef06b187a04b900086582c8",
			"createdBy": "5ef06b187a04b900086582c8",
			"updatedBy": "5ef06b187a04b900086582c8",
			"__v": 1
		},
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				25,
				50,
				75
			],
			"_id": "5ede9644a58ea603352a1e27",
			"name": "FinancialViewPageSize",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": [
				25
			],
			"description": "Page size for the All Transactions view"
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "60630328b429a600086b0dc5",
			"name": "MoveProDashboardEmployeeListColumnViewOther",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdBy": "5dcf232b4f716121fff618e4",
			"updatedBy": "5dcf232b4f716121fff618e4",
			"createdAt": "2021-03-30T10:53:28.624Z",
			"updatedAt": "2021-03-30T10:53:28.624Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6063032ab429a600086b0dc7",
			"name": "MoveProEmployeeListCurrencyCodes",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": "EUR",
			"description": "MovePro Dashboard Employee Record currency",
			"createdBy": "5dcf232b4f716121fff618e4",
			"updatedBy": "5dcf232b4f716121fff618e4",
			"createdAt": "2021-03-30T10:53:30.218Z",
			"updatedAt": "2021-03-30T10:53:30.218Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6063032ab429a600086b0dc8",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": "EUR",
			"description": "MovePro Dashboard Employee Record currency",
			"createdBy": "5dcf232b4f716121fff618e4",
			"updatedBy": "5dcf232b4f716121fff618e4",
			"createdAt": "2021-03-30T10:53:30.858Z",
			"updatedAt": "2021-03-30T10:53:30.858Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6063032eb429a600086b0dc9",
			"name": "MoveProAdminListCurrencyCodes",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdBy": "5dcf232b4f716121fff618e4",
			"updatedBy": "5dcf232b4f716121fff618e4",
			"createdAt": "2021-03-30T10:53:34.642Z",
			"updatedAt": "2021-03-30T10:53:34.642Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [],
			"_id": "6063032fb429a600086b0dca",
			"name": "MoveProClientListCurrencyCodes",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"text": "This is another question I want to ask you",
			"createdBy": "5dcf232b4f716121fff618e4",
			"updatedBy": "5dcf232b4f716121fff618e4",
			"createdAt": "2021-03-30T10:53:35.345Z",
			"updatedAt": "2021-03-30T10:53:35.345Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "606486f872f98600088c99ae",
			"name": "MoveProDashboardEmployeeListColumnViewOtherMQWIVNZ1115",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T14:28:08.009Z",
			"updatedAt": "2021-03-31T14:28:08.009Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064879572f98600088c99af",
			"name": "MoveProDashboardEmployeeListColumnViewOtherQFZUKGI1343",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T14:30:45.843Z",
			"updatedAt": "2021-03-31T14:30:45.843Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064886972f98600088c99b0",
			"name": "MoveProDashboardEmployeeListColumnViewOtherGILEVNP1",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T14:34:17.537Z",
			"updatedAt": "2021-03-31T14:34:17.537Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "60648b614b43860009b469ce",
			"name": "MoveProDashboardEmployeeListColumnViewOtherOOGAZMV1086",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T14:46:57.275Z",
			"updatedAt": "2021-03-31T14:46:57.275Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "60648ba64b43860009b469cf",
			"name": "MoveProDashboardEmployeeListColumnViewOtherALPUKGP1299",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T14:48:06.014Z",
			"updatedAt": "2021-03-31T14:48:06.014Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "60648bc24b43860009b469d0",
			"name": "MoveProDashboardEmployeeListColumnViewOtherTDAEYUC1131",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T14:48:34.713Z",
			"updatedAt": "2021-03-31T14:48:34.713Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "606490d63f974f0008b5bc7b",
			"name": "MoveProEmployeeListCurrIAUOBJR1820",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": "EUR",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-03-31T15:10:14.572Z",
			"updatedAt": "2021-03-31T15:10:14.572Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "606491193f974f0008b5bc7c",
			"name": "MoveProEmployeeListCurrTTJOGQE1857",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": "EUR",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-03-31T15:11:21.454Z",
			"updatedAt": "2021-03-31T15:11:21.454Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "606491793f974f0008b5bc7d",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherPJWOBQH1",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T15:12:57.354Z",
			"updatedAt": "2021-03-31T15:12:57.354Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064b758ee37800008b69c3a",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherUPLATVE1604",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T17:54:32.867Z",
			"updatedAt": "2021-03-31T17:54:32.867Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064b777ee37800008b69c3b",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherCBUOYEE1",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T17:55:03.504Z",
			"updatedAt": "2021-03-31T17:55:03.504Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064c97f36cfcd0008118806",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherQWEECLO1",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T19:11:59.226Z",
			"updatedAt": "2021-03-31T19:11:59.226Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6064c9cd36cfcd0008118807",
			"name": "test-preference-MoveProAdminListCurrencyCodesHHJOPRS1",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-03-31T19:13:17.098Z",
			"updatedAt": "2021-03-31T19:13:17.098Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6064c9f336cfcd0008118808",
			"name": "test-preference-MoveProAdminListCurrencyCodesOOSINLU1081",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-03-31T19:13:55.541Z",
			"updatedAt": "2021-03-31T19:13:55.541Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6064ca1336cfcd0008118809",
			"name": "test-preference-MoveProAdminListCurrencyCodesIJCOINZ1",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-03-31T19:14:27.401Z",
			"updatedAt": "2021-03-31T19:14:27.401Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [],
			"_id": "6064caec36cfcd000811880a",
			"name": "test-preference-MoveProAdminListCurrencyCodesTMZOMSI1759",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"text": "This is another question I want to ask you",
			"createdAt": "2021-03-31T19:18:04.066Z",
			"updatedAt": "2021-03-31T19:18:04.066Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064d83cd350ff000810d4d7",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherZRQURWM1",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T20:14:52.521Z",
			"updatedAt": "2021-03-31T20:14:52.521Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064d8f0d350ff000810d4d8",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherAVJUPLU1OIX",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T20:17:52.418Z",
			"updatedAt": "2021-03-31T20:17:52.418Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6064db0e63091c000808328b",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-03-31T20:26:54.172Z",
			"updatedAt": "2021-03-31T21:02:21.079Z",
			"__v": 3
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064ddb047ac820008f416f7",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherVNVASDX1401",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T20:38:08.492Z",
			"updatedAt": "2021-03-31T20:38:08.492Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064dea247ac820008f416f8",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherSHNELAE1",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T20:42:10.893Z",
			"updatedAt": "2021-03-31T20:42:10.893Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064ded947ac820008f416f9",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherYBNAHRR1HAR",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T20:43:05.132Z",
			"updatedAt": "2021-03-31T20:43:05.132Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6064e4701e794d00087370af",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-03-31T21:06:56.781Z",
			"updatedAt": "2021-03-31T21:09:03.429Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6064e4721e794d00087370b1",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-03-31T21:06:58.500Z",
			"updatedAt": "2021-03-31T21:09:10.696Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "60651285689e07000832055b",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-04-01T00:23:33.006Z",
			"updatedAt": "2021-04-01T00:23:55.416Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "60651287689e07000832055d",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-04-01T00:23:35.062Z",
			"updatedAt": "2021-04-01T00:23:53.931Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6065128c689e07000832055e",
			"name": "test-preference-MoveProAdminListCurrencyCodesAWYACSR1",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-04-01T00:23:40.970Z",
			"updatedAt": "2021-04-01T00:23:40.970Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6065157e0536f30008a9a50e",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-04-01T00:36:14.137Z",
			"updatedAt": "2021-04-01T00:36:34.864Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6065157f0536f30008a9a510",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-04-01T00:36:15.748Z",
			"updatedAt": "2021-04-01T00:36:34.013Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "606515850536f30008a9a511",
			"name": "test-preference-MoveProAdminListCurrencyCodesTXFUNIR1",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-04-01T00:36:21.649Z",
			"updatedAt": "2021-04-01T00:36:21.649Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6065161d0536f30008a9a513",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-04-01T00:38:53.781Z",
			"updatedAt": "2021-04-01T00:39:12.260Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6065161f0536f30008a9a515",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-04-01T00:38:55.238Z",
			"updatedAt": "2021-04-01T00:39:11.495Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "606516250536f30008a9a516",
			"name": "test-preference-MoveProAdminListCurrencyCodesLLGAPFN1287",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-04-01T00:39:01.332Z",
			"updatedAt": "2021-04-01T00:39:01.332Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "6065e5f8112db90008900e73",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-04-01T15:25:44.877Z",
			"updatedAt": "2021-04-01T15:26:04.607Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6065e5fa112db90008900e75",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-04-01T15:25:46.341Z",
			"updatedAt": "2021-04-01T15:26:04.070Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "6065e5ff112db90008900e76",
			"name": "test-preference-MoveProAdminListCurrencyCodesSNDATJZ1",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-04-01T15:25:51.425Z",
			"updatedAt": "2021-04-01T15:25:51.425Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "606b151a2a01fd0008072a44",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-04-05T13:48:10.123Z",
			"updatedAt": "2021-04-05T13:48:29.616Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "606b151b2a01fd0008072a46",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-04-05T13:48:11.493Z",
			"updatedAt": "2021-04-05T13:48:28.824Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "606b151f2a01fd0008072a47",
			"name": "test-preference-MoveProAdminListCurrencyCodesCNGACCG1898",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-04-05T13:48:15.782Z",
			"updatedAt": "2021-04-05T13:48:15.782Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "606b154f2a01fd0008072a49",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherCIAUAZD1YIQ",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-04-05T13:49:03.403Z",
			"updatedAt": "2021-04-05T13:49:03.403Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "60fac3687cedad00099f9e68",
			"name": "test-preference-MoveProDashboardEmployeeListColumnViewOtherXIZAMGY1",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Textbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-07-23T13:26:00.047Z",
			"updatedAt": "2021-07-23T13:26:00.047Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "60fac3747cedad00099f9e6b",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-07-23T13:26:12.770Z",
			"updatedAt": "2021-07-23T13:26:34.628Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "60fac3767cedad00099f9e71",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-07-23T13:26:14.153Z",
			"updatedAt": "2021-07-23T13:26:34.029Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "60fac37b7cedad00099f9e75",
			"name": "test-preference-MoveProAdminListCurrencyCodesJJGEMLP1",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-07-23T13:26:19.390Z",
			"updatedAt": "2021-07-23T13:26:19.390Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "60fac4487cedad00099f9e7d",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-07-23T13:29:44.509Z",
			"updatedAt": "2021-07-23T13:29:59.201Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "60fac4497cedad00099f9e83",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-07-23T13:29:45.966Z",
			"updatedAt": "2021-07-23T13:29:58.688Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "60fac44f7cedad00099f9e87",
			"name": "test-preference-MoveProAdminListCurrencyCodesWROEPMV1",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-07-23T13:29:51.002Z",
			"updatedAt": "2021-07-23T13:29:51.002Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "61001470067a950008e9e3d4",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-07-27T14:13:04.404Z",
			"updatedAt": "2021-07-27T14:13:23.995Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "61001471067a950008e9e3da",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-07-27T14:13:05.790Z",
			"updatedAt": "2021-07-27T14:13:23.440Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "61001475067a950008e9e3de",
			"name": "test-preference-MoveProAdminListCurrencyCodesVAUIDZB1",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-07-27T14:13:09.672Z",
			"updatedAt": "2021-07-27T14:13:09.672Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "610014d6067a950008e9e3e6",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-07-27T14:14:46.097Z",
			"updatedAt": "2021-07-27T14:15:00.609Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "610014d7067a950008e9e3ec",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-07-27T14:14:47.428Z",
			"updatedAt": "2021-07-27T14:14:59.930Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "610014db067a950008e9e3f0",
			"name": "test-preference-MoveProAdminListCurrencyCodesRWHEAFZ1",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-07-27T14:14:51.622Z",
			"updatedAt": "2021-07-27T14:14:51.622Z",
			"__v": 0
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"Starred",
				"File Status Updated",
				"Move Phase",
				"Policy",
				"Departure",
				"Destination",
				"Providers",
				"Recent Sentiment",
				"Effective Transfer Date",
				"Recent Milestones",
				"Total Cost",
				"Move Risk Status"
			],
			"_id": "610015c0067a950008e9e3f8",
			"name": "MoveProDashboardEmployeeListColumnView4",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Checkbox",
			"default": [
				"Departure",
				"Destination",
				"Effective Transfer Date",
				"Full Name",
				"File Status Updated",
				"Move Phase",
				"Move Risk Status",
				"Policy",
				"Providers",
				"Recent Sentiment",
				"Recent Milestones",
				"Starred",
				"Total Cost"
			],
			"description": "MovePro360 Dashboard Employee List Default View",
			"createdAt": "2021-07-27T14:18:40.735Z",
			"updatedAt": "2021-07-27T14:18:56.565Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "preference",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "610015c2067a950008e9e3fe",
			"name": "MoveProEmployeeListCurrency",
			"appName": "MovePro360",
			"isGlobal": false,
			"fieldType": "Dropdown",
			"default": "PLN",
			"description": "MovePro Dashboard Employee Record currency",
			"createdAt": "2021-07-27T14:18:42.265Z",
			"updatedAt": "2021-07-27T14:18:55.869Z",
			"__v": 1
		}
	},
	{
		"preferenceconfig": {
			"kind": "sentiment",
			"options": [
				"AUD",
				"BRL",
				"CAD",
				"CHF",
				"CNY",
				"CZK",
				"DKK",
				"EUR",
				"GBP",
				"HKD",
				"INR",
				"JPY",
				"NOK",
				"PLN",
				"SEK",
				"SGD",
				"USD",
				"ZAR"
			],
			"_id": "610015c7067a950008e9e402",
			"name": "test-preference-MoveProAdminListCurrencyCodesHJCASEM1ZEK",
			"appName": "MovePro360",
			"fieldType": "Checkbox",
			"description": "MovePro Dashboard Admin Record currency",
			"text": "This is the question I want to ask you",
			"createdAt": "2021-07-27T14:18:47.205Z",
			"updatedAt": "2021-07-27T14:18:47.205Z",
			"__v": 0
		}
	}
]