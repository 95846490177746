import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-admin-filter-chip',
  templateUrl: './admin-filter-chip.component.html',
  styleUrls: ['./admin-filter-chip.component.scss']
})
export class AdminFilterChipComponent implements OnInit, OnChanges {

  /** data variable will hold the filters data to display as chips */
  data = [];

  /** To get the selected filters */
  @Input() filterData;

  /** Output the filters data after selected filter is removed */
  @Output() filterOptions = new EventEmitter<any[]>();

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    this.data = this.filterData;
  }

  ngOnInit() {
    //this.data = this.filterData;
    /*this.userPreferenceService.getPreference('chips_data', false).subscribe((response) => {
      if (response) {
        this.data = response;
      }
    });*/
  }

  removeFilter(filter) {
    const index = this.filterData.indexOf(filter);
    if (index >= 0) {
      this.filterData.splice(index, 1);
    }
    this.filterOptions.emit(filter);
  }
}
