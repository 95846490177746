export const currencyData = {
    "values": [
        {
            "value": "AFA",
            "displayValue": "Afghanisthan Afghani"
        },
        {
            "value": "ALL",
            "displayValue": "Albanian Lek"
        },
        {
            "value": "DZD",
            "displayValue": "Algerian Dinar"
        },
        {
            "value": "ADP",
            "displayValue": "Andorran Peseta"
        },
        {
            "value": "AOA",
            "displayValue": "Angolan Kwanza"
        },
        {
            "value": "ARS",
            "displayValue": "Argentine Peso"
        },
        {
            "value": "AMD",
            "displayValue": "Armenia Dram"
        },
        {
            "value": "AWG",
            "displayValue": "Aruban Florin"
        },
        {
            "value": "AUD",
            "displayValue": "Australian Dollar"
        },
        {
            "value": "ATS",
            "displayValue": "Austrian Schilling"
        },
        {
            "value": "AZN",
            "displayValue": "Azerbaijani Manat"
        },
        {
            "value": "BSD",
            "displayValue": "Bahamas Dollar "
        },
        {
            "value": "BHD",
            "displayValue": "Bahraini Dinar"
        },
        {
            "value": "BDT",
            "displayValue": "Bangladeshi Taka"
        },
        {
            "value": "BBD",
            "displayValue": "Barbados Dollar"
        },
        {
            "value": "BEF",
            "displayValue": "Belgian Franc"
        },
        {
            "value": "BZD",
            "displayValue": "Belize Dollar"
        },
        {
            "value": "BLD",
            "displayValue": "Belizean Dollar"
        },
        {
            "value": "BMD",
            "displayValue": "Bermudian Dollar"
        },
        {
            "value": "BTN",
            "displayValue": "Bhutan Ngultrum"
        },
        {
            "value": "BOB",
            "displayValue": "Bolivia Boliviano"
        },
        {
            "value": "BWP",
            "displayValue": "Botswana Pula"
        },
        {
            "value": "BRL",
            "displayValue": "Brazilian Real"
        },
        {
            "value": "GBP",
            "displayValue": "British Pound"
        },
        {
            "value": "BND",
            "displayValue": "Brunei Dollar"
        },
        {
            "value": "BGN",
            "displayValue": "Bulgarian Lev"
        },
        {
            "value": "BIF",
            "displayValue": "Burundi Franc"
        },
        {
            "value": "KHR",
            "displayValue": "Cambodian Riel"
        },
        {
            "value": "CAD",
            "displayValue": "Canadian Dollar"
        },
        {
            "value": "CVE",
            "displayValue": "Cape Verde Escudo"
        },
        {
            "value": "KYD",
            "displayValue": "Cayman Islands Dollar"
        },
        {
            "value": "XOF",
            "displayValue": "CFA Franc BCEAO"
        },
        {
            "value": "XAF",
            "displayValue": "CFA Franc BEAC "
        },
        {
            "value": "CLP",
            "displayValue": "Chilean Peso"
        },
        {
            "value": "CLF",
            "displayValue": "Chilean Unidades de fomento"
        },
        {
            "value": "CNY",
            "displayValue": "Chinese Yuan Renminbi"
        },
        {
            "value": "COP",
            "displayValue": "Colombian Peso"
        },
        {
            "value": "KMF",
            "displayValue": "Comoros Franc"
        },
        {
            "value": "CRC",
            "displayValue": "Costa Rican Colon"
        },
        {
            "value": "HRD",
            "displayValue": "Croatian Dinar"
        },
        {
            "value": "HRK",
            "displayValue": "Croatian Kuna"
        },
        {
            "value": "CUP",
            "displayValue": "Cuban Peso"
        },
        {
            "value": "CYP",
            "displayValue": "Cyprus Pound"
        },
        {
            "value": "CZK",
            "displayValue": "Czech Koruna"
        },
        {
            "value": "DKK",
            "displayValue": "Danish Krone"
        },
        {
            "value": "DMK",
            "displayValue": "Denmark Krone"
        },
        {
            "value": "DJF",
            "displayValue": "Djibouti Franc"
        },
        {
            "value": "DOP",
            "displayValue": "Dominican Peso"
        },
        {
            "value": "NLG",
            "displayValue": "Dutch Guilder"
        },
        {
            "value": "XCD",
            "displayValue": "East Caribbean Dollar "
        },
        {
            "value": "ECS",
            "displayValue": "Ecuador Sucre"
        },
        {
            "value": "EGP",
            "displayValue": "Egyptian Pound"
        },
        {
            "value": "SVC",
            "displayValue": "El Salvador Colon"
        },
        {
            "value": "ERN",
            "displayValue": "ERN"
        },
        {
            "value": "EEK",
            "displayValue": "Estonian Kroon"
        },
        {
            "value": "ETB",
            "displayValue": "Ethiopia Birr"
        },
        {
            "value": "EUR",
            "displayValue": "Euro"
        },
        {
            "value": "FKP",
            "displayValue": "Falkland Islands Pound"
        },
        {
            "value": "FJD",
            "displayValue": "Fiji Dollar"
        },
        {
            "value": "FIM",
            "displayValue": "Finnish Markka "
        },
        {
            "value": "FRF",
            "displayValue": "French Franc"
        },
        {
            "value": "GMD",
            "displayValue": "Gambian Dalasi"
        },
        {
            "value": "GEL",
            "displayValue": "Georgian Lari"
        },
        {
            "value": "DEM",
            "displayValue": "German Deutschmark"
        },
        {
            "value": "GHS",
            "displayValue": "Ghana Cedi"
        },
        {
            "value": "GIP",
            "displayValue": "Gibraltar Pound"
        },
        {
            "value": "GRD",
            "displayValue": "Greek Drachma"
        },
        {
            "value": "GTQ",
            "displayValue": "Guatemalan Quetzal"
        },
        {
            "value": "GNF",
            "displayValue": "Guinea Franc"
        },
        {
            "value": "GYD",
            "displayValue": "Guyanan Dollar"
        },
        {
            "value": "HTG",
            "displayValue": "Haitian Gourde"
        },
        {
            "value": "HNL",
            "displayValue": "Honduran Lempira"
        },
        {
            "value": "HKD",
            "displayValue": "Hong Kong Dollar"
        },
        {
            "value": "HUF",
            "displayValue": "Hungarian Forint"
        },
        {
            "value": "ISK",
            "displayValue": "Iceland Krona"
        },
        {
            "value": "INR",
            "displayValue": "Indian Rupee"
        },
        {
            "value": "IDR",
            "displayValue": "Indonesian Rupiah"
        },
        {
            "value": "IRR",
            "displayValue": "Iranian Rial"
        },
        {
            "value": "IQD",
            "displayValue": "Iraqi Dinar"
        },
        {
            "value": "IEP",
            "displayValue": "Irish Punt"
        },
        {
            "value": "ILS",
            "displayValue": "Israeli New Shekel"
        },
        {
            "value": "ITL",
            "displayValue": "Italian Lira"
        },
        {
            "value": "JMD",
            "displayValue": "Jamaican Dollar"
        },
        {
            "value": "JPY",
            "displayValue": "Japanese Yen"
        },
        {
            "value": "JOD",
            "displayValue": "Jordanian Dinar"
        },
        {
            "value": "UAK",
            "displayValue": "Karbovanet"
        },
        {
            "value": "KZT",
            "displayValue": "Kazakhstan Tenge"
        },
        {
            "value": "KES",
            "displayValue": "Kenyan Schilling"
        },
        {
            "value": "KRW",
            "displayValue": "Korean Won"
        },
        {
            "value": "KWD",
            "displayValue": "Kuwaiti Dinar"
        },
        {
            "value": "KGS",
            "displayValue": "Kyrgyzstanian Som"
        },
        {
            "value": "LAK",
            "displayValue": "Lao Kip"
        },
        {
            "value": "LVL",
            "displayValue": "Latvian Lats"
        },
        {
            "value": "LBP",
            "displayValue": "Lebanese Pound"
        },
        {
            "value": "LSL",
            "displayValue": "Lesotho Loti"
        },
        {
            "value": "LRD",
            "displayValue": "Liberian Dollar"
        },
        {
            "value": "LYD",
            "displayValue": "Libyan Dinar"
        },
        {
            "value": "LTL",
            "displayValue": "Lithuanian Litas"
        },
        {
            "value": "LUF",
            "displayValue": "Luxembourg Franc"
        },
        {
            "value": "MOP",
            "displayValue": "Macau Pataca"
        },
        {
            "value": "MKD",
            "displayValue": "Macedonia Denar"
        },
        {
            "value": "MGF",
            "displayValue": "Malagasy Franc"
        },
        {
            "value": "MWK",
            "displayValue": "Malawi Kwacha"
        },
        {
            "value": "MYR",
            "displayValue": "Malaysian Ringgit"
        },
        {
            "value": "MVR",
            "displayValue": "Maldive Rufiyaa"
        },
        {
            "value": "MTL",
            "displayValue": "Maltese Lira"
        },
        {
            "value": "MRO",
            "displayValue": "Mauritanian Ouguiya"
        },
        {
            "value": "MUR",
            "displayValue": "Mauritius Rupee"
        },
        {
            "value": "MXN",
            "displayValue": "Mexican Peso"
        },
        {
            "value": "MNT",
            "displayValue": "Mongolian Tugrik"
        },
        {
            "value": "MAD",
            "displayValue": "Moroccan Dirham"
        },
        {
            "value": "MZN",
            "displayValue": "Mozambique New Metical"
        },
        {
            "value": "MMK",
            "displayValue": "Myanmar Kyat"
        },
        {
            "value": "NAD",
            "displayValue": "Namibian Dollar"
        },
        {
            "value": "NPR",
            "displayValue": "Nepalese Rupee"
        },
        {
            "value": "ANG",
            "displayValue": "Netherlands Antillian Guilder"
        },
        {
            "value": "BYN",
            "displayValue": "New Belarusian Ruble"
        },
        {
            "value": "RON",
            "displayValue": "New Romanian Lei"
        },
        {
            "value": "RSD",
            "displayValue": "New Serbian Dinar"
        },
        {
            "value": "NZD",
            "displayValue": "New Zealand Dollar"
        },
        {
            "value": "NIO",
            "displayValue": "Nicaraguan Cordoba Oro"
        },
        {
            "value": "NGN",
            "displayValue": "Nigerian Naira"
        },
        {
            "value": "KPW",
            "displayValue": "North Korean Won"
        },
        {
            "value": "NOK",
            "displayValue": "Norwegian Krone"
        },
        {
            "value": "OMR",
            "displayValue": "Omani Rial"
        },
        {
            "value": "PKR",
            "displayValue": "Pakistan Rupee"
        },
        {
            "value": "PAB",
            "displayValue": "Panama Balboa"
        },
        {
            "value": "PGK",
            "displayValue": "Papua New Guinea Kina"
        },
        {
            "value": "PYG",
            "displayValue": "Paraguay Guarani"
        },
        {
            "value": "PEN",
            "displayValue": "Peruvian Nuevo Sol"
        },
        {
            "value": "UYU",
            "displayValue": "Peso Uruguayo"
        },
        {
            "value": "PHP",
            "displayValue": "Philippine Peso"
        },
        {
            "value": "PLN",
            "displayValue": "Polish Zloty"
        },
        {
            "value": "XPF",
            "displayValue": "Polynesian Franc"
        },
        {
            "value": "PTE",
            "displayValue": "Portuguese Escudo"
        },
        {
            "value": "QAR",
            "displayValue": "Qatari Rial"
        },
        {
            "value": "MDL",
            "displayValue": "Romanian Leu"
        },
        {
            "value": "RUB",
            "displayValue": "Russian Ruble"
        },
        {
            "value": "RWF",
            "displayValue": "Rwanda Franc"
        },
        {
            "value": "WST",
            "displayValue": "Samoan Tala"
        },
        {
            "value": "STD",
            "displayValue": "Sao Tome and Principe Dobra"
        },
        {
            "value": "SAR",
            "displayValue": "Saudi Riyal"
        },
        {
            "value": "SCR",
            "displayValue": "Seychelles Rupee"
        },
        {
            "value": "SLL",
            "displayValue": "Sierra Leone Leone"
        },
        {
            "value": "SGD",
            "displayValue": "Singapore Dollar"
        },
        {
            "value": "SKK",
            "displayValue": "Slovak Koruna"
        },
        {
            "value": "SIT",
            "displayValue": "Slovenian Tolar"
        },
        {
            "value": "SBD",
            "displayValue": "Solomon Islands Dollar"
        },
        {
            "value": "SOS",
            "displayValue": "Somali Schilling"
        },
        {
            "value": "ZAR",
            "displayValue": "South African Rand"
        },
        {
            "value": "ESP",
            "displayValue": "Spanish Peseta"
        },
        {
            "value": "SRG",
            "displayValue": "SRG"
        },
        {
            "value": "LKR",
            "displayValue": "Sri Lanka Rupee"
        },
        {
            "value": "SHP",
            "displayValue": "St. Helena Pound"
        },
        {
            "value": "SDD",
            "displayValue": "Sudanese Dinar"
        },
        {
            "value": "SDP",
            "displayValue": "Sudanese Pound"
        },
        {
            "value": "SZL",
            "displayValue": "Swaziland Lilangeni"
        },
        {
            "value": "SEK",
            "displayValue": "Swedish Krona"
        },
        {
            "value": "CHF",
            "displayValue": "Swiss Franc"
        },
        {
            "value": "SYP",
            "displayValue": "Syrian Pound"
        },
        {
            "value": "TWD",
            "displayValue": "Taiwanese Dollar"
        },
        {
            "value": "TJS",
            "displayValue": "Tajikistani Somoni"
        },
        {
            "value": "TZS",
            "displayValue": "Tanzanian Schilling"
        },
        {
            "value": "THB",
            "displayValue": "Thai Baht"
        },
        {
            "value": "TOP",
            "displayValue": "Tongan Pa'anga"
        },
        {
            "value": "TTD",
            "displayValue": "Trinidad and Tobago Dollar"
        },
        {
            "value": "TND",
            "displayValue": "Tunisian Dinar"
        },
        {
            "value": "TRY",
            "displayValue": "Turkish Lira"
        },
        {
            "value": "TMT",
            "displayValue": "Turkmenistan Manat"
        },
        {
            "value": "UGX",
            "displayValue": "Uganda Shilling"
        },
        {
            "value": "UAH",
            "displayValue": "Ukraine Hryvnia"
        },
        {
            "value": "AED",
            "displayValue": "United Arab Emirates Dirham"
        },
        {
            "value": "UYP",
            "displayValue": "Uruguayan Peso"
        },
        {
            "value": "USD",
            "displayValue": "US Dollar"
        },
        {
            "value": "UZS",
            "displayValue": "Uzbekistan Sum"
        },
        {
            "value": "VUV",
            "displayValue": "Vanuatu Vatu"
        },
        {
            "value": "VES",
            "displayValue": "Venezuelan Bolívar Soberano"
        },
        {
            "value": "VND",
            "displayValue": "Vietnamese Dong"
        },
        {
            "value": "YER",
            "displayValue": "Yemeni Rial"
        },
        {
            "value": "YUM",
            "displayValue": "Yugoslav Dinar "
        },
        {
            "value": "ZMK",
            "displayValue": "Zambian Kwacha"
        },
        {
            "value": "ZWD",
            "displayValue": "Zimbabwe Dollar"
        }
    ]
}