// export const programBenefitData = {
//   "benefits": [
//     {
//       "reference": "OFF-AccntModBBIntl-2c45f0d5-e629-4d02-a9d3-2dcb67369f2b",
//       "productSpecificationReference": "PRD-AccntMod",
//       "displayName": "Accent Modification: 12 Hours",
//       "description": "Intensive program designed to improve clarity and intelligibility of speech for advanced level English as a second language speakers. Programs focus on preparing for immediate business tasks or high stakes situations. A specialized accent modification coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.\n            * Technical speech gap analysis and customized learning plan developed by the coach.\n            * Addresses pronunciation of sounds, intonation, and rhythm within the new cultural context.\n            * Flexible scheduling and online delivery.",
//       "category": "Ongoing",
//       "points": 7,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-AirfareReimbBndlBBIntl-d1ee99ee-614d-45c0-ad86-358eda1ac8fa",
//       "productSpecificationReference": "PRD-AirfareReimbBndl",
//       "displayName": "Airfare Expense Support",
//       "description": "Reimbursement of airfare expenses for parents, au pair, and family members to visit you in the host location who are not considered dependents.",
//       "category": "Travel and Transport",
//       "points": 1,
//       "multiplicity": true,
//       "maxSelection": 3,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-AirfareUpgradeBndlBBIntl-f25e6cc1-4378-43bd-bcb1-4e31b67488ae",
//       "productSpecificationReference": "PRD-AirfareUpgradeBndl",
//       "displayName": "Airfare Upgrade",
//       "description": "This reimbursement is intended to cover the expense of upgrading your class of airfare for the pre-assignment trip or home leave.",
//       "category": "Travel and Transport",
//       "points": 1,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-AirfareUpgrdBusnClsBBIntl-cb3d6750-19e9-41be-b911-7ec9b1f3dc6b",
//       "productSpecificationReference": "PRD-AirfareUpgrade",
//       "displayName": "Home Leave Business Class Upgrade",
//       "description": "Your new Business Unit has approved you for an upgrade to Business Class for your Home Leave trip.",
//       "category": "After You Arrive",
//       "points": 0,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HouseHuntUpgrdBB-446644a8-1164-45b6-addc-89c60e1f8f5d",
//       "productSpecificationReference": "PRD-AirfareUpgrade",
//       "displayName": "House Hunting Business Class Upgrade",
//       "description": "Your new Business Unit has approved you for an upgrade to Business Class for your house hunting trip.",
//       "category": "Before You Move",
//       "points": 0,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-ApplAllowBB-9e5056f1-cd16-45ae-8e50-aa06085a3119",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Appliance Allowance",
//       "description": "You will receive an appliance allowance.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-CompletionBonusBB-387b8b00-c091-40eb-9524-2e727b042807",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Completion Bonus",
//       "description": "Following the completion of your assignment, you will be eligible for a bonus. This will be noted in your overall assignment package and payable at the completion of your assignment.",
//       "category": "After You Arrive",
//       "points": 0,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-DangerPayBB-2e53b866-a454-4747-ad0b-2caa8ddffccc",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Danger Pay",
//       "description": "Danger pay may be paid if you are moving into an area identified as hazardous. Your Cartus consultant will confirm if your host location falls into one of these categories.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-FamSocAsstBBIntl-dc56b436-c05a-4f36-aa13-49dd38142438",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Family and Social Assistance",
//       "description": "Your client sponsor provides a set budget to assist you and your dependents in becoming acclimated to the host location. The allowance depends on the family size in the host location. Your Cartus Consultant will review this allowance with you.",
//       "category": "Ongoing",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-FamilyAllowBB-4cb4d6cd-7799-49bf-b3e7-3187e23f918c",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Family Allowance",
//       "description": "This allowance is designed to offset the costs of moving parents and additional home leave associated with them living with you.",
//       "category": "After You Arrive",
//       "points": 0,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HomeLeaveAllowBBIntl-a2ef7a83-8eea-4648-8046-e029f0487a92",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Home Leave Allowance",
//       "description": "The Company covers home leave transportation expenses for all family members on assignment. The allowance will be provided based on the approved point value.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HouseFittingBB-52611bc7-9a22-4be9-b089-6d30e36b8d2b",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "House Fitting Lump Sum",
//       "description": "Depending on your destination country, you may receive a one-time allowance to coordinate the installation of cabinetry, closets and light fixtures.",
//       "category": "After You Arrive",
//       "points": 1,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HstHsngAllwncSuppBBIntlCr-2e0979ae-692e-4bc7-96ca-275338aa7e61",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Host Housing Allowance Support",
//       "description": "Provided for the duration of your assignment based on family size as determined by the Company utilizing an international third-party data provider.",
//       "category": "Allowances",
//       "points": 0,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HstHsngAllwncSuppBBIntlFlx-93a6ea2d-bd61-44e7-9737-805b83856459",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Host Housing Allowance Support",
//       "description": "You are eligible to receive a housing allowance in your host country based on your family size needs. Your consultant will discuss the allowance based on the family size of accompanying members relocating with you.",
//       "category": "Allowances",
//       "points": 0,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-MiscAllwnc10IntlBB-08588922-e76f-46f7-bf4c-fbac4dc08156",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Miscellaneous Expense Allowance",
//       "description": "An allowance to help offset incidental assignment expenses not specifically reimbursed or covered by the policy, the Company provides a one-time payment equal to one month's base pay not to exceed USD 10,000 USD or local currency equivalent.",
//       "category": "Allowances",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-MiscAllwnc2IntlBB-c8231dbd-b97e-42e9-a525-e9699cf53abd",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Miscellaneous Expense Allowance",
//       "description": "You can select an allowance to cover move related expenses of USD 2,000.",
//       "category": "Allowances",
//       "points": 4,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-MiscAllwnc5IntlBB-f5bd42cb-75c6-43ea-a67c-079330337f85",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Miscellaneous Expense Allowance",
//       "description": "An allowance to help offset incidental assignment expenses not specifically reimbursed or covered by the policy, the Company provides a one-time payment equal to USD 5,000 or local currency equivalent.",
//       "category": "Allowances",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-MobPremBB-68daaf6d-f093-4ea2-8ce8-16cc2aaab8f7",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Mobility Premium",
//       "description": "Your new Business Unit has approved a mobility premium. Your Cartus consultant will provide additional details.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-PetTrnsprtAllwncBB-df164f18-993f-4d6e-b211-697746382403",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Pet Transportation Allowance",
//       "description": "A one-time allowance to cover the cost of transporting your pet(s) of USD 500.",
//       "category": "Allowances",
//       "points": 1,
//       "exclusions": [
//         "OFF-PetTrnsprtReimbBB-94d073f3-3c40-4715-8902-21add9e92917"
//       ],
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-PetTrnsprtBBIntlCore-97c3a394-ba5b-4a73-9bdb-313251fd5c5a",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Pet Transportation Reimbursement",
//       "description": "When you arrange the transportation of your pet, you will be reimbursed for the transportation costs of your pet(s) from your old location to your new location.",
//       "category": "Allowances",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-UtilAllowBB-aa65aa8a-0d5e-4cf4-9441-71355712e77a",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Utility Allowance",
//       "description": "You will receive an allowance to offset the cost utilities at your host location.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-WireFeeReimbBBIntl-75e2c439-1a84-493e-ab0f-9e34511426cb",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Wire Fee Reimbursement",
//       "description": "While on assignment, you may be approved for reimbursement of applicable monthly ATM and/or wire transfer fees up to a maximum amount of USD 25 per month not to exceed USD 300 per year or local currency equivalent.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-ApplncReimbBBIntl-797e224f-0125-4016-99cd-190ec90a7c03",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Appliance Reimbursement",
//       "description": "You are eligible to receive an appliance reimbursement up to USD 3,500 or local currency equivalent.  Your consultant will review what documentation is needed.",
//       "category": "Allowances",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-CashOutBBAdv-3465a1d5-40fe-41b1-ad85-08dc63e7cde1",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Cash Out",
//       "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
//       "category": "Ongoing",
//       "points": 0,
//       "advCashOut": true,
//       "tierConfigs": [
//         {
//           "maxRange": 5,
//           "maxSelection": 5,
//           "minRange": 1,
//           "pointValue": 2999
//         }
//       ],
//       "maxSelection": 5,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-CostOfLvngDiffIntlBB-1a267874-e7d2-4293-b528-5c70199af8e2",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Cost of Living Differential",
//       "description": "The Cost of Living Differential is based on your level of income at the departure location, as well as other criteria determined by the Company (for example, number of dependents).  The cost of living factors may include, but are not limited to, housing costs, taxes, goods and services, transportation, etc.  Your consultant will review the guidelines with you in more detail.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-DualUtilityBBIntl-6b0a12f4-1dcb-4ba7-a92d-1702eb582b91",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Dual Utilities Cost",
//       "description": "If you are an unaccompanied assignee and your family remained in the home country.  You will be reimbursed the cost of utilities up to a reasonable level of consumption.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-ExcsBagBB-95f0022c-a7f2-4218-94bb-338ec9fefb7b",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Excess Baggage Allowance",
//       "description": "A one-time allowance to cover the cost of excess baggage fees assessed by an airline of USD 500.",
//       "category": "Travel and Transport",
//       "points": 1,
//       "exclusions": [
//         "OFF-ExcsBagReimbBB-c676c281-1457-4b9a-b54e-a084575b20a9"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-ExcsBagReimbBB-c676c281-1457-4b9a-b54e-a084575b20a9",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Excess Baggage Reimbursement",
//       "description": "This benefit covers the reimbursement of excess baggage by an airline. Each point is USD 500.",
//       "category": "Travel and Transport",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 3,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "exclusions": [
//         "OFF-ExcsBagBB-95f0022c-a7f2-4218-94bb-338ec9fefb7b"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-FurnAllowIntl-c603f017-6e36-49d2-97fa-db033ab2035b",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Furniture Allowance",
//       "description": "In lieu of any type of moving services and storage, you may select an allowance not to exceed USD 5,000.",
//       "category": "After You Arrive",
//       "points": 10,
//       "exclusions": [
//         "OFF-HHGIntlCoreBB-1abac541-344c-4f52-acd7-e0e3957422cf",
//         "OFF-HHGIntlAirBB-b1c387d6-10f6-4478-accd-e078dadb5892",
//         "OFF-HHGIntlSeaBB-d93ff9f1-ac32-4b28-a1a7-91e16ee3b779",
//         "OFF-HHGIntlSea20BB-2c8b2bfc-de1e-4a7e-bc38-a405c3c5a967",
//         "OFF-HHGIntlSea40BB-3da136db-8406-4006-9b64-b03d048d7b61",
//         "OFF-HHGIntlFlxBB-7a8bb6c9-bf61-43bf-a58b-d53f59ae28ba"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-HlthCrReimbBB-d3a1208b-5b96-41be-b18e-f95a3d496a24",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Health Care",
//       "description": "Reimbursement of relocation health inoculations not covered under the health plan for you and your accompanying family members.",
//       "category": "Before You Move",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       },
//     },
//     {
//       "reference": "OFF-LocHrdshpPremBBIntl-2bd65e5b-3d50-4a6e-b599-96ff9a7b0b49",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Hardship/Location Premium",
//       "description": "A location premium may be paid depending upon the specific circumstances surrounding unusually difficult host country conditions such as harsh climate, political instability, physical risk, or the lack of social amenities. Your Cartus consultant will confirm if your host location falls into one of these categories.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       },
//       "suppress": true
//     },
//     {
//       "reference": "OFF-PetTrnsprtReimbBB-94d073f3-3c40-4715-8902-21add9e92917",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Pet Transportation Reimbursement",
//       "description": "When you arrange the transportation of your pet, you will be reimbursed for the transportation costs your pet (s) from your old location to your new location. Each point is USD 500. You can submit for reimbursement up to your selected point value.",
//       "category": "Allowances",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 4,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "exclusions": [
//         "OFF-PetTrnsprtBndlBB-05165c88-a2c6-4ee9-ab57-3ea7d01b6643"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-PrkngReimbBBIntl-91c4d227-f2d2-4afe-9bde-dff3580a24df",
//       "productSpecificationReference": "PRD-MiscAllwnc",
//       "displayName": "Parking Space Monthly Reimbursement",
//       "description": "If one (1) parking space is not included in your lease in the host location, you will be reimbursed up to USD 350 per month not to exceed USD 4,200 per year or local currency equivalent.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       },
//       "suppress": true
//     },
//     {
//       "reference": "OFF-AutoDispBBIntl-aa48777f-5f28-4bf8-ad53-c59806833fb3",
//       "productSpecificationReference": "PRD-AutoLoss",
//       "displayName": "Auto Lease Breakage",
//       "description": "If you elect to sell or cancel the lease on your automobile prior to your move, you will be reimbursed up to the selected point value.",
//       "category": "Before You Move",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 12,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-AutoDispBndlBB-BBCloned-ce8a15c3-3cd0-4334-99f7-7fb1a761eafd",
//       "productSpecificationReference": "PRD-AutoDispBndl",
//       "displayName": "Clone of Automobile Disposition - Total 2 vehicles",
//       "description": "If you elect to sell or cancel the lease on your automobile(s) prior to your move, you will receive an allowance.",
//       "category": "Before You Move",
//       "points": 6,
//       "multiplicity": true,
//       "maxSelection": 2,
//       "exclusions": [
//         "OFF-VhclShpmtBndlIntlBB-7aaea022-1243-4254-a0ef-b950cfd71ab4"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-AutoDispBndlBB-BBCloned-e81467c1-2874-49b3-b32c-38830a2d9c1a",
//       "productSpecificationReference": "PRD-AutoDispBndl",
//       "displayName": "Clone of Automobile Disposition - Total 2 vehicles",
//       "description": "If you elect to sell or cancel the lease on your automobile(s) prior to your move, you will receive an allowance.",
//       "category": "Before You Move",
//       "points": 6,
//       "multiplicity": true,
//       "maxSelection": 2,
//       "exclusions": [
//         "OFF-VhclShpmtBndlIntlBB-7aaea022-1243-4254-a0ef-b950cfd71ab4"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-AutoDispBndlBB-dcfede5e-0f8d-4b5c-8d65-6b078ca39284",
//       "productSpecificationReference": "PRD-AutoDispBndl",
//       "displayName": "Automobile Disposition - Total 2 vehicles",
//       "description": "If you elect to sell or cancel the lease on your automobile(s) prior to your move, you will receive an allowance.",
//       "category": "Before You Move",
//       "points": 6,
//       "multiplicity": true,
//       "maxSelection": 2,
//       "exclusions": [
//         "OFF-VhclShpmtBndlIntlBB-7aaea022-1243-4254-a0ef-b950cfd71ab4"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-AutoLeaseBBIntl-150d73ba-9122-415f-bd28-5bd840ee15db",
//       "productSpecificationReference": "PRD-AutoLease",
//       "displayName": "Host Country Car Provision",
//       "description": "You will be provided with a maintained car by the host company in accordance with the standard level in the Host Location. At the host company’s sole discretion, the size or type of car may be changed to suit family size or road conditions.",
//       "category": "Ongoing",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-BrkrFndrFeeIntlBB-4f59910b-73f8-41b6-82d6-f96f46cbb5df",
//       "productSpecificationReference": "PRD-BrkrFndrFee",
//       "displayName": "Broker's Fee/Finder's Fee",
//       "description": "Reimbursement of agency fees associated with securing a lease in your new location. One point is USD 500.",
//       "category": "After You Arrive",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 10,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-BsnCommEnglBBIntl20-1e818999-a65c-4b32-ba60-bb430d891c65",
//       "productSpecificationReference": "PRD-BusnComm",
//       "displayName": "Business English: 20 Hours",
//       "description": "Comprehensive language skill development for the workplace for all levels. Learners are matched with an experienced corporate language coach familiar with the local business culture. \n            * Flexible scheduling managed online through mobile device offers convenience for busy assignees.\n            * All sessions delivered virtually through our digital delivery platform.\n            * Course is customized, with primary focus on the learner's actual work communication.\n            * Commonly targeted goal areas include: developing relationships, small talk, networking, phone calls, meetings, emails, understanding accents, influencing and presentations.",
//       "category": "Ongoing",
//       "points": 3,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-BusnCommAdvBBIntl-1e4c3e43-fd0f-4d47-b7ce-095fea4ebefc",
//       "productSpecificationReference": "PRD-BusnComm",
//       "displayName": "Advanced Business Communication Skills Coaching: 20 hours",
//       "description": "Designed for highly proficient speakers who have mastered fluency and business communication skills for work but who would like to adapt one’s style for maximum efficiency and impact. The program can be customized to address any of the following: creating more impactful presentation style feedback and practice, influencing and persuasion techniques, improving speech/voice clarity, and flexing communication styles to local culture and systems, as well as addressing many other challenges.",
//       "category": "Ongoing",
//       "points": 3,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-BusnEnglWrtngBBIntl20-8d361844-d81f-44bb-868f-75f2b0d27ac8",
//       "productSpecificationReference": "PRD-BusnComm",
//       "displayName": "Business English Writing: 20 Hours",
//       "description": "Intensive program designed to address specific skill gaps for C1+ advanced level English speakers. A specialized Business English Writing Coach incorporates actual work content to promote the ability to confidently engage and influence local and global audiences.",
//       "category": "Ongoing",
//       "points": 5,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-GlbLdrshpCommBBIntl-27cfea09-bae0-4026-9c9f-b706e8a915b2",
//       "productSpecificationReference": "PRD-BusnComm",
//       "displayName": "Global Leadership Communication Coaching: 12 Hours",
//       "description": "Intensive program to address language skill and executive style gaps for C1+ advanced level English speakers using English as a second language. The program focuses on preparing for immediate business tasks or high stakes situations. A specialized corporate communications coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.",
//       "category": "Ongoing",
//       "points": 9,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-PrsntSkillsBusnEnglBBIntl12-30bbe019-ad65-4d3b-ab84-bccdb788f8d7",
//       "productSpecificationReference": "PRD-BusnComm",
//       "displayName": "Presentation Skills Coaching for Business English: 12 Hours",
//       "description": "Intensive program designed to address specific skill gaps for C1+ advanced level English speakers. Programs focus on preparing for immediate business tasks or high stakes situations. A specialized corporate communications coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.\n            * Gap analysis and customized learning plan developed by the coach\n            * Flexible scheduling and online delivery",
//       "category": "Ongoing",
//       "points": 6,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-CarRntlIntlBB-909e8638-0f71-4fc8-92d8-15b77c4b5d63",
//       "productSpecificationReference": "PRD-CarRntl",
//       "displayName": "Car Rental",
//       "description": "Reimbursement for an economy class car rental for 15 days.",
//       "category": "Shipping",
//       "points": 1,
//       "rangeMin": 15,
//       "rangeMax": 60,
//       "rangeIncrementValue": 15,
//       "rangeIncrementUnit": "Days",
//       "incremental": true,
//       "isNewlyAdded": true,
//       "isNewlyAddedRef" :true,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-CareerTransitionReimbBBIntl-c213a5c9-acdf-44ba-95cc-fcb2af0af645",
//       "productSpecificationReference": "PRD-CareerTransitionReimb",
//       "displayName": "Spouse/Partner Transition Reimbursement",
//       "description": "You may be reimbursed for job-hunting expenses, online education programs, and transportation relating to the transition.",
//       "category": "After You Arrive",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 6,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ],
//         "spouse_partner": {
//           "id": "5e9e353111b56c4518f00f65",
//           "spousePartnerRelocating": true
//         }
//       }
//     },
//     {
//       "reference": "OFF-ChildCareReimbBBIntl-fde49627-183a-4b05-9e59-5dbf616f2376",
//       "productSpecificationReference": "PRD-ChildCareReimb",
//       "displayName": "Child Care Reimbursement Support",
//       "description": "Designed to cover daycare costs while your spouse/partner is job hunting or in off site training classes.",
//       "category": "After You Arrive",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 10,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "isNewlyAdded": true,
//       "isNewlyAddedRef" : true,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ],
//         "spouse_partner": {
//           "id": "5e9e353111b56c4518f00f65",
//           "spousePartnerRelocating": true
//         }
//       }
//     },
//     {
//       "reference": "OFF-ParentReimbBBIntl-90f658d1-04d0-4023-9b1a-55681a6101ad",
//       "productSpecificationReference": "PRD-ChildCareReimb",
//       "displayName": "Single Parent Reimbursement Support",
//       "description": "Designed to cover cleaning services, child care, and other miscellaneous chores where a single parent needs assistance.",
//       "category": "After You Arrive",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 6,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ],
//         "spouse_partner": {
//           "id": "5e9e353111b56c4518f00f65",
//           "spousePartnerRelocating": true
//         }
//       }
//     },
//     {
//       "reference": "OFF-ChildCareBndlBBIntl-870b0f9d-814a-4543-9ba8-b50bc18f55b9",
//       "productSpecificationReference": "PRD-ChildCareReimb",
//       "displayName": "Child Care Support",
//       "description": "You may select up to 1,500 USD in reimbursements for child care support.",
//       "category": "After You Arrive",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 3,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HomeCtryParentReimbBBIntl-507e15c3-d6be-403f-9dd8-93707358089f",
//       "productSpecificationReference": "PRD-ChildCareReimb",
//       "displayName": "Home Country Parent Reimbursement",
//       "description": "Designed to cover expenses at your home country location including shopping assistance, house cleaning, and transportation.",
//       "category": "After You Arrive",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 6,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "isNewlyAdded": true,
//       "isNewlyAddedRef" : true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-CltrlTrngBB-02d0fcae-a6d1-4f60-8f59-5db9caf4246f",
//       "productSpecificationReference": "PRD-CltrlTrng",
//       "displayName": "Cultural Training - up to 2 days",
//       "description": "A cross-cultural training program customized for you and your accompanying family members. This training is designed to help you identify cultural differences between your current and new location.",
//       "category": "Ongoing",
//       "points": 6,
//       "rangeMin": 1,
//       "rangeMax": 2,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "Days",
//       "incremental": true,
//       "incrementalTimespan": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-CltrlTrngOnlnBB-19b9014b-bf47-4f45-a88f-93faaadac685",
//       "productSpecificationReference": "PRD-CltrlTrng",
//       "displayName": "Online Cultural Tool",
//       "description": "An online cultural profile and information tool designed to help you identify cultural differences between your work and communication style and the style typical of your new host country. This tool allows you to:· Discover your personal cultural preferences and compare these to your focus countries· Explore nearly 100 country guides for in-depth information on culture, communication, protocol and business practices· Access your profile and cultural information for 1 year.",
//       "category": "Shipping",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-CltrlTrnsSuppBB2Hr-c8990297-96d3-4076-9379-60ff9dad67e9",
//       "productSpecificationReference": "PRD-CltrlTrnsSupp",
//       "displayName": "2 Hour Cultural Transition Support",
//       "description": "Work with an experienced international assignment coach and country expert to prepare you (and your accompanying family members) for a smooth transition to your host location. Includes 12-month access to an online cultural profile and information tool.\nThis 2-hour virtual coaching session, tailored to your topics of interest, will focus on your host country's:\n    * Cultural values,        \n    * Communication styles,\n    * Social norms,\n    * Business practices, and\n    * Guidance for navigating daily life and managing the cultural adjustment process for each member of the family.",
//       "category": "After You Arrive",
//       "points": 2,
//       "exclusions": [
//         "OFF-CltrlTrnsSuppBB4Hr-3f649de2-2b8a-4b11-a87e-b1eab4d64e37",
//         "OFF-CltrlTrnsSuppCoreBB-81577013-2063-4594-8559-321c72904232",
//         "OFF-CltrlTrnsSuppExtBB-49e38df7-4c37-4857-b99f-f904c666c306"
//       ],
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-CltrlTrnsSuppBB4Hr-3f649de2-2b8a-4b11-a87e-b1eab4d64e37",
//       "productSpecificationReference": "PRD-CltrlTrnsSupp",
//       "displayName": "4 Hour Cultural Transition Support",
//       "description": "Work with an experienced international assignment coach and country expert to prepare you (and your accompanying family members) for a smooth transition to your host location. Includes 12-month access to an online cultural profile and information tool.\nWith 4 hours of cultural transition support (2 x 2-hour virtual coaching sessions), tailored to your topics of interest, you will:\n    * Gain insights to know what to expect and how to understand the local cultural values and mindset;\n    * Explore strategies to effectively adjust to the different business and daily life norms of the host country; and\n    * Receive personalized guidance on navigating daily life in the new environment and managing the cultural adjustment process for each member of the family.",
//       "category": "Ongoing",
//       "points": 4,
//       "exclusions": [
//         "OFF-CltrlTrnsSuppBB2Hr-c8990297-96d3-4076-9379-60ff9dad67e9",
//         "OFF-CltrlTrnsSuppCoreBB-81577013-2063-4594-8559-321c72904232",
//         "OFF-CltrlTrnsSuppExtBB-49e38df7-4c37-4857-b99f-f904c666c306"
//       ],
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-CltrlTrnsSuppCoreBB-81577013-2063-4594-8559-321c72904232",
//       "productSpecificationReference": "PRD-CltrlTrnsSupp",
//       "displayName": "Cultural Transition - Core Support",
//       "description": "Work with an experienced international assignment coach and country expert to prepare you (and your accompanying family members) for a smooth transition to your host location.\nWith 6 hours of cultural transition support (4 x 90-minute virtual coaching sessions), tailored to your topics of interest, you will:\n    * Gain insights to know what to expect and how to understand the local cultural values and mindset;\n    * Explore strategies to effectively adjust to the different business and daily life norms of the host country; and\n    * Get personalized guidance on navigating daily life in the new environment and managing the cultural adjustment process for each member of the family; and\n        * Receive ongoing, real-time support from your coach - send a note for quick advice anytime between sessions and for up to 3 months post-arrival.\n        * Includes 12-month access to an online cultural profile and information tool.\n\nAlso available as a 1-day in-person program where possible.",
//       "category": "Ongoing",
//       "points": 6,
//       "exclusions": [
//         "OFF-CltrlTrnsSuppBB2Hr-c8990297-96d3-4076-9379-60ff9dad67e9",
//         "OFF-CltrlTrnsSuppBB4Hr-3f649de2-2b8a-4b11-a87e-b1eab4d64e37",
//         "OFF-CltrlTrnsSuppExtBB-49e38df7-4c37-4857-b99f-f904c666c306"
//       ],
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-CltrlTrnsSuppExtBB-49e38df7-4c37-4857-b99f-f904c666c306",
//       "productSpecificationReference": "PRD-CltrlTrnsSupp",
//       "displayName": "Cultural Transition -  Extended Support",
//       "description": "Work with an experienced international assignment coach and country expert to prepare you (and your accompanying family members) for a smooth transition to your host location.\nWith 9 hours of cultural transition support (6 x 90-minute virtual coaching sessions), tailored to your topics of interest, you will:\n    * Gain insights to know what to expect and how to understand the local cultural values and mindset;\n    * Explore strategies to effectively adjust to the different business and daily life norms of the host country; and\n    * Get personalized guidance on navigating daily life in the new environment and managing the cultural adjustment process for each member of the family; and\n        * Receive ongoing, real-time support from your coach - send a note for quick advice anytime between sessions and for up to 6 months post-arrival.\n        * Includes 12-month access to an online cultural profile and information tool.\n\nAlso available as a 2-day in-person program where possible.",
//       "category": "Ongoing",
//       "points": 10,
//       "exclusions": [
//         "OFF-CltrlTrnsSuppBB2Hr-c8990297-96d3-4076-9379-60ff9dad67e9",
//         "OFF-CltrlTrnsSuppBB4Hr-3f649de2-2b8a-4b11-a87e-b1eab4d64e37",
//         "OFF-CltrlTrnsSuppCoreBB-81577013-2063-4594-8559-321c72904232"
//       ],
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-CltrlTrnsSuppYouthBB-e20f128c-ba36-4275-82fd-d7a0d4d51df5",
//       "productSpecificationReference": "PRD-CltrlTrnsSupp",
//       "displayName": "Youth Cultural Transition - Core Support",
//       "description": "For children, adapting and integrating into school and daily life in a new country can be challenging - they must learn new social norms, behaviors,languages, and routines.\n             Contact us to discuss how this program can be tailored for your children (a face-to-face delivery option may be available).\n             Prepare and support your children for a smooth through a program with our highly experienced and engaging youth transition coaches,who will facilitate an interactive and customized program based on your children's ages and interests, \n             allowing them to:  \n            * Learn about the culture, geography, food, games, and language of the new country. \n            * Participate in activities, videos, and open discussion to learn about themselves and the host culture. \n            * Build confidence by learning strategies to manage culture shock, fitting in, and making new friends.",
//       "category": "Ongoing",
//       "points": 4,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-CndtAssmtCoreBB-d6b0dbd4-34b3-4b7d-bd1f-b363cf6377b0",
//       "productSpecificationReference": "PRD-CndtAssmt",
//       "displayName": "Candidate Assessment",
//       "description": "You and your spouse/partner can participate in a guided assessment program designed to help you consider the implications of living and working in a new cultural environment and assess your (and your family's) readiness for this specific assignment.",
//       "category": "Before You Move",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       },
//       "suppress": true
//     },
//     {
//       "reference": "OFF-CndtAssmtFlxBB-859ca3cc-2c51-4ef8-99ae-418f463bd012",
//       "productSpecificationReference": "PRD-CndtAssmt",
//       "displayName": "Candidate Assessment",
//       "description": "You and your spouse/partner participate in an assessment program which is designed to help you consider the implications of living and working in a different cultural environment.",
//       "category": "Before You Move",
//       "points": 2,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-DestSvcsLTBndlBB-a91feb8f-0f6a-4574-ba45-085efa5884cf",
//       "productSpecificationReference": "PRD-DestSvcsBndl",
//       "displayName": "Destination - Host Country Services Bundle",
//       "description": "A local professional will accompany you in your new location providing home finding services, assistance with lease negotiations, and settling in services.",
//       "category": "After You Arrive",
//       "points": 6,
//       "exclusions": [
//         "OFF-DestSvcsBndlBB-5233f84d-ca2f-489c-835d-77317e90b918",
//         "OFF-DestSvcs3DayBndlBB-f28106c3-83c7-4d8f-a6af-4145e12b25de",
//         "OFF-DestSvcs4DayBndlBB-dbc3a74c-b8a2-4f19-931f-0a0603bb135c",
//         "OFF-HmFndngBB-55de55ca-80e6-4ee1-94df-e11c342f7cc9",
//         "OFF-StlngInBB-f6bb2d74-e621-463f-be48-6cf6d72d1756"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-DestSvcsLTDepBndlBB-ebf7fd02-cae7-4a53-9c57-6aec48f22eb8",
//       "productSpecificationReference": "PRD-DestSvcsBndl",
//       "displayName": "Destination - Host Country Services Bundle",
//       "description": "A local professional will accompany you in your new location providing home finding services, assistance with lease negotiations, school searches, and settling in services.",
//       "category": "After You Arrive",
//       "points": 6,
//       "exclusions": [
//         "OFF-DestSvcsBndlBB-5233f84d-ca2f-489c-835d-77317e90b918",
//         "OFF-DestSvcs3DayBndlBB-f28106c3-83c7-4d8f-a6af-4145e12b25de",
//         "OFF-DestSvcs4DayBndlBB-dbc3a74c-b8a2-4f19-931f-0a0603bb135c",
//         "OFF-HmFndngBB-55de55ca-80e6-4ee1-94df-e11c342f7cc9",
//         "OFF-StlngInBB-f6bb2d74-e621-463f-be48-6cf6d72d1756"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-DestSvcs3DayBndlBB-f28106c3-83c7-4d8f-a6af-4145e12b25de",
//       "productSpecificationReference": "PRD-DestSvcsBndl",
//       "displayName": "Destination - 3 Day Support",
//       "description": "A local professional will customize a program based on your specific needs which may include home finding, school search, lease negotiation, and settling in services that does not exceed 3 days of support.",
//       "category": "After You Arrive",
//       "points": 5,
//       "exclusions": [
//         "OFF-DestSvcsLTBndlBB-a91feb8f-0f6a-4574-ba45-085efa5884cf",
//         "OFF-DestSvcsLTDepBndlBB-ebf7fd02-cae7-4a53-9c57-6aec48f22eb8",
//         "OFF-DestSvcsBndlBB-5233f84d-ca2f-489c-835d-77317e90b918",
//         "OFF-DestSvcs4DayBndlBB-dbc3a74c-b8a2-4f19-931f-0a0603bb135c",
//         "OFF-HmFndngBB-55de55ca-80e6-4ee1-94df-e11c342f7cc9"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-DestSvcs4DayBndlBB-dbc3a74c-b8a2-4f19-931f-0a0603bb135c",
//       "productSpecificationReference": "PRD-DestSvcsBndl",
//       "displayName": "Destination - 4 Day Support",
//       "description": "A local professional will customize a program based on your specific needs which may include home finding, school search, lease negotiation, and settling in services that does not exceed 4 days of support.",
//       "category": "After You Arrive",
//       "points": 6,
//       "exclusions": [
//         "OFF-DestSvcsLTBndlBB-a91feb8f-0f6a-4574-ba45-085efa5884cf",
//         "OFF-DestSvcsLTDepBndlBB-ebf7fd02-cae7-4a53-9c57-6aec48f22eb8",
//         "OFF-DestSvcsBndlBB-5233f84d-ca2f-489c-835d-77317e90b918",
//         "OFF-DestSvcs3DayBndlBB-f28106c3-83c7-4d8f-a6af-4145e12b25de",
//         "OFF-HmFndngBB-55de55ca-80e6-4ee1-94df-e11c342f7cc9"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-DestSvcsBndlBB-5233f84d-ca2f-489c-835d-77317e90b918",
//       "productSpecificationReference": "PRD-DestSvcsBndl",
//       "displayName": "Destination - 2 Day Support",
//       "description": "A local professional will customize a program based on your specific needs which may include home finding, school search, lease negotiation, and settling in services that does not exceed 2 days of support.",
//       "category": "After You Arrive",
//       "points": 3,
//       "exclusions": [
//         "OFF-DestSvcsLTBndlBB-a91feb8f-0f6a-4574-ba45-085efa5884cf",
//         "OFF-DestSvcsLTDepBndlBB-ebf7fd02-cae7-4a53-9c57-6aec48f22eb8",
//         "OFF-DestSvcs3DayBndlBB-f28106c3-83c7-4d8f-a6af-4145e12b25de",
//         "OFF-DestSvcs4DayBndlBB-dbc3a74c-b8a2-4f19-931f-0a0603bb135c",
//         "OFF-HmFndngBB-55de55ca-80e6-4ee1-94df-e11c342f7cc9"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-DrvrsLcsnsExchBBIntl-694cc53c-29a8-465b-bb37-dd9d9ae05c55",
//       "productSpecificationReference": "PRD-DrvrsLcsnsExch",
//       "displayName": "Driver's License Exchange",
//       "description": "A local professional arranged by Cartus will provide counseling and accompany you throughout this process.  They will assist you in scheduling lessons and the exams as needed as well as forms completion.",
//       "category": "After You Arrive",
//       "points": 2,
//       "scopes": {
//         "complex_rules": [],
//         "spouse_partner": {
//           "id": "6013b7e291f02a2c5c59ea68",
//           "spousePartnerRelocating": true
//         }
//       }
//     },
//     {
//       "reference": "OFF-DrvrsLcsnsExchMultiBBIntl-cc3d4e29-50d4-4980-ab75-d380e5662c8a",
//       "productSpecificationReference": "PRD-DrvrsLcsnsExchMulti",
//       "displayName": "Driver's License Exchange - Accompanied",
//       "description": "A local professional arranged by Cartus will provide counseling and accompany you throughout this process. They will assist you, and your accompanying spouse/partner if selected, in scheduling lessons and the exams as needed as well as forms completion.",
//       "category": "After You Arrive",
//       "points": 2,
//       "scopes": {
//         "complex_rules": [],
//         "spouse_partner": {
//           "id": "5e9e353111b56c4518f00f65",
//           "spousePartnerRelocating": true
//         }
//       }
//     },
//     {
//       "reference": "OFF-DrvrsLicenseReimbBBIntl-55719331-c9e1-4709-9c1b-fdab8909dea1",
//       "productSpecificationReference": "PRD-DrvrsLicenseReimb",
//       "displayName": "Driving Lesson/Licensing Support",
//       "description": "Reimbursement for costs associated with driving lessons and obtaining your license in your host location for you and/or your spouse/partner.",
//       "category": "After You Arrive",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 3,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-DupHousingBB-ad65228c-55b6-4e84-a396-ab42ce3c8597",
//       "productSpecificationReference": "PRD-DupHsng",
//       "displayName": "Duplicate Housing",
//       "description": "If you close on a new home prior to the sale of your existing home in the departure location, you can elect reimbursement for mortgage interest, taxes, insurance, and other potential expenses outlined by policy for one residence. Each point is USD 500.",
//       "category": "Before You Move",
//       "points": 3,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-EdAssistBB-1d78eca8-3556-4335-81ec-ab79a37e4400",
//       "productSpecificationReference": "PRD-EdAssist",
//       "displayName": "Educational Assistance",
//       "description": "Advanced identification and placement for school-aged children. Some options available include: consultation, appointment setting, testing, and navigating the complex differences that exist among educational systems and  curriculums.",
//       "category": "After You Arrive",
//       "points": 7,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-EduSuppBBIntl-023c8db3-d901-4a52-b170-ca34abb13b56",
//       "productSpecificationReference": "PRD-EduSupp",
//       "displayName": "Dependent Education Assistance",
//       "description": "Reimbursement for education up to USD 30,000 or local currency equivalent per child per year.",
//       "category": "Ongoing",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-EduSuppReimbBBIntl-1c23c63e-e08a-4ccf-96a8-27cd5b3b229c",
//       "productSpecificationReference": "PRD-EduSupp",
//       "displayName": "Dependent Education Support",
//       "description": "This reimbursement is intended to cover miscellaneous educational expenses such as uniforms, field trips, and transportation to and from school.",
//       "category": "Ongoing",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 6,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-EduSuppBndlBBIntl-7676de53-60b6-410b-b98f-13f2819e2c47",
//       "productSpecificationReference": "PRD-EduSuppBndl",
//       "displayName": "Dependent Education Support",
//       "description": "This reimbursement is intended to cover miscellaneous educational expenses such as uniforms, field trips, and transportation to and from school.",
//       "category": "After You Arrive",
//       "points": 1,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-FinalTripReimbBB-4da9726c-cf95-47cb-9831-4821659ef4a2",
//       "productSpecificationReference": "PRD-FinalTrip",
//       "displayName": "Final Trip to New Location",
//       "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
//       "category": "Travel and Transport",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 10,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-FnlTrnsprtBB-dde89d98-5fe6-4fd7-8bd3-6e0b05c1c8d0",
//       "productSpecificationReference": "PRD-FnlTrnsprt",
//       "displayName": "Final Transportation to New Location",
//       "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
//       "category": "Travel and Transport",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-FrnRntlBBIntl-d7de3fa4-1916-44b8-9402-96fc47768510",
//       "productSpecificationReference": "PRD-FrnRntl",
//       "displayName": "Furniture Rental",
//       "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
//       "category": "After You Arrive",
//       "points": 4,
//       "rangeMin": 1,
//       "rangeMax": 3,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "Months",
//       "incremental": true,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-GoWiderGoFarBB-532b6b5c-15b0-49cf-8bc8-735cf5784147",
//       "productSpecificationReference": "PRD-GoWiderGoFar",
//       "displayName": "Go Wider - Go Farther",
//       "description": "Your consultant will review your approved program with you.",
//       "category": "After You Arrive",
//       "points": 0,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlAirBBUnacmp-INS-a2d4ed4c-5952-488e-b717-463368994b48",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Air Shipment - Unaccompanied / Cartus Insured",
//       "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size. If you are single or moving unaccompanied, you are authorized for a \"D\" container.",
//       "category": "Shipping",
//       "points": 4,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           }
//         ],
//         "spouse_partner": {
//           "id": "6013b7e291f02a2c5c59ea68",
//           "spousePartnerRelocating": true
//         }
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlAirBBUnacmp-aee01e02-6412-4de1-8700-9f577db54c4b",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Air Shipment - Unaccompanied",
//       "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size. If you are single or moving unaccompanied, you are authorized for a \"D\" container.",
//       "category": "Shipping",
//       "points": 4,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           }
//         ],
//         "spouse_partner": {
//           "id": "6013b7e291f02a2c5c59ea68",
//           "spousePartnerRelocating": true
//         }
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlAirBBAcmp-42cbe42e-2949-4480-b1b2-4a0d5f0df57e",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Air Shipment - Accompanied",
//       "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size. If you are moving with family members you are authorized for an \"LDN\" container.",
//       "category": "Shipping",
//       "points": 6,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           },
//           {
//             "id": "602291781e64fd73f4f4f0fd",
//             "name": "Accompanied",
//             "description": "For transferees that are accompanied by a spouse or partner and/or dependents"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlAirBBAcmp-INS-31850819-e848-4462-8216-b7d517f58805",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Air Shipment - Accompanied / Cartus Insured",
//       "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size. If you are moving with family members you are authorized for an \"LDN\" container.",
//       "category": "Shipping",
//       "points": 6,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           },
//           {
//             "id": "602291781e64fd73f4f4f0fd",
//             "name": "Accompanied",
//             "description": "For transferees that are accompanied by a spouse or partner and/or dependents"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlFlxBB-7a8bb6c9-bf61-43bf-a58b-d53f59ae28ba",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Household Goods - Ground Shipment",
//       "description": "Cartus will engage a professional moving company and will coordinate the shipment of your household goods and personal effects from your old location to your new location.",
//       "category": "Shipping",
//       "points": 20,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114a",
//             "name": "Intra-country",
//             "description": "Moves where the departure country and destination country match"
//           },
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlFlxBB-INS-19630e09-74ca-4cff-ba80-b217f87779f8",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Household Goods - Ground Shipment / Cartus Insured",
//       "description": "Cartus will engage a professional moving company and will coordinate the shipment of your household goods and personal effects from your old location to your new location.",
//       "category": "Shipping",
//       "points": 20,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114a",
//             "name": "Intra-country",
//             "description": "Moves where the departure country and destination country match"
//           },
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlAirBB-INS-05794134-270d-4c15-962a-90fef8b9d6aa",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Air Shipment / Cartus Insured",
//       "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size.",
//       "category": "Shipping",
//       "points": 10,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlAirBB-b1c387d6-10f6-4478-accd-e078dadb5892",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Air Shipment",
//       "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size.",
//       "category": "Shipping",
//       "points": 10,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlCoreBB-1abac541-344c-4f52-acd7-e0e3957422cf",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Household Goods Shipment",
//       "description": "Cartus will engage a freight forwarder to pack and load your personal belongings to ship via air and surface as needed. Your consultant will review the program specifics with you.",
//       "category": "Shipping",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlCoreBB-INS-7ea7e441-7539-4c9f-9c2a-e973feb119e4",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Household Goods Shipment / Cartus Insured",
//       "description": "Cartus will engage a freight forwarder to pack and load your personal belongings to ship via air and surface as needed. Your consultant will review the program specifics with you.",
//       "category": "Shipping",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlSea20BB-2c8b2bfc-de1e-4a7e-bc38-a405c3c5a967",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Sea Shipment - 20 ft (6.06m) - Container",
//       "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
//       "category": "Shipping",
//       "points": 20,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlSea20BB-INS-34a8b8f1-95bb-4e86-8fe3-edf665c11c44",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Sea Shipment - 20 ft (6.06m) - Container / Cartus Insured",
//       "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
//       "category": "Shipping",
//       "points": 20,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlSea40BB-3da136db-8406-4006-9b64-b03d048d7b61",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Sea Shipment - 40 ft (12.2m) - Container",
//       "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
//       "category": "Shipping",
//       "points": 30,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlSea40BB-INS-95c193ba-2495-4587-88c6-a08f940ebe15",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Sea Shipment - 40 ft (12.2m) - Container / Cartus Insured",
//       "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
//       "category": "Shipping",
//       "points": 30,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlSeaBB-INS-836a3b10-2334-4fab-b285-e3169e4f6afe",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Sea Shipment / Cartus Insured",
//       "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
//       "category": "Shipping",
//       "points": 30,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HHGIntlSeaBB-d93ff9f1-ac32-4b28-a1a7-91e16ee3b779",
//       "productSpecificationReference": "PRD-HHG",
//       "displayName": "Sea Shipment",
//       "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
//       "category": "Shipping",
//       "points": 30,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "5f15edcc671145697c1d114b",
//             "name": "Inter-country",
//             "description": "Moves where the departure country and destination country do not match"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HmFndngBB-55de55ca-80e6-4ee1-94df-e11c342f7cc9",
//       "productSpecificationReference": "PRD-HmFndng",
//       "displayName": "Homefinding",
//       "description": "A local professional will accompanying you on a 2 day home finding tour and show you multiple properties as well as assist you in securing a lease.",
//       "category": "Before You Move",
//       "points": 2,
//       "exclusions": [
//         "OFF-DestSvcsLTBndlBB-a91feb8f-0f6a-4574-ba45-085efa5884cf",
//         "OFF-DestSvcsLTDepBndlBB-ebf7fd02-cae7-4a53-9c57-6aec48f22eb8",
//         "OFF-DestSvcsBndlBB-5233f84d-ca2f-489c-835d-77317e90b918",
//         "OFF-DestSvcs3DayBndlBB-f28106c3-83c7-4d8f-a6af-4145e12b25de",
//         "OFF-DestSvcs4DayBndlBB-dbc3a74c-b8a2-4f19-931f-0a0603bb135c"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-HmSlReimbIntlBB-385a7b2a-1be2-4bd9-943b-8156a844a5f8",
//       "productSpecificationReference": "PRD-HmSlReimb",
//       "displayName": "Home Sale Reimbursement",
//       "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs.  Each point is USD 500. ",
//       "category": "Before You Move",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 15,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-HomeLeaveExchBBIntl-0de56268-c06d-4b60-985a-bd8e64b4142b",
//       "productSpecificationReference": "PRD-AirfareReimb",
//       "displayName": "Home Leave Exchange",
//       "description": "In lieu of your trip home, the company will allow you to trade you or your accompanying members home leave reimbursement to allow family members to visit you in your assignment location.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HomeLeaveReimbBBIntl-75956c07-3173-4cf2-9e60-ae990a97d767",
//       "productSpecificationReference": "PRD-AirfareReimb",
//       "displayName": "Home Leave Reimbursement",
//       "description": "The Company covers home leave transportation expenses for all family members on assignment.  All other transportation, car rental, meals, housing, and other incidentals will not be reimbursed and will be at your expense. The class of airfare is based on your Company's Travel and Expense Policy.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-NonAccompDepTrvlBBIntl-cb78d357-0793-46f2-a67c-157065689282",
//       "productSpecificationReference": "PRD-AirfareReimb",
//       "displayName": "Dependent Travel - Non-accompanying Children",
//       "description": "You will be reimbursed for one round-trip ticket annually via economy class air travel from the home to the host location for each eligible dependent child.   Those eligible include dependent children attending primary or secondary school in the home country or a third country or unmarried, dependent children under attending university as full-time.  Your Cartus consultant will provide your Company's eligibility age requirements for this benefit.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-NonAccompDepTrvlReimbBBIntl-53de806a-4c4d-491e-ae18-b122a85ad561",
//       "productSpecificationReference": "PRD-AirfareReimb",
//       "displayName": "Dependent Travel - Non-accompanying Children",
//       "description": "Reimbursement to offset travel costs for non-accompanying dependents.",
//       "category": "Ongoing",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 4,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-HomePurchaseReimbursementBB-65737a31-dbf3-48f1-b792-6d11913769b8",
//       "productSpecificationReference": "PRD-HomePurchaseReimbursement",
//       "displayName": "Home Purchase Reimbursement",
//       "description": "Reimbursement assistance covering normal and customary closing costs.",
//       "category": "After You Arrive",
//       "points": 3,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-ImmgrtnSvcsBB-a0ee5535-5051-46a7-9fa1-abca3f1319a5",
//       "productSpecificationReference": "PRD-ImmgrtnSvcs",
//       "displayName": "Immigration Services",
//       "description": "A professional immigration provider will assist you in securing the required immigration documents for you and your eligible accompanying family members.",
//       "category": "Before You Move",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-LngTrngBBIntlHybrdDep-51e1b9cb-c63f-4d20-a951-5c06eb418833",
//       "productSpecificationReference": "PRD-LngTrng",
//       "displayName": "Language Training - Children",
//       "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language. Starting level assessment conducted by the coach. Flexible digital scheduling; interchange in-person lessons at home or office with virtual lessons as needed. Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location. Industry-leading materials are custom-selected per learner. Live practice in the community. Youth lessons build local language skill and offer critical homework support. (Children may be eligible if not covered in school curriculum already.) Test prep for immigration requirements available.",
//       "category": "Ongoing",
//       "points": 3,
//       "rangeMin": 20,
//       "rangeMax": 100,
//       "rangeIncrementValue": 20,
//       "rangeIncrementUnit": "Hours",
//       "incremental": true,
//       "incrementalTimespan": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-LngTrngBBIntlHybrdSpouse-bb632661-aa5a-49c7-91c3-88b578ee19a0",
//       "productSpecificationReference": "PRD-LngTrng",
//       "displayName": "Language Training - Spouse",
//       "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language. Flexible digital scheduling; interchange in-person lessons at home or office with virtual lessons as needed. Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location. Industry-leading materials are custom-selected per learner. Live practice in the community. Test prep for immigration requirements available.",
//       "category": "Ongoing",
//       "points": 3,
//       "rangeMin": 20,
//       "rangeMax": 100,
//       "rangeIncrementValue": 20,
//       "rangeIncrementUnit": "Hours",
//       "incremental": true,
//       "incrementalTimespan": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ],
//         "spouse_partner": {
//           "id": "5e9e353111b56c4518f00f65",
//           "spousePartnerRelocating": true
//         }
//       }
//     },
//     {
//       "reference": "OFF-LngTrngBB-56c21408-b631-4b3f-abe5-fa5d9c0cf866",
//       "productSpecificationReference": "PRD-LngTrng",
//       "displayName": "Language Training: 20 Hours",
//       "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language. Lessons are offered as individual or family group (you and your spouse/partner).   Starting level assessment conducted by the coach.   Flexible digital scheduling interchange in-person lessons at home or office with virtual lessons as needed.  Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location.   Industry-leading materials are custom-selected per learner.  Live practice in the community.  Youth lessons build local language skill and offer critical homework support. (Children may be eligible if not covered in school curriculum already.)  Test prep for immigration requirements available.",
//       "category": "Ongoing",
//       "points": 4,
//       "rangeMin": 20,
//       "rangeMax": 100,
//       "rangeIncrementValue": 20,
//       "rangeIncrementUnit": "Hours",
//       "incremental": true,
//       "incrementalTimespan": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-LngTrngBB40Hr-471ad31a-fa73-4bbc-b5ac-eef61bfa90ea",
//       "productSpecificationReference": "PRD-LngTrng",
//       "displayName": "Language Training: 40 Hours",
//       "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language. Lessons are offered as individual or family group (you and your spouse/partner).\n    * Starting level assessment conducted by the coach.\n    * Flexible digital scheduling; interchange in-person lessons at home or office with virtual lessons as needed.\n    * Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location.\n    * Industry-leading materials are custom-selected per learner.\n    ** Live practice in the community.\n    ** Youth lessons build local language skills and offer critical homework support. (Children may be eligible if not covered in the school curriculum already.)\n    ** Test prep for immigration requirements available.",
//       "category": "Ongoing",
//       "points": 5,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-LngTrngBBIntlHybrd-0568f454-9858-4ebc-8fd3-419227afcf68",
//       "productSpecificationReference": "PRD-LngTrng",
//       "displayName": "Language Training",
//       "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language. Flexible digital scheduling; interchange in-person lessons at home or office with virtual lessons as needed. Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location. Industry-leading materials are custom-selected per learner. Live practice in the community. Test prep for immigration requirements available.",
//       "category": "Ongoing",
//       "points": 3,
//       "rangeMin": 20,
//       "rangeMax": 100,
//       "rangeIncrementValue": 20,
//       "rangeIncrementUnit": "Hours",
//       "incremental": true,
//       "incrementalTimespan": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-LngTrngOnlnBB-16a1e782-22a0-4f4d-99e3-b494652c3763",
//       "productSpecificationReference": "PRD-LngTrng",
//       "displayName": "Language Training - Online Tool",
//       "description": "Self-paced online language training tool for your and your accompanying members.",
//       "category": "Ongoing",
//       "points": 2,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-LsNegtnBB-925011c1-b8a9-49e1-8342-ebf40addf345",
//       "productSpecificationReference": "PRD-LsNegtn",
//       "displayName": "Lease Negotiation",
//       "description": "Cartus will coordinate assigning local professional to assist in securing and negotiating a lease in your new location.",
//       "category": "After You Arrive",
//       "points": 4,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-LsTermBndl-7bba5194-2310-495a-a419-97682df39421",
//       "productSpecificationReference": "PRD-LsTerm",
//       "displayName": "Lease Cancellation",
//       "description": "Lease termination is designed to offset costs you may incur for early termination of your current lease in the event of a lease breakage and stipulated in the lease agreement.",
//       "category": "Before You Move",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 10,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-LsTermIntlCoreBB-b768904e-acbf-4c4c-a2c7-a08e9737fc85",
//       "productSpecificationReference": "PRD-LsTerm",
//       "displayName": "Lease Cancellation",
//       "description": "Lease termination is designed to offset costs you may incur for early termination of your current lease in the event of a lease breakage and stipulated in the lease agreement.",
//       "category": "Before You Move",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-OngoingAllowBBIntl-58a5d28b-6a18-4100-9a40-33d9ee7a8ef3",
//       "productSpecificationReference": "PRD-OngoingAllow",
//       "displayName": "Expatriate Premium",
//       "description": "Your client sponsor recognizes that you are making a significant move both personally and professionally. Therefore, in recognition of the move, your client sponsor will provide an expatriate premium budget to you throughout the duration of the assignment (initiated once the temporary living period has ended). Your consultant will provide the calculation of the premium.",
//       "category": "Ongoing",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-PetTrnsprtBndlBB-05165c88-a2c6-4ee9-ab57-3ea7d01b6643",
//       "productSpecificationReference": "PRD-PetTrnsprtBndl",
//       "displayName": "Pet Transportation Service - Total 2 Pets",
//       "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
//       "category": "Shipping",
//       "points": 4,
//       "multiplicity": true,
//       "maxSelection": 2,
//       "exclusions": [
//         "OFF-PetTrnsprtReimbBB-94d073f3-3c40-4715-8902-21add9e92917"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-PlcyCnslIntlBB-9c40e37a-80b6-4988-9810-e083d526ad78",
//       "productSpecificationReference": "PRD-PlcyCnsl",
//       "displayName": "Policy Counseling and Administration",
//       "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
//       "category": "Before You Move",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-PreDcsnOrntn1DayBB-b9693a5b-0bab-43eb-90f2-7f2ff121ac07",
//       "productSpecificationReference": "PRD-PreDcsnOrntn",
//       "displayName": "Pre-Decision Orientation - 1 Day",
//       "description": "A local professional coordinated by Cartus will accompanying you on a tour of your destination location and provide information about local amenities.",
//       "category": "Before You Move",
//       "points": 2,
//       "exclusions": [
//         "OFF-PreDcsnOrntnBB-b176e21d-a2cd-4314-be84-9761635d79f8",
//         "OFF-PreDcsnOrntn2DayBB-d30bd318-978b-4b47-84f6-918f095d7c23"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-PreDcsnOrntn2DayBB-d30bd318-978b-4b47-84f6-918f095d7c23",
//       "productSpecificationReference": "PRD-PreDcsnOrntn",
//       "displayName": "Pre-Decision Orientation - 2 Day",
//       "description": "A local professional coordinated by Cartus will accompanying you on a tour of your destination location, show you some representative properties, and provide information about local amenities.",
//       "category": "Before You Move",
//       "points": 3,
//       "exclusions": [
//         "OFF-PreDcsnOrntnBB-b176e21d-a2cd-4314-be84-9761635d79f8",
//         "OFF-PreDcsnOrntn1DayBB-b9693a5b-0bab-43eb-90f2-7f2ff121ac07"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-PreDcsnOrntnBB-b176e21d-a2cd-4314-be84-9761635d79f8",
//       "productSpecificationReference": "PRD-PreDcsnOrntn",
//       "displayName": "Pre-Decision Orientation",
//       "description": "A local professional arranged by Cartus will take you on an area tour and show you 3 to 5 representative properties.  If you are interested, they will provide information on local schools.",
//       "category": "Before You Move",
//       "points": 3,
//       "exclusions": [
//         "OFF-PreDcsnOrntn1DayBB-b9693a5b-0bab-43eb-90f2-7f2ff121ac07",
//         "OFF-PreDcsnOrntn2DayBB-d30bd318-978b-4b47-84f6-918f095d7c23"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-PropMaintBBIntl-d46918ad-0d80-400d-ad47-07763a4ce758",
//       "productSpecificationReference": "PRD-PropMaint",
//       "displayName": "Property Maintenance",
//       "description": "You will be reimbursed for the expenses relating to property maintenance support for your residence in the home location. Examples of potential expenses: lawn care, utilities, and insurance.",
//       "category": "Ongoing",
//       "points": 4,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-PropMgmtBB-2d5a8498-03d0-4ccb-9a42-1124513a3132",
//       "productSpecificationReference": "PRD-PropMgmt",
//       "displayName": "Property Management",
//       "description": "Cartus will coordinate managing your home country property including managing tenant occupancy.",
//       "category": "Ongoing",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-PropMgmtBBIntl-c3b384a5-7867-48d9-9fd1-7497be4c7c3d",
//       "productSpecificationReference": "PRD-PropMgmt",
//       "displayName": "Local Property Management",
//       "description": "A local professional arranged by Cartus will assist in resolving issues which include the coordination of repairs for you, with your landlord, and with local contractors.",
//       "category": "After You Arrive",
//       "points": 2,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-PrvwTrpBB-26f1803d-e2d0-4b45-bc9c-90b15bcfc1e6",
//       "productSpecificationReference": "PRD-PrvwTrp",
//       "displayName": "Pre-Assignment or Pre-Transfer Trip",
//       "description": "The pre-assignment/pre-transfer trip is designed to assist you in setting realistic expectations about the host/transfer location. This benefit covers the cost of flights, hotel, local transportation and meals for you and eligible accompanying family members.",
//       "category": "Before You Move",
//       "points": 4,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-PrvwTrpReimbBB-bc8823ba-6dc7-4648-8412-ec4fec37e4e0",
//       "productSpecificationReference": "PRD-PrvwTrp",
//       "displayName": "Pre-Transfer Trip",
//       "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.  You will be reimbursed for the amount selected.",
//       "category": "Shipping",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 10,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-RAndRBBIntl-43856a64-d0ff-4f5f-b4f4-21112f2cf858",
//       "productSpecificationReference": "PRD-RAndR",
//       "displayName": "Rest & Recuperation",
//       "description": "For assignment in locations defined as extreme hardship locations by the company’s data provider, additional trips away from the host location may be provided on a periodic basis to a company-designated location. Duration and frequency of trips are pre-set by the data provider and the company will cover the cost of round trip airfare according to the company’s travel policy, accommodations, and ground transportation.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-ReloAcctngIntlBB-a7956898-4639-4742-97ee-c36a729fc1f7",
//       "productSpecificationReference": "PRD-ReloAcctng",
//       "displayName": "Expense Management",
//       "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
//       "category": "Before You Move",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-ReturnTrpReimbBB-9fdd78bd-e054-4320-b54c-b6ef377eb1fd",
//       "productSpecificationReference": "PRD-ReturnTrp",
//       "displayName": "Return Trip Home Reimbursement",
//       "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
//       "category": "Travel and Transport",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 5,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-SchlFndgEdCmplBBIntl-530591c0-0700-4bb8-945f-b28f78e7df5c",
//       "productSpecificationReference": "PRD-SchlFndg",
//       "displayName": "Education Complete",
//       "description": "A local professional coordinated by Cartus will provide you with information and options for schools, schedule visits, assist with the enrollment process and if needed, will assist in retrieving a school deposit if refundable (if/when an application falls through).",
//       "category": "After You Arrive",
//       "points": 4,
//       "exclusions": [
//         "OFF-DestSvcsBndlBB-5233f84d-ca2f-489c-835d-77317e90b918",
//         "OFF-DestSvcs3DayBndlBB-f28106c3-83c7-4d8f-a6af-4145e12b25de",
//         "OFF-DestSvcs4DayBndlBB-dbc3a74c-b8a2-4f19-931f-0a0603bb135c"
//       ],
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-SchlFndgEdCmplBBIntl1Day-8e3cdcff-4a88-4127-91d6-3beb49674fcf",
//       "productSpecificationReference": "PRD-SchlFndg",
//       "displayName": "Education Complete - 1 Day",
//       "description": "A local professional will provide you with information and options for schools.",
//       "category": "After You Arrive",
//       "points": 2,
//       "exclusions": [
//         "OFF-DestSvcsBndlBB-5233f84d-ca2f-489c-835d-77317e90b918",
//         "OFF-DestSvcs3DayBndlBB-f28106c3-83c7-4d8f-a6af-4145e12b25de",
//         "OFF-DestSvcs4DayBndlBB-dbc3a74c-b8a2-4f19-931f-0a0603bb135c"
//       ],
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-SchlFndgEdCmplBBIntl2Day-75605fda-23a6-45b6-955e-0f3360865757",
//       "productSpecificationReference": "PRD-SchlFndg",
//       "displayName": "Education Complete - 2 Day",
//       "description": "A local professional arranged by Cartus will provide you with options for schools and schedule a visit as necessary. Depending on your need they will assist with the enrollment process.",
//       "category": "After You Arrive",
//       "points": 3,
//       "exclusions": [
//         "OFF-DestSvcsBndlBB-5233f84d-ca2f-489c-835d-77317e90b918",
//         "OFF-DestSvcs3DayBndlBB-f28106c3-83c7-4d8f-a6af-4145e12b25de",
//         "OFF-DestSvcs4DayBndlBB-dbc3a74c-b8a2-4f19-931f-0a0603bb135c"
//       ],
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-SchlFndgBB-5314a037-0b47-4f38-b307-c7e710e725bb",
//       "productSpecificationReference": "PRD-SchlFndg",
//       "displayName": "School Search",
//       "description": "A local professional will provide you information on local schools, assist in setting up meetings and accompany you on a tour of the schools.",
//       "category": "After You Arrive",
//       "points": 2,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-SlfStrgReimbBBIntl-05b4c33a-fb91-41ee-b954-ebe9d3ea88b6",
//       "productSpecificationReference": "PRD-SlfStrg",
//       "displayName": "Self Storage Reimbursement",
//       "description": "You can be reimbursed for storage at your home location.",
//       "category": "Shipping",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 3,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-SpouseCareerSuppIntlBB-4b13ed51-cf0f-4f48-8be2-c7eadd04f8dc",
//       "productSpecificationReference": "PRD-SpouseCareerSupp",
//       "displayName": "Spouse/Partner Career Support",
//       "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
//       "category": "After You Arrive",
//       "points": 4,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ],
//         "spouse_partner": {
//           "id": "5e9e353111b56c4518f00f65",
//           "spousePartnerRelocating": true
//         }
//       }
//     },
//     {
//       "reference": "OFF-StlngInBB-f6bb2d74-e621-463f-be48-6cf6d72d1756",
//       "productSpecificationReference": "PRD-StlngIn",
//       "displayName": "Settling In",
//       "description": "A local professional will provide you with an overview of neighborhoods and housing, shopping, transportation systems, medical services and other local amenities.",
//       "category": "After You Arrive",
//       "points": 2,
//       "exclusions": [
//         "OFF-DestSvcsLTBndlBB-a91feb8f-0f6a-4574-ba45-085efa5884cf",
//         "OFF-DestSvcsLTDepBndlBB-ebf7fd02-cae7-4a53-9c57-6aec48f22eb8",
//         "OFF-DestSvcsBndlBB-5233f84d-ca2f-489c-835d-77317e90b918",
//         "OFF-DestSvcs3DayBndlBB-f28106c3-83c7-4d8f-a6af-4145e12b25de",
//         "OFF-DestSvcs4DayBndlBB-dbc3a74c-b8a2-4f19-931f-0a0603bb135c"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-StrgInTrnst180BB-72238aa4-0abe-4c20-a096-a0a8baec39a7",
//       "productSpecificationReference": "PRD-StrgInTrnst",
//       "displayName": "Storage in Transit - Up To 180 Days",
//       "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
//       "category": "Shipping",
//       "points": 1,
//       "rangeMin": 15,
//       "rangeMax": 180,
//       "rangeIncrementValue": 15,
//       "rangeIncrementUnit": "Days",
//       "incremental": true,
//       "prerequisites": [
//         "OFF-HHGIntlCoreBB-1abac541-344c-4f52-acd7-e0e3957422cf",
//         "OFF-HHGIntlAirBB-b1c387d6-10f6-4478-accd-e078dadb5892",
//         "OFF-HHGIntlSeaBB-d93ff9f1-ac32-4b28-a1a7-91e16ee3b779",
//         "OFF-HHGIntlSea20BB-2c8b2bfc-de1e-4a7e-bc38-a405c3c5a967",
//         "OFF-HHGIntlSea40BB-3da136db-8406-4006-9b64-b03d048d7b61",
//         "OFF-HHGIntlFlxBB-7a8bb6c9-bf61-43bf-a58b-d53f59ae28ba"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-StrgInTrnstBBIntl30-7d0f301e-a809-4600-81b5-b51300a80727",
//       "productSpecificationReference": "PRD-StrgInTrnst",
//       "displayName": "Storage in Transit - 30 Days",
//       "description": "Cartus will arrange for a professional freight forwarder to store your personal belongings for 30 days.",
//       "category": "Shipping",
//       "points": 3,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-StrgInTrnstBBIntl3160-cc5cfddb-512d-4030-8db7-ee2e5aecdaae",
//       "productSpecificationReference": "PRD-StrgInTrnst",
//       "displayName": "Additional Storage in Transit - 31 to 60 Days",
//       "description": "You may select an additional 30 days of storage following your initial selection of 30 days.",
//       "category": "Shipping",
//       "points": 3,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-StrgInTrnstBBIntl6190-992af3e4-7eb3-4b94-a088-d423a8f1fefe",
//       "productSpecificationReference": "PRD-StrgInTrnst",
//       "displayName": "Additional Storage in Transit - 61 to 90 Days",
//       "description": "You may select an additional 30 days of storage if you need to extend your storage beyond the 60 days selected.",
//       "category": "Shipping",
//       "points": 3,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-TaxBrfngBB-e9f7d65b-393c-445d-b36b-faf2fbcce960",
//       "productSpecificationReference": "PRD-TaxCnslt",
//       "displayName": "Tax Briefing",
//       "description": "Your Company's international tax provider will review and outline the potential tax implications relating to your assignment or transfer.",
//       "category": "Travel and Transport",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-VIPTaxBBIntl-9a12c067-3470-4eec-bdc9-fc186d30fffc",
//       "productSpecificationReference": "PRD-TaxCnslt",
//       "displayName": "VIP Tax Service",
//       "description": "Your new Business Unit has approved you for VIP tax service.",
//       "category": "After You Arrive",
//       "points": 0,
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-TaxPrepBB-9d9bee9e-a6d4-4293-8938-d8bb9a60baf7",
//       "productSpecificationReference": "PRD-TaxPrep",
//       "displayName": "Tax Preparation",
//       "description": "Your company has arranged for a third party international tax provider to provide tax preparation services for a period of time based on your assignment or transfer.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-TmpHsngCrIntlBB-8aa8669f-ae69-46c7-a5c6-78a57974dbfc",
//       "productSpecificationReference": "PRD-TmpHsng",
//       "displayName": "Temporary Housing",
//       "description": "You will receive 45 days of temporary housing at your new location. Your Cartus consultant will assist in securing accommodations, arrange, whenever possible, for direct billing to Cartus on your behalf, and advise you of other reimbursable expenses.",
//       "category": "After You Arrive",
//       "points": "Guaranteed",
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-TmpHsngFlxIntlBB-e9609051-ac7d-465a-959e-f47b2d681597",
//       "productSpecificationReference": "PRD-TmpHsng",
//       "displayName": "Temporary Housing",
//       "description": "This benefit covers temporary housing at your new location in single-month increments. Your Cartus consultant will assist in securing accommodations, arrange, whenever possible, for direct billing to Cartus on your behalf, and advise you of other reimbursable expenses. You can submit up to 3 months for reimbursement, at 8 points per month.",
//       "category": "After You Arrive",
//       "points": 8,
//       "rangeMin": 1,
//       "rangeMax": 3,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "Months",
//       "incremental": true,
//       "exclusions": [
//         "OFF-TmpHsngReimbIntlBB-828e28fb-14f1-4185-9a60-fccf3c8f5e08"
//       ],
//       "scopes": {
//         "complex_rules": [
//           {
//             "id": "601bbca4da353fac12ee90db",
//             "name": "International",
//             "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
//           }
//         ]
//       }
//     },
//     {
//       "reference": "OFF-TmpHsngAddDaysBBIntlHybrd-c320a5da-d21f-4b19-b7de-102441d41a65",
//       "productSpecificationReference": "PRD-TmpHsng",
//       "displayName": "Temporary Living Additional Days",
//       "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location.  Your Cartus consultant can provide additional insight as to what this benefit covers.",
//       "category": "After You Arrive",
//       "points": 4,
//       "rangeMin": 15,
//       "rangeMax": 120,
//       "rangeIncrementValue": 15,
//       "rangeIncrementUnit": "Days",
//       "incremental": true,
//       "incrementalTimespan": true,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-TmpHsngReimbIntlBB-828e28fb-14f1-4185-9a60-fccf3c8f5e08",
//       "productSpecificationReference": "PRD-TmpHsng",
//       "displayName": "Temporary Housing - Reimbursement",
//       "description": "This benefit covers the cost of temporary housing at your new location. Please refer to your company's policy guidelines for covered expenses. You can submit for reimbursement up to your selected point value. One point is USD 500.",
//       "category": "After You Arrive",
//       "points": 1,
//       "rangeMin": 1,
//       "rangeMax": 12,
//       "rangeIncrementValue": 1,
//       "rangeIncrementUnit": "USD",
//       "perPointCurrency": 500,
//       "incrementalCurrency": true,
//       "exclusions": [
//         "OFF-TmpHsngFlxIntlBB-e9609051-ac7d-465a-959e-f47b2d681597"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-TravelCoordBB-b27ff52c-84d0-440a-89f0-beb788e452db",
//       "productSpecificationReference": "PRD-TravelCoord",
//       "displayName": "Travel Coordination",
//       "description": "Cartus will assist you with booking air travel for up to any relocation trips (home finding, en route, return trip) as outlined in policy.",
//       "category": "Travel and Transport",
//       "points": 1,
//       "scopes": {
//         "complex_rules": []
//       }
//     },
//     {
//       "reference": "OFF-VhclShpmtBndlIntlBB-7aaea022-1243-4254-a0ef-b950cfd71ab4",
//       "productSpecificationReference": "PRD-VhclShpmtBndl",
//       "displayName": "Automobile Shipment - up to 2 automobiles",
//       "description": "Cartus will arrange for your automobiles, up to a maximum of two, to be shipped from your departure location to your new location. Your consultant will be able to provide additional guidance.",
//       "category": "Shipping",
//       "points": 4,
//       "multiplicity": true,
//       "maxSelection": 2,
//       "exclusions": [
//         "OFF-AutoDispBndlBB-dcfede5e-0f8d-4b5c-8d65-6b078ca39284"
//       ],
//       "scopes": {
//         "complex_rules": []
//       }
//     }
//   ],
//   "count": 144
// }

export const programBenefitData = {
  "benefits": [
    {
      "reference": "OFF-AccntModBBIntl-fda16753-4a0e-446f-aa05-96273d7d24c1",
      "productSpecificationReference": "PRD-AccntMod",
      "displayName": "Accent Modification: 12 Hours",
      "description": "Intensive program designed to improve clarity and intelligibility of speech for advanced level English as a second language speakers. Programs focus on preparing for immediate business tasks or high stakes situations. A specialized accent modification coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.\n            * Technical speech gap analysis and customized learning plan developed by the coach.\n            * Addresses pronunciation of sounds, intonation, and rhythm within the new cultural context.\n            * Flexible scheduling and online delivery.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Language Training",
          "subProductName": "Accent Modification "
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 7,
      "scopes": {
        "complex_rules": []
      },
      "isNewlyAdded": true,
      "isNewlyAddedRef" : true,
    },
    {
      "reference": "OFF-AirfareReimbBndlBBIntl-19784b57-a74e-474f-8d78-cbfacd30242a",
      "productSpecificationReference": "PRD-AirfareReimbBndl",
      "displayName": "Airfare Expense Support",
      "description": "Reimbursement of airfare expenses for parents, au pair, and family members to visit you in the host location who are not considered dependents.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Home Leave",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Travel & Transport",
      "points": 1,
      "multiplicity": true,
      "maxSelection": 3,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      },
      "suppress": true,
      "isNewlyAdded": true,
      "isNewlyAddedRef" : true
    },
    {
      "reference": "OFF-AirfareUpgradeBndlBBIntl-d065b47d-4e37-430d-b5da-4fc161b6377d",
      "productSpecificationReference": "PRD-AirfareUpgradeBndl",
      "displayName": "Airfare Upgrade",
      "description": "This reimbursement is intended to cover the expense of upgrading your class of airfare for the pre-assignment trip or home leave.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Familarization Trip/Look-See Trip or Enroute or Home Leave",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Enroute, Homefinding, Return Trip",
          "subProductName": ""
        }
      },
      "category": "Travel & Transport",
      "points": 1,
      "multiplicity": true,
      "maxSelection": 2,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-AirfareUpgrdBusnClsBBIntl-7311c8fa-6371-4aff-aae7-63c11235a964",
      "productSpecificationReference": "PRD-AirfareUpgrade",
      "displayName": "Home Leave Business Class Upgrade",
      "description": "Your new Business Unit has approved you for an upgrade to Business Class for your Home Leave trip.",
      "productSubDetails": {},
      "category": "After Arrival",
      "points": 0,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HouseHuntUpgrdBB-6f748c27-036d-42d0-986f-3709f9a298cd",
      "productSpecificationReference": "PRD-AirfareUpgrade",
      "displayName": "House Hunting Business Class Upgrade",
      "description": "Your new Business Unit has approved you for an upgrade to Business Class for your house hunting trip.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Home Finding",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Home Finding",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": 0,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-ApplAllowBB-1a45b531-059c-4df9-8f8b-126a21b143d8",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Appliance Allowance",
      "description": "You will receive an appliance allowance.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Furniture Services",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-ApplianceReimbBB-e6ce51a6-7f06-41fb-858b-88a1b5933da9",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Appliance Reimbursement",
      "description": "You are eligible for receive an appliance reimbursement.  You can select the amount you need incrementally to cover the costs.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Furniture Services",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 1,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      },
      "isNewlyAdded": true,
      "isNewlyAddedRef" : true
    },
    {
      "reference": "OFF-CompletionBonusBB-b814052a-84ba-4b4e-8bcf-095634f26918",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Completion Bonus",
      "description": "Following the completion of your assignment, you will be eligible for a bonus. This will be noted in your overall assignment package and payable at the completion of your assignment.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Repat Allowance",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 0,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-DangerPayBB-5f59c8f3-7cb1-468f-a920-7ea8bfc68d44",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Danger Pay",
      "description": "Danger pay may be paid if you are moving into an area identified as hazardous. Your Cartus consultant will confirm if your host location falls into one of these categories.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-FamSocAsstBBIntl-a5cb9480-393a-492a-bab7-03fbf46a83d0",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Family and Social Assistance",
      "description": "Your client sponsor provides a set budget to assist you and your dependents in becoming acclimated to the host location. The allowance depends on the family size in the host location. Your Cartus Consultant will review this allowance with you.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-FamilyAllowBB-76019174-2c74-4526-94c3-f43a0537533f",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Family Allowance",
      "description": "This allowance is designed to offset the costs of moving parents and additional home leave associated with them living with you.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 0,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HomeLeaveAllowBBIntl-d3ee98b9-65cf-4253-bb21-32f4476618a3",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Home Leave Allowance",
      "description": "The Company covers home leave transportation expenses for all family members on assignment. The allowance will be provided based on the approved point value.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Home Leave",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HouseFittingBB-da56d513-fa64-45c4-96c1-f9b8f21790ba",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "House Fitting Lump Sum",
      "description": "Depending on your destination country, you may receive a one-time allowance to coordinate the installation of cabinetry, closets and light fixtures.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Furniture Services",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 1,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HstHsngAllwncSuppBBIntlCr-0ac8481f-ac5d-4bf3-ac33-b6386f18a127",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Host Housing Allowance Support",
      "description": "Provided for the duration of your assignment based on family size as determined by the Company utilizing an international third-party data provider.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Host Housing",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": 0,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HstHsngAllwncSuppBBIntlFlx-befd9385-8e34-4e04-a4a2-0ce199cbe395",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Host Housing Allowance Support",
      "description": "You are eligible to receive a housing allowance in your host country based on your family size needs. Your consultant will discuss the allowance based on the family size of accompanying members relocating with you.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Host Housing",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": 0,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-MiscAllwnc10IntlBB-77adfc1c-afdd-4897-8aac-1b4681c04f10",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Miscellaneous Expense Allowance",
      "description": "An allowance to help offset incidental assignment expenses not specifically reimbursed or covered by the policy, the Company provides a one-time payment equal to one month's base pay not to exceed USD 10,000 USD or local currency equivalent.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Miscellaneous Expense Allowance ",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-MiscAllwnc2IntlBB-555c6844-4d92-458e-bceb-ff380d070b0e",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Miscellaneous Expense Allowance",
      "description": "You can select an allowance to cover move related expenses of USD 2,000.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Miscellaneous Expense Allowance ",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": 4,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-MiscAllwnc5IntlBB-5e1db41b-f682-4eb7-970d-134a5c9ae7e5",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Miscellaneous Expense Allowance",
      "description": "An allowance to help offset incidental assignment expenses not specifically reimbursed or covered by the policy, the Company provides a one-time payment equal to USD 5,000 or local currency equivalent.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Miscellaneous Expense Allowance ",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-MobPremBB-6eb7a362-38b6-4c8f-8e2e-33f445d2217e",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Mobility Premium",
      "description": "Your new Business Unit has approved a mobility premium. Your Cartus consultant will provide additional details.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-PetTrnsprtAllwncBB-4a5357aa-7e8e-43f6-a762-f94ffc03a4ae",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Pet Transportation Allowance",
      "description": "A one-time allowance to cover the cost of transporting your pet(s) of USD 500.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Pet Shipment",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": 1,
      "exclusions": [
        "OFF-PetTrnsprtReimbBB-da24271d-09d8-40cb-99d3-a918dd1ab540"
      ],
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-PetTrnsprtBBIntlCore-3224a6f6-356d-4336-a13d-07a1a51cfba5",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Pet Transportation Reimbursement",
      "description": "When you arrange the transportation of your pet, you will be reimbursed for the transportation costs of your pet(s) from your old location to your new location.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Pet Shipment",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-UtilAllowBB-8b3d63a5-ac24-4397-a802-f8b0ba7cee1f",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Utility Allowance",
      "description": "You will receive an allowance to offset the cost utilities at your host location.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Host Location Housing",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-WireFeeReimbBBIntl-f95e0e57-4c75-4018-a653-f0449e895f41",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Wire Fee Reimbursement",
      "description": "While on assignment, you may be approved for reimbursement of applicable monthly ATM and/or wire transfer fees up to a maximum amount of USD 25 per month not to exceed USD 300 per year or local currency equivalent.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Banking Services",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-AllwncInLieuShipBB-5d431501-1f91-4caa-9237-1bd5573f1348",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Allowance in Lieu of Shipping",
      "description": "If you elect not to ship your personal belongings, you may receive an allowance.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Lump Sum",
          "subProductName": "",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "Shipping",
      "points": 10,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-ApplncReimbBBIntl-03e6926d-34bd-47b3-9d2f-fb83bbdbdc7b",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Appliance Reimbursement",
      "description": "You are eligible to receive an appliance reimbursement up to USD 3,500 or local currency equivalent.  Your consultant will review what documentation is needed.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Furniture Services",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-CashOutBBAdv-0c358d81-c6ca-4223-838e-eaf0cefc6969",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Cash Out",
      "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": " Lump Sum",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Lump Sum",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": 0,
      "advCashOutV2": true,
      "pointsPerSelection": 1,
      "tierConfigs": [
        {
          "maxRange": 3,
          "maxSelection": 3,
          "minRange": 1,
          "pointValue": 1290
        },
        {
          "maxRange": 7,
          "maxSelection": 4,
          "minRange": 4,
          "pointValue": 890
        }
      ],
      "maxSelection": 7,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-CashOutBBAdvV2-7e9ef4c4-1e8b-49b8-863b-1f88e31b2d87",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Cash Out",
      "description": "If you have completed the selection of your benefits and have surplus points,you can trade your points for cash to use against other miscellaneous relocation expenses that may arise.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Lump Sum",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Lump Sum",
          "subProductName": ""
        }
      },
      "category": "Allowance",
      "points": 0,
      "advCashOutV2": true,
      "pointsPerSelection": 1,
      "scopes": {
        "complex_rules": []
      },
      "isNewlyAdded": true,
      "isNewlyAddedRef" : true
    },
    {
      "reference": "OFF-CostOfLvngDiffIntlBB-c6f936ba-4ed9-4041-ac71-3873f2ca4a03",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Cost of Living Differential",
      "description": "The Cost of Living Differential is based on your level of income at the departure location, as well as other criteria determined by the Company (for example, number of dependents).  The cost of living factors may include, but are not limited to, housing costs, taxes, goods and services, transportation, etc.  Your consultant will review the guidelines with you in more detail.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Cost of Living or Compensation Services",
          "subProductName": "Ongoing Comp Support"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-DualUtilityBBIntl-3d62118c-7423-4e6b-828f-69d296fd748a",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Dual Utilities Cost",
      "description": "If you are an unaccompanied assignee and your family remained in the home country.  You will be reimbursed the cost of utilities up to a reasonable level of consumption.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Host Location Housing",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-ExcsBagBB-0e82dab3-805d-4bb0-a5d4-b2c2906c4b8b",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Excess Baggage Allowance",
      "description": "A one-time allowance to cover the cost of excess baggage fees assessed by an airline of USD 500.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "EnRoute",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "EnRoute",
          "subProductName": ""
        }
      },
      "category": "Travel & Transport",
      "points": 1,
      "exclusions": [
        "OFF-ExcsBagReimbBB-b07c10a1-6784-4438-9eaf-9e4ddb58c2d4"
      ],
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-ExcsBagReimbBB-b07c10a1-6784-4438-9eaf-9e4ddb58c2d4",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Excess Baggage Reimbursement",
      "description": "This benefit covers the reimbursement of excess baggage by an airline. Each point is USD 500.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "EnRoute",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "EnRoute",
          "subProductName": ""
        }
      },
      "category": "Travel & Transport",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 3,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "exclusions": [
        "OFF-ExcsBagBB-0e82dab3-805d-4bb0-a5d4-b2c2906c4b8b"
      ],
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-FurnAllowIntl-a2fc16ab-d2eb-455c-8e76-5aef717e2949",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Furniture Allowance",
      "description": "In lieu of any type of moving services and storage, you may select an allowance not to exceed USD 5,000.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Furniture Services",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 10,
      "exclusions": [
        "OFF-HHGIntlCoreBB-f7b1cd39-adfe-4f34-8b4f-5778f593a258",
        "OFF-HHGIntlAirBB-b1baa567-7e20-4506-a16d-9bc53991e8a6",
        "OFF-HHGIntlSeaBB-e426eb19-4639-4831-a396-87a1aa6e3257",
        "OFF-HHGIntlSea20BB-7bd97888-6a83-4839-b94b-712a41c85d9c",
        "OFF-HHGIntlSea40BB-835de02d-4f82-4189-bf81-7ce9d81f9424",
        "OFF-HHGIntlFlxBB-cedf8414-aaa9-4777-a33e-1021232ba79b"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-HlthCrReimbBB-55cbf9ad-91c9-4c59-99fe-1dabe13e2bc3",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Health Care",
      "description": "Reimbursement of relocation health inoculations not covered under the health plan for you and your accompanying family members.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Medical Examination",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-LocHrdshpPremBBIntl-2067a20e-52d4-4ab7-a10c-80d4061d205c",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Hardship/Location Premium",
      "description": "A location premium may be paid depending upon the specific circumstances surrounding unusually difficult host country conditions such as harsh climate, political instability, physical risk, or the lack of social amenities. Your Cartus consultant will confirm if your host location falls into one of these categories.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Comp Services",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-PetTrnsprtReimbBB-da24271d-09d8-40cb-99d3-a918dd1ab540",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Pet Transportation Reimbursement",
      "description": "When you arrange the transportation of your pet, you will be reimbursed for the transportation costs your pet (s) from your old location to your new location. Each point is USD 500. You can submit for reimbursement up to your selected point value.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Pet Shipment",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Pet Shipment",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 4,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "exclusions": [
        "OFF-PetTrnsprtBndlBB-ae1b73c5-d355-4a04-983e-b97c18a8bb9e"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-PrkngReimbBBIntl-8882e4e4-e654-4dc5-bf07-3d0baaf20245",
      "productSpecificationReference": "PRD-MiscAllwnc",
      "displayName": "Parking Space Monthly Reimbursement",
      "description": "If one (1) parking space is not included in your lease in the host location, you will be reimbursed up to USD 350 per month not to exceed USD 4,200 per year or local currency equivalent.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Additional Employee Benefits",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-AutoDispBBIntl-e1bae93e-a4b5-437f-9ed1-9af7b14d00fc",
      "productSpecificationReference": "PRD-AutoLoss",
      "displayName": "Auto Lease Breakage",
      "description": "If you elect to sell or cancel the lease on your automobile prior to your move, you will be reimbursed up to the selected point value.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Loss on Auto",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Loss on Auto",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 12,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-AutoDispBndlBB-388eb389-b91a-45c4-a2a4-267ccb4e17a4",
      "productSpecificationReference": "PRD-AutoDispBndl",
      "displayName": "Automobile Disposition - Total 2 vehicles",
      "description": "If you elect to sell or cancel the lease on your automobile(s) prior to your move, you will receive an allowance.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Loss on Auto",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Vehicle Services",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": 6,
      "multiplicity": true,
      "maxSelection": 2,
      "exclusions": [
        "OFF-VhclShpmtBndlIntlBB-4892280b-e4f6-4e4a-847a-5253aaf5164b"
      ],
      "scopes": {
        "complex_rules": []
      },
      "isNewlyAdded": true,
      "isNewlyAddedRef" : true
    },
    {
      "reference": "OFF-AutoLeaseBBIntl-856fe191-5fd0-4543-b2de-d6d3de0a0414",
      "productSpecificationReference": "PRD-AutoLease",
      "displayName": "Host Country Car Provision",
      "description": "You will be provided with a maintained car by the host company in accordance with the standard level in the Host Location. At the host company’s sole discretion, the size or type of car may be changed to suit family size or road conditions.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Vehicle Services",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-BrkrFndrFeeIntlBB-614d1a33-e30a-4437-b20d-2e463fd6c2a9",
      "productSpecificationReference": "PRD-BrkrFndrFee",
      "displayName": "Broker's Fee/Finder's Fee",
      "description": "Reimbursement of agency fees associated with securing a lease in your new location. One point is USD 500.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Host Location Housing",
          "subProductName": "",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "After Arrival",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 10,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      },
      "suppress": true
    },
    {
      "reference": "OFF-BsnCommEnglBBIntl20-0888b0ee-9db4-4a92-a022-4df56996ab2f",
      "productSpecificationReference": "PRD-BusnComm",
      "displayName": "Business English: 20 Hours",
      "description": "Comprehensive language skill development for the workplace for all levels. Learners are matched with an experienced corporate language coach familiar with the local business culture. \n            * Flexible scheduling managed online through mobile device offers convenience for busy assignees.\n            * All sessions delivered virtually through our digital delivery platform.\n            * Course is customized, with primary focus on the learner's actual work communication.\n            * Commonly targeted goal areas include: developing relationships, small talk, networking, phone calls, meetings, emails, understanding accents, influencing and presentations.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Language Training",
          "subProductName": "Business English Online"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 3,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-BusnCommAdvBBIntl-7b945422-dc27-4f65-af8d-fcfd13e15bf5",
      "productSpecificationReference": "PRD-BusnComm",
      "displayName": "Advanced Business Communication Skills Coaching: 20 hours",
      "description": "Designed for highly proficient speakers who have mastered fluency and business communication skills for work but who would like to adapt one’s style for maximum efficiency and impact. The program can be customized to address any of the following: creating more impactful presentation style feedback and practice, influencing and persuasion techniques, improving speech/voice clarity, and flexing communication styles to local culture and systems, as well as addressing many other challenges.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Language Training",
          "subProductName": "Advanced Business Communication Skills-20 Hr Individual Ongoing"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 3,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-BusnEnglWrtngBBIntl20-bc646467-8d73-4828-9e65-2dc0a8f776a3",
      "productSpecificationReference": "PRD-BusnComm",
      "displayName": "Business English Writing: 20 Hours",
      "description": "Intensive program designed to address specific skill gaps for C1+ advanced level English speakers. A specialized Business English Writing Coach incorporates actual work content to promote the ability to confidently engage and influence local and global audiences.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Language Training",
          "subProductName": "Business Writing "
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 5,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-GlbLdrshpCommBBIntl-cbd39c82-7584-48c9-bff1-8b487b8b0178",
      "productSpecificationReference": "PRD-BusnComm",
      "displayName": "Global Leadership Communication Coaching: 12 Hours",
      "description": "Intensive program to address language skill and executive style gaps for C1+ advanced level English speakers using English as a second language. The program focuses on preparing for immediate business tasks or high stakes situations. A specialized corporate communications coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Language Training",
          "subProductName": "Global Leadership Communication Coaching"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 9,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-PrsntSkillsBusnEnglBBIntl12-9133604d-2905-4d45-8026-0f47ef4754c6",
      "productSpecificationReference": "PRD-BusnComm",
      "displayName": "Presentation Skills Coaching for Business English: 12 Hours",
      "description": "Intensive program designed to address specific skill gaps for C1+ advanced level English speakers. Programs focus on preparing for immediate business tasks or high stakes situations. A specialized corporate communications coach incorporates relevant work content to promote the ability to confidently engage and influence local and global audiences.\n            * Gap analysis and customized learning plan developed by the coach\n            * Flexible scheduling and online delivery",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Language Training",
          "subProductName": "Presentation Skills Coaching "
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 6,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-CarRntlIntlBB-48fa70ee-1b62-45b4-b847-4df196bce5c3",
      "productSpecificationReference": "PRD-CarRntl",
      "displayName": "Car Rental",
      "description": "Reimbursement for an economy class car rental for 15 days.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Vehicle Services (Car Rental)",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Travel & Transport",
      "points": 1,
      "rangeMin": 15,
      "rangeMax": 60,
      "rangeIncrementValue": 15,
      "rangeIncrementUnit": "Days",
      "incremental": true,
      "incrementalTimespan": true,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-CareerTransitionReimbBBIntl-b319f17a-2481-48f5-8da7-049ef43608fe",
      "productSpecificationReference": "PRD-CareerTransitionReimb",
      "displayName": "Spouse/Partner Transition Reimbursement",
      "description": "You may be reimbursed for job-hunting expenses, online education programs, and transportation relating to the transition.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Career Transition Assistance",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 6,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ],
        "spouse_partner": {
          "id": "5e9e353111b56c4518f00f65",
          "spousePartnerRelocating": true
        }
      }
    },
    {
      "reference": "OFF-ChildCareReimbBBIntl-BBCloned-5c4fc1c0-bf66-47d0-bed4-cc8a81463ba7",
      "productSpecificationReference": "PRD-ChildCareReimb",
      "displayName": "2 Child Care Reimbursement Support",
      "description": "Designed to cover daycare costs while your spouse/partner is job hunting or in off site training classes.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "After Arrival",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 15,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ],
        "spouse_partner": {
          "id": "5e9e353111b56c4518f00f65",
          "spousePartnerRelocating": true
        }
      }
    },
    {
      "reference": "OFF-ChildCareReimbBBIntl-c4d471ea-9dd3-48a4-886c-2cf12401546a",
      "productSpecificationReference": "PRD-ChildCareReimb",
      "displayName": "Child Care Reimbursement Support",
      "description": "Designed to cover daycare costs while your spouse/partner is job hunting or in off site training classes.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 10,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ],
        "spouse_partner": {
          "id": "5e9e353111b56c4518f00f65",
          "spousePartnerRelocating": true
        }
      }
    },
    {
      "reference": "OFF-ParentReimbBBIntl-d45e9a2b-5bd5-457c-bfb2-a69efa9d2a6f",
      "productSpecificationReference": "PRD-ChildCareReimb",
      "displayName": "Single Parent Reimbursement Support",
      "description": "Designed to cover cleaning services, child care, and other miscellaneous chores where a single parent needs assistance.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 6,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ],
        "spouse_partner": {
          "id": "5e9e353111b56c4518f00f65",
          "spousePartnerRelocating": true
        }
      }
    },
    {
      "reference": "OFF-ChildCareBndlBBIntl-a736a5d9-c917-437a-b5b2-d7d4083b714c",
      "productSpecificationReference": "PRD-ChildCareReimb",
      "displayName": "Child Care Support",
      "description": "You may select up to 1,500 USD in reimbursements for child care support.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 3,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HomeCtryParentReimbBBIntl-2b64bbed-7e0a-40f4-88da-233c29e83bff",
      "productSpecificationReference": "PRD-ChildCareReimb",
      "displayName": "Home Country Parent Reimbursement",
      "description": "Designed to cover expenses at your home country location including shopping assistance, house cleaning, and transportation.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 6,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-CltrlTrnsSuppBB2Hr-3984675e-5075-4236-8e19-c633c62bb77e",
      "productSpecificationReference": "PRD-CltrlTrnsSupp",
      "displayName": "2 Hour Cultural Transition Support",
      "description": "Work with an experienced international assignment coach and country expert to prepare you (and your accompanying family members) for a smooth transition to your host location. Includes 12-month access to an online cultural profile and information tool.\nThis 2-hour virtual coaching session, tailored to your topics of interest, will focus on your host country's:\n    * Cultural values,        \n    * Communication styles,\n    * Social norms,\n    * Business practices, and\n    * Guidance for navigating daily life and managing the cultural adjustment process for each member of the family.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Cross-Cultural Program",
          "subProductName": "Country Overview"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 2,
      "exclusions": [
        "OFF-CltrlTrnsSuppBB4Hr-6851c539-09e3-406e-86d8-d12634e79e17",
        "OFF-CltrlTrnsSuppCoreBB-972f6825-6c99-4a11-a7cb-5a5e48ea0b45",
        "OFF-CltrlTrnsSuppExtBB-2150353d-b249-4bf4-a757-a25f73d3a04d"
      ],
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-CltrlTrnsSuppBB4Hr-6851c539-09e3-406e-86d8-d12634e79e17",
      "productSpecificationReference": "PRD-CltrlTrnsSupp",
      "displayName": "4 Hour Cultural Transition Support",
      "description": "Work with an experienced international assignment coach and country expert to prepare you (and your accompanying family members) for a smooth transition to your host location. Includes 12-month access to an online cultural profile and information tool.\nWith 4 hours of cultural transition support (2 x 2-hour virtual coaching sessions), tailored to your topics of interest, you will:\n    * Gain insights to know what to expect and how to understand the local cultural values and mindset;\n    * Explore strategies to effectively adjust to the different business and daily life norms of the host country; and\n    * Receive personalized guidance on navigating daily life in the new environment and managing the cultural adjustment process for each member of the family.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Cross-Cultural Program",
          "subProductName": "Cultural Coaching (4 hours) or 1/2 Day"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 4,
      "exclusions": [
        "OFF-CltrlTrnsSuppBB2Hr-3984675e-5075-4236-8e19-c633c62bb77e",
        "OFF-CltrlTrnsSuppCoreBB-972f6825-6c99-4a11-a7cb-5a5e48ea0b45",
        "OFF-CltrlTrnsSuppExtBB-2150353d-b249-4bf4-a757-a25f73d3a04d"
      ],
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-CltrlTrnsSuppCoreBB-972f6825-6c99-4a11-a7cb-5a5e48ea0b45",
      "productSpecificationReference": "PRD-CltrlTrnsSupp",
      "displayName": "Cultural Transition - Core Support",
      "description": "Work with an experienced international assignment coach and country expert to prepare you (and your accompanying family members) for a smooth transition to your host location.\nWith 6 hours of cultural transition support (4 x 90-minute virtual coaching sessions), tailored to your topics of interest, you will:\n    * Gain insights to know what to expect and how to understand the local cultural values and mindset;\n    * Explore strategies to effectively adjust to the different business and daily life norms of the host country; and\n    * Get personalized guidance on navigating daily life in the new environment and managing the cultural adjustment process for each member of the family; and\n        * Receive ongoing, real-time support from your coach - send a note for quick advice anytime between sessions and for up to 3 months post-arrival.\n        * Includes 12-month access to an online cultural profile and information tool.\n\nAlso available as a 1-day in-person program where possible.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Cross-Cultural Program",
          "subProductName": "Coaching Core Support or 1 Day"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 6,
      "exclusions": [
        "OFF-CltrlTrnsSuppBB2Hr-3984675e-5075-4236-8e19-c633c62bb77e",
        "OFF-CltrlTrnsSuppBB4Hr-6851c539-09e3-406e-86d8-d12634e79e17",
        "OFF-CltrlTrnsSuppExtBB-2150353d-b249-4bf4-a757-a25f73d3a04d"
      ],
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-CltrlTrnsSuppExtBB-2150353d-b249-4bf4-a757-a25f73d3a04d",
      "productSpecificationReference": "PRD-CltrlTrnsSupp",
      "displayName": "Cultural Transition -  Extended Support",
      "description": "Work with an experienced international assignment coach and country expert to prepare you (and your accompanying family members) for a smooth transition to your host location.\nWith 9 hours of cultural transition support (6 x 90-minute virtual coaching sessions), tailored to your topics of interest, you will:\n    * Gain insights to know what to expect and how to understand the local cultural values and mindset;\n    * Explore strategies to effectively adjust to the different business and daily life norms of the host country; and\n    * Get personalized guidance on navigating daily life in the new environment and managing the cultural adjustment process for each member of the family; and\n        * Receive ongoing, real-time support from your coach - send a note for quick advice anytime between sessions and for up to 6 months post-arrival.\n        * Includes 12-month access to an online cultural profile and information tool.\n\nAlso available as a 2-day in-person program where possible.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Cross-Cultural Program",
          "subProductName": "Coaching Extended Support or 2 Day"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 10,
      "exclusions": [
        "OFF-CltrlTrnsSuppBB2Hr-3984675e-5075-4236-8e19-c633c62bb77e",
        "OFF-CltrlTrnsSuppBB4Hr-6851c539-09e3-406e-86d8-d12634e79e17",
        "OFF-CltrlTrnsSuppCoreBB-972f6825-6c99-4a11-a7cb-5a5e48ea0b45"
      ],
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-CltrlTrnsSuppYouthBB-95887a79-f9c3-4560-8df9-2fd979828a48",
      "productSpecificationReference": "PRD-CltrlTrnsSupp",
      "displayName": "Youth Cultural Transition - Core Support",
      "description": "For children, adapting and integrating into school and daily life in a new country can be challenging - they must learn new social norms, behaviors,languages, and routines.\n             Contact us to discuss how this program can be tailored for your children (a face-to-face delivery option may be available).\n             Prepare and support your children for a smooth through a program with our highly experienced and engaging youth transition coaches,who will facilitate an interactive and customized program based on your children's ages and interests, \n             allowing them to:  \n            * Learn about the culture, geography, food, games, and language of the new country. \n            * Participate in activities, videos, and open discussion to learn about themselves and the host culture. \n            * Build confidence by learning strategies to manage culture shock, fitting in, and making new friends.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Youth Cross-Cultural Program",
          "subProductName": "Virtual Core Support or  1 Day "
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 4,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-CltrlCoachFollowUpBB-e1e044b9-bdd5-4251-8ac8-774ddfbe98b1",
      "productSpecificationReference": "PRD-CltrlTrnsSupp",
      "displayName": "Cultural Coaching Follow Up Support",
      "description": "Two (2) hours of follow-up coaching which can be added on to any previously completed Cultural Transition Coaching Support program for additional personalized sessions with your Coach.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Cross-Cultural Program",
          "subProductName": "Follow Up Support",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "Ongoing Support",
      "points": 3,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-CltrlTrngBB-2f300454-edc8-4bf6-80b8-57f06ae542d8",
      "productSpecificationReference": "PRD-CltrlTrng",
      "displayName": "Cultural Training - up to 2 days",
      "description": "A cross-cultural training program customized for you and your accompanying family members. This training is designed to help you identify cultural differences between your current and new location.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Cross Cultural Program",
          "subProductName": "1 Day or 2 Day"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 6,
      "rangeMin": 1,
      "rangeMax": 2,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "Days",
      "incremental": true,
      "incrementalTimespan": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-CltrlTrngOnlnBB-27b3e678-f57c-4340-aded-79b32e5e48f7",
      "productSpecificationReference": "PRD-CltrlTrng",
      "displayName": "Online Cultural Tool",
      "description": "An online cultural profile and information tool designed to help you identify cultural differences between your work and communication style and the style typical of your new host country. This tool allows you to:· Discover your personal cultural preferences and compare these to your focus countries· Explore nearly 100 country guides for in-depth information on culture, communication, protocol and business practices· Access your profile and cultural information for 1 year.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Cross-Cultural Program",
          "subProductName": "Online Cultural Tool",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "Before Move",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      },
      "suppress": true
    },
    {
      "reference": "OFF-CndtAssmtCoreBB-5438b335-511c-4bb6-982b-244453020182",
      "productSpecificationReference": "PRD-CndtAssmt",
      "displayName": "Candidate Assessment",
      "description": "You and your spouse/partner can participate in a guided assessment program designed to help you consider the implications of living and working in a new cultural environment and assess your (and your family's) readiness for this specific assignment.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Candidate Assessment",
          "subProductName": "Virtual Assessment",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "Before Move",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-CndtAssmtFlxBB-0148196a-61dd-4fc5-b4d9-afc810caf21e",
      "productSpecificationReference": "PRD-CndtAssmt",
      "displayName": "Candidate Assessment",
      "description": "You and your spouse/partner participate in an assessment program which is designed to help you consider the implications of living and working in a different cultural environment.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Candidate Assessment",
          "subProductName": "Virtual Assessment",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "Before Move",
      "points": 2,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-DestSvcs5DayBB-69205149-c84b-4270-b0dc-2b41f8fb36d6",
      "productSpecificationReference": "PRD-DestSvcsBndl",
      "displayName": "Destination - 5 Day Support",
      "description": "A local professional will customize a program based on your specific needs which may include home finding, school search, lease negotiation and settling in services that do not exceed 5 days of support.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Intl Assign Daily Rate Package 5 Days"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 6,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      },
      "isNewlyAdded": true,
      "isNewlyAddedRef" : true
    },
    {
      "reference": "OFF-DestSvcsLTBndlBB-aa55d0bb-605c-409b-87f7-d40b7fc7a5a2",
      "productSpecificationReference": "PRD-DestSvcsBndl",
      "displayName": "Destination - Host Country Services Bundle",
      "description": "A local professional will accompany you in your new location providing home finding services, assistance with lease negotiations, and settling in services.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Long Term Assign w/o Children Accompanied "
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 6,
      "exclusions": [
        "OFF-DestSvcsBndlBB-97d668e1-ede5-4a2c-b866-4ffedda8cb19",
        "OFF-DestSvcs3DayBndlBB-0290a163-ed65-4a38-87f9-3222e2931df6",
        "OFF-DestSvcs4DayBndlBB-deb47a1f-0b21-47ac-9884-46578e4e5f13",
        "OFF-HmFndngBB-a5563494-843f-4861-9831-eb4427762bcc",
        "OFF-StlngInBB-6b90871e-9526-43c1-a295-dae50aba2f69"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-DestSvcsLTDepBndlBB-ce20ca36-92c5-412b-b0a3-29c68dc95fdf",
      "productSpecificationReference": "PRD-DestSvcsBndl",
      "displayName": "Destination - Host Country Services Bundle",
      "description": "A local professional will accompany you in your new location providing home finding services, assistance with lease negotiations, school searches, and settling in services.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Long Term Assign w/ Children Accompanied "
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 6,
      "exclusions": [
        "OFF-DestSvcsBndlBB-97d668e1-ede5-4a2c-b866-4ffedda8cb19",
        "OFF-DestSvcs3DayBndlBB-0290a163-ed65-4a38-87f9-3222e2931df6",
        "OFF-DestSvcs4DayBndlBB-deb47a1f-0b21-47ac-9884-46578e4e5f13",
        "OFF-HmFndngBB-a5563494-843f-4861-9831-eb4427762bcc",
        "OFF-StlngInBB-6b90871e-9526-43c1-a295-dae50aba2f69"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-DestSvcs3DayBndlBB-0290a163-ed65-4a38-87f9-3222e2931df6",
      "productSpecificationReference": "PRD-DestSvcsBndl",
      "displayName": "Destination - 3 Day Support",
      "description": "A local professional will customize a program based on your specific needs which may include home finding, school search, lease negotiation, and settling in services that does not exceed 3 days of support.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Intl Assign Daily Rate Package 3 Days"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 5,
      "exclusions": [
        "OFF-DestSvcsLTBndlBB-aa55d0bb-605c-409b-87f7-d40b7fc7a5a2",
        "OFF-DestSvcsLTDepBndlBB-ce20ca36-92c5-412b-b0a3-29c68dc95fdf",
        "OFF-DestSvcsBndlBB-97d668e1-ede5-4a2c-b866-4ffedda8cb19",
        "OFF-DestSvcs4DayBndlBB-deb47a1f-0b21-47ac-9884-46578e4e5f13",
        "OFF-HmFndngBB-a5563494-843f-4861-9831-eb4427762bcc"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-DestSvcs4DayBndlBB-deb47a1f-0b21-47ac-9884-46578e4e5f13",
      "productSpecificationReference": "PRD-DestSvcsBndl",
      "displayName": "Destination - 4 Day Support",
      "description": "A local professional will customize a program based on your specific needs which may include home finding, school search, lease negotiation, and settling in services that does not exceed 4 days of support.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Intl Assign Daily Rate Package 4 Days"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 6,
      "exclusions": [
        "OFF-DestSvcsLTBndlBB-aa55d0bb-605c-409b-87f7-d40b7fc7a5a2",
        "OFF-DestSvcsLTDepBndlBB-ce20ca36-92c5-412b-b0a3-29c68dc95fdf",
        "OFF-DestSvcsBndlBB-97d668e1-ede5-4a2c-b866-4ffedda8cb19",
        "OFF-DestSvcs3DayBndlBB-0290a163-ed65-4a38-87f9-3222e2931df6",
        "OFF-HmFndngBB-a5563494-843f-4861-9831-eb4427762bcc"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-DestSvcsBndlBB-97d668e1-ede5-4a2c-b866-4ffedda8cb19",
      "productSpecificationReference": "PRD-DestSvcsBndl",
      "displayName": "Destination - 2 Day Support",
      "description": "A local professional will customize a program based on your specific needs which may include home finding, school search, lease negotiation, and settling in services that does not exceed 2 days of support.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Intl Assign Daily Rate Package 2 Days"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 3,
      "exclusions": [
        "OFF-DestSvcsLTBndlBB-aa55d0bb-605c-409b-87f7-d40b7fc7a5a2",
        "OFF-DestSvcsLTDepBndlBB-ce20ca36-92c5-412b-b0a3-29c68dc95fdf",
        "OFF-DestSvcs3DayBndlBB-0290a163-ed65-4a38-87f9-3222e2931df6",
        "OFF-DestSvcs4DayBndlBB-deb47a1f-0b21-47ac-9884-46578e4e5f13",
        "OFF-HmFndngBB-a5563494-843f-4861-9831-eb4427762bcc"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-DrvrsLcsnsExchBBIntl-63dec211-7005-4e4a-b261-17d8c086c8d6",
      "productSpecificationReference": "PRD-DrvrsLcsnsExch",
      "displayName": "Driver's License Exchange",
      "description": "A local professional arranged by Cartus will provide counseling and accompany you throughout this process.  They will assist you in scheduling lessons and the exams as needed as well as forms completion.",
      "productSubDetails": {},
      "category": "After Arrival",
      "points": 2,
      "scopes": {
        "complex_rules": [],
        "spouse_partner": {
          "id": "6013b7e291f02a2c5c59ea68",
          "spousePartnerRelocating": true
        }
      }
    },
    {
      "reference": "OFF-DrvrsLcsnsExchMultiBBIntl-29abeed1-38b3-429e-bf68-26b18a64da63",
      "productSpecificationReference": "PRD-DrvrsLcsnsExchMulti",
      "displayName": "Driver's License Exchange - Accompanied",
      "description": "A local professional arranged by Cartus will provide counseling and accompany you throughout this process. They will assist you, and your accompanying spouse/partner if selected, in scheduling lessons and the exams as needed as well as forms completion.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Driver's License Exchange (Accompanied)"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 2,
      "scopes": {
        "complex_rules": [],
        "spouse_partner": {
          "id": "5e9e353111b56c4518f00f65",
          "spousePartnerRelocating": true
        }
      }
    },
    {
      "reference": "OFF-DrvrsLicenseReimbBBIntl-b79dac7e-7c3c-4164-95de-586eb9ae9189",
      "productSpecificationReference": "PRD-DrvrsLicenseReimb",
      "displayName": "Driving Lesson/Licensing Support",
      "description": "Reimbursement for costs associated with driving lessons and obtaining your license in your host location for you and/or your spouse/partner.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 3,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-DupHousingBB-e5b7cbeb-9894-4b91-ba52-f1d7c95f0195",
      "productSpecificationReference": "PRD-DupHsng",
      "displayName": "Duplicate Housing",
      "description": "If you close on a new home prior to the sale of your existing home in the departure location, you can elect reimbursement for mortgage interest, taxes, insurance, and other potential expenses outlined by policy for one residence. Each point is USD 500.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Duplicate Housing",
          "subProductName": "",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "Before Move",
      "points": 3,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      },
      "suppress": true
    },
    {
      "reference": "OFF-EdAssistBB-e079e96e-2d46-4fd1-a478-db1f60cd9ac6",
      "productSpecificationReference": "PRD-EdAssist",
      "displayName": "Educational Assistance",
      "description": "Advanced identification and placement for school-aged children. Some options available include: consultation, appointment setting, testing, and navigating the complex differences that exist among educational systems and  curriculums.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Educational Assistance",
          "subProductName": "Educational Assistance"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 7,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-EduSuppBBIntl-cee474cb-0a82-4051-8f1d-5c4078a35849",
      "productSpecificationReference": "PRD-EduSupp",
      "displayName": "Dependent Education Assistance",
      "description": "Reimbursement for education up to USD 30,000 or local currency equivalent per child per year.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Educational Assistance",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-EduSuppReimbBBIntl-e2bdcad5-24d9-4e01-9a2c-59f0580da332",
      "productSpecificationReference": "PRD-EduSupp",
      "displayName": "Dependent Education Support",
      "description": "This reimbursement is intended to cover miscellaneous educational expenses such as uniforms, field trips, and transportation to and from school.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Ongoing Assignment Support or Educational Assistance",
          "subProductName": "Expat"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 6,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-EduSuppBndlBBIntl-611ee5f6-2163-4a61-a3e0-a57b10a08d6d",
      "productSpecificationReference": "PRD-EduSuppBndl",
      "displayName": "Dependent Education Support",
      "description": "This reimbursement is intended to cover miscellaneous educational expenses such as uniforms, field trips, and transportation to and from school.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Ongoing Assignment Support or Educational Assistance",
          "subProductName": "Expat"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 1,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-FinalTripReimbBB-fdb2682a-8c59-4448-b8a4-4d42d52d1668",
      "productSpecificationReference": "PRD-FinalTrip",
      "displayName": "Final Trip to New Location",
      "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Enroute",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Travel & Transport",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 10,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-FnlTrnsprtBB-802915d4-de8d-42f9-903c-61a1aad147ae",
      "productSpecificationReference": "PRD-FnlTrnsprt",
      "displayName": "Final Transportation to New Location",
      "description": "Your final one-way transportation to your new location will be reimbursed. Expenses typically include hotel, flights or mileage, and meals at departure and arrival locations for you and your accompanying members.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "EnRoute",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "EnRoute",
          "subProductName": ""
        }
      },
      "category": "Travel & Transport",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-FrnRntlBBIntl-52234bd0-cd18-4d58-b5d7-3cf12cae45b7",
      "productSpecificationReference": "PRD-FrnRntl",
      "displayName": "Furniture Rental",
      "description": "This benefit covers 30 days of rental of furniture allowing you to move into you new home while you wait for you furniture to arrive. You can submit for reimbursement up to your selected number of months.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Furniture Services",
          "subProductName": "Rental"
        },
        "usDomesticProduct": {
          "productName": "Furniture Services",
          "subProductName": "Rental"
        }
      },
      "category": "After Arrival",
      "points": 4,
      "rangeMin": 1,
      "rangeMax": 3,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "Months",
      "incremental": true,
      "incrementalTimespan": true,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-GoWiderGoFarBB-bb90b29a-1633-4628-be6a-c256d9023a81",
      "productSpecificationReference": "PRD-GoWiderGoFar",
      "displayName": "Go Wider - Go Farther",
      "description": "Your consultant will review your approved program with you.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Authorizations Client Specific Matrix/Consultant Advice",
          "subProductName": "",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "Before Move",
      "points": 0,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-HHGIntlAirBBUnacmp-INS-10bac9ba-b7e7-4d4f-829e-60e5203ccbe2",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Air Shipment - Unaccompanied / Cartus Insured",
      "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size. If you are single or moving unaccompanied, you are authorized for a \"D\" container.",
      "productSubDetails": {},
      "category": "Shipping",
      "points": 4,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          }
        ],
        "spouse_partner": {
          "id": "6013b7e291f02a2c5c59ea68",
          "spousePartnerRelocating": true
        }
      }
    },
    {
      "reference": "OFF-HHGIntlAirBBUnacmp-fdccb426-806c-408e-99fe-67250394ff40",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Air Shipment - Unaccompanied",
      "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size. If you are single or moving unaccompanied, you are authorized for a \"D\" container.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 4,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          }
        ],
        "spouse_partner": {
          "id": "6013b7e291f02a2c5c59ea68",
          "spousePartnerRelocating": true
        }
      }
    },
    {
      "reference": "OFF-HHGIntlAirBBAcmp-4f7f40e2-35e1-4d59-8afa-21081a59b18c",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Air Shipment - Accompanied",
      "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size. If you are moving with family members you are authorized for an \"LDN\" container.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 6,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          },
          {
            "id": "602291781e64fd73f4f4f0fd",
            "name": "Accompanied",
            "description": "For transferees that are accompanied by a spouse or partner and/or dependents"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlAirBBAcmp-INS-7d17d626-6cdf-4dcd-8087-3b81b6fd605d",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Air Shipment - Accompanied / Cartus Insured",
      "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size. If you are moving with family members you are authorized for an \"LDN\" container.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 6,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          },
          {
            "id": "602291781e64fd73f4f4f0fd",
            "name": "Accompanied",
            "description": "For transferees that are accompanied by a spouse or partner and/or dependents"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlFlxBB-INS-0ba2bff7-30be-4851-ad77-5dcf9cc5a700",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Household Goods - Ground Shipment / Cartus Insured",
      "description": "Cartus will engage a professional moving company and will coordinate the shipment of your household goods and personal effects from your old location to your new location.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "Moving Services",
          "subProductName": "US Household Goods"
        }
      },
      "category": "Shipping",
      "points": 20,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114a",
            "name": "Intra-country",
            "description": "Moves where the departure country and destination country match"
          },
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlFlxBB-cedf8414-aaa9-4777-a33e-1021232ba79b",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Household Goods - Ground Shipment",
      "description": "Cartus will engage a professional moving company and will coordinate the shipment of your household goods and personal effects from your old location to your new location.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 20,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114a",
            "name": "Intra-country",
            "description": "Moves where the departure country and destination country match"
          },
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlAirBB-INS-062d8f45-a8b8-423f-8cb6-96b48e528dd2",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Air Shipment / Cartus Insured",
      "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 10,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlAirBB-b1baa567-7e20-4506-a16d-9bc53991e8a6",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Air Shipment",
      "description": "Cartus will engage a freight forwarder to pack and load personal items you will need immediately in your new location. The container sizes will be determined by your unaccompanied status or family size.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 10,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlCoreBB-INS-a9a0263e-75e8-4b1c-b78b-3f00a537d3e3",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Household Goods Shipment / Cartus Insured",
      "description": "Cartus will engage a freight forwarder to pack and load your personal belongings to ship via air and surface as needed. Your consultant will review the program specifics with you.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlCoreBB-f7b1cd39-adfe-4f34-8b4f-5778f593a258",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Household Goods Shipment",
      "description": "Cartus will engage a freight forwarder to pack and load your personal belongings to ship via air and surface as needed. Your consultant will review the program specifics with you.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlSea20BB-7bd97888-6a83-4839-b94b-712a41c85d9c",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Sea Shipment - 20 ft (6.06m) - Container",
      "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 20,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlSea20BB-INS-4b45d62b-75af-4c6b-a9c8-c3d0c08973cb",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Sea Shipment - 20 ft (6.06m) - Container / Cartus Insured",
      "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 20,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlSea40BB-835de02d-4f82-4189-bf81-7ce9d81f9424",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Sea Shipment - 40 ft (12.2m) - Container",
      "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 30,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlSea40BB-INS-762489dc-f587-4110-980c-d3ce3f2c2cda",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Sea Shipment - 40 ft (12.2m) - Container / Cartus Insured",
      "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 30,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlSeaBB-INS-1329c87e-54a9-41ae-9e12-6eaeeae40537",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Sea Shipment / Cartus Insured",
      "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 30,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          }
        ]
      }
    },
    {
      "reference": "OFF-HHGIntlSeaBB-e426eb19-4639-4831-a396-87a1aa6e3257",
      "productSpecificationReference": "PRD-HHG",
      "displayName": "Sea Shipment",
      "description": "Cartus will engage a freight forwarder to pack and load your personal effects. The maximum shipping limits whether surface or sea will be based on your unaccompanied status or family size. Storage in transit is also provided for up to 30 days if required.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 30,
      "scopes": {
        "complex_rules": [
          {
            "id": "5f15edcc671145697c1d114b",
            "name": "Inter-country",
            "description": "Moves where the departure country and destination country do not match"
          }
        ]
      }
    },
    {
      "reference": "OFF-HmFndngBB-BBCloned-1c717701-a4f0-4012-8dd2-53d2faed7533",
      "productSpecificationReference": "PRD-HmFndng",
      "displayName": "Homefinding 6",
      "description": "A local professional will accompanying you on a 2 day home finding tour and show you multiple properties as well as assist you in securing a lease.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "1 Day Homefinding - Executive",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "Homefinding",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "Before Move",
      "points": 12,
      "exclusions": [
        "OFF-HmFndngBB-a5563494-843f-4861-9831-eb4427762bcc"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-HmFndngBB-a5563494-843f-4861-9831-eb4427762bcc",
      "productSpecificationReference": "PRD-HmFndng",
      "displayName": "Homefinding",
      "description": "A local professional will accompanying you on a 2 day home finding tour and show you multiple properties as well as assist you in securing a lease.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Home Finding - Accompanied",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "Homefinding",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "Before Move",
      "points": 2,
      "exclusions": [
        "OFF-HmFndngBB-BBCloned-1c717701-a4f0-4012-8dd2-53d2faed7533"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-HmSlReimbIntlBB-529875b0-fb9b-4742-9e66-3a51cadd6254",
      "productSpecificationReference": "PRD-HmSlReimb",
      "displayName": "Home Sale Reimbursement",
      "description": "Upon the closing of your home in the departure location you will be reimbursed for normal and customary seller's closing costs.  Each point is USD 500. ",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Dept Home Reimbursement",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 15,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-HomeLeaveExchBBIntl-ff74fae8-443e-488d-a453-981747403a0d",
      "productSpecificationReference": "PRD-AirfareReimb",
      "displayName": "Home Leave Exchange",
      "description": "In lieu of your trip home, the company will allow you to trade you or your accompanying members home leave reimbursement to allow family members to visit you in your assignment location.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Home Leave",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HomeLeaveReimbBBIntl-440b0a17-d2cd-450f-9023-e97870151cc9",
      "productSpecificationReference": "PRD-AirfareReimb",
      "displayName": "Home Leave Reimbursement",
      "description": "The Company covers home leave transportation expenses for all family members on assignment.  All other transportation, car rental, meals, housing, and other incidentals will not be reimbursed and will be at your expense. The class of airfare is based on your Company's Travel and Expense Policy.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Home Leave",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-NonAccompDepTrvlBBIntl-4aec5835-7eb6-48b4-8450-7a469d7d45b5",
      "productSpecificationReference": "PRD-AirfareReimb",
      "displayName": "Dependent Travel - Non-accompanying Children",
      "description": "You will be reimbursed for one round-trip ticket annually via economy class air travel from the home to the host location for each eligible dependent child.   Those eligible include dependent children attending primary or secondary school in the home country or a third country or unmarried, dependent children under attending university as full-time.  Your Cartus consultant will provide your Company's eligibility age requirements for this benefit.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "EnRoute",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-NonAccompDepTrvlReimbBBIntl-758a52f2-2ee3-4d3f-bcb5-77378fad389c",
      "productSpecificationReference": "PRD-AirfareReimb",
      "displayName": "Dependent Travel - Non-accompanying Children",
      "description": "Reimbursement to offset travel costs for non-accompanying dependents.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Non-Accompanying Dependent Visit",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 4,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-HomePurchaseReimbursementBB-a732ff63-38fe-401e-a108-c3eedfa7cb17",
      "productSpecificationReference": "PRD-HomePurchaseReimbursement",
      "displayName": "Home Purchase Reimbursement",
      "description": "Reimbursement assistance covering normal and customary closing costs.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "New Home Purchase",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "New Home Purchase Costs",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 3,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-ImmgrtnSvcsBB-76bd291e-ca90-42b8-ab14-57c867c24b83",
      "productSpecificationReference": "PRD-ImmgrtnSvcs",
      "displayName": "Immigration Services",
      "description": "A professional immigration provider will assist you in securing the required immigration documents for you and your eligible accompanying family members.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Immigration Services",
          "subProductName": "Management"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-LngTrngBBIntlHybrdDep-7cf2ed63-de6f-40b5-bfba-0a6da5da9ce1",
      "productSpecificationReference": "PRD-LngTrng",
      "displayName": "Language Training - Children",
      "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language. Starting level assessment conducted by the coach. Flexible digital scheduling; interchange in-person lessons at home or office with virtual lessons as needed. Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location. Industry-leading materials are custom-selected per learner. Live practice in the community. Youth lessons build local language skill and offer critical homework support. (Children may be eligible if not covered in school curriculum already.) Test prep for immigration requirements available.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Youth Language Training",
          "subProductName": "One-to-One On-going / Group On-going"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 3,
      "rangeMin": 20,
      "rangeMax": 100,
      "rangeIncrementValue": 20,
      "rangeIncrementUnit": "Hours",
      "incremental": true,
      "incrementalTimespan": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-LngTrngBBIntlHybrdSpouse-cbeba1fb-1b6a-4f31-8d31-908bb82a6bb3",
      "productSpecificationReference": "PRD-LngTrng",
      "displayName": "Language Training - Spouse",
      "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language. Flexible digital scheduling; interchange in-person lessons at home or office with virtual lessons as needed. Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location. Industry-leading materials are custom-selected per learner. Live practice in the community. Test prep for immigration requirements available.",
      "productSubDetails": {},
      "category": "Ongoing Support",
      "points": 3,
      "rangeMin": 20,
      "rangeMax": 100,
      "rangeIncrementValue": 20,
      "rangeIncrementUnit": "Hours",
      "incremental": true,
      "incrementalTimespan": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ],
        "spouse_partner": {
          "id": "5e9e353111b56c4518f00f65",
          "spousePartnerRelocating": true
        }
      }
    },
    {
      "reference": "OFF-LangTrainSpouseBBIntl-ae0533a5-9327-499a-8d07-5c5f60cfe569",
      "productSpecificationReference": "PRD-LngTrng",
      "displayName": "Language Training - Spouse",
      "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language.Flexible digital scheduling; interchange in-person lessons at home or office with virtual lessons as needed. Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location. Industry-leading materials are custom-selected per learner.Live practice in the community. Test prep for immigration requirements available.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Language Training",
          "subProductName": "Group On-going / One-to-One On-going"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-LngTrngBB-52560a01-a15b-4e3d-b8d5-e0cae8948540",
      "productSpecificationReference": "PRD-LngTrng",
      "displayName": "Language Training: 20 Hours",
      "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language. Lessons are offered as individual or family group (you and your spouse/partner).   Starting level assessment conducted by the coach.   Flexible digital scheduling interchange in-person lessons at home or office with virtual lessons as needed.  Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location.   Industry-leading materials are custom-selected per learner.  Live practice in the community.  Youth lessons build local language skill and offer critical homework support. (Children may be eligible if not covered in school curriculum already.)  Test prep for immigration requirements available.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "International Language Training",
          "subProductName": "Group On-going / One-to-One On-going"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 4,
      "rangeMin": 20,
      "rangeMax": 100,
      "rangeIncrementValue": 20,
      "rangeIncrementUnit": "Hours",
      "incremental": true,
      "incrementalTimespan": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-LngTrngBB40Hr-198306fa-316a-4f18-a8b9-6b6d2551fb3a",
      "productSpecificationReference": "PRD-LngTrng",
      "displayName": "Language Training: 40 Hours",
      "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language. Lessons are offered as individual or family group (you and your spouse/partner).\n    * Starting level assessment conducted by the coach.\n    * Flexible digital scheduling; interchange in-person lessons at home or office with virtual lessons as needed.\n    * Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location.\n    * Industry-leading materials are custom-selected per learner.\n    ** Live practice in the community.\n    ** Youth lessons build local language skills and offer critical homework support. (Children may be eligible if not covered in the school curriculum already.)\n    ** Test prep for immigration requirements available.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Language Training",
          "subProductName": "One-to-one On-going or Group On-going"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 5,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-LngTrngBBIntlHybrd-8fadd14c-c60f-4150-954f-c5b8fe673cb3",
      "productSpecificationReference": "PRD-LngTrng",
      "displayName": "Language Training",
      "description": "Comprehensive language skill development for all languages, all levels. Learners are matched with a language coach with the background to address specific linguistic requirements in your target language. Flexible digital scheduling; interchange in-person lessons at home or office with virtual lessons as needed. Coaches build language skills and act as ongoing mentors for daily- and work-life adjustment to the new location. Industry-leading materials are custom-selected per learner. Live practice in the community. Test prep for immigration requirements available.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Language Training",
          "subProductName": "Group On-going / One-to-One On-going"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 3,
      "rangeMin": 20,
      "rangeMax": 100,
      "rangeIncrementValue": 20,
      "rangeIncrementUnit": "Hours",
      "incremental": true,
      "incrementalTimespan": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-LngTrngOnlnBB-e3bd7693-7af7-4167-8bca-81d0037d8f18",
      "productSpecificationReference": "PRD-LngTrng",
      "displayName": "Language Training - Online Tool",
      "description": "Self-paced online language training tool for your and your accompanying members.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Language Training",
          "subProductName": "Self-Paced"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 2,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-LsNegtnBB-a4115935-7c51-4cc0-8bae-8ac3f1a5d6fd",
      "productSpecificationReference": "PRD-LsNegtn",
      "displayName": "Lease Negotiation",
      "description": "Cartus will coordinate assigning local professional to assist in securing and negotiating a lease in your new location.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Lease Coord and Negotiation"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 4,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-LsTermBB-31a7c756-cb98-4297-8e21-2d7c91c8e3b3",
      "productSpecificationReference": "PRD-LsTermBndl",
      "displayName": "Lease Termination",
      "description": "Lease termination is designed to offset costs you may incur for early termination as outlined in your current lease. You will receive reimbursement for reasonable eligible lease cancellation expenses. Select the value of reimbursement.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Lease Cancellation Reimb",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Lease Cancellation Reimb",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": 4,
      "multiplicity": true,
      "maxSelection": 2,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-LsTermBndl-67d07a0c-e7e6-4da4-adfc-dddcc49ad4c4",
      "productSpecificationReference": "PRD-LsTerm",
      "displayName": "Lease Cancellation",
      "description": "Lease termination is designed to offset costs you may incur for early termination of your current lease in the event of a lease breakage and stipulated in the lease agreement.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Lease Cancellation Reimb",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 10,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-LsTermIntlCoreBB-1810ca7a-9955-434f-97e2-6b195d93117d",
      "productSpecificationReference": "PRD-LsTerm",
      "displayName": "Lease Cancellation",
      "description": "Lease termination is designed to offset costs you may incur for early termination of your current lease in the event of a lease breakage and stipulated in the lease agreement.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Lease Cancellation ",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-OngoingAllowBBIntl-bb831a4f-cca2-4ae0-b990-f4f811e50fc5",
      "productSpecificationReference": "PRD-OngoingAllow",
      "displayName": "Expatriate Premium",
      "description": "Your client sponsor recognizes that you are making a significant move both personally and professionally. Therefore, in recognition of the move, your client sponsor will provide an expatriate premium budget to you throughout the duration of the assignment (initiated once the temporary living period has ended). Your consultant will provide the calculation of the premium.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-PetTrnsprtBndlBB-ae1b73c5-d355-4a04-983e-b97c18a8bb9e",
      "productSpecificationReference": "PRD-PetTrnsprtBndl",
      "displayName": "Pet Transportation Service - Total 2 Pets",
      "description": "Cartus will arrange for professional pet carrier services to transport one standard domestic household pet to the destination location.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 4,
      "multiplicity": true,
      "maxSelection": 2,
      "exclusions": [
        "OFF-PetTrnsprtReimbBB-da24271d-09d8-40cb-99d3-a918dd1ab540"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-PlcyCnslIntlBB-40479dd4-49d4-4daa-823e-fb51ce071d66",
      "productSpecificationReference": "PRD-PlcyCnsl",
      "displayName": "Policy Counseling and Administration",
      "description": "Cartus will assign a consultant that will support you and provide guidance regarding your approved benefits for your upcoming move.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Intl Policy Counseling",
          "subProductName": "Expat - Long Term / One Way Move"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-PreDcsnOrntn1DayBB-e512a449-17f7-4a79-88e7-ae85f7ba4b90",
      "productSpecificationReference": "PRD-PreDcsnOrntn",
      "displayName": "Pre-Decision Orientation - 1 Day",
      "description": "A local professional coordinated by Cartus will accompanying you on a tour of your destination location and provide information about local amenities.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Pre Decision Orientation - 1 Day"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": 2,
      "exclusions": [
        "OFF-PreDcsnOrntnBB-33216196-d362-4a31-b853-3b33c6d3e7d7",
        "OFF-PreDcsnOrntn2DayBB-796b30b9-cf67-405f-b5b4-27c94746a5ee"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-PreDcsnOrntn2DayBB-796b30b9-cf67-405f-b5b4-27c94746a5ee",
      "productSpecificationReference": "PRD-PreDcsnOrntn",
      "displayName": "Pre-Decision Orientation - 2 Day",
      "description": "A local professional coordinated by Cartus will accompanying you on a tour of your destination location, show you some representative properties, and provide information about local amenities.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Pre Decision Orientation - 2 Day"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": 3,
      "exclusions": [
        "OFF-PreDcsnOrntnBB-33216196-d362-4a31-b853-3b33c6d3e7d7",
        "OFF-PreDcsnOrntn1DayBB-e512a449-17f7-4a79-88e7-ae85f7ba4b90"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-PreDcsnOrntnBB-33216196-d362-4a31-b853-3b33c6d3e7d7",
      "productSpecificationReference": "PRD-PreDcsnOrntn",
      "displayName": "Pre-Decision Orientation",
      "description": "A local professional arranged by Cartus will take you on an area tour and show you 3 to 5 representative properties.  If you are interested, they will provide information on local schools.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Predecision Orientation"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": 3,
      "exclusions": [
        "OFF-PreDcsnOrntn1DayBB-e512a449-17f7-4a79-88e7-ae85f7ba4b90",
        "OFF-PreDcsnOrntn2DayBB-796b30b9-cf67-405f-b5b4-27c94746a5ee"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-PropMaintBBIntl-e3680f44-8231-487c-b57f-0743ddffaa88",
      "productSpecificationReference": "PRD-PropMaint",
      "displayName": "Property Maintenance",
      "description": "You will be reimbursed for the expenses relating to property maintenance support for your residence in the home location. Examples of potential expenses: lawn care, utilities, and insurance.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Property Management International",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Ongoing Support",
      "points": 4,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-PropMgmtBB-b4f6c285-fed6-4be5-9b86-05ddc04922a6",
      "productSpecificationReference": "PRD-PropMgmt",
      "displayName": "Property Management",
      "description": "Cartus will coordinate managing your home country property including managing tenant occupancy.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Property Management (Domestic/International)",
          "subProductName": "Occupied Home Program"
        },
        "usDomesticProduct": {
          "productName": "Property Management (Domestic/International)",
          "subProductName": "Occupied Home Program"
        }
      },
      "category": "Ongoing Support",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-PropMgmtBBIntl-5196aa2d-7cca-4799-aa08-68f84280b156",
      "productSpecificationReference": "PRD-PropMgmt",
      "displayName": "Local Property Management",
      "description": "A local professional arranged by Cartus will assist in resolving issues which include the coordination of repairs for you, with your landlord, and with local contractors.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Local Property Support"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 2,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-PrvwTrpBB-75586f26-589d-4306-9254-d39dbc433578",
      "productSpecificationReference": "PRD-PrvwTrp",
      "displayName": "Pre-Assignment or Pre-Transfer Trip",
      "description": "The pre-assignment/pre-transfer trip is designed to assist you in setting realistic expectations about the host/transfer location. This benefit covers the cost of flights, hotel, local transportation and meals for you and eligible accompanying family members.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Familiarization Trip/Look-See Trip",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": 4,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-PrvwTrpReimbBB-6a3324cf-b5f2-45cf-aa82-090c8f196a35",
      "productSpecificationReference": "PRD-PrvwTrp",
      "displayName": "Pre-Transfer Trip",
      "description": "This trip allows you to become familiar with your new location. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile, hotel, meals and rental car if needed for you and an eligible accompanying family member.  You will be reimbursed for the amount selected.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Look See/Familiarization",
          "subProductName": "",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "Before Move",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 10,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-RAndRBBIntl-ef52389c-bc62-4604-8526-6101089aa804",
      "productSpecificationReference": "PRD-RAndR",
      "displayName": "Rest & Recuperation",
      "description": "For assignment in locations defined as extreme hardship locations by the company’s data provider, additional trips away from the host location may be provided on a periodic basis to a company-designated location. Duration and frequency of trips are pre-set by the data provider and the company will cover the cost of round trip airfare according to the company’s travel policy, accommodations, and ground transportation.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Rest & Relaxation Trip, Intl Comp Services, Ongoing Assignment Support",
          "subProductName": "Rest & Relaxation Trip"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-ReloAcctngIntlBB-e5ce4d71-2a9b-495f-a70b-99669a763d63",
      "productSpecificationReference": "PRD-ReloAcctng",
      "displayName": "Expense Management",
      "description": "Cartus will reimburse you for expenses that were identified as core benefits as well as those you selected in the flex choice category in accordance with your company's relocation policy and selected limits.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Relocation Accounting",
          "subProductName": "Expense Processing"
        },
        "usDomesticProduct": {
          "productName": "Relocation Accounting",
          "subProductName": "Expense Processing"
        }
      },
      "category": "Before Move",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-RentInLieuBB-0298e842-b867-46e6-bdc7-ebd325e8d2ca",
      "productSpecificationReference": "PRD-RentInLieu",
      "displayName": "Rent in Lieu",
      "description": "Rent in lieu of corporate housing. If you do not need the full 60 days of corporate housing, you may receive this in cash in 30 day increments.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Temporary Living",
          "subProductName": "International Rent in Lieu",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": "",
          "requiresRevision": false
        }
      },
      "category": "After Arrival",
      "points": 0,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-ReturnTrpReimbBB-ffdd223f-9149-4a32-9b0f-1cdeb4b3ca87",
      "productSpecificationReference": "PRD-ReturnTrp",
      "displayName": "Return Trip Home Reimbursement",
      "description": "You will be reimbursed for travel expenses to visit your family while you are in temporary living. This benefit covers the cost of airfare or reimbursement of mileage if driving your personal automobile.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Return Trip Home",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Return Trip Home",
          "subProductName": ""
        }
      },
      "category": "Travel & Transport",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 5,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-SchlFndgEdCmplBBIntl-e7513b1e-c0ca-415d-b593-d865f9f2f605",
      "productSpecificationReference": "PRD-SchlFndg",
      "displayName": "Education Complete",
      "description": "A local professional coordinated by Cartus will provide you with information and options for schools, schedule visits, assist with the enrollment process and if needed, will assist in retrieving a school deposit if refundable (if/when an application falls through).",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Education Complete"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 4,
      "exclusions": [
        "OFF-DestSvcsBndlBB-97d668e1-ede5-4a2c-b866-4ffedda8cb19",
        "OFF-DestSvcs3DayBndlBB-0290a163-ed65-4a38-87f9-3222e2931df6",
        "OFF-DestSvcs4DayBndlBB-deb47a1f-0b21-47ac-9884-46578e4e5f13"
      ],
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-SchlFndgEdCmplBBIntl1Day-5e03d4ee-2519-49cf-9d0c-5b0c8aeb7cfa",
      "productSpecificationReference": "PRD-SchlFndg",
      "displayName": "Education Complete - 1 Day",
      "description": "A local professional will provide you with information and options for schools.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Education Complete - 1 day"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 2,
      "exclusions": [
        "OFF-DestSvcsBndlBB-97d668e1-ede5-4a2c-b866-4ffedda8cb19",
        "OFF-DestSvcs3DayBndlBB-0290a163-ed65-4a38-87f9-3222e2931df6",
        "OFF-DestSvcs4DayBndlBB-deb47a1f-0b21-47ac-9884-46578e4e5f13"
      ],
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-SchlFndgEdCmplBBIntl2Day-2ee26c50-1825-41f8-866b-29c490837ab9",
      "productSpecificationReference": "PRD-SchlFndg",
      "displayName": "Education Complete - 2 Day",
      "description": "A local professional arranged by Cartus will provide you with options for schools and schedule a visit as necessary. Depending on your need they will assist with the enrollment process.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Education Complete - 2 day"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 3,
      "exclusions": [
        "OFF-DestSvcsBndlBB-97d668e1-ede5-4a2c-b866-4ffedda8cb19",
        "OFF-DestSvcs3DayBndlBB-0290a163-ed65-4a38-87f9-3222e2931df6",
        "OFF-DestSvcs4DayBndlBB-deb47a1f-0b21-47ac-9884-46578e4e5f13"
      ],
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-SchlFndgBB-6a4f228c-30c0-424a-a60b-3ef325312951",
      "productSpecificationReference": "PRD-SchlFndg",
      "displayName": "School Search",
      "description": "A local professional will provide you information on local schools, assist in setting up meetings and accompany you on a tour of the schools.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Education Complete"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 2,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-SlfStrgReimbBBIntl-7dc7964f-5f6f-4727-8fb1-fae4b3d2214f",
      "productSpecificationReference": "PRD-SlfStrg",
      "displayName": "Self Storage Reimbursement",
      "description": "You can be reimbursed for storage at your home location.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Compensation",
          "subProductName": "Ongoing Phase"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 3,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-SpouseCareerSuppIntlBB-a9d04130-45e8-42fb-89aa-55240481e914",
      "productSpecificationReference": "PRD-SpouseCareerSupp",
      "displayName": "Spouse/Partner Career Support",
      "description": "Cartus will arrange for third party assistance so your spouse/partner establishes connections in the new community as a result of the relocation.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Career Transition Assistance",
          "subProductName": "Spouse Counselling"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 4,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ],
        "spouse_partner": {
          "id": "5e9e353111b56c4518f00f65",
          "spousePartnerRelocating": true
        }
      }
    },
    {
      "reference": "OFF-StlngInBB-6b90871e-9526-43c1-a295-dae50aba2f69",
      "productSpecificationReference": "PRD-StlngIn",
      "displayName": "Settling In",
      "description": "A local professional will provide you with an overview of neighborhoods and housing, shopping, transportation systems, medical services and other local amenities.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Destination Services",
          "subProductName": "Settling In Complete"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 2,
      "exclusions": [
        "OFF-DestSvcsLTBndlBB-aa55d0bb-605c-409b-87f7-d40b7fc7a5a2",
        "OFF-DestSvcsLTDepBndlBB-ce20ca36-92c5-412b-b0a3-29c68dc95fdf",
        "OFF-DestSvcsBndlBB-97d668e1-ede5-4a2c-b866-4ffedda8cb19",
        "OFF-DestSvcs3DayBndlBB-0290a163-ed65-4a38-87f9-3222e2931df6",
        "OFF-DestSvcs4DayBndlBB-deb47a1f-0b21-47ac-9884-46578e4e5f13"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-StrgInTrnst180BB-4f02166c-b05e-4b16-9cf0-da69e65637a2",
      "productSpecificationReference": "PRD-StrgInTrnst",
      "displayName": "Storage in Transit - Up To 180 Days",
      "description": "You may elect up to 180 days of storage with your professional moving company. Your Cartus consultant is available to assist with this request.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "Moving Services",
          "subProductName": "US Household Goods"
        }
      },
      "category": "Shipping",
      "points": 1,
      "rangeMin": 10,
      "rangeMax": 180,
      "rangeIncrementValue": 10,
      "rangeIncrementUnit": "Days",
      "incremental": true,
      "incrementalTimespan": true,
      "prerequisites": [
        "OFF-HHGIntlCoreBB-f7b1cd39-adfe-4f34-8b4f-5778f593a258",
        "OFF-HHGIntlAirBB-b1baa567-7e20-4506-a16d-9bc53991e8a6",
        "OFF-HHGIntlSeaBB-e426eb19-4639-4831-a396-87a1aa6e3257",
        "OFF-HHGIntlSea20BB-7bd97888-6a83-4839-b94b-712a41c85d9c",
        "OFF-HHGIntlSea40BB-835de02d-4f82-4189-bf81-7ce9d81f9424",
        "OFF-HHGIntlFlxBB-cedf8414-aaa9-4777-a33e-1021232ba79b"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-StrgInTrnstBBIntl30-f3adae58-110c-4fdd-b9ed-5bf3e854b6ff",
      "productSpecificationReference": "PRD-StrgInTrnst",
      "displayName": "Storage in Transit - 30 Days",
      "description": "Cartus will arrange for a professional freight forwarder to store your personal belongings for 30 days.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "Moving Services",
          "subProductName": "US Household Goods"
        }
      },
      "category": "Shipping",
      "points": 3,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-StrgInTrnstBBIntl3160-990bab5d-e54f-4443-baa2-d1fbdcb7c97f",
      "productSpecificationReference": "PRD-StrgInTrnst",
      "displayName": "Additional Storage in Transit - 31 to 60 Days",
      "description": "You may select an additional 30 days of storage following your initial selection of 30 days.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 3,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-StrgInTrnstBBIntl6190-aa0236c2-989f-4758-b4ae-cacce7936cf4",
      "productSpecificationReference": "PRD-StrgInTrnst",
      "displayName": "Additional Storage in Transit - 61 to 90 Days",
      "description": "You may select an additional 30 days of storage if you need to extend your storage beyond the 60 days selected.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 3,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-StrgInTrnstGlblBB-e206f85a-81a6-4e05-9b45-d17345262c48",
      "productSpecificationReference": "PRD-StrgInTrnst",
      "displayName": "Storage In Transit",
      "description": "If you elected the professional household good move through Cartus and in the event you are not able to move your household goods directly to your new residence, Cartus will arrange temporary storage using the points you elect to allocate for this service.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods",
          "requiresRevision": false
        },
        "usDomesticProduct": {
          "productName": "Moving Services",
          "subProductName": "US Household Goods",
          "requiresRevision": false
        }
      },
      "category": "Shipping",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 10,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-TaxBrfngBB-69d35f6d-34e8-4147-8319-d01e3dbac23a",
      "productSpecificationReference": "PRD-TaxCnslt",
      "displayName": "Tax Briefing",
      "description": "Your Company's international tax provider will review and outline the potential tax implications relating to your assignment or transfer.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Tax Consultation",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Tax Consultation",
          "subProductName": ""
        }
      },
      "category": "Before Move",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-VIPTaxBBIntl-e4e75602-c8aa-45eb-a441-9d7bc69ee91e",
      "productSpecificationReference": "PRD-TaxCnslt",
      "displayName": "VIP Tax Service",
      "description": "Your new Business Unit has approved you for VIP tax service.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Tax Consultation",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 0,
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-TaxPrepBB-3db9869b-061f-407e-83e8-c1c583790842",
      "productSpecificationReference": "PRD-TaxPrep",
      "displayName": "Tax Preparation",
      "description": "Your company has arranged for a third party international tax provider to provide tax preparation services for a period of time based on your assignment or transfer.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Tax Preparation",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Tax Preparation",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-TmpHsngCrIntlBB-ee2d10cd-dcc8-4584-b982-dec6768d0f8f",
      "productSpecificationReference": "PRD-TmpHsng",
      "displayName": "Temporary Housing",
      "description": "You will receive 45 days of temporary housing at your new location. Your Cartus consultant will assist in securing accommodations, arrange, whenever possible, for direct billing to Cartus on your behalf, and advise you of other reimbursable expenses.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Temporary Living",
          "subProductName": "International"
        },
        "usDomesticProduct": {
          "productName": "Temporary Living",
          "subProductName": "US Domestic"
        }
      },
      "category": "After Arrival",
      "points": "Guaranteed",
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-TmpHsngFlxIntlBB-f865dcc0-bb7c-45fc-964f-39dae4be0bd7",
      "productSpecificationReference": "PRD-TmpHsng",
      "displayName": "Temporary Housing",
      "description": "This benefit covers temporary housing at your new location in single-month increments. Your Cartus consultant will assist in securing accommodations, arrange, whenever possible, for direct billing to Cartus on your behalf, and advise you of other reimbursable expenses. You can submit up to 3 months for reimbursement, at 8 points per month.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Temporary Living",
          "subProductName": "International"
        },
        "usDomesticProduct": {
          "productName": "Temporary Living",
          "subProductName": "US Domestic"
        }
      },
      "category": "After Arrival",
      "points": 8,
      "rangeMin": 1,
      "rangeMax": 3,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "Months",
      "incremental": true,
      "incrementalTimespan": true,
      "exclusions": [
        "OFF-TmpHsngReimbIntlBB-6a49553c-3df9-48d6-b229-cf6ab5cf85ad"
      ],
      "scopes": {
        "complex_rules": [
          {
            "id": "601bbca4da353fac12ee90db",
            "name": "International",
            "description": "US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada"
          }
        ]
      }
    },
    {
      "reference": "OFF-TmpHsngAddDaysBBIntlHybrd-d3a0f476-9be1-4601-84e6-c51d2ab94934",
      "productSpecificationReference": "PRD-TmpHsng",
      "displayName": "Temporary Living Additional Days",
      "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location.  Your Cartus consultant can provide additional insight as to what this benefit covers.",
      "productSubDetails": {},
      "category": "After Arrival",
      "points": 4,
      "rangeMin": 15,
      "rangeMax": 120,
      "rangeIncrementValue": 15,
      "rangeIncrementUnit": "Days",
      "incremental": true,
      "incrementalTimespan": true,
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-TmpHsngReimbIntlBB-6a49553c-3df9-48d6-b229-cf6ab5cf85ad",
      "productSpecificationReference": "PRD-TmpHsng",
      "displayName": "Temporary Housing - Reimbursement",
      "description": "This benefit covers the cost of temporary housing at your new location. Please refer to your company's policy guidelines for covered expenses. You can submit for reimbursement up to your selected point value. One point is USD 500.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Temporary Living",
          "subProductName": ""
        },
        "usDomesticProduct": {
          "productName": "Temporary Living",
          "subProductName": ""
        }
      },
      "category": "After Arrival",
      "points": 1,
      "rangeMin": 1,
      "rangeMax": 12,
      "rangeIncrementValue": 1,
      "rangeIncrementUnit": "USD",
      "perPointCurrency": 500,
      "incrementalCurrency": true,
      "exclusions": [
        "OFF-TmpHsngFlxIntlBB-f865dcc0-bb7c-45fc-964f-39dae4be0bd7"
      ],
      "scopes": {
        "complex_rules": []
      }
    },
    {
      "reference": "OFF-TravelCoordBB-42f746d1-e47e-4bb5-be0b-36610a19ac0d",
      "productSpecificationReference": "PRD-TravelCoord",
      "displayName": "Travel Coordination",
      "description": "Cartus will assist you with booking air travel for up to any relocation trips (home finding, en route, return trip) as outlined in policy.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Mobility CardTravel Services",
          "subProductName": "Credit"
        },
        "usDomesticProduct": {
          "productName": "Mobility CardTravel Services",
          "subProductName": "Credit"
        }
      },
      "category": "Travel & Transport",
      "points": 1,
      "scopes": {
        "complex_rules": []
      },
      "suppress": true
    },
    {
      "reference": "OFF-TempLvngCoreIncrTimeBB-9f8151fc-4bec-46ae-97ee-ff6699a81ff2",
      "productSpecificationReference": "PRD-TmpLvng",
      "displayName": "Temporary Living Accommodations - up to 120 days",
      "description": "You may select temporary living accommodations, up to the allotted number of days as necessary, in your new location. Your Cartus consultant can provide additional insight as to what this benefit covers.",
      "productSubDetails": {
      "internationalProduct": {
      "productName": "Temporary Living",
      "subProductName": "International",
      "requiresRevision": false
      },
      "usDomesticProduct": {
      "productName": "Temporary Living",
      "subProductName": "US Domestic",
      "requiresRevision": false
      }
      },
      "iconDisplayName": "Temporary Living Accommodations - up to 120 days",
      "category": "After Arrival",
      "points": 4,
      "tempLiving": true,
      "rangeMin": 15,
      "rangeMax": 105,
      "rangeIncrementValue": 15,
      "rangeIncrementUnit": "Months",
      "incremental": true,
      "incrementalTimespan": true,
      "hybridTempLiving": true,
      "coreUnitsPerSelection": 30,
      "scopes": {
      "complex_rules": []
      }
      },
    {
      "reference": "OFF-VhclShpmtBndlIntlBB-4892280b-e4f6-4e4a-847a-5253aaf5164b",
      "productSpecificationReference": "PRD-VhclShpmtBndl",
      "displayName": "Automobile Shipment - up to 2 automobiles",
      "description": "Cartus will arrange for your automobiles, up to a maximum of two, to be shipped from your departure location to your new location. Your consultant will be able to provide additional guidance.",
      "productSubDetails": {
        "internationalProduct": {
          "productName": "Moving Services",
          "subProductName": "International Household Goods"
        },
        "usDomesticProduct": {
          "productName": "",
          "subProductName": ""
        }
      },
      "category": "Shipping",
      "points": 4,
      "multiplicity": true,
      "maxSelection": 2,
      "exclusions": [
        "OFF-AutoDispBndlBB-388eb389-b91a-45c4-a2a4-267ccb4e17a4"
      ],
      "scopes": {
        "complex_rules": []
      }
    }
  ],
  "count": 152
}