import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger, MatInput, MatOption } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

import { NgForm } from '@angular/forms';
import { UserService } from './user.service';
import { loggedInUserData } from 'src/app/login/login.service';
import { ManageUsersService } from '../login/manage-users.service';
import { ManageClientsService } from '../login/manage-clients.service';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {
  @ViewChild('f', {static: false}) userFormdata: NgForm;
  @ViewChild('subcriptionEndDate', {static: false}) subcriptionEndDatedata: MatInput;
  @ViewChild('selectedUserType', {static: false}) selectedUserType: MatOption;
  formTitle = "Add new User";
  canBeAdminUser = false;
  client = this.data.clientName;
  clients:clientList[]= [];
  user: string;
  isAdmin = false;
  minDate: Date = new Date();
  maxDate: Date = new Date();
  userTypes : string[] = ["user","admin","superadmin"];
  loggedInUserType: string;
  firstName = this.data.firstName;
  lastName = this.data.lastName;
  email = this.data.email;
  userType = this.data.userType;
  subscriptionEndDate = this.data.subscriptionEndDate;
  userName = this.data.userName;
  isEditEnable = this.data.isEditEnable;
 
   /**to disable close button when snackbar is opened */
   formDisable = false;
  
   
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<UserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly toastrService: ToastrService,
    private readonly spinner: NgxSpinnerService,
    private userService: UserService,
    private manageUsersService: ManageUsersService,
    private manageClientService: ManageClientsService
  ) { }

  ngOnInit() {
    this.fetchClientsData();
    this.manageClientService.manageClientList.subscribe(
      data =>{
        if(data) {
          this.clients = data.clients;
        }
      }
    )
    this.loggedInUserType = (JSON.parse(localStorage.getItem(loggedInUserData)).userType).toLowerCase();
    this.maxDate.setMonth(new Date().getMonth());
    this.maxDate.setDate(new Date().getDate() + 15);
    this.canBeAdminUser = this.checkEmail(this.email);
    this.changeUserType(this.userType);

    if(this.isEditEnable){
      this.formTitle= "Update User Details";
    }
    if(this.userType){
      if(this.userType.toLowerCase() == "admin"){
        this.isAdmin = true;
      }
    }
  }

 /**
   * Invoked on click of dismiss/close
   */
  onDismiss(userForm: NgForm) {
    if(userForm) {
      userForm.resetForm();
    }
    this.dialogRef.close();
  }

  onSubmit(formData: NgForm) {
    this.spinner.show();
    let newUserData = null;
    if(formData) {
      newUserData = formData.value;
    }
    if(newUserData) {
     /*  api call to create new User */
    let clientId =  this.getClientId(newUserData.client);
    newUserData = {...newUserData,...{"clientId": clientId}}
      this.userService.registerUser(newUserData).subscribe(
        data => {
          let message = data.message;
          if(message == "User added successfully!") {
            this.showSuccessToast( "User Registered Successfully !");
            this.fetchAllUsersData();
            this.dialog.closeAll();
          }
        },
        error => {
          let errorMessage = error.error.error.message;
          this.showErrorToastMessage(errorMessage)
        }
        );
    }
    this.spinner.hide()
  }

  checkEmail(emailIdEntered: string) {
    if(emailIdEntered) {
      if(emailIdEntered.toLowerCase().includes("@cartus.com")) {
        this.canBeAdminUser = true;
        return true;
      }
      else {
        this.canBeAdminUser = false;
        this.isAdmin = false;
        if(this.userType != 'user') {
          this.subscriptionEndDate = null;
        }
        this.changeUserType('user');
    
      }
    }
    return false;
  }

  showSuccessToast(message: string) {
    this.toastrService.success(message, 'success', {
      timeOut: 3000,
      closeButton: true
    })
  }

  showErrorToastMessage(message: string) {
    this.toastrService.error(message, 'error', {
      timeOut: 3000,
    })
  }

  setSubscriptionEndDate(isAdminSelected) {
    if (isAdminSelected) {
      this.maxDate.setDate(this.maxDate.getDate() - 15);
      this.maxDate.setMonth(this.maxDate.getMonth() + 6);
    }
    else {
      this.maxDate.setDate(this.maxDate.getDate() + 15);
    }
  }
  changeUserType(event) {
    if(event) {
    this.maxDate.setMonth(new Date().getMonth());
    this.maxDate.setDate(new Date().getDate());
      if(event == true) {
        this.changeUserType('admin');
      }
      else if(event == false){
        this.changeUserType('user');
      }
    else {
      switch(event) {
        case 'admin': {
          this.userType = 'admin';
          this.maxDate.setMonth(new Date().getMonth() + 6);
          if(!this.canBeAdminUser) {
              this.subscriptionEndDate = null;
          }
          break;
        }
        case 'user' : {
          this.userType = 'user';
          //this.maxDate.setDate(new Date().getDate() + 15);
          this.maxDate.setMonth(new Date().getMonth() + 6);
          if(this.canBeAdminUser) {
            this.subscriptionEndDate = null;
           }
          break;
        }
        case 'superAdmin' : {
          this.userType = 'superAdmin';
            this.subscriptionEndDate = null;
          break;
        }
        default: {
          this.maxDate.setMonth(new Date().getMonth())
        }
      }
    }
  }
  }

  updateUser(formData: NgForm) {
    this.spinner.show();
    let newUserData = null;
    if(formData) {
      newUserData = formData.value;
      let clientId =  this.getClientId(newUserData.client);
      newUserData = {...newUserData,...{"clientId": clientId}}
    }
    if(newUserData) {
     /*  api call to update new User */
      this.userService.updateUser(newUserData).subscribe(
        data => {
          let message = data.message;
          if(message == "User details updated successfully!") {
            this.showSuccessToast( "User Updated Successfully !");
            this.fetchAllUsersData();
            this.dialog.closeAll();
          }
        },
        error => {
          let errorMessage = error.error.error;
          if (errorMessage.message) {
            if (errorMessage.message.includes("Incorrect Subscription Date.")) {
              errorMessage = "First change UserType!"
            }
          }
          if(error.status === 500) {
            if (errorMessage.error == "Subscription End Date cannot be set for Super Admin User") {
              errorMessage = "Cannot degrade userType!!!"
            }
            else {
              errorMessage = errorMessage.error;
            }
          }
          this.showErrorToastMessage(errorMessage.toString())
        }
        );
    }
    this.spinner.hide()
  }

   fetchAllUsersData()  {

    this.manageUsersService.getAllAdminUsers().subscribe( data =>{
      if(data) {
        this.manageUsersService.manageUserList.next(data);
       }
    })
  }
  
  toggleIsAdminCheckbox(e) {
    if(e.checked) {
      this.isAdmin = true;
      this.changeUserType('admin')
    }
    else {
      this.isAdmin = false;
      this.changeUserType('user')
    }
  }
  
  fetchClientsData()  {
    this.manageClientService.getClients().subscribe( data =>{
      if(data) {
        this.manageClientService.manageClientList.next(data);
       }
    })
  }

  getClientId(clientName) {
   return  this.clients.filter(d => d.clientName == clientName )[0].clientId;
  }

}

export interface clientList{
  
    clientId: number;
    clientName: string;
    noOfUsers: number;

}
