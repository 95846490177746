import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TransfereeRoutingModule } from './transferee-routing.module';
import { TransfereeDetailComponent } from './transferee-detail/transferee-detail.component';
import { SensitiveInfoMaskComponent } from './components/sensitive-info-mask/sensitive-info-mask.component';
import {MatCardModule} from '@angular/material/card';
import { FlexModule, FlexLayoutModule } from '@angular/flex-layout';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatAutocompleteModule, MatButtonModule, MatCheckboxModule, MatChipsModule, MatDatepickerModule, MatDividerModule, MatInputModule, MatNativeDateModule, MatRadioModule, MatSlideToggleModule, MatTableModule } from '@angular/material';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSelectModule} from '@angular/material/select';
import { AvatarModule } from './../../../../core/components/avatar/avatar.module';
import { SharedModule } from './../../../../core/shared.module';
import {TWarningDialogComponent} from './transferee-detail/transferee-detail.component'
import { FamilyInfoComponent, WarningDialogComponent } from './components/family-info/family-info.component';
import { AddFamilyInfoDialogComponent } from './components/add-family-info-dialog/add-family-info-dialog.component';
import { GetPreferredPhonePipe } from './pipes/get-preferred-phone.pipe';
import {MatDialogModule} from '@angular/material';
import { UnconfirmBenefitsModalComponent } from './components/unconfirm-benefits-modal/unconfirm-benefits-modal.component';
import { ScheduledPolicyCallModalComponent } from './components/scheduled-policy-call-modal/scheduled-policy-call-modal.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { UnlockSubmittedHighValueGoodsModalComponent } from './components/unlock-submitted-high-value-goods-modal/unlock-submitted-high-value-goods-modal.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SharedTransfereeProgramModule} from './../shared/shared.module';
import { SharedTradionalOrderModule } from '../../../../shared/shared-tradional-order.module';
import { UpdateDepatureDestinationComponent } from './update-depature-destination/update-depature-destination.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { WorkDetailPopupComponent } from './work-detail-popup/work-detail-popup.component';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../../../../../environments/environment';
import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { BookingDetailsPopupComponent } from './booking-details-popup/booking-details-popup.component';
import { DWarningDialogComponent } from './transferee-detail/transferee-detail.component';


@NgModule({
  declarations: [
    TransfereeDetailComponent,
    SensitiveInfoMaskComponent,
    FamilyInfoComponent,
    AddFamilyInfoDialogComponent,
    GetPreferredPhonePipe,
    WarningDialogComponent,
    TWarningDialogComponent,
    DWarningDialogComponent,
    UnlockSubmittedHighValueGoodsModalComponent,
    ScheduledPolicyCallModalComponent,
    UnconfirmBenefitsModalComponent,
    UpdateDepatureDestinationComponent,
    WorkDetailPopupComponent,
    BookingDetailsPopupComponent
  ],
  imports: [
    CommonModule,
    TransfereeRoutingModule,
    FormsModule,
    MatCardModule,
    SharedModule,
    SharedTransfereeProgramModule,
    FlexModule,
    FlexLayoutModule,
    AvatarModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatDialogModule,
    MatIconModule,
    MatSelectModule,
    MatRadioModule,
    MatDatepickerModule,
    MatTooltipModule,
    NgxMaterialTimepickerModule,
    MatDialogModule,
    MatChipsModule,
    MatDividerModule,
    SharedTradionalOrderModule,
    NgxSpinnerModule,
    MatAutocompleteModule,
    MatTableModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    AgmCoreModule.forRoot({
      apiKey: environment.GOOGLE_MAPS_API_KEY,
      libraries: ['places']
    }),
    MatGoogleMapsAutocompleteModule
  ],
  exports: [
    TransfereeDetailComponent
  ],
  entryComponents: [AddFamilyInfoDialogComponent, WarningDialogComponent, TWarningDialogComponent, ScheduledPolicyCallModalComponent, UnlockSubmittedHighValueGoodsModalComponent,UpdateDepatureDestinationComponent, WorkDetailPopupComponent, BookingDetailsPopupComponent, DWarningDialogComponent ]
})
export class TransfereeModule { }
