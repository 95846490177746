import { Component, OnInit, ViewEncapsulation } from '@angular/core';
// import { copyright } from 'src/app/core/constants';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  /**property to print copyright string */
  flexProperty = 'column';
  showFullWidth = false;
  readonly copyright = `© 2000-${new Date().getFullYear()} Cartus Corporation`;
  constructor(private router: Router) {
    //if (this.router.url.includes('changePassword')) {
      // this.flexProperty = 'row';
      // this.showFullWidth = true;
    // }
   }

  ngOnInit() {
  }

}
