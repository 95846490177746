<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
  <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column">
  <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between"
    fxLayoutGap="20px" fxLayoutGap.xs="0em">
    <div class="candidate-dialogtitle"> {{ formTitle }}</div>
    <a href="#" class="close-icon" (click)="onDismiss($event)" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <form fxLayout="column" class="middle-section" [formGroup]="updateClientContactForm">
    <div class="middle-section">
      <mat-dialog-content>
        <div>
          <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em"
            formGroupName="nameDetails">

            <!--First Name field-->
            <div fxFlex="48%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input matInput placeholder="First Name" readonly character-only formControlName="firstName"
                  minLength="1" maxlength="50">

              </mat-form-field>
            </div>
            <!--Last Name field-->
            <div fxFlex="48%" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <input matInput placeholder="Last Name" readonly character-only formControlName="lastName" minLength="2"
                  maxlength="50">

              </mat-form-field>
            </div>
          </div>

          <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em">

            <!--Company-->
            <div fxFlex="48%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <mat-label>Company </mat-label>
                <mat-select disabled formControlName="company" matInput aria-label="Company">
                  <mat-option [value]="data?.company">{{ data?.company }}</mat-option>
                </mat-select>

              </mat-form-field>
            </div>

            <!--Client role-->
            <div fxFlex="48%" fxLayout.xs="column" fxFlex.xs="100%">
              <mat-form-field>
                <mat-label>Role </mat-label>
                <mat-select formControlName="role" matInput aria-label="Role"
                  [disabled]="editClientContactRegistration.status == 'Active'">
                  <mat-option value="client-contact">Client Contact</mat-option>
                  <mat-option value="client-contact-initiator">Client Contact Initiator</mat-option>
                </mat-select>
                <mat-error>
                  {{ getErrorMessage('role', 'Role') }}
                </mat-error>
              </mat-form-field>

            </div>
          </div>

          <div class="input-row" class="addcandidate-toppadding" fxLayout="row" fxLayoutAlign="space-between"
            fxLayout.xs="column" fxLayoutGap.xs="0em">
            <!--Email Address field-->
            <div fxFlex="48%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" class="emailcontainer m-t-23">
              <mat-form-field fxFlex>
                <input matInput type="text" placeholder="Email Address" formControlName="email"
                  [readonly]="editClientContactRegistration.status == 'Active'">
                <mat-error>
                  {{ getErrorMessage('email', 'Email Address') }}
                </mat-error>
              </mat-form-field>
            </div>
            <!--Mobile Phone-->
            <div fxFlex="48%" fxLayout.xs="row" fxFlex.xs="100%" fxLayout="row" fxFlexFill
              fxLayoutAlign="space-between">
              <div fxFlex="30%" fxFlex.xs="30%" class="m-t-23">
                <mat-form-field>
                  <mat-label>Mobile</mat-label>
                  <input type="text" aria-label="Phone Code" matInput #autoCompleteInput
                    formControlName="phoneDialingCode" [matAutocomplete]="auto"
                    [readonly]="editClientContactRegistration.status == 'Active'">
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [panelWidth]="293">
                    <mat-option [value]="option.countryDialingCode" *ngFor="let option of filteredOptions | async">
                      {{ option.countryName }} (+ {{ option.countryDialingCode }})
                    </mat-option>
                  </mat-autocomplete>
                  <mat-error>
                    {{ getErrorMessage('phoneDialingCode', 'Phone Code') }}
                  </mat-error>
                </mat-form-field>
              </div>
              <div fxFlex="70%" fxFlex.xs="70%" class="international-extn m-t-23">
                <mat-form-field fxFlex>
                  <mat-label>Phone Number </mat-label>
                  <input matInput type="text" placeholder="Phone Number" number-only formControlName="phoneNumber"
                    minLength="7" maxlength="18" [readonly]="editClientContactRegistration.status == 'Active'">
                  <mat-error>
                    {{ getErrorMessage('phoneNumber', 'Mobile #') }}
                  </mat-error>
                  <mat-hint>Mobile # is required to receive text messages</mat-hint>
                </mat-form-field>
              </div>
            </div>

          </div>
        </div>

      </mat-dialog-content>
    </div>
    <mat-dialog-actions class="statusBtn add-dialogbtn" fxLayout="row" fxLayoutAlign="flex-end" fxLayoutGap="10px"
      fxLayoutGap.xs="0px" fxLayoutAlign.xs="space-between center">
      <button fxFlex="25%" type="button" class="mat-button text-button" color="primary" id="cancel"
        (click)="onDismiss($event)">CANCEL</button>

      <button fxFlex="35%"
        *ngIf="editClientContactRegistration.status !== 'Active' && editClientContactRegistration.status !== 'Invitation Sent'"
        (click)="saveClientContactDetails('draft')" class="mat-button outlined-button" type="button" id="addEditSave">
        Save Draft</button>

      <button fxFlex="35%"
        *ngIf="editClientContactRegistration.status == 'Active' || editClientContactRegistration.status == 'Invitation Sent'"
        class="mat-button contained-button send-invite" [disabled]="editClientContactRegistration.status === 'Active'"
        id="addEditSendInvite" (click)="saveClientContactDetails('update')">Save Updates</button>

      <button fxFlex="35%" *ngIf="editClientContactRegistration.status != 'Active'" (click)="sendResendInvite()"
        class="mat-button contained-button send-invite" id="addEditSendInvite">
        {{ editClientContactRegistration.status == 'Invitation Sent' ? 'Resend' : 'Send Invite' }}</button>

    </mat-dialog-actions>
  </form>
</div>
