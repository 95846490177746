import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Transaction } from '../models/transaction.model';
import { RecentTransaction } from '../models/recent-transaction.model';
import { AggregationFetchparameter } from '../models/aggregation-fetch-parameter.model';
import { HttpParams } from '@angular/common/http';
import { totalCost } from '../data/employeeInfo'
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';
import { getDatefromPassedDate } from './provider.service';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
/** TransactionService  to fetch data from Api and to display the data in the table format All cost transactions for employee view */
@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private moveProDashboadDataService: MoveProDashboadDataService
  ) {}

  /** getTransactionByFileNum method to fetch data from Api to display in All Cost transaction for employee table */
  getTransactionByFileNum(filenumber: number, aggregationFetchParameter?: AggregationFetchparameter): Observable<Transaction> {
    let sortColumnName = 'invoiceDate';
    let sortOrder = 'desc';
    if (aggregationFetchParameter) {
    if (aggregationFetchParameter.sortColumnName) {
      sortColumnName = aggregationFetchParameter.sortColumnName;
    }
    if (aggregationFetchParameter.sortOrder) {
      sortOrder = aggregationFetchParameter.sortOrder;
    }
  }
    const params = new HttpParams({ fromObject: {
      itemsPerPage: aggregationFetchParameter.itemsPerPage ? aggregationFetchParameter.itemsPerPage.toString() : '25',
      pageNumber: aggregationFetchParameter.pageNumber ? aggregationFetchParameter.pageNumber.toString() : '1',
      sortColumnName: sortColumnName,
      sortOrder: sortOrder,
      currencyAbbr: aggregationFetchParameter.preferredCurr ? aggregationFetchParameter.preferredCurr : null
    }});
    // return this.baseClient
    //   .getOne<Transaction>(
    //     `v1/transaction/${filenumber}`,
    //     'get the all cost transaction for employee',
    //     params
    //   )
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: Transaction = null;
    //       this.logSvc.logError(err);
    //       return of(empty);
    //     })
    //   );
    let response = [];
    this.moveProDashboadDataService.totalCostDataSubject.getValue().filter((ele) => {
      if (ele.fileNumber === filenumber) {
        this.updateTotalCostData(ele,filenumber);
        response.push(ele);
      }
    });
    return of(JSON.parse(JSON.stringify(response[0])));
  }

  /** getRecentTransactions to fetch recent transaction data from Api */
  getRecentTransactions(fileNumber: number, aggregationFetchParameter : any): Observable<RecentTransaction> {
    const params = new HttpParams({ fromObject: {
      currencyAbbr: aggregationFetchParameter.preferredCurr ? aggregationFetchParameter.preferredCurr : null
    }});
    // return this.baseClient
    //   .getOne<RecentTransaction>(
    //     `v1/transaction/${fileNumber}/recent`,
    //     'get the all cost transaction for employee',
    //     params
    //   )
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: RecentTransaction = null;
    //       this.logSvc.logError(err);
    //       return of(empty);
    //     })
    //   );
    let response = [];
    this.moveProDashboadDataService.totalCostDataSubject.getValue().filter((ele) => {
      if (ele.fileNumber === fileNumber) {
        this.updateTotalCostData(ele,fileNumber);
        response.push(ele);
      }
    });
    return of(JSON.parse(JSON.stringify(response[0])));
  }

  
updateTotalCostData(data, fileNumber) {
  let employeeDatas = this.moveProDashboadDataService.employeeDataSubject.getValue();
  let employee ;
  employeeDatas.data.filter((ele) => {
    if(ele.fileNumber === fileNumber) {
      employee = ele;
    }
  });

  if(employee) {
    if(data && data.results && data.results.length>0) {
      data.results.forEach((element, index) => {
        element.invoiceDate = getDatefromPassedDate((index*2)+1, false,employee.effectiveTransferDate);
        if(element.accountSubCategory == 'PERMANENT STORAGE') {
          element.transactionDesc = `Permanent Storage, Billing Period From Dt  ${getDatefromPassedDate(index+30,false,element.invoiceDate)} - Billing Period To Dt ${getDatefromPassedDate(2,false, element.invoiceDate)}`
        }
      });
    }
   
  }
}
}


