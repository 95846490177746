import { Injectable, Input } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { RemoteLoggingService } from './../../../core/services/remote-logging.service';
import { Observable, of, Subject, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { candidateProfile } from './../../../core/models/candidate.model';
import { APIResponse } from './../../../core/models/response.model';
import { HttpParams } from '@angular/common/http';
import { AdminClient } from './../../../core/models/admin-client.model';
import { UserTypeService, UserType } from '../services/user-type.service';
import { transfereeListData, transfereeInprogressData, transfereeBenefitsConfirmedData, filterData, countryInfoData, valueList, cciList, contracts, moveTypes, tempLivingData, currencyData, orderPriority, bookingDetailsData } from '../../../core/data/data';
import { cc_divisionPrograms } from '../../../core/data/data';
import { cc_divisions } from '../../../core/data/data';
import { element } from 'protractor';
import { shortAddress } from '../../../core/models/address.model';
import { phoneDetails } from '../../../core/models/phone.model';
import { emailDetails } from '../../../core/models/email.model';
import { DateConversionService } from './../../../core/services/date-conversion.service';
import { BbAdminDataService } from '../../../core/data/bb-admin-data.service';
import moment from 'moment';

export interface locationFilter {
  countries: Array<string>;
  states: Array<string>;
  cities: Array<string>;
}

export interface CandidateFilter {
  filters: {
    departure: locationFilter,
    destination: locationFilter
  };
  searchText: string;
}

export interface CalenderInvite {
  options: {
    recipients: Array<{ email: string }>,
    smart_invite_id: string,
    callback_url: string,
    event: {
      summary: string,
      description: string,
      start: Date,
      end: Date,
      tzid: string,
      location: {
        description: string
      }
    },
    organizer: {
      name: string
    }
  };
  subject: string;
  emailTemplate: string;
  orderId: string;
}

export interface PetsInformation {
  name: string,
  type: string,
  breed: string,
  weight: number
}
export interface AssessNeeds {
  accompanyingMembers: Array<any>,
  petsInformation: PetsInformation[],
  departureLocation: string,
  travelHistory: Array<string>, 
  assessNeedsDraft: boolean
  assessNeedsSubmittedDate: any
}
export interface HousingPreference {
  desiredLocation?: {
    city: string,
    state: string,
    country: string
  },
  alternateLocation?: {
    city: string,
    state: string,
    country: string
  },
  destinationOfficeLocation?: {
    city: string,
    state: string,
    country: string
  },
  moveInDate?: string,
  moveOutDate?: string,
  numberBedrooms?: string,
  numberApartments?: any,
  babyCotRequired?: boolean,
  acceptableCommute?: number,
  priorities?: Array<string>,
  additionalInstructions?: string,
  housingPreferencesDraft?: boolean,
  housingPreferencesSubmittedDate?: string,
  numberBedroomsValueList?: Array<string>,
  prioritiesValueList?: Array<string>,
  accommodationType?: any,
  housekeepingInfo?: {
    housekeepingFrequency?: string,
    comments?: any;
  }
  furnished?: boolean,
  kitchenRequired?: boolean,
  parkingInfo?: {
    parkingApproved?: boolean,
    financialResponsibility?: string,
    comments?: any;
  }
  numberOfBathroomsValueList?: any,
  numberOfBathrooms?: string,
}
export interface CustomerRelocationDetails {
  filePriority?: any,
  daysAuthorized?:any,
  serviceDeliveryLocation?: any,
  financialResponsibility?: any,
  clientBudgetCurrency?: any,
  clientDirectedProviderComment?: any,
  provider?: any,
  requiresResidencyLetter?: any,
  clientBudgetAmount?: any
}

export interface bookingDetails{
  bookingAgent?: any,
  statusUpdatedDate?: any,
  status?: any,
  providerName?: any,
  propertyName?: any,
  apartmentType?:any,
  rentPaidBy?: any,
  moveDates?: {
    moveIn?: any,
    moveOut?: any
  },
  address?: {
    streetLine1?: any,
    city?: any,
    state?: any,
    country?: any,
    postalCode?: any
  }

}

export interface BookingAgent {
  partyId?:string;
}

@Injectable({
  providedIn: "root",
})
export class CandidateProfileService {
  /**
    * base constructor
    * @param baseClientService baseclient service
    * @param logSvc remote logging service
    */
  tempTransfereeListData = { "candidates": new Array, "count": 0, "costProjection": new Array, "cpCount": 0 };
  constructor(
    private readonly baseClientService: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private userTypeService: UserTypeService,
    private dateConversionService: DateConversionService,
    private bbadmindataService: BbAdminDataService
    // private readonly customLogger: LoggerService
  ) { }

  /**observable for subject */
  selectedValue$ = new Subject<string>();
  testSubject = new Subject<any>();
  testSubject$: Observable<any> = this.testSubject.asObservable();
  refreshListSubject = new Subject<any>();
  public refreshListSubject$: Observable<any> = this.refreshListSubject.asObservable();
  newCandidate: candidateProfile;

  /** user role */
  userRole: UserType = this.userTypeService.userType;

  /** clientID of the selected client */
  clientId: any;

  /**
   * Returns the list of table filters available for Candidate List
   */
  getListFilters(): Observable<{ moveStatuses: Array<string> }> {
    // return this.baseClientService.getOne<any>('v1/admin/candidate/filters').pipe(
    //   map(r => r.body), catchError((err, source) => {
    //     const empty: any = null;
    //     console.log('Failed to get Locations: ', err);
    //     return of(empty);
    //   })
    // );
    return of(filterData);
  }

  /**
   * Return the candidate json list and loop to display in the table
   * @param queryString querystring values
   * @param clientDetails client Id to be passed for Program/account manager
   */

  /**
   * Return the candidate json list and loop to display in the table
   * @param queryString querystring values
   * @param clientDetails client Id to be passed for Program/account manager
   */
  getCandidateProfiles(
    options: any,
    clientDetails: AdminClient,
    candidateOrCost: any
  ): Observable<{ candidates: Array<candidateProfile>; count: number }> {
    let httpParams = new HttpParams();
    let data = {
      candidates: [],
      count: 0,
    };
    let transfereelist = [];
    if (
      options &&
      options.hasOwnProperty("itemsPerPage") &&
      options.hasOwnProperty("pageNumber")
    ) {
      httpParams = httpParams.set("itemsPerPage", options.itemsPerPage);
      httpParams = httpParams.set("pageNumber", options.pageNumber + 1);
    }
    if (options && options.sortBy && options.sortDirection) {
      httpParams = httpParams.set("sortBy", options.sortBy);
      httpParams = httpParams.set("sortDirection", options.sortDirection);
    }

    // let data;
    // data = (options && options.searchText) ? { searchText: options.searchText, searchProperties: options.searchProperties } : {};
    // clientDetails ? data['clientId'] = clientDetails.clientId : null;
    // if (this.userRole !== 'client-contact-initiator') {
    // this.clientId = clientDetails.clientId; // assigning for use later. temp fix until the listing of transferees against that division
    // }
    // return this.baseClientService
    //   .post<any>(`${ (options && options.hasOwnProperty('itemsPerPage') && options.hasOwnProperty('pageNumber')) ?
    //     'v1/admin/candidate/list?' + httpParams :
    //     'v1/admin/candidate/list'}`, data)
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //     this.logSvc.logError(err);
    //     const emptyArray = null;
    //       return of(emptyArray);
    //     })
    //   );
    this.bbadmindataService.transfereeListDataSubject.subscribe(
      data => {
        this.tempTransfereeListData = data;
      }
    )

    if (options && options.filters || options && options.searchText) {
      return this.searchCandidateProfiles(options, clientDetails, candidateOrCost);
    }

    else {
      transfereelist = candidateOrCost === 'transferee' ? this.tempTransfereeListData.candidates : candidateOrCost === 'cost-projection' ? this.tempTransfereeListData.costProjection : null ;
      data.candidates = transfereelist;
      data.count = transfereelist.length;
      return of(data);
    }
  }

  searchCandidateProfiles(
    searchBody: any,
    clientDetails: AdminClient,
    candidateOrCost: any
  ): Observable<{ candidates: Array<candidateProfile>; count: number }> {
    //  let httpParams = new HttpParams();
    //  if (searchBody && searchBody.hasOwnProperty('itemsPerPage') && searchBody.hasOwnProperty('pageNumber')) {
    //    httpParams = httpParams.set('itemsPerPage', searchBody.itemsPerPage);
    //    httpParams = httpParams.set('pageNumber', searchBody.pageNumber+1);
    //  };
    //  delete searchBody.itemsPerPage;
    //  delete searchBody.pageNumber;
    // clientDetails ? searchBody['clientId'] = clientDetails.clientId : null;
    // return this.baseClientService
    //   .post<any>(`${ 'v1/admin/candidate/list?' + httpParams}`, searchBody || {})
    //   .pipe(
    //     map(r => r.body),
    //     catchError(err => {
    //     this.logSvc.logError(err);
    //     const emptyArray = null;
    //       return of(emptyArray);
    //     })
    //   );
    let parentList = candidateOrCost === 'transferee' ? this.tempTransfereeListData.candidates : candidateOrCost === 'cost-projection' ? this.tempTransfereeListData.costProjection : null;
    if (searchBody.searchText) {
      let filteredlist = [];
      let data = {
        candidates: [],
        count: 0
      }
      let searchText = searchBody.searchText;
      if(candidateOrCost === 'transferee') {
      this.tempTransfereeListData.candidates.forEach(element => {
        let currentNme = `${element.nameDetails.lastName},${element.nameDetails.firstName}`;
        let depaturearr = `${element.departureAddr.city},${element.departureAddr.country},${element.departureAddr.state},${element.departureAddr.streetLine1}`;
        let destinationarr = `${element.destinationAddr.city},${element.destinationAddr.country},${element.destinationAddr.state},${element.destinationAddr.streetLine1}`;
        if (
          currentNme.toLowerCase().includes(searchText.toLowerCase()) ||
          element.programDetails.programName.toLowerCase().includes(searchText.toLowerCase()) ||
          depaturearr.toLowerCase().includes(searchText.toLowerCase()) ||
          destinationarr.toLowerCase().includes(searchText.toLowerCase()) ||
          element.moveStatus.toLowerCase().includes(searchText.toLowerCase()) ||
          (('deactivated'.includes(searchText.toLowerCase()) && element.moveStatus.toLowerCase() === 'withdrawn')) ||
          element.authorizationType.toLowerCase().includes(searchText.toLowerCase())
        ) {
          filteredlist.push(element);
        }
      });
    }
    else if(candidateOrCost === 'cost-projection') {
      this.tempTransfereeListData.costProjection.forEach(element => {
        let currentNme = `${element.nameDetails.lastName},${element.nameDetails.firstName}`;
        let depaturearr = `${element.departureAddr.city},${element.departureAddr.country},${element.departureAddr.state},${element.departureAddr.streetLine1}`;
        let destinationarr = `${element.destinationAddr.city},${element.destinationAddr.country},${element.destinationAddr.state},${element.destinationAddr.streetLine1}`;
        if (
          currentNme.toLowerCase().includes(searchText.toLowerCase()) ||
          element.programDetails.programName.toLowerCase().includes(searchText.toLowerCase()) ||
          depaturearr.toLowerCase().includes(searchText.toLowerCase()) ||
          destinationarr.toLowerCase().includes(searchText.toLowerCase()) ||
          element.moveStatus.toLowerCase().includes(searchText.toLowerCase()) ||
          (('deactivated'.includes(searchText.toLowerCase()) && element.moveStatus.toLowerCase() === 'withdrawn')) ||
          element.authorizationType.toLowerCase().includes(searchText.toLowerCase())
        ) {
          filteredlist.push(element);
        }
      });
    }
      if (filteredlist.length > 0) {
        parentList = filteredlist;
      } else {
        data.candidates = filteredlist;
        data.count = filteredlist.length;
        if (searchBody.sortBy && searchBody.sortDirection) {
          return this.sortTransfereeList(data.candidates, searchBody);
        }
        return of(data);
      }
    }


    let data = {
      candidates: [],
      count: 0
    }

    // if(searchBody.filters.moveStatuses && searchBody.filters.moveStatuses.length >0) {
    //   let filtermovelist=[];
    //   let filterstatus=searchBody.filters.moveStatuses;
    //   parentList.forEach(ele=>{
    //     filterstatus.forEach(element => {
    //       if(ele.moveStatus.includes(element) || (('Deactivated'.includes(ele.moveStatus) && element === 'Withdrawn'))){
    //         filtermovelist.push(ele);
    //       }
    //     });

    //     data.candidates = filteredlist;
    //     data.count = filteredlist.length;
    //     parentList = data.candidates;
    //     // if (searchBody.sortBy && searchBody.sortDirection) {
    //     //   return this.sortTransfereeList(data.candidates,searchBody);
    //     // }
    //     // return of(data);
    //   }


    //   let data = {
    //     candidates: [],
    //     count: 0,
    //   };

    if (
      searchBody.filters.moveStatuses &&
      searchBody.filters.moveStatuses.length > 0
    ) {
      let filtermovelist = [];
      let filterstatus = searchBody.filters.moveStatuses;
      parentList.forEach((ele) => {
        filterstatus.forEach((element) => {
          if (
            ele.moveStatus.includes(element) ||
            ("Deactivated".includes(ele.moveStatus) && element === "Withdrawn")
          ) {
            filtermovelist.push(ele);
          }
        });
      });
      if (filtermovelist.length > 0) {
        parentList = filtermovelist;
      } else {
        data.candidates = filtermovelist;
        data.count = filtermovelist.length;
        if (searchBody.sortBy && searchBody.sortDirection) {
          return this.sortTransfereeList(data.candidates, searchBody);
        }
        return of(data);
      }
    }
    
    if (
      searchBody.filters.authorizationType &&
      searchBody.filters.authorizationType.length > 0
    ) {
      let filterauthlist = [];
      let filterstatus = searchBody.filters.authorizationType;
      parentList.forEach((ele) => {
        filterstatus.forEach((element) => {
          if (
            ele.authorizationType.includes(element) 
          ) {
            filterauthlist.push(ele);
          }
        });
      });
      if (filterauthlist.length > 0) {
        parentList = filterauthlist;
      } else {
        data.candidates = filterauthlist;
        data.count = filterauthlist.length;
        if (searchBody.sortBy && searchBody.sortDirection) {
          return this.sortTransfereeList(data.candidates, searchBody);
        }
        return of(data);
      }
    }
    if (searchBody.filters.departure) {
      let filterdepartureList = [];
      let filtersearches = [];
      if (
        searchBody.filters.departure.countries &&
        Array.isArray(searchBody.filters.departure.countries)
      ) {
        searchBody.filters.departure.countries.forEach((ele) => {
          filtersearches.push(ele);
        });
      } else if (searchBody.filters.departure.countries) {
        filtersearches.push(searchBody.filters.departure.countries);
      }
      if (
        searchBody.filters.departure.cities &&
        Array.isArray(searchBody.filters.departure.cities)
      ) {
        searchBody.filters.departure.cities.forEach((ele) => {
          filtersearches.push(ele);
        });
      } else if (searchBody.filters.departure.cities) {
        filtersearches.push(searchBody.filters.departure.cities);
      }
      if (
        searchBody.filters.departure.states &&
        Array.isArray(searchBody.filters.departure.states)
      ) {
        searchBody.filters.departure.states.forEach((ele) => {
          filtersearches.push(ele);
        });
      } else if (searchBody.filters.departure.states) {
        filtersearches.push(searchBody.filters.departure.states);
      }
      parentList.forEach((element) => {
        let depaturearr = `${element.departureAddr.city},${element.departureAddr.country},${element.departureAddr.state},${element.departureAddr.streetLine1}`;
        filtersearches.forEach((ele) => {
          if (depaturearr.toLowerCase().includes(ele.toLowerCase())) {
            filterdepartureList.push(element);
          }
        });
      });
      let departureSet = new Set(filterdepartureList);
      let dispalydeparture = [...departureSet];
      if (dispalydeparture.length > 0) {
        parentList = dispalydeparture;
      } else {
        data.candidates = dispalydeparture;
        data.count = dispalydeparture.length;
        if (searchBody.sortBy && searchBody.sortDirection) {
          return this.sortTransfereeList(data.candidates, searchBody);
        }
        return of(data);
      }
    }
    if (searchBody.filters.destination) {
      let destinationfilterlist = [];
      let filtersearches = [];
      if (
        searchBody.filters.destination.countries &&
        Array.isArray(searchBody.filters.destination.countries)
      ) {
        searchBody.filters.destination.countries.forEach((ele) => {
          filtersearches.push(ele);
        });
      } else if (searchBody.filters.destination.countries) {
        filtersearches.push(searchBody.filters.destination.countries);
      }
      if (
        searchBody.filters.destination.cities &&
        Array.isArray(searchBody.filters.destination.cities)
      ) {
        searchBody.filters.destination.cities.forEach((ele) => {
          filtersearches.push(ele);
        });
      } else if (searchBody.filters.destination.cities) {
        filtersearches.push(searchBody.filters.destination.cities);
      }
      if (
        searchBody.filters.destination.states &&
        Array.isArray(searchBody.filters.destination.states)
      ) {
        searchBody.filters.destination.states.forEach((ele) => {
          filtersearches.push(ele);
        });
      } else if (searchBody.filters.destination.states) {
        filtersearches.push(searchBody.filters.destination.states);
      }
      parentList.forEach((element) => {
        let destinationarr = `${element.destinationAddr.city},${element.destinationAddr.country},${element.destinationAddr.state},${element.destinationAddr.streetLine1}`;
        filtersearches.forEach((ele) => {
          if (destinationarr.toLowerCase().includes(ele.toLowerCase())) {
            destinationfilterlist.push(element);
          }
        });
      });
      let destinationSet = new Set(destinationfilterlist);
      let dispalydeparture = [...destinationSet];
      if (dispalydeparture.length > 0) {
        parentList = dispalydeparture;
      } else {
        data.candidates = dispalydeparture;
        data.count = dispalydeparture.length;
        if (searchBody.sortBy && searchBody.sortDirection) {
          return this.sortTransfereeList(data.candidates, searchBody);
        }
        return of(data);
      }
    }

    if (searchBody.filters && searchBody.filters.statusDateRange) {
      let daterangedata = [];
      let Startdate = Date.parse(searchBody.filters.statusDateRange.startDate);
      let Enddate = Date.parse(searchBody.filters.statusDateRange.endDate);
      let currentdate = Date.parse(Date());

      parentList.forEach((ele) => {
        if (
          (Date.parse(ele.moveStatusDate) >= Startdate &&
            Date.parse(ele.moveStatusDate) <= Enddate) ||
          (typeof searchBody.filters.statusDateRange.endDate === "undefined" &&
            Date.parse(ele.moveStatusDate) >= Startdate &&
            Date.parse(ele.moveStatusDate) <= currentdate)
        ) {
          daterangedata.push(ele);
        }
      });

      if (daterangedata.length > 0) {
        parentList = daterangedata;
      } else {
        data.candidates = daterangedata;
        data.count = daterangedata.length;
        if (searchBody.sortBy && searchBody.sortDirection) {
          return this.sortTransfereeList(data.candidates, searchBody);
        }
        return of(data);
      }
    }

    if (searchBody.filters && searchBody.filters.authorizationDateRange) {
      let Authdaterangedata = [];
      let Startdate = Date.parse(
        searchBody.filters.authorizationDateRange.startDate
      );
      let Enddate = Date.parse(
        searchBody.filters.authorizationDateRange.endDate
      );
      let currentdate = Date.parse(Date());
      parentList.forEach((ele) => {
        if (
          (Date.parse(ele.authorizationDate) >= Startdate &&
            Date.parse(ele.authorizationDate) <= Enddate) ||
          (typeof searchBody.filters.authorizationDateRange.endDate ===
            "undefined" &&
            Date.parse(ele.moveStatusDate) >= Startdate &&
            Date.parse(ele.moveStatusDate) <= currentdate)
        ) {
          Authdaterangedata.push(ele);
        }
      });
      if (Authdaterangedata.length > 0) {
        parentList = Authdaterangedata;
      } else {
        data.candidates = Authdaterangedata;
        data.count = Authdaterangedata.length;
        if (searchBody.sortBy && searchBody.sortDirection) {
          return this.sortTransfereeList(data.candidates, searchBody);
        }
        return of(data);
      }
    }

    data.candidates = parentList;
    data.count = parentList.length;
    if (searchBody.sortBy && searchBody.sortDirection) {
      return this.sortTransfereeList(data.candidates, searchBody);
    }
    return of(data);
  }

  sortTransfereeList(parentList, options) {
    switch (options.sortBy) {
      case "fullName":
        let fullName = {
          candidates: JSON.parse(
            JSON.stringify(
              options.sortDirection === "ASC"
                ? parentList.sort((a, b) =>
                  a.nameDetails.lastName.toLowerCase() <
                    b.nameDetails.lastName.toLowerCase()
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.nameDetails.lastName.toLowerCase() <
                    a.nameDetails.lastName.toLowerCase()
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(fullName);
      case "programName":
        let programName = {
          candidates: JSON.parse(
            JSON.stringify(
              options.sortDirection === "ASC"
                ? parentList.sort((a, b) =>
                  a.programDetails.programName.toLowerCase() <
                    b.programDetails.programName.toLowerCase()
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.programDetails.programName.toLowerCase() <
                    a.programDetails.programName.toLowerCase()
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(programName);
      case "status":
        let moveStatus = {
          candidates: JSON.parse(
            JSON.stringify(
              options.sortDirection === "ASC"
                ? parentList.sort((a, b) =>
                  a.moveStatus.toLowerCase() < b.moveStatus.toLowerCase()
                    ? 1
                    : -1
                )
                : parentList.sort((a, b) =>
                  b.moveStatus.toLowerCase() < a.moveStatus.toLowerCase()
                    ? 1
                    : -1
                )
            )
          ),
          count: parentList.length,
        };
        return of(moveStatus);
      case "authorizationDate":
        let authorizationDate = {
          candidates: JSON.parse(
            JSON.stringify(
              options.sortDirection === "ASC"
                ? parentList.sort((a, b) =>
                  a.authorizationDate < b.authorizationDate ? 1 : -1
                ) &&
                parentList.sort(
                  (a, b) =>
                    +new Date(a.authorizationDate) -
                    +new Date(b.authorizationDate)
                )
                : parentList.sort((a, b) =>
                  b.authorizationDate < a.authorizationDate ? 1 : -1
                ) &&
                parentList.sort(
                  (a, b) =>
                    +new Date(b.authorizationDate) -
                    +new Date(a.authorizationDate)
                )
            )
          ),
          count: parentList.length,
        };
        return of(authorizationDate);
      case "departure":
        let country =
          options.sortDirection === "ASC"
            ? parentList.sort((a, b) =>
              b.departure.toLowerCase() <
                a.departure.toLowerCase()
                ? 1
                : -1
            )
            : parentList.sort((a, b) =>
              a.departure.toLowerCase() <
                b.departure.toLowerCase()
                ? 1
                : -1
            );
        let departure = {
          candidates: JSON.parse(JSON.stringify(country)),
          count: parentList.length,
        };
        return of(departure);
      case "destination":
        let countryDes =
          options.sortDirection === "ASC"
            ? parentList.sort((a, b) =>
              b.destination.toLowerCase() <
                a.destination.toLowerCase()
                ? 1
                : -1
            // ) &&
            // parentList.sort((a, b) =>
            //   a.destinationAddr.state.toLowerCase() <
            //     b.destinationAddr.state.toLowerCase()
            //     ? 1
            //     : -1
            // ) &&
            // parentList.sort((a, b) =>
            //   a.destinationAddr.city.toLowerCase() <
            //     b.destinationAddr.city.toLowerCase()
            //     ? 1
            //     : -1
            )
            : parentList.sort((a, b) =>
              a.destination.toLowerCase() <
                b.destination.toLowerCase()
                ? 1
                : -1
            // ) &&
            // parentList.sort((a, b) =>
            //   b.destinationAddr.state.toLowerCase() <
            //     a.destinationAddr.state.toLowerCase()
            //     ? 1
            //     : -1
            // ) &&
            // parentList.sort((a, b) =>
            //   b.destinationAddr.city.toLowerCase() <
            //     a.destinationAddr.city.toLowerCase()
            //     ? 1
            //     : -1
            );
        let destination = {
          candidates: JSON.parse(JSON.stringify(countryDes)),
          count: parentList.length,
        };
        return of(destination);
    }
  }

  /**
   * use to subscribe to selectedValue$
   */
  getMessage(): Observable<any> {
    return this.selectedValue$.asObservable();
  }
  /**
   * used to add a candidate
   * @param request candidate whose details are to be added
   */
  createCandidate(request: candidateProfile, costProjection) {
    /*   return this.baseClientService.post<candidateProfile>('v1/admin/candidate/', request).pipe(
       map(r => r.body),
       catchError((err, source) => {
         const empty: candidateProfile = null;
         this.logSvc.logError('Failed to add transferee details' + err);
       console.log('Failed to add candidate profiles' + err);
         return of(empty);
       })
     );  */
    if(costProjection) {
      this.tempTransfereeListData.costProjection.push(this.prepareTransfereeData(request));
    }
    else {
      this.tempTransfereeListData.candidates.push(this.prepareTransfereeData(request));
    }
    
    
   // this.bbadmindataService.transfereeListDataSubject.next(this.tempTransfereeListData);
  }

  updateCandidateLocation(request: candidateProfile,reqId){ 
    return this.baseClientService.put<candidateProfile>(`v1/admin/candidate/updateLocationInfo/${reqId}`, request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: candidateProfile = null;
        this.logSvc.logError('Failed to add transferee details' + err);
      console.log('Failed to update candidate profiles' + err);
        return of(empty);
      })
    );
    //this.tempTransfereeListData.candidates.push(this.prepareTransfereeData(request));

  }

  prepareTransfereeData(request: candidateProfile) {
    let depAdd: shortAddress = {
      streetLine1: "",
      country: "",
      city: "",
      state: "",
      postalCode: "",
    };
    if (request.departureAddr == null) {
      request.departureAddr = depAdd;
    }

    if (request.destinationAddr == null) {
      request.destinationAddr = depAdd;
    }
    if (request.phoneDetailsList == null) {
      request.phoneDetailsList = [];
      let phnDetail: phoneDetails = {
        primary: true,
        usageType: "personal",
        phoneDialCode: 0,
        phoneNumber: "",
      };
      request.phoneDetailsList.push(phnDetail);
    }
    if (request.emailDetailsList == null) {
      request.emailDetailsList = [];
      let emailDetail: emailDetails = {
        primary: true,
        usageType: "business",
        emailAddress: "",
      };
      request.emailDetailsList.push(emailDetail);
    }
    if (request.pointsOverrideHistory == null) {
      request.pointsOverrideHistory = [];
      let pointsOverride =
        "{'nameDetails':{'firstName':'', 'lastName':''}, 'points': , 'date':''}";
      request.pointsOverrideHistory.push(pointsOverride);
    }
    let prepareAdditionalDetails = [];
    if (request.additionalDetails) {
        request.additionalDetails.forEach(i => {
          let tempAdditionalDetails = {
            displayName: i.displayName,
            value: i.value,
            _specType: i._specType,
            type: i.type,
            valueList: i.valueList
          }
          prepareAdditionalDetails.push(tempAdditionalDetails);
        })
    }
    let prepareEmailDetails = [];
    if(request.emailDetailsList) {
      request.emailDetailsList.forEach(i => {
        let tempEmailDetails = {
          emailAddress: i.emailAddress,
          primary: i.primary,
          usageType: i.usageType
        }
        prepareEmailDetails.push(tempEmailDetails);
      })
    }
    let preparePhoneDetails = [];
    if(request.phoneDetailsList) {
      request.phoneDetailsList.forEach(i =>{
        let tempPhoneDetails = {
          phoneDialCode: i.phoneDialCode,
          phoneNumber: i.phoneNumber,
          primary: i.primary ? true : false,
          textingAvailable: i.textingAvailable ? true : false,
          usageType: i.usageType
        }
        preparePhoneDetails.push(tempPhoneDetails);
      })
    }
    // const transfereeData = {
    //   orderRequestId: this.orderIdGenarator(),
    //   nameDetails: {
    //     title: request.nameDetails.title,
    //     firstName: request.nameDetails.firstName,
    //     lastName: request.nameDetails.lastName,
    //   },
    //   phoneDetailsList: [
    //     {
    //       _id: "60124a5799d733000915423a",
    //       textingAvailable: true,
    //       primary: true,
    //       usageType: "personal",
    //       phoneDialCode: request.phoneDetailsList[0].phoneDialCode,
    //       phoneNumber: request.phoneDetailsList[0].phoneNumber,
    //     },
    //   ],
    //   emailDetailsList: [
    //     {
    //       _id: "60124a5799d733000915423b",
    //       primary: true,
    //       usageType: "business",
    //       emailAddress: request.emailDetailsList[0].emailAddress,
    //     },
    //   ],
    //   moveStatus: request.moveStatus,
    //   moveStatusDate: request.moveStatusDate,
    //   authorizationDate: "2021-01-28",
    //   /* additionalDetails: {
    //     costCenter: request.additionalDetails.costCenter,
    //     poBillingInvoice: request.additionalDetails.poBillingInvoice,
    //     employeeID: request.additionalDetails.employeeID,
    //     jobStartDate: request.additionalDetails.jobStartDate,
    //   }, */

    //   ///Additional details will be an array of size 2 
    //   additionalDetails: prepareAdditionalDetails,
    //   programDetails: {
    //     // divisionPartyId is not inside program details in new form
    //     divisionPartyId: request.divisionPartyId,
    //     programName: request.programDetails.programName,
    //     points: request.programDetails.points,
    //     pointOverride: request.programDetails.pointOverride,
    //     originalPoints: request.programDetails.originalPoints,
    //   },
    //   authorizationDetails: {
    //     baseContract: 505,
    //     cciPartyId: "5e5ee8f5c78aa40008670d36",
    //     currency: [],
    //   },
    //   departureAddr: {
    //     streetLine1: request.departureAddr.streetLine1,
    //     city: request.departureAddr.city,
    //     state: request.departureAddr.state,
    //     country: request.departureAddr.country,
    //     postalCode: request.departureAddr.postalCode,
    //   },
    //   destinationAddr: {
    //     streetLine1: request.destinationAddr.streetLine1,
    //     city: request.destinationAddr.city,
    //     state: request.destinationAddr.state,
    //     country: request.destinationAddr.country,
    //     postalCode: request.destinationAddr.postalCode,
    //   },
    //   pointsOverrideHistory: request.pointsOverrideHistory,
    // };

    //new mapping 
    const transfereeData = 
    {
      orderRequestId: request.orderRequestId,
      nameDetails: {
        title: request.nameDetails.title,
        firstName: request.nameDetails.firstName,
        lastName: request.nameDetails.lastName
      },
      phoneDetailsList: preparePhoneDetails,
      emailDetailsList: prepareEmailDetails,
      moveStatus: request.moveStatus,
      moveStatusDate: request.moveStatusDate,
      authorizationDate: "2021-01-28",
      authorizationType:request.authorizationType,
      jobStartDate: request.optionalDetails.jobStartDate,
      divisionPartyId: request.divisionPartyId,
      moveType: request.moveType,
      timeZone: request.timeZone,
      initialContactBy: request.initialContactBy,
      deliveryTeamModel: request.deliveryTeamModel,
      cliAuthSubTime: request.cliAuthSubTime,
      cliAuthSubDt: request.cliAuthSubDt,
      authReadytoProcessTime: request.authReadytoProcessTime,
      authReadytoProcessDt: request.authReadytoProcessDt, 
      assignedPolicy: request.assignedPolicy,
      executiveOfficer: request.executiveOfficer,
      additionalDetails: prepareAdditionalDetails,
      programDetails: {
        divisionPartyId: request.divisionPartyId,
        programName: request.programDetails?request.programDetails.programName: request.assignedPolicy?request.assignedPolicy:null,
        points: request.programDetails?request.programDetails.points: null,
        pointOverride: request.programDetails?request.programDetails.pointOverride: null,
        originalPoints: request.programDetails?request.programDetails.originalPoints: null
      },
      authorizationDetail: {
       // baseContract: request.authorizationDetails.baseContract?request.authorizationDetails.baseContract:"",
        baseContract: "345",
       // cciPartyId: request.authorizationDetails.cciPartyId,
        cciPartyId: "5e5ee8f5c78aa40008670d36",
        currency: []
      },
      departureAddr: {
        streetLine1: request.departureAddr.streetLine1,
        city: request.departureAddr.city,
        state: request.departureAddr.state,
        country: request.departureAddr.country,
        postalCode: request.departureAddr.postalCode
      },
      departure: request.departureAddr.country + request.departureAddr.state + request.departureAddr.city,
      destinationAddr: {
        streetLine1: request.destinationAddr.streetLine1,
        city: request.destinationAddr.city,
        state: request.destinationAddr.state,
        country: request.destinationAddr.country,
        postalCode: request.destinationAddr.postalCode
      },
      destination: request.destinationAddr.country+ request.destinationAddr.state  + request.destinationAddr.city,
      optionalDetails: {
        costCenter: request.optionalDetails.costCenter,
        poBillingInvoice: request.optionalDetails.poBillingInvoice,
        employeeID: request.optionalDetails.employeeID,
        jobStartDate: request.optionalDetails.jobStartDate,
        priority: request.optionalDetails.priority,
        employeeType: request.optionalDetails.employeeType,
        defaultHomeCountry: request.optionalDetails.defaultHomeCountry,
        defaultHostCountry: request.optionalDetails.defaultHostCountry,
        assignmentStartDate: request.optionalDetails.assignmentStartDate,
        assignmentEndDate: request.optionalDetails.assignmentEndDate,
        // jobStartDate: request.optionalDetails.jobStartDateTrad,
    },
    authorizationDetails: {
      clientId: request.authorizationDetails.clientId,
      cciDetails :{
         name: request.authorizationDetails.cciPartyId,
      },
      contractDetails: {
        baseContract: request.authorizationDetails.contractDetails.baseContract,
        contractId: request.authorizationDetails.contractDetails.contractId,
        contractName: request.authorizationDetails.contractDetails.contractName,
      },
      baseContract: request.authorizationDetails.baseContract,
      contractId: request.authorizationDetails.contractId,
      currency: {
        code: request.authorizationDetails.currency.code,
        description: request.authorizationDetails.currency.description,
      }
    },
    }
    return transfereeData;
  }

  /**
   * used to add a candidate
   * @param request candidate whose details are to be added
   */
  clientDetails: AdminClient;
  index: number; // for updateCandidate index
  updateCandidate(request: candidateProfile, costProjection) {
    /* return this.baseClientService.put<candidateProfile>('v1/admin/candidate/', request).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: candidateProfile = null;
        this.logSvc.logError('Failed to add transferee details' + err);
        console.log('Failed to update candidate profiles' + err);
        return of(empty);
      })
    ); */
    let candidateOrCost = costProjection == true ?  "cost-projection" : "transferee";
    if(costProjection) {
      this.index = this.tempTransfereeListData.costProjection.findIndex(
        (i) => i.orderRequestId == request.orderRequestId);
    } else {
      this.index = this.tempTransfereeListData.candidates.findIndex(
        (i) => i.orderRequestId == request.orderRequestId);
    }
    
    let updatedlist = [];
    this.getCandidateProfiles(Option, this.clientDetails, candidateOrCost).subscribe((data) => {
      if (data) {
        updatedlist = data.candidates;
      }
    });

    let tempPointsHist: Array<any> = [];
    if (updatedlist[this.index].programDetails.pointOverride) {
      if (updatedlist[this.index].pointsOverrideHistory !== "undefined" && updatedlist[this.index].pointsOverrideHistory.length > 0 ) {
        tempPointsHist = updatedlist[this.index].pointsOverrideHistory;
      }
    }
    updatedlist[this.index] = this.prepareTransfereeData(request);
    if (updatedlist[this.index].programDetails.pointOverride) {
      if (updatedlist[this.index].pointsOverrideHistory !== "undefined" && updatedlist[this.index].pointsOverrideHistory.length > 0) {
        updatedlist[this.index].pointsOverrideHistory.push(...tempPointsHist);
      }
    }
    if(costProjection) {
      this.tempTransfereeListData.costProjection = [];
      this.tempTransfereeListData.costProjection.push(...updatedlist);
    } else {
      this.tempTransfereeListData.candidates = [];
      this.tempTransfereeListData.candidates.push(...updatedlist);
    }
    
    //this.bbadmindataService.transfereeListDataSubject.next(this.tempTransfereeListData);
  }

  /**
   * used to send email invite to candidate
   * @param request candidate to whom invite is to be sent
   */
  sendInviteToCandidate(request: candidateProfile): Observable<APIResponse> {
    return this.baseClientService
      .post<APIResponse>(`v1/admin/candidate/authorized`, request)
      .pipe(
        map((r) => r.body),
        catchError((err, source) => {
          this.logSvc.logError("Failed to send invite" + err);
          // return this.catchBlock('sending Invite to Candidate Failed', err, 'string');
          return null;
        })
      );
  }

  /**
   * used to send email invite to candidate
   * @param request candidate to whom invite is to be sent
   */
  reSendInviteToCandidate(request: candidateProfile): Observable<APIResponse> {
    return this.baseClientService
      .put<APIResponse>(`v1/admin/candidate/authorized`, request)
      .pipe(
        map((r) => r.body),
        catchError((err, source) => {
          this.logSvc.logError("Failed to resend invite" + err);
          // return this.catchBlock('sending Invite to Candidate Failed', err, 'string');
          return null;
        })
      );
  }

  /**
   * Search Candidate Distinct Locations
   * @param type Type of search destination or departure
   * @param searchText Actual Search text
   */
  searchCandidateLocations(
    type: string,
    searchText: string
  ): Observable<{
    countries: Array<string>;
    states: Array<string>;
    cities: Array<string>;
  }> {
    // this.userRole = this.userTypeService.userType;
    // let baseURL = `v1/admin/candidate/locations?type=${type}&searchText=${searchText}`;

    // if (!type || !searchText) { return; }

    // (this.userRole === 'client-contact-initiator') ? (baseURL = baseURL) : (baseURL = (baseURL + `&clientId=${this.clientId}`));
    //   return this.baseClientService.getOne<any>(baseURL).pipe(
    //     map(r => r.body), catchError((err, source) => {
    //       const empty: any = null;
    //       console.log('Failed to get Locations: ', err);
    //       return of(empty);
    //     })
    //   );
    //   let filteredlist= [];
    let data = {
      countries: [],
      states: [],
      cities: [],
    };
    let departurecities = [];
    let destinationcities = [];
    let departureStates = [];
    let destinationStates = [];

    this.tempTransfereeListData.candidates.forEach((element) => {
      departureStates.push(element.departureAddr.state);
      departurecities.push(element.departureAddr.city);
      destinationStates.push(element.destinationAddr.state);
      destinationcities.push(element.destinationAddr.city);
    });

    let parentStates = [];
    if (type === "departure") {
      parentStates = departureStates;
    } else {
      if (type === "destination") {
        parentStates = destinationStates;
      }
    }
    let parentCities = [];
    if (type === "departure") {
      parentCities = departurecities;
    } else {
      if (type === "destination") {
        parentCities = destinationcities;
      }
    }
    let states = parentStates.filter((statesname) => {
      return statesname.toLowerCase().includes(searchText.toLowerCase());
    });
    const statess = new Set(states);
    const displaystates = [...statess];
    let city = parentCities.filter((statesname) => {
      return statesname.toLowerCase().includes(searchText.toLowerCase());
    });
    const cities = new Set(city);
    const displaycities = [...cities];
    let searchCountry = countryInfoData.filter((countryname) => {
      return countryname.countryName
        .toLowerCase()
        .includes(searchText.toLowerCase());
    });
    searchCountry.forEach((ele) => {
      data.countries.push(ele.countryName);
    });
    data.cities = displaycities;
    data.states = displaystates;
    return of(data);
  }


  /**
  * used to deactivate transferee
  * @param request array of order request id's to be deactivated
  */
  deactivateTransferee(request): Observable<any> {
    // return this.baseClientService
    //   .put<APIResponse>(`v1/admin/candidate/deactivate`, request)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       this.logSvc.logError('Failed to deactivate transferee' + err);
    //       return null;
    //     })
    //   );
    let startDate = moment().format("YYYY-MM-DD");
     let deactiveList = [];
     request.orderRequestId.forEach(element => {
      if (element) {
        deactiveList.push(this.deactivateReturn(element,startDate))
      }
    });
 return of(deactiveList);
}

deactivateReturn(element,startDate) {
  return  {
    "_id": element,
    "status": "SUCCESS",
    "moveStatus": "Withdrawn",
    "moveStatusDate": startDate
      }
}

  /**
   * get division
   */
  getDivision(divisionId) {
    let httpParams: HttpParams = new HttpParams();
    if (divisionId) {
      httpParams = httpParams.append("divisionId", divisionId.toString());
    }
    /* return this.baseClientService.getArr<any>(`v1/admin/cc-division?${httpParams.toString()}`).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get Division: ', err);
        return of(empty);
      })
    ); */
    return of(cc_divisions.cc_divisions);
  }

  /**
   * get program on the basis of division
   */
  getProgram(divisionId) {
    /*   return this.baseClientService.getArr<any>(`v1/admin/cc-division/programs/${divisionId}`).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get Program: ', err);
        return of(empty);
      })
    ); */
    //console.log("in getProgram"+cc_divisionPrograms.cc_divisionPrograms);
    // console.log("getProgram called in candidate service"+ divisionId)
    // return of(cc_divisionPrograms.cc_divisionPrograms);
    return cc_divisionPrograms.cc_divisionPrograms;
  }

  /**
   * get transferee order request
   */
  getTransferee(reqId, data) {
    // return this.baseClientService.getOne<any>(`v1/admin/candidate/order-request/${reqId}`).pipe(
    //   map(r => r.body), catchError((err, source) => {
    //     const empty: any = null;
    //     console.log('Failed to get transferee request id: ', err);
    //     return of(empty);
    //   })
    // );
    let startDate: any = new Date();
    let authDate: any = new Date();
    startDate.setDate(startDate.getDate() + 10);
    authDate.setDate(authDate.getDate() - 2);
    let resp: any = {};
    if (data.moveStatus === "In Progress") {
      resp = transfereeInprogressData;
    } else if (data.moveStatus === "Benefits Confirmed") {
      resp = transfereeBenefitsConfirmedData;
    }
    resp.candidateDetails.nameDetails = data.nameDetails;
    resp.candidateDetails.departureAddr = data.departureAddr;
    resp.candidateDetails.destinationAddr = data.destinationAddr;
    resp.candidateDetails.additionalDetails.jobStartDate = startDate;
    resp.candidateDetails.authorizationDate = authDate;
    resp.candidateDetails.authorizationType = data.authorizationType;
    resp.candidateDetails.phoneDetailsList[0].phoneNumber =
      data.phoneDetailsList[0].phoneNumber;
    resp.candidateDetails.emailDetailsList[0].emailAddress =
      data.emailDetailsList[0].emailAddress;
    resp.candidateDetails.programDetails.points = data.programDetails.points;
    resp.candidateDetails.programDetails.programName =
      data.programDetails.programName;
    resp.candidateDetails.fileNumber = 4109145;

    return of(resp);
  }

  getSharedSessionCode(
    orderId: string,
    name: string
  ): Observable<{ session: string }> {
    return this.baseClientService
      .post<any>(`v1/admin/candidate/consultant/session`, { orderId, name })
      .pipe(
        map((r) => r.body),
        catchError((err, source) => {
          const empty: any = null;
          console.log("Failed to generate session code : ", err);
          return of(empty);
        })
      );
  }

  /** Mark policy complete of order request Id */
  makePolicyCallComplete(reqId: string) {
    return this.baseClientService
      .put<any>(
        `v1/admin/candidate/order-request/${reqId}/policyCallStatus`,
        {}
      )
      .pipe(
        map((r) => r.body),
        catchError((err, source) => {
          const empty: any = null;
          this.logSvc.logError("Failed to add transferee details" + err);
          console.log("Failed to update candidate profiles" + err);
          return of(empty);
        })
      );
  }

  /**
   * used to update points of candidate
   * @param request order request id whose points are to be updated
   */
  updatePoints(request, obj) {
    const orderIndex = this.tempTransfereeListData.candidates.findIndex(obj => obj.orderRequestId === request);
    if (orderIndex >= 0) {
      if (this.tempTransfereeListData.candidates[orderIndex].hasOwnProperty('pointsOverrideHistory')) {
        this.tempTransfereeListData.candidates[orderIndex]['pointsOverrideHistory'].push(
          {
            "nameDetails": {
              "firstName": this.tempTransfereeListData.candidates[orderIndex].nameDetails.firstName,
              "lastName": this.tempTransfereeListData.candidates[orderIndex].nameDetails.lastName
            },
            "points": obj.points,
            "date": this.dateConversionService.convertToYyyyMmDd(new Date())
          }
        )
      } else {
        this.tempTransfereeListData.candidates[orderIndex]['pointsOverrideHistory'] = [{
          "nameDetails": {
            "firstName": this.tempTransfereeListData.candidates[orderIndex].nameDetails.firstName,
            "lastName": this.tempTransfereeListData.candidates[orderIndex].nameDetails.lastName
          },
          "points": obj.points,
          "date": this.dateConversionService.convertToYyyyMmDd(new Date())
        }]
        this.tempTransfereeListData.candidates[orderIndex]['programDetails'].pointOverride = true;
      }
      this.tempTransfereeListData.candidates[orderIndex]['programDetails'].points = obj.points;
      return of(null);
    }
    else {
      alert("orderIndex not found")
    }
  }

  /**
   * used to update job start date of candidate
   * @param requestId order request id whose Job start Date are to be updated
   * @param obj object required to pass for updating job start date
   */
  updateJobStartDate(requestId, obj) {
    return this.baseClientService
      .put<string>(
        `v1/admin/candidate/order-request/${requestId}/jobstartdate`,
        obj
      )
      .pipe(
        map((r) => r.body),
        catchError((err, source) => {
          const empty: any = null;
          this.logSvc.logError("Failed to update Job start date" + err);
          console.log("Failed to update Job start date" + err);
          return of(empty);
        })
      );
  }

  unconfirmBenefits(orderId: string) {
    // let url = `v1/admin/candidate/order-request/${orderId}/unconfirmBenefit`;
    // return this.baseClientService.put<string>(url, {}).pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     const empty: any = null;
    //     this.logSvc.logError('Failed to unconfirm benefits' + err);
    //     console.log('Failed to unconfirm benefits' + err);
    //     return of(empty);
    //   })
    // );
    return of(orderId);
  }

  unconfirmSelectedBenefit(
    orderId: string,
    productId = null,
    unconfirmBenefit = null,
    confirmedPoints = 0
  ) {
    // let url = `v1/admin/candidate/order-request/${orderId}/unconfirmBenefit`;
    // url += productId ? `/${productId}` : ``;
    // return this.baseClientService.delete<string>(url).pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     const empty: any = null;
    //     this.logSvc.logError('Failed to unconfirm benefit' + err);
    //     console.log('Failed to unconfirm benefit' + err);
    //     return of(empty);
    //   })
    // );
    let points = unconfirmBenefit.points;
    if (unconfirmBenefit.points === "Guaranteed") {
      points = 0;
    } else if (typeof points !== "number" && points.includes(":")) {
      points = points.split(":")[0].split(" ")[0];
    }
    return of({
      unconfirmedBenefits: unconfirmBenefit.reference,
      updatedConfirmedPoints: confirmedPoints - points,
    });
  }

  updateProgramDetails(requestId, obj) {
    return this.baseClientService
      .put<string>(
        `v1/admin/candidate/order-request/${requestId}/changeProgramName`,
        obj
      )
      .pipe(
        map((r) => r.body),
        catchError((err, source) => {
          const empty: any = null;
          this.logSvc.logError("Failed to update program Name" + err);
          console.log("Failed to update program Name" + err);
          return of(empty);
        })
      );
  }

  /**
   * used to update points of candidate
   * @param productId order request id whose points are to be updated
   */
  unlockSubmittedHighValueGoods(productId) {
    return this.baseClientService
      .put<any>(
        `v1/admin/candidate/order-request/benefit/${productId}/hvg/unlock`,
        {}
      )
      .pipe(
        map((r) => r.body),
        catchError((err, source) => {
          const empty: candidateProfile = null;
          this.logSvc.logError("Failed to unlock high value goods" + err);
          console.log("Failed to unlock high value goods" + err);
          return of(empty);
        })
      );
  }

  /**
   * used to reschedule policy Call of candidate
   * @param reqObj object required to pass for rescheduling policy call date
   */
  reSchedulePolicyCall(reqObj: any) {
    const emailList = reqObj.recipientsEmailList.map((item) => {
      return { email: item.emailAddress };
    });
    const calendarInvite: CalenderInvite = {
      options: {
        callback_url:
          "https://example.yourapp.com/cronofy/smart_invite/notifications",
        event: {
          description: "Benefits Builder - Policy call",
          summary: "Benefits Builder - Policy call",
          tzid: reqObj.tzid,
          start: reqObj.start,
          end: reqObj.end,
          location: {
            description: "",
          },
        },
        organizer: {
          name: "Benefits Builder - Policy Call",
        },
        recipients: emailList,
        smart_invite_id: reqObj.smart_invite_id,
      },
      orderId: reqObj.orderId,
      subject: `Policy Call Rescheduled`,
      emailTemplate: `<p>Dear ${reqObj.candidateDetails.nameDetails.firstName}&nbsp;${reqObj.candidateDetails.nameDetails.lastName},</p>
      <p>The Policy Call has been rescheduled by your Consultant.
      Please contact your Consultant in case you are not available on the rescheduled date and time.
      During this call, your Consultant will explain the value of each benefit for which you are eligible and assist you in optimizing
      your points. Following your policy call, you will be able to confirm all or some of your benefits.&nbsp;</p>
      <p>Best wishes,
      <br>Cartus</p>`,
    };

    let httpParams = new HttpParams({
      fromObject: { "transferee-context": reqObj.partyId },
    });

    return this.baseClientService
      .post("v1/cronofy/calendarInvite", calendarInvite, "", httpParams, true)
      .pipe(
        map((r) => r.body),
        catchError((err) => {
          console.log("Failed to Reschedule Policy Call", err);
          const emptyResp = null;
          return of(emptyResp);
        })
      );
  }

  orderIdGenarator() {
    let orderId = "5f893f34689fdf00080cf";
    var timestamp = new Date().getUTCMilliseconds();
    orderId = orderId + timestamp;
    return orderId;
    //return "5f893f34689fdf00080cf286"
  }

  getTodaysDate() {
    let d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  /**get Transferee move order details */
  getMoveDetails(candidatePartyId, clientId,currentTransferee) {
    const payload = {
      candidatePartyId: candidatePartyId,
      clientId: clientId
    }
    
     let selectedtransferees = []
    // return this.baseClientService.post<any>(`v1/admin/candidate/list`,payload).pipe(
    //   map(r => r.body), catchError((err, source) => {
    //     const empty: any = null;
    //     console.log('Failed to get transferee move details: ', err);
    //     return of(empty);
    //   })
    // );
    this.tempTransfereeListData.candidates.forEach (ele => {
      if ((ele.nameDetails.firstName === currentTransferee.nameDetails.firstName) && (ele.nameDetails.lastName = currentTransferee.nameDetails.lastName))  {
      selectedtransferees.push(ele);
      }
    })
    return of(selectedtransferees);
  }
  updateCustomFields(orderId, data: any):Observable<any> {
   // /v1/admin/candidate/order-request/:orderReqId/additional-details
  // return this.baseClientService.put<any>(`v1/admin/candidate/order-request/${orderId}/additional-details`, data).pipe(
  //   map(r => r.body),
  //   catchError(err => {
  //     this.logSvc.logError(err);
  //     const emptyArray = null;
  //       return of(emptyArray);
  //     })
  // );
  return of(
    {
      "code": "ADDITIONAL_DETAIL_UPDATE_SUCCESS",
      "message": "Additional detail updated successfully"
    }
  );
}


   /** get search candidate for duplicate check  */
   getCandidateCheck(reqObj) {
    /* let httpParams: HttpParams = new HttpParams();
    return this.baseClientService.post<any>(`v1/admin/candidate/checkCandidates`, reqObj).pipe(
      map(r => r.body), catchError((err, source) => {
        const empty: any = null;
        console.log('Failed to get cnadidate for duplicate check: ', err);
        return of(empty);
      })
    ); */
    let result = [];
    let filteredTransferee =[];
    filteredTransferee.push(this.tempTransfereeListData.candidates.filter(t => t.nameDetails.firstName == reqObj.name.firstName));
    filteredTransferee.push(this.tempTransfereeListData.candidates.filter(t => t.nameDetails.lastName == reqObj.name.lastName));
    filteredTransferee.push(this.tempTransfereeListData.candidates.filter(t => t.emailDetailsList[0].emailAddress == reqObj.contact.email));
    filteredTransferee.push(this.tempTransfereeListData.candidates.filter(t => t.phoneDetailsList[0].phoneNumber == reqObj.contact.phoneNumber));
    
    const uniqueObjectArray =[];
    let temp =[];
    const uniqueObjectSet = new Set();
    for(const object of filteredTransferee){
      const objectSON = JSON.stringify(object);
      if(!uniqueObjectSet.has(objectSON)){
        uniqueObjectArray.push(object);
      }
      uniqueObjectSet.add(objectSON)
    }
    
    uniqueObjectArray.forEach(element =>{
        element.forEach(i => {
          temp.push(i)
        });
    })
    temp.forEach(element => {
      result.push(this.createCheckCandidateResult(element));
    });
    return of(result);
  }

  createCheckCandidateResult(data) {
    let result = {
      "_id": data.orderRequestId,
      "title": data.nameDetails.title,
      "name": [
        data.nameDetails.firstName,
        data.nameDetails.lastName,
      ],
      "empId": null,
      "departureAddr": {
        "line1": data.departureAddr.streetLine1,
        "city": data.departureAddr.city,
        "state": data.departureAddr.state,
        "country": data.departureAddr.country,
        "postalCode": data.departureAddr.postalCode
      },
      "destinationAddr": {
        "line1": data.destinationAddr.streetLine1,
        "city": data.destinationAddr.city,
        "state": data.destinationAddr.state,
        "country": data.destinationAddr.country,
        "postalCode": data.destinationAddr.postalCode
      },
      "phoneDetailsList": [
        {
          "_id": data.phoneDetailsList[0]._id,
          "textingAvailable": data.phoneDetailsList[0].textingAvailable,
          "primary": data.phoneDetailsList[0].primary,
          "usageType": data.phoneDetailsList[0].usageType,
          "phoneDialCode": data.phoneDetailsList[0].phoneDialCode,
          "phoneNumber": data.phoneDetailsList[0].phoneNumber
        }
      ],
      "emailDetailsList": [
        {
          "_id": data.emailDetailsList[0]._id,
          "primary": data.emailDetailsList[0].primary,
          "usageType": data.emailDetailsList[0].usageType,
          "emailAddress": data.emailDetailsList[0].emailAddress
        }
      ]
    }
    return result;
  }

    /** for traditional transferee get employee type */
    getEmployeeType(): Observable<any> {
     /*  return this.baseClientService
      .getOne<any>(`v1/admin/value-list?key=EmployeeType`)
      .pipe(
        map(r => r.body),
        catchError(err => {
          this.logSvc.logError(err);
          const empty = null;
          return of(empty);
        })
      ) */
      return of (employeeType);
    }
    
    /**ValueList api for traditional flow */
    getValueList(): Observable<any> {
      /*  return this.baseClientService
       .getOne<any>(`v1/admin/value-list?key=EmployeeType`)
       .pipe(
         map(r => r.body),
         catchError(err => {
           this.logSvc.logError(err);
           const empty = null;
           return of(empty);
         })
       ) */
       return of (valueList);
     }

    /**ValueList api for traditional flow */
    getClientContactList(): Observable<any> {
      /*  return this.baseClientService
       .getOne<any>(`v1/admin/value-list?key=EmployeeType`)
       .pipe(
         map(r => r.body),
         catchError(err => {
           this.logSvc.logError(err);
           const empty = null;
           return of(empty);
         })
       ) */
       return of (cciList);
     }

     getContractList(): Observable<any> {
       return of (contracts);
     }

     getMoveList(): Observable<any> {
       return of (moveTypes);
     }
	 


/**TEMP LIVING WORK ORDER STARTS HERE */

/**
 * Method to add AssessNeeds Items    NEED TO CORRECT
 * @param assessNeeds
 */
  addAssessNeedsItem(assessNeeds: AssessNeeds, housingPreference: HousingPreference, customerRelocationDetails: CustomerRelocationDetails, bookingAgent: BookingAgent, submit: boolean, ID): Observable<any> {
    // let reqbody = { needsAssessment: assessNeeds, housingPreference: housingPreference, customerRelocationDetails: customerRelocationDetails, bookingAgent: bookingAgent, submit }
    // // addAssessNeedsItem(assessNeeds: AssessNeeds, ID): Observable<any> {
    // //   let reqbody= {needsAssessment:assessNeeds}
    // return this.baseClientService
    //   .put(`v1/admin/candidate/supplier-order-request/${ID}`, reqbody)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(err);
    //     })
    //   );
    return of ({"code":"SUPPLIER_ORDER_UPDATE_SUCCESS","message":"Supplier Order successfully updated","supplierOrderStatus":"in-progress"});
  }
  
/**
* Method to add orderRequest       NEED TO CORRECT
* @param orderRequest
*/
  addOrderRequest(assessNeeds: AssessNeeds, housingPreference: HousingPreference, customerRelocationDetails: CustomerRelocationDetails, bookingAgent: BookingAgent, submit: boolean, ID): Observable<any> {
    // let reqbody = { needsAssessment: assessNeeds, housingPreference: housingPreference, customerRelocationDetails: customerRelocationDetails, bookingAgent: bookingAgent, submit }
    // return this.baseClientService.post<any>(`v1/admin/candidate/supplier-order-request/${ID}`, reqbody).pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     const empty: any = null;
    //     return of(empty);
    //   })
    // );
    return of ({"Message":"Added Successfully","Cloned product":"62986d9226fc8600098ba330","New Supplier Req":"62986d9422d8ca000941a3f4","Move Order req":"62675c72e877e700095e2f95"})
  }

    getOrderPriority(): Observable<any> {
      // return this.baseClientService
      // .getOne<any>(`v1/admin/value-list?key=OrderPriority`)
      // .pipe(
      //   map(r => r.body),
      //   catchError(err => {
      //     this.logSvc.logError(err);
      //     const empty = null;
      //     return of(empty);
      //   })
      // )
      return of (orderPriority);
    }

    //for work order popup  
    getWorkorderDetails(reqId,orderid) {
    // let orderId= !orderid;
    // return this.baseClientService.getOne<any>(`v1/admin/candidate/supplier-order-request/${reqId}?orderFlag=${orderId}`).pipe(
    //   map(r => r.body), catchError((err, source) =>{
    //     const empty: any = null;
    //     console.log('failed to get details: ',err);
    //     return of(empty);
        
    //   })
    // );
    return of (tempLivingData);
  }
  
   //for work order popup currency list  
  getCurrencies(): Observable<any> {
    // return this.baseClientService
    // .getOne<any>(`v1/admin/value-list?key=Currencies`)
    // .pipe(
    //   map(r => r.body),
    //   catchError(err => {
    //     this.logSvc.logError(err);
    //     const empty = null;
    //     return of(empty);
    //   })
    // )
    return of (currencyData);
  }

  /**
* Method to get bookingDetails Items
* @param bookingDetails
*/
  getBookingDetails(id) {
    // return this.baseClientService.getOne<any>(`v1/admin/candidate/order/${id}/booking-details`).pipe(
    //   map(r => r.body), catchError((err, source) => {
    //     const empty: any = null;
    //     console.log('failed to get details: ', err);
    //     return of(empty);
    //   })
    // )
    return of (bookingDetailsData);
  }

  /**
  * Method to update bookingDetails Items
  * @param updatebookingDetails
  */
  updateBookingDetails(bookingOrderData: bookingDetails, id): Observable<any> {
    // return this.baseClientService
    //   .put(`v1/admin/candidate/order/${id}/booking-details`, bookingOrderData)
    //   .pipe(map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    return of ({"code":"SUPPLIER_ORDER_UPDATE_SUCCESS","message":"Order successfully updated","supplierOrderStatus":"in-progress"})
  }

  /**
   * Method to accept order 
   * @param acceptOrder 
   */
  acceptOrder(id, modifyID): Observable<any> {
    return this.baseClientService.put<any>(`v1/admin/candidate/order/${id}/modification/${modifyID}/accept`, {}).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        return of(empty);
      })
    );
  }

  /**
     * Method to reject Order 
     * @param rejectOrder
     */

  rejectOrder(id): Observable<any> {
    return this.baseClientService.put<any>(`v1/admin/candidate/order/${id}/modification/reject`, {}).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        return of(empty);
      })
    );
  }

  deleteWorkOrder(reqId,orderid) {
    let orderId= !orderid;
    return this.baseClientService.delete<any>(`v1/admin/candidate/supplier-order-request/${reqId}?orderFlag=${orderId}`).pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty: any = null;
        this.logSvc.logError('Failed to unconfirm benefit' + err);
        console.log('Failed to unconfirm benefit' + err);
        return of(empty);
      })
    );
  }

  /**WORK ORDER ENDS HERE */
}

//Stub data for checkCandidateResult Api.
export const checkCandidateResult  =
[
  {
      "_id": "60e69444f5734f000923bfec",
      "title": "Dr.",
      "name": [
          "Evelyn",
          "Darcy"
      ],
      "empId": null,
      "departureAddr": {
          "line1": "343, Pitt Street",
          "city": "Sydney",
          "state": "New South Wales",
          "country": "Australia",
          "postalCode": "2000"
      },
      "destinationAddr": {
          "line1": "341, Orient Street, Arcadia",
          "city": "Pretoria",
          "state": "Gauteng",
          "country": "South Africa",
          "postalCode": "0007"
      },
      "phoneDetailsList": [
          {
              "_id": "60e6944037dfd60008c2a907",
              "textingAvailable": true,
              "primary": true,
              "usageType": "personal",
              "phoneDialCode": 91,
              "phoneNumber": "8908462287"
          }
      ],
      "emailDetailsList": [
          {
              "_id": "60e6944137dfd60008c2a908",
              "primary": true,
              "usageType": "business",
              "emailAddress": "souyam.kasta@mobilitydba.com"
          }
      ]
  },
  {
      "_id": "60e6ea2a8445ce00093cbabd",
      "title": "Dr.",
      "name": [
          "Andy",
          "TSTMurray"
      ],
      "empId": null,
      "departureAddr": {
          "line1": "E3569, 750th Avenue",
          "city": "Menomonie",
          "state": "Wisconsin",
          "country": "United States",
          "postalCode": "54751"
      },
      "destinationAddr": {
          "line1": "433, West Harrison Street, South Loop",
          "city": "Chicago",
          "state": "Illinois",
          "country": "United States",
          "postalCode": "60607"
      },
      "phoneDetailsList": [
          {
              "_id": "60e6ea27d0e8610008f1ac9d",
              "textingAvailable": true,
              "primary": true,
              "usageType": "personal",
              "phoneDialCode": 91,
              "phoneNumber": "23432432432"
          }
      ],
      "emailDetailsList": [
          {
              "_id": "60e6ea28d0e8610008f1ac9e",
              "primary": true,
              "usageType": "business",
              "emailAddress": "test@test.com"
          }
      ]
  }
]
export const employeeType = 
{
	"values": [
		"Candidate",
		"College Graduate",
		"Existing Employee",
		"Experienced New Hire",
		"Inexperienced New Hire",
		"Intern",
		"New Employee",
		"New Hire",
		"Non New Hire",
		"Separation",
		"Transfer"
	]
}
