import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LinkedListData } from '../../../../core/models/linked-list-data.model';
import { Sentiment } from '../../../../core/models/sentiment.model';
import { DateConversionService } from '../../../../core/services/date-conversion.service';
import { SentimentService } from '../../../../core/services/sentiment.service';
import { ModalData } from '../../../../core/services/popup-position.service';
import { Sentiments } from '../../../../core/models/sentiments.model';

/** EmployeeRecentSentimentComponent to set the Recent Sentiment of Employee */
@Component({
  selector: 'app-employee-recent-sentiment',
  templateUrl: './employee-recent-sentiment.component.html',
  styleUrls: ['./employee-recent-sentiment.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeRecentSentimentComponent implements OnInit, OnDestroy {
  /**recentSentiments  variable to store the response data */
  recentSentimentsSub: Subscription;

  /** holds the data for the Linked list component */
  keyDatesData: LinkedListData[] = [];

  /** Base constructor method
   * @param sentimentService SentimentService injection
   * @param dateSvc Date converstion service  injection
   * @param dialogRef MatDialogRef<EmployeeRecentSentimentComponent> injection
   * @Inject MAT_DIALOG_DATA to get the data from the parent component
   * @param data to get the data through MAT_DIALOG_DATA
   */
  constructor(
    private readonly sentimentService: SentimentService,
    private readonly dateSvc: DateConversionService,
    @Inject(ModalData) public data: any,
    private readonly router: Router
  ) {}

  /** viewAll function to view all recent sentiment */
  viewAll(): void {
    this.router.navigate(['/bbadmin/administration/empInfo/' + this.data.fileId], { queryParams: { sentimentView: 'open' }} );
    this.close();
  }

  /** get the recent sentiment data and setup the key dates data */
  ngOnInit() {
      this.createKeyDatesData(this.data.sentiments);
  }

  /** setup the key dates data for the linked list component */
  createKeyDatesData(data: Sentiments): void {
    // tslint:disable-next-line: prefer-const
    let sortedList: Sentiment[] = data.timeLine;
    sortedList.sort((a, b) =>
      this.dateSvc.convertToEpoch(a.sentimentCompletedDate) -
      this.dateSvc.convertToEpoch(b.sentimentCompletedDate)
    );
    sortedList.reverse();
    sortedList.slice(0, 5).forEach(element => {
      this.keyDatesData.push({
        value: this.dateSvc.convertToDisplayDate(element.sentimentCompletedDate.toString()),
        valueClass: 'active',
        columns: [
          {
            label: element.sentimentName
          },
          {
            label: element.sentimentLevel,
            type: 'star'
          }
        ]
      } as LinkedListData);
    });
  }

  /** cleanup the subscription and anything else necessary */
  ngOnDestroy(): void {
    if (this.recentSentimentsSub) {
      this.recentSentimentsSub.unsubscribe();
    }
  }

  /** To close the dialog */
  close() {
    this.data.overlayRef.dispose();
  }
}
