import { AggregationView } from './../../core/models/aggregation-view.model';
import { Injectable } from '@angular/core';
import { Observable, of,from } from 'rxjs';
import { catchError, groupBy, map, mergeMap, toArray } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { AggregationFetchparameter } from '../models/aggregation-fetch-parameter.model';
import { HttpParams } from '@angular/common/http';
import { employeeData } from '../data/data';
import { element } from 'protractor';
import moment from 'moment';
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';


/** Destination Country Service to fetch data from Api and to display the data in the table format under aggregation view */
@Injectable({
  providedIn: "root",
})
export class AggregationViewService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
   tempEmployeeData = {"data": new Array};
  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private moveProDashboadDataService: MoveProDashboadDataService
  ) {}

  /** getDestCountry method to fetch location data from Api to display in aggregation table */
  getAggregateView(
    aggregationType: string,
    aggregationFetchParameter: AggregationFetchparameter
  ): Observable<AggregationView> {
    const params = new HttpParams({
      fromObject: {
        itemsPerPage: aggregationFetchParameter.itemsPerPage.toString(),
        pageNumber: aggregationFetchParameter.pageNumber.toString(),
        groupBy: aggregationType,
        searchText: aggregationFetchParameter.searchText
          ? aggregationFetchParameter.searchText
          : "",
        currencyAbbr: aggregationFetchParameter.preferredCurr
          ? aggregationFetchParameter.preferredCurr
          : null,
      },
    });
    // return this.baseClient
    //   .getOne<AggregationView>(
    //     'v1/aggregateDashboard',
    //     'get the Aggregation view details',
    //     params
    //   )
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty: AggregationView = null;
    //       this.logSvc.logError(err);
    //       return of(empty);
    //     })
    //   );
    this.moveProDashboadDataService.employeeDataSubject.subscribe(
      data => {
       this.tempEmployeeData = data;
      }
    )
    if (aggregationType === "policyName") {
      return of(this.displayPolicy("policyName"));
    }
    if (aggregationType === "divisionName") {
      return of(this.displayDivision("divisionName"));
    }
    if (aggregationType === "departureCountry") {
      return of(this.displayDeparture("departureCountry"));
    }
    if (aggregationType === "destinationCountry") {
      return of(this.displayDestination("destinationCountry"));
    }
  }
  getEmployee(parent, aggregationType) {
    let aggregationDatas = {
      data: [],
      count: 0,
    };
    let aggregationListes = [];
    parent.forEach((ele) => {
      aggregationListes.push(this.createAggregation(ele, aggregationType));
    });
    aggregationDatas.data = aggregationListes;
    aggregationDatas.count = aggregationListes.length;
    return aggregationDatas;
  }
  createAggregation(data, type) {
    let value = "";
    let activeEmployees = 0;
    let divisionName = "";
    let divisionNumber = "";
    let totalChange;
    let curentCurrency;
    let pastCurrency;
    if (type == "policyName") {
      if (data.currentCost == 0) {
        data.currentCost = "N/A";
      }
      if (data.pastCost == 0) {
        data.pastCost = "N/A";
      }
      if (data.currentCost !== "N/A" && data.pastCost !== "N/A") {
        totalChange = (data.currentCost - data.pastCost) / data.pastCost;
      }
      if(data.currentCost == 'N/A' && data.pastCost == 'N/A') {
        data.currentCurrency = "";
        data.pastCurrency = "";
      }
      curentCurrency = data.currentCurrency;
      pastCurrency = data.pastCurrency;
      value = data.policyName;
      activeEmployees = data.numberOfPolicy;
      divisionNumber = data.divisionNumber;
      divisionName = data.divisionName;
    }
    if (type == "divisionName") {
      type = "divisionNumber";
      if (data.currentCost == 0) {
        data.currentCost = "N/A";
      }
      if (data.pastCost == 0) {
        data.pastCost = "N/A";
      }
      if (data.currentCost !== "N/A" && data.pastCost !== "N/A") {
        totalChange = (data.currentCost - data.pastCost) / data.pastCost;
      }
      if(data.currentCost == 'N/A' && data.pastCost == 'N/A') {
        data.currentCurrency = "";
        data.pastCurrency = "";
      }
      curentCurrency = data.currentCurrency;
      pastCurrency = data.pastCurrency;
      value = data.divisionNumber;
      activeEmployees = data.numberOfdivision;
      divisionNumber = data.divisionNumber;
      divisionName = data.divisionName;
    }
    if (type == "departureCountry") {
      if (data.currentCost == 0) {
        data.currentCost = "N/A";
      }
      if (data.pastCost == 0) {
        data.pastCost = "N/A";
      }
      if (data.currentCost !== "N/A" && data.pastCost !== "N/A") {
        totalChange = (data.currentCost - data.pastCost) / data.pastCost;
      }
      if(data.currentCost == 'N/A' && data.pastCost == 'N/A') {
        data.currentCurrency = "";
        data.pastCurrency = "";
      }
      curentCurrency = data.currentCurrency;
      pastCurrency = data.pastCurrency;
      value = data.departureCountry;
      activeEmployees = data.numberOfCountry;
      divisionNumber = data.divisionNumber;
      divisionName = data.divisionName;
    }
    if (type == "destinationCountry") {
      if (data.currentCost == 0) {
        data.currentCost = "N/A";
      }
      if (data.pastCost == 0) {
        data.pastCost = "N/A";
      }
      if (data.currentCost !== "N/A" && data.pastCost !== "N/A") {
        totalChange = (data.currentCost - data.pastCost) / data.pastCost;
      }
      if(data.currentCost == 'N/A' && data.pastCost == 'N/A') {
        data.currentCurrency = "";
        data.pastCurrency = "";
      }
      curentCurrency = data.currentCurrency;
      pastCurrency = data.pastCurrency;
      value = data.destinationCountry;
      activeEmployees = data.numberOfCountry;
      divisionNumber = data.divisionNumber;
      divisionName = data.divisionName;
    }
    return {
      activeEmployees: activeEmployees,
      currentYtdCosts: data.currentCost,
      currentYtdCurr: curentCurrency,
      pastYtdCosts: data.pastCost,
      pastYtdCurr: pastCurrency,
      changeOfTotalCost: totalChange,
      aggregationType: type,
      moveAtRiskCount: 0,
      moveOnTrackCount: 0,
      moveRiskNACount: data.moveRiskNA.length,
      moveRiskCompleteCount: data.moveRiskComplted.length,
      aggregationName: value,
      divisionNumber: divisionNumber,
      divisionName: divisionName,
    };
  }
  formatDate(effectiveTransferDate) {
    return moment(effectiveTransferDate).format("YYYY");
  }
  displayPolicy(aggregationType) {
    let parentList = this.tempEmployeeData.data;
    const thisYear = new Date().getFullYear();
    const lastYear = thisYear - 1;
    let policylist = [];
    const source = from(parentList);
    source
      .pipe(
        groupBy((person) => person.policyName),
        mergeMap((group) => group.pipe(toArray()))
      )
      .subscribe((val) => {
        policylist.push(val);
      });
    let policyDataArray = [];
    policylist.forEach((ele) => {
      let polvalue = {
        policyName: "",
        numberOfPolicy: 0,
        moveRiskComplted: [],
        moveRiskNA: [],
        currentCost: 0,
        pastCost: 0,
        currentCurrency:"",
        pastCurrency:""
      };
      ele.forEach((element) => {
        polvalue.policyName = element.policyName;
        polvalue.numberOfPolicy = ele.length;
        if (element.moveRisk == 4) {
          polvalue.moveRiskComplted.push(element.length);
        }
        if (element.moveRisk == 3) {
          polvalue.moveRiskNA.push(element.length);
        }
        if (
          parseInt(this.formatDate(element.effectiveTransferDate)) === thisYear
        ) {
          polvalue.currentCost = polvalue.currentCost + element.totalCost;
          polvalue.currentCurrency = element.totalCostCurr;
        }
        if (
          parseInt(this.formatDate(element.effectiveTransferDate)) === lastYear
        ) {
          polvalue.pastCost = polvalue.pastCost + element.totalCost;
          polvalue.pastCurrency = element.totalCostCurr;
        }
      });
      policyDataArray.push(polvalue);
    });
    return this.getEmployee(policyDataArray, aggregationType);
  }
  displayDivision(aggregationType) {
    let parentList = this.tempEmployeeData.data;
    const thisYear = new Date().getFullYear();
    const lastYear = thisYear - 1;
    let divisionList = [];
    const source = from(parentList);
    source
      .pipe(
        groupBy((person) => person.divisionName),
        mergeMap((group) => group.pipe(toArray()))
      )
      .subscribe((val) => {
        divisionList.push(val);
      });
    console.log(divisionList);
    let divisionDataArray = [];
    divisionList.forEach((ele) => {
      let polvalue = {
        divisionName: "",
        numberOfdivision: 0,
        moveRiskComplted: [],
        moveRiskNA: [],
        divisionNumber: 0,
        currentCost: 0,
        pastCost: 0,
        currentCurrency:"",
        pastCurrency:""
      };
      ele.forEach((element) => {
        polvalue.divisionName = element.divisionName;
        polvalue.divisionNumber = element.divisionNumber;
        polvalue.numberOfdivision = ele.length;
        if (element.moveRisk == 4) {
          polvalue.moveRiskComplted.push(element.length);
        }
        if (element.moveRisk == 3) {
          polvalue.moveRiskNA.push(element.length);
        }
        if (
          parseInt(this.formatDate(element.effectiveTransferDate)) === thisYear
        ) {
          polvalue.currentCost = polvalue.currentCost + element.totalCost;
          polvalue.currentCurrency = element.totalCostCurr;
        }
        if (
          parseInt(this.formatDate(element.effectiveTransferDate)) === lastYear
        ) {
          polvalue.pastCost = polvalue.pastCost + element.totalCost;
          polvalue.pastCurrency = element.totalCostCurr;
        }
      });
      divisionDataArray.push(polvalue);
    });
    return this.getEmployee(divisionDataArray, aggregationType);
  }
  displayDeparture(aggregationType) {
    let parentList = this.tempEmployeeData.data;
    const thisYear = new Date().getFullYear();
    const lastYear = thisYear - 1;
    let departurelist = [];
    const source = from(parentList);
    source
      .pipe(
        groupBy((person) => person.departureCountry),
        mergeMap((group) => group.pipe(toArray()))
      )
      .subscribe((val) => {
        departurelist.push(val);
      });
    let departureDataArray = [];
    departurelist.forEach((ele) => {
      let polvalue = {
        departureCountry: "",
        numberOfCountry: 0,
        moveRiskComplted: [],
        moveRiskNA: [],
        currentCost: 0,
        pastCost: 0,
        currentCurrency:"",
        pastCurrency:""
      };
      ele.forEach((element) => {
        polvalue.departureCountry = element.departureCountry;
        polvalue.numberOfCountry = ele.length;
        if (element.moveRisk == 4) {
          polvalue.moveRiskComplted.push(element.length);
        }
        if (element.moveRisk == 3) {
          polvalue.moveRiskNA.push(element.length);
        }
        if (
          parseInt(this.formatDate(element.effectiveTransferDate)) === thisYear
        ) {
          polvalue.currentCost = polvalue.currentCost + element.totalCost;
          polvalue.currentCurrency = element.totalCostCurr;
        }
        if (
          parseInt(this.formatDate(element.effectiveTransferDate)) === lastYear
        ) {
          polvalue.pastCost = polvalue.pastCost + element.totalCost;
          polvalue.pastCurrency = element.totalCostCurr;
        }
      });
      departureDataArray.push(polvalue);
    });
    return this.getEmployee(departureDataArray, aggregationType);
  }
  displayDestination(aggregationType) {
    let parentList = this.tempEmployeeData.data;
    const thisYear = new Date().getFullYear();
    const lastYear = thisYear - 1;
    let desnationlist = [];
    const source = from(parentList);
    source
      .pipe(
        groupBy((person) => person.destinationCountry),
        mergeMap((group) => group.pipe(toArray()))
      )
      .subscribe((val) => {
        desnationlist.push(val);
      });
    let destinationDataArray = [];
    desnationlist.forEach((ele) => {
      let polvalue = {
        destinationCountry: "",
        numberOfCountry: 0,
        moveRiskComplted: [],
        moveRiskNA: [],
        currentCost: 0,
        pastCost: 0,
        currentCurrency:"",
        pastCurrency:""
      };
      ele.forEach((element) => {
        polvalue.destinationCountry = element.destinationCountry;
        polvalue.numberOfCountry = ele.length;
        if (element.moveRisk == 4) {
          polvalue.moveRiskComplted.push(element.length);
        }
        if (element.moveRisk == 3) {
          polvalue.moveRiskNA.push(element.length);
        }
        if (
          parseInt(this.formatDate(element.effectiveTransferDate)) === thisYear
        ) {
          polvalue.currentCost = polvalue.currentCost + element.totalCost;
          polvalue.currentCurrency = element.totalCostCurr;
        }
        if (
          parseInt(this.formatDate(element.effectiveTransferDate)) === lastYear
        ) {
          polvalue.pastCost = polvalue.pastCost + element.totalCost;
          polvalue.pastCurrency = element.totalCostCurr;
        }
      });
      destinationDataArray.push(polvalue);
    });
    return this.getEmployee(destinationDataArray, aggregationType);
  }
}

