import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-consultant-only-modal',
  templateUrl: './consultant-only-modal.component.html',
  styleUrls: ['./consultant-only-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ConsultantOnlyModalComponent implements OnInit {
  selectedOption: string;
  
  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<ConsultantOnlyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit() {
    this.data.benefit.consultantBenefitInfo ? this.selectedOption = this.data.benefit.consultantBenefitInfo.qualifyingInput : null;
  }

  /**
	* to save
	*/
  save() {
    this.dialogRef.close({consultant: true, qualifyingInput : +this.selectedOption});
  }

  /**
	* To to cancel dialogbox
	*/
  cancel() {
    this.selectedOption = null;
    if (this.data.benefit.consultantBenefitInfo) {
      this.data.benefit.consultantBenefitInfo.consultant = this.data.benefit.consultantBenefitInfo.qualifyingInput ? true : false;
    }
    this.dialogRef.close(this.data.benefit.consultantBenefitInfo);
  }
}
