import { Component, OnInit, Inject, OnDestroy, ViewEncapsulation, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { LinkedListData } from '../../../../core/models/linked-list-data.model';
import { MovePhaseService } from '../../../../core/services/move-phase.service';
import { ModalData } from '../../../../core/services/popup-position.service';

/** Move Phase component to display the move phase details of the employee in a pop up */
@Component({
  selector: 'app-employee-move-phase',
  templateUrl: './employee-move-phase.component.html',
  styleUrls: ['./employee-move-phase.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmployeeMovePhaseComponent implements OnInit, OnDestroy {
  @Input() selectedMovePhaseNo: any ;
  @Input() traditional: boolean;
  /** empMovePhase array variable of type LinkedListData[] */
  public empMovePhases: LinkedListData[] = [];

  /** sub variable of type Subscription*/
  public sub: Subscription;

  /** Base constructor method
   * @param movePhaseService MovePhaseService injection
   * @param data to get the data through ModalData
   */
  constructor(private readonly movePhaseService: MovePhaseService,
    @Inject(ModalData) public data: any) { }

  /** Component Angular initialization lifecycle hook */
  ngOnInit() {
    this.sub = this.movePhaseService.getMovePhases().subscribe(movePhases => {


      /**Functionailty to avoid N/A */
      const index: number = movePhases.findIndex(it => it.key === '0');
      if (index >= 0) {
        movePhases.splice(index, 1);
      }

      Object.assign(this.empMovePhases, movePhases.map(movePhase => {
        const valueClass: string = this.getValueClass(movePhase.key);
        return {
          value: movePhase.key,
          valueClass: valueClass,
          columns: [
            {
              label: movePhase.value
            }
          ]
        } as LinkedListData;
      }));
    });
  }

  /** getValueClass  method to assign value to valueClass variable in LinkedListData */
  getValueClass(movePhaseNumber): string {
    /* return Number(movePhaseNumber) === this.data.movePhaseNo ? 'active'
      : (Number(movePhaseNumber) > this.data.movePhaseNo ? 'notDone' : 'done'); */
      return Number(movePhaseNumber) === (this.traditional ? this.selectedMovePhaseNo : this.data.movePhaseNo) ? 'active' : (Number(movePhaseNumber) > (this.traditional ? this.selectedMovePhaseNo : this.data.movePhaseNo) ? 'notDone' : 'done');
  }

  /** To close the dialog */
  close() {
    this.data.overlayRef.dispose();
  }

  /** Component Angular destructor lifecycle hook */
  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }
}
