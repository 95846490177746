<div tabindex="0" class="downloadReportContainer">
  Download: 
  <span class="menu-style" [matMenuTriggerFor]="downloadOptionsMenu">
    <span class="menuIconBlock">
      <mat-icon>save_alt</mat-icon>
      <mat-icon class="expandMore">arrow_drop_down</mat-icon> 
      <mat-icon class="expandLess">arrow_drop_up</mat-icon>
    </span>
  </span>
  <mat-menu class="downloadOptionsMenu" #downloadOptionsMenu="matMenu">
      <p class="clear-cache-warning">
        Downloading files with sensitive information is a security risk, please clear your cache afterwards
      </p>
      <button mat-menu-item (click)="downloadExcelReport(reportName)">
        Download XLSX
      </button>
  </mat-menu> 
</div>
