import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatMenuTrigger, MatToolbar } from '@angular/material';
import { Router } from '@angular/router';
import { NeedsAssessmentSharedService } from '../../../../../src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { Subscription } from 'rxjs';
import { TransfereeNeedsAssessment } from '../../models/candidateneeds-assessment.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { notificationMessage } from '../../models/constants';
import { ViewChild } from '@angular/core';
import { PolicyModalComponent } from '../../../../../src/app/public/dashboard/components/policy-modal/policy-modal.component';
import { TasksInfoService } from '../../services/tasks-info.service';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition } from '@angular/material';
import { ToastIdleTimeoutComponent } from '../../../public/toast-idle-timeout/toast-idle-timeout.component';
import { LocationsService } from '../../../public/needs-assessment/services/locations.service';
import { AppDataService } from '../../services/app-data.service';
import { CookieService } from 'ngx-cookie-service';
import { benefitsData, consentinfodata, countryinfodata, familydata, informativetextdata, taskData, taskInfo, timelineData, transfereeNeedsAssessmentData } from '../../data/app-data';
import { BenefitsData, BenefitService } from '../../../public/benefits/services/benefit.service';
import { DashboardService } from '../../../public/dashboard/services/dashboard.service.service';
import { FamilyInfoService } from '../../services/family-info.service';
import { PersonalInfoService } from '../../services/personal-info.service';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
    initials: string;
    latest: any;
    older: any;
    move: any;
    tempCountryList: any;
    username: string;
    onHover: boolean;
    clickCoBrowsing: boolean = false;
    clickConsultantView: boolean = false;

    // tslint:disable-next-line: no-output-rename
    @Output('openSideNav') openSideNav: EventEmitter<any> = new EventEmitter();
    @Output('coBrowsing') showCoBrowsing: EventEmitter<any> = new EventEmitter();
    @Input() isNeedsAssessment;
    /** List of notification items to be displayed */
    @Input() notificationList: Array<{
        header,
        reference,
        message,
        key
    }>;
    /** Flag to open notifications menu */
    @Input() openNotificationMenu: boolean;
    /** Flag to indicate notification menu has been closed */
    @Output() menuClosed: EventEmitter<boolean> = new EventEmitter();
    /** Notification menu trigger */
    @ViewChild('notificationMenuTrigger', {
        static: false
    }) notificationTrigger: MatMenuTrigger;
    listOfNotifications: any = null;
    languages : Array<any> = [];
    subsriptionList: Subscription;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'bottom';

    /**     *
     * @param needsAssessmentShared
     * @param router
     * @param spinner
     * @param tasksService
     * @param toastr
     * @param dialog
     */
    constructor(
        private readonly needsAssessmentShared: NeedsAssessmentSharedService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private readonly tasksService: TasksInfoService,
        private readonly locationService: LocationsService,
        private readonly appDataService: AppDataService,
        private cookieService: CookieService, 
        private toastr: MatSnackBar,
        public dialog: MatDialog,
        private translate: TranslateService,
        private readonly benefitService: BenefitService,
        private needsAssessmentService: NeedsAssessmentSharedService,
        private readonly dashboardService: DashboardService,
        private readonly benefitsService: BenefitService,
        private readonly familyInfoService: FamilyInfoService,
        private readonly personalInfoSevice: PersonalInfoService
    ) {
        translate.addLangs(['en', 'fr', 'sp']);
        this.languages.push({"text": "English", "value":"en"});
        this.languages.push({"text": "French", "value":"fr"});
        this.languages.push({"text": "Spanish", "value":"sp"});
    }

    ngOnInit() {
        this.loadHeaderData();
        this.latest = [];
        this.older = [];
        this.appDataService.bbdata.subscribe(data => {
            this.move = data;
            this.locationService.countryList.subscribe(countryListData => {
              if (countryListData.length > 0 && this.move.orderRequestDetails.length > 0) {
                this.move.orderRequestDetails.forEach(data => {
                  if (data.departureAddr) {
                    this.tempCountryList = countryListData.find(ele => ele.countryName === data.departureAddr.country)
                    data.departureAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
                  }
                  if (data.destinationAddr) {
                    this.tempCountryList = countryListData.find(ele => ele.countryName === data.destinationAddr.country)
                    data.destinationAddr['countryCode'] = this.tempCountryList.countryiso3CharCode;
                  }
                });
              }
            });
            if (data) {
              this.older = [];
              for (var i = 0; i < data.orderRequestDetails.length; i++) {
                if (i == 0) {
                  this.latest = data.orderRequestDetails[0];
                }
                else {
                  this.older.push(data.orderRequestDetails[i]);
                }
              }
            }
          });
    }

    ngOnChanges() {
        this.listOfNotifications = this.notificationList && this.notificationList.length > 0 ? this.notificationList : [];
        this.openNotificationMenu ? this.notificationTrigger.openMenu() : null;
        if(this.listOfNotifications.length > 0) {
                let index = this.notificationList.findIndex(item => {return item.reference == "policy call"})
                this.tasksService.taskInformation.subscribe(data => {
                if (data && data.policyCall && data.policyCall.status == 'scheduled' && index > -1) {
                  this.notificationList.splice(index, 1); 
                  this.listOfNotifications = this.notificationList && this.notificationList.length > 0 ? this.notificationList : [];
                }
                });
                this.benefitService.contactCardInformation.subscribe(res => {
                  if(res){
                          this.listOfNotifications = this.notificationList.filter(ele => ele.key !== res);
                 }
              })
              }
      }
    
    
    /**
     * To open navigation menu
     */
    openNav() {
        this.openSideNav.emit();
    }
    /**
     * To show co-browsing
     */
    coBrowsing(user) {
        this.clickCoBrowsing = true;
        (user !== 'Transferee') ? (this.clickConsultantView = true) : (this.clickConsultantView = false);
        (user === 'Offline') ? (this.clickCoBrowsing = false) : (this.clickCoBrowsing = true);
        this.showCoBrowsing.emit(user);
    }
    /**
     * To close navigation menu
     */
    notificationClosed() {
        this.menuClosed.emit(false);
    }

    openPopup() {
        this.toastr.openFromComponent(ToastIdleTimeoutComponent, {
            // duration:120000,
            verticalPosition:this.verticalPosition,
            horizontalPosition: this.horizontalPosition,		  
        });
    }

    /**
     * To load header information
     */
    loadHeaderData() {
        this.subsriptionList = this.needsAssessmentShared.transfereeNeedsAssessmentDetails.subscribe(data => {
            if (!data) {
                return;
            }
            const consultantDetails = sessionStorage.getItem('co-browse');
            if (consultantDetails) {
                this.consultantName(JSON.parse(consultantDetails));
            } else {
                const userDetails: TransfereeNeedsAssessment = data;
                //  this.username = `${userDetails.nameDetails.firstName} ${userDetails.nameDetails.lastName}`;
                this.username = `${userDetails.nameDetails.firstName}`;
                this.initials = `${(userDetails.nameDetails.firstName).charAt(0)}${(userDetails.nameDetails.lastName).charAt(0)}`;
            }
        });
    }

    /**
     * To redirect user to appropriate actions by notification
     * @param notificationDetail notification information
     */
    goToNotification(notificationDetail) {
        if (notificationDetail.reference === 'policy call') {
            // To open policy modal component
            this.getCronofyElementsToken(notificationDetail);
        } else if (notificationDetail.reference === 'family info') {
            this.router.navigate(['/bb/profile-info', '1']);
        } else if (notificationDetail.reference === 'tempLiving') {
          this.router.navigate(['/bb/benefits/confirmed/tempLiving']);
        } else {
            this.router.navigate(['/bb/benefits/confirmed/hvb']);
        }
    }

    getCronofyElementsToken(notificationDetail) {

        // Need to check if consultant is assigned or not.
        if (!notificationDetail.consultantDetails) {
            this.toastr.open('Consultant is not assigned. Try again later or contact support', null, {
                duration: 5000
            });
            return;
        }

        this.spinner.show();
        const cronofyId = sessionStorage.getItem('cronofyId');
        this.tasksService.getCronofyToken(cronofyId).subscribe(res => {
            if (res) {
                this.spinner.hide();
                this.dialog.open(PolicyModalComponent, {
                    height: 'auto',
                    data: {
                        token: res.token,
                        cronofyId: cronofyId,
                        timezone: notificationDetail.consultantDetails.timeZone
                    }
                }).afterClosed().subscribe(data => {
                    if (data) {
                        //this.message = null;
                        const taskInfo = {
                            ...this.tasksService.taskInformation.getValue()
                        };
                        taskInfo.policyCall.status = 'scheduled';
                        taskInfo.family.status = true;
                        this.tasksService.taskInformation.next(taskInfo);
                    }
                });
            } else {
                this.spinner.hide();
            }
        });
    }

    consultantName(consultantDetails: {
        name: string
    }) {
        const name = consultantDetails.name.split(' ');
        this.username = name[0];
        this.initials = `${(name[0]).charAt(0)}${(name[name.length - 1]).charAt(0)}`;
    }

    logout() {
        this.router.navigate(['/home']);
    }

    setTransLanguage(selectLang) {
        this.translate.use(selectLang);
    }

    ngOnDestroy() {
        // if (this.subsriptionList) this.subsriptionList.unsubscribe();
    }

    getDetails(moveData) {
        this.older = this.older.filter(ele => ele.orderRequestId !== moveData.orderRequestId);
        this.older.push(this.latest);
        this.latest = moveData;
        this.appDataService.bbdata.subscribe(res => {
          if (res) {
            const matchData = res.orderRequestDetails.filter(ele => ele.orderRequestId === moveData.orderRequestId);
            const index = res.orderRequestDetails.findIndex(ele => ele.orderRequestId === moveData.orderRequestId);        
            if (res.orderRequestDetails[index].confirmStatus) {
                this.InitializeStubData(index,res);
                //this.appDataService.getData(res)              
            } else {
              this.router.navigate(['/bb/needs-assessment']);
              this.InitializeStubData(index,res);
            }
          }
        })
      }

        InitializeStubData(index,bbData) {
        if (bbData) {
            this.appDataService.benefitsStubData.next(bbData.orderRequestDetails[index].benefitData ? this.parseBenefitsData(index,bbData) : benefitsData);
            this.benefitsService.benefitsData.next(bbData.orderRequestDetails[index].benefitData ? this.parseBenefitsData(index,bbData) : benefitsData);

            this.appDataService.familyStubData.next(bbData.orderRequestDetails[index].familydata ? bbData.orderRequestDetails[index].familydata : familydata);
            this.familyInfoService.familyMemberInformation.next(bbData.orderRequestDetails[index].familydata ? bbData.orderRequestDetails[index].familydata : familydata);
            this.familyInfoService.familyInformation.next(bbData.orderRequestDetails[index].familydata ? bbData.orderRequestDetails[index].familydata : familydata);

            this.appDataService.taskStubData.next(bbData.orderRequestDetails[index].taskData ? bbData.orderRequestDetails[index].taskData : taskData);
            this.tasksService.taskInformation.next(bbData.orderRequestDetails[index].taskData ? bbData.orderRequestDetails[index].taskData : taskData)

            this.appDataService.consentinfoStubData.next(bbData.orderRequestDetails[index].consentinfodata ? bbData.orderRequestDetails[index].consentinfodata : consentinfodata);
            this.appDataService.countryinfoStubdata.next(bbData.orderRequestDetails[index].countryinfodata ? bbData.orderRequestDetails[index].countryinfodata : countryinfodata);
            this.appDataService.informativetextStubdata.next(bbData.orderRequestDetails[index].informativetextdata ? bbData.orderRequestDetails[index].informativetextdata : informativetextdata);
            this.appDataService.taskInfoStubdata.next(bbData.orderRequestDetails[index].taskInfo ? bbData.orderRequestDetails[index].taskInfo : taskInfo);
            this.parseNeedsAssementData(index,bbData);
            this.parseTimelinedata(index,bbData);
        }
      }

      parseNeedsAssementData(index,bbData) {

        let needsAssementData: any = {};
        needsAssementData.orderRequestId = bbData.orderRequestDetails[index].orderRequestId;
        needsAssementData.orderRefNum = bbData.orderRequestDetails[index].orderRefNum;
        needsAssementData.clientId = bbData.orderRequestDetails[index].clientId;
        needsAssementData.clientEntityName = bbData.orderRequestDetails[index].clientEntityName;
        needsAssementData.programName = bbData.orderRequestDetails[index].programName;
        needsAssementData.nameDetails = bbData.orderRequestDetails[index].nameDetails;
        needsAssementData.departureAddr = bbData.orderRequestDetails[index].departureAddr;
        needsAssementData.destinationAddr = bbData.orderRequestDetails[index].destinationAddr;
        bbData.orderRequestDetails[index].spousePartnerRelocating ? needsAssementData.spousePartnerRelocating = bbData.orderRequestDetails[index].spousePartnerRelocating : null;
        bbData.orderRequestDetails[index].childrenDependentsRelocating ? needsAssementData.childrenDependentsRelocating : null;
        bbData.orderRequestDetails[index].totalNumberOfRelocatingMembers ? needsAssementData.totalNumberOfRelocatingMembers = bbData.orderRequestDetails[index].totalNumberOfRelocatingMembers : null;
        bbData.orderRequestDetails[index].confirmStatus ? needsAssementData.confirmStatus = bbData.orderRequestDetails[index].confirmStatus : null;
        bbData.orderRequestDetails[index].jobStartDate ? needsAssementData.jobStartDate = bbData.orderRequestDetails[index].jobStartDate : null;
        bbData.orderRequestDetails[index].children ? needsAssementData.children = bbData.orderRequestDetails[index].children : null;
        bbData.orderRequestDetails[index].phoneDetailsList ? needsAssementData.phoneDetailsList = bbData.orderRequestDetails[index].phoneDetailsList : null;
        bbData.orderRequestDetails[index].emailDetailsList ? needsAssementData.emailDetailsList = bbData.orderRequestDetails[index].emailDetailsList : null;
        bbData.orderRequestDetails[index].pointDetails ? needsAssementData.pointDetails = bbData.orderRequestDetails[index].pointDetails : null;
        bbData.orderRequestDetails[index].benefitDetails ? needsAssementData.benefitDetails = bbData.orderRequestDetails[index].benefitDetails : null;
    
        this.appDataService.transfereeNeedsAssementStubData.next(needsAssementData);
        this.needsAssessmentService.transfereeNeedsAssessmentDetails.next(needsAssementData);
        this.personalInfoSevice.personalInformationDetails.next(needsAssementData);
      }
    
      parseTimelinedata(index,bbData) {
        let timeline: any = {};
        timeline.timeLines = bbData.orderRequestDetails[index].timeLines;
        timeline.count = bbData.orderRequestDetails[index].timeLines.length;
        this.appDataService.timelineStubData.next(timeline);
        this.dashboardService.timelineDetails.next(timeline);
      }
    
      parseBenefitsData(index,bbData) {
        let benefitsDataObj: BenefitsData = {
          availableBenefits: bbData.orderRequestDetails[index].benefitData.availablebenefits,
          selectedBenefits: bbData.orderRequestDetails[index].benefitData.selectedbenefits,
          confirmedBenefits: bbData.orderRequestDetails[index].benefitData.confirmedbenefits,
          points: bbData.orderRequestDetails[index].benefitData.points
        }
        return benefitsDataObj;
        
      }

      gotoDashBoard() {
        this.router.navigate(['/bb/dashboard']);
      }
      

}
