<h2>
    Client Portfolio
    <div class="add-btn">
      <!-- <button mat-mini-fab class="user-water" (click)="openDialog()">
        <mat-icon>add</mat-icon>
      </button> -->
    </div>
  </h2>
  <div [style.display]="(clearResults) ? 'none' : 'block' " class="emp-details mat-elevation-z8">
    <div class="table-scroll">
      <table mat-table [dataSource]="dataSource" matSort [matSortDisableClear]="true">
  
        <!-- Client Name Column -->
        <ng-container matColumnDef="clientEntityName" sticky>
          <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Change sorting for Client Name"
            (click)="applySort('clientName')"> Client Name </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.clientEntityName">
              <a tabindex="0" (click)="selectClient(element, 'clientName')" aria-label="Open Client Detail">
                <div>
                  <span [innerHTML]="element.clientEntityName | searchHighlight: searchKeyword"></span>
                  <br/>
                  (Client #: 
                  <span [innerHTML]="element.clientNumber | searchHighlight: searchKeyword"></span>
                  )
                </div>
              </a>
            </div>
          </td>
        </ng-container>
  
        <!-- Client Location Column -->
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="applySort('location')">
            Client Location </th>
          <td mat-cell *matCellDef="let element">
            <div *ngIf="element.clientAddress">
              <span [innerHTML]="element.clientAddress.city | searchHighlight: searchKeyword"> {{element.clientAddress.city}}</span>,
              <span [innerHTML]="element.clientAddress.state | searchHighlight: searchKeyword">{{element.clientAddress.state}}</span> <br />
              <span [innerHTML]="element.clientAddress.country | searchHighlight: searchKeyword">{{element.clientAddress.country}}</span>
            </div>
          </td>
        </ng-container>

        <!-- Client totalClientContacts Column -->
        <ng-container matColumnDef="totalClientContacts">
          <th mat-header-cell *matHeaderCellDef>
            Client Contacts </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <a tabindex="0" (click)="selectClient(element, 'clientContact')" aria-label="Open Client Contact List">
                <span>{{element.totalClientContacts || 0 }}</span>
              </a>
            </div>
          </td>
        </ng-container>

        <!-- Client totalCandidates Column -->
        <ng-container matColumnDef="totalCandidates">
          <th mat-header-cell *matHeaderCellDef>
            Transferees </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <a tabindex="0" (click)="selectClient(element, 'candidates')" aria-label="Open Transferee List">
                <span>{{element.totalCandidates || 0 }}</span>
              </a>
            </div>
          </td>
        </ng-container>

        <!-- Client totalPrograms Column -->
        <ng-container matColumnDef="totalActivePrograms">
          <th mat-header-cell *matHeaderCellDef>
            Active Programs </th>
          <td mat-cell *matCellDef="let element">
            <div>
              <a tabindex="0" (click)="selectClient(element, 'programs')" aria-label="Open Program List">
                <span>{{element.totalActivePrograms || 0 }}</span>
              </a>
            </div>
          </td>
        </ng-container>
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
    <div fxLayout="row" class="table-footer" fxLayoutAlign="end center" fxLayoutAlign.xs="start start">
      <div fxFlex="0 0 auto" fxLayoutAlign="start center">
        <!-- Download Button in future -->
      </div>
      <!-- <div fxFlex="64%"></div> -->
      <div fxFlex="34%" fxFlex.xs="100%">
          <mat-paginator [pageSizeOptions]="[25, 50, 75]"></mat-paginator>
      </div>
    </div>
  </div>
  