import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material';
import {LoggerService} from '../../../core/services/logger.service';
import { LoggedInUserService } from '../../../core/services/loggedin-user-service';


@Component({
  selector: 'app-invitation-sent',
  templateUrl: './invitation-sent.component.html',
  styleUrls: ['./invitation-sent.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvitationSentComponent implements OnInit {

  constructor(private readonly _snackRef: MatSnackBarRef<InvitationSentComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private readonly Logger: LoggerService,
    private readonly loggedInUserService: LoggedInUserService) { }

  ngOnInit() {
    this.loggedInUserService.getLoggedInUserDetails()
    .subscribe(response => {
      const userId: any = response.userId.replace(/ .*/, '');
      this.Logger.activityAudit('ACTIVITY', userId, 'ALPHA-INVITATION_SENT', 'INVITATION_SENT');
    });

  }
  dismiss() {
    this._snackRef.dismiss();
  }

}
