import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatAutocompleteTrigger } from '@angular/material';
@Component({
  selector: 'app-dup-check-popup',
  templateUrl: './dup-check-popup.component.html',
  styleUrls: ['./dup-check-popup.component.scss']
})
export class DupCheckPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DupCheckPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close({
      action: 'dismiss'
    });
  }

  onConfirm(): void {
    this.dialogRef.close({
      data: true,
      action: 'dismiss'
    });
  }

}
