import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { UserConfig } from '../models/user-config.model';
import { BaseClientService } from '../services/base-client.service';
import { RemoteLoggingService } from '../services/remote-logging.service';
import { map, catchError } from 'rxjs/operators';
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';
import { userPreference } from '../data/contextData';
/** UserConfigService to get and set the user preferences data from the API */
@Injectable({
  providedIn: 'root'
})
export class UserConfigService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private moveProDashboadDataService: MoveProDashboadDataService) {
  }
  currencyDataSubject = new BehaviorSubject<any>(null);
  /** getUserConfigDetails() method to get the reponse from the api using base client service */
  getUserConfigDetails(): Observable<Array<UserConfig>> {
    // return this.baseClient.getArr<UserConfig>('v1/userPreference', 'get the UserConfig data').pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     const empty: UserConfig[] = [];
    //     this.logSvc.logError(err);
    //     return of(empty);
    //   })
    // );
    return of(JSON.parse(JSON.stringify(userPreference)));
  }

  /** settUserConfigDetails() method to set the reponse to the api using base client service */
  setUserConfigDetails(userConfig: UserConfig): Observable<boolean> {
    return this.baseClient.post<boolean>('v1/userConfig', userConfig, 'setting user config').pipe(
      map(r => r.body),
      catchError((err, source) => {
        const empty = false;
        this.logSvc.logError(err);
        return of(empty);
      })
    );
  }

  /** settUserConfigDetails() method to set the reponse to the api using base client service */
  updatePreference(req, isUpdateCurrencyData = false): Observable<boolean> {
    // return this.baseClient.put<boolean>('v1/userPreference', req, 'update user preference').pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //       const empty = false;
    //       this.logSvc.logError(err);
    //       return of(empty);
    //   })
    // );
    if(isUpdateCurrencyData) {
      this.updateEmployeeData(req);
      this.updateProviderData(req);
      this.updateProviderInvoiceData(req);
      this.updateTotalCostData(req);
    }
    return of (JSON.parse(JSON.stringify(userPreference)));
  }

  updateEmployeeData(req): Observable<any> {
    let response = this.moveProDashboadDataService.employeeDataSubject.getValue();
    response.data.map((ele) => {
      if (ele.totalCostCurr !== req.name) {
        ele.totalCost = this.convertCurrency(req.value, ele.totalCostCurr, ele.totalCost);
        ele.totalCostCurr = req.value
      }
    })
    return of(this.moveProDashboadDataService.employeeDataSubject.next(response));
  }

  updateProviderData(req): Observable<any> {
    let response = this.moveProDashboadDataService.providerDataSubject.getValue();
    response.map((ele) => {
      ele.suppliers.map((ele) => {
        if (ele.incurredCurrCode !== req.name) {
          ele.incurredAmt = this.convertCurrency(req.value, ele.incurredCurrCode, ele.incurredAmt);
          ele.incurredCurrCode = req.value
        }
      })
    })
    return of(this.moveProDashboadDataService.providerDataSubject.next(response));
  }

  updateProviderInvoiceData(req): Observable<any> {
    let response = this.moveProDashboadDataService.providerInvoiceDataSubject.getValue();
    response.map((ele) => {
      ele.suppliers.map((ele) => {
        if (ele.transactionCurrCode !== req.name) {
          ele.transAmt = this.convertCurrency(req.value, ele.transactionCurrCode, ele.transAmt);
          ele.transactionCurrCode = req.value
        }
        ele.invoices.map((ele) => {
          ele.list.map((ele) => {
            if (ele.incurredCurrCode !== req.name) {
              ele.incurredAmt = this.convertCurrency(req.value, ele.incurredCurrCode, ele.incurredAmt);
              ele.incurredCurrCode = req.value
            }
            if (ele.originalCurrCode !== req.name) {
              ele.originalAmt = this.convertCurrency(req.value, ele.originalCurrCode, ele.originalAmt);
              ele.originalCurrCode = req.value
            }
          })
        })
      })
    })
    return of(this.moveProDashboadDataService.providerInvoiceDataSubject.next(response));
  }
  updateTotalCostData(req): Observable<any> {
    let response = this.moveProDashboadDataService.totalCostDataSubject.getValue();
    response.map((ele) => {
      if (ele.transactionCurrCode !== req.name) {
        ele.totalRecentTransaction = this.convertCurrency(req.value, ele.transactionCurrCode, ele.totalRecentTransaction);
        ele.totalExpense = this.convertCurrency(req.value, ele.transactionCurrCode, ele.totalExpense);
        ele.transactionCurrCode = req.value
      }
      ele.results.map((ele)=>{
        if (ele.billCurrency !== req.name && ele.transactionCurrCode !== req.name) {
          ele.billAmount = this.convertCurrency(req.value, ele.billCurrency, ele.billAmount);
          ele.transactionAmount = this.convertCurrency(req.value, ele.billCurrency, ele.transactionAmount);
          ele.transactionCurrCode = req.value;
          ele.billCurrency = req.value
        }
      })
    })
    return of(this.moveProDashboadDataService.totalCostDataSubject.next(response));
  }

  convertCurrency(toConvertCurrency, currentCurrency, currentTotalCost) {
    let converetedTotalCost = 0;
    switch (toConvertCurrency) {
      case 'USD': (currentCurrency === 'EUR') ? (converetedTotalCost = Number(currentTotalCost) * 1.21) :
        (currentCurrency === 'GBP') ? (converetedTotalCost = Number(currentTotalCost) * 1.39) :
          (currentCurrency === 'AUD') ? (converetedTotalCost = Number(currentTotalCost) * 0.77) :
            (currentCurrency === 'CAD') ? (converetedTotalCost = Number(currentTotalCost) * 0.81) :
              (currentCurrency === 'BRL') ? (converetedTotalCost = Number(currentTotalCost) * 1.39) :
                (currentCurrency === 'USD') ? (converetedTotalCost = Number(currentTotalCost)) :
                  (converetedTotalCost = Number(currentTotalCost));
        return converetedTotalCost;
      case 'EUR': (currentCurrency === 'EUR') ? (converetedTotalCost = Number(currentTotalCost)) :
        (currentCurrency === 'GBP') ? (converetedTotalCost = Number(currentTotalCost) * 1.15) :
          (currentCurrency === 'AUD') ? (converetedTotalCost = Number(currentTotalCost) * 0.64) :
            (currentCurrency === 'CAD') ? (converetedTotalCost = Number(currentTotalCost) * 0.67) :
              (currentCurrency === 'BRL') ? (converetedTotalCost = Number(currentTotalCost) * 0.83) :
                (currentCurrency === 'USD') ? (converetedTotalCost = Number(currentTotalCost)) :
                  (converetedTotalCost = Number(currentTotalCost));
        return converetedTotalCost;
      case 'GBP': (currentCurrency === 'EUR') ? (converetedTotalCost = Number(currentTotalCost) * 0.87) :
        (currentCurrency === 'GBP') ? (converetedTotalCost = Number(currentTotalCost)) :
          (currentCurrency === 'AUD') ? (converetedTotalCost = Number(currentTotalCost) * 0.56) :
            (currentCurrency === 'CAD') ? (converetedTotalCost = Number(currentTotalCost) * 0.58) :
              (currentCurrency === 'BRL') ? (converetedTotalCost = Number(currentTotalCost) * 0.13) :
                (currentCurrency === 'USD') ? (converetedTotalCost = Number(currentTotalCost * 0.72)) :
                  (converetedTotalCost = Number(currentTotalCost));
        return converetedTotalCost;
      case 'AUD': (currentCurrency === 'EUR') ? (converetedTotalCost = Number(currentTotalCost) * 1.21) :
        (currentCurrency === 'GBP') ? (converetedTotalCost = Number(currentTotalCost) * 1.79) :
          (currentCurrency === 'AUD') ? (converetedTotalCost = Number(currentTotalCost)) :
            (currentCurrency === 'CAD') ? (converetedTotalCost = Number(currentTotalCost) * 1.05) :
              (currentCurrency === 'BRL') ? (converetedTotalCost = Number(currentTotalCost) * 0.24) :
                (currentCurrency === 'USD') ? (converetedTotalCost = Number(currentTotalCost) * 1.29) :
                  (converetedTotalCost = Number(currentTotalCost));
        return converetedTotalCost;
      case 'CAD': (currentCurrency === 'EUR') ? (converetedTotalCost = Number(currentTotalCost) * 1.48) :
        (currentCurrency === 'GBP') ? (converetedTotalCost = Number(currentTotalCost) * 1.71) :
          (currentCurrency === 'AUD') ? (converetedTotalCost = Number(currentTotalCost) * 0.95) :
            (currentCurrency === 'CAD') ? (converetedTotalCost = Number(currentTotalCost)) :
              (currentCurrency === 'BRL') ? (converetedTotalCost = Number(currentTotalCost) * 0.23) :
                (currentCurrency === 'USD') ? (converetedTotalCost = Number(currentTotalCost) * 1.23) :
                  (converetedTotalCost = Number(currentTotalCost));
        return converetedTotalCost;
      case 'BRL': (currentCurrency === 'EUR') ? (converetedTotalCost = Number(currentTotalCost) * 6.52) :
        (currentCurrency === 'GBP') ? (converetedTotalCost = Number(currentTotalCost) * 7.52) :
          (currentCurrency === 'AUD') ? (converetedTotalCost = Number(currentTotalCost) * 4.19) :
            (currentCurrency === 'CAD') ? (converetedTotalCost = Number(currentTotalCost) * 4.41) :
              (currentCurrency === 'BRL') ? (converetedTotalCost = Number(currentTotalCost)) :
                (currentCurrency === 'USD') ? (converetedTotalCost = Number(currentTotalCost) * 5.41) :
                  (converetedTotalCost = Number(currentTotalCost));
        return converetedTotalCost;
    }
  }
}
