<ngx-spinner type="ball-spin-clockwise-fade" role="alert" aria-atomic="true" size="medium">
    <span class="cdk-visually-hidden">Loading, please wait...</span>
</ngx-spinner>

<div class="dialogMainContainer candidate-formcontainer" flexLayout fxLayout.xs="column" fxFlex="100%">
    <div mat-dialog-title disableTypography className={styles.dialogTitle} fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="20px" fxLayoutGap.xs="0em">
        <div class="candidate-dialogtitle"> {{ formTitle }}</div>
        <a  class="close-icon" (click)="onDismiss(null)" [ngClass]="{'disable':formDisable==true}" title="close"  fxLayoutAlign="end" >
            <mat-icon>close</mat-icon>
        </a>
    </div>
    <form fxLayout="column" class="middle-section" (ngSubmit)= "onSubmit(f)" #f="ngForm" [appMatchPassword]="['password', 'confirmPassword']">
        <div class="middle-section" style = "justify-content: space-between;">
            <mat-dialog-content>
                <div>
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em">
                        <!--First Name field-->
                        <div fxFlex="35%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <input matInput placeholder="First Name" character-only [ngModel] = "firstName" name="firstName" minLength="1"
                                    maxlength="50" required>
                            </mat-form-field>
                        </div>
                        <!--Last Name field-->
                        <div fxFlex="35%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <input matInput placeholder="Last Name" character-only [ngModel]="lastName" name="lastName" minLength="2" maxlength="50" required>
                            </mat-form-field>
                        </div>
                    </div>
                    
                </div>

                <div>
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em">
                        <!--Email Id field-->
                        <div fxFlex="35%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <input matInput placeholder="Email Id " character-only [ngModel]="email" name="email" minLength="1"
                                    maxlength="50" required email (ngModelChange)= "checkEmail($event)">
                            </mat-form-field>
                        </div>
                        <!--Is a cartus Admin User ? -->
                        <div fxFlex="35%" fxLayout.xs="column" fxFlex.xs="100%" *ngIf="loggedInUserType == 'admin'" >
                                <mat-checkbox [disabled] = "!canBeAdminUser"[(ngModel)]="isAdmin" name="isAdmin" ngModel  (change)=toggleIsAdminCheckbox($event)>
                                <label  class="isAdmin">Is a Cartus Admin user?</label></mat-checkbox>
                        </div>
                        <!-- User type Dropdown in case of Super Admin User -->
                        <div fxFlex="35%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%" *ngIf="loggedInUserType == 'superadmin'">
                            <mat-form-field>
                                <mat-label>User Type</mat-label>
                                <mat-select  [(ngModel)]="userType" name="userType" (ngModelChange)= "changeUserType($event)" #selectedUserType>
                                    <mat-option  *ngFor="let user of userTypes" [value]="user"  [disabled] = "!canBeAdminUser && (user=='admin' || user=='superadmin')">{{user}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <span class="text-danger" *ngIf="loggedInUserType != 'admin' && (!canBeAdminUser  && (userType =='superadmin' || userType =='admin'))" fxLayout="row" fxLayoutAlign="end">
                        Admin/Super Admin must have cartus.com domain
                     </span>
                </div>

                <div>
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em">
                        <!--Client Field-->
                        <div fxFlex="35%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <mat-label>Client</mat-label>
                                <mat-select  [ngModel]="client" name="client">
                                    <mat-option  *ngFor="let client of clients" [value]="client.clientName">{{client.clientName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <!--Subcription End Date-->
                        <div fxFlex="35%" fxLayout.xs="column" fxFlex.xs="100%"  *ngIf="!isEditEnable">
                            <mat-form-field  *ngIf="!isEditEnable && userType !='superadmin'">
                                <mat-label>Subscription End Date </mat-label>
                                <input matInput   [matDatepicker]="subcriptionEndDate" [max]="maxDate" [min]="minDate"  [(ngModel)] ="subscriptionEndDate" name="subscriptionEndDate"  [readonly] = "userType =='superadmin'" [required] = "userType !='superadmin'"> 
                                <mat-datepicker-toggle matSuffix [for]="subcriptionEndDate" [disabled] = "userType =='superadmin'"></mat-datepicker-toggle>
                                <mat-datepicker #subcriptionEndDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <!--Subcription End Date for edit user-->
                        <div fxFlex="35%" fxLayout.xs="column" fxFlex.xs="100%" *ngIf="isEditEnable">
                            <mat-form-field *ngIf="isEditEnable && userType !='superadmin'">
                                <mat-label>Subscription End Date </mat-label>
                                <input matInput   [matDatepicker]="subcriptionEndDate" [max]="maxDate" [min]="minDate"  [(ngModel)]="subscriptionEndDate" name="subscriptionEndDate"  [readonly] = "userType =='superadmin'" [required] = "userType !='superadmin'">
                                <mat-datepicker-toggle matSuffix [for]="subcriptionEndDate" [disabled] = "userType =='superadmin'"></mat-datepicker-toggle>
                                <mat-datepicker #subcriptionEndDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                    
                </div>

            <div>
                <div >
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em">
                        <!--User id field -->
                        <div fxFlex="35%" fxLayoutAlign="start" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <input matInput placeholder="UserId" character-only [ngModel]="userName" name="userName" minLength="1" maxlength="50" required [readonly] = "isEditEnable">
                            </mat-form-field>
                        </div>
                        <!--Password field--> <!-- matTooltip="Password should be minimum 8 characters long. Should be alphanumeric with mixed cases and special characters" -->
                        <div fxFlex="35%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <input type="password" matInput placeholder="Password" 
                                 character-only ngModel name="password" 
                                  minLength="8" maxlength="20"  #pass="ngModel" [required] = "!isEditEnable" pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{6,12}$">
                            </mat-form-field>
                            <span class="text-danger"
                                *ngIf="(pass.touched && pass.errors?.pattern) && pass.touched">
                                Password should be minimum 8 characters long.Should be alphanumeric with mixed cases and special characters
                            </span>
                        </div>
                    </div>
                    
                </div>


                <div *ngIf = "pass.valid && pass.dirty">
                    <div class="input-row" fxLayout="row" fxLayoutAlign="start" fxLayout.xs="column" fxLayoutGap.xs="0em" style="padding-left: 70px;">
                        <!--Confirm Password field-->
                        <div fxFlex="35%" fxLayout.xs="column" fxFlex.xs="100%">
                            <mat-form-field>
                                <input type="password" matInput placeholder="Confirm Password" 
                                character-only ngModel name="confirmPassword"
                                minLength="8" maxlength="20"
                                #confirmPassword="ngModel" [required] = "!isEditEnable">
                            </mat-form-field>
                            <span class="text-danger"
                                *ngIf="confirmPassword.touched && confirmPassword.errors?.passwordMismatch">
                                Passwords does not match 
                            </span>
                        </div>
                    </div>
                    
                </div>
            </div>
                <!-- Buttons -->
                    <div class="input-row" fxLayout="row" fxLayoutAlign="space-evenly" fxLayout.xs="column" fxLayoutGap.xs="0em">
                        <button fxFlex="20%"  type="button" class="mat-button text-button" (click)="onDismiss(f)" id="cancel">CANCEL</button>
                        <button fxFlex="20%" type="submit" class="mat-button contained-button send-invite" id="save" [disabled] ="(!canBeAdminUser && userType =='superadmin') || !f.valid"  *ngIf = "!isEditEnable">SAVE</button>
                        <button fxFlex="20%" type="button" class="mat-button contained-button send-invite" id="save" [disabled] ="(!canBeAdminUser && userType =='superadmin') || !f.valid" *ngIf = "isEditEnable" (click)="updateUser(f)">UPDATE</button>
                    </div>
            </mat-dialog-content>
        </div>
    </form>
</div>
