<router-outlet></router-outlet>



















<!-- <app-footer></app-footer> -->
<!-- <mat-sidenav-content fxLayout="column">
        <app-footer fxFlexOffset="auto"></app-footer>
</mat-sidenav-content> -->

