import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseClientService } from './base-client.service';
import { Tasks, CalenderInvite } from '../models/tasks.model';
import { NeedsAssessmentSharedService } from '../../../../src/app/public/needs-assessment/services/needs-assessment-shared.service';
import { taskData, cronofyToken } from '../data/app-data';
import { AppDataService } from './app-data.service';

@Injectable({
    providedIn: 'root'
})
export class TasksInfoService {
    /** Behaviour subject for tasks information */
    taskInformation = new BehaviorSubject < Tasks > (null);
   // taskInfo = this.taskInformation.asObservable();
    /**
     * @param baseClientService Inject base client service
     */
    constructor(
        private readonly baseClientService: BaseClientService,
        private readonly needsAssessment: NeedsAssessmentSharedService,
        private appDataService: AppDataService
    ) {
        this.getTaskInformation();
    }

    /**
     * To get Tasks information
     */
    getTasks(): Observable < Tasks > {
        // return this.baseClientService
        //     .get < Tasks > (`/v1/tasks`)
        //     .pipe(
        //         map(r => r.body),
        //         catchError(err => {
        //             console.log('Failed to get tasks', err);
        //             const emptyResp = null;
        //             return of(emptyResp);
        //         })
        //     );
        this.updateTaskInfo(this.appDataService.taskStubData.getValue());
        return of (this.appDataService.taskStubData.getValue());
    }

    /**
     * To update tasks behaviour subject with updated value
     * @param data : tasks information
     */
    updateTaskInfo(data: Tasks) {
        this.taskInformation.next(data);
    }


    /**
     * Function to Get family member information and update Shared-Service
     */
    getTaskInformation() {
        if (!this.taskInformation.getValue()) {
            this.getTasks().subscribe(data => {
                if (data) {
                    this.updateTaskInfo(data);
                }
            });
        } else {
            return this.taskInformation.getValue();
        }
    }

    getCronofyToken(token: string): Observable <{ token: string }> {
        // return this.baseClientService
        //     .get(`/v1/cronofy/token?id=${token}`)
        //     .pipe(
        //         map(r => r.body),
        //         catchError(err => {
        //             console.log('Failed to get token', err);
        //             const emptyResp = null;
        //             return of(emptyResp);
        //         })
        //     );
        return of (cronofyToken);
    }

    schedulePolicyCall(start: Date, end: Date) {
        const taskInfo = this.taskInformation.getValue();
        const transferee = this.needsAssessment.transfereeNeedsAssessmentDetails.getValue();
        const emailList = taskInfo.policyCall.consultantDetails.emailDetailsList.map(item => {
            return { email: item.emailAddress };
        });
        emailList.push({
            email: transferee.emailDetailsList.find(item => item.primary).emailAddress
        });
        const phoneNumber = transferee.phoneDetailsList.find(phone => phone.primary);
        const calendarInvite: CalenderInvite = {
            options: {
                callback_url: 'https://example.yourapp.com/cronofy/smart_invite/notifications',
                event: {
                    description: 'Benefits Builder - Policy call',
                    summary: 'Benefits Builder - Policy call',
                    tzid: taskInfo.policyCall.consultantDetails.timeZone,
                    start: start,
                    end: end,
                    location: {
                        description: ''
                    }
                },
                organizer: {
                    name: 'Benefits Builder - Policy Call'
                },
                recipients: emailList,
                smart_invite_id: `MY_POLICY_CALL_${Date.now()}`
            },
            orderId: transferee.orderRequestId,
            // tslint:disable-next-line: max-line-length
            emailTemplate: `<p>Hello,</p><p>&nbsp;</p><p>${transferee.nameDetails.firstName}&nbsp;${transferee.nameDetails.lastName},&nbsp;${taskInfo.policyCall.custFileId}, has setup a policy call with you on your Outlook calendar as a next step toward selecting and confirming eligible benefits.&nbsp; If this selection is not acceptable, please contact the transferee directly to change the meeting time.&nbsp; Please remember to add the policy call date and time in Atlas. &nbsp;</p><p>&nbsp;</p><p>Client Name:&nbsp;${transferee.clientEntityName}</p><p>Program Name:&nbsp;${transferee.programName}</p><p>EE file id:&nbsp;${taskInfo.policyCall.custFileId}</p><p>Order Rer #:&nbsp;${transferee.orderRefNum}</p><p>EE email:&nbsp;${emailList[emailList.length - 1].email}</p><p>EE phone #:&nbsp;(${phoneNumber.phoneDialCode}) ${phoneNumber.phoneNumber}</p>`,
        };

        return this.baseClientService
            .post('/v1/cronofy/calendarInvite', calendarInvite)
            .pipe(
                map(r => r.body),
                catchError(err => {
                    console.log('Failed to send invite', err);
                    const emptyResp = null;
                    return of(emptyResp);
                })
            );

    }

}
