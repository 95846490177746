import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  EmbeddedViewRef,
  ComponentRef,
  Type
} from '@angular/core';
import { DialogComponent } from './../dialog.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {

  dialogComponentRef: ComponentRef<DialogComponent>;
  content = '';
  isbutton: boolean;
  notificationType = '';

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) { }

  private appendDialogComponentToBody() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DialogComponent);
    const componentRef = componentFactory.create(this.injector);
    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.dialogComponentRef = componentRef;
  }

  public removeDialogComponentFromBody() {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }

  public open(type, content, isbutton) {
    this.appendDialogComponentToBody();
    this.notificationType = type;
    this.isbutton = isbutton;
    this.content = content;

    // Auto dismisses in 5 sec.
    setTimeout(() => {
      this.removeDialogComponentFromBody();
    }, 5000);
  }
}
