import { Injectable, Injector } from '@angular/core';
import { BaseClientService } from './../../../core/services/base-client.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { TransfereeNeedsAssessmentService } from '../../needs-assessment/services/transferee-needs-assessment.service';
import { NeedsAssessmentSharedService } from '../../needs-assessment/services/needs-assessment-shared.service';
import { MatBottomSheet, MatSnackBar } from '@angular/material';
import { ICONS_MAPPING } from './../../../core/models/constants';
import { CookieService } from 'ngx-cookie-service';
import { rxSubscriber } from 'rxjs/internal-compatibility';
import { Subject } from 'rxjs/internal/Subject';
// import { benefitsData, taskInfo } from '../../../core/data/app-data'
import { AppDataService } from '../../../core/services/app-data.service'
import { DatePipe } from '@angular/common';


export interface Benefit {
  tierValues?: Array<TierValues>;
  singleSelectMultiplicity?: boolean;
  isCashoutSpecialized?: any;
  iconDisplayName?: string;
  iconLink?: string;
  dynamicPointsSupport?: any;
  reference: string;
  tempLiving?: boolean;
  productSpecificationReference: string;
  displayName: string;
  description: string;
  category: string;
  points?: number | 'Guaranteed';
  /**amount specific for MMUser */
  amount?: number | 'Guaranteed';
  type?: string; //type for MMUser simple or complex
  selectedbenefitAmount?: number; // selected benefit amount
  selectedbenefitCurrency?: string; // selected benefit currency
  selectedAmount?: number; // Total of selected MMUser benefit amount and added benefit
  hostCurrency?: string; //host currency after selection
  iscurrencyconversion?: boolean; //to check if currency conversion done or not
  selected?: boolean;
  maxMul?: number;
  selectedbenefitCount?: number; // selected benefit count
  selectedCount?: number; // Total of selected benefit count and added benefit
  confirmedCount?: number;
  confirmedDate?: string;
  selectedDate?: string;
  rangeMin?: number; // for cashout min value
  rangeMax?: number; // for cashout max value
  pointValue?: number; // for cashout card point equivalent dollar value
  selectedCashOutPoints?: number;  // for cashout card selected cashout benefit point(temporary).
  selectedCashOutValue?: number;  // for cashout card selected cashout benefit value.
  hybridTempLiving?: any; //hybrid 
  coreTimeUnit?: any; //core hybrid time aptr
  coreUnits?: any; //
  modifications?: any;
  confirmedCashOutPoints?: number;  // for cashout card confirmed cashout point
  prerequisites?: Array<string>;
  exclusions?: Array<string>;
  inclusions?: Array<string>;
  disable?: boolean; // flag to disable benefit if part of exclusions
  taskInfo?: TaskInfo;
  productId?: string;
  pointsPerIncrement?: number; // for time range increment benefits, points increment value
  rangeIncrementValue?: number; // for time range increment benefits, range value increment value
  rangeIncrementUnit?: string; // for time range increment benefits range units
  selectedIncrementCount?: number; // for time range increment benefits, selected benfit counts
  confirmedIncrementCount?: number; // for time range increment benefits, confirmed benefit benfit counts
  
  perPointCurrency?: number; // for incremental dollar per point cards
  selectedDollarIncrementCount?: number; // for incremental dollar per point cards, selected benefit counts 
  confirmedDollarIncrementCount?: number; // for incremental dollar per point cards, confirmed benefit counts
  icon?: string;
  hasNoPreRequisite?: boolean; // flag to disable benefit when pre requisites are not added
  consultantOnlyCard?: boolean; // flag to determine if it can be configured by consultant only
  qualifyingInput?: 1 | 2; // qualifying question to be displayed
  activated?: boolean; // flag to determine if the consultant card benefit is activated for transferee
  tierConfigs?: Array<TierConfig>;
  advCashOutPointValue?: number; // cashout total value selected
  advCashOutSelectedPoints?: number // cashout selected points
  confirmedAdvCashOutPoints?: number // cashout confirmed points
  confirmedAdvCashOutValue?: number; // cashout total value confirmed
  confirmedAdvCashOutCurr?: string; // cashout currency 
  addCount?: number; //Multiplicity card selected 
  /**MMUser budget structure to be finalised*/
  budget?: {
    total?: {
      estimatedAmount?: {
        amount: number,
        currency: string,
        displayedAmount?: string;
      },
      actualAmount?: {
        amount: number,
        currency: string,
      }
    },
    budgetBreakUp?: {
      estimatedAmount?: Array<Expense>,
      actualAmount?: Array<Expense>
    }
  };
  productMonetaryValue?:{
    amount?:number,
    currency?:string
  }
  parameters?: Array<Question>;
  hybridDelCount?: any; //temp var for deleting
  assessNeeds?: AssessNeeds;
  housingPreference?: HousingPreference;
  pointsPerSelection?: number; // cash out points per selection
  legacyProduct?: { //MMU leagacy product mapping
    productName?: string,
    subProductName?: string
  };
  actualAmount?: number;
  derivedValueSources?: Array<string>;
  cashOut?: boolean;
  triggeredAccessInfo?:{
    days?: number
    milestone?: string
    milestoneDate?: string
    percentage?: number
  };
  dynamicAmount?: number;
  dynamicCurrency?: string;
  dynamicAvailable?: boolean ; 
  }
  

export interface Expense {
  displayname: string,
  expense: {
    amount: number,
    currency: string
  }
  activated ?: boolean; // flag to determine if the consultant card benefit is activated for transferee
  tierConfigs ?: Array <TierConfig>;
  advCashOutPointValue ?: number; // cashout total value selected
  advCashOutSelectedPoints ?:number // cashout selected points
  confirmedAdvCashOutPoints ?:number // cashout confirmed points
  confirmedAdvCashOutValue ?: number; // cashout total value confirmed
  confirmedAdvCashOutCurr?:string; // cashout currency 
  pointsPerSelection?: number; // cash out points per selection
  iconDisplayName?: string;
  iconLink?: any;
  tempLiving?: boolean; // flag to identify tempLiving offering
}
export interface Question {
  _id: string,
  displayname: string,
  type: string,
  value?: {
    amount?: number,
    currency?: string
  },
  enum?: Array<string>
  
}
/**MMUser budget structure to be finalised*/
export interface TierConfig {
  maxSelection: number;
  totalMaxSelectionLimit?: number;
  minRange: number;
  maxRange: number;
  pointValue: number;
}

export interface TierValues{
  displayValue :string;
  value: any;
  isSelected?:boolean
}

export interface BenefitsData {
  availableBenefits?: Array<Benefit>;
  selectedBenefits?: Array<Benefit>;
  confirmedBenefits?: Array<Benefit>;
  points?: number;
  amount?: number;
  /**Get actuals for MMUser integrated in getbenefits api */
  benefitsActualsInfo?: {
    benefitsBreakDowns?: [{
      billingCurrencyAmt :number,
      billingCurrencyCode :number,
      disbursedCurrencyAmt :number,
      disbursedCurrencyCode : string
      hostCurrencyAmt : number,
      hostCurrencyCode: string,
      reference :string
    }],
    totalsInfo?: {
      actualRemaining?: number
      actualSpend?: number
      hostCurrencyAmt?: number
      hostCurrencyCode?: string
    }
  }
}

export interface TaskInfo {
  allTaskComplete?: boolean;
  allHighValueTaskOptOut?: boolean;
  allHighValueTaskComplete?: boolean;
  tasks: Task[];

}
export interface Task {
  displayName: string;
  description?: string;
  reference?: string;
  status?: boolean;
  highValueItemTypeList?: HighValueItemTypeInfo[];
  highValueItems?: HighValueItem[];
  date?: string;
}
export interface PetsInformation {
  name: string,
  type: string,
  breed: string,
  weight: number
}
export interface PetsInformation {
  name: string,
  type: string,
  breed: string,
  weight: number
}
export interface AssessNeeds {
  accompanyingMembers: Array<any>,
  petsInformation: PetsInformation[],
  departureLocation: string,
  travelHistory: Array<string>, 
  assessNeedsDraft: boolean
  assessNeedsSubmittedDate: any
}

  export interface HousingPreference {
    desiredLocation?: {
      city: string,
      state: string,
      country: string
    },
    alternateLocation?: {
      city: string,
      state: string,
      country: string
    },
    destinationOfficeLocation?: {
      city: string,
      state: string,
      country: string
    },
    moveInDate?: string,
    moveOutDate?: string,
    numberBedrooms?: string,
    numberApartments?: any,
    babyCotRequired?: boolean,
    acceptableCommute?: number,
    priorities?: Array<string>,
    additionalInstructions?: string,
    housingPreferencesDraft?: boolean,
    housingPreferencesSubmittedDate?: string,
    numberBedroomsValueList?: Array<string>,
    prioritiesValueList?: Array<string>,
    accommodationType?: any,
    housekeepingInfo?: any,
    furnished?: boolean,
    kitchenRequired?: boolean,
    parkingInfo?: any,
    numberOfBathroomsValueList?: any,
    numberOfBathrooms?: string,
  }

export interface HighValueItem {
  id?: string;
  name: string;
  replacementValue: number;
  description: string;
  serialNumber: string;
}

export interface HighValueItemTypeInfo {
  name: string;
  group: string;
}


export interface TaskUpdate {
  reference: string,
  status: boolean
}
export interface PetsInformation {
  name: string,
  type: string,
  breed: string,
  weight: number
}
export interface AssessNeeds {
  accompanyingMembers: Array<any>,
  petsInformation: PetsInformation[],
  departureLocation: string,
  travelHistory: Array<string>, 
  assessNeedsDraft: boolean
  assessNeedsSubmittedDate: any
}

export interface highValue_tempLiving {
  displayName: string,
  id: string
}

  export interface HousingPreference {
    desiredLocation?: {
      city: string,
      state: string,
      country: string
    },
    alternateLocation?: {
      city: string,
      state: string,
      country: string
    },
    destinationOfficeLocation?: {
      city: string,
      state: string,
      country: string
    },
    moveInDate?: string,
    moveOutDate?: string,
    numberBedrooms?: string,
    numberApartments?: any,
    babyCotRequired?: boolean,
    acceptableCommute?: number,
    priorities?: Array<string>,
    additionalInstructions?: string,
    housingPreferencesDraft?: boolean,
    housingPreferencesSubmittedDate?: string,
    numberBedroomsValueList?: Array<string>,
    prioritiesValueList?: Array<string>,
    accommodationType?: any,
    housekeepingInfo?: any,
    furnished?: boolean,
    kitchenRequired?: boolean,
    parkingInfo?: any,
    numberOfBathroomsValueList?: any,
    numberOfBathrooms?: string,
  }

type BenefitDataTypesAvailableToUpdate = 'availableBenefits' | 'selectedBenefits';

export type BenefitCategory = 'Before Move' | 'Shipping' | 'Travel & Transport' | 'After Arrival' | 'Ongoing Support' | 'Allowance' | 'All';

export const CategoryMapping = {
  'Before You Move': 'Before Move',
  'Shipping': 'Shipping',
  'Travel and Transport': 'Travel & Transport',
  'After You Arrive': 'After Arrival',
  'Ongoing': 'Ongoing Support',
  'Relocation Allowances': 'Allowance',
};

export const BenefitColorMapping = {
  'Before Move': '#00EBB3',
  'Shipping': '#FFAE4E',
  'Travel & Transport': '#A564FF',
  'After Arrival': '#FFDE2B',
  'Allowance': '#FF6767',
  'Ongoing Support': '#27E33D',
};
@Injectable({
  providedIn: 'root'
})
export class BenefitService {

  readonly BenefitCategories = ['Before You Move', 'Shipping', 'Travel and Transport', 'After You Arrive', 'Relocation Allowances', 'Ongoing'];
  constructor(
    private readonly baseClientService: BaseClientService,
    private injector: Injector,
    private readonly cookieService: CookieService,
    private appDataService: AppDataService,
    private datePipe: DatePipe
  ) { }

  /** Keeps all the data related to Benefits */
  public benefitsData = new BehaviorSubject<BenefitsData>(null);
  public benefitsData$ = this.benefitsData.asObservable();
    /** Behaviour subject for temp living contact card */
    contactCardInformation = new BehaviorSubject<any>(null);

  /** Keeps all the data related to Benefits */
  pendingHighValueBenefitsData = new BehaviorSubject<Benefit[]>([]);



  selectedBenefitCategory = new BehaviorSubject<string>(null);
  /*getHgvBenefits(data) {
    if (data.confirmedBenefits && data.confirmedBenefits.length > 0) {
      data.confirmedBenefits[1].taskInfo.allHighValueTaskComplete = false;
      data.confirmedBenefits[1].taskInfo.tasks[0].highValueItems = [];
    }
    return data;
  }*/

  public benefitsDataResponse(response: any) {
    if (response.confirmedBenefits) {
      this.benefitsData.next(response);
      //this.benefitsData.complete();
    }
  }



  getPendingNonHighValueBenefits(confirmedBenefits: Benefit[]) {
    let pendingNonHvgBenefits: Benefit[] = [];

    if (confirmedBenefits && confirmedBenefits.length > 0) {
      confirmedBenefits.forEach(benefit => {
        if (benefit && benefit.taskInfo && (!benefit.taskInfo.hasOwnProperty('allHighValueTaskComplete') ||
          !benefit.taskInfo.hasOwnProperty('allHighValueTaskOptOut')) &&
          benefit.taskInfo.hasOwnProperty('allTaskComplete')) {
          if (!benefit.taskInfo.allTaskComplete) {
            pendingNonHvgBenefits.push(benefit);
          }
        }
      });
    }
    return pendingNonHvgBenefits;
  }
  getPendingHighValueBenefits(confirmedBenefits: Benefit[]) {
    let pendingHighvalueBenefits: Benefit[] = [];


    if (confirmedBenefits && confirmedBenefits.length > 0) {
      confirmedBenefits.forEach(benefit => {
        if (benefit && benefit.taskInfo &&
          benefit.taskInfo.hasOwnProperty('allHighValueTaskComplete') ||
          benefit.taskInfo.hasOwnProperty('allHighValueTaskOptOut')
        ) {
          if (!benefit.taskInfo.allHighValueTaskComplete && !benefit.taskInfo.allHighValueTaskOptOut) {
            pendingHighvalueBenefits.push(benefit);
          }
        }
      });
    }
    return pendingHighvalueBenefits;
  }

  getTempLivingBenefits(confirmedBenefits: Benefit[]) {
    let tempLivingBenefits: Benefit[] = [];
    if (confirmedBenefits && confirmedBenefits.length > 0) {
      confirmedBenefits.forEach(benefit => {
        if (benefit && benefit.tempLiving && !benefit.taskInfo.allTaskComplete) {
          tempLivingBenefits.push(benefit);
        }
      });
    }
    return tempLivingBenefits;
  }

  getBenefits(): Observable<BenefitsData> {
    // return this.baseClientService
    //   .get(`/v1/benefit?availableBenefits=true&selectedBenefits=true&confirmedBenefits=true`)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty  = null;
    //       return of(empty);
    //     })
    //   );
    let value = this.benefitsData.getValue();
    if (value) {
      return of(value);
    }
    return of(this.appDataService.benefitsStubData.getValue());

  }

  mapIconToBenefit(benefitName: string) {
    benefitName = benefitName.slice(0, -37)
    const icon = ICONS_MAPPING.find(item => (benefitName === item.reference));
    return icon ? icon.icon : null;
  }

  assignBenefitColor(benefit: Benefit) {
    return BenefitColorMapping[benefit.category];
  }

  /**
   * Takes a benefit from one source and adds it to the target.
   * @param benefit Benefit being moved.
   * @param source Source list
   * @param target Destination target list.
   */
   updateBenefitsData(benefit: Benefit, source: BenefitDataTypesAvailableToUpdate, target: BenefitDataTypesAvailableToUpdate) {
    if (source === target) {
      return;
    }

    if (target === 'availableBenefits') {
      delete benefit.selectedDate;
    }

    const benefitsData = {
      ...this.benefitsData.getValue()
    };
    target === 'selectedBenefits' && benefit.hybridTempLiving ? delete benefit.selectedbenefitCount : null;
    if (!benefit.maxMul && !benefit.pointsPerIncrement && !benefit.perPointCurrency && !benefit.pointValue && !benefit.tierConfigs) {
      // Removing benefit from the source list if no multiplicity
      benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      // Adding benefit to target list

      // Requirement for Selected benefits is to add a new benefit to the top of the list..
      if (target === 'selectedBenefits') {
        benefitsData[target].unshift(benefit);
      } else {
        benefitsData[target].push(benefit);
      }

    } else {
      // All available benefits are selected or selected benefits are deleted(source)  -- Remove card
      if ((benefit.maxMul === ((benefit.selectedCount || 0) + (benefit.confirmedCount || 0)) || benefit.selectedCount === 0) && !benefit.singleSelectMultiplicity) {
        benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      } else if (benefit.pointsPerIncrement /*&& ((
        ((benefit.selectedIncrementCount || 0) +
          (benefit.confirmedIncrementCount || 0)) === (benefit.rangeMax / benefit.rangeIncrementValue)
      ) || benefit.selectedIncrementCount === 0)*/) {
        benefit.hybridTempLiving ? benefitsData[source][benefitsData[source].findIndex(ele => ele.reference == benefit.reference)] = benefit : null;
        benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      } else if (benefit.perPointCurrency &&
        ((benefit.selectedDollarIncrementCount || 0) +
          (benefit.confirmedDollarIncrementCount || 0)) === (benefit.rangeMax / benefit.rangeIncrementValue)) {
            benefitsData[source][benefitsData[source].findIndex(ele => ele.reference == benefit.reference)] = benefit;
            benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      } else if (benefit.tierConfigs &&
        ((benefit.rangeMax === ((benefit.advCashOutSelectedPoints || 0) + (benefit.confirmedAdvCashOutPoints || 0))) ||
          (benefit.selectedCashOutPoints === 0)
        )) {
        benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      }
      else if ((benefit.maxMul)&& benefit.singleSelectMultiplicity) {
        benefitsData[source] = this.removeCard(benefitsData, source, benefit);
      }
      else {
        // update available/selected source card with updated selected count
        benefitsData[source] = this.updateCard(benefitsData, source, benefit);
      }
      // update target available/selected card with selected count
      benefitsData[target] = this.updateCard(benefitsData, target, benefit);
    }

    // Passing new Benefits data to BenefitsData
    setTimeout(() => {
      const newBenefitsData = {
        ...benefitsData
      };

      this.benefitsData.next(newBenefitsData);
    }, 200);

  }
  updateConfirmBenefitsData(benefit: Benefit, source: BenefitDataTypesAvailableToUpdate, target: 'confirmedBenefits') {
    let date = new Date();
    const benefitsData = this.appDataService.benefitsStubData.getValue();
    benefitsData[source] = this.removeCard(benefitsData, source, benefit);
    let taskInfo = this.appDataService.taskInfoStubdata.getValue();
    let tasks = [];
    taskInfo.tasks.forEach(element => {      
      let task : any = {};
      task.reference = Math.floor(Math.random() * 10000000).toString();
      task.displayName = element.displayName;
      task.status = element.status;
      task.description = element.description;
      tasks.push(task);
    });
    benefit.taskInfo = {"tasks":tasks};
    benefit.confirmedDate = this.datePipe.transform(date, 'yyyy-MM-dd');;
    if (benefit.maxMul) {
      benefit.confirmedCount = benefit.confirmedCount ? benefit.confirmedCount + benefit.selectedCount : benefit.selectedCount;
    } else if (benefit.tierConfigs) {
      benefit.confirmedAdvCashOutValue = benefit.confirmedAdvCashOutValue ? benefit.confirmedAdvCashOutValue + benefit.advCashOutPointValue : benefit.advCashOutPointValue;
      benefit.confirmedAdvCashOutPoints = benefit.confirmedAdvCashOutPoints ? benefit.confirmedAdvCashOutPoints + benefit.advCashOutSelectedPoints : benefit.advCashOutSelectedPoints;
      benefit.confirmedAdvCashOutCurr = 'USD';
    } else if (benefit.pointsPerIncrement) {
      benefit.confirmedIncrementCount = benefit.confirmedIncrementCount ? benefit.confirmedIncrementCount + benefit.selectedIncrementCount : benefit.selectedIncrementCount;
    } else if (benefit.perPointCurrency) {
      benefit.confirmedDollarIncrementCount = benefit.confirmedDollarIncrementCount ? benefit.confirmedDollarIncrementCount + benefit.selectedDollarIncrementCount : benefit.selectedDollarIncrementCount;
    }
    delete benefit.selectedCount;
    delete benefit.advCashOutSelectedPoints;
    delete benefit.advCashOutPointValue;
    delete benefit.selectedIncrementCount;
    delete benefit.selectedDollarIncrementCount;

    if (!this.isBenfitAvailable(benefit, 'confirmedBenefits')) {
      benefitsData[target].push(benefit);
    }
    else {
      benefitsData['confirmedBenefits'].forEach((item) => {
        if (item.reference === benefit.reference) {
          if (benefit.maxMul) {
            item.confirmedCount = benefit.confirmedCount;
            delete item.selectedCount;
          } else if (benefit.tierConfigs) {
            item.confirmedAdvCashOutValue = benefit.confirmedAdvCashOutValue;
            item.confirmedAdvCashOutPoints = benefit.confirmedAdvCashOutPoints;
            item.confirmedAdvCashOutCurr = 'USD';
            delete item.advCashOutSelectedPoints;
            delete item.advCashOutPointValue;
          } else if (benefit.pointsPerIncrement) {
            item.confirmedIncrementCount = benefit.confirmedIncrementCount;
            delete item.selectedIncrementCount;
          } else if (benefit.perPointCurrency) {
            item.confirmedDollarIncrementCount = benefit.confirmedDollarIncrementCount;
            delete item.selectedIncrementCount;
          }
        }
      })
    }
    let data = this.benefitsData.getValue();
    data.confirmedBenefits = benefitsData.confirmedBenefits;
    data.selectedBenefits = benefitsData.selectedBenefits;
    this.benefitsData.next(data);
  }

  isBenfitAvailable(benefit, target: 'availableBenefits' | 'confirmedBenefits') {
    const benefitsData = this.appDataService.benefitsStubData.getValue();
    const itemIndex = benefitsData[target].findIndex((item) => {
      return item.reference === benefit.reference;
    });
    return itemIndex !== -1 ? true : false;
  }

  movebenefitFromSelectToConfirm() {
    let data = this.benefitsData.getValue();
    let selectedBenefits = data.selectedBenefits;
    selectedBenefits.forEach(element => {
      this.updateConfirmBenefitsData(element, 'selectedBenefits', 'confirmedBenefits');
    });
    data = this.benefitsData.getValue();
    // data.selectedBenefits = [];
    // this.benefitsData.next(data);
  }
  updateCard(benefits: BenefitsData, type: BenefitDataTypesAvailableToUpdate | 'confirmedBenefits', updatedbenefit): Array<Benefit> {
    const benefitToBeUpdated = benefits[type].filter(b => b.hybridTempLiving ? (b.reference === updatedbenefit.reference && b.pointsPerIncrement) : b.reference === updatedbenefit.reference);
    // If card does not exist add new card
    if (!benefitToBeUpdated || benefitToBeUpdated.length === 0) {
      if (type === 'selectedBenefits') { // Adding Benefit List to the top of the list.. for selected benefits...
        benefits[type].unshift(updatedbenefit);
        return benefits[type];
      } else {
        return benefits[type].concat(updatedbenefit);
      }

    } else { // update existing card
      return benefits[type].map(b => {
        if (b.hybridTempLiving ? b.reference === updatedbenefit.reference && b.pointsPerIncrement : b.reference === updatedbenefit.reference) {
          return updatedbenefit;
        } else {
          return b;
        }
      });
    }
  }


  removeCard(benefits, type, updatedbenefit) {
    if (
      (updatedbenefit.perPointCurrency && updatedbenefit.selectedDollarIncrementalCount)|| 
      (updatedbenefit.pointsPerIncrement && updatedbenefit.selectedIncrementCount > 0 && type == 'selectedBenefits') || 
      (updatedbenefit.hybridTempLiving && updatedbenefit.selectedIncrementCount > 0 && type == 'selectedBenefits') || 
      (updatedbenefit.pointsPerIncrement && updatedbenefit.selectedIncrementCount && type == 'availableBenefits' && updatedbenefit.selectedIncrementCount < (updatedbenefit.rangeMax / updatedbenefit.rangeIncrementValue)) ||
      (updatedbenefit.perPointCurrency && updatedbenefit.selectedDollarIncrementCount && type == 'availableBenefits' && updatedbenefit.selectedDollarIncrementCount < (updatedbenefit.rangeMax / updatedbenefit.rangeIncrementValue)) 
      ) {
      return benefits[type];
    } else {
      return benefits[type].filter(b => b.hybridTempLiving && (b.reference === updatedbenefit.reference)? (b.coreUnits ? b.reference  === updatedbenefit.reference : b.reference !== updatedbenefit.reference && b.pointsPerIncrement): b.reference !== updatedbenefit.reference);
    }
  }


  selectBenefit(benefit: Benefit): Observable<{ reference: string, productSpecificationReference: string, selectedPoints: string }> {
    benefit = { ...benefit };
    delete benefit.icon;
    delete benefit.hasNoPreRequisite;
    delete benefit.selectedDate;
    delete benefit.hybridDelCount ;
    let response: any = " ";
    // return this.baseClientService
    //   .post(`/v1/benefit`, benefit)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty  = null;
    //       return of(empty);
    //     })
    //   );
    const res = { ...benefit };
    let points = res.points.toString();
    let pointsAdded = benefit.maxMul ? (benefit.selectedCount - benefit.selectedIncrementCount) as number *
      (benefit.points as number) : ((benefit.pointValue) ?
        (benefit.selectedIncrementCount - (benefit.selectedCashOutPoints || 0)) : (benefit.pointsPerIncrement ?
          (benefit.pointsPerIncrement * benefit.selectedIncrementCount) : benefit.perPointCurrency ?
            (benefit.rangeIncrementValue * benefit.selectedDollarIncrementCount) : (benefit.tierConfigs ? (benefit.advCashOutSelectedPoints - benefit.selectedCashOutPoints) || 0 :
              (benefit.points as number))));
    if (res.displayName === "Cash Out" && res.category === "Allowance") {
      pointsAdded = res.advCashOutSelectedPoints;
    }
    response = {
      "reference": res.reference,
      "productSpecificationReference": res.productSpecificationReference,
      "selectedPoints": pointsAdded,
    };
    return of(response)
  }

  updateCategoryNames(benefits: Array<Benefit>) {
    let newMapping = {};
    Object.keys(CategoryMapping).map(i => {
      newMapping[i] = CategoryMapping[i];
    });
    newMapping['Allowances'] = 'Allowance';
    return benefits.map(benefit => {
      const category = newMapping[Object.keys(newMapping).find(key => key.toLowerCase() === benefit.category.toLowerCase())];
      if (category) {
        benefit.category = category;
      }
      return benefit;
    });
  }

  /**
  /**
   * Delete benefit information 
   * @param benefits Array of benefits to be deleted
   */
   deleteBenefits(benefits: Array<Benefit>, deleteAllHybrid?: boolean) {
    const benToBeDeleted = JSON.parse(JSON.stringify(benefits)) || [];
    const benefitBody = benToBeDeleted.map(
      ({ reference, productSpecificationReference, points, selectedCount, selectedIncrementCount, selectedDollarIncrementCount, rangeMin,
        rangeIncrementValue, confirmedIncrementCount, hybridTempLiving, selectedbenefitCount }) => {
          if (selectedIncrementCount) {
            if (benToBeDeleted.length === 1) {
              // selectedIncrementCount = ((deleteAllHybrid && hybridTempLiving) || hybridTempLiving) ? selectedIncrementCount : ((selectedIncrementCount <= (rangeMin / rangeIncrementValue)) && !confirmedIncrementCount) ?
              selectedIncrementCount = (deleteAllHybrid === false && hybridTempLiving ) ? selectedbenefitCount : (deleteAllHybrid && hybridTempLiving) ? selectedIncrementCount : ((selectedIncrementCount <= (rangeMin / rangeIncrementValue)) && !confirmedIncrementCount) ?
                (rangeMin / rangeIncrementValue) : 1;
            }
  
          return {
            reference,
            productSpecificationReference,
            points,
            selectedIncrementCount
          };
        }
        if (selectedDollarIncrementCount) {
          if (benToBeDeleted.length === 1) {
            selectedIncrementCount = ((deleteAllHybrid && hybridTempLiving) || hybridTempLiving) ? selectedIncrementCount : ((selectedIncrementCount <= (rangeMin / rangeIncrementValue)) && !confirmedIncrementCount) ?
              (rangeMin / rangeIncrementValue) : 1;
          }
          return {
            reference,
            productSpecificationReference,
            points,
            selectedDollarIncrementCount
          };
        }
        if (benToBeDeleted.length === 1) { selectedCount = 1; } // when multiplicity cards are not deleted in bulk
        return {
          reference,
          productSpecificationReference,
          points,
          selectedCount
        };
      });
    // return this.baseClientService
    //     // tslint:disable-next-line: max-line-length
    //     .bulkDelete(`/v1/benefit`, benefitBody)
    //     .pipe(
    //         map(r => r),
    //         catchError(err => {
    //             console.log('Failed to delete benefit', err);
    //             const emptyResp = null;
    //             return of(emptyResp);
    //         })
    //     );
    const pointsAdded = benToBeDeleted[0].maxMul ? (benToBeDeleted[0].selectedCount - benToBeDeleted[0].selectedbenefitCount) as number *
      (benToBeDeleted[0].points as number) : ((benToBeDeleted[0].pointValue) ?
        (benToBeDeleted[0].selectedbenefitCount - (benToBeDeleted[0].selectedCashOutPoints || 0)) : (benToBeDeleted[0].pointsPerIncrement ?
          (benToBeDeleted[0].pointsPerIncrement * benToBeDeleted[0].selectedbenefitCount) : (benToBeDeleted[0].tierConfigs ? (benToBeDeleted[0].advCashOutSelectedPoints - benToBeDeleted[0].selectedCashOutPoints) || 0 :
            (benToBeDeleted[0].points as number))));
    let response = {
      "selectedPoints": pointsAdded,
      "benefits": [
        {
          "reference": benToBeDeleted[0].reference,
          "productSpecificationReference": benToBeDeleted[0].productSpecificationReference,
          "selectedIncrementCount": benToBeDeleted[0].tierConfigs ? benToBeDeleted[0].advCashOutSelectedPoints : benToBeDeleted[0].points
        }
      ]
    }
    return of(response.benefits);
  }

  benefitPointValidation(points: number) {
    const toastr = this.injector.get(MatSnackBar);
    const needsAssessmentService = this.injector.get(NeedsAssessmentSharedService);
    const needsAssessment = needsAssessmentService.transfereeNeedsAssessmentDetails.getValue();
    const totalPoints = this.benefitsData.getValue().points;
    const newTotalPoints = (needsAssessment.pointDetails.confirmedPoints || 0) + (needsAssessment.pointDetails.selectedPoints || 0)
      + points;
    if (newTotalPoints > totalPoints) {
      toastr.open('The total points selected is exceeding the total points allocated', null, {
        duration: 6000
      });
      return false;
    }
    return true;
  }

  /**
   * Split multilicity benefits to array of individual benefits
   */
  splitBenefits(benefits: Array<Benefit>): Array<Benefit> {
    let splitselectedBenefits = [];
    benefits.forEach((benefit) => {
      if (benefit.maxMul) {
        splitselectedBenefits = splitselectedBenefits.concat(new Array(benefit.selectedCount).fill(benefit));
      } else if (benefit.pointsPerIncrement && !benefit.hybridTempLiving) { // for incremental benefits
        splitselectedBenefits = splitselectedBenefits.concat(new Array(benefit.selectedIncrementCount).fill(benefit));
      } else if (benefit.perPointCurrency && !benefit.hybridTempLiving) { // for incremental benefits
        splitselectedBenefits = splitselectedBenefits.concat(new Array(benefit.selectedDollarIncrementCount).fill(benefit));
      } else {
        splitselectedBenefits.push(benefit);
      }
    });
    return splitselectedBenefits;
  }
  
  /**to split hybrid benefit as core and flex */
  splitHybridBenefit(selectBenefitList: Array<Benefit>, updateFlexBenefit?: Benefit) {
    let benefitList = selectBenefitList;
    if( updateFlexBenefit ) {
      const benefitIndex = selectBenefitList.findIndex(benefit => benefit.reference === updateFlexBenefit.reference && benefit.pointsPerIncrement && !benefit.coreUnits);
      selectBenefitList[benefitIndex] = {...updateFlexBenefit}
    } else {
      benefitList.forEach((benefit, index) => {
        if(benefit.hybridTempLiving && benefit.coreUnits && benefit.pointsPerIncrement) {
          let coreBenefit = {...benefit};
          
  
          coreBenefit['points'] = 'Guaranteed';
          delete coreBenefit.pointsPerIncrement; 
          delete coreBenefit.rangeIncrementUnit;
          delete coreBenefit.rangeIncrementValue; 
          delete coreBenefit.rangeMax;
          delete coreBenefit.rangeMin;
          delete coreBenefit.selectedIncrementCount
          delete benefit.selectedbenefitCount;
          delete coreBenefit.selectedbenefitCount
          selectBenefitList.push(coreBenefit);
  
          let flexBenefit = {...benefit};
          delete flexBenefit.coreTimeUnit;
          delete flexBenefit.coreUnits;
  
          selectBenefitList[index] = flexBenefit;
          
        }
        else if (benefit.hybridTempLiving && benefit.pointsPerIncrement && benefit.selectedIncrementCount === 0) {
          selectBenefitList.splice(index,1);
        }

      });
    }
    
    return selectBenefitList;
  }

  mergeHybridBenefit(selectBenefitList: Array<Benefit>): Array<Benefit> {
    selectBenefitList.forEach((benefit, index) => {
      if (benefit.hybridTempLiving && !benefit.coreUnits && benefit.pointsPerIncrement && !benefit.assessNeeds) // finding flex benefit is hybrid and having associated core
      {
        let associatedCoreBen = selectBenefitList.findIndex((coreBen, index) => coreBen.reference === benefit.reference && coreBen.coreUnits);
        if (associatedCoreBen != -1) {
          let coreBenefit = selectBenefitList.find((coreBen, index) => coreBen.reference === benefit.reference && coreBen.coreUnits);
          selectBenefitList.splice(associatedCoreBen, 1);
          delete coreBenefit.points; // delete hard coded points = 'Guaranteed'
          let merge = Object.assign(coreBenefit, benefit);
          const flexIndex = selectBenefitList.findIndex(ben => ben.reference === benefit.reference);
          selectBenefitList[flexIndex] = { ...merge }
        }
      }
    });
    return selectBenefitList;
  }

  /*** To confirm selected benefits
   * @param benefits Array of selected benefits
   */
  confirmBenefit(benefits: Array<Benefit>): Observable<[{ reference: string, productSpecificationReference: string, status: string }]> {
    let response: any = " ";
    let res: any = " ";
    const data = {
      'benefits': [...benefits.map(benefit => {
        delete benefit.icon;
        delete benefit.hasNoPreRequisite;
        delete benefit.selectedDate;
        delete benefit.selectedCashOutValue;
        delete benefit.selectedCashOutPoints;
        return benefit;
      })]
    };
    // return this.baseClientService
    //   .post(`/v1/benefit/confirm`, data)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    response =
    {
      ...benefits.map(benefit => [
        {
          reference: benefit.reference,
          productSpecificationReference: benefit.productSpecificationReference,
          status: "Success",
          "action": "Create"
        }
      ]
      )
    };
    res = JSON.parse(JSON.stringify(response));
    return of(res);
  }

  /**
   * To disable benefits based on exclusions of selected benefits
   * @param exclusionList Array of benefits to be excluded
   * @param benefits benefit listing
   */
  resetExclusions(exclusionList: Array<string>, benefits) {
    benefits.availableBenefits.map((benefit) => {
      exclusionList.includes(benefit.reference) ? benefit.disable = true : delete benefit.disable;
    });
  }

  /**
   * Iterate through all selected and confirmed benefits to get list of exclusions
   * @param benefits list of all benefits
   */
  getListOfExclusions(benefits) {
    const selectedBenefits = benefits.selectedBenefits.concat(benefits.confirmedBenefits);
    let listOfSelectedExclusions: Array<string> = [];
    selectedBenefits.map((b) => {
      (b.exclusions && b.exclusions.length > 0) ? listOfSelectedExclusions = listOfSelectedExclusions.concat(b.exclusions) : null;
    });
    this.resetExclusions([...new Set(listOfSelectedExclusions)], benefits);
  }

  getListOfPreRequisites(benefits) {
    const selectedBenefits = benefits.selectedBenefits.concat(benefits.confirmedBenefits);
    benefits.availableBenefits.map((benefit: Benefit) => {
      if (benefit.prerequisites) {
        for (const p of benefit.prerequisites) {
          if (selectedBenefits.filter((benefit) => benefit.reference === p).length > 0) {
            benefit.hasNoPreRequisite = false;
            break; // break from loop of even if one of the pre requisites matches with selected benefit (Or Condition)
          }
          benefit.hasNoPreRequisite = true;
        }
      }
    });
    return benefits;
  }

  /**
   * To get list of selected benefits for which the provided benefit is a prerequisite of
   * @param benefits : array of benefits
   */
  getPreRequisiteDependencies(benefits: Array<Benefit>) {
    let dependencies: Array<Benefit> = [];
    let dependentBenefits = [];
    for (let benefit of benefits) {
      const allBenefits = this.benefitsData.getValue();
      const confirmselectBenefits = allBenefits ? [...allBenefits.selectedBenefits, ...allBenefits.confirmedBenefits] : null;
      if (confirmselectBenefits) {
        let confirmedSelBenefitsRef = confirmselectBenefits.map((val) => val.reference);
        confirmedSelBenefitsRef = confirmedSelBenefitsRef.filter((val) => val !== benefit.reference);
        // get list of all dependent cards
        const allDependentBenefits = allBenefits.selectedBenefits.filter((selBenefit: Benefit) => {
          if (selBenefit.prerequisites && selBenefit.points !== 'Guaranteed') {
            return selBenefit.prerequisites.includes(benefit.reference);
          }
        });
        // get list of all dependent cards with no pre requisite in selected list
        dependentBenefits = dependentBenefits.concat(allDependentBenefits.filter((d) => {
          for (const pre of d.prerequisites) {
            if (confirmedSelBenefitsRef.includes(pre)) {
              return null;
            }
          }
          return d;
        }));
      } else {
        return null;
      }
    }
    if (dependentBenefits.length > 0) {
      dependencies = this.getPreRequisiteDependencies(dependentBenefits);
    }
    dependencies = [...dependentBenefits, ...dependencies];
    return dependencies;
  }

  /**Method to update the task status for benefits pending with tasks */
  updateBenefitTaskStatus(taskUpdate: Array<TaskUpdate>, benefit: any): Observable<any> {
    const data = { 'tasks': taskUpdate };
    let is_hvgTask_include = false;
    // return this.baseClientService
    //   .patch(`/v1/benefit/${benefit.productId}`, data)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    const hgvtask = benefit.taskInfo.tasks.filter((data) => {
      if (data && data.hasOwnProperty('highValueItemTypeList')) {
        return data;
      }
    });
    if (hgvtask && hgvtask.length > 0) {
      is_hvgTask_include = true;
    }
    let pendingTaskCount = benefit.taskInfo.tasks.filter(task => !task.status).length;
    // Below condition is made as for high value task, there is no status comes.
    if (is_hvgTask_include &&
      (benefit.taskInfo.allHighValueTaskComplete ||
        benefit.taskInfo.allHighValueTaskOptOut)) {
      pendingTaskCount = pendingTaskCount - 1;
    }
    const response = {
      "allTaskComplete": (pendingTaskCount > 0) ? false : true,
      "reference": taskUpdate[0].reference,
      "status": taskUpdate[0].status
    };

    return of(response);
  }

  /**
   * Sort the benefits by { Before Move, Shipping, Travel & Transport, After Arrival, Allowance, Ongoing Support }
   * in the exact order with each group alphabetically sorted benefits..
   * @param benefits List of Selected Benefits
   */
  sortBenefitByGroup(benefits: Array<Benefit>): Array<Benefit> {
    let _benefits = [];
    const _category = {
      'Before Move': null,
      'Shipping': null,
      'Travel & Transport': null,
      'After Arrival': null,
      'Allowance': null,
      'Ongoing Support': null
    };

    // For core cards
    Object.keys(_category).map(category => {
      // Get Benefits by Category and sort the list by name;
      const _benefitsByCategory = benefits
        .filter(benefit => benefit.category === category && (benefit.points && benefit.points === 'Guaranteed'))
        .sort((a, b) => a.displayName.localeCompare(b.displayName));

      // Merge the benefits into the source list...
      _benefits = _benefits.concat(_benefitsByCategory.map(benefit => benefit));
    });

    // Merge the flexit benefits into the source list...
    _benefits = _benefits
      .concat(benefits
        .filter(benefit => (benefit.points !== 'Guaranteed'))
        .sort((a, b) => new Date(b.selectedDate).getTime() - new Date(a.selectedDate).getTime()));

    return _benefits;
  }

  /**
  * To reorder available benefits by consultant only card
  * @param availableBenefits available benefits ordered alphabetically
  */
  reOrderAvailableBenefits(availableBenefits) {
    const activatedConsultantOnlyCards = [];
    const nonActivatedConsultantCards = [];
    availableBenefits = availableBenefits.filter(benefit => {
      if (!benefit.consultantOnlyCard) {
        return benefit;
      } else if (benefit.consultantOnlyCard && benefit.activated) {
        activatedConsultantOnlyCards.push(benefit);
      } else if (benefit.consultantOnlyCard && !benefit.activated) {
        nonActivatedConsultantCards.push(benefit);
      }
    });
    availableBenefits = this.cookieService.get('transferee-context') ?
      [...availableBenefits, ...activatedConsultantOnlyCards, ...nonActivatedConsultantCards] :
      [...availableBenefits, ...activatedConsultantOnlyCards];
    return availableBenefits;

  }

  /**
   * To activate/deactivate consultant card
   * @param activateBenefit benefit reference with flag to activate/deactivate
   */
  activateConsultantCard(activateBenefit: { referenceId: string, activated: boolean }) {
    return this.baseClientService
      .put(`/v1/benefit/activate-deactivate`, activateBenefit)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }
  /**
   * Method for opting out or decline the High value insurance task
   * @param productId 
   */
  optOutHighValueTask(productId: string): Observable<any> {
    // return this.baseClientService
    //   .patch(`/v1/benefit/${productId}/hvg/optout`, data)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    const data = { "code": "HVG_OPTOUT_UPDATE_SUCCESS", "message": "High value insurance optout updated successfully" };
    return of(data);
  }
  /**
   * Method to add insurance Item
   * @param highValueItem 
   * @param productId 
   */
  addHighValueItem(highValueItem: HighValueItem, productId: string): Observable<{ code: string, message: string, id: string }> {
    // return this.baseClientService
    //   .post(`/v1/benefit/${productId}/hvg/insurance`, highValueItem)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    const data = { "code": "HVG_INSURANCE_CREATE_SUCCESS", "message": "High Value Item Added", id: productId };
    return of(data);
  }
  /**
   * Method to edit added high value Item
   * @param highValueItem 
   * @param productId 
   */
  editHighValueItem(highValueItem: HighValueItem, productId: string): Observable<{ code: string, message: string, id: string }> {
    // return this.baseClientService
    //   .put(`/v1/benefit/${productId}/hvg/insurance/${highValueItem.id}`, highValueItem)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    const data = { "code": "HVG_INSURANCE_UPDATE_SUCCESS", "message": "Changes Saved", id: productId };
    return of(data);
  }
  /**
   * Method to delete added high value item.
   * @param highValueItemId
   * @param productId
   */
  deleteHighValueItem(highValueItemId: string, productId: string): Observable<{ code: string, message: string }> {
    // return this.baseClientService
    //   .delete(`/v1/benefit/${productId}/hvg/insurance/${highValueItemId}`)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    const data = { "code": "HVG_INSURANCE_DELETE_SUCCESS", "message": "High value insurance deleted successfully" };
    return of(data);
  }
  /**
   * Method to submit high value item task
   * @param productId
   */
  submitHighValueItem(productId: string): Observable<{ code: string, message: string }> {
    // return this.baseClientService
    //   .put(`/v1/benefit/${productId}/hvg/insurance/submit`, {})
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    const data = { "code": "HVG_SUBMIT_UPDATE_SUCCESS", "message": "High Value Items Submitted" };
    return of(data);
  }

  addHousingPreferenceItem(housingPreference: HousingPreference, productId): Observable<any> {
    // return this.baseClientService
    //   .put(`/v1/benefit/${productId}/housing-preference`, housingPreference)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
    let value = {
      'benefit': {
        'housingPreference': {
          "housingPreferencesDraft": true,
          "desiredLocation": {
            "streetLine1": "100, Raoul Wallenberg Place Southwest, Southwest Washington",
            "city": "Washington",
            "state": "District of Columbia",
            "country": "United States",
            "postalCode": "20024"
          },
          "alternateLocation": {
            "streetLine1": "Golden Gate Bridge",
            "city": "San Francisco",
            "state": "California",
            "country": "United States",
            "postalCode": null
          },
          "destinationOfficeLocation": {
            "streetLine1": null,
            "city": null,
            "state": null,
            "country": null,
            "postalCode": null
          },
          "priorities": [
            "Meets Special Requests",
            "Assigned Parking Available",
            "Notice to Vacate - 14 days",
            "Requested # of Bedrooms",
            "Parking Lot Available"
          ],
          "numberBedroomsValueList": [
            "Studio",
            "1 Bedroom",
            "2 Bedroom",
            "3 Bedroom"
          ],
          "prioritiesValueList": [
            "None",
            "Assigned Parking Available",
            "Meets Special Requests",
            "Notice to Vacate - 14 days",
            "Parking Lot Available",
            "Pet Friendly",
            "Requested # of Bedrooms",
            "Requested Dates Available",
            "Requested Floor Level - 1st",
            "Requested Floor Level - Top",
            "Requested Location",
            "Within Budget Limit"
          ],
          "housekeepingInfo": {
            "housekeepingFrequency": "None",
            "comments": "hello"
          },
          "parkingInfo": {
            "parkingApproved": null,
            "comments": null
          },
          "housingPreferencesSubmittedDate": null,
          "additionalInstructions": null,
          "moveInDate": "2022-05-06",
          "moveOutDate": "2022-05-31",
          "numberApartments": 2,
          "numberBedrooms": "2 Bedroom",
          "babyCotRequired": false,
          "acceptableCommute": null,
          "furnished": false,
          "kitchenRequired": false,
          "lengthOfStay": 26,
          "numberOfBathrooms": 2,
          "numberOfBathroomsValueList": [
            1,
            2,
            3,
            4
          ]
        }
      }
    }
    return of(value);
  }
   /** get my contacts and notification for temp living */
   getMyContacts(): Observable<any> {
    return of (contactData);
    // return this.baseClientService
    // .getById<any>('/v1/benefit/booking-details')
    // .pipe(
    //   map(r => r),
    //   catchError((err, source) => {
    //     const empty: any = null;
    //     return of(empty);
    //   })
    // );
  }
  
  /**method to remove notificatio for my contact
   * temp living
   */
  updateViewStatus(viewStatus: boolean, id): Observable<any> {
    const payload = {
      'viewStatus': viewStatus
    }
    return this.baseClientService
      .put(`/v1/benefit/order/${id}/view-status`, payload)
      .pipe(
        map(r => r.body),
        catchError((err, source) => {
          const empty = null;
          return of(empty);
        })
      );
  }
  /**
  * Method to add AssessNeeds Items
  * @param assessNeeds
  */
   addAssessNeedsItem(assessNeeds: AssessNeeds, productId): Observable<any> {
    // return this.baseClientService
    //   .put(`/v1/benefit/${productId}/assess-needs`, assessNeeds)
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const empty = null;
    //       return of(empty);
    //     })
    //   );
     let values = {
       'benefit': {
      'assessNeeds': {
        "petsInformation": [{name: "puppy ", breed: "shefered", weight: "3.6 lb", type: "Dog"}],
        "departureLocation": "United States",
        "travelHistory": ["Afghanistan", "Aland Islands"],
        "assessNeedsDraft": true,
        "accompanyingMembers": ["61b18b415377f70008e19e25","61b2045d88856f00093132d9","61b6fbf60d430e0009d3d419"]
      }
     }
     }
     return of(values);
  }
}

export const contactData = {
  "body": {
    "benefits": [
      {
        "offeringReference": "OFF-TempLvngCoreIncrTimeBB-8ef7eea2-0637-4fed-9c2a-eef79b6335e4",
        "displayName": "Temporary Living Core/Flex Option",
        "iconLink": "https://assetstst01.cartus.com/OfferingsIcons/illustration-tempLiving.svg",
        "iconName": "Temp Housing",
        "suplierOrders": [
          {
            "id": "626fa3817dac7c0009e5c90b",
            "orderReference": "SUP-20220502-2749",
            "productId": "626f8c4f02585600090687f7",
            "viewStatus": false,
            "bookingInfo": {
              "propertyName": "TestOnly",
              "providerName": "Peter",
              "rentPaidBy": "Cartus",
              "apartmentType": "Bunglow",
              "address": {
                "city": "Miami",
                "state": "Florida",
                "country": "United States",
                "postalCode": "33132",
                "streetLine1": "401, Biscayne Boulevard, Downtown Miami"
              },
              "moveDates": {
                "moveIn": "2022-05-05",
                "moveOut": "2022-05-31"
              }
            }
          }
        ],
        "tempLiving": true
      }
    ],
    "movOrderRef": "MOV-20220425-3000"
  }
}

