import { Injectable, Output, EventEmitter } from '@angular/core';

@Injectable()
export class BreadCrumbService {

  isOpen = false;
    arrVal = [];
  getBreadCrumbs(currPath, filenumber, empName) {
      if (currPath === 'emp-cost-transactions') {
        this.arrVal = [{
            params: '',
            label: 'Home',
            url: '/bbadmin/administration/dashboard'
          },
          {
            params: '',
            label: empName,
            url: '/bbadmin/administration/empInfo/' + filenumber
          },
          {
            params: '',
            label: 'Transactions',
            url: ''
          }];
      } else if (currPath === 'empInfo') {
        this.arrVal = [{
            params: '',
            label: 'Home',
            url: '/bbadmin/administration/dashboard'
          },
          {
            params: '',
            label: empName,
            url: ''
          }];
      } else if (currPath === 'provider') {
        this.arrVal = [{
            params: '',
            label: 'Home',
            url: '/bbadmin/administration/dashboard'
          },
          {
            params: '',
            label: empName,
            url: '/bbadmin/administration/empInfo/' + filenumber
          },
          {
            params: '',
            label: 'Providers',
            url: ''
          }];
      } else if (currPath === 'settings') {
        this.arrVal = [{
            params: '',
            label: 'My Dashboard',
            url: '/bbadmin/administration/dashboard'
          },
          {
            params: '',
            label: 'Account Settings',
            url: '/bbadmin/administration/settings/' + filenumber
          }];
      }
      return this.arrVal;
  }
}
