import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BBAdminSharedModule } from 'projects/BBAdmin/src/app/app.module';
import { BenefitBuilderSharedModule } from 'projects/BenefitBuilder/src/app/app.module';
import { DashBoardSharedModule } from 'projects/Dashboard/src/app/app.module';
import { MobilifySharedModule } from 'projects/Mobilify/src/app/app.module';
import { HomePageComponent } from './public/home-page/home-page.component';
import { LoginComponent } from './login/login.component';
import { TranslateModule } from '@ngx-translate/core';

import { UserComponent } from './user/user.component';
import { ManageUsersComponent } from './public/manage-users/manage-users.component';
import { ManageClientsComponent } from './public/manage-clients/manage-clients.component';
import { AddEditClientsComponent } from './public/clients/add-edit-clients/add-edit-clients.component';


const routes: Routes = [
  {
    path: 'bb',
    loadChildren: 'projects/BenefitBuilder/src/app/app.module#BenefitBuilderSharedModule'
  },
  {
    path: 'bbadmin',
    loadChildren: 'projects/BBAdmin/src/app/app.module#BBAdminSharedModule'
  },
  {
    path: 'mobilify',
    loadChildren: 'projects/Mobilify/src/app/app.module#MobilifySharedModule'
  },
  {
    path: 'dashboard',
    loadChildren: 'projects/Dashboard/src/app/app.module#DashBoardSharedModule'
  },
  { path: 'home', component: HomePageComponent },
  { path: 'login', component: LoginComponent },
  { path: 'user' , component: UserComponent},
  { path: 'users', component: ManageUsersComponent},
  { path: 'clients', component: ManageClientsComponent},
  { path: 'addclient', component: AddEditClientsComponent},
  { path: 'login/:id', component: LoginComponent },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
  BenefitBuilderSharedModule.forRoot(),
  BBAdminSharedModule.forRoot(),
  MobilifySharedModule.forRoot(),
  DashBoardSharedModule.forRoot()
  ],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }
