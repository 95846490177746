import { Injectable } from "@angular/core";
import { BaseClientService } from "./base-client.service";
import { RemoteLoggingService } from "./remote-logging.service";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { employeeData } from "../data/employeeData";
import { MoveProDashboadDataService } from '../data/move-pro-dashboad-data.service';

/** This service is used for returning policy names for the particular client */
@Injectable({
  providedIn: "root",
})
export class PolicyService {
  /** Base constructor method
   * @param baseClient BaseClientService injection
   * @param logSvc RemoteLoggingService injection
   */
  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService,
    private moveProDashboadDataService: MoveProDashboadDataService
  ) {}

  /** getPoliciesByClientId() method to get the policy names from the api using base client service */
  getPoliciesByClientId(): Observable<string[]> {
    // return this.baseClient.getArr<string>('v1/policy', 'get policy name').pipe(
    //   map(r => r.body),
    //   catchError((err, source) => {
    //     const emptyArray: string[] = [];
    //     this.logSvc.logError(err);
    //     return of(emptyArray);
    //   })
    // );
    let tempEmployeeData = {"data": new Array};
    tempEmployeeData = this.moveProDashboadDataService.employeeDataSubject.getValue();
    let policiesArray = [];
    let policiesList = {
      count: 0,
      policyNames: [],
    };
    tempEmployeeData .data.forEach((ele) => {
      policiesArray.push(ele.policyName);
    });
    const policySet = new Set(policiesArray);
    const displayPolicy = [...policySet];
    policiesList.count = displayPolicy.length;
    policiesList.policyNames = displayPolicy;

    return of(JSON.parse(JSON.stringify(policiesList)));
  }
}
