import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { userPreference } from '../data/data';
import { UserPreference } from '../models/user-preference.model';

/** Service to get and set user preferences */
@Injectable({
  providedIn: 'root'
})
export class UserPreferencesService {
  /** standard constructor for services */
  constructor() { }
 
  /**
   * Get user preference
   */
  getPreference(key: string, persistent: boolean, isFirst = false): Observable<any> {
    if (isFirst) {
      return of(userPreference);
    }
    if (persistent) {
      return of(JSON.parse(localStorage.getItem(key)));
    } else {
      return of(JSON.parse(sessionStorage.getItem(key)));
    }
     //return of (userPreference);
  }

  /**
   * Set user preference
   */
  setPreference(userPreference: UserPreference): void {
    if (userPreference.Persist) {
      localStorage.setItem(userPreference.Key, JSON.stringify(userPreference.Value));
    } else {
      sessionStorage.setItem(userPreference.Key, JSON.stringify(userPreference.Value));
    }
  }
}
