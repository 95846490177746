<div class="main-content">
    <h1>My Dashboard
        <span>
            <button tabindex="0" #btnDashboardConfig="matButton" mat-icon-button onclick="this.blur()" matTooltip="Open dashboard view selection" class="setting" aria-label="open dashboard view selection" (click)="dashboardViewDialog()"><mat-icon>settings</mat-icon></button>
        </span>
    </h1>
    <div class="kpi-section">
        <app-powerbi-report-container [configs$]="configs$"></app-powerbi-report-container>
    </div>
    <div class="search-block" fxLayout.xs="row" fxLayoutAlign.xs="center center" *ngIf="aggregateDashboardBy==='employeeList'">
        <mat-form-field class="table-search" appearance="outline">
            <span matPrefix  class="lg-only">
                <mat-icon>search</mat-icon>
            </span>
            <!-- <input tabindex="0" matInput placeholder="Search within table for..." (keyup.enter)="getSearchTextResults($event.target.value)"> -->
            <input tabindex="0" matInput placeholder="Search within table for..." (keyup.enter)="getSearchTextResults($event.target.value)" [(ngModel)]="searchValue">
            <button tabindex="0" mat-button *ngIf="searchValue" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearchTextResults()">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
        <button tabindex="0" mat-icon-button *ngIf="aggregateDashboardBy==='employeeList'" class="filter-icon"
            (click)="filterModal()" [disabled] = "!enableFilterIcon" >
            <mat-icon tabindex="0" [matTooltip]="enableFilterIcon ? 'Filter results ' : 'Please choose to show a detail in the table by using the column button to be able to filter on it.'"  aria-hidden="true" class="material-icons">filter_list</mat-icon></button>
        <!-- <button mat-icon-button class="filter-icon" matTooltip="selection results" (click)="dataViewSelectionModal()"><span tabindex="0" aria-hidden="true" class="material-icons">selection_list</span></button> -->
        <button tabindex="0" mat-icon-button class="selection-icon" aria-label="Select data view table list" matTooltip="Column Selection results" (click)="dataViewSelectionModal()">
            <mat-icon>menu</mat-icon>
          </button>
        <button mat-icon-button class="filter-column" fxHide><span class="material-icons">view_column</span></button>
    </div>
     <app-filter-chip *ngIf="aggregateDashboardBy==='employeeList'" [filterData]="filterDetailsListItems" (filterOptions)="getUpdatedFilterOptions($event)"></app-filter-chip> 
    <app-aggregation-view *ngIf="aggregateDashboardBy==='destinationCountry'" (setActive)="setActive([$event])" [aggregationType]='"destinationCountry"' [aggregationTypeDesc]='"Destination Country"'></app-aggregation-view>
    <app-aggregation-view *ngIf="aggregateDashboardBy==='departureCountry'" (setActive)="setActive([$event])" [aggregationType]='"departureCountry"' [aggregationTypeDesc]='"Departure Country"'></app-aggregation-view>
    <app-aggregation-view *ngIf="aggregateDashboardBy==='policyName'" (setActive)="setActive([$event])" [aggregationType]='"policyName"' [aggregationTypeDesc]='"Policy Name"'></app-aggregation-view>
    <app-aggregation-view *ngIf="aggregateDashboardBy==='divisionName'" (setActive)="setActive([$event])" [aggregationType]='"divisionName"' [aggregationTypeDesc]='"Division"'></app-aggregation-view>
    <app-employee-details *ngIf="aggregateDashboardBy==='employeeList'" (getClearCriteriaChange)="status($event)" [filters]="filters"
    [aggregationFetchParameter]="aggregationFetchParameter" [searchKeyword]="searchValue" [searchTriggered]="isSearchTriggered" [clearResults]="resultsExists" (getSortOptions)="sortOptions($event)"></app-employee-details>

    <div mat-elevation-z8 *ngIf="resultsExists">
        <mat-card class="empty-results">
            <div fxLayout="column" fxLayoutAlign="space-around center" >
                <p>0 results matching your criteria</p>
                <a (click)="clearCriteria()">Clear Criteria</a>
            </div>
        </mat-card>
    </div>
</div>
