import { Component, OnInit ,Input, Inject, Output,EventEmitter, ViewEncapsulation, ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProgramManagementService } from '../../../../services/program-management.service';
import { RemoteLoggingService } from './../../../../../../core/services/remote-logging.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatInputModule, MatSelectChange, MatSelectionList, MatOption, MatListOption } from '@angular/material';
import {​​ LocationsService }​​ from './../../../../services/locations.service';

@Component({
  selector: 'app-edit-benefit-dialog',
  templateUrl: './edit-benefit-dialog.component.html',
  styleUrls: ['./edit-benefit-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class EditBenefitDialogComponent implements OnInit {
  
  rule = new FormControl();
  value = 'Clear me';
  ruleForm: FormGroup;
  restrictionForm : FormGroup;
  clientDivisions: Array<any>;
  countryList: Array<any>;
  
  ruleList: Array<any> =  [{name: "Enable Linked Benefit"}, {name: "Disable Linked Benefit"}];

  disableSave: boolean = true;
  disableAddRule: boolean = true;
  addedDivision: any;
  addRule: any;
  @ViewChild('divisionSelect', {static: true}) divisionSelect: MatListOption;
  @ViewChild('allSelect', {static: true}) allSelect: MatListOption;
  @Output() publishDivisions = new EventEmitter<String>();

  selectedValue: string;
  selectedCar: string;
  allBenefitList: Array<any>; //filtered benefit list
  originalBenefitList: Array<any>; //original benefit list
  allRuleList: Array<any>;
  allRestrictionList: Array<any> = [
    { type: 'transferee_related', name: 'Transferee with Spouse' },
    { type: 'complex_rules', name: 'Intra-US or intra-Canada', description: 'Moves within the US or within Canada' },
    { type: 'complex_rules', name: 'US/Canada cross-border', description: 'Moves between the US and Canada' },
    { type: 'complex_rules', name: 'International', description: 'US inbound, US outbound, Canada inbound, Canada outbound, or any move between countries that are not US or Canada' },
    { type: 'complex_rules', name: 'Intra-country', description: 'Moves where the departure country and destination country match' },
    { type: 'complex_rules', name: 'Inter-country', description: 'Moves where the departure country and destination country do not match' },
    { type: 'departure_locations', name: 'Departure Location Includes…',  includes: [] },
    { type: 'departure_locations', name: 'Departure Location Excludes…', excludes: [] },
    { type: 'destination_locations', name: 'Destination Location Includes…', includes: [] },
    { type: 'destination_locations', name: 'Destination Location Excludes…', excludes: [] },
  ];
  countryListForEachRestrictionType = [
    { name: 'Departure Location Includes…', countryList: [] },
    { name: 'Departure Location Excludes…', countryList: [] },
    { name: 'Destination Location Includes…', countryList: [] },
    { name: 'Destination Location Excludes…', countryList: [] }
  ];
  restrictionList: Array<any> = [];
  allCountryList: Array<any> = [];
  deletedComplexRestrictions: Array<any> =[];
  restrictDisableFlag:Boolean= false;
  unmodifiedData = {};  
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    public dialogRef: MatDialogRef<EditBenefitDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly locationService: LocationsService
  ) { 
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.unmodifiedData = JSON.parse(JSON.stringify(this.data));
    this.restrictionList = [...this.allRestrictionList];
    this.getCountries();
    this.allBenefitList = [...this.data.benefitList];
    this.originalBenefitList = [...this.data.allBenefitList];
    this.allRuleList = [...this.ruleList];
    this.generateForm();
  }

  getCountries() {
    this.locationService.countryList.subscribe(countryList => {
      if (countryList.length > 0) {
        this.allCountryList = [...countryList];
        this.allCountryList.sort(function (a, b) {
          return a.countryName.localeCompare(b.countryName);
        });
        this.countryList = [...this.allCountryList];
        this.countryListForEachRestrictionType.forEach(ele=>ele.countryList=[...this.allCountryList]);
      }
    });
  }
  generateForm() {
    if (this.data.benefit.scopes) {
      if (this.data.benefit.scopes.complex_rules) {
        this.data.benefit.scopes.complex_rules.forEach(ele => {
          if (ele.hasOwnProperty('action')) {
            this.deletedComplexRestrictions.push(ele);
          }
        });
        this.data.benefit.scopes.complex_rules = this.data.benefit.scopes.complex_rules.filter(ele => !ele.hasOwnProperty('action'));
      }
    }

    this.ruleForm = new FormGroup({
      ruleArray: new FormArray([
        this.initRuleSection()
      ]),
    });

    this.restrictionForm = new FormGroup({
      restrictionArray: new FormArray([])
    });
    // this.addEmptyRestrictions();
    const prerequisites = [];
    let prerequisitesIndex;
    const exclusions = [];
    let exclusionIndex;
    if (this.data.prerequisites && this.data.prerequisites.length > 0) {
      this.data.prerequisites.forEach((item) => {
        //prerequisitesIndex = this.originalBenefitList.findIndex(benefit => benefit.reference === item.reference);
        prerequisites.push({ind: item});
        if(this.data.prerequisites.length > this.ruleForm.get(['ruleArray', 0, 'addedRule']).value.length) {
        const formArrayControl = <FormArray>this.ruleForm.get(['ruleArray', 0, 'addedRule']);
        formArrayControl.push(this.initBenefitSection());
        }
      }); 
      //prerequisites.push({ind: ''});
      this.ruleForm.get('ruleArray').setValue([{
        rule: 'Enable Linked Benefit',
        addedRule : prerequisites
      }]);
    }
    if (this.data.benefit.exclusions && this.data.benefit.exclusions.length > 0) {
      this.data.benefit.exclusions.forEach((item) => {
        exclusionIndex = this.originalBenefitList.findIndex(benefit => benefit.reference === item);
        exclusions.push({ind: this.originalBenefitList[exclusionIndex]});
        if (this.ruleForm.get('ruleArray').value.length < 2
          && this.data.prerequisites && this.data.prerequisites.length > 0) {
          const control = <FormArray>this.ruleForm.get('ruleArray');
          control.push(this.initRuleSection());
        }
      });
      const formArrayControl = <FormArray>this.ruleForm.get(['ruleArray', this.ruleForm.get(['ruleArray']).value.length - 1, 'addedRule']);
      for (let i = 0; i < exclusions.length -1 ; i++) {
        formArrayControl.push(this.initBenefitSection()) ;
      }
      this.ruleForm.get(['ruleArray', this.ruleForm.get(['ruleArray']).value.length - 1]).setValue({
        rule: 'Disable Linked Benefit',
        addedRule : exclusions
      });
    }

    if (this.data.benefit.scopes) { 
      if (this.data.benefit.scopes.departure_locations) {
        if (this.data.benefit.scopes.departure_locations.hasOwnProperty('includes') && this.data.benefit.scopes.departure_locations.includes.length>0) {
          this.restrictionArray.push(
            new FormGroup({
              restriction: new FormControl('Departure Location Includes…'),
              addedRestrictionArray: new FormArray(this.data.benefit.scopes.departure_locations.includes.map(ele=>new FormControl(ele)))
            })
          );
          this.restrictionList = this.restrictionList.filter(ele => ((ele.name !== 'Departure Location Excludes…') && (ele.name !== 'Departure Location Includes…')));
        }
        if (this.data.benefit.scopes.departure_locations.hasOwnProperty('excludes') && this.data.benefit.scopes.departure_locations.excludes.length>0) {
          this.restrictionArray.push(
            new FormGroup({
              restriction: new FormControl('Departure Location Excludes…'),
              addedRestrictionArray: new FormArray(this.data.benefit.scopes.departure_locations.excludes.map(ele=>new FormControl(ele)))
            })
          );
          this.restrictionList = this.restrictionList.filter(ele => ((ele.name !== 'Departure Location Excludes…') && (ele.name !== 'Departure Location Includes…')));
        }
      }
      if (this.data.benefit.scopes.destination_locations) { 
        if (this.data.benefit.scopes.destination_locations.hasOwnProperty('includes') && this.data.benefit.scopes.destination_locations.includes.length>0) {
          this.restrictionArray.push(
            new FormGroup({
              restriction: new FormControl('Destination Location Includes…'),
              addedRestrictionArray: new FormArray(this.data.benefit.scopes.destination_locations.includes.map(ele=>new FormControl(ele)))
            })
          );
          this.restrictionList = this.restrictionList.filter(ele => ((ele.name !== 'Destination Location Excludes…') && (ele.name !== 'Destination Location Includes…')));
        }
        if (this.data.benefit.scopes.destination_locations.hasOwnProperty('excludes') && this.data.benefit.scopes.destination_locations.excludes.length>0) {
          this.restrictionArray.push(
            new FormGroup({
              restriction: new FormControl('Destination Location Excludes…'),
              addedRestrictionArray: new FormArray(this.data.benefit.scopes.destination_locations.excludes.map(ele=>new FormControl(ele)))
            })
          );
          this.restrictionList = this.restrictionList.filter(ele => ((ele.name !== 'Destination Location Excludes…') && (ele.name !== 'Destination Location Includes…')));
        }
      }
      if (this.data.benefit.scopes.hasOwnProperty('spouse_partner')) {
        if (this.data.benefit.scopes.spouse_partner.spousePartnerRelocating) {
          this.restrictionArray.push(
            new FormGroup({
              restriction: new FormControl('Transferee with Spouse'),
              addedRestrictionArray: new FormArray([])
            })
          );
          this.restrictionList = this.restrictionList.filter(ele => ((ele.name !== 'Transferee with Spouse')));
        }
      }
      if (this.data.benefit.scopes.hasOwnProperty('complex_rules')) {
        
          this.data.benefit.scopes.complex_rules.forEach(ele => {
            this.restrictionArray.push(
              new FormGroup({
                restriction: new FormControl(ele.name),
                addedRestrictionArray: new FormArray([])
              })
            );
            this.restrictionList = this.restrictionList.filter(curr => ((curr.name !== ele.name)));
          })
        
      }

    }
    if ((<FormArray>this.restrictionForm.get('restrictionArray')).value.length === 8) {
      this.restrictDisableFlag = true;
    } else {
    this.addEmptyRestrictions();
    }
    this.ruleForm.get('ruleArray').value.forEach((rule, key) => {
      this.ruleForm.get('ruleArray').value[key].addedRule.forEach((element, index) => {
        index < rule.addedRule.length - 1 ? 
          this.data.benefitList = this.data.benefitList.filter(benefit => benefit.reference !== element.ind.reference): null;
      });
    });
}

disableRestriction(){
  if (this.restrictionForm.get('restrictionArray').value[this.restrictionForm.get('restrictionArray').value.length - 1].restriction === ''
    || (this.restrictionList.length <= 1)
    || !this.restrictionForm.get('restrictionArray').valid) {
    return true;
  }
  return false;
}

  addBenefitFormField(ruleIndex) {
    const formArrayControl = <FormArray>this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']);
    formArrayControl.value.length === 0 ? formArrayControl.push(this.initBenefitSection()) : null;
  }

  get addedRule(): FormArray {
    return this.ruleForm.get('addedRule') as FormArray;
  }

  checkRemoveButtonVisibility2(index: number, form) {
    
    if (index === 0 && form.value.addedRule.length === 1 && form.value.addedRule[0].ind == '') {
      return false;
    }
    return true;
  }

  checkAddButtonVisibility2(index: number, form) {
    if (index === form.value.addedRule.length - 1) {
      return true;
    }
    return false;
  }

  pushBackToListCountry(ind, restrictItem, restrictSectionIndex, restrictSection) {
    if (restrictItem.value !== '') {
      let countryList = this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList;
      countryList.push(this.allCountryList.find((ele => ele.countryName === restrictItem.value)));
      countryList.sort(function (a, b) {
        return a.countryName.localeCompare(b.countryName);
      });
    }
    if (ind === restrictSection.get('addedRestrictionArray').controls.length - 1) {
      restrictSection.get('addedRestrictionArray').controls[ind].setValue('');
    }else if ((restrictSection.get('addedRestrictionArray').controls[ind].value !== '') && restrictSection.get('addedRestrictionArray').controls.length > 1) {
      (<FormArray>restrictSection.get('addedRestrictionArray')).removeAt(ind);
    }else if (restrictSection.get('addedRestrictionArray').controls.length <= 1 && ind == 0 && restrictSection.get('addedRestrictionArray').controls[0] ) {
      restrictSection.get('addedRestrictionArray').controls[0].setValue('');
    }
  }

  
  pushBackToList(index: number, ruleIndex) {
    const control = this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule', index]);
    const findIndex = this.data.benefitList.findIndex(x => 
      x.reference === control.value.ind.reference);
    let removeValue;
      if (this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']).value.length > 0) {
        removeValue = control.value.ind;
        if (this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']).value.length === 1) {
          this.ruleForm.get(['ruleArray', ruleIndex]).value.rule === 'Enable Linked Benefit' ? this.data.prerequisites = [] : null;
          this.ruleForm.get(['ruleArray', ruleIndex]).value.rule === 'Disable Linked Benefit' ? this.data.benefit.exclusions = [] : null;
        }
        const formArrayControl = <FormArray>this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']);
        formArrayControl.value.length === 1? this.ruleForm.get(['ruleArray', ruleIndex, 'rule']).setValue(''): null;
        formArrayControl.value.forEach(item => {
          this.data.benefitList.findIndex(ele => ele.reference === item.ind.reference) === -1 ? this.data.benefitList.push(item.ind) : null;
        });
        this.data.benefitList.sort((a, b) => a.displayName ? a.displayName.localeCompare(b.displayName) : null);
        formArrayControl.removeAt(index);
        this.disableSave = false;
      }
  }

  associateBenefit(index, ruleIndex) {
    const formArrayControl = <FormArray>this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']);
    //const control = formArrayControl.controls[index].get('addedRule');
    formArrayControl.push(this.initBenefitSection());
    this.ruleForm.get('ruleArray').value.forEach((rule) => { 
      rule.addedRule.forEach(element => {
        this.data.benefitList = this.data.benefitList.filter(benefit => benefit.reference !== element.ind.reference);
      });
    });
    this.disableSave = false;
  }

  compareBenefits(o1: any, o2: any): boolean {
    return o1.reference === o2.reference;
  }

  close() {
    this.data = JSON.parse(JSON.stringify(this.unmodifiedData));
    this.dialogRef.close(this.data);
  }

  clearRestrictions(restrictSectionIndex, restrictSection) {
    if (restrictSection.get('restriction').value !=='' &&(<FormArray>this.restrictionForm.get('restrictionArray')).length > 0 && (<FormArray>this.restrictionForm.get('restrictionArray')).length - 1 !== restrictSectionIndex) {
      if (this.data.benefit.scopes) {
        let restrictSectionValue = restrictSection.get('restriction').value;
        if (this.data.benefit.scopes.departure_locations) {
          restrictSectionValue === 'Departure Location Includes…' ? this.data.benefit.scopes.departure_locations.includes = [] : null;
          restrictSectionValue === 'Departure Location Excludes…' ? this.data.benefit.scopes.departure_locations.excludes = [] : null;
        }
        if (this.data.benefit.scopes.destination_locations) {
          restrictSectionValue === 'Destination Location Includes…' ? this.data.benefit.scopes.destination_locations.includes = [] : null;
          restrictSectionValue === 'Destination Location Excludes…' ? this.data.benefit.scopes.destination_locations.excludes = [] : null;
        }
        if (this.data.benefit.scopes.complex_rules) { 
          let complexIndex = this.data.benefit.scopes.complex_rules.findIndex(ele => ele.name === restrictSectionValue);
          if ( complexIndex !== -1 && this.data.benefit.scopes.complex_rules[complexIndex].hasOwnProperty('id')) {
            this.deletedComplexRestrictions.push(Object.assign({}, { ...this.data.benefit.scopes.complex_rules[complexIndex], action: 'delete' }));
          }
          complexIndex !== -1 ? this.data.benefit.scopes.complex_rules.splice(complexIndex, 1) : null;
        }
        if (this.data.benefit.scopes.spouse_partner) { 
          this.allRestrictionList.find(obj => obj.name === restrictSectionValue).type === 'transferee_related' ? this.data.benefit.scopes.spouse_partner.spousePartnerRelocating = false : null;
        }
      }
      if (this.restrictDisableFlag) {
        this.addEmptyRestrictions();
        this.restrictDisableFlag = false;
      }
      (<FormArray>this.restrictionForm.get('restrictionArray')).removeAt(restrictSectionIndex);
      this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === restrictSection.value.restriction));
      restrictSection.value.restriction === 'Departure Location Includes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Departure Location Excludes…')) : null;
      restrictSection.value.restriction === 'Departure Location Excludes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Departure Location Includes…')) : null;
      restrictSection.value.restriction === 'Destination Location Includes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Destination Location Excludes…')) : null;
      restrictSection.value.restriction === 'Destination Location Excludes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Destination Location Includes…')) : null
    }else if (restrictSection.get('restriction').value !=='' && (<FormArray>this.restrictionForm.get('restrictionArray')).length - 1 === restrictSectionIndex) {​​​​​​​
      if (this.data.benefit.scopes) {
        let restrictSectionValue = restrictSection.get('restriction').value;
        if (this.data.benefit.scopes.departure_locations) {
          restrictSectionValue === 'Departure Location Includes…' ? this.data.benefit.scopes.departure_locations.includes = [] : null;
          restrictSectionValue === 'Departure Location Excludes…' ? this.data.benefit.scopes.departure_locations.excludes = [] : null;
        }
        if (this.data.benefit.scopes.destination_locations) {
          restrictSectionValue === 'Destination Location Includes…' ? this.data.benefit.scopes.destination_locations.includes = [] : null;
          restrictSectionValue === 'Destination Location Excludes…' ? this.data.benefit.scopes.destination_locations.excludes = [] : null;
        }
        if (this.data.benefit.scopes.complex_rules) { 
          let complexIndex = this.data.benefit.scopes.complex_rules.findIndex(ele => ele.name === restrictSectionValue);
          if ( complexIndex !== -1 && this.data.benefit.scopes.complex_rules[complexIndex].hasOwnProperty('id')) {
            this.deletedComplexRestrictions.push(Object.assign({}, { ...this.data.benefit.scopes.complex_rules[complexIndex], action: 'delete' }));
          }
          complexIndex !== -1 ? this.data.benefit.scopes.complex_rules.splice(complexIndex, 1) : null;
        }
        if (this.data.benefit.scopes.spouse_partner) { 
          this.allRestrictionList.find(obj => obj.name === restrictSectionValue).type === 'transferee_related' ? this.data.benefit.scopes.spouse_partner.spousePartnerRelocating = false : null;
        }
      }
      this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === restrictSection.value.restriction));
      restrictSection.value.restriction === 'Departure Location Includes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Departure Location Excludes…')) : null;
      restrictSection.value.restriction === 'Departure Location Excludes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Departure Location Includes…')) : null;
      restrictSection.value.restriction === 'Destination Location Includes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Destination Location Excludes…')) : null;
      restrictSection.value.restriction === 'Destination Location Excludes…' ? this.restrictionList.push(this.allRestrictionList.find(ele => ele.name === 'Destination Location Includes…')) : null;
      (<FormArray>this.restrictionForm.get('restrictionArray')).at(restrictSectionIndex).patchValue({​​​​​​​
        restriction: '',
        addedRestrictionArray: []
      });
      this.restrictDisableFlag = false;
    }
    this.restrictionList = this.restrictionList.filter((ele, index, self) =>
    index === self.findIndex((t) => (
      t.name === ele.name
    ))
    )​​​​​​​
  }

  clearRestrictions2(ruleIndex){
    const control = this.ruleForm.get(['ruleArray', ruleIndex]);
    //const findIndex = this.data.benefitList.findIndex(x => x.reference === control.value.ind.reference);
    let removeValue;
      if (this.ruleForm.get('ruleArray').value.length > 0) {
        removeValue = control.value;
        this.ruleList.findIndex(rule => rule.name === removeValue.rule) === -1 ?
        this.ruleList.push(removeValue) : null;
        removeValue.rule === 'Enable Linked Benefit' ? this.data.prerequisites = [] : null;
        removeValue.rule === 'Disable Linked Benefit' ? this.data.benefit.exclusions = [] : null;
        if (this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']).value.length > 0) {
          const benefitformArrayControl = <FormArray>this.ruleForm.get(['ruleArray', ruleIndex, 'addedRule']);
          benefitformArrayControl.value.forEach(item => {
            this.data.benefitList.findIndex(ele => 
              ele.reference === item.ind.reference) === -1 ? this.data.benefitList.push(item.ind) : null;
          });
          this.data.benefitList.sort((a, b) => a.displayName.localeCompare(b.displayName));
        }
        const formArrayControl = <FormArray>this.ruleForm.get(['ruleArray']);
        formArrayControl.removeAt(ruleIndex);
        this.disableSave = false;
        formArrayControl.value.length === 0 ? this.ruleForm = new FormGroup({
          ruleArray: new FormArray([
            this.initRuleSection()
          ]),
        }) : null;
      }
     
  }

  addRuleSection() {
    const control = <FormArray>this.ruleForm.get('ruleArray');
    control.push(this.initRuleSection());
    this.ruleForm.get('ruleArray').value.forEach(element => {
      this.ruleList = this.ruleList.filter(rule => rule.name !== element.rule);
    });
    this.ruleForm.get('ruleArray').value.forEach((rule) => {
      rule.addedRule.forEach(element => {
        this.data.benefitList = this.data.benefitList.filter(benefit => benefit.reference !== element.ind.reference);
      });
    });
    this.disableSave = false;
  }

  addRestrictionSection() {
    let restrictionType = this.getRestrictionType((<FormArray>this.restrictionForm.get('restrictionArray')).controls.length-1).value;
    if ((this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'departure_locations' ||
      this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'destination_locations'
    )) {
      restrictionType === 'Departure Location Includes…' ? this.restrictionList = this.restrictionList.filter(ele => ele.name !== 'Departure Location Excludes…') : null;
      restrictionType === 'Departure Location Excludes…' ? this.restrictionList = this.restrictionList.filter(ele => ele.name !== 'Departure Location Includes…') : null;
      restrictionType === 'Destination Location Includes…' ? this.restrictionList = this.restrictionList.filter(ele => ele.name !== 'Destination Location Excludes…') : null;
      restrictionType === 'Destination Location Excludes…' ? this.restrictionList = this.restrictionList.filter(ele => ele.name !== 'Destination Location Includes…') : null;
    }
    this.addEmptyRestrictions();
    this.restrictionForm.get('restrictionArray').value.forEach(element => {
      this.restrictionList = this.restrictionList.filter(restriction => restriction.name !== element.restriction);
    });
    this.addedRestrictionArray((<FormArray>this.restrictionForm.get('restrictionArray')).controls.length-1).clear();
    this.countryList = [...this.allCountryList];
    
  }
  get restrictionArray(): FormArray {
    return this.restrictionForm.controls.restrictionArray as FormArray;
  }
  
  addedRestrictionArray(index): FormArray {
    return this.restrictionArray.at(index).get('addedRestrictionArray') as FormArray;
  }

  getRestrictionType(index):FormControl {
    return this.restrictionArray.at(index).get('restriction') as FormControl;
  }

  addEmptyRestrictions() {
    this.restrictionArray.push(
      new FormGroup({
        restriction: new FormControl(''),
        addedRestrictionArray: new FormArray([])
      })
    );
  }
  addEmptyaddedRestriction(index,restrictSection) {
    let restrictionType = this.getRestrictionType(index).value;
    if ( (index ===  (<FormArray>this.restrictionForm.get('restrictionArray')).controls.length-1 ) && (this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'departure_locations' || 
        this.restrictionList[this.restrictionList.findIndex(obj => obj.name === restrictionType)].type === 'destination_locations'
    )) {
      this.addedRestrictionArray(index).clear();
      this.addedRestrictionArray(index).push(
        new FormControl('')
      );
    }
  }

  checkDescriptionAvailability(restrictSectionIndex) {
    if (this.getRestrictionType(restrictSectionIndex).value !== "" && this.allRestrictionList[this.allRestrictionList.findIndex(obj => obj.name === this.getRestrictionType(restrictSectionIndex).value)].type === "complex_rules") {
      return true;
    };  
    return false;
  }

  checkCountrySelectAvailability(restrictSectionIndex) {
    if (this.getRestrictionType(restrictSectionIndex).value !== "" && this.allRestrictionList[this.allRestrictionList.findIndex(obj => obj.name === this.getRestrictionType(restrictSectionIndex).value)].type === "departure_locations"
        || this.getRestrictionType(restrictSectionIndex).value !== "" && this.allRestrictionList[this.allRestrictionList.findIndex(obj => obj.name === this.getRestrictionType(restrictSectionIndex).value)].type === "destination_locations") {
      return true;
    };  
    return false;
  }


  getDescription(restrictSectionIndex) {
    return this.allRestrictionList[this.allRestrictionList.findIndex(obj => obj.name === this.getRestrictionType(restrictSectionIndex).value)].description
  }
  
  associateCountry(index, restrictItem, restrictSection) {
    this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList;
    if (restrictItem.value !== '' && (restrictSection.get('addedRestrictionArray').value.findIndex(ele=>ele===restrictItem.value) !== -1)) {
      this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList = this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList.filter(ele => ele.countryName !== restrictItem.value);
      this.addedRestrictionArray(index).push(
        new FormControl('')
      );
    }
  }

  getCountryListWRTType(restrictSection) {
    this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList.sort(function (a, b) {
      return a.countryName.localeCompare(b.countryName);
    });
    return this.countryListForEachRestrictionType[this.countryListForEachRestrictionType.findIndex(ele => ele.name === restrictSection.get('restriction').value)].countryList; 
  }
  
  addRestrictionFormField(index) {

  }
  initRuleSection() {
    return new FormGroup({
      rule: new FormControl(''),
      addedRule: new FormArray([
        this.initBenefitSection()
        ])
    });
  }

  initBenefitSection() {
    return new FormGroup({
      ind: new FormControl('', Validators.required)
    });
  }

  getaddedRule(form) {
    //console.log(form.get('options').controls);
    return form.controls.addedRule.controls;

  }
  getRuleSections(form) {
    //console.log(form.get('options').controls);
    return form.controls.ruleArray.controls;
  }

  addBenefit(e) {
    console.log(e);
    e.value.reference !== '' ? this.disableSave = false : null;
  }

  save() {
    const prerequisites = [];
    const exclusions = [];
    this.ruleForm.get('ruleArray').value.forEach((item) => {
      if (item.rule === 'Enable Linked Benefit') {
        item.addedRule.forEach((benefit) => {
          benefit.ind.reference ? prerequisites.push(benefit.ind.reference) : null;
        });
        this.data.prerequisites = prerequisites;
      } else if (this.ruleForm.get('ruleArray').value.length === 1 && 
          (!this.data.prerequisites || (this.data.prerequisites && this.data.prerequisites.length === 0))) {
        this.data.prerequisites = [];
      }
      if (item.rule === 'Disable Linked Benefit') {
        item.addedRule.forEach((benefit) => {
          benefit.ind.reference ? exclusions.push(benefit.ind.reference) : null;
        });
        this.data.benefit.exclusions = exclusions;
        this.data.benefit.excludeRule = [];
        this.data.benefit.exclusions.forEach(exclusion => {
          const index = this.originalBenefitList.findIndex( itemBenefit => itemBenefit.reference === exclusion);
          this.data.benefit.excludeRule.push(this.originalBenefitList[index].displayName);
        });
        
      } else if (this.ruleForm.get('ruleArray').value.length === 1 && 
          (!this.data.benefit.exclusions || (this.data.benefit.exclusions && this.data.benefit.exclusions.length === 0))) {
        this.data.benefit.exclusions = [];
      }
    });
    let sanitizedRestrictionForm = this.restrictionForm.value.restrictionArray.filter(ele => ele.restriction !== '');
    sanitizedRestrictionForm = sanitizedRestrictionForm.map(ele => {
      if (!(this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'departure_locations' ||
        this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'destination_locations'
      )) {
        return { restriction: ele.restriction }
      }
      return ele;
    });
    sanitizedRestrictionForm.forEach(ele => {
      if (ele.addedRestrictionArray) {
        ele.addedRestrictionArray = ele.addedRestrictionArray.filter(curr => curr !== '');
      }
    })
    sanitizedRestrictionForm.forEach(ele => {
      if (this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'departure_locations') {
        if (this.data.benefit.scopes.departure_locations) {
          ele.restriction === 'Departure Location Includes…' ? this.data.benefit.scopes.departure_locations.includes = [...ele.addedRestrictionArray] : null;
          ele.restriction === 'Departure Location Excludes…' ? this.data.benefit.scopes.departure_locations.excludes = [...ele.addedRestrictionArray] : null;
        } else if (ele.restriction === 'Departure Location Includes…' || ele.restriction === 'Departure Location Excludes…' ) {
          this.data.benefit.scopes['departure_locations'] = { includes: [], excludes: [] };
          ele.restriction === 'Departure Location Includes…' ? this.data.benefit.scopes.departure_locations.includes = [...ele.addedRestrictionArray] : null;
          ele.restriction === 'Departure Location Excludes…' ? this.data.benefit.scopes.departure_locations.excludes = [...ele.addedRestrictionArray] : null;
        }
      }
      if (this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'destination_locations') {
        if (this.data.benefit.scopes.destination_locations) {
          ele.restriction === 'Destination Location Includes…' ? this.data.benefit.scopes.destination_locations.includes = [...ele.addedRestrictionArray] : null;
          ele.restriction === 'Destination Location Excludes…' ? this.data.benefit.scopes.destination_locations.excludes = [...ele.addedRestrictionArray] : null;
        } else if (ele.restriction === 'Destination Location Includes…' || ele.restriction === 'Destination Location Excludes…' ) {
          this.data.benefit.scopes['destination_locations'] = { includes: [], excludes: [] };
          ele.restriction === 'Destination Location Includes…' ? this.data.benefit.scopes.destination_locations.includes = [...ele.addedRestrictionArray] : null;
          ele.restriction === 'Destination Location Excludes…' ? this.data.benefit.scopes.destination_locations.excludes = [...ele.addedRestrictionArray] : null;
        }
      }
      if (this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'complex_rules') {
        if (this.data.benefit.scopes.complex_rules) {
          if (this.data.benefit.scopes.complex_rules.findIndex(curr => curr.name === ele.restriction) === -1) {
            this.data.benefit.scopes.complex_rules.push({ name: ele.restriction, description: this.allRestrictionList.find(curr => curr.name === ele.restriction).description });
          }
        } else {
          this.data.benefit.scopes['complex_rules'] = [];
          this.data.benefit.scopes.complex_rules.push(this.data.benefit.scopes.complex_rules.push({ name: ele.restriction, description: this.allRestrictionList.find(curr => curr.name === ele.restriction).description }))
        }
      }
      if (this.allRestrictionList.find(obj => obj.name === ele.restriction).type === 'transferee_related') {
        if (this.data.benefit.scopes.spouse_partner) {
          ele.restriction === 'Transferee with Spouse' ? this.data.benefit.scopes.spouse_partner.spousePartnerRelocating = true : null;
        } else {
          this.data.benefit.scopes['spouse_partner'] = { spousePartnerRelocating: false }
          ele.restriction === 'Transferee with Spouse' ? this.data.benefit.scopes.spouse_partner.spousePartnerRelocating = true : null;
        }
      }
    });
    if (this.deletedComplexRestrictions.length > 0) {
      this.deletedComplexRestrictions.forEach(ele => this.data.benefit.scopes.complex_rules.push(ele));
    }
    this.dialogRef.close(this.data);
  }

}
