<table class="linked-list" [ngClass]="[ listStyleClasses ? listStyleClasses : '' ]" *ngIf="dataSet">
    <ng-container *ngFor="let item of dataSet; let i = index">
      <tr class="{{item.valueClass}}">
        <td cdk-cell class="col-key-dates"
          [ngClass]="[ columnStyleClasses && columnStyleClasses[0] ? columnStyleClasses[0] : '' ]">
          <div class="key-date" tabindex="0">
            {{item.value}}
          </div>
          <div class="vertical" *ngIf="i != dataSet.length - 1">
            <div>&nbsp;</div>
          </div>
        </td>
        <ng-container *ngFor="let column of item.columns; let j = index">
          <td class="col-data" *ngIf="column.type === 'star'; else default" [ngClass]="[ columnStyleClasses && columnStyleClasses[j + 1] ? columnStyleClasses[j + 1] : '' ]">
            <star-rating [rating]="column.label" matTooltip="{{ column.label }} star{{column.label > 1 ? 's' : ' '}}" [readOnly]="true" [starType]="'svg'"></star-rating>
          </td>
          <ng-template #default>
            <td class="col-data"
              [ngClass]="[ columnStyleClasses && columnStyleClasses[j + 1] ? columnStyleClasses[j + 1] : '' ]">
              <span tabindex="0">{{column.label}}</span><span tabindex="0" *ngIf="column.subLabel" class="small-text">{{column.subLabel}}</span>
            </td>
          </ng-template>
        </ng-container>
      </tr>
    </ng-container>
  </table>
