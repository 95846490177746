<div
  class="dialogMainContainer candidate-formcontainer"
  flexLayout
  fxLayout.xs="column"
  [ngStyle]="{ width: '40vh' }"
>
  <div
    mat-dialog-title
    disableTypography
    className="{styles.dialogTitle}"
    fxLayout="row"
    fxLayoutAlign="space-between"
    fxLayoutGap="20px"
    fxLayoutGap.xs="0em"
  >
    <div class="candidate-dialogtitle">{{ title }}</div>
    <a href="#" class="close-icon" (click)="onDismiss($event)" title="close">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <mat-dialog-content class="middle-section" [ngStyle]="{ overflow: 'hidden' }">
    <mat-divider></mat-divider>

    <mat-list [ngStyle]="{ 'max-height': '250px', 'overflow-y': 'auto' }">
      <div
        *ngFor="
          let overrideHistory of candidateProfile?.pointsOverrideHistory
            | arrayIterationOrder
        "
        fxLayout="column"
      >
        <div fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
          <div fxFlex="100%" fxLayout="column" fxLayoutAlign="end end">
            <span>Points Override:</span>
            <span>Override By:</span>
            <span>Override Date:</span>
          </div>

          <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start start">
            <span>{{ overrideHistory.points }}</span>
            <span>
              <mat-chip color="primary" selected>
                {{ loggedInUser.firstName | nameInitials }}
                {{ loggedInUser.lastName | nameInitials }}
              </mat-chip>
              {{ loggedInUser.firstName }}
            </span>
            <span>{{ overrideHistory.date }}</span>
          </div>
        </div>
        <mat-divider></mat-divider>
      </div>
    </mat-list>

    <div fxLayout="column">
      <div fxFlex="100%" fxLayout="row" fxLayoutGap="20px">
        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="end end">
          <span>Default Program Points:</span>
        </div>

        <div fxFlex="100%" fxLayout="column" fxLayoutAlign="start start">
          <span>{{ candidateProfile.programDetails?.originalPoints }}</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions
    class="statusBtn add-dialogbtn"
    fxLayout="row"
    fxLayoutAlign="center center"
    fxLayoutGap="10px"
    fxLayoutGap.xs="0px"
    fxLayoutAlign.xs="space-between center"
  >
    <button
      fxFlex="35%"
      type="button"
      mat-raised-button
      class="mat-button text-button"
      (click)="onDismiss($event)"
      color="primary"
      id="cancel"
    >
      CLOSE
    </button>
  </mat-dialog-actions>
</div>
