import moment from 'moment';

export const getDatefromCurrentDate = (numberOfdays, isFuture) => {
  let currentDate: any = new Date();
  if (isFuture) {
    return moment(currentDate.setDate(currentDate.getDate() + numberOfdays)).format('YYYY-MM-DD');
    //logic to add number of days for current date;
  } else {
    return moment(currentDate.setDate(currentDate.getDate() - numberOfdays)).format('YYYY-MM-DD')//logic to reduce number of days for currentdate
  }
}

export const transfereeInprogressData = {
  "consultantinfo": {},
  "benifits": [
    {
      "displayName": "Temporary Living Accommodations - up to 120 additional days",
      "category": "After Arrival",
      "confirmedDate": "2022-05-02",
      "reference": "OFF-TempLvngCoreIncrTimeBB-8ef7eea2-0637-4fed-9c2a-eef79b6335e4",
      "productId": "626f8c4f02585600090687f7",
      "points": "8Point(s):30Days",
      "incremental": true,
      "rangeIncrementUnit": "Days",
      "hybridTempLiving": true,
      "coreUnitsPerSelection": "0 Point(s)30Days",
      "tempLiving": true,
      "supplierInfo": {
        "supplierOrderReqLastUpdatedBy": "Sue Sibbitt",
        "supplierOrderReqLastUpdatedAt": "2022-05-13T05:18:05.303Z",
        "supplierOrderRequests": [
          {
            "viewAction": true,
            "status": "open",
            "supplierRequestId": "626f8c5550d84c000905f80f",
            "createdAtDate": "2022-05-02T07:46:29.200Z",
            "supplierOrderReference": "SUP-20220502-9810",
            "modification": 0,
            "modifications": [
              {
                "modificationId": "626bc1a3123646003936e6aa",
                "daysAdded": 15,
                "dateOfModification": "2022-04-29T00:00:00.000Z",
                "status": "Set Status",
                "totalLengthofStay": 13,
                "newMoveOutDate": "2022-06-16T00:00:00.000Z"
              },
            ],
            "statusDate": `${getDatefromCurrentDate(1, false)}`,
            "providerName": "Peter",
            "bookingAgent": "DwellWorks"
          },
          // {
          //   "viewAction": true,
          //   "status": "paused",
          //   "supplierRequestId": "626f8c5550d84c000905f80f",
          //   "createdAtDate": "2022-05-02T07:46:29.200Z",
          //   "supplierOrderReference": "SUP-20220502-9810",
          //   "modification": 0,
          //   "statusDate": "2022-05-04",
          //   "providerName": "Peter",
          //   "bookingAgent": "DwellWorks"
          // }
        ],
        "supplierOrderLastUpdatedBy": "Ana Martin",
        "supplierOrderLastUpdatedAt": "2022-05-05T11:55:45.656Z",
        // "supplierOrders": [
        //   {
        //     "viewAction": true,
        //     "status": "in-progress",
        //     "supplierRequestId": "626fa3817dac7c0009e5c90b",
        //     "createdAtDate": "2022-05-02T09:25:21.766Z",
        //     "supplierOrderReference": "SUP-20220502-2749",
        //     "fromRequest": "626f8c5550d84c000905f80f",
        //     "modification": 1,
        //     "modifications": [
        //       {
        //         "modificationId": "626bc1a3123646003936e6aa",
        //         "daysAdded": 15,
        //         "dateOfModification": "2022-04-29T00:00:00.000Z",
        //         "status": "Set Status",
        //         "totalLengthofStay": 13,
        //         "newMoveOutDate": "2022-06-16T00:00:00.000Z"
        //       },
        //     ],
        //     "statusDate": "2022-05-05",
        //     "providerName": "Peter",
        //     "bookingAgent": "Dwellworks Living"
        //   },
        // ],
        "lastUpdatedAt": `${getDatefromCurrentDate(1, false)}`,
        "lastUpdatedBy": "Sue Sibbitt"
      }
    },
    // {
    //   "displayName": "Temporary Living Accommodations - up to 120 additional days",
    //   "category": "After Arrival",
    //   "confirmedDate": "2022-05-02",
    //   "reference": "OFF-TmpLvngIncrTimeBB-de3c0555-4e62-4434-b08e-34b80ee5e11d",
    //   "productId": "626f8c52d810290009fb7657",
    //   "points": "8Point(s):30Days",
    //   "incremental": true,
    //   "rangeIncrementUnit": "Days",
    //   "hybridTempLiving": true,
    //   "tempLiving": true,
    //   "supplierInfo": {
    //       "supplierOrderReqLastUpdatedBy": "Ana Martin",
    //       "supplierOrderReqLastUpdatedAt": "2022-05-05T06:43:34.547Z",
    //       "supplierOrderRequests": [
    //           {
    //               "viewAction": false,
    //               "status": "in-progress",
    //               "supplierRequestId": "626f8c5666519d00090aa96a",
    //               "createdAtDate": "2022-05-02T07:46:30.115Z",
    //               "supplierOrderReference": "SUP-20220502-5255",
    //               "modification": 0,
    //               "statusDate": "2022-05-02",
    //               "bookingAgent": "DwellWorks"
    //           }
    //       ],
    //       "lastUpdatedAt": "2022-05-05T06:43:34.547Z",
    //       "lastUpdatedBy": "Ana Martin"
    //   }
    // }
  ],
  "candidateDetails": {
    "candidateId": "5f4cd0c1fa51820007dfea26",
    "orderRefNum": "MOV-r493db5r",
    "jobStartDate": "2020-10-27T18:30:00.000Z",
    "phoneDetailsList": [
      {
        "_id": "5f4cdb24dea9200007dc6a75",
        "textingAvailable": true,
        "primary": true,
        "usageType": "personal",
        "phoneDialCode": 91,
        "phoneNumber": "8971706157"
      }
    ],
    "emailDetailsList": [
      {
        "_id": "5f4cdb23dea9200007dc6a74",
        "primary": true,
        "usageType": "business",
        "emailAddress": "ashwath.n@mobilitydba.com"
      }
    ],
    "createdByNameDetails": {
      "firstName": "Initiator",
      "lastName": "One"
    },
    "nameDetails": {
      "title": "Mr.",
      "firstName": "test",
      "lastName": "Clark"
    },
    "ccinitiatorNameDetails": {
      "firstName": "Initiator",
      "lastName": "One"
    },
    "optionalDetails": {
      "costCenter": "",
      "employeeID": "",
      "poBillingInvoice": ""
    },
    "additionalDetails": [
      {
        "displayName": "# School Age Children",
        "type": "Number",
        "isAuthorizationRequired": true
      },
      {
        "displayName": "Authorizor",
        "type": "Text",
        "isAuthorizationRequired": true,
        "value": "Text testt"
      },
      {
        "displayName": "Policy Type",
        "type": "Text",
        "isAuthorizationRequired": true
      },
      {
        "displayName": "Secondary File #",
        "type": "Number",
        "isAuthorizationRequired": true
      }
    ],
    "authorizationDate": "2021-01-28T05:23:42.240Z",
    "invitationSentDate": "2020-08-31T11:12:36.957Z",
    "programDetails": {
      "programName": "Pre Standard HomeOwner1",
      "points": 50,
      "policyCallRequired": false
    },
    "departureAddr": {
      "streetLine1": "Test",
      "city": "Seattle",
      "state": "Washington",
      "country": "United States",
      "postalCode": "09984"
    },
    "destinationAddr": {
      "city": "New York",
      "state": "New York",
      "country": "United States"
    },
    "policyCallDetails": {},
    "divisionName": "People & Culture",
    "divisionNumber": "032"
  }
}