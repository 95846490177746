<div class="row powerbi-container" fxLayout="row" fxLayoutGap="2em" fxLayout.xs="column" fxLayoutGap.xs="1em">
  <mat-card fxFlex="33%" fxLayout.xs="column" fxFlex.xs="100%" class="powerbi-report mat-elevation-z4"
    ngClass.xs="report-vertical">
    <div class="report">
      <button mat-icon-button class="fullscreen" (click)="openFullscreen(authByTimeTemplate, 'authByTime')">
        <span tabindex="0" role="main" aria-label="Open Report Full Screen" class="material-icons">open_in_new</span>
      </button>
      <canvas #authByTime style="height: 305px; width: 497px;"></canvas>
    </div>
  </mat-card>
  <mat-card fxFlex="33%" fxLayout.xs="column" fxFlex.xs="100%" class="powerbi-report mat-elevation-z4"
    ngClass.xs="report-vertical" style="padding: 0;">
    <div class="report" (resized)="onResized($event)">
      <button mat-icon-button class="fullscreen" (click)="openFullscreen(programCostCategoriesTemplate, 'programCost')"
        style="background-color: white;">
        <span tabindex="0" role="main" aria-label="Open Report Full Screen" class="material-icons">open_in_new</span>
      </button>
      <img src="../../../../../../assets/csOnline/CartusOnline/pages/img/placeholder-02.png" width="100%" >
    </div>
  </mat-card>
  <mat-card fxFlex="33%" fxLayout.xs="column" fxFlex.xs="100%" class="powerbi-report mat-elevation-z4"
    ngClass.xs="report-vertical">
    <div class="report">
      <button mat-icon-button class="fullscreen" (click)="openFullscreen(serviceResultTemplate, 'serviceResult')">
        <span tabindex="0" role="main" aria-label="Open Report Full Screen" class="material-icons">open_in_new</span>
      </button>
      <canvas #serviceResultByTime style="height: 305px; width: 497px;"></canvas>
    </div>
  </mat-card>
</div>

<ng-template #authByTimeTemplate>
  <button mat-icon-button (click)="closeDialog()" class="close-button">
    <span class="material-icons">close</span>
  </button>
  <div class="ccu-theme chartPage zoom">
    <div class="flexBox zoomContainer">
      <div class="flexItem small-height" style='position:relative;'>
        <div>
          <a mat-icon-button (click)="closeDialog()" class="themableBackgroundColor" title="Click here to follow link"
            style="cursor: pointer;width: 45.5172px;height: 44.4828px;overflow: hidden;position: relative;background-color: transparent;border:none;display: inline-block;">
            <span class="button-text"
              style="text-align: center; max-width: 37.5172px; pointer-events: none; color: rgb(102, 102, 102); font-family: 'arial', wf_segoe-ui_normal, helvetica, arial, sans-serif; font-size: 13.3333px; position: absolute; left: 4px; top: 13.2414px;"></span>
            <div class="button-icon">
              <svg viewBox="0 0 32 32" width="36.48275862068966" height="36.48275862068966"
                style="fill: none; pointer-events: none; position: absolute; left: 4px; top: 4px;">
                <line x1="30" y1="15" x2="2" y2="15"
                  style="vector-effect: non-scaling-stroke; stroke-width: 5px; stroke: rgb(13, 133, 219); stroke-opacity: 1;">
                </line>
                <polyline points="15,2 2,15 15,30"
                  style="vector-effect: non-scaling-stroke; stroke-width: 5px; stroke: rgb(13, 133, 219); stroke-opacity: 1;">
                </polyline>
              </svg>
            </div>
            <div class="button-outline"
              style="pointer-events: none; top: 0px; left: 0px; position: absolute; width: 45.5172px; height: 44.4828px; border: none;">
            </div>
          </a>
          <div class="scrollWrapper" style="display: inline-block;vertical-align: text-bottom;">
            <div class="textbox"
              style="font-family: 'Segoe UI Light', wf_segoe-ui_light, helvetica, arial, sans-serif; font-size: 14px; color: rgb(7, 15, 37);">
              <p>
                <span class="textRun"
                  style="font-family: 'arial', wf_segoe-ui_normal, helvetica, arial, sans-serif; font-size: 36px; color: rgb(23, 49, 89);">Authorizations</span>
              </p>
            </div>
          </div>
        </div>

        <div class="YTDbuttons">
          <div style="flex: 0 0 75%;display:flex;justify-content: stretch;">
            <a (click)="showTab('ytdTab')" [ngClass]="{'selected': showAuthByTimeYtd === true}">YTD</a>
            <a (click)="showTab('mtdTab')" [ngClass]="{'selected': showAuthByTimeMtd === true}">MTD</a>
            <a (click)="showTab('last12monthsTab')" [ngClass]="{'selected': showLast12months === true}">Last
              12 Months</a>
          </div>
          <div style="position: absolute;right: 6px;width: 241px;z-index: 2;top: 49px;">
            <img src="assets/csOnline/CartusOnline/pages/img/select-image-1-off.png" style="width: 430px;height: auto;"
              onclick="$(this).hide().next().show()">
            <img src="assets/csOnline/CartusOnline/pages/img/select-image-1-on.png"
              style="width: 430px;height: auto; display:none" onmouseout="$(this).hide().prev().show()"
              onclick="$(this).hide().prev().show()">

          </div>

        </div>
      </div>
      <div class="flexItem  small-height" style="display: flex;">
        <div class="numberBucket"
          style="font-family: 'arial', wf_segoe-ui_normal, helvetica, arial, sans-serif;font-size: 18px;color: rgb(23, 49, 89);">
          <div class="top">Current Period</div>
          <div class="middle">{{selectedDynamicData.currentPeriod}}</div>
          <div class="bottom">
            <div>Intl<br>{{selectedDynamicData.currentPeriodIntl}}</div>
            <div>Dom<br>{{selectedDynamicData.currentPeriodDom}}</div>
          </div>
        </div>
        <div class="numberBucket"
          style="font-family: 'arial', wf_segoe-ui_normal, helvetica, arial, sans-serif;font-size: 18px;color: rgb(23, 49, 89);">
          <div class="top">Prior Period</div>
          <div class="middle">{{selectedDynamicData.priorPeriod}}</div>
          <div class="bottom">
            <div>Intl<br>{{selectedDynamicData.priorPeriodIntl}}</div>
            <div>Dom<br>{{selectedDynamicData.priorPeriodDom}}</div>
          </div>
        </div>
        <div class="numberBucket"
          style="font-family: 'arial', wf_segoe-ui_normal, helvetica, arial, sans-serif;font-size: 18px;color: rgb(23, 49, 89);">
          <div class="top">Change</div>
          <div class="middle" style="display: flex;justify-content: center;">
            <div>{{selectedDynamicData.change}}</div>
            <span style="
                    display: inline-block;
                    margin: -20px;
                    margin-right: -40px;">
              <svg width="80.8548168249661" height="67.17774762550883" class="card setFocusRing" tabindex="0"
                focusable="true" aria-label="Arrow ⇩.">
                <g transform="translate(40.42740841248305,47.588873812754414)"><text class="value"
                    style="text-anchor: middle; fill: rgb(23, 49, 89); font-family: wf_standard-font, helvetica, arial, sans-serif; font-size: 36px; white-space: pre;">
                    <tspan x="0" dy="0">⇩</tspan>
                    <title>⇩</title>
                  </text></g>
                <g></g>
              </svg>
            </span>
          </div>
        </div>

      </div>

      <div class="flexItem">
        <div class='whiteBox'>
          <canvas #authByTimeYtd *ngIf="showAuthByTimeYtd" style="height: 390px; width: 915px;"></canvas>
          <canvas #authByTimeMtd *ngIf="showAuthByTimeMtd" style="height: 390px; width: 915px;"></canvas>
          <canvas #last12MonthChart *ngIf="showLast12months" style="height: 390px; width: 915px;"></canvas>
          <button class='cornerButton' (click)='openBoxMenu($event)'> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class='boxMenu'>
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>
      <div class="flexItem">
        <div class="whiteBox">
          <img *ngIf="showAuthByTimeYtd"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd.png">
          <img *ngIf="showAuthByTimeMtd"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-mtd/top-policies-mtd.png">
          <img *ngIf="showLast12months"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies/top-policies-last12.png">
          <img class="callout-1"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd-callout-01.png"
            style="display: none;">
          <img class="callout-2"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd-callout-01a.png">
          <img class="callout-3"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd-callout-02.png"
            style="display: none;">
          <img class="callout-4"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd-callout-03.png">
          <img class="callout-5"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd-callout-03a.png">
          <img class="callout-6"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd-callout-04.png">
          <img class="callout-7"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd-callout-04a.png">
          <img class="callout-8"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd-callout-05.png">
          <img class="callout-9"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd-callout-05a.png">
          <img class="callout-10"
            src="assets/csOnline/CartusOnline/pages/img/charts/top-policies-ytd/top-policies-ytd-callout-06.png">
          <button class="calloutTrigger" style="left: 102px;width: 60px;height: 189px;top: 98px;" data-id="callout-1"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 162px;width: 60px;height: 231px;top: 57px;" data-id="callout-2"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 259px;width: 60px;height: 141px;top: 147px;" data-id="callout-3"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 413px;width: 60px;height: 54px;top: 235px;" data-id="callout-4"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 473px;width: 60px;height: 15px;top: 276px;" data-id="callout-5"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 567px;width: 60px;height: 24px;top: 267px;" data-id="callout-6"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 626px;width: 60px;height: 132px;top: 157px;" data-id="callout-7"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 721px;width: 60px;height: 20px;top: 269px;" data-id="callout-8"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 780px;width: 60px;height: 37px;top: 250px;" data-id="callout-9"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="cornerButton" (click)="openBoxMenu($event)"> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class="boxMenu">
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>
      <div class="flexItem">
        <div class='whiteBox'>
          <img *ngIf="showAuthByTimeYtd" src="assets/mp360/auth-A-05-ytd.png">
          <img *ngIf="showAuthByTimeMtd" src="assets/mp360/auth-A-05-mtd.png">
          <img *ngIf="showLast12months" src="assets/mp360/auth-A-05.png">
          <button class='cornerButton' (click)='openBoxMenu($event)'> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class='boxMenu'>
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>
      <div class="flexItem">
        <div class='whiteBox'>
          <img *ngIf="showAuthByTimeYtd" src="assets/csOnline/CartusOnline/pages/img/section1/auth-A-06-ytd.png">
          <img *ngIf="showAuthByTimeMtd" src="assets/csOnline/CartusOnline/pages/img/section1/auth-A-06-mtd.png">
          <img *ngIf="showLast12months" src="assets/csOnline/CartusOnline/pages/img/section1/auth-A-06.png">
          <button class='cornerButton' (click)='openBoxMenu($event)'> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class='boxMenu'>
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>
      <div class="flexItem full-width footer"></div>
    </div>


    <!-- START CART EXPANSION 1-->
    <div class="placeHolder-base2 " style='display:none;'>
      <button class="btn btn-sm btn-primary"
        style="position:absolute;top:60px;left: 30px;border-radius: 0 !important;width: 173px !important;height: 52px !important;background: none !important;/* border: none !important; */"
        type="button" data-section="placeHolder3b_01-static" onclick="showSection(this)"></button>

      <button class="btn btn-sm btn-primary"
        style="position:absolute;top:60px;left: 207px;border-radius: 0 !important;width: 173px !important;height: 52px !important;background: none !important;/* border: none !important; */"
        type="button" data-section="placeHolder3b_02-static" onclick="showSection(this)"></button>

      <button class="btn btn-sm btn-primary"
        style="position:absolute;top:60px;left: 380px;border-radius: 0 !important;width: 173px !important;height: 52px !important;background: none !important;/* border: none !important; */"
        type="button" data-section="placeHolder3b_03-static" onclick="showSection(this)"></button>
    </div>
  </div>
</ng-template>

<ng-template #programCostCategoriesTemplate>
  <button mat-icon-button (click)="closeDialog()" class="close-button">
    <span class="material-icons">close</span>
  </button>
  <div class="ccu-theme chartPage zoom">
    <div class="flexBox zoomContainer">
      <div class="flexItem small-height" style='position:relative;'>
        <div>
          <a mat-icon-button (click)="closeDialog()" class="themableBackgroundColor" title="Click here to follow link"
            style="cursor: pointer;width: 45.5172px;height: 44.4828px;overflow: hidden;position: relative;background-color: transparent;border:none;display: inline-block;">
            <span class="button-text"
              style="text-align: center; max-width: 37.5172px; pointer-events: none; color: rgb(102, 102, 102);   font-size: 13.3333px; position: absolute; left: 4px; top: 13.2414px;"></span>
            <div class="button-icon">
              <svg viewBox="0 0 32 32" width="36.48275862068966" height="36.48275862068966"
                style="fill: none; pointer-events: none; position: absolute; left: 4px; top: 4px;">
                <line x1="30" y1="15" x2="2" y2="15"
                  style="vector-effect: non-scaling-stroke; stroke-width: 5px; stroke: rgb(13, 133, 219); stroke-opacity: 1;">
                </line>
                <polyline points="15,2 2,15 15,30"
                  style="vector-effect: non-scaling-stroke; stroke-width: 5px; stroke: rgb(13, 133, 219); stroke-opacity: 1;">
                </polyline>
              </svg>
            </div>
            <div class="button-outline"
              style="pointer-events: none; top: 0px; left: 0px; position: absolute; width: 45.5172px; height: 44.4828px; border: none;">
            </div>
          </a>
          <div class="scrollWrapper" style="display: inline-block;vertical-align: text-bottom;">
            <div class="textbox" style=" font-size: 14px; color: rgb(7, 15, 37);">
              <p>
                <span class="textRun"
                  style="font-family: 'Segoe UI', wf_segoe-ui_normal, helvetica, arial, sans-serif; font-size: 36px; color: rgb(23, 49, 89);">Program
                  Cost</span>
              </p>
            </div>
          </div>
        </div>

        <div class="YTDbuttons">
          <div style="flex: 0 0 75%;display:flex;justify-content: stretch;">
            <a (click)="showProgramCostTab('ytdTab')" [ngClass]="{'selected': showProgramCostYtd === true}">YTD</a>
            <a (click)="showProgramCostTab('mtdTab')" [ngClass]="{'selected': showProgramCostMtd === true}">MTD</a>
            <a (click)="showProgramCostTab('last12monthsTab')"
              [ngClass]="{'selected': showProgramCostLast12Months === true}">Last 12
              Months</a>
          </div>
          <div style="position: absolute;right: 6px;width: 241px;z-index: 3;top: 4px;">
            <img src="assets/csOnline/CartusOnline/pages/img/select-image-2-off.png"
              style="width: 430px; height: auto; display: inline;" onclick="$(this).hide().next().show()">
            <img src="assets/csOnline/CartusOnline/pages/img/select-image-2-on.png"
              style="width: 430px; height: auto; display: none;" onmouseout="$(this).hide().prev().show()"
              onclick="$(this).hide().prev().show()">
          </div>
          <div style="position: absolute;right: 6px;width: 241px;z-index: 2;top: 66px;">
            <img src="assets/csOnline/CartusOnline/pages/img/select-image-1-off.png"
              style="width: 430px; height: auto; display: inline;" onclick="$(this).hide().next().show()">
            <img src="assets/csOnline/CartusOnline/pages/img/select-image-1-on.png"
              style="width: 430px; height: auto; display: none;" onmouseout="$(this).hide().prev().show()"
              onclick="$(this).hide().prev().show()">
          </div>
        </div>
      </div>
      <div class="flexItem  small-height" style="display: flex;">
        <div class="numberBucket" style=" font-size: 18px;color: rgb(23, 49, 89);">
          <div class="top">Current Period</div>
          <div class="middle">{{selectedDynamicData.currentPeriod}}</div>
          <div class="bottom">
            <div>Int'l<br>{{selectedDynamicData.currentPeriodIntl}}</div>
            <div>Dom<br>{{selectedDynamicData.currentPeriodDom}}</div>
          </div>
        </div>
        <div class="numberBucket" style=" font-size: 18px;color: rgb(23, 49, 89);">
          <div class="top">Prior Period</div>
          <div class="middle">{{selectedDynamicData.priorPeriod}}</div>
          <div class="bottom">
            <div>Int'l<br>{{selectedDynamicData.priorPeriodIntl}}</div>
            <div>Dom<br>{{selectedDynamicData.priorPeriodDom}}</div>
          </div>
        </div>
        <div class="numberBucket" style=" font-size: 18px;color: rgb(23, 49, 89);">
          <div class="top">Change</div>
          <div class="middle" style="display: flex;justify-content: center;">
            <div *ngIf="!selectedDynamicData.isPositiveChange" style="margin-left:100px;">{{selectedDynamicData.change}}</div>
            <div *ngIf="selectedDynamicData.isPositiveChange">{{selectedDynamicData.change}}</div>
            <span style="
                display: inline-block;
                margin: -20px;
                margin-right: -40px;">
              <svg width="80.8548168249661" height="67.17774762550883" class="card setFocusRing" tabindex="0"
                focusable="true" aria-label="Arrow ⇩." *ngIf="!selectedDynamicData.isPositiveChange">
                <g transform="translate(40.42740841248305,47.588873812754414)"><text class="value"
                    style="text-anchor: middle; fill: rgb(23, 49, 89); font-family: wf_standard-font, helvetica, arial, sans-serif; font-size: 36px; white-space: pre;">
                    <tspan x="0" dy="0">⇩</tspan>
                    <title>⇩</title>
                  </text></g>
                <g></g>
              </svg>
              <svg width="87" height="67" class="card setFocusRing" tabindex="0" focusable="true" aria-label="#Arrow ⇧." pbi-focus-tracker-idx="1">
                <g transform="translate(43.5,47.5)" *ngIf="selectedDynamicData.isPositiveChange">
                <text class="value" style="text-anchor: middle; fill: rgb(7, 15, 37); font-family: wf_standard-font, helvetica, arial, sans-serif; font-size: 36px; font-weight: normal; white-space: pre;">
                  <tspan x="0" dy="0">⇧</tspan><title>⇧</title></text></g><g></g></svg>
            </span>
          </div>
        </div>

      </div>


      <div class="flexItem">
        <div class="whiteBox">
          <img *ngIf="showProgramCostMtd"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/programCosts-mtd-01.png">
          <img *ngIf="showProgramCostYtd"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/programCosts-ytd-01.png">
          <img *ngIf="showProgramCostLast12Months"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/programCosts-last12-01.png">
          <img class="callout-1"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-01-01.png"
            style="display: none;">
          <img class="callout-2"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-01-02.png"
            style="display: none;">
          <img class="callout-3"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-01-03.png"
            style="display: none;">
          <img class="callout-4"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-01-04.png"
            style="display: none;">
          <img class="callout-5"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-01-05.png"
            style="display: none;">
          <button class="calloutTrigger" style="left: 468px;height: 173px;width: 136px;" data-id="callout-1"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 449px;height: 133px;width: 149px;top: 228px;" data-id="callout-2"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 308px;height: 137px;width: 136px;top: 223px;" data-id="callout-3"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 308px;height: 122px;width: 88px;top: 100px;" data-id="callout-4"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 401px;height: 49px;width: 58px;top: 80px;" data-id="callout-5"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>

          <button class="cornerButton" (click)="openBoxMenu($event)"> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class="boxMenu">
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>
      <div class="flexItem">
        <div class="whiteBox">
          <img *ngIf="showProgramCostYtd"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/programCosts-ytd-02.png">
          <img *ngIf="showProgramCostMtd"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/programCosts-mtd-02.png">
          <img *ngIf="showProgramCostLast12Months"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/programCosts-last12-02.png">
          <img class="callout-1"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-02-01a.png">
          <img class="callout-2"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-02-01b.png">
          <img class="callout-3"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-02-02a.png">
          <img class="callout-4"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-02-02b.png">
          <img class="callout-5"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-02-03a.png">
          <img class="callout-6"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-02-03b.png">
          <img class="callout-7"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-02-04a.png">
          <img class="callout-8"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-02-04b.png">
          <img class="callout-9"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-02-05a.png">
          <img class="callout-10"
            src="assets/csOnline/CartusOnline/pages/img/charts/programCosts/ProgramCost-MTD-02-05b.png"
            style="display: none;">
          <button class="calloutTrigger" style="left: 111px;height: 98px;top: 200px;width: 50px;" data-id="callout-1"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 161px;height: 98px;top: 200px;width: 50px;" data-id="callout-2"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 269px;height: 88px;top: 210px;width: 50px;" data-id="callout-3"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 322px;height: 233px;top: 66px;width: 50px;" data-id="callout-4"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 427px;height: 67px;top: 228px;width: 50px;" data-id="callout-5"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 475px;height: 51px;top: 246px;width: 50px;" data-id="callout-6"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 581px;height: 23px;top: 274px;width: 50px;" data-id="callout-7"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 631px;height: 38px;top: 258px;width: 50px;" data-id="callout-8"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 736px;height: 18px;top: 280px;width: 50px;" data-id="callout-9"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 792px;height: 18px;top: 275px;width: 50px;" data-id="callout-10"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>


          <button class="cornerButton" (click)="openBoxMenu($event)"> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class="boxMenu">
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>
      <div class="flexItem">
        <div class='whiteBox'>
          <img *ngIf="showProgramCostYtd"
            src="assets/mp360/programCosts-ytd-03.png">
          <img *ngIf="showProgramCostMtd"
            src="assets/mp360/programCosts-mtd-03.png">
          <img *ngIf="showProgramCostLast12Months"
            src="assets/mp360/programCosts-last12-03.png">
          <button class='cornerButton' (click)='openBoxMenu($event)'> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class='boxMenu'>
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>
      <div class="flexItem">
        <div class='whiteBox'>
          <img *ngIf="showProgramCostYtd" src="assets/mp360/programCosts-ytd-04.png">
          <img *ngIf="showProgramCostMtd" src="assets/mp360/programCosts-mtd-04.png">
          <img *ngIf="showProgramCostLast12Months" src="assets/mp360/programCosts-last12-04.png">

          <button class='cornerButton' (click)='openBoxMenu($event)'> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class='boxMenu'>
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>

      <div class="flexItem full-width footer"></div>
    </div>


    <!-- START CART EXPANSION 1-->
    <div class="placeHolder-base2 " style='display:none;'>
      <button class="btn btn-sm btn-primary"
        style="position:absolute;top:60px;left: 30px;border-radius: 0 !important;width: 173px !important;height: 52px !important;background: none !important;/* border: none !important; */"
        type="button" data-section="placeHolder3b_01-static" onclick="showSection(this)"></button>

      <button class="btn btn-sm btn-primary"
        style="position:absolute;top:60px;left: 207px;border-radius: 0 !important;width: 173px !important;height: 52px !important;background: none !important;/* border: none !important; */"
        type="button" data-section="placeHolder3b_02-static" onclick="showSection(this)"></button>

      <button class="btn btn-sm btn-primary"
        style="position:absolute;top:60px;left: 380px;border-radius: 0 !important;width: 173px !important;height: 52px !important;background: none !important;/* border: none !important; */"
        type="button" data-section="placeHolder3b_03-static" onclick="showSection(this)"></button>
    </div>
  </div>
</ng-template>

<ng-template #serviceResultTemplate>
  <button mat-icon-button (click)="closeDialog()" class="close-button">
    <span class="material-icons">close</span>
  </button>
  <div class="ccu-theme chartPage zoom">
    <div class="flexBox zoomContainer">
      <div class="flexItem small-height" style='position:relative;'>
        <div>
          <a mat-icon-button (click)="closeDialog()" class="themableBackgroundColor" title="Click here to follow link"
            style="cursor: pointer;width: 45.5172px;height: 44.4828px;overflow: hidden;position: relative;background-color: transparent;border:none;display: inline-block;">
            <span class="button-text"
              style="text-align: center; max-width: 37.5172px; pointer-events: none; color: rgb(102, 102, 102); font-family: &quot;Segoe UI&quot;, wf_segoe-ui_normal, helvetica, arial, sans-serif; font-size: 13.3333px; position: absolute; left: 4px; top: 13.2414px;"></span>
            <div class="button-icon">
              <svg viewBox="0 0 32 32" width="36.48275862068966" height="36.48275862068966"
                style="fill: none; pointer-events: none; position: absolute; left: 4px; top: 4px;">
                <line x1="30" y1="15" x2="2" y2="15"
                  style="vector-effect: non-scaling-stroke; stroke-width: 5px; stroke: rgb(13, 133, 219); stroke-opacity: 1;">
                </line>
                <polyline points="15,2 2,15 15,30"
                  style="vector-effect: non-scaling-stroke; stroke-width: 5px; stroke: rgb(13, 133, 219); stroke-opacity: 1;">
                </polyline>
              </svg>
            </div>
            <div class="button-outline"
              style="pointer-events: none; top: 0px; left: 0px; position: absolute; width: 45.5172px; height: 44.4828px; border: none;">
            </div>
          </a>
          <div class="scrollWrapper" style="display: inline-block;vertical-align: text-bottom;">
            <div class="textbox"
              style="font-family: &quot;Segoe UI Light&quot;, wf_segoe-ui_light, helvetica, arial, sans-serif; font-size: 14px; color: rgb(7, 15, 37);">
              <p>
                <span class="textRun"
                  style="font-family: &quot;Segoe UI&quot;, wf_segoe-ui_normal, helvetica, arial, sans-serif; font-size: 36px; color: rgb(23, 49, 89);">Service</span>
              </p>
            </div>
          </div>
        </div>

        <div class="YTDbuttons">
          <div style="flex: 0 0 75%;display:flex;justify-content: stretch;">
            <a (click)="showServiceResultTab('ytdTab')" [ngClass]="{'selected': showServiceResultYtd === true}">YTD</a>
            <a (click)="showServiceResultTab('mtdTab')" [ngClass]="{'selected': showServiceResultMtd === true}">MTD</a>
            <a (click)="showServiceResultTab('last12monthsTab')"
              [ngClass]="{'selected': showServiceResultLast12Months === true}">Last 12 Months</a>
          </div>
          <div style="position: absolute;right: 6px;width: 241px;z-index: 2;top: 49px;">
            <img src="assets/csOnline/CartusOnline/pages/img/select-image-1-off.png" style="width: 430px;height: auto;"
              onclick=$(this).hide().next().show()>
            <img src="assets/csOnline/CartusOnline/pages/img/select-image-1-on.png"
              style="width: 430px;height: auto; display:none" onmouseout="$(this).hide().prev().show()"
              onclick="$(this).hide().prev().show()">

          </div>

        </div>
      </div>
      <div class="flexItem  small-height" style="display: flex;">
        <div class="numberBucket"
          style="font-family: &quot;Segoe UI&quot;, wf_segoe-ui_normal, helvetica, arial, sans-serif;font-size: 18px;color: rgb(23, 49, 89);">
          <div class="top">Current Favorable</div>
          <div class="middle">{{selectedDynamicData.currentPeriod}}</div>
          <div class="bottom">
            <div>Int'l<br>{{selectedDynamicData.currentPeriodIntl}}</div>
            <div>Dom<br>{{selectedDynamicData.currentPeriodDom}}</div>
          </div>
        </div>
        <div class="numberBucket"
          style="font-family: &quot;Segoe UI&quot;, wf_segoe-ui_normal, helvetica, arial, sans-serif;font-size: 18px;color: rgb(23, 49, 89);">
          <div class="top">Prior Favorable</div>
          <div class="middle">{{selectedDynamicData.priorPeriod}}</div>
          <div class="bottom">
            <div>Int'l<br>{{selectedDynamicData.priorPeriodIntl}}</div>
            <div>Dom<br>{{selectedDynamicData.priorPeriodDom}}</div>
          </div>
        </div>
        <div class="numberBucket"
          style="font-family: &quot;Segoe UI&quot;, wf_segoe-ui_normal, helvetica, arial, sans-serif;font-size: 18px;color: rgb(23, 49, 89);">
          <div class="top">Change</div>
          <div class="middle" style="display: flex;justify-content: center;">
            <div>{{selectedDynamicData.change}}</div>
            <span style="
                transform: rotate(180deg);
                display: inline-block;
                margin: -20px;
                margin-right: -40px;">
              <svg width="80.8548168249661" height="67.17774762550883" class="card setFocusRing" tabindex="0"
                focusable="true" aria-label="Arrow ⇩.">
                <g transform="translate(40.42740841248305,47.588873812754414)"><text class="value"
                    style="text-anchor: middle; fill: rgb(23, 49, 89); font-family: wf_standard-font, helvetica, arial, sans-serif; font-size: 36px; white-space: pre;">
                    <tspan x="0" dy="0">⇩</tspan>
                    <title>⇩</title>
                  </text></g>
                <g></g>
              </svg>
            </span>
          </div>
        </div>

      </div>

      <div class="flexItem">
        <div class='whiteBox'>
          <img *ngIf="showServiceResultYtd"
            src="assets/csOnline/CartusOnline/pages/img/charts/services/services-ytd-01.png">
          <img *ngIf="showServiceResultMtd"
            src="assets/csOnline/CartusOnline/pages/img/charts/services/services-mtd-01.png">
          <img *ngIf="showServiceResultLast12Months"
            src="assets/csOnline/CartusOnline/pages/img/charts/services/services-last12-01.png">
          <button class='cornerButton' (click)='openBoxMenu($event)'> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class='boxMenu'>
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>
      <div class="flexItem">
        <div class='whiteBox'>
          <canvas *ngIf="showServiceResultYtd" #serviceResultYtd style="height: 405px; width:915px;"></canvas>
          <canvas *ngIf="showServiceResultMtd" #serviceResultMtd style="height: 405px; width:915px;"></canvas>
          <canvas *ngIf="showServiceResultLast12Months" #serviceResultLast12Months
            style="height: 405px; width:915px;"></canvas>
          <button class='cornerButton' (click)='openBoxMenu($event)'> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class='boxMenu'>
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>
      <div class="flexItem">
        <div class='whiteBox'>
          <img *ngIf="showServiceResultYtd"
            src="assets/csOnline/CartusOnline/pages/img/charts/services/services-ytd-03.png">
          <img *ngIf="showServiceResultMtd"
            src="assets/csOnline/CartusOnline/pages/img/charts/services/services-mtd-03.png">
          <img *ngIf="showServiceResultLast12Months"
            src="assets/csOnline/CartusOnline/pages/img/charts/services/services-last12-03.png">
          <button class='cornerButton' (click)='openBoxMenu($event)'> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class='boxMenu'>
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>
      <div class="flexItem">
        <div class="whiteBox">
          <img *ngIf="showServiceResultYtd"
            src="assets/mp360/services-ytd-04.png">
          <img *ngIf="showServiceResultMtd"
            src="assets/mp360/services-mtd-04.png">
          <img *ngIf="showServiceResultLast12Months"
            src="assets/mp360/services-last12-04.png">
          <img class="callout-1" src="assets/csOnline/CartusOnline/pages/img/charts/services/services-ytd-04-01a.png">
          <img class="callout-2" src="assets/csOnline/CartusOnline/pages/img/charts/services/services-ytd-04-01b.png"
            style="display: none;">
          <img class="callout-3" src="assets/csOnline/CartusOnline/pages/img/charts/services/services-ytd-04-02a.png">
          <img class="callout-4" src="assets/csOnline/CartusOnline/pages/img/charts/services/services-ytd-04-02b.png">
          <button class="calloutTrigger" style="left: 273px;height: 255px;top: 66px;width: 89px;" data-id="callout-1"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 362px;height: 258px;top: 61px;width: 89px;" data-id="callout-2"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 508px;height: 132px;top: 186px;width: 89px;" data-id="callout-3"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>
          <button class="calloutTrigger" style="left: 599px;height: 268px;top: 54px;width: 89px;" data-id="callout-4"
            onmouseenter="showPopup(this)" onmouseleave="hidePopup(this)"></button>


          <button class="cornerButton" (click)="openBoxMenu($event)"> <img
              src="assets/csOnline/CartusOnline/pages/img/icons/cornerButton.png"
              style="width: 61px;height: 32px;"></button>
          <div class="boxMenu">
            <img src="assets/csOnline/CartusOnline/pages/img/cornerButtonMenu.png"
              style="width: 165px;height: auto;box-shadow: -2px 2px 6px #a9a9a9;border: 1px solid #cecece;">
          </div>
        </div>
      </div>

      <div class="flexItem full-width footer"></div>
    </div>


    <!-- START CART EXPANSION 1-->
    <div class="placeHolder-base2 " style='display:none;'>
      <button class="btn btn-sm btn-primary"
        style="position:absolute;top:60px;left: 30px;border-radius: 0 !important;width: 173px !important;height: 52px !important;background: none !important;/* border: none !important; */"
        type="button" data-section="placeHolder3b_01-static" onclick="showSection(this)"></button>

      <button class="btn btn-sm btn-primary"
        style="position:absolute;top:60px;left: 207px;border-radius: 0 !important;width: 173px !important;height: 52px !important;background: none !important;/* border: none !important; */"
        type="button" data-section="placeHolder3b_02-static" onclick="showSection(this)"></button>

      <button class="btn btn-sm btn-primary"
        style="position:absolute;top:60px;left: 380px;border-radius: 0 !important;width: 173px !important;height: 52px !important;background: none !important;/* border: none !important; */"
        type="button" data-section="placeHolder3b_03-static" onclick="showSection(this)"></button>
    </div>
  </div>
</ng-template>
