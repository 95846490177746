import { Injectable } from '@angular/core';
import { BaseClientService } from './base-client.service';
import { RemoteLoggingService } from './remote-logging.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { countryInfoData, employeeData } from '../data/data';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  constructor(
    protected baseClient: BaseClientService,
    private readonly logSvc: RemoteLoggingService
  ) {}

  getLocations(type: string, searchText: string): Observable<any> {
    // return this.baseClient
    //   .getArr<string>(`v1/locations?type=${type}&searchText=${searchText}`, 'get location name')
    //   .pipe(
    //     map(r => r.body),
    //     catchError((err, source) => {
    //       const emptyArray: string[] = [];
    //       this.logSvc.logError(err);
    //       return of(emptyArray);
    //     })
    //   );
    let data =[
      {
        name:"country",
        label:"Contries",
        values:[]
      },
      {
        name:"state",
        label:"States",
        values:[]
      },
      {
        name:"city",
        label:"Cities",
        values :[]
      }
    ];
    let departurecities = [];
    let destinationcities = [];
    let departureStates = [];
    let destinationStates = [];

    employeeData.data.forEach(element =>{
      departureStates.push(element.departureState);
      departurecities.push(element.departureCity);
      destinationStates.push(element.destinationState);
      destinationcities.push(element.destinationCity);
        })
        let parentStates =[];
  if(type === 'dept') {
    parentStates =departureStates;
  } else  {
    if(type==='dest'){
      parentStates=destinationStates;
    }
  }
  let parentCities=[];
  if(type === 'dept') {
    parentCities =departurecities;
  } else  {
    if(type==='dest'){
      parentCities=destinationcities;
    }
  }
  let states=parentStates.filter(statesname=>{
    return statesname.toLowerCase().includes(searchText.toLowerCase())
  });
  const statess=new Set(states);
  const displaystates=[...statess];
  let city=parentCities.filter(statesname=>{
     return statesname.toLowerCase().includes(searchText.toLowerCase())
  });
  const cities=new Set(city);
  const displaycities=[...cities];   

  let searchCountry=  countryInfoData.filter(countryname=>{
    return countryname.countryName.toLowerCase().includes(searchText.toLowerCase())
     });
     searchCountry.forEach(ele=>{
       data[0].values.push(ele.countryName)
     })

     data[2].values=displaycities;
    data[1].values=displaystates;
        return of(data);
  }
  
}
